import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils';

const _localCache = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    total: 0,
    filledSlots: 0,
    openSlots: 0,
    volunteerCommitted: 0,
    volunteerInterested: 0,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunitySearchActions.ON_STATISTICS':
            return action.result;
    }
    return state;
}
