import { combineReducers } from 'redux';
import overview from './overview/overview.reducer.js';
import overviewTaskList from './overviewTaskListPage/overviewTaskList.reducer.js';
import task from './task/task.reducer.js';
import taskCategory from './taskCategory/taskCategory.reducer.js';
import taskGroups from './taskGroups/taskGroups.reducer.js';
import taskList from './taskList/taskList.reducer.js';
import tasksReassignDrawer from './taskList/tasksReassignDrawer.reducer.js';

const reducers = combineReducers({
    overview,
    overviewTaskList,
    task,
    taskCategory,
    tasksReassignDrawer,
    taskList,
    taskGroups,
});

export default reducers;
