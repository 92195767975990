import {
    isEqual,
    isNil,
} from 'lodash';
import { GIVING_ACTIVITY_SORT_OPTION_DEFAULT } from './constants';
import {
    Filters,
    GiverFilterTypeId,
    GivingActivityFilterState,
} from './models';
import { getFilteredArrayByIsChecked } from './utils';
import ActionType from './listPage.actionTypes';

export const DEFAULT_FILTER_STATE: Filters = {
    amountRangeEnd: null,
    amountRangeStart: null,
    channelTypes: [],
    designations: [],
    giverTypes: [
        GiverFilterTypeId.Guest,
        GiverFilterTypeId.Registered,
    ],
    paymentDateEnd: null,
    paymentDateStart: null,
    paymentMethodTypes: [],
    paymentStatuses: [],
    sort: GIVING_ACTIVITY_SORT_OPTION_DEFAULT,
};

export const DEFAULT_STATE: GivingActivityFilterState = {
    appliedFilters: { ...DEFAULT_FILTER_STATE },
    areFiltersDirty: false,
    dirtyFilters: { ...DEFAULT_FILTER_STATE },
    isFiltering: false,
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (
    state: GivingActivityFilterState | undefined,
    action: any,
): GivingActivityFilterState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.RESET_FILTERS: {
                return DEFAULT_STATE;
            }

            case ActionType.SET_APPLY_FILTERS: {
                const { dirtyFilters } = state;
                const isFiltering = !isEqual(dirtyFilters, DEFAULT_FILTER_STATE);

                return {
                    ...state,
                    appliedFilters: { ...dirtyFilters },
                    areFiltersDirty: false,
                    isFiltering,
                };
            }

            case ActionType.SET_CLEAR_FILTERS: {
                return updateDirtyFilterState(state, { ...DEFAULT_FILTER_STATE });
            }

            case ActionType.SET_DIRTY_SORT_ORDER: {
                return updateDirtyFilterState(
                    state,
                    { ...state.dirtyFilters,
                        sort: action.value },
                );
            }

            case ActionType.SET_DIRTY_CHANNEL_TYPE_FILTER_CHANGE: {
                const channelTypes = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    channelTypes,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_DESIGNATION_TYPE_IDS_FILTER_CHANGE: {
                const designations = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    designations,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_GIVER_TYPE_FILTER_CHANGE: {
                const { giverTypeId, isChecked } = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    giverTypes: getFilteredArrayByIsChecked(
                        state.dirtyFilters.giverTypes,
                        DEFAULT_FILTER_STATE.giverTypes,
                        giverTypeId,
                        isChecked,
                    ),
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_PAYMENT_AMOUNT_RANGE_END: {
                const amountRangeEnd = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    amountRangeEnd,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_PAYMENT_AMOUNT_RANGE_START: {
                const amountRangeStart = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    amountRangeStart,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_PAYMENT_END_DATE_FILTER_CHANGE: {
                const paymentDateEnd = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    paymentDateEnd,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_PAYMENT_METHOD_TYPE: {
                const paymentMethodTypes = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    paymentMethodTypes,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_PAYMENT_STATUS_FILTER_CHANGE: {
                const paymentStatuses = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    paymentStatuses,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_PAYMENT_START_DATE_FILTER_CHANGE: {
                const paymentDateStart = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    paymentDateStart,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            default:
                return state;
        }
    }

    return state;
};
