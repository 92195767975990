import '@babel/register';
// eslint-disable-next-line import/no-unresolved
import 'css-cm-ui';
// eslint-disable-next-line import/no-unresolved
import 'images/browser-icons-24px.png';

import React from 'react';
import { StylesProvider } from '@material-ui/styles';
import { versions } from '@saddlebackchurch/react-cm-ui';
import {
    theme,
    ThemeProvider,
} from '@saddlebackchurch/react-cm-ui/core/styles';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
    browserHistory,
    Router,
} from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { appReduxStore } from './global/configureReduxStore.js';
import { isCmProductionEnv } from './global/utils/environments.js';
import { cmAuthUtils } from './global/utils/utils.js';
import routes from './routes.js';
import {
    initializeAnalytics,
    isAnalyticsAvailable,
} from './tools/analytics'; // eslint-disable-line import/extensions
import jssPreset from './tools/jssPreset.js';

window.versions = versions;

if (isCmProductionEnv) {(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments) },i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','https://www.google-analytics.com/analytics.js','ga')}; // eslint-disable-line

// Should at some point extract the Client ID from the API or somewhere...
function gaSend(location, userID) {
    if (isCmProductionEnv) {
        /* eslint-disable no-undef */
        ga('create', 'UA-1141682-58', 'auto'); // NOSONAR
        ga('set', 'page', location.hash); // NOSONAR
        ga('set', { // NOSONAR
            dimension1: userID,
            dimension2: location.host,
        });
        ga('send', 'pageview'); // NOSONAR
        /* eslint-enable no-undef */
    }
}

function fireTracking() {
    // Google Analytics to send page & user data
    if (typeof gaSend === 'function') {
        gaSend(window.location, cmAuthUtils.getUserPersonUniqueId());
    }
}

function onRouterUpdate() {
    // Ensure we scroll to the top of the page when we transition to a new route
    window.scrollTo(0, 0);

    // And ... fire off some tracking if we must! ;-)
    fireTracking();
}

if (isAnalyticsAvailable()) {
    initializeAnalytics();
}

const container = document.getElementById('coreApp');
const history = syncHistoryWithStore(browserHistory, appReduxStore);
const root = ReactDOM.createRoot(container);
const jss = jssPreset();

root.render(
    <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
            <Provider store={appReduxStore}>
                <Router
                    history={history}
                    onUpdate={onRouterUpdate}
                    routes={routes}
                />
            </Provider>
        </ThemeProvider>
    </StylesProvider>,
);
