import './memberStatusDot.scss';

import _ from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ToolTip from 'react-tooltip';
import EnumerationStore from '../js/stores/EnumerationStore.js';
import { translationFactory } from './i18nUtils.js';

const i18n = translationFactory('Person.MembershipStatus');

function MemberStatusDot(props) {
    const {
        className,
        deceasedDate: isDeceased,
        memberStatus: currentStatus,
        style,
        toolTip,
    } = props;
    const status = isDeceased ? i18n('Deceased') : currentStatus;
    const label = status ?
        `${i18n('MembershipStatus')}: ${EnumerationStore.getMembershipStatusName(status)}` :
        '';
    let memberStatusClass;

    switch (status) {
        case 'Deceased':
            memberStatusClass = 'deceased';
            break;
        case 'DisengagedMember':
        case 'Disengaged Member':
            memberStatusClass = 'inactive';
            break;
        case 'Member':
            memberStatusClass = 'member';
            break;
        case 'MembershipReview':
        case 'Membership Review':
            memberStatusClass = 'under-review';
            break;
        case 'NonMember':
        case 'Non-Member':
            memberStatusClass = 'not-a-member';
            break;
        case 'Pending':
            memberStatusClass = 'pending';
            break;
        case 'Unknown':
        default:
            memberStatusClass = 'unknown';
            break;
    }

    const statusClasses = ClassNames('membership-status', memberStatusClass, className);

    return (
        <span className={statusClasses} data-tip={label} style={style}>
            {_.isUndefined(toolTip) || toolTip ? <ToolTip /> : null}
        </span>
    );
}

MemberStatusDot.propTypes = {
    className: PropTypes.string,
    deceasedDate: PropTypes.number,
    memberStatus: PropTypes.string,
    style: PropTypes.shape({}),
    toolTip: PropTypes.bool,
};

MemberStatusDot.defaultProps = {
    className: undefined,
    deceasedDate: undefined,
    memberStatus: undefined,
    style: undefined,
    toolTip: undefined,
};

export default MemberStatusDot;
