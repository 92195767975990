import request from 'axios';
import ApiUtils from '../../global/apiUtils.js';

class AppSettingsAPIUtils extends ApiUtils {
}

const appSettingsAPIUtils = new AppSettingsAPIUtils('/api/app-settings/');

appSettingsAPIUtils.buildClass(
    { name: 'save', rq: request.put, route: '{appSettingsId}' },
);

export default appSettingsAPIUtils;
