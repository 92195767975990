import React, {
    useCallback,
    useRef,
    useState,
} from 'react';
import {
    Address,
    dateUtils as reactCmUiDateUtils,
    EmailLink,
    Icon,
    Loader,
    PersonCoreMilestones,
    Table,
    TelephoneLink,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import ClassNames from 'classnames';
import {
    isEmpty,
    isNaN,
    noop,
} from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { PeoplePathnameSegmentType } from '../../../app/navigation/models';
import { i18n } from '../../../global/constants.js';
import UserAccessStore from '../../../global/userAccessStore.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import Utils from '../../../global/utils/utils.js';
import PersonRecordDrawer from '../../record/global/personRecordDrawer';
import { BEM_BLOCK_NAME } from '../searchConstants';
import { PERSON_SEARCH_MAX_RESULTS_LENGTH } from './constants.js';
import PersonAvatarImage from './personAvatarImage.jsx';
import PersonPersonalInfo from './personPersonalInfo.jsx';

const propTypes = {
    canLoadMore: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
        address: PropTypes.string,
        personCoreMilesones: PropTypes.string,
        preferredContactMethod: PropTypes.string,
        refineQueryWarning: PropTypes.string,
        root: PropTypes.string,
        searchBck: PropTypes.string,
        tableCellEmpty: PropTypes.string,
        tableHeaderCell: PropTypes.string,
        tableHeaderCellCoreMilestones: PropTypes.string,
        singleLine: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
    }),
    fetchMore: PropTypes.func,
    isDrawerChild: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    onDrawerClose: PropTypes.func.isRequired,
    persons: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    total: PropTypes.number,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    classes: {},
    fetchMore: noop,
    persons: [],
    total: null,
};

const styles = ({
    palette,
    typography,
}) => ({
    address: {
        '& span': {
            whiteSpace: 'nowrap',
        },
    },
    root: {},
    personalBasicInfoWrapper: {
        display: 'flex',
    },
    personCoreMilesones: {
        '& .grid-row': {
            width: 'auto',
            flexWrap: 'nowrap',
        },
    },
    preferredContactMethod: {
        lineHeight: '20px',
    },
    tableCellEmpty: {
        padding: '0 !important',
    },
    tableHeaderCell: {
        backgroundColor: [palette.background.primary, '!important'],
        color: [palette.text.secondary, '!important'],
        lineHeight: '20px',
        paddingTop: '11px !important',
    },
    tableHeaderCellCoreMilestones: {
        minWidth: 173,
        width: 173,
    },
    refineQueryWarning: {
        textAlign: 'center',
        padding: 11,
    },
    singleLine: {
        whiteSpace: 'nowrap',
    },
    subTitle: {
        color: palette.text.secondary,
        fontWeight: typography.fontWeightMedium,
        lineHeight: '18px',
        fontSize: 12,
        whiteSpace: 'nowrap',
    },
    title: {
        color: palette.text.primary,
        fontWeight: typography.fontWeightRegular,
        lineHeight: '20px',
        fontSize: 14,
    },
});

const bemBlockName = 'person_search';

const MemoizedPersonCoreMilestones = React.memo(PersonCoreMilestones);

function FunctionBasedTableRow(props) {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Table.Row {...props} />
    );
}

const MemoizedTableRow = React.memo(FunctionBasedTableRow, () => (true));

export function PersonListTableView(props) {
    const {
        canLoadMore,
        classes,
        fetchMore,
        isDrawerChild,
        isFetching,
        isMobile,
        onDrawerClose,
        persons,
        router,
        total,
        userAccess,
    } = props;

    const [isPersonRecordDrawerOpen, setIsPersonRecordDrawerOpen] = useState(false);
    const [selectedPersonRecordId, setSelectedPersonRecordId] = useState(null);

    const canAccessDateOfBirth = userAccess.hasPermission(
        USER_PERMISSIONS.accessDateOfBirth,
    );

    const observer = useRef();

    const fetchMoreRef = useCallback((node) => {
        if (isFetching) {
            return null;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && canLoadMore) {
                fetchMore();
            }
        });

        if (node) {
            observer.current.observe(node);
        }
        return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    if (isEmpty(persons)) {
        return null;
    }

    const onViewRecord = (personId) => {
        if (isDrawerChild) {
            let pathnameSegments = window.location.pathname.split('/');
            pathnameSegments = pathnameSegments.slice(1, pathnameSegments.length);
            const pathnameSegmentsLength = pathnameSegments.length;

            let shouldNotOpenInDrawer = true;

            if (pathnameSegmentsLength >= 4) {
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < pathnameSegmentsLength; i++) {
                    if (i === 0) {
                        shouldNotOpenInDrawer =
                            pathnameSegments[i] !== PeoplePathnameSegmentType.People;
                    } else if (i === 1) {
                        shouldNotOpenInDrawer =
                            pathnameSegments[i] !== PeoplePathnameSegmentType.SearchOrAdd;
                    } else if (i === 2) {
                        shouldNotOpenInDrawer =
                            pathnameSegments[i] !== PeoplePathnameSegmentType.Record;
                    } else if (i === 3) {
                        shouldNotOpenInDrawer = isNaN(pathnameSegments[i] * 1);
                    }

                    if (shouldNotOpenInDrawer) {
                        break;
                    }
                }
            }

            if (shouldNotOpenInDrawer) {
                setIsPersonRecordDrawerOpen(true);
                setSelectedPersonRecordId(personId);

                return;
            }

            onDrawerClose();
        }

        router.push(`/people/search-or-add/record/${personId}`);
    };

    const onPersonRecordDrawerClose = ({ closePersonSearchDrawer = false }) => {
        setIsPersonRecordDrawerOpen(false);
        setSelectedPersonRecordId(null);
        if (closePersonSearchDrawer) {
            onDrawerClose();
        }
    };

    const personIdTableHeaderCellClasses = ClassNames(
        classes.tableHeaderCell,
        {
            'table-header-cell-one': !isMobile,
        },
    );

    const shouldRenderRefineQueryWarning = persons.length >= PERSON_SEARCH_MAX_RESULTS_LENGTH &&
        total > PERSON_SEARCH_MAX_RESULTS_LENGTH;

    const rows = persons.map((item) => {
        const {
            birthDate,
            formattedContactInfo: {
                email,
                phone,
                address,
            },
            contactPreferences: {
                preferredMethod,
            },
        } = item;

        const formattedBirthDate = reactCmUiDateUtils.formatDate(birthDate);
        let coreMilestonesData = item.coreMilestonesData || {};

        if (!isEmpty(coreMilestonesData)) {
            coreMilestonesData = {
                ...coreMilestonesData,
                hasTakenClass101: coreMilestonesData.hasAttendedClass101,
                hasTakenClass201: coreMilestonesData.hasAttendedClass201,
                hasTakenClass301: coreMilestonesData.hasAttendedClass301,
                hasTakenClass401: coreMilestonesData.hasAttendedClass401,
                gender: item.gender,
                recordType: item.recordType,
            };
        }

        return (
            <MemoizedTableRow
                id={`person-list-${item.id}-row`}
                key={`person-list-${item.id}-row`}
                onClick={() => onViewRecord(item.id)}
            >
                <Table.Cell>
                    <PersonAvatarImage
                        person={item}
                    />
                </Table.Cell>

                <Table.Cell>
                    <PersonPersonalInfo
                        person={item}
                    />
                </Table.Cell>

                <Table.Cell textAlign="left">
                    <MemoizedPersonCoreMilestones
                        backgroundTransparent
                        className={classes.personCoreMilesones}
                        data={coreMilestonesData}
                        disablePopover
                        id={`${bemBlockName}--core_milestones`}
                        removeCongregationDateColumn
                        removeFirstContactDateColumn
                    />
                </Table.Cell>

                <Table.Cell>
                    {email && (
                        <EmailLink
                            email={email.email}
                        />
                    )}
                    {email?.isPreferred && (
                        <Typography className={classes.subTitle}>
                            {i18n('Preferred Contact')}
                        </Typography>
                    )}
                </Table.Cell>

                <Table.Cell>
                    {phone && (
                        <TelephoneLink
                            className={classes.singleLine}
                            number={phone.number}
                        />
                    )}
                    {(phone?.isPreferred || (preferredMethod === 'text-message')) && (
                        <Typography className={classes.subTitle}>
                            {i18n('Preferred Contact')}
                        </Typography>
                    )}
                </Table.Cell>

                <Table.Cell>
                    {address && (
                        <div className={classes.address}>
                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                            <Address {...address} />
                        </div>
                    )}
                    {address?.isPreferred && (
                        <Typography className={classes.subTitle}>
                            {i18n('Preferred Contact')}
                        </Typography>
                    )}
                </Table.Cell>

                <Table.Cell>
                    {item.id}
                </Table.Cell>

                <Table.Cell
                    style={{
                        display: canAccessDateOfBirth ? 'table-cell' : 'none',
                    }}
                >
                    {canAccessDateOfBirth ? formattedBirthDate : ''}
                </Table.Cell>

                <Table.Cell textAlign="right">
                    <Icon
                        align="right"
                        size={10}
                        type="chevron-right"
                    />
                </Table.Cell>
            </MemoizedTableRow>
        );
    });

    return (
        <React.Fragment>
            <Table
                className={classes.root}
                collapsing
                fontSize="xsmall"
                fullWidth
                id={`${BEM_BLOCK_NAME}--table_view`}
                resizableColumnWidthPercentage={isMobile ? '60' : '20'}
                selectable
                stickyColumnCount={2}
                stretch="very"
                style={{
                    width: '100%',
                }}
                width="auto"
            >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            className={classes.tableHeaderCell}
                            style={{ width: '66px' }}
                        >
                            {i18n('Pic')}
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            className={classes.tableHeaderCell}
                        >
                            {i18n('Personal Details')}
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            className={ClassNames(
                                classes.tableHeaderCell,
                                classes.tableHeaderCellCoreMilestones,
                            )}
                            textAlign="left"
                        >
                            {i18n('Milestones')}
                        </Table.HeaderCell>

                        <Table.HeaderCell>
                            {i18n('Email')}
                        </Table.HeaderCell>

                        <Table.HeaderCell>
                            {i18n('Phone')}
                        </Table.HeaderCell>

                        <Table.HeaderCell>
                            {i18n('Address')}
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            className={personIdTableHeaderCellClasses}
                        >
                            {i18n('Id')}
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            style={{
                                display: canAccessDateOfBirth ? 'table-cell' : 'none',
                            }}
                        >
                            {i18n('DOB')}
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            className={classes.tableHeaderCell}
                            style={{ width: '28px' }}
                        />
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>

            {canLoadMore && (
                <div
                    className="margin-top text-center"
                    id={`${BEM_BLOCK_NAME}--table_view_loader`}
                    ref={fetchMoreRef}
                    style={{
                        paddingBottom: 11,
                    }}
                >
                    <Loader />
                </div>
            )}

            {shouldRenderRefineQueryWarning && (
                <div
                    className={classes.refineQueryWarning}
                    id={`${BEM_BLOCK_NAME}--refine_query_warning_label`}
                >
                    <Typography variant="h5">
                        {Utils.format(
                            i18n('There are over {0} results. Please refine your query.'),
                            PERSON_SEARCH_MAX_RESULTS_LENGTH,
                        )}
                    </Typography>
                </div>
            )}

            {isDrawerChild ? (
                <PersonRecordDrawer
                    isOpen={isPersonRecordDrawerOpen}
                    maxWidth={768}
                    onClose={onPersonRecordDrawerClose}
                    personId={selectedPersonRecordId}
                />
            ) : null}
        </React.Fragment>
    );
}

PersonListTableView.propTypes = propTypes;
PersonListTableView.defaultProps = defaultProps;

export default React.memo(
    withRouter(withStyles(styles)(PersonListTableView)),
    (prevProps, nextProps) => {
        const shouldMemoize = prevProps.persons === nextProps.persons &&
            prevProps.canLoadMore === nextProps.canLoadMore;
        return shouldMemoize;
    },
);
