import { keyBy } from 'lodash';
import LoginConstants from '../../js/constants/Login/LoginConstants.js';

export const DEFAULT_STATE = {};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
        case 'SubVenueCacheActions.ON_RESET_SUBVENUES_BY_IDS':
            return DEFAULT_STATE;

        case 'SubVenueCacheActions.ON_GETSUBVENUESBYIDS':
            return {
                ...state,
                ...keyBy(action.result, 'id'),
            };

        default:
            return state;
    }
};
