import { combineReducers } from 'redux';
import advancedSearchFields from './global/peopleSearchAdvancedFields.reducer.js';
import index from './searchBlockTypeManagerReducer.js';
import peopleList from './global/peopleSearchDrawer.reducer.js';
import searchFilters from './peopleSearchFilters.reducer.js';

const reducers = combineReducers({
    advancedSearchFields,
    index,
    peopleList,
    searchFilters,
});

export default reducers;
