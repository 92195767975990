import { i18n } from '../../global/constants.js';

const SERVING_OPPS_SECTION = 'serving-opps';

export const MY_SERVING_OPPS_SECTION_TITLE = i18n('My Serving Opps');

export const SERVING_OPPS_SECTIONS = {
    root: SERVING_OPPS_SECTION,
    myServingOpps: `/my-${SERVING_OPPS_SECTION}`,
    servingOpps: `/${SERVING_OPPS_SECTION}`,
};

export const MY_SERVING_OPPS_ATTACHMENT_URL = '/api/v2/serving-opportunity/mine/volunteers/email/attachment';

export const LOCAL_STORAGE_SO_CHECKIN_CONTEXT = 'tempServingOppCheckInContext';

export const LOCAL_STORAGE_EVENT_CHECKIN_CONTEXT = 'tempEventCheckInContext';
