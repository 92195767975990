import React, {
    useState,
    useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Drawer,
} from '@saddlebackchurch/react-cm-ui';
import validator from 'validator';
import {
    includes,
    intersection,
    isEmpty,
    isNil,
    some,
    map,
    noop,
} from 'lodash';
import PropTypes from 'prop-types';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import PossibleDuplicatesAddPerson from './possibleDuplicatesAddPerson.jsx';
import PossibleDuplicatesAddPersonActions, {
    setIsPossibleDuplicatesDrawerOpen as setIsPossibleDuplicatesDrawerOpenAction,
} from './possibleDuplicatesAddPersonActions.js';
import AddPersondActions from './addPersonRecordActions.js';
import PersonAvatar from './personAvatar.jsx';
import PersonalForm from './personalForm.jsx';
import ContactForm from './contactForm.jsx';
import CoreMilestonesActions from './coreMilestones.actions';
import EmergencyContactForm from './emergencyContactForm.jsx';
import BannerUtils from '../../../js/utils/BannerUtils.js';
import { i18n } from '../../../global/constants.js';
import { onSavePerson } from './addPersonV2Utils.js';
import {
    BEM_BLOCK_NAME,
    POSSSIBLE_DUPLICATES_BANNER_ID,
    PERSON_RECORD_TYPES,
    personRecordPropTypes,
} from './addPersonV2Constants.js';
import UserAccessStore from '../../../global/userAccessStore.js';

const propTypes = {
    avatarImage: PropTypes.shape({}),
    contactFormData: PropTypes.shape({
        phone: PropTypes.shape({
            phoneNumber: PropTypes.string,
            phoneTypeId: PropTypes.number,
            isValidPhoneNumber: PropTypes.bool,
        }),
        country: PropTypes.shape({
            code: PropTypes.string,
        }),
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        email: PropTypes.string,
        postalCode: PropTypes.string,
        region: PropTypes.string,
    }),
    defaultChurchEntityId: PropTypes.number,
    duplicatesValidationOptions: PropTypes.shape({
        exclusionsIds: PropTypes.arrayOf(PropTypes.number),
        exclusionsSelectButtonCustomLabel: PropTypes.string,
    }),
    isPersonSaveDisabled: PropTypes.bool,
    isPossibleDuplicatesDrawerOpen: PropTypes.bool,
    isSelectRecordVisible: PropTypes.bool,
    isViewRecordVisible: PropTypes.bool,
    onAfterSave: PropTypes.func,
    onCancel: PropTypes.func,
    onPersonValidated: PropTypes.func,
    onSelectPossibleDuplicate: PropTypes.func,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    personalFormData: PropTypes.shape({
        birthDate: PropTypes.string,
        firstName: PropTypes.string,
        grade: PropTypes.string,
        lastName: PropTypes.string,
        campus: PropTypes.string,
    }),
    personSource: PropTypes.string,
    recordType: personRecordPropTypes,
    resetOnUnmount: PropTypes.bool,
    selectedEmergencyContacts: PropTypes.arrayOf(PropTypes.shape({})),
    setIsPossibleDuplicatesDrawerOpen: PropTypes.func,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
    withActionBar: PropTypes.bool,
    possibleDuplicatesList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ).isRequired,
};

const defaultProps = {
    avatarImage: null,
    contactFormData: null,
    defaultChurchEntityId: null,
    duplicatesValidationOptions: {
        exclusionsIds: [],
        exclusionsSelectButtonCustomLabel: null,
    },
    isPersonSaveDisabled: true,
    isPossibleDuplicatesDrawerOpen: false,
    isSelectRecordVisible: false,
    isViewRecordVisible: true,
    onAfterSave: noop,
    onCancel: noop,
    onPersonValidated: noop,
    onSelectPossibleDuplicate: noop,
    pageNumber: null,
    pageSize: null,
    personalFormData: null,
    personSource: null,
    recordType: null,
    resetOnUnmount: true,
    selectedEmergencyContacts: [],
    setIsPossibleDuplicatesDrawerOpen: noop,
    withActionBar: false,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        breakpoint: {
            isMobile,
        },
        people: {
            record: {
                personForm: {
                    avatarImage,
                    contactFormData,
                    isPersonSaveDisabled,
                    personalFormData,
                    recordType,
                },
                emergencyContactForm: {
                    selectedEmergencyContacts,
                },
                possibleDuplicatesAddPerson: {
                    data: possibleDuplicatesList,
                    isFetching: isFetchingPossibleDuplicates,
                    isPossibleDuplicatesDrawerOpen,
                    pageNumber,
                    pageSize,
                },
            },
        },
    } = state;

    return {
        avatarImage,
        contactFormData,
        isFetchingPossibleDuplicates,
        isMobile,
        isPersonSaveDisabled,
        isPossibleDuplicatesDrawerOpen,
        pageNumber,
        pageSize,
        personalFormData,
        possibleDuplicatesList,
        recordType,
        selectedEmergencyContacts,
        userAccess,
    };
};

function checkValidityContactData(
    hasAddressValues,
    isAddressValid,
    emailValue,
    isEmailValid,
    numberValue,
    isPhoneValid,
) {
    if (hasAddressValues && !!emailValue && !!numberValue) {
        return isAddressValid && isEmailValid && isPhoneValid;
    }

    if (hasAddressValues && !emailValue && !!numberValue) {
        return isAddressValid && isPhoneValid;
    }

    if (hasAddressValues && !!emailValue && !numberValue) {
        return isAddressValid && isEmailValid;
    }

    if (!hasAddressValues && !!emailValue && !!numberValue) {
        return isEmailValid && isPhoneValid;
    }

    if (!hasAddressValues && !emailValue && !!numberValue) {
        return isPhoneValid;
    }

    if (!hasAddressValues && !!emailValue && !numberValue) {
        return isEmailValid;
    }

    return isAddressValid;
}

export function AddPersonV2(props) {
    const {
        avatarImage,
        contactFormData,
        defaultChurchEntityId,
        duplicatesValidationOptions,
        isPersonSaveDisabled,
        isPossibleDuplicatesDrawerOpen,
        isSelectRecordVisible,
        isViewRecordVisible,
        onAfterSave,
        onCancel,
        onPersonValidated,
        onSelectPossibleDuplicate,
        pageNumber,
        pageSize,
        personalFormData,
        personSource,
        possibleDuplicatesList,
        recordType,
        resetOnUnmount,
        selectedEmergencyContacts,
        setIsPossibleDuplicatesDrawerOpen,
        userAccess,
        withActionBar,
    } = props;

    const [personFocus, setPersonFocus] = useState({});
    const [activeLoadMorePossibleDuplicates, setActiveLoadMorePossibleDuplicates] = useState(false);
    const [isRegionRequired, setIsRegionRequired] = useState(true);

    const {
        birthDate,
        firstName,
        grade,
        lastName,
        campus,
    } = personalFormData;

    const {
        address1,
        address2,
        city,
        country,
        email,
        phone,
        postalCode,
        region,
    } = contactFormData;

    const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';
    let isPostalCodeRequired = false;
    let isPostalCodeValid = false;
    let isPersonalFormSaveDisabled = true;
    let isContactFormSaveDisabled = true;
    let isEmergencyContactFormSaveDisabled = true;
    let isRelationshipSetOnECF = false;
    let disableSavePerson = true;

    const hasCoreMilestonesPermissions = userAccess.hasPermission(
        USER_PERMISSIONS.readPersonMilestones,
    );

    const getCoreMilestonesByPersonIds = (data) => {
        if (hasCoreMilestonesPermissions && !isEmpty(data) && !isEmpty(data.results)) {
            const personIds = map(data.results, 'id');
            CoreMilestonesActions.getCoreMilestonesByPersonIds({
                personIds,
            });
        }
    };

    const onDismissPossibleDuplicatesBanner = () => {
        BannerUtils.closeBanner(POSSSIBLE_DUPLICATES_BANNER_ID);
    };

    const onPossibleDuplicatesDrawerToggle = () => {
        setIsPossibleDuplicatesDrawerOpen();
        BannerUtils.closeBanner(POSSSIBLE_DUPLICATES_BANNER_ID);
    };

    const onNextPagePossibleDuplicates = () => {
        const dataPersonal = {
            firstName,
            lastName,
            address1,
            address2,
            city,
            email,
            postalCode,
        };

        const fields = [
            'firstName',
            'lastName',
            'nickName',
            'address1',
            'city',
            'postalCode',
            'homePhone',
            'workPhone',
            'cellPhone',
            'email',
        ];

        const first = false;
        const fieldsWithValue = intersection(
            fields,
            Object.keys(dataPersonal).filter((f) => !!dataPersonal[f]),
        );

        PossibleDuplicatesAddPersonActions.get(
            fieldsWithValue, dataPersonal, pageSize, (pageNumber + 1), { first },
        ).then((res) => {
            getCoreMilestonesByPersonIds(res);
            setActiveLoadMorePossibleDuplicates(true);
        });
    };

    const onSetActiveLoadMore = (value) => new Promise((resolve) => {
        setActiveLoadMorePossibleDuplicates(value);
        resolve();
    });

    const onBlurFieldCalcPB = (field, value) => {
        const dataPersonal = {
            firstName,
            lastName,
            address1,
            address2,
            city,
            email,
            postalCode,
            phone,
            phoneNumber: phone?.phoneNumber ?? '',
        };

        if (field === 'email' && !validator.isEmail(value)) {
            return;
        }

        const fields = [
            'firstName',
            'lastName',
            'nickName',
            'address1',
            'city',
            'postalCode',
            'homePhone',
            'workPhone',
            'cellPhone',
            'email',
            'phoneNumber',
        ];

        if (!fields.includes(field)) {
            return;
        }
        const first = true;
        const hasData = (f) => typeof dataPersonal[f] !== 'undefined' && dataPersonal[f] !== '';

        const hasMutated = (f) => personFocus[f] !== value;

        const hasPassedThreshold = () => {
            // threshold given to field which triggers duplicate search api
            // i.e  to trigger api weight should be greater or equal than cummulative of firstName and lastName's threshold

            const distinctMap = {
                address1: 0.400199101,
                cellPhone: 0.170175613,
                city: 0.022184444,
                email: 0.867449747,
                firstName: 0.053948842,
                homePhone: 0.080386438,
                lastName: 0.116226771,
                nickName: 0.012355428,
                postalCode: 0.015079807,
                workPhone: 0.042059726,
                phoneNumber: 0.767449747,
            };

            const weight = Object.keys(dataPersonal)
                .filter((key) => !!dataPersonal[key])
                .map((key) => distinctMap[key] || 0)
                .reduce((total, n) => total + n);

            return weight >= distinctMap.firstName + distinctMap.lastName;
        };

        if (hasData(field) && hasMutated(field) && hasPassedThreshold(field)) {
            const fieldsWithValue = intersection(
                fields,
                Object.keys(dataPersonal).filter((f) => !!dataPersonal[f]),
            );

            PossibleDuplicatesAddPersonActions.get(
                fieldsWithValue, dataPersonal, pageSize, 0, { first },
            ).then((res) => {
                const {
                    results,
                    resultCount,
                } = res;

                getCoreMilestonesByPersonIds(res);
                setActiveLoadMorePossibleDuplicates(true);

                if (!isEmpty(results)) {
                    onDismissPossibleDuplicatesBanner();
                    BannerUtils.addBanner({
                        children: (
                            <div>
                                <Button
                                    color="outline"
                                    id={`${BEM_BLOCK_NAME}--view_duplicates_btn`}
                                    inverse
                                    onClick={onPossibleDuplicatesDrawerToggle}
                                    style={{
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <span>{i18n('View')}</span>
                                </Button>
                                <Button
                                    color="outline"
                                    id={`${BEM_BLOCK_NAME}--dismiss_duplicates_btn`}
                                    inverse
                                    onClick={onDismissPossibleDuplicatesBanner}
                                    style={{
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <span>{i18n('Dismiss')}</span>
                                </Button>
                            </div>
                        ),
                        id: POSSSIBLE_DUPLICATES_BANNER_ID,
                        level: 'warning',
                        levelIcon: 'users',
                        message: `${resultCount} ${i18n('possible duplicates were found in our system')}`,
                        timeout: false,
                        title: i18n('Warning'),
                        type: 'notification',
                    });
                }
            });
        }
    };

    const onFocusFieldCalcPB = (field, value) => {
        const nextPersonFocus = {
            ...personFocus,
            [field]: value,
        };

        setPersonFocus(nextPersonFocus);
    };

    const onSetRegionRequired = (value) => {
        setIsRegionRequired(value);
    };

    const validateSaveDisabled = () => {
        if (!isEmpty(selectedEmergencyContacts)) {
            isRelationshipSetOnECF = some(
                selectedEmergencyContacts, (item) => !item.relationType,
            );
        }

        if (!isEmpty(country) &&
            includes(validator.isPostalCodeLocales, country.code)
        ) {
            isPostalCodeValid = !!postalCode && validator.isPostalCode(postalCode, country.code);
            isPostalCodeRequired = true;
        }

        const isEmailValid = validator.isEmail(email);
        const isPhoneInfoValid = phone.phoneNumber && phone.phoneTypeId && phone.isValidPhoneNumber;
        const isAddressInfoValid = !!address1 &&
            !!city &&
            (
                (isPostalCodeRequired && !!postalCode && isPostalCodeValid) ||
                (!isPostalCodeRequired)
            ) &&
            (
                (isRegionRequired && !isEmpty(region)) ||
                (!isRegionRequired)
            ) &&
            !isEmpty(country);
        const hasAddresssValues = !!address1 || !!city || !!postalCode || !isEmpty(region);

        const isContactDataValid = checkValidityContactData(
            hasAddresssValues,
            isAddressInfoValid,
            email,
            isEmailValid,
            phone.phoneNumber,
            isPhoneInfoValid,
        );

        if (recordType === PERSON_RECORD_TYPES.adult) {
            isPersonalFormSaveDisabled = !firstName || !lastName || isEmpty(campus);
            isContactFormSaveDisabled = !isContactDataValid;
            disableSavePerson = isPersonalFormSaveDisabled ||
                isContactFormSaveDisabled ||
                isRelationshipSetOnECF;
        } else if (recordType === PERSON_RECORD_TYPES.student) {
            isPersonalFormSaveDisabled = !firstName ||
                !lastName ||
                isEmpty(campus) ||
                isEmpty(grade) ||
                isNil(birthDate);
            isContactFormSaveDisabled = !isContactDataValid;
            isEmergencyContactFormSaveDisabled = isEmpty(selectedEmergencyContacts);
            disableSavePerson = isPersonalFormSaveDisabled ||
                !(!isContactFormSaveDisabled ||
                !isEmergencyContactFormSaveDisabled) ||
                isRelationshipSetOnECF;
        } else {
            isPersonalFormSaveDisabled = !firstName ||
            !lastName ||
            isEmpty(campus) ||
            isEmpty(grade) ||
            isNil(birthDate);
            isEmergencyContactFormSaveDisabled = isEmpty(selectedEmergencyContacts);
            disableSavePerson = isPersonalFormSaveDisabled ||
            isEmergencyContactFormSaveDisabled ||
            isRelationshipSetOnECF;
        }

        return disableSavePerson;
    };

    useEffect(() => {
        const shouldPersonSaveDisable = validateSaveDisabled();

        if (isPersonSaveDisabled !== shouldPersonSaveDisable) {
            AddPersondActions.setIsSaveDisabled(shouldPersonSaveDisable);
        }

        if (!shouldPersonSaveDisable) {
            const personToSave = {
                ...avatarImage,
                ...personalFormData,
                ...contactFormData,
                recordType,
            };

            onPersonValidated(personToSave);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarImage, personalFormData, contactFormData, recordType, selectedEmergencyContacts]);

    useEffect(() => {
        const willUnmount = () => {
            if (resetOnUnmount) {
                PossibleDuplicatesAddPersonActions.reset();
                PossibleDuplicatesAddPersonActions.resetPersonalDetails();
                CoreMilestonesActions.resetCoreMilestonesByPersonIds();
            }
        };

        return willUnmount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldShowPossibleDuplicatesButton = withActionBar && possibleDuplicatesList.length > 0;

    return (
        <div>
            {withActionBar && (
                <Drawer.ActionBar
                    style={{
                        textAlign: 'right',
                    }}
                >
                    {shouldShowPossibleDuplicatesButton && (
                        <Button
                            color="outline"
                            id={`${BEM_BLOCK_NAME}--button_possible_duplicates`}
                            onClick={onPossibleDuplicatesDrawerToggle}
                        >
                            Possible Duplicates
                        </Button>
                    )}
                    <Button
                        color="alternate"
                        id={`${BEM_BLOCK_NAME}--button_cancel`}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        onClick={() => {
                            onSavePerson(
                                (savedPerson) => onAfterSave(savedPerson),
                                {},
                                personSource,
                            );
                        }}
                        disable={isPersonSaveDisabled}
                        id={`${BEM_BLOCK_NAME}--button_save`}
                    >
                        Save
                    </Button>
                </Drawer.ActionBar>
            )}

            <PersonAvatar
                fullName={fullName}
                resetOnUnmount={resetOnUnmount}
            />

            <PersonalForm
                onBlur={onBlurFieldCalcPB}
                onFocus={onFocusFieldCalcPB}
                recordType={recordType}
                resetOnUnmount={resetOnUnmount}
                defaultChurchEntityId={defaultChurchEntityId}
            />

            {recordType !== PERSON_RECORD_TYPES.child && (
                <ContactForm
                    isEmergencyContactInfoSet={
                        !(isEmergencyContactFormSaveDisabled || isRelationshipSetOnECF)
                    }
                    isRegionRequired={isRegionRequired}
                    onBlur={onBlurFieldCalcPB}
                    onFocus={onFocusFieldCalcPB}
                    onSetRegionRequired={onSetRegionRequired}
                    recordType={recordType}
                    required={isContactFormSaveDisabled}
                    resetOnUnmount={resetOnUnmount}
                />
            )}

            <EmergencyContactForm
                recordType={recordType}
                resetOnUnmount={resetOnUnmount}
            />

            <PossibleDuplicatesAddPerson
                activeLoadMore={activeLoadMorePossibleDuplicates}
                isOpen={isPossibleDuplicatesDrawerOpen}
                isSelectRecordVisible={isSelectRecordVisible}
                isViewRecordVisible={isViewRecordVisible}
                onClose={onPossibleDuplicatesDrawerToggle}
                onNextPage={onNextPagePossibleDuplicates}
                onSetActiveLoadMore={onSetActiveLoadMore}
                onSelect={onSelectPossibleDuplicate}
                resetOnUnmount={resetOnUnmount}
                validationOptions={duplicatesValidationOptions}
            />
        </div>
    );
}

AddPersonV2.propTypes = propTypes;
AddPersonV2.defaultProps = defaultProps;

export default connect(
    mapStateToProps, { setIsPossibleDuplicatesDrawerOpen: setIsPossibleDuplicatesDrawerOpenAction },
)(AddPersonV2);
