import {
    isEmpty,
    isUndefined,
    noop,
} from 'lodash';
import {
    parseTabsToSectionalTabsItems,
    resetNavigationItems,
    updateNavigationItems,
} from '../app/navigation/navigationUtils.jsx';
import {
    MY_MINISTRIES_PATH,
    MY_MINISTRIES_TITLE,
    MINISTRIES_SECTIONS,
} from './constants.js';
import { EVENTS_SECTIONS } from '../event/eventsCentral/constants.js';
import EventActions from '../event/eventsCentral/actions/event.actions.js';
import { getEventNavigationBreadcrumbs } from '../event/eventsCentral/navigationUtils.js';
import {
    SERVING_OPPS_SECTIONS,
    MY_SERVING_OPPS_SECTION_TITLE,
} from './myServingOpps/constants.js';

const MINISTRIES_SERVING_OPPS_SECTION = `${MINISTRIES_SECTIONS.myMinistries}${MINISTRIES_SECTIONS.servingOpp}`;

export const getFeatureSection = (pathname) => {
    let featureSection;
    if (pathname.includes(EVENTS_SECTIONS.dashboardMyEvents) &&
        pathname.includes(SERVING_OPPS_SECTIONS.root)) {
        featureSection = EVENTS_SECTIONS.dashboardMyEvents;
    } else if (pathname.includes(EVENTS_SECTIONS.root)) {
        if (pathname.includes(EVENTS_SECTIONS.myEvents)) {
            featureSection = EVENTS_SECTIONS.myEvents;
        } else if (pathname.includes(EVENTS_SECTIONS.event)) {
            featureSection = EVENTS_SECTIONS.event;
        }
    } else if (pathname.includes(MINISTRIES_SERVING_OPPS_SECTION)) {
        featureSection = MINISTRIES_SERVING_OPPS_SECTION;
    } else if (pathname.includes(MINISTRIES_SECTIONS.myMinistries)) {
        featureSection = MINISTRIES_SECTIONS.myMinistries;
    }

    const isServingOppSection = pathname.includes(SERVING_OPPS_SECTIONS.myServingOpps);

    if (isServingOppSection) {
        featureSection = SERVING_OPPS_SECTIONS.myServingOpps;
    }

    return featureSection;
};

const getServingOppsNavigationItems = (
    opportunityChurchEntityId,
    opportunityId,
    router,
) => {
    const {
        getCurrentLocation,
        params,
    } = router;

    const featureSection = getFeatureSection(getCurrentLocation().pathname);

    let routesPrefix = `${MY_MINISTRIES_PATH}/serving-opportunity/${opportunityId}/${opportunityChurchEntityId}`;

    if (featureSection === EVENTS_SECTIONS.event) {
        routesPrefix = `${EVENTS_SECTIONS.root}${EVENTS_SECTIONS.event}/${params.eventId}${EVENTS_SECTIONS.servingOpps}/${opportunityId}`;
    } else if (featureSection === EVENTS_SECTIONS.myEvents) {
        routesPrefix = `${EVENTS_SECTIONS.root}${EVENTS_SECTIONS.myEvents}/${params.eventId}${EVENTS_SECTIONS.servingOpps}/${opportunityId}`;
    } else if (featureSection === SERVING_OPPS_SECTIONS.myServingOpps) {
        routesPrefix = `${SERVING_OPPS_SECTIONS.myServingOpps}/${params.id}`;
    } else if (featureSection === EVENTS_SECTIONS.dashboardMyEvents) {
        routesPrefix = `${EVENTS_SECTIONS.dashboardMyEvents}/${params.eventId}${EVENTS_SECTIONS.servingOpps}/${opportunityId}`;
    }

    const sectionItemSchedule = {
        accessRequires: { permission: 'bypass' },
        label: 'Serving Schedule',
        to: `${routesPrefix}/schedule`,
        isBeta: false,
    };

    const sectionItemRoster = {
        accessRequires: { permission: 'bypass' },
        label: 'Roster',
        to: `${routesPrefix}/roster`,
        isBeta: false,
    };

    const sectionItemCommunications = {
        accessRequires: { permission: 'bypass' },
        label: 'Communications',
        to: `${routesPrefix}/communications`,
        isBeta: false,
    };

    const sectionItemSettings = {
        accessRequires: { permission: 'bypass' },
        label: 'Settings',
        to: `${routesPrefix}/settings`,
        isBeta: false,
    };

    return [
        sectionItemSchedule,
        sectionItemRoster,
        sectionItemCommunications,
        sectionItemSettings,
    ];
};

export const getMinistrySectionItems = (ministryId, locationId) => {
    const sectionItemMembers = {
        isBeta: false,
        label: 'Members',
        accessRequires: {
            permission: 'accessLocalMinistries',
            ministryId,
            churchEntityId: locationId,
        },
        to: `/my-ministry/volunteers/members/${ministryId}/${locationId}`,
        total: null,
    };

    const sectionItemInterested = {
        isBeta: false,
        label: 'Interested',
        accessRequires: {
            permission: 'accessLocalMinistries',
            ministryId,
            churchEntityId: locationId,
        },
        to: `/my-ministry/volunteers/interested/${ministryId}/${locationId}`,
        total: null,
    };

    const sectionItemServingOpps = {
        isBeta: false,
        label: 'Serving Opps',
        accessRequires: {
            permission: 'accessLocalMinistries',
            ministryId,
            churchEntityId: locationId,
        },
        to: `/my-ministry/serving-opportunities/${ministryId}/${locationId}`,
        total: null,
    };

    const sectionItemServingOppsActive = {
        isBeta: false,
        label: 'Active',
        accessRequires: {
            permission: 'accessLocalMinistries',
            ministryId,
            churchEntityId: locationId,
        },
        to: `/my-ministry/active-serving-opportunities/${ministryId}/${locationId}`,
        total: null,
    };

    const sectionItemServingOppsClosed = {
        isBeta: false,
        label: 'Closed',
        accessRequires: {
            permission: 'accessLocalMinistries',
            ministryId,
            churchEntityId: locationId,
        },
        to: `/my-ministry/closed-serving-opportunities/${ministryId}/${locationId}`,
        total: null,
    };

    const sectionItemCommunicationsMinistry = {
        isBeta: false,
        label: 'Communications',
        accessRequires: {
            permission: 'accessLocalMinistries',
            ministryId,
            churchEntityId: locationId,
        },
        to: `/my-ministry/communications/${ministryId}/${locationId}`,
        total: null,
    };

    const sectionItemSettings = {
        accessRequires: {
            permission: 'accessLocalMinistries',
            ministryId,
            churchEntityId: locationId,
        },
        isBeta: false,
        label: 'Settings',
        to: `/my-ministry/settings/ministry-description/${ministryId}/${locationId}`,
        total: null,
    };

    const myMinistrySectionItems = {
        sectionItemMembers,
        sectionItemInterested,
        sectionItemServingOpps,
        sectionItemServingOppsActive,
        sectionItemServingOppsClosed,
        sectionItemCommunicationsMinistry,
        sectionItemSettings,
    };

    return myMinistrySectionItems;
};

export const getMinistrySectionalTabs = (ministryId, locationId, routerPushFn) => {
    let parsedMinistrySectionalTabs = [];

    if (ministryId && locationId) {
        const ministrySectionItems = getMinistrySectionItems(ministryId, locationId);

        const myMinistrySectionalTabs = [
            ministrySectionItems.sectionItemMembers,
            ministrySectionItems.sectionItemInterested,
            ministrySectionItems.sectionItemServingOpps,
            ministrySectionItems.sectionItemCommunicationsMinistry,
            ministrySectionItems.sectionItemSettings,
        ];

        parsedMinistrySectionalTabs = parseTabsToSectionalTabsItems(
            myMinistrySectionalTabs,
            routerPushFn,
        );
    }

    return parsedMinistrySectionalTabs;
};

export const getNavigationHeaderBreadcrumbs = (breadcrumbsInfo) => {
    const {
        data: {
            eventName,
            ministryId,
            ministryName,
            servingOppName,
        },
        routerPushFn,
        router: {
            getCurrentLocation,
            params,
        },
    } = breadcrumbsInfo;

    const { pathname } = getCurrentLocation();
    let headerBreadcrumbs = [];
    const featureSection = getFeatureSection(pathname);
    let rootBreadcrumb = {};
    let secondLevelBreadcrumb = {};
    let thirdLevelBreadcrumb = {};

    const isMinistrySection = featureSection === MINISTRIES_SECTIONS.myMinistries ||
        featureSection === MINISTRIES_SERVING_OPPS_SECTION;
    const isEventSection = featureSection === EVENTS_SECTIONS.event;
    const isMyEventSection = featureSection === EVENTS_SECTIONS.myEvents;
    const isMyServingOppsSection = featureSection === SERVING_OPPS_SECTIONS.myServingOpps;
    const isDashboardMyEventSection = featureSection === EVENTS_SECTIONS.dashboardMyEvents;

    if (isMinistrySection) {
        rootBreadcrumb = {
            title: MY_MINISTRIES_TITLE,
            to: MINISTRIES_SECTIONS.myMinistries,
            onBreadcrumbClick: () => {
                resetNavigationItems(true, MY_MINISTRIES_TITLE);
                routerPushFn(MINISTRIES_SECTIONS.myMinistries);
            },
        };

        const secondLevelPath = `${MINISTRIES_SECTIONS.myMinistries}${MINISTRIES_SECTIONS.servingOpps}/${ministryId}/${params.location}`;
        const shouldDisableOnBreadcrumbClick =
            isUndefined(ministryId) ||
            pathname.toLowerCase() === secondLevelPath.toLowerCase();

        secondLevelBreadcrumb = {
            to: secondLevelPath,
            title: ministryName,
            onBreadcrumbClick: shouldDisableOnBreadcrumbClick ?
                noop :
                () => routerPushFn(secondLevelPath),
        };

        if (featureSection === MINISTRIES_SERVING_OPPS_SECTION) { // let's add the third breadcrumb if needed
            const thirdLevelPath = `${MINISTRIES_SECTIONS.myMinistries}${MINISTRIES_SECTIONS.servingOpp}/${params.id}/${params.location}/schedule`;

            thirdLevelBreadcrumb = {
                to: thirdLevelPath,
                title: servingOppName,
                onBreadcrumbClick: noop,
            };
        }
    } else if (isEventSection || isMyEventSection) {
        const rootPath = `${EVENTS_SECTIONS.root}${isEventSection ? EVENTS_SECTIONS.allEvents : EVENTS_SECTIONS.myEvents}`;
        const rootTitle = isEventSection ? 'All Events' : 'My Events';

        rootBreadcrumb = {
            title: rootTitle,
            to: rootPath,
            onBreadcrumbClick: () => {
                resetNavigationItems(true, rootTitle);
                routerPushFn(rootPath);
            },
        };

        const secondLevelPath = `${EVENTS_SECTIONS.root}${isEventSection ? EVENTS_SECTIONS.event : EVENTS_SECTIONS.myEvents}/${params.eventId}${EVENTS_SECTIONS.servingOpps}`;
        secondLevelBreadcrumb = {
            to: secondLevelPath,
            title: eventName,
            onBreadcrumbClick: () => routerPushFn(secondLevelPath),
        };

        const thirdLevelPath = `${secondLevelPath}/${params.id}/${params.location}`;

        thirdLevelBreadcrumb = {
            to: thirdLevelPath,
            title: servingOppName,
            onBreadcrumbClick: noop,
        };
    } else if (isMyServingOppsSection) {
        rootBreadcrumb = {
            title: MY_SERVING_OPPS_SECTION_TITLE,
            to: SERVING_OPPS_SECTIONS.myServingOpps,
            onBreadcrumbClick: () => {
                resetNavigationItems(true, MY_SERVING_OPPS_SECTION_TITLE);
                routerPushFn(SERVING_OPPS_SECTIONS.myServingOpps);
            },
        };

        secondLevelBreadcrumb = {
            to: '',
            title: servingOppName,
            onBreadcrumbClick: noop,
        };
    } else if (isDashboardMyEventSection) {
        const rootPath = `${EVENTS_SECTIONS.dashboardMyEvents}`;
        const rootTitle = 'My Events';

        rootBreadcrumb = {
            title: rootTitle,
            to: rootPath,
            onBreadcrumbClick: () => {
                resetNavigationItems(true, rootTitle);
                routerPushFn(rootPath);
            },
        };

        const secondLevelPath = `${EVENTS_SECTIONS.dashboardMyEvents}/${params.eventId}${EVENTS_SECTIONS.servingOpps}`;
        secondLevelBreadcrumb = {
            to: secondLevelPath,
            title: eventName,
            onBreadcrumbClick: () => routerPushFn(secondLevelPath),
        };

        const thirdLevelPath = `${secondLevelPath}/${params.id}/${params.location}`;

        thirdLevelBreadcrumb = {
            to: thirdLevelPath,
            title: servingOppName,
            onBreadcrumbClick: noop,
        };
    }

    const areAllEmtpyBreadcrumbs = isEmpty(rootBreadcrumb) &&
        isEmpty(secondLevelBreadcrumb) &&
        isEmpty(thirdLevelBreadcrumb);

    if (areAllEmtpyBreadcrumbs) {
        return [];
    }

    const shouldAddThirdLevelBreadcrumb = featureSection === MINISTRIES_SERVING_OPPS_SECTION ||
        isEventSection || isMyEventSection || isDashboardMyEventSection;

    headerBreadcrumbs = [
        rootBreadcrumb,
        secondLevelBreadcrumb,
        ...(shouldAddThirdLevelBreadcrumb ? [thirdLevelBreadcrumb] : []),
    ];

    return headerBreadcrumbs;
};

export const getMinistriesNavigationBreadcrumbs = (
    ministryId,
    ministryName,
    locationId,
    routerPushFn,
) => {
    let ministryNavigationBreadcrumbs = [];

    if (ministryId && ministryName && locationId) {
        const ministrySectionItems = getMinistrySectionItems(ministryId, locationId);
        ministryNavigationBreadcrumbs = [
            {
                title: MY_MINISTRIES_TITLE,
                items: [],
                onClick: () => {
                    resetNavigationItems(true, MY_MINISTRIES_TITLE);
                    routerPushFn(MY_MINISTRIES_PATH);
                },
            },
            {
                title: ministryName,
                items: [
                    ministrySectionItems.sectionItemMembers,
                    ministrySectionItems.sectionItemInterested,
                    ministrySectionItems.sectionItemServingOpps,
                    ministrySectionItems.sectionItemCommunicationsMinistry,
                    ministrySectionItems.sectionItemSettings,
                ],
                onClick: () => routerPushFn(ministrySectionItems.sectionItemServingOpps.to),
            },
        ];
    }

    return ministryNavigationBreadcrumbs;
};

export const updateMinistryNavigationItems = (
    location,
    router,
) => {
    const {
        churchEntityId,
        ministryId,
        ministryName,
    } = location;

    const nextSectionalTabs = getMinistrySectionalTabs(
        ministryId, churchEntityId, router.push,
    );

    const nextNavigationHeaderBreadcrumbs = getNavigationHeaderBreadcrumbs({
        data: {
            ministryName,
        },
        routerPushFn: router.push,
        router,
    });

    const nextNavigationBreadcrumbs = getMinistriesNavigationBreadcrumbs(
        ministryId,
        ministryName,
        churchEntityId,
        router.push,
    );

    updateNavigationItems(
        nextSectionalTabs,
        nextNavigationHeaderBreadcrumbs,
        nextNavigationBreadcrumbs,
    );
};

export const getServingOppNavigationBreadcrumbs = (
    // for ministry breadcrumbs
    ministryId,
    ministryName,
    locationId,
    // for serving opp breadcrumbs
    servingOppId,
    servingOppName,
    routerPushFn,
    router,
) => {
    let ministryNavigationBreadcrumbs = [];

    const isThereEnoughInfo = ministryId &&
        ministryName &&
        locationId &&
        servingOppId &&
        servingOppName &&
        routerPushFn;

    if (isThereEnoughInfo) {
        ministryNavigationBreadcrumbs = getMinistriesNavigationBreadcrumbs(
            ministryId,
            ministryName,
            locationId,
            routerPushFn,
        );

        const servingOppsTabs = getServingOppsNavigationItems(locationId, servingOppId, router);

        ministryNavigationBreadcrumbs = [
            ...ministryNavigationBreadcrumbs,
            { title: servingOppName, items: [...servingOppsTabs] },
        ];
    }

    return ministryNavigationBreadcrumbs;
};

export const getServingOppSectionalTabs = (
    opportunityId,
    opportunityChurchEntityId,
    routerPushFn,
    router,
) => {
    let servingOppSectionalTabs = [];

    if (opportunityId && opportunityChurchEntityId && routerPushFn) {
        const servingOppTabs = getServingOppsNavigationItems(
            opportunityChurchEntityId, opportunityId, router,
        );
        servingOppSectionalTabs = parseTabsToSectionalTabsItems(
            servingOppTabs, routerPushFn,
        );
    }

    return servingOppSectionalTabs;
};

export const updateServingOppNavigationItems = (servingOpp, router) => {
    const {
        id: servingOppId,
        name: servingOppName,
        churchEntityId,
        ministry: {
            id: ministryId,
            name: ministryName,
        },
        eventId,
    } = servingOpp;

    const {
        getCurrentLocation,
    } = router;

    const nextSectionalTabs = getServingOppSectionalTabs(
        servingOppId, churchEntityId, router.push, router,
    );

    const featureSection = getFeatureSection(getCurrentLocation().pathname);
    const isEventSection = featureSection === EVENTS_SECTIONS.event;
    const isMyEventSection = featureSection === EVENTS_SECTIONS.myEvents;
    const isMyServingOppsSection = featureSection === SERVING_OPPS_SECTIONS.myServingOpps;
    const isDashboardMyEventSection = featureSection === EVENTS_SECTIONS.dashboardMyEvents;

    if (isEventSection || isMyEventSection || isDashboardMyEventSection) {
        EventActions.get({ id: eventId })
            .then((eventResponse) => {
                const {
                    name,
                } = eventResponse;

                const nextNavigationHeaderBreadcrumbs = getNavigationHeaderBreadcrumbs({
                    data: { ministryName, servingOppName, eventName: name },
                    routerPushFn: router.push,
                    router,
                });

                const eventNavigationBreadcrumbs = getEventNavigationBreadcrumbs(
                    eventResponse,
                    isMyEventSection,
                    router,
                );

                const servingOppNavigationBreadcrumbs = getServingOppNavigationBreadcrumbs(
                    ministryId, ministryName, churchEntityId, // for ministry breadcrumbs
                    servingOppId, servingOppName, // for serving opp breadcrumbs
                    router.push,
                    router,
                );

                const nextNavigationBreadcrumbs = [ // let's merge events/serving opp breadcrumbs
                    ...eventNavigationBreadcrumbs,
                    servingOppNavigationBreadcrumbs[2],
                ];

                updateNavigationItems(
                    nextSectionalTabs,
                    nextNavigationHeaderBreadcrumbs,
                    nextNavigationBreadcrumbs,
                );
            });
    } else if (isMyServingOppsSection) {
        const nextNavigationHeaderBreadcrumbs = getNavigationHeaderBreadcrumbs({
            data: { servingOppName },
            routerPushFn: router.push,
            router,
        });

        const myServingOppTabs = getServingOppsNavigationItems(
            churchEntityId,
            servingOppId,
            router,
        );

        const myServingOppsNavigationBreadcrumbs = [
            {
                title: MY_MINISTRIES_TITLE,
                items: [],
                onClick: () => {
                    resetNavigationItems(true, MY_SERVING_OPPS_SECTION_TITLE);
                    router.push(SERVING_OPPS_SECTIONS.myServingOpps);
                },
            },
            {
                title: servingOppName,
                items: [...myServingOppTabs],
                onClick: noop,
            },
        ];

        updateNavigationItems(
            nextSectionalTabs,
            nextNavigationHeaderBreadcrumbs,
            myServingOppsNavigationBreadcrumbs,
        );
    } else {
        const nextNavigationHeaderBreadcrumbs = getNavigationHeaderBreadcrumbs({
            data: { ministryName, servingOppName, ministryId },
            routerPushFn: router.push,
            router,
        });

        const nextNavigationBreadcrumbs = getServingOppNavigationBreadcrumbs(
            ministryId, ministryName, churchEntityId, // for ministry breadcrumbs
            servingOppId, servingOppName, // for serving opp breadcrumbs
            router.push,
            router,
        );

        updateNavigationItems(
            nextSectionalTabs,
            nextNavigationHeaderBreadcrumbs,
            nextNavigationBreadcrumbs,
        );
    }
};
