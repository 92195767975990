import {
    find,
    isEmpty,
    isNil,
    isString,
    trimStart,
} from 'lodash';
import moment from 'moment-timezone';
import { i18n } from '../../global/constants.js';
import { GRADE_OPTIONS } from './constants.js';
import { getRecordType } from '../record/global/utils.js';
import PersonEmergencyContactUtils from './personEmergencyContactUtils.js';

export const getPersonChurchEntityNameText = (person) => {
    let churchEntityNameText;

    if (isNil(person.churchEntityId)) {
        churchEntityNameText = person.churchEntityName ??
            (person.churchEntityKnown ? i18n('Does Not Attend') : i18n('Unknown'));
    } else {
        churchEntityNameText = person.churchEntityName;
    }

    return churchEntityNameText;
};

export const getGenderLabel = (gender) => {
    if (!gender) { return null; }
    if (gender.toLowerCase() === 'm') { return i18n('Male'); }
    if (gender.toLowerCase() === 'f') { return i18n('Female'); }

    return null;
};

export const getPersonMetaInfoText = (
    person,
    options = {
        withPersonId: false,
        hasAccessDateOfBirth: false,
    },
) => {
    const {
        age,
        birthDate,
        gender,
        isAdult,
        isChild,
        isStudent,
        id,
        maritalStatus,
    } = person;

    const genderText = getGenderLabel(gender);
    const churchEntityNameText = getPersonChurchEntityNameText(person);
    let birthdayDate = '';

    if (birthDate) {
        if (isString(birthDate)) {
            birthdayDate = moment(birthDate, 'YYYY-MM-DD').format('L');
        } else { // presumably a number/UNIX Timestamp
            birthdayDate = moment.unix(birthDate).utc().format('L');
        }
    }

    let metaInfoText = '';

    if (genderText) {
        metaInfoText += genderText;
    }

    if (isAdult && maritalStatus) {
        metaInfoText += ` | ${maritalStatus}`;
    }

    if (options.hasAccessDateOfBirth && (isChild || isStudent) && birthDate) {
        metaInfoText += ` | ${age}yr ${birthdayDate}`;
    }

    if (churchEntityNameText) {
        metaInfoText += ` | ${churchEntityNameText}`;
    }

    if (options.withPersonId) {
        metaInfoText += ` | ${id}`;
    }

    metaInfoText = trimStart(metaInfoText, '| ');

    return metaInfoText;
};

export const getPersonFormattedContactInfo = (person) => {
    const {
        contactPreferences,
        emails,
        phones,
        addresses,
    } = person;

    const {
        doNotMail,
        doNotPhone,
        doNotText,
        doNotEmail,
        doNotContact,
        preferredMethod,
    } = contactPreferences || {};

    if (doNotContact) {
        return {};
    }

    const email = find(emails, { isPrimary: true });
    const phone = find(phones, { isPrimary: true });
    const address = find(addresses, { isPrimary: true });

    const shouldShowEmail = !doNotEmail && email;
    const shouldShowPhone = !doNotPhone && phone;
    const shouldShowAddress = !isEmpty(address);

    return { // let's add the isPreferred property to the primary contact for each method
        ...(shouldShowEmail && {
            email: {
                ...email,
                isPreferred: !doNotEmail && preferredMethod === 'email',
            },
        }),
        ...(shouldShowPhone && {
            phone: {
                ...phone,
                isPreferred: (
                    (!doNotText && preferredMethod === 'text') ||
                    (!doNotPhone && preferredMethod === 'phone')
                ),
            },
        }),
        ...(shouldShowAddress && {
            address: {
                ...address,
                isPreferred: !doNotMail && preferredMethod === 'mail',
            },
        }),
    };
};

export const getPreferredContactMethod = (contactPreferencesInfo, emails, phones) => {
    if (isEmpty(contactPreferencesInfo) || (isEmpty(emails) && isEmpty(phones))) {
        return {};
    }

    let preferredContactInfoText;
    let preferredContactInfoMethod;

    const {
        preferredMethod,
    } = contactPreferencesInfo;

    const primaryEmail = find(emails, 'isPrimary');
    const primaryPhone = find(phones, 'isPrimary');

    switch (preferredMethod) {
        case 'email':
            if (!isEmpty(primaryEmail) && primaryEmail.email) {
                preferredContactInfoText = primaryEmail.email;
                preferredContactInfoMethod = preferredMethod;
            }

            break;
        case 'phone':
            if (!isEmpty(primaryPhone) && (primaryPhone.phoneNumber || primaryPhone.number)) {
                preferredContactInfoText = primaryPhone.phoneNumber ?
                    primaryPhone.phoneNumber : primaryPhone.number;
                preferredContactInfoMethod = preferredMethod;
            }

            break;
        case 'text-message':
            if (!isEmpty(primaryPhone) && (primaryPhone.phoneNumber || primaryPhone.number)) {
                preferredContactInfoText = primaryPhone.phoneNumber ?
                    primaryPhone.phoneNumber : primaryPhone.number;
                preferredContactInfoMethod = 'text';
            }

            break;
        default:
            if (!isEmpty(primaryPhone) && (primaryPhone.phoneNumber || primaryPhone.number)) {
                preferredContactInfoText = primaryPhone.phoneNumber ?
                    primaryPhone.phoneNumber : primaryPhone.number;
            } else if (primaryEmail.email) {
                preferredContactInfoText = primaryEmail.email;
            }

            preferredContactInfoMethod = preferredMethod;
    }

    return {
        method: preferredContactInfoMethod,
        text: preferredContactInfoText,
    };
};

export const getRecordTypeByGrade = (grade) => {
    if (isEmpty(grade)) { return null; }
    const adultGradeOptions = GRADE_OPTIONS.slice(0, 1);
    const childGradeOptions = GRADE_OPTIONS.slice(1, 8);
    const studentGradeOptions = GRADE_OPTIONS.slice(8, 15);
    let recordType;
    if (find(adultGradeOptions, (item) => item.value === grade.value || item.value === grade)) {
        recordType = 'adult';
    }

    if (find(childGradeOptions, (item) => item.value === grade.value || item.value === grade)) {
        recordType = 'child';
    }

    if (find(studentGradeOptions, (item) => item.value === grade.value || item.value === grade)) {
        recordType = 'student';
    }

    return recordType;
};

export const getPersonFullName = (person, withNickName = false) => {
    if (isNil(person)) {
        return null;
    }

    const {
        firstName,
        lastName,
        nickName,
    } = person;

    const shouldAddNickName = withNickName && nickName;
    const fullName = `${firstName} ${shouldAddNickName ? `(${nickName})` : ''} ${lastName}`;

    return fullName;
};

export const getPersonAvatarUrl = (person) => (
    // favor photo which might be in `photoUrl` or `profilePhotoUrl`
    person?.photoUrl ||
    person?.profilePhotoUrl ||
    // then check avatar which might be in `avatarUrl` or `profilePictureUrl`
    person?.avatarUrl ||
    person?.profilePictureUrl ||
    // otherwise, we give up, no picture for you!  Too bad, so sad...
    null
);

export const formatPersonResults = (
    persons,
    personsMilestones,
    options = {
        withMetaInfoText: true,
    },
) => {
    const {
        withMetaInfoText,
    } = options;

    const personsWithMilestones = persons.map((item) => {
        const fullNameText = getPersonFullName(item, true);
        const personAvatar = item.avatarUrl || item.photoUrl;
        const primaryEmergencyContact = find(item.emergencyContacts, 'isPrimary');

        const churchEntityNameText = getPersonChurchEntityNameText(item);
        const metaInfoText = withMetaInfoText && getPersonMetaInfoText(item);

        let preferredContactMethodInfo;
        let preferredContactMethodText;
        let preferredContactMethodInfoText;

        const studentContactPreferenceSet = item.isStudent &&
            !isEmpty(item.contactPreferences) &&
            (!isEmpty(item.phones) || !isEmpty(item.emails));

        const studentEmergencyContactSet =
            item.isStudent && !isEmpty(primaryEmergencyContact);

        if (item.isAdult || studentContactPreferenceSet) {
            if (!isEmpty(item.contactPreferences) &&
                item.contactPreferences.doNotContact) {
                preferredContactMethodText = i18n('Do Not Contact');
            } else if (item.contactPreferences?.preferredMethod === 'none') {
                preferredContactMethodText = `(${i18n('no preference')})`;
            } else {
                preferredContactMethodInfo = getPreferredContactMethod(
                    item.contactPreferences, item.emails, item.phones,
                );
                preferredContactMethodText = `prefers ${preferredContactMethodInfo.method}`;
                preferredContactMethodInfoText =
                    preferredContactMethodInfo.text;
            }
        }

        if (item.isChild ||
            (!studentContactPreferenceSet && studentEmergencyContactSet)) {
            const emergencyContactPreferredInfo = PersonEmergencyContactUtils
                .getPersonPreferredContactMethod(
                    primaryEmergencyContact,
                );

            if (
                primaryEmergencyContact &&
                !isEmpty(primaryEmergencyContact.emergencyContactPreference)
            ) {
                if (primaryEmergencyContact.emergencyContactPreference.preferredMethod === 'none') {
                    preferredContactMethodText = `(${i18n('no preference')})`;
                } else {
                    preferredContactMethodText =
                        `${primaryEmergencyContact.emergencyContactRelationshipName}'s ${emergencyContactPreferredInfo.method}`;
                }
                preferredContactMethodInfoText =
                    emergencyContactPreferredInfo.text;
            }
        }

        const coreMilestonesData = find(personsMilestones, { uniquePersonID: item.id });
        const recordType = getRecordType(item);

        const formattedContactInfo = getPersonFormattedContactInfo(item);

        return {
            ...item,
            personAvatar,
            fullNameText,
            churchEntityNameText,
            ...(withMetaInfoText && {
                metaInfoText,
            }),
            recordType,
            preferredContactMethodInfoText,
            preferredContactMethodText,
            coreMilestonesData,
            formattedContactInfo,
        };
    });

    return personsWithMilestones;
};
