import { addOrReplaceBy } from '../global/utils';

const DEFAULT_STATE = {
    // Shared Connection Card Template Manager State
    cardDetails: {},
    // Workflow Entities State
    templateWorkflowEntity: {},
    fieldWorkflowEntities: [],
};

// eslint-disable-next-line arrow-body-style
const isItTheOne = (entity, entityType, entityId) => {
    return entity.entityType === entityType &&
        entity.entityId === entityId;
};

const ConnectionCard = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ConnectionCardActions.ON_GETCONNECTIONCARDDETAILS':
            return {
                ...state,
                cardDetails: action.result,
            };

        case 'ConnectionCardActions.ON_PUTCONNECTIONCARDPUBLICLABELS':
            return {
                ...state,
                cardDetails: action.result,
            };

        case 'ConnectionCardActions.ON_GETWORKFLOWENTITIES':
        {
            const { template, fields } = action.result;
            return {
                ...state,
                templateWorkflowEntity: template,
                fieldWorkflowEntities: fields,
            };
        }

        case 'ConnectionCardActions.RESET':
            return {
                ...state,
                cardDetails: {},
            };

        case 'ConnectionCardActions.RESETCARDS':
            return {
                ...state,
                responseCardsAtLocation: [],
                cardDetails: {},
            };

        case 'ConnectionCardActions.RESET_WORKFLOW_ENTITIES':
            return {
                ...state,
                // Workflow Entity State
                templateWorkflowEntity: {},
                fieldWorkflowEntities: [],
            };

        case 'WorkflowRuleEditorActions.ON_CREATEWORKFLOWRULE':
        {
            const { templateWorkflowEntity, fieldWorkflowEntities } = state;
            const { result: newlyCreatedRule } = action;
            const entityCondition = newlyCreatedRule.entityConditions[0];
            const { entityType, entityId } = entityCondition;

            switch (entityType) {
                case 'response_card':
                {
                    const { ruleCount: previousRuleCount } = templateWorkflowEntity;
                    return {
                        ...state,
                        templateWorkflowEntity: {
                            ...templateWorkflowEntity,
                            ruleCount: previousRuleCount + 1,
                        },
                    };
                }

                case 'response_card_field':
                case 'response_card_sub_field':
                {
                    const isEntityOfInterest =
                        (entity) => isItTheOne(entity, entityType, entityId);

                    const getEntityWithIncrementedCount = (entity) => {
                        const { ruleCount: previousRuleCount } = entity;
                        return {
                            ...entity,
                            ruleCount: previousRuleCount + 1,
                        };
                    };

                    return {
                        ...state,
                        fieldWorkflowEntities: addOrReplaceBy(
                            fieldWorkflowEntities,
                            isEntityOfInterest,
                            getEntityWithIncrementedCount,
                        ),
                    };
                }

                default:
                    return state;
            }
        }

        case 'WorkflowRuleEditorActions.ON_DELETEWORKFLOWRULE':
        {
            const { templateWorkflowEntity, fieldWorkflowEntities } = state;
            const { entityId, entityType } = action.callbackParams;

            switch (entityType) {
                case 'response_card':
                {
                    const { ruleCount: previousRuleCount } = templateWorkflowEntity;
                    return {
                        ...state,
                        templateWorkflowEntity: {
                            ...templateWorkflowEntity,
                            ruleCount: previousRuleCount - 1,
                        },
                    };
                }

                case 'response_card_field':
                case 'response_card_sub_field':
                {
                    const isEntityOfInterest =
                        (entity) => isItTheOne(entity, entityType, entityId);

                    const getEntityWithDecrementedCount = (entity) => {
                        const { ruleCount: previousRuleCount } = entity;
                        return {
                            ...entity,
                            ruleCount: previousRuleCount - 1,
                        };
                    };

                    return {
                        ...state,
                        fieldWorkflowEntities: addOrReplaceBy(
                            fieldWorkflowEntities,
                            isEntityOfInterest,
                            getEntityWithDecrementedCount,
                        ),
                    };
                }

                default:
                    return state;
            }
        }

        default:
            return state;
    }
};

export default ConnectionCard;
