import { i18n } from '../../global/constants.js';

/**
 * Navigation related constants
 */
export const EVENTS_CENTRAL_SECTION_EVENT = 'event';
export const EVENTS_CENTRAL_SECTION_MY_EVENT = 'my-events';

export const EVENTS_SECTIONS = {
    root: '/events-central',
    allEvents: '/overview',
    event: '/event',
    myEvents: '/my-events',
    servingOpps: '/serving-opps',
    dashboardMyEvents: '/my-dashboard/my-events',
};

/**
 * Constants for filtering and sorting Events / Occurrences
 */
export const EVENT_CATEGORY_ID_WORSHIP_SERVICE = 1;

export const EVENT_FILTER_CANCELLED = 'Cancelled';
export const EVENT_FILTER_PAST = 'Past';
export const EVENT_FILTER_UPCOMING = 'Upcoming';

export const OPTION_ENTRY_ANY = 1;
export const OPTION_ENTRY_PAID = 2;
export const OPTION_ENTRY_FREE = 3;

export const OPTION_LEVEL_SERIES = 'Series';
export const OPTION_LEVEL_OCCURRENCE = 'Occurrence';

export const OPTION_STATUS_ANY = 1;
export const OPTION_STATUS_UNPUBLISHED = 2;
export const OPTION_STATUS_PUBLISHED = 3;

export const OPTION_REGISTRATION_ANY = 1;
export const OPTION_REGISTRATION_REQUIRED = 2;
export const OPTION_REGISTRATION_NOTREQUIRED = 3;

export const SORT_OPTIONS = [
    {
        label: i18n('Event Date (Ascending)'),
        value: 'date,asc',
    },
    {
        label: i18n('Event Date (Descending)'),
        value: 'date,desc',
    },
];

export const VIEW_TYPE_OVERVIEW = 1;
export const VIEW_TYPE_CANCELED = 2;
export const VIEW_TYPE_MY_EVENTS = 3;

/* ****************************************************************************
 * BEGIN: Defaults for customizing Registration Confirmation Email Template
 * ************************************************************************* */

/**
 * Default subject line for all Registration Confirmation Email Templates
 */
export const DEFAULT_REGISTRATION_EMAIL_SUBJECT = 'Success! You’re all set and registered for [[EventName]]';

/**
 * Default Email Body Content for a Free Event taking place at a physical venue.
 */
export const DEFAULT_FREE_PHYSICAL_EVENT_CUSTOM_REGISTRATION_EMAIL_BODY = `
<figure class="image" style="margin: 22px 0; padding: 0">
    <img src="https://s3-us-west-1.amazonaws.com/cm-prod-1/templates/email/SBLogo.png" style="display: block; width: 188px; height: 29px; margin: 0" title="Saddleback Church" alt="Saddleback Church"/>
</figure>
<p>Dear [[PreferredName]],</p>
<p>Looks like everything’s in order.</p>
<p>
    Your registration for [[EventName]] has been confirmed, and
    you’re scheduled to be there on [[EventNextDate]] at [[EventNextTime]].
    [[ContactFullName]] will let you know if you need to do anything else to
    get ready for the big day.
</p>
<p>
    If you can’t make it for any reason (or you
    suspect this registration was done in error) please let [[ContactFullName]]
    know as soon as possible by reaching out to them at [[ContactData]].
</p>
<p>We hope your event goes well.</p>

<div>
    [[EventSignUpDetails]]
</div>

<p>[[EventICalUrl]]</p>
<p>[[EventRegistrationCancellationUrl]]</p>
`;

/**
 * Default Email Body Content for a Free Virtual Event.
 */
export const DEFAULT_FREE_VIRTUAL_EVENT_CUSTOM_REGISTRATION_EMAIL_BODY = `
<figure class="image" style="margin: 22px 0; padding: 0">
    <img src="https://s3-us-west-1.amazonaws.com/cm-prod-1/templates/email/SBLogo.png" style="display: block; width: 188px; height: 29px; margin: 0" title="Saddleback Church" alt="Saddleback Church"/>
</figure>
<p>Dear [[PreferredName]],</p>
<p>Looks like everything’s in order.</p>
<p>
    Your registration for [[EventName]] has been confirmed, and
    the virtual event will be taking place on [[EventNextDate]] at [[EventNextTime]].
    [[ContactFullName]] will let you know if you need to do anything else to
    get ready for the big day.
</p>
<p>
    If you can’t make it for any reason (or you
    suspect this registration was done in error) please let [[ContactFullName]]
    know as soon as possible by reaching out to them at [[ContactData]].
</p>
<p>We hope your event goes well.</p>
<p>
    Join Meeting<br />
    [[EventUrl]]
</p>
<div>
    [[EventSignUpDetails]]
</div>

<p>[[EventICalUrl]]</p>
<p>[[EventRegistrationCancellationUrl]]</p>
`;

/**
 * Default Email Body Content for a Paid Event (regardless of whether physical or virtual)
 */
export const DEFAULT_PAID_EVENT_CUSTOM_REGISTRATION_EMAIL_BODY = `
<figure class="image" style="margin: 22px 0; padding: 0">
    <img src="https://s3-us-west-1.amazonaws.com/cm-prod-1/templates/email/SBLogo.png" style="display: block; width: 188px; height: 29px; margin: 0" title="Saddleback Church" alt="Saddleback Church"/>
</figure>
<p>Dear [[PreferredName]],</p>
<p>Looks like everything’s in order.</p>
<p>
    Your ticket(s) for [[EventName]] have been confirmed, and
    you’re scheduled to be there at [[EventNextTime]] on [[EventNextDate]].
    [[ContactFullName]] will let you know if you need to do anything else to
    get ready for the event.
</p>
<p>
    If you have any questions about this event’s refund policy or would like to request a refund,
    please contact [[ContactFullName]] at [[ContactData]].
</p>
<p>We hope you enjoy your event!</p>

<div>
    [[EventSignUpDetails]]
</div>
<div>
    [[PaidEventOrderSummary]]
</div>

<p>[[EventICalUrl]]</p>
<p>[[EventRegistrationCancellationUrl]]</p>
`;

/* ****************************************************************************
 * END: Defaults for customizing Registration Confirmation Email Template
 * ************************************************************************* */

/** Event Check Rooms */
export const ROOM_TYPE_NONE = 'none';
export const ROOM_TYPE_CHILD = 'child';
export const ROOM_TYPE_GENERAL = 'general';
