import {
    find,
    isEmpty,
    map,
} from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ConnectionCardsEntryFormFields from './connectionCardsEntryFormFields.jsx';
import Form from '../../global/form';

const propTypes = {
    className: PropTypes.string,
    disableFields: PropTypes.bool,
    entryFieldsetsData: PropTypes.arrayOf(PropTypes.shape({})),
    isPersonInfoVisible: PropTypes.bool,
    style: PropTypes.shape({}),
    templateFieldsetsData: PropTypes.arrayOf(PropTypes.shape({})),
    bypassFieldValidation: PropTypes.bool,
};

const defaultProps = {
    className: undefined,
    disableFields: false,
    entryFieldsetsData: [],
    isPersonInfoVisible: true,
    style: {},
    templateFieldsetsData: [],
    bypassFieldValidation: false,
};

const bemBlockName = 'connection_cards_entry_form';

function ConnectionCardEntryForm(props) {
    const {
        className,
        disableFields,
        entryFieldsetsData,
        isPersonInfoVisible,
        style,
        templateFieldsetsData,
        bypassFieldValidation,
    } = props;

    const isDataEmpty = isEmpty(entryFieldsetsData) || isEmpty(templateFieldsetsData);

    if (
        isDataEmpty ||
        (!isDataEmpty && entryFieldsetsData.responseCardId !== templateFieldsetsData.id)
    ) {
        return null;
    }

    const containerClasses = ClassNames(bemBlockName, className);

    return (
        <Form className={containerClasses} style={style}>
            {map(entryFieldsetsData, (fieldset) => {
                const templateFieldset = find(templateFieldsetsData, { id: fieldset.id });

                const isPersonalSection = templateFieldset.title === 'Personal';

                return (isPersonalSection && !isPersonInfoVisible) ?
                    null : (
                        <Form.Fieldset
                            className={`${bemBlockName}--field_set`}
                            id={`${bemBlockName}--field_set_${fieldset.id}`}
                            key={`${bemBlockName}--field_set_${fieldset.id}`}
                            title={fieldset.title}
                            subTitle={fieldset.subtitle}
                        >
                            {map(fieldset.values, (field) => {
                                const templateField = bypassFieldValidation ?
                                    field : find(templateFieldset.fields, {
                                        id: field.responseCardFieldId,
                                    });

                                return (
                                    <ConnectionCardsEntryFormFields
                                        bemBlockName={bemBlockName}
                                        entryFieldData={field}
                                        disable={disableFields}
                                        key={`${bemBlockName}--fields_${field.responseCardFieldId}`}
                                        templateFieldData={templateField}
                                    />
                                );
                            })}
                        </Form.Fieldset>
                    );
            })}
        </Form>
    );
}

ConnectionCardEntryForm.propTypes = propTypes;
ConnectionCardEntryForm.defaultProps = defaultProps;

export default ConnectionCardEntryForm;
