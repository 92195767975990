// eslint-disable-next-line import/prefer-default-export
export const getRecordType = (personData) => {
    const {
        isAdult,
        isChild,
        isStudent,
    } = personData;

    let recordType;

    if (isAdult) {
        recordType = 'adult';
    } else if (isStudent) {
        recordType = 'student';
    } else if (isChild) {
        recordType = 'child';
    }

    return recordType;
};
