import { ADD_PERSON_SET_IS_SAVE_DISABLED } from './addPersonTypes.js';

const DEFAULT_STATE = {
    churchEntities: [],
    isFetchingChurchEntities: false,
    isPersonSaveDisabled: true,
    personalFormData: {
        firstName: '',
        lastName: '',
        birthDate: null,
        gender: null,
        grade: null,
        campus: null,
    },
    contactFormData: {
        email: '',
        phone: {
            countryCode: 'US',
            extension: '',
            isBadContact: false,
            isValidPhoneNumber: false,
            phoneNumber: '',
            phoneType: 'Home Phone',
            phoneTypeId: 1,
            isPrimary: true,
        },
        address1: '',
        address2: '',
        city: '',
        postalCode: '',
        country: null,
        region: null,
    },
    avatarImage: {
        filename: '',
        url: '',
    },
    recordType: '',
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'AddPersonRecordActions.ON_BEFORE_GETCHURCHENTITY': {
            return {
                ...state,
                isFetchingChurchEntities: true,
            };
        }

        case 'AddPersonRecordActions.ON_GETCHURCHENTITY': {
            return {
                ...state,
                isFetchingChurchEntities: false,
                churchEntities: action.result,
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_BIRTHDATE': {
            const { value: birthDate } = action;
            return {
                ...state,
                personalFormData: {
                    ...state.personalFormData,
                    birthDate,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_FIRSTNAME': {
            const { value: firstName } = action;
            return {
                ...state,
                personalFormData: {
                    ...state.personalFormData,
                    firstName,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_LASTNAME': {
            const { value: lastName } = action;
            return {
                ...state,
                personalFormData: {
                    ...state.personalFormData,
                    lastName,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_GENDER': {
            const { value: gender } = action;
            return {
                ...state,
                personalFormData: {
                    ...state.personalFormData,
                    gender,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_GRADE': {
            const { value: grade } = action;
            return {
                ...state,
                personalFormData: {
                    ...state.personalFormData,
                    grade,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_CAMPUS': {
            const { value: campus } = action;
            return {
                ...state,
                personalFormData: {
                    ...state.personalFormData,
                    campus,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_EMAIL': {
            const { value: email } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    email,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_PHONE': {
            const {
                value: {
                    countryCode,
                    isValidPhoneNumber,
                    phoneNumber,
                },
            } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    phone: {
                        ...state.contactFormData.phone,
                        countryCode,
                        isValidPhoneNumber,
                        phoneNumber,
                    },
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_PHONE_TYPE': {
            const {
                value: {
                    phoneType,
                    phoneTypeId,
                },
            } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    phone: {
                        ...state.contactFormData.phone,
                        extension: '',
                        phoneType,
                        phoneTypeId,
                    },
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_PHONE_EXTENSION': {
            const {
                value: extension,
            } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    phone: {
                        ...state.contactFormData.phone,
                        extension,
                    },
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_ADDRESS_ONE': {
            const { value: address1 } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    address1,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_ADDRESS_TWO': {
            const { value: address2 } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    address2,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_CITY': {
            const { value: city } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    city,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_COUNTRY': {
            const { value: country } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    country,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_AVATAR': {
            const {
                value: {
                    filename,
                    url,
                },
            } = action;
            return {
                ...state,
                avatarImage: {
                    ...state.avatarImage,
                    filename,
                    url,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_POSTAL_CODE': {
            const { value: postalCode } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    postalCode,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_REGION': {
            const { value: region } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    region,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_RECORD_TYPE': {
            const { value: recordType } = action;
            return {
                ...state,
                recordType,
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_PERSONAL_FORM': {
            const { value: data } = action;
            return {
                ...state,
                personalFormData: {
                    ...state.personalFormData,
                    ...data,
                },
            };
        }

        case 'AddPersonRecordActions.ON_UPDATE_CONTACT_FORM': {
            const { value: data } = action;
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    ...data,
                },
            };
        }

        case 'AddPersonRecordActions.ON_RESET_REGION': {
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    region: null,
                },
            };
        }

        case 'AddPersonRecordActions.ON_RESET_PERSONAL_FORM': {
            return {
                ...state,
                personalFormData: DEFAULT_STATE.personalFormData,
            };
        }

        case 'AddPersonRecordActions.ON_RESET_CONTACT_FORM': {
            return {
                ...state,
                contactFormData: DEFAULT_STATE.contactFormData,
            };
        }

        case 'AddPersonRecordActions.ON_RESET_AVATAR': {
            return {
                ...state,
                avatarImage: DEFAULT_STATE.avatarImage,
            };
        }

        case ADD_PERSON_SET_IS_SAVE_DISABLED: {
            return {
                ...state,
                isPersonSaveDisabled: action.isPersonSaveDisabled,
            };
        }

        default:
            return state;
    }
};
