import {
    filter,
    isEmpty,
    isEqual,
    without,
} from 'lodash';
import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

// exported for unit test purposes
export const pagedRulesList = new ChunkedPaginationUtils();

// exported for unit test purposes
export const defaultFilters = {
    isActive: null,
    selectedChurchEntityIds: [],
    sort: {
        label: 'Rule Name (A > Z)',
        value: 'name',
    },
};

// exported for unit test purposes
export const DEFAULT_STATE = {
    appliedFilters: { ...defaultFilters },
    areFiltersDirty: false,
    canLoadMore: false,
    defaultFilters: { ...defaultFilters },
    dirtyFilters: { ...defaultFilters },
    isFetching: false,
    isFiltering: false,
    rules: [],
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedRulesList.getPageSize(),
    searchTerm: null,
    total: 0,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

const WorkflowRulesList = (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
        value: actionValue,
    } = action;

    const first = !isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        // Fetching List of Rules
        case 'WorkflowRulesListActions.ON_BEFORE_GETWORKFLOWRULES':
            if (first) {
                return { ...state, isFetching: true };
            }

            return state;

        case 'WorkflowRulesListActions.ON_GETWORKFLOWRULES':
        {
            const { resultCount, results } = actionResult;
            pagedRulesList.loadPage(results, resultCount, first);
            const rules = pagedRulesList.getAll(true);

            return {
                ...state,
                canLoadMore: pagedRulesList.canLoadMore(),
                isFetching: false,
                needsToRequest: pagedRulesList.needsToLoadPage(),
                pageNumber: pagedRulesList.getCurrentPageNumber(),
                pageSize: pagedRulesList.getPageSize(),
                rules,
                total: first ? pagedRulesList.getTotalCount() : state.total,
            };
        }

        case 'WorkflowRulesListActions.ON_GET_RULES_NEXT_PAGE':
        {
            const rules = pagedRulesList.getAll(true);

            return {
                ...state,
                canLoadMore: pagedRulesList.canLoadMore(),
                needsToRequest: pagedRulesList.needsToLoadPage(),
                rules,
            };
        }

        // Manipulating Filters and Sorts
        case 'WorkflowRulesListActions.ON_SET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: actionValue,
            };

        case 'WorkflowRulesListActions.ON_CLEAR_SEARCH_TERM':
            return {
                ...state,
                searchTerm: null,
            };

        case 'WorkflowRulesListActions.ON_APPLY_FILTERS':
        {
            const { dirtyFilters } = state;
            const isFiltering = !isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'WorkflowRulesListActions.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(state, { ...defaultFilters });

        case 'WorkflowRulesListActions.ON_CHURCH_ENTITY_FILTER_CHANGED_AND_APPLIED':
        {
            const { churchEntityId, isChecked } = actionValue;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedChurchEntityIds: isChecked ?
                    [...state.appliedFilters.selectedChurchEntityIds, churchEntityId] :
                    without(
                        state.appliedFilters.selectedChurchEntityIds,
                        churchEntityId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'WorkflowRulesListActions.ON_SORT_ORDER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, sort: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'WorkflowRulesListActions.ON_STATUS_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, isActive: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'WorkflowRulesListActions.ON_DIRTY_CHURCH_ENTITY_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedChurchEntityIds: actionValue },
            );
        }

        case 'WorkflowRulesListActions.ON_DIRTY_SORT_ORDER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(state, { ...dirtyFilters, sort: actionValue });
        }

        case 'WorkflowRulesListActions.ON_DIRTY_STATUS_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(state, { ...dirtyFilters, isActive: actionValue });
        }

        // Handle rule deletion
        // TODO: Might need to change...?  Will this work with pagination?
        case 'WorkflowRuleEditorActions.ON_DELETEWORKFLOWRULE':
        {
            const { workflowRuleId: deletedRuleId } = action.callbackParams;
            const updatedRuleSet = filter(state.rules, (r) => r.id !== deletedRuleId);
            return {
                ...state,
                rules: updatedRuleSet,
            };
        }

        // Reset all state to default
        case 'WorkflowRulesListActions.RESET':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};

export default WorkflowRulesList;
