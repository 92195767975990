import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher.js';
import BootstrapConstants from '../constants/BootstrapConstants.js';
import AppSettingsAPIUtils from '../utils/AppSettingsAPIUtils.js';

const CHANGE_EVENT = 'change';
const BODY_CLASS_EVENT = 'bodyChange';
const GLOBAL_FOOTER = 'footerChange';
const SAVE_EVENT = 'save';

class AppStoreClass extends EventEmitter {
    constructor() {
        super();

        // default API version # to same version # as Front End
        // if/when API version call completes, we'll update the API version #
        // this.internalApiVersion = __CM_CORE_FE_VERSION__; // eslint-disable-line
        this.internalApiVersion = null;
        this.internalAppSettings = {};
        this.internalServingOpportunityOptions = {};
        this.internalUploadingOptions = {};
        this.internalBodyClass = true;
        this.internalGlobalFooter = true;
    }

    updateApiVersion(apiVersion) {
        this.internalApiVersion = apiVersion;
    }

    updateAppSettings(appSettings) {
        this.internalAppSettings = appSettings;
    }

    updateServingOpportunityOptions(servingOpportunityOptions) {
        this.internalServingOpportunityOptions = servingOpportunityOptions;
    }

    updateUploadingOptions(uploadingOptions) {
        this.internalUploadingOptions = uploadingOptions;
    }

    getBodyClass() {
        return this.internalBodyClass;
    }

    toggleBodyClass(bodyClass) {
        this.internalBodyClass = bodyClass;
        this.emitBodyClassChange();
    }

    addBodyClassChangeListener(callback) {
        this.on(BODY_CLASS_EVENT, callback);
    }

    emitBodyClassChange() {
        this.emit(BODY_CLASS_EVENT);
    }

    getGlobalFooter() {
        return this.internalGlobalFooter;
    }

    updateGlobalFooter(globalFooter) {
        this.internalGlobalFooter = globalFooter;
        this.emitGlobalFooterChange();
    }

    removeBodyClassChangeListener(callback) {
        this.removeListener(BODY_CLASS_EVENT, callback);
    }

    addGlobalFooterChangeListener(callback) {
        this.on(GLOBAL_FOOTER, callback);
    }

    emitGlobalFooterChange() {
        this.emit(GLOBAL_FOOTER);
    }

    removeGlobalFooterListener(callback) {
        this.removeListener(GLOBAL_FOOTER, callback);
    }

    getApiVersion() {
        return this.internalApiVersion;
    }

    getAppSettings() {
        return this.internalAppSettings;
    }

    getServingOpportunityOptions() {
        return this.internalServingOpportunityOptions;
    }

    getUploadingOptions() {
        return this.internalUploadingOptions;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    addSaveListener(callback) {
        this.on(SAVE_EVENT, callback);
    }

    removeSaveListener(callback) {
        this.removeListener(SAVE_EVENT, callback);
    }

    emitSave() {
        this.emit(SAVE_EVENT);
    }
}

const AppStore = new AppStoreClass();

AppDispatcher.register((payload) => {
    switch (payload.actionType) {
        case BootstrapConstants.GET_API_VERSION:
            AppStore.updateApiVersion(payload.data.apiVersion);
            AppStore.emitChange();
            break;

        case BootstrapConstants.GET_BOOTSTRAP:
            AppStore.updateApiVersion(payload.data.apiVersion);
            AppStore.updateAppSettings(payload.data.appSettings);
            AppStore.updateServingOpportunityOptions(payload.data.servingOpportunityOptions);
            AppStore.updateUploadingOptions(payload.data.uploadingOptions);
            AppStore.emitChange();
            break;

        case AppSettingsAPIUtils.ON_SAVE:
            AppStore.updateAppSettings(payload.result);
            AppStore.emitChange();
            AppStore.emitSave();
            break;

        default:
        // no op
    }
});

export default AppStore;
