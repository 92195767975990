import NotificationBannerConstants from '../../constants/Notification/NotificationBannerConstants';

export const DEFAULT_STATE = {};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case NotificationBannerConstants.SET_NOTIFICATION_BANNER:
            return { bannerId: action.id };
    }
    return state;
}
