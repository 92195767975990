import {
    includes,
    isEmpty,
    isNil,
} from 'lodash';
import moment from 'moment-timezone';
import { CONDITION_TYPE } from './conditionConstants.js';

export const conditionsNotRequiringParameters = [
    CONDITION_TYPE.IsSelected,
    CONDITION_TYPE.IsNotSelected,
    CONDITION_TYPE.HasValue,
    CONDITION_TYPE.DoesNotHaveValue,
];

export const doesConditionRequireParameter =
    (condition) => (!includes(conditionsNotRequiringParameters, condition));

export const parseDateRange = (dateRangeParameterString) => {
    if (isEmpty(dateRangeParameterString)) {
        return null;
    }

    const inclusiveOnStart = dateRangeParameterString.startsWith('[');
    const inclusiveOnEnd = dateRangeParameterString.endsWith(']');
    const dates = dateRangeParameterString.slice(1, dateRangeParameterString.length - 1);
    const datesSplit = dates.split('/');

    let dateRangeStart = null;
    let dateRangeEnd = null;

    if (datesSplit.length && !isEmpty(datesSplit[0])) {
        dateRangeStart = moment(datesSplit[0]);
    }

    if (datesSplit.length > 1 && !isEmpty(datesSplit[1])) {
        dateRangeEnd = moment(datesSplit[1]);
    }

    return {
        dateRangeStart,
        dateRangeEnd,
        inclusiveOnStart,
        inclusiveOnEnd,
    };
};

export const serializeDateRange =
    (dateRangeStart, dateRangeEnd, inclusiveOnStart, inclusiveOnEnd) => {
        const dateRangeStartString = isNil(dateRangeStart) ?
            '' :
            dateRangeStart.format('YYYY-MM-DD');

        const dateRangeEndString = isNil(dateRangeEnd) ?
            '' :
            dateRangeEnd.format('YYYY-MM-DD');

        return `${inclusiveOnStart ? '[' : '('}${dateRangeStartString}/${dateRangeEndString}${inclusiveOnEnd ? ']' : ')'}`;
    };
