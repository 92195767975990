import { concat } from 'lodash';
import { selectBoxTransform } from '../../../global/utils';

export const DEFAULT_STATE = [
    { value: 0, label: 'All Regions' },
];

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'RegionActions.ON_GETALL':
            return concat(DEFAULT_STATE, selectBoxTransform(action, 'id', 'name'));

        default:
            return state;
    }
};
