/**
 * This component renders a single Connection Forms 2.0 Form Entry.
 * It is used in:
 * 1) Person Record 2.0 (for the Form Entries Carousel feature)
 * 2) My Follow Ups 2.0 (to be able to view a complete Form Entry response on
 *    within the Follow Ups tab in the Person Record 2.0 Drawer) [WIP]
 */

import { map } from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Form from '../../../../global/form';
import FormEntryPersonalPanel from './formEntryPersonalPanel.jsx';
import FormEntrySection from './formEntrySection.jsx';

const bemBlockName = 'connection_forms_entry_form';

const propTypes = {
    className: PropTypes.string,
    disableFields: PropTypes.bool,
    formEntry: PropTypes.shape({
        agreements: PropTypes.arrayOf(PropTypes.shape({})),
        answers: PropTypes.arrayOf(PropTypes.shape({})),
        form: PropTypes.shape({
            litePersonalPanel: PropTypes.bool,
            sections: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
            })),
        }),
        person: PropTypes.shape({
            churchEntityId: PropTypes.number,
        }),
        personalInfo: PropTypes.shape({}),
    }).isRequired,
    hideRedactedAnswers: PropTypes.bool,
    isPersonInfoVisible: PropTypes.bool,
    showOnlyAnsweredFields: PropTypes.bool,
    style: PropTypes.shape({}),
};

const defaultProps = {
    className: undefined,
    disableFields: false,
    hideRedactedAnswers: false,
    isPersonInfoVisible: true,
    showOnlyAnsweredFields: false,
    style: {},
};

function FormEntry({
    className,
    disableFields,
    formEntry,
    hideRedactedAnswers,
    isPersonInfoVisible,
    showOnlyAnsweredFields,
    style,
}) {
    const {
        agreements,
        answers,
        form: {
            litePersonalPanel: isLitePersonalPanel,
            sections,
        },
        personalInfo,
    } = formEntry;

    const containerClasses = ClassNames(bemBlockName, className);

    let visibleSections;

    if (showOnlyAnsweredFields) {
        const answeredQuestionsIds = answers.map((answer) => (answer.questionId));
        visibleSections = sections.map((section) => ({
            ...section,
            fields: section.fields.filter(
                (field) => (answeredQuestionsIds.includes(field.questionId)),
            ),
        }));
    } else {
        visibleSections = sections;
    }

    return (
        <React.Fragment>
            <Form
                className={containerClasses}
                style={style}
            >
                <FormEntryPersonalPanel
                    bemBlockName={bemBlockName}
                    disableFields={disableFields}
                    isLitePersonalPanel={isLitePersonalPanel}
                    isPersonInfoVisible={isPersonInfoVisible}
                    personalInfo={personalInfo}
                />
            </Form>

            {map(visibleSections, (section) => (
                <FormEntrySection
                    agreements={agreements}
                    answers={answers}
                    bemBlockName={bemBlockName}
                    disableFields={disableFields}
                    entryChurchEntityId={formEntry?.person?.churchEntityId}
                    hideRedactedAnswers={hideRedactedAnswers}
                    key={`form_section_${section.id}`}
                    formSection={section}
                />
            ))}
        </React.Fragment>
    );
}

FormEntry.propTypes = propTypes;
FormEntry.defaultProps = defaultProps;

export default FormEntry;
