import { Action } from '../../../global/models';
import ActionType from '../../actionTypes/communications.actionTypes';

export const INITIAL_STATE = {
    churchEntityId: null,
    isActive: null,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_COMMUNICATIONS_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        case ActionType.RESET_COMMUNICATIONS_FILTERS:
            return {
                ...state,
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};
