export default {
    BANNER_ERROR: 'ERROR',
    BANNER_SUCCESS: 'SUCCESS',
    BANNER_SECONDARY: 'SECONDARY',
    BANNER_WARNING: 'WARNING',
    ADD_BANNER: 'ADD_BANNER',
    CLOSE_BANNER: 'CLOSE_BANNER',
    REMOVE_BANNER: 'REMOVE_BANNER',
    UPDATE_BANNER: 'UPDATE_BANNER',
};
