import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../global/utils/utils.js';

export default class AddressFormatter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const k = () => Utils.getIncreasingUniqueKey();

        let address1; let address2; let city; let region; let postalCode; let
            country = '';
        const address = this.props.addressObject;

        if (address) {
            address1 = address.address1;
            address2 = address.address2;
            city = address.city;
            region = address.region;
            postalCode = address.postalCode;
            country = address.country;
        } else {
            address1 = this.props.address1;
            address2 = this.props.address2;
            city = this.props.city;
            region = this.props.region;
            postalCode = this.props.postalCode;
            country = this.props.country;
        }

        return (
            <div className={this.props.classes}>
                {
                (address1 || address2 || city || region || postalCode || country) ? [
                    <div key={k()}>
                        {address1 ? [<span className="address-1" key={k()} dangerouslySetInnerHTML={this.getMarkupFromFields(address1)} />, <br key={k()} />] : null}
                        {address2 ? [<span className="address-2" key={k()} dangerouslySetInnerHTML={this.getMarkupFromFields(address2)} />, <br key={k()} />] : null}
                        {city ? <span className="city" dangerouslySetInnerHTML={this.getMarkupFromFields(`${city},&nbsp;`)} /> : null}
                        {region ? [<span className="region" key={k()} dangerouslySetInnerHTML={this.getMarkupFromFields(`${region}&nbsp;`)} />] : null}
                        {postalCode ? <span className="postal-code" dangerouslySetInnerHTML={this.getMarkupFromFields(postalCode)} /> : null}
                        {country ? [<br key={k()} />, <span className="country" key={k()} dangerouslySetInnerHTML={this.getMarkupFromFields(country)} />] : null}
                    </div>,
                ] : null
            }
            </div>
        );
    }

    getMarkupFromFields(...fields) {
        return { __html: fields.map((f) => _.unescape(f)).join(' ') };
    }
}

AddressFormatter.propTypes = {
    address1: PropTypes.string,
    address2: PropTypes.string,
    addressObject: PropTypes.object,
    city: PropTypes.string,
    classes: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.string,
    returnHTML: PropTypes.bool,
};
