import {
    get,
    find,
} from 'lodash';
import moment from 'moment-timezone';
import {
    NOTE_REMINDER_DATE_FORMAT,
    NOTE_REMINDER_DETAIL_DUE_DATE_FORMAT,
    NOTE_REMINDER_DUE_HOUR,
    NOTE_REMINDER_DUE_TIME,
} from './noteReminderConstants';
import dateUtils from '../../../../global/dateUtils.js';
import Person from '../../../../connectionForms/public/models/personDetails.model';
import PersonNote from '../models/PersonNote.model';
import {
    DateTypeOption,
    NoteReminder,
    NoteReminderFormData,
    ReminderUserData,
    Sequence,
    Schedule,
} from './models';

export const getSequence = (date: string): Sequence => {
    if (!date) {
        return null;
    }

    const startDate = moment(date);
    const endDate = moment(date).add(1, 'days');
    const startDateFormatted = startDate.format(NOTE_REMINDER_DATE_FORMAT);
    const occurrenceStartDate = startDateFormatted;
    const dayOfWeek = startDate.isoWeekday();
    const startTime = NOTE_REMINDER_DUE_TIME;
    const endDateFormatted = endDate.format(NOTE_REMINDER_DATE_FORMAT);
    const sequence = {
        canBeChanged: false,
        dayOfWeek,
        duration: '0.00:05:00',
        endDate: endDateFormatted,
        interval: 1,
        isRecurring: false,
        occurrenceStartDate,
        startDate: startDateFormatted,
        startTime,
        timeZone: dateUtils.getPreferredTimeZone(),
    };

    return sequence;
};

export const buildCalendarSchedulePayload = (
    churchEntityId: number,
    dateTypeOption: DateTypeOption,
    customDate: moment.MomentTimezone,
): Schedule => {
    const isThereEnoughInfo = typeof churchEntityId === 'number' && dateTypeOption;

    if (!isThereEnoughInfo) {
        return null;
    }

    const today = moment();
    const todayFormatted = today.format(NOTE_REMINDER_DATE_FORMAT);
    let dueDate;
    const optionLabel = get(dateTypeOption, 'label', '').toLowerCase();

    if (optionLabel && optionLabel !== 'custom') {
        const daysFromNow = parseInt(optionLabel.split(' ')[0], 10);
        dueDate = moment(today).add(daysFromNow, 'days');
    } else {
        dueDate = customDate;
    }

    const dueDateFormatted = dueDate.format(NOTE_REMINDER_DATE_FORMAT);
    const sequence = getSequence(dueDate);
    const calendarSchedulePayLoad = {
        churchEntityId,
        custom: false,
        endDate: dueDateFormatted,
        occurrencesCount: 1,
        scheduleEntityTypeId: 4,
        sequences: [
            sequence,
        ],
        startDate: todayFormatted,
    };

    return calendarSchedulePayLoad;
};

export const buildReminderPayload = (
    loggedInUserData: ReminderUserData,
    noteData: PersonNote,
    personData: Person,
    schedulePayload: Schedule,
): NoteReminder => {
    const isThereEnoughInfo = loggedInUserData &&
        noteData &&
        personData &&
        personData &&
        schedulePayload;

    if (!isThereEnoughInfo) {
        return null;
    }

    const {
        senderId,
        senderName,
        replyEmail,
    } = loggedInUserData;

    const noteDirectLink = `${window.location.origin}/people/search-or-add/record/${get(personData, 'id')}/note/${get(noteData, 'id')}`;
    let userName = senderName || replyEmail;
    userName = userName ? `, ${userName}` : '';
    const dueDate = moment(get(schedulePayload, 'endDate'));
    const reminderDate = dueDate.format(NOTE_REMINDER_DETAIL_DUE_DATE_FORMAT);
    const reminderHtmlBody = `
        Hi ${userName}!
        <br/><br/>That reminder you scheduled for yourself for
        ${reminderDate} at ${NOTE_REMINDER_DUE_TIME} hours is here! You can head over to have a look at it using this handy <a href="${noteDirectLink}">link.</a>
        <br/><br/>Have a great rest of your day!
    `;

    const reminderPayload = {
        before: 0,
        enabled: true,
        reminderData: {
            emailData: {
                ccToSender: false,
                htmlBody: reminderHtmlBody,
                plainTextBody: get(noteData, 'noteText'),
                replyEmail,
                subject: 'Heads up! You’ve got a reminder',
                senderId,
                senderName,
            },
        },
        reminderInterval: 1,
        scheduleId: 0,
        reminderDate,
        noteDirectLink,
    };

    return reminderPayload;
};

export const getNoteReminderDueTimeInfo = (
    reminderData: NoteReminder & NoteReminderFormData,
): {
    remainingDays: number | null;
    dueDate: moment.MomentTimezone | null;
} => {
    if (!reminderData) {
        return {
            remainingDays: null,
            dueDate: null,
        };
    }

    const today = moment();
    const reminderId = get(reminderData, 'id');
    let dueDate;
    let shouldAjustRelativeNextMorning = true;

    if (reminderId) {
        dueDate = get(reminderData, 'dueDate');
    } else { // means the info is in the redux store waiting for the note to be created
        const {
            dateTypeOption,
            customDate,
        } = reminderData;

        const optionLabel = get(dateTypeOption, 'label', '').toLowerCase();

        if (optionLabel && optionLabel !== 'custom') {
            const daysFromNow = parseInt(optionLabel.split(' ')[0], 10);
            dueDate = moment(today).add(daysFromNow, 'days');
        } else {
            dueDate = moment(customDate).add(1, 'days');
        }

        shouldAjustRelativeNextMorning = false;
    }

    let remainingDays: number = dueDate.diff(today, 'days');
    const hoursToday = today.get('hours');
    const isRelativeNextMorning = hoursToday > NOTE_REMINDER_DUE_HOUR;

    if (shouldAjustRelativeNextMorning && isRelativeNextMorning) {
        remainingDays += 1;
    }

    const reminderDueTimeInfo = {
        remainingDays,
        dueDate,
    };

    return reminderDueTimeInfo;
};

export const getNotesWithExpiredReminders = (
    notes: PersonNote[],
    reminders: NoteReminder[],
): PersonNote[] => {
    const expiredReminders = [];

    if (!notes && !reminders) {
        return expiredReminders;
    }

    const now = moment();
    for (let i = 0; i < reminders.length; i += 1) {
        const reminder = reminders[i];
        const scheduleEndDate = get(reminder, 'schedule.endDate');
        const scheduleTimeZone = get(reminder, 'schedule.sequences[0].timeZone');
        const dueDate = moment(`${scheduleEndDate} 08:00:00`).tz(scheduleTimeZone).add(1, 'days');
        const diff = dueDate.diff(now, 'minutes');

        if (diff < 0) {
            expiredReminders.push(reminder);
        }
    }
    const notesWithExpiredReminders = [];

    for (let i = 0; i < expiredReminders.length; i += 1) {
        const expiredReminder = expiredReminders[i];
        const expiredReminderId = get(expiredReminder, 'id');
        const noteWithExpiredReminder = find(notes, { reminderId: expiredReminderId }) ?? {};

        if (noteWithExpiredReminder) {
            notesWithExpiredReminders.push({
                ...noteWithExpiredReminder,
                reminderId: null,
                expiredReminder: {
                    ...expiredReminder,
                    enabled: false,
                    schedule: null,
                },
            });
        }
    }

    return notesWithExpiredReminders;
};
