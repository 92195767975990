import metricAgeGroups, { DEFAULT_STATE as METRIC_AGE_GROUPS_DEFAULT_STATE } from './metricAgeGroups';
import metricCategories, { DEFAULT_STATE as METRIC_CATEGORIES_DEFAULT_STATE } from './metricCategories';
import worshipService, { DEFAULT_STATE as WORSHIP_SERVICE_DEFAULT_STATE } from './worshipService';
import worshipServiceCategories, { DEFAULT_STATE as WORSHIP_SERVICE_CATEGORIES_DEFAULT_STATE } from './worshipServiceCategories';
import worshipServiceMetrics, { DEFAULT_STATE as WORSHIP_SERVICE_METRICS_DEFAULT_STATE } from './worshipServiceMetrics';
import worshipServiceSearch, { DEFAULT_STATE as WORSHIP_SERVICE_SEARCH_DEFAULT_STATE } from './worshipServiceSearch';
import LoginConstants from '../../constants/Login/LoginConstants';

const DEFAULT_STATE = {
    metricAgeGroups: METRIC_AGE_GROUPS_DEFAULT_STATE,
    metricCategories: METRIC_CATEGORIES_DEFAULT_STATE,
    worshipService: WORSHIP_SERVICE_DEFAULT_STATE,
    worshipServiceCategories: WORSHIP_SERVICE_CATEGORIES_DEFAULT_STATE,
    worshipServiceMetrics: WORSHIP_SERVICE_METRICS_DEFAULT_STATE,
    worshipServiceSearch: WORSHIP_SERVICE_SEARCH_DEFAULT_STATE,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'WorshipServicesSearchActions.ON_RESET':
        case 'WorshipServicesSearchActions.ON_FILTERED':
        case 'WorshipServicesSearchActions.ON_CLOSED':
        case 'WorshipServicesSearchActions.ON_NEXTPAGE':
            return { ...state, worshipServiceSearch: worshipServiceSearch(state.worshipServiceSearch, action) };

        case 'MetricAgeGroupsActions.ON_GETALL':
            return { ...state, metricAgeGroups: metricAgeGroups(state.metricAgeGroups, action) };

        case 'MetricCategoriesActions.ON_GETALL':
            return { ...state, metricCategories: metricCategories(state.metricCategories, action) };

        case 'WorshipServiceMetricsActions.ON_GETALL':
        case 'WorshipServiceMetricsActions.ON_ADD_METRIC':
        case 'WorshipServiceMetricsActions.ON_CHANGE_METRIC_CATEGORY':
        case 'WorshipServiceMetricsActions.ON_REMOVE_METRIC':
        case 'WorshipServiceMetricsActions.ON_REMOVE_ALL_METRICS_FOR_VENUE':
        case 'WorshipServiceMetricsActions.ON_CHANGE_METRIC_AGE_GROUP':
            return { ...state, worshipServiceMetrics: worshipServiceMetrics(state.worshipServiceMetrics, action) };

        case 'WorshipServiceCategoryActions.ON_GETALL':
            return { ...state, worshipServiceCategories: worshipServiceCategories(state.worshipServiceCategories, action) };

        case 'WorshipServicesActions.ON_CLEARSERVICECATEGORY':
        case 'WorshipServicesActions.ON_GETSERVICECATEGORY':
            return { ...state, worshipService: worshipService(state.worshipService, action) };

        case 'WorshipServicesActions.ON_CLEARSEARCHRESULTS':
            return { ...state, worshipServiceSearch: worshipServiceSearch(state.worshipService, action) };
    }

    return state;
}
