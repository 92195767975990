import {
    Box,
    Grid,
    DatePickerInput,
} from '@saddlebackchurch/react-cm-ui';
import {
    capitalize,
    isString,
} from 'lodash';
import {
    Controller,
} from 'react-hook-form';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import moment from 'moment-timezone';
import React from 'react';

type PropTypes = {
    control: any,
    dateFromDataTestId?: string | null,
    dateFromId?: string | null;
    dateFromLabel?: string | null;
    dateFromName: string;
    dateToDataTestId?: string | null,
    dateToId?: string | null;
    dateToLabel?: string | null;
    dateToName: string;
    infoMessagePrefix?: string | null;
    maxDate?: string | null;
    minDate?: string | null;
    rules?: {
        required?: boolean,
    },
};

const useStyles = makeStyles(({
    palette,
    spacing,
    typography,
}) => ({
    infoMessage: {
        backgroundColor: palette.hexToRGBA(palette.sky[500], 0.15),
        fontSize: typography.pxToRem(14),
        padding: spacing(1),
    },
}));

function FormFieldDatePickerInputRange({
    control,
    dateFromDataTestId = null,
    dateFromId = null,
    dateFromLabel = null,
    dateFromName,
    dateToDataTestId = null,
    dateToId = null,
    dateToLabel = null,
    dateToName,
    infoMessagePrefix = null,
    maxDate = null,
    minDate = null,
    rules = {},
}: PropTypes) {
    const [dates, setDates] = React.useState<{ dateFrom: object, dateTo: object }>({
        dateFrom: null,
        dateTo: null,
    });

    const dateToRef = React.useRef<any>();

    const formattedDateFromLabel = dateFromLabel ?
        dateFromLabel.replace(/\w+/g, capitalize) :
        null;

    const formattedDateToLabel = dateToLabel ?
        dateToLabel.replace(/\w+/g, capitalize) :
        null;

    const classes = useStyles();

    const momentMaxDate = maxDate ? moment(maxDate) : null;
    const momentMinDate = minDate ? moment(minDate) : null;

    const onDateFromChange = (onChange, { dateFrom, dateTo }) => {
        onChange(dateFrom);

        dateToRef.current.inputElement.focus();

        setDates({
            dateFrom,
            dateTo,
        });
    };

    const onDateToChange = (onChange, { dateFrom, dateTo }) => {
        onChange(dateTo);

        setDates({
            dateFrom,
            dateTo,
        });
    };

    const formattedDateFrom = moment(dates.dateFrom).format('L');
    const formattedDateTo = moment(dates.dateTo).format('L');

    return (
        <React.Fragment>
            <Grid.Column
                md={6}
                sm={12}
            >
                <Controller
                    control={control}
                    name={dateFromName}
                    render={({
                        field: {
                            onChange,
                            value: controllerValue,
                        },
                    }) => {
                        let value: object | null = null;

                        if (dates.dateFrom) {
                            value = dates.dateFrom;
                        } else if (isString(controllerValue)) {
                            value = moment(controllerValue);
                        }

                        return (
                            <DatePickerInput
                                dataTestId={dateFromDataTestId}
                                dateFrom={value}
                                dateTo={dates.dateTo}
                                fluid
                                id={dateFromId}
                                label={formattedDateFromLabel}
                                maxDate={momentMaxDate}
                                minDate={momentMinDate}
                                onChange={(dateValues) => onDateFromChange(onChange, dateValues)}
                                rangeFrom
                                required={rules?.required}
                                tabIndex={0}
                            />
                        );
                    }}
                    rules={rules}
                />
            </Grid.Column>

            <Grid.Column
                md={6}
                sm={12}
            >
                <Controller
                    control={control}
                    name={dateToName}
                    render={({
                        field: {
                            onChange,
                            value: controllerValue,
                        },
                    }) => {
                        let value: object | null = null;

                        if (dates.dateTo) {
                            value = dates.dateTo;
                        } else if (isString(controllerValue)) {
                            value = moment(controllerValue);
                        }

                        return (
                            <DatePickerInput
                                dataTestId={dateToDataTestId}
                                dateFrom={dates.dateFrom}
                                dateTo={value}
                                fluid
                                id={dateToId}
                                label={formattedDateToLabel}
                                maxDate={momentMaxDate}
                                minDate={momentMinDate}
                                onChange={(dateValues) => onDateToChange(onChange, dateValues)}
                                rangeTo
                                ref={dateToRef}
                                required={rules?.required}
                                tabIndex={0}
                            />
                        );
                    }}
                    rules={rules}
                />
            </Grid.Column>

            {infoMessagePrefix && dates.dateFrom && dates.dateTo ? (
                <Grid.Column>
                    <div
                        className={classes.infoMessage}
                    >
                        {`${infoMessagePrefix} `}

                        <Box
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {`${formattedDateFrom} `}
                        </Box>

                        to

                        <Box
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {` ${formattedDateTo}`}
                        </Box>
                    </div>
                </Grid.Column>
            ) : null}
        </React.Fragment>
    );
}

export default FormFieldDatePickerInputRange;
