import { addOrReplaceBy } from '../../../global/utils';

const DEFAULT_STATE = {
    isFetching: false,
    list: [],
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'MinistryMemberActions.ON_BEFORE_GETMEMBERNOTES':
            return { ...state, isFetching: true };

        case 'MinistryMemberActions.ON_GETMEMBERNOTES':
            return {
                ...state,
                isFetching: false,
                list: actionResult,
            };

        case 'MinistryMemberActions.ON_ADDNOTE':
            return {
                ...state,
                isFetching: false,
                list: [actionResult, ...state.list],
            };

        case 'MinistryMemberActions.ON_UPDATENOTE':
            return {
                ...state,
                isFetching: false,
                list: addOrReplaceBy(
                    state.list,
                    (note) => note.id === actionResult.id,
                    () => actionResult,
                ),
            };

        case 'MinistryMemberActions.ON_RESET_MEMBER_DETAILS':
            return DEFAULT_STATE;

        default:
            // No-op
    }

    return state;
};
