import Utils from '../../../global/utils/utils.js';

const DEFAULT_STATE = {
    isMessageValid: false,
    message: {},
};

const isEmailMessageValid = (emailMessageState) => {
    const {
        body,
        replyEmail,
        senderName,
        subject,
    } = emailMessageState;

    return !Utils.isStringNullOrWhiteSpace(body) &&
        !Utils.isStringNullOrWhiteSpace(replyEmail) &&
        !Utils.isStringNullOrWhiteSpace(senderName) &&
        !Utils.isStringNullOrWhiteSpace(subject);
};

const updateEmailAttachmentsState = (prevState, updatedAttachments) => ({
    ...prevState,
    message: {
        ...prevState.message,
        attachments: updatedAttachments,
    },
});

const updatedEmailMessageState = (prevState, update) => {
    const updatedMessageState = { ...prevState.message, ...update };

    return {
        ...prevState,
        isMessageValid: isEmailMessageValid(updatedMessageState),
        message: updatedMessageState,
    };
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'CommunicationsDrawerActions.ON_UPDATE_EMAIL_FROM':
        {
            const { value: senderName } = action;
            return updatedEmailMessageState(state, { senderName });
        }

        case 'CommunicationsDrawerActions.ON_UPDATE_EMAIL_REPLY_TO':
        {
            const { value: replyEmail } = action;
            return updatedEmailMessageState(state, { replyEmail });
        }

        case 'CommunicationsDrawerActions.ON_UPDATE_EMAIL_SUBJECT':
        {
            const { value: subject } = action;
            return updatedEmailMessageState(state, { subject });
        }

        case 'CommunicationsDrawerActions.ON_UPDATE_EMAIL_BODY':
        {
            const { value: body } = action;
            return updatedEmailMessageState(state, { body });
        }

        case 'CommunicationsDrawerActions.ON_ADD_EMAIL_ATTACHMENT':
        {
            const { value: { file, url } } = action;
            const emailAttachments = { ...state.message.attachments };
            emailAttachments[file] = url;
            return updateEmailAttachmentsState(state, emailAttachments);
        }

        case 'CommunicationsDrawerActions.ON_REMOVE_EMAIL_ATTACHMENT':
        {
            const { value: file } = action;
            const emailAttachments = { ...state.message.attachments };
            delete emailAttachments[file];
            return updateEmailAttachmentsState(state, emailAttachments);
        }

        case 'CommunicationsDrawerActions.ON_UPDATE_SMS_MESSAGE_TEXT':
        {
            const { value: smsMessageText } = action;

            return {
                ...state,
                isMessageValid: !Utils.isStringNullOrWhiteSpace(smsMessageText),
                message: { ...state.message, smsMessageText },
            };
        }

        case 'CommunicationsDrawerActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
