import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';

class RelationshipActions extends ApiUtils {
    resetRelationship() {
        appReduxStore.dispatch({
            type: 'RelationshipActions.ON_RESET_RELATIONSHIP',
        });
    }

    resetRelation() {
        appReduxStore.dispatch({
            type: 'RelationshipActions.ON_RESET_RELATION',
        });
    }
}

const actions = new RelationshipActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'get',
        route: 'person/{personId}/relationship',
        rq: request.get,
    }, {
        name: 'create',
        route: 'person/relationship',
        rq: request.post,
        suppressError: true,
    }, {
        name: 'update',
        route: 'person/relationship/{relationshipId}',
        rq: request.put,
        suppressError: true,
    }, {
        name: 'delete',
        route: 'person/relationship/{relationshipId}',
        rq: request.delete,
    }, {
        name: 'getRelation',
        route: 'person/relation',
        rq: request.get,
    }, {
        name: 'getPersonalDetails',
        route: 'v2/person/{personId}?suppressMergeRedirect=true&expand=address,contactpreference,email,phone,photo,avatar',
        rq: request.get,
    }, {
        name: 'getPersonRelations',
        route: 'relation',
        rq: request.get,
    },
);

export default actions;
