import { get } from 'lodash';
import ActionType from './checkIn.actionTypes';

export const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ActionType.ON_GETVOLUNTEERLIST: {
            const cached = get(action, 'callbackParams.cached', true);
            return cached ? action.result : state;
        }

        case ActionType.ON_BEFORE_GETVOLUNTEERLIST: {
            const cached = get(action, 'callbackParams.cached', true);
            return cached ? DEFAULT_STATE : state;
        }

        case ActionType.ON_FAILED_GETVOLUNTEERLIST: {
            const cached = get(action, 'callbackParams.cached', true);
            return cached ? [] : state;
        }

        case ActionType.ON_RESET_VOLUNTEERLIST:
            return DEFAULT_STATE;

        default:
            return state;
    }
};
