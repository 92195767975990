import request from 'axios';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import {
    cmAuthUtils,
} from '../../../global/utils/utils.js';
import apiConfig from '../../../global/api/apiConfig';

export const actionType = 'RecordActions';
const coreApiHost = apiConfig.get();

export function addChildForCheckIn(person) {
    appReduxStore.dispatch({
        person,
        type: `${actionType}.ON_ADD_CHILD_FOR_CHECK_IN`,
    });
}

export const getInsightsPersonEnagement = ({
    personUniqueId,
    pageNumber,
    pageSize,
}) => new Promise((resolve, reject) => {
    request
        .get(`${coreApiHost}/api/insights/person/${personUniqueId}/engagement?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${cmAuthUtils.getAccessToken()}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            resolve(response);
        })
        .catch(({ response }) => {
            reject(response);
        });
});

export function removeChildForCheckIn(person) {
    appReduxStore.dispatch({
        person,
        type: `${actionType}.ON_REMOVE_CHILD_FOR_CHECK_IN`,
    });
}

export const reset = () => (dispatch) => {
    dispatch({
        type: `${actionType}.ON_RESET`,
    });
};

export function resetCheckIn() {
    appReduxStore.dispatch({
        type: `${actionType}.ON_RESET_CHECK_IN`,
    });
}

export function resetActionBar() {
    appReduxStore.dispatch({
        type: `${actionType}.ON_RESET_ACTION_BAR`,
    });
}

export function resetDetailsWindow() {
    appReduxStore.dispatch({
        type: `${actionType}.ON_RESET_DETAILS_WINDOW`,
    });
}

export function setActionBar(result) {
    appReduxStore.dispatch({
        result,
        type: `${actionType}.ON_SET_ACTION_BAR`,
    });
}

export const setBlockType = (type) => (dispatch) => {
    dispatch({
        result: type,
        type: `${actionType}.ON_SET_BLOCK_TYPE`,
    });
};

export const setCanReadPersonNotes = (canReadPersonNotes) => (dispatch) => {
    dispatch({
        result: canReadPersonNotes,
        type: `${actionType}.ON_SET_CAN_READ_PERSON_NOTES`,
    });
};

export function setDetailsWindow(result) {
    appReduxStore.dispatch({
        result,
        type: `${actionType}.ON_SET_DETAILS_WINDOW`,
    });
}

export function setElements(els = {}) {
    appReduxStore.dispatch({
        result: els,
        type: `${actionType}.ON_SET_ELEMENTS`,
    });
}

export const setLifeStageType = (isAdult, isChild, isStudent) => (dispatch) => {
    let lifeStageType = 'adult';

    if (isChild) {
        lifeStageType = 'child';
    } else if (isStudent) {
        lifeStageType = 'student';
    }

    dispatch({
        result: lifeStageType,
        type: `${actionType}.ON_SET_LIFE_STAGE_TYPE`,
    });
};

export const setIsNotesDrawerOpen = (result) => (dispatch) => {
    dispatch({
        result,
        type: `${actionType}.ON_SET_IS_NOTES_DRAWER_OPEN`,
    });
};

export function setIsNotesDrawerExpanded(result) {
    appReduxStore.dispatch({
        result,
        type: `${actionType}.ON_SET_IS_NOTES_DRAWER_EXPANDED`,
    });
}
