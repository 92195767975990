import { combineReducers } from 'redux';
import coupon from '../../coupon/coupon.reducers';
import installmentPaymentPlans from '../../installmentPaymentPlan/installmentPaymentPlan.reducer';
import ticket from '../../ticket/ticket.reducers';

const reducers = combineReducers({
    coupon,
    installmentPaymentPlans,
    ticket,
});

export default reducers;
