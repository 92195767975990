import '../../scss/components/Modal.scss';

import ClassNames from 'classnames';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React from 'react';
import If from '../../global/if.js';
import i18nUtils from '../../global/i18nUtils.js';

const _i = i18nUtils.translationFactory('Modal');

const appElement = document.getElementById('modal');
Modal.setAppElement(appElement);

export default class ReactModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fullscreen: props.fullscreen || false,
            height: '100%',
            isOpen: false,
            maxHeight: '100%',
            maxWidth: 'none',
            minHeight: 'auto',
            minWidth: '320px',
            outerPadding: '6px',
            width: '100%',
        };

        this._default = {
            height: this.state.height,
            maxHeight: this.state.maxHeight,
            maxWidth: this.state.maxWidth,
            minHeight: this.state.minHeight,
            minWidth: this.state.minWidth,
            outerPadding: this.state.outerPadding,
            width: this.state.width,
        };

        this._closeModal = this._closeModal.bind(this);
        this._onResizeRef = this._onResize.bind(this);
    }

    render() {
        const { overlayClassName, overlayClickClose } = this.props;
        const modalWrapStyles = { padding: this.state.outerPadding };

        const modalContentClass = ClassNames('ReactModal-content', this.props.modalClass);

        const modalContentStyles = {
            height: this.state.height,
            maxHeight: this.state.maxHeight,
            maxWidth: this.state.maxWidth,
            minHeight: this.state.minHeight,
            minWidth: this.state.minWidth,
            width: this.state.width,
        };

        return (
            <Modal
                contentLabel={this.props.title || ''}
                isOpen={this.state.isOpen}
                onRequestClose={this._closeModal}
                overlayClassName={overlayClassName}
                shouldCloseOnOverlayClick={overlayClickClose}
            >
                <div className="ReactModal-container">
                    <div className="ReactModal-content-wrap" style={modalWrapStyles}>
                        <div className={modalContentClass} ref="modalContent" style={modalContentStyles}>
                            <If predicate={this.props.title}>
                                <h2 className="modal-title text-center no-margin-top margin-bottom">{this.props.title}</h2>
                            </If>

                            <If predicate={!this.props.hideClose}>
                                <button
                                    className="old-icon-close"
                                    id="ui_legacy-button--modal-close"
                                    onClick={this._closeModal}
                                    style={{ zIndex: '99999' }}
                                    title={_i('Close')}
                                    type="button"
                                />
                            </If>

                            {this.props.children}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    componentDidMount() {
        window.addEventListener('resize', this._onResizeRef);
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('modal-is-open');
        window.removeEventListener('resize', this._onResizeRef);
    }

    _closeModal() {
        const { onClose } = this.props;

        this.setState({ isOpen: false });

        // If onClose() returns 'true' - do not modify 'modal-is-open' class,
        // it can be needed for other modals
        if (onClose && onClose()) {
            return;
        }

        document.querySelector('body').classList.remove('modal-is-open');
    }

    _onResize() {
        const { fullscreen } = this.state;

        if (window.matchMedia('(min-width: 768px)').matches === true) {
            this.setState({
                height: this.props.height && !fullscreen ? this.props.height : fullscreen ? '100%' : 'auto',
                maxHeight: this.props.maxHeight ? this.props.maxHeight : this._default.maxHeight,
                maxWidth: this.props.maxWidth ? this.props.maxWidth : this._default.maxWidth,
                minHeight: this.props.minHeight ? this.props.minHeight : '305px',
                minWidth: this.props.minWidth ? this.props.minWidth : this._default.minWidth,
                outerPadding: fullscreen ? 0 : this._default.outerPadding,
                width: this.props.width && !fullscreen ? this.props.width : fullscreen ? '100%' : '320px',
            });
        } else {
            this.setState({
                height: this._default.height,
                maxHeight: this._default.maxHeight,
                maxWidth: this._default.maxWidth,
                minHeight: this._default.minHeight,
                minWidth: this._default.minWidth,
                modalWrapHeight: this._default.modalWrapHeight,
                outerPadding: 0,
                width: this._default.width,
            });
        }
    }

    _openModal() {
        this._onResizeRef();

        this.setState({ isOpen: true });

        const bodyElement = document.querySelector('body');

        const classList = ClassNames({
            'modal-is-fullscreen': this.state.fullscreen,
            'modal-is-open': true,
        }).split(' ');

        classList.forEach((className) => bodyElement.classList.add(className));
    }
}

ReactModal.propTypes = {
    fullscreen: PropTypes.bool,
    height: PropTypes.string,
    hideClose: PropTypes.bool,
    maxHeight: PropTypes.string,
    maxWidth: PropTypes.string,
    minHeight: PropTypes.string,
    minWidth: PropTypes.string,
    modalClass: PropTypes.string,
    onClose: PropTypes.func,
    outerPadding: PropTypes.number,
    overlayClassName: PropTypes.string,
    overlayClickClose: PropTypes.bool,
    title: PropTypes.string,
    width: PropTypes.string,
};
