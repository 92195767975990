import {
    forEach,
    map,
    minBy,
    pull,
    size,
} from 'lodash';
import LoginConstants from '../../js/constants/Login/LoginConstants.js';

export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'WarehouseDashboardActions.ON_GETCLASSDATA':
            forEach(action.result, (category) => {
                while (size(category.graphData) > 3) {
                    const minData = minBy(category.graphData, 'name');
                    pull(category.graphData, minData);
                }
            });

            map(action.result, (item) => ({
                ...item,
                detailedChoice: 0,
            }));

            return action.result;

        // Handle Switch Details View User Gesture
        case 'WarehouseDashboardActions.ON_SWITCHDETAILEDVIEW':
            return map(
                state,
                (item) => (item.categoryName === action.categoryName ?
                    { ...item, detailedChoice: action.value } :
                    item),
            );

        default:
            return state;
    }
};
