import { appReduxStore } from './configureReduxStore.js';

const actionType = 'AvatarManagerActions';

export function resetAvatar() {
    appReduxStore.dispatch({
        type: `${actionType}.ON_RESET_AVATAR`,
    });
}

export function updatePersonPhoto(imageInfo) {
    appReduxStore.dispatch({
        type: `${actionType}.ON_UPDATE_AVATAR`,
        value: imageInfo,
    });
}
