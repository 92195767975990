import _ from 'lodash';

export const DEFAULT_STATE = [];

export default function (state = DEFAULT_STATE, action) {
    if (action.type === 'AdminImportDataSourceActions.ON_GETALL') {
        return _.map(action.result, (v) => ({ ...v, value: v.id, label: v.name }));
    }
    return state;
}
