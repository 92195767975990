import _ from 'lodash';
import LoginConstants from '../../constants/Login/LoginConstants';

export const DEFAULT_STATE = {
    allNoteCategories: [],
    savedNoteCategory: {},
    selectedNoteCategory: {},
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
        case 'NoteCategoryActions.RESET':
            return DEFAULT_STATE;
        case 'NoteCategoryActions.ON_GET':
            return { ...state, selectedNoteCategory: action.result };
        case 'NoteCategoryActions.ON_CREATE':
        case 'NoteCategoryActions.ON_UPDATE':
            return { ...state, savedNoteCategory: action.result };
        case 'NoteCategoryActions.ON_GETALL':
            return { ...state, allNoteCategories: action.result };
    }

    return state;
}
