import { connect } from 'react-redux';
import { ModalDeprecated } from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import { get } from 'lodash';
import { theme } from '@saddlebackchurch/react-cm-ui/core/styles';
import {
    resetReminderForm,
    setIsReminderFormModalOpen,
    setSelectedReminder,
    ResetReminderForm,
    SetIsReminderFormModalOpen,
    SetSelectedReminder,
} from './noteReminderActions';
import { NoteReminderFormConnected as NoteReminderForm } from './noteReminderForm';
import { i18n } from '../../../../global/constants.js';
import { getNoteReminderDueTimeInfo } from './noteRemindersUtils';
import NoteReminderDetail from './noteReminderDetail';
import {
    AddToReminderToNote,
    RemoveReminderFromNote,
} from '../models/PersonNotesDrawer.types';
import {
    NoteReminderOpenSource,
    NoteReminderFormData,
    NoteReminder,
} from './models';
import { PersonNote } from '../models';

interface PropTypes {
    addReminderToNote: AddToReminderToNote;
    hasCreateReminderButtonBeenClicked: boolean;
    isReminderFormModalOpen: boolean;
    reminderFormData: NoteReminder & NoteReminderFormData;
    reminderModalOpenFrom: NoteReminderOpenSource;
    removeReminderFromNote: RemoveReminderFromNote;
    resetReminderForm: ResetReminderForm;
    setIsReminderFormModalOpen: SetIsReminderFormModalOpen;
    selectedReminder: NoteReminder & NoteReminderFormData;
    selectedNote: PersonNote;
    setSelectedReminder: SetSelectedReminder;
}

const mapStateToProps = (state) => {
    const {
        people: {
            record: {
                noteForm: {
                    hasCreateReminderButtonBeenClicked,
                    isReminderFormModalOpen,
                    reminderFormData,
                    reminderModalOpenFrom,
                    selectedReminder,
                    selectedNote,
                },
            },
        },
    } = state;

    return {
        hasCreateReminderButtonBeenClicked,
        isReminderFormModalOpen,
        reminderFormData,
        reminderModalOpenFrom,
        selectedReminder,
        selectedNote,
    };
};

const mapDispatchToProps = {
    resetReminderForm,
    setIsReminderFormModalOpen,
    setSelectedReminder,
};

export function NoteReminderModal(props: PropTypes) {
    const {
        addReminderToNote,
        hasCreateReminderButtonBeenClicked,
        isReminderFormModalOpen,
        reminderFormData,
        reminderModalOpenFrom,
        removeReminderFromNote,
        resetReminderForm: resetReminderFormAction,
        selectedNote,
        selectedReminder,
        setIsReminderFormModalOpen: setIsReminderFormModalOpenAction,
        setSelectedReminder: setSelectedReminderAction,
    } = props;

    const onCancelReminderClick = () => {
        if (reminderModalOpenFrom === NoteReminderOpenSource.OpenFromNoteForm) {
            resetReminderFormAction();
        } else if (
            reminderModalOpenFrom === NoteReminderOpenSource.OpenFromNoteDetail ||
            reminderModalOpenFrom === NoteReminderOpenSource.OpenFromNoteFormEdit
        ) {
            resetReminderFormAction();
            setSelectedReminderAction();
            removeReminderFromNote(selectedNote, selectedReminder);
        }
    };

    const onCloseModal = () => {
        setIsReminderFormModalOpenAction(false);
    };

    let modalContent;
    let modalTitle;
    let closeButton: boolean | string = false;
    let onClose = () => {};
    const reminderData = reminderFormData || selectedReminder;
    const reminderDueTimeInfo = getNoteReminderDueTimeInfo(reminderData);
    const dueDate = get(reminderData, 'dueDate');
    const shouldRenderReminderForm =
        (reminderModalOpenFrom === NoteReminderOpenSource.OpenFromNoteForm &&
            !hasCreateReminderButtonBeenClicked) ||
        ((reminderModalOpenFrom === NoteReminderOpenSource.OpenFromNoteDetail ||
                reminderModalOpenFrom === NoteReminderOpenSource.OpenFromNoteFormEdit) &&
            (!dueDate && !hasCreateReminderButtonBeenClicked));

    if (shouldRenderReminderForm) {
        modalTitle = i18n('New Reminder');
        modalContent = (
            <NoteReminderForm
                isMobile={false}
                onCancelReminderClick={onCancelReminderClick}
                removeReminderFromNote={removeReminderFromNote}
                addReminderToNote={addReminderToNote}
            />
        );
    } else {
        modalTitle = i18n('My Reminder');
        onClose = onCloseModal;
        closeButton = 'close';
        modalContent = (
            <NoteReminderDetail
                dueDate={dueDate || reminderDueTimeInfo.dueDate}
                onCancelReminderClick={onCancelReminderClick}
            />
        );
    }

    const modalPlaceHolderHeight = shouldRenderReminderForm && isReminderFormModalOpen ? 306 : 110;
    const modalPlaceholder = (
        <div
            style={{
                height: modalPlaceHolderHeight,
            }}
        />
    );

    return (
        <ModalDeprecated
            closeButton={closeButton}
            isOpen={isReminderFormModalOpen}
            onClose={onClose}
            title={modalTitle}
            autoHeight
            minHeight="auto"
            height="auto"
            headerStyle={{
                zIndex: 100,
                backgroundColor: get(theme, 'palette.common.white'),
            }}
            style={{
                width: 500,
            }}
        >
            {isReminderFormModalOpen && modalContent}
            {!isReminderFormModalOpen && modalPlaceholder}
        </ModalDeprecated>
    );
}

export const NoteReminderModalConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NoteReminderModal) as any;
