import {
    forEach,
    get,
    map,
} from 'lodash';
import moment from 'moment-timezone';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';
import LoginConstants from '../../constants/Login/LoginConstants.js';
import Utils from '../../../global/utils/utils.js';

const RESET = 'OccurrenceActionsClass.RESET';
const GET_BY_DATE_RANGE = 'OccurrenceActionsClass.ON_GETBYDATERANGE';
const GET_OCCURRENCE_BY_DATE = 'OccurrenceActionsClass.ON_GETOCCURRENCEBYDATE';
const GET_OCCURRENCE_BY_ID = 'OccurrenceActionsClass.ON_GETOCCURRENCEBYID';
const GET_OCCURRENCES = 'OccurrenceActionsClass.ON_GETOCCURRENCES';
const GET_NEXT_OCCURRENCE = 'OccurrenceActionsClass.ON_GETNEXTOCCURRENCE';
const GET_NEXT_CURRENT_PREVIOUS_OCCURRENCE = 'OccurrenceActionsClass.ON_GETNEXTCURRENTPREVIOUSOCCURRENCE';
const ON_GETOCCURRENCES_NEXTPAGE = 'OccurrenceActionsClass.ON_GETOCCURRENCES_NEXTPAGE';

const pagedOccurrences = new ChunkedPaginationUtils(25, 50);

const DEFAULT_STATE = {
    // next / current / previous occurrence
    nextOccurrence: null,
    currentOccurrence: null,
    previousOccurrence: null,

    // specific occurrence requested
    occurrence: null,

    // occurrences for select options
    occurrenceSelectOptions: [],

    // paginated occurrences for 'search & browse' type functionality
    occurrences: [],
    occurrencesCanLoadMore: false,
    occurrencesNeedsToRequest: false,
    occurrencesPageNumber: 0,
    occurrencesPageSize: pagedOccurrences.getPageSize(),
    occurrencesTotal: 0,
};

function enrichOccurrences(occurrences) {
    forEach(occurrences, (evt) => {
        /* eslint-disable no-param-reassign */
        evt.id = evt.id || -Utils.getIncreasingUniqueKey();
        const evtStartTimeMoment = moment
            .unix(evt.startDateTime)
            .utc()
            .tz(evt.timeZone);
        evt.name = evt.name || evtStartTimeMoment.format('ddd L LT z');
        /* eslint-enable no-param-reassign */
    });

    return occurrences;
}

function selectBoxTransform(occurrences) {
    return map(occurrences, (evt) => ({
        label: evt.name, // required props for drop-down options
        scheduleId: evt.scheduleId,
        startDateTime: evt.startDateTime,
        value: evt.id, // required props for drop-down options
    }));
}

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
        case RESET: // reset when we're told too (e.g. on a consuming component's unmount)
            return DEFAULT_STATE;

        case GET_BY_DATE_RANGE: {
            const occurrences = enrichOccurrences(action.result);
            return {
                ...state,
                occurrenceSelectOptions: selectBoxTransform(occurrences),
                occurrences,
            };
        }

        case GET_OCCURRENCES: {
            const first = get(action, 'callbackParams.first', true);

            const occurrences = enrichOccurrences(action.result);
            pagedOccurrences.loadPage(occurrences, 0, first);

            const newState = {
                occurrenceSelectOptions: selectBoxTransform(occurrences),
                occurrences: pagedOccurrences.getAll(true),
                occurrencesCanLoadMore: pagedOccurrences.canLoadMore(),
                occurrencesNeedsToRequest: pagedOccurrences.needsToLoadPage(),
                occurrencesPageNumber: pagedOccurrences.getCurrentPageNumber(),
                occurrencesPageSize: pagedOccurrences.getPageSize(),
                occurrencesTotal: pagedOccurrences.getTotalCount(),
            };

            return { ...state, ...newState };
        }

        case GET_OCCURRENCE_BY_DATE:
        case GET_OCCURRENCE_BY_ID:
            return {
                ...state,
                occurrence: action.result,
            };

        case GET_NEXT_OCCURRENCE:
            return {
                ...state,
                nextOccurrence: action.result,
            };

        case GET_NEXT_CURRENT_PREVIOUS_OCCURRENCE:
            return {
                ...state,
                nextOccurrence: action.result.next,
                currentOccurrence: action.result.current,
                previousOccurrence: action.result.previous,
            };

        case ON_GETOCCURRENCES_NEXTPAGE: {
            const newState = {
                occurrences: pagedOccurrences.getAll(true),
                occurrencesCanLoadMore: pagedOccurrences.canLoadMore(),
                occurrencesNeedsToRequest: pagedOccurrences.needsToLoadPage(),
                occurrencesPageNumber: pagedOccurrences.getCurrentPageNumber(),
                occurrencesPageSize: pagedOccurrences.getPageSize(),
                occurrencesTotal: pagedOccurrences.getTotalCount(),
            };

            return { ...state, ...newState };
        }

        default:
            return state;
    }
}
