import './addPersonRecordDrawer.scss';

import {
    Button,
    Drawer,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from '../../../global/i18nUtils.js';
import { setIsPossibleDuplicatesDrawerOpen } from './possibleDuplicatesAddPersonActions.js';
import { onSavePerson as onSavePersonGlobal } from './addPersonV2Utils.js';
import AddPersonV2 from './addPersonV2.jsx';

const propTypes = {
    isMobile: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    isPersonSaveDisabled: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    possibleDuplicatesList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    recordType: PropTypes.oneOf(['adult', 'child', 'student']),
    setIsPossibleDuplicatesDrawerOpen: PropTypes.func.isRequired,
};

const defaultProps = {
    isOpen: false,
    possibleDuplicatesList: [],
    recordType: 'adult',
};

const contextTypes = {
    router: PropTypes.shape({
        getCurrentLocation: PropTypes.func,
    }),
};

const i18n = translationFactory();

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            isMobile,
        },
        people: {
            record: {
                personForm: {
                    isPersonSaveDisabled,
                    recordType,
                },
                possibleDuplicatesAddPerson: {
                    data: possibleDuplicatesList,
                },
            },
        },
    } = state;

    return {
        isMobile,
        isPersonSaveDisabled,
        possibleDuplicatesList,
        recordType,
    };
};

const BEM_BLOCK_NAME = 'person_add_drawer';

class AddPersonRecordDrawer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onSavePerson = this.onSavePerson.bind(this);
        this.onPossibleDuplicatesDrawerToggle = this.onPossibleDuplicatesDrawerToggle.bind(this);
    }

    onPossibleDuplicatesDrawerToggle() {
        const {
            setIsPossibleDuplicatesDrawerOpen,
        } = this.props;

        setIsPossibleDuplicatesDrawerOpen(true);
    }

    onSavePerson() { // local method
        const {
            onClose,
        } = this.props;

        const {
            router,
        } = this.context;

        // global method
        onSavePersonGlobal((res) => {
            onClose();
            router.push(`/people/search-or-add/record/${res.id}`);
        });
    }

    render() {
        const {
            isMobile,
            isOpen,
            isPersonSaveDisabled,
            onClose,
            possibleDuplicatesList,
            recordType,
        } = this.props;

        const possibleDuplicatesActionButtonDesktopJSX = (
            <Button
                color="alternate"
                id={`${BEM_BLOCK_NAME}--view_duplicates_btn`}
                outlined
                onClick={this.onPossibleDuplicatesDrawerToggle}
            >
                <span>{i18n('Possible Duplicates')}</span>
            </Button>
        );

        let actionBarColumns;

        if (!isMobile) {
            actionBarColumns = [
                {
                    flexGrow: 1,
                    jsx: <div />,
                },
                !_.isEmpty(possibleDuplicatesList) && {
                    jsx: possibleDuplicatesActionButtonDesktopJSX,
                    style: {
                        paddingRight: 0,
                    },
                }, {
                    button: {
                        color: 'alternate',
                        id: `${BEM_BLOCK_NAME}--cancel_btn`,
                        onClick: onClose,
                        label: 'Cancel',
                    },
                }, {
                    button: {
                        color: 'success',
                        id: `${BEM_BLOCK_NAME}--save_btn`,
                        disabled: isPersonSaveDisabled,
                        label: 'Save',
                        onClick: this.onSavePerson,
                    },
                    style: {
                        paddingLeft: 0,
                    },
                },
            ];
        } else {
            actionBarColumns = [
                {
                    list: [
                        {
                            flexGrow: 1,
                            jsx: (
                                <Button
                                    className={`${BEM_BLOCK_NAME}--close`}
                                    color="alternate"
                                    id={`${BEM_BLOCK_NAME}--close_375`}
                                    icon
                                    onClick={onClose}
                                >
                                    <Icon color="success" type="close" />
                                </Button>
                            ),
                        }, {
                            jsx: (
                                <Button
                                    className={`${BEM_BLOCK_NAME}--save`}
                                    color="success"
                                    disabled={isPersonSaveDisabled}
                                    id={`${BEM_BLOCK_NAME}--save_375`}
                                    icon
                                    onClick={this.onSavePerson}
                                >
                                    <Icon type="success" />
                                </Button>
                            ),
                            divide: false,
                            style: {
                                paddingLeft: 0,
                            },
                        },
                        !_.isEmpty(possibleDuplicatesList) && {
                            actionsButton: {
                                header: 'Actions',
                                options: [
                                    {
                                        iconType: 'users-circle',
                                        id: `${BEM_BLOCK_NAME}--view_duplicates_btn_375`,
                                        label: i18n('View Possible Duplicates'),
                                        onClick: this.onPossibleDuplicatesDrawerToggle,
                                    },
                                ],
                            },
                            divide: false,
                            style: {
                                paddingLeft: 0,
                            },
                        },
                    ],
                },
            ];
        }

        return (
            <React.Fragment>
                <Drawer
                    className={BEM_BLOCK_NAME}
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <Drawer.TitleBar
                        className={`${BEM_BLOCK_NAME}--title_bar`}
                        title={`New ${_.capitalize(recordType)} Record`}
                    />

                    <Drawer.ActionBar
                        className={`${BEM_BLOCK_NAME}--action_bar`}
                        columns={actionBarColumns}
                    />

                    <Drawer.Content>
                        <AddPersonV2 />
                    </Drawer.Content>
                </Drawer>
            </React.Fragment>
        );
    }
}

AddPersonRecordDrawer.propTypes = propTypes;
AddPersonRecordDrawer.contextTypes = contextTypes;
AddPersonRecordDrawer.defaultProps = defaultProps;

export default connect(
    mapStateToProps,
    { setIsPossibleDuplicatesDrawerOpen },
)(AddPersonRecordDrawer);
