import {
    Button,
    Grid,
    MobileStepper,
    Modal,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import useMediaQuery from '@saddlebackchurch/react-cm-ui/core/utils/useMediaQuery';
import useTheme from '@saddlebackchurch/react-cm-ui/core/styles/useTheme';
import {
    BEM_BLOCK_NAME,
    STEP_PROP_TYPE,
} from './constants.js';
import {
    i18n,
} from '../constants.js';

const propTypes = {
    activeStep: PropTypes.number.isRequired,
    maxSteps: PropTypes.number.isRequired,
    onBackClick: PropTypes.func.isRequired,
    onDoneClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    step: STEP_PROP_TYPE.isRequired,
};

const useStyles = makeStyles(({
    breakpoints,
    palette,
    spacing,
}) => ({
    backButton: {
        backgroundColor: palette.grey[400],
    },
    buttons: {
        margin: [[11, 0, 0]],
        '&:first-child': {
            margin: 0,
        },
        [breakpoints.only('sm')]: {
            width: 200,
        },
    },
    callToActionButton: {
        boxShadow: `inset 0 0 0 1px ${palette.grey[400]} !important`,
        color: `${palette.text.primary} !important`,
    },
    mdNextButtonColumn: {
        textAlign: 'right',
    },
    middleColumns: {
        textAlign: 'center',
    },
    mobileStepper: {
        justifyContent: 'center',
        [breakpoints.only('sm')]: {
            paddingTop: 0,
            top: spacing(2),
        },
        [breakpoints.up('md')]: {
            paddingTop: 14,
        },
    },
    mobileStepperColumn: {
        minHeight: 30,
    },
    root: {
        margin: [[spacing(5), 0, 0]],
        [breakpoints.up('md')]: {
            margin: [[spacing(5), 0, -30]],
        },
    },
}));

function EducationalDialogActions(props) {
    const {
        activeStep,
        maxSteps,
        onBackClick,
        onDoneClick,
        onNextClick,
        step,
    } = props;

    // Hooks
    const classes = useStyles(props);
    const theme = useTheme();
    const isMediumBp = useMediaQuery(theme.breakpoints.up('md'));

    // Component Constants
    const isFirstStep = activeStep === 0;
    const isLastStep = activeStep + 1 === maxSteps;

    const onCallToActionClick = () => {
        if (step.callToAction?.url) {
            window.open(step.callToAction.url);
        }
    };

    const backButtonNode = !isFirstStep ? (
        <Button
            classes={{
                root: ClassNames(
                    classes.buttons,
                    classes.backButton,
                ),
            }}
            id={`${BEM_BLOCK_NAME}--back_button`}
            onClick={onBackClick}
        >
            {i18n('Back')}
        </Button>
    ) : null;

    const callToActionButtonNode = (
        <Button
            classes={{
                root: classes.buttons,
                outlined: classes.callToActionButton,
            }}
            id={`${BEM_BLOCK_NAME}--call_to_action_button`}
            onClick={onCallToActionClick}
            outlined
        >
            {step.callToAction?.label || i18n('Learn More')}
        </Button>
    );

    const nextButtonNode = ({ width } = { width: null }) => (
        <Button
            color={isLastStep ? 'success' : 'primary'}
            classes={{
                root: classes.buttons,
            }}
            id={`${BEM_BLOCK_NAME}--next_button`}
            onClick={onNextClick}
            width={width}
        >
            {i18n('Next')}
        </Button>
    );

    const doneButtonNode = (
        <Button
            color={isLastStep ? 'success' : 'primary'}
            classes={{
                root: classes.buttons,
            }}
            id={`${BEM_BLOCK_NAME}--done_button`}
            onClick={onDoneClick}
        >
            {(step.doneLabel && i18n(step.doneLabel)) || i18n('Got It!')}
        </Button>
    );

    const mobileStepperNode = (
        <MobileStepper
            activeStep={activeStep}
            classes={{
                root: classes.mobileStepper,
            }}
            id={`${BEM_BLOCK_NAME}--mobile_stepper`}
            steps={maxSteps}
            position={isMediumBp ? 'static' : 'top'}
        />
    );

    return (
        <Modal.Actions
            alignItems={isMediumBp ? null : 'center'}
            classes={{
                root: classes.root,
            }}
            direction={isMediumBp ? null : 'column'}
        >
            {!isMediumBp && (
                <React.Fragment>
                    {step.callToAction?.url ? callToActionButtonNode : null}

                    {backButtonNode}

                    {!isLastStep ? nextButtonNode() : null}

                    {isLastStep ? doneButtonNode : null}

                    {maxSteps > 1 ? mobileStepperNode : null}
                </React.Fragment>
            )}

            {isMediumBp && (
                <Grid>
                    <Grid.Column
                        sm
                    >
                        {backButtonNode}
                    </Grid.Column>

                    <Grid.Column
                        classes={{
                            root: classes.middleColumns,
                        }}
                        sm
                    >
                        {/**
                         * Show next button here only if it's the first step and there's
                         * not a call to action url.
                         */}

                        {step.callToAction?.url && callToActionButtonNode}

                        {
                            !step.callToAction?.url &&
                            isFirstStep &&
                            nextButtonNode({ width: 200 })
                        }
                    </Grid.Column>

                    <Grid.Column
                        classes={{
                            root: classes.mdNextButtonColumn,
                        }}
                        sm
                    >
                        {
                            !isLastStep && (
                                (isFirstStep && step.callToAction?.url) ||
                                !isFirstStep
                            ) ?
                                nextButtonNode() : null
                        }

                        {isLastStep ? doneButtonNode : null}
                    </Grid.Column>

                    <Grid.Column
                        classes={{
                            root: ClassNames(
                                classes.middleColumns,
                                classes.mobileStepperColumn,
                            ),
                        }}
                        sm={12}
                    >
                        {maxSteps > 1 ? mobileStepperNode : null}
                    </Grid.Column>
                </Grid>
            )}
        </Modal.Actions>
    );
}

EducationalDialogActions.propTypes = propTypes;

export default EducationalDialogActions;
