import { keys } from 'lodash';
import keyMirror from 'keymirror';
import PropTypes from 'prop-types';

// See WorkflowConditionType.cs
// Using the _Order_ for the integer here (versus the numeric sequential _ID_)
export const CONDITION_TYPE = keyMirror({
    RelatedRecordAdded: 1,
    RelatedRecordEdited: 2,
    RelatedRecordDeleted: 3,
    HasValue: 4,
    DoesNotHaveValue: 5,
    IsEqualTo: 6,
    IsNotEqualTo: 7,
    Contains: 8,
    DoesNotContain: 9,
    IsSelected: 10,
    IsNotSelected: 11,
    IsLessThan: 12,
    IsLessThanOrEqualTo: 13,
    IsGreaterThan: 14,
    IsGreaterThanOrEqualTo: 15,
    IsBetween: 16,
    IsTrue: 17,
    IsFalse: 18,
});

export const CONDITION_TYPE_PROP_TYPE = PropTypes.oneOf(keys(CONDITION_TYPE));
