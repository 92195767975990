import { combineReducers } from 'redux';
import emergencyContact from './emergencyContact/emergencyContactReducer.js';
import record from './record/recordReducers.js';
import search from './search/global/peopleSearchReducer.js';
import searchV2 from './search/search.reducers.js';
import addPerson from './global/addPersonReducers.js';
import settings from './settings/settings.reducers.js';

const reducers = combineReducers({
    addPerson,
    emergencyContact,
    record,
    search,
    searchV2,
    settings,
});

export default reducers;
