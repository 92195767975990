import './quickNotification.scss';

import {
    Box,
    Button,
    Drawer,
    Icon,
    List,
    Select,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import withStyles from '@saddlebackchurch/react-cm-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { isCurrentSourceTypeSupported } from '../global/notificationCenterUtils.js';
import {
    i18n,
} from '../global/constants.js';
import LoadMoreTrigger from '../js/components/loadMoreTrigger.jsx';
import NotificationCenterDetailActions from './notificationCenterDetailActions.js';
import QuickNotificationActions from './quickNotificationActions.js';
import SelectionCustomComponent from './selectionCustomComponent.jsx';

const BEM_BLOCK_NAME = 'quick_notification';

const filterOptions = [
    {
        filterKeywords: null,
        key: 'total',
        label: 'All Notifications',
        value: '1',
    }, {
        filterKeywords: 'Workflow',
        icon: 'workflow',
        key: 'workflow',
        label: 'Follow Ups',
        value: '2',
    }, {
        filterKeywords: 'Ministry',
        icon: 'ministry',
        key: 'ministry',
        label: 'Ministry',
        value: '3',
    }, {
        filterKeywords: 'Events',
        icon: 'calendar',
        key: 'events',
        label: 'Events',
        value: '4',
    }, {
        filterKeywords: 'Discipleship',
        icon: 'book-open',
        key: 'discipleship',
        label: 'Discipleship',
        value: '5',
    },
];
const sourceCategory = [
    {
        key: 'workflow',
        label: 'Follow Ups',
        value: 'Workflow',
    }, {
        key: 'ministry',
        label: 'Ministry',
        value: 'Ministry',

    }, {
        key: 'events',
        label: 'Events',
        value: 'Events',

    }, {
        key: 'discipleship',
        label: 'Discipleship',
        value: 'Discipleship',
    }, {
        key: 'system',
        label: 'Other',
        value: 'System',
    },
];

const propTypes = {
    classes: PropTypes.shape({
        drawerActionBar: PropTypes.string,
        headerButtons: PropTypes.string,
        titleBar: PropTypes.string,
    }).isRequired,
    hideNotificationDetailsWing: PropTypes.func,
    isWingOpen: PropTypes.bool,
    onCloseDrawer: PropTypes.func,
    onWingToggle: PropTypes.func,
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: { userIdentity },
        },
        breakpoint,
        quickNotifications: {
            canLoadMore,
            counts,
            isFetching,
            needsToRequest,
            notifications,
            pageNumber,
            pageSize,
        },
    } = state;

    const currentContextUserPersonId = userIdentity.getPersonId();

    _.map(notifications, (item, index) => {
        _.each(item.recipients, (data) => {
            if (data.personId === currentContextUserPersonId) {
                item.currentRecipent = data;
            }
        });

        item.formattedDate = moment.unix(item.createDate).format('dddd MMMM Do');
        item.index = index;
        return item;
    });

    const groupedNotification = _.groupBy(notifications, 'formattedDate');

    return {
        breakpoint,
        notifications,
        groupedNotification,
        pageNumber,
        pageSize,
        canLoadMore,
        needsToRequest,
        isFetching,
        counts,
    };
};

const styles = ({ palette }) => ({
    drawerActionBar: {
        '& > div': {
            backgroundColor: palette.background.primary,
        },
    },
    headerButtons: {
        padding: [[0, 14]],
    },
    titleBar: {
        height: [206, '!important'],
    },
});

export class QuickNotification extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: null,
            activeLoadMore: true,
            viewedNotificationList: [],
            isNotificationFilterDrawerOpen: false,
            sourceCategoryId: filterOptions[0].value,
        };

        this.clearAllNotification = this.clearAllNotification.bind(this);
        this.markAllAsRead = this.markAllAsRead.bind(this);
        this.clearNotificationByDate = this.clearNotificationByDate.bind(this);
        this.onLoadMoreNotificaion = this.onLoadMoreNotificaion.bind(this);
        this.onNavigateToNotificationCenter = this.onNavigateToNotificationCenter.bind(this);
        this.onSourceCategoryChange = this.onSourceCategoryChange.bind(this);
        this.setActiveNotificationIndex = this.setActiveNotificationIndex.bind(this);
    }

    componentDidMount() {
        this.requestNotification(true);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.activeLoadMore && !this.props.isFetching) {
            this.setState({ activeLoadMore: true });
        }

        if (!_.isEqual(prevProps.notifications, this.props.notifications)) {
            const viewedNotificationList = [];
            _.each(this.props.notifications, (item) => {
                if (item.currentRecipent.isViewed) {
                    viewedNotificationList.push(item.id);
                }
            });

            this.setState({
                viewedNotificationList:
                    this.state.viewedNotificationList.concat(viewedNotificationList),
            });
        }
    }

    onNavigateToNotificationCenter() {
        const {
            onCloseDrawer,
            router,
        } = this.props;

        router.push('/my-dashboard/notifications');

        onCloseDrawer();
    }

    onSourceCategoryChange(selectedOptions) {
        this.setState({ sourceCategoryId: selectedOptions.value });
        this.requestNotification(true, selectedOptions.filterKeywords);
    }

    onLoadMoreNotificaion() {
        const { canLoadMore, needsToRequest } = this.props;

        if (needsToRequest) {
            this.requestNotification(false).then(() => {
                this.setState({ activeLoadMore: false });
            });
        } else if (canLoadMore) {
            this.setState({ activeLoadMore: false });
            QuickNotificationActions.getNotificationNextPage();
        }
    }

    getIconType(category) {
        let icon = '';

        switch (category) {
            case 'Ministry':
                icon = 'ministry';
                break;

            case 'Workflow':
                icon = 'workflow';
                break;

            case 'Discipleship':
                icon = 'book-open';
                break;

            case 'Events':
                icon = 'calendar';
                break;

            case 'System':
                icon = 'church';
                break;

            default:
                break;
        }

        return icon;
    }

    setActiveNotificationIndex(item, index) {
        const prevActiveIndex = this.state.activeIndex;
        const { viewedNotificationList } = this.state;
        const isIdInList = _.find(viewedNotificationList, (id) => id === item.id);

        this.setState({
            activeIndex: index,
            viewedNotificationList,
        }, () => {
            if (isCurrentSourceTypeSupported(item.sourceType)) {
                NotificationCenterDetailActions.getNotificationDetail({ id: item.id });
            } else {
                NotificationCenterDetailActions.resetNotificationDetail();
            }

            this.props.onWingToggle(item, prevActiveIndex, index);
        });

        if (!isIdInList) {
            viewedNotificationList.push(item.id);

            const postBody = {
                ids: [
                    item.currentRecipent.id,
                ],
                isViewed: true,
            };
            QuickNotificationActions.updateNotificationStatus({}, postBody);
        }
    }

    clearAllNotification() {
        const { notifications } = this.props;
        const ids = [];
        _.each(notifications, (item) => {
            ids.push(item.currentRecipent.id);
        });
        const postBody = {
            ids,
            isViewed: true,
        };
        QuickNotificationActions.updateNotificationStatus({}, postBody).then(() => {
            this.requestNotification(true);
            this.props.hideNotificationDetailsWing();
        });
    }

    clearNotificationByDate(dateKey) {
        const { groupedNotification } = this.props;
        const notifications = groupedNotification[dateKey];
        const ids = [];
        _.each(notifications, (item) => {
            ids.push(item.currentRecipent.id);
        });
        const postBody = {
            ids,
            isViewed: true,
        };
        QuickNotificationActions.updateNotificationStatus({}, postBody).then(() => {
            this.requestNotification(true);
            this.props.hideNotificationDetailsWing();
        });
    }

    clearNotificationById(event, id) {
        event.stopPropagation();
        const ids = [id];
        const postBody = {
            ids,
            isViewed: true,
        };
        QuickNotificationActions.updateNotificationStatus({}, postBody).then(() => {
            this.requestNotification(true);
            this.props.hideNotificationDetailsWing();
        });
    }

    markAllAsRead() {
        const { notifications } = this.props;
        const ids = [];
        _.each(notifications, (item) => {
            if (!item.currentRecipent.isViewed) {
                ids.push(item.currentRecipent.id);
            }
        });
        const postBody = {
            ids,
            isViewed: true,
        };
        QuickNotificationActions.updateNotificationStatus({}, postBody).then(() => {
            this.requestNotification(true);
        });
    }

    requestNotification(first, category) {
        const { breakpoint, pageNumber, pageSize } = this.props;
        const { filters } = this.state;

        if (breakpoint.isMobile) {
            let sortBy;
            let days;
            let isViewed;
            let selectedSourceCategory = null;

            if (filters) {
                sortBy = filters.sortId ? filters.sortId.sortBy : 'createdate,DESC';
                days = filters.timelineId ? filters.timelineId.days : null;
                isViewed = filters.isViewed === 1 ? false : null;
                selectedSourceCategory = filters.selectedSourceCategory.length ? encodeURIComponent(filters.selectedSourceCategory) : '';
            }

            QuickNotificationActions.getUserNotifications({
                pageNumber: first ? 0 : pageNumber + 1,
                pageSize,
                days,
                sortBy,
                category: selectedSourceCategory,
                isViewed,
            }, null, null, { first });
        } else {
            QuickNotificationActions.getUserNotifications({
                pageNumber: first ? 0 : pageNumber + 1,
                pageSize,
                days: 30,
                sortBy: 'createdate,DESC',
                category: category || '',
                isViewed: false,
            }, null, null, { first });
        }
    }

    render() {
        const {
            breakpoint,
            canLoadMore,
            classes,
            counts,
            groupedNotification,
            isFetching,
            isWingOpen,
        } = this.props;

        const {
            activeIndex,
            activeLoadMore,
            sourceCategoryId,
            viewedNotificationList,
        } = this.state;

        if (!_.isEmpty(counts)) {
            _.map(sourceCategory, (item) => {
                item.counts = counts[item.key].total;
            });

            _.map(filterOptions, (item) => {
                item.counts = counts[item.key].total;
            });
        }

        const renderExportNotification = (exportServiceSummary) => {
            if (exportServiceSummary?.length > 0) {
                const parsedSummary = JSON.parse(exportServiceSummary);

                if (parsedSummary.IsSuccessful) {
                    return <span>Your recent download is Ready</span>;
                }

                if (parsedSummary.ErrorMessage) {
                    return (
                        <div>
                            {parsedSummary.ErrorMessage.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    );
                }
            }

            return (
                <span>
                    {'An error has occurred and we were unable to generate the requested export; ' +
                    'we\'re sorry.'}
                </span>
            );
        };

        return (
            <React.Fragment>
                <Drawer.TitleBar
                    className={classes.titleBar}
                    ref={(ref) => {
                        this.drawerHeader = ref;
                    }}
                    title={(
                        <React.Fragment>
                            <Box
                                p={2}
                                pt={1}
                            >
                                {!breakpoint.isMobile ? (
                                    <Typography
                                        variant="h2"
                                    >
                                        {i18n('New Notifications')}
                                    </Typography>
                                ) : null}

                                <Typography
                                    variant="h4"
                                >
                                    {i18n('Past 30 Days')}
                                </Typography>

                                <Box
                                    mt={2}
                                >
                                    <Button
                                        className={classes.headerButtons}
                                        color="primary"
                                        designVersion={2}
                                        disable={_.isEmpty(groupedNotification)}
                                        onClick={this.clearAllNotification}
                                        tabIndex={0}
                                    >
                                        {i18n('Clear All')}
                                    </Button>

                                    <Button
                                        color="primary"
                                        designVersion={2}
                                        onClick={this.onNavigateToNotificationCenter}
                                        tabIndex={0}
                                        variant="outlined"
                                    >
                                        {i18n('Notification Center')}
                                    </Button>
                                </Box>
                            </Box>

                            <Box
                                border={1}
                                borderColor="border.primary"
                                borderLeft={0}
                                borderRight={0}
                                px={2}
                                py={1}
                            >
                                <Select
                                    className="notification_filter"
                                    clearable={false}
                                    fluid
                                    id={`${BEM_BLOCK_NAME}--source_category_select`}
                                    onChange={this.onSourceCategoryChange}
                                    options={filterOptions}
                                    optionComponent={SelectionCustomComponent}
                                    value={sourceCategoryId}
                                />
                            </Box>
                        </React.Fragment>
                    )}
                />
                <div className="notification-list">
                    {_.isEmpty(groupedNotification) && !isFetching ? (
                        <span className="text-semibold empty-notifications">
                            {i18n('No New Notifications')}
                        </span>
                    ) : _.map(groupedNotification, (notification, date) => (
                        <div key={date}>
                            <div className="notification-list-header">
                                <div className="date-wrapper">
                                    <span className="first-word font-size-small font-weight-bold">
                                        {' '}
                                        {date.replace(/ .*/, '')}
                                    </span>
                                    <span className="font-size-small">
                                        {' '}
                                        {date.substr(date.indexOf(' ') + 1)}
                                    </span>
                                </div>

                                {!breakpoint.isMobile ? (
                                    <span
                                        className="color-static font-size-small"
                                        onClick={() => this.clearNotificationByDate(date)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {i18n('Clear')}
                                    </span>
                                ) : null}
                            </div>

                            <List as="div" divide>
                                {_.map(notification, (item, index) => {
                                    const isViewed = _.find(
                                        viewedNotificationList,
                                        (id) => id === item.id,
                                    );

                                    return (
                                        <List.Item
                                            className={activeIndex === item.index ? 'active' : ''}
                                            key={index + item.summary}
                                            onClick={() => (
                                                this.setActiveNotificationIndex(item, item.index)
                                            )}
                                            style={{ padding: 22 }}
                                        >
                                            <div className="list-item-wrapper">
                                                <div className={`notfication-type-icons ${!isViewed ? 'is-not-viewed' : 'is-viewed'}`}>
                                                    <Icon color={isViewed ? 'static' : 'highlight'} size={24} type={this.getIconType(item.category)} />
                                                </div>

                                                <div className="notification-content">
                                                    <span className={`font-size-xsmall ${!isViewed ? 'font-weight-bold' : ''}`}>
                                                        {item.sourceType !== 'ExportService' ? item.summary : renderExportNotification(item.summary)}
                                                    </span>
                                                    <div className="notification-info">
                                                        <span
                                                            className="font-size-xxsmall color-static text-semibold"
                                                        >
                                                            {item.senderContact && item.senderContact.senderName ? item.senderContact.senderName : 'Healthy Church'}
                                                        </span>

                                                        <span
                                                            className="notification-info-time font-size-xxsmall color-static text-semibold"
                                                            style={{ marginLeft: 10 }}
                                                        >
                                                            {moment.unix(item.createDate, 'YYYYMMDD').fromNow()}
                                                        </span>
                                                    </div>
                                                </div>

                                                {!isWingOpen ||
                                                    (isWingOpen && activeIndex !== item.index) ? (
                                                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                                        <div
                                                            className="notification-clear-icon"
                                                            onClick={(e) => (
                                                                this.clearNotificationById(
                                                                    e,
                                                                    item.currentRecipent.id,
                                                                )
                                                            )}
                                                        >
                                                            <Icon
                                                                color="static"
                                                                size="medium"
                                                                type="times-circle"
                                                            />
                                                        </div>
                                                    ) : null}
                                            </div>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        </div>
                    ))}

                    {canLoadMore ? (
                        <div style={{ textAlign: 'center' }}>
                            <LoadMoreTrigger
                                active={activeLoadMore}
                                offset={{ bottom: -150 }}
                                onChange={this.onLoadMoreNotificaion}
                            />
                        </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

QuickNotification.propTypes = propTypes;

export default withRouter(
    withStyles(styles, { withTheme: true })(
        connect(mapStateToProps)(QuickNotification),
    ),
);
