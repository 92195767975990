import {
    isNil,
} from 'lodash';
import {
    REPEAT_NONE,
} from '../eventCustomScheduleUtils.js';
import {
    WHO_MINISTRY,
} from '../eventDetailsDrawer/constants.js';
import {
    IMAGE_GROUPS_CONFIG,
} from '../../../global/constants.js';
import ActionType from './eventBasicInfoSection/eventBasicInfo.actionTypes';
import EventDetailActionType from './eventDetailSection/eventDetailSection.actionTypes';
import EventLocationOption from './eventLocationOption';
import EventRegistrationActionType from './eventRegistrationSection/eventRegistrationInfo.actionTypes';
import EventPublishActionType from './eventPublishSection/eventPublishSection.actionTypes';

export const DEFAULT_STATE = {
    event: {
        allowGuestRegistration: false,
        allowGroupRegistration: false,
        canRegister: false,
        capacity: null,
        churchEntityId: null,
        churchEntityName: '',
        companionAppHeader: null,
        customRegistrationNotificationSettings: null,
        description: '',
        eventCategoryId: null,
        eventContactIds: [],
        hostMinistryId: null,
        id: null,
        isDuplicateServingOpps: false,
        isPaid: false,
        isRegistrationClosed: false,
        isVirtual: false,
        maxGroupPartySize: 1,
        name: '',
        publishedContactName: '',
        publishedEmail: '',
        publishedPhone: '',
        registrationEndDate: null,
        registrationStartDate: null,
        schedule: null,
        scheduleId: null,
        status: '',
        subVenues: null,
        tagIds: [],
        thumbnail: null,
        venues: [],
        virtualUrl: null,
        webHeader: null,
    },
    campusToChange: null,
    canRegisterOnEvent: false,
    customOptions: [],
    customQuestions: [],
    endDate: null,
    endTime: null,
    hostMinistry: null,
    isCustomRegistration: false,
    isDirty: false,
    isImageUploadInProgress: false,
    isPrivate: false,
    isPrivateInitialValue: false,
    isPublishedTenantInitialState: false,
    imageUploaderData: IMAGE_GROUPS_CONFIG,
    leaderIds: [],
    leaders: [],
    publishDate: null,
    publishUrl: '',
    repeatings: [],
    repeating: REPEAT_NONE,
    repeatingLabel: '',
    selectedPublishedCampuses: [],
    showPromptToChangeCampus: false,
    startDate: null,
    startTime: null,
    tz: null,
    tzLabel: null,
    venue: {
        capacity: null,
        name: null,
        latitude: null,
        longitude: null,
        privateAddress: false,
        venueAddress: {
            country: 'USA',
        },
        venueCapacity: null,
    },
    whereCampus: null,
    whereSelected: EventLocationOption.OnCampus,
    whereVenues: [],
    whoSelected: WHO_MINISTRY,
};

const updateEventAttribute = (state, objWithUpdate) => {
    const { event } = state;
    const updatedEvent = { ...event, ...objWithUpdate };

    return {
        ...state,
        isDirty: true,
        event: updatedEvent,
    };
};

export default (
    state: any,
    action: any,
) => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            // Start Event Type
            case ActionType.SET_EVENT_TYPE: {
                const { value: isPaid } = action;

                return updateEventAttribute(state, { isPaid });
            }
            // End Event Type

            // Start Event Hosting Type
            case ActionType.SET_EVENT_CHURCH_ENTITY: {
                const {
                    value: {
                        churchEntityId,
                        churchEntityName,
                    },
                } = action;

                return updateEventAttribute(
                    state,
                    {
                        churchEntityId,
                        churchEntityName,
                    },
                );
            }

            case ActionType.SET_EVENT_CHURCH_ENTITY_NAME: {
                const { value: churchEntityName } = action;

                return updateEventAttribute(state, { churchEntityName });
            }

            case ActionType.SET_EVENT_HOST_MINISTRY: {
                const { value: hostMinistry } = action;

                return {
                    ...state,
                    hostMinistry,
                };
            }

            case ActionType.SET_EVENT_LEADERS: {
                const { value: leaders } = action;

                return {
                    ...state,
                    leaders,
                };
            }

            case ActionType.SET_EVENT_LEADER_IDS: {
                const { value: leaderIds } = action;

                return {
                    ...state,
                    leaderIds,
                };
            }

            case ActionType.SET_EVENT_TIMEZONE: {
                const {
                    value:
                    {
                        tz,
                        tzLabel,
                    },
                } = action;

                return {
                    ...state,
                    tz,
                    tzLabel,
                };
            }

            case ActionType.SET_EVENT_WHO_SELECTED: {
                const { value: whoSelected } = action;

                return {
                    ...state,
                    whoSelected,
                };
            }
            // End Event Hosting Type

            case ActionType.SET_EVENT_CATEGORY_ID: {
                const { value: eventCategoryId } = action;

                return updateEventAttribute(state, { eventCategoryId });
            }

            case ActionType.SET_EVENT_NAME: {
                const { value: name } = action;

                return updateEventAttribute(state, { name });
            }

            // Start Event Organizer
            case ActionType.SET_EVENT_CONTACT_IDS: {
                const { value: eventContactIds } = action;

                return updateEventAttribute(state, { eventContactIds });
            }
            // End Event Organizer

            // Start Event Date & time
            case ActionType.SET_EVENT_CUSTOM_OPTIONS: {
                const { value: customOptions } = action;

                return {
                    ...state,
                    customOptions,
                };
            }

            case ActionType.SET_EVENT_END_DATE: {
                const { value: endDate } = action;

                return {
                    ...state,
                    endDate,
                    isDirty: true,
                };
            }

            case ActionType.SET_EVENT_END_TIME: {
                const { value: endTime } = action;

                return {
                    ...state,
                    endTime,
                    isDirty: true,
                };
            }

            case ActionType.SET_EVENT_START_DATE: {
                const { value: startDate } = action;

                return {
                    ...state,
                    startDate,
                    isDirty: true,
                };
            }

            case ActionType.SET_EVENT_START_TIME: {
                const { value: startTime } = action;

                return {
                    ...state,
                    startTime,
                    isDirty: true,
                };
            }

            case ActionType.SET_EVENT_REPEATING_LABEL: {
                const { value: repeatingLabel } = action;

                return {
                    ...state,
                    repeatingLabel,
                };
            }

            case ActionType.SET_EVENT_REPEATINGS: {
                const { value: repeatings } = action;

                return {
                    ...state,
                    repeatings,
                    isDirty: true,
                };
            }
            // End Event Date & time

            // Start Event Location On Campus Actions
            case ActionType.SET_EVENT_LOCATION_ON_WHERE_SELECTED: {
                const { value: whereSelected } = action;

                return {
                    ...state,
                    whereSelected,
                };
            }

            case ActionType.SET_EVENT_LOCATION_ON_WHERE_CAMPUS: {
                const { value: whereCampus } = action;

                return {
                    ...state,
                    whereCampus,
                };
            }

            case ActionType.SET_EVENT_LOCATION_ON_SUB_VENUES: {
                const { value: subVenues } = action;

                return updateEventAttribute(state, { subVenues });
            }

            case ActionType.SET_EVENT_LOCATION_ON_VENUES: {
                const { value: venues } = action;

                return updateEventAttribute(state, { venues });
            }

            case ActionType.SET_EVENT_LOCATION_ON_VENUE: {
                const { value: venue } = action;

                return {
                    ...state,
                    venue,
                };
            }

            case ActionType.SET_EVENT_LOCATION_ON_WHERE_VENUES: {
                const { value: whereVenues } = action;

                return {
                    ...state,
                    whereVenues,
                };
            }

            case ActionType.SET_EVENT_LOCATION_ON_SHOW_PROMPT_TO_CHANGE_CAMPUS: {
                const { value: showPromptToChangeCampus } = action;

                return {
                    ...state,
                    showPromptToChangeCampus,
                };
            }

            case ActionType.SET_EVENT_LOCATION_ON_CAMPUS_TO_CHANGE: {
                const { value: campusToChange } = action;

                return {
                    ...state,
                    campusToChange,
                };
            }
            // End Event Location On Campus Actions

            // Start Event Location Type Virtual
            case ActionType.SET_EVENT_LOCATION_IS_VIRTUAL: {
                const { value: isVirtual } = action;

                return updateEventAttribute(state, { isVirtual });
            }

            case ActionType.SET_EVENT_LOCATION_VIRTUAL_CAPACITY: {
                const { value: capacity } = action;

                return updateEventAttribute(state, { capacity });
            }

            case ActionType.SET_EVENT_LOCATION_VIRTUAL_URL: {
                const { value: virtualUrl } = action;

                return updateEventAttribute(state, { virtualUrl });
            }
            // End Event Location Type Virtual

            // Start Event Location Type Off Campus
            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_ADDRESS_ONE: {
                const { value: address1 } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        venueAddress: {
                            ...state.venue.venueAddress,
                            address1,
                        },
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_ADDRESS_TWO: {
                const { value: address2 } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        venueAddress: {
                            ...state.venue.venueAddress,
                            address2,
                        },
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_CAPACITY: {
                const { value: capacity } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        capacity,
                        venueCapacity: capacity,
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_CITY: {
                const { value: city } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        venueAddress: {
                            ...state.venue.venueAddress,
                            city,
                        },
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_COUNTRY: {
                const { value: country } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        venueAddress: {
                            ...state.venue.venueAddress,
                            country,
                            region: '',
                        },
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_LATITUDE: {
                const { value: latitude } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        latitude,
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_LONGITUDE: {
                const { value: longitude } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        longitude,
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_NAME: {
                const { value: name } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        name,
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_POSTAL_CODE: {
                const { value: postalCode } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        venueAddress: {
                            ...state.venue.venueAddress,
                            postalCode,
                        },
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_PRIVATE_ADDRESS: {
                const { value: privateAddress } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        privateAddress,
                    },
                };
            }

            case ActionType.SET_EVENT_LOCATION_OFF_CAMPUS_REGION: {
                const { value: region } = action;

                return {
                    ...state,
                    isDirty: true,
                    venue: {
                        ...state.venue,
                        venueAddress: {
                            ...state.venue.venueAddress,
                            region,
                        },
                    },
                };
            }
            // End Event Location Type Off Campus

            // Start Event Imagery
            case EventDetailActionType.SET_EVENT_IMAGE_UPLOADER_DATA: {
                const { value: imageUploaderData } = action;

                return {
                    ...state,
                    imageUploaderData,
                };
            }

            case EventDetailActionType.SET_EVENT_IMAGE_UPLOADER_IN_PROGRESS: {
                const { value: isImageUploadInProgress } = action;

                return {
                    ...state,
                    isImageUploadInProgress,
                };
            }

            case EventDetailActionType.SET_EVENT_COMPANION_APP_HEADER: {
                const { value: companionAppHeader } = action;

                return updateEventAttribute(state, { companionAppHeader });
            }

            case EventDetailActionType.SET_EVENT_COMPANION_APP_HEADER_URL: {
                const { value: companionAppHeaderUrl } = action;

                return {
                    ...state,
                    companionAppHeaderUrl,
                };
            }

            case EventDetailActionType.SET_EVENT_WEB_HEADER: {
                const { value: webHeader } = action;

                return updateEventAttribute(state, { webHeader });
            }

            case EventDetailActionType.SET_EVENT_WEB_HEADER_URL: {
                const { value: webHeaderUrl } = action;

                return {
                    ...state,
                    webHeaderUrl,
                };
            }

            case EventDetailActionType.SET_EVENT_THUMBNAIL: {
                const { value: thumbnail } = action;

                return updateEventAttribute(state, { thumbnail });
            }

            case EventDetailActionType.SET_EVENT_THUMBNAIL_URL: {
                const { value: thumbnailUrl } = action;

                return {
                    ...state,
                    thumbnailUrl,
                };
            }
            // End Event Imagery

            // Start Event Description
            case EventDetailActionType.SET_EVENT_DESCRIPTION: {
                const { value: description } = action;

                return updateEventAttribute(state, { description });
            }

            case EventDetailActionType.SET_EVENT_TAGS: {
                const { value: tagIds } = action;

                return updateEventAttribute(state, { tagIds });
            }
            // End Event Description

            // Start Event Contact
            case EventDetailActionType.SET_EVENT_PUBLISHED_CONTACT_NAME: {
                const { value: publishedContactName } = action;

                return updateEventAttribute(state, { publishedContactName });
            }

            case EventDetailActionType.SET_EVENT_PUBLISHED_EMAIL: {
                const { value: publishedEmail } = action;

                return updateEventAttribute(state, { publishedEmail });
            }

            case EventDetailActionType.SET_EVENT_PUBLISHED_PHONE: {
                const { value: publishedPhone } = action;

                return updateEventAttribute(state, { publishedPhone });
            }
            // End Event Contact

            // Start Event Registration
            case EventRegistrationActionType.SET_EVENT_REGISTRATION: {
                const { value: canRegister } = action;

                return updateEventAttribute(state, { canRegister });
            }
            // End Event Registration

            // Start Event Registration Window
            case EventRegistrationActionType.SET_EVENT_IS_CUSTOM_REGISTRATION: {
                const { value: isCustomRegistration } = action;

                return {
                    ...state,
                    isCustomRegistration,
                };
            }

            case EventRegistrationActionType.SET_EVENT_IS_REGISTRATION_CLOSED: {
                const { value: isRegistrationClosed } = action;

                return {
                    ...state,
                    isRegistrationClosed,
                };
            }

            case EventRegistrationActionType.SET_EVENT_REGISTRATION_END_DATE: {
                const { value: registrationEndDate } = action;

                return updateEventAttribute(state, { registrationEndDate });
            }

            case EventRegistrationActionType.SET_EVENT_REGISTRATION_START_DATE: {
                const { value: registrationStartDate } = action;

                return updateEventAttribute(state, { registrationStartDate });
            }
            // End Event Registration Window

            // Start Event Allow Guest Registration
            case EventRegistrationActionType.SET_EVENT_ALLOW_GUEST_REGISTRATION: {
                const { value: allowGuestRegistration } = action;

                return updateEventAttribute(state, { allowGuestRegistration });
            }
            // End Event Allow Guest Registration

            // Start Event Allow Group Registration
            case EventRegistrationActionType.SET_EVENT_ALLOW_GROUP_REGISTRATION: {
                const { value: allowGroupRegistration } = action;

                return updateEventAttribute(state, { allowGroupRegistration });
            }

            case EventRegistrationActionType.SET_EVENT_MAX_GROUP_PARTY_SIZE: {
                const { value: maxGroupPartySize } = action;

                return updateEventAttribute(state, { maxGroupPartySize });
            }
            // End Event Allow Group Registration

            // Start Event Registration Question
            case EventRegistrationActionType.SET_EVENT_CUSTOM_QUESTIONS: {
                const { value: customQuestions } = action;

                return {
                    ...state,
                    customQuestions,
                };
            }
            // End Event Registration Question

            // Start Event Confirmation Email
            case EventRegistrationActionType.SET_EVENT_CUSTOM_REGISTRATION_NOTIFICATION_SETTINGS: {
                const { value: customRegistrationNotificationSettings } = action;

                return updateEventAttribute(state, { customRegistrationNotificationSettings });
            }
            // End Event Confirmation Email
            // Start Updating Event Published Info
            case EventPublishActionType.SET_EVENT_PUBLISHED_INFO: {
                const { value: publishedInfo } = action;

                return {
                    ...state,
                    ...publishedInfo,
                };
            }
            // End Updating Event Published Info

            // Start pre-populating values for events
            case ActionType.SET_EVENT_DATA: {
                const { value: eventData } = action;

                return {
                    ...state,
                    ...eventData,
                };
            }

            case ActionType.RESET_EVENT_DATA: {
                return DEFAULT_STATE;
            }
            // End pre-populating values for events

            default:
                return state;
        }
    }

    return state;
};
