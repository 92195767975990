export const SERVICE_COMPANION_ROUTE_PATHS = {
    locations: 'locations',
    overview: 'overview',
    root: 'content-management/service-companion',
};

/**
 * Tab type for the various service companion item drawers.
 */
export enum ServiceCompanionDrawerTabs {
    Existing = 0,
    New = 1,
}

/**
 * Type of item to be displayed by the service companion overview
 * list.
 */
export enum ServiceCompanionItemType {
    ConnectionForm = 'Connection Form',
    MediaUrl = 'Media Url',
    ServiceOutline = 'Service Outline',
    Announcement = 'Announcement',
    Song = 'Song',
}

export const CHURCH_ENTITY_FIELD = 'churchEntityId';
export const CAMPUS_ICON_TYPE = 'location';
export const CAMPUS_ITEM_TEXT = 'Campus Specific';
export const CAMPUS_ITEM_TEXT_SHORT = 'Campus';
export const GENERAL_ICON_TYPE = 'favorite';
export const GENERAL_ITEM_TEXT = 'General';

export const DEFAULT_LEAD_TIME_HOURS =
    (service) => Math.floor(service?.leadTime / 60) ?? 0;
export const DEFAULT_LEAD_TIME_MINUTES = (service) => service?.leadTime % 60 ?? 10;
