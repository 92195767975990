import {
    Icon,
    Input,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
} from 'lodash';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    withRouter,
} from 'react-router';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React, {
    useCallback,
    useRef,
    useState,
} from 'react';
import {
    i18n,
} from '../../global/constants.js';
import {
    ENTER_KEY_CODE,
} from '../../global/keyCodeConstants.js';
import {
    useComponentDidUpdate,
} from '../../global/lifeCycleHooks';
import {
    isEnterKeyCode,
} from '../../global/utils/utils.js';
import {
    updateSearchTerm,
} from '../../people/search/peopleSearchFilters.actions.js';
import {
    RootState,
} from '../../reducers';
import {
    BEM_BLOCK_NAME,
} from './constants';
import PersonSearchDrawer from '../../people/search/global/personSearchDrawer';

type Value = string;

type PropTypes = {
    router: {
        location: {
            pathname: string;
        };
        push: (pathname: string) => void;
    },
};

const useStyles = makeStyles(({
    palette,
    spacing,
}) => ({
    clearButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        height: spacing(4),
        justifyContent: 'center',
        position: 'absolute',
        margin: [[0], '!important'],
        right: 0,
        top: 0,
        width: spacing(4),
        zIndex: 2,
    },
    input: {
        zIndex: 1,
        maxWidth: 120,
        '&.ui.input': {
            '& input': {
                backgroundColor: palette.background.light,
                borderColor: palette.background.light,
                padding: [[0, 0, 0, spacing(3.5)]],
                transition: 'border-color 150ms ease-out, background-color 150ms ease-out',
            },
            '&.input-focused input': {
                backgroundColor: palette.background.primary,
                borderColor: palette.active.main,
            },
        },
    },
    magnifyingGlassIconContainer: {
        alignItems: 'center',
        display: 'flex',
        height: spacing(4),
        left: 0,
        margin: [[0, spacing(1)]],
        position: 'absolute',
        top: 0,
        zIndex: 2,
    },
    root: {
        position: 'relative',
    },
}));

const personSearchPathname: string = '/people/search-or-add';

function PersonSearchInput({
    router,
}: PropTypes) {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [shouldOpenDrawer, setShouldOpenDrawer] = useState<boolean>(false);
    const [value, setValue] = useState<Value>('');

    const classes: {
        root: string;
        magnifyingGlassIconContainer: string;
        input: string;
    } = useStyles();

    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const searchTerm = useSelector((state: RootState) => (
        state.people.searchV2.searchFilters.searchTerm
    ));

    const isPersonSearchPage: boolean = router?.location?.pathname === personSearchPathname;

    useComponentDidUpdate(() => {
        if (!isPersonSearchPage) {
            setValue(searchTerm);
        }
    }, [
        searchTerm,
    ]);

    useComponentDidUpdate(() => {
        if (!isEmpty(searchTerm) && shouldOpenDrawer) {
            setIsDrawerOpen(true);
            setShouldOpenDrawer(false);
        }
    }, [
        searchTerm,
        shouldOpenDrawer,
    ]);

    const onDrawerClose = useCallback(() => {
        setValue('');
        updateSearchTerm('')(dispatch);
        setIsDrawerOpen(false);
        setShouldOpenDrawer(false);
    }, [
        dispatch,
    ]);

    const onInputChange = useCallback((newValue) => {
        setValue(newValue);
    }, []);

    const search = useCallback(() => {
        if (isPersonSearchPage) {
            setValue('');

            /**
             * Remount the person search page.
             */
            router.push(personSearchPathname);
        } else {
            setShouldOpenDrawer(true);
        }

        updateSearchTerm(value)(dispatch);
    }, [
        dispatch,
        isPersonSearchPage,
        router,
        value,
    ]);

    const onInputKeyDown = useCallback((event) => {
        if (isEnterKeyCode(event)) {
            search();
        }
    }, [
        search,
    ]);

    const onSearchClick = useCallback(() => {
        if (!isEmpty(value)) {
            search();
        } else {
            inputRef?.current?.focus();
        }
    }, [
        value,
        search,
    ]);

    const onSearchKeyDown = useCallback((event) => {
        if (event.keyCode === ENTER_KEY_CODE) {
            onSearchClick();
        }
    }, [
        onSearchClick,
    ]);

    return (
        <div
            className={classes.root}
        >
            <div
                className={classes.magnifyingGlassIconContainer}
            >
                <Icon
                    compact
                    id={`${BEM_BLOCK_NAME}--app_header_search_icon`}
                    onClick={onSearchClick}
                    onKeyDown={onSearchKeyDown}
                    title={i18n('Search')}
                    type="search"
                    tabIndex={0}
                />
            </div>

            <Input
                className={classes.input}
                fluid
                id={`${BEM_BLOCK_NAME}--app_header_input`}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                placeholder={i18n('People')}
                ref={inputRef}
                tabIndex={0}
                value={value}
            />

            <PersonSearchDrawer
                isOpen={isDrawerOpen}
                onClose={onDrawerClose}
            />
        </div>
    );
}

export default withRouter(PersonSearchInput);
