import ActionType from '../../actionTypes/segment.actionTypes';
import { Action } from '../../../global/models';

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_SEGMENT:
            return action.payload;
        default:
            return state;
    }
};
