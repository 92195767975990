export const BEM_BLOCK_NAME = 'check_in_page';

export const ACTION_NOTIFY_ATTENDEES_ID = `${BEM_BLOCK_NAME}--action_notify_attendees`;
export const ACTION_NOTIFY_EVERYONE_ID = `${BEM_BLOCK_NAME}--action_notify_everyone`;
export const ACTION_NOTIFY_PARENTS_ID = `${BEM_BLOCK_NAME}--action_notify_parents`;
export const ACTION_NOTIFY_VOLUNTEERS_ID = `${BEM_BLOCK_NAME}--action_notify_volunteers`;

export const IS_ROOM_FOR_CHILDREN = 'isRoomForChildren';

export const SEARCH_BY_EVENT_VOLUNTEERS_TAB = 0;
export const SEARCH_BY_EVENT_MINISTRY_TAB = 1;
export const SEARCH_BY_ALL_VOLUNTEERS_TAB = 2;

export const childRelationsWhitelist = [
    'child',
    'stepchild',
    'sibling',
    'stepsibling',
    'grandchild',
    'stepgrandchild',
    'siblingschild',
    'fosterchild',
    'cousin',
    'neighbor',
    'other',
    'friend',
];
