import { i18n } from '../global/constants.js';

export const BEM_CONNECTION_FORMS = 'connection_forms';

export const CONNECTION_FORMS_PATH = '/data-capture/connection-forms';
export const CONNECTION_FORMS_SECTION_AUTO_REPLY_EMAIL = 'auto-reply-email';
export const CONNECTION_FORMS_SECTION_OVERVIEW = 'overview';
export const CONNECTION_FORMS_SECTION_ENTRIES = 'entries';
export const CONNECTION_FORMS_SECTION_FOLLOW_UPS = 'follow-ups';
export const CONNECTION_FORMS_SECTION_FORM_BUILDER = 'builder';
export const CONNECTION_FORMS_SECTION_SETTINGS = 'settings';

export const CONNECTION_FORMS_TITLE = i18n('Connection Form');
