import _ from 'lodash';
import AdminImportParsingCountersConstants from '../../../constants/Admin/AdminImportParsingCountersConstants';

function getCode(action) {
    const field = action.params.personStatus ? action.params.personStatus : action.params.personAction;
    return _.includes(field, 'milestone') ? 2 : (_.includes(field, 'household') ? 1 : 0);
}

class Counters {
    constructor() {
        this._counts = {};
        this._countsFiltered = {};
        this._countsStatistics = {};
    }

    onGet(status, count) {
        this._counts[status] = count;
    }

    onGetFiltered(status, count) {
        this._countsFiltered[status] = count;
    }

    onGetStatistics(status, action, count) {
        if (!this._countsStatistics[status]) {
            this._countsStatistics[status] = {};
        }
        this._countsStatistics[status][action] = count;
    }

    getNew(filtered) {
        const counts = filtered ? this._countsFiltered : this._counts;
        return counts[AdminImportParsingCountersConstants.IMPORT_COUNTER_NEW] || 0;
    }

    getChanged(filtered) {
        const counts = filtered ? this._countsFiltered : this._counts;
        return counts[AdminImportParsingCountersConstants.IMPORT_COUNTER_CHANGED] || 0;
    }

    getUnchanged(filtered) {
        const counts = filtered ? this._countsFiltered : this._counts;
        return counts[AdminImportParsingCountersConstants.IMPORT_COUNTER_UNCHANGED] || 0;
    }

    getError(filtered) {
        const counts = filtered ? this._countsFiltered : this._counts;
        return counts[AdminImportParsingCountersConstants.IMPORT_COUNTER_ERROR] || 0;
    }

    getStatisticsNew() {
        return this._countsStatistics[AdminImportParsingCountersConstants.IMPORT_COUNTER_NEW] || 0;
    }

    getStatisticsChanged() {
        return this._countsStatistics[AdminImportParsingCountersConstants.IMPORT_COUNTER_CHANGED] || 0;
    }

    getStatisticsUnchanged() {
        return this._countsStatistics[AdminImportParsingCountersConstants.IMPORT_COUNTER_UNCHANGED] || 0;
    }

    getStatisticsError() {
        return this._countsStatistics[AdminImportParsingCountersConstants.IMPORT_COUNTER_ERROR] || 0;
    }
}

class AdminImportParsingCountersStore {
    constructor(base) {
        this.counters = base ? _.cloneDeep(base.counters) : [new Counters(), new Counters(), new Counters()];
        this.total = base ? _.cloneDeep(base.total) : [{}, {}, {}];
    }

    _onGet(status, count, code) {
        this.counters[code].onGet(status, count);
    }

    _onGetFiltered(status, count, code) {
        this.counters[code].onGetFiltered(status, count);
    }

    _onGetStatistics(status, action, count, code) {
        this.counters[code].onGetStatistics(status, action, count);
    }

    _onGetStatisticsTotal(action, count, code) {
        this.total[code][action] = count;
    }

    getNew(filtered) {
        return this.counters[0].getNew(filtered);
    }

    getChanged(filtered) {
        return this.counters[0].getChanged(filtered);
    }

    getUnchanged(filtered) {
        return this.counters[0].getUnchanged(filtered);
    }

    getError(filtered) {
        return this.counters[0].getError(filtered);
    }

    getCreateHousehold(filtered) {
        return this.counters[1].getNew(filtered);
    }

    getHouseholdChanges(filtered) {
        return this.counters[1].getChanged(filtered);
    }

    getAddMilestones(filtered) {
        return this.counters[2].getNew(filtered);
    }

    getStatisticsNew() {
        return this.counters[0].getStatisticsNew();
    }

    getStatisticsChanged() {
        return this.counters[0].getStatisticsChanged();
    }

    getStatisticsUnchanged() {
        return this.counters[0].getStatisticsUnchanged();
    }

    getStatisticsError() {
        return this.counters[0].getStatisticsError();
    }

    getStatisticsCreateHousehold() {
        return this.counters[1].getStatisticsNew();
    }

    getStatisticsHouseholdChanges() {
        return this.counters[1].getStatisticsChanged();
    }

    getStatisticsAddMilestones() {
        return this.counters[2].getStatisticsNew();
    }

    getStatisticsTotal() {
        return this.total[0];
    }

    getStatisticsTotalHouseholds() {
        return this.total[1];
    }

    getStatisticsTotalMilestones() {
        return this.total[2];
    }
}

export const DEFAULT_STATE = {
    store: new AdminImportParsingCountersStore(),
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'AdminImportParsingCountersActions.ON__GETPERSONBYSTATUS': {
            const store = new AdminImportParsingCountersStore(state.store);
            store._onGet(
                action.params.status,
                action.result,
                getCode(action),
            );
            return { store };
        }

        case 'AdminImportParsingCountersActions.ON__GETPERSONBYSTATUSANDNAME': {
            const store = new AdminImportParsingCountersStore(state.store);
            store._onGetFiltered(
                action.params.status,
                action.result,
                getCode(action),
            );
            return { store };
        }

        case 'AdminImportParsingCountersActions.ON__GETPERSONBYSTATUSANDACTION': {
            const store = new AdminImportParsingCountersStore(state.store);
            store._onGetStatistics(
                action.params.status,
                action.params.action,
                action.result,
                getCode(action),
            );
            return { store };
        }

        case 'AdminImportParsingCountersActions.ON__GETPERSONBYACTION': {
            const store = new AdminImportParsingCountersStore(state.store);
            store._onGetStatisticsTotal(
                action.params.action,
                action.result,
                getCode(action),
            );
            return { store };
        }
    }
    return state;
}
