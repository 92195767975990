export const BEM_BLOCK_NAME = 'event_details_drawer';

export const ID_REPEATING_NO = `${BEM_BLOCK_NAME}--repeating_no`;
export const ID_REPEATING_YES = `${BEM_BLOCK_NAME}--repeating_yes`;

export const WHO_CAMPUS = 0;
export const WHO_MINISTRY = 1;

export const ID_EVENT_HOSTING_CAMPUS = `${BEM_BLOCK_NAME}--event_hosting_campus`;
export const ID_EVENT_HOSTING_MINISTRY = `${BEM_BLOCK_NAME}--event_hosting_ministry`;

export const NO_MARGIN_STYLES = { margin: '0' };
