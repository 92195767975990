import '../../scss/components/Button.scss';

import _ from 'lodash';
import ClassNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default class Button extends React.Component {
    render() {
        const {
            boxType, children, className,
            color, disabled, id, size,
            style, styleInner, theme,
            title,
        } = this.props;

        this.props.toParams ? console.error('Passing toParams to Button is depricated and will not pass through.  Send the params through with props.to') : null;

        const buttonClasses = ClassNames('btn', className, {
            'btn-alert': !disabled && _.includes(color, 'alert'),
            'btn-blank': !disabled && _.includes(color, 'blank'),
            'btn-primary': !disabled && (!color || _.includes(color, 'primary')),
            'btn-warning': !disabled && _.includes(color, 'warning'),
            'btn-secondary': !disabled && _.includes(color, 'secondary'),
            'btn-success': !disabled && _.includes(color, 'success'),
            'btn-inline': boxType === 'inline',
            'btn-light': theme === 'light',
            'btn-outline': theme === 'outline',
            'btn-large': size === 'large' || size === 'l',
            'btn-small': size === 'small' || size === 's',
            'btn-disabled': disabled,
        });

        return (
            <a
                className={buttonClasses}
                disabled={this.props.disabled}
                id={id}
                onClick={this._onClick.bind(this)}
                style={style}
                title={title}
            >
                {children ? (
                    <span className="btn-inner" style={styleInner}>
                        {children}
                    </span>
                ) : null}
            </a>
        );
    }

    _onClick(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.props.disabled) {
            const { to } = this.props;

            if (to) {
                this.context.router.push(to);
            }

            if (typeof this.props.onClick === 'function') {
                this.props.onClick();
            }
        }
    }
}

Button.propTypes = {
    boxType: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    style: PropTypes.object,
    styleInner: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string,
    toParams: PropTypes.object,
};

Button.contextTypes = {
    router: PropTypes.object.isRequired,
};
