// Generated from ../tools/ANTLR/DSL-Parser/Dsl.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { DslVisitor } from "./DslVisitor";


export class DslParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly BOOL_OR = 5;
	public static readonly BOOL_AND = 6;
	public static readonly BOOL_NOT = 7;
	public static readonly STAR = 8;
	public static readonly STRING = 9;
	public static readonly NESTED_ID = 10;
	public static readonly OPEN_RANGE = 11;
	public static readonly CLOSE_RANGE = 12;
	public static readonly DATE = 13;
	public static readonly NUMBER = 14;
	public static readonly TERM_WITH_OPTIONS = 15;
	public static readonly TERM = 16;
	public static readonly WS = 17;
	public static readonly RULE_dsl = 0;
	public static readonly RULE_sequenceOr = 1;
	public static readonly RULE_sequenceAnd = 2;
	public static readonly RULE_itemGroup = 3;
	public static readonly RULE_itemGroupNot = 4;
	public static readonly RULE_item = 5;
	public static readonly RULE_value = 6;
	public static readonly RULE_valueGroup = 7;
	public static readonly RULE_valueOr = 8;
	public static readonly RULE_valueAnd = 9;
	public static readonly RULE_valueNot = 10;
	public static readonly RULE_valueGroupItem = 11;
	public static readonly RULE_directValue = 12;
	public static readonly RULE_directItem = 13;
	public static readonly RULE_range = 14;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"dsl", "sequenceOr", "sequenceAnd", "itemGroup", "itemGroupNot", "item", 
		"value", "valueGroup", "valueOr", "valueAnd", "valueNot", "valueGroupItem", 
		"directValue", "directItem", "range",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'('", "')'", "':'", "'TO'", undefined, undefined, undefined, 
		"'*'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, "BOOL_OR", "BOOL_AND", 
		"BOOL_NOT", "STAR", "STRING", "NESTED_ID", "OPEN_RANGE", "CLOSE_RANGE", 
		"DATE", "NUMBER", "TERM_WITH_OPTIONS", "TERM", "WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(DslParser._LITERAL_NAMES, DslParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return DslParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "Dsl.g4"; }

	// @Override
	public get ruleNames(): string[] { return DslParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return DslParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(DslParser._ATN, this);
	}
	// @RuleVersion(0)
	public dsl(): DslContext {
		let _localctx: DslContext = new DslContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, DslParser.RULE_dsl);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 30;
			this.sequenceOr();
			this.state = 31;
			this.match(DslParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public sequenceOr(): SequenceOrContext {
		let _localctx: SequenceOrContext = new SequenceOrContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, DslParser.RULE_sequenceOr);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 36;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 0, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 33;
					this.match(DslParser.WS);
					}
					}
				}
				this.state = 38;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 0, this._ctx);
			}
			this.state = 39;
			this.sequenceAnd();
			this.state = 54;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 3, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 41;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					do {
						{
						{
						this.state = 40;
						this.match(DslParser.WS);
						}
						}
						this.state = 43;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
					} while (_la === DslParser.WS);
					this.state = 45;
					this.match(DslParser.BOOL_OR);
					this.state = 47;
					this._errHandler.sync(this);
					_alt = 1;
					do {
						switch (_alt) {
						case 1:
							{
							{
							this.state = 46;
							this.match(DslParser.WS);
							}
							}
							break;
						default:
							throw new NoViableAltException(this);
						}
						this.state = 49;
						this._errHandler.sync(this);
						_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
					} while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER);
					this.state = 51;
					this.sequenceAnd();
					}
					}
				}
				this.state = 56;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 3, this._ctx);
			}
			this.state = 60;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 57;
					this.match(DslParser.WS);
					}
					}
				}
				this.state = 62;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public sequenceAnd(): SequenceAndContext {
		let _localctx: SequenceAndContext = new SequenceAndContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, DslParser.RULE_sequenceAnd);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 66;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DslParser.WS) {
				{
				{
				this.state = 63;
				this.match(DslParser.WS);
				}
				}
				this.state = 68;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 71;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case DslParser.BOOL_NOT:
				{
				this.state = 69;
				this.itemGroupNot();
				}
				break;
			case DslParser.T__0:
			case DslParser.STAR:
			case DslParser.STRING:
			case DslParser.NESTED_ID:
			case DslParser.DATE:
			case DslParser.NUMBER:
			case DslParser.TERM_WITH_OPTIONS:
				{
				this.state = 70;
				this.itemGroup();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 92;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 11, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 74;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					do {
						{
						{
						this.state = 73;
						this.match(DslParser.WS);
						}
						}
						this.state = 76;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
					} while (_la === DslParser.WS);
					this.state = 84;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					if (_la === DslParser.BOOL_AND) {
						{
						this.state = 78;
						this.match(DslParser.BOOL_AND);
						this.state = 80;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
						do {
							{
							{
							this.state = 79;
							this.match(DslParser.WS);
							}
							}
							this.state = 82;
							this._errHandler.sync(this);
							_la = this._input.LA(1);
						} while (_la === DslParser.WS);
						}
					}

					this.state = 88;
					this._errHandler.sync(this);
					switch (this._input.LA(1)) {
					case DslParser.BOOL_NOT:
						{
						this.state = 86;
						this.itemGroupNot();
						}
						break;
					case DslParser.T__0:
					case DslParser.STAR:
					case DslParser.STRING:
					case DslParser.NESTED_ID:
					case DslParser.DATE:
					case DslParser.NUMBER:
					case DslParser.TERM_WITH_OPTIONS:
						{
						this.state = 87;
						this.itemGroup();
						}
						break;
					default:
						throw new NoViableAltException(this);
					}
					}
					}
				}
				this.state = 94;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 11, this._ctx);
			}
			this.state = 98;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 12, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 95;
					this.match(DslParser.WS);
					}
					}
				}
				this.state = 100;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 12, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public itemGroup(): ItemGroupContext {
		let _localctx: ItemGroupContext = new ItemGroupContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, DslParser.RULE_itemGroup);
		let _la: number;
		try {
			let _alt: number;
			this.state = 118;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case DslParser.T__0:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 101;
				this.match(DslParser.T__0);
				this.state = 105;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 13, this._ctx);
				while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						{
						{
						this.state = 102;
						this.match(DslParser.WS);
						}
						}
					}
					this.state = 107;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 13, this._ctx);
				}
				this.state = 108;
				this.sequenceOr();
				this.state = 112;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === DslParser.WS) {
					{
					{
					this.state = 109;
					this.match(DslParser.WS);
					}
					}
					this.state = 114;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 115;
				this.match(DslParser.T__1);
				}
				break;
			case DslParser.STAR:
			case DslParser.STRING:
			case DslParser.NESTED_ID:
			case DslParser.DATE:
			case DslParser.NUMBER:
			case DslParser.TERM_WITH_OPTIONS:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 117;
				this.item();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public itemGroupNot(): ItemGroupNotContext {
		let _localctx: ItemGroupNotContext = new ItemGroupNotContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, DslParser.RULE_itemGroupNot);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 120;
			this.match(DslParser.BOOL_NOT);
			this.state = 122;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 121;
				this.match(DslParser.WS);
				}
				}
				this.state = 124;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (_la === DslParser.WS);
			this.state = 126;
			this.itemGroup();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public item(): ItemContext {
		let _localctx: ItemContext = new ItemContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, DslParser.RULE_item);
		let _la: number;
		try {
			this.state = 138;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 18, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 128;
				this.match(DslParser.NESTED_ID);
				this.state = 129;
				this.match(DslParser.T__2);
				this.state = 133;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === DslParser.WS) {
					{
					{
					this.state = 130;
					this.match(DslParser.WS);
					}
					}
					this.state = 135;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 136;
				this.value();
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 137;
				this.directItem();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public value(): ValueContext {
		let _localctx: ValueContext = new ValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, DslParser.RULE_value);
		try {
			this.state = 142;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case DslParser.T__0:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 140;
				this.valueGroup();
				}
				break;
			case DslParser.STAR:
			case DslParser.STRING:
			case DslParser.NESTED_ID:
			case DslParser.OPEN_RANGE:
			case DslParser.DATE:
			case DslParser.NUMBER:
			case DslParser.TERM_WITH_OPTIONS:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 141;
				this.directValue();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public valueGroup(): ValueGroupContext {
		let _localctx: ValueGroupContext = new ValueGroupContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, DslParser.RULE_valueGroup);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 144;
			this.match(DslParser.T__0);
			this.state = 145;
			this.valueOr();
			this.state = 146;
			this.match(DslParser.T__1);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public valueOr(): ValueOrContext {
		let _localctx: ValueOrContext = new ValueOrContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, DslParser.RULE_valueOr);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 151;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 20, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 148;
					this.match(DslParser.WS);
					}
					}
				}
				this.state = 153;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 20, this._ctx);
			}
			this.state = 154;
			this.valueAnd();
			this.state = 169;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 23, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 156;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					do {
						{
						{
						this.state = 155;
						this.match(DslParser.WS);
						}
						}
						this.state = 158;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
					} while (_la === DslParser.WS);
					this.state = 160;
					this.match(DslParser.BOOL_OR);
					this.state = 162;
					this._errHandler.sync(this);
					_alt = 1;
					do {
						switch (_alt) {
						case 1:
							{
							{
							this.state = 161;
							this.match(DslParser.WS);
							}
							}
							break;
						default:
							throw new NoViableAltException(this);
						}
						this.state = 164;
						this._errHandler.sync(this);
						_alt = this.interpreter.adaptivePredict(this._input, 22, this._ctx);
					} while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER);
					this.state = 166;
					this.valueAnd();
					}
					}
				}
				this.state = 171;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 23, this._ctx);
			}
			this.state = 175;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DslParser.WS) {
				{
				{
				this.state = 172;
				this.match(DslParser.WS);
				}
				}
				this.state = 177;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public valueAnd(): ValueAndContext {
		let _localctx: ValueAndContext = new ValueAndContext(this._ctx, this.state);
		this.enterRule(_localctx, 18, DslParser.RULE_valueAnd);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 181;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DslParser.WS) {
				{
				{
				this.state = 178;
				this.match(DslParser.WS);
				}
				}
				this.state = 183;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 186;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case DslParser.BOOL_NOT:
				{
				this.state = 184;
				this.valueNot();
				}
				break;
			case DslParser.T__0:
			case DslParser.STAR:
			case DslParser.STRING:
			case DslParser.NESTED_ID:
			case DslParser.OPEN_RANGE:
			case DslParser.DATE:
			case DslParser.NUMBER:
			case DslParser.TERM_WITH_OPTIONS:
				{
				this.state = 185;
				this.valueGroupItem();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 207;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 31, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 189;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					do {
						{
						{
						this.state = 188;
						this.match(DslParser.WS);
						}
						}
						this.state = 191;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
					} while (_la === DslParser.WS);
					this.state = 199;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					if (_la === DslParser.BOOL_AND) {
						{
						this.state = 193;
						this.match(DslParser.BOOL_AND);
						this.state = 195;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
						do {
							{
							{
							this.state = 194;
							this.match(DslParser.WS);
							}
							}
							this.state = 197;
							this._errHandler.sync(this);
							_la = this._input.LA(1);
						} while (_la === DslParser.WS);
						}
					}

					this.state = 203;
					this._errHandler.sync(this);
					switch (this._input.LA(1)) {
					case DslParser.BOOL_NOT:
						{
						this.state = 201;
						this.valueNot();
						}
						break;
					case DslParser.T__0:
					case DslParser.STAR:
					case DslParser.STRING:
					case DslParser.NESTED_ID:
					case DslParser.OPEN_RANGE:
					case DslParser.DATE:
					case DslParser.NUMBER:
					case DslParser.TERM_WITH_OPTIONS:
						{
						this.state = 202;
						this.valueGroupItem();
						}
						break;
					default:
						throw new NoViableAltException(this);
					}
					}
					}
				}
				this.state = 209;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 31, this._ctx);
			}
			this.state = 213;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 32, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 210;
					this.match(DslParser.WS);
					}
					}
				}
				this.state = 215;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 32, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public valueNot(): ValueNotContext {
		let _localctx: ValueNotContext = new ValueNotContext(this._ctx, this.state);
		this.enterRule(_localctx, 20, DslParser.RULE_valueNot);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 216;
			this.match(DslParser.BOOL_NOT);
			this.state = 218;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 217;
				this.match(DslParser.WS);
				}
				}
				this.state = 220;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (_la === DslParser.WS);
			this.state = 222;
			this.valueGroupItem();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public valueGroupItem(): ValueGroupItemContext {
		let _localctx: ValueGroupItemContext = new ValueGroupItemContext(this._ctx, this.state);
		this.enterRule(_localctx, 22, DslParser.RULE_valueGroupItem);
		try {
			this.state = 226;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case DslParser.T__0:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 224;
				this.valueGroup();
				}
				break;
			case DslParser.STAR:
			case DslParser.STRING:
			case DslParser.NESTED_ID:
			case DslParser.OPEN_RANGE:
			case DslParser.DATE:
			case DslParser.NUMBER:
			case DslParser.TERM_WITH_OPTIONS:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 225;
				this.directValue();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public directValue(): DirectValueContext {
		let _localctx: DirectValueContext = new DirectValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 24, DslParser.RULE_directValue);
		try {
			this.state = 230;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case DslParser.OPEN_RANGE:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 228;
				this.range();
				}
				break;
			case DslParser.STAR:
			case DslParser.STRING:
			case DslParser.NESTED_ID:
			case DslParser.DATE:
			case DslParser.NUMBER:
			case DslParser.TERM_WITH_OPTIONS:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 229;
				this.directItem();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public directItem(): DirectItemContext {
		let _localctx: DirectItemContext = new DirectItemContext(this._ctx, this.state);
		this.enterRule(_localctx, 26, DslParser.RULE_directItem);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 232;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << DslParser.STAR) | (1 << DslParser.STRING) | (1 << DslParser.NESTED_ID) | (1 << DslParser.DATE) | (1 << DslParser.NUMBER) | (1 << DslParser.TERM_WITH_OPTIONS))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public range(): RangeContext {
		let _localctx: RangeContext = new RangeContext(this._ctx, this.state);
		this.enterRule(_localctx, 28, DslParser.RULE_range);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 234;
			this.match(DslParser.OPEN_RANGE);
			this.state = 238;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DslParser.WS) {
				{
				{
				this.state = 235;
				this.match(DslParser.WS);
				}
				}
				this.state = 240;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 241;
			_la = this._input.LA(1);
			if (!(_la === DslParser.DATE || _la === DslParser.NUMBER)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			this.state = 243;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 242;
				this.match(DslParser.WS);
				}
				}
				this.state = 245;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (_la === DslParser.WS);
			this.state = 247;
			this.match(DslParser.T__3);
			this.state = 249;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 248;
				this.match(DslParser.WS);
				}
				}
				this.state = 251;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (_la === DslParser.WS);
			this.state = 253;
			_la = this._input.LA(1);
			if (!(_la === DslParser.DATE || _la === DslParser.NUMBER)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			this.state = 257;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DslParser.WS) {
				{
				{
				this.state = 254;
				this.match(DslParser.WS);
				}
				}
				this.state = 259;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 260;
			this.match(DslParser.CLOSE_RANGE);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\x13\u0109\x04" +
		"\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04" +
		"\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r" +
		"\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x03\x02\x03\x02\x03\x02\x03" +
		"\x03\x07\x03%\n\x03\f\x03\x0E\x03(\v\x03\x03\x03\x03\x03\x06\x03,\n\x03" +
		"\r\x03\x0E\x03-\x03\x03\x03\x03\x06\x032\n\x03\r\x03\x0E\x033\x03\x03" +
		"\x07\x037\n\x03\f\x03\x0E\x03:\v\x03\x03\x03\x07\x03=\n\x03\f\x03\x0E" +
		"\x03@\v\x03\x03\x04\x07\x04C\n\x04\f\x04\x0E\x04F\v\x04\x03\x04\x03\x04" +
		"\x05\x04J\n\x04\x03\x04\x06\x04M\n\x04\r\x04\x0E\x04N\x03\x04\x03\x04" +
		"\x06\x04S\n\x04\r\x04\x0E\x04T\x05\x04W\n\x04\x03\x04\x03\x04\x05\x04" +
		"[\n\x04\x07\x04]\n\x04\f\x04\x0E\x04`\v\x04\x03\x04\x07\x04c\n\x04\f\x04" +
		"\x0E\x04f\v\x04\x03\x05\x03\x05\x07\x05j\n\x05\f\x05\x0E\x05m\v\x05\x03" +
		"\x05\x03\x05\x07\x05q\n\x05\f\x05\x0E\x05t\v\x05\x03\x05\x03\x05\x03\x05" +
		"\x05\x05y\n\x05\x03\x06\x03\x06\x06\x06}\n\x06\r\x06\x0E\x06~\x03\x06" +
		"\x03\x06\x03\x07\x03\x07\x03\x07\x07\x07\x86\n\x07\f\x07\x0E\x07\x89\v" +
		"\x07\x03\x07\x03\x07\x05\x07\x8D\n\x07\x03\b\x03\b\x05\b\x91\n\b\x03\t" +
		"\x03\t\x03\t\x03\t\x03\n\x07\n\x98\n\n\f\n\x0E\n\x9B\v\n\x03\n\x03\n\x06" +
		"\n\x9F\n\n\r\n\x0E\n\xA0\x03\n\x03\n\x06\n\xA5\n\n\r\n\x0E\n\xA6\x03\n" +
		"\x07\n\xAA\n\n\f\n\x0E\n\xAD\v\n\x03\n\x07\n\xB0\n\n\f\n\x0E\n\xB3\v\n" +
		"\x03\v\x07\v\xB6\n\v\f\v\x0E\v\xB9\v\v\x03\v\x03\v\x05\v\xBD\n\v\x03\v" +
		"\x06\v\xC0\n\v\r\v\x0E\v\xC1\x03\v\x03\v\x06\v\xC6\n\v\r\v\x0E\v\xC7\x05" +
		"\v\xCA\n\v\x03\v\x03\v\x05\v\xCE\n\v\x07\v\xD0\n\v\f\v\x0E\v\xD3\v\v\x03" +
		"\v\x07\v\xD6\n\v\f\v\x0E\v\xD9\v\v\x03\f\x03\f\x06\f\xDD\n\f\r\f\x0E\f" +
		"\xDE\x03\f\x03\f\x03\r\x03\r\x05\r\xE5\n\r\x03\x0E\x03\x0E\x05\x0E\xE9" +
		"\n\x0E\x03\x0F\x03\x0F\x03\x10\x03\x10\x07\x10\xEF\n\x10\f\x10\x0E\x10" +
		"\xF2\v\x10\x03\x10\x03\x10\x06\x10\xF6\n\x10\r\x10\x0E\x10\xF7\x03\x10" +
		"\x03\x10\x06\x10\xFC\n\x10\r\x10\x0E\x10\xFD\x03\x10\x03\x10\x07\x10\u0102" +
		"\n\x10\f\x10\x0E\x10\u0105\v\x10\x03\x10\x03\x10\x03\x10\x02\x02\x02\x11" +
		"\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10\x02\x12\x02\x14" +
		"\x02\x16\x02\x18\x02\x1A\x02\x1C\x02\x1E\x02\x02\x04\x04\x02\n\f\x0F\x11" +
		"\x03\x02\x0F\x10\x02\u0121\x02 \x03\x02\x02\x02\x04&\x03\x02\x02\x02\x06" +
		"D\x03\x02\x02\x02\bx\x03\x02\x02\x02\nz\x03\x02\x02\x02\f\x8C\x03\x02" +
		"\x02\x02\x0E\x90\x03\x02\x02\x02\x10\x92\x03\x02\x02\x02\x12\x99\x03\x02" +
		"\x02\x02\x14\xB7\x03\x02\x02\x02\x16\xDA\x03\x02\x02\x02\x18\xE4\x03\x02" +
		"\x02\x02\x1A\xE8\x03\x02\x02\x02\x1C\xEA\x03\x02\x02\x02\x1E\xEC\x03\x02" +
		"\x02\x02 !\x05\x04\x03\x02!\"\x07\x02\x02\x03\"\x03\x03\x02\x02\x02#%" +
		"\x07\x13\x02\x02$#\x03\x02\x02\x02%(\x03\x02\x02\x02&$\x03\x02\x02\x02" +
		"&\'\x03\x02\x02\x02\')\x03\x02\x02\x02(&\x03\x02\x02\x02)8\x05\x06\x04" +
		"\x02*,\x07\x13\x02\x02+*\x03\x02\x02\x02,-\x03\x02\x02\x02-+\x03\x02\x02" +
		"\x02-.\x03\x02\x02\x02./\x03\x02\x02\x02/1\x07\x07\x02\x0202\x07\x13\x02" +
		"\x0210\x03\x02\x02\x0223\x03\x02\x02\x0231\x03\x02\x02\x0234\x03\x02\x02" +
		"\x0245\x03\x02\x02\x0257\x05\x06\x04\x026+\x03\x02\x02\x027:\x03\x02\x02" +
		"\x0286\x03\x02\x02\x0289\x03\x02\x02\x029>\x03\x02\x02\x02:8\x03\x02\x02" +
		"\x02;=\x07\x13\x02\x02<;\x03\x02\x02\x02=@\x03\x02\x02\x02><\x03\x02\x02" +
		"\x02>?\x03\x02\x02\x02?\x05\x03\x02\x02\x02@>\x03\x02\x02\x02AC\x07\x13" +
		"\x02\x02BA\x03\x02\x02\x02CF\x03\x02\x02\x02DB\x03\x02\x02\x02DE\x03\x02" +
		"\x02\x02EI\x03\x02\x02\x02FD\x03\x02\x02\x02GJ\x05\n\x06\x02HJ\x05\b\x05" +
		"\x02IG\x03\x02\x02\x02IH\x03\x02\x02\x02J^\x03\x02\x02\x02KM\x07\x13\x02" +
		"\x02LK\x03\x02\x02\x02MN\x03\x02\x02\x02NL\x03\x02\x02\x02NO\x03\x02\x02" +
		"\x02OV\x03\x02\x02\x02PR\x07\b\x02\x02QS\x07\x13\x02\x02RQ\x03\x02\x02" +
		"\x02ST\x03\x02\x02\x02TR\x03\x02\x02\x02TU\x03\x02\x02\x02UW\x03\x02\x02" +
		"\x02VP\x03\x02\x02\x02VW\x03\x02\x02\x02WZ\x03\x02\x02\x02X[\x05\n\x06" +
		"\x02Y[\x05\b\x05\x02ZX\x03\x02\x02\x02ZY\x03\x02\x02\x02[]\x03\x02\x02" +
		"\x02\\L\x03\x02\x02\x02]`\x03\x02\x02\x02^\\\x03\x02\x02\x02^_\x03\x02" +
		"\x02\x02_d\x03\x02\x02\x02`^\x03\x02\x02\x02ac\x07\x13\x02\x02ba\x03\x02" +
		"\x02\x02cf\x03\x02\x02\x02db\x03\x02\x02\x02de\x03\x02\x02\x02e\x07\x03" +
		"\x02\x02\x02fd\x03\x02\x02\x02gk\x07\x03\x02\x02hj\x07\x13\x02\x02ih\x03" +
		"\x02\x02\x02jm\x03\x02\x02\x02ki\x03\x02\x02\x02kl\x03\x02\x02\x02ln\x03" +
		"\x02\x02\x02mk\x03\x02\x02\x02nr\x05\x04\x03\x02oq\x07\x13\x02\x02po\x03" +
		"\x02\x02\x02qt\x03\x02\x02\x02rp\x03\x02\x02\x02rs\x03\x02\x02\x02su\x03" +
		"\x02\x02\x02tr\x03\x02\x02\x02uv\x07\x04\x02\x02vy\x03\x02\x02\x02wy\x05" +
		"\f\x07\x02xg\x03\x02\x02\x02xw\x03\x02\x02\x02y\t\x03\x02\x02\x02z|\x07" +
		"\t\x02\x02{}\x07\x13\x02\x02|{\x03\x02\x02\x02}~\x03\x02\x02\x02~|\x03" +
		"\x02\x02\x02~\x7F\x03\x02\x02\x02\x7F\x80\x03\x02\x02\x02\x80\x81\x05" +
		"\b\x05\x02\x81\v\x03\x02\x02\x02\x82\x83\x07\f\x02\x02\x83\x87\x07\x05" +
		"\x02\x02\x84\x86\x07\x13\x02\x02\x85\x84\x03\x02\x02\x02\x86\x89\x03\x02" +
		"\x02\x02\x87\x85\x03\x02\x02\x02\x87\x88\x03\x02\x02\x02\x88\x8A\x03\x02" +
		"\x02\x02\x89\x87\x03\x02\x02\x02\x8A\x8D\x05\x0E\b\x02\x8B\x8D\x05\x1C" +
		"\x0F\x02\x8C\x82\x03\x02\x02\x02\x8C\x8B\x03\x02\x02\x02\x8D\r\x03\x02" +
		"\x02\x02\x8E\x91\x05\x10\t\x02\x8F\x91\x05\x1A\x0E\x02\x90\x8E\x03\x02" +
		"\x02\x02\x90\x8F\x03\x02\x02\x02\x91\x0F\x03\x02\x02\x02\x92\x93\x07\x03" +
		"\x02\x02\x93\x94\x05\x12\n\x02\x94\x95\x07\x04\x02\x02\x95\x11\x03\x02" +
		"\x02\x02\x96\x98\x07\x13\x02\x02\x97\x96\x03\x02\x02\x02\x98\x9B\x03\x02" +
		"\x02\x02\x99\x97\x03\x02\x02\x02\x99\x9A\x03\x02\x02\x02\x9A\x9C\x03\x02" +
		"\x02\x02\x9B\x99\x03\x02\x02\x02\x9C\xAB\x05\x14\v\x02\x9D\x9F\x07\x13" +
		"\x02\x02\x9E\x9D\x03\x02\x02\x02\x9F\xA0\x03\x02\x02\x02\xA0\x9E\x03\x02" +
		"\x02\x02\xA0\xA1\x03\x02\x02\x02\xA1\xA2\x03\x02\x02\x02\xA2\xA4\x07\x07" +
		"\x02\x02\xA3\xA5\x07\x13\x02\x02\xA4\xA3\x03\x02\x02\x02\xA5\xA6\x03\x02" +
		"\x02\x02\xA6\xA4\x03\x02\x02\x02\xA6\xA7\x03\x02\x02\x02\xA7\xA8\x03\x02" +
		"\x02\x02\xA8\xAA\x05\x14\v\x02\xA9\x9E\x03\x02\x02\x02\xAA\xAD\x03\x02" +
		"\x02\x02\xAB\xA9\x03\x02\x02\x02\xAB\xAC\x03\x02\x02\x02\xAC\xB1\x03\x02" +
		"\x02\x02\xAD\xAB\x03\x02\x02\x02\xAE\xB0\x07\x13\x02\x02\xAF\xAE\x03\x02" +
		"\x02\x02\xB0\xB3\x03\x02\x02\x02\xB1\xAF\x03\x02\x02\x02\xB1\xB2\x03\x02" +
		"\x02\x02\xB2\x13\x03\x02\x02\x02\xB3\xB1\x03\x02\x02\x02\xB4\xB6\x07\x13" +
		"\x02\x02\xB5\xB4\x03\x02\x02\x02\xB6\xB9\x03\x02\x02\x02\xB7\xB5\x03\x02" +
		"\x02\x02\xB7\xB8\x03\x02\x02\x02\xB8\xBC\x03\x02\x02\x02\xB9\xB7\x03\x02" +
		"\x02\x02\xBA\xBD\x05\x16\f\x02\xBB\xBD\x05\x18\r\x02\xBC\xBA\x03\x02\x02" +
		"\x02\xBC\xBB\x03\x02\x02\x02\xBD\xD1\x03\x02\x02\x02\xBE\xC0\x07\x13\x02" +
		"\x02\xBF\xBE\x03\x02\x02\x02\xC0\xC1\x03\x02\x02\x02\xC1\xBF\x03\x02\x02" +
		"\x02\xC1\xC2\x03\x02\x02\x02\xC2\xC9\x03\x02\x02\x02\xC3\xC5\x07\b\x02" +
		"\x02\xC4\xC6\x07\x13\x02\x02\xC5\xC4\x03\x02\x02\x02\xC6\xC7\x03\x02\x02" +
		"\x02\xC7\xC5\x03\x02\x02\x02\xC7\xC8\x03\x02\x02\x02\xC8\xCA\x03\x02\x02" +
		"\x02\xC9\xC3\x03\x02\x02\x02\xC9\xCA\x03\x02\x02\x02\xCA\xCD\x03\x02\x02" +
		"\x02\xCB\xCE\x05\x16\f\x02\xCC\xCE\x05\x18\r\x02\xCD\xCB\x03\x02\x02\x02" +
		"\xCD\xCC\x03\x02\x02\x02\xCE\xD0\x03\x02\x02\x02\xCF\xBF\x03\x02\x02\x02" +
		"\xD0\xD3\x03\x02\x02\x02\xD1\xCF\x03\x02\x02\x02\xD1\xD2\x03\x02\x02\x02" +
		"\xD2\xD7\x03\x02\x02\x02\xD3\xD1\x03\x02\x02\x02\xD4\xD6\x07\x13\x02\x02" +
		"\xD5\xD4\x03\x02\x02\x02\xD6\xD9\x03\x02\x02\x02\xD7\xD5\x03\x02\x02\x02" +
		"\xD7\xD8\x03\x02\x02\x02\xD8\x15\x03\x02\x02\x02\xD9\xD7\x03\x02\x02\x02" +
		"\xDA\xDC\x07\t\x02\x02\xDB\xDD\x07\x13\x02\x02\xDC\xDB\x03\x02\x02\x02" +
		"\xDD\xDE\x03\x02\x02\x02\xDE\xDC\x03\x02\x02\x02\xDE\xDF\x03\x02\x02\x02" +
		"\xDF\xE0\x03\x02\x02\x02\xE0\xE1\x05\x18\r\x02\xE1\x17\x03\x02\x02\x02" +
		"\xE2\xE5\x05\x10\t\x02\xE3\xE5\x05\x1A\x0E\x02\xE4\xE2\x03\x02\x02\x02" +
		"\xE4\xE3\x03\x02\x02\x02\xE5\x19\x03\x02\x02\x02\xE6\xE9\x05\x1E\x10\x02" +
		"\xE7\xE9\x05\x1C\x0F\x02\xE8\xE6\x03\x02\x02\x02\xE8\xE7\x03\x02\x02\x02" +
		"\xE9\x1B\x03\x02\x02\x02\xEA\xEB\t\x02\x02\x02\xEB\x1D\x03\x02\x02\x02" +
		"\xEC\xF0\x07\r\x02\x02\xED\xEF\x07\x13\x02\x02\xEE\xED\x03\x02\x02\x02" +
		"\xEF\xF2\x03\x02\x02\x02\xF0\xEE\x03\x02\x02\x02\xF0\xF1\x03\x02\x02\x02" +
		"\xF1\xF3\x03\x02\x02\x02\xF2\xF0\x03\x02\x02\x02\xF3\xF5\t\x03\x02\x02" +
		"\xF4\xF6\x07\x13\x02\x02\xF5\xF4\x03\x02\x02\x02\xF6\xF7\x03\x02\x02\x02" +
		"\xF7\xF5\x03\x02\x02\x02\xF7\xF8\x03\x02\x02\x02\xF8\xF9\x03\x02\x02\x02" +
		"\xF9\xFB\x07\x06\x02\x02\xFA\xFC\x07\x13\x02\x02\xFB\xFA\x03\x02\x02\x02" +
		"\xFC\xFD\x03\x02\x02\x02\xFD\xFB\x03\x02\x02\x02\xFD\xFE\x03\x02\x02\x02" +
		"\xFE\xFF\x03\x02\x02\x02\xFF\u0103\t\x03\x02\x02\u0100\u0102\x07\x13\x02" +
		"\x02\u0101\u0100\x03\x02\x02\x02\u0102\u0105\x03\x02\x02\x02\u0103\u0101" +
		"\x03\x02\x02\x02\u0103\u0104\x03\x02\x02\x02\u0104\u0106\x03\x02\x02\x02" +
		"\u0105\u0103\x03\x02\x02\x02\u0106\u0107\x07\x0E\x02\x02\u0107\x1F\x03" +
		"\x02\x02\x02*&-38>DINTVZ^dkrx~\x87\x8C\x90\x99\xA0\xA6\xAB\xB1\xB7\xBC" +
		"\xC1\xC7\xC9\xCD\xD1\xD7\xDE\xE4\xE8\xF0\xF7\xFD\u0103";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!DslParser.__ATN) {
			DslParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(DslParser._serializedATN));
		}

		return DslParser.__ATN;
	}

}

export class DslContext extends ParserRuleContext {
	public sequenceOr(): SequenceOrContext {
		return this.getRuleContext(0, SequenceOrContext);
	}
	public EOF(): TerminalNode { return this.getToken(DslParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_dsl; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitDsl) {
			return visitor.visitDsl(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class SequenceOrContext extends ParserRuleContext {
	public sequenceAnd(): SequenceAndContext[];
	public sequenceAnd(i: number): SequenceAndContext;
	public sequenceAnd(i?: number): SequenceAndContext | SequenceAndContext[] {
		if (i === undefined) {
			return this.getRuleContexts(SequenceAndContext);
		} else {
			return this.getRuleContext(i, SequenceAndContext);
		}
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	public BOOL_OR(): TerminalNode[];
	public BOOL_OR(i: number): TerminalNode;
	public BOOL_OR(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.BOOL_OR);
		} else {
			return this.getToken(DslParser.BOOL_OR, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_sequenceOr; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitSequenceOr) {
			return visitor.visitSequenceOr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class SequenceAndContext extends ParserRuleContext {
	public itemGroupNot(): ItemGroupNotContext[];
	public itemGroupNot(i: number): ItemGroupNotContext;
	public itemGroupNot(i?: number): ItemGroupNotContext | ItemGroupNotContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ItemGroupNotContext);
		} else {
			return this.getRuleContext(i, ItemGroupNotContext);
		}
	}
	public itemGroup(): ItemGroupContext[];
	public itemGroup(i: number): ItemGroupContext;
	public itemGroup(i?: number): ItemGroupContext | ItemGroupContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ItemGroupContext);
		} else {
			return this.getRuleContext(i, ItemGroupContext);
		}
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	public BOOL_AND(): TerminalNode[];
	public BOOL_AND(i: number): TerminalNode;
	public BOOL_AND(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.BOOL_AND);
		} else {
			return this.getToken(DslParser.BOOL_AND, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_sequenceAnd; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitSequenceAnd) {
			return visitor.visitSequenceAnd(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ItemGroupContext extends ParserRuleContext {
	public sequenceOr(): SequenceOrContext | undefined {
		return this.tryGetRuleContext(0, SequenceOrContext);
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	public item(): ItemContext | undefined {
		return this.tryGetRuleContext(0, ItemContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_itemGroup; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitItemGroup) {
			return visitor.visitItemGroup(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ItemGroupNotContext extends ParserRuleContext {
	public BOOL_NOT(): TerminalNode { return this.getToken(DslParser.BOOL_NOT, 0); }
	public itemGroup(): ItemGroupContext {
		return this.getRuleContext(0, ItemGroupContext);
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_itemGroupNot; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitItemGroupNot) {
			return visitor.visitItemGroupNot(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ItemContext extends ParserRuleContext {
	public NESTED_ID(): TerminalNode | undefined { return this.tryGetToken(DslParser.NESTED_ID, 0); }
	public value(): ValueContext | undefined {
		return this.tryGetRuleContext(0, ValueContext);
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	public directItem(): DirectItemContext | undefined {
		return this.tryGetRuleContext(0, DirectItemContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_item; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitItem) {
			return visitor.visitItem(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueContext extends ParserRuleContext {
	public valueGroup(): ValueGroupContext | undefined {
		return this.tryGetRuleContext(0, ValueGroupContext);
	}
	public directValue(): DirectValueContext | undefined {
		return this.tryGetRuleContext(0, DirectValueContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_value; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitValue) {
			return visitor.visitValue(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueGroupContext extends ParserRuleContext {
	public valueOr(): ValueOrContext {
		return this.getRuleContext(0, ValueOrContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_valueGroup; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitValueGroup) {
			return visitor.visitValueGroup(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueOrContext extends ParserRuleContext {
	public valueAnd(): ValueAndContext[];
	public valueAnd(i: number): ValueAndContext;
	public valueAnd(i?: number): ValueAndContext | ValueAndContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ValueAndContext);
		} else {
			return this.getRuleContext(i, ValueAndContext);
		}
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	public BOOL_OR(): TerminalNode[];
	public BOOL_OR(i: number): TerminalNode;
	public BOOL_OR(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.BOOL_OR);
		} else {
			return this.getToken(DslParser.BOOL_OR, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_valueOr; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitValueOr) {
			return visitor.visitValueOr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueAndContext extends ParserRuleContext {
	public valueNot(): ValueNotContext[];
	public valueNot(i: number): ValueNotContext;
	public valueNot(i?: number): ValueNotContext | ValueNotContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ValueNotContext);
		} else {
			return this.getRuleContext(i, ValueNotContext);
		}
	}
	public valueGroupItem(): ValueGroupItemContext[];
	public valueGroupItem(i: number): ValueGroupItemContext;
	public valueGroupItem(i?: number): ValueGroupItemContext | ValueGroupItemContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ValueGroupItemContext);
		} else {
			return this.getRuleContext(i, ValueGroupItemContext);
		}
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	public BOOL_AND(): TerminalNode[];
	public BOOL_AND(i: number): TerminalNode;
	public BOOL_AND(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.BOOL_AND);
		} else {
			return this.getToken(DslParser.BOOL_AND, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_valueAnd; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitValueAnd) {
			return visitor.visitValueAnd(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueNotContext extends ParserRuleContext {
	public BOOL_NOT(): TerminalNode { return this.getToken(DslParser.BOOL_NOT, 0); }
	public valueGroupItem(): ValueGroupItemContext {
		return this.getRuleContext(0, ValueGroupItemContext);
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_valueNot; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitValueNot) {
			return visitor.visitValueNot(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueGroupItemContext extends ParserRuleContext {
	public valueGroup(): ValueGroupContext | undefined {
		return this.tryGetRuleContext(0, ValueGroupContext);
	}
	public directValue(): DirectValueContext | undefined {
		return this.tryGetRuleContext(0, DirectValueContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_valueGroupItem; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitValueGroupItem) {
			return visitor.visitValueGroupItem(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DirectValueContext extends ParserRuleContext {
	public range(): RangeContext | undefined {
		return this.tryGetRuleContext(0, RangeContext);
	}
	public directItem(): DirectItemContext | undefined {
		return this.tryGetRuleContext(0, DirectItemContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_directValue; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitDirectValue) {
			return visitor.visitDirectValue(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DirectItemContext extends ParserRuleContext {
	public NESTED_ID(): TerminalNode | undefined { return this.tryGetToken(DslParser.NESTED_ID, 0); }
	public STRING(): TerminalNode | undefined { return this.tryGetToken(DslParser.STRING, 0); }
	public TERM_WITH_OPTIONS(): TerminalNode | undefined { return this.tryGetToken(DslParser.TERM_WITH_OPTIONS, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(DslParser.NUMBER, 0); }
	public DATE(): TerminalNode | undefined { return this.tryGetToken(DslParser.DATE, 0); }
	public STAR(): TerminalNode | undefined { return this.tryGetToken(DslParser.STAR, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_directItem; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitDirectItem) {
			return visitor.visitDirectItem(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class RangeContext extends ParserRuleContext {
	public OPEN_RANGE(): TerminalNode { return this.getToken(DslParser.OPEN_RANGE, 0); }
	public CLOSE_RANGE(): TerminalNode { return this.getToken(DslParser.CLOSE_RANGE, 0); }
	public DATE(): TerminalNode[];
	public DATE(i: number): TerminalNode;
	public DATE(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.DATE);
		} else {
			return this.getToken(DslParser.DATE, i);
		}
	}
	public NUMBER(): TerminalNode[];
	public NUMBER(i: number): TerminalNode;
	public NUMBER(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.NUMBER);
		} else {
			return this.getToken(DslParser.NUMBER, i);
		}
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DslParser.WS);
		} else {
			return this.getToken(DslParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DslParser.RULE_range; }
	// @Override
	public accept<Result>(visitor: DslVisitor<Result>): Result {
		if (visitor.visitRange) {
			return visitor.visitRange(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


