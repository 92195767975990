import request from 'axios';
import {
    cmAuthUtils,
} from '../../../../global/utils/utils.js';
import { appReduxStore } from '../../../../global/configureReduxStore.js';
import ApiConfig from '../../../../global/api/apiConfig';
import ApiUtils from '../../../../global/apiUtils.js';
import PersonManagerActionCreators from '../../../actionCreators/Person/Manager/PersonManagerActionCreators.js';

export default class PersonManagerAPIUtils {
    static getPerson(id, ministryId, churchEntityId, includeContactDetails) {
        let url = `${ApiConfig.get()}/api/person/${id}?suppressMergeRedirect=true`;

        if (ministryId && churchEntityId) {
            url += `&ministryId=${ministryId}&churchEntityId=${churchEntityId}`;
        }

        if (includeContactDetails) {
            url += '&includeContactDetails=true';
        }

        return new Promise((resolve, reject) => {
            request
                .get(url, {
                    headers: { Authorization: `Bearer ${cmAuthUtils.getAccessToken()}` },
                })
                .then((res) => {
                    if (res.data.newPersonId !== undefined) {
                        PersonManagerActionCreators.goToPerson(res.data.newPersonId);
                    } else {
                        PersonManagerActionCreators.getPersonCompleted(res.data);
                    }

                    resolve(res);
                })
                .catch((res) => {
                    if (res.response && res.response.status === 404) {
                        appReduxStore.dispatch({ type: 'ON_404_NOT_FOUND_RECVD' });
                        return;
                    }

                    ApiUtils.handleError(res);
                    reject(res);
                });
        });
    }

    static getPersonLite(personId) {
        const url = `${ApiConfig.get()}/api/v2/person/${personId}?suppressMergeRedirect=true&expand=address,avatar,emergencycontacts,contactpreference,email,occupation,phone,preferredservice,photo,milestone`;

        return new Promise((resolve, reject) => {
            request
                .get(url, {
                    headers: { Authorization: `Bearer ${cmAuthUtils.getAccessToken()}` }, // eslint-disable-line
                })
                .then((res) => {
                    if (res.data.newPersonId !== undefined) {
                        PersonManagerActionCreators.goToPerson(res.data.newPersonId);
                    } else {
                        PersonManagerActionCreators.getPersonCompleted(res.data);
                    }
                    resolve(res);
                })
                .catch((res) => {
                    if (res.response && res.response.status === 404) {
                        appReduxStore.dispatch({ type: 'ON_404_NOT_FOUND_RECVD' });
                        return;
                    }

                    ApiUtils.handleError(res);
                    reject(res);
                });
        });
    }

    static updatePerson(originalPerson) {
        const person = {
            ...originalPerson,
            gender: (originalPerson.gender === ' ') ? null : originalPerson.gender,
            maritalStatus: (originalPerson.maritalStatus === ' ') ? null : originalPerson.maritalStatus,
        };

        return new Promise((resolve, reject) => {
            request
                .put(`${ApiConfig.get()}/api/person/${person.id}`, person, {
                    headers: {
                        Authorization: `Bearer ${cmAuthUtils.getAccessToken()}`,
                        'X-Referrer': window.location.href,
                    },
                })
                .then((res) => {
                    PersonManagerActionCreators.savePersonCompleted(res.data);
                    resolve(res);
                })
                .catch((res) => {
                    ApiUtils.handleError(res);
                    reject(res);
                });
        });
    }

    static insertPerson(
        person,
        sourceOverride,
        ministryId,
        churchEntityId,
        hasConsentedToSms = false,
    ) {
        const requestHeaders = {
            Authorization: `Bearer ${cmAuthUtils.getAccessToken()}`,
            'X-Referrer': window.location.href,
        };

        if (sourceOverride) {
            requestHeaders['X-Source'] = sourceOverride;
        }

        let url = `${ApiConfig.get()}/api/person?hasConsentedToSms=${hasConsentedToSms}`;

        if (ministryId && churchEntityId) {
            url += `&ministryId=${ministryId}&churchEntityId=${churchEntityId}`;
        }

        return new Promise((resolve, reject) => {
            request
                .post(url, person, {
                    headers: requestHeaders,
                })
                .then((res) => {
                    PersonManagerActionCreators.savePersonCompleted(res.data);
                    resolve(res);
                })
                .catch((res) => {
                    ApiUtils.handleError(res);
                    reject(res);
                });
        });
    }

    static saveMembershipStatus(
        personId,
        newMembershipStatus,
        note,
        noteSensitive,
        disengagementReason,
    ) {
        const additionalParams = {
            headers: {
                Authorization: `Bearer ${cmAuthUtils.getAccessToken()}`,
                'X-Referrer': window.location.href,
            },
        };

        const payload = {
            disengagementReason: disengagementReason || null,
            newStatus: newMembershipStatus,
            note: note || null,
            noteSensitive: noteSensitive || false,
        };

        return new Promise((resolve, reject) => {
            request
                .put(`${ApiConfig.get()}/api/person/${personId}/membership-status`, payload, additionalParams)
                .then((res) => {
                    PersonManagerActionCreators.saveMembershipStatusCompleted(res.data);
                    resolve(res);
                })
                .catch((res) => {
                    ApiUtils.handleError(res);
                    reject(res);
                });
        });
    }
}
