import {
    filter,
    isNil,
} from 'lodash';
import {
    BaseInstallmentPaymentPlan,
} from './models';
import { addOrReplaceBy } from '../../../global/utils';
import ActionType from './installmentPaymentPlan.actionTypes';

type InstallmentPaymentPlanState = {
    isDirty: boolean;
    plans: BaseInstallmentPaymentPlan[];
};

export const DEFAULT_STATE: InstallmentPaymentPlanState = {
    isDirty: false,
    plans: [],
};

const updateInstallmentPaymentPlanOptionAction = (
    existingState: InstallmentPaymentPlanState,
    installmentPaymentPlanOptionId: number,
    objWithUpdates: BaseInstallmentPaymentPlan,
): InstallmentPaymentPlanState => {
    const updatedPlans = filter(
        addOrReplaceBy(
            existingState.plans,
            (ippo) => ippo.id === installmentPaymentPlanOptionId,
            (existingInstallmentPaymentPlanOption) => {
                if (!isNil(existingInstallmentPaymentPlanOption)) {
                    return {
                        ...existingInstallmentPaymentPlanOption,
                        ...objWithUpdates,
                    };
                }

                return null;
            },
        ),
        (a) => !isNil(a),
    );

    return {
        ...existingState,
        isDirty: true,
        plans: updatedPlans,
    };
};

export default (
    state: InstallmentPaymentPlanState | undefined,
    action: any,
): InstallmentPaymentPlanState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REMOVE_INSTALLMENT_PAYMENT_PLAN_OPTION_RANGE: {
                const { value: id } = action;

                const filteredInstallmentPaymentPlanOptions = filter(
                    state.plans,
                    (planOption) => planOption.id !== id,
                );

                return {
                    ...state,
                    isDirty: true,
                    plans: filteredInstallmentPaymentPlanOptions,
                };
            }

            case ActionType.RESET_DATA: {
                return DEFAULT_STATE;
            }

            case ActionType.SET_ADD_NEW_INSTALLMENT_PAYMENT_PLAN_OPTION_RANGE: {
                const { value: newInstallmentPaymentPlanOptionRange } = action;

                return {
                    ...state,
                    isDirty: true,
                    plans: [...state.plans, newInstallmentPaymentPlanOptionRange],
                };
            }

            case ActionType.SET_INSTALLMENT_PAYMENT_PLAN_LIST: {
                const { value: installmentPaymentPlanList } = action;

                return {
                    isDirty: false,
                    plans: [...installmentPaymentPlanList],
                };
            }

            case ActionType.SET_INSTALLMENT_PAYMENT_PLAN_OPTION: {
                const { value: installmentPaymentPlanOption } = action;

                return updateInstallmentPaymentPlanOptionAction(
                    state,
                    installmentPaymentPlanOption.id,
                    installmentPaymentPlanOption,
                );
            }

            default:
                return state;
        }
    }

    return state;
};
