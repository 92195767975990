import LoginConstants from '../../js/constants/Login/LoginConstants.js';

export const DEFAULT_STATE = {
    dates: {},
    total: 0,
    totalByCategory: [],
    byCampus: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'WeekendFlashReportActions.ON_GET':
            return action.result;

        default:
            return state;
    }
};
