import PropTypes from 'prop-types';
import { i18n } from '../../../global/constants.js';

export const BEM_BLOCK_NAME = 'overview_task_list_page';

export const FilterPropType = PropTypes.shape({
    filterDueDateType: PropTypes.arrayOf(PropTypes.string),
    filterStatuses: PropTypes.arrayOf(PropTypes.string),
    searchQuery: PropTypes.string,
    sort: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
});

export const SORT_OPTIONS = [
    {
        label: i18n('Name (Ascending)'),
        value: 'name',
    }, {
        label: i18n('Name (Descending)'),
        value: '-name',
    }, {

        label: i18n('Received (Ascending)'),
        value: 'received',
    }, {
        label: i18n('Received (Descending)'),
        value: '-received',
    },
];

export const DUE_DATE_TYPE_OPTIONS = [
    {
        label: i18n('Past Due'),
        value: 'PastDue',
    },
    {
        label: i18n('Due Now'),
        value: 'DueToday',
    },
    {
        label: i18n('Upcoming'),
        value: 'Upcoming',
    },
];

export const TASK_STATUS_OPTIONS = [
    {
        label: i18n('Open'),
        value: 'Open',
    },
    {
        label: i18n('On Hold'),
        value: 'Blocked',
    },
];
