import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

// Setting UI page size to match APi page size of 150 in order to address bug:
// https://dev.azure.com/saddlebackchurch/Church%20Management/_workitems/edit/56648
const pagedServingOpps = new ChunkedPaginationUtils(150, 150);

export const DEFAULT_STATE = {
    results: [],
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedServingOpps.getPageSize(),
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'MinistryServingOpportunitySearchActions.ON_SEARCH':
            // TODO/FIXME: API does not provide a total count and because of this `_totalCount` was set to result.length.
            // If we want to be able to use `ChunkedPaginationUtils` correctly,
            // we will need API to report total number of results.
            pagedServingOpps.loadPage(
                action.result,
                action.result.length,
                action.callbackParams.first,
            );

            return {
                results: pagedServingOpps.getAll(true),
                total: pagedServingOpps.getTotalCount(),
                canLoadMore: pagedServingOpps.canLoadMore(),
                needsToRequest: pagedServingOpps.needsToLoadPage(),
                pageNumber: pagedServingOpps.getCurrentPageNumber(),
                pageSize: pagedServingOpps.getPageSize(),
            };

        case 'MinistryServingOpportunitySearchActions.ON_NEXTPAGE':
            return {
                results: pagedServingOpps.getAll(true),
                total: pagedServingOpps.getTotalCount(),
                canLoadMore: pagedServingOpps.canLoadMore(),
                needsToRequest: pagedServingOpps.needsToLoadPage(),
                pageNumber: pagedServingOpps.getCurrentPageNumber(),
                pageSize: pagedServingOpps.getPageSize(),
            };

        case 'MinistryServingOpportunitySearchActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
