import {
    SortTypes,
} from './models';
import {
    i18n,
} from '../../global/constants.js';

export const SERVING_OPPORTUNITY_PAGE_ACTION_BAR_MODE = {
    roster: Symbol('roster'),
    servingSchedule: Symbol('servingSchedule'),
};

export const VIEW_TYPE = {
    grid: 0,
    list: 1,
    calendar: 2,
};

export const SEARCH_TYPE_CLOSED = 'Closed';

export const UNSCHEDULED_EMAIL_CONFIRMATION_TYPE = 'UnscheduledEmailConfirmation';
export const SCHEDULED_EMAIL_CONFIRMATION_TYPE = 'ScheduledEmailConfirmation';

export const BEM_SERVING_OPPS_FILTERS_RAIL = 'serving_opportunity_filters_rail';

export const STATUS_RADIO_FILTER = {
    upcoming: `${BEM_SERVING_OPPS_FILTERS_RAIL}--radio_upcoming`,
    closed: `${BEM_SERVING_OPPS_FILTERS_RAIL}--radio_closed`,
};

export const ID_ADD_FOR_THIS_OCCURRENCE = 4;
export const ID_ADD_FOR_ALL_OCCURRENCES = 5;

export const SERVING_OPPORTUNITY_SORT_OPTIONS = [
    { label: i18n('First Name (Ascending)'), value: SortTypes.FirstNameASC },
    { label: i18n('First Name (Descending)'), value: SortTypes.FirstNameDSC },
    { label: i18n('Last Name (Ascending)'), value: SortTypes.LastNameASC },
    { label: i18n('Last Name (Descending)'), value: SortTypes.LastNameDSC },
    { label: i18n('Date Added (Ascending)'), value: SortTypes.DateAddedASC },
    { label: i18n('Date Added (Descending)'), value: SortTypes.DateAddedDSC },
];

export const SERVING_OPPORTUNITY_CHECKEDIN_STATUS_OPTIONS = [
    { label: i18n('Any'), value: null },
    { label: i18n('Checked In'), value: true },
    { label: i18n('Not Checked In'), value: false },
];

export const SERVING_OPPORTUNITY_SERVED_STATUS_OPTIONS = [
    { label: i18n('Any'), value: null },
    { label: i18n('Served'), value: true },
    { label: i18n('Not Served'), value: false },
];

export const MINISTRY_MEMBERS = 1;
export const MINISTRY_NON_MEMBERS = 2;

export const PUBLISH_TYPES = {
    notPublished: 1,
    publishedPrivate: 2,
    publishedPublic: 3,
};

export const STRING_PUBLISH_TYPES = {
    notPublished: 'NotPublished',
    publishedPrivate: 'PublishedPrivate',
    publishedPublic: 'PublishedPublic',
};

export const SERVING_OPPORTUNITY_MEMBERSHIP_OPTIONS = [
    {
        label: i18n('Members'),
        value: MINISTRY_MEMBERS,
    },
    {
        label: i18n('Non Members'),
        value: MINISTRY_NON_MEMBERS,
    },
];

export const EDIT_CAPACITY = 7;
export const BEM_BLOCK_NAME = 'serving_opportunities';

/* eslint-disable no-bitwise */
export const SERVING_OPPORTUNITY_REQUIREMENTS_FILTERS = {
    None: 0,
    Age: 1 << 1,
    Gender: 1 << 2,
    Milestones: 1 << 3,
    // add to below as added to above :)
    All: (1 << 1) | (1 << 2) | (1 << 3),
};
/* eslint-enable no-bitwise */

/* eslint-disable no-bitwise */
export const SERVING_OPPORTUNITY_REQUIREMENTS_FILTER_OPTIONS = [
    {
        label: i18n('Missing Requirements'),
        value: SERVING_OPPORTUNITY_REQUIREMENTS_FILTERS.All,
    },
];
/* eslint-enable no-bitwise */

export const PUBLISH_ERROR_PLURAL_MESSAGES = [
    {
        singularMessage: i18n('Serving Opportunity is not in a state where it can be published'),
        pluralMessage: i18n('Serving Opportunities are not in a state where they can be published'),
    },
    {
        singularMessage: i18n('You do not have permission to publish the Serving Opportunity'),
        pluralMessage: i18n('You do not have permission to publish the Serving Opportunities'),
    },
    {
        singularMessage: i18n('Serving Opportunity cannot be published to the web because it is in Draft Mode'),
        pluralMessage: i18n('Serving Opportunities cannot be published to the web because they are in Draft Mode'),
    },
    {
        singularMessage: i18n('Serving Opportunity was not found'),
        pluralMessage: i18n('Serving Opportunities were not found'),
    },
];
