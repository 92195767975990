import {
    isEmpty,
    without,
} from 'lodash';
import moment, { isMoment } from 'moment-timezone';
import { TimeZoneString } from '../../../global/models';
import {
    Filters,
    AccountFilterStatusId,
    SearchParams,
} from './models';

export const getFilteredArrayByIsChecked = (
    stateFiltersArr: string[],
    defaultFiltersArr: string[],
    filterId: string,
    isChecked: boolean,
) => {
    let newFilteredArr = [];

    if (isChecked) {
        newFilteredArr = [
            ...stateFiltersArr,
            filterId,
        ];
    } else if (stateFiltersArr.length === 1) {
        newFilteredArr = without(
            defaultFiltersArr,
            filterId,
        );

        if (defaultFiltersArr.length > 2) {
            newFilteredArr = [newFilteredArr[0]];
        }
    } else {
        newFilteredArr = without(
            stateFiltersArr,
            filterId,
        );
    }

    return newFilteredArr;
};

export const getSearchParams = (
    filters: Filters,
    searchValue: string,
) => {
    const searchParams: SearchParams = {};
    const timeZone: TimeZoneString = moment.tz.guess();

    const {
        accountStatuses,
        accountSetupDateEnd,
        accountSetupDateStart,
        sort,
    } = filters;

    if (!isEmpty(searchValue)) {
        searchParams.query = searchValue;
    }

    if (!isEmpty(accountStatuses) && accountStatuses.length === 1) {
        if (accountStatuses.includes(AccountFilterStatusId.Active)) {
            searchParams.isActive = true;
        } else {
            searchParams.isActive = false;
        }
    }

    if (isMoment(accountSetupDateEnd)) {
        searchParams.endDate = accountSetupDateEnd.clone().add(1, 'day').startOf('day').format('YYYY-MM-DD');
    }

    if (isMoment(accountSetupDateStart)) {
        searchParams.startDate = accountSetupDateStart.clone().startOf('day').format('YYYY-MM-DD');
    }

    if (isMoment(accountSetupDateEnd) || isMoment(accountSetupDateStart)) {
        searchParams.timeZone = timeZone;
    }

    searchParams.sortQuery = sort.value;

    return searchParams;
};
