import { combineReducers } from 'redux';
import filters from './filters.reducer';
import settings from './settingsActions.reducer';

const reducers = combineReducers({
    filters,
    settings, // Operations from Actions buttons -Duplicate/Close SO-
});

export default reducers;
