import emailVariables, { DEFAULT_STATE as EMAIL_VARIABLES_DEFAULT_STATE } from './emailVariables.js';
import locationNotification, { DEFAULT_STATE as LOCATION_NOTIFICATION_DEFAULT_STATE } from './locationNotification.js';
import locationNotifications, { DEFAULT_STATE as LOCATION_NOTIFICATIONS_DEFAULT_STATE } from './locationNotifications.js';
import locationSmsNotification, { DEFAULT_STATE as LOCATION_SMS_NOTIFICATION_DEFAULT_STATE } from './locationSmsNotification.js';
import locationSmsNotifications, { DEFAULT_STATE as LOCATION_SMS_NOTIFICATIONS_DEFAULT_STATE } from './locationSmsNotifications.js';
import LoginConstants from '../../js/constants/Login/LoginConstants.js';
import ministry, { DEFAULT_STATE as MINISTRY_DEFAULT_STATE } from './ministry.js';
import ministryCache, { DEFAULT_STATE as MINISTRY_CACHE_DEFAULT_STATE } from './ministryCache.js';
import ministryNotification, { DEFAULT_STATE as MINISTRY_NOTIFICATION_DEFAULT_STATE } from './ministryNotification.js';
import ministryNotifications, { DEFAULT_STATE as MINISTRY_NOTIFICATIONS_DEFAULT_STATE } from './ministryNotifications.js';
import quickView, { DEFAULT_STATE as QUICKVIEW_DEFAULT_STATE } from './quickView.js';
import servingOpportunityEmailRecipients, { DEFAULT_STATE as SERVING_OPPORTUNITY_EMAIL_RECIPIENTS_DEFAULT_STATE } from './servingOpportunityEmailRecipients.js';
import servingOpportunityEvent, { DEFAULT_STATE as SERVING_OPPORTUNITY_EVENT_DEFAULT_STATE } from './servingOpportunityEvent.js';
import servingOpportunityTag, { DEFAULT_STATE as SERVING_OPPORTUNITY_TAG_DEFAULT_STATE } from './servingOpportunityTag.js';
import servingOpportunityDraft, { DEFAULT_STATE as SERVING_OPPORTUNITY_DRAFT_DEFAULT_STATE } from './servingOpportunityDraft.js';
import servingOpportunityOccurrences, { DEFAULT_STATE as SERVING_OPPORTUNITY_OCCURRENCES_DEFAULT_STATE } from './servingOpportunityOccurrences.js';
import servingOpportunityScheduledVolunteers, { DEFAULT_STATE as SERVING_OPPORTUNITY_SCHEDULED_VOLUNTEERS_DEFAULT_STATE } from './servingOpportunityScheduledVolunteers.js';
import servingOpportunityUnscheduledVolunteers, { DEFAULT_STATE as SERVING_OPPORTUNITY_UNSCHEDULED_VOLUNTEERS_DEFAULT_STATE } from './servingOpportunityUnscheduledVolunteers.js';
import servingOpportunitySearch, { DEFAULT_STATE as SERVING_OPPORTUNITY_SEARCH_DEFAULT_STATE } from './servingOpportunitySearch.js';
import servingOpportunityStatistics, { DEFAULT_STATE as SERVING_OPPORTUNITY_STATISTICS_DEFAULT_STATE } from './servingOpportunityStatistics.js';
import servingOpportunitySearchByFilter, { DEFAULT_STATE as SERVING_OPPORTUNITY_SEARCH_BY_FILTER_DEFAULT_STATE } from './servingOpportunitySearchByFilter.js';
import servingOpportunitySearchRecipients, { DEFAULT_STATE as SERVING_OPPORTUNITY_SEARCH_RECIPIENTS_DEFAULT_STATE } from './servingOpportunitySearchRecipients.js';
import servingOpportunitySearchScheduledRecipients,
{ DEFAULT_STATE as SERVING_OPPORTUNITY_SEARCH_SCHEDULED_RECIPIENTS_DEFAULT_STATE }
    from '../servingOpportunities/servingOpportunitySearchScheduledRecipients.reducer.js';
import servingOpportunity, { DEFAULT_STATE as SERVING_OPPORTUNITY_DEFAULT_STATE } from './servingOpportunity.js';
import servingOpportunityList, { DEFAULT_STATE as SERVING_OPPORTUNITY_LIST_DEFAULT_STATE } from './servingOpportunityList.js';
import servingOpportunityVolunteers, { DEFAULT_STATE as SERVING_OPPORTUNITY_VOLUNTEERS_DEFAULT_STATE } from './servingOpportunityVolunteers.js';
import servingOpportunityAllVolunteers, { DEFAULT_STATE as SERVING_OPPORTUNITY_ALL_VOLUNTEERS_DEFAULT_STATE } from './servingOpportunityAllVolunteers.js';
import servingOpportunityNotification, { DEFAULT_STATE as SERVING_OPPORTUNITY_NOTIFICATION_DEFAULT_STATE } from './servingOpportunityNotification.js';
import servingOpportunityNotifications, { DEFAULT_STATE as SERVING_OPPORTUNITY_NOTIFICATIONS_DEFAULT_STATE } from './servingOpportunityNotifications.js';

const DEFAULT_STATE = {
    servingOpportunityEmailRecipients: SERVING_OPPORTUNITY_EMAIL_RECIPIENTS_DEFAULT_STATE,
    servingOpportunityEvent: SERVING_OPPORTUNITY_EVENT_DEFAULT_STATE,
    servingOpportunityTag: SERVING_OPPORTUNITY_TAG_DEFAULT_STATE,
    servingOpportunityDraft: SERVING_OPPORTUNITY_DRAFT_DEFAULT_STATE,
    servingOpportunityOccurrences: SERVING_OPPORTUNITY_OCCURRENCES_DEFAULT_STATE,
    servingOpportunityStatistics: SERVING_OPPORTUNITY_STATISTICS_DEFAULT_STATE,
    servingOpportunitySearch: SERVING_OPPORTUNITY_SEARCH_DEFAULT_STATE,
    servingOpportunitySearchByFilter: SERVING_OPPORTUNITY_SEARCH_BY_FILTER_DEFAULT_STATE,
    servingOpportunitySearchRecipients: SERVING_OPPORTUNITY_SEARCH_RECIPIENTS_DEFAULT_STATE,
    servingOpportunitySearchScheduledRecipients: SERVING_OPPORTUNITY_SEARCH_SCHEDULED_RECIPIENTS_DEFAULT_STATE, // eslint-disable-line max-len
    servingOpportunity: SERVING_OPPORTUNITY_DEFAULT_STATE,
    servingOpportunityList: SERVING_OPPORTUNITY_LIST_DEFAULT_STATE,
    servingOpportunityVolunteers: SERVING_OPPORTUNITY_VOLUNTEERS_DEFAULT_STATE,
    servingOpportunityAllVolunteers: SERVING_OPPORTUNITY_ALL_VOLUNTEERS_DEFAULT_STATE,
    servingOpportunityScheduledVolunteers: SERVING_OPPORTUNITY_SCHEDULED_VOLUNTEERS_DEFAULT_STATE,
    servingOpportunityUnscheduledVolunteers:
        SERVING_OPPORTUNITY_UNSCHEDULED_VOLUNTEERS_DEFAULT_STATE,
    emailVariables: EMAIL_VARIABLES_DEFAULT_STATE,
    servingOpportunityNotification: SERVING_OPPORTUNITY_NOTIFICATION_DEFAULT_STATE,
    servingOpportunityNotifications: SERVING_OPPORTUNITY_NOTIFICATIONS_DEFAULT_STATE,
    ministryNotification: MINISTRY_NOTIFICATION_DEFAULT_STATE,
    ministryNotifications: MINISTRY_NOTIFICATIONS_DEFAULT_STATE,
    locationNotification: LOCATION_NOTIFICATION_DEFAULT_STATE,
    locationNotifications: LOCATION_NOTIFICATIONS_DEFAULT_STATE,
    locationSmsNotification: LOCATION_SMS_NOTIFICATION_DEFAULT_STATE,
    locationSmsNotifications: LOCATION_SMS_NOTIFICATIONS_DEFAULT_STATE,
    quickView: QUICKVIEW_DEFAULT_STATE,
    ministryCache: MINISTRY_CACHE_DEFAULT_STATE,
    ministry: MINISTRY_DEFAULT_STATE,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'MinistryServingOpportunityTagActions.ON__GETALL':
            return {
                ...state,
                servingOpportunityTag: servingOpportunityTag(state.servingOpportunityTag, action),
            };

        case 'MinistryServingOpportunityDraftActions.ON_GET':
        case 'MinistryServingOpportunityDraftActions.ON_ADD':
        case 'MinistryServingOpportunityDraftActions.ON_UPDATE':
            return {
                ...state,
                servingOpportunityDraft: servingOpportunityDraft(
                    state.servingOpportunityDraft,
                    action,
                ),
            };

        case 'MinistryServingOpportunityActions.ON_GET':
        case 'MinistryServingOpportunityActions.ON_UPDATE':
        case 'MinistryServingOpportunityActions.ON_CLOSE':
        case 'MinistryServingOpportunityActions.ON_RESET':
            return {
                ...state,
                servingOpportunity: servingOpportunity(state.servingOpportunity, action),
            };

        case 'MinistryServingOpportunityActions.ON_LIST':
            return {
                ...state,
                servingOpportunityList: servingOpportunityList(
                    state.servingOpportunityList,
                    action,
                ),
            };

        case 'MinistryQuickviewActions.ON_NEXTPAGE':
        case 'MinistryQuickviewActions.ON_SEARCH':
        case 'MinistryQuickviewActions.ON_RESET':
            return {
                ...state,
                quickView: quickView(state.quickView, action),
            };

        case 'MinistryServingOpportunitySearchActions.ON_NEXTPAGE':
        case 'MinistryServingOpportunitySearchActions.ON_SEARCH':
        case 'MinistryServingOpportunitySearchActions.ON_RESET':
            return {
                ...state,
                servingOpportunitySearch: servingOpportunitySearch(
                    state.servingOpportunitySearch,
                    action,
                ),
            };

        case 'MinistryServingOpportunitySearchActions.ON_STATISTICS':
            return {
                ...state,
                servingOpportunityStatistics: servingOpportunityStatistics(
                    state.servingOpportunityStatistics,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_GETOCCURRENCES':
        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_OCCURRENCES':
        case 'MinistryServingOpportunityVolunteerActions.UPDATE_OCCURRENCE':
            return {
                ...state,
                servingOpportunityOccurrences: servingOpportunityOccurrences(
                    state.servingOpportunityOccurrences,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_RESET': {
            const defaultResult = servingOpportunityVolunteers(
                state.servingOpportunityVolunteers,
                action,
            );

            return {
                ...state,
                servingOpportunityVolunteers: defaultResult,
                servingOpportunitySearchByFilter: defaultResult,
                servingOpportunitySearchRecipients: defaultResult,
            };
        }

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCH':
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCH_NEXTPAGE':
            return {
                ...state,
                servingOpportunityVolunteers: servingOpportunityVolunteers(
                    state.servingOpportunityVolunteers,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_ALL':
            return {
                ...state,
                servingOpportunityAllVolunteers: servingOpportunityAllVolunteers(
                    state.servingOpportunityAllVolunteers,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHROSTERVOLUNTEERS':
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHROSTERVOLUNTEERS_NEXTPAGE':
            return {
                ...state,
                servingOpportunitySearchByFilter: servingOpportunitySearchByFilter(
                    state.servingOpportunitySearchByFilter,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHRECIPIENTS':
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHRECIPIENTS_NEXTPAGE':
        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_SEARCH_RECIPIENTS':
            return {
                ...state,
                servingOpportunitySearchRecipients: servingOpportunitySearchRecipients(
                    state.servingOpportunitySearchRecipients,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHSCHEDULEDRECIPIENTS':
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCH_SCHEDULED_RECIPIENTS_NEXTPAGE':
        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_SEARCH_SCHEDULED_RECIPIENTS':
            return {
                ...state,
                servingOpportunitySearchScheduledRecipients:
                servingOpportunitySearchScheduledRecipients(
                    state.servingOpportunitySearchScheduledRecipients,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHSCHEDULEDVOLUNTEERS':
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCH_SCHEDULED_VOLUNTEERS_NEXTPAGE':
        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_SEARCH_SCHEDULED_VOLUNTEERS':
        case 'MinistryServingOpportunityActions.ON_UPDATEOCCURRENCECAPACITY':
            return {
                ...state,
                servingOpportunityScheduledVolunteers: servingOpportunityScheduledVolunteers(
                    state.servingOpportunityScheduledVolunteers,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_BEFORE_GETUNSCHEDULEDVOLUNTEERS':
        case 'MinistryServingOpportunityVolunteerActions.ON_GETUNSCHEDULEDVOLUNTEERS':
        case 'MinistryServingOpportunityVolunteerActions.ON_GET_UNSCHEDULED_VOLUNTEERS_NEXTPAGE':
        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_UNSCHEDULED_VOLUNTEERS':
            return {
                ...state,
                servingOpportunityUnscheduledVolunteers: servingOpportunityUnscheduledVolunteers(
                    state.servingOpportunityUnscheduledVolunteers,
                    action,
                ),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_GET':
        case 'MinistryServingOpportunityVolunteerActions.ON_UPDATE':
            return {
                ...state,
                servingOpportunityUpdatedVolunteer: action.result,
            };

        case 'MinistryEmailActions.ON_EMAILVARIABLES':
            return {
                ...state,
                emailVariables: emailVariables(state.emailVariables, action),
            };

        case 'MinistryServingOpportunityActions.ON_NOTIFICATION':
            return {
                ...state,
                servingOpportunityNotification: servingOpportunityNotification(
                    state.servingOpportunityNotification,
                    action,
                ),
            };

        case 'MinistryServingOpportunityActions.ON_NOTIFICATIONS':
        case 'MinistryServingOpportunityActions.ON_NOTIFICATIONS_NEXTPAGE':
            return {
                ...state,
                servingOpportunityNotifications: servingOpportunityNotifications(
                    state.servingOpportunityNotifications,
                    action,
                ),
            };

        case 'MinistryServingOpportunityActions.ON_BEFORE_GETMULTIPLEOPPORTUNITYVOLUNTEERSINTERNAL':
        case 'MinistryServingOpportunityActions.ON_GETMULTIPLEOPPORTUNITYVOLUNTEERSINTERNAL':
        case 'MinistryServingOpportunityActions.ON_GET_MULTIPLE_OPPORTUNITY_VOLUNTEERS_NEXTPAGE':
        case 'MinistryServingOpportunityActions.RESET_MULTIPLE_OPPORTUNITY_VOLUNTEERS':
            return {
                ...state,
                servingOpportunityEmailRecipients: servingOpportunityEmailRecipients(
                    state.servingOpportunityEmailRecipients,
                    action,
                ),
            };

        case 'MinistryServingOpportunityActions.ON_EVENT':
            return {
                ...state,
                servingOpportunityEvent: servingOpportunityEvent(
                    state.servingOpportunityEvent,
                    action,
                ),
            };

        case 'MinistryNotificationActions.ON_NOTIFICATION':
            return {
                ...state,
                ministryNotification: ministryNotification(state.ministryNotification, action),
            };

        case 'MinistryNotificationActions.ON_NOTIFICATIONS':
        case 'MinistryNotificationActions.ON_NOTIFICATIONS_NEXTPAGE':
            return {
                ...state,
                ministryNotifications: ministryNotifications(state.ministryNotifications, action),
            };

        case 'MinistryNotificationActions.ON_NOTIFICATIONBYLOCATION':
            return {
                ...state,
                locationNotification: locationNotification(state.locationNotification, action),
            };

        case 'MinistryNotificationActions.ON_NOTIFICATIONSBYLOCATION':
        case 'MinistryNotificationActions.ON_NOTIFICATIONSBYLOCATION_NEXTPAGE':
            return {
                ...state,
                locationNotifications: locationNotifications(state.locationNotifications, action),
            };

        case 'MinistryNotificationActions.ON_SMSNOTIFICATIONBYLOCATION':
            return {
                ...state,
                locationSmsNotification: locationSmsNotification(
                    state.locationSmsNotification,
                    action,
                ),
            };

        case 'MinistryNotificationActions.ON_SMSNOTIFICATIONSBYLOCATION':
        case 'MinistryNotificationActions.ON_SMSNOTIFICATIONSBYLOCATION_NEXTPAGE':
            return {
                ...state,
                locationSmsNotifications: locationSmsNotifications(
                    state.locationSmsNotifications,
                    action,
                ),
            };

        case 'MinistryCacheActions.ON_GET':
        case 'MinistryCacheActions.ON_RESET':
            return {
                ...state,
                ministryCache: ministryCache(state.ministryCache, action),
            };

        case 'MinistryActions.ON_GETCATEGORIES':
        case 'MinistryActions.ON_GETTAGS':
        case 'MinistryActions.ON_GETLOCATIONLEADERS':
            return {
                ...state,
                ministry: ministry(state.ministry, action),
            };

        default:
            return state;
    }
};
