enum PromotedJourneySortOptions {
    CategoryName = 'CategoryName',
    CategoryNameDesc = 'CategoryNameDesc',
    Default = 'Default',
    PromotedOrder = 'PromotedOrder',
    PromotedOrderDesc = 'PromotedOrderDesc',
    JourneyName = 'JourneyName',
    JourneyNameDesc = 'JourneyNameDesc',
}

export default PromotedJourneySortOptions;
