import axios, { AxiosResponse } from 'axios';
import {
    ApiMethodType,
    ServiceType,
} from '../../../global/api/models';
import { ResponseStatus } from '../../../global/models';
import serviceError from '../../../global/api/serviceError';
import getBaseConfig from '../../../global/api/getBaseConfig';

export const getPersonalSMSConsent = async (personId: number) => {
    const axiosConfig = getBaseConfig({
        method: ApiMethodType.Get,
        params: {
            personId,
        },
        service: ServiceType.Notifications,
        url: '/api/notification/sms-consent/{personId}',
    });

    let response;

    try {
        response = (await axios(axiosConfig)).data as AxiosResponse['data'];
    } catch (error) {
        // we only want to show the error banner if the error status is not 404 Not Found
        // 404 Not Found is an expected status for people with no record of SMS Consent
        if (error.response?.status !== ResponseStatus.NOT_FOUND) {
            serviceError({
                response: error.response,
            });
        }

        response = error.response?.data as AxiosResponse['data'];
    }
    return response;
};

export const getSMSConsentSentDate = async (personId: number) => {
    const axiosConfig = getBaseConfig({
        method: ApiMethodType.Get,
        params: {
            personId,
        },
        service: ServiceType.Notifications,
        url: '/api/notification/sms-consent-request/{personId}/last-request-date',
    });

    let response;

    try {
        response = (await axios(axiosConfig)).data as AxiosResponse['data'];
    } catch (error) {
        serviceError({
            response: error.response,
        });
        response = error.response?.data as AxiosResponse['data'];
    }
    return response;
};

export const resendPersonalSMSConsentEmail = async (personId: number) => {
    const axiosConfig = getBaseConfig({
        method: ApiMethodType.Post,
        params: {
            personId,
        },
        service: ServiceType.Notifications,
        url: '/api/notification/sms-consent-request/{personId}',
    });

    let response;

    try {
        response = (await axios(axiosConfig)).data as AxiosResponse['data'];
    } catch (error) {
        serviceError({
            response: error.response,
        });
    }
    return response;
};
