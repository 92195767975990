import {
    trim,
    memoize,
} from 'lodash';
import {
    apiHostsDirectory,
    isValidService,
} from './apiHostsDirectory';
import {
    API_EVENT_HOST,
    API_HOST,
    API_JOURNEY_HOST,
    API_NOTIFICATION_HOST,
} from '../constants.js';
import {
    determineAppEnvironment,
} from '../../app/environments/determineAppEnvironment';
import {
    isPrivateHostname,
} from '../utils/utils.js';
import {
    EnvironmentApiHostDirectory,
    HealthyChurchEnvironment,
    ServiceType,
} from './models';

export class ApiConfig {
    /* eslint-disable @typescript-eslint/lines-between-class-members */
    private _environment: HealthyChurchEnvironment;
    private _environmentApiConfig: EnvironmentApiHostDirectory;
    private _environmentName: string;
    /* eslint-enable @typescript-eslint/lines-between-class-members */

    /* eslint-disable no-underscore-dangle */
    constructor() {
        this._environment = determineAppEnvironment();
        this._environmentName = HealthyChurchEnvironment[this._environment];
        this._environmentApiConfig = apiHostsDirectory[this._environment];
        this.getApiHost = memoize(this._getApiHost.bind(this));
    }

    public get(service = 'Core'): string {
        if (!isValidService(service)) {
            // eslint-disable-next-line no-console
            console.error(`Healthy Church API: '${service}' is not a valid service; unable to determine Host URL.`);

            return null;
        }

        return this.getApiHost(service);
    }

    public getApiHost(service: string): string {
        return this._getApiHost(service);
    }

    public getEnvironmentName(): string {
        return this._environmentName;
    }

    private _getApiHost(service = 'Core') {
        switch (service) {
            case ServiceType.Core:
            {
                if (API_HOST) {
                    const apiHost = trim(API_HOST, '"'); // on Windows, the environment variable's value is delimited by double-quotes
                    const scheme = isPrivateHostname(apiHost.split(':')[0]) ? 'http' : 'https';
                    return `${scheme}://${apiHost}`;
                }

                return this._environmentApiConfig.Core;
            }

            case ServiceType.Events:
            {
                if (API_EVENT_HOST) {
                    const apiHost = trim(API_EVENT_HOST, '"'); // on Windows, the environment variable's value is delimited by double-quotes
                    const scheme = isPrivateHostname(apiHost.split(':')[0]) ? 'http' : 'https';
                    return `${scheme}://${apiHost}`;
                }

                return this._environmentApiConfig.Events;
            }

            case ServiceType.Journey:
            {
                if (API_JOURNEY_HOST) {
                    const apiHost = trim(API_JOURNEY_HOST, '"'); // on Windows, the environment variable's value is delimited by double-quotes
                    const scheme = isPrivateHostname(apiHost.split(':')[0]) ? 'http' : 'https';
                    return `${scheme}://${apiHost}`;
                }

                return this._environmentApiConfig.Journey;
            }

            case ServiceType.Notifications:
            {
                if (API_NOTIFICATION_HOST) {
                    const apiHost = trim(API_NOTIFICATION_HOST, '"'); // on Windows, the environment variable's value is delimited by double-quotes
                    const scheme = isPrivateHostname(apiHost.split(':')[0]) ? 'http' : 'https';
                    return `${scheme}://${apiHost}`;
                }

                return this._environmentApiConfig.Notifications;
            }

            default:
                return this._environmentApiConfig[service];
        }
    }
    /* eslint-enable no-underscore-dangle */
}

const apiConfig = new ApiConfig();

export default apiConfig;
