import '../../scss/components/ButtonConfirmation.scss';

import _ from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from './Button.react';
import i18nUtils from '../../global/i18nUtils.js';
import Icon from './Icon.react';

const _i = i18nUtils.translationFactory('ButtonConfirmation');

export default class ButtonConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isActive: false };
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isUndefined(this.props.isActive) && !_.isEqual(nextProps.isActive, this.props.isActive)) {
            this.setState({ isActive: nextProps.isActive });
        }
    }

    render() {
        const {
            animateFrom,
            boxType,
            children,
            className,
            disabled,
            id,
            message,
            messageIcon,
            title,
            type,
            marginTop,
            style,
        } = this.props;
        const buttonContainerClasses = ClassNames('btn-confirmation-container', {
            'is-active': this.state.isActive,
            'btn-block': boxType === 'block',
            'btn-disabled': disabled,
            'offset-top': marginTop,
        });
        const buttonClasses = ClassNames('btn-confirmation', className, {
            'animate-from-left': !animateFrom || _.includes(animateFrom, 'left'),
            'animate-from-right': _.includes(animateFrom, 'right'),
        });
        const messageClasses = ClassNames('btn-inner-message', {
            'btn-alert': _.includes(type, 'alert'),
            'btn-blocked': _.includes(type, 'blocked'),
            'btn-closed': _.includes(type, 'closed'),
            'btn-opened': _.includes(type, 'opened'),
            'btn-reassigned': !type || _.includes(type, 'reassigned'),
            'btn-success': _.includes(type, 'success'),
        });

        return (
            <div
                className={buttonContainerClasses}
                id={id}
                style={style}
            >
                {children ? React.cloneElement(children, {
                    className: 'btn-confirmation-child',
                    onClick: this._onClick.bind(this),
                }) : null}

                <div className={buttonClasses} title={title}>
                    <div className="btn-inner">
                        <div className={messageClasses}>
                            {messageIcon ? <Icon color="inverse" size="s" type={messageIcon} /> : null}

                            <span className="copy">{message}</span>
                        </div>
                        <Button boxType="inline" className="btn-yes" onClick={this._onYesClick.bind(this)} disabled={disabled}>
                            <span className="copy">{_i('Yes')}</span>
                        </Button>

                        <Button boxType="inline" className="btn-no" onClick={this._onNoClick.bind(this)} disabled={disabled}>
                            <span className="copy">{_i('No')}</span>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    activate() {
        this.setState({ isActive: true });
    }

    deactivate() {
        this.setState({ isActive: false });
    }

    _onClick() {
        if (!this.props.disabled) {
            if (this.props.children && typeof this.props.children.props.onClick === 'function') {
                this.props.children.props.onClick();
            }

            this.setState({ isActive: true });
        }
    }

    _onNoClick() {
        if (typeof this.props.onDeclineClick === 'function') {
            this.props.onDeclineClick();
        }

        this.setState({ isActive: false });
    }

    _onYesClick() {
        if (typeof this.props.onConfirmClick === 'function') {
            this.props.onConfirmClick();
        }

        if (typeof this.props.onDeclineClick !== 'function') {
            this.setState({ isActive: false });
        }
    }
}

ButtonConfirmation.propTypes = {
    animateFrom: PropTypes.string,
    boxType: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    marginTop: PropTypes.bool,
    message: PropTypes.string,
    messageIcon: PropTypes.string,
    onConfirmClick: PropTypes.func.isRequired,
    onDeclineClick: PropTypes.func,
    style: PropTypes.object,
    title: PropTypes.string,
};
