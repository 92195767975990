import {
    reduce,
    size,
    split,
} from 'lodash';
import distance from 'jaro-winkler';

export const THRESHOLD = 0.875;

export const scorePhrase = (
    phrase: string,
    query: string,
    searchOptions: object = { caseSensitive: false },
) => {
    // if the query contains only one word, then it is better to calculate
    // its maximum distance to each word of the phrase
    if (size(split(query, ' ')) === 1) {
        const tokenizedPhrase = split(phrase, ' ');

        return reduce(
            tokenizedPhrase,
            (max_score, v) => Math.max(max_score, distance(v, query, searchOptions)),
            0,
        );
    }

    // if the query contains multiple words, then it is better to calculate
    // the distance from the full query to phrase
    return distance(phrase, query, searchOptions);
};
