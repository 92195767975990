/**
 * This component renders a single "Container" of Form Fields for a Connection Forms 2.0 Form Entry.
 * "Containers" are a presentational construct for grouping Form Fields within a Section.
 * As of right now, it is predominently used for Grouped Checkboxes.
 * (In theory it could be used for any kind of Fields, but in practice it's only used for Checkboxes.)
 */

import { Grid } from '@saddlebackchurch/react-cm-ui';
import {
    find,
    map,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { isAnswerRedacted } from '../../entryEditor/utils.js';
import Form from '../../../../global/form';
import FormEntryField from './formEntryField.jsx';

const propTypes = {
    answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    bemBlockName: PropTypes.string.isRequired,
    disableFields: PropTypes.bool,
    formContainerField: PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string,
    }).isRequired,
    hideRedactedAnswers: PropTypes.bool,
};

const defaultProps = {
    disableFields: false,
    hideRedactedAnswers: false,
};

function FormEntryContainer({
    answers,
    bemBlockName,
    disableFields,
    formContainerField,
    hideRedactedAnswers,
}) {
    const {
        fields,
        title: formContainerFieldLabel,
    } = formContainerField;

    // if needs to hide the redacted fields, and they are all like that,
    // don't need to render anything
    if (hideRedactedAnswers) {
        if (fields.every((field) => {
            const answer = find(answers, (a) => a.questionId === field.questionId);
            return isAnswerRedacted(answer);
        })) {
            return null;
        }
    }

    return (
        <Grid.Column width={12}>
            <Grid spacing={2}>
                {formContainerFieldLabel && <Form.Legend value={formContainerFieldLabel} />}

                {map(fields, (field) => {
                    const answer = find(answers, (a) => a.questionId === field.questionId);

                    if (hideRedactedAnswers && isAnswerRedacted(answer)) {
                        return null;
                    }

                    return (
                        <FormEntryField
                            answer={answer}
                            bemBlockName={bemBlockName}
                            disable={disableFields}
                            formField={field}
                            key={`container-field-${field.questionId}`}
                        />
                    );
                })}
            </Grid>
        </Grid.Column>
    );
}

FormEntryContainer.propTypes = propTypes;
FormEntryContainer.defaultProps = defaultProps;

export default FormEntryContainer;
