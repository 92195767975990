import { combineReducers } from 'redux';
import activity from './memberActivityReducer.js';
import details from './memberDetailsReducer.js';
import notes from './memberNotesReducer.js';
import person from './personReducer.js';

const reducers = combineReducers({
    activity,
    details,
    notes,
    person,
});

export default reducers;
