const DEFAULT_STATE = {
    dates: {},
    isFetching: true,
    isOverviewStatsFetching: false,
    lastUpdated: null,
    overview: {},
    statistics: {},
    taskGroups: [],
};

const overview = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'FollowUpOverviewActions.ON_BEFORE_GETFOLLOWUPOVERVIEW':
        case 'FollowUpOverviewActions.ON_BEFORE_GETALLFOLLOWUPSOVERVIEW':
            return {
                ...state,
                isFetching: true,
                taskGroups: [],
            };

        case 'FollowUpOverviewActions.ON_GETFOLLOWUPOVERVIEW':
        case 'FollowUpOverviewActions.ON_GETALLFOLLOWUPSOVERVIEW':
            return {
                ...state,
                dates: action.result.dates,
                isFetching: false,
                lastUpdated: Date.now(),
                statistics: action.result.statistics,
                taskGroups: action.result.tasks,
            };

        case 'FollowUpOverviewActions.ON_BEFORE_GETOVERVIEWSTATS':
        case 'FollowUpOverviewActions.ON_BEFORE_GETALLFOLLOWUPSOVERVIEWSTATS':
            return {
                ...state,
                isOverviewStatsFetching: true,
            };

        case 'FollowUpOverviewActions.ON_GETOVERVIEWSTATS':
        case 'FollowUpOverviewActions.ON_GETALLFOLLOWUPSOVERVIEWSTATS':
            return {
                ...state,
                overview: action.result,
            };

        case 'FollowUpOverviewActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default overview;
