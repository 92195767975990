import {
    Grid,
} from '@saddlebackchurch/react-cm-ui';
import {
    isFunction,
} from 'lodash';
import ClassNames from 'classnames';
import React from 'react';
import FormField from './formField.jsx';
import FormFieldDatePickerInputRange from './formFieldDatePickerInputRange';
import FormFieldImageUploader from './formFieldImageUploader';
import FormFieldInput from './formFieldInput';
import FormFieldset from './formFieldset.jsx';
import FormFieldTextArea from './formFieldTextArea';
import FormFieldMultiSelect from './formFieldMultiSelect';
import FormFieldSelect from './formFieldSelect';
import FormLegend from './formLegend.jsx';
import FormFieldDatePickerTimezone from './formFieldDatePickerTimezone';

type PropTypes = {
    children?: React.ReactNode;
    className?: string;
    id?: string;
    handleSubmit?: Function;
    onSubmit?: Function;
    style?: React.CSSProperties;
};

function Form({
    children = undefined,
    className = undefined,
    id = undefined,
    handleSubmit = undefined,
    onSubmit = undefined,
    style = {},
}: PropTypes) {
    const rootClasses = ClassNames(
        'form',
        className,
    );

    return (
        <form
            data-testid={id ?? 'form'}
            id={id}
            onSubmit={isFunction(handleSubmit) && isFunction(onSubmit) ?
                handleSubmit(onSubmit) :
                null}
        >
            <Grid
                className={rootClasses}
                spacing={2}
                style={{
                    marginBottom: -16.5,
                    marginTop: -16.5,
                    ...style,
                }}
            >
                {children}
            </Grid>
        </form>
    );
}

Form.Field = FormField;
Form.FieldDatePickerInputRange = FormFieldDatePickerInputRange;
Form.FieldImageUploader = FormFieldImageUploader;
Form.FieldInput = FormFieldInput;
Form.Fieldset = FormFieldset;
Form.FieldTextArea = FormFieldTextArea;
Form.FieldMultiSelect = FormFieldMultiSelect;
Form.FieldSelect = FormFieldSelect;
Form.Legend = FormLegend;
Form.FormFieldDatePickerTimezone = FormFieldDatePickerTimezone;

export default Form;
