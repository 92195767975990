import _ from 'lodash';

export const DEFAULT_STATE = null;

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryNotificationActions.ON_SMSNOTIFICATIONBYLOCATION':
            return { ...(_.isEmpty(action.result && action.result.list) ? DEFAULT_STATE : action.result.list[0]) };
    }

    return state;
}
