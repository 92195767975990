import _ from 'lodash';
import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const _pagedMembers = new ChunkedPaginationUtils();

const DEFAULT_STATE = {
    canLoadMore: false,
    isFetching: false,
    members: [],
    needsToRequest: false,
    pageNumber: 0,
    pageSize: _pagedMembers.getPageSize(),
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    const first = !_.isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        case 'MinistryMemberActions.ON_BEFORE_LISTMEMBERS':
        {
            const { first } = callbackParams;

            if (first) {
                return { ...state, isFetching: true };
            }
            return state;
        }

        case 'MinistryMemberActions.ON_LISTMEMBERS':
            const { members, total } = actionResult;
            _pagedMembers.loadPage(members, total, first);

            // Fall through to next page case

        case 'MinistryMemberActions.ON_LIST_MEMBERS_NEXT_PAGE':
            return {
                ...state,
                canLoadMore: _pagedMembers.canLoadMore(),
                isFetching: false,
                members: _pagedMembers.getAll(true),
                needsToRequest: _pagedMembers.needsToLoadPage(),
                pageNumber: _pagedMembers.getCurrentPageNumber(),
                pageSize: _pagedMembers.getPageSize(),
                total: first ? _pagedMembers.getTotalCount() : state.total,
            };

        case 'MinistryMemberActions.ON_RESET_MEMBERS_LIST':
            return DEFAULT_STATE;
    }

    return state;
};
