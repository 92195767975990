import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import PersonCoreMilestonesData from '../../global/models/personCoreMilestonesData.model';
import QuerySuggestion from '../models/querySuggestion.model';
import SearchResults from '../models/searchResults.model';

class PersonSearchActions extends ApiUtils {
    getPeople: (
        {
            query,
        } : {
            query: string;
        },
        bodyParams?: object | null,
        cancel?: Function | null,
    ) => Promise<SearchResults>;

    getCoreMilestonesByPersonIds: (
        params: number[],
    ) => Promise<PersonCoreMilestonesData[]>;

    typeAhead: (
        params: {
            query: string;
            includeDeceased?: boolean;
        },
    ) => Promise<Array<QuerySuggestion>>;
}

const actions = new PersonSearchActions('/api/');

actions.buildClass(
    {
        name: 'getPeople',
        route: 'v2/person/search{query}',
        rq: request.get,
    },
    {
        name: 'getCoreMilestonesByPersonIds',
        route: 'insights/person/core-milestone?personIds={personIds}',
        rq: request.get,
    },
    {
        name: 'typeAhead',
        route: 'person/typeahead?q={query}&includeDeceased={includeDeceased}',
        rq: request.get,
    },
);

export default actions;
