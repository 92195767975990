enum DigitalProgramButtomFormField {
    buttonLabel = 'buttonLabel',
    description = 'description',
    entityIds = 'entityIds',
    entities = 'entities',
    iconColorInHex = 'iconColorInHex',
    iconName = 'iconName',
    imageUrl = 'imageUrl',
    title = 'title',
    url = 'url',
}

export default DigitalProgramButtomFormField;
