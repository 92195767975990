enum FeatureConstants {
    AgreementManagement = 'Agreement Management',
    CheckIn_1_0 = 'Check-in 1.0',
    CommunicationSegmentManagement = 'Communication Segment Management',
    CommunicationsCentral = 'Communications Central',
    CommunicationsCentralCommunicationManagement = 'Communications Central Communication Management',
    ConnectionForms_2_0 = 'Connection Forms 2.0',
    ContentManagement = 'Content Management',
    DigitalProgramManagement = 'Digital Program Management',
    EventManagment_1_0 = 'Event Management 1.0',
    GivingCentral = 'Giving Central',
    GivingPaymentHistory = 'HC Giving Payment History',
    JourneyManagement = 'Journey Management',
    ManageGeneralLedgerChartOfAccounts = 'Finance Management',
    MinistrySMS = 'Ministry SMS',
    PaidEvents_2_0 = 'Paid Events 2.0',
    PersonRecordSettings_1_0 = 'Person Record Settings 1.0',
    PersonRecord_2_0 = 'Person Record 2.0',
    PushNotificationManagement = 'Push Notification Management',
    ServingOpportunityCustomFields = 'Serving Opportunity Custom Fields',
    MappNewEngagePlatform = 'MAPP REST APIs',
}

export default FeatureConstants;
