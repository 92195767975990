import PropTypes from 'prop-types';

export const BEM_BLOCK_NAME = 'educational_dialog';

export const IMAGES_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string,
}));

export const STEP_PROP_TYPE = PropTypes.shape({
    body: PropTypes.string.isRequired,
    doneLabel: PropTypes.string,
    heading: PropTypes.string.isRequired,
    callToAction: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
    }),
    images: IMAGES_PROP_TYPE,
});

export const STEPS_PROP_TYPE = PropTypes.arrayOf(STEP_PROP_TYPE);

export const SM_BP_CONTAINER_MAX_WIDTH = 496;
