import './relationshipConnectionCard.scss';

import {
    isEmpty,
    isFunction,
    isNil,
    trimEnd,
} from 'lodash';
import {
    Card,
    Header,
    Icon,
    Image,
    Popover,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import React from 'react';
import MemberStatusDot from '../../../global/memberStatusDot.jsx';
import RelationshipPopoverContent from './relationshipPopoverContent.jsx';
import { Relationship } from '../models';

type PropTypes = {
    children?: JSX.Element;
    className?: string;
    data: Relationship;
    isCardClickDisabled?: boolean;
    isLastSlide?: boolean;
    lastSlideDidMount: () => void;
    onClick?: (relationshipData: Relationship) => void;
    showRelationshipsTooltip?: boolean;
};

type StateTypes = {
    isTooltipOpen: boolean;
};

class RelationshipCard extends React.PureComponent<PropTypes, StateTypes> {
    anchorRef: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.openTooltip = this.openTooltip.bind(this);
        this.closeTooltip = this.closeTooltip.bind(this);
        this.anchorRef = React.createRef();

        this.state = {
            isTooltipOpen: false,
        };
    }

    componentDidMount() {
        const { isLastSlide, lastSlideDidMount } = this.props;

        if (isLastSlide) {
            lastSlideDidMount();
        }
    }

    onClick() {
        const {
            data,
            onClick,
        } = this.props;

        if (isFunction(onClick)) {
            onClick(data);
        }
    }

    openTooltip() {
        this.setState({ isTooltipOpen: true });
    }

    closeTooltip() {
        this.setState({ isTooltipOpen: false });
    }

    render() {
        const {
            children,
            className,
            data,
            isCardClickDisabled,
            showRelationshipsTooltip,
        } = this.props;

        const {
            isTooltipOpen,
        } = this.state;

        const bemBlock = 'person_relationship_card';

        const containerClasses = ClassNames(bemBlock, className, {
            'card-click-disabled': isCardClickDisabled,
        });

        if (isEmpty(data)) {
            return null;
        }

        const {
            age,
            connectionType,
            firstName,
            fullName,
            gender,
            isAddressMatch,
            lastName,
            maritalStatus,
            membershipStatus,
            ministries,
            profilePictureUrl,
            relation,
            relationshipType,
        } = data;

        const isConnectionTypeLeader =
            connectionType === 'Ministry Leader' || connectionType === 'Small Group Leader';
        let genderText;

        switch (gender) {
            case 'M':
                genderText = 'Male';

                break;
            case 'F':
                genderText = 'Female';

                break;
            default:
        }

        const ageText = !isNil(age) && age < 18 && `${age}`;
        const relationshipText = maritalStatus === 'Married' && 'Married';
        let statusesText = '';

        if (genderText) {
            statusesText += `${genderText} | `;
        }

        if (relationshipText) {
            statusesText += `${relationshipText} | `;
        }

        if (ageText) {
            statusesText += ageText;
        }

        statusesText = trimEnd(statusesText, ' | ');

        const shouldShowPopover = isConnectionTypeLeader &&
            showRelationshipsTooltip &&
            isTooltipOpen &&
            !isEmpty(ministries);

        return (
            <Popover
                content={<RelationshipPopoverContent personMinistries={ministries} />}
                mouseEvent="onMouseEnter"
                open={shouldShowPopover}
                placement="top"
                ref={this.anchorRef.current}
                width={400}
            >
                <div
                    onMouseEnter={this.openTooltip}
                    onMouseLeave={this.closeTooltip}
                    ref={this.anchorRef}
                >
                    <Card
                        className={containerClasses}
                        onClick={isCardClickDisabled ? undefined : this.onClick}
                    >
                        {!isConnectionTypeLeader && (
                            <Header
                                className={`${bemBlock}--relation_type`}
                                size="small"
                            >
                                {relation}
                            </Header>
                        )}

                        {isConnectionTypeLeader && (
                            <span className={`${bemBlock}--triangle_top_left`} />
                        )}

                        <div className={`${bemBlock}--details`}>
                            <div className={`${bemBlock}--images`}>
                                <Image
                                    className={`${bemBlock}--avatar`}
                                    name={`${firstName} ${lastName}`}
                                    size={44}
                                    src={profilePictureUrl}
                                    type={isConnectionTypeLeader ? 'user' : 'person'}
                                />

                                {isAddressMatch && !isConnectionTypeLeader && (
                                    <Icon
                                        color="disable"
                                        title="Same Address"
                                        type="home"
                                    />
                                )}
                            </div>

                            <div className={`${bemBlock}--person_info`}>
                                <div className={`${bemBlock}--person_info_row_first`}>
                                    <div className={`${bemBlock}--fullname`}>
                                        {fullName}
                                    </div>

                                    {!isConnectionTypeLeader && (
                                        <div className={`${bemBlock}--statuses`}>
                                            {statusesText}
                                        </div>
                                    )}

                                    {isConnectionTypeLeader && (
                                        <div className={`${bemBlock}--ministry_position`}>
                                            {relationshipType}
                                        </div>
                                    )}
                                </div>

                                <div className={`${bemBlock}--person_info_row_last`}>
                                    <div className={`${bemBlock}--membership_status`}>
                                        <MemberStatusDot
                                            memberStatus={membershipStatus}
                                        />

                                        <span className={`${bemBlock}--membership_status_text`}>
                                            {membershipStatus}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {children}
                    </Card>
                </div>
            </Popover>
        );
    }
}

export default RelationshipCard;
