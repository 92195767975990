import LoginConstants from '../../constants/Login/LoginConstants';

const DEFAULT_STATE = {
    template: null,
    templateList: [],
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'EmailTemplateActions.ON_GET':
            return { ...state, template: action.result };

        case 'EmailTemplateActions.ON_GETALL':
            return { ...state, templateList: action.result };
    }

    return state;
}
