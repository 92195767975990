import './personAvatar.scss';

import {
    Grid,
    Image,
    ModalDeprecated,
} from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import AppStore from '../../../js/stores/AppStore.js';
import BannerUtils from '../../../js/utils/BannerUtils.js';
import { translationFactory } from '../../../global/i18nUtils.js';
import AvatarManagerUpload from '../../../global/avatarManagerUpload.jsx';
import AddPersonRecordActions from './addPersonRecordActions.js';

const propTypes = {
    avatarImage: PropTypes.shape({
        filename: PropTypes.string,
        url: PropTypes.string,
    }),
    fullName: PropTypes.string,
    resetOnUnmount: PropTypes.bool,
};

const defaultProps = {
    avatarImage: {},
    fullName: '',
    resetOnUnmount: true,
};

const mapStateToProps = (state) => {
    const {
        people: {
            record: {
                personForm: {
                    avatarImage,
                },
            },
        },
    } = state;

    return {
        avatarImage,
    };
};

const i18n = translationFactory();
const MODAL_WIDTH_FOR_DESKTOP = 294;

class PersonAvatar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.uint32Array = new Uint32Array(1);

        this.state = {
            avatarInputKey: crypto.getRandomValues(this.uint32Array)[0],
            isAvatarSaved: false,
            isOpenAvatarImageModal: false,
        };
        this.onCloseAvatarImageModal = this.onCloseAvatarImageModal.bind(this);
        this.onOpenAvatarImageModal = this.onOpenAvatarImageModal.bind(this);
        this.onHandleAvatarImageChange = this.onHandleAvatarImageChange.bind(this);
        this.onRemoveAvatarImage = this.onRemoveAvatarImage.bind(this);
        this.onSetAvatar = this.onSetAvatar.bind(this);
    }

    componentWillUnmount() {
        const {
            resetOnUnmount,
        } = this.props;

        if (resetOnUnmount) {
            AddPersonRecordActions.resetPersonPhoto();
        }
    }

    onOpenAvatarImageModal() {
        this.setState((prevState) => ({
            isOpenAvatarImageModal: !prevState.isOpenAvatarImageModal,
        }));
    }

    onCloseAvatarImageModal() {
        this.setState({
            isOpenAvatarImageModal: false,
            avatarInputKey: crypto.getRandomValues(this.uint32Array)[0],
            isAvatarSaved: false,
        });
    }

    onHandleAvatarImageChange(e, modalOpen = false) {
        e.preventDefault();
        const reader = new FileReader();
        const avatarFile = e.target.files[0];
        const avatarFileName = e.target.files[0].name;
        const fileAllowedTypes = ['image/png', 'image/jpeg'];

        if (!avatarFile) {
            return;
        }

        if (avatarFile.size > AppStore.getUploadingOptions().maxEmailAttachmentSize) {
            BannerUtils.addBanner({
                children: (
                    <p style={{ margin: 0 }}>
                        {i18n('The attachment is too large')}
                    </p>
                ),
                id: 'ui-banner--attachment_too_large',
                level: 'error',
                timeout: true,
                type: 'notification',
            });
            return;
        }

        if (!fileAllowedTypes.includes(avatarFile.type)) {
            BannerUtils.addBanner({
                children: (
                    <p style={{ margin: 0 }}>
                        {i18n('Invalid File Format')}
                    </p>
                ),
                id: 'ui-banner--invalid_file_format',
                level: 'error',
                timeout: true,
                type: 'notification',
            });
            return;
        }

        reader.onloadend = () => {
            AddPersonRecordActions.updatePersonPhoto({
                filename: avatarFileName,
                url: reader.result,
            });
        };
        reader.readAsDataURL(avatarFile);
        if (modalOpen) {
            this.onOpenAvatarImageModal();
        }
    }

    onRemoveAvatarImage() {
        this.setState({
            avatarInputKey: crypto.getRandomValues(this.uint32Array)[0],
            isAvatarSaved: false,
        });
        AddPersonRecordActions.resetPersonPhoto();
    }

    onSetAvatar(name, url) {
        this.setState({
            isAvatarSaved: true,
        }, () => {
            AddPersonRecordActions.updatePersonPhoto({
                filename: name,
                url,
            });
            this.onOpenAvatarImageModal();
        });
    }

    render() {
        const {
            avatarInputKey,
            isAvatarSaved,
            isOpenAvatarImageModal,
        } = this.state;

        const {
            fullName,
            avatarImage: {
                filename: avatarFileName,
                url: avatarPreviewUrl,
            },
        } = this.props;

        const blockClassName = 'person_add--person_avatar';
        const blockClassNameImage = `${blockClassName}_image`;
        const blockClassNamesImage = ClassNames(blockClassNameImage, {
            'no-image': !isAvatarSaved && !fullName,
        });

        return (
            <Grid
                className={blockClassName}
                spacing={2}
            >
                <Grid.RowDeprecated columns={1}>
                    <Grid.Column>
                        <div className={`${blockClassName}_image_preview`}>
                            <Image
                                border={isAvatarSaved && avatarPreviewUrl ? 3 : undefined}
                                borderInverse={!!(isAvatarSaved && avatarPreviewUrl)}
                                className={blockClassNamesImage}
                                name={fullName || '+'}
                                size={100}
                                src={isAvatarSaved && avatarPreviewUrl ? avatarPreviewUrl : null}
                                style={{
                                    fontSize: '24px',
                                }}
                                type="person"
                            />
                            { avatarPreviewUrl && isAvatarSaved ?
                                (
                                    <span
                                        className={`${blockClassName}_remove_photo_link`}
                                        onClick={this.onRemoveAvatarImage}
                                        role="button"
                                    >
                                        {i18n('Remove Profile Photo')}
                                    </span>
                                ) : (
                                    <label
                                        className={`${blockClassName}_upload_photo_link`}
                                    >
                                        <input
                                            accept="image/png, image/jpeg"
                                            id={`${blockClassName}--file_upload`}
                                            type="file"
                                            className={`${blockClassName}--avatar_file_upload`}
                                            onChange={
                                                (e) => this.onHandleAvatarImageChange(e, true)
                                            }
                                            key={avatarInputKey}
                                        />
                                        {i18n('Upload Profile Photo')}
                                    </label>
                                )}
                        </div>
                        <ModalDeprecated
                            autoHeight
                            className={`${blockClassName}_upload_modal`}
                            isOpen={isOpenAvatarImageModal}
                            height="auto"
                            onClose={this.onCloseAvatarImageModal}
                            title={i18n('Profile Image')}
                            width={MODAL_WIDTH_FOR_DESKTOP}
                        >
                            <AvatarManagerUpload
                                avatarFileName={avatarFileName}
                                avatarPreviewUrl={avatarPreviewUrl}
                                onHandleAvatarImageChange={this.onHandleAvatarImageChange}
                                onSave={this.onSetAvatar}
                            />
                        </ModalDeprecated>
                    </Grid.Column>
                </Grid.RowDeprecated>
            </Grid>
        );
    }
}

PersonAvatar.propTypes = propTypes;
PersonAvatar.defaultProps = defaultProps;

export default connect(mapStateToProps)(PersonAvatar);
