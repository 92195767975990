import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';

/**
 * TODO: Need permission checks for each of these baed on user ID for:
 * Personal, Relationships, CoreMilestones and all other implemented endpoints.
 * A hasPermission boolean should be passed from here to APIUtils to fire off an event updating
 * isFetching and a new property (TBD) to explain to the components why isFetching was toggled to
 * false and endpoint was never called.
 */

class PersonActions extends ApiUtils {
    getPersonal: (
        param: {
            personId?: number;
            suppressMergeRedirect?: boolean | null;
        }
    ) => Promise<{}>;

    setPersonal(data) {
        appReduxStore.dispatch({
            type: 'PersonActions.ON_GETPERSONAL',
            result: data,
        });
    }

    resetPersonal() {
        appReduxStore.dispatch({
            type: 'PersonActions.ON_RESET_PERSONAL',
        });
    }
}

const actions = new PersonActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'getPersonal',
        route: 'v2/person/{personId}?suppressMergeRedirect={suppressMergeRedirect}&expand=address,avatar,emergencycontacts,contactpreference,email,occupation,phone,preferredservice,photo',
        rq: request.get,
    },
);

export default actions;
