import {
    filter,
    map,
} from 'lodash';
import moment from 'moment-timezone';

const DEFAULT_STATE = {
    isFetching: false,
    worshipServices: [],
    worshipServicesForSelect: [],
};

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'WorshipServicesActions.ON_BEFORE_GETWORSHIPSERVICES':
            return {
                ...state,
                isFetching: true,
            };

        case 'WorshipServicesActions.ON_GETWORSHIPSERVICES':
        {
            // We only want Worship Services with an Event record associated
            const filteredWorshipServices = filter(
                action.result,
                (ws) => ws.eventId !== 0,
            );

            return {
                ...state,
                isFetching: false,
                worshipServices: filteredWorshipServices,
                worshipServicesForSelect: map(
                    filteredWorshipServices,
                    (worshipSvcOccurrence) => ({
                        ...worshipSvcOccurrence,
                        label: moment
                            .tz(`${worshipSvcOccurrence.date}T${worshipSvcOccurrence.startTime}`, worshipSvcOccurrence.timeZone)
                            .format('L LT z'),
                        value: worshipSvcOccurrence.scheduleId,
                    }),
                ),
            };
        }

        case 'WorshipServicesActions.RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default reducer;
