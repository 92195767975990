import './contactForm.scss';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
    colorAlert,
} from '../../global/styles/colors.scss';
import {
    setAddress1,
    setAddress2,
    setCity,
    setCountry,
    setEmail,
    setPhone,
    setPhoneExtension,
    setPhoneType,
    setPostalCode,
    setRegion,
} from './contactFormActions.js';
import { translationFactory } from '../../global/i18nUtils.js';
import Form from '../../global/form';

const propTypes = {
    data: PropTypes.shape({
        address: PropTypes.shape({
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            isAddressValid: PropTypes.bool,
            isPostalCodeValid: PropTypes.bool,
            postalCode: PropTypes.string,
            isPostalCodeRequired: PropTypes.bool,
            country: PropTypes.string,
            countryAlpha2: PropTypes.string,
            countryAlpha3: PropTypes.string,
            region: PropTypes.string,
            regionCode: PropTypes.string,
        }),
        email: PropTypes.shape({
            isEmailValid: PropTypes.bool,
            value: PropTypes.string,
        }),
        phone: PropTypes.shape({
            countryCode: PropTypes.string,
            displayPhoneNumber: PropTypes.string,
            extension: PropTypes.number,
            isHome: PropTypes.bool,
            isMobile: PropTypes.bool,
            isPhoneValid: PropTypes.bool,
            isWork: PropTypes.bool,
            phoneNumber: PropTypes.phoneNumber,
            phoneType: PropTypes.string,
            phoneTypeId: PropTypes.number,
        }),
        isRequirementFullfilled: PropTypes.bool,
    }),
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

const defaultProps = {
    data: {},
    onBlur: undefined,
    onFocus: undefined,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            enumerations: {
                enumerations: {
                    countries,
                },
            },
        },
        people: {
            addPerson: {
                contactForm: {
                    data,
                },
            },
        },
    } = state;

    return {
        countries,
        data,
    };
};

const i18n = translationFactory();

function ContactForm(props) {
    const {
        data,
        onBlur,
        onFocus,
    } = props;
    const {
        address: {
            address1: address1Value,
            address2: address2Value,
            city: cityValue,
            isPostalCodeValid,
            postalCode: postalCodeValue,
            isPostalCodeRequired,
            country: countryValue,
            countryAlpha2: countryAlpha2Value,
            countryAlpha3: countryAlpha3Value,
            region: regionValue,
            regionCode: regionCodeValue,
        },
        email: {
            isEmailValid,
            value: emailValue,
        },
        phone: {
            extension: phoneExtensionValue,
            isHome,
            isMobile,
            isWork,
            phoneNumber: phoneNumberValue,
            isPhoneValid,
        },
        isRequirementFullfilled,
    } = data;
    const bemBlockName = 'person_add_contact_form';
    const inputElementName = `${bemBlockName}--input_phone`;

    const onAddress1Change = (value) => {
        setAddress1(value);
    };

    const onAddress2Change = (value) => {
        setAddress2(value);
    };

    const onCityChange = (value) => {
        setCity(value);
    };

    const onCountryChange = (value) => {
        setCountry(value);
    };

    const onEmailChange = (value) => {
        setEmail(value);
    };

    const onPhoneChange = (value, formattedNumber, dialCode, countryCode, isValid) => {
        setPhone({
            countryCode,
            displayPhoneNumber: formattedNumber,
            isValid,
            phoneNumber: value,
        });
    };

    const onPhoneExtensionChange = (value) => {
        setPhoneExtension(value);
    };

    const onPhoneTypeChange = (value) => {
        const isValueHomeId = value === `${inputElementName}_home`;
        const isValueMobileId = value === `${inputElementName}_mobile`;
        const isValueWorkId = value === `${inputElementName}_work`;

        setPhoneType({
            isHome: isValueHomeId,
            isMobile: isValueMobileId,
            isWork: isValueWorkId,
        });
    };

    const onPostalCodeChange = (value) => {
        setPostalCode(value);
    };

    const onRegionChange = (value) => {
        setRegion(value);
    };

    return (
        <Form className={`${bemBlockName}--form`}>
            <Form.Fieldset
                className={`${bemBlockName}--field_set`}
                id={`${bemBlockName}--field_set`}
                title={(
                    <React.Fragment>
                        {i18n('Contact')}
                        {!isRequirementFullfilled && (
                            <span style={{ color: colorAlert }}> *</span>
                        )}
                    </React.Fragment>
                    )}
            >
                <Form.Legend
                    className={`${bemBlockName}--field_legend`}
                    value={i18n('One form of contact is required.')}
                />

                <Form.Field
                    error={!!emailValue && !isEmailValid}
                    fieldId={`${bemBlockName}--input_email`}
                    fieldType="inputEmail"
                    label={i18n('Email')}
                    onBlur={() => onBlur('email', emailValue)}
                    onFocus={() => onFocus('email', emailValue)}
                    onChange={onEmailChange}
                    required={!isRequirementFullfilled}
                    value={emailValue}
                />

                <Form.Field
                    error={!!phoneNumberValue && !isPhoneValid}
                    fieldId={inputElementName}
                    fieldProps={{
                        extension: phoneExtensionValue,
                        isHome,
                        isMobile,
                        isWork,
                        onPhoneExtensionChange,
                        onPhoneTypeChange,
                    }}
                    fieldType="inputTel"
                    label={i18n('Phone')}
                    onChange={onPhoneChange}
                    required={!isRequirementFullfilled}
                    value={phoneNumberValue}
                />

                <Form.Field
                    fieldId={`${bemBlockName}--input_address_1`}
                    fieldType="inputText"
                    label={i18n('Address 1')}
                    onBlur={() => onBlur('address1', address1Value)}
                    onFocus={() => onFocus('address1', address1Value)}
                    onChange={onAddress1Change}
                    required={!isRequirementFullfilled}
                    value={address1Value}
                />

                <Form.Field
                    fieldId={`${bemBlockName}--input_address_2`}
                    fieldType="inputText"
                    label={i18n('Address 2')}
                    onChange={onAddress2Change}
                    value={address2Value}
                />

                <Form.Field
                    fieldId={`${bemBlockName}--input_city`}
                    fieldType="inputText"
                    label={i18n('City')}
                    medium={6}
                    onBlur={() => onBlur('city', cityValue)}
                    onFocus={() => onFocus('city', cityValue)}
                    onChange={onCityChange}
                    required={!isRequirementFullfilled}
                    value={cityValue}
                />

                <Form.Field
                    fieldId={`${bemBlockName}--select_country`}
                    fieldType="selectCountry"
                    label={i18n('Country')}
                    medium={6}
                    onChange={onCountryChange}
                    required={!isRequirementFullfilled}
                    value={(
                        !!countryValue && {
                            code: countryAlpha2Value,
                            label: countryValue,
                            value: countryAlpha3Value,
                        }
                    ) || null}
                />

                <Form.Field
                    error={!!postalCodeValue && !isPostalCodeValid}
                    fieldId={`${bemBlockName}--input_postal_code`}
                    fieldType="inputNumber"
                    label={i18n('Postal Code')}
                    medium={6}
                    onBlur={() => onBlur('postalCode', postalCodeValue)}
                    onFocus={() => onFocus('postalCode', postalCodeValue)}
                    onChange={onPostalCodeChange}
                    required={isPostalCodeRequired && !isRequirementFullfilled}
                    value={postalCodeValue}
                />

                <Form.Field
                    fieldId={`${bemBlockName}--select_region`}
                    fieldProps={{
                        countryAlpha3: countryAlpha3Value ?? '',
                    }}
                    fieldType="selectRegion"
                    label={i18n('Region')}
                    medium={6}
                    onChange={onRegionChange}
                    required={!isRequirementFullfilled}
                    value={(
                        !!regionCodeValue && {
                            label: regionValue,
                            value: regionCodeValue,
                        }
                    ) || null}
                />
            </Form.Fieldset>
        </Form>
    );
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default connect(mapStateToProps)(ContactForm);
