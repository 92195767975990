/**
 * This component fetches and renders a single Connection Forms 2.0 Entry.
 * It is used for the Form Entries Carousel element on the Person Record 2.0 Page.
 */
import {
    ActivityIndicator,
} from '@saddlebackchurch/react-cm-ui';
import {
    debounce,
    isEmpty,
} from 'lodash';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import React from 'react';
import {
    useComponentDidMount,
    useComponentDidUpdate,
    useComponentWillUnmount,
} from '../../../../global/lifeCycleHooks';
import {
    usePrevious,
} from '../../../../global/utils/utils.js';
import FormEntry from './formEntry.jsx';
import connectionFormEntryActions from '../entriesByPerson/entriesByPerson.actions.js';

const propTypes = {
    formEntry: PropTypes.shape({}).isRequired, // from Redux
    formEntryId: PropTypes.number,
    isDrawerFullyOpen: PropTypes.bool,
    isFetchingFormEntry: PropTypes.bool.isRequired, // from Redux
    isPersonInfoVisible: PropTypes.bool,
    showOnlyAnsweredFields: PropTypes.bool,
};

const defaultProps = {
    formEntryId: undefined,
    isDrawerFullyOpen: false,
    isPersonInfoVisible: true,
    showOnlyAnsweredFields: false,
};

const mapStateToProps = (state) => {
    const {
        connectionForms: {
            entries: {
                entriesByPerson: {
                    entry: {
                        data: formEntry,
                        isFetching: isFetchingFormEntry,
                    },
                },
            },
        },
    } = state;

    return {
        formEntry,
        isFetchingFormEntry,
    };
};

const fetchFormEntryDebounced = debounce(
    (entryId) => {
        connectionFormEntryActions.getEntry({ entryId });
    },
    500,
);

function ConnectionFormEntryDrawerContent({
    formEntry,
    formEntryId,
    isDrawerFullyOpen,
    isFetchingFormEntry,
    isPersonInfoVisible,
    showOnlyAnsweredFields,
}) {
    const prevFormEntryId = usePrevious(formEntryId);
    const prevIsDrawerFullyOpen = usePrevious(isDrawerFullyOpen);

    useComponentDidMount(() => {
        if (!isFetchingFormEntry && isEmpty(formEntry)) {
            fetchFormEntryDebounced(formEntryId);
        }
    });

    useComponentDidUpdate(() => {
        const justOpenedDrawer = !prevIsDrawerFullyOpen && isDrawerFullyOpen;
        const formEntryIdChanged = prevFormEntryId !== formEntryId;

        if (justOpenedDrawer || (formEntryIdChanged && isDrawerFullyOpen)) {
            fetchFormEntryDebounced(formEntryId);
        }
    }, [
        formEntryId,
        isDrawerFullyOpen,
        prevFormEntryId,
        prevIsDrawerFullyOpen,
    ]);

    useComponentWillUnmount(() => {
        connectionFormEntryActions.resetSingleEntry();
    });

    return (
        <React.Fragment>
            <CSSTransitionGroup
                transitionEnterTimeout={250}
                transitionLeaveTimeout={166}
                transitionName={{
                    enter: 'animate-fade-in-enter',
                    enterActive: 'animate-active',
                    leave: 'animate-fade-out-leave',
                    leaveActive: 'animate-active',
                }}
            >
                {isFetchingFormEntry && (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'absolute',
                            height: 'calc(100vh - 206px)',
                            width: '100%',
                        }}
                    >
                        <ActivityIndicator />
                    </div>
                )}
            </CSSTransitionGroup>

            {!isFetchingFormEntry && !isEmpty(formEntry) && (
                <FormEntry
                    disableFields
                    formEntry={formEntry}
                    isPersonInfoVisible={isPersonInfoVisible}
                    showOnlyAnsweredFields={showOnlyAnsweredFields}
                />
            )}
        </React.Fragment>
    );
}

ConnectionFormEntryDrawerContent.propTypes = propTypes;
ConnectionFormEntryDrawerContent.defaultProps = defaultProps;

export default connect(mapStateToProps)(ConnectionFormEntryDrawerContent);
