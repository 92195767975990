import { combineReducers } from 'redux';
import newServingOpportunitiesDrawer from './newServingOpportunitiesDrawer/newServingOpportunitiesDrawer.reducer.js';
import roster from './servingOpportunity/roster/roster.reducers';
import servingSchedule from './servingOpportunity/schedule/servingSchedule.reducers';

const reducers = combineReducers({
    newServingOpportunitiesDrawer,
    roster,
    servingSchedule,
});

export default reducers;
