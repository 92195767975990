import { EventEmitter } from 'events';
import AppDispatcher from '../../dispatcher/AppDispatcher';
import LoginConstants from '../../constants/Login/LoginConstants';
import UserPreferenceConstants from '../../constants/User/UserPreferenceConstants';
import BootstrapConstants from '../../constants/BootstrapConstants';

const CHANGE_EVENT = 'change';

class UserPreferenceStoreClass extends EventEmitter {
    constructor() {
        super();

        this._preference = {};
    }

    updatePreference(preference) {
        this._preference = preference;
    }

    getPreference() {
        return this._preference;
    }

    getTimeZoneId() {
        if (!this._preference || Object.keys(this._preference).length === 0) { return ''; }

        return this._preference.timeZoneId;
    }

    reset() {
        this._preference = {};
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
}

AppDispatcher.register((payload) => {
    switch (payload.actionType) {
        case UserPreferenceConstants.USER_PREFERENCE_INSERT_COMPLETED:
            UserPreferenceStore.updatePreference(payload.preference);
            UserPreferenceStore.emitChange();
            break;

        case BootstrapConstants.GET_BOOTSTRAP:
            UserPreferenceStore.updatePreference(payload.data.userPreference);
            UserPreferenceStore.emitChange();
            break;

        case LoginConstants.LOGOUT:
            UserPreferenceStore.reset();
            UserPreferenceStore.emitChange();
            break;

        default:
        // no op
    }
});

let UserPreferenceStore = new UserPreferenceStoreClass();
export default UserPreferenceStore;
