import { combineReducers } from 'redux';
import drawer from './global/communicationsDrawer/communicationsDrawer.reducer.js';
import templatesDrawer from './templates/communicationTemplatesDrawer.reducer.js';
import templateEditor from './templates/communicationTemplateEditor.reducer.js';

const reducers = combineReducers({
    drawer,
    templatesDrawer,
    templateEditor,
});

export default reducers;
