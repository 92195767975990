enum ActionType {
    RESET_COMMUNICATIONS = 'CommunicationsCentral.RESET_COMMUNICATIONS ',
    RESET_COMMUNICATIONS_FILTERS = 'CommunicationsCentral.RESET_COMMUNICATIONS_FILTERS',
    SET_COMMUNICATIONS = 'CommunicationsCentral.SET_COMMUNICATIONS',
    SET_COMMUNICATIONS_FILTERS = 'CommunicationsCentral.SET_COMMUNICATIONS_FILTERS',
    SET_COMMUNICATIONS_LOADING = 'CommunicationsCentral.SET_COMMUNICATIONS_LOADING',
    SET_COMMUNICATIONS_SEARCH_VALUE = 'CommunicationsCentral.COMMUNICATIONS_SEARCH_VALUE',
}

export default ActionType;
