import {
    filter,
    sortBy,
} from 'lodash';
import {
    addOrReplaceBy,
    selectBoxTransform,
} from '../../../global/utils';

const DEFAULT_STATE = {
    groups: [],
    isFetching: false,
    selectOptions: [],
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'MinistryGroupActions.ON_BEFORE_GETGROUPS':
            return { ...state, isFetching: true };

        case 'MinistryGroupActions.ON_GETGROUPS':
            return {
                ...state,
                groups: actionResult,
                isFetching: false,
                selectOptions: selectBoxTransform(action, 'id', 'name'),
            };

        case 'MinistryGroupActions.ON_ADDGROUP':
            return {
                ...state,
                groups: sortBy([...state.groups, actionResult], (grp) => grp.name),
            };

        case 'MinistryGroupActions.ON_SAVEGROUP':
            return {
                ...state,
                groups: addOrReplaceBy(
                    state.groups,
                    (grp) => grp.id === actionResult.id,
                    () => actionResult,
                ),
            };

        case 'MinistryGroupActions.ON_DELETEGROUP':
            return {
                ...state,
                groups: filter(state.groups, (grp) => grp.id !== callbackParams.id),
            };

        case 'MinistryGroupActions.ON_RESET_GROUPS_LIST':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
