import {
    every,
    filter,
    isEmpty,
    isNil,
    map,
    maxBy,
} from 'lodash';
import { EDITOR_MODE } from '../constants.js';
import { addOrReplaceBy } from '../../../global/utils';
import { isRuleAttributeConditionValid } from './attributeConditionUtils.js';
import { isRuleEntityConditionValid } from './entityConditionUtils.js';

let nextAttributeConditionSequentialId = -1;
let nextEntityConditionSequentialId = -1;

export const getNextAttributeConditionSequentialId =
    () => (nextAttributeConditionSequentialId--); // eslint-disable-line no-plusplus

export const recallPreviousAttributeConditionSequentialId =
    () => (nextAttributeConditionSequentialId + 1);

export const getNextEntityConditionSequentialId =
    () => (nextEntityConditionSequentialId--); // eslint-disable-line no-plusplus

export const recallPreviousEntityConditionSequentialId =
    () => (nextEntityConditionSequentialId + 1);

// eslint-disable-next-line arrow-body-style
const isAbleToSaveRuleAttributeConditions = (attributeConditions) => {
    return every(
        attributeConditions,
        (ac) => !!ac.ableToSave,
    );
};

// eslint-disable-next-line arrow-body-style
const isAbleToSaveRuleEntityConditions = (entityConditions) => {
    return every(
        entityConditions,
        (ec) => !!ec.ableToSave,
    );
};

// eslint-disable-next-line arrow-body-style
export const isAbleToSaveRuleTrigger = (rule) => {
    return isAbleToSaveRuleEntityConditions(rule.entityConditions) &&
        isAbleToSaveRuleAttributeConditions(rule.attributeConditions);
};

const updateRuleTriggerState = (state, updatedRule) => {
    const { domainSpecificLogic } = state;

    const ableToSaveRuleTrigger =
        isAbleToSaveRuleTrigger(updatedRule, domainSpecificLogic);

    return {
        ...state,
        ableToSaveRuleTrigger,
        isDirty: true,
        rule: updatedRule,
    };
};

const updateRuleAttributeCondition = (existingRule, attributeConditionId, objWithUpdates) => ({
    ...existingRule,
    attributeConditions: filter(
        addOrReplaceBy(
            existingRule.attributeConditions,
            (ac) => ac.id === attributeConditionId,
            (existingItem) => {
                /* istanbul ignore else */
                if (!isNil(existingItem)) {
                    const updatedItem = {
                        ...existingItem,
                        ...objWithUpdates,
                    };

                    const ableToSave = isRuleAttributeConditionValid(updatedItem);

                    return {
                        ...updatedItem,
                        ableToSave,
                    };
                }

                /* istanbul ignore next */
                return null;
            },
        ),
        (ac) => !isNil(ac),
    ),
});

const updateRuleEntityCondition = (existingRule, entityConditionId, objWithUpdates) => ({
    ...existingRule,
    entityConditions: filter(
        addOrReplaceBy(
            existingRule.entityConditions,
            (ec) => ec.id === entityConditionId,
            (existingItem) => {
                /* istanbul ignore else */
                if (!isNil(existingItem)) {
                    const updatedItem = {
                        ...existingItem,
                        ...objWithUpdates,
                    };

                    const ableToSave = isRuleEntityConditionValid(updatedItem);

                    return {
                        ...updatedItem,
                        ableToSave,
                    };
                }

                /* istanbul ignore next */
                return null;
            },
        ),
        (ac) => !isNil(ac),
    ),
});

export const updateRuleTrigger = (state, actionType, actionValue) => {
    switch (actionType) {
        case 'WorkflowRuleTriggerActions.ON_ADD_ATTRIBUTE_CONDITION':
        {
            const { rule: existingRule } = state;

            const id = getNextAttributeConditionSequentialId();

            const order = !isEmpty(existingRule.attributeConditions) ?
                maxBy(
                    existingRule.attributeConditions,
                    (ac) => ac.order,
                ).order + 1 :
                1;

            return {
                ...state,
                ableToSaveRuleTrigger: false,
                editingAttributeConditionId: id,
                isDirty: true,
                rule: {
                    ...existingRule,
                    attributeConditions: [
                        ...existingRule.attributeConditions,
                        {
                            ableToSave: false,
                            id,
                            order,
                        },
                    ],
                },
                ruleTriggerDetailEditorMode: EDITOR_MODE.add,
            };
        }

        case 'WorkflowRuleTriggerActions.ON_DONE_EDITING_ATTRIBUTE_CONDITION':
            return {
                ...state,
                editingAttributeConditionId: null,
                ruleTriggerDetailEditorMode: null,
            };

        case 'WorkflowRuleTriggerActions.ON_DONE_EDITING_ENTITY_CONDITION':
            return {
                ...state,
                editingEntityConditionId: null,
                ruleTriggerDetailEditorMode: null,
            };

        case 'WorkflowRuleTriggerActions.ON_EDIT_ATTRIBUTE_CONDITION':
            return {
                ...state,
                editingAttributeConditionId: actionValue,
                editingEntityConditionId: null,
                ruleTriggerDetailEditorMode: EDITOR_MODE.edit,
            };

        case 'WorkflowRuleTriggerActions.ON_EDIT_ENTITY_CONDITION':
            return {
                ...state,
                editingAttributeConditionId: null,
                editingEntityConditionId: actionValue,
                ruleTriggerDetailEditorMode: EDITOR_MODE.edit,
            };

        case 'WorkflowRuleTriggerActions.ON_REMOVE_ATTRIBUTE_CONDITION':
        {
            const { rule: existingRule } = state;

            const updatedRule = {
                ...existingRule,
                attributeConditions: map(
                    filter(
                        existingRule.attributeConditions,
                        (ac) => ac.id !== actionValue,
                    ),
                    (ac, index) => ({ ...ac, order: index + 1 }),
                ),
            };

            const updatedState = updateRuleTriggerState(state, updatedRule);

            return {
                ...updatedState,
                editingAttributeConditionId: null,
                ruleTriggerDetailEditorMode: null,
            };
        }

        case 'WorkflowRuleTriggerActions.ON_UPDATE_ATTRIBUTE_CONDITION_ATTRIBUTE':
        {
            const { rule: existingRule } = state;

            const {
                attribute,
                attributeConditionId,
            } = actionValue;

            const updates = { attribute };

            const updatedRule = updateRuleAttributeCondition(
                existingRule,
                attributeConditionId,
                updates,
            );

            return updateRuleTriggerState(state, updatedRule);
        }

        case 'WorkflowRuleTriggerActions.ON_UPDATE_ATTRIBUTE_CONDITION_CONDITION_TYPE':
        {
            const { rule: existingRule } = state;

            const {
                conditionType: condition,
                attributeConditionId,
            } = actionValue;

            const updates = { condition };

            const updatedRule = updateRuleAttributeCondition(
                existingRule,
                attributeConditionId,
                updates,
            );

            return updateRuleTriggerState(state, updatedRule);
        }

        case 'WorkflowRuleTriggerActions.ON_UPDATE_ATTRIBUTE_CONDITION_PARAMETER':
        {
            const { rule: existingRule } = state;

            const {
                attributeConditionId,
                isParameterIncomplete,
                parameter,
            } = actionValue;

            const updates = isParameterIncomplete ?
                {
                    parameter,
                    isParameterIncomplete,
                    entityType: null,
                    entityId: null,
                } : {
                    parameter,
                    isParameterIncomplete: undefined,
                    entityType: null,
                    entityId: null,
                };

            const updatedRule = updateRuleAttributeCondition(
                existingRule,
                attributeConditionId,
                updates,
            );

            return updateRuleTriggerState(state, updatedRule);
        }

        case 'WorkflowRuleTriggerActions.ON_UPDATE_ATTRIBUTE_CONDITION_ENTITY_REFERENCE':
        {
            const { rule: existingRule } = state;

            const {
                attributeConditionId,
                entityType,
                entityId,
            } = actionValue;

            const updates = {
                entityType,
                entityId,
                parameter: null,
                isParameterIncomplete: undefined,
            };

            const updatedRule = updateRuleAttributeCondition(
                existingRule,
                attributeConditionId,
                updates,
            );

            return updateRuleTriggerState(state, updatedRule);
        }

        case 'WorkflowRuleTriggerActions.ON_UPDATE_ENTITY_CONDITION_CONDITION_TYPE':
        {
            const { rule: existingRule } = state;

            const {
                conditionType: condition,
                entityConditionId,
            } = actionValue;

            const updates = { condition };

            const updatedRule = updateRuleEntityCondition(
                existingRule,
                entityConditionId,
                updates,
            );

            return updateRuleTriggerState(state, updatedRule);
        }

        case 'WorkflowRuleTriggerActions.ON_UPDATE_ENTITY_CONDITION_PARAMETER':
        {
            const { rule: existingRule } = state;

            const {
                parameter,
                entityConditionId,
                isParameterIncomplete,
            } = actionValue;

            const updates = isParameterIncomplete ?
                { isParameterIncomplete, parameter } :
                {
                    isParameterIncomplete: undefined,
                    parameter,
                };

            const updatedRule = updateRuleEntityCondition(
                existingRule,
                entityConditionId,
                updates,
            );

            return updateRuleTriggerState(state, updatedRule);
        }

        default:
            return state;
    }
};
