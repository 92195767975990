export const DEFAULT_STATE = null;

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryEventActions.ON_SEARCH':
            return action.result;

        case 'MinistryEventActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
}
