import {
    Grid,
    Select,
} from '@saddlebackchurch/react-cm-ui';
import {
    capitalize,
    isString,
} from 'lodash';
import {
    Controller,
} from 'react-hook-form';
import ClassNames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';

type PropTypes = {
    className?: string | null;
    control: any;
    formatLabel?: boolean;
    id?: string | null;
    label?: string | null;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
    name: string;
    options: {
        label: string;
        value: number;
    }[] | null;
    placeholder?: string | null;
    rules?: {
        required?: boolean;
        validate?: any;
    };
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    root: {
        paddingBottom: spacing(1.5),
        paddingTop: spacing(1.5),
    },
}));

function FormFieldMultiSelect({
    className = null,
    control,
    formatLabel = true,
    id = null,
    label = null,
    md = null,
    name,
    options,
    placeholder = null,
    rules = {},
    sm = 12,
}: PropTypes) {
    const classes = useStyles();

    const formattedLabel = formatLabel && isString(formatLabel) ?
        label.replace(/\w+/g, capitalize) :
        label;

    const rootClasses = ClassNames(
        'form--field_mutli_select',
        classes.root,
        className,
    );

    return (
        <Grid.Column
            className={rootClasses}
            md={md}
            sm={sm}
        >
            <Controller
                control={control}
                name={name}
                render={({
                    field: {
                        onChange,
                        value,
                    },
                }) => (
                    <Select
                        fluid
                        id={id}
                        label={formattedLabel}
                        multiple
                        onChange={onChange}
                        options={options}
                        placeholder={placeholder}
                        required={rules?.required}
                        searchable
                        tabIndex={0}
                        value={value}
                    />
                )}
                rules={rules}
            />
        </Grid.Column>
    );
}
export default FormFieldMultiSelect;
