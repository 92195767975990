import UserAuthenticationInfoActionType from './userAuthenticationInfo.actionTypes';
import UserAuthenticationInfo from './userAuthenticationInfo.model';

export const DEFAULT_STATE: UserAuthenticationInfo = {
    accessToken: null,
    campusId: null,
    campusName: null,
    email: null,
    firstName: null,
    hasLoadedUserInfo: false,
    isLoggedIn: false,
    lastName: null,
    personId: null,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case UserAuthenticationInfoActionType.SET_STATE:
            return {
                ...state,
                ...action.state,
            };

        case UserAuthenticationInfoActionType.RESET:
            return {
                ...DEFAULT_STATE,
            };

        default:
            return state;
    }
};
