import { isEqual } from 'lodash';
import {
    OPTION_ENTRY_ANY,
    OPTION_REGISTRATION_ANY,
    OPTION_STATUS_ANY,
    SORT_OPTIONS,
} from '../../eventsCentral/constants.js';

export const defaultFilters = {
    churchEntityIds: [],
    endDate: null,
    entry: OPTION_ENTRY_ANY,
    includeWorshipServices: false,
    startDate: null,
    registration: OPTION_REGISTRATION_ANY,
    sort: SORT_OPTIONS[0],
    status: OPTION_STATUS_ANY,
};

const DEFAULT_STATE = {
    appliedFilters: { ...defaultFilters },
    areFiltersDirty: false,
    defaultFilters: { ...defaultFilters },
    dirtyFilters: { ...defaultFilters },
    isFiltering: false,
    searchTerm: null,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, prevState.defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        // Search Term
        case 'EventFlatOccurrenceSearchV2Actions.ON_SET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: actionValue,
            };

        case 'EventFlatOccurrenceSearchV2Actions.ON_CLEAR_SEARCH_TERM':
            return {
                ...state,
                searchTerm: null,
            };

        // Apply Dirty Filters (for mboile)
        case 'EventFlatOccurrenceSearchV2Actions.ON_APPLY_FILTERS':
        {
            const { dirtyFilters } = state;
            const isFiltering = !isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        // Clear Filters (for mobile)
        case 'EventFlatOccurrenceSearchV2Actions.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(state, { ...defaultFilters });

        // Default Filters (default date range / include or don't include Worship Services / default church entity ID(s))
        case 'EventFlatOccurrenceSearchV2Actions.ON_DEFAULT_FILTERS_INITIALIZED':
        {
            const {
                churchEntityIds,
                endDate,
                includeWorshipServices,
                startDate,
                status,
            } = actionValue;

            const updatedFilters = {
                ...state.appliedFilters,
                churchEntityIds,
                endDate,
                includeWorshipServices,
                startDate,
                status,
            };

            return {
                ...(updateAppliedFilterState(state, updatedFilters)),
                defaultFilters: updatedFilters,
            };
        }

        // On various Filter and Sort Options Changed and Applied (i.e. filtering in Desktop Mode)
        case 'EventFlatOccurrenceSearchV2Actions.ON_CHURCH_ENTITIES_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, churchEntityIds: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_END_DATE_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, endDate: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_ENTRY_FILTER_OPTION_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, entry: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_REGISTRATION_FILTER_OPTION_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, registration: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_SORT_OPTION_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, sort: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_START_DATE_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, startDate: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_STATUS_FILTER_OPTION_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, status: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        // On various *Dirty* Filter and Sort Options Changed (i.e. filtering in Mobile Mode)
        case 'EventFlatOccurrenceSearchV2Actions.ON_DIRTY_CHURCH_ENTITIES_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, churchEntityIds: actionValue },
            );
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_DIRTY_END_DATE_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, endDate: actionValue },
            );
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_DIRTY_ENTRY_FILTER_OPTION_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, entry: actionValue },
            );
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_DIRTY_REGISTRATION_FILTER_OPTION_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, registration: actionValue },
            );
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_DIRTY_SORT_OPTION_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, sort: actionValue },
            );
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_DIRTY_START_DATE_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, startDate: actionValue },
            );
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_DIRTY_STATUS_FILTER_OPTION_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, status: actionValue },
            );
        }

        // Reset
        case 'EventFlatOccurrenceSearchV2Actions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
