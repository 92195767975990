import _ from 'lodash';

export const DEFAULT_STATE = [];

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunityVolunteerActions.ON_ALL':
            return action.result.volunteers;
    }
    return state;
}
