import _ from 'lodash';

export const DEFAULT_STATE = [];

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'NotificationSourceTypesActions.ON_GET':
            return _.cloneDeep(action.result);
    }
    return state;
}
