import {
    Dropdown,
    Icon,
    Image,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from '../../global/i18nUtils.js';
import { setIsNavigationCollapsed } from '../navigation/navigation.actions';
import {
    RELEASE_NOTES_OPTION_ID,
} from './constants';
import {
    USER_PERMISSIONS,
} from '../../global/userPermissionConstants.js';
import Sticky from '../../global/sticky.jsx';
import UserAccessStore from '../../global/userAccessStore.js';

const i18n = translationFactory();

const propTypes = {
    isCmsPreviewEnabled: PropTypes.bool.isRequired,
    isConnected: PropTypes.bool,
    notificationUnreadCount: PropTypes.number,
    onUserMenuChange: PropTypes.func.isRequired,
    setIsNavigationCollapsed: PropTypes.func.isRequired,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    isConnected: false,
    notificationUnreadCount: 0,
    subTitle: '',
    title: '',
};

const mapStateToProps = (state) => {
    const {
        appHeader,
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        myAccount: {
            preferences: {
                isCmsPreviewEnabled,
            },
        },
        notification: {
            pushNotifications,
        },
        quickNotifications: {
            notificationUnreadCount,
        },
    } = state;
    let subTitle = '';
    let title = '';

    if (appHeader) {
        subTitle = appHeader.subTitle;
        title = appHeader.title;
    }

    return {
        isCmsPreviewEnabled,
        isConnected: pushNotifications && pushNotifications.connected,
        notificationUnreadCount,
        subTitle,
        title,
        userAccess,
    };
};

const mapDispatchToProps = {
    setIsNavigationCollapsed,
};

function AppHeaderMobile(props) {
    const {
        isCmsPreviewEnabled,
        isConnected,
        notificationUnreadCount,
        onUserMenuChange,
        subTitle,
        title,
        setIsNavigationCollapsed: setIsNavigationCollapsedAction,
        userAccess,
    } = props;

    const onToggleNav = () => {
        setIsNavigationCollapsedAction(false);
    };

    const hasBrowseInDraftMode = userAccess.hasPermission(
        USER_PERMISSIONS.browseInDraftMode,
    );

    return (
        <Sticky
            className="app-header mobile"
        >
            <header
                className="header-bar"
            >
                <span
                    className="menu-toggle"
                    onClick={onToggleNav}
                    onKeyDown={onToggleNav}
                    role="button"
                    tabIndex={-1}
                >
                    <span
                        className="copy"
                    >
                        {i18n('Menu')}
                    </span>
                </span>

                <div className="row">
                    <div className="title-bar">
                        <div className="page-title">{title}</div>
                        <div className="page-subtitle">{subTitle}</div>
                    </div>

                    <Dropdown
                        className={notificationUnreadCount ? 'dropdown-has-notification' : ''}
                        collapseMenuOnChange
                        onChange={onUserMenuChange}
                        text={(
                            <span>
                                <Image type="user" />

                                {!isConnected && (
                                    <Icon
                                        color="warning"
                                        size="xxsmall"
                                        style={{
                                            left: '26px',
                                            position: 'absolute',
                                            top: '4px',
                                        }}
                                        title={i18n('No Push Notifications')}
                                        type="arrow-sort"
                                    />
                                )}
                            </span>
                        )}
                    >
                        <Dropdown.Item className={notificationUnreadCount ? 'has-notification' : ''} iconColor="static" iconType="bell" id="notifications" label={i18n('Notifications')} />
                        <Dropdown.Item iconColor="static" iconType="question-circle" id="help" label={i18n('Help')} />
                        <Dropdown.Item iconColor="static" iconType="sliders" id="notificationPreferences" label={i18n('Notification Preferences')} />
                        <Dropdown.Item iconColor="static" iconType="cog" id="previewFeature" label={i18n('Preview Feature')} />
                        <Dropdown.Item iconColor="static" iconType="cog" id="preferences" label={i18n('Preferences')} />
                        <Dropdown.Item iconColor="static" iconType="lock" id="changePassword" label={i18n('Change Password')} />

                        {hasBrowseInDraftMode && isCmsPreviewEnabled ? (
                            <Dropdown.Item
                                iconColor="static"
                                iconType="bell"
                                id={RELEASE_NOTES_OPTION_ID}
                                label={i18n('Release Notes')}
                            />
                        ) : null}

                        <Dropdown.Item iconColor="static" iconType="power-square" id="logOut" label={i18n('Log Out')} />
                    </Dropdown>
                </div>
            </header>
        </Sticky>
    );
}

AppHeaderMobile.propTypes = propTypes;
AppHeaderMobile.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppHeaderMobile);
