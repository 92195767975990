import './connectionCardEntryDrawerContent.scss';

import { ActivityIndicator } from '@saddlebackchurch/react-cm-ui';
import {
    debounce,
    isEmpty,
} from 'lodash';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import React from 'react';
import connectionCardsActions from './connectionCardsActions.js';
import ConnectionCardsEntryForm from './connectionCardsEntryForm.jsx';

const propTypes = {
    formEntryData: PropTypes.shape({
        id: PropTypes.number,
        responseCardId: PropTypes.number,
        sections: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    formEntryId: PropTypes.number,
    formTemplateData: PropTypes.shape({
        id: PropTypes.number,
        sections: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    formTemplateId: PropTypes.number,
    isDrawerFullyOpen: PropTypes.bool,
    isEntryDataFetching: PropTypes.bool,
    isPersonInfoVisible: PropTypes.bool,
    isTemplateDataFetching: PropTypes.bool,
};

const defaultProps = {
    formEntryData: {},
    formEntryId: undefined,
    formTemplateData: {},
    formTemplateId: undefined,
    isDrawerFullyOpen: false,
    isEntryDataFetching: false,
    isPersonInfoVisible: true,
    isTemplateDataFetching: false,
};

const mapStateToProps = (state) => {
    const {
        connectionCards: {
            global: {
                entry: {
                    data: formEntryData,
                    isFetching: isEntryDataFetching,
                },
                template: {
                    data: formTemplateData,
                    isFetching: isTemplateDataFetching,
                },
            },
        },
    } = state;

    return {
        formEntryData,
        formTemplateData,
        isEntryDataFetching,
        isTemplateDataFetching,
    };
};

const bemBlockName = 'connection_cards_entry_drawer_content';

class ConnectionCardEntryDrawerContent extends React.PureComponent {
    static resetData() {
        connectionCardsActions.resetEntry();
        connectionCardsActions.resetTemplate();
    }

    constructor() {
        super();

        this.onGetDataDebounced = debounce(
            (newEntriesEntryId) => this.getData(newEntriesEntryId), 500,
        );

        this.shouldGetData = false;
    }

    componentDidMount() {
        const { isDrawerFullyOpen } = this.props;

        if (isDrawerFullyOpen) {
            this.onGetDataDebounced();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            formEntryId: prevEntryId,
            isDrawerFullyOpen: prevIsDrawerFullyOpen,
        } = prevProps;

        const {
            formEntryId: nextEntryId,
            isDrawerFullyOpen: nextIsDrawerFullyOpen,
        } = this.props;

        const justOpenedDrawer = !prevIsDrawerFullyOpen && nextIsDrawerFullyOpen;
        const formEntryIdChanged = prevEntryId !== nextEntryId;

        if (justOpenedDrawer || (formEntryIdChanged && nextIsDrawerFullyOpen)) {
            this.onGetDataDebounced();
        }
    }

    componentWillUnmount() {
        ConnectionCardEntryDrawerContent.resetData();
    }

    getData() {
        const {
            formEntryId,
            formTemplateId,
        } = this.props;

        connectionCardsActions.getEntry({ id: formEntryId });
        connectionCardsActions.getTemplate({ id: formTemplateId });
    }

    render() {
        const {
            formEntryData,
            formTemplateData,
            isEntryDataFetching,
            isDrawerFullyOpen,
            isPersonInfoVisible,
            isTemplateDataFetching,
        } = this.props;

        const isDataEmpty = isEmpty(formEntryData) || isEmpty(formTemplateData);
        const isDataNotFetching = !isEntryDataFetching && !isTemplateDataFetching;

        const areDataIdsEqual = !isDataEmpty &&
            formEntryData.responseCardId === formTemplateData.id;

        const shouldShowEntry = isDrawerFullyOpen && isDataNotFetching && areDataIdsEqual;

        return (
            <React.Fragment>
                <CSSTransitionGroup
                    transitionEnterTimeout={250}
                    transitionLeaveTimeout={166}
                    transitionName={{
                        enter: 'animate-fade-in-enter',
                        enterActive: 'animate-active',
                        leave: 'animate-fade-out-leave',
                        leaveActive: 'animate-active',
                    }}
                >
                    {!shouldShowEntry && (
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'absolute',
                                height: 'calc(100vh - 206px)',
                                width: '100%',
                            }}
                        >
                            <ActivityIndicator />
                        </div>
                    )}
                </CSSTransitionGroup>

                {shouldShowEntry && (
                    <ConnectionCardsEntryForm
                        bemBlockName={bemBlockName}
                        disableFields
                        entryFieldsetsData={formEntryData.sections}
                        isPersonInfoVisible={isPersonInfoVisible}
                        templateFieldsetsData={formTemplateData.sections}
                    />
                )}
            </React.Fragment>
        );
    }
}

ConnectionCardEntryDrawerContent.propTypes = propTypes;
ConnectionCardEntryDrawerContent.defaultProps = defaultProps;

export default connect(mapStateToProps)(ConnectionCardEntryDrawerContent);
