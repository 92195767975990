import {
    noop,
} from 'lodash';
import {
    Drawer,
    Icon,
    Page,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { BEM_BLOCK_NAME } from './searchConstants';
import { USER_PERMISSIONS } from '../../global/userPermissionConstants.js';
import { i18n } from '../../global/constants.js';
import UserAccessStore from '../../global/userAccessStore.js';

const propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.shape({}),
    }),
    isDrawerChild: PropTypes.bool,
    isFiltering: PropTypes.bool,
    isFiltersOpen: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isSearchInitiated: PropTypes.bool.isRequired,
    onAddPersonDrawerToggle: PropTypes.func,
    onClearClick: PropTypes.func,
    onSearchHelpModalOpen: PropTypes.func,
    onSearchKeyDown: PropTypes.func,
    onSearchTermChange: PropTypes.func,
    onToggleFilters: PropTypes.func,
    searchTerm: PropTypes.string.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    classes: {},
    isDrawerChild: false,
    isFiltering: false,
    onAddPersonDrawerToggle: noop,
    onClearClick: noop,
    onSearchHelpModalOpen: noop,
    onSearchKeyDown: noop,
    onSearchTermChange: noop,
    onToggleFilters: noop,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        breakpoint: {
            isMobile,
        },
    } = state;

    return {
        isMobile,
        userAccess,
    };
};

const BEM_ACTION_BAR_BLOCK_NAME = `${BEM_BLOCK_NAME}--action_bar`;

export class PersonActionBar extends React.PureComponent {
    render() {
        const {
            isDrawerChild,
            isFiltering,
            isFiltersOpen,
            isMobile,
            onAddPersonDrawerToggle,
            onClearClick,
            onSearchHelpModalOpen,
            onSearchKeyDown,
            onSearchTermChange,
            onToggleFilters,
            isSearchInitiated,
            searchTerm,
            userAccess,
        } = this.props;

        let ActionBar = Page.ActionBar; // eslint-disable-line prefer-destructuring

        if (isDrawerChild) {
            ActionBar = Drawer.ActionBar;
        }

        const rootContainerClasses = ClassNames(
            BEM_ACTION_BAR_BLOCK_NAME,
            {
                [`ui ${BEM_ACTION_BAR_BLOCK_NAME}_search_not_initiated`]: !isSearchInitiated && !isMobile,
            },
        );

        const canCreatePersonalData = userAccess.hasPermission(
            USER_PERMISSIONS.createPersonalData,
        );

        const canCreatePersonContactData = userAccess.hasPermission(
            USER_PERMISSIONS.createPersonContactData,
        );

        const hasPermissionToAddPerson = canCreatePersonalData && canCreatePersonContactData;
        let actionBarColumns = [];
        const dropdownButtonOptions = [
            {
                iconBackgroundColor: '',
                iconType: 'plus',
                id: `${BEM_ACTION_BAR_BLOCK_NAME}--add_adult`,
                label: i18n('Adult'),
                onClick: () => onAddPersonDrawerToggle('adult'),
            }, {
                iconBackgroundColor: '',
                iconType: 'plus',
                id: `${BEM_ACTION_BAR_BLOCK_NAME}--add_student`,
                label: i18n('Student (Grades 6-12)'),
                onClick: () => onAddPersonDrawerToggle('student'),
            }, {
                iconBackgroundColor: '',
                iconType: 'plus',
                id: `${BEM_ACTION_BAR_BLOCK_NAME}--add_child`,
                label: i18n('Child (Up to Grades 5)'),
                onClick: () => onAddPersonDrawerToggle('child'),
            },
        ];
        const iconFilter = {
            id: `${BEM_ACTION_BAR_BLOCK_NAME}--filter_icon`,
            isFiltering,
            onClick: onToggleFilters,
            selected: isFiltersOpen ? 'highlight' : null,
        };
        const search = {
            id: `${BEM_ACTION_BAR_BLOCK_NAME}--search_input`,
            onChange: onSearchTermChange,
            onClearClick,
            onKeyDown: onSearchKeyDown,
            autoFocus: true,
            value: searchTerm,
        };

        let addPersonButton;

        if (!isMobile) {
            if (hasPermissionToAddPerson) {
                addPersonButton = {
                    id: `${BEM_ACTION_BAR_BLOCK_NAME}--add_person_button`,
                    color: 'success',
                    iconType: 'plus',
                    options: [
                        ...dropdownButtonOptions,
                    ],
                    label: i18n('Add Person'),
                };
            }

            actionBarColumns = [
                ...actionBarColumns,
                {
                    list: [
                        {
                            iconFilter: {
                                ...iconFilter,
                            },
                        },
                    ],
                }, {
                    search: {
                        ...search,
                    },
                    sm: true,
                }, {
                    button: {
                        color: 'primary',
                        iconType: 'info',
                        id: `${BEM_ACTION_BAR_BLOCK_NAME}--help_button`,
                        onClick: onSearchHelpModalOpen,
                        title: i18n('Help'),
                    },
                    sm: 'auto',
                    style: {
                        paddingRight: 0,
                        textAlign: 'right',
                    },
                }, {
                    dropdownButton: {
                        ...addPersonButton,
                    },
                    sm: 'auto',
                    style: {
                        textAlign: 'right',
                    },
                },
            ];
        } else {
            if (hasPermissionToAddPerson) {
                addPersonButton = {
                    header: i18n('Add Person'),
                    options: [
                        ...dropdownButtonOptions,
                    ],
                };
            }

            actionBarColumns = [
                ...actionBarColumns,
                {
                    list: [
                        {
                            flexGrow: 1,
                            jsx: () => {},
                        }, {
                            divide: false,
                            iconSearch: {
                                ...search,
                            },
                        }, {
                            jsx: (
                                <Icon
                                    compact
                                    id={`${BEM_ACTION_BAR_BLOCK_NAME}--help_button`}
                                    onClick={onSearchHelpModalOpen}
                                    type="info"
                                />
                            ),
                        }, {
                            iconFilter: {
                                ...iconFilter,
                            },
                        }, {
                            actionsButton: {
                                ...addPersonButton,
                            },
                        },
                    ],
                    sm: true,
                },
            ];
        }

        return (
            <ActionBar
                className={rootContainerClasses}
                columns={actionBarColumns}
                isDrawerChild={isDrawerChild}
            />
        );
    }
}

PersonActionBar.propTypes = propTypes;
PersonActionBar.defaultProps = defaultProps;

export default connect(mapStateToProps)(PersonActionBar);
