import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const _chunkLoader = new ChunkedPaginationUtils(25, 50);

export const DEFAULT_STATE = {
    results: [],
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: _chunkLoader.getPageSize(),
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunityVolunteerActions.ON_RESET':
            return DEFAULT_STATE;

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCH':
            _chunkLoader.loadPage(action.result.volunteers, action.result.total, action.callbackParams.first);
            return {
                results: _chunkLoader.getAll(true),
                total: _chunkLoader.getTotalCount(),
                canLoadMore: _chunkLoader.canLoadMore(),
                needsToRequest: _chunkLoader.needsToLoadPage(),
                pageNumber: _chunkLoader.getCurrentPageNumber(),
                pageSize: _chunkLoader.getPageSize(),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCH_NEXTPAGE':
            return {
                results: _chunkLoader.getAll(true),
                total: _chunkLoader.getTotalCount(),
                canLoadMore: _chunkLoader.canLoadMore(),
                needsToRequest: _chunkLoader.needsToLoadPage(),
                pageNumber: _chunkLoader.getCurrentPageNumber(),
                pageSize: _chunkLoader.getPageSize(),
            };
    }
    return state;
}
