import { combineReducers } from 'redux';
import global from './global/connectionCardsReducer.js';
import connectionCard from './connectionCard.reducer.js';
import connectionCardEntrySearch from './connectionCardEntrySearch.reducer.js';

const reducers = combineReducers({
    connectionCard,
    connectionCardEntrySearch,
    global,
});

export default reducers;
