export const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'SendNotificationResultsActions.ON_SHOW_RESULTS':
            return action.results;

        default:
            return state;
    }
};
