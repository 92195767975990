import keyMirror from 'keymirror';

export default keyMirror({
    GET_PERSON: null,
    CLEAR_MANAGER: null,
    SET_MANAGER_MODE: null,
    UPDATE_PERSON_FIELD: null,
    SAVE_PERSON_COMPLETED: null,
    SAVE_MEMBERSHIP_STATUS_COMPLETED: null,
    GO_TO_PERSON: null,
});
