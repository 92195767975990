import request from 'axios';
import { appReduxStore } from '../configureReduxStore.js';
import ApiUtils from '../apiUtils.js';
import {
    Campus,
} from '../models';

class ChurchStructureActions extends ApiUtils {
    getCampus: (
        params: {
            id: number;
        },
    ) => Promise<Campus>;

    getCampuses: () => Promise<Campus[]>;

    resetCampus() {
        appReduxStore.dispatch({ type: 'ChurchStructureActions.ON_RESET_CAMPUS' });
    }

    resetCampuses() {
        appReduxStore.dispatch({ type: 'ChurchStructureActions.ON_RESET_CAMPUSES' });
    }
}

export const setCampuses = (campuses: Campus[]) => (dispatch) => {
    dispatch({
        type: 'ChurchStructureActions.ON_SET_CAMPUSES',
        campuses,
    });
};

const actions = new ChurchStructureActions('/api/churchentity/');

actions.buildReduxActionClass(
    {
        name: 'getCampuses',
        route: '',
        rq: request.get,
    },
    {
        name: 'getCampus',
        route: '{id}',
        rq: request.get,
    },
);

export default actions;
