import _ from 'lodash';
import moment from 'moment-timezone';

const DEFAULT_STATE = {
    announcementCategories: [],
    announcements: [],
    availServices: [],
    campuses: [],
    campusSongIds: [],
    connectionForm: null,
    connectionQuestions: [],
    dateEnd: moment(),
    dateStart: moment(),
    description: '',
    eventCategories: [],
    eventTags: [],
    events: [],
    id: null,
    image: null,
    isPublished: false,
    isLoading: false,
    ministries: [],
    ministryCategories: [],
    ministryTags: [],
    name: '',
    mediaId: undefined,
    mediaUrl: undefined,
    isExternalMedia: false,
    newAnnouncements: [],
    newConnectionQuestions: [],
    newNextStepQuestions: [],
    newNextStepServiceQuestions: [],
    newServiceQuestions: [],
    nextStepQuestions: [],
    nextStepServiceQuestions: [],
    outline: {
        serviceTemplateId: 0,
        name: null,
        category: 0,
        categoryId: 0,
        categoryName: undefined,
        content: null,
        version: 0,
    },
    query: '',
    questionHeaders: [
        { name: 'name', text: 'Name' },
        { name: 'title', text: 'Title' },
        { name: 'milestone', text: 'Milestone' },
    ],
    removeAnnouncements: [],
    removeConnectionQuestions: [],
    removeNextStepQuestions: [],
    searchFilterSort: {
        searchValue: '',
        searchOptions: {
            query: '',
            isActiveFilter: null,
            isInUseFilter: null,
            sortValue: 0,
            sortQuery: 'name',
            sortFieldOrder: 'asc',
            pageNumber: 0,
        },
    },
    seriesId: null,
    seriesList: null,
    seriesName: null,
    seriesSet: [],
    seriesPartNumber: null,
    serviceAnnouncements: [],
    serviceCompanionDetails: {},
    serviceCompanionList: [],
    serviceCompanionLocations: [],
    serviceQuestions: [],
    servicesAvailable: [],
    servicesFiltered: [],
    songs: [],
    speakerList: [],
    speakerOptions: [],
    speakers: [],
    sortedServiceQuestions: [],
    usedOccurrences: [],
};

const ServiceCompanionReducers = (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'ServiceCompanionActions.ON_RESETSERVICECOMPANION':
            return {
                ...state,
                ...action.details,
            };

        case 'ServiceCompanionActions.ON_WIPESERVICECOMPANION':
            return DEFAULT_STATE;

        case 'ServiceCompanionActions.ON_WIPESERVICECOMPANIONDETAILS':
            return {
                ...state,
                announcements: [],
                availServices: [],
                campuses: [],
                campusSongIds: [],
                connectionForm: null,
                connectionQuestions: [],
                dateEnd: moment(),
                dateStart: moment(),
                description: '',
                id: null,
                image: null,
                isPublished: false,
                name: '',
                mediaId: null,
                mediaUrl: null,
                isExternalMedia: false,
                newAnnouncements: [],
                newConnectionQuestions: [],
                newNextStepQuestions: [],
                newNextStepServiceQuestions: [],
                newServiceQuestions: [],
                nextStepConnectionQuestions: [],
                nextStepServiceQuestions: [],
                outline: {
                    serviceTemplateId: 0,
                    name: null,
                    category: 0,
                    categoryId: 0,
                    categoryName: undefined,
                    content: null,
                    version: 0,
                },
                removeAnnouncements: [],
                removeConnectionQuestions: [],
                removeNextStepQuestions: [],
                seriesSearchFilterSort: {
                    searchValue: '',
                    searchOptions: {
                        query: '',
                        isActiveFilter: null,
                        isInUseFilter: null,
                        sortValue: 0,
                        sortQuery: 'name',
                        sortFieldOrder: 'asc',
                        pageNumber: 0,
                    },
                },
                seriesSet: [],
                seriesId: null,
                seriesList: null,
                seriesName: null,
                seriesPartNumber: null,
                serviceAnnouncements: [],
                serviceCompanionDetails: {},
                serviceCompanionLocations: [],
                serviceQuestions: [],
                servicesAvailable: [],
                servicesFiltered: [],
                songs: [],
                speakerList: [],
                speakerOptions: [],
                speakers: [],
                sortedServiceQuestions: [],
            };

        case 'ServiceCompanionActions.ON_WIPESERVICEQUESTIONS':
            return {
                ...state,
                serviceQuestions: [],
                sortedServiceQuestions: [],
            };

        case 'ServiceCompanionActions.ON_WIPENEWSERVICEQUESTIONS':
            return {
                ...state,
                newConnectionQuestions: [],
                newServiceQuestions: [],
                removeConnectionQuestions: [],
            };

        case 'ServiceCompanionActions.ON_WIPENEXTSTEPSERVICEQUESTIONS':
            return {
                ...state,
                nextStepServiceQuestions: [],
            };

        case 'ServiceCompanionActions.ON_WIPENEWNEXTSTEPSERVICEQUESTIONS':
            return {
                ...state,
                newNextStepQuestions: [],
                newNextStepServiceQuestions: [],
                removeNextStepQuestions: [],
            };

        case 'ServiceCompanionActions.ON_WIPENEWANNOUNCEMENTS':
            return {
                ...state,
                newAnnouncements: [],
                removeAnnouncements: [],
            };

        case 'ServiceCompanionActions.ON_SETCONNECTIONFORM':
            return {
                ...state,
                connectionForm: action.connectionForm,
            };

        case 'ServiceCompanionActions.ON_SETISPUBLISHED':
            return {
                ...state,
                isPublished: action.isPublished,
            };

        case 'ServiceCompanionActions.ON_SETSERVICEDETAILS':
            return {
                ...state,
                [action.key]: action.details,
            };

        case 'ServiceCompanionActions.ON_SETSERVICEANNOUNCEMENTS':
            return {
                ...state,
                announcements: action.announcements,
            };

        case 'ServiceCompanionActions.ON_SETSERVICECAMPUSES':
            return {
                ...state,
                campuses: action.campuses,
            };

        case 'ServiceCompanionActions.ON_SETSERVICESERVICES':
            return {
                ...state,
                availServices: action.services,
            };

        case 'ServiceCompanionActions.ON_SETSERVICEQUESTIONS':
            return {
                ...state,
                serviceQuestions: action.connectionQuestions,
            };

        case 'ServiceCompanionActions.ON_SETNEWCONNECTIONQUESTIONS':
            return {
                ...state,
                newConnectionQuestions: action.connectionQuestions,
            };

        case 'ServiceCompanionActions.ON_SETNEWSERVICEQUESTIONS':
            return {
                ...state,
                newServiceQuestions: action.newServiceQuestions,
            };

        case 'ServiceCompanionActions.ON_SETSORTEDSERVICEQUESTIONS':
            return {
                ...state,
                sortedServiceQuestions: action.connectionQuestions,
            };

        case 'ServiceCompanionActions.ON_SETREMOVECONNECTIONQUESTIONS':
            return {
                ...state,
                removeConnectionQuestions: action.connectionQuestions,
            };

        case 'ServiceCompanionActions.ON_SETNEXTSTEPSERVICEQUESTIONS':
            return {
                ...state,
                nextStepServiceQuestions: action.connectionQuestions,
            };

        case 'ServiceCompanionActions.ON_SETNEWNEXTSTEPQUESTIONS':
            return {
                ...state,
                newNextStepQuestions: action.connectionQuestions,
            };

        case 'ServiceCompanionActions.ON_SETREMOVENEXTSTEPQUESTIONS':
            return {
                ...state,
                removeNextStepQuestions: action.connectionQuestions,
            };

        case 'ServiceCompanionActions.ON_SETNEWANNOUNCEMENTS':
            return {
                ...state,
                newAnnouncements: action.announcements,
            };

        case 'ServiceCompanionActions.ON_SETREMOVEANNOUNCEMENTS':
            return {
                ...state,
                removeAnnouncements: action.announcements,
            };

        case 'ServiceCompanionActions.ON_GETSERVICECOMPANIONS':
            return {
                ...state,
                query: action.params.query,
                serviceCompanionList: action.result,
            };

        case 'ServiceCompanionActions.ON_GETSERVICECOMPANION':
            return {
                ...state,
                ...action.result,
                speakerOptions: _.map(action.result.speakers, (speaker) => (
                    {
                        label: `${speaker.firstName} ${speaker.lastName}`,
                        value: speaker.id,
                    })),
            };

        case 'ServiceCompanionActions.ON_GETSERVICECOMPANIONTEMPLATECAMPUSES':
            return {
                ...state,
                serviceCompanionLocations: action.result,
            };

        case 'ServiceCompanionActions.ON_REMOVECAMPUSOCCURRENCES':
            return state;

        case 'ServiceCompanionActions.ON_ADDCAMPUSOCCURRENCES':
            return state;

        case 'ServiceCompanionActions.ON_ADDLOCATION':
            return state;

        case 'ServiceCompanionActions.ON_GETSERVICECOMPANIONCAMPUSTEMPLATEOCCURRENCES':
            return {
                ...state,
                campusSongIds: action.result.songIds,
                isPublished: action.result.isPublished,
                services: action.result.occurrences,
            };

        case 'ServiceCompanionActions.ON_GETSERVICECOMPANIONCAMPUSTEMPLATEOCCURRENCESFILTERED':
            return {
                ...state,
                servicesFiltered: action.result,
            };

        case 'ServiceCompanionActions.ON_GETSERVICECOMPANIONCAMPUSTEMPLATEAVAILABLEOCCURRENCES':
            return {
                ...state,
                servicesAvailable: action.result,
            };

        case 'ServiceCompanionActions.ON_GETCAMPUSSERVICETEMPLATEISPUBLISHED':
            return {
                ...state,
                isPublished: action.result,
            };

        case 'ServiceCompanionActions.ON_GETSERVICETEMPLATEQUESTIONS':
            return {
                ...state,
                connectionQuestions: actionResult.map((q) => q.id),
                serviceQuestions: actionResult,
                sortedServiceQuestions: actionResult,
            };

        case 'ServiceCompanionActions.ON_GETSERVICEQUESTIONS':
            return {
                ...state,
                serviceQuestions: actionResult,
                sortedServiceQuestions: actionResult,
            };

        case 'ServiceCompanionActions.ON_GETNEWSERVICEQUESTIONS':
            return {
                ...state,
                newServiceQuestions: action.result,
            };

        case 'ServiceCompanionActions.ON_GETNEXTSTEPSERVICEQUESTIONS':
            return {
                ...state,
                nextStepServiceQuestions: action.result,
            };

        case 'ServiceCompanionActions.ON_GETNEWNEXTSTEPSERVICEQUESTIONS':
            return {
                ...state,
                newNextStepServiceQuestions: action.result,
            };

        case 'ServiceCompanionActions.ON_GETSERVICETEMPLATENEXTSTEPQUESTIONS':
            return {
                ...state,
                nextStepQuestions: action.result.map((q) => q.id),
                nextStepServiceQuestions: action.result,
            };

        case 'ServiceCompanionActions.ON_GETMINISTRIES':
            return {
                ...state,
                ministries: Array.isArray(action.ministries) ?
                    [...state.ministries, ...action.ministries] :
                    [...state.ministries],
            };

        case 'ServiceCompanionActions.ON_GETANNOUNCEMENTCATEGORIES':
            return {
                ...state,
                announcementCategories: action.categories,
            };

        case 'ServiceCompanionActions.ON_GETMINISTRYCATEGORIES':
            return {
                ...state,
                ministryCategories: action.categories,
            };

        case 'ServiceCompanionActions.ON_GETMINISTRYTAGS':
            return {
                ...state,
                ministryTags: action.tags,
            };

        case 'ServiceCompanionEventsActions.ON_GETEVENTCATEGORIES':
            return {
                ...state,
                eventCategories: action.categories,
            };

        case 'ServiceCompanionEventsActions.ON_GETEVENTS':
            return {
                ...state,
                events: action.events,
            };

        case 'ServiceCompanionActions.ON_GETEVENTTAGS':
            return {
                ...state,
                eventTags: action.tags,
            };

        case 'ServiceCompanionActions.ON_GETCAMPUSES':
            return {
                ...state,
                campuses: action.campuses,
            };

        case 'ServiceCompanionActions.ON_SETSPEAKEROPTIONS':
            return {
                ...state,
                speakerOptions: action.speakerOptions,
            };

        case 'ServiceCompanionActions.ON_GETSPEAKERS':
            return {
                ...state,
                speakerList: action.result,
            };

        case 'ServiceCompanionActions.ON_GETSERIES':
            return {
                ...state,
                seriesList: action.result,
            };

        case 'ServiceCompanionActions.ON_GETSERVICETEMPLATEANNOUNCEMENTS':
            return {
                ...state,
                serviceAnnouncements: action.result,
            };

        case 'ServiceCompanionActions.ON_GETUSEDOCCURRENCES':
            return {
                ...state,
                usedOccurrences: action.result,
            };

        // Get Songs
        case 'ServiceCompanionActions.ON_GETSONGS':
            return {
                ...state,
                songs: action.result,
            };

        // Set series loading
        case 'ServiceCompanionActions.ON_SETSERVICELOADING':
            return {
                ...state,
                isLoading: true,
            };

        // Get seriesSet
        case 'ServiceCompanionActions.ON_GETSERVICESERIES':
            return {
                ...state,
                query: action.params.query,
                isLoading: false,
                seriesSet: action.result.results,
            };

        case 'ServiceCompanionActions.ON_SETOUTLINE':
            return {
                ...state,
                outline: action.outline,
            };

        case 'ServiceCompanionActions.ON_SETSEARCHOPTIONS':
            return {
                ...state,
                searchOptions: action.searchOptions,
            };

        case 'ServiceCompanionActions.ON_TOGGLEISEXTERNALMEDIA':
            return {
                ...state,
                isExternalMedia: !state.isExternalMedia,
            };

        default:
            return state;
    }
};

export default ServiceCompanionReducers;
