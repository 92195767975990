import moment from 'moment-timezone';
import { i18n } from '../../global/constants.js';
import { SelectOption } from '../../global/models';
import { ButtonType } from './models';

export const EMPTY_BUTTON_ID = -1;

export const BEM_BLOCK_NAME = 'digital-program-builder';
export const BEM_BLOCK_NAME_SERVICES = 'digital-program-builder-services';
export const BEM_BLOCK_NAME_CUSTOM_OPTION = 'digital_program_builder_form';
export const BEM_BLOCK_NAME_BUTTON_CONTENT_CONTAINER = 'digital_program--button_content_container';
export const BEM_BLOCK_NAME_LEAD_TIME = 'digital-program-builder-lead-time';

export const BUTTON_TYPE_LABELS = {
    [ButtonType.Announcement]: 'Announcements',
    [ButtonType.ConnectionFormTemplate]: 'Connection Form',
    [ButtonType.Link]: 'External Link',
    [ButtonType.GivingDesignation]: 'Giving',
    [ButtonType.Journey]: 'Journey',
    [ButtonType.SermonOutline]: 'Notes',
};

export const ICON_BUILDER_OPTIONS = [
    {
        label: 'Action',
        value: 'action',
    },
    {
        label: 'Activity',
        value: 'activity',
    },
    {
        label: 'Alert',
        value: 'alert',
    },
    {
        label: 'Baptism',
        value: 'baptism',
    },
    {
        label: 'Campus',
        value: 'campus',
    },
    {
        label: 'Child',
        value: 'child',
    },
    {
        label: 'Class 101',
        value: 'class-101',
    },
    {
        label: 'Class 201',
        value: 'class-201',
    },
    {
        label: 'Class 301',
        value: 'class-301',
    },
    {
        label: 'Class 401',
        value: 'class-401',
    },
    {
        label: 'Class All',
        value: 'class-taken',
    },
    {
        label: 'Comment',
        value: 'comment',
    },
    {
        label: 'Connection-Card',
        value: 'connection-cards',
    },
    {
        label: 'Date',
        value: 'date',
    },
    {
        label: 'Devotion',
        value: 'devotion',
    },
    {
        label: 'Discipleship-Milestone',
        value: 'discipleship',
    },
    {
        label: 'Document',
        value: 'document',
    },
    {
        label: 'Duplicate',
        value: 'duplicate',
    },
    {
        label: 'Edit Text',
        value: 'edit-text',
    },
    {
        label: 'Email',
        value: 'email',
    },
    {
        label: 'Event ticket',
        value: 'ticket',
    },
    {
        label: 'Favorite',
        value: 'favorite',
    },
    {
        label: 'Flag',
        value: 'flag',
    },
    {
        label: 'Home',
        value: 'home',
    },
    {
        label: 'Info',
        value: 'info',
    },
    {
        label: 'Insights',
        value: 'insights',
    },
    {
        label: 'Journey',
        value: 'journey',
    },
    {
        label: 'Location',
        value: 'location',
    },
    {
        label: 'Media',
        value: 'media',
    },
    {
        label: 'Ministry',
        value: 'ministry',
    },
    {
        label: 'Music',
        value: 'music',
    },
    {
        label: 'Navigate',
        value: 'navigate',
    },
    {
        label: 'Note',
        value: 'note',
    },
    {
        label: 'Occupation',
        value: 'occupation',
    },
    {
        label: 'Password',
        value: 'password',
    },
    {
        label: 'Pencil',
        value: 'edit-2',
    },
    {
        label: 'People',
        value: 'people',
    },
    {
        label: 'Person',
        value: 'person',
    },
    {
        label: 'Personal Milestone',
        value: 'milestone',
    },
    {
        label: 'Phone Cell',
        value: 'phone-cell',
    },
    {
        label: 'Phone Home',
        value: 'phone-home',
    },
    {
        label: 'Phone Work',
        value: 'phone-work',
    },
    {
        label: 'Pin',
        value: 'pin',
    },
    {
        label: 'Placeholder',
        value: 'placeholder',
    },
    {
        label: 'Prayer',
        value: 'prayer',
    },
    {
        label: 'Privacy',
        value: 'security',
    },
    {
        label: 'Pulpit',
        value: 'pulpit',
    },
    {
        label: 'Reassigned',
        value: 'reassigned-2',
    },
    {
        label: 'Recurring',
        value: 'recurring',
    },
    {
        label: 'Saddleback',
        value: 'saddleback',
    },
    {
        label: 'Scan ID',
        value: 'scan-id',
    },
    {
        label: 'Search',
        value: 'search',
    },
    {
        label: 'Send',
        value: 'send',
    },
    {
        label: 'Serve',
        value: 'volunteer',
    },
    {
        label: 'Serving Opportunity',
        value: 'serving-opportunity',
    },
    {
        label: 'Settings',
        value: 'settings',
    },
    {
        label: 'Shape Experiences',
        value: 'shape-experiences',
    },
    {
        label: 'Shape Heart',
        value: 'shape-heart',
    },
    {
        label: 'Share',
        value: 'share-2',
    },
    {
        label: 'Snail Mail',
        value: 'snail-mail',
    },
    {
        label: 'Steps',
        value: 'steps',
    },
    {
        label: 'Team',
        value: 'team',
    },
    {
        label: 'Time',
        value: 'time',
    },
    {
        label: 'Time Period',
        value: 'time-period',
    },
    {
        label: 'Transfer',
        value: 'transfer',
    },
    {
        label: 'User',
        value: 'user',
    },
    {
        label: 'Attendance',
        value: 'attendance',
    },
];

export const COLOR_BUILDER_OPTIONS = [
    {
        label: 'Sky',
        value: '#6DCFF6',
    },
    {
        label: 'Sky - Dark',
        value: '#4095B6',
    },
    {
        label: 'Cyan',
        value: '#00AEEF',
    },
    {
        label: 'Cyan - Dark',
        value: '#0093C6',
    },
    {
        label: 'Blue',
        value: '#134174',
    },
    {
        label: 'Green',
        value: '#54CD86',
    },
    {
        label: 'Green - Dark',
        value: '#398F5D',
    },
    {
        label: 'Teal',
        value: '#56C4C4',
    },
    {
        label: 'Teal - Dark',
        value: '#329594',
    },
    {
        label: 'Purple',
        value: '#C68EF6',
    },
    {
        label: 'Purple - Dark',
        value: '#9958D2',
    },
    {
        label: 'Pink',
        value: '#EB4EA1',
    },
    {
        label: 'Pink - Dark',
        value: '#C23680',
    },
    {
        label: 'Red',
        value: '#EE4334',
    },
    {
        label: 'Red - Dark',
        value: '#C2241A',
    },
    {
        label: 'Red Orange',
        value: '#F47A46',
    },
    {
        label: 'Red Orange - Dark',
        value: '#D94710',
    },
    {
        label: 'Orange',
        value: '#F99E49',
    },
    {
        label: 'Orange - Dark',
        value: '#C96D20',
    },
    {
        label: 'Gray',
        value: '#DBE0E3',
    },
    {
        label: 'Gray - Dark',
        value: '#97A4AB',
    },
];

export const DEFAULT_FILTER_OPTIONS = {
    endDate: moment().endOf('day'),
    sort: 'date,asc',
    startDate: moment().startOf('day'),
};

export const SORT_OPTIONS: SelectOption<string, string>[] = [
    {
        label: i18n('Service Date (Asc)'),
        value: 'date,asc',
    }, {
        label: i18n('Service Date (Desc)'),
        value: 'date,desc',
    },
];

export const DEFAULT_LEAD_TIME_VALUE: number = 5;

export const DEFAULT_LEAD_TIME_HOURS =
    (service) => Math.floor((service?.leadTime ?? 0) / 60);

export const DEFAULT_LEAD_TIME_MINUTES =
    (service) => (service?.leadTime ?? DEFAULT_LEAD_TIME_VALUE) % 60;
