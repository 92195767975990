import './personRecordDrawer.scss';

import {
    Drawer,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
    isFunction,
    noop,
} from 'lodash';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import React, { useState } from 'react';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import {
    BEM_BLOCK_NAME,
    PERSON_RECORD_TITLE,
} from '../recordConstants';
import {
    useComponentDidMount,
    useComponentDidUpdate,
} from '../../../global/lifeCycleHooks';
import * as personRecordActions from './personRecord.actions.js';
import { i18n } from '../../../global/constants.js';
import Record from '../record';
import { Tab } from '../models';
import { Person } from '../../global/models';

type PropTypes = {
    actionBarChildren?: React.ReactNode;
    actionBarControls?: {
        md: any[];
        sm: any[];
        smControls?: any[];
    };
    children?: React.ReactNode;
    className?: string | null;
    isOpen: boolean;
    tabs: Tab[];
    maxWidth?: number | string;
    noteDefaultCategoryId?: number | null;
    notesEntityType?: string | null;
    notesEntityId?: number | null;
    onClose: (params?) => void;
    onCloseComplete?: () => void;
    onOpenComplete?: () => void;
    onRelationshipCardClick?: (personId: number) => void;
    shouldOverrideActions?: boolean | null;
    person?: Person | null;
    personId: number | null;
    relationshipConnectionApiParams?: {
        checkInValidation?: boolean;
    } | null;
    shouldInitialNotesDrawerOpen?: boolean | null;
    title?: string | null;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    root: {
        '& .drawer--action_bar': {
            '& .action_bar--grid': {
                position: 'absolute',
                right: spacing(2),
                width: 'auto',
                '& .action_bar--grid_column': {
                    paddingLeft: 0,
                    paddingRight: `${spacing(1)} !important`,
                    '&:last-child': {
                        paddingRight: '0 !important',
                    },
                },
            },
        },
    },
}));

function PersonRecordDrawer(props: PropTypes) {
    const {
        actionBarChildren,
        actionBarControls = {
            sm: [],
            md: [],
        },
        children,
        className,
        isOpen,
        maxWidth,
        noteDefaultCategoryId,
        notesEntityType = 'person',
        notesEntityId,
        onClose,
        onCloseComplete: onCloseCompleteProp = noop,
        onOpenComplete: onOpenCompleteProp = noop,
        onRelationshipCardClick: onRelationshipCardClickProp,
        shouldOverrideActions,
        person,
        personId: personIdProp,
        relationshipConnectionApiParams,
        shouldInitialNotesDrawerOpen,
        tabs,
        title,
    } = props;

    const [blockSize, setBlockSize] = useState(null);
    const [personId, setPersonId] = useState(personIdProp);

    const classes = useStyles();

    // Lifecycle Hooks
    useComponentDidUpdate(() => {
        setPersonId(personIdProp);
    }, [
        personIdProp,
    ]);

    useComponentDidMount(() => {
        personRecordActions.reset();
        personRecordActions.resetActionBar();
        personRecordActions.resetDetailsWindow();
    });

    // Component Actions
    const onCloseComplete = () => {
        onCloseCompleteProp();
    };

    const onOpenComplete = () => {
        const element = document.querySelector(`.${BEM_BLOCK_NAME}`);

        if (element instanceof HTMLElement) {
            setBlockSize(element.offsetWidth);
        }

        onOpenCompleteProp();
    };

    const onRelationshipCardClick = (newPersonId) => {
        if (isFunction(onRelationshipCardClickProp)) {
            onRelationshipCardClickProp(newPersonId);
        } else {
            setPersonId(newPersonId);
        }
    };

    const onCloseRecord = () => {
        onClose({
            closePersonSearchDrawer: true,
        });
    };

    const defaultTitle = `${PERSON_RECORD_TITLE} ${personId || i18n('N/A')}`;

    return (
        <Drawer
            className={ClassNames(
                'person_record_drawer',
                className,
                {
                    [classes.root]: shouldOverrideActions && !isEmpty(actionBarControls),
                },
            )}
            isOpen={isOpen}
            maxWidth={maxWidth}
            onCloseComplete={onCloseComplete}
            onOpenComplete={onOpenComplete}
        >
            <Drawer.TitleBar
                closeButton={<Icon compact onClick={onClose} type="times" />}
                title={title || defaultTitle}
            />

            <Record
                actionBarChildren={actionBarChildren}
                actionBarControls={actionBarControls}
                blockSize={blockSize}
                hasBackButton={false}
                isDrawerChild
                noteDefaultCategoryId={noteDefaultCategoryId}
                notesEntityType={notesEntityType}
                notesEntityId={notesEntityId || personId}
                onRelationshipCardClick={onRelationshipCardClick}
                person={person}
                personId={personId}
                relationshipConnectionApiParams={relationshipConnectionApiParams}
                shouldInitialNotesDrawerOpen={shouldInitialNotesDrawerOpen}
                shouldOverrideActions={shouldOverrideActions}
                tabs={tabs}
                onCloseRecord={onCloseRecord}
            />

            {children}
        </Drawer>
    );
}

export default connect()(PersonRecordDrawer);
