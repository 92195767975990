import ChunkedPaginationUtils from '../global/chunkedPaginationUtils.js';

const _localCache = new ChunkedPaginationUtils();
const DEFAULT_STATE = {
    canLoadMore: false,
    isFetching: false,
    isDetailFetching: false,
    needsToRequest: false,
    pageNumber: 0,
    total: 0,
    notifications: [],
    discipleship: {},
    ministry: {},
    workflow: {},
    system: {},
    events: {},
    isViewed: null,
    summary: {},
    notificationDetail: {},
    pageSize: _localCache.getPageSize(),
    searchQuery: '',
    sortBy: 'createdate,DESC',
    sourceCategoryFilter: '',
    timeline: 0,
    notificationUnreadCount: 0,
};

const notificationCenter = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'NotificationCenterActions.ON_BEFORE_GETUSERNOTIFICATIONSFORNOTIFICATIONCENTER':
            return {
                ...state,
                isFetching: true,
                timeline: action.params.days,
                pageNumber: action.params.pageNumber,
                notifications: action.params.pageNumber === 0 ? [] : state.notifications,
                sortBy: action.params.sortBy,
                sourceCategoryFilter: action.params.category,
            };

        case 'NotificationCenterActions.ON_GETUSERNOTIFICATIONSFORNOTIFICATIONCENTER':
            const data = action.result;
            const { counts } = data;
            _localCache.loadPage(data.notifications, counts.summary.total, action.callbackParams.first);
            return {
                ...state,
                canLoadMore: _localCache.canLoadMore(),
                pageNumber: _localCache.getCurrentPageNumber(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageSize: _localCache.getPageSize(),
                isFetching: false,
                total: _localCache.getTotalCount(),
                notifications: _localCache.getAll(true),
                discipleship: counts.discipleship,
                ministry: counts.ministry,
                workflow: counts.workflow,
                system: counts.system,
                events: counts.events,
                summary: counts.summary,
                counts,
            };

        case 'NotificationCenterActions.ON_GETNOTIFICATIONFORNOTIFICATIONCENTER_NEXTPAGE':
            return {
                ...state,
                isFetching: false,
                canLoadMore: _localCache.canLoadMore(),
                pageNumber: _localCache.getCurrentPageNumber(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageSize: _localCache.getPageSize(),
                total: _localCache.getTotalCount(),
                notifications: _localCache.getAll(true),
            };

        case 'NotificationCenterDetailActions.ON_BEFORE_GETNOTIFICATIONDETAIL':
            return {
                ...state,
                isDetailFetching: true,
                notificationDetail: {},
            };

        case 'NotificationCenterDetailActions.ON_GETNOTIFICATIONDETAIL':
            return {
                ...state,
                isDetailFetching: false,
                notificationDetail: action.result,
            };
        case 'NotificationCenterActions.ON_UPDATE_UNREAD_NOTIFICATION_COUNT_FOR_NOTIFICATION_CENTER':
            return { ...state, notificationUnreadCount: action.params.count };

        default:
            return state;
    }
};

export default notificationCenter;
