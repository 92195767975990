import _ from 'lodash';

export const DEFAULT_STATE = {
    parsedFieldNames: [],
    destinationFields: [],
    sourceData: [],
    parsedDataPreview: [],
    isValid: false,
};

export default function (state = DEFAULT_STATE, action) {
    if (action.type === 'AdminImportPreviewActions.ON_PREVIEW') {
        if (action.result.isOk) {
            let n = 1;
            return {
                isValid: action.result.isOk,
                parsedFieldNames: _.cloneDeep(action.result.parsedFieldNames),
                sourceData: _.cloneDeep(action.result.sourceData),
                parsedDataPreview: _.cloneDeep(action.result.parsedDataPreview),
                destinationFields: _.map(action.result.destinationFields, (value, key) => ({ key, value: n++, label: value })),
            };
        }
        return DEFAULT_STATE;
    }
    return state;
}
