import { isEqual } from 'lodash';
import { i18n } from '../../global/constants.js';

export const defaultFilters = {
    deceased: false,
    sort: {
        label: i18n('Relevance'),
        value: 'relevance',
    },
};

export const DEFAULT_STATE = {
    searchTerm: '',
    appliedFilters: { ...defaultFilters },
    areFiltersDirty: false,
    defaultFilters: { ...defaultFilters },
    dirtyFilters: { ...defaultFilters },
    isFiltering: false,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        case 'PeopleSearchFilters.ON_APPLY_FILTERS':
        {
            const { dirtyFilters } = state;
            const isFiltering = !isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'PeopleSearchFilters.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(state, { ...defaultFilters });

        case 'PeopleSearchFilters.ON_CLEAR_SEARCH_TERM':
        {
            return {
                ...state,
                searchTerm: DEFAULT_STATE.searchTerm,
            };
        }

        case 'PeopleSearchFilters.ON_DECEASED_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, deceased: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'PeopleSearchFilters.ON_SORT_ORDER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, sort: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'PeopleSearchFilters.ON_DIRTY_SORT_ORDER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(state, { ...dirtyFilters, sort: actionValue });
        }

        case 'PeopleSearchFilters.ON_DIRTY_DECEASED_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(state, { ...dirtyFilters, deceased: actionValue });
        }

        case 'PeopleSearchFilters.ON_UPDATE_SEARCH_TERM':
        {
            return {
                ...state,
                searchTerm: actionValue,
            };
        }

        case 'PeopleSearchFilters.ON_RESET':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};
