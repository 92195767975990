import _ from 'lodash';

const defaultFilters = {
    groups: [],
    roles: [],
    sort: {
        label: 'Last Name (Ascending)',
        value: 'lastname,asc',
    },
    statuses: [],
};

const DEFAULT_STATE = {
    appliedFilters: { ...defaultFilters },
    areFiltersDirty: false,
    defaultFilters: { ...defaultFilters },
    dirtyFilters: { ...defaultFilters },
    isFiltering: false,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !_.isEqual(nextAppliedFilters, defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, update) => {
    const { appliedFilters, dirtyFilters: prevDirtyFilters } = prevState;

    const nextDirtyFilters = { ...prevDirtyFilters, ...update };

    const areFiltersDirty = !_.isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        case 'MinistryRosterFiltersActions.ON_APPLY_FILTERS':
        {
            const { dirtyFilters } = state;
            const isFiltering = !_.isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'MinistryRosterFiltersActions.ON_RESET_FILTERS':
            return { ...DEFAULT_STATE };

        case 'MinistryRosterFiltersActions.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(state, { ...defaultFilters });

        case 'MinistryRosterFiltersActions.ON_GROUP_SELECTION_CHANGED_AND_APPLIED':
        {
            const { group, isSelected } = actionValue;
            const { appliedFilters: prevAppliedFilters } = state;
            const { groups: prevSelectedGroups } = prevAppliedFilters;

            const nextSelectedGroups = isSelected ?
                prevSelectedGroups.concat([group]) :
                _.filter(prevSelectedGroups, (g) => g.value !== group.value);

            const nextAppliedFilters = { ...prevAppliedFilters, groups: nextSelectedGroups };

            return updateAppliedFilterState(state, nextAppliedFilters);
        }

        case 'MinistryRosterFiltersActions.ON_ROLE_SELECTION_CHANGED_AND_APPLIED':
        {
            const { isSelected, role } = actionValue;
            const { appliedFilters: prevAppliedFilters } = state;
            const { roles: prevSelectedRoles } = prevAppliedFilters;

            const nextSelectedRoles = isSelected ?
                prevSelectedRoles.concat([role]) :
                _.filter(prevSelectedRoles, (r) => r.value !== role.value);

            const nextAppliedFilters = { ...prevAppliedFilters, roles: nextSelectedRoles };

            return updateAppliedFilterState(state, nextAppliedFilters);
        }

        case 'MinistryRosterFiltersActions.ON_SORT_ORDER_CHANGED_AND_APPLIED':
        {
            const { appliedFilters: prevAppliedFilters } = state;

            const nextAppliedFilters = { ...prevAppliedFilters, sort: actionValue };

            return updateAppliedFilterState(state, nextAppliedFilters);
        }

        case 'MinistryRosterFiltersActions.ON_STATUS_SELECTION_CHANGED_AND_APPLIED':
        {
            const { isSelected, status } = actionValue;

            const { appliedFilters: prevAppliedFilters } = state;
            const { statuses: prevSelectedStatuses } = prevAppliedFilters;

            const nextSelectedStatuses = isSelected ?
                prevSelectedStatuses.concat([status]) :
                _.filter(prevSelectedStatuses, (s) => s.value !== status.value);

            const nextAppliedFilters = { ...prevAppliedFilters, statuses: nextSelectedStatuses };

            return updateAppliedFilterState(state, nextAppliedFilters);
        }

        case 'MinistryRosterFiltersActions.ON_DIRTY_GROUP_SELECTIONS_CHANGED':
            return updateDirtyFilterState(state, { groups: actionValue });

        case 'MinistryRosterFiltersActions.ON_DIRTY_ROLE_SELECTIONS_CHANGED':
            return updateDirtyFilterState(state, { roles: actionValue });

        case 'MinistryRosterFiltersActions.ON_DIRTY_SORT_ORDER_CHANGED':
            return updateDirtyFilterState(state, { sort: actionValue });

        case 'MinistryRosterFiltersActions.ON_DIRTY_STATUS_SELECTIONS_CHANGED':
            return updateDirtyFilterState(state, { statuses: actionValue });
    }

    return state;
};
