enum HealthyChurchComponent {
    Core = 'Core',
    ChurchInsights = 'ChurchInsights',
    Events = 'Events',
    Giving = 'Giving',
    Journey = 'Journey',
    Notifications = 'Notifications',
    SmallGroups = 'SmallGroups',
    Workflows = 'Workflows',
    UI = 'UI', // deliberately last (versus above 'Workflows' as it would be alphabetically) to drive ordering on /about page
}

export default HealthyChurchComponent;
