import {
    isEqual,
    without,
} from 'lodash';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

export const localCache = new ChunkedPaginationUtils();

export const defaultFilters = {
    selectedChurchEntityIds: [],
    sort: {
        label: 'Name (A > Z)',
        value: 'name',
    },
};

export const DEFAULT_STATE = {
    appliedFilters: { ...defaultFilters },
    areFiltersDirty: false,
    canLoadMore: false,
    data: [],
    defaultFilters: { ...defaultFilters },
    dirtyFilters: { ...defaultFilters },
    isFiltering: false,
    isFetching: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: localCache.getPageSize(),
    searchTerm: '',
    total: 0,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'UserSearchActions.ON_APPLY_FILTERS':
        {
            const { dirtyFilters } = state;
            const isFiltering = !isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'UserSearchActions.ON_BEFORE_GET': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'UserSearchActions.ON_CHURCH_ENTITY_FILTER_CHANGED_AND_APPLIED':
        {
            const { churchEntityId, isChecked } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedChurchEntityIds: isChecked ?
                    [...state.appliedFilters.selectedChurchEntityIds, churchEntityId] :
                    without(
                        state.appliedFilters.selectedChurchEntityIds,
                        churchEntityId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'UserSearchActions.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(state, { ...defaultFilters });

        case 'UserSearchActions.ON_CLEAR_SEARCH_TERM': {
            return {
                ...state,
                searchTerm: DEFAULT_STATE.searchTerm,
            };
        }

        case 'UserSearchActions.ON_DIRTY_CHURCH_ENTITY_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedChurchEntityIds: action.value },
            );
        }

        case 'UserSearchActions.ON_DIRTY_SORT_ORDER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(state, { ...dirtyFilters, sort: action.value });
        }

        case 'UserSearchActions.ON_GET': {
            localCache.loadPage(
                action.result.users,
                action.result.count,
                action.callbackParams.first,
            );

            // fall-through to UserSearchActions.ON_NEXTPAGE case
        }

        // eslint-disable-next-line no-fallthrough
        case 'UserSearchActions.ON_NEXTPAGE': {
            return {
                ...state,
                isFetching: false,
                data: localCache.getAll(true),
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'UserSearchActions.ON_RESET': {
            return DEFAULT_STATE;
        }

        case 'UserSearchActions.ON_SET_SEARCH_TERM': {
            return {
                ...state,
                searchTerm: action.value,
            };
        }

        case 'UserSearchActions.ON_SORT_ORDER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, sort: action.value };
            return updateAppliedFilterState(state, updatedFilters);
        }

        default:
            return state;
    }
};
