import filterOptionsReducer from '../../../global/filterOptionsReducer.js';
import { sortOptions } from './constants.js';

const DEFAULT_FILTER_OPTIONS = {
    campus: null,
    categoryIds: [],
    sortBy: sortOptions[0],
    statusActive: true,
    statusInactive: true,
    typeRegular: true,
    typeWorship: true,
};

export default (state, action) => (filterOptionsReducer(
    state,
    action.params,
    action.query,
    action.filters,
    DEFAULT_FILTER_OPTIONS,
    action.type === 'ConnectionFormTemplatesActions.ON_RESET_SEARCH',
    action.type === 'ConnectionFormTemplatesActions.ON_SEARCH_QUERY',
    action.type === 'ConnectionFormTemplatesActions.ON_FILTERS',
    action.type === 'ConnectionFormTemplatesActions.ON_APPLY_FILTERS',
    action.type === 'ConnectionFormTemplatesActions.ON_CLEAR_FILTERS',
));
