export const DEFAULT_STATE = {
    historic: [],
    occurrence: [],
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventAttendeeActions.ON_GETHISTORICATTENDEE':
            return { ...state, historic: action.result };

        case 'EventAttendeeActions.ON_GETOCCURRENCEATTENDEE':
            return { ...state, occurrence: action.result };
    }

    return state;
}
