import {
    MobileStepper,
    ModalDeprecated,
} from '@saddlebackchurch/react-cm-ui';
import {
    makeStyles,
} from '@saddlebackchurch/react-cm-ui/core/styles';
import ClassNames from 'classnames';
import {
    isEmpty,
    noop,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
    useState,
} from 'react';
import Carousel from 'react-simply-carousel';
import './modalPrompt.scss';
import ModalPromptBody, {
    modalPromptDefaultProps,
    modalPromptPropTypes,
} from './modalPromptBody.jsx';

const propTypes = {
    ...modalPromptPropTypes,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    multipleWarnings: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            subTitle: PropTypes.string,
        }),
    ),
};

const defaultProps = {
    ...modalPromptDefaultProps,
    className: undefined,
    dataTestId: undefined,
    isOpen: false,
    onClose: undefined,
    multipleWarnings: [],
};

const useStyles = makeStyles({
    root: {
        '& .modal-container-inner': {
            // when having multiple messages, the dots in the bottom container will handle the space needed
            paddingBottom: ({ multipleWarnings }) => (multipleWarnings?.length > 1 ?
                '0 !important' :
                44
            ),
        },
    },
    multipleMessagesContainer: {
        '& .mobile_stepper': {
            justifyContent: 'center',
            width: '100%',
            position: 'inherit',
            paddingTop: 36,
            paddingBottom: 11,
        },
    },
});

const MODAL_WIDTH_FOR_DESKTOP = 442;
const blockClassName = 'modal_prompt';

function ModalPrompt(props) {
    const {
        cancelBtn,
        cancelBtnColor,
        cancelBtnLabel,
        className,
        confirmBtnColor,
        confirmBtnLabel,
        dataTestId,
        iconColor,
        iconType,
        isOpen,
        secondaryConfirmBtn,
        onCancel,
        onClose,
        onConfirm,
        subTitle,
        title,
        multipleWarnings,
    } = props;

    const classes = useStyles(props);

    const containerClasses = ClassNames(
        blockClassName,
        className,
        classes.root,
    );
    const [activeSlide, setActiveSlide] = useState(0);
    const [modalBodyWidth, setModalBodyWidth] = useState(0);

    const onMultipleWarningsContainerRefUpdate = isEmpty(multipleWarnings) ?
        noop :
        (ref) => {
            const shouldUpdateModalBodyWidth = ref &&
            ref.offsetWidth > 0 &&
            modalBodyWidth === 0;

            if (shouldUpdateModalBodyWidth) {
                setModalBodyWidth(ref.clientWidth);
            }
        };

    const getNextMessage = () => { setActiveSlide((prev) => (prev + 1)); };

    const onMultipleWarningMessagesClose = () => {
        setTimeout(() => {
            setActiveSlide(0);
        }, 500);

        onCancel();
    };

    const modalPromtBodyProps = {
        classes,
        cancelBtn,
        cancelBtnColor,
        cancelBtnLabel,
        confirmBtnColor,
        confirmBtnLabel,
        iconColor,
        iconType,
        secondaryConfirmBtn,
        onCancel,
        onConfirm,
        subTitle,
        title,
    };

    let modalBody;

    if (isEmpty(multipleWarnings)) {
        modalBody = (
            <ModalPromptBody
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...modalPromtBodyProps}
            />
        );
    } else if (multipleWarnings.length === 1) {
        const warning = multipleWarnings[0];
        modalBody = (
            <ModalPromptBody
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...modalPromtBodyProps}
                title={warning.title}
                subTitle={warning.subTitle}
            />
        );
    } else {
        modalBody = (
            <div
                className={classes.multipleMessagesContainer}
                ref={onMultipleWarningsContainerRefUpdate}
            >
                {modalBodyWidth > 0 && (
                    <Carousel
                        containerProps={{
                            style: {
                                width: '100%',
                                justifyContent: 'space-between',
                                userSelect: 'none',
                            },
                        }}
                        preventScrollOnSwipe
                        disableSwipeByMouse
                        disableSwipeByTouch
                        swipeTreshold={60}
                        activeSlideIndex={activeSlide}
                        onRequestChange={setActiveSlide}
                        forwardBtnProps={{ show: false }}
                        backwardBtnProps={{ show: false }}
                        dotsNav={{ show: false }}
                        itemsToShow={1}
                        itemsToScroll={1}
                        speed={200}
                        infinite={false}
                    >
                        {multipleWarnings.map((warning, index, arr) => {
                            const isLast = index === arr.length - 1;

                            return (
                                <ModalPromptBody
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...modalPromtBodyProps}
                                    onCancel={onMultipleWarningMessagesClose}
                                    onConfirm={isLast ? onConfirm : getNextMessage}
                                    subTitle={warning.subTitle}
                                    title={warning.title}
                                    style={{ width: modalBodyWidth }}
                                />
                            );
                        })}
                    </Carousel>
                )}

                <MobileStepper
                    steps={multipleWarnings.length}
                    activeStep={activeSlide}
                />
            </div>
        );
    }

    return (
        <ModalDeprecated
            autoHeight
            className={containerClasses}
            dataTestId={dataTestId}
            height="auto"
            isOpen={isOpen}
            onClose={onClose}
            width={MODAL_WIDTH_FOR_DESKTOP}
        >
            {modalBody}
        </ModalDeprecated>
    );
}

ModalPrompt.propTypes = propTypes;
ModalPrompt.defaultProps = defaultProps;

export default ModalPrompt;
