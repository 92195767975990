import {
    filter,
    sortBy,
} from 'lodash';
import {
    addOrReplaceBy,
    selectBoxTransform,
} from '../../../global/utils';

const DEFAULT_STATE = {
    isFetching: false,
    roles: [],
    selectOptions: [],
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'MinistryRoleActions.ON_BEFORE_GETROLES':
            return { ...state, isFetching: true };

        case 'MinistryRoleActions.ON_GETROLES':
            return {
                ...state,
                isFetching: false,
                roles: actionResult,
                selectOptions: selectBoxTransform(action, 'id', 'name'),
            };

        case 'MinistryRoleActions.ON_ADDROLE':
            return {
                ...state,
                roles: sortBy([...state.roles, actionResult], (role) => role.name),
            };

        case 'MinistryRoleActions.ON_SAVEROLE':
            return {
                ...state,
                roles: addOrReplaceBy(
                    state.roles,
                    (role) => role.id === actionResult.id,
                    () => actionResult,
                ),
            };

        case 'MinistryRoleActions.ON_DELETEROLE':
            return {
                ...state,
                roles: filter(state.roles, (role) => role.id !== callbackParams.id),
            };

        case 'MinistryRoleActions.ON_RESET_ROLES_LIST':
            return DEFAULT_STATE;

        default:
            return state;
    }

    return state;
};
