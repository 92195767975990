import request from 'axios';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import ApiUtils from '../../../global/apiUtils.js';

class ConnectionFormEntryEditorActions extends ApiUtils {
    setAnonymousPerson(status) {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntryEditorActions.ON_SET_ANONYMOUS_PERSON',
            result: status,
        });
    }

    setEventOccurrence(eventOccurrence) {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'ConnectionFormEntryEditorActions.ON_SET_EVENT_OCCURRENCE',
                result: eventOccurrence,
            });

            resolve();
        });
    }

    setFormEntryEditorMode(mode) {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'ConnectionFormEntryEditorActions.ON_SET_FORM_ENTRY_EDITOR_MODE',
                result: mode,
            });

            resolve();
        });
    }

    setSelectedEntryPerson(person) {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntryEditorActions.ON_SET_SELECTED_ENTRY_PERSON',
            result: person,
        });
    }

    setWorshipService(worshipService) {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'ConnectionFormEntryEditorActions.ON_SET_WORSHIP_SERVICE',
                result: worshipService,
            });

            resolve();
        });
    }

    resetAnonymousPerson() {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntryEditorActions.ON_RESET_ANONYMOUS_PERSON',
        });
    }

    resetEventOccurrence() {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntryEditorActions.ON_RESET_EVENT_OCCURRENCE',
        });
    }

    resetFormEntryEditorMode() {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntryEditorActions.ON_RESET_FORM_ENTRY_EDITOR_MODE',
        });
    }

    resetSelectedEntryPerson() {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntryEditorActions.ON_RESET_SELECTED_ENTRY_PERSON',
        });
    }

    resetWorshipService() {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntryEditorActions.ON_RESET_WORSHIP_SERVICE',
        });
    }
}

const actions = new ConnectionFormEntryEditorActions('/api/connection-form/entry/');

actions.buildReduxActionClass(
    {
        name: 'get',
        route: '{id}',
        rq: request.get,
    },
    {
        name: 'add',
        route: '',
        rq: request.post,
    },
    {
        name: 'update',
        route: '{id}',
        rq: request.put,
    },
    {
        name: 'delete',
        route: '{id}',
        rq: request.delete,
    },
);

export default actions;
