import _ from 'lodash';

export const DEFAULT_STATE = {
    all: [],
    byCampus: [],
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'AdminDepartmentActions.ON_GETALL':
            return { ...state, all: action.result };
        case 'AdminDepartmentActions.ON_GETALLBYCAMPUS':
            return { ...state, byCampus: action.result };
        case 'AdminDepartmentActions.ON_CREATE':
        {
            const newDept = { id: action.result.id, name: action.result.name, isUsed: action.result.isUsed };
            const newDeptsByCampus = _.sortBy(state.byCampus.concat([newDept]), 'name');
            const newAllDepts = _.cloneDeep(state.all);
            const newAllDeptsForCampus = _.find(newAllDepts, (c) => c.churchEntityId === action.result.churchEntityId);

            if (!_.isNil(newAllDeptsForCampus)) {
                newAllDeptsForCampus.departments = _.sortBy(newAllDeptsForCampus.departments.concat([newDept]), 'name');
            }

            return {
                ...state,
                all: newAllDepts,
                byCampus: newDeptsByCampus,
            };
        }
        case 'AdminDepartmentActions.ON_DELETE':
        {
            const newDeptsByCampus = _.filter(state.byCampus, (d) => d.id !== action.params.departmentId);
            const newAllDepts = _.cloneDeep(state.all);
            const newAllDeptsForCampus = _.find(newAllDepts, (c) => c.churchEntityId === action.callbackParams.churchEntityId);

            if (!_.isNil(newAllDeptsForCampus)) {
                newAllDeptsForCampus.departments = _.filter(newAllDeptsForCampus.departments, (d) => d.id !== action.params.departmentId);
            }

            return {
                ...state,
                all: newAllDepts,
                byCampus: newDeptsByCampus,
            };
        }
        case 'AdminDepartmentActions.ON_RESET':
            return DEFAULT_STATE;
    }

    return state;
}
