import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

export const chunkLoader = new ChunkedPaginationUtils(20, 60);

export const DEFAULT_STATE = {
    results: [],
    scheduled: 0,
    unscheduled: 0,
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: chunkLoader.getPageSize(),
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHROSTERVOLUNTEERS':
            chunkLoader.loadPage(
                action.result.volunteers,
                action.result.total,
                action.callbackParams.first,
            );

        // eslint-disable-next-line no-fallthrough
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHROSTERVOLUNTEERS_NEXTPAGE':
            return {
                results: chunkLoader.getAll(true),
                scheduled: action.result?.scheduled ?? state.scheduled,
                unscheduled: action.result?.unscheduled ?? state.unscheduled,
                total: chunkLoader.getTotalCount(),
                canLoadMore: chunkLoader.canLoadMore(),
                needsToRequest: chunkLoader.needsToLoadPage(),
                pageNumber: chunkLoader.getCurrentPageNumber(),
                pageSize: chunkLoader.getPageSize(),
            };

        default:
            return state;
    }
}
