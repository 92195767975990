/**
* This enumeration contains the Action Type string constants for installment payment plan
*/
enum ActionType {
    REMOVE_INSTALLMENT_PAYMENT_PLAN_OPTION_RANGE = 'InstallmentPaymentPlanActions.ON_REMOVE_INSTALLMENT_PAYMENT_PLAN_OPTION_RANGE',
    SET_ADD_NEW_INSTALLMENT_PAYMENT_PLAN_OPTION_RANGE = 'InstallmentPaymentPlanActions.ON_UPDATE_ADD_NEW_INSTALLMENT_PAYMENT_PLAN_OPTION_RANGE',
    SET_INSTALLMENT_PAYMENT_PLAN_LIST = 'InstallmentPaymentPlanActions.ON_UPDATE_INSTALLMENT_PAYMENT_PLAN_LIST',
    SET_INSTALLMENT_PAYMENT_PLAN_OPTION = 'InstallmentPaymentPlanActions.ON_UPDATE_INSTALLMENT_PAYMENT_PLAN_OPTION',
    RESET_DATA = 'InstallmentPaymentPlanActions.ON_RESET',
}

export default ActionType;
