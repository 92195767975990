import _ from 'lodash';

const DEFAULT_STATE = {
    isFetching: false,
    data: [],
    selectedEmergencyContact: {},
    selectedEmergencyContactNew: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'EmergencyContactActions.ON_BEFORE_GET': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'EmergencyContactActions.ON_GET': {
            return {
                ...state,
                isFetching: false,
                data: action.result,
            };
        }

        case 'EmergencyContactActions.ON_RESET_EMERGENCY_CONTACT': {
            return DEFAULT_STATE;
        }

        case 'EmergencyContactActions.ON_RESET_SELECTED_EMERGENCY_CONTACT': {
            return {
                ...state,
                selectedEmergencyContact: {},
            };
        }

        case 'EmergencyContactActions.ON_UPDATE_SELECTED_EMERGENCY_CONTACT': {
            const { value: person } = action;
            return {
                ...state,
                selectedEmergencyContact: { ...person },
            };
        }

        default:
            return state;
    }
};
