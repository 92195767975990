import './emergencyContactForm.scss';

import {
    Button,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { colorAlert } from '../../../global/styles/colors.scss';
import { translationFactory } from '../../../global/i18nUtils.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import { personPropTypes } from './personPropTypes.js';
import AddEmergencyContactFromPersonDrawer from '../../search/global/addEmergencyContactFromPersonDrawer.js';
import BannerUtils from '../../../js/utils/BannerUtils.js';
import EmergencyContactFormActions from './emergencyContactFormActions.js';
import PersonEmergencyContact from '../../emergencyContact/personEmergencyContact.jsx';
import RelationshipActions from '../../emergencyContact/global/relationshipActions.js';
import UserAccessStore from '../../../global/userAccessStore.js';

const i18n = translationFactory();

const propTypes = {
    recordType: PropTypes.oneOf(['adult', 'child', 'student']),
    resetOnUnmount: PropTypes.bool,
    selectedEmergencyContacts: PropTypes.arrayOf(
        personPropTypes,
    ),
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    recordType: 'adult',
    resetOnUnmount: true,
    selectedEmergencyContacts: [],
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        people: {
            record: {
                emergencyContactForm: {
                    selectedEmergencyContacts,
                },
            },
        },
    } = state;

    return {
        selectedEmergencyContacts,
        userAccess,
    };
};

class EmergencyContactForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isEmergencyContactDrawerOpen: false,
        };

        this.onOpenPersonSelectDrawer = this.onOpenPersonSelectDrawer.bind(this);
        this.setPerson = this.setPerson.bind(this);
    }

    componentDidMount() {
        RelationshipActions.getRelation();
    }

    componentWillUnmount() {
        const {
            resetOnUnmount,
        } = this.props;

        if (resetOnUnmount) {
            EmergencyContactFormActions.resetSelectedEmergencyContact();
        }
    }

    onOpenPersonSelectDrawer() {
        this.setState((prevState) => ({
            isEmergencyContactDrawerOpen: !prevState.isEmergencyContactDrawerOpen,
        }));
    }

    setPerson(selectedPerson) {
        const {
            selectedEmergencyContacts,
        } = this.props;
        if (selectedPerson) {
            const isPersonAlreadySelected =
                _.find(selectedEmergencyContacts, (item) => item.id === selectedPerson.id);
            if (!_.isEmpty(isPersonAlreadySelected)) {
                BannerUtils.addBanner({
                    children: (
                        <p style={{ margin: 0 }}>
                            {i18n('Person Already Selected as Emergency Contact')}
                        </p>
                    ),
                    id: 'emergency_contact--linked_person_banner_warning',
                    level: 'warning',
                    timeout: true,
                    type: 'notification',
                });
            } else {
                RelationshipActions
                    .getPersonalDetails({ personId: selectedPerson.id }).then((res) => {
                        EmergencyContactFormActions.addNewSelectedEmergencyContact(res);
                    });
                BannerUtils.addBanner({
                    children: (
                        <p style={{ margin: 0 }}>
                            {i18n('Successfully Linked A Person')}
                        </p>
                    ),
                    id: 'emergency_contact--linked_person_banner_success',
                    level: 'success',
                    timeout: true,
                    type: 'notification',
                });
            }
        }
    }

    render() {
        const {
            isEmergencyContactDrawerOpen,
        } = this.state;

        const {
            recordType,
            selectedEmergencyContacts,
            userAccess,
        } = this.props;

        const BLOCK_CLASSNAME = 'person_record--emergency_contact_form';

        const emergencyConactList = _.map(selectedEmergencyContacts, (person) => (
            <PersonEmergencyContact
                personalData={person}
            />
        ));

        return (
            <React.Fragment>
                <div className={`${BLOCK_CLASSNAME}--content`}>
                    <div className={`${BLOCK_CLASSNAME}_header_title`}>
                        {i18n('Emergency Contact')}
                        {recordType === 'child' && (
                            <span style={{ color: colorAlert }}> *</span>
                        )}
                    </div>
                    <div
                        className={`${BLOCK_CLASSNAME}_header_info`}
                        style={{ marginBottom: !_.isEmpty(selectedEmergencyContacts) ? '22px' : '0' }}
                    >
                        {i18n('Children are required to have at least one emergency contact')}
                    </div>
                    {emergencyConactList}
                    {userAccess.hasPermission(USER_PERMISSIONS.createPersonEmergencyContacts) && (
                        <Button
                            className={`${BLOCK_CLASSNAME}_link_person_btn`}
                            color="success"
                            onClick={this.onOpenPersonSelectDrawer}
                        >
                            <Icon color="inverse" type="link" />
                            <span>{i18n('Link a Person')}</span>
                        </Button>
                    )}
                </div>
                <AddEmergencyContactFromPersonDrawer
                    isOpen={isEmergencyContactDrawerOpen}
                    onClose={this.onOpenPersonSelectDrawer}
                    setPerson={this.setPerson}
                    title={i18n('Emergency Contact')}
                />
            </React.Fragment>
        );
    }
}

EmergencyContactForm.propTypes = propTypes;
EmergencyContactForm.defaultProps = defaultProps;

export default connect(mapStateToProps)(EmergencyContactForm);
