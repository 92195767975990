import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';

class CommonlyAttendedServiceActions extends ApiUtils {
    constructor(baseUrl) {
        super(baseUrl, 'Events');
    }

    reset() {
        appReduxStore.dispatch({
            type: 'CommonlyAttendedServiceActions.ON_RESET',
        });
    }
}

const actions = new CommonlyAttendedServiceActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'get',
        route: 'event/worship-service/person/{personId}/most-commonly-attended',
        rq: request.get,
    },
);

export default actions;
