import {
    isEqual,
    map,
    without,
} from 'lodash';
import {
    SORT_UNCLAIMED_OPTIONS,
} from './constants.js';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

const paginatedTasks = new ChunkedPaginationUtils();

export const DEFAULT_FILTER_STATE = {
    filterStatuses: [],
    sort: SORT_UNCLAIMED_OPTIONS[0],
};

const DEFAULT_STATE = {
    appliedFilters: { ...DEFAULT_FILTER_STATE },
    areFiltersDirty: false,
    canLoadMore: false,
    dirtyFilters: { ...DEFAULT_FILTER_STATE },
    isFetching: true,
    isFiltering: false,
    isTaskListFetching: false,
    lastUpdated: null,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: paginatedTasks.getPageSize(),
    searchQuery: '',
    tasks: [],
    total: 0,
    view: 'list',
    taskList: {},
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, DEFAULT_FILTER_STATE);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

const taskList = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'TaskListActions.ON_BEFORE_GETINPROGRESSTASKLIST':
        case 'TaskListActions.ON_BEFORE_GETCLOSEDTASKLIST':
        case 'TaskListActions.ON_BEFORE_GETUNCLAIMEDTASKLIST':
        case 'TaskListActions.ON_BEFORE_GETESCALATEDTASKLIST':
            return {
                ...state,
                isFetching: true,
            };

        case 'TaskListActions.ON_GETINPROGRESSTASKLIST':
        case 'TaskListActions.ON_GETCLOSEDTASKLIST':
        case 'TaskListActions.ON_GETUNCLAIMEDTASKLIST':
        case 'TaskListActions.ON_GETESCALATEDTASKLIST':
        {
            paginatedTasks.loadPage(
                action.result.results,
                action.result.resultCount,
                action.callbackParams.first,
            );

            const {
                query: searchQuery,
            } = action.params;

            const tasks = paginatedTasks.getAll(true);

            return {
                ...state,
                canLoadMore: paginatedTasks.canLoadMore(),
                isFetching: false,
                lastUpdated: Date.now(),
                needsToRequest: paginatedTasks.needsToLoadPage(),
                pageNumber: paginatedTasks.getCurrentPageNumber(),
                pageSize: paginatedTasks.getPageSize(),
                searchQuery,
                tasks,
                total: paginatedTasks.getTotalCount(),
            };
        }

        case 'TaskListActions.ON_GETINPROGRESSTASKLIST_NEXTPAGE':
        case 'TaskListActions.ON_GETCLOSEDTASKLIST_NEXTPAGE':
        case 'TaskListActions.ON_GETUNCLAIMEDTASKLIST_NEXTPAGE':
        case 'TaskListActions.ON_GETESCALATEDTASKLIST_NEXTPAGE':
        {
            const tasks = paginatedTasks.getAll(true);

            return {
                ...state,
                canLoadMore: paginatedTasks.canLoadMore(),
                isFetching: false,
                lastUpdated: Date.now(),
                needsToRequest: paginatedTasks.needsToLoadPage(),
                pageNumber: paginatedTasks.getCurrentPageNumber(),
                pageSize: paginatedTasks.getPageSize(),
                tasks,
                total: paginatedTasks.getTotalCount(),
            };
        }

        case 'TaskListActions.ON_BEFORE_TASKLIST':
            return {
                ...state,
                isFetching: true,
            };

        case 'TaskListActions.ON_TASKLIST':
            return {
                ...state,
                isTaskListFetching: false,
                taskList: action.result,
            };

        case 'TaskListActions.ON_TASKLISTVIEW':
            return {
                ...state,
                view: action.view,
            };

        case 'TaskListActions.ON_RESETINPROGRESSTASKLIST':
            return {
                ...state,
                canLoadMore: DEFAULT_STATE.canLoadMore,
                isFetching: false,
                lastUpdated: DEFAULT_STATE.lastUpdated,
                needsToRequest: DEFAULT_STATE.needsToRequest,
                pageNumber: DEFAULT_STATE.pageNumber,
                pageSize: DEFAULT_STATE.pageSize,
                searchQuery: DEFAULT_STATE.searchQuery,
                tasks: DEFAULT_STATE.tasks,
                total: DEFAULT_STATE.total,
            };

        case 'TaskListActions.ON_RESETTASKLIST':
            return {
                ...state,
                appliedFilters: DEFAULT_FILTER_STATE,
                areFiltersDirty: false,
                dirtyFilters: DEFAULT_FILTER_STATE,
                isFiltering: false,
                isTaskListFetching: DEFAULT_STATE.isTaskListFetching,
                taskList: DEFAULT_STATE.taskList,
            };

        case 'TaskListActions.ON_SELECT_TASKS':
        {
            const { count } = action;
            let numCheckedTasks = 0;

            const updatedTasks = map(state.tasks, (item) => {
                if (count) {
                    if (numCheckedTasks < count) {
                        ++numCheckedTasks; // eslint-disable-line no-plusplus

                        return {
                            ...item,
                            checked: true,
                        };
                    }

                    // else if it was closed or we have exceeded the `count`, make it not checked
                    return {
                        ...item,
                        checked: false,
                    };
                }

                return {
                    ...item,
                    checked: true,
                };
            });

            return {
                ...state,
                tasks: updatedTasks,
            };
        }

        case 'TaskListActions.ON_CLEAR_SELECTED_TASKS':
        {
            const uncheckedTasks = map(
                state.tasks,
                (task) => ({
                    ...task,
                    checked: false,
                }),
            );

            return {
                ...state,
                tasks: uncheckedTasks,
            };
        }

        case 'TaskListActions.ON_SELECT_TASK':
        {
            const updatedTasks = map(
                state.tasks,
                // eslint-disable-next-line no-confusing-arrow
                (item) => item.id === action.id ? { ...item, checked: true } : item,
            );

            return {
                ...state,
                tasks: updatedTasks,
            };
        }

        case 'TaskListActions.ON_UNSELECT_TASK':
        {
            const updatedTasks = map(
                state.tasks,
                // eslint-disable-next-line no-confusing-arrow
                (item) => item.id === action.id ? { ...item, checked: false } : item,
            );

            return {
                ...state,
                tasks: updatedTasks,
            };
        }

        case 'TaskActions.ON_POSTCOMMENT':
        {
            // Increment comment count for corresponding Task in the Tasks collection in state
            const { taskId: affectedTaskId } = action.result;

            const updatedTasks = map(
                state.tasks,
                // eslint-disable-next-line no-confusing-arrow
                (item) => item.id === affectedTaskId ?
                    { ...item, commentCount: item.commentCount + 1 } :
                    item,
            );

            return {
                ...state,
                tasks: updatedTasks,
            };
        }

        case 'TaskActions.ON_DELETECOMMENT':
        {
            // Decrement comment count for corresponding Task in the Tasks collection in state
            const { taskId: affectedTaskId } = action.result;

            const updatedTasks = map(
                state.tasks,
                // eslint-disable-next-line no-confusing-arrow
                (item) => item.id === affectedTaskId ?
                    { ...item, commentCount: item.commentCount - 1 } :
                    item,
            );

            return {
                ...state,
                tasks: updatedTasks,
            };
        }

        case 'TaskListActions.ON_APPLY_FILTERS': {
            const { dirtyFilters } = state;
            const isFiltering = !isEqual(dirtyFilters, DEFAULT_FILTER_STATE);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'TaskListActions.ON_CLEAR_FILTERS': {
            return updateDirtyFilterState(state, { ...DEFAULT_FILTER_STATE });
        }

        case 'TaskListActions.ON_SET_AND_APPLY_SORT_ORDER': {
            const nextAppliedFilters = { ...state.appliedFilters, sort: action.value };
            return updateAppliedFilterState(state, nextAppliedFilters);
        }

        case 'TaskListActions.ON_SET_AND_APPLY_TASK_STATUS_FILTER_CHANGE': {
            const { taskStatusId, isChecked } = action.value;

            const updatedFilters = {
                ...state.appliedFilters,
                filterStatuses: isChecked ?
                    [...state.appliedFilters.filterStatuses, taskStatusId] :
                    without(
                        state.appliedFilters.filterStatuses,
                        taskStatusId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'TaskListActions.ON_SET_DIRTY_SORT_ORDER': {
            return updateDirtyFilterState(
                state,
                { ...state.dirtyFilters, sort: action.value },
            );
        }

        case 'TaskListActions.ON_SET_DIRTY_TASK_STATUS_FILTER_CHANGE': {
            return updateDirtyFilterState(
                state,
                { ...state.dirtyFilters, filterStatuses: action.value },
            );
        }

        case 'TaskListActions.ON_SET_SEARCH_TERM': {
            return {
                ...state,
                searchQuery: action.value,
            };
        }

        case 'TaskListActions.ON_CLEAR_SEARCH_TERM': {
            return {
                ...state,
                searchQuery: '',
            };
        }

        default:
            return state;
    }
};

export default taskList;
