import React, { useState } from 'react';
import { Grid } from '@saddlebackchurch/react-cm-ui';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import useTheme from '@saddlebackchurch/react-cm-ui/core/styles/useTheme';
import useMediaQuery from '@saddlebackchurch/react-cm-ui/core/utils/useMediaQuery';
import {
    find,
    isArray,
    isNil,
    map,
} from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GROUPS_PROP_TYPES } from './constants';
import ImageUploaderActions from './imageUploaderActions.jsx';
import ImageUploaderGroups from './imageUploaderGroups.jsx';
import ImageUploaderGroupsMobile from './imageUploaderGroupsMobile.jsx';

const propTypes = {
    allOptions: PropTypes.bool,
    disabled: PropTypes.bool,
    groups: GROUPS_PROP_TYPES.isRequired,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    readOnly: PropTypes.bool,
    singleImage: PropTypes.bool,
};

const defaultProps = {
    allOptions: true,
    disabled: false,
    onClear: undefined,
    readOnly: false,
    singleImage: false,
};

const mapStateToProps = (state) => {
    const {
        global: {
            imageUploader,
        },
    } = state;

    return {
        imageUploader,
    };
};

const BLOCK_CLASS_NAME = 'image_uploader';

const styles = {
    actionsRoot: {},
    root: {
        [`& .${BLOCK_CLASS_NAME}--input_file_button_wrapper`]: {
            marginRight: '11px',
            padding: '8px 22px !important',
        },
        [`& .${BLOCK_CLASS_NAME}--avatar_image canvas`]: {
            borderRadius: '1px !important',
        },
    },
};

function ImageUploader(props) {
    const {
        allOptions,
        disabled,
        groups,
        onClear,
        onChange: onChangeProp,
        readOnly,
        singleImage,
    } = props;

    const [allGroups, setAllGroups] = useState(undefined);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('sm'));

    const onChange = (groupsForAll, index) => {
        if (isNil(groupsForAll)) {
            onChangeProp(groups, null, index);
        } else if (Object.keys(groupsForAll).length === 5) {
            if (allGroups === undefined) {
                setAllGroups([...groups]);
            }

            const updatedProps =
                groups.map((element) => (
                    element.imageContainer.id === groupsForAll.imageContainer.id ? {
                        ...element,
                        imageContainer:
                        {
                            ...element.imageContainer,
                            src: groupsForAll.imageContainer.src,
                            fileName: groupsForAll.imageContainer.fileName,
                        },
                    } : element));

            setAllGroups([...updatedProps]);
            onChangeProp(updatedProps, null, index);
        } else {
            const newGroups = map(groups, (group) => {
                if (!isArray(groupsForAll)) {
                    return group.imageContainer.id === groupsForAll.imageContainer.id ?
                        groupsForAll :
                        group;
                }

                return find(groupsForAll, (newGroup) => (
                    group.imageContainer.id === newGroup.imageContainer.id
                ));
            });

            if (!singleImage) {
                setAllGroups([...newGroups]);
            }

            onChangeProp(newGroups, groupsForAll?.id);
        }
    };

    const renderGroups = () => (
        <React.Fragment>
            {isMobile ? (
                <ImageUploaderGroupsMobile
                    allOptions={allOptions}
                    disabled={disabled}
                    groups={groups}
                    onChange={onChange}
                    onClear={onClear}
                    readOnly={readOnly}
                />
            ) : (
                <ImageUploaderGroups
                    allOptions={allOptions}
                    disabled={disabled}
                    groups={groups}
                    onChange={onChange}
                    onClear={onClear}
                    readOnly={readOnly}
                />
            )}
        </React.Fragment>
    );

    return (
        <Grid>
            <Grid.Column
                sm={12}
            >
                <div>
                    {renderGroups()}
                </div>
            </Grid.Column>

            {allOptions && !readOnly ? (
                <ImageUploaderActions
                    groups={groups}
                    onChange={onChange}
                />
            ) : null}
        </Grid>
    );
}

ImageUploader.propTypes = propTypes;
ImageUploader.defaultProps = defaultProps;

export default withStyles(styles)(connect(mapStateToProps)(ImageUploader));
