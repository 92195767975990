import {
    Button,
    Drawer,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import {
    find,
    isEmpty,
    map,
    noop,
} from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import {
    withStyles,
} from '@saddlebackchurch/react-cm-ui/core/styles'; // eslint-disable-line import/extensions
import { i18n } from '../../../global/constants.js';
import LoadMoreTrigger from '../../../js/components/loadMoreTrigger.jsx';
import { coreMilestonesPropTypes } from './coreMilestonesPropTypes.js';
// eslint-disable-next-line import/no-named-as-default
import PossibleDuplicatesAddPersonActions from './possibleDuplicatesAddPersonActions.js';
import PersonPanelAccordionItem from '../../global/personPanelAccordionItem.jsx';
import BannerUtils from '../../../js/utils/BannerUtils.js';
import { POSSSIBLE_DUPLICATES_BANNER_ID } from './addPersonV2Constants.js';

const propTypes = {
    activeLoadMore: PropTypes.bool.isRequired,
    canLoadMore: PropTypes.bool.isRequired,
    coreMilestonesDataByPersonIds: PropTypes.arrayOf(
        coreMilestonesPropTypes,
    ),
    isMobile: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    needsToRequest: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onSetActiveLoadMore: PropTypes.func.isRequired,
    possibleDuplicatesList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    isSelectRecordVisible: PropTypes.bool,
    onSelect: PropTypes.func,
    validationOptions: PropTypes.shape({
        exclusionsIds: PropTypes.arrayOf(PropTypes.number),
        exclusionsSelectButtonCustomLabel: PropTypes.string,
    }),
};

const defaultProps = {
    coreMilestonesDataByPersonIds: [],
    isOpen: false,
    isSelectRecordVisible: false,
    possibleDuplicatesList: [],
    onSelect: noop,
    validationOptions: {
        exclusionsIds: [],
        exclusionsSelectButtonCustomLabel: null,
    },
};

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            isMobile,
        },
        people: {
            record: {
                coreMilestones: {
                    dataByPersonIds: coreMilestonesDataByPersonIds,
                },
                possibleDuplicatesAddPerson: {
                    data: possibleDuplicatesList,
                    isFetching: isFetchingPossibleDuplicates,
                    canLoadMore,
                    needsToRequest,
                    pageNumber,
                    pageSize,
                },
            },
        },
    } = state;

    return {
        canLoadMore,
        coreMilestonesDataByPersonIds,
        isFetchingPossibleDuplicates,
        isMobile,
        needsToRequest,
        pageNumber,
        pageSize,
        possibleDuplicatesList,
    };
};

const styles = {
    actionBtns: {
        marginTop: '11px',
    },
    coreMilestonesWrapper: {
        border: '1px solid #EDF1F5',
        display: 'flex',
        flexWrap: 'wrap',
        height: '52px',
        margin: '0 -22px 11px',
    },
    coreMilestones: {
        display: 'flex',
        margin: '0 22px',
    },
};

class PossibleDuplicatesAddPerson extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onLoadMoreChange = this.onLoadMoreChange.bind(this);
        this.onViewRecord = this.onViewRecord.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            isOpen: prevIsOpen,
        } = prevProps;

        const {
            isOpen,
        } = this.props;

        const justOpened = !prevIsOpen && isOpen;

        if (justOpened) {
            BannerUtils.closeBanner(POSSSIBLE_DUPLICATES_BANNER_ID);
        }
    }

    onLoadMoreChange(isVisible) {
        if (isVisible) {
            const {
                canLoadMore,
                onNextPage,
                onSetActiveLoadMore,
                needsToRequest,
            } = this.props;

            onSetActiveLoadMore(false).then(() => {
                if (needsToRequest) {
                    onNextPage();
                } else if (canLoadMore) {
                    PossibleDuplicatesAddPersonActions.nextPage();
                    onSetActiveLoadMore(true);
                }
            });
        }
    }

    onViewRecord(personId) {
        const {
            router,
        } = this.props;
        router.push(`/people/search-or-add/record/${personId}`);
    }

    render() {
        const {
            activeLoadMore,
            canLoadMore,
            coreMilestonesDataByPersonIds,
            isMobile,
            isOpen,
            isSelectRecordVisible,
            possibleDuplicatesList,
            onClose,
            onSelect,
            validationOptions: {
                exclusionsIds,
                exclusionsSelectButtonCustomLabel,
            },
        } = this.props;

        const blockClassName = 'possible_duplicates_drawer';

        const personAccordionItemList = map(possibleDuplicatesList, (person) => {
            let coreMilestonesData;

            if (!isEmpty(coreMilestonesDataByPersonIds)) {
                coreMilestonesData = find(
                    coreMilestonesDataByPersonIds, (
                        coremilestone,
                    ) => person.id === coremilestone.uniquePersonID,
                );
            }

            const exists = exclusionsIds.indexOf(person.id) > -1;
            const selectRecordButtonLabel = exists ? exclusionsSelectButtonCustomLabel : null;

            return (
                <PersonPanelAccordionItem
                    coreMilestonesData={coreMilestonesData}
                    onSelect={onSelect}
                    person={{
                        ...person,
                        profilePhotoUrl: person.photoUrl,
                        profilePictureUrl: person.avatarUrl,
                    }}
                    isSelectRecordVisible={isSelectRecordVisible}
                    isViewRecordVisible
                    isMatchPercentageVisible
                    selectRecordButtonDisable={exists}
                    selectRecordButtonLabel={selectRecordButtonLabel}
                />
            );
        });

        return (
            <Drawer
                className={blockClassName}
                isOpen={isOpen}
                onClose={onClose}
            >
                <Drawer.TitleBar
                    className={`${blockClassName}--title_bar`}
                    closeButton={(
                        <Button
                            className={`${blockClassName}--close`}
                            color="alternate"
                            icon
                            onClick={onClose}
                        >
                            <Icon
                                compact
                                type="times"
                            />
                        </Button>
                    )}
                    title={i18n('Possible Duplicates')}
                />

                <Drawer.Content>
                    <React.Fragment>
                        {personAccordionItemList}
                        {
                            canLoadMore ? (
                                <div
                                    className="margin-top text-center"
                                    style={{ marginBottom: !isMobile ? '88px' : '0' }}
                                >
                                    <LoadMoreTrigger
                                        active={activeLoadMore}
                                        onChange={this.onLoadMoreChange}
                                    />
                                </div>
                            ) : null
                        }
                    </React.Fragment>
                </Drawer.Content>
            </Drawer>
        );
    }
}

PossibleDuplicatesAddPerson.propTypes = propTypes;
PossibleDuplicatesAddPerson.defaultProps = defaultProps;

export default withRouter(
    withStyles(styles)(connect(mapStateToProps)(PossibleDuplicatesAddPerson)),
);
