const DEFAULT_STATE = {
    isFetching: false,
    data: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PersonNotesActions.ON_BEFORE_GETCATEGORIES': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'PersonNotesActions.ON_GETCATEGORIES': {
            return {
                ...state,
                isFetching: false,
                data: action.result,
            };
        }

        case 'PersonNotesActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
