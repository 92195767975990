import PropTypes from 'prop-types';
import React from 'react';
import Classnames from 'classnames';
import LogoEnvDemoSVG from '../images/logo-env_demo.svg';
import LogoEnvDevelopmentSVG from '../images/logo-env_development.svg';
import LogoEnvFeatureSVG from '../images/logo-env_feature.svg';
import LogoEnvLocalSVG from '../images/logo-env_local.svg';
import LogoEnvProductionSVG from '../images/logo-env_production.svg';
import LogoEnvProductionCollapsedSVG from '../images/logo-env_production_collapsed.svg';
import LogoEnvQaSVG from '../images/logo-env_qa.svg';
import LogoEnvStageSVG from '../images/logo-env_stage.svg';
import LogoEnvStage2SVG from '../images/logo-env_stage2.svg';
import { i18n } from '../global/constants.js';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    isNavigationCollapsed: PropTypes.bool,
};

const defaultProps = {
    isMobile: undefined,
    isNavigationCollapsed: undefined,
};

function Logo(props) {
    const {
        onClick,
        isMobile,
        isNavigationCollapsed,
    } = props;

    const onKeyDown = () => {
        // TODO: Add an onKeyDown func in the future.
    };

    const {
        location,
    } = window;

    let logoSvgSrc;
    let logoSvgHeight = '44px';
    let logoSvgWidth = '44px';
    let isProduction = false;

    const shouldShowCollapsedStateLogo = isMobile || isNavigationCollapsed;

    switch (location.hostname) {
        case 'hc-demo.saddleback.com':
            logoSvgSrc = LogoEnvDemoSVG;

            break;
        case 'hc-dev.saddleback.com':
            logoSvgSrc = LogoEnvDevelopmentSVG;

            break;
        case 'hc-feature.saddleback.com':
            logoSvgSrc = LogoEnvFeatureSVG;

            break;
        case 'hc-qa.saddleback.com':
            logoSvgSrc = LogoEnvQaSVG;

            break;
        case 'hc-stage.saddleback.com':
            logoSvgSrc = LogoEnvStageSVG;

            break;
        case 'hc-stage2.saddleback.com':
            logoSvgSrc = LogoEnvStage2SVG;

            break;
        case 'localhost':
            logoSvgSrc = LogoEnvLocalSVG;

            break;
        case 'hc.saddleabck.com':
        default:
            isProduction = true;
            logoSvgSrc = shouldShowCollapsedStateLogo ?
                LogoEnvProductionCollapsedSVG :
                LogoEnvProductionSVG;
            logoSvgHeight = '33px';
            logoSvgWidth = '137px';

            break;
    }

    const logoImageClassNames = Classnames(
        'navigation--logo-image',
        { 'logo-env-prod': isProduction },
    );

    return (
        <div
            className="navigation-logo"
            onClick={onClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={-1}
        >
            <img
                className={logoImageClassNames}
                alt={i18n('Healthy Church')}
                height={logoSvgHeight}
                src={logoSvgSrc}
                width={logoSvgWidth}
            />
        </div>
    );
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
