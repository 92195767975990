import {
    isEmpty,
    isNumber,
} from 'lodash';
import Utils from '../../../../../global/utils/utils.js';

const DEFAULT_STATE = {
    adultPerson: {
        isAdult: true,
        phones: [{
            isPrimary: true,
        }],
    },
    childPerson: {},
    hasConsentedToSms: false,
    isSaveDisabled: true,
    isSaving: false,
    relationType: null,
};

const validateForm = ({ adultPerson, childPerson }) => {
    const isChildValid = isNumber(childPerson.churchEntityId) &&
        !Utils.isStringNullOrWhiteSpace(childPerson.firstName) &&
        !Utils.isStringNullOrWhiteSpace(childPerson.lastName) &&
        !isEmpty(childPerson.gradeLevel) &&
        !isEmpty(childPerson.birthDate);

    const isAdultValid = adultPerson.phones[0].isValidPhoneNumber &&
        !Utils.isStringNullOrWhiteSpace(adultPerson.firstName) &&
        !Utils.isStringNullOrWhiteSpace(adultPerson.lastName);

    return isChildValid && isAdultValid;
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'SearchPersonDrawerPersonNewChildPersonActions.ON_RESET':
            return DEFAULT_STATE;

        case 'SearchPersonDrawerPersonNewChildPersonActions.ON_DISABLE_SAVE': {
            return {
                ...state,
                isSaveDisabled: action.isSaveDisabled,
            };
        }

        case 'SearchPersonDrawerPersonNewChildPersonActions.ON_UPDATE_FORM': {
            const newState = {
                ...state,
                ...action.form,
            };

            newState.isSaveDisabled = !validateForm(newState);

            return newState;
        }

        case 'SearchPersonDrawerPersonNewChildPersonActions.ON_BEFORE_SAVE': {
            return {
                ...state,
                isSaving: true,
            };
        }

        case 'SearchPersonDrawerPersonNewChildPersonActions.ON_FAILED_SAVE':
        case 'SearchPersonDrawerPersonNewChildPersonActions.ON_SAVE': {
            return {
                ...state,
                isSaving: false,
            };
        }

        default:
            return state;
    }
};
