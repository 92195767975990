import filterOptionsReducer from '../../../../../global/filterOptionsReducer.js';

const DEFAULT_FILTER_OPTIONS = {
};

export default function (state, action) {
    return filterOptionsReducer(
        state,
        action.params,
        action.query,
        action.filters,
        DEFAULT_FILTER_OPTIONS,
        action.type === 'SearchPersonDrawerOccurrenceRosterActions.ON_RESET',
        action.type === 'SearchPersonDrawerOccurrenceRosterActions.ON_SEARCH_QUERY',
        action.type === 'SearchPersonDrawerOccurrenceRosterActions.ON_FILTERS',
        action.type === 'SearchPersonDrawerOccurrenceRosterActions.ON_APPLY_FILTERS',
        action.type === 'SearchPersonDrawerOccurrenceRosterActions.ON_CLEAR_FILTERS',
    );
}
