import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';

class EmergencyContactActions extends ApiUtils {
    resetEmergencyContact() {
        appReduxStore.dispatch({ type: 'EmergencyContactActions.ON_RESET_EMERGENCY_CONTACT' });
    }

    resetSelectedEmergencyContact() {
        appReduxStore.dispatch({
            type: 'EmergencyContactActions.ON_RESET_SELECTED_EMERGENCY_CONTACT',
        });
    }

    updateSelectedEmergencyContact(selectedPerson) {
        appReduxStore.dispatch({
            type: 'EmergencyContactActions.ON_UPDATE_SELECTED_EMERGENCY_CONTACT',
            value: selectedPerson,
        });
    }
}

const actions = new EmergencyContactActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'get',
        route: 'person/{personId}/emergency-contact',
        rq: request.get,
    }, {
        name: 'save',
        route: 'person/{personId}/emergency-contact',
        rq: request.post,
    }, {
        name: 'delete',
        route: 'person/{personId}/emergency-contact/delete',
        rq: request.post,
    }, {
        name: 'getExistingRelationship',
        route: 'person/{personId}/relationship',
        rq: request.get,
    }, {
        name: 'getRelation',
        route: 'person/relation',
        rq: request.get,
    },
);

export default actions;
