import { Action } from '../../../global/models';
import ActionType from '../../actionTypes/segment.actionTypes';
import { SegmentCriteriaCount } from '../../models';

export const INITIAL_STATE: SegmentCriteriaCount = {
    additionalEngagementQueryPart: null,
    demographicQueryPart: null,
    eventCountQueryPart: null,
    eventStreakQueryPart: null,
    isFetchingCount: false,
    matchedPersonQueryResult: null,
    milestoneQueryPart: null,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_SEGMENT_CRITERIA_COUNT:
            return action.payload;
        default:
            return state;
    }
};
