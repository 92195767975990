import {
    Grid,
} from '@saddlebackchurch/react-cm-ui';
import {
    find,
    isEmpty,
    map,
    mapValues,
} from 'lodash';
import ClassNames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';
import {
    useComponentDidUpdate,
} from '../lifeCycleHooks';
import {
    ImageGroup,
} from './models';
import ImageUploader from '../imageUploader/imageUploader.jsx';

type PropTypes = {
    className?: string | null;
    defaultImageGroups: ImageGroup[];
    getValues: Function;
    name: string;
    onUpload: Function;
    register: any;
    setValue: Function;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    imageUploaderActions: {
        margin: 0,
    },
    root: {
        paddingBottom: spacing(1.5),
        paddingTop: spacing(1.5),
    },
}));

function FormFieldImageUploader({
    className = null,
    defaultImageGroups,
    getValues,
    name,
    onUpload,
    register,
    setValue,
}: PropTypes) {
    const classes = useStyles();

    const [groups, setGroups] = React.useState<ImageGroup[]>(defaultImageGroups);

    const rootClasses = ClassNames(
        'form--field_image_uploader',
        classes.root,
        className,
    );

    const onImageUploaderChange = async (
        newImageGroups,
    ) => {
        const returnedData = await onUpload(newImageGroups);

        setGroups(returnedData);

        let newControllerFieldValue = {
            ...getValues(name),

        };

        newControllerFieldValue = mapValues(newControllerFieldValue, (oldValue, key) => {
            const newValue = find(returnedData, { id: key }).imageContainer.url;
            return isEmpty(newValue) ? oldValue : newValue;
        });

        setValue(name, newControllerFieldValue, { shouldValidate: true, shouldDirty: true });
    };

    useComponentDidUpdate(() => {
        setGroups(defaultImageGroups);
    }, [
        defaultImageGroups,
    ]);

    return (
        <Grid.Column
            className={rootClasses}
            sm={12}
        >
            <ImageUploader
                classes={{
                    actionsRoot: classes.imageUploaderActions,
                }}
                groups={groups}
                onChange={onImageUploaderChange}
            />

            {map(groups, (group) => (
                <input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register(
                        `${name}.${group.id}`,
                        {
                            required: group.required,
                        },
                    )}
                    hidden
                    key={`${name}_${group.id}`}
                />
            ))}
        </Grid.Column>
    );
}
export default FormFieldImageUploader;
