import {
    isNil,
    isNull,
} from 'lodash';
import {
    isValidEnvironment,
} from '../../global/api/apiHostsDirectory';
import HealthyChurchComponent from './healthyChurchComponent.model';
import ServiceVersionInfo from './serviceVersionInfo.model';

export default class EnvironmentVersionInfo {
    /* eslint-disable @typescript-eslint/lines-between-class-members */
    private _environment: string | null = null;
    private _services: Map<HealthyChurchComponent, ServiceVersionInfo | null> = new Map();
    private _isFetching = false;
    private _attemptedDataFetch = false;
    /* eslint-enable @typescript-eslint/lines-between-class-members */

    /* eslint-disable no-underscore-dangle */
    constructor(environment: string) {
        this._environment = isValidEnvironment(environment) ? environment : null;
    }

    public get attemptedDataFetch() {
        return this._attemptedDataFetch;
    }

    public set attemptedDataFetch(value) {
        this._attemptedDataFetch = !!value;
    }

    public get environment() {
        return this._environment;
    }

    public set environment(environment) {
        if (isNull(environment) || isValidEnvironment(environment)) {
            this._environment = environment;
        }
    }

    public get isFetching() {
        return this._isFetching;
    }

    public set isFetching(value) {
        this._isFetching = value;
    }

    public get services() {
        return this._services;
    }

    public getService(serviceName: HealthyChurchComponent) {
        return this._services.get(serviceName);
    }

    public upsertService(
        serviceName: HealthyChurchComponent,
        serviceVersionInfo: ServiceVersionInfo | null,
    ) {
        if (serviceVersionInfo instanceof ServiceVersionInfo) {
            this._services.set(serviceName, serviceVersionInfo);
        } else if (isNil(serviceVersionInfo)) {
            this._services.set(serviceName, null);
        }
    }
    /* eslint-enable no-underscore-dangle */
}
