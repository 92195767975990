import {
    filter,
    isNil,
} from 'lodash';
import { addOrReplaceBy } from '../../../../global/utils';
import { isAbleToSaveFollowUpTemplate } from './followUpActionUtils.js';

export const updateIndividualRuleFollowUpAction = (existingRule, ruleActionId, objWithUpdates) => ({
    ...existingRule,
    actions: filter(
        addOrReplaceBy(
            existingRule.actions,
            (a) => a.id === ruleActionId,
            (existingAction) => {
                if (!isNil(existingAction)) {
                    const updatedFollowUpActionTemplate = {
                        ...existingAction.actionTemplate,
                        ...objWithUpdates,
                    };

                    const ableToSave = isAbleToSaveFollowUpTemplate(updatedFollowUpActionTemplate);

                    return {
                        ...existingAction,
                        ableToSave,
                        actionTemplate: updatedFollowUpActionTemplate,
                    };
                }

                return null;
            },
        ),
        (a) => !isNil(a),
    ),
});

export const updateRuleFollowUpAction = (existingRule, actionType, actionValue) => {
    switch (actionType) {
        case 'FollowUpActionEditorActions.ON_REMOVE_ASSIGNEE':
        {
            const {
                ruleActionId,
            } = actionValue;

            const updates = {
                defaultAssigneePerson: null,
                defaultAssigneeTeam: null,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_REMOVE_SUPERVISOR':
        {
            const {
                ruleActionId,
            } = actionValue;

            const updates = {
                defaultSupervisor: null,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_ASSIGNEE_TEAM':
        {
            const {
                ruleActionId,
                selectedTeam,
            } = actionValue;

            const updates = {
                defaultAssigneePerson: null,
                defaultAssigneeTeam: selectedTeam,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_ASSIGNEE_USER':
        {
            const {
                ruleActionId,
                selectedUser,
            } = actionValue;

            const updates = {
                defaultAssigneePerson: selectedUser,
                defaultAssigneeTeam: null,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_DUE_DATE_TIME_LIMIT':
        {
            const {
                ruleActionId,
                dueDateTimeLimit,
            } = actionValue;

            const updates = {
                defaultTimeLimit: dueDateTimeLimit,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_FOLLOW_UP_TITLE':
        {
            const {
                ruleActionId,
                title,
            } = actionValue;

            const updates = {
                taskName: title,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_FOLLOW_UP_INSTRUCTIONS':
        {
            const {
                ruleActionId,
                instructions,
            } = actionValue;

            const updates = {
                taskInstructions: instructions,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_SUPERVISOR':
        {
            const {
                ruleActionId,
                selectedUser,
            } = actionValue;

            const updates = {
                defaultSupervisor: selectedUser,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_CATEGORY': {
            const {
                ruleActionId,
                category,
            } = actionValue;

            const updates = {
                taskCategory: category,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_TASK_NOTIFICATION_TEMPLATE_ID': {
            const {
                ruleActionId,
                notification,
            } = actionValue;

            const updates = {
                pushNotificationCommunicationTemplateId: notification,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'FollowUpActionEditorActions.ON_UPDATE_TASK_NOTIFICATION_TYPE': {
            const {
                ruleActionId,
                notificationType,
            } = actionValue;

            const updates = {
                pushNotificationType: notificationType,
            };

            return updateIndividualRuleFollowUpAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        default:
            return existingRule;
    }
};
