import {
    isNil,
    isNull,
    isString,
} from 'lodash';
import HealthyChurchComponent from './healthyChurchComponent.model';

const isValidHealthyChurchComponent =
    (component: string): boolean => HealthyChurchComponent[component] !== undefined;

export default class ServiceVersionInfo {
    /* eslint-disable @typescript-eslint/lines-between-class-members */
    private _name: string | null = null;
    private _url: string | null = null;
    private _version: string | null = null;
    private _branch: string | null = null;
    private _isLoading: boolean = false;
    private _error: string | null = null;
    /* eslint-enable @typescript-eslint/lines-between-class-members */

    /* eslint-disable no-underscore-dangle */
    constructor(
        name?: string,
        url?: string,
        version?: string,
        branch?: string,
        isLoading?: boolean,
    ) {
        this._name = isValidHealthyChurchComponent(name) ? name : null;
        this._url = url ?? null;
        this._version = version ?? null;
        this._branch = branch ?? null;
        this._isLoading = isLoading ?? true;
    }

    public get name() {
        return this._name;
    }

    public set name(name) {
        if (isNull(name) || isValidHealthyChurchComponent(name)) {
            this._name = name;
        }
    }

    public get url() {
        return this._url;
    }

    public set url(url) {
        if (isString(url) || isNull(url)) {
            this._url = url;
        }
    }

    public get version() {
        return this._version;
    }

    public set version(version) {
        if (isString(version) || isNull(version)) {
            this._version = version;
        }
    }

    public get branch() {
        return this._branch;
    }

    public set branch(branch) {
        if (isString(branch) || isNull(branch)) {
            this._branch = branch;
        }
    }

    public get isLoading() {
        return this._isLoading;
    }

    public set isLoading(isLoading) {
        this._isLoading = isLoading;
    }

    public get error() {
        return this._error;
    }

    public set error(error) {
        this._error = error;
    }

    public hasError(): boolean {
        return !isNil(this._error);
    }
    /* eslint-enable no-underscore-dangle */
}
