import _ from 'lodash';
import LoginConstants from '../../constants/Login/LoginConstants.js';
import subVenueCache, { DEFAULT_STATE as SUBVENUE_CACHE_DEFAULT_STATE } from './subVenueCache.js';
import venueCache, { DEFAULT_STATE as VENUE_CACHE_DEFAULT_STATE } from './venueCache.js';

const DEFAULT_STATE = {
    subVenueCache: SUBVENUE_CACHE_DEFAULT_STATE,
    venueCache: VENUE_CACHE_DEFAULT_STATE,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'VenueCacheActions.ON_GET':
        case 'VenueCacheActions.ON_GETALLBYCAMPUS':
        case 'VenueCacheActions.ON_RESET': {
            const newVenueCache = venueCache(state.venueCache, action);
            const newSubVenueCache = subVenueCache(newVenueCache);
            return _.assign({}, state, {
                subVenueCache: newSubVenueCache,
                venueCache: newVenueCache,
            });
        }

        default:
            return state;
    }
}
