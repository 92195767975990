import { keyBy } from 'lodash';

export const DEFAULT_STATE = {
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'LightPersonCacheActions.ON_RESET':
            return DEFAULT_STATE;

        case 'LightPersonCacheActions.ON_GET':
            return { ...state, ...keyBy(action.result, 'id') };

        default:
            return state;
    }
};
