import './personNotesDrawerWing.scss';
import _ from 'lodash';
import { Button, Drawer, Icon } from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
    backgroundColorAlert,
    backgroundColorHighlightDark,
    backgroundColorStatic,
} from '../../../global/styles/colors.scss';
import { translationFactory } from '../../../global/i18nUtils.js';
import {
    noteDefaultProps, notePropTypes, noteFormDefaultProps, noteFormPropTypes,
} from './notePropTypes.js';
import PersonNotesDrawerNoteDetails from './personNotesDrawerNoteDetails.jsx';
import PersonNoteForm from './personNoteForm.jsx';
import PersonNotesAction from './personNotesActions.js';
import UserAccessStore from '../../../global/userAccessStore.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import { NoteReminderFormConnected as NoteReminderForm } from './noteReminders/noteReminderForm';
import NoteReminderDetail from './noteReminders/noteReminderDetail';
import {
    resetReminderForm,
    setHasCreateReminderButtonBeenClicked,
    setMobileNextWingType,
    setReminderElementOpenFrom,
    setSelectedReminder,
} from './noteReminders/noteReminderActions';
import {
    getNoteReminderDueTimeInfo,
} from './noteReminders/noteRemindersUtils';
import {
    OPEN_FROM_NOTE_DETAIL_MOBILE,
    OPEN_FROM_NOTE_FORM_EDIT_MOBILE,
    WING_TYPE_NOTE_REMINDER_DETAIL,
    WING_TYPE_NEW_REMINDER_FORM,
} from './noteReminders/noteReminderConstants';

const i18n = translationFactory('Person.Record.Notes');

const propTypes = {
    addReminderToNote: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    disableNoteSave: PropTypes.bool.isRequired,
    isNotePinDisabled: PropTypes.bool.isRequired,
    loggedInUserPersonId: PropTypes.number.isRequired,
    mobileNextWingType: PropTypes.func.isRequired,
    noteData: notePropTypes,
    noteDefaultCategoryId: PropTypes.number,
    noteFormData: noteFormPropTypes,
    onCloseNotesDrawerForWing: PropTypes.func.isRequired,
    onNoteEditClick: PropTypes.func,
    onNotePinClick: PropTypes.func,
    onNoteRemoveClick: PropTypes.func,
    onNoteSaveClick: PropTypes.func,
    onOpenNoteWing: PropTypes.func.isRequired,
    reminderFormData: PropTypes.shape({}),
    reminderModalOpenFrom: PropTypes.func.isRequired,
    removeReminderFromNote: PropTypes.func.isRequired,
    resetReminderForm: PropTypes.func.isRequired,
    selectedNote: PropTypes.shape({}),
    selectedReminder: PropTypes.shape({}),
    setMobileNextWingType: PropTypes.func.isRequired,
    setHasCreateReminderButtonBeenClicked: PropTypes.func.isRequired,
    setReminderElementOpenFrom: PropTypes.func.isRequired,
    setSelectedReminder: PropTypes.func.isRequired,
    setWingType: PropTypes.func.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
    wingOpenType: PropTypes.oneOf(
        [null, 'details', 'new_note_form', 'new_reminder_form', 'note_reminder_detail'],
    ),
};

const defaultProps = {
    noteData: noteDefaultProps,
    noteDefaultCategoryId: undefined,
    noteFormData: noteFormDefaultProps,
    onNoteEditClick: undefined,
    onNotePinClick: undefined,
    onNoteRemoveClick: undefined,
    onNoteSaveClick: undefined,
    reminderFormData: undefined,
    selectedNote: undefined,
    selectedReminder: undefined,
    wingOpenType: null,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
                userIdentity,
            },
        },
        people: {
            record: {
                noteForm: {
                    formData: noteFormData,
                    mobileNextWingType,
                    reminderFormData,
                    reminderModalOpenFrom,
                    selectedReminder,
                    selectedNote,
                },
            },
        },
    } = state;

    const loggedInUserPersonId = userIdentity.getPersonId();

    return {
        loggedInUserPersonId,
        noteFormData,
        userAccess,
        reminderFormData,
        mobileNextWingType,
        selectedReminder,
        selectedNote,
        reminderModalOpenFrom,
    };
};

class PersonNotesDrawerWing extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onNoteEdit = this.onNoteEdit.bind(this);
        this.onNotePin = this.onNotePin.bind(this);
        this.onNoteRemove = this.onNoteRemove.bind(this);
    }

    static onCancelNoteForm() {
        PersonNotesAction.resetNoteForm();
        PersonNotesAction.setWingType();
        PersonNotesAction.setNoteFormChangeStatus();
    }

    onNoteEdit() {
        const {
            onNoteEditClick,
            noteData,
            setReminderElementOpenFrom: setReminderElementOpenFromAction,
        } = this.props;

        setReminderElementOpenFromAction(OPEN_FROM_NOTE_FORM_EDIT_MOBILE);
        onNoteEditClick(noteData.id);
    }

    onNotePin() {
        const {
            onNotePinClick,
            noteData,
        } = this.props;
        onNotePinClick(noteData.id, noteData.isPinned);
    }

    onNoteRemove() {
        const {
            onNoteRemoveClick,
            noteData,
        } = this.props;
        onNoteRemoveClick(noteData.id);
    }

    render() {
        const {
            addReminderToNote,
            className: blockClassName,
            disableNoteSave,
            isNotePinDisabled,
            loggedInUserPersonId,
            mobileNextWingType,
            noteData,
            noteDefaultCategoryId,
            noteFormData,
            onCloseNotesDrawerForWing,
            onNoteSaveClick,
            onOpenNoteWing,
            reminderFormData,
            reminderModalOpenFrom,
            resetReminderForm: resetReminderFormAction,
            removeReminderFromNote,
            setHasCreateReminderButtonBeenClicked: setHasCreateReminderButtonBeenClickedAction,
            setMobileNextWingType: setMobileNextWingTypeAction,
            selectedNote,
            selectedReminder,
            setReminderElementOpenFrom: setReminderElementOpenFromAction,
            setSelectedReminder: setSelectedReminderAction,
            setWingType,
            userAccess,
            wingOpenType,
        } = this.props;

        const reminderData = reminderFormData || selectedReminder;
        const dueTimeInfo = reminderData && getNoteReminderDueTimeInfo(reminderData);
        const parsedReminderData = {
            ...reminderData,
            ...(dueTimeInfo && {
                remainingDays: dueTimeInfo.remainingDays,
                dueDate: dueTimeInfo.dueDate,
            }),
        };

        let actionsBarColumnsList = [];
        let noteWingDrawerTitle = 'Notes';

        if (wingOpenType !== 'new_note_form') {
            actionsBarColumnsList = [
                {
                    iconBack: {
                        onClick: () => onOpenNoteWing(),
                    },
                    flexGrow: 1,
                    divide: false,
                },
            ];
        }

        if (noteData && wingOpenType === 'details') {
            const {
                categoryName,
                categoryId,
                createdBy,
            } = noteData;

            noteWingDrawerTitle = categoryName;
            const canEditPersonNote = userAccess.hasPermission(
                USER_PERMISSIONS.updatePersonNotes,
            );

            const authorizedEditNoteCategoryIds = userAccess.getAuthzdNotesCategoryIdsByPermission(
                USER_PERMISSIONS.updatePersonNotes,
            );
            const hasEditCategoryNotePermission = _.some(authorizedEditNoteCategoryIds,
                (id) => id === categoryId);

            const canDeletePersonNote = userAccess.hasPermission(
                USER_PERMISSIONS.deletePersonNotes,
            );

            const authorizedDeleteNoteCategoryIds = userAccess
                .getAuthzdNotesCategoryIdsByPermission(
                    USER_PERMISSIONS.deletePersonNotes,
                );

            const hasDeleteCategoryNotePermission = _.some(authorizedDeleteNoteCategoryIds,
                (id) => id === categoryId);

            let actionButtonOptionEdit = [];
            let actionButtonOptionDelete = [];
            let actionButtonReminder = [];

            const hasLoggedUserCreatedNote = loggedInUserPersonId === createdBy;

            if (canEditPersonNote && hasEditCategoryNotePermission && hasLoggedUserCreatedNote) {
                actionButtonOptionEdit = [{
                    iconType: 'pencil',
                    label: i18n('Edit'),
                    onClick: this.onNoteEdit,
                }];
            }

            if (canDeletePersonNote && hasDeleteCategoryNotePermission) {
                actionButtonOptionDelete = [{
                    iconType: 'trash',
                    label: i18n('Delete'),
                    iconBackgroundColor: backgroundColorAlert,
                    onClick: this.onNoteRemove,
                    promptColor: 'alert',
                    promptMessage: i18n('AreYouSure'),
                    requiresPrompt: true,
                }];
            }

            if (hasLoggedUserCreatedNote && !selectedReminder) {
                actionButtonReminder = [{
                    iconType: 'clock-period',
                    iconBackgroundColor: backgroundColorHighlightDark,
                    label: i18n('Reminder'),
                    onClick: () => {
                        setReminderElementOpenFromAction(OPEN_FROM_NOTE_DETAIL_MOBILE);
                        setMobileNextWingTypeAction('details');

                        if (reminderData) {
                            setWingType(WING_TYPE_NOTE_REMINDER_DETAIL);
                        } else {
                            setWingType(WING_TYPE_NEW_REMINDER_FORM);
                        }
                    },
                }];
            }

            const actionOptions = [
                ...actionButtonReminder,
                {
                    iconType: isNotePinDisabled ? 'spinner' : 'pin',
                    iconBackgroundColor: (noteData.isPinned || isNotePinDisabled) ?
                        backgroundColorStatic :
                        backgroundColorHighlightDark,
                    label: noteData.isPinned ? i18n('UnpinNote') : i18n('PinNote'),
                    onClick: this.onNotePin,
                    disabled: isNotePinDisabled,
                },
                ...actionButtonOptionEdit,
                ...actionButtonOptionDelete,
            ];

            const actionButtonsList = {
                actionsButton: {
                    header: 'Actions',
                    options: actionOptions,
                },
                divide: false,
            };
            actionsBarColumnsList = [...actionsBarColumnsList, actionButtonsList];
        }

        if (wingOpenType === 'new_note_form') {
            noteWingDrawerTitle = noteFormData.noteId ? i18n('EditNote') : i18n('NewNote');
            const noteFormActionBarColumns = [
                {
                    jsx: (
                        <Button
                            color="alternate"
                            icon
                            id={`${blockClassName}--cancel_person_note_form`}
                            onClick={() => {
                                if (!noteFormData.noteId) {
                                    setSelectedReminderAction();
                                    resetReminderFormAction();
                                }

                                PersonNotesDrawerWing.onCancelNoteForm();
                            }}
                        >
                            <Icon
                                type="times"
                            />
                        </Button>
                    ),
                    divide: false,
                }, {
                    jsx: (
                        <Button
                            color="success"
                            disable={disableNoteSave}
                            icon
                            id={`${blockClassName}--save_person_note_form`}
                            onClick={onNoteSaveClick}
                        >
                            <Icon
                                type="check"
                            />
                        </Button>
                    ),
                    divide: false,
                },
            ];
            actionsBarColumnsList = [...actionsBarColumnsList, ...noteFormActionBarColumns];
        }

        if (wingOpenType === WING_TYPE_NEW_REMINDER_FORM) {
            noteWingDrawerTitle = i18n('New Reminder');
            const dateTypeOption = _.get(reminderFormData, 'dateTypeOption');
            const customDate = _.get(reminderFormData, 'customDate');
            const dateOption = _.get(dateTypeOption, 'label', '').toLowerCase();
            const shouldDisableAddReminderButton = !dateOption || (dateOption === 'custom' && !customDate);
            const noteFormActionBarColumns = [
                {
                    jsx: (
                        <Button
                            color="alternate"
                            icon
                            id={`${blockClassName}--cancel_person_note_form`}
                            onClick={() => {
                                setHasCreateReminderButtonBeenClickedAction(false);
                                resetReminderFormAction();

                                if (mobileNextWingType) {
                                    setWingType(mobileNextWingType);
                                } else {
                                    setWingType('new_note_form');
                                }
                            }}
                        >
                            <Icon
                                title={false}
                                type="times"
                            />
                        </Button>
                    ),
                    divide: false,
                }, {
                    jsx: (
                        <Button
                            color="success"
                            icon
                            id={`${blockClassName}--save_person_note_form`}
                            onClick={() => {
                                if (reminderModalOpenFrom === OPEN_FROM_NOTE_DETAIL_MOBILE) {
                                    addReminderToNote(selectedNote, reminderFormData, true, 'update');
                                } else {
                                    setHasCreateReminderButtonBeenClickedAction(true);
                                }

                                if (mobileNextWingType) {
                                    setWingType(mobileNextWingType);
                                } else {
                                    setWingType('new_note_form');
                                }
                            }}
                            disable={shouldDisableAddReminderButton}
                        >
                            <Icon
                                title={false}
                                type="add"
                            />
                        </Button>
                    ),
                    divide: false,
                },
            ];
            actionsBarColumnsList = [...actionsBarColumnsList, ...noteFormActionBarColumns];
        }

        if (wingOpenType === WING_TYPE_NOTE_REMINDER_DETAIL) {
            noteWingDrawerTitle = i18n('My Reminder');
            actionsBarColumnsList = [
                ...actionsBarColumnsList,
                {
                    actionsButton: {
                        header: i18n('Actions'),
                        options: [
                            {
                                iconType: 'times-circle',
                                iconBackgroundColor: '#f99e49',
                                label: i18n('Cancel Reminder'),
                                onClick: () => {
                                    if (reminderModalOpenFrom === OPEN_FROM_NOTE_DETAIL_MOBILE) {
                                        removeReminderFromNote(selectedNote, selectedReminder);
                                    } else { // it means that comes from 'new note form'
                                        resetReminderFormAction();
                                    }

                                    if (mobileNextWingType) {
                                        setWingType(mobileNextWingType);
                                    }
                                },
                                promptColor: 'warning',
                                promptMessage: i18n('AreYouSure'),
                                requiresPrompt: true,
                            },
                        ],
                    },
                },
            ];
        }

        const actionsBarColumns = [
            {
                flexGrow: 1,
                list: actionsBarColumnsList,
            },
        ];

        return (
            <React.Fragment>
                <Drawer.TitleBar
                    className={`${blockClassName}--title_bar`}
                    closeButton={(
                        <Icon
                            align="right"
                            className={`${blockClassName}--close`}
                            id={`${blockClassName}--close`}
                            type="contract"
                            onClick={onCloseNotesDrawerForWing}
                        />
                    )}
                    title={noteWingDrawerTitle}
                />
                <Drawer.ActionBar
                    className={`${blockClassName}--mobile_action_bar`}
                    columns={actionsBarColumns}
                />
                <Drawer.Content
                    className={`${blockClassName}--content`}
                >

                    {noteData && wingOpenType && wingOpenType === 'details' && (
                        <PersonNotesDrawerNoteDetails
                            data={noteData}
                        />
                    )}

                    {wingOpenType && wingOpenType === 'new_note_form' && (
                        <PersonNoteForm
                            isMobile
                            setWingType={setWingType}
                            noteDefaultCategoryId={noteDefaultCategoryId}
                        />
                    )}

                    {wingOpenType && wingOpenType === 'new_reminder_form' && (
                        <NoteReminderForm
                            isMobile
                        />
                    )}
                    {wingOpenType && wingOpenType === 'note_reminder_detail' && (
                        <NoteReminderDetail
                            isMobile
                            dueDate={parsedReminderData.dueDate}
                        />
                    )}

                </Drawer.Content>
            </React.Fragment>
        );
    }
}

PersonNotesDrawerWing.propTypes = propTypes;
PersonNotesDrawerWing.defaultProps = defaultProps;

const mapDispatchToProps = {
    setHasCreateReminderButtonBeenClicked,
    resetReminderForm,
    setMobileNextWingType,
    setReminderElementOpenFrom,
    setSelectedReminder,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonNotesDrawerWing);
