import {
    cloneDeep,
} from 'lodash';

export const DEFAULT_STATE = {};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventCacheActions.ON_RESET':
            if (action.id) {
                const newState = cloneDeep(state);
                delete newState[action.id];
                return newState;
            }
            return DEFAULT_STATE;

        case 'EventCacheActions.ON_BEFORE_GET':
            return {
                ...state,
                [action.params.id]: null,
            };

        case 'EventCacheActions.ON_GET':
            return {
                ...state,
                [action.params.id]: action.result,
            };

        default:
            return state;
    }
}
