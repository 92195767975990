const DEFAULT_STATE = {
    churchEntities: [],
    isFetchingChurchEntities: false,
    recordType: '',
    data: {
        firstName: '',
        lastName: '',
        birthDate: null,
        gender: null,
        grade: null,
        campus: null,
    },
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PersonalFormActions.ON_BEFORE_GETCHURCHENTITY': {
            return {
                ...state,
                isFetchingChurchEntities: true,
            };
        }

        case 'PersonalFormActions.ON_GETCHURCHENTITY': {
            return {
                ...state,
                isFetchingChurchEntities: false,
                churchEntities: action.result,
            };
        }

        case 'PersonalFormActions.ON_UPDATE_BIRTHDATE': {
            const { value: birthDate } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    birthDate,
                },
            };
        }

        case 'PersonalFormActions.ON_UPDATE_FIRSTNAME': {
            const { value: firstName } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    firstName,
                },
            };
        }

        case 'PersonalFormActions.ON_UPDATE_LASTNAME': {
            const { value: lastName } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    lastName,
                },
            };
        }

        case 'PersonalFormActions.ON_UPDATE_GENDER': {
            const { value: gender } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    gender,
                },
            };
        }

        case 'PersonalFormActions.ON_UPDATE_GRADE': {
            const { value: grade } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    grade,
                },
            };
        }

        case 'PersonalFormActions.ON_UPDATE_PERSONAL_DATA': {
            const { value: personalData } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    ...personalData,
                },
            };
        }

        case 'PersonalFormActions.ON_UPDATE_CAMPUS': {
            const { value: campus } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    campus,
                },
            };
        }

        case 'PersonalFormActions.ON_UPDATE_RECORD_TYPE': {
            const { value: recordType } = action;
            return {
                ...state,
                recordType,
            };
        }

        case 'PersonalFormActions.ON_RESET_DATA': {
            return {
                ...state,
                data: DEFAULT_STATE.data,
            };
        }

        default:
            return state;
    }
};
