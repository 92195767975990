import {
    i18n,
} from '../../../global/constants.js';
import {
    QuizQuestionTypeName,
} from '../models';

export const BEM_BLOCK_ADD_DRAWER = 'quiz_add_drawer';
export const BEM_BLOCK_NAME = 'quiz_list_page';
export const BEM_BLOCK_NAME_QUIZ_QUESTION = 'quiz_question';
export const BEM_BLOCK_NAME_QUIZ_SECTION = 'quiz_section';

export const ANSWER_CHOICE_LABEL_MAX_LENGTH = 120;

export const QUESTION_TYPES = [
    {
        icon: 'element-button',
        label: i18n('Multiple Choice'),
        value: QuizQuestionTypeName.MultipleChoice,
    },
    {
        icon: 'element-checkbox',
        label: i18n('Checkboxes'),
        value: QuizQuestionTypeName.Checkboxes,
    },
    {
        icon: 'element-boolean',
        label: i18n('True or False'),
        value: QuizQuestionTypeName.TrueOrFalse,
    },
];

export const DEFAULT_TRUE_OR_FALSE_ANSWER = [
    {
        id: 1,
        label: 'True',
        sortOrder: 0,
    },
    {
        id: 2,
        label: 'False',
        sortOrder: 1,
    },
];

export const DEFAULT_MULTIPLE_CHOICE_ANSWER = [
    {
        id: 1,
        label: 'Option 1',
        sortOrder: 0,
    },
    {
        id: 2,
        label: 'Option 2',
        sortOrder: 1,
    },
    {
        id: 3,
        label: 'Option 3',
        sortOrder: 2,
    },
];

export const DEFAULT_CHECK_BOX_ANSWER = [
    {
        id: 1,
        label: 'Checkbox 1',
        sortOrder: 0,
    },
    {
        id: 2,
        label: 'Checkbox 2',
        sortOrder: 1,
    },
    {
        id: 3,
        label: 'Checkbox 3',
        sortOrder: 2,
    }, {
        id: 4,
        label: 'Checkbox 4',
        sortOrder: 3,
    },
];

export const DEFAULT_QUESTION = {
    choices: DEFAULT_TRUE_OR_FALSE_ANSWER,
    correctAnswerFeedback: '',
    correctChoices: [],
    incorrectAnswerFeedback: '',
    sortOrder: 0,
    tempChoices: [],
    tempCorrectAnswerFeedback: '',
    tempCorrectChoices: [],
    tempIncorrectAnswerFeedback: '',
    title: '',
    type: QuizQuestionTypeName.TrueOrFalse,
};

export const DEFAULT_QUIZ_IDS = {
    title: '',
    questions: [DEFAULT_QUESTION],
    connectionFormTemplateId: 0,
    connectionFormTemplateContainerId: 0,
    connectionFormTemplateSectionId: 0,
    id: 0,
    name: '',
    sectionName: '',
};

export const CK_EDITOR_CONTROLS = [
    'bold',
    'italic',
    'underline',
];

export const QUIZ_FILTER_QUERY = 'query&stepType=TakeAQuiz&isPublished=true&entityIds=';
