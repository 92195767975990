import {
    isEqual,
    map,
    without,
} from 'lodash';
import { SORT_OPTIONS } from './constants.js';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

// exported for unit testing
export const pagedTasksCache = new ChunkedPaginationUtils();

// exported for unit testing
export const DEFAULT_FILTER_STATE = {
    filterDueDateType: ['PastDue', 'DueToday', 'Upcoming'],
    filterStatuses: ['Open', 'Blocked'],
    sort: SORT_OPTIONS[0],
};

// exported for unit testing
export const DEFAULT_STATE = {
    appliedFilters: { ...DEFAULT_FILTER_STATE },
    areFiltersDirty: false,
    canLoadMore: false,
    dirtyFilters: { ...DEFAULT_FILTER_STATE },
    dates: {},
    isFetching: false,
    isFiltering: false,
    isOverviewStatsFetching: true,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedTasksCache.getPageSize(),
    searchQuery: '',
    tasks: [],
    total: 0,
    view: 'list',
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, DEFAULT_FILTER_STATE);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

const overviewTaskList = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'FollowUpOverviewActions.ON_BEFORE_GETOVERVIEWSTATSBYASSIGNEE':
        case 'FollowUpOverviewActions.ON_BEFORE_GETOVERVIEWUNCLAIMEDSTATS':
        case 'FollowUpOverviewActions.ON_BEFORE_GETALLFOLLOWUPSOVERVIEWSTATSBYASSIGNEE':
        case 'FollowUpOverviewActions.ON_BEFORE_GETALLFOLLOWUPSOVERVIEWUNCLAIMEDSTATS':
            return {
                ...state,
                isOverviewStatsFetching: true,
            };

        case 'FollowUpOverviewActions.ON_GETOVERVIEWSTATSBYASSIGNEE':
        case 'FollowUpOverviewActions.ON_GETOVERVIEWUNCLAIMEDSTATS':
        case 'FollowUpOverviewActions.ON_GETALLFOLLOWUPSOVERVIEWSTATSBYASSIGNEE':
        case 'FollowUpOverviewActions.ON_GETALLFOLLOWUPSOVERVIEWUNCLAIMEDSTATS':
            return {
                ...state,
                dates: action.result.dates,
                isOverviewStatsFetching: false,
                workflow: action.result.statistics,
            };

        case 'FollowUpOverviewActions.ON_BEFORE_GETOVERVIEWTASKLISTBYASSIGNEE':
        case 'FollowUpOverviewActions.ON_BEFORE_GETOVERVIEWUNCLAIMEDTASKLIST':
        case 'FollowUpOverviewActions.ON_BEFORE_GETALLFOLLOWUPSOVERVIEWTASKLISTBYASSIGNEE':
        case 'FollowUpOverviewActions.ON_BEFORE_GETALLFOLLOWUPSOVERVIEWUNCLAIMEDTASKLIST':
            return {
                ...state,
                isFetching: true,
            };

        case 'FollowUpOverviewActions.ON_GETOVERVIEWTASKLISTBYASSIGNEE':
        case 'FollowUpOverviewActions.ON_GETOVERVIEWUNCLAIMEDTASKLIST':
        case 'FollowUpOverviewActions.ON_GETALLFOLLOWUPSOVERVIEWTASKLISTBYASSIGNEE':
        case 'FollowUpOverviewActions.ON_GETALLFOLLOWUPSOVERVIEWUNCLAIMEDTASKLIST':
        {
            pagedTasksCache.loadPage(
                action.result.results,
                action.result.resultCount,
                action.callbackParams.first,
            );

            const tasks = pagedTasksCache.getAll(true);

            return {
                ...state,
                canLoadMore: pagedTasksCache.canLoadMore(),
                isFetching: false,
                lastUpdated: Date.now(),
                needsToRequest: pagedTasksCache.needsToLoadPage(),
                pageNumber: pagedTasksCache.getCurrentPageNumber(),
                pageSize: pagedTasksCache.getPageSize(),
                tasks,
                total: pagedTasksCache.getTotalCount(),
            };
        }

        case 'FollowUpOverviewActions.ON_TASKLIST_NEXTPAGE':
        {
            const tasks = pagedTasksCache.getAll(true);

            return {
                ...state,
                canLoadMore: pagedTasksCache.canLoadMore(),
                isFetching: false,
                lastUpdated: Date.now(),
                needsToRequest: pagedTasksCache.needsToLoadPage(),
                pageNumber: pagedTasksCache.getCurrentPageNumber(),
                pageSize: pagedTasksCache.getPageSize(),
                tasks,
                total: pagedTasksCache.getTotalCount(),
            };
        }

        case 'FollowUpOverviewActions.ON_SELECT_TASKS':
        {
            const { count } = action;
            let numCheckedTasks = 0;

            const updatedTasks = map(state.tasks, (item) => {
                const updatedItem = { ...item };
                const isTaskClosed =
                    item.status === 'Successful' ||
                    item.status === 'Unsuccessful';

                if (count) {
                    if (!isTaskClosed && numCheckedTasks < count) {
                        updatedItem.checked = true;
                        ++numCheckedTasks; // eslint-disable-line no-plusplus
                    } else {
                        updatedItem.checked = false;
                    }
                } else if (!isTaskClosed) {
                    updatedItem.checked = true;
                }

                return updatedItem;
            });

            return { ...state, tasks: updatedTasks };
        }

        case 'FollowUpOverviewActions.ON_CLEAR_SELECTED_TASKS':
        {
            const updatedTasks = map(state.tasks, (item) => ({ ...item, checked: false }));

            return { ...state, tasks: updatedTasks };
        }

        case 'FollowUpOverviewActions.ON_SELECT_TASK':
            return {
                ...state,
                tasks: map(
                    state.tasks,
                    (item) => {
                        if (item.id === action.id) {
                            return { ...item, checked: true };
                        }

                        return item;
                    },
                ),
            };

        case 'FollowUpOverviewActions.ON_UNSELECT_TASK':
            return {
                ...state,
                tasks: map(
                    state.tasks,
                    (item) => {
                        if (item.id === action.id) {
                            return { ...item, checked: false };
                        }

                        return item;
                    },
                ),
            };

        case 'FollowUpOverviewActions.ON_RESET_OVERVIEW_TASK_LIST':
            return DEFAULT_STATE;

        case 'FollowUpOverviewActions.ON_RESET_OVERVIEW_TASK_LIST_FILTERS':
            return updateAppliedFilterState(state, { ...DEFAULT_FILTER_STATE });

        case 'FollowUpOverviewActions.ON_APPLY_FILTERS': {
            const { dirtyFilters } = state;
            const isFiltering = !isEqual(dirtyFilters, DEFAULT_FILTER_STATE);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'FollowUpOverviewActions.ON_CLEAR_FILTERS': {
            return updateDirtyFilterState(state, { ...DEFAULT_FILTER_STATE });
        }

        case 'FollowUpOverviewActions.ON_SET_AND_APPLY_SORT_ORDER': {
            const nextAppliedFilters = { ...state.appliedFilters, sort: action.value };
            return updateAppliedFilterState(state, nextAppliedFilters);
        }

        case 'FollowUpOverviewActions.ON_SET_AND_APPLY_DUE_DATE_TYPE_FILTER_CHANGE': {
            const { dueDateTypeId, isChecked } = action.value;

            const updatedFilters = {
                ...state.appliedFilters,
                filterDueDateType: isChecked ?
                    [...state.appliedFilters.filterDueDateType, dueDateTypeId] :
                    without(
                        state.appliedFilters.filterDueDateType,
                        dueDateTypeId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'FollowUpOverviewActions.ON_SET_AND_APPLY_TASK_STATUS_FILTER_CHANGE': {
            const { taskStatusId, isChecked } = action.value;

            const updatedFilters = {
                ...state.appliedFilters,
                filterStatuses: isChecked ?
                    [...state.appliedFilters.filterStatuses, taskStatusId] :
                    without(
                        state.appliedFilters.filterStatuses,
                        taskStatusId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'FollowUpOverviewActions.ON_SET_DIRTY_DUE_DATE_TYPE_FILTER_CHANGE': {
            return updateDirtyFilterState(
                state,
                { ...state.dirtyFilters, filterDueDateType: action.value },
            );
        }

        case 'FollowUpOverviewActions.ON_SET_DIRTY_SORT_ORDER': {
            return updateDirtyFilterState(
                state,
                { ...state.dirtyFilters, sort: action.value },
            );
        }

        case 'FollowUpOverviewActions.ON_SET_DIRTY_TASK_STATUS_FILTER_CHANGE': {
            return updateDirtyFilterState(
                state,
                { ...state.dirtyFilters, filterStatuses: action.value },
            );
        }

        case 'FollowUpOverviewActions.ON_CLEAR_SEARCH_TERM': {
            return {
                ...state,
                searchQuery: DEFAULT_STATE.searchQuery,
            };
        }

        case 'FollowUpOverviewActions.ON_SET_SEARCH_TERM': {
            return {
                ...state,
                searchQuery: action.value,
            };
        }

        default:
            return state;
    }
};

export default overviewTaskList;
