import ActionType from './finance.actionTypes';

const DEFAULT_STATE = {
    areSegmentsLoaded: false,
    segmentsCodes: {},
    segments: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ActionType.SET_ARE_SEGMENTS_LOADED:
            return {
                ...state,
                areSegmentsLoaded: action.areSegmentsLoaded,
            };

        case ActionType.SET_SEGMENTS:
            return {
                ...state,
                segments: action.segments,
            };

        case ActionType.SET_SEGMENTS_CODES:
            return {
                ...state,
                segmentsCodes: action.segmentsCodes,
            };

        case ActionType.SET_STATE:
            return {
                ...state,
                ...action.state,
            };

        default:
            return state;
    }
};
