import {
    clone,
    filter,
    get,
    isEmpty,
    map,
} from 'lodash';
import request from 'axios';
import dateUtils from '@saddlebackchurch/react-cm-ui/core/utils/dateUtils.js';
import {
    EVENT_CATEGORY_ID_WORSHIP_SERVICE,
    OPTION_LEVEL_SERIES,
    OPTION_ENTRY_FREE,
    OPTION_ENTRY_PAID,
    OPTION_REGISTRATION_NOTREQUIRED,
    OPTION_REGISTRATION_REQUIRED,
    OPTION_STATUS_PUBLISHED,
    OPTION_STATUS_UNPUBLISHED,
    VIEW_TYPE_CANCELED,
    VIEW_TYPE_MY_EVENTS,
} from '../constants.js';
import { SCHEDULE_ENTITY_TYPE } from '../../../global/constants.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import { normalizeEvent } from '../reducers/event.js';
import EventsApiUtils from '../../../global/eventsApiUtils.js';

class EventActions extends EventsApiUtils {
    getOccurrencesNextPage() {
        appReduxStore.dispatch({
            type: 'EventActions.ON_GETOCCURRENCES_NEXTPAGE',
        });
    }

    getStatisticsByRange(startDate, endDate, options) {
        const eventFilter = this.makeFilter(
            options,
            `startDate=${startDate}&endDate=${endDate}`,
        );

        return this.getStatistics({ filter: eventFilter }, null, null, {
            endDate,
            startDate,
        });
    }

    // This method is used to fetch a list of Worship Service Events filtered by Start/End Date
    getWorshipServiceEventsByDate(startDate, endDate, options) {
        const eventFilter = this.makeFilter(
            options,
            `categoryIds=${EVENT_CATEGORY_ID_WORSHIP_SERVICE}&startDate=${startDate}&endDate=${endDate}`,
        );

        return this.getPreferredEvent({ filter: eventFilter }, null, null, {
            endDate,
            startDate,
        });
    }

    getStatisticsByDate(date, options) {
        const eventFilter = this.makeFilter(options, `date=${date}`);
        return this.getStatistics({ filter: eventFilter }, null, null, { date });
    }

    getOccurrencesByRange(startDate, endDate, options, referenceDate, first) {
        const shouldAddDateRange = !options.eventStatus || // means it's the previous filter (Active/Cancelled)
            (options?.eventStatus.value && (options.rangeFrom || options.rangeTo));

        const initialFilter = shouldAddDateRange ? `startDate=${startDate}&endDate=${endDate}` : '';

        const eventFilter = this.makeFilter(
            options,
            initialFilter,
            first,
        );

        const api = get(options, 'level.value') === OPTION_LEVEL_SERIES ?
            this.getSeries : this.getOccurrences;

        return api({ filter: eventFilter }, null, null, {
            date: referenceDate,
            endDate,
            first,
            startDate,
        });
    }

    getOccurrencesByDate(date, options, first) {
        const eventFilter = this.makeFilter(options, `date=${date}`, first);
        return this.getOccurrences({ filter: eventFilter }, null, null, { date, first });
    }

    clearEventOccurrences() {
        appReduxStore.dispatch({
            type: 'EventActions.ON_CLEAREVENTOCCURRENCESBYRANGE',
        });
    }

    reset() {
        appReduxStore.dispatch({
            type: 'EventActions.ON_RESET',
        });
    }

    search(options, first) {
        const dateFilter = `startDate=${options.startDate}&endDate=${options.endDate}`;

        const eventFilter = this.makeFilter(
            options,
            dateFilter,
            first,
        );

        // eslint-disable-next-line no-underscore-dangle
        return this._search({ filter: eventFilter }, null, null, {
            first,
        });
    }

    searchNextPage() {
        appReduxStore.dispatch({
            type: 'EventActions.ON_SEARCH_NEXTPAGE',
        });
    }

    searchReset() {
        appReduxStore.dispatch({
            type: 'EventActions.ON_SEARCH_RESET',
        });
    }

    makeFilter(options, initialFilter = '', first = true) {
        let eventFilter = initialFilter; // avoids ESLint warning when re-assigning params

        if (initialFilter !== '') {
            eventFilter += '&';
        }

        if (options.eventStatus) { // means that is events in navigation V3
            eventFilter += `eventFilterStatus=${options.eventStatus.value}`;

            if (!options.rangeFrom && !options.rangeTo) {
                const userTimeZone = dateUtils.getDetectedTimeZone();
                eventFilter += `&defaultTimeZone=${userTimeZone}`;
            }
        } else {
            eventFilter += `occurrenceStatus=${
                options.type === VIEW_TYPE_CANCELED ?
                    'Canceled' :
                    'Active'
            }`;
        }

        if (options.type === VIEW_TYPE_MY_EVENTS) {
            eventFilter += '&myEvents=true';
        }

        eventFilter += `&eventTypes=${SCHEDULE_ENTITY_TYPE.worshipService},${SCHEDULE_ENTITY_TYPE.event}`;

        if (options.pageSize) {
            eventFilter += `&pageSize=${options.pageSize}`;
            eventFilter += `&pageNumber=${first ? 0 : options.pageNumber}`;
        }

        if (get(options, 'registration.value') === OPTION_REGISTRATION_REQUIRED) {
            eventFilter += '&canRegister=true';
        } else if (get(options, 'registration.value') === OPTION_REGISTRATION_NOTREQUIRED) {
            eventFilter += '&canRegister=false';
        }

        if (get(options, 'entry.value') === OPTION_ENTRY_PAID) {
            eventFilter += '&isPaid=true';
        } else if (get(options, 'entry.value') === OPTION_ENTRY_FREE) {
            eventFilter += '&isPaid=false';
        }

        if (get(options, 'status.value') === OPTION_STATUS_PUBLISHED) {
            eventFilter += '&advertiseOnWeb=true';
        } else if (get(options, 'status.value') === OPTION_STATUS_UNPUBLISHED) {
            eventFilter += '&advertiseOnWeb=false';
        }

        if (get(options, 'campus.checked') && !get(options, 'ministry.checked')) {
            eventFilter += '&campusEvent=true';
        }

        if (!get(options, 'campus.checked') && get(options, 'ministry.checked')) {
            eventFilter += '&campusEvent=false';
        }

        const recurring = get(options, 'recurring.checked');

        if (recurring === true) {
            eventFilter += '&recurring=true';
        } else if (recurring === false) {
            eventFilter += '&recurring=false';
        }

        // TODO/FIXME:
        // Right now there seems to be different variations of the shape of `options.campusValue`; hence this if/else statement.
        // We'd ideally like to establish and expect a single common format here, and require that components that
        // call this method massage their own internal data accordinaly, versus having to sniff the shape of the data in here
        // and handling the different variations differently.
        if (get(options, 'campusValue.value')) {
            eventFilter += `&churchEntityIds=${options.campusValue.value}`;
        } else {
            const campuses = filter(map(options.campusValue, (v, k) => v && k), (v) => v);

            if (!isEmpty(campuses)) {
                eventFilter += `&churchEntityIds=${campuses}`;
            }
        }

        if (get(options, 'sortValue.value')) {
            eventFilter += `&sort=${options.sortValue.value}`;
        }

        if (options.query) {
            eventFilter += `&query=${options.query}`;
        }

        return eventFilter;
    }

    add(event, sourceEventId) {
        normalizeEvent(clone(event), false);
        return this._add(sourceEventId ? { sourceEventId } : null, event); // eslint-disable-line no-underscore-dangle
    }

    update(event) {
        normalizeEvent(clone(event), false);
        return this._update({ id: event.id }, event); // eslint-disable-line no-underscore-dangle
    }

    getUploadURL() {
        return `${this.baseUrl}attachment`;
    }

    notificationsNextPage() {
        appReduxStore.dispatch({
            type: 'EventActions.ON_GETNOTIFICATIONS_NEXTPAGE',
        });
    }
}

const actions = new EventActions('/api/event/');

actions.buildReduxActionClass(
    {
        name: '_add',
        route: '',
        rq: request.post,
        suppressError: true,
    },
    {
        name: '_update',
        route: '{id}',
        rq: request.put,
        suppressError: true,
    },
    {
        name: 'getEmailVariables',
        route: 'email-variables',
        rq: request.get,
    },
    {
        name: 'delete',
        route: '{id}',
        rq: request.delete,
    },
    {
        name: 'close',
        route: '{id}/cancel',
        rq: request.put,
    },
    {
        name: 'get',
        route: '{id}',
        rq: request.get,
    },
    {
        name: 'getNextOccurrence',
        route: '{id}/next-occurrence-venue?dateTime={dateTime}&utc={utc}',
        rq: request.get,
    },
    {
        name: 'getInvites',
        route: '{id}/ministry-invite?includeNames={includeNames}',
        rq: request.get,
    },
    {
        name: 'addInvites',
        route: '{id}/ministry-invite',
        rq: request.put,
    },
    {
        name: 'invite',
        route: '{id}/ministry-invite',
        rq: request.post,
    },
    {
        name: 'removeInvites',
        route: '{id}/ministry-invite?inviteIds={inviteIds}',
        rq: request.delete,
    },
    {
        name: 'getOccurrences',
        route: 'flat-occurrences?{filter}',
        rq: request.get,
    },
    {
        name: 'getSeries',
        route: 'series?{filter}',
        rq: request.get,
    },
    {
        name: 'getEventOccurrencesByRange',
        route: '{id}/occurrences?{filter}',
        rq: request.get,
    },
    {
        name: 'cancel',
        route: '{id}/cancel',
        rq: request.put,
    },
    {
        name: 'cancelOccurrence',
        route: '{id}/cancel/{occurrenceId}',
        rq: request.put,
    },
    {
        name: 'cancelPotentialOccurrence',
        route: '{id}/cancel-occurrence',
        rq: request.put,
    },
    {
        name: 'getStatistics',
        route: 'statistics?{filter}',
        rq: request.get,
    },
    {
        name: 'publish',
        route: '{id}/publish',
        rq: request.put,
    },
    {
        name: 'getPreferredEvent',
        route: '?{filter}',
        rq: request.get,
    },
    {
        name: 'getMinistryLocation',
        route: 'ministry-location?ministryId={ministryId}&churchEntityId={churchEntityId}',
        rq: request.get,
    },
    {
        name: 'sendEmailToSOContacts',
        route: '{eventId}/serving-opportunity/email?servingOpportunityIds={opportunityIds}',
        rq: request.post,
    },
    {
        name: '_search',
        route: '?{filter}',
        rq: request.get,
    },
    {
        name: 'updateOccurrence',
        route: '{id}/occurrence',
        rq: request.put,
    },
    {
        name: 'getNotifications',
        route: '{id}/notification',
        rq: request.get,
    },
);

export default actions;
