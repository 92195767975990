/* eslint-disable max-classes-per-file */
import { HubConnectionState, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { random } from 'lodash';
import {
    cmAuthUtils,
} from '../../../global/utils/utils.js';
import {
    isCmDevelopmentEnv,
} from '../../../global/utils/environments.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import ActivityConstants from '../../constants/Notification/ActivityConstants.js';
import ApiConfig from '../../../global/api/apiConfig'; // eslint-disable-line import/no-named-as-default
import ApiUtils from '../../../global/apiUtils.js';
import CommonExportActions from '../../../system/migrationSettings/export/global/export.actions.js';
import NotificationBannerActions from '../../reduxActions/Notification/NotificationBannerActions.js';
import NotificationImportProgressActionCreators from '../../actionCreators/Notification/NotificationImportProgressActionCreators.js';
import PushNotificationActions from '../../reduxActions/Notification/PushNotificationActions.js';
import QuickNotificationActions from '../../../quickNotificationCenter/quickNotificationActions.js';

const isDev = isCmDevelopmentEnv;
const delay = (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms));

let startImportOnConnect = false;

class SignalRConnection {
    static getConnection(signalrHost) {
        const connection = new HubConnectionBuilder()
            .withUrl(`${signalrHost}?token=${cmAuthUtils.getAccessToken()}`)
            .configureLogging(isDev ? LogLevel.Trace : LogLevel.None)
            .build();

        connection.on('PostNotificationAlert', SignalRConnection.onPostNotificationAlert);
        connection.on('PostMessage', SignalRConnection.onNotification);
        connection.on('PostSummary', SignalRConnection.onSummary);
        connection.on('PostImportProgress', SignalRConnection.onImportProgress);
        connection.on('PostExportProgress', SignalRConnection.onExportProgress);

        return connection;
    }

    static onNotification(notification) {
        // Temporarily filtering out notification type Delivery Result
        if (notification.sourceType !== ActivityConstants.DELIVERY_RESULT &&
                notification.sourceType !== ActivityConstants.EXPORT_SERVICE) {
            appReduxStore.dispatch(NotificationBannerActions.showNotification(notification));
        }
    }

    static onPostNotificationAlert(notification) {
        QuickNotificationActions.updateUnreadNotificationCount(notification.unreadMessagesCount);
    }

    static onSummary(summary) {
        appReduxStore.dispatch(NotificationBannerActions.showSummary(summary));
    }

    static onImportProgress(progress) {
        NotificationImportProgressActionCreators.onReceive(progress);
    }

    static onExportProgress(progress) {
        console.log('onExportProgress', progress); // eslint-disable-line no-console
        CommonExportActions.exportProgressUpdated(progress);
    }

    constructor(signalrHost) {
        this.connected = false;
        this.fails = 0;
        this.onError = this.onError.bind(this);
        this.signalrHost = signalrHost;

        this.connection = SignalRConnection.getConnection(signalrHost);
        this.connection.onclose(this.onError);
    }

    onError(e) {
        console.warn('SignalR Connection Error!', e); // eslint-disable-line no-console
        this.connected = false;
        this.fails = 0;
        appReduxStore.dispatch(PushNotificationActions.connect(false));
        this.tryToReconnect();
    }

    start() {
        if (this.connected) {
            return;
        }

        this.connection.start()
            .then(() => {
                // Successful connection
                console.log('Successfully connected to SignalR hub'); // eslint-disable-line no-console
                appReduxStore.dispatch(PushNotificationActions.connect(true));
                this.connected = true;
                this.connection.invoke('clientConnected')
                    .then(() => {
                        if (startImportOnConnect) {
                            this.startImport();
                        }
                    })
                    .catch((res) => {
                        if (res) {
                            console.warn('SignalR Connection Set Person Error', res); // eslint-disable-line no-console
                            this.stop();
                        }
                    });
            })
            .catch((e) => {
                console.warn('Error occurred in Start', e); // eslint-disable-line no-console
                this.connected = this.connection.state !== HubConnectionState.Disconnected;
                if (!this.connected) {
                    this.fails++; // eslint-disable-line no-plusplus
                    appReduxStore.dispatch(PushNotificationActions.connect(false));
                    console.warn('ConnectError', this.fails); // eslint-disable-line no-console
                    // If there is an error, calling tryToReconnect will try to reconnect with a backoff retry policy
                    this.tryToReconnect();
                }
            });
    }

    stop() {
        if (!this.connected) {
            return;
        }

        this.connection.stop();
        this.connected = false;
        appReduxStore.dispatch(PushNotificationActions.connect(false));
    }

    timeoutToConnect() {
        if (this.connected) { return null; }

        // 1st try, try right away (1 instead of 0 as 0 means infinity)
        if (this.fails < 1) { return 1; }

        // NOTE: choose a random time to prevent all clients from flooding the server
        if (this.fails < 5) { return random(2, 10, true) * 1000; }

        return random(11, 60, true) * 1000;
    }

    startImport() {
        if (!this.connected || !this.connection) {
            return;
        }

        this.connection.invoke('enableImportNotifications')
            .catch((res) => {
                if (res) {
                    ApiUtils.handleClientError(res);
                    this.stop();
                }
            });
    }

    stopImport() {
        if (!this.connected || !this.connection) {
            return;
        }

        this.connection.invoke('disableImportNotifications')
            .catch((res) => {
                if (res) {
                    ApiUtils.handleClientError(res);
                    this.stop();
                }
            });
    }

    tryToReconnect() {
        const timeout = this.timeoutToConnect();
        if (!timeout) { return; }

        console.debug(`will attempt to connect to signalR server in ${timeout}ms...`); // eslint-disable-line no-console

        delay(timeout).then(() => {
            console.debug('attempting to connect to signalR server', cmAuthUtils.isLoggedIn(), this.connection, this.fails); // eslint-disable-line no-console
            if (cmAuthUtils.isLoggedIn()) {
                if (!this.connection || this.fails > 0) {
                    this.connection = SignalRConnection.getConnection(this.signalrHost);
                    this.connection.onclose(this.onError);
                }
                console.debug('attempting to connect to signalR server'); // eslint-disable-line no-console
                this.start();
            }
        });
    }
}

var signalRConnection; // eslint-disable-line no-var, vars-on-top

export default class BannerNotificationAPIUtils {
    static connect() {
        const signalrHost = `${ApiConfig.get()}/notificationhub`;

        signalRConnection = new SignalRConnection(signalrHost);
        signalRConnection.tryToReconnect();
    }

    static startImport() {
        startImportOnConnect = true;
        if (signalRConnection) {
            signalRConnection.startImport();
        }
    }

    static stopImport() {
        startImportOnConnect = false;
        if (signalRConnection) {
            signalRConnection.stopImport();
        }
    }
}
/* eslint-enable max-classes-per-file */
