import _ from 'lodash';
import ChunkedPaginationUtils from './chunkedPaginationUtils.js';

export default class ChunkedBidirectionalPaginationUtils {
    constructor(pageSize = 25, chunkSize = 150) {
        this._backwardBuffer = new ChunkedPaginationUtils(pageSize, chunkSize);
        this._forwardBuffer = new ChunkedPaginationUtils(pageSize, chunkSize);
    }

    reset() {
        this._backwardBuffer.reset();
        this._forwardBuffer.reset();
    }

    loadPage(results, forward, reset) {
        if (forward) {
            this._forwardBuffer.loadPage(results, 0, reset);
            if (reset) {
                this._backwardBuffer.reset();
            }
        } else {
            this._backwardBuffer.loadPage(results, 0, reset, true);
            // The first call always for forward buffer
            // no situation when we should load the backward buffer
            // and reset the forward one
        }
    }

    canLoadMoreBackward() {
        return this._backwardBuffer.canLoadMore();
    }

    canLoadMoreForward() {
        return this._forwardBuffer.canLoadMore();
    }

    needsToLoadChunkBackward() {
        return this._backwardBuffer.needsToLoadChunk();
    }

    needsToLoadChunkForward() {
        return this._forwardBuffer.needsToLoadChunk();
    }

    getAll(forward = true, next = true) {
        const b1 = this._backwardBuffer.getAll(!forward && next);
        const b2 = this._forwardBuffer.getAll(forward && next);
        return _.concat(b1, b2);
    }

    getPageSize() {
        return this._forwardBuffer.getPageSize();
    }

    getCurrentChunkBackward() {
        return this._backwardBuffer.getCurrentPageNumber();
    }

    getCurrentChunkForward() {
        return this._forwardBuffer.getCurrentPageNumber();
    }
}
