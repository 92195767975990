import { keyBy } from 'lodash';
import { selectBoxTransform } from '../../../global/utils';

export const DEFAULT_STATE = {
    categories: [],
    categoryById: {},
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'RoomCategoryActions.ON_GETALL': {
            const categories = selectBoxTransform(action, 'id', 'name');
            const categoryById = keyBy(categories, 'value');
            return {
                categories,
                categoryById,
            };
        }

        default:
            return state;
    }
};
