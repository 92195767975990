import {
    Button,
    Modal,
    Select,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
    map,
} from 'lodash';
import { connect } from 'react-redux';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';
import {
    useComponentDidMount,
    useComponentDidUpdate,
} from '../../global/lifeCycleHooks';
import {
    i18n,
} from '../../global/constants.js';
import {
    getAll as getAllAction,
} from './releaseNotesVersionSelectDialog.actions';
import {
    USER_PERMISSIONS,
} from '../../global/userPermissionConstants.js';
import UserAccessStore from '../../global/userAccessStore.js';

type PropTypes = {
    /**
     * Calls `api/promotion/release-notes`
     */
    getAll: Function;
    /**
     * If `true`, the dialog opens.
     */
    isOpen: boolean;
    isCmsPreviewEnabled: boolean;
    /**
     * Event that handles closing the dialog.
     */
    onClose: Function;
    /**
     * User Access Store
     */
    userAccess: UserAccessStore;
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        myAccount: {
            preferences: {
                isCmsPreviewEnabled,
            },
        },
    } = state;

    return {
        isCmsPreviewEnabled,
        userAccess,
    };
};

const mapDispatchToProps = {
    getAll: getAllAction,
};

const useStyles = makeStyles(({
    spacing,
}) => {
    const buttonWidth = 250;

    return {
        actions: {
            marginBottom: spacing(2),
            marginTop: spacing(3),
        },
        button: {
            width: buttonWidth,
        },
        select: {
            minWidth: buttonWidth,
        },
        subTitle: {
            margin: `${spacing(2)}px 0 ${spacing(1)}px !important`,
        },
    };
});

interface SelectValue {
    contentItemId: string;
    contentItemVersionId: string;
    label: string;
    value: string;
}

const BEM_BLOCK_NAME = 'release_notes_version_select';

function ReleaseNotesVersionSelectDialog({
    getAll,
    isOpen,
    isCmsPreviewEnabled,
    onClose,
    userAccess,
}: PropTypes) {
    const classes = useStyles();

    const [options, setOptions] = React.useState<SelectValue[]>([]);
    const [selectValue, setSelectValue] = React.useState<SelectValue>(null);
    const prevIsCmsPreviewEnabledRef = React.useRef(isCmsPreviewEnabled);

    const hasBrowseInDraftMode = userAccess.hasPermission(
        USER_PERMISSIONS.browseInDraftMode,
    );

    const fetchResults = () => {
        getAll()
            .then((response) => {
                if (!isEmpty(response.data)) {
                    setOptions(map(response.data, (data) => ({
                        contentItemVersionId: data.contentItemVersionId,
                        contentItemId: data.contentItemId,
                        label: data.title,
                        value: data.contentItemId,
                    })));

                    setSelectValue({
                        contentItemId: response.data[0].contentItemId,
                        contentItemVersionId: response.data[0].contentItemVersionId,
                        label: response.data[0].title,
                        value: response.data[0].contentItemId,
                    });
                }
            })
            .catch((response) => {
                // eslint-disable-next-line no-console
                console.log('Error getting Release Notes:', response);
            });
    };

    useComponentDidMount(() => {
        if (hasBrowseInDraftMode && isCmsPreviewEnabled) {
            fetchResults();
        }
    });

    useComponentDidUpdate(() => {
        if (hasBrowseInDraftMode && !prevIsCmsPreviewEnabledRef.current && isCmsPreviewEnabled) {
            fetchResults();
        }
    }, [
        hasBrowseInDraftMode,
        isCmsPreviewEnabled,
        prevIsCmsPreviewEnabledRef.current,
    ]);

    const onOpenClick = () => {
        onClose({
            contentItemId: selectValue.contentItemId,
            contentItemVersionId: selectValue.contentItemVersionId,
        });
    };

    const onSelectChange = (option) => {
        setSelectValue(option);
    };

    if (!hasBrowseInDraftMode || !isCmsPreviewEnabled) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <Modal.Content
                alignItems="center"
            >
                <Typography
                    variant="h2"
                >
                    {i18n('Release Notes Preview')}
                </Typography>

                <Typography
                    align="center"
                    className={classes.subTitle}
                    color="textSecondary"
                    variant="subtitle1"
                >
                    {i18n('Please pick a version to preview.')}
                </Typography>

                <Select
                    classes={{
                        root: classes.select,
                    }}
                    clearable={false}
                    dropdownMenuMaxHeight={70}
                    fluid
                    id={`${BEM_BLOCK_NAME}--select`}
                    onChange={onSelectChange}
                    options={options}
                    value={selectValue}
                />
            </Modal.Content>

            <Modal.Actions
                alignItems="center"
                classes={{
                    root: classes.actions,
                }}
                direction="column"
            >
                <Button
                    classes={{
                        root: classes.button,
                    }}
                    disabled={isEmpty(selectValue)}
                    designVersion={2}
                    id={`${BEM_BLOCK_NAME}--open_button`}
                    onClick={onOpenClick}
                >
                    {i18n('Open Release Notes')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotesVersionSelectDialog);
