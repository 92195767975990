// eslint-disable-next-line import/no-unresolved
import 'app/app.scss';

import {
    Button,
    domUtils,
    ModalDeprecated,
} from '@saddlebackchurch/react-cm-ui';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import withWidth from '@saddlebackchurch/react-cm-ui/core/utils/withWidth';
import _ from 'lodash';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
    isChrome,
    isEdge,
    isFirefox,
    isOpera,
    isSafari,
} from 'react-device-detect';
import { onLogOut } from './app.actions.js';
import {
    CM_CORE_FE_VERSION,
    i18n,
} from '../global/constants.js';
import { cmAuthUtils } from '../global/utils/utils.js';
import { isInMobileAppMode } from '../global/utils/environments.js';
import { setIsNavigationCollapsed } from './navigation/navigation.actions';
import AppBanners from './appBanners.jsx';
import AppHeader from './appHeader/appHeader.jsx';
import AppNavigation from './appNavigation.jsx';
import AppSettingsActions from './appSettingsActions.js';
import AppStore from '../js/stores/AppStore.js';
import BannerNotificationAPIUtils from '../js/utils/Notification/BannerNotificationAPIUtils.js';
import BannerUtils from '../js/utils/BannerUtils.js';
import bootstrapActions from './bootstrapActions.js';
import breakpointActions from './breakpointActions.js';
import ChurchStructureActions from '../global/actions/churchStructure.actions';
import DataCovenantModal from './dataCovenantModal.jsx';
import EnumerationStore from '../js/stores/EnumerationStore.js';
import ExportDownloadBanner from './exportDownloadBanner/exportDownloadBanner';
import HelpContextual from '../help/contextualHelp/contextualHelp.jsx';
import HelpLayout from '../help/helpLayout.js';
import i18nMessagesStore from '../global/i18nMessagesStore.js';
import ModalStore from '../js/stores/ModalStore.js';
import quickNotificationActions from '../quickNotificationCenter/quickNotificationActions.js';
import RegionActions from '../js/reduxActions/Security/RegionActions.js';
import ReleaseNotesDialog from './releaseNotesDialog';
import ReleaseNotesVersionSelectDialog from './releaseNotesVersionSelectDialog';
import UserIdentityStore from '../global/userIdentityStore';
import UserPreferenceStore from '../js/stores/User/UserPreferenceStore.js';
import styles from './appNavigationStyles.js';

const propTypes = {
    agreements: PropTypes.shape({}),
    bootstrap: PropTypes.shape({
        hasCoreBootstrapLoaded: PropTypes.bool,
        securityContext: PropTypes.shape({
            userIdentity: PropTypes.instanceOf(UserIdentityStore),
        }),
    }),
    breakpoint: PropTypes.shape({
        clientHeight: PropTypes.number,
        isLarge: PropTypes.bool,
    }),
    children: PropTypes.element.isRequired,
    classes: PropTypes.shape({
        navigationRoot: PropTypes.string,
    }).isRequired,
    dataCovenantAgreement: PropTypes.shape({
        active: PropTypes.bool,
        content: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        predecessorId: PropTypes.number,
        title: PropTypes.string,
        version: PropTypes.number,
    }),
    dataCovenantIsFetching: PropTypes.bool,
    error: PropTypes.shape({
        has403Error: PropTypes.bool,
        has404Error: PropTypes.bool,
    }),
    isMobile: PropTypes.bool.isRequired,
    isNavigationCollapsed: PropTypes.bool.isRequired,
    setIsNavigationCollapsed: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
};

const defaultProps = {
    agreements: null,
    bootstrap: {},
    breakpoint: {},
    dataCovenantAgreement: {},
    dataCovenantIsFetching: false,
    error: {},
};

const contextTypes = {
    router: PropTypes.shape({
        getCurrentLocation: PropTypes.func,
    }),
};

const DATA_COVENANT_AGREEMENT_NAME = 'Data Covenant';

const mapStateToProps = (state) => {
    const {
        appSettings: {
            agreements,
            isFetchingAgreement: dataCovenantIsFetching,
        },
        bootstrap,
        breakpoint,
        error,
        login,
        navigation,
    } = state;

    return {
        agreements,
        bootstrap,
        breakpoint,
        dataCovenantIsFetching,
        error,
        isLoggedIn: login.isLoggedIn,
        isMobile: breakpoint.isMobile,
        isNavigationCollapsed: navigation.isNavigationCollapsed,
    };
};

const unauthenticatedRoutes = [
    '/about',
    '/environments',
    '/public',
    '/sms-consent',
    '/sms-terms-and-conditions',
];

function addBodyClassChange() {
    const designV1 = 'design-v1';
    const bodyClass = AppStore.getBodyClass() ? designV1 : '';

    if (bodyClass) {
        document.body.classList.add(bodyClass);
    } else {
        document.body.classList.remove(designV1);
    }
}

function browserCompatibilityBanner() {
    const isBrowserCompatible = isChrome || isFirefox || isSafari || isOpera || isEdge;

    if (!isBrowserCompatible) {
        BannerUtils.addBanner({
            children: (
                <div>
                    <p className="font-size-xsmall">{i18n('Try one of these!')}</p>

                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginBottom: '11px',
                        }}
                    >
                        <span
                            style={{
                                background: 'url(images/browser-icons-24px.png) 0 0 no-repeat',
                                display: 'inline-block',
                                height: '24px',
                                marginRight: '11px',
                                width: '24px',
                            }}
                        />
                        <Button href="//www.google.com/chrome/browser/desktop" width="98px"><span>Chrome</span></Button>
                    </div>

                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginBottom: '11px',
                        }}
                    >
                        <span
                            style={{
                                background: 'url(images/browser-icons-24px.png) -34px 0 no-repeat',
                                display: 'inline-block',
                                height: '25px',
                                marginLeft: '-1px',
                                marginRight: '10px',
                                width: '26px',
                            }}
                        />
                        <Button href="//www.mozilla.org/en-US/firefox/new" width="98px"><span>Firefox</span></Button>
                    </div>

                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginBottom: '11px',
                        }}
                    >
                        <span
                            style={{
                                background: 'url(images/browser-icons-24px.png) -70px 0 no-repeat',
                                display: 'inline-block',
                                height: '24px',
                                marginRight: '11px',
                                width: '24px',
                            }}
                        />
                        <Button href="//www.apple.com/safari" width="98px"><span>Safari</span></Button>
                    </div>

                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <span
                            style={{
                                background: 'url(images/browser-icons-24px.png) -104px 0 no-repeat',
                                display: 'inline-block',
                                height: '24px',
                                marginRight: '11px',
                                width: '24px',
                            }}
                        />
                        <Button href="//www.microsoft.com/en-us/windows/microsoft-edge" width="98px"><span>Edge</span></Button>
                    </div>
                </div>
            ),
            level: 'warning',
            levelIcon: 'ban',
            timeout: false,
            title: i18n('Your Browser is not supported'),
            type: 'notification',
        });
    }
}

function onKeyDownLogOut(event) {
    if (event.keyCode === 13) {
        onLogOut();
    }
}

function onResize() {
    if (domUtils.hasClassName(document.body, 'pushed-right')) {
        document.body.classList.remove('pushed-right');
        document.querySelector('.app-header').style.left = 0;
    }

    breakpointActions.update();
}

function removeAppLoader() {
    const appLoader = document.querySelector('.app-loader');

    if (appLoader && appLoader.parentNode) {
        appLoader.parentNode.removeChild(appLoader);
    }
}

function removeHash() {
    const loc = window.location;

    // preferred method for modern browsers
    if (
        history && _.isFunction(history.replaceState) // eslint-disable-line no-restricted-globals
    ) {
        history.replaceState( // eslint-disable-line no-restricted-globals
            {},
            document.title,
            loc.pathname + loc.search,
        );
    } else { // fallback for older browsers with limited HTML5 History API support
        // Prevent scrolling by storing the page's current scroll offset
        const scrollV = document.body.scrollTop;
        const scrollH = document.body.scrollLeft;

        loc.hash = '';

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);

        document.querySelector('html').classList.add(domUtils.browserDetect());

        this.state = {
            dataCovenantModalOpen: false,
            isFetchingEnumerations: false,
            isLoggedIn: false,
            isReleaseNotesVersionSelectDialogOpen: false,
            modals: [],
            releaseNoteIds: null,
        };

        this.loginDisabled = false;

        this.onDataCovenantModalClose = this.onDataCovenantModalClose.bind(this);
        this.onLayoutClick = this.onLayoutClick.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.onReleaseNotesDialogCloseComplete = this.onReleaseNotesDialogCloseComplete.bind(this);
        this.onReleaseNotesVersionSelectDialogToggle =
            this.onReleaseNotesVersionSelectDialogToggle.bind(this);
        this.onStoreChangeRef = this.onStoreChange.bind(this);
        this.onStoreModalCloseRef = this.onStoreModalClose.bind(this);

        this.loginInterval = setInterval(this.onLogin, 250);
        this.onResizeDebounce = _.debounce(() => onResize(), 80);

        console.log('Healthy Church client version:', CM_CORE_FE_VERSION); // eslint-disable-line no-console
    }

    componentDidMount() {
        AppStore.addBodyClassChangeListener(addBodyClassChange);
        AppStore.addChangeListener(this.onStoreChangeRef);
        EnumerationStore.addChangeListener(this.onStoreChangeRef);
        i18nMessagesStore.addChangeListener(this.onStoreChangeRef);
        ModalStore.addChangeListener(this.onStoreChangeRef);
        ModalStore.addModalCloseListener(this.onStoreModalCloseRef);
        UserPreferenceStore.addChangeListener(this.onStoreChangeRef);

        window.addEventListener('resize', this.onResizeDebounce);
        onResize();

        addBodyClassChange();
        browserCompatibilityBanner();

        const appLoader = document.querySelector('.app-loader');

        if (appLoader && appLoader.parentNode) {
            appLoader.parentNode.removeChild(appLoader);
        }
    }

    componentDidUpdate(prevProps) {
        const { router } = this.context;
        const {
            bootstrap: {
                hasCoreBootstrapLoaded,
                securityContext: { userIdentity },
            },
            dataCovenantIsFetching,
            error,
        } = this.props;

        const { dataCovenantModalOpen, isFetchingEnumerations } = this.state;
        const bodyClassList = document.querySelector('body').classList;
        const isSectionHelp = router.location.pathname.split('/')[1] === 'help';
        const recvd403Error = error.has403Error && !prevProps.error.has403Error;
        const recvd404Error = error.has404Error && !prevProps.error.has404Error;

        if (!dataCovenantModalOpen &&
            !dataCovenantIsFetching &&
            !isFetchingEnumerations &&
            hasCoreBootstrapLoaded &&
            !userIdentity.hasAcceptedDataCovenant()
        ) {
            this.setState({ dataCovenantModalOpen: true });
        }

        if (recvd403Error && window.location.pathname !== '/403') {
            router.push(`/403?conflictURL=${window.location.pathname}`);
        }

        if (recvd404Error && window.location.pathname !== '/404') {
            router.push('/404');
        }

        if (isSectionHelp) {
            bodyClassList.add('help');
        }

        if (!isSectionHelp && bodyClassList.contains('help')) {
            bodyClassList.remove('help');
        }
    }

    componentWillUnmount() {
        AppStore.removeBodyClassChangeListener(addBodyClassChange);
        AppStore.removeChangeListener(this.onStoreChangeRef);
        EnumerationStore.removeChangeListener(this.onStoreChangeRef);
        i18nMessagesStore.removeChangeListener(this.onStoreChangeRef);
        ModalStore.removeChangeListener(this.onStoreChangeRef);
        ModalStore.removeModalCloseListener(this.onStoreModalCloseRef);
        UserPreferenceStore.removeChangeListener(this.onStoreChangeRef);

        window.removeEventListener('resize', this.onResizeDebounce);
    }

    onDataCovenantModalClose() {
        this.setState({ dataCovenantModalOpen: false });
    }

    onLayoutClick() {
        const { isMobile } = this.props;

        if (isMobile && domUtils.hasClassName(document.body, 'pushed-right')) {
            document.body.classList.remove('pushed-right');
            document.querySelector('.app-header').style.left = 0;
        }
    }

    onLogin() {
        if (cmAuthUtils.isLoggedIn()) {
            clearInterval(this.loginInterval);

            this.setState({ isLoggedIn: true }, () => {
                removeHash();
                bootstrapActions.getBootstrap()
                    .then(() => {
                        // Remove App Loading Spinner
                        removeAppLoader();

                        // If Bootstrap API succeeds, we know that a valid user is logged in
                        // We can continue with additional actions needed to bootstrap the app ...
                        BannerNotificationAPIUtils.connect();

                        // Fetch the Enumerations
                        this.setState({
                            isFetchingEnumerations: true,
                        }, () => {
                            bootstrapActions
                                .getEnumerations()
                                .then(() => {
                                    this.setState({
                                        isFetchingEnumerations: false,
                                    });
                                });
                        });

                        // Fetch the Data Covenant Agreement
                        // TODO/FIXME: Consider doing this inside Data Covenant
                        //             component (only if we need it)
                        //             rather than in here, always.
                        AppSettingsActions.getAgreement({
                            agreementName: encodeURIComponent(DATA_COVENANT_AGREEMENT_NAME),
                        });

                        // Fetch Church Entity Regions (used for grouping Church Entities
                        // geographically for security-trimming purposes)
                        RegionActions.getAll();

                        // Fetch data for user's 'Quick Notifications Center'
                        quickNotificationActions.getUserNotifications({
                            category: '',
                            days: 30,
                            isViewed: false,
                            pageNumber: 0,
                            pageSize: null,
                            sortBy: 'createdate,DESC',
                        }, null, null, { first: true });

                        // Fetch the global list of Campuses
                        ChurchStructureActions.getCampuses({ includeInactive: true });
                    })
                    .catch((res) => {
                        const is403 = res && res.response && res.response.status === 403;
                        this.loginDisabled = is403;

                        if (this.loginDisabled) {
                            /**
                             * If Bootstrap API returned 403 Forbidden the user
                             * is likely disabled and thus not authorized to use
                             * the HC Back Office Application
                             */
                            BannerUtils.addBanner({
                                children: (
                                    <div className="application-error">
                                        <h3 className="no-margin-top">
                                            User Account Disabled
                                        </h3>

                                        <p className="no-margin-top">
                                            Sorry, this user account has been disabled.
                                        </p>

                                        <p>
                                            <span
                                                className="app--banner_logout"
                                                onClick={onLogOut}
                                                onKeyDown={onKeyDownLogOut}
                                                role="link"
                                                tabIndex="-1"
                                            >
                                                Logout
                                            </span>
                                        </p>
                                    </div>
                                ),
                                level: 'warning',
                                type: 'alert',
                            });
                        }
                    });
            });
        }
    }

    onReleaseNotesDialogCloseComplete() {
        this.setState({
            releaseNoteIds: null,
        });
    }

    onReleaseNotesVersionSelectDialogToggle({
        contentItemId,
        contentItemVersionId,
    } = {
        contentItemId: null,
        contentItemVersionId: null,
    }) {
        const releaseNoteIds = contentItemId && contentItemVersionId ? {
            contentItemId,
            contentItemVersionId,
        } : null;

        this.setState((prevState) => ({
            isReleaseNotesVersionSelectDialogOpen: !prevState.isReleaseNotesVersionSelectDialogOpen,
            releaseNoteIds,
        }));
    }

    onStoreChange() {
        setTimeout(() => {
            this.setState(() => {
                const newState = {
                    lcid: i18nMessagesStore.getLCID(),
                    modals: ModalStore.getModals(),
                    timeZoneId: UserPreferenceStore.getTimeZoneId(),
                };

                return newState;
            });
        }, 0); // avoiding a nested dispatch
    }

    onStoreModalClose(modalId) {
        if (
            modalId &&
            this.modal[modalId] &&
            _.isFunction(this.modal[modalId]._onClose) // eslint-disable-line no-underscore-dangle
        ) {
            this.modal[modalId]._onClose(); // eslint-disable-line no-underscore-dangle
        }
    }

    render() {
        const {
            router,
        } = this.context;

        const {
            dataCovenantModalOpen,
            isLoggedIn,
            isReleaseNotesVersionSelectDialogOpen,
            modals,
            releaseNoteIds,
        } = this.state;

        const {
            agreements,
            bootstrap,
            children,
            classes,
            isNavigationCollapsed,
            setIsNavigationCollapsed: setIsNavigationCollapsedAction,
            width,
        } = this.props;

        const dataCovenantAgreement = !_.isEmpty(agreements) ?
            agreements[DATA_COVENANT_AGREEMENT_NAME] :
            {};

        const isMobile = width === 'sm';
        const personId = bootstrap.securityContext.userIdentity.getPersonId();
        const hasPersonId = personId !== 0;
        const isSectionHelp = router.location.pathname.split('/')[1] === 'help';
        const userFullName = bootstrap.securityContext.userIdentity.getFullName();

        if (_.some(unauthenticatedRoutes, (ur) => window.location.pathname.startsWith(ur))) {
            return (
                <div id="page-content">
                    {children}
                </div>
            );
        }

        if (!this.loginDisabled && (!isLoggedIn || !hasPersonId)) {
            return false;
        }

        const coreAppInnerClasses = ClassNames(
            'coreApp-inner',
            classes.navigationRoot,
            {
                'modal-open': modals.length > 0,
                'remove-nav': isSectionHelp,
            },
        );

        const layoutClasses = ClassNames(
            'layout',
            'navigation-v3',
            {
                'navigation-md': !isMobile,
                'navigation-sm': isMobile,
                'navigation-open': !isNavigationCollapsed,
                'navigation-closed': isNavigationCollapsed,
            },
        );

        return (
            <div className={coreAppInnerClasses}>
                {!isSectionHelp ? (
                    <React.Fragment>
                        <AppNavigation
                            loginDisabled={this.loginDisabled}
                        />

                        <div id="layout" className={layoutClasses}>
                            {isMobile && (
                                <div
                                    className="mobile-content-transparent-wrapper"
                                    onClick={() => setIsNavigationCollapsedAction(true)}
                                    role="presentation"
                                />
                            )}

                            <AppHeader
                                onReleaseNotesVersionSelectDialogOpen={
                                    this.onReleaseNotesVersionSelectDialogToggle
                                }
                                loginDisabled={this.loginDisabled}
                            />

                            <div
                                className="page-content"
                                onClick={this.onLayoutClick}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={-1}
                            >
                                {children}
                            </div>

                            <ModalDeprecated
                                closeButton={false}
                                height={860}
                                isOpen={dataCovenantModalOpen}
                                onClose={this.onDataCovenantModalClose}
                                title={dataCovenantAgreement.title}
                                width={768}
                            >
                                <DataCovenantModal
                                    closeModal={this.onDataCovenantModalClose}
                                    dataCovenantAgreement={dataCovenantAgreement}
                                    personId={personId}
                                />
                            </ModalDeprecated>
                        </div>

                        {/**
                         * NOTE: This is way old-school and needs to be removed sooner rather
                         * than later. For now, it lives, only because of the time it would take
                         * to refactor old Modal code to use react-cm-ui Modal.
                        */}
                        <div className="modals-container">
                            {_.map(modals, (m, i) => {
                                const modalsLength = modals.length;
                                const layerNum = i + 1;

                                return (
                                    React.cloneElement(m.children, {
                                        disableOnClickOutside: modalsLength !== layerNum,
                                        key: `modal-key-${layerNum}`,
                                        layerIndex: i,
                                        modalId: m.id,
                                        ref: (ref) => { this.modal[m.id] = ref; },
                                    })
                                );
                            })}
                        </div>

                        <AppBanners />

                        <ExportDownloadBanner />

                        {!isMobile && !isInMobileAppMode && <HelpContextual />}

                        <ReleaseNotesVersionSelectDialog
                            isOpen={isReleaseNotesVersionSelectDialogOpen}
                            onClose={this.onReleaseNotesVersionSelectDialogToggle}
                        />

                        <ReleaseNotesDialog
                            onCloseComplete={this.onReleaseNotesDialogCloseComplete}
                            releaseNoteIds={releaseNoteIds}
                        />
                    </React.Fragment>
                ) : (
                    <HelpLayout userFullName={userFullName}>
                        {children}
                    </HelpLayout>
                )}
            </div>
        );
    }
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;
App.contextTypes = contextTypes;

const AppWithStyles = withStyles(styles, { withTheme: true })(withWidth()(App));
export default connect(mapStateToProps, { setIsNavigationCollapsed })(AppWithStyles);
