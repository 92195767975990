const DEFAULT_STATE = {
    isFetching: true,
    data: {},
};

export default (state = DEFAULT_STATE, action) => {
    const actionType = 'PersonActions';
    const {
        result,
        type,
    } = action;

    switch (type) {
        case `${actionType}.ON_BEFORE_GETPERSONAL`: {
            return {
                ...state,
                isFetching: true,
            };
        }

        case `${actionType}.ON_GETPERSONAL`: {
            return {
                ...state,
                isFetching: false,
                data: result,
            };
        }

        case `${actionType}.ON_RESET_PERSONAL`: {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
