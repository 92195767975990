enum CardBrand {
    AmEx = 'amex',
    DinersClub = 'diners',
    Discover = 'discover',
    JCB = 'jcb',
    Mastercard = 'mastercard',
    UnionPay = 'union pay',
    Visa = 'visa',
}

export default CardBrand;
