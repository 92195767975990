import _ from 'lodash';
import AdminImportNavConstants from '../../../constants/Admin/AdminImportNavConstants';

export const DEFAULT_STATE = {
    step: AdminImportNavConstants.STEP_HISTORY,
    stepParams: null,
    showSelectFileModal: false,
    selectedSourceType: null,
    selectedFile: null,
    isAuditMode: false,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'AdminImportNavActions.ON_STEP':
            return {
                ...state,
                step: action.step,
                stepParams: action.params,
                isAuditMode: (action.params && action.params.auditMode) || false,
            };

        case 'AdminImportNavActions.ON_SHOW_SELECT_FILE':
            return { ...state, showSelectFileModal: action.showModal };

        case 'AdminImportNavActions.ON_SELECT_FILE':
            return {
                ...state,
                selectedSourceType: action.sourceType,
                selectedFile: action.file,
            };

        case 'AdminImportNavActions.ON_RESET':
            return DEFAULT_STATE;
    }
    return state;
}
