import './personalForm.scss';

import {
    find,
    isEmpty,
} from 'lodash';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import { translationFactory } from '../../../global/i18nUtils.js';
import { getRecordTypeByGrade } from '../../global/utils.js';
import { personPersonalFormPropTypes, personPersonalFormDefaultProps } from './personFormPropTypes.js';
import { PERSON_RECORD_TYPES, personRecordPropTypes } from './addPersonV2Constants.js';
import Form from '../../../global/form';
import AddPersonRecordActions from './addPersonRecordActions.js';
import EmergencyContactFormActions from './emergencyContactFormActions.js';
import ModalPrompt from '../../../global/modalPrompt.jsx';

const propTypes = {
    defaultChurchEntityId: PropTypes.number,
    churchEntitiesList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ).isRequired,
    className: PropTypes.string,
    ministryId: PropTypes.number,
    ministryLocationId: PropTypes.number,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    personalFormData: personPersonalFormPropTypes,
    recordType: personRecordPropTypes,
    resetOnUnmount: PropTypes.bool,
    style: PropTypes.shape({}),
};

const defaultProps = {
    defaultChurchEntityId: null,
    className: undefined,
    ministryId: null,
    ministryLocationId: null,
    onBlur: undefined,
    onFocus: undefined,
    personalFormData: personPersonalFormDefaultProps,
    recordType: undefined,
    resetOnUnmount: true,
    style: {},
};

const mapStateToProps = (state) => {
    const {
        global: {
            churchCampuses,
        },
        navigation: {
            myMinistryId: ministryId,
            myMinistryLocationId: ministryLocationId,
        },
        people: {
            record: {
                personForm: {
                    personalFormData,
                    recordType,
                },
            },
        },
    } = state;

    const churchEntitiesList = churchCampuses.getAllCampusesForSelect({
        includeNulls: true,
        excludeExtensions: true,
    });

    return {
        churchEntitiesList,
        ministryId: parseInt(ministryId, 10),
        ministryLocationId: parseInt(ministryLocationId, 10),
        personalFormData,
        recordType,
    };
};

const i18n = translationFactory();

class PersonalForm extends React.PureComponent {
    static onChangeBirthDate(value) {
        AddPersonRecordActions.updateBirthDate(value.date.unix());
    }

    static onChangeFirstName(value) {
        AddPersonRecordActions.updateFirstName(value);
    }

    static onChangeLastName(value) {
        AddPersonRecordActions.updateLastName(value);
    }

    static onChangeGender(value) {
        AddPersonRecordActions.updateGender(value);
    }

    static onChangeCampus(value) {
        AddPersonRecordActions.updateCampus(value);
    }

    constructor(props) {
        super(props);

        this.state = {
            isOpenRecordChangeTypeModal: false,
            selectedGrade: null,
        };

        this.onCancelRecordType = this.onCancelRecordType.bind(this);
        this.onChangeGrade = this.onChangeGrade.bind(this);
        this.onConfirmRecordTypeChange = this.onConfirmRecordTypeChange.bind(this);
    }

    componentDidMount() {
        const {
            defaultChurchEntityId, // optional value manually added in <AddPersonV2 />
            churchEntitiesList,
            ministryId,
            ministryLocationId, // value comes automatically from navigation
        } = this.props;

        AddPersonRecordActions.getChurchEntity();

        const targetValue = ministryLocationId || defaultChurchEntityId;

        const defaultCampus = find(
            churchEntitiesList,
            { value: targetValue },
        );

        const shouldDefaultCampus = (
            (ministryId && ministryLocationId) ||
            defaultChurchEntityId
        ) && defaultCampus;

        if (shouldDefaultCampus) {
            AddPersonRecordActions.updateCampus(defaultCampus);
        }
    }

    componentWillUnmount() {
        const {
            resetOnUnmount,
        } = this.props;

        if (resetOnUnmount) {
            AddPersonRecordActions.resetPersonalForm();
        }
    }

    onCancelRecordType() {
        this.setState({ isOpenRecordChangeTypeModal: false });
    }

    onChangeGrade(value) {
        const {
            recordType: previousRecordType,
        } = this.props;

        const {
            selectedGrade,
        } = this.state;
        const recordType = getRecordTypeByGrade(value);
        if (!isEmpty(selectedGrade) && !isEmpty(value) && previousRecordType !== recordType) {
            this.setState({
                isOpenRecordChangeTypeModal: true,
                selectedGrade: value,
            });
        } else {
            this.setState({
                selectedGrade: value,
            });
            AddPersonRecordActions.updateGrade(value);
            AddPersonRecordActions.updateRecordType(recordType);
        }
    }

    onConfirmRecordTypeChange() {
        const {
            selectedGrade,
        } = this.state;
        AddPersonRecordActions.resetPersonalForm();
        AddPersonRecordActions.resetContactForm();
        AddPersonRecordActions.resetPersonPhoto();
        EmergencyContactFormActions.resetSelectedEmergencyContact();
        const recordType = getRecordTypeByGrade(selectedGrade);
        AddPersonRecordActions.updateRecordType(recordType);
        AddPersonRecordActions.updateGrade(selectedGrade);
        this.setState({
            isOpenRecordChangeTypeModal: false,
        });
    }

    render() {
        const {
            churchEntitiesList,
            className,
            onBlur,
            onFocus,
            personalFormData,
            recordType,
            style,
        } = this.props;

        const {
            isOpenRecordChangeTypeModal,
        } = this.state;

        const {
            birthDate,
            firstName,
            lastName,
            gender,
            grade,
            campus,
        } = personalFormData;

        const bemBlockName = 'person_add_personal_form';
        const bemBlockElementName = 'person_add_personal_form--field';
        const containerClasses = ClassNames(bemBlockName, className);

        const campusFieldProps = {
            options: churchEntitiesList,
        };

        const gradeFieldProps = {
            campus,
            isRecordTypeLabelEnabled: true,
            recordType,
        };

        const birthDateFieldProps = {
            maxDate: moment(),
        };

        const isDateOfBirthdateRequired = (recordType === PERSON_RECORD_TYPES.child) ||
            (recordType === PERSON_RECORD_TYPES.student);

        return (
            <React.Fragment>
                <Form className={containerClasses} style={style}>
                    <Form.Fieldset
                        className={`${bemBlockName}--field_set`}
                        id={`${bemBlockName}--field_set`}
                        title={i18n('Personal')}
                    >
                        <Form.Field
                            className={`${bemBlockElementName}_firstname`}
                            fieldType="inputText"
                            label={i18n('FirstName')}
                            medium={6}
                            onBlur={() => onBlur('firstName', firstName)}
                            onFocus={() => onFocus('firstName', firstName)}
                            onChange={PersonalForm.onChangeFirstName}
                            required
                            value={firstName}
                        />
                        <Form.Field
                            className={`${bemBlockElementName}_lastname`}
                            fieldType="inputText"
                            label={i18n('LastName')}
                            medium={6}
                            onBlur={() => onBlur('lastName', lastName)}
                            onFocus={() => onFocus('lastName', lastName)}
                            onChange={PersonalForm.onChangeLastName}
                            required
                            value={lastName}
                        />
                        <Form.Field
                            className={`${bemBlockElementName}_birthdate`}
                            fieldType="inputDate"
                            fluid
                            label={i18n('Birthdate')}
                            fieldProps={birthDateFieldProps}
                            medium={6}
                            onChange={PersonalForm.onChangeBirthDate}
                            required={isDateOfBirthdateRequired}
                            value={birthDate}
                        />
                        <Form.Field
                            className={`${bemBlockElementName}_gender`}
                            fieldType="selectGender"
                            label={i18n('Gender')}
                            medium={6}
                            onChange={PersonalForm.onChangeGender}
                            value={gender}
                        />
                        <Form.Field
                            className={`${bemBlockElementName}_campus`}
                            fieldType="select"
                            fieldProps={campusFieldProps}
                            fluid
                            label={i18n('Campus')}
                            medium={6}
                            onChange={PersonalForm.onChangeCampus}
                            required
                            value={campus}
                        />
                        <Form.Field
                            className={`${bemBlockElementName}_grade`}
                            fieldType="selectGrade"
                            fieldProps={gradeFieldProps}
                            fluid
                            label={i18n('Grade')}
                            medium={6}
                            onChange={this.onChangeGrade}
                            required
                            value={grade}
                        />
                    </Form.Fieldset>
                </Form>
                <ModalPrompt
                    confirmBtnColor="warning"
                    confirmBtnLabel={i18n('Yes')}
                    isOpen={isOpenRecordChangeTypeModal}
                    onCancel={this.onCancelRecordType}
                    onClose={this.onCancelRecordType}
                    onConfirm={this.onConfirmRecordTypeChange}
                    subTitle={i18n('The grade selected will result in a different record type. Some of the data you entered may be lost. Are you sure you want to proceed?')}
                    title={i18n('Record Type Change')}
                />
            </React.Fragment>
        );
    }
}

PersonalForm.propTypes = propTypes;
PersonalForm.defaultProps = defaultProps;

export default connect(mapStateToProps)(PersonalForm);
