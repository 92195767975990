import './personNotesDrawerNoteDetails.scss';

import _ from 'lodash';
import {
    Header,
    Icon,
    Image,
} from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { noteDefaultProps, notePropTypes } from './notePropTypes.js';
import NoteReminderIconHandler from './noteReminders/noteReminderIcon';
import personNotesAction from './personNotesActions.js';
import {
    setMobileNextWingType,
    setReminderElementOpenFrom,
} from './noteReminders/noteReminderActions';
import personRecordUtils from './personRecordUtils.js';

const propTypes = {
    data: notePropTypes,
    loggedInUserPersonId: PropTypes.number.isRequired,
    reminderFormData: PropTypes.shape({}),
    selectedReminder: PropTypes.shape({}),
    setReminderElementOpenFrom: PropTypes.func.isRequired,
    setMobileNextWingType: PropTypes.func.isRequired,
};

const defaultProps = {
    data: noteDefaultProps,
    reminderFormData: undefined,
    selectedReminder: undefined,
};

const mapDispatchToProps = {
    setMobileNextWingType,
    setReminderElementOpenFrom,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userIdentity,
            },
        },
        people: {
            record: {
                noteForm: {
                    reminderFormData,
                    selectedReminder,
                },
            },
        },
    } = state;
    const loggedInUserPersonId = userIdentity.getPersonId();
    return {
        reminderFormData,
        selectedReminder,
        loggedInUserPersonId,
    };
};

function personNotesDrawerNoteDetails(props) {
    const {
        data,
        loggedInUserPersonId,
        reminderFormData,
        selectedReminder,
        setMobileNextWingType: setMobileNextWingTypeAction,
        setReminderElementOpenFrom: setReminderElementOpenFromAction,
    } = props;

    const {
        categoryId,
        churchEntity,
        createdBy,
        createdByEmail: email,
        createdByName: fullName,
        createdByAvatar: avatarUrl,
        ministry,
        isPinned,
        noteText,
        modifyDate,
        subject,
        taskName,
    } = data;

    const didLoggedInUserCreateTheNote = loggedInUserPersonId === createdBy;
    const reminderData = reminderFormData || selectedReminder;

    const onReminderIconClick = () => {
        if (selectedReminder) {
            setReminderElementOpenFromAction('openFromNoteDetailMobile');
            setMobileNextWingTypeAction('details');
            personNotesAction.setWingType('note_reminder_detail');
        }
    };

    const blockClassName = 'person_notes_drawer--note_details';

    return (
        <div className={blockClassName}>
            <div className={`${blockClassName}_header`}>
                <span className={`${blockClassName}_date`}>
                    {moment.unix(modifyDate).format('MM/DD/YY')}
                </span>

                {isPinned && (
                    <Icon
                        align="right"
                        className={`${blockClassName}_pinned_indicator`}
                        id={`${blockClassName}--pinned_indicator`}
                        color="highlight"
                        type="pin"
                    />
                )}
            </div>

            <Header
                className={`${blockClassName}_subject`}
                size="xsmall"
                weight="bold"
            >
                {_.upperFirst(subject)}
            </Header>

            <div
                className={`${blockClassName}_message`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: personRecordUtils.linkify(noteText),
                }}
            />

            <div className={`${blockClassName}_footer`}>
                <div className={`${blockClassName}_footer_meta_data`}>
                    <Image
                        className={`${blockClassName}_author_image`}
                        name={fullName}
                        size={44}
                        src={avatarUrl}
                        type="user"
                    />
                    <div className={`${blockClassName}_author`}>
                        <div className={`${blockClassName}_author_meta_data`}>
                            <span className={`${blockClassName}_author_name`}>
                                {fullName}
                            </span>
                            <span className={`${blockClassName}_author_email`}>
                                {email}
                            </span>
                        </div>
                    </div>
                </div>
                {(categoryId === 2 || categoryId === 6) && !_.isEmpty(ministry) && !_.isEmpty(churchEntity) && (
                    <div className={`${blockClassName}_ministry_info`}>
                        <span className={`${blockClassName}_ministry_location_name`}>
                            {`${ministry} | ${churchEntity}`}
                        </span>
                    </div>
                )}
                {(categoryId === 2 || categoryId === 7) && !_.isEmpty(taskName) && (
                    <div className={`${blockClassName}_task`}>
                        <span className={`${blockClassName}_task_name`}>
                            {taskName}
                        </span>
                    </div>
                )}
                {(didLoggedInUserCreateTheNote && selectedReminder) && (
                    <NoteReminderIconHandler
                        onClick={onReminderIconClick}
                        renderedFrom="noteDetailMobile"
                        reminderData={reminderData}
                        isMobile
                    />
                )}
            </div>
        </div>
    );
}

personNotesDrawerNoteDetails.propTypes = propTypes;
personNotesDrawerNoteDetails.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(personNotesDrawerNoteDetails);
