export default {
    STEP_HISTORY: Symbol('STEP_HISTORY'),
    STEP_FILE_PREVIEW: Symbol('STEP_FILE_PREVIEW'),
    STEP_VALIDATION_PROGRESS: Symbol('STEP_VALIDATION_PROGRESS'),
    STEP_VALIDATION_FAILED: Symbol('STEP_VALIDATION_FAILED'),
    STEP_PROCESS_FILE: Symbol('STEP_PROCESS_FILE'),
    STEP_PARSING_RESULTS: Symbol('STEP_PARSING_RESULTS'),
    STEP_IMPORT_FINALIZATION: Symbol('STEP_IMPORT_FINALIZATION'),
    STEP_IMPORT_COMPLETE: Symbol('STEP_IMPORT_COMPLETE'),
};
