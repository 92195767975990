import { Loader } from '@saddlebackchurch/react-cm-ui';
import { isFunction } from 'lodash';
import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

const propTypes = {
    active: PropTypes.bool,
    canLoadMore: PropTypes.bool,
    children: PropTypes.element,
    className: PropTypes.string,
    containment: PropTypes.shape({
        getBoundingClientRect: PropTypes.func,
    }),
    delayedCall: PropTypes.bool,
    intervalDelay: PropTypes.number,
    offset: PropTypes.shape({
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
        top: PropTypes.number,
    }),
    onChange: PropTypes.func,
    partialVisibility: PropTypes.bool,
    showLoader: PropTypes.bool,
};

const defaultProps = {
    active: true,
    canLoadMore: true,
    children: null,
    className: null,
    containment: null,
    delayedCall: false,
    intervalDelay: 100,
    offset: null,
    onChange: null,
    partialVisibility: false,
    showLoader: true,
};

export const LOAD_MORE_TRIGGER_HEIGHT = 94;

class LoadMoreTrigger extends React.Component {
    constructor() {
        super();
        this.startWatching = this.startWatching.bind(this);
        this.stopWatching = this.stopWatching.bind(this);
        this.check = this.check.bind(this);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            isVisible: false,
            visibilityRect: {},
        };
    }

    componentDidMount() {
        const {
            active,
        } = this.props;

        // eslint-disable-next-line react/no-find-dom-node
        this.node = ReactDOM.findDOMNode(this);

        if (active) {
            this.startWatching();
        }
    }

    componentDidUpdate(prevProps) {
        const { props: nextProps } = this;

        if (!prevProps.active && nextProps.active) {
            this.setState(this.getInitialState());
            this.startWatching();
        } else if (!nextProps.active) {
            this.stopWatching();
        }
    }

    componentWillUnmount() {
        this.stopWatching();
    }

    getContainer() {
        const {
            containment,
        } = this.props;

        return containment || window;
    }

    // eslint-disable-next-line consistent-return
    check() {
        const {
            containment,
            offset,
            onChange,
            partialVisibility,
        } = this.props;

        const {
            isVisible,
        } = this.state;

        const el = this.node;
        let containmentRect;

        // if the component has rendered to null, don't update visibility
        if (!el) {
            return this.state;
        }

        const rect = el.getBoundingClientRect();

        if (containment) {
            containmentRect = containment.getBoundingClientRect();
        } else {
            containmentRect = {
                top: 0,
                left: 0,
                bottom:
                    window.innerHeight || document.documentElement.clientHeight,
                right: window.innerWidth || document.documentElement.clientWidth,
            };
        }

        // Check if visibility is wanted via offset?
        const newOffset = offset || {};

        containmentRect.top += newOffset.top || 0;
        containmentRect.left += newOffset.left || 0;
        containmentRect.bottom -= newOffset.bottom || 0;
        containmentRect.right -= newOffset.right || 0;

        let newIsVisible = false;

        if (partialVisibility) {
            newIsVisible = !(
                rect.right < containmentRect.left ||
                rect.left > containmentRect.right ||
                rect.bottom < containmentRect.top ||
                rect.top > containmentRect.bottom
            );
        } else {
            newIsVisible =
                rect.top >= containmentRect.top &&
                rect.left >= containmentRect.left &&
                rect.bottom <= containmentRect.bottom &&
                rect.right <= containmentRect.right;
        }
        // notify the parent when the value changed
        if (isVisible !== newIsVisible && isFunction(onChange)) {
            this.setState({
                isVisible: newIsVisible,
            });

            onChange(newIsVisible);
        }
    }

    startWatching() {
        const {
            delayedCall,
            intervalDelay,
        } = this.props;

        if (this.debounceCheck || this.interval) {
            return;
        }

        this.interval = setInterval(this.check, intervalDelay);

        // if dont need delayed call, check on load ( before the first interval fires )
        if (!delayedCall) {
            this.check();
        }
    }

    stopWatching() {
        if (this.debounceCheck) {
            // clean up event listeners and their debounce callers
            // eslint-disable-next-line no-restricted-syntax
            for (const debounceEvent in this.debounceCheck) {
                // eslint-disable-next-line no-prototype-builtins
                if (this.debounceCheck.hasOwnProperty(debounceEvent)) {
                    const debounceInfo = this.debounceCheck[debounceEvent];

                    clearTimeout(debounceInfo.getLastTimeout());
                    debounceInfo.target.removeEventListener(
                        debounceEvent,
                        debounceInfo.fn,
                    );

                    this.debounceCheck[debounceEvent] = null;
                }
            }
        }

        this.debounceCheck = null;

        if (this.interval) {
            this.interval = clearInterval(this.interval);
        }
    }

    render() {
        const {
            active,
            canLoadMore,
            children,
            className,
            showLoader,
        } = this.props;

        return (
            <div
                className={ClassName(
                    'load_more_trigger',
                    className,
                )}
                data-testid="load_more_trigger"
            >
                {(canLoadMore || active) &&
                    (showLoader ? <Loader fluid /> : children)}
            </div>
        );
    }
}

LoadMoreTrigger.propTypes = propTypes;
LoadMoreTrigger.defaultProps = defaultProps;

const loader = (WrappedComponent) => {
    function Container(props) {
        const { canLoadMore } = props;

        if (!canLoadMore) {
            return null;
        }

        return (
            <WrappedComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        );
    }

    Container.propTypes = propTypes;
    Container.defaultProps = defaultProps;

    return Container;
};

export default loader(LoadMoreTrigger);
