import request from 'axios';
import {
    appReduxStore,
} from '../../global/configureReduxStore.js';
import {
    MinistryMember,
} from '../../global/api/core/models';
import ApiUtils from '../../global/apiUtils.js';

class PersonActions extends ApiUtils {
    getMinistries: ({
        personId,
    }: {
        personId: number;
    }) => Promise<MinistryMember[]>;

    reset() {
        appReduxStore.dispatch({ type: 'PersonActions.RESET' });
    }
}

const actions = new PersonActions('/api/person/');

actions.buildReduxActionClass(
    { name: 'get', rq: request.get, route: '{personId}' },
    { name: 'getGroups', rq: request.get, route: '{personId}/groups' },
    { name: 'getMinistries', rq: request.get, route: '{personId}/ministry' },
    { name: 'getDuplicates', rq: request.get, route: '{personId}/duplicate' },
    { name: 'getSearchFields', rq: request.get, route: 'search-fields' },
);

export default actions;
