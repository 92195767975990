import {
    Select,
} from '@saddlebackchurch/react-cm-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from './i18nUtils.js';

const i18n = translationFactory('ConnectionCards.Global.EntryDrawerPersonalContent');

const propTypes = {
    className: PropTypes.string,
    disable: PropTypes.bool,
    id: PropTypes.string,
    onGenderChange: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.shape({}),
    value: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.string,
    ]),
};

const defaultProps = {
    className: undefined,
    disable: false,
    id: undefined,
    onGenderChange: undefined,
    required: false,
    style: undefined,
    value: undefined,
};

const OPTIONS = [
    {
        label: i18n('Female'),
        value: 'F',
    }, {
        label: i18n('Male'),
        value: 'M',
    },
];

function SelectGender(props) {
    const {
        className,
        disable,
        id,
        onGenderChange,
        required,
        style,
        value,
    } = props;

    return (
        <Select
            className={className}
            disable={disable}
            fluid
            id={id}
            label={i18n('Gender')}
            onChange={onGenderChange}
            options={OPTIONS}
            placeholder={i18n('Select')}
            required={required}
            searchable
            style={style}
            value={value}
        />
    );
}

SelectGender.propTypes = propTypes;
SelectGender.defaultProps = defaultProps;

export default SelectGender;
