import {
    setFactIsSaving,
    setFactNeedsSaving,
    transformEventFactGroups,
    updateFactValue,
    updateSavedFact,
} from './dataCaptureReducerTransforms.js';
import LoginConstants from '../js/constants/Login/LoginConstants';

const DEFAULT_STATE = {
    eventFactGroup: null,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
        case 'DataCaptureActions.RESET': // reset when we're told too (e.g. on a consuming component's unmount)
            return DEFAULT_STATE;

        case 'DataCaptureActions.ON_GETFACTS':
            return { ...state, eventFactGroup: transformEventFactGroups(action.result)[0] };

        case 'DataCaptureActions.ON_BEFORE_CREATEFACT':
        case 'DataCaptureActions.ON_BEFORE_UPDATEFACT':
        {
            const {
                bodyParams: { venueId },
                callbackParams: { factId },
            } = action;

            const { eventFactGroup } = state;

            return { ...state, eventFactGroup: setFactIsSaving(eventFactGroup, venueId, factId) };
        }

        case 'DataCaptureActions.ON_CREATEFACT':
        case 'DataCaptureActions.ON_UPDATEFACT':
        {
            const {
                bodyParams: { venueId },
                callbackParams: { factId: oldFactId },
                result: { id: newFactId },
            } = action;

            const { eventFactGroup } = state;

            return { ...state, eventFactGroup: updateSavedFact(eventFactGroup, venueId, oldFactId, newFactId) };
        }

        case 'DataCaptureActions.ON_UPDATE_FACT_VALUE':
        {
            const { factId, value, venueId } = action;
            const { eventFactGroup } = state;

            return { ...state, eventFactGroup: updateFactValue(eventFactGroup, venueId, factId, value) };
        }

        case 'DataCaptureActions.ON_FACT_NEEDS_SAVING':
        {
            const { factId, venueId } = action;
            const { eventFactGroup } = state;

            return { ...state, eventFactGroup: setFactNeedsSaving(eventFactGroup, venueId, factId) };
        }
    }

    return state;
}
