export const DEFAULT_STATE = {
    worshipServiceCategory: null,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'WorshipServicesActions.ON_CLEARSERVICECATEGORY':
            return { worshipServiceCategory: null };

        case 'WorshipServicesActions.ON_GETSERVICECATEGORY':
            return { worshipServiceCategory: action.result };
    }

    return state;
}
