const DEFAULT_STATE = {
    isFetching: false,
    question: {},
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'ConnectionQuestionDetailsActions.ON_BEFORE_GET':
            return {
                ...state,
                isFetching: true,
            };

        case 'ConnectionQuestionDetailsActions.ON_GET':
            return {
                ...state,
                isFetching: false,
                question: actionResult,
            };

        case 'ConnectionQuestionDetailsActions.ON_SETAUTOREPLYEMAILCONTENT':
        case 'ConnectionQuestionEditorActions.ON_UPDATE':
        case 'ConnectionQuestionEditorActions.ON_TOGGLETOACTIVE':
        case 'ConnectionQuestionEditorActions.ON_TOGGLETOINACTIVE':
            return {
                ...state,
                question: {
                    ...state.question,
                    ...actionResult,
                },
            };

        case 'ConnectionQuestionDetailsActions.RESET':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};
