import PropTypes from 'prop-types';

export const personAddressesPropTypesShape = PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    regionCode: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    countryAlpha2: PropTypes.string,
    countryAlpha3: PropTypes.string,
    id: PropTypes.number,
    personId: PropTypes.number,
    title: PropTypes.string,
    isPrimary: PropTypes.bool,
    isValidated: PropTypes.bool,
    isBadContact: PropTypes.bool,
});

export const personEmergencyContactPropTypesShape = PropTypes.shape({
    emergencyContactAge: PropTypes.number,
    emergencyContactBirthDate: PropTypes.number,
    emergencyContactId: PropTypes.number,
    emergencyContactEmails: PropTypes.arrayOf(PropTypes.shape({})),
    emergencyContactMembershipStatus: PropTypes.string,
    emergencyContactMembershipStatusId: PropTypes.number,
    emergencyContactPreference: PropTypes.shape({}),
    emergencyContactPerson: PropTypes.shape({
        avatar: PropTypes.string,
        churchEntityId: PropTypes.number,
        prefix: PropTypes.string,
        deceasedDate: PropTypes.number,
        email: PropTypes.string,
        emailVerifyDate: PropTypes.number,
        firstName: PropTypes.string,
        id: PropTypes.number,
        isDeceased: PropTypes.bool,
        isEmailVerified: PropTypes.bool,
        lastName: PropTypes.string,
        middleName: PropTypes.string,
        nickName: PropTypes.string,
        suffix: PropTypes.string,
        uniqueId: PropTypes.number,
    }),
    emergencyContactPhones: PropTypes.arrayOf(PropTypes.shape({})),
    emergencyContactRelationshipName: PropTypes.string,
    emergencyContactRelationship: PropTypes.shape({}),
    isPrimary: PropTypes.bool,
    personId: PropTypes.number,
    title: PropTypes.string,
});

export const personEmailsPropTypesShape = PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
    isBadContact: PropTypes.bool,
    isPrimary: PropTypes.bool,
    isPublic: PropTypes.bool,
    isValidated: PropTypes.bool,
    personId: PropTypes.number,
    title: PropTypes.string,
});

export const personOccupationsPropTypesShape = PropTypes.shape({
    category: PropTypes.string,
    categoryId: PropTypes.number,
    company: PropTypes.string,
    departmentChurchEntityId: PropTypes.number,
    departmentChurchEntityName: PropTypes.string,
    departmentId: PropTypes.Number,
    departmentName: PropTypes.string,
    id: PropTypes.number,
    isPrimary: PropTypes.bool,
    isStaff: PropTypes.bool,
    personId: PropTypes.number,
    title: PropTypes.string,
    typeId: PropTypes.number,
    type: PropTypes.string,
});

export const personPhonesPropTypesShape = PropTypes.shape({
    countryCode: PropTypes.string,
    displayPhoneNumber: PropTypes.string,
    extension: PropTypes.string,
    id: PropTypes.number,
    isBadContact: PropTypes.bool,
    isPrimary: PropTypes.bool,
    isPublic: PropTypes.bool,
    isValidated: PropTypes.bool,
    phoneNumber: PropTypes.string,
    phoneType: PropTypes.string,
    phoneTypeId: PropTypes.number,
    personId: PropTypes.number,
    title: PropTypes.string,
});

export const personPropTypes = PropTypes.shape({
    addresses: PropTypes.arrayOf(personAddressesPropTypesShape),
    allergies: PropTypes.string,
    birthDate: PropTypes.number,
    churchEntityId: PropTypes.number,
    churchEntityKnown: PropTypes.bool,
    churchEntityName: PropTypes.string,
    contactPreferences: PropTypes.shape({
        doNotContact: PropTypes.bool,
        doNotEmail: PropTypes.bool,
        doNotMail: PropTypes.bool,
        doNotPhone: PropTypes.bool,
        doNotText: PropTypes.bool,
        id: PropTypes.number,
        personId: PropTypes.number,
        preferredMethod: PropTypes.string,
    }),
    deceasedDate: PropTypes.number,
    departmentChurchEntityId: PropTypes.number,
    departmentChurchEntityName: PropTypes.string,
    departmentId: PropTypes.number,
    departmentName: PropTypes.string,
    disengagementReason: PropTypes.string,
    emails: PropTypes.arrayOf(personEmailsPropTypesShape),
    emergencyContacts: PropTypes.arrayOf(personEmergencyContactPropTypesShape),
    firstContact: PropTypes.number,
    firstName: PropTypes.string,
    gender: PropTypes.string,
    gradeLevel: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    maritalStatus: PropTypes.string,
    maritalStatusId: PropTypes.number,
    membershipStatus: PropTypes.string,
    membershipStatusId: PropTypes.number,
    middleName: PropTypes.string,
    milestones: PropTypes.arrayOf(
        PropTypes.shape({ // TODO: find out what the map looks like here.
            foo: PropTypes.string,
        }),
    ),
    modifiedDate: PropTypes.number,
    nickName: PropTypes.string,
    occupations: PropTypes.arrayOf(personOccupationsPropTypesShape),
    phones: PropTypes.arrayOf(personPhonesPropTypesShape),
    preferredService: PropTypes.shape({
        accommodations: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        advertiseOnWeb: PropTypes.bool,
        allowSingleOccurrenceRegistration: PropTypes.bool,
        capacity: PropTypes.string,
        canRegister: PropTypes.bool,
        changeableSequenceIds: PropTypes.shape({}),
        churchEntityId: PropTypes.number,
        description: PropTypes.string,
        eventCategoryId: PropTypes.number,
        eventCategoryName: PropTypes.string,
        eventContacts: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        hostMinistryId: PropTypes.number,
        id: PropTypes.number,
        isPaid: PropTypes.bool,
        maxCapacityUnpublish: PropTypes.bool,
        name: PropTypes.string,
        occurence: PropTypes.shape({}),
        relatedEvents: PropTypes.shape({}),
        recurring: PropTypes.bool,
        schedule: PropTypes.shape({}),
        scheduleId: PropTypes.number,
        subVenues: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        status: PropTypes.string,
        tagIds: PropTypes.arrayOf(PropTypes.number),
        venues: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        webHeader: PropTypes.string,
        worshipServiceCategoryId: PropTypes.number,
    }),
    preferredServiceEventId: PropTypes.number,
    prefix: PropTypes.string,
    profilePhotoUrl: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    suffix: PropTypes.string,
});

export const personDefaultProps = {
    addresses: [],
    allergies: '',
    birthDate: null,
    churchEntityId: null,
    churchEntityKnown: false,
    churchEntityName: '',
    contactPreferences: {},
    deceasedDate: null,
    departmentChurchEntityId: null,
    departmentChurchEntityName: '',
    departmentId: null,
    departmentName: '',
    disengagementReason: '',
    emails: [],
    emergencyContacts: [],
    firstContact: null,
    gender: '',
    gradeLevel: '',
    maritalStatus: '',
    maritalStatusId: null,
    membershipStatus: '',
    membershipStatusId: null,
    middleName: '',
    milestones: [],
    modifiedDate: null,
    nickName: '',
    occupations: [],
    phones: [],
    preferredServiceEventId: null,
    prefix: '',
    profilePhotoUrl: '',
    profilePictureUrl: '',
    suffix: '',
};
