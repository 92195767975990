export const DEFAULT_STATE = {};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventAttendeeActions.ON_GETFILTEREDSTATISTICS':
            return action.result;

        case 'EventAttendeeActions.ON_CLEARSTATISTICS':
            return DEFAULT_STATE;
    }

    return state;
}
