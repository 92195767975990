enum DigitalProgramButtonType {
    Announcement = 'Announcement',
    ConnectionFormTemplate = 'ConnectionFormTemplate',
    Link = 'Link',
    GivingDesignation = 'GivingDesignation',
    Journey = 'Journey',
    SermonOutline = 'SermonOutline',
}

export default DigitalProgramButtonType;
