import {
    isEmpty,
} from 'lodash';
import { i18n } from '../../global/constants.js';

export const GRADE_OPTIONS = [
    {
        label: i18n('None'),
        value: 'None',
    }, {
        label: i18n('PreK'),
        value: 'PreK',
    }, {
        label: i18n('Kindergarten'),
        value: 'Kindergarten',
    }, {
        label: i18n('Grade 1'),
        value: 'First',
    }, {
        label: i18n('Grade 2'),
        value: 'Second',
    }, {
        label: i18n('Grade 3'),
        value: 'Third',
    }, {
        label: i18n('Grade 4'),
        value: 'Fourth',
    }, {
        label: i18n('Grade 5'),
        value: 'Fifth',
    }, {
        label: i18n('Grade 6'),
        value: 'Sixth',
    }, {
        label: i18n('Grade 7'),
        value: 'Seventh',
    }, {
        label: i18n('Grade 8'),
        value: 'Eighth',
    }, {
        label: i18n('Grade 9'),
        value: 'Ninth',
    }, {
        label: i18n('Grade 10'),
        value: 'Tenth',
    }, {
        label: i18n('Grade 11'),
        value: 'Eleventh',
    }, {
        label: i18n('Grade 12'),
        value: 'Twelfth',
    },
];

export const getGradeOptions = (churchEntity) => {
    const {
        gradeSchoolYear,
    } = churchEntity;

    return [
        {
            label: i18n('None'),
            value: 'None',
        }, {
            label: i18n(`PreK${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'PreK',
        }, {
            label: i18n(`Kindergarten${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Kindergarten',
        }, {
            label: i18n(`Grade 1${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'First',
        }, {
            label: i18n(`Grade 2${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Second',
        }, {
            label: i18n(`Grade 3${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Third',
        }, {
            label: i18n(`Grade 4${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Fourth',
        }, {
            label: i18n(`Grade 5${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Fifth',
        }, {
            label: i18n(`Grade 6${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Sixth',
        }, {
            label: i18n(`Grade 7${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Seventh',
        }, {
            label: i18n(`Grade 8${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Eighth',
        }, {
            label: i18n(`Grade 9${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Ninth',
        }, {
            label: i18n(`Grade 10${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Tenth',
        }, {
            label: i18n(`Grade 11${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Eleventh',
        }, {
            label: i18n(`Grade 12${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
            value: 'Twelfth',
        },
    ];
};

export const MIN_RELEVANCE_THRESHOLD = Object.freeze({
    best: 1.2,
    good: 0.8,
    average: 0.5,
});

export const PATH_PEOPLE_SEARCH_OR_ADD = '/people/search-or-add';

export const PHONE_TYPE_ID = Object.freeze({
    cell: 3,
    home: 1,
    work: 2,
});

export const TITLE_PEOPLE_SEARCH_OR_ADD = i18n('Search or Add');

export const PEOPLE_SETTINGS_PATH = '/people/settings';
export const PEOPLE_SETTINGS_TITLE = i18n('Settings and Preferences');
