import { Campus } from '../models/campus.model';
import IChurchCampusStore from '../churchCampusStore.interface';
import ChurchCampusStore from '../churchCampusStore';

export const DEFAULT_STATE: IChurchCampusStore = new ChurchCampusStore();

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ChurchStructureActions.ON_GETCAMPUSES':
            return new ChurchCampusStore(action.result as Campus[]);

        case 'ChurchStructureActions.ON_RESET_CAMPUSES':
            return DEFAULT_STATE;

        case 'ChurchStructureActions.ON_SET_CAMPUSES':
            return new ChurchCampusStore(action.campuses as Campus[]);

        default:
            return state;
    }
};
