import {
    ActivityIndicator,
    Box,
    Timeline,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
    isNil,
    map,
} from 'lodash';
import ClassNames from 'classnames';
import React from 'react';
import {
    ResponseStatus,
} from '../../../global/models';
import {
    BEM_NAME,
    TABS_CONTENT_BEM_NAME,
} from '../constants';
import {
    getInsightsPersonEnagement,
} from '../global/personRecord.actions.js';
import {
    i18n,
} from '../../../global/constants.js';
import ErrorStatus from '../../../global/errorStatus';
import InfiniteScroll from '../../../global/infiniteScroll';
import InsightsPersonEngagement from '../global/models/insightsPersonEngagement.model';
import { InsightsPersonEngagementCategoryName } from '../global/models';
import NoResults from '../../../global/noResults';
import TimelineItem from './timelineItem';

type PropTypes = {
    blockSize: number | null;
    personId: number;
};

export const ENGAGEMENT_CLASS_NAME = `${BEM_NAME}--${TABS_CONTENT_BEM_NAME}_engagement`;

function TabsContentEngagement({
    blockSize,
    personId,
}: PropTypes) {
    const [hasResults, setHasResults] = React.useState<boolean | null>(null);
    const [responseStatus, setResponseStatus] = React.useState<ResponseStatus>(null);
    const [results, setResults] = React.useState<InsightsPersonEngagement[]>([]);

    const isResponseStatusNil = isNil(responseStatus);

    const rootClasses = ClassNames(
        `${BEM_NAME}--${TABS_CONTENT_BEM_NAME}`,
        ENGAGEMENT_CLASS_NAME,
    );

    let containerHeight;

    if (blockSize >= 768 && blockSize < 1018) {
        containerHeight = 'calc(100vh - 140px)'; // action bar height + details window height
    } else if (blockSize >= 1018) {
        containerHeight = 'calc(100vh - 394px)'; // app bar height + action bar height + details window height + content margin top
    } else {
        containerHeight = 'calc(100vh - 375px)'; // app bar height + action bar height + details window height + content margin top
    }

    let timelineItemGlobalIndex = 0;

    const fetchData = async ({
        pageNumber,
        pageSize,
    }) => {
        const response = await getInsightsPersonEnagement({
            pageNumber,
            pageSize,
            personUniqueId: personId,
        });

        const newResults = response.data.data.filter((r: InsightsPersonEngagement) => !(
            r.categoryName === InsightsPersonEngagementCategoryName.PersonRecord &&
                r.title.toLowerCase().includes('logged into')
        ));

        setHasResults(!isEmpty(newResults));
        setResponseStatus(response.status);

        return {
            results: newResults,
            total: response.data.total,
        };
    };

    const onNewDataAvailable = ({
        results: newResults,
    }) => {
        setResults(newResults);
    };

    return (
        <div
            className={rootClasses}
            data-testid={ENGAGEMENT_CLASS_NAME}
        >
            <InfiniteScroll
                chunkSize={20}
                fetchData={fetchData}
                pageSize={50}
                onNewDataAvailable={onNewDataAvailable}
            >
                {isResponseStatusNil ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        height={containerHeight}
                        justifyContent="center"
                    >
                        <ActivityIndicator />
                    </Box>
                ) : (
                    <React.Fragment>
                        {responseStatus !== ResponseStatus.OK ? (
                            <ErrorStatus
                                status={responseStatus}
                                pt={5}
                            />
                        ) : (
                            <React.Fragment>
                                {hasResults === false ? (
                                    <NoResults
                                        caption={i18n('This person hasn\'t engaged with the church yet that we know of. Consider reaching out to welcome them!')}
                                        title={i18n('We Haven\'t Connected Yet')}
                                    />
                                ) : (
                                    <Timeline
                                        align="alternate"
                                    >
                                        {map(results, (item) => {
                                            timelineItemGlobalIndex += 1;

                                            return (
                                                <TimelineItem
                                                    categoryName={item.categoryName}
                                                    dateUtc={item.dateUtc}
                                                    eventName={item.eventName}
                                                    key={`timline_item-${timelineItemGlobalIndex}`}
                                                    ministryName={item.ministryName}
                                                    servingOpportunityName={
                                                        item.servingOpportunityName
                                                    }
                                                    title={item.title}
                                                />
                                            );
                                        })}
                                    </Timeline>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </InfiniteScroll>
        </div>
    );
}

export default TabsContentEngagement;
