import React from 'react';
import request from 'axios';
import {
    isEmpty,
    isFunction,
    isNil,
    uniq,
} from 'lodash';
import AppDispatcher from '../js/dispatcher/AppDispatcher.js';
import BannerUtils from '../js/utils/BannerUtils.js';
import apiConfig from './api/apiConfig';
import { appReduxStore } from './configureReduxStore.js';
import { i18n } from './constants.js';
import Utils, { cmAuthUtils } from './utils/utils.js';

export default class ApiUtils {
    constructor(url, service = 'Core') {
        this.baseUrl = apiConfig.get(service) + url;
        this.dispatchDelay = 0; // To simulate network latency, ms
    }

    baseBuildClass(dispatch, isRedux, ...calls) {
        for (let i = 0; i < calls.length; i += 1) {
            const {
                contentType,
                name,
                progress,
                route = '',
                rq,
                suppressError,
            } = calls[i];

            // Add own ON_BEFORE_XXX and ON_XXX constants
            const callName = name.toUpperCase();
            const className = this.constructor.name;
            const actionType = `ON_${callName}`;
            const actionName = `${className}.${actionType}`;
            const preActionType = `ON_BEFORE_${callName}`;
            const preActionName = `${className}.${preActionType}`;
            const failedActionType = `ON_FAILED_${callName}`;
            const failedActionName = `${className}.${failedActionType}`;

            Object.defineProperty(this, actionType, {
                value: actionName,
                writable: false,
                enumerable: false,
                configurable: false,
            });

            Object.defineProperty(this, preActionType, {
                value: preActionName,
                writable: false,
                enumerable: false,
                configurable: false,
            });

            Object.defineProperty(this, failedActionType, {
                value: failedActionName,
                writable: false,
                enumerable: false,
                configurable: false,
            });

            // Add a method for api call
            this.constructor.prototype[name] = ( // eslint-disable-line no-loop-func
                params,
                bodyParams,
                cancel,
                callbackParams,
            ) => {
                // 1) Setup headers
                const headers = {
                    'X-Referrer': window.location.href, /* this is for audit logging of certain kinds of creates and updates */
                };

                if (params && params.sourceOverride) { /* this is also for audit logging */
                    headers['X-Source'] = params.sourceOverride; // specify the source header
                    // eslint-disable-next-line no-param-reassign, max-len
                    delete params.sourceOverride; // then remove it so it does not become a query string parameter
                }

                // 2) Build api's url
                const url = Utils.buildUrl(this.baseUrl + route, params);

                // 3) Build arguments for request call
                const args = [url];

                if (rq === request.post || rq === request.put) {
                    // Axios bug/feature?
                    // If bodyParams eq null/undefined, axios will not send 'Content-Type'.
                    args.push(bodyParams || {});
                }

                // `CmAuthUtils` is in global scope (from `authentication.js`)
                // eslint-disable-next-line no-undef
                const accessToken = cmAuthUtils.getAccessToken();

                if (!isNil(accessToken)) {
                    headers.Authorization = `Bearer ${accessToken}`;
                }

                if (contentType) {
                    headers['Content-Type'] = contentType;
                }

                // 4) Add a cancel token
                const cancelSource = request.CancelToken.source();

                // Pass the cancel token to the callback
                if (isFunction(cancel)) {
                    cancel(cancelSource);
                }

                // 5) Build a config
                const config = {
                    headers,
                    cancelToken: cancelSource.token,
                };

                if (progress) {
                    config.onUploadProgress = progress;
                }

                args.push(config);

                // 6) Dispatch Pre/"On Before" Action
                // (This is Redux ONLY for now ... Flux sucks too badly to handle it)
                if (isRedux) {
                    dispatch({
                        actionType: this[preActionType],
                        type: this[preActionType],
                        params,
                        bodyParams,
                        callbackParams,
                    });
                }
                // 7) Make the HTTP Request and return the Promise
                const result = new Promise((resolve, reject) => {
                    rq(...args)
                        .then((res) => {
                            const doDispatch = () => {
                                dispatch({
                                    actionType: this[actionType],
                                    type: this[actionType],
                                    result: res.data,
                                    params,
                                    bodyParams,
                                    callbackParams,
                                });

                                resolve(res.data);
                            };

                            if (this.dispatchDelay > 0) {
                                setTimeout(doDispatch, this.dispatchDelay);
                            } else {
                                doDispatch();
                            }

                            // Reset the cancel token
                            if (isFunction(cancel)) {
                                cancel(null);
                            }
                        })
                        .catch((res) => {
                            const { response } = res;
                            /**
                             * res doesn't include response when inspecting it,
                             * response is available only when destructuring as we do above
                             * so we need to parse/create a new error object for the promise rejection.
                             */
                            const fullResponse = {
                                ...JSON.parse(JSON.stringify(res)),
                                response,
                            };

                            // Dispatch "On Failed" Action
                            if (isRedux) {
                                dispatch({
                                    actionType: this[failedActionType],
                                    type: this[failedActionType],
                                    params,
                                    bodyParams,
                                    callbackParams,
                                });
                            }

                            if ((response && response.status === 401) || !suppressError) {
                                ApiUtils.handleError(res);
                            }

                            reject(fullResponse);

                            // Reset the cancel token
                            if (isFunction(cancel)) {
                                cancel(null);
                            }
                        });
                });

                // The additional error handler to avoid 'Uncaught (in promise) Error'
                result.catch(() => {});

                return result;
            };
        }
    }

    buildClass(...calls) {
        const dispatch = (...args) => AppDispatcher.dispatch(...args);
        this.baseBuildClass(dispatch, false, ...calls);
    }

    buildReduxActionClass(...calls) {
        const dispatch = (...args) => appReduxStore.dispatch(...args);
        this.baseBuildClass(dispatch, true, ...calls);
    }

    static handleClientError(msg, timeout, delay) {
        ApiUtils.handleError(
            {
                response: {
                    status: 400,
                    data: { system: msg },
                },
            },
            timeout,
            delay,
        );
    }

    static handleError(error, timeout, delay, bannerClassName) {
        const { response } = error;

        if (!isNil(response)) {
            if (response.status === 401) { // 401 Unauthorized (with really means "unauthenticated", i.e. access token is invalid or has expired)
                // If access_token has expired, then redirect user to login
                console.log('Recv\'d 401 Unauthorized response from API'); // eslint-disable-line no-console

                // `CmAuthUtils` is in global scope (from `authentication.js`)
                // eslint-disable-next-line no-undef
                cmAuthUtils.logout();
            } else if (response.status === 403) { // 403 Forbidden (which means "not authorized", i.e.  does not have permission)
                // Do a Redux dispatch that will redirect user to Sad Dog Page
                appReduxStore.dispatch({ type: 'ON_403_FORBIDDEN_RECVD' });
            } else {
                // Handle remaining 400s and 500s
                const responseLevel = Math.floor(response.status / 100);

                // If we have a response level
                if (responseLevel > 0) {
                    let errorTitle = i18n('Application Error');
                    let responseMessage = null;
                    let validationErrorItemsJsx = null;
                    let cmd = false;

                    const {
                        data: responseData,
                    } = response;

                    if (!isNil(responseData)) {
                        const {
                            command: responseDataCommand,
                            detail: responseErrorMessage_DetailField, // eslint-disable-line @typescript-eslint/naming-convention
                            errors: responseDataErrors, // this is expected to be an ASP.NET model state dictionary
                            import: responseDataImport,
                            message: responseErrorMessage_MsgField, // eslint-disable-line @typescript-eslint/naming-convention
                            system: responseDataSystem,
                        } = responseData;

                        const responseErrorMessage =
                            responseErrorMessage_DetailField ?? responseErrorMessage_MsgField;

                        const hasErrorMessage = !isEmpty(responseErrorMessage);
                        const hasErrorDictionary = !isNil(responseDataErrors);

                        const isModelValidationError = response.status === 400 &&
                            (hasErrorMessage || hasErrorDictionary);

                        if (isModelValidationError) {
                            errorTitle = i18n('Request is invalid:');
                        }

                        if (hasErrorMessage) {
                            const msg = responseErrorMessage;

                            if (msg.indexOf('|') > 0) { // pipe-delimited list of validation (model state) errors
                                const msgs = msg.split('|');
                                // eslint-disable-next-line react/jsx-filename-extension
                                validationErrorItemsJsx = uniq(msgs).map((m) => (<li key={`error-${Utils.getIncreasingUniqueKey()}`}>{m}</li>));
                            } else { // just a normal error message string
                                responseMessage = msg;
                            }
                        } else if (hasErrorDictionary) {
                            validationErrorItemsJsx = Object.values(responseDataErrors).flatMap((e) => (<li key={`error-${Utils.getIncreasingUniqueKey()}`}>{e}</li>));
                        } else if (responseDataCommand) {
                            // eslint-disable-next-line prefer-destructuring
                            responseMessage = responseDataCommand[0];
                            cmd = true;
                        } else if (responseDataImport) {
                            // eslint-disable-next-line prefer-destructuring
                            responseMessage = responseDataImport[0];
                            cmd = true;
                        } else if (responseDataSystem) {
                            responseMessage = responseDataSystem;
                            cmd = true;
                        }
                    }

                    const hasValidationErrorsList = !isNil(validationErrorItemsJsx);

                    const bannerClasses = hasValidationErrorsList ?
                        'no-margin-top' :
                        'no-margin-top no-margin-btm';

                    BannerUtils.addBanner({
                        title: errorTitle,
                        children: (
                            <div className="application-error">
                                <p className={bannerClasses}>
                                    {cmd ?
                                        `${i18n('Response')} : ${responseMessage}` :
                                        responseMessage}
                                </p>
                                {hasValidationErrorsList ? (
                                    <ul>
                                        {validationErrorItemsJsx}
                                    </ul>
                                ) : null}
                                {cmd ? null :
                                    (
                                        <p className="no-margin-btm no-margin-top text-small text-muted">
                                            {`${i18n('Status Code')}: ${response.status} ${response.statusText}`}
                                        </p>
                                    )}
                            </div>
                        ),
                        className: bannerClassName,
                        level: responseLevel === 5 ? 'error' : 'warning',
                        timeout: delay,
                        type: 'notification',
                    });
                } else {
                    console.log('We just got an error message from the API without a Status Code.'); // eslint-disable-line no-console
                }

                console.log(response); // eslint-disable-line no-console
            }
        } else if (error.toString() === 'Cancel') {
            console.log('Request successfully canceled.'); // eslint-disable-line no-console
        } else {
            console.log('Non-XHR error caught in APIUtils. Response was undefined.'); // eslint-disable-line no-console
            console.log(error); // eslint-disable-line no-console
        }
    }

    get agent() {
        const headers = {};
        const accessToken = cmAuthUtils.getAccessToken();

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        return request.create({
            baseURL: apiConfig.get(),
            headers,
        });
    }

    getAgent(service = 'Core') {
        const headers = {};
        const accessToken = cmAuthUtils.getAccessToken();

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        // Pass the service name to apiConfig.get()
        const baseURL = apiConfig.get(service);

        return request.create({
            baseURL,
            headers,
        });
    }
}
