import {
    find,
    forEach,
    isEmpty,
    isNil,
    isNull,
    isNumber,
    map,
    sortBy,
} from 'lodash';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { QuestionTypeId } from '../../../connectionQuestions/global/models';
import {
    DOES_NOT_ATTEND_CHURCH_ENTITY,
    UNKNOWN_CHURCH_ENTITY,
} from '../../../global/constants.js';
import { FORM_TYPE_WORSHIP_SERVICE } from '../listPage/constants.js';
import { PersonalInformationPanelField } from '../../models';
import { PHONE_TYPE_ID } from '../../../people/global/constants.js';
import {
    QUESTION_ANSWER_TYPE_BOOLEAN,
    QUESTION_ANSWER_TYPE_DATETIME,
    QUESTION_ANSWER_TYPE_NUMERIC,
    QUESTION_ANSWER_TYPE_TEXT,
} from './constants.js';
import { setContactData } from '../../../people/global/contactFormActions.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import entryEditorActions from './entryEditor.actions.js';
import personalFormActions from '../../../people/global/personalFormActions.js';
import Utils from '../../../global/utils/utils.js';

export const getAnswerType = (question) => {
    if (isEmpty(question)) {
        return null;
    }

    switch (question.questionTypeId) {
        case QuestionTypeId.SingleLineText:
        case QuestionTypeId.ParagraphText:
            return QUESTION_ANSWER_TYPE_TEXT;

        case QuestionTypeId.Date:
            return QUESTION_ANSWER_TYPE_DATETIME;

        case QuestionTypeId.MultipleChoice:
        case QuestionTypeId.DropDown:
            return QUESTION_ANSWER_TYPE_NUMERIC; // using `numericValue` field to store the ID of the selected Answer Choice.  Retarded, but that's the way it is.  Sigh.

        case QuestionTypeId.Checkbox:
        case QuestionTypeId.Agreement:
            return QUESTION_ANSWER_TYPE_BOOLEAN;

        default:
            return null;
    }
};

export const isAnswerRedacted = (answer) => (
    isNull(answer?.textValue) &&
    isNull(answer?.numericValue) &&
    isNull(answer?.booleanValue) &&
    isNull(answer?.dateTimeValue)
);

export const doesSectionHaveRedactedResponse = (section, answers) => {
    const actualQuestions = [];

    section.fields?.forEach((f) => {
        if (f.questionId) {
            actualQuestions.push(f.questionId);
        } else if (f.question?.isContainer) {
            f.question.customFields?.forEach((cf) => {
                if (cf.id) {
                    actualQuestions.push(cf.id);
                }
            });
        }
    });

    section.containers?.forEach((container) => {
        container.fields?.forEach((f) => {
            if (f.questionId) {
                actualQuestions.push(f.questionId);
            } else if (f.question?.isContainer) {
                f.question.customFields?.forEach((cf) => {
                    if (cf.id) {
                        actualQuestions.push(cf.id);
                    }
                });
            }
        });
    });

    const actualAnswers = answers?.filter((v) => actualQuestions.includes(v.questionId));

    return actualAnswers?.some((answer) => isAnswerRedacted(answer));
};

export const doesSectionHaveSensitiveQuestion = (section) => {
    const actualQuestions = [];

    section.fields?.forEach((f) => {
        if (f.questionId) {
            actualQuestions.push(f.question);
        } else if (f.question?.isContainer) {
            f.question.customFields?.forEach((cf) => {
                if (cf.id) {
                    actualQuestions.push(cf);
                }
            });
        }
    });

    section.containers?.forEach((container) => {
        container.fields?.forEach((f) => {
            if (f.questionId) {
                actualQuestions.push(f.question);
            } else if (f.question?.isContainer) {
                f.question.customFields?.forEach((cf) => {
                    if (cf.id) {
                        actualQuestions.push(cf);
                    }
                });
            }
        });
    });

    return actualQuestions?.some((question) => question.isSensitive);
};

export const modifySectionToRender = (sections) => {
    const newSections = map(sections, (section) => {
        let containerFields = [];

        if (!isEmpty(section)) {
            if (!isEmpty(section.containers)) {
                containerFields = map(section.containers, (container) => {
                    const customFields = map(container.fields, (field) => ({
                        ...field.question,
                        id: field.questionId,
                        isRequired: field.isRequired,
                        label: field.title,
                        order: field.order,
                    }));

                    return {
                        connectionFormTemplateSectionId: section.id,
                        connectionFormTemplateContainerId: container.id,
                        connectionFormTemplateId: section.connectionFormTemplateId,
                        order: container.order,
                        title: container.title,
                        question: {
                            choices: null,
                            customFields,
                            connectionFormTemplateContainerId:
                                container.id,
                            isContainer: true,
                            name: container.title,
                            questionTypeId: QuestionTypeId.Checkbox,
                            sectionId: container.connectionFormTemplateSectionId,
                            title: container.title,
                        },
                    };
                });
            }
        }

        const sectionFields = section?.fields?.map((field) => ({
            ...field,
            question: {
                ...field.question,
                questionTypeId: field.agreement ?
                    QuestionTypeId.Agreement : field.question.questionTypeId,
                title: field.title,
            },
        })) || [];

        const combineFields = [
            ...containerFields,
            ...sectionFields,
        ];

        const sortByOrderCombineFields = sortBy(combineFields, (item) => item.order);

        return {
            id: section.id,
            name: section.name,
            order: section.order,
            containers: null,
            fields: sortByOrderCombineFields,
        };
    });

    return newSections;
};

export const setEventWorshipServiceOrOccurence = (eventData) => {
    const {
        eventId,
        form: {
            type: formType,
        },
        occurrence,
    } = eventData;

    if (!isNil(eventId) &&
        !isNil(occurrence)) {
        const {
            date,
            duration,
            id: occurrenceId,
            scheduleId,
            startTime,
            timeZone,
        } = occurrence;

        if (formType === FORM_TYPE_WORSHIP_SERVICE) {
            const worshipService = {
                date,
                duration,
                eventId,
                occurrenceId,
                scheduleId,
                startTime,
                timeZone,
            };

            entryEditorActions.setWorshipService(worshipService);
        } else { // formType === FORM_TYPE_REGULAR
            const eventOccurrence = {
                id: eventId,
                occurrence: {
                    date,
                    duration,
                    id: occurrenceId,
                    startTime,
                    timeZone,
                },
                scheduleId,
            };

            entryEditorActions.setEventOccurrence(eventOccurrence);
        }
    }
};

export const setPersonalContactInfoForEditEntry = (personalInfo, countries) => {
    personalFormActions.updatePersonalData({
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        gender: personalInfo?.gender,

        // eslint-disable-next-line no-nested-ternary
        campus: !isNil(personalInfo.churchEntityId) ?
            personalInfo.churchEntityId :
            personalInfo.churchEntityKnown ?
                DOES_NOT_ATTEND_CHURCH_ENTITY :
                UNKNOWN_CHURCH_ENTITY,
    });

    let countryCode = 'US';
    let displayPhoneNumber = '';
    let extension = null;
    let isHome = false;
    let isMobile = false;
    let isValid = false;
    let isWork = false;
    let phoneNumber = null;
    let phoneType = 'Home Phone';
    let phoneTypeId = PHONE_TYPE_ID.home;

    if (!isNil(personalInfo.formattedHomePhone) &&
        isValidPhoneNumber(personalInfo.homePhone)
    ) {
        isHome = true;
        isValid = personalInfo.formattedHomePhone.isValid;
        countryCode = personalInfo.formattedHomePhone.countryCode;
        displayPhoneNumber = personalInfo.formattedHomePhone.displayPhoneNumber;
        extension = personalInfo.formattedHomePhone.extension;
        phoneNumber = personalInfo.homePhone;
    } else if (!isNil(personalInfo.formattedCellPhone) &&
        isValidPhoneNumber(personalInfo.cellPhone)
    ) {
        isMobile = true;
        isValid = personalInfo.formattedCellPhone.isValid;
        countryCode = personalInfo.formattedCellPhone.countryCode;
        displayPhoneNumber = personalInfo.formattedCellPhone.displayPhoneNumber;
        extension = personalInfo.formattedCellPhone.extension;
        phoneNumber = personalInfo.cellPhone;
        phoneTypeId = PHONE_TYPE_ID.cell;
        phoneType = 'Cell Phone';
    } else if (!isNil(personalInfo.formattedWorkPhone) &&
        isValidPhoneNumber(personalInfo.workPhone)
    ) {
        isWork = true;
        isValid = personalInfo.formattedWorkPhone.isValid;
        countryCode = personalInfo.formattedWorkPhone.countryCode;
        displayPhoneNumber = personalInfo.formattedWorkPhone.displayPhoneNumber;
        extension = personalInfo.formattedWorkPhone.extension;
        phoneNumber = personalInfo.workPhone;
        phoneTypeId = PHONE_TYPE_ID.work;
        phoneType = 'Work Phone';
    }

    const personCountryInfo = find(
        countries, (item) => item.shortName === personalInfo?.country,
    );

    setContactData({
        email: personalInfo.email,
        phone: {
            isHome,
            isMobile,
            isValid,
            isWork,
            countryCode: countryCode || 'US',
            displayPhoneNumber,
            extension,
            phoneNumber,
            phoneType,
            phoneTypeId,
        },
        address: {
            address1: personalInfo.address1,
            address2: personalInfo.address2,
            city: personalInfo.city,
            postalCode: personalInfo.postalCode,
            country: personCountryInfo?.longName || 'United States of America',
            countryAlpha2: personCountryInfo?.code || 'US',
            countryAlpha3: personalInfo.country,
            regionCode: personalInfo.region,
        },
    });
};

export const setPersonalContactInfoForCreateEntry = (personalInfo) => {
    personalFormActions.updatePersonalData({
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        gender: personalInfo.gender,

        // eslint-disable-next-line no-nested-ternary
        campus: !isNil(personalInfo.churchEntityId) ?
            personalInfo.churchEntityId :
            personalInfo.churchEntityKnown ?
                DOES_NOT_ATTEND_CHURCH_ENTITY :
                UNKNOWN_CHURCH_ENTITY,
    });

    const primaryAddress = find(personalInfo.addresses, (item) => item.isPrimary);
    const primaryEmail = find(personalInfo.emails, (item) => item.isPrimary);
    const primaryPhone = find(personalInfo.phones, (item) => item.isPrimary);

    let countryCode = 'US';
    let displayPhoneNumber = '';
    let extension = null;
    let isHome = true;
    let isMobile = false;
    let isValid = false;
    let isWork = false;
    let phoneNumber = null;
    let phoneType = 'Home Phone';
    let phoneTypeId = PHONE_TYPE_ID.home;

    if (!isEmpty(primaryPhone) &&
        !isEmpty(primaryPhone.phoneNumber) &&
        isValidPhoneNumber(primaryPhone.phoneNumber)
    ) {
        if (primaryPhone.phoneTypeId === PHONE_TYPE_ID.home) {
            isHome = true;
            isMobile = false;
            isWork = false;
        } else if (primaryPhone.phoneTypeId === PHONE_TYPE_ID.cell) {
            isMobile = true;
            isHome = false;
            isWork = false;
        } else {
            isWork = true;
            isHome = false;
            isMobile = false;
        }

        isValid = true;
        countryCode = primaryPhone.countryCode;
        displayPhoneNumber = primaryPhone.displayPhoneNumber;
        extension = primaryPhone.extension;
        phoneNumber = primaryPhone.phoneNumber;
        phoneTypeId = primaryPhone.phoneTypeId;
        phoneType = primaryPhone.phoneType;
    }

    setContactData({
        email: primaryEmail?.email,
        phone: {
            isHome,
            isMobile,
            isValid,
            isWork,
            countryCode,
            displayPhoneNumber,
            extension,
            phoneNumber,
            phoneType,
            phoneTypeId,
        },
        address: {
            address1: primaryAddress?.address1,
            address2: primaryAddress?.address2,
            city: primaryAddress?.city,
            postalCode: primaryAddress?.postalCode,
            country: primaryAddress?.country,
            countryAlpha2: primaryAddress?.countryAlpha2,
            countryAlpha3: primaryAddress?.countryAlpha3,
            region: primaryAddress?.region,
            regionCode: primaryAddress?.regionCode,
        },
    });
};

export const areFormAgreementsValid = (form, agreements) => {
    let canSaveAgreements = true;

    // eslint-disable-next-line consistent-return
    forEach(form.sections, (section) => {
        // eslint-disable-next-line consistent-return
        forEach(section?.fields, (field) => {
            if (field.isRequired && !isEmpty(field.agreement)) {
                const existingAgreement = agreements.find(
                    (v) => v.agreementId === field.agreement.id,
                );

                if (isEmpty(existingAgreement) || !existingAgreement.agreementConsented) {
                    canSaveAgreements = false;
                    return false;
                }
            }
        });

        if (!canSaveAgreements) {
            return false;
        }
    });

    return canSaveAgreements;
};

export const areFormAnswersValid = (form, answers) => {
    let canSaveAnswers = true;

    // eslint-disable-next-line consistent-return
    forEach(form.sections, (section) => {
        if (!isEmpty(section)) {
            if (!isEmpty(section.fields)) {
                // eslint-disable-next-line consistent-return
                forEach(section.fields, (field) => {
                    if (isEmpty(field.agreement)) {
                        if (field.isRequired && !field.connectionFormTemplateContainerId) {
                            const answerInfo = find(
                                answers,
                                (item) => item.questionId === field.questionId,
                            );

                            const answerType = getAnswerType(
                                field.question,
                            );

                            if (isEmpty(answerInfo)) {
                                canSaveAnswers = false;
                                return false;
                            }

                            const isRedacted = isNull(answerInfo[`${answerType}`]);

                            if (!isRedacted && !answerInfo[`${answerType}`]) {
                                canSaveAnswers = false;
                                return false;
                            }
                        } else if (field.connectionFormTemplateContainerId) {
                            // eslint-disable-next-line consistent-return
                            forEach(field.question.customFields, (cf) => {
                                if (cf.isRequired) {
                                    const answerInfo = find(
                                        answers,
                                        (item) => item.questionId === cf.id,
                                    );

                                    const answerType = getAnswerType(cf);

                                    if (isEmpty(answerInfo)) {
                                        canSaveAnswers = false;
                                        return false;
                                    }

                                    const isRedacted = isNull(answerInfo[`${answerType}`]);

                                    if (!isRedacted && !answerInfo[`${answerType}`]) {
                                        canSaveAnswers = false;
                                        return false;
                                    }
                                }
                            });
                        }
                    }
                });
            }
        }

        if (!canSaveAnswers) {
            return false;
        }
    });

    return canSaveAnswers;
};

export const isPersonalFormDataValid = (form, personalFormData) => {
    const {
        firstName,
        lastName,
        gender,
        campus,
    } = personalFormData;

    const hasFirstName = !Utils.isStringNullOrWhiteSpace(firstName);
    const hasLastName = !Utils.isStringNullOrWhiteSpace(lastName);
    const hasGender = !Utils.isStringNullOrWhiteSpace(gender);
    const hasCampus = campus > 0 || campus === DOES_NOT_ATTEND_CHURCH_ENTITY;

    let canSavePersonalForm = true;

    if (!isEmpty(form.personalPanelRequiredFields)) {
        forEach(form.personalPanelRequiredFields, (requiredField) => { /* We want to bail out early as soon as we discover a problem; hence the need to suppress `consistent-return` */ // eslint-disable-line consistent-return
            switch (requiredField) {
                case PersonalInformationPanelField.FirstName:
                    canSavePersonalForm = canSavePersonalForm && hasFirstName;
                    break;

                case PersonalInformationPanelField.LastName:
                    canSavePersonalForm = canSavePersonalForm && hasLastName;
                    break;

                case PersonalInformationPanelField.Gender:
                    canSavePersonalForm = canSavePersonalForm && hasGender;
                    break;

                case PersonalInformationPanelField.Campus:
                    canSavePersonalForm = canSavePersonalForm && hasCampus;
                    break;

                default:
                    break;
            }

            if (!canSavePersonalForm) {
                return false;
            }
        });
    }

    return canSavePersonalForm;
};

export const doesDataHavePersonalInformation = (personalFormData) => {
    const {
        firstName,
        lastName,
        gender,
        campus,
    } = personalFormData;

    const hasFirstName = !Utils.isStringNullOrWhiteSpace(firstName);
    const hasLastName = !Utils.isStringNullOrWhiteSpace(lastName);
    const hasGender = !Utils.isStringNullOrWhiteSpace(gender);
    const hasCampus = campus > 0 || campus === DOES_NOT_ATTEND_CHURCH_ENTITY;

    return hasFirstName || hasLastName || hasGender || hasCampus;
};

export const isContactFormDataValid = (form, contactFormData) => {
    const {
        address: {
            address1: address1Value,
            address2: address2Value,
            city: cityValue,
            countryAlpha3: countryAlpha3Value,
            isAddressValid,
            postalCode: postalCodeValue,
            regionCode: regionCodeValue,
        },
        email: {
            isEmailValid,
            value: emailValue,
        },
        phone: {
            phoneNumber: phoneNumberValue,
            isPhoneValid,
        },
    } = contactFormData;

    const hasEmail = !Utils.isStringNullOrWhiteSpace(emailValue);
    const hasPhone = !Utils.isStringNullOrWhiteSpace(phoneNumberValue);
    const hasErrorOnEmail = hasEmail && !isEmailValid;
    const hasErrorOnPhone = hasPhone && !isPhoneValid;

    const hasAddressValues = !!cityValue ||
        !!postalCodeValue ||
        !!address1Value ||
        (!!countryAlpha3Value && countryAlpha3Value !== 'USA') || // Since USA is a default value here, that value does not automatically signify that the user has entered any address data on the form
        !!regionCodeValue;

    const hasErrorOnAddress = hasAddressValues && !isAddressValid;
    const hasContactFormError = hasErrorOnEmail || hasErrorOnPhone || hasErrorOnAddress;

    let canSaveContactForm = !hasContactFormError;

    if (canSaveContactForm && !isEmpty(form.personalPanelRequiredFields)) {
        forEach(form.personalPanelRequiredFields, (requiredField) => { /* We want to bail out early as soon as we discover a problem; hence the need to suppress `consistent-return` */ // eslint-disable-line consistent-return
            switch (requiredField) {
                case PersonalInformationPanelField.Email:
                    canSaveContactForm = canSaveContactForm && hasEmail && isEmailValid;
                    break;

                case PersonalInformationPanelField.SinglePhoneNumberWithSpecifiedType:
                    canSaveContactForm = canSaveContactForm && hasPhone && isPhoneValid;
                    break;

                case PersonalInformationPanelField.Address1:
                    canSaveContactForm = canSaveContactForm &&
                        !Utils.isStringNullOrWhiteSpace(address1Value);
                    break;

                case PersonalInformationPanelField.Address2:
                    canSaveContactForm = canSaveContactForm &&
                        !Utils.isStringNullOrWhiteSpace(address2Value);
                    break;

                case PersonalInformationPanelField.City:
                    canSaveContactForm = canSaveContactForm &&
                        !Utils.isStringNullOrWhiteSpace(cityValue);
                    break;

                case PersonalInformationPanelField.Region:
                    canSaveContactForm = canSaveContactForm &&
                        !Utils.isStringNullOrWhiteSpace(regionCodeValue);
                    break;

                case PersonalInformationPanelField.PostalCode:
                    canSaveContactForm = canSaveContactForm &&
                        !Utils.isStringNullOrWhiteSpace(postalCodeValue);
                    break;

                case PersonalInformationPanelField.Country:
                    canSaveContactForm = canSaveContactForm &&
                        !Utils.isStringNullOrWhiteSpace(countryAlpha3Value);
                    break;

                default:
                    break;
            }

            if (!canSaveContactForm) {
                return false;
            }
        });
    }

    return canSaveContactForm;
};

export const doesDataHaveContactInformation = (contactFormData) => {
    const {
        address: {
            address1: address1Value,
            city: cityValue,
            countryAlpha3: countryAlpha3Value,
            postalCode: postalCodeValue,
            regionCode: regionCodeValue,
        },
        email: {
            value: emailValue,
        },
        phone: {
            phoneNumber: phoneNumberValue,
        },
    } = contactFormData;

    const hasEmail = !Utils.isStringNullOrWhiteSpace(emailValue);
    const hasPhone = !Utils.isStringNullOrWhiteSpace(phoneNumberValue);

    const hasAddressValues = !!cityValue ||
        !!postalCodeValue ||
        !!address1Value ||
        (!!countryAlpha3Value && countryAlpha3Value !== 'USA') || // Since USA is a default value here, that value does not automatically signify that the user has entered any address data on the form
        !!regionCodeValue;

    return hasEmail || hasPhone || hasAddressValues;
};

export const canUserReadSensitiveConnectionQuestionAnswers = (userAccess, entryChurchEntityId) => (
    // null entryChurchEntityId is valid for isAuthorizedForCampus()
    isNull(entryChurchEntityId) || isNumber(entryChurchEntityId) ?
        userAccess.isAuthorizedForCampus(
            entryChurchEntityId,
            USER_PERMISSIONS.readSensitiveConnectionQuestionAnswers,
        ) :
        userAccess.hasPermission(
            USER_PERMISSIONS.readSensitiveConnectionQuestionAnswers,
        )
);
