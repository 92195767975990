import request from 'axios';
import apiConfig from '../../global/api/apiConfig';
import {
    cmAuthUtils,
} from '../../global/utils/utils.js';

const coreApiHost = apiConfig.get();

// eslint-disable-next-line import/prefer-default-export
export const getAll = () => () => new Promise((resolve, reject) => {
    request
        .get(`${coreApiHost}/api/promotion/release-notes`, {
            headers: {
                Authorization: `Bearer ${cmAuthUtils.getAccessToken()}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            resolve(response);
        })
        .catch(({ response }) => {
            reject(response);
        });
});
