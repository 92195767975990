const DEFAULT_STATE = {
    teamMembers: {},
    teamMembersCount: null,
    teamMembersIsFetching: false,
};

const TasksReassignDrawerReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'TasksReassignDrawerActions.ON_BEFORE_GETTEAMMEMBERS':
            return {
                ...state,
                teamMembersIsFetching: true,
            };

        case 'TasksReassignDrawerActions.ON_GETTEAMMEMBERS':
            return {
                ...state,
                teamMembersIsFetching: false,
                teamMembersCount: action.result.resultCount,
                teamMembers: action.result.results,
            };

        case 'TasksReassignDrawerActions.ON_BEFORE_GETALLMEMBERS':
            return {
                ...state,
                teamMembersIsFetching: true,
            };

        case 'TasksReassignDrawerActions.ON_GETALLMEMBERS':
            return {
                ...state,
                teamMembersIsFetching: false,
                teamMembersCount: action.result.count,
                teamMembers: action.result.users,
            };

        case 'TasksReassignDrawerActions.ON_RESETTEAMMEMBERS':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default TasksReassignDrawerReducer;
