import PropTypes from 'prop-types';
import { i18n } from '../../../global/constants.js';
import { BEM_CONNECTION_FORMS } from '../../constants.js';

export const BEM_BLOCK_NAME = `${BEM_CONNECTION_FORMS}_list_page`; // connection_forms_list_page

export const listPagePropTypes = {
    campusesCache: PropTypes.shape({}).isRequired,
    campusOptions: PropTypes.arrayOf(PropTypes.shape({})),
    filterOptions: PropTypes.shape({
        searchQuery: PropTypes.string,
    }).isRequired,
    isMobile: PropTypes.bool,
    onRowClick: PropTypes.func,
    searchResults: PropTypes.shape({}),
};

export const listPageDefaultProps = {
    consumer: 'connectionForms',
    campusOptions: null,
    onRowClick: null,
    searchResults: null,
};

export const filtersPropTypes = {
    campusOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    categoryCount: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    searchFilter: PropTypes.shape({
        areFiltersDirty: PropTypes.bool.isRequired,
        filterOptions: PropTypes.shape({
            campus: PropTypes.number,
            sortBy: PropTypes.shape({}).isRequired,
            statusActive: PropTypes.bool,
            statusInactive: PropTypes.bool,
            typeRegular: PropTypes.bool,
            typeWorship: PropTypes.bool,
        }).isRequired,
    }).isRequired,
    statusActiveCount: PropTypes.number.isRequired,
    statusInactiveCount: PropTypes.number.isRequired,
    typeRegularCount: PropTypes.number.isRequired,
    typeWorshipCount: PropTypes.number.isRequired,
};

export const sortOptions = [
    {
        label: i18n('Name (A > Z)'),
        value: 'name,asc',
    }, {
        label: i18n('Name (Z > A)'),
        value: 'name,desc',
    }, {
        label: i18n('Creation Date (Asc)'),
        value: 'createdate,asc',
    }, {
        label: i18n('Creation Date (Desc)'),
        value: 'createdate,desc',
    }, {
        label: i18n('Last Entry Date (Asc)'),
        value: 'lastentrydate,asc',
    }, {
        label: i18n('Last Entry Date (Desc)'),
        value: 'lastentrydate,desc',
    }, {
        label: i18n('Modify Date (Asc)'),
        value: 'updatedate,asc',
    }, {
        label: i18n('Modify Date (Desc)'),
        value: 'updatedate,desc',
    }, {
        label: i18n('Published (Asc)'),
        value: 'published,asc',
    }, {
        label: i18n('Published (Desc)'),
        value: 'published,desc',
    },
];
