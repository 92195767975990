import { isNil } from 'lodash';
import { Currency } from '../../../global/models';
import { GiftForEditor } from './models';
import { PaymentMethodType } from '../../public/shared/models';
import ActionType from './giftEditor.actionTypes';

export const DEFAULT_STATE: GiftForEditor = {
    amount: {
        amount: null,
        currency: Currency.USD,
    },
    channelId: null,
    designationCampusId: null,
    designationId: null,
    giverPersonUniqueId: null,
    giverFirstName: null,
    giverLastName: null,
    id: null,
    paymentDateMoment: null,
    paymentDetails: null,
    paymentMethod: PaymentMethodType.Card,
};

export default (
    state: GiftForEditor | undefined,
    action: any,
): GiftForEditor => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.RESET: {
                return DEFAULT_STATE;
            }

            case ActionType.SET_CHANNEL_ID: {
                const { value: channelId } = action;

                return {
                    ...state,
                    channelId,
                };
            }

            case ActionType.SET_DESIGNATION_CAMPUS_ID: {
                const { value: designationCampusId } = action;

                return {
                    ...state,
                    designationCampusId,
                };
            }

            case ActionType.SET_DESIGNATION_ID: {
                const { value: designationId } = action;

                return {
                    ...state,
                    designationId,
                };
            }

            case ActionType.SET_GIFT_AMOUNT: {
                const { value: amount } = action;

                return {
                    ...state,
                    amount: {
                        ...state.amount,
                        amount,
                    },
                };
            }

            case ActionType.SET_GIFT_EDITOR_DATA: {
                const { value: data } = action;

                return {
                    ...state,
                    ...data,
                };
            }

            case ActionType.SET_GIFT_PAYMENT_DATE: {
                const { value: paymentDateMoment } = action;

                return {
                    ...state,
                    paymentDateMoment,
                };
            }

            case ActionType.SET_GIVER_PAYMENT_FIRST_NAME: {
                const { value: giverFirstName } = action;

                return {
                    ...state,
                    giverFirstName,
                };
            }

            case ActionType.SET_GIVER_PAYMENT_LAST_NAME: {
                const { value: giverLastName } = action;

                return {
                    ...state,
                    giverLastName,
                };
            }

            case ActionType.SET_GIVER_PAYMENT_METHOD_TYPE: {
                const { value: paymentMethod } = action;

                return {
                    ...state,
                    paymentMethod,
                };
            }

            case ActionType.SET_GIVER_PERSON_UNIQUE_ID: {
                const { value: giverPersonUniqueId } = action;

                return {
                    ...state,
                    giverPersonUniqueId,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
