import '../../images/bck-desktop.png';
import '../../images/bck-search-mobile.png';

import {
    isEmpty,
    noop,
} from 'lodash';
import {
    Drawer,
    Page,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
    withStyles,
} from '@saddlebackchurch/react-cm-ui/core/styles';
import { connect } from 'react-redux';
import { i18n } from '../../global/constants.js';
import { BEM_BLOCK_NAME } from './searchConstants';
import {
    clearSearchTerm,
    reset as resetSearchFilters,
    updateSearchTerm,
} from './peopleSearchFilters.actions.js';
import {
    setBlockType,
    setBlockSize,
} from './searchBlockTypeManagerActions.js';
import {
    getPeopleSearchFormattedQuery,
    isSearchQueryValid,
} from './global/queryUtils/queryUtils.js';
import { isEnterKeyCode } from '../../global/utils/utils.js';
import { USER_PERMISSIONS } from '../../global/userPermissionConstants.js';
import AddPersonRecordDrawer from '../record/global/addPersonRecordDrawer.jsx';
import addPersonRecordActions from '../record/global/addPersonRecordActions.js';
import personActions from '../actions_old/person.actions';
import Modal from '../../js/components/Modal.react.js';
import { formatPersonResults } from '../global/utils.js';
import helpActions from '../../help/help.actions.js';
import PersonActionBar from './personActionBar.jsx';
import PersonSearchHelpModal from './global/helpModal.jsx';
import PersonListTableView from './global/personListTableView.jsx';
import PersonSearchFilters from './peopleSearchFilters.jsx';
import personSearchActions from './global/personSearch.actions';
import { PERSON_SEARCH_MAX_RESULTS_LENGTH } from './global/constants.js';
import ChunkedPagination from '../../global/chunkedPaginationUtils.js';
import UserAccessStore from '../../global/userAccessStore.js';
import StyledActivityIndicator from '../../global/styledActivityIndicator';

const propTypes = {
    appliedFilters: PropTypes.shape({
        deceased: PropTypes.bool,
        sort: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    }).isRequired,
    blockSize: PropTypes.number,
    blockType: PropTypes.string,
    classes: PropTypes.shape({
        drawerContent: PropTypes.string,
        root: PropTypes.string,
        bckSearchImage: PropTypes.string,
        bckSearchMobileImage: PropTypes.string,
        desktopBckContainer: PropTypes.string,
        mobileBckContainer: PropTypes.string,
        personSearchContainer: PropTypes.string,
        noResultsContainer: PropTypes.string,
    }),
    clearSearchTerm: PropTypes.func,
    isDrawerChild: PropTypes.bool,
    isFiltering: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    onDrawerClose: PropTypes.func,
    resetSearchFilters: PropTypes.func,
    searchFields: PropTypes.arrayOf(PropTypes.string),
    searchTerm: PropTypes.string,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
    updateSearchTerm: PropTypes.func,
    winWidth: PropTypes.number.isRequired,
};

const defaultProps = {
    blockSize: null,
    blockType: 'page',
    classes: {},
    clearSearchTerm: undefined,
    isDrawerChild: false,
    isFiltering: false,
    onDrawerClose: noop,
    resetSearchFilters: undefined,
    searchFields: [],
    searchTerm: '',
    updateSearchTerm: undefined,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        breakpoint: {
            isMobile,
            winWidth,
        },
        person: {
            searchFields,
        },
        people: {
            searchV2: {
                index: {
                    blockSize,
                    blockType,
                },
                searchFilters: {
                    appliedFilters,
                    isFiltering,
                    searchTerm,
                },
            },
        },
    } = state;

    return {
        appliedFilters,
        blockSize,
        blockType,
        isFiltering,
        isMobile,
        searchFields,
        searchTerm,
        userAccess,
        winWidth,
    };
};

const mapDispatchToProps = {
    clearSearchTerm,
    resetSearchFilters,
    updateSearchTerm,
};

const styles = ({ breakpoints, palette, spacing }) => ({
    drawerContent: {
        padding: 0,
    },
    root: {
        [`& .${BEM_BLOCK_NAME}--search_not_initiated`]: {
            backgroundColor: palette.background.primary,
        },
        [`& .${BEM_BLOCK_NAME}--content_page`]: {
            minHeight: 'calc(100vh - 70px)',
        },
        [`& .${BEM_BLOCK_NAME}--content_page_initiated`]: {
            padding: '0',
        },
        [`& .${BEM_BLOCK_NAME}--content_drawer_search_not_initiated`]: {
            minHeight: 'calc(100vh - 140px)',
            '& .drawer-container-inner': {
                minHeight: '100%',
            },
        },
    },
    personSearchContainer: {
        backgroundColor: palette.background.primary,
    },
    bckSearchImage: {
        display: 'inline-block',
        height: 'auto',
        left: '50%',
        maxWidth: '100%',
        padding: '0',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        verticalAlign: 'top',
        [breakpoints.down(768)]: {
            position: 'fixed',
        },
    },
    noResultsContainer: {
        width: '100%',
        padding: `${spacing(3)}px 0`,
        textAlign: 'center',
    },
});

const pagedPersons = new ChunkedPagination(25, 100);

export class PeopleSearch extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            canLoadMore: true,
            first: true,
            isAddPersonDrawerOpen: false,
            isFetching: false,
            isFiltersOpen: false,
            isSearchInitiated: false,
            page: 0,
            persons: [],
            total: 0,
        };

        this.onAddPersonDrawerToggle = this.onAddPersonDrawerToggle.bind(this);
        this.onClearSearchTerms = this.onClearSearchTerms.bind(this);
        this.fetchMore = this.fetchMore.bind(this);
        this.onSearchHelpModalOpen = this.onSearchHelpModalOpen.bind(this);
        this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
        this.onSearchTermChange = this.onSearchTermChange.bind(this);
        this.search = this.search.bind(this);
        this.onToggleFilters = this.onToggleFilters.bind(this);
    }

    componentDidMount() {
        const {
            isDrawerChild,
            searchTerm,
        } = this.props;

        const {
            isSearchInitiated,
        } = this.state;

        helpActions.getFilteredHelpArticles({
            module: 'People',
            name: 'Search',
        });

        personActions.getSearchFields();
        setBlockType(isDrawerChild ? 'drawer' : 'page');
        setBlockSize(document.querySelector(`.${BEM_BLOCK_NAME}`));

        if (!isSearchInitiated) {
            const pageContentDOMEL = document.querySelector('.page-content');
            if (!isEmpty(pageContentDOMEL)) {
                pageContentDOMEL.style.maxHeight = 'calc(100vh - 140px)';
            }
        }

        if (!isEmpty(searchTerm)) {
            this.setState({
                canLoadMore: true,
                first: true,
                isFetching: true,
                isSearchInitiated: true,
                page: 0,
                persons: [],
                total: 0,
            }, () => {
                this.search(true);
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            appliedFilters: prevAppliedFilters,
            winWidth: prevWinWidth,
        } = prevProps;

        const {
            appliedFilters: nextAppliedFilters,
            winWidth: nextWinWidth,
            searchTerm,
        } = this.props;

        if (prevWinWidth !== nextWinWidth) {
            setBlockSize(document.querySelector(`.${BEM_BLOCK_NAME}`));
        }

        if (prevAppliedFilters !== nextAppliedFilters && !isEmpty(searchTerm)) {
            this.setState({
                canLoadMore: true,
                first: true,
                isFetching: true,
                isSearchInitiated: true,
                page: 0,
                persons: [],
                total: 0,
            }, () => {
                this.search(true);
            });
        }
    }

    onAddPersonDrawerToggle(recordType) {
        this.setState((prevState) => {
            const isOpen = !prevState.isAddPersonDrawerOpen;

            return {
                isAddPersonDrawerOpen: isOpen,
            };
        });

        addPersonRecordActions.updateRecordType(recordType);
    }

    onClearSearchTerms() {
        const {
            clearSearchTerm: clearSearchTermAction,
        } = this.props;

        this.setState({
            first: false,
            canLoadMore: false,
        }, () => {
            clearSearchTermAction();
        });
    }

    onSearchHelpModalOpen() {
        // eslint-disable-next-line no-underscore-dangle
        this.searchHelpModalRef._openModal();
    }

    onSearchKeyDown(event) {
        const {
            searchTerm,
        } = this.props;

        if (isEnterKeyCode(event) && !isEmpty(searchTerm)) {
            this.setState({
                canLoadMore: true,
                first: true,
                isFetching: true,
                isSearchInitiated: true,
                page: 0,
                persons: [],
                total: 0,
            }, () => {
                window.scrollTo(0, 0);
                this.search(true);
            });
        }
    }

    onSearchTermChange(value) {
        const {
            updateSearchTerm: updateSearchTermAction,
        } = this.props;

        if (!value) {
            this.setState({
                first: false,
                canLoadMore: false,
            });
        }

        updateSearchTermAction(value);
    }

    onToggleFilters() {
        this.setState((prevState) => ({
            isFiltersOpen: !prevState.isFiltersOpen,
        }));
    }

    fetchMore() {
        const {
            first,
        } = this.state;

        if (pagedPersons.needsToLoadPage()) {
            this.setState({
                isFetching: true,
                page: first ? 0 : pagedPersons.getCurrentPageNumber() + 1,
            }, () => {
                this.search();
            });
        } else {
            const personsNextPage = pagedPersons.getAll(true);
            const canLoadMore = personsNextPage.length < PERSON_SEARCH_MAX_RESULTS_LENGTH &&
                pagedPersons.canLoadMore();

            this.setState({
                canLoadMore,
                persons: personsNextPage,
            });
        }
    }

    search() {
        const {
            appliedFilters,
            searchFields,
            searchTerm,
            userAccess,
        } = this.props;

        const {
            first,
            canLoadMore,
            page,
        } = this.state;

        if (!searchTerm) {
            this.setState({
                canLoadMore: false,
            });

            return;
        }

        const {
            deceased,
            sort: { value: sortQuery },
        } = appliedFilters;

        if (!isSearchQueryValid(searchTerm, searchFields)) {
            console.warn('Person search query was not valid.  Bailing out.  query:', searchTerm);
            return;
        }

        const params = {
            includeDeceasedPersons: deceased,
            pageNumber: first ? 0 : page,
            pageSize: pagedPersons.getPageSize(),
            q: searchTerm,
        };

        if (sortQuery) {
            params.sortFieldOrder = `${(sortQuery.slice(0, 1) === '-' ? 'desc' : 'asc')}`;
            params.sortField =
                `${(sortQuery.slice(0, 1) === '-' ? sortQuery.substr(1) : sortQuery)}`;
        }

        const query = getPeopleSearchFormattedQuery(params);

        if (first || canLoadMore) {
            personSearchActions.getPeople({
                query,
            }).then((personResponse) => {
                const {
                    resultCount,
                    results: personsResponse,
                } = personResponse;

                const hasCoreMilestonesPermissions = userAccess.hasPermission(
                    USER_PERMISSIONS.readPersonMilestones,
                );

                const personIds = personsResponse.map((person) => (person.id));

                if (!hasCoreMilestonesPermissions || personIds.length === 0) {
                    this.setState({ isFetching: false });
                    return;
                }

                personSearchActions.getCoreMilestonesByPersonIds({ personIds })
                    .then((milestonesResponse) => {
                        const personsWithMilestones = formatPersonResults(
                            personsResponse,
                            milestonesResponse,
                            { withMetaInfoText: false },
                        );

                        pagedPersons.loadPage(
                            personsWithMilestones,
                            resultCount,
                            first,
                        );

                        const personsNextPage = pagedPersons.getAll(true);

                        const reallyCanLoadMore = pagedPersons.canLoadMore() &&
                            personsNextPage.length < PERSON_SEARCH_MAX_RESULTS_LENGTH;

                        this.setState({
                            canLoadMore: reallyCanLoadMore,
                            first: false,
                            total: resultCount,
                            isFetching: false,
                            persons: personsNextPage,
                        });
                    });
            });
        }
    }

    render() {
        const {
            classes,
            isDrawerChild,
            isFiltering,
            isMobile,
            onDrawerClose,
            searchTerm,
            userAccess,
        } = this.props;

        const {
            canLoadMore,
            isAddPersonDrawerOpen,
            isFetching,
            isFiltersOpen,
            isSearchInitiated,
            persons,
            total,
        } = this.state;

        const containerClasses = ClassNames(
            classes.personSearchContainer,
            BEM_BLOCK_NAME,
            {
                [`ui ${BEM_BLOCK_NAME}--drawer`]: isDrawerChild,
                [`ui ${BEM_BLOCK_NAME}--search_not_initiated`]: !isSearchInitiated && !isMobile,
                [`ui ${BEM_BLOCK_NAME}--search_not_initiated_drawer`]:
                    !isSearchInitiated && !isMobile && isDrawerChild,
            },
        );

        const containerClassesByType = ClassNames(
            `${BEM_BLOCK_NAME}--container`,
            {
                [`${BEM_BLOCK_NAME}--container_drawer`]: isDrawerChild,
                [`${BEM_BLOCK_NAME}--container_page`]: !isDrawerChild,
            },
        );

        const contentClassesByType = ClassNames(
            `${BEM_BLOCK_NAME}--content`,
            {
                [classes.drawerContent]: isDrawerChild,
                [`${BEM_BLOCK_NAME}--content_page`]: !isDrawerChild,
                [`${BEM_BLOCK_NAME}--content_drawer_search_not_initiated`]:
                    !isSearchInitiated && isDrawerChild && !isMobile,
                [`${BEM_BLOCK_NAME}--content_page_initiated`]: isSearchInitiated && !isDrawerChild,
                [`${BEM_BLOCK_NAME}--content_page_initiated_rails_open`]:
                    isSearchInitiated && !isDrawerChild && isFiltersOpen,
            },
        );

        let Main = 'div';
        // eslint-disable-next-line prefer-destructuring
        let Container = Drawer.Container;
        // eslint-disable-next-line prefer-destructuring
        let Content = Drawer.Content;

        const containerProps = {
            className: containerClasses,
        };

        if (!isDrawerChild) {
            Main = Page;
            Container = Page.Container;
            Content = Page.Content;
        }

        const searchBckImageContainerJSX = (!isSearchInitiated && (
            <div className={classes.desktopBckContainer}>
                <img
                    alt={i18n('Person Search Background')}
                    className={classes.bckSearchImage}
                    src="../../images/bck-desktop.png"
                />
            </div>
        ));

        return (
            <div
                className={classes.root}
            >
                <Main
                    {...containerProps} // eslint-disable-line react/jsx-props-no-spreading
                >
                    <PersonActionBar
                        isDrawerChild={isDrawerChild}
                        isFiltering={isFiltering}
                        isFiltersOpen={isFiltersOpen}
                        isSearchInitiated={isSearchInitiated}
                        onAddPersonDrawerToggle={this.onAddPersonDrawerToggle}
                        onClearClick={this.onClearSearchTerms}
                        onSearchHelpModalOpen={this.onSearchHelpModalOpen}
                        onSearchKeyDown={this.onSearchKeyDown}
                        onSearchTermChange={this.onSearchTermChange}
                        onToggleFilters={this.onToggleFilters}
                        searchTerm={searchTerm}
                    />

                    <Container className={containerClassesByType}>
                        <PersonSearchFilters
                            isDrawerChild={isDrawerChild}
                            isOpen={isFiltersOpen}
                            onClose={this.onToggleFilters}
                        />

                        <Content
                            className={contentClassesByType}
                            isFiltersRailOpen={isFiltersOpen}
                        >
                            {searchBckImageContainerJSX}

                            {persons.length > 0 && (
                                <PersonListTableView
                                    canLoadMore={canLoadMore}
                                    fetchMore={this.fetchMore}
                                    isDrawerChild={isDrawerChild}
                                    isFetching={isFetching}
                                    isMobile={isMobile}
                                    onDrawerClose={onDrawerClose}
                                    persons={persons}
                                    total={total}
                                    userAccess={userAccess}
                                />
                            )}

                            {(isSearchInitiated && isFetching && persons.length === 0) && (
                                <StyledActivityIndicator />
                            )}

                            {(isSearchInitiated && !isFetching && persons.length === 0) && (
                                <div className={classes.noResultsContainer}>
                                    <Typography
                                        variant="body1"
                                    >
                                        {i18n('No results found')}
                                    </Typography>
                                </div>
                            )}
                        </Content>
                    </Container>
                </Main>

                <AddPersonRecordDrawer
                    isOpen={isAddPersonDrawerOpen}
                    onClose={this.onAddPersonDrawerToggle}
                />

                <Modal
                    maxWidth="880"
                    modalClass="person-search-help-modal"
                    ref={(ref) => { this.searchHelpModalRef = ref; }}
                    width="100%"
                >
                    <PersonSearchHelpModal />
                </Modal>
            </div>
        );
    }
}

PeopleSearch.propTypes = propTypes;
PeopleSearch.defaultProps = defaultProps;

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(PeopleSearch),
);
