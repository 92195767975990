import {
    cloneDeep,
    filter,
    forEach,
    includes,
    map,
    pull,
} from 'lodash';

/*
 * This 'store' encapsulates the state of Feature enablement throught the system.
 * It can be used by components to check whether certain Features are enabled or not.
 * It should also be used to toggle on or off any features that are 'preview' features
 * and enablement is subject to end-user preference.
 */
export default class FeatureToggleStore {
    // Private class fields!
    // Courtesty of:
    // https://www.sitepoint.com/javascript-private-class-fields/
    // https://github.com/tc39/proposal-private-methods
    // https://www.npmjs.com/package/@babel/plugin-proposal-class-properties
    /* eslint-disable lines-between-class-members */
    #systemFeatures = [];
    #previewFeatures = [];
    #enabledFeatureCache = [];
    /* eslint-enable lines-between-class-members */

    constructor(features, previewFeatures) {
        this.#systemFeatures = features || [];
        this.#previewFeatures = previewFeatures || [];
        this.#enabledFeatureCache = map(
            filter(features, (f) => f.enabled === true),
            (f) => f.name,
        );

        forEach(
            filter(previewFeatures, (pf) => pf.enabled === false),
            (pf) => {
                pull(this.#enabledFeatureCache, pf.name);
            },
        );
    }

    isFeatureEnabled(featureName) {
        return includes(this.#enabledFeatureCache, featureName);
    }

    getEnabledFeatureNames() {
        return [...this.#enabledFeatureCache]; // trying to enforce immutability
    }

    getPreviewFeatures() {
        return cloneDeep(this.#previewFeatures); // trying to enforce immutability
    }

    getSystemFeatures() {
        return cloneDeep(this.#systemFeatures); // trying to enforce immutability
    }
}
