enum ActionType {
    ClearFilterOptions = 'Journeys.CLEAR_FILTER_OPTIONS',
    ClearSearchQuery = 'Journeys.CLEAR_SEARCH_QUERY',
    SetAreFiltersDirty = 'Journeys.SET_ARE_FILTERS_DIRTY',
    SetFilterOptions = 'Journeys.SET_FILTER_OPTIONS',
    SetFormData = 'Journeys.SET_FORM_DATA',
    SetStepFormData = 'Journeys.SET_STEP_FORM_DATA',
    SetStepGroupFormData = 'Journeys.SET_STEP_GROUP_FORM_DATA',
    SetStepGroups = 'Journeys.SET_STEP_GROUPS',
    SetIsFiltering = 'Journeys.SET_IS_FILTERING',
    SetJourney = 'Journeys.SET_JOURNEY',
    SetJourneyList = 'Journeys.SET_JOURNEY_LIST',
    SetOwnerSearchQuery = 'Journeys.SET_OWNER_SEARCH_QUERY',
    SetOwnerSearchResults = 'Journeys.SET_OWNER_SEARCH_RESULTS',
    SetSearchQuery = 'Journeys.SET_SEARCH_QUERY',
    SetSearchResults = 'Journeys.SET_SEARCH_RESULTS',
    SetStepTypes = 'Journeys.SET_STEP_TYPES',
    SetSuggestedJourneys = 'Journeys.SET_SUGGESTED_JOURNEYS',
    SetDisableNavigation = 'NAVIGATION_DISABLE',
}

export default ActionType;
