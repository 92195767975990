import { combineReducers } from 'redux';
import campaigns from './campaigns/campaigns.reducers';
import designations from './designations/designations.reducers';
import givingActivity from './givingActivity/givingActivity.reducers';
import sharedAccounts from './sharedAccounts/sharedAccounts.reducers';

const reducers = combineReducers({
    campaigns,
    designations,
    givingActivity,
    sharedAccounts,
});

export default reducers;
