import _ from 'lodash';

export const DEFAULT_STATE = [];

export default function (state = DEFAULT_STATE, action) {
    if (action.type === 'AdminImportCurrentActions.ON_GETALL') {
        return _.concat([], action.result);
    }
    return state;
}
