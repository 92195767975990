import ClassNames from 'classnames';
import {
    Button,
    Drawer,
    Icon,
    Page,
} from '@saddlebackchurch/react-cm-ui';
import withStyles from '@saddlebackchurch/react-cm-ui/core/styles/withStyles';
import {
    find,
    isEmpty,
    isFunction,
    isNil,
    noop,
    toNumber,
} from 'lodash';
import { connect } from 'react-redux';
import {
    InjectedRouter,
    withRouter,
} from 'react-router';
import React from 'react';
import { BEM_BLOCK_NAME } from './recordConstants';
import { translationFactory } from '../../global/i18nUtils.js';
import { setIsNotesDrawerOpen as setIsNotesDrawerOpenAction } from './global/personRecord.actions.js';
import domUtils from '../../global/domUtils.js';
import personNotesActions from './global/personNotesActions.js';
import {
    Breakpoint,
    Email,
    Phone,
} from '../../global/models';

type PropTypes = {
    actionBar?: {
        md: (args: {}) => any[];
        sm: (args: {}) => any[];
    };
    actionBarControls: {
        md: any[];
        sm: any[];
        smControls: any[];
    };
    canReadPersonNotes?: boolean;
    noteFormStateChange: boolean;
    router: InjectedRouter;
    primaryPhone?: {
        phoneNumber: Phone;
    };
    personId?: number;
    params?: {
        personId: number;
    };
    primaryEmail?: Email;
    isNotesDrawerOpen?: boolean;
    setIsNotesDrawerOpen?: (isNotesDrawerOpen: boolean) => void;
    contactPreferences?: {
        doNotPhone?: boolean;
        doNotEmail?: boolean;
        doNotContact?: boolean;
    };
    fullName?: string;
    hasBackButton?: boolean;
    isBlWdMdUp?: boolean;
    /**
     * When true, the action bar will replace the controls with the content
     * in the prop actionBarControls, otherwise will merge actionBarControls with
     * the existent ones.
     */
    shouldOverrideActions: boolean;
    children?: JSX.Element;
    classes?: {
        root?: string;
    };
    isDrawerChild: boolean;
    winWidth: number;
    onClose?: () => void;
    isDeceased: boolean;
};

type StateTypes = {
    notesButtonColor?: string;
};

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            winWidth,
        },
        people: {
            record: {
                index: {
                    actionBar,
                    canReadPersonNotes,
                    isNotesDrawerOpen,
                },
                notes: {
                    noteFormStateChange,
                },
                person: {
                    data: dataPerson,
                    isFetching: isPersonalDataFetching,
                },
            },
        },
    } = state;
    const {
        contactPreferences = {},
        emails,
        firstName,
        lastName,
        phones,
        deceasedDate,
    } = dataPerson;

    return {
        actionBar,
        canReadPersonNotes,
        contactPreferences,
        fullName: `${firstName} ${lastName}`,
        isDeceased: !isNil(deceasedDate),
        isNotesDrawerOpen,
        isPersonalDataFetching,
        noteFormStateChange,
        primaryEmail: find(emails, 'isPrimary'),
        primaryPhone: find(phones, 'isPrimary'),
        winWidth,
    };
};

const mapDispatchToProps = {
    setIsNotesDrawerOpen: setIsNotesDrawerOpenAction,
};

const styles = () => ({
    root: {
        '& > div': {
            display: 'contents',
            '& .person_record--action_bar--children_container': {
                margin: '0 0 0 11px',
            },
        },
    },
});

const i18n = translationFactory();

export class ActionBar extends React.PureComponent<PropTypes, StateTypes> {
    constructor(props) {
        super(props);

        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onCallPersonClick = this.onCallPersonClick.bind(this);
        this.onEditPersonClick = this.onEditPersonClick.bind(this);
        this.onEmailPersonClick = this.onEmailPersonClick.bind(this);
        this.onNotesDrawerToggle = this.onNotesDrawerToggle.bind(this);

        this.state = {
            notesButtonColor: props.noteFormStateChange ? 'warning' : 'alternate',
        };
    }

    componentDidUpdate(prevProps) {
        const {
            noteFormStateChange: prevNoteFormStateChange,
        } = prevProps;

        const {
            canReadPersonNotes,
            noteFormStateChange: nextNoteFormStateChange,
        } = this.props;

        if (canReadPersonNotes && prevNoteFormStateChange !== nextNoteFormStateChange) {
            this.setState({
                notesButtonColor: nextNoteFormStateChange ? 'warning' : 'alternate',
            });
        }
    }

    onBackButtonClick() {
        const {
            router,
        } = this.props;

        router.push('/people/search-or-add');
    }

    onCallPersonClick(event) {
        const {
            primaryPhone: {
                phoneNumber,
            },
        } = this.props;

        if (phoneNumber) {
            domUtils.onTelLinkClick(event, phoneNumber);
        }
    }

    onEditPersonClick() {
        const {
            params: {
                personId: paramsPersonId,
            },
            personId: drawerPropPersonId,
            router,
            onClose = noop,
        } = this.props;
        const personId = drawerPropPersonId || toNumber(paramsPersonId);

        onClose();
        router.push(`/people/search-or-add/record/${personId}/edit`);
    }

    onEmailPersonClick(event) {
        const {
            primaryEmail: {
                email,
            },
        } = this.props;

        if (email) {
            domUtils.onMailToLinkClick(event, email);
        }
    }

    onNotesDrawerToggle() {
        const {
            isNotesDrawerOpen,
            setIsNotesDrawerOpen,
        } = this.props;

        personNotesActions.setWingType();
        setIsNotesDrawerOpen(!isNotesDrawerOpen);
    }

    getColumns() {
        const {
            actionBar,
            actionBarControls,
            canReadPersonNotes,
            contactPreferences: {
                doNotPhone,
                doNotEmail,
                doNotContact,
            },
            fullName,
            hasBackButton,
            isBlWdMdUp,
            noteFormStateChange,
            shouldOverrideActions,
            primaryEmail,
            primaryPhone,
            isDeceased,
        } = this.props;

        const {
            notesButtonColor,
        } = this.state;

        const actionBarCallPersonButton = {
            color: 'alternate',
            iconType: 'phone',
            id: `${BEM_BLOCK_NAME}--call_person`,
            onClick: this.onCallPersonClick,
            title: `${i18n('Call')} ${fullName}`,
            disabled: isDeceased,
        };
        const actionBarEmailPersonButton = {
            color: 'alternate',
            iconType: 'envelope',
            id: `${BEM_BLOCK_NAME}--email_person`,
            onClick: this.onEmailPersonClick,
            title: `${i18n('Email')} ${fullName}`,
            disabled: isDeceased,
        };
        const actionBarEditPersonButton = {
            color: 'alternate',
            iconType: 'pencil',
            id: `${BEM_BLOCK_NAME}--edit_person`,
            onClick: this.onEditPersonClick,
            title: i18n('Edit Person Record'),
            disabled: isDeceased,
        };
        const actionBarNotesPersonButton = {
            color: notesButtonColor,
            iconType: 'text-edit',
            id: `${BEM_BLOCK_NAME}--open_person_notes`,
            onClick: this.onNotesDrawerToggle,
            title: i18n('Notes'),
        };
        const actionBarNotesPersonColumn = {
            button: actionBarNotesPersonButton,
            flexGrow: 0,
            style: {
                paddingRight: 0,
                textAlign: 'right',
            },
        };
        const noEmail = doNotContact || doNotEmail || isEmpty(primaryEmail);
        const noPhone = doNotContact || doNotPhone || isEmpty(primaryPhone);
        let actionsBarColumnsSM = [];
        let smActionsButtonOptions = [];
        let actionsBarColumnsMD = [];

        if (canReadPersonNotes && !isBlWdMdUp) {
            actionsBarColumnsMD = [
                ...actionsBarColumnsMD,
                {
                    ...actionBarNotesPersonColumn,
                },
            ];
        }

        if (!noEmail) {
            actionsBarColumnsMD = [
                {
                    button: actionBarEmailPersonButton,
                    flexGrow: 0,
                    style: {
                        paddingRight: 0,
                        textAlign: 'right',
                    },
                },
                ...actionsBarColumnsMD,
            ];

            smActionsButtonOptions = [
                {
                    iconType: actionBarEmailPersonButton.iconType,
                    label: actionBarEmailPersonButton.title,
                    onClick: actionBarEmailPersonButton.onClick,
                    disabled: isDeceased,
                },
                ...smActionsButtonOptions,
            ];
        }

        if (!noPhone) {
            actionsBarColumnsMD = [
                {
                    button: actionBarCallPersonButton,
                    flexGrow: 0,
                    style: {
                        paddingRight: 0,
                        textAlign: 'right',
                    },
                },
                ...actionsBarColumnsMD,
            ];

            smActionsButtonOptions = [
                {
                    iconType: actionBarCallPersonButton.iconType,
                    label: actionBarCallPersonButton.title,
                    onClick: actionBarCallPersonButton.onClick,
                    disabled: isDeceased,
                },
                ...smActionsButtonOptions,
            ];
        }

        actionsBarColumnsMD = [
            ...actionsBarColumnsMD,
            {
                button: actionBarEditPersonButton,
                flexGrow: 0,
                style: {
                    paddingRight: 0,
                    textAlign: 'right',
                },
            },
        ];

        smActionsButtonOptions = [
            ...smActionsButtonOptions,
            {
                iconType: actionBarEditPersonButton.iconType,
                label: actionBarEditPersonButton.title,
                onClick: actionBarEditPersonButton.onClick,
                disabled: actionBarEditPersonButton.disabled,
            },
        ];

        smActionsButtonOptions = [
            ...(shouldOverrideActions ? [] : smActionsButtonOptions),
            ...actionBarControls?.sm ?? [],
        ];

        const openNotesDrawerMobileButton = {
            jsx: (
                canReadPersonNotes && (
                    <Button
                        color={noteFormStateChange ? 'warning' : 'alternate'}
                        icon
                        id={`${BEM_BLOCK_NAME}--open_person_notes`}
                        onClick={this.onNotesDrawerToggle}
                        style={{ margin: 0 }}
                    >
                        <Icon
                            type="text-edit"
                            title={false}
                        />
                    </Button>
                )
            ),
        };

        actionsBarColumnsSM = [
            ...actionsBarColumnsSM,
            ...(!isEmpty(actionBarControls?.smControls) ? [
                ...actionBarControls.smControls,
                ...(!isEmpty(actionBarControls.sm) ? [
                    {
                        list: [
                            openNotesDrawerMobileButton,
                            {
                                actionsButton: {
                                    header: 'Actions',
                                    options: smActionsButtonOptions,
                                },
                                divide: false,
                            },
                        ],
                    },
                ] : []),
                ...(isEmpty(actionBarControls.sm) ?
                    [openNotesDrawerMobileButton] :
                    []
                )!,
            ] : [
                {
                    flexGrow: 1,
                    jsx: hasBackButton ? (
                        <Icon
                            id={`${BEM_BLOCK_NAME}--back_button_icon`}
                            type="chevron-left"
                            onClick={this.onBackButtonClick}
                            style={{
                                marginLeft: 5,
                            }}
                            title={false}
                        />
                    ) : (<div />),
                },
                {
                    list: [
                        openNotesDrawerMobileButton,
                        {
                            actionsButton: {
                                header: 'Actions',
                                options: smActionsButtonOptions,
                            },
                            divide: false,
                        },
                    ],
                },
            ]),
        ];

        actionsBarColumnsMD[0].flexGrow = 1;
        actionsBarColumnsSM[0].flexGrow = 1;

        if (!isEmpty(actionBar) && isFunction(actionBar.md)) {
            actionsBarColumnsMD = actionBar.md({
                actionBarProps: this.props,
                defaultColumns: actionsBarColumnsMD,
            });
        }

        if (!isEmpty(actionBar) && isFunction(actionBar.sm)) {
            actionsBarColumnsSM = actionBar.sm({
                actionBarProps: this.props,
                defaultColumns: actionsBarColumnsSM,
            });
        }

        actionsBarColumnsMD = [
            ...(shouldOverrideActions ? [] : actionsBarColumnsMD),
            ...actionBarControls?.md ?? [],
        ];

        return {
            md: actionsBarColumnsMD,
            sm: actionsBarColumnsSM,
        };
    }

    render() {
        const {
            children,
            classes,
            isBlWdMdUp,
            isDrawerChild,
            winWidth,
        } = this.props;

        const bemClassName = `${BEM_BLOCK_NAME}--action_bar`;
        const ActionBarSwitch = isDrawerChild ? Drawer.ActionBar : Page.ActionBar;
        const isBreakpointMd = (!isDrawerChild && winWidth >= Breakpoint.md) || isBlWdMdUp;
        const columnsObject = this.getColumns();

        let columns;

        if (isBreakpointMd) {
            columns = columnsObject.md;
        } else {
            columns = columnsObject.sm;
        }

        return (
            <ActionBarSwitch
                className={ClassNames(
                    bemClassName,
                    classes.root,
                )}
                columns={columns}
            >
                {children && (
                    <div className={`${bemClassName}--children_container`}>
                        {children}
                    </div>
                )}
            </ActionBarSwitch>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(
        withStyles(
            styles, { withTheme: true },
        )(ActionBar),
    ) as any,
);
