import _ from 'lodash';
import { EventEmitter } from 'events';
import Utils from '../../global/utils/utils.js';

const CHANGE_EVENT = 'change';
const MODAL_CLOSE_EVENT = 'close';

class ModalStoreClass extends EventEmitter {
    constructor() {
        super();

        this._modals = [];
    }

    addModal(modal) {
        const modalId = `modal-${Utils.getIncreasingUniqueKey()}`;
        modal.id = modalId;

        this._modals.push(modal);
        this.emitChange();

        return modalId;
    }

    closeModal(modalId) {
        this.emitModalClose(modalId);
    }

    removeModal(modalId) {
        const modal = _.find(this._modals, (m) => m.id === modalId);

        if (modal) {
            _.pull(this._modals, modal);

            this.emitChange();
        }
    }

    getModals() {
        return this._modals;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    emitModalClose(modalId) {
        this.emit(MODAL_CLOSE_EVENT, modalId);
    }

    addModalCloseListener(callback) {
        this.on(MODAL_CLOSE_EVENT, callback);
    }

    removeModalCloseListener(callback) {
        this.removeListener(MODAL_CLOSE_EVENT, callback);
    }
}

const ModalStore = new ModalStoreClass();
export default ModalStore;
