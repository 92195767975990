import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';

export const coreMilestonesPropTypes = PropTypes.shape({
    congregationDate: PropTypes.oneOfType([
        MomentPropTypes.momentObj,
        MomentPropTypes.momentString,
        PropTypes.string,
    ]),
    firstContactDate: PropTypes.oneOfType([
        MomentPropTypes.momentObj,
        MomentPropTypes.momentString,
        PropTypes.string,
    ]),
    hasAcceptedChrist: PropTypes.bool,
    hasAttendedClass101: PropTypes.bool,
    hasAttendedClass201: PropTypes.bool,
    hasAttendedClass301: PropTypes.bool,
    hasAttendedClass401: PropTypes.bool,
    hasSignedMaturityCovenant: PropTypes.bool,
    hasSignedMembershipAgreement: PropTypes.bool,
    hasSignedMinistryCovenant: PropTypes.bool,
    hasSignedMissionCovenant: PropTypes.bool,
    isBaptised: PropTypes.bool,
    isInMinistry: PropTypes.bool,
    isInSmallGroup: PropTypes.bool,
    isPEACE: PropTypes.bool,
});

export const coreMilestonesDefaultProps = {
    congregationDate: '',
    firstContactDate: '',
    hasAcceptedChrist: false,
    hasAttendedClass101: false,
    hasAttendedClass201: false,
    hasAttendedClass301: false,
    hasAttendedClass401: false,
    hasSignedMembershipAgreement: false,
    hasSignedMaturityCovenant: false,
    hasSignedMinistryCovenant: false,
    hasSignedMissionCovenant: false,
    isBaptised: false,
    isInMinistry: false,
    isInSmallGroup: false,
    isPEACE: false,
};
