import _ from 'lodash';
import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const recommendedMinistriesCache = new ChunkedPaginationUtils();

const DEFAULT_STATE = {
    canLoadMore: false,
    isFetching: false,
    isSaving: false,
    ministries: [],
    needsToRequest: false,
    pageNumber: 0,
    pageSize: recommendedMinistriesCache.getPageSize(),
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    const first = !_.isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        case 'RecommendedMinistriesActions.ON_BEFORE_GET': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'RecommendedMinistriesActions.ON_GET': {
            const {
                results,
                resultsCount,
            } = actionResult;

            recommendedMinistriesCache.loadPage(results, resultsCount, first);

            // fall-through to NEXT_PAGE case
        }

        case 'RecommendedMinistriesActions.NEXT_PAGE': {
            return {
                ...state,
                canLoadMore: recommendedMinistriesCache.canLoadMore(),
                isFetching: false,
                ministries: recommendedMinistriesCache.getAll(true),
                needsToRequest: recommendedMinistriesCache.needsToLoadPage(),
                pageNumber: recommendedMinistriesCache.getCurrentPageNumber(),
                pageSize: recommendedMinistriesCache.getPageSize(),
                total: recommendedMinistriesCache.getTotalCount(),
            };
        }

        case 'RecommendedMinistriesActions.ON_BEFORE_UPDATE': {
            return {
                ...state,
                isFetching: true,
                isSaving: true,
            };
        }

        case 'RecommendedMinistriesActions.ON_UPDATE': {
            const { results, resultsCount } = actionResult;
            recommendedMinistriesCache.loadPage(results, resultsCount, true);

            return {
                ...state,
                canLoadMore: recommendedMinistriesCache.canLoadMore(),
                isFetching: false,
                isSaving: false,
                ministries: recommendedMinistriesCache.getAll(true),
                needsToRequest: recommendedMinistriesCache.needsToLoadPage(),
                pageNumber: recommendedMinistriesCache.getCurrentPageNumber(),
                pageSize: recommendedMinistriesCache.getPageSize(),
                total: recommendedMinistriesCache.getTotalCount(),
            };
        }

        case 'RecommendedMinistriesActions.RESET': {
            return DEFAULT_STATE;
        }

        default:
            // no-op
    }

    return state;
};
