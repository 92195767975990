/**
* This enumeration contains the Action Type string constants for filters on designation list
*/
enum ActionType {
    // Action Type for Filter of Designation List
    SET_APPLY_FILTERS = 'DesignationListActions.ON_APPLY_FILTERS',
    SET_CLEAR_FILTERS = 'DesignationListActions.ON_CLEAR_FILTERS',
    SET_APPLY_SORT_ORDER = 'DesignationListActions.ON_SET_AND_APPLY_SORT_ORDER',
    SET_AND_APPLY_DESIGNATION_STATUS_FILTER_CHANGE = 'DesignationListActions.ON_SET_AND_APPLY_DESIGNATION_STATUS_FILTER_CHANGE',
    SET_AND_APPLY_DESIGNATION_PUBLISHED_STATUS_FILTER_CHANGE = 'DesignationListActions.ON_SET_AND_APPLY_DESIGNATION_PUBLISHED_STATUS_FILTER_CHANGE',
    SET_AND_APPLY_DESIGNATION_TYPE_FILTER_CHANGE = 'DesignationListActions.ON_SET_AND_APPLY_DESIGNATION_TYPE_FILTER_CHANGE',
    SET_DIRTY_SORT_ORDER = 'DesignationListActions.ON_SET_DIRTY_SORT_ORDER',
    SET_DIRTY_DESIGNATION_STATUS_FILTER_CHANGE = 'DesignationListActions.ON_SET_DIRTY_DESIGNATION_STATUS_FILTER_CHANGE',
    SET_DIRTY_DESIGNATION_PUBLISHED_STATUS_FILTER_CHANGE = 'DesignationListActions.ON_SET_DIRTY_DESIGNATION_STATUS_PUBLISHED_FILTER_CHANGE',
    SET_DIRTY_DESIGNATION_TYPE_FILTER_CHANGE = 'DesignationListActions.ON_SET_DIRTY_DESIGNATION_TYPE_FILTER_CHANGE',
}

export default ActionType;
