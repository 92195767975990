import {
    Select,
} from '@saddlebackchurch/react-cm-ui';
import {
    isNil,
    map,
    upperFirst,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from './i18nUtils.js';
import {
    GRADE_OPTIONS,
    getGradeOptions,
} from '../people/global/constants.js';

const i18n = translationFactory('ConnectionCards.Global.EntryDrawerPersonalContent');

const propTypes = {
    campus: PropTypes.shape({}),
    className: PropTypes.string,
    disable: PropTypes.bool,
    excludeGradeByRecordType: PropTypes.oneOf(['adult', 'child', 'student']),
    id: PropTypes.string,
    isRecordTypeLabelEnabled: PropTypes.bool,
    onGradeChange: PropTypes.func,
    style: PropTypes.shape({}),
    recordType: PropTypes.oneOf(['adult', 'child', 'student']),
    required: PropTypes.bool,
    value: PropTypes.oneOf([
        PropTypes.shape({}),
        PropTypes.string,
    ]),
};

const defaultProps = {
    campus: undefined,
    className: undefined,
    disable: false,
    excludeGradeByRecordType: undefined,
    id: undefined,
    isRecordTypeLabelEnabled: false,
    onGradeChange: undefined,
    recordType: undefined,
    required: false,
    style: undefined,
    value: undefined,
};

function SelectGrade(props) {
    const {
        campus,
        className,
        disable,
        excludeGradeByRecordType,
        id,
        isRecordTypeLabelEnabled,
        onGradeChange,
        recordType,
        required,
        style,
        value,
    } = props;

    let gradeOptions = !isNil(campus) ? getGradeOptions(campus) : [...GRADE_OPTIONS];

    if (excludeGradeByRecordType) {
        if (excludeGradeByRecordType === 'child') {
            gradeOptions.splice(1, 8);
        } else if (excludeGradeByRecordType === 'student') {
            gradeOptions.splice(8, 15);
        } else if (excludeGradeByRecordType === 'adult') {
            gradeOptions.splice(0, 1);
        }
    }

    if (isRecordTypeLabelEnabled) {
        gradeOptions = map(gradeOptions, (item) => {
            if (item === value && recordType && !item.label.includes('(Child)') && !item.label.includes('(Student)') && !item.label.includes('(Adult)')) {
                value.label = `${item.label} (${upperFirst(recordType)})`;
                return value;
            }
            if (item !== value && (item.label.includes('(Child)') || item.label.includes('(Student)') || item.label.includes('(Adult)'))) {
                const newItem = item;
                newItem.label = newItem.label.replace(' (Child)', '');
                newItem.label = newItem.label.replace(' (Student)', '');
                newItem.label = newItem.label.replace(' (Adult)', '');
                return newItem;
            }

            return item;
        });
    }

    return (
        <Select
            className={className}
            disable={disable}
            fluid
            id={id}
            label={i18n('Grade')}
            onChange={onGradeChange}
            options={gradeOptions}
            placeholder={i18n('Select')}
            required={required}
            searchable
            style={style}
            value={value}
        />
    );
}

SelectGrade.propTypes = propTypes;
SelectGrade.defaultProps = defaultProps;

export default SelectGrade;
