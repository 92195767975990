import { combineReducers } from 'redux';
import list from './milestones.reducer.js';
import manager from './milestoneManagerDrawer.reducer.js';
import details from './details/details.reducer.js';

const reducers = combineReducers({
    details,
    list,
    manager,
});

export default reducers;
