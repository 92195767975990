import ActionType from './checkIn.actionTypes';

export const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ActionType.ON_SEARCH:
            return action.result;

        case ActionType.ON_BEFORE_SEARCH:
        case ActionType.ON_RESET_SEARCH:
            return DEFAULT_STATE;

        default:
            return state;
    }
};
