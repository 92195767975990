import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import { connect } from 'react-redux';
import {
    getPersonMetaInfoText,
    getPersonFullName,
} from '../../global/utils.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import UserAccessStore from '../../../global/userAccessStore.js';

const personPersonalInfoPropTypes = {
    options: PropTypes.shape({
        withPersonId: PropTypes.bool,
    }),
    person: PropTypes.shape({
        id: PropTypes.number,
        metaInfoText: PropTypes.string,
    }).isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    options: {},
};

const useStyles = makeStyles(({ palette }) => ({
    personalInfo: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    },
    fullname: {
        color: palette.text.primary,
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '22px',
        marginBottom: '2px',
        textWrap: 'wrap',
        wordBreak: 'break-word',
    },
    metaInfoText: {
        color: palette.text.secondary,
        fontSize: '12px',
        lineHeight: '18px',
    },
}));

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
    } = state;

    return { userAccess };
};

function PersonPersonalInfo(props) {
    const {
        person: {
            id,
        },
        person,
        options,
        userAccess,
    } = props;

    const classes = useStyles();

    const hasAccessDateOfBirth = userAccess.hasPermission(
        USER_PERMISSIONS.accessDateOfBirth,
    );

    const fullName = getPersonFullName(person, true);
    const metaInfoText = getPersonMetaInfoText(person, { ...options, ...{ hasAccessDateOfBirth } });
    const shouldDisplayMetaInfo = !isNil(metaInfoText);

    return (
        <div
            data-testid="person-personal-info"
            className={classes.personalInfo}
        >
            <div className={classes.fullname}>
                {fullName}
            </div>

            {shouldDisplayMetaInfo && (
                <div
                    className={classes.metaInfoText}
                    key={`person-list-${id}-personal_info_gender`}
                >
                    {metaInfoText}
                </div>
            )}
        </div>
    );
}

PersonPersonalInfo.propTypes = personPersonalInfoPropTypes;
PersonPersonalInfo.defaultProps = defaultProps;

export default connect(mapStateToProps)(PersonPersonalInfo);
