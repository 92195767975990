import {
    every,
    first,
    isArray,
    isFunction,
    isString,
    some,
} from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserAccessStore from './userAccessStore.js';
import UserIdentityStore from './userIdentityStore';

const propTypes = {
    anyPermissions: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]).isRequired,
    churchEntityId: PropTypes.number,
    eventId: PropTypes.number,
    hasAnyMinistries: PropTypes.bool,
    ministryId: PropTypes.number,
    permission: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]).isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
    userIdentity: PropTypes.instanceOf(UserIdentityStore).isRequired,
};

const defaultProps = {
    anyPermissions: false,
    churchEntityId: null,
    eventId: null,
    hasAnyMinistries: false,
    ministryId: null,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
                userIdentity,
            },
        },
    } = state;

    return { userAccess, userIdentity };
};

export const hasUserPermission = ({
    anyPermissions,
    churchEntityId,
    eventId,
    hasAnyMinistries,
    ministryId,
    permission,
    userAccess,
    userIdentity,
    connectionFormTemplateId,
}) => {
    let hasPermission = false;

    if (!userAccess) {
        return hasPermission;
    }

    if (isArray(permission)) {
        if (isString(first(permission))) {
            // if permission is an array of strings, just doing default handling

            if (anyPermissions) {
                hasPermission = userAccess.hasAnyPermission(permission);
            } else {
                hasPermission = userAccess.hasAllPermissions(permission);
            }
        } else {
            // in other case, we should do recursive call for each permission object

            if (anyPermissions) {
                return some(
                    permission,
                    (p) => hasUserPermission({
                        ...p,
                        userAccess,
                        userIdentity,
                    }),
                );
            }

            return every(
                permission,
                (p) => hasUserPermission({
                    ...p,
                    userAccess,
                    userIdentity,
                }),
            );
        }
    } else if (eventId) {
        hasPermission = userAccess.isAuthorizedForEvent(
            eventId, ministryId, churchEntityId, permission,
        );
    } else if (ministryId && churchEntityId) {
        hasPermission = userAccess.isAuthorizedForMinistryLocation(
            ministryId, churchEntityId, permission,
        );
    } else if (churchEntityId) {
        hasPermission = userAccess.isAuthorizedForCampus(
            churchEntityId, permission,
        );
    } else if (ministryId) {
        hasPermission = userAccess.isAuthorizedForMinistry(ministryId, permission);
    } else {
        hasPermission = userAccess.hasPermission(permission);
    }

    if (hasPermission && hasAnyMinistries) {
        hasPermission = userIdentity.hasLocalMinistryLeadership();
    }

    if (connectionFormTemplateId && churchEntityId && permission) {
        hasPermission = userAccess.isAuthorizedForForm(
            churchEntityId,
            connectionFormTemplateId,
            permission,
        );
    }

    return hasPermission;
};

export function UserPermission(props) {
    const {
        anyPermissions,
        children,
        churchEntityId,
        eventId,
        hasAnyMinistries,
        ministryId,
        permission,
        userAccess,
        userIdentity,
    } = props;

    const hasPermission = hasUserPermission({
        anyPermissions,
        churchEntityId,
        eventId,
        hasAnyMinistries,
        ministryId,
        permission,
        userAccess,
        userIdentity,
    });

    if (isFunction(children)) {
        return children(hasPermission);
    }

    if (hasPermission) {
        return children;
    }

    return null;
}

UserPermission.propTypes = propTypes;
UserPermission.defaultProps = defaultProps;

export default connect(mapStateToProps)(UserPermission);
