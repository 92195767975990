import _ from 'lodash';
import LoginConstants from '../../constants/Login/LoginConstants';

export const DEFAULT_STATE = {
    allRoles: [],
    savedRole: {},
    selectedRole: {},
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
        case 'RoleActions.RESET':
            return DEFAULT_STATE;
        case 'RoleActions.ON_GET':
            return { ...state, selectedRole: action.result };
        case 'RoleActions.ON_CREATE':
        case 'RoleActions.ON_UPDATE':
            return { ...state, savedRole: action.result };
        case 'RoleActions.ON_GETALL':
            return { ...state, allRoles: action.result };
    }

    return state;
}
