import { Action } from '../../../global/models';
import ActionType from '../../actionTypes/communicationTemplate.actionTypes';

export const INITIAL_STATE = {
    id: null,
    churchEntityId: null,
    churchEntityName: null,
    name: null,
    description: null,
    isActive: false,
    isSystem: false,
    isEmail: false,
    isPush: false,
    isSMS: false,
    modifyDate: null,
    thumbnailImageUrl: null,
    emailBody: null,
    emailSubjectContent: null,
    pushNotificationTitle: null,
    pushNotificationBody: null,
    smsBody: null,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.RESET_COMMUNICATION_TEMPLATE:
            return INITIAL_STATE;
        case ActionType.SET_COMMUNICATION_TEMPLATE:
            return action.payload;
        default:
            return state;
    }
};
