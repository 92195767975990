import { i18n } from '../../../global/constants.js';

export const BEM_BLOCK_NAME = 'giving_activity_list';

export const DEBOUNCE_TIMEOUT = 500;

export const GIVING_ACTIVITY_SORT_OPTION_DEFAULT = {
    label: i18n('Payment Date (Desc)'),
    value: 'PaymentDateDesc',
};

export const GIVING_ACTIVITY_SEARCH_MAX_RESULTS_LENGTH = 200;
