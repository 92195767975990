import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';
import { POSSIBLE_DUPLICATES_ADD_PERSON_SET_IS_DRAWER_OPEN } from './possibleDuplicatesAddPersonTypes.js';

const localCache = new ChunkedPaginationUtils();

const DEFAULT_STATE = {
    data: [],
    isFetching: false,
    isPossibleDuplicatesDrawerOpen: false,
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: localCache.getPageSize(),
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PossibleDuplicatesAddPersonActions.ON_BEFORE_GET': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'PossibleDuplicatesAddPersonActions.ON__GET': {
            localCache.loadPage(
                action.result.results,
                action.result.resultCount,
                action.callbackParams.first,
            );

            return {
                ...state,
                isFetching: false,
                data: localCache.getAll(true),
                hasPinnedNotes: action.result.hasPinnedNotes,
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'PossibleDuplicatesAddPersonActions.ON_NEXTPAGE': {
            return {
                ...state,
                data: localCache.getAll(true),
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'PossibleDuplicatesAddPersonActions.ON_RESET': {
            return DEFAULT_STATE;
        }

        case POSSIBLE_DUPLICATES_ADD_PERSON_SET_IS_DRAWER_OPEN: {
            const {
                isPossibleDuplicatesDrawerOpen,
            } = action;

            const shouldToggle = typeof isPossibleDuplicatesDrawerOpen !== 'boolean';

            return {
                ...state,
                isPossibleDuplicatesDrawerOpen: shouldToggle ?
                    !state.isPossibleDuplicatesDrawerOpen :
                    isPossibleDuplicatesDrawerOpen,
            };
        }

        default:
            return state;
    }
};
