import { isEmpty } from 'lodash';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

const pagedEventOccurrencesList = new ChunkedPaginationUtils();

const DEFAULT_STATE = {
    canLoadMore: false,
    eventOccurrences: [],
    isFetching: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedEventOccurrencesList.getPageSize(),
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    const first = !isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        case 'EventFlatOccurrenceSearchV2Actions.ON_BEFORE_INTERNAL_GETOCCURRENCES':
            if (first) {
                return { ...state, isFetching: true };
            }

            return state;

        case 'EventFlatOccurrenceSearchV2Actions.ON_INTERNAL_GETOCCURRENCES':
        {
            pagedEventOccurrencesList.loadPage(actionResult, actionResult.length, first); // we don't get a total count from API
            const eventOccurrences = pagedEventOccurrencesList.getAll(true);

            return {
                ...state,
                eventOccurrences,
                canLoadMore: pagedEventOccurrencesList.canLoadMore(),
                isFetching: false,
                needsToRequest: pagedEventOccurrencesList.needsToLoadPage(),
                pageNumber: pagedEventOccurrencesList.getCurrentPageNumber(),
                pageSize: pagedEventOccurrencesList.getPageSize(),
                total: first ? pagedEventOccurrencesList.getTotalCount() : state.total,
            };
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_GET_OCCURRENCES_NEXT_PAGE':
        {
            const eventOccurrences = pagedEventOccurrencesList.getAll(true);

            return {
                ...state,
                eventOccurrences,
                canLoadMore: pagedEventOccurrencesList.canLoadMore(),
                needsToRequest: pagedEventOccurrencesList.needsToLoadPage(),
            };
        }

        case 'EventFlatOccurrenceSearchV2Actions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
