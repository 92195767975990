import '../../../images/icons/agreement.svg';

import { isNil } from 'lodash';
import {
    Box,
    Checkbox,
    dateUtils,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';
import { Agreement } from 'connectionForms/models/agreement.model';
import { CustomField } from '../../../global/customFields/models';
import { formatVersion } from '../../templates/formBuilderPage/addAgreementDrawer/utils';

type PropTypes = {
    agreement: Agreement;
    disable?: boolean;
    isRequired?: boolean;
    isVersionLatest?: boolean | null;
    onChange: (id: string, checked: boolean) => void;
    question: CustomField;
    showVersion?: boolean | null;
    title: string;
    value?: boolean | null;
};

const useStyles = makeStyles(({
    palette,
    shape,
    spacing,
    typography,
}) => ({
    checkbox: {
        marginTop: spacing(2),
    },
    description: {
        marginTop: spacing(2),
    },
    header: {
        fontWeight: typography.fontWeightBold,
    },
    required: {
        color: palette.error.main,
    },
    root: {
        borderColor: palette.teal[500],
        borderRadius: shape.borderRadiusSecondary,
        borderStyle: 'solid',
        borderWidth: `1px 1px 1px ${shape.borderRadiusSecondary}px`,
        marginTop: spacing(3),
        padding: spacing(2),
    },
    thumbnailImage: {
        marginRight: spacing(2),
        width: 33,
    },
}));

function EntryFormAgreement({
    agreement,
    disable,
    isRequired,
    isVersionLatest = true,
    onChange,
    question,
    showVersion = true,
    title,
    value,
}: PropTypes) {
    const classes = useStyles();
    const questionTitle = question?.title;
    const questionLabel = title || questionTitle;
    const disableAgreement = isNil(value);
    const checkboxValue = disableAgreement ? false : value;
    let checkboxLabel = agreement.acceptance;

    if (value) {
        checkboxLabel += ` (${dateUtils.formatDate(agreement.createDate)})`;
    }

    return (
        <div
            className={classes.root}
            data-testid="entry-form-agreement"
        >
            <Box display="flex">
                <img
                    alt=""
                    className={classes.thumbnailImage}
                    src="/images/icons/agreement.svg"
                    title=""
                />
                <div>
                    <Typography
                        variant="h4"
                        className={classes.header}
                    >
                        {questionLabel}
                        {isRequired && (
                            <span
                                className={classes.required}
                                data-testid="agreement-required-asterix"
                            >
                                *
                            </span>
                        )}
                    </Typography>
                    {showVersion && (
                        <Typography
                            color="textSecondary"
                            data-testid="agreement-version"
                            variant="h5"
                        >
                            {formatVersion(agreement, isVersionLatest, true)}
                        </Typography>
                    )}
                </div>
            </Box>
            <div
                className={classes.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: agreement.content }}
            />
            <div className={classes.checkbox}>
                <Checkbox
                    checked={checkboxValue}
                    disabled={disable || disableAgreement}
                    fluid
                    label={checkboxLabel}
                    labelWeight="bold"
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

export default EntryFormAgreement;
