import _ from 'lodash';

export const DEFAULT_STATE = [
];

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'WorshipServiceCategoryActions.ON_GETALL':
            return _.map(action.result, (v) => ({
                value: v.id,
                label: v.name,
                isUsed: v.isUsed,
            }));
    }

    return state;
}
