export const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ConnectionFormTemplatesActions.ON_INTERNALGET':
        case 'ConnectionFormTemplatesActions.ON_INTERNALUPDATE':
            return action.result || {};

        case 'ConnectionFormTemplatesActions.ON_RESET_FORM_DETAILS':
            return DEFAULT_STATE;

        case 'ConnectionFormEntriesListPageActions.ON_BULKDELETE':
            return {
                ...state,
                ...action.result,
            };

        default:
            return state;
    }
};
