import { combineReducers } from 'redux';
import checkIn from './myServingOpps/checkIn/checkIn.reducers';
import communicationTemplates from './communications/communicationTemplates.reducer';
import multiMinistryCommunications from './multiMinistryCommunicationsReducer.js';
import roster from './ministryRoster/rosterReducers.js';
import selectedMinistry from './selectedMinistry.reducer';
import servingOpportunities from './servingOpportunities/servingOpportunities.reducers.js';
import settings from './settings/reducers_old/settingsReducers.js';

const reducers = combineReducers({
    checkIn,
    communicationTemplates,
    multiMinistryCommunications,
    roster,
    selectedMinistry,
    servingOpportunities,
    settings,
});

export default reducers;
