import {
    concat,
    slice,
} from 'lodash';

/**
 * Chunk = Smaller, UI-side subset of results (e.g. 25 things at a time)
 * Page = Larger, back-end pages of data from the API (e.g. 150 things a time)
 */
export const defaultChunkSize = 25;
export const defaultMaxPages = -1;
export const defaultPageSize = 150;

export default class ChunkedPaginationUtils {
    constructor(chunkSize, pageSize, maxPages) {
        this.chunkSize = chunkSize ?? defaultChunkSize;
        this.maxPages = maxPages ?? defaultMaxPages;
        this.pageSize = pageSize ?? defaultPageSize;

        this.reset();
    }

    canLoadMore() {
        return !this.loaded || !this.isLastPage || (this.latestPageIndex < this.data.length);
    }

    getAllInternalData() {
        return this.data;
    }

    getAll(next = true) {
        if (next) {
            this.latestPageIndex += this.chunkSize;
        }

        return slice(this.data, 0, this.latestPageIndex);
    }

    getCurrentCount() {
        return this.data.length;
    }

    getCurrentPageNumber() {
        return this.currentPageNumber;
    }

    getIsLastPage() {
        return this.isLastPage;
    }

    getLatestPageIndex() {
        return this.latestPageIndex;
    }

    getPageSize() {
        return this.pageSize;
    }

    getTotalCount() {
        return this.totalCount;
    }

    isMaxPage() {
        return (this.maxPages > 0) && (this.currentPageNumber >= (this.maxPages - 1));
    }

    loadPage(results, totalCount, reset, prepend = false) {
        if (reset) {
            this.data = results;
            this.latestPageIndex = 0;
            this.currentPageNumber = 0;
        } else {
            this.data = prepend ? concat(results, this.data) : concat(this.data, results);
            this.currentPageNumber++; // eslint-disable-line no-plusplus
            // No need to update the latestPageIndex - it will be updated by getAll() method
        }

        this.isLastPage = this.isMaxPage() || results.length < this.pageSize;
        this.totalCount = totalCount;
        this.loaded = true;
    }

    maxRows() {
        return (this.maxPages > 0) ? this.maxPages * this.pageSize : -1;
    }

    needsToLoadPage() {
        return !this.isLastPage && (this.latestPageIndex >= this.data.length);
    }

    reset() {
        this.latestPageIndex = 0;
        this.data = [];
        this.isLastPage = true;
        this.currentPageNumber = 0;
        this.totalCount = 0;
        this.loaded = false;
    }

    updateDefaults({
        chunkSize,
        maxPages,
        pageSize,
    }) {
        this.chunkSize = chunkSize ?? this.chunkSize;
        this.maxPages = maxPages ?? this.maxPages;
        this.pageSize = pageSize ?? this.pageSize;
    }
}
