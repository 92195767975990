import {
    filter,
    isEmpty,
    isNil,
    isUndefined,
    map,
} from 'lodash';
import {
    ActivityIndicator,
    Drawer,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import {
    checkRequirements,
    PersonRequirements,
} from './personRequirements.jsx';
import { isSearchQueryValid } from './queryUtils/queryUtils.js';
import { i18n } from '../../../global/constants.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import isChildToAgeCategory from './utils';
import PersonListGroupView from './personListGroupView.jsx';
import peopleSearchDrawerActions from './peopleSearchDrawer.actions.js';
import PeopleSearchDrawerSearchForm from './peopleSearchDrawerSearchForm.jsx';
import UserAccessStore from '../../../global/userAccessStore.js';

const propTypes = {
    activeLoadMore: PropTypes.bool.isRequired,
    advancedSearchFields: PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        nickName: PropTypes.string,
        phone: PropTypes.string,
        searchTerm: PropTypes.string,
        zipCode: PropTypes.string,
    }),
    apiParams: PropTypes.shape({
        churchEntityId: PropTypes.number,
        ministryId: PropTypes.number,
    }),
    canLoadMore: PropTypes.bool.isRequired,
    checkPublicContacts: PropTypes.bool,
    classes: PropTypes.shape({
        loaderWrapper: PropTypes.string,
        root: PropTypes.string,
    }),
    coreMilestonesByPersonIds: PropTypes.arrayOf(PropTypes.shape({})),
    expandPerson: PropTypes.func,
    isAllowedToBypassEligibility: PropTypes.bool,

    /**
     * Optional filter passed to the person search.
     * Can be null | false | true
     * null => do not filter by record type
     * false => only include Adults and Students (not Children)
     * true => only include Children
     * TODO: Consider removing this `isChild` and it can be part of `apiParams`
     */
    isChild: PropTypes.bool,

    isExclamationColumnVisible: PropTypes.bool,
    isFetching: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    needsToRequest: PropTypes.bool.isRequired,
    otherDataGroups: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.func,
    ]),
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    people: PropTypes.arrayOf(PropTypes.shape({})),
    personId: PropTypes.number,
    personSearchFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    promptSelectRecord: PropTypes.bool,
    promptViewRecord: PropTypes.bool,
    selectRecordDisabledButtonLabel: PropTypes.string,
    setPerson: PropTypes.func,
    showAdvancedSearchFields: PropTypes.bool,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    advancedSearchFields: {},
    apiParams: {},
    checkPublicContacts: false,
    classes: {},
    coreMilestonesByPersonIds: [],
    expandPerson: undefined,
    isAllowedToBypassEligibility: true,
    isChild: null,
    isExclamationColumnVisible: false,
    otherDataGroups: null,
    people: [],
    personId: null,
    promptSelectRecord: true,
    promptViewRecord: true,
    selectRecordDisabledButtonLabel: null,
    setPerson: undefined,
    showAdvancedSearchFields: true,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        breakpoint: {
            isMobile,
        },
        people: {
            searchV2: {
                advancedSearchFields,
                peopleList: {
                    activeLoadMore,
                    canLoadMore,
                    isFetching,
                    coreMilestonesByPersonIds,
                    needsToRequest,
                    pageNumber,
                    pageSize,
                    people,
                },
            },
        },
        person: {
            searchFields: personSearchFields,
        },
    } = state;

    return {
        activeLoadMore,
        advancedSearchFields,
        canLoadMore,
        coreMilestonesByPersonIds,
        isFetching,
        isMobile,
        needsToRequest,
        pageNumber,
        pageSize,
        people,
        personSearchFields,
        userAccess,
    };
};

const BEM_BLOCK_NAME = 'people_search_drawer';

const styles = () => ({
    loaderWrapper: {
        marginTop: 55,
        textAlign: 'center',
    },
    root: {
        [`& .${BEM_BLOCK_NAME}--total_count-action_bar_search`]: {
            marginBottom: '33px',
        },
        [`& .${BEM_BLOCK_NAME}--total_count-advanced_search`]: {
            margin: '33px 0',
        },
    },
});

export class PeopleSearchDrawerContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPerson: {},
            isRequirementsDrawerOpen: false,
        };

        this.peopleNodeWrapper = createRef();
        this.onLoadMoreChange = this.onLoadMoreChange.bind(this);
        this.onRequirementsDrawerClick = this.onRequirementsDrawerClick.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.search = this.search.bind(this);
        this.setSelectedPerson = this.setSelectedPerson.bind(this);
    }

    componentWillUnmount() {
        peopleSearchDrawerActions.resetCoreMilestonesByPersonIds();
        peopleSearchDrawerActions.resetPeople();
    }

    onLoadMoreChange(isVisible) {
        if (isVisible) {
            const {
                canLoadMore,
                needsToRequest,
            } = this.props;

            peopleSearchDrawerActions.onToggleActiveLoadMore(false);

            if (needsToRequest) {
                this.searchHandler(false);
            } else if (canLoadMore) {
                peopleSearchDrawerActions.getPeopleNextPage();
                peopleSearchDrawerActions.onToggleActiveLoadMore(true);
            }
        }
    }

    onRequirementsDrawerClick(person) {
        this.setState((prevState) => ({
            currentPerson: prevState.isRequirementsDrawerOpen ? {} : person,
            isRequirementsDrawerOpen: !prevState.isRequirementsDrawerOpen,
        }));
    }

    setSelectedPerson(person) {
        const { setPerson } = this.props;

        if (!isUndefined(setPerson)) {
            this.setState({ isRequirementsDrawerOpen: false });

            setPerson(person);
        }
    }

    search(query, first) {
        const {
            personSearchFields,
            userAccess,
        } = this.props;

        if (!isSearchQueryValid(query, personSearchFields)) {
            // eslint-disable-next-line no-console
            console.warn('Person search query was not valid.  Bailing out.  query:', query);
            return;
        }

        const {
            apiParams,
            isChild,
            pageNumber,
            pageSize,
        } = this.props;

        peopleSearchDrawerActions.getPeople({
            ...apiParams,
            ageCategory: isChildToAgeCategory(isChild),
            checkPublicContacts: false,
            pageNumber: first ? 0 : pageNumber + 1,
            pageSize,
            q: query,
        }, { first }).then((res) => {
            const hasCoreMilestonesPermissions = userAccess.hasPermission(
                USER_PERMISSIONS.readPersonMilestones,
            );

            if (hasCoreMilestonesPermissions && res && !isEmpty(res.results)) {
                const personIds = map(res.results, 'id');
                peopleSearchDrawerActions.getCoreMilestonesByPersonIds({
                    personIds,
                });
            }

            peopleSearchDrawerActions.onToggleActiveLoadMore(true);
        });
    }

    searchHandler(firstPage) {
        const {
            showAdvancedSearchFields,
            advancedSearchFields: {
                address1,
                city,
                email,
                firstName,
                lastName,
                nickName,
                phone,
                searchTerm,
                zipCode,
            },
        } = this.props;

        let query = '';

        if (showAdvancedSearchFields) {
            if (!isEmpty(firstName)) {
                query = `first-name:"${firstName}"`;
            }

            if (!isEmpty(lastName)) {
                query += ` last-name:"${lastName}"`;
            }

            if (!isEmpty(nickName)) {
                query += ` nickname:"${nickName}"`;
            }

            if (!isEmpty(phone)) {
                query += ` phone.number:"${phone.replace(/[^\d]/gi, '')}"`;
            }

            if (!isEmpty(email)) {
                query += ` email:"${email}"`;
            }

            if (!isEmpty(address1)) {
                query += ` address.address1:"${address1}"`;
            }

            if (!isEmpty(city)) {
                query += ` address.city:"${city}"`;
            }

            if (!isEmpty(zipCode)) {
                query += ` address.postal-code:"${zipCode}"`;
            }
        } else {
            query = searchTerm;
        }

        if (query) {
            this.search(query, firstPage);
        }
    }

    render() {
        const {
            activeLoadMore,
            apiParams,
            canLoadMore,
            checkPublicContacts,
            classes,
            coreMilestonesByPersonIds,
            expandPerson,
            isAllowedToBypassEligibility,
            isChild,
            isExclamationColumnVisible,
            isFetching,
            isMobile,
            otherDataGroups,
            personId,
            people,
            promptSelectRecord,
            promptViewRecord,
            selectRecordDisabledButtonLabel,
            showAdvancedSearchFields,
        } = this.props;

        const {
            currentPerson,
            isRequirementsDrawerOpen,
        } = this.state;

        const totalCountClasses = ClassNames(`${BEM_BLOCK_NAME}--total_count`, {
            [`${BEM_BLOCK_NAME}--total_count-action_bar_search`]: !showAdvancedSearchFields,
            [`${BEM_BLOCK_NAME}--total_count-advanced_search`]: showAdvancedSearchFields,
        });

        const filteredPeople = isEmpty(people) ?
            people : filter(people, (item) => item.id !== personId);

        const filteredTotal = isEmpty(filteredPeople) ? 0 : filteredPeople.length;

        return (
            <div
                className={classes.root}
                data-testid="people-search-drawer-content"
                id={`${BEM_BLOCK_NAME}--content`}
                ref={this.peopleNodeWrapper}
            >
                {showAdvancedSearchFields && (
                    <PeopleSearchDrawerSearchForm
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...apiParams}
                        checkPublicContacts={checkPublicContacts}
                        isChild={isChild}
                        showAdvancedSearchFields
                    />
                )}

                <div className={totalCountClasses}>
                    {i18n('Total Count')}
                    &nbsp;
                    {filteredTotal}
                </div>

                <div
                    id={`${BEM_BLOCK_NAME}--results`}
                >
                    {isFetching ? (
                        <div className={classes.loaderWrapper}>
                            <ActivityIndicator />
                        </div>
                    ) : (
                        <PersonListGroupView
                            activeLoadMore={activeLoadMore}
                            canLoadMore={canLoadMore}
                            isAllowedToBypassEligibility={isAllowedToBypassEligibility}
                            isExclamationColumnVisible={isExclamationColumnVisible}
                            isMobile={isMobile}
                            isSelectRecordVisible
                            coreMilestonesByPersonIds={coreMilestonesByPersonIds}
                            onLoadMoreChange={this.onLoadMoreChange}
                            onSelect={this.setSelectedPerson}
                            expandPerson={expandPerson}
                            otherDataGroups={otherDataGroups}
                            people={people}
                            promptSelectRecord={promptSelectRecord}
                            promptViewRecord={promptViewRecord}
                            selectRecordDisabledButtonLabel={selectRecordDisabledButtonLabel}
                        />
                    )}
                </div>

                {/* TODO/FIXME: We don't want to be referencing
                    `apiParams.ministryId` and `apiParams.ministryId` here like
                    this.  Generally speaking, we don't want feature-specific
                    stuff in global components where we can help it; we want
                    general extensibility points instead so that
                    feature-specific aspects can be handled in a proper manner.
                */}
                {!isNil(apiParams.ministryId) && !isNil(apiParams.churchEntityId) && (
                    <Drawer
                        className="person_search_missing_requirements_drawer"
                        isOpen={isRequirementsDrawerOpen}
                        maxWidth={375}
                    >
                        <Drawer.TitleBar
                            className="person_search_missing_requirements_drawer--title_bar"
                            closeButton={(
                                <Icon
                                    compact
                                    onClick={this.onRequirementsDrawerClick}
                                    size="large"
                                    title="Close"
                                    type="times"
                                />
                            )}
                            title={i18n('Missing Requirements')}
                        />

                        <Drawer.Content className="person_search_missing_requirements_drawer--content">
                            <PersonRequirements
                                churchEntityId={apiParams.churchEntityId}
                                ministryId={apiParams.ministryId}
                                person={currentPerson}
                                requirements={checkRequirements(currentPerson.eligibility)}
                                setPerson={this.setSelectedPerson}
                            />
                        </Drawer.Content>
                    </Drawer>
                )}
            </div>
        );
    }
}

PeopleSearchDrawerContent.propTypes = propTypes;
PeopleSearchDrawerContent.defaultProps = defaultProps;

export default withStyles(styles)(
    connect(mapStateToProps)(PeopleSearchDrawerContent),
);
