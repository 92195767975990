import { map } from 'lodash';
import LoginConstants from '../../js/constants/Login/LoginConstants.js';

export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'WarehouseDashboardActions.ON_GETREGIONS':
            return map(action.result, (r) => ({ label: r.name, value: r.id }));

        default:
            return state;
    }
};
