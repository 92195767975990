import {
    sortArticles,
    parseArticle,
} from './helpArticleUtils.js';

export const DEFAULT_STATE = {
    article: {},
    articleId: '',
    articles: [],
    filteredArticles: [],
    isArticleFetching: false,
    isArticleIdFetching: false,
    isArticlesFetching: false,
};

const help = (state = DEFAULT_STATE, action) => {
    let sortedArticles;
    let parsedArticle;

    switch (action.type) {
        case 'HelpActions.ON_BEFORE_FETCHALLHELPARTICLES':
            return {
                ...state,
                isArticlesFetching: true,
            };
        case 'HelpActions.ON_FETCHALLHELPARTICLES':
            sortedArticles = sortArticles(action.result, 'module');

            return {
                ...state,
                articles: sortedArticles,
                isArticlesFetching: false,
            };
        case 'HelpActions.ON_BEFORE_GETHELPARTICLEBYID':
            return {
                ...state,
                isArticleFetching: true,
            };
        case 'HelpActions.ON_GETHELPARTICLEBYID':
            parsedArticle = parseArticle(action.result);
            return {
                ...state,
                article: parsedArticle,
                isArticleFetching: false,
            };
        case 'HelpActions.ON_BEFORE_FETCHFILTEREDHELPARTICLES':
            return { ...state, isFilteredArticleFetching: true };
        case 'HelpActions.ON_FETCHFILTEREDHELPARTICLES':
            // eslint-disable-next-line no-case-declarations
            const sortedArticlesFiltered = sortArticles(action.result, 'module');

            return {
                ...state,
                filteredArticles: sortedArticlesFiltered,
                isFilteredArticleFetching: false,
            };
        case 'HelpActions.ON_RESETFILTEREDHELPARTICLES':
            return { ...state, filteredArticles: DEFAULT_STATE.filteredArticles };
        case 'HelpActions.ON_RESETHELPARTICLE':
            return {
                ...state,
                article: DEFAULT_STATE.article,
                articleId: DEFAULT_STATE.articleId,
            };
        case 'HelpActions.ON_BEFORE_GETHELPARTICLE':
            return { ...state, isArticleIdFetching: true };
        case 'HelpActions.ON_GETHELPARTICLE':
            return {
                ...state,
                articleId: action.result[0].url,
                isArticleIdFetching: false,
            };
        default:
            return state;
    }
};

export default help;
