import ClassNames from 'classnames';
import { DropTarget } from 'react-dnd';
import Preview from 'react-dnd-preview';
import PropTypes from 'prop-types';
import React from 'react';
import HelpContextualFABContent from './contextualHelpFABContent.jsx';
import HelpContextualFAB from './contextualHelpFAB.jsx';

const propTypes = {
    allArticles: PropTypes.arrayOf(PropTypes.shape({})),
    article: PropTypes.PropTypes.shape({}),
    breakpoint: PropTypes.PropTypes.shape({
        clientHeight: PropTypes.number,
        clientWidth: PropTypes.number,
    }),
    connectDropTarget: PropTypes.func.isRequired,
    filteredArticles: PropTypes.arrayOf(PropTypes.shape({})),
    isContainerOpen: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

const defaultProps = {
    allArticles: undefined,
    article: undefined,
    breakpoint: undefined,
    filteredArticles: undefined,
    isContainerOpen: false,
};

const BUTTON_SIZE = 44;
const MARGIN = 8;

const RenderPreview = (itemType, item, style) => (
    <div
        className="item-list__item"
        style={({
            ...style,
            height: '44px',
            width: '44px',
        })}
    >
        <HelpContextualFAB />
    </div>
);

export class HelpContextualFABContainer extends React.Component {
    constructor() {
        super();

        this.state = {
            buttonXCoordinate: MARGIN,
            buttonYCoordinate: document.documentElement.clientHeight - (BUTTON_SIZE + MARGIN),
            contentXCoordinate: MARGIN,
            contentYCoordinate: null,
            isDragging: false,
            isUITop: false,
        };

        this.onButtonClick = this.onButtonClick.bind(this);
        this.onButtonDrag = this.onButtonDrag.bind(this);
    }

    componentDidMount() {
        if (this.helpContextualFABContentRef) {
            this.setContentYCoordinate(
                this.helpContextualFABContentRef.helpContextualFABContentRef,
            );
        }
    }

    componentDidUpdate(prevProps) {
        const {
            breakpoint: {
                clientHeight,
                clientWidth,
            },
        } = this.props;

        const {
            buttonXCoordinate,
            buttonYCoordinate,
        } = this.state;

        const content = document.querySelector('.help-contextual-fab-content');

        if (prevProps.breakpoint.clientHeight !== clientHeight) {
            let newButtonYCoordinate; let
                newContentYCoordinate;
            const contentHeight = this.getContentHeight(content);

            if (buttonYCoordinate < clientHeight / 2) {
                newButtonYCoordinate = MARGIN;
                newContentYCoordinate = BUTTON_SIZE + (MARGIN * 2);
            } else if (buttonYCoordinate > clientHeight / 2) {
                newButtonYCoordinate = clientHeight - (BUTTON_SIZE + MARGIN);
                newContentYCoordinate = clientHeight - (contentHeight + BUTTON_SIZE + (MARGIN * 2));
            }

            this.setState({
                buttonYCoordinate: newButtonYCoordinate,
                contentYCoordinate: newContentYCoordinate,
            });
        }

        if (prevProps.breakpoint.clientWidth !== clientWidth) {
            let newButtonXCoordinate; let
                newContentXCoordinate;
            const contentWidth = this.getContentWidth(content);

            if (buttonXCoordinate < clientWidth / 2) {
                newButtonXCoordinate = MARGIN;
                newContentXCoordinate = MARGIN;
            } else if (buttonXCoordinate > clientWidth / 2) {
                newButtonXCoordinate = clientWidth - (BUTTON_SIZE + MARGIN);
                newContentXCoordinate = clientWidth - (contentWidth + MARGIN);
            }

            this.setState({
                buttonXCoordinate: newButtonXCoordinate,
                contentXCoordinate: newContentXCoordinate,
            });
        }
    }

    onButtonClick() {
        const { onClick } = this.props;

        onClick();
    }

    onButtonDrag(isDragging) {
        this.setState({
            isDragging,
        });
    }

    getContentHeight(el) {
        let contentHeight;

        if (!el.style.display) {
            // eslint-disable-next-line no-param-reassign
            el.style.display = 'block';
            contentHeight = el.offsetHeight;
            // eslint-disable-next-line no-param-reassign
            el.style.display = null;
        } else {
            contentHeight = el.offsetHeight;
        }

        return contentHeight;
    }

    getContentWidth(el) {
        let contentWidth;

        if (!el.style.display) {
            // eslint-disable-next-line no-param-reassign
            el.style.display = 'block';
            contentWidth = el.offsetWidth;
            // eslint-disable-next-line no-param-reassign
            el.style.display = null;
        } else {
            contentWidth = el.offsetHeight;
        }

        return contentWidth;
    }

    setContentYCoordinate(el) {
        const contentHeight = this.getContentHeight(el);
        const vpHeight = document.documentElement.clientHeight;
        const yCoordinate = vpHeight - (contentHeight + BUTTON_SIZE + (MARGIN * 2));

        this.setState({
            contentYCoordinate: yCoordinate,
        });
    }

    buttonDropped(
        buttonXCoordinate,
        buttonYCoordinate,
        contentXCoordinate,
        contentYCoordinate,
        isUITop,
    ) {
        this.setState({
            buttonXCoordinate,
            buttonYCoordinate,
            contentXCoordinate,
            contentYCoordinate,
            isUITop,
        });
    }

    render() {
        const {
            allArticles,
            article,
            connectDropTarget,
            filteredArticles,
            isContainerOpen,
        } = this.props;

        const {
            buttonXCoordinate,
            buttonYCoordinate,
            contentXCoordinate,
            contentYCoordinate,
            isDragging,
            isUITop,
        } = this.state;

        const dimmerClassNames = ClassNames('help-contextual-fab-dimmer', {
            'is-closed': !isContainerOpen,
            'is-opened': isContainerOpen,
        });

        return connectDropTarget(
            <div
                className="help-contextual"
                id="help-contextual"
                style={{
                    pointerEvents: !isDragging ? 'none' : null,
                }}
            >
                <div
                    style={{
                        height: isDragging ? 1 : null,
                        opacity: isDragging ? 0 : null,
                    }}
                >
                    <HelpContextualFABContent
                        allArticles={allArticles}
                        article={article}
                        filteredArticles={filteredArticles}
                        isOpen={isContainerOpen}
                        isTop={isUITop}
                        ref={(ref) => { this.helpContextualFABContentRef = ref; }}
                        xCoordinate={contentXCoordinate}
                        yCoordinate={contentYCoordinate}
                    />

                    <HelpContextualFAB
                        isContainerOpen={isContainerOpen}
                        isDragging={isDragging}
                        onClick={this.onButtonClick}
                        onDrag={this.onButtonDrag}
                        xCoordinate={buttonXCoordinate}
                        yCoordinate={buttonYCoordinate}
                    />

                    <div className={dimmerClassNames} />
                </div>

                <Preview generator={RenderPreview} />
            </div>,
        );
    }
}

HelpContextualFABContainer.propTypes = propTypes;
HelpContextualFABContainer.defaultProps = defaultProps;

export default DropTarget(
    'helpContextualFAB',
    {
        drop(props, monitor, component) {
            if (!component) {
                return;
            }

            const delta = monitor.getDifferenceFromInitialOffset();
            const content = document.querySelector('.help-contextual-fab-content');
            const contentHeight = component.getContentHeight(content);
            const contentWidth = component.getContentWidth(content);
            const item = monitor.getItem();
            const vpHeight = document.documentElement.clientHeight;
            const vpWidth = document.documentElement.clientWidth;

            const droppedXCoordinate = Math.round(item.xCoordinate + delta.x);
            const droppedYCoordinate = Math.round(item.yCoordinate + delta.y);

            let buttonXCoordinate;
            let buttonYCoordinate;
            let contentXCoordinate;
            let contentYCoordinate;
            let isUITop;

            if (droppedXCoordinate < vpWidth / 2) {
                buttonXCoordinate = MARGIN;
                contentXCoordinate = MARGIN;
            } else if (droppedXCoordinate > vpWidth / 2) {
                buttonXCoordinate = vpWidth - (BUTTON_SIZE + MARGIN);
                contentXCoordinate = vpWidth - (contentWidth + MARGIN);
            }

            if (droppedYCoordinate < vpHeight / 2) {
                buttonYCoordinate = MARGIN;
                contentYCoordinate = BUTTON_SIZE + (MARGIN * 2);
                isUITop = true;
            } else if (droppedYCoordinate > vpHeight / 2) {
                buttonYCoordinate = vpHeight - (BUTTON_SIZE + MARGIN);
                contentYCoordinate = vpHeight - (contentHeight + BUTTON_SIZE + (MARGIN * 2));
            }

            component.buttonDropped(
                buttonXCoordinate,
                buttonYCoordinate,
                contentXCoordinate,
                contentYCoordinate,
                isUITop,
            );
        },
    },
    (connect) => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(HelpContextualFABContainer);
