/**
 * This enumeration contains Designation Type ID
 */
enum DesignationTypeId {
    General = 1,
    Project = 2,
    Fund = 3,
}

export default DesignationTypeId;
