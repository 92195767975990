import {
    isEmpty,
    isNil,
} from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
    i18n,
    UNKNOWN_CHURCH_ENTITY,
    DOES_NOT_ATTEND_CHURCH_ENTITY,
} from '../../../../global/constants.js';
import Form from '../../../../global/form';

const propTypes = {
    bemBlockName: PropTypes.string.isRequired,
    campusOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    disableFields: PropTypes.bool,
    isLitePersonalPanel: PropTypes.bool.isRequired,
    isPersonInfoVisible: PropTypes.bool.isRequired,
    personalInfo: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        cellPhone: PropTypes.string,
        churchEntityId: PropTypes.number,
        churchEntityKnown: PropTypes.bool,
        churchEntityName: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        formattedCellPhone: PropTypes.shape({}),
        formattedHomePhone: PropTypes.shape({}),
        formattedWorkPhone: PropTypes.shape({}),
        gender: PropTypes.string,
        homePhone: PropTypes.string,
        lastName: PropTypes.string,
        postalCode: PropTypes.string,
        region: PropTypes.string,
        workPhone: PropTypes.string,
    }).isRequired,
};

const defaultProps = {
    disableFields: false,
};

const mapStateToProps = (state) => {
    const {
        global: { churchCampuses },
    } = state;

    const campusOptions = churchCampuses.getAllCampusesForSelect({
        includeNulls: true,
        excludeExtensions: true,
    });

    return { campusOptions };
};

function FormEntryPersonalPanel({
    bemBlockName,
    campusOptions,
    disableFields,
    isLitePersonalPanel,
    isPersonInfoVisible,
    personalInfo,
}) {
    if (!isPersonInfoVisible) {
        return null;
    }

    const bemBlockElementName = `${bemBlockName}--field`;

    const {
        address1,
        address2,
        churchEntityId,
        churchEntityKnown,
        city,
        country,
        email,
        firstName,
        formattedCellPhone,
        formattedHomePhone,
        formattedWorkPhone,
        gender,
        lastName,
        postalCode,
        region,
    } = personalInfo ?? {};

    const hasCellPhone = !isEmpty(formattedCellPhone);
    const hasHomePhone = !isEmpty(formattedHomePhone);
    const hasWorkPhone = !isEmpty(formattedWorkPhone);

    /* eslint-disable no-nested-ternary */
    const phoneNumberWithHighestPrecedence =
        hasCellPhone ?
            formattedHomePhone :
            hasHomePhone ?
                formattedHomePhone :
                hasWorkPhone ?
                    formattedWorkPhone :
                    null;

    const hightPrecedencePhoneType =
        hasCellPhone ?
            'mobile' :
            hasHomePhone ?
                'home' :
                hasWorkPhone ?
                    'work' :
                    'none';
    /* eslint-enable no-nested-ternary */

    let selectedCampusId = churchEntityId;

    if (isNil(selectedCampusId)) {
        selectedCampusId = churchEntityKnown ?
            DOES_NOT_ATTEND_CHURCH_ENTITY :
            UNKNOWN_CHURCH_ENTITY;
    }

    return (
        <Form.Fieldset
            className={`${bemBlockName}--field_set`}
            id={`${bemBlockName}--field_set_personal_panel`}
            title={i18n('Personal')}
        >
            {/* First Name */}
            <Form.Field
                disable={disableFields}
                fieldId={`${bemBlockElementName}_first_name`}
                fieldType="inputText"
                label={i18n('First Name')}
                medium={6}
                value={firstName}
            />

            {/* Last Name */}
            <Form.Field
                disable={disableFields}
                fieldId={`${bemBlockElementName}_last_name`}
                fieldType="inputText"
                label={i18n('Last Name')}
                medium={6}
                value={lastName}
            />

            {/* Email */}
            <Form.Field
                disable={disableFields}
                fieldId={`${bemBlockElementName}_email`}
                fieldType="inputText"
                label={i18n('Email')}
                value={email}
            />

            {/* Address */}
            {!isLitePersonalPanel && (
                <React.Fragment>
                    <Form.Field
                        disable={disableFields}
                        fieldId={`${bemBlockElementName}_address1`}
                        fieldType="inputText"
                        label={i18n('Address 1')}
                        value={address1}
                    />

                    <Form.Field
                        disable={disableFields}
                        fieldId={`${bemBlockElementName}_address2`}
                        fieldType="inputText"
                        label={i18n('Address 2')}
                        value={address2}
                    />

                    <Form.Field
                        disable={disableFields}
                        fieldId={`${bemBlockElementName}_city`}
                        fieldType="inputText"
                        label={i18n('City')}
                        medium={6}
                        value={city}
                    />

                    <Form.Field
                        disable={disableFields}
                        fieldId={`${bemBlockElementName}_country`}
                        fieldType="selectCountry"
                        label={i18n('Country')}
                        medium={6}
                        value={country}
                    />

                    {!disableFields || !isEmpty(country) ? (
                        <Form.Field
                            disable={disableFields}
                            fieldId={`${bemBlockElementName}_region`}
                            fieldProps={{
                                countryAlpha3: country,
                            }}
                            fieldType="selectRegion"
                            label={i18n('Region')}
                            medium={6}
                            value={region}
                        />
                    ) : (
                        <Form.Field
                            disable={disableFields}
                            fieldId={`${bemBlockElementName}_region`}
                            fieldType="inputText"
                            label={i18n('Region')}
                            medium={6}
                            value={region}
                        />
                    )}

                    <Form.Field
                        disable={disableFields}
                        fieldId={`${bemBlockElementName}_postal_code`}
                        fieldType="inputText"
                        label={i18n('Postal Code')}
                        medium={6}
                        value={postalCode}
                    />
                </React.Fragment>
            )}

            {/* Phone Number(s) */}
            {isLitePersonalPanel ? (
                <Form.Field
                    disable={disableFields}
                    fieldId={`${bemBlockElementName}_phone_number`}
                    fieldProps={{
                        countryAlpha2: phoneNumberWithHighestPrecedence?.country,
                        isHome: hightPrecedencePhoneType === 'home',
                        isMobile: hightPrecedencePhoneType === 'mobile',
                        isWork: hightPrecedencePhoneType === 'work',
                    }}
                    fieldType="inputTel"
                    label={i18n('Phone')}
                    value={phoneNumberWithHighestPrecedence?.displayPhoneNumber}
                />
            ) : (
                <React.Fragment>
                    <Form.Field
                        disable={disableFields}
                        fieldId={`${bemBlockElementName}_mobile_phone_number`}
                        fieldProps={{
                            countryAlpha2: formattedCellPhone?.country,
                            isHome: false,
                            isMobile: true,
                            isWork: false,
                        }}
                        fieldType="inputTel"
                        label={i18n('Mobile Phone')}
                        value={formattedCellPhone?.displayPhoneNumber}
                    />

                    <Form.Field
                        disable={disableFields}
                        fieldId={`${bemBlockElementName}_home_phone_number`}
                        fieldProps={{
                            countryAlpha2: formattedHomePhone?.country,
                            isHome: true,
                            isMobile: false,
                            isWork: false,
                        }}
                        fieldType="inputTel"
                        label={i18n('Home Phone')}
                        value={formattedHomePhone?.displayPhoneNumber}
                    />

                    <Form.Field
                        disable={disableFields}
                        fieldId={`${bemBlockElementName}_work_phone_number`}
                        fieldProps={{
                            countryAlpha2: formattedWorkPhone?.country,
                            isHome: false,
                            isMobile: false,
                            isWork: true,
                        }}
                        fieldType="inputTel"
                        label={i18n('Work Phone')}
                        value={formattedWorkPhone?.displayPhoneNumber}
                    />
                </React.Fragment>
            )}

            {/* Gender */}
            <Form.Field
                disable={disableFields}
                fieldId={`${bemBlockElementName}_gender`}
                fieldProps={{
                    options: [
                        {
                            label: 'Female',
                            value: 'F',
                        },
                        {
                            label: 'Male',
                            value: 'M',
                        },
                    ],
                }}
                fieldType="select"
                label={i18n('Gender')}
                medium={6}
                value={gender}
            />

            {/* Campus */}
            <Form.Field
                disable={disableFields}
                fieldId={`${bemBlockElementName}_campus`}
                fieldProps={{
                    options: campusOptions,
                }}
                fieldType="select"
                label={i18n('Campus')}
                medium={6}
                value={selectedCampusId}
            />
        </Form.Fieldset>
    );
}

FormEntryPersonalPanel.propTypes = propTypes;
FormEntryPersonalPanel.defaultProps = defaultProps;

export default connect(mapStateToProps)(FormEntryPersonalPanel);
