import { Action } from '../../global/models';
import ActionType from '../actionTypes/inlineEditor.actionTypes';
import { StudentAgeGroup } from '../segmentSearch/segmentEdit/sections/demographic/constants';

export const INITIAL_STATE = {
    isEditing: false,
    isResetMode: false,
    isSaving: false,
    isValid: true,
    sectionType: '',
    additionalEngagement: {
        inMinistry: undefined,
        inSmallGroup: undefined,
        membershipStatuses: [],
    },
    demographic: {
        ageGroup: StudentAgeGroup.None,
        campusLocationIds: [],
        childGradeLevels: [],
        genders: [],
        hasChildren: null,
        maritalStatuses: [],
        maxAge: null,
        minAge: null,
        studentAgeGroupGradeLevels: [],
    },
    eventOptions: {
        event: null,
        eventId: null,
        eventType: null,
        hasEventName: null,
        includeParentDetails: false,
        startDate: null,
        endDate: null,
        occurrencesCount: null,
        occurrencesDidAttend: null,
        firstStreakCount: null,
        firstStreakDidAttend: null,
        secondStreakCount: null,
        secondStreakDidAttend: null,
    },
    milestones: [
        {
            afterDate: null,
            beforeDate: null,
            isAchieved: false,
            milestoneId: null,
            onDate: null,
        },
    ],
    milestoneGroupOperator: null,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.RESET_INLINE_EDITOR:
            return INITIAL_STATE;
        case ActionType.RESET_EVENT_OPTIONS:
            return {
                ...state,
                eventOptions: {
                    ...state.eventOptions,
                    startDate: null,
                    endDate: null,
                    occurrencesCount: null,
                    occurrencesDidAttend: null,
                    firstStreakCount: null,
                    firstStreakDidAttend: null,
                    secondStreakCount: null,
                    secondStreakDidAttend: null,
                    includeParentDetails: false,
                },
            };
        case ActionType.SET_INLINE_EDITOR_IS_EDITING:
            return {
                ...state,
                isEditing: action.payload,
            };
        case ActionType.SET_INLINE_EDITOR_IS_RESET_MODE:
            return {
                ...state,
                isResetMode: action.payload,
            };
        case ActionType.SET_INLINE_EDITOR_IS_SAVING:
            return {
                ...state,
                isSaving: action.payload,
            };
        case ActionType.SET_INLINE_EDITOR_IS_VALID:
            return {
                ...state,
                isValid: action.payload,
            };
        case ActionType.SET_INLINE_EDITOR_SECTION_TYPE:
            return {
                ...state,
                sectionType: action.payload,
            };
        case ActionType.SET_ADDITIONAL_ENGAGEMENT:
            return {
                ...state,
                additionalEngagement: {
                    ...state.additionalEngagement,
                    ...action.payload,
                },
            };
        case ActionType.SET_DEMOGRAPHIC:
            return {
                ...state,
                demographic: {
                    ...state.demographic,
                    ...action.payload,
                },
            };
        case ActionType.SET_EVENT_OPTIONS:
            return {
                ...state,
                eventOptions: {
                    ...state.eventOptions,
                    ...action.payload,
                },
            };
        case ActionType.SET_MILESTONES:
            return {
                ...state,
                milestones: action.payload,
            };
        case ActionType.SET_MILESTONES_GROUP_OPERATOR:
            return {
                ...state,
                milestoneGroupOperator: action.payload,
            };
        default:
            return state;
    }
};
