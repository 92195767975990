/**
 * This component renders a single Form Field of a Connection Forms 2.0 Form Entry.
 */

import React from 'react';
import { Grid } from '@saddlebackchurch/react-cm-ui';
import {
    isNil,
    isNull,
    isNumber,
    map,
} from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Form from '../../../../global/form';
import EntryFormAgreement from '../../entryEditor/entryFormAgreement';
import { FORM_ENTRY_FIELD_TYPES } from './constants.js';

const propTypes = {
    answer: PropTypes.shape({
        agreement: PropTypes.shape({
            version: PropTypes.number,
        }),
        agreementConsented: PropTypes.bool,
        booleanValue: PropTypes.bool,
        dateTimeValue: PropTypes.number, // UNIX Timestamp or NULL
        numericValue: PropTypes.number,
        textValue: PropTypes.string,
    }).isRequired,
    bemBlockName: PropTypes.string.isRequired,
    disable: PropTypes.bool,
    formField: PropTypes.shape({
        agreement: PropTypes.shape({
            version: PropTypes.number,
        }),
        isRequired: PropTypes.bool,
        question: PropTypes.shape({
            choices: PropTypes.arrayOf(PropTypes.shape({})),
            placeholder: PropTypes.string,
            questionTypeName: PropTypes.string,
            title: PropTypes.string,
        }),
        questionId: PropTypes.number,
        title: PropTypes.string,
    }).isRequired,
};

const defaultProps = {
    disable: false,
};

function FormEntryField({
    answer,
    bemBlockName,
    disable,
    formField,
}) {
    const {
        agreement: formAgreement,
        isRequired,
        question,
        questionId,
        title: fieldLabel,
    } = formField;

    let questionTypeName = question?.questionTypeName;
    const answerChoices = question?.choices;
    const placeholder = question?.placeholder;
    const questionTitle = question?.title;
    const responseLength = answer?.textValue?.length ?? 0;

    if (questionTypeName === FORM_ENTRY_FIELD_TYPES.singleLineText && responseLength > 50) {
        questionTypeName = FORM_ENTRY_FIELD_TYPES.paragraphText;
    }

    const bemBlockElementName = `${bemBlockName}--field`;

    const hasAnswer = !isNil(answer);

    switch (questionTypeName) {
        case FORM_ENTRY_FIELD_TYPES.checkbox:
        {
            const isChecked = hasAnswer && answer.booleanValue;
            const isRedacted = isNull(answer?.booleanValue);

            return (
                <Form.Field
                    disable={disable}
                    fieldId={`${bemBlockElementName}_checkbox_${questionId}`}
                    fieldProps={{
                        checked: isChecked,
                    }}
                    fieldType="inputCheckbox"
                    formatLabel={false}
                    isRedacted={isRedacted}
                    key={`${bemBlockElementName}_checkbox_${questionId}`}
                    label={fieldLabel || questionTitle}
                    required={isRequired}
                    value={questionId}
                />
            );
        }

        case FORM_ENTRY_FIELD_TYPES.singleLineText:
        {
            const isRedacted = isNull(answer?.textValue);

            return (
                <Form.Field
                    disable={disable}
                    fieldId={`${bemBlockElementName}_text_${questionId}`}
                    fieldType="inputText"
                    formatLabel={false}
                    isRedacted={isRedacted}
                    label={fieldLabel}
                    required={isRequired}
                    value={answer?.textValue ?? ''}
                />
            );
        }

        case FORM_ENTRY_FIELD_TYPES.paragraphText:
        {
            const isRedacted = isNull(answer?.textValue);

            return (
                <Form.Field
                    disable={disable}
                    fieldId={`${bemBlockElementName}_textarea_${questionId}`}
                    fieldProps={{
                        autoHeight: true,
                    }}
                    fieldType="textarea"
                    formatLabel={false}
                    isRedacted={isRedacted}
                    label={fieldLabel}
                    required={isRequired}
                    value={answer?.textValue ?? ''}
                />
            );
        }

        case FORM_ENTRY_FIELD_TYPES.multipleChoice:
        {
            const isRedacted = isNull(answer?.numericValue);

            return (
                <Grid.Column width={12}>
                    <Grid spacing={2}>
                        {fieldLabel && (
                            <Form.Legend
                                showRequiredIndicator={isRequired}
                                value={fieldLabel}
                            />
                        )}

                        {map(answerChoices, (option) => {
                            const fieldId = `${bemBlockElementName}_multiple_choice_option_${option.id}`;

                            // For the selected choice, value should be same as ID to render it selected
                            const fieldValue = hasAnswer && answer.numericValue === option.id ?
                                fieldId :
                                option.id;

                            return (
                                <Form.Field
                                    disable={disable}
                                    fieldId={fieldId}
                                    fieldType="inputRadio"
                                    formatLabel={false}
                                    isRedacted={isRedacted}
                                    key={fieldId}
                                    label={option.label}
                                    value={fieldValue}
                                />
                            );
                        })}
                    </Grid>
                </Grid.Column>
            );
        }

        case FORM_ENTRY_FIELD_TYPES.dropDown:
        {
            const isRedacted = isNull(answer?.numericValue);

            return (
                <Form.Field
                    disable={disable}
                    fieldId={`${bemBlockElementName}_dropdown_${questionId}`}
                    fieldProps={{
                        options: map(
                            answerChoices,
                            (ac) => ({
                                ...ac,
                                value: ac.id,
                            }),
                        ),
                        placeholder,
                    }}
                    fieldType="select"
                    formatLabel={false}
                    isRedacted={isRedacted}
                    label={fieldLabel}
                    required={isRequired}
                    value={answer?.numericValue ?? null}
                />
            );
        }

        case FORM_ENTRY_FIELD_TYPES.date:
        {
            const isRedacted = isNull(answer?.dateTimeValue);
            const date = isNumber(answer?.dateTimeValue) ?
                moment.unix(answer?.dateTimeValue).utc() :
                moment.utc(answer?.dateTimeValue);
            const value = answer?.dateTimeValue && date.isValid() ? date : null;

            return (
                <Form.Field
                    disable={disable}
                    fieldId={`${bemBlockElementName}_date_${questionId}`}
                    fieldType="inputDate"
                    formatLabel={false}
                    isRedacted={isRedacted}
                    label={fieldLabel}
                    required={isRequired}
                    value={value}
                />
            );
        }

        case undefined:
            if (!isNil(formAgreement)) {
                const agreement = answer?.agreement || formAgreement;

                return (
                    <EntryFormAgreement
                        agreement={agreement}
                        disable={disable}
                        isRequired={isRequired}
                        isVersionLatest={formAgreement?.version === answer?.agreement?.version}
                        question={question}
                        title={agreement.title}
                        value={answer?.agreementConsented}
                    />
                );
            }

        // eslint-disable-next-line no-fallthrough
        default:
            // eslint-disable-next-line no-console
            console.warn(`No rendering support for questions of type '${questionTypeName}'`);
            return null;
    }
}

FormEntryField.propTypes = propTypes;
FormEntryField.defaultProps = defaultProps;

export default FormEntryField;
