import {
    isEmpty,
    reduce,
} from 'lodash';

const fixedFloatingNumber = (x) => parseFloat(x).toFixed(2);

// eslint-disable-next-line import/prefer-default-export
export const getFollowUpsInsightsData = (statisticsData) => {
    let averageResponseTime = 0;
    let blocked = 0;
    let blockedEscalatedPercentage = 0;
    let blockedPastDuePercentage = 0;
    let blockedPercentage = 0;
    let escalated = 0;
    let inProgress = 0;
    let open = 0;
    let openEscalatedPercentage = 0;
    let openPastDuePercentage = 0;
    let openPercentage = 0;
    let pastDue = 0;
    let received = 0;
    let responded = 0;
    let successful = 0;
    let successfulPastDuePercentage = 0;
    let successfulPercentage = 0;
    let successfullyEscalatedPercentage = 0;
    let unsuccessful = 0;
    let unsuccessfulPastDuePercentage = 0;
    let unsuccessfulPercentage = 0;
    let unsuccessfullyEscalatedPercentage = 0;

    if (!isEmpty(statisticsData)) {
        received = reduce(statisticsData, (s, item) => s + parseInt(item.total, 10), 0);
        responded = statisticsData.successful.total + statisticsData.unsuccessful.total;
        inProgress = statisticsData.open.total + statisticsData.blocked.total;

        pastDue = reduce(statisticsData, (s, item, key) => {
            if (key === 'open' || key === 'blocked') {
                return s + parseInt(item.pastDue, 10);
            }
            return s;
        }, 0);

        escalated = reduce(statisticsData, (s, item) => s + parseInt(item.escalation, 10), 0);

        successful = statisticsData.successful.total;
        unsuccessful = statisticsData.unsuccessful.total;
        open = statisticsData.open.total;
        blocked = statisticsData.blocked.total;

        /* eslint-disable max-len */
        if (responded) {
            successfulPercentage = fixedFloatingNumber((statisticsData.successful.total / responded) * 100);
            unsuccessfulPercentage = fixedFloatingNumber((statisticsData.unsuccessful.total / responded) * 100);
            averageResponseTime = fixedFloatingNumber(((statisticsData.successful.averageResponseTimeInDays * successful) + (statisticsData.unsuccessful.averageResponseTimeInDays * unsuccessful)) / responded);
        }

        if (successful) {
            successfullyEscalatedPercentage = fixedFloatingNumber((statisticsData.successful.escalation / successful) * 100);
            successfulPastDuePercentage = fixedFloatingNumber((statisticsData.successful.pastDue / successful) * 100);
        }

        if (unsuccessful) {
            unsuccessfullyEscalatedPercentage = fixedFloatingNumber((statisticsData.unsuccessful.escalation / unsuccessful) * 100);
            unsuccessfulPastDuePercentage = fixedFloatingNumber((statisticsData.unsuccessful.pastDue / unsuccessful) * 100);
        }

        if (inProgress) {
            openPercentage = fixedFloatingNumber((statisticsData.open.total / inProgress) * 100);
            blockedPercentage = fixedFloatingNumber((statisticsData.blocked.total / inProgress) * 100);
        }

        if (open) {
            openEscalatedPercentage = fixedFloatingNumber((statisticsData.open.escalation / open) * 100);
            openPastDuePercentage = fixedFloatingNumber((statisticsData.open.pastDue / open) * 100);
        }

        if (blocked) {
            blockedEscalatedPercentage = fixedFloatingNumber((statisticsData.blocked.escalation / blocked) * 100);
            blockedPastDuePercentage = fixedFloatingNumber((statisticsData.blocked.pastDue / blocked) * 100);
        }
        /* eslint-enable max-len */
    }

    return {
        averageResponseTime,
        blocked,
        blockedEscalatedPercentage,
        blockedPastDuePercentage,
        blockedPercentage,
        escalated,
        inProgress,
        open,
        openEscalatedPercentage,
        openPastDuePercentage,
        openPercentage,
        pastDue,
        received,
        responded,
        successful,
        successfulPastDuePercentage,
        successfulPercentage,
        successfullyEscalatedPercentage,
        unsuccessful,
        unsuccessfulPastDuePercentage,
        unsuccessfulPercentage,
        unsuccessfullyEscalatedPercentage,
    };
};
