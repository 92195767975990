import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    message: PropTypes.string.isRequired,
};

function SummaryBannerInner(props) {
    const { message } = props;

    const link = <p className="no-margin-top">{message}</p>;

    return (
        <div className="no-margin-top no-margin-btm">
            {link}
        </div>
    );
}

SummaryBannerInner.propTypes = propTypes;

export default SummaryBannerInner;
