import {
    Button,
    Card,
    Divider,
    Header,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import {
    forEach,
    isEmpty,
    noop,
} from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars';
import withStyles from '@saddlebackchurch/react-cm-ui/core/styles/withStyles';
import {
    trackEvent,
} from '../../tools/analytics/index.js';
import {
    CONTEXTUAL_HELP_ARTICLE_NEW_TAB,
} from '../../tools/analytics/eventsToTrack.js';
import ArticleTitle from '../articleTitle.jsx';

const propTypes = {
    classes: PropTypes.shape({
        heading: PropTypes.string,
    }).isRequired,
    article: PropTypes.shape({
        contentItemId: PropTypes.string,
        contentItemVersionId: PropTypes.string,
        htmlBody: PropTypes.node,
        isPublished: PropTypes.bool,
        title: PropTypes.string,
        primaryHelpVideoLink: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
        }),
    }).isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

const defaultProps = {
    isOpen: false,
};

const styles = () => ({
    heading: {
        paddingRight: 7,
    },
});

class HelpContextualFABArticle extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            isClosed: true,
            isClosing: false,
            isOpened: false,
            isOpening: false,
            isVideoPoppedUp: false,
        };

        this.onCinemaModeToggle = this.onCinemaModeToggle.bind(this);
        this.onCloseAnimationComplete = this.onCloseAnimationComplete.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onHTMLBodyClick = this.onHTMLBodyClick.bind(this);
        this.onOpenAnimationComplete = this.onOpenAnimationComplete.bind(this);
        this.onOpenInNewTabClick = this.onOpenInNewTabClick.bind(this);
    }

    componentDidMount() {
        const { article } = this.props;

        if (!isEmpty(article) && this.helpContextualFABArticleRef) {
            const animationEvent = this.animationProps(this.helpContextualFABArticleRef);

            this.helpContextualFABArticleRef.addEventListener(
                animationEvent,
                this.onOpenAnimationComplete,
            );

            this.setState({
                isClosed: false,
                isOpening: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { article, isOpen } = this.props;
        const { isOpened } = this.state;

        if (this.helpContextualFABArticleRef && !prevProps.isOpen && isOpen) {
            const animationEvent = this.animationProps(this.helpContextualFABArticleRef);

            this.helpContextualFABArticleRef.addEventListener(
                animationEvent,
                this.onOpenAnimationComplete,
            );

            this.setState({
                isClosed: false,
                isOpening: true,
            });
        }

        if (
            this.helpContextualFABArticleRef &&
            isOpened &&
            !isEmpty(article) &&
            article.htmlBody
        ) {
            const imageArray = this.helpContextualFABArticleRef.querySelectorAll('img');

            forEach(imageArray, (img) => {
                const popUpContainer = document.createElement('div');
                popUpContainer.className = 'image-popup-container';
                img.parentNode.insertBefore(popUpContainer, img);
                popUpContainer.appendChild(img);

                const imageContainer = document.createElement('div');
                imageContainer.className = 'image-container';
                imageContainer.style.height = `${img.offsetHeight}px`;
                popUpContainer.parentNode.insertBefore(imageContainer, popUpContainer);
                imageContainer.appendChild(popUpContainer);
            });
        }
    }

    onOpenInNewTabClick() {
        const { article } = this.props;

        if (article.contentItemId) {
            window.open(`${window.location.origin}/help/article/${article.contentItemId}/${article.contentItemVersionId}`);

            trackEvent(
                CONTEXTUAL_HELP_ARTICLE_NEW_TAB,
                {
                    Action: {
                        ArticleId: article.contentItemId,
                        ArticleTitle: article.title,
                    },
                },
            );
        }
    }

    onCinemaModeToggle() {
        const { isVideoPoppedUp } = this.state;

        this.setState({
            isVideoPoppedUp: !isVideoPoppedUp,
        });
    }

    onCloseAnimationComplete() {
        const {
            article,
            onClose,
        } = this.props;

        const animationEvent = this.animationProps(this.helpContextualFABArticleRef);

        this.helpContextualFABArticleRef.removeEventListener(
            animationEvent,
            this.onCloseAnimationComplete,
        );

        this.setState({
            isClosed: true,
            isClosing: false,
            isOpened: false,
            isOpening: false,
        }, () => {
            onClose(article);
        });
    }

    onCloseClick() {
        const animationEvent = this.animationProps(this.helpContextualFABArticleRef);

        this.helpContextualFABArticleRef.addEventListener(
            animationEvent,
            this.onCloseAnimationComplete,
        );

        this.setState({
            isClosing: true,
            isOpened: false,
        });
    }

    onHTMLBodyClick(event) {
        if (event.target.tagName === 'IMG') {
            event.target.closest('.image-popup-container').classList.add('is-poped-up');
        }

        if (event.target.classList.contains('image-popup-container')) {
            event.target.closest('.image-popup-container').classList.remove('is-poped-up');
        }
    }

    onOpenAnimationComplete() {
        const animationEvent = this.animationProps(this.helpContextualFABArticleRef);

        this.helpContextualFABArticleRef.removeEventListener(
            animationEvent,
            this.onOpenAnimationComplete,
        );

        this.setState({
            isClosed: false,
            isClosing: false,
            isOpened: true,
            isOpening: false,
        });
    }

    // eslint-disable-next-line consistent-return
    animationProps(el) {
        let a;

        const animations = {
            animation: 'animationend',
            OAnimation: 'oAnimationEnd',
            MozAnimation: 'animationend',
            WebkitAnimation: 'webkitAnimationEnd',
        };

        // eslint-disable-next-line no-restricted-syntax
        for (a in animations) {
            if (el.style[a] !== undefined) {
                return animations[a];
            }
        }
    }

    render() {
        const {
            article,
            classes,
        } = this.props;

        const { isPublished } = article;

        const {
            isClosed,
            isClosing,
            isOpened,
            isOpening,
            isTop,
            isVideoPoppedUp,
        } = this.state;

        const rootClasses = ClassNames(
            'help-contextual-fab-article',
            {
                'is-closed': isClosed,
                'is-closing': isClosing,
                'is-opened': isOpened,
                'is-opening': isOpening,
                'is-top': isTop,
            },
        );

        const videoPopupContainerClasses = ClassNames(
            'video-popup-container',
            {
                'is-poped-up': isVideoPoppedUp,
            },
        );

        return (
            <div
                className={rootClasses}
                ref={(ref) => { this.helpContextualFABArticleRef = ref; }}
            >
                <Card nest>
                    <div>
                        <Icon
                            align="right"
                            className="help-contextual-fab-close-article"
                            onClick={this.onCloseClick}
                            type="times"
                        />

                        <div className="top-fade" />

                        <ScrollBar
                            className="help-contextual-fab-article-scrollbar"
                        >
                            {!isEmpty(article) ? (
                                <div style={{ padding: '14px 22px 22px 14px' }}>
                                    <ArticleTitle
                                        classes={{
                                            root: classes.heading,
                                        }}
                                        component="div"
                                        isPublished={isPublished}
                                        title={article.title}
                                        variant="h2"
                                    />

                                    {article.primaryHelpVideoLink.url ? (
                                        <div className="video" style={{ marginTop: '33px' }}>
                                            <Header size="medium">
                                                {article.primaryHelpVideoLink.text}
                                            </Header>

                                            <div className="video-container">
                                                <div className={videoPopupContainerClasses}>
                                                    <iframe
                                                        name="fitvid0"
                                                        src={article.primaryHelpVideoLink.url}
                                                        title="video"
                                                    />

                                                    {isVideoPoppedUp ? (
                                                        <Icon
                                                            className="video-popup-close"
                                                            compact
                                                            inverse
                                                            onClick={this.onCinemaModeToggle}
                                                            type="times"
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div
                                                className="our-video-controls"
                                            >
                                                <span
                                                    className="cinema-mode"
                                                    onClick={this.onCinemaModeToggle}
                                                    onKeyDown={noop}
                                                    role="button"
                                                    tabIndex={0}
                                                >
                                                    <span className="font-size-xsmall">
                                                        Cinema Mode
                                                    </span>

                                                    <Icon
                                                        align="right"
                                                        color="highlight"
                                                        type="arrows-alt"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}

                                    {article.htmlBody ? (
                                        <div className="html-body" style={{ marginTop: '33px' }}>
                                            <div
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: article.htmlBody,
                                                }}
                                                label={
                                                    !isPublished ? `${article.title} (draft)` : article.title
                                                }
                                                onClick={this.onHTMLBodyClick}
                                                onKeyDown={noop}
                                                role="button"
                                                tabIndex={0}
                                            />
                                        </div>
                                    ) : null}

                                    <div>
                                        <Divider relaxed />

                                        <Button
                                            onClick={this.onOpenInNewTabClick}
                                        >
                                            <span>Open in new tab</span>

                                            <Icon
                                                type="link-external"
                                            />
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </ScrollBar>
                    </div>
                </Card>
            </div>
        );
    }
}

HelpContextualFABArticle.propTypes = propTypes;
HelpContextualFABArticle.defaultProps = defaultProps;

export default withStyles(styles)(HelpContextualFABArticle);
