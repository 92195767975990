import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';
import { normalizeEvents } from '../../../event/eventsCentral/reducers/event.js';

const _localCache = new ChunkedPaginationUtils(25, 50);

export const DEFAULT_STATE = {
    items: [],
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: _localCache.getPageSize(),
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'WorshipServicesSearchActions.ON_RESET':
            return DEFAULT_STATE;

        case 'WorshipServicesSearchActions.ON_FILTERED':
            normalizeEvents(action.result);
            _localCache.loadPage(action.result, 0, (action.callbackParams && action.callbackParams.hasOwnProperty('first')) ? action.callbackParams.first : true);
            return {
                items: _localCache.getAll(true),
                canLoadMore: _localCache.canLoadMore(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageNumber: _localCache.getCurrentPageNumber(),
                pageSize: _localCache.getPageSize(),
            };

        case 'WorshipServicesSearchActions.ON_NEXTPAGE':
            normalizeEvents(action.result);
            return {
                items: _localCache.getAll(true),
                canLoadMore: _localCache.canLoadMore(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageNumber: _localCache.getCurrentPageNumber(),
                pageSize: _localCache.getPageSize(),
            };

        case 'WorshipServicesSearchActions.ON_CLOSED':
            normalizeEvents(action.result);
            _localCache.loadPage(action.result, 0, (action.callbackParams && action.callbackParams.hasOwnProperty('first')) ? action.callbackParams.first : true);
            return {
                items: _localCache.getAll(true),
                canLoadMore: _localCache.canLoadMore(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageNumber: _localCache.getCurrentPageNumber(),
                pageSize: _localCache.getPageSize(),
            };
    }

    return state;
}
