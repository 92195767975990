import './previewFeatureModal.scss';

import { Checkbox, Header } from '@saddlebackchurch/react-cm-ui';
import {
    find,
    isFunction,
    isEmpty,
} from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import bootstrapActions from './bootstrapActions.js';
import { i18n } from '../global/constants.js';

class Feature extends React.PureComponent {
    constructor() {
        super();

        this._onToggleChange = this._onToggleChange.bind(this);
    }

    render() {
        const {
            description,
            enabled,
            featureId,
            name,
            onAfterEnable,
        } = this.props;

        return (
            <div className="feature">
                <div className="feature-name-toggle">
                    <Header size="medium" weight="semibold">{name}</Header>

                    <div className="feature-toggle">
                        <Checkbox
                            checked={enabled}
                            id={`feature-${featureId}-toggle`}
                            onChange={(checkboxId, isEnabled) => this._onToggleChange(featureId, isEnabled, onAfterEnable)}
                            toggle
                        />
                    </div>
                </div>

                <div className="feature-desciption">
                    {description}
                </div>
            </div>
        );
    }

    _onToggleChange(featureId, isChecked, onAfterEnable) {
        bootstrapActions.togglePreviewFeature(featureId, isChecked)
            .then(() => {
                if (isChecked && isFunction(onAfterEnable)) {
                    onAfterEnable();
                }
            });
    }
}

Feature.propTypes = {
    description: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    featureId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    onAfterEnable: PropTypes.func,
};

Feature.defaultProps = {
    onAfterEnable: undefined,
};

const previewFeaturePropTypes = {
    visibleFeatureToggles: PropTypes.arrayOf(PropTypes.shape({})),
    previewFeatures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    description: PropTypes.string,
};

const previewFeatureDefaultProps = {
    description: '',
    visibleFeatureToggles: [],
};

class PreviewFeature extends React.PureComponent {
    render() {
        const {
            previewFeatures: currentPreviewFeatures,
            visibleFeatureToggles,
            description,
        } = this.props;

        const previewFeatures = !isEmpty(visibleFeatureToggles) ?
            currentPreviewFeatures.map((feature) => {
                const visibleFeatureToggle = find(visibleFeatureToggles, { name: feature.name });
                return {
                    ...(!!visibleFeatureToggle && {
                        ...visibleFeatureToggle,
                    }),
                    ...feature,
                    isVisible: !!visibleFeatureToggle,
                };
            }).filter((feature) => (feature.isVisible)) :
            currentPreviewFeatures;
        const contentDescription = description ||
            i18n('Use this menu to control which preview features you would like to try out.');

        return (
            <React.Fragment>
                <div>
                    {contentDescription}
                </div>

                <div className="features-list">
                    {_.map(previewFeatures, (pf) => (
                        <Feature
                            description={pf.description}
                            enabled={pf.enabled}
                            featureId={pf.id}
                            key={`preview-feature-${pf.id}`}
                            name={pf.name}
                            onAfterEnable={pf.onAfterEnable}
                        />
                    ))}

                </div>
            </React.Fragment>
        );
    }
}

PreviewFeature.propTypes = previewFeaturePropTypes;

PreviewFeature.defaultProps = previewFeatureDefaultProps;

const mapStateToProps = (state) => {
    const { bootstrap: { featureToggles } } = state;

    return {
        previewFeatures: featureToggles.getPreviewFeatures(),
    };
};

export default connect(mapStateToProps)(PreviewFeature);
