/* eslint-disable max-classes-per-file */ // this is totally justified in this case!
import request from 'axios';
import ApiUtils from '../global/apiUtils.js';
import EventsApiUtils from '../global/eventsApiUtils.js';

class CoreBootstrapActions extends ApiUtils { }

class EventsBootstrapActions extends EventsApiUtils { }

class BootstrapActions {
    constructor() {
        this.coreActions = new CoreBootstrapActions('/api/');
        this.coreActions.buildReduxActionClass(
            {
                name: 'getApiVersion',
                route: 'version',
                rq: request.get,
            }, {
                name: 'getBootstrap',
                route: 'bootstrap',
                rq: request.get,
            }, {
                name: 'getEnumerations',
                route: 'enumerations?expand=eventCategories',
                rq: request.get,
            }, {
                name: 'togglePreviewFeature',
                route: 'preview-feature/{featureId}',
                rq: request.put,
            }, {
                name: 'getRegionsByCountryCode',
                route: 'region/bycountry/{countryCode}',
                rq: request.get,
            },
        );

        this.eventsActions = new EventsBootstrapActions('/api/');
        this.eventsActions.buildReduxActionClass(
            {
                name: 'getBootstrap',
                route: 'bootstrap',
                rq: request.get,
            }, {
                name: 'getEnumerations',
                route: 'enumerations',
                rq: request.get,
            }, {
                name: 'getCheckInLabels',
                route: 'event/check-in/labels',
                rq: request.get,
            },
        );
    }

    getApiVersion() {
        return this.coreActions.getApiVersion();
    }

    getRegionsByCountryCode(params) {
        return this.coreActions.getRegionsByCountryCode(params);
    }

    getBootstrap() {
        return this.coreActions.getBootstrap();
    }

    getEnumerations() {
        return Promise.all([
            this.coreActions.getEnumerations(),
            this.eventsActions.getEnumerations(),
            this.eventsActions.getCheckInLabels(),
        ]);
    }

    togglePreviewFeature(featureId, isChecked) {
        const params = { featureId };

        const payload = {
            enabled: isChecked,
            id: featureId,
        };

        return this.coreActions.togglePreviewFeature(params, payload);
    }
}

const actions = new BootstrapActions();

export default actions;
