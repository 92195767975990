import keyMirror from 'keymirror';

// Scope values indicating access to "All" entities of a kind
export const SCOPE_ALL_MINISTRIES = 0;
export const SCOPE_ALL_REGIONS = 0;

// Set of defined permissions for the entire application
export const USER_PERMISSIONS = keyMirror({
    bypass: 0, // Used to indicate Nav Menu Items that do not require any permission to access
    accessPersonSearch: 1,
    createPersonalData: 2,
    readPersonalData: 3,
    updatePersonalData: 4,
    deletePersonalData: 5,
    accessDateOfBirth: 76,
    createPersonContactData: 6,
    readPersonContactData: 7,
    updatePersonContactData: 8,
    deletePersonContactData: 9,
    createPersonDocuments: 10,
    readPersonDocuments: 11,
    updatePersonDocuments: 12,
    deletePersonDocuments: 13,
    createPersonMilestones: 14,
    readPersonMilestones: 15,
    updatePersonMilestones: 16,
    deletePersonMilestones: 17,
    managePersonMembershipStatus: 18,
    createPersonRelationships: 19,
    readPersonRelationships: 20,
    updatePersonRelationships: 21,
    deletePersonRelationships: 22,
    createPrayerRequests: 23,
    readPrayerRequests: 24,
    updatePrayerRequests: 25,
    deletePrayerRequests: 26,
    createPersonNotes: 27,
    readPersonNotes: 28,
    updatePersonNotes: 29,
    deletePersonNotes: 30,
    createPersonSafetyFiles: 35,
    readPersonSafetyFiles: 36,
    updatePersonSafetyFiles: 37,
    deletePersonSafetyFiles: 38,
    accessPersonAuditLogs: 39,
    mergePersonRecords: 40,
    managePersonCredentials: 81,
    accessMinistryCentral: 41,
    createInterestedMinistryMembers: 77,
    extractMinistryMemberData: 78,
    enterMetricDataForCurrentPeriod: 43,
    enterMetricDataForAnyPeriod: 44,
    accessAllVenues: 45,
    manageMetrics: 46,
    createConnectionFormEntry: 47,
    readConnectionFormEntries: 48,
    updateConnectionFormEntry: 49,
    deleteConnectionFormEntry: 50,
    // ID 51 `accessSensitiveResponseCardFields` (below) should be used only with Connection Cards (Response Cards 1.0).
    // It should not be used with Connection Forms 2.0.
    // For that, use `readSensitiveConnectionQuestionAnswers` instead.
    accessSensitiveResponseCardFields: 51,
    createConnectionForm: 52,
    readConnectionForm: 53,
    updateConnectionForm: 54,
    deleteConnectionForm: 55,
    manageEventSchedules: 56,
    manageServicePeriods: 57,
    manageWorshipServices: 58,
    manageSpeakers: 59,
    extractPersonData: 60,
    extractConnectionCardData: 61,
    accessChurchInsightsDashboard: 63,
    // ID 62 ("Extract Workflow Data") is deprecated.  It referred to the the old legacy "Workflows" feature, not the current "Follow Ups" feature.
    accessWeekendFlashReport: 113,
    manageMedia: 64,
    manageChurchLocations: 65,
    manageVenues: 66,
    manageIntegrationDataSources: 67,
    performPersonDataImports: 68,
    accessSecurityAuditLogs: 69,
    manageSecurityRoles: 70,
    manageUserAccess: 71,
    directlyAssignPermissions: 80,
    performSensitiveAccessManagement: 72,
    manageGlobalApplicationSettings: 73,
    accessDeveloperDiagnostics: 74,
    manageTags: 75,
    // ID 79 ("Acess Workflow Tasks") is deprecated.  It referred to the the old legacy "Workflows" feature, not the current "Follow Ups" feature.
    createGlobalMinistry: 82,
    createLocalMinistry: 83,
    manageMinistryGlobalWebSettings: 84,
    manageMinistryLocalWebSettings: 85,
    manageMinistrySettingsAndPreferences: 86,
    createPersonOccupations: 87,
    readPersonOccupations: 88,
    updatePersonOccupations: 89,
    deletePersonOccupations: 90,
    createMinistryMembers: 91,
    updateMinistryMembers: 92,
    deleteMinistryMembers: 93,
    managePersonCredentialsEmail: 94,
    manageTeams: 95,
    updateLocalMinistryLeadership: 96,
    updateGlobalMinistryLeadership: 97,
    emailMinistryLeadership: 98,
    emailMinistryMembers: 99,
    emailServingOpportunityVolunteers: 100,
    sendSmsToServingOpportunityVolunteers: 154,
    updateGlobalMinistriesDetails: 101,
    updateGlobalMinistriesMembershipSettings: 102,
    updateLocalMinistryMembershipSettings: 103,
    updateInterestedMinistryMembers: 104,
    deleteInterestedMinistryMembers: 105,
    updateLocalMinistryDetails: 106,
    createServingOpportunities: 107,
    updateServingOpportunities: 108,
    manageServingOpportunityVolunteers: 109,
    manageRolesAndGroups: 110,
    accessGlobalMinistries: 111,
    accessLocalMinistries: 112,
    manageFollowUpRules: 114,
    createFreeEvent: 115,
    updateEvent: 116,
    cancelEvent: 117,
    readEvent: 118,
    manageConnectionQuestions: 119,
    readPersonEmailSubscriptions: 123,
    managePersonEmailSubscriptions: 124,
    accessFollowUpResponsesDashboard: 125,
    accessChurchHealthReport: 127,
    sendSmsToMinistryMembers: 129,
    accessPriorYearsDataForChurchInsightsDashboard: 131,
    manageServiceCompanionBuilder: 132,
    manageCampusServiceTemplate: 133,
    readCampusServiceTemplate: 196,
    readAllFollowUpTasks: 134,
    updateAllFollowUpTasks: 135,
    commentOnAllFollowUpTasks: 136,
    reassignAllFollowUpTasks: 137,
    readTeamMembers: 138,
    emailMultipleMinistryRosters: 139,
    createPersonEmergencyContacts: 140,
    readPersonEmergencyContacts: 141,
    updatePersonEmergencyContacts: 142,
    deletePersonEmergencyContacts: 143,
    createPaidEvent: 144,
    manageEventAttendees: 145,
    manageMinistryInvitations: 146,
    readConnectionQuestionAnswers: 147,
    readSensitiveConnectionQuestionAnswers: 148,
    updateFormOwners: 155,
    updateFormContributors: 156,
    managePersonRecordSettings: 149,
    manageEventSettings: 150,
    readPersonGiving: 153,
    manageCheckInSpaces: 157,
    manageCheckInAttendeesAndVolunteers: 158,
    manageCheckInChildAttendees: 159,
    overrideEventCheckInChildRoomRequirements: 160,
    browseInDraftMode: 161,
    readPersonEngagement: 162,
    manageCommunicationTemplate: 163,
    extractConnectionFormData: 164,
    manageJourneys: 167,
    manageCheckInVolunteersInServingOpportunities: 171,
    publishEventToMultipleCampuses: 172,
    publishServingOpportunityToMultipleCampuses: 173,
    manageAgreements: 188,
    createPersonAgreementEntries: 189,
    manageGeneralLedgerChartOfAccounts: 193,
    accessGivingCentral: 194,
    manageEmbeddedReportCategories: 197,
    manageEmbeddedReports: 198,
    printPersonData: 211,
    manageConnectionFormCategories: 212,
    managePromotionalPushCommunicationJob: 213,
    managePromotionalEmailCommunicationJob: 214,
    accessPersonGivingStatements: 215,
});
