import _ from 'lodash';

const DEFAULT_STATE = {
    selectedEmergencyContacts: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'EmergencyContactFormActions.ON_ADD_NEW_EMERGENCY_CONTACT': {
            const { value: person } = action;
            return {
                ...state,
                selectedEmergencyContacts: [
                    ...state.selectedEmergencyContacts,
                    person,
                ],
            };
        }

        case 'EmergencyContactFormActions.ON_CHANGE_RELATIONSHIP_EMERGENCY_CONTACT': {
            const { value: person } = action;
            return {
                ...state,
                selectedEmergencyContacts: _.map(state.selectedEmergencyContacts,
                    (item) => ((item.id === person.id) ? person : item)),
            };
        }

        case 'EmergencyContactFormActions.ON_REMOVE_SELECTED_EMERGENCY_CONTACT': {
            const { value: personId } = action;
            _.remove(state.selectedEmergencyContacts, { id: personId });
            return {
                ...state,
                selectedEmergencyContacts: [...state.selectedEmergencyContacts],
            };
        }

        case 'EmergencyContactFormActions.ON_RESET_SELECTED_EMERGENCY_CONTACT': {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
