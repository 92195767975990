import {
    isNil,
} from 'lodash';
import {
    DesignationTypeId,
} from '../models';
import {
    DesignationForEditor,
} from './models';
import ActionType from './designationEditor.actionTypes';

export const DEFAULT_STATE: DesignationForEditor = {
    companionAppHeader: null,
    contactEmail: null,
    id: null,
    description: '',
    designationCode: '',
    designationTypeId: DesignationTypeId.General,
    isActive: false,
    isDefault: false,
    isFeatured: false,
    matchGiverHomeCampus: false,
    name: '',
    revenueSubaccountId: null,
    shortDescription: '',
    thumbnail: null,
    webHeader: null,
};

export default (
    state: DesignationForEditor | undefined,
    action: any,
): DesignationForEditor => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.RESET_DATA: {
                return DEFAULT_STATE;
            }

            case ActionType.SET_COMPANION_APP_HEADER: {
                const { value: companionAppHeader } = action;

                return {
                    ...state,
                    companionAppHeader,
                };
            }

            case ActionType.SET_DESCRIPTION: {
                const { value: description } = action;

                return {
                    ...state,
                    description,
                };
            }

            case ActionType.SET_DESIGNATION_CODE: {
                const { value: designationCode } = action;

                return {
                    ...state,
                    designationCode,
                };
            }

            case ActionType.SET_DESIGNATION_CONTACT_EMAIL: {
                const { value: contactEmail } = action;

                return {
                    ...state,
                    contactEmail,
                };
            }

            case ActionType.SET_DESIGNATION_EDITOR_FORM_DATA: {
                const { value: formData } = action;

                return {
                    ...state,
                    ...formData,
                };
            }

            case ActionType.SET_DESIGNATION_IS_ACTIVE: {
                const { value: isActive } = action;

                return {
                    ...state,
                    isActive,
                };
            }

            case ActionType.SET_DESIGNATION_IS_DEFAULT: {
                const { value: isDefault } = action;

                return {
                    ...state,
                    isDefault,
                };
            }

            case ActionType.SET_DESIGNATION_IS_FEATURED: {
                const { value: isFeatured } = action;

                return {
                    ...state,
                    isFeatured,
                };
            }

            case ActionType.SET_DESIGNATION_MATCH_GIVER_HOME_CAMPUS: {
                const { value: matchGiverHomeCampus } = action;

                return {
                    ...state,
                    matchGiverHomeCampus,
                };
            }

            case ActionType.SET_DESIGNATION_TYPE_ID: {
                const { value: designationTypeId } = action;

                return {
                    ...state,
                    designationTypeId,
                };
            }

            case ActionType.SET_NAME: {
                const { value: name } = action;

                return {
                    ...state,
                    name,
                };
            }

            case ActionType.SET_REVENUE_SUB_ACCOUNT_ID: {
                const { value: revenueSubaccountId } = action;

                return {
                    ...state,
                    revenueSubaccountId,
                };
            }

            case ActionType.SET_THUMBNAIL: {
                const { value: thumbnail } = action;

                return {
                    ...state,
                    thumbnail,
                };
            }

            case ActionType.SET_SHORT_DESCRIPTION: {
                const { value: shortDescription } = action;

                return {
                    ...state,
                    shortDescription,
                };
            }

            case ActionType.SET_WEB_HEADER: {
                const { value: webHeader } = action;

                return {
                    ...state,
                    webHeader,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
