import {
    isEmpty,
    map,
} from 'lodash';
import {
    Grid,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';
import useMediaQuery from '@saddlebackchurch/react-cm-ui/core/utils/useMediaQuery';
import useTheme from '@saddlebackchurch/react-cm-ui/core/styles/useTheme';
import {
    IMAGES_PROP_TYPE,
} from './constants.js';
import {
    stepImages,
} from './utils.js';

const propTypes = {
    images: IMAGES_PROP_TYPE,
};

const defaultProps = {
    images: [],
};

const useStyles = makeStyles(({
    palette,
    shape,
}) => ({
    image: {
        backgroundColor: palette.static.main,
        borderRadius: shape.borderRadiusSecondary,
        boxShadow: [['inset', 0, 0, 0, 1, palette.static.main]],
        width: '100%',
    },
    imageColumn: {},
    root: {},
}));

function EducationalDialogImages(props) {
    const {
        images: imagesProp,
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isMediumBp = useMediaQuery(theme.breakpoints.up('md'));

    if (!isMediumBp || isEmpty(imagesProp)) {
        return null;
    }

    const images = stepImages(imagesProp);

    return (
        <Grid
            spacing={2}
        >
            {map(images, (image, index) => (
                <Grid.Column
                    key={index}
                    className={classes.imageColumn}
                    sm={Math.round(12 / images.length)}
                >
                    <img
                        alt={image.title}
                        className={classes.image}
                        src={image.url}
                    />
                </Grid.Column>
            ))}
        </Grid>
    );
}

EducationalDialogImages.propTypes = propTypes;
EducationalDialogImages.defaultProps = defaultProps;

export default EducationalDialogImages;
