import {
    Button,
    Icon,
    Image,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
    truncate,
    upperFirst,
} from 'lodash';
import ClassNames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import { i18n } from '../../../global/constants.js';
import personRecordUtils from './personRecordUtils.js';

const propTypes = {
    isMobile: PropTypes.bool,
    dueDate: PropTypes.shape({
        format: PropTypes.func.isRequired,
    }),
    onCloseClick: PropTypes.func,
    personNote: PropTypes.shape({}),
};

const defaultProps = {
    dueDate: undefined,
    isMobile: false,
    onCloseClick: undefined,
    personNote: undefined,
};

const blockClassName = 'person_note_detail';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        display: 'flex',
        marginTop: '22px',
        [theme.breakpoints.down(865)]: {
            marginBottom: '33px',
        },
        '&:first-child': {
            marginTop: 0,
        },
        '&:last-child': {
            borderBottom: 0,
        },
        '&:hover': {
            cursor: 'pointer',
            [theme.breakpoints.down(865)]: {
                cursor: 'default',
            },
        },
        [`& .${blockClassName}`]: {
            '&--left_column': {
                display: 'inline-flex',
                flexDirection: 'column',
                '& .ui.icon': {
                    marginBottom: '11px',
                },
            },
            '&--right_column': {
                width: '100%',
            },
            '&--date': {
                color: theme.palette.text.secondary,
                fontSize: '14px',
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '14px',
                [theme.breakpoints.down(865)]: {
                    fontSize: '12px',
                    lineHeight: '12px',
                },
            },
            '&--type': {
                color: theme.palette.text.secondary,
                fontSize: '14px',
                fontWeight: theme.typography.fontWeightMedium,
                justifyContent: 'flex-end',
                lineHeight: '14px',
                [theme.breakpoints.down(865)]: {
                    fontSize: '12px',
                    lineHeight: '12px',
                },
            },
            '&--header': {
                display: 'flex',
                justifyContent: 'space-between',
            },
            '&--actions': {
                alignItems: 'flex-end',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '55px',
            },
            '&--subject': {
                lineHeight: '20px',
                margin: '11px 0!important',
                [theme.breakpoints.down(865)]: {
                    fontSize: '16px!important',
                    lineHeight: '14px',
                },
            },
            '&--message': {
                color: theme.palette.text.primary,
                fontSize: '16px',
                lineHeight: '20px',
                whiteSpace: 'pre-wrap',
                [theme.breakpoints.down(865)]: {
                    fontSize: '14px',
                },
            },
            '&--footer': {
                marginTop: '11px',
                [theme.breakpoints.down(865)]: {
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            },
            '&--footer_meta_data': {
                display: 'flex',
            },
            '&--author': {
                lineHeight: '16px',
            },
            '&--author_name': {
                color: theme.palette.text.primary,
                fontSize: '14px',
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '14px',
                marginBottom: '7px',
                width: '100%',
            },
            '&--author_email': {
                color: theme.palette.text.secondary,
                fontSize: '12px',
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '12px',
            },
            '&--ministry_info': {
                marginTop: '11px',
            },
            '&--ministry_location_name': {
                color: theme.palette.text.secondary,
                fontSize: '14px',
                lineHeight: '13px',
            },
            '&--task': {
                marginTop: '11px',
            },
            '&--task_name': {
                color: theme.palette.text.secondary,
                fontSize: '14px',
                lineHeight: '13px',
            },
        },
    },
}));

const NOTE_CATEGORY_SENSITIVE = 2;
const NOTE_CATEGORY_MINISTRY = 6;
const NOTE_CATEGORY_FOLLOW_UPS = 7;

function PersonNoteDetail(props) {
    const {
        isMobile,
        onCloseClick,
        personNote: {
            churchEntity,
            createdBy,
            createdByEmail,
            createdByName,
            createdByAvatar,
            subject,
            ministry,
            noteText,
            isPinned,
            categoryName,
            modifyDate,
            categoryId,
            taskName,
        },
    } = props;

    const classes = useStyles();

    return (
        <div className={ClassNames(blockClassName, classes.root)}>
            <aside className={`${blockClassName}--left_column`}>
                <Icon
                    className={`${blockClassName}--indicator`}
                    color="warning"
                    type="cards"
                    role="button"
                />

                {isPinned && (
                    <Icon
                        className={`${blockClassName}--pinned_indicator`}
                        color="primary"
                        type="pin"
                        role="button"
                    />
                )}
            </aside>

            <div className={`${blockClassName}--right_column`}>
                <div className={`${blockClassName}--header`}>
                    <span className={`${blockClassName}--date`}>
                        {moment.unix(modifyDate).format('MM/DD/YY')}
                    </span>

                    <span className={`${blockClassName}--type`}>
                        {categoryName}
                    </span>
                </div>

                <Typography
                    className={`${blockClassName}--subject`}
                    variant="h2"
                >
                    {upperFirst(subject)}
                </Typography>

                <div
                    className={`${blockClassName}--message`}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: isMobile ?
                            truncate(noteText, { length: 85 }) : personRecordUtils.linkify(noteText),
                    }}
                />

                {(categoryId === NOTE_CATEGORY_SENSITIVE || categoryId === NOTE_CATEGORY_MINISTRY) &&
                    !isEmpty(ministry) &&
                    !isEmpty(churchEntity) && (
                        <div className={`${blockClassName}--ministry_info`}>
                            <span className={`${blockClassName}--ministry_location_name`}>
                                {`${ministry} | ${churchEntity}`}
                            </span>
                        </div>
                )}

                {(categoryId === NOTE_CATEGORY_SENSITIVE || categoryId === NOTE_CATEGORY_FOLLOW_UPS) && !isEmpty(taskName) && (
                    <div className={`${blockClassName}--task`}>
                        <span className={`${blockClassName}--task_name`}>
                            {taskName}
                        </span>
                    </div>
                )}

                {!isMobile ? (
                    <div
                        className={`${blockClassName}--footer`}
                        style={{
                            marginTop: '22px',
                        }}
                    >

                        <div className={`${blockClassName}--footer_meta_data`}>
                            <Image
                                className={`${blockClassName}--author_image`}
                                name={createdByName}
                                size={33}
                                src={createdByAvatar}
                                type="user"
                            />
                            <div
                                className={`${blockClassName}--author`}
                                style={{
                                    marginLeft: '11px',
                                }}
                            >
                                <div className={`${blockClassName}--author_name`}>
                                    {createdByName}
                                </div>
                                <div className={`${blockClassName}--author_email`}>
                                    {createdByEmail}
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                    (
                        <div className={`${blockClassName}--footer`}>
                            <div
                                className={`${blockClassName}--footer_meta_data`}
                            >
                                <span className={`${blockClassName}--author_name`}>
                                    {createdByName}
                                </span>
                            </div>
                        </div>
                    )}

                <div className={`${blockClassName}--actions`}>
                    <span className={`${blockClassName}--type`}>
                        <Button
                            id={`${blockClassName}--close_button`}
                            onClick={onCloseClick}
                        >
                            {i18n('Got it')}
                        </Button>
                    </span>
                </div>
            </div>
        </div>
    );
}

PersonNoteDetail.propTypes = propTypes;
PersonNoteDetail.defaultProps = defaultProps;

export default PersonNoteDetail;
