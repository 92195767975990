import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';

/**
 * TODO: Need permission checks for each of these baed on user ID for:
 * Personal, Relationships, CoreMilestones and all other implemented endpoints.
 * A hasPermission boolean should be passed from here to APIUtils to fire off an event updating
 * isFetching and a new property (TBD) to explain to the components why isFetching was toggled to
 * false and endpoint was never called.
 */

class CoreMilestonesActions extends ApiUtils {
    getCoreMilestonesRequest: ({ personId }: { personId: number }) => void;

    getCoreMilestonesByPersonIds: ({ personIds }: { personIds: number[] }) => void;

    getCoreMilestones({ personId }) {
        this.getCoreMilestonesRequest({ personId });
    }

    resetCoreMilestones() {
        appReduxStore.dispatch({
            type: 'CoreMilestonesActions.ON_RESET_CORE_MILESTONES',
        });
    }

    resetCoreMilestonesByPersonIds() {
        appReduxStore.dispatch({
            type: 'CoreMilestonesActions.ON_RESET_CORE_MILESTONES_BY_PERSON_IDS',
        });
    }

    requestGetCoreMilestonesByPersonIds(personIds) {
        this.getCoreMilestonesByPersonIds({ personIds });
    }
}

const actions = new CoreMilestonesActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'getCoreMilestonesRequest',
        route: 'insights/person/{personId}/core-milestone',
        rq: request.get,
    },
    {
        name: 'getCoreMilestonesByPersonIds',
        route: 'insights/person/core-milestone?personIds={personIds}',
        rq: request.get,
    },
);

export default actions;
