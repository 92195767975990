import useMediaQuery from '@saddlebackchurch/react-cm-ui/core/utils/useMediaQuery';
import useTheme from '@saddlebackchurch/react-cm-ui/core/styles/useTheme';
import PropTypes from 'prop-types';
import React from 'react';
import Navigation from './navigation/navigation.jsx';

const propTypes = {
    loginDisabled: PropTypes.bool,
    theme: PropTypes.shape({
        breakpoints: PropTypes.shape({
            only: PropTypes.func,
        }),
    }),
};

const defaultProps = {
    loginDisabled: false,
    theme: null,
};

function AppNavigation(props) {
    const {
        loginDisabled,
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('sm'));

    if (loginDisabled) {
        return null;
    }

    return (
        <Navigation isMobile={isMobile} />
    );
}

AppNavigation.propTypes = propTypes;
AppNavigation.defaultProps = defaultProps;

export default AppNavigation;
