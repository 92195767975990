import { combineReducers } from 'redux';
import index from './indexReducer.js';
import relationship from './global/relationshipReducer.js';
import relation from './global/relationReducer.js';

const reducers = combineReducers({
    relationship,
    index,
    relation,
});

export default reducers;
