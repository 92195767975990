import _ from 'lodash';
import Utils from '../global/utils/utils.js';

export function transformEventFactGroups(eventFactGroups) {
    _.forEach(eventFactGroups, (evtFactGrp) => {
        _.forEach(evtFactGrp.venues, (venue) => {
            _.forEach(venue.facts, (fact) => {
                // Persistence "state" flags
                fact.needsSaving = false;
                fact.isSaving = false;
                fact.wasSaved = false;

                fact.id = fact.id || -Utils.getIncreasingUniqueKey();
                fact.venueId = venue.venueId;
                fact.scheduleId = evtFactGrp.scheduleId;

                if (evtFactGrp.occurrenceId > 0) {
                    fact.occurrenceId = evtFactGrp.occurrenceId;
                } else {
                    fact.occurrenceDate = evtFactGrp.occurrenceDate;
                    fact.occurrenceStartTime = evtFactGrp.occurrenceStartTime;
                }
            });
        });
    });

    return eventFactGroups;
}

export function updateFactValue(eventFactGroup, venueId, factId, value) {
    return updateFactObject(eventFactGroup, venueId, factId, { value });
}

export function setFactNeedsSaving(eventFactGroup, venueId, factId) {
    return updateFactObject(
        eventFactGroup,
        venueId,
        factId,
        {
            needsSaving: true,
            wasSaved: false,
        },
    );
}

export function setFactIsSaving(eventFactGroup, venueId, factId) {
    return updateFactObject(
        eventFactGroup,
        venueId,
        factId,
        {
            isSaving: true,
            needsSaving: false,
        },
    );
}

export function updateSavedFact(eventFactGroup, venueId, oldFactId, newFactId) {
    return updateFactObject(
        eventFactGroup,
        venueId,
        oldFactId,
        {
            id: newFactId,
            isSaving: false,
            needsSaving: false,
            wasSaved: true,
        },
    );
}

function updateFactObject(eventFactGroup, venueId, factId, factPropertyUpdates) {
    if (_.isEmpty(eventFactGroup)) {
        return eventFactGroup;
    }

    const updatedEventFactGroup = {
        ...eventFactGroup,
        venues: _.map(eventFactGroup.venues, (venue) => {
            if (venue.venue.id === venueId) {
                const updatedVenue = {
                    ...venue,
                    facts: _.map(venue.facts, (fact) => {
                        if (fact.id === factId) {
                            const updatedFact = { ...fact, ...factPropertyUpdates };

                            return updatedFact;
                        }

                        return fact;
                    }),
                };

                return updatedVenue;
            }

            return venue;
        }),
    };

    return updatedEventFactGroup;
}
