import {
    Card,
    Icon,
    Input,
    TitleBar,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
    startCase,
    uniqueId,
} from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars';
import { filterArticles } from '../helpArticleUtils.js';
import {
    ENTER_KEY_CODE,
    BACKSPACE_KEY_CODE,
} from '../../global/keyCodeConstants.js';
import {
    i18n,
} from '../../global/constants.js';
import {
    trackEvent,
} from '../../tools/analytics/index.js';
import {
    CONTEXTUAL_HELP_ARTICLE_CLOSED,
    CONTEXTUAL_HELP_ARTICLE_OPENED,
} from '../../tools/analytics/eventsToTrack.js';
import helpActions from '../help.actions.js';
import HelpContextualFABArticle from './contextualHelpFABArticle.jsx';
import HelpContextualFABArticlePreview from './contextualHelpFABPArticlePreview.jsx';

const propTypes = {
    allArticles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    article: PropTypes.shape({}).isRequired,
    filteredArticles: PropTypes.arrayOf(PropTypes.shape({})),
    isOpen: PropTypes.bool,
    isTop: PropTypes.bool,
    xCoordinate: PropTypes.number.isRequired,
    yCoordinate: PropTypes.number,
};

const defaultProps = {
    filteredArticles: undefined,
    isOpen: false,
    isTop: false,
    yCoordinate: undefined,
};

class HelpContextualFABContent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            isArticleOpen: false,
            isClosed: true,
            isClosing: false,
            isOpened: false,
            isOpening: false,
            searchInputValue: '',
            searchKeyWord: '',
        };

        this.onArticlePreviewClick = this.onArticlePreviewClick.bind(this);
        this.onArticleClose = this.onArticleClose.bind(this);
        this.onCloseAnimationComplete = this.onCloseAnimationComplete.bind(this);
        this.onOpenAnimationComplete = this.onOpenAnimationComplete.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchClearClick = this.onSearchClearClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.searchInputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { article, isOpen } = this.props;

        if (!prevProps.isOpen && isOpen && this.helpContextualFABContentRef) {
            const animationEvent = this.animationProps(this.helpContextualFABContentRef);

            this.helpContextualFABContentRef.addEventListener(
                animationEvent,
                this.onOpenAnimationComplete,
            );

            this.setState({
                isClosed: false,
                isOpening: true,
            });
        }

        if (prevProps.isOpen && !isOpen && this.helpContextualFABContentRef) {
            const animationEvent = this.animationProps(this.helpContextualFABContentRef);

            this.helpContextualFABContentRef.addEventListener(
                animationEvent,
                this.onCloseAnimationComplete,
            );

            this.setState({
                isClosing: true,
                isOpened: false,
            });
        }

        if (isEmpty(prevProps.article) && !isEmpty(article)) {
            this.setState({
                isArticleOpen: true,
            });
        }
    }

    onArticleClose(article) {
        const {
            contentItemId,
            title,
        } = article;

        helpActions.resetHelpArticle();

        this.setState({
            isArticleOpen: false,
        }, () => {
            trackEvent(
                CONTEXTUAL_HELP_ARTICLE_CLOSED,
                {
                    Action: {
                        ArticleId: contentItemId,
                        ArticleTitle: title,
                    },
                },
            );
        });
    }

    onArticlePreviewClick(article) {
        const {
            contentItemId,
            contentItemVersionId,
        } = article;

        if (article.contentItemId) {
            helpActions.getHelpArticleById({
                contentId: contentItemId,
                versionId: contentItemVersionId,
            });

            this.setState({
                isArticleOpen: true,
            }, () => {
                trackEvent(
                    CONTEXTUAL_HELP_ARTICLE_OPENED,
                    {
                        Action: {
                            ArticleId: contentItemId,
                            ArticleTitle: article.title,
                        },
                    },
                );
            });
        }
    }

    onCloseAnimationComplete() {
        const animationEvent = this.animationProps(this.helpContextualFABContentRef);

        this.helpContextualFABContentRef.removeEventListener(
            animationEvent,
            this.onCloseAnimationComplete,
        );

        this.setState({
            isClosed: true,
            isClosing: false,
            isOpened: false,
            isOpening: false,
        });
    }

    onOpenAnimationComplete() {
        const animationEvent = this.animationProps(this.helpContextualFABContentRef);

        this.helpContextualFABContentRef.removeEventListener(
            animationEvent,
            this.onOpenAnimationComplete,
        );

        this.setState({
            isClosed: false,
            isClosing: false,
            isOpened: true,
            isOpening: false,
        });
    }

    onSearchChange(value) {
        this.setState({
            searchInputValue: value,
            // Useful when the user selects all the input text and press 'Backspace'
            ...(value === '' && { searchKeyWord: '' }),
        });
    }

    onKeyDown(event) {
        const {
            searchInputValue,
        } = this.state;
        const {
            keyCode,
            target: { value },
        } = event;
        // eslint-disable-next-line max-len
        const shouldTriggerSearch = keyCode === ENTER_KEY_CODE || (value.length === 1 && keyCode === BACKSPACE_KEY_CODE);
        if (shouldTriggerSearch) {
            this.setState({
                searchKeyWord: searchInputValue,
            });
        }
    }

    onSearchClearClick() {
        this.setState({
            searchInputValue: '',
            searchKeyWord: '',
        });
    }

    animationProps(el) {
        let a;

        const animations = {
            animation: 'animationend',
            OAnimation: 'oAnimationEnd',
            MozAnimation: 'animationend',
            WebkitAnimation: 'webkitAnimationEnd',
        };

        // eslint-disable-next-line no-restricted-syntax
        for (a in animations) {
            if (el.style[a] !== undefined) {
                return animations[a];
            }
        }

        return null;
    }

    render() {
        const {
            allArticles,
            article,
            filteredArticles,
            isTop,
            xCoordinate,
            yCoordinate,
        } = this.props;

        const {
            isArticleOpen,
            isClosed,
            isClosing,
            isOpened,
            isOpening,
            searchInputValue,
            searchKeyWord,
        } = this.state;

        const containerClasses = ClassNames('help-contextual-fab-content', {
            'is-closed': isClosed,
            'is-closing': isClosing,
            'is-opened': isOpened,
            'is-opening': isOpening,
            'is-top': isTop,
        });

        let articles = allArticles;
        let title = 'All Help Resources';
        let shouldShowModuleLabel = true;
        let moduleKeyCounter = 0;

        if (!isEmpty(filteredArticles)) {
            articles = filteredArticles;
            title = articles[0].name;
            shouldShowModuleLabel = false;
        }

        if (searchKeyWord) {
            const filteredArticlesByTitle = filterArticles(articles, 'title', searchKeyWord);
            articles = filteredArticlesByTitle;
        }

        // This way we're not passing a very long string as className prop.
        const moduleHeaderStyles = ClassNames(
            'ui',
            'header',
            'header-size-medium',
            'header-weight-bold',
            'help-contextual-fab-article--module-label',
        );

        const emptyArticleCards = [];

        if (isEmpty(articles)) {
            if (searchKeyWord) {
                emptyArticleCards.push(
                    <div className="help-contextual-fab-module-container">
                        <div className={moduleHeaderStyles}>
                            { i18n(`No results for '${searchKeyWord}' found`) }
                        </div>
                    </div>,
                );
            }
            for (let i = 0; i < 4; i += 1) {
                emptyArticleCards.push(
                    <Card
                        key={`help-contextual-fab-article-preview-${i}`}
                        className="help-contextual-fab-article-preview"
                        style={{ height: '87px' }}
                    />,
                );
            }
        }

        return (
            <div
                className={containerClasses}
                ref={(ref) => { this.helpContextualFABContentRef = ref; }}
                style={{
                    left: xCoordinate,
                    top: yCoordinate,
                }}
            >
                <div
                    className="help-contextual-fab-content-inner"
                    style={{
                        height: '100%',
                    }}
                >
                    <HelpContextualFABArticle
                        article={article}
                        isOpen={isArticleOpen}
                        isTop={isTop}
                        onClose={this.onArticleClose}
                    />

                    <ScrollBar
                        className="all-help-resources-scroll-bar"
                    >
                        <div style={{
                            padding: '0 11px 11px',
                        }}
                        >
                            <TitleBar title={title} />

                            <div className="help-contextual-fab-article-cards">
                                {!isEmpty(articles) ? articles.map((module) => {
                                    moduleKeyCounter += 1;

                                    const moduleArticles = (
                                        <div
                                            key={`help-contextual-fab-module-container-${moduleKeyCounter}`}
                                            className="help-contextual-fab-module-container"
                                        >
                                            {shouldShowModuleLabel && (
                                                <div className={moduleHeaderStyles}>
                                                    {startCase(module.name)}
                                                </div>
                                            )}

                                            {module.articles.map((moduleArticle) => (
                                                <HelpContextualFABArticlePreview
                                                    article={moduleArticle}
                                                    key={`${moduleArticle.url}-${uniqueId()}`}
                                                    onClick={this.onArticlePreviewClick}
                                                    description={moduleArticle.description}
                                                />
                                            ))}
                                        </div>
                                    );

                                    return moduleArticles;
                                }) : (
                                    <div className="preview-skeleton">
                                        {emptyArticleCards}
                                    </div>
                                )}
                            </div>
                        </div>
                    </ScrollBar>
                </div>

                <footer>
                    <Input
                        className="ui-input--help_fab_search"
                        fluid
                        icon={searchInputValue ?
                            <Icon compact onClick={this.onSearchClearClick} type="times" /> :
                            <Icon compact type="search" />}
                        onChange={this.onSearchChange}
                        onKeyDown={this.onKeyDown}
                        placeholder="Search by topic"
                        ref={this.searchInputRef}
                        style={{
                            border: 0,
                        }}
                        value={searchInputValue}
                    />
                </footer>
            </div>
        );
    }
}

HelpContextualFABContent.propTypes = propTypes;
HelpContextualFABContent.defaultProps = defaultProps;

export default HelpContextualFABContent;
