import { combineReducers } from 'redux';
import topLevel from './editorReducer.js';
import ruleFollowUpPushNotifications from './ruleActions/followUpTaskPushNotifications/followUpTaskPushNotificationTemplates.reducer';
import triggerMetadata from './ruleTrigger/ruleTriggerMetadataReducer.js';

const reducers = combineReducers({
    topLevel,
    triggerMetadata,
    ruleFollowUpPushNotifications,
});

export default reducers;
