import { noop } from 'lodash';
import { InjectedRouter } from 'react-router';
import {
    resetNavigationItems,
    updateNavigationItems,
} from '../../../app/navigation/navigationUtils.jsx';
import {
    PATH_PEOPLE_SEARCH_OR_ADD,
    TITLE_PEOPLE_SEARCH_OR_ADD,
} from '../../global/constants.js';
import { PERSON_RECORD_TITLE } from '../recordConstants';

// eslint-disable-next-line import/prefer-default-export
export const updateRecordNavigationItems = (personId: number, router: InjectedRouter) => {
    if (!personId || !router) {
        return;
    }

    const currentPath = `${PATH_PEOPLE_SEARCH_OR_ADD}/record/${personId}`;
    const currentTitle = `${PERSON_RECORD_TITLE} ${personId}`;

    const nextNavigationHeaderBreadcrumbs = [
        {
            to: PATH_PEOPLE_SEARCH_OR_ADD,
            title: TITLE_PEOPLE_SEARCH_OR_ADD,
            onBreadcrumbClick: () => {
                resetNavigationItems(true, TITLE_PEOPLE_SEARCH_OR_ADD);
                router.push(PATH_PEOPLE_SEARCH_OR_ADD);
            },
        },
        {
            to: currentPath,
            title: currentTitle,
            onBreadcrumbClick: noop,
        },
    ];

    const nextNavigationBreadcrumbs = [
        {
            items: [],
            parentItem: [],
            title: TITLE_PEOPLE_SEARCH_OR_ADD,
            onClick: () => {
                resetNavigationItems(true, TITLE_PEOPLE_SEARCH_OR_ADD);
                router.push(PATH_PEOPLE_SEARCH_OR_ADD);
            },
        },
        {
            items: [],
            parentItem: { segment: 'people' },
            onClick: noop,
            title: currentTitle,
        },
    ];

    updateNavigationItems(
        [],
        nextNavigationHeaderBreadcrumbs,
        nextNavigationBreadcrumbs,
    );
};
