import ActionType from '../communicationsCentral.actionTypes';
import { Action } from '../../global/models';

const INITIAL_STATE = '';

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_SEARCH_QUERY:
            return action.payload;
        case ActionType.CLEAR_SEARCH_QUERY:
            return INITIAL_STATE;
        default:
            return state;
    }
};
