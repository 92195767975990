import { Grid } from '@saddlebackchurch/react-cm-ui';
import {
    find,
    isNil,
    map,
} from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from '../../global/i18nUtils.js';
import Form from '../../global/form';

const i18n = translationFactory('ConnectionCards.Global.EntryTemplateFields');

const propTypes = {
    bemBlockName: PropTypes.string.isRequired,
    disable: PropTypes.bool,
    entryFieldData: PropTypes.shape({
        dateTimeValue: PropTypes.number,
        fieldLabel: PropTypes.string,
        fieldType: PropTypes.string.isRequired,
        responseCardFieldId: PropTypes.number.isRequired,
        textValue: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    templateFieldData: PropTypes.shape({
        choices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
};

const defaultProps = {
    disable: false,
};

class ConnectionCardEntryFormFields extends React.PureComponent {
    render() {
        const {
            bemBlockName,
            disable,
            entryFieldData,
            templateFieldData,
        } = this.props;
        const {
            dateTimeValue,
            fieldLabel,
            fieldType,
            responseCardFieldId,
            textValue,
            values: subFields,
        } = entryFieldData;
        const bemBlockElementName = `${bemBlockName}--field`;

        switch (fieldType) {
            case 'address': { // RC UI Label: Address Template Fields
                const countryObject = find(subFields, { subFieldLabel: 'Country' });
                const countryAlpha3 = countryObject && countryObject.textValue;

                return map(subFields, (subField) => {
                    const { subFieldLabel } = subField;
                    let medium;
                    let newFieldType = 'inputText';
                    let fieldProps = {};

                    switch (subFieldLabel) {
                        case 'City':
                        case 'PostalCode':
                            medium = 6;

                            break;
                        case 'Region':
                            medium = 6;

                            if (countryAlpha3) {
                                newFieldType = 'selectRegion';
                                fieldProps = {
                                    ...fieldProps,
                                    countryAlpha3,
                                };
                            } else {
                                newFieldType = 'inputText';
                            }

                            break;
                        case 'Country':
                            medium = 6;
                            newFieldType = 'selectCountry';

                            break;
                        default:
                    }

                    return (
                        <Form.Field
                            disable={disable}
                            fieldId={`${bemBlockElementName}_${subField.responseCardSubFieldId}`}
                            fieldProps={fieldProps}
                            fieldType={newFieldType}
                            key={`${bemBlockElementName}_${subField.responseCardSubFieldId}`}
                            label={subFieldLabel}
                            medium={medium}
                            value={subField.textValue}
                        />
                    );
                });
            }

            case 'checkboxes': // RC UI Label: Checkbox(es)
            case 'home_church_entity': // RC UI Label: Home Church Checkbox Template
            case 'next_steps': // RC UI Label: Next Steps Checkboxes Template
            case 'class_signup': // RC UI Label: CLASS Signup Template
            case 'subscriptions': // RC UI Label: Subscriptions Template
                return (
                    <Grid.Column width={12}>
                        <Grid spacing={2}>
                            {fieldLabel && <Form.Legend value={fieldLabel} />}

                            {map(subFields, (subField) => {
                                const isChecked = subField.textValue?.toLocaleLowerCase() === 'true';

                                return (
                                    <Form.Field
                                        disable={disable}
                                        fieldId={`${bemBlockElementName}_${subField.responseCardSubFieldId}`}
                                        fieldProps={{
                                            checked: isChecked,
                                        }}
                                        formatLabel={fieldType === 'home_church_entity'}
                                        fieldType="inputCheckbox"
                                        key={`${bemBlockElementName}_${subField.responseCardSubFieldId}`}
                                        label={subField.subFieldLabel}
                                        value={subField.id}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid.Column>
                );

            case 'date': // RC UI Label: Date
                return (
                    <Form.Field
                        disable={disable}
                        fieldId={`${bemBlockElementName}_${responseCardFieldId}`}
                        fieldType="inputDate"
                        label={fieldLabel}
                        value={!isNil(dateTimeValue) ? moment.unix(dateTimeValue).utc() : null}
                    />
                );

            case 'email': // RC UI Label: Email Template Field
                return (
                    <Form.Field
                        disable={disable}
                        fieldId={`${bemBlockElementName}_${responseCardFieldId}`}
                        fieldType="inputEmail"
                        label={i18n('Email')}
                        value={textValue}
                    />
                );

            case 'name': // RC UI Label: (Long) Name Template Fields
            case 'name_abbreviated': // UI Label: (Short) Name Template Fields
                return map(subFields, (subField, index) => {
                    const subFieldCount = index + 1;
                    const isLastSubField = subFields.length === subFieldCount;
                    const isLastSubFieldOdd = isLastSubField && subFieldCount % 2;

                    return (
                        <Form.Field
                            disable={disable}
                            fieldId={`${bemBlockElementName}_${subField.responseCardSubFieldId}`}
                            fieldType="inputText"
                            key={`${bemBlockElementName}_${subField.responseCardSubFieldId}`}
                            label={subField.subFieldLabel}
                            medium={!isLastSubFieldOdd ? 6 : undefined}
                            value={subField.textValue}
                        />
                    );
                });

            case 'phone': // RC UI Label: Phone Template Fields
                return map(subFields, (subField) => (
                    <Form.Field
                        disable={disable}
                        fieldId={`${bemBlockElementName}_${subField.responseCardSubFieldId}`}
                        fieldProps={{
                            isHome: subField.subFieldLabel === 'HomePhone',
                            isMobile: subField.subFieldLabel === 'CellPhone',
                            isWork: subField.subFieldLabel === 'WorkPhone',
                        }}
                        fieldType="inputTel"
                        key={`${bemBlockElementName}_${subField.responseCardSubFieldId}`}
                        label={i18n('Phone')}
                        value={subField.textValue}
                    />
                ));

            case 'radio': // RC UI Label: Multiple Choice
            case 'already_beginning_considering_dontever':
                return (
                    <Grid.Column width={12}>
                        <Grid spacing={2}>
                            {fieldLabel && <Form.Legend value={fieldLabel} />}

                            {map(templateFieldData.choices, (option) => (
                                <Form.Field
                                    disable={disable}
                                    fieldId={`${bemBlockElementName}_${option.id}`}
                                    formatLabel={false}
                                    fieldType="inputRadio"
                                    key={`${bemBlockElementName}_${option.id}`}
                                    label={option.label}
                                    value={option.option}
                                />
                            ))}
                        </Grid>
                    </Grid.Column>
                );

            case 'select': // RC UI Label: Dropdown Menu
            case 'gender': { // RC UI Label: Gender Selector Template
                let selectValue;
                const options = map(templateFieldData.choices, (option) => {
                    if (option.label === textValue) {
                        selectValue = option.id;
                    }

                    return {
                        label: option.label,
                        value: option.id,
                    };
                });

                return (
                    <Form.Field
                        disable={disable}
                        fieldId={`${bemBlockElementName}_${responseCardFieldId}`}
                        fieldProps={{
                            options,
                        }}
                        fieldType="select"
                        label={fieldLabel}
                        value={selectValue}
                    />
                );
            }

            case 'text': // RC UI Label: Single Line Text
                return (
                    <Form.Field
                        disable={disable}
                        fieldId={`${bemBlockElementName}_${responseCardFieldId}`}
                        fieldType="inputText"
                        label={fieldLabel}
                        value={textValue}
                    />
                );

            case 'textarea': // RC UI Label: Paragraph Text
                return (
                    <Form.Field
                        disable={disable}
                        fieldId={`${bemBlockElementName}_${responseCardFieldId}`}
                        fieldProps={{
                            autoHeight: true,
                        }}
                        fieldType="textarea"
                        label={fieldLabel}
                        value={textValue}
                    />
                );

            default:
                // eslint-disable-next-line no-console
                console.warn(`No cases were matched with the fieldType: ${fieldType}.`);

                return null;
        }
    }
}

ConnectionCardEntryFormFields.propTypes = propTypes;
ConnectionCardEntryFormFields.defaultProps = defaultProps;

export default ConnectionCardEntryFormFields;
