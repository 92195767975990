import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import appHeader from './app/appHeader/appHeader.reducer.js';
import navigation from './app/appNavigationReducer.js';
import appSettings from './app/appSettingsReducer.js';
import bootstrap from './app/bootstrapReducer.js';
import breakpoint from './app/breakpointReducer.js';
import environments from './app/environments/environments.reducer';
import auditLog from './auditLog/auditLogReducers.js';
import churchStructure from './churchStructure/global/index.reducer';
import communications from './communications/communications.reducers.js';
import communicationsCentral from './communicationsCentral/reducers';
import connectionCards from './connectionCards/connectionCardsReducers.js';
import connectionForms from './connectionForms/connectionForms.reducers.js';
import connectionQuestions from './connectionQuestions/connectionQuestionsReducers.js';
import serviceCompanion from './contentManagement/serviceCompanionReducers.js';
import digitalProgram from './digitalProgram/digitalProgram.reducers';
import eventService from './event/eventsCentral/reducers/index.js';
import finance from './finance/finance.reducer';
import followUps from './followUps/reducers.js';
import giving from './giving/giving.reducers';
import customFieldAnswer from './global/customFields/customFieldAnswerReducer.js';
import customField from './global/customFields/customFieldReducer.js';
import global from './global/reducers/index.js';
import help from './help/help.reducer.js';
import insights from './insights/insights.reducers.js';
import journey from './journey/journey.reducers';
import quiz from './journey/quizBuilder/quiz.reducer';
import admin from './js/reducers/Admin/index.js';
import banners from './js/reducers/Banners.js';
import emailTemplate from './js/reducers/EmailTemplate/index.js';
import error from './js/reducers/Error/index.js';
import login from './js/reducers/Login/index.js';
import notification from './js/reducers/Notification/index.js';
import occurrences from './js/reducers/Occurrences/index.js';
import person from './js/reducers/Person/index.js';
import security from './js/reducers/Security/index.js';
import venues from './js/reducers/Venues/index.js';
import worshipServices from './js/reducers/WorshipServices/index.js';
import dataCapture from './metricsDataCapture/dataCaptureReducer.js';
import recommendedMinistries from './ministry/ministriesCentral/recommendedMinistries.reducer.js';
import myMinistry from './ministry/ministry.reducers.js';
import ministries from './ministry/reducers_old/index.js';
import myAccount from './myAccount/myAccount.reducers';
import notificationCenter from './notificationCenter/notificationCenterReducer.js';
import people from './people/people.reducers.js';
import quickNotifications from './quickNotificationCenter/quickNotificationReducer.js';
import dataExport from './system/migrationSettings/export/export.reducers.js';
import workflowRules from './workflowRules/workflowRulesReducers.js';

const rootReducer = combineReducers({
    admin,
    appHeader,
    appSettings,
    auditLog,
    banners,
    bootstrap,
    breakpoint,
    churchStructure,
    communications,
    communicationsCentral,
    connectionCards,
    connectionForms,
    connectionQuestions,
    customField,
    customFieldAnswer,
    dataCapture,
    emailTemplate,
    environments,
    error,
    eventService,
    export: dataExport,
    finance,
    followUps,
    giving,
    global,
    help,
    insights,
    journey,
    quiz,
    login,
    ministries,
    myAccount,
    myMinistry,
    navigation,
    notification,
    notificationCenter,
    occurrences,
    people,
    person,
    quickNotifications,
    recommendedMinistries,
    routing: routerReducer,
    security,
    serviceCompanion,
    venues,
    workflowRules,
    worshipServices,
    digitalProgram,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
