import ClassNames from 'classnames';
import {
    flatMap,
    get,
    isEmpty,
    isNil,
    some,
    uniq,
    debounce,
    find,
} from 'lodash';
import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    isMobile as isMobileDevice,
} from 'react-device-detect';
import {
    withTheme,
} from '@saddlebackchurch/react-cm-ui/core/styles';
import {
    connect,
} from 'react-redux';
import {
    withRouter,
} from 'react-router';
import {
    CSSTransitionGroup,
} from 'react-transition-group';
import PropTypes from 'prop-types';
import domUtils from '../../global/domUtils.js';
import Logo from '../logo.jsx';
import MainButton from './mainButton.jsx';
import {
    NavigationLevelOneWithRouter as NavigationLevelOne,
} from './navigationLevelOne.jsx';
import {
    setIsNavigationCollapsed,
    setItems,
    setNavigationBreadcrumbs,
    setNavigationHeaderBreadcrumbs,
    setNavigationSectionalTabs,
    toggleNavigationCollapse,
} from './navigation.actions';
import {
    translationFactory,
} from '../../global/i18nUtils.js';
import {
    useComponentDidUpdate,
    useComponentDidMount,
} from '../../global/lifeCycleHooks';
import {
    areSectionalTabsEqual,
    getLevelTwoBreadcrumbs,
    getUserCollapsedStateSessionKey,
    isCollapsedStateSessionKeySet,
    parseTabsToSectionalTabsItems,
} from './navigationUtils.jsx';
import {
    setAppHeaderTitle,
} from '../appHeader/appHeader.actions.js';

const i18n = translationFactory('App.Navigation');

const TRANSITIONS_TIME_OUT = 300;
const DEBOUNCE_TIME_MOUSE_ENTER_MAIN_ITEM = 95;
const DEBOUNCE_TIME_MOUSE_ENTER_MAIN_ITEM_ANOTHER = 20;
const DEBOUNCE_TIME_NAVIGATION_ON_SCROLL = 100;

const propTypes = {
    appHeaderTitle: PropTypes.string.isRequired,
    currentScrollPosition: PropTypes.number,
    disableNavigation: PropTypes.bool.isRequired,
    isMenuButtonDisabled: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isNavigationCollapsed: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    location: PropTypes.shape({
        action: PropTypes.string,
        pathname: PropTypes.string,
    }).isRequired,
    navigationBreadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({}),
            ),
        }),
    ).isRequired,
    previousMdCollapsedState: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        isActive: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }),
        push: PropTypes.func.isRequired,
    }).isRequired,
    sectionalTabsItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setAppHeaderTitle: PropTypes.func.isRequired,
    setIsNavigationCollapsed: PropTypes.func.isRequired,
    setItems: PropTypes.func.isRequired,
    setNavigationBreadcrumbs: PropTypes.func.isRequired,
    setNavigationHeaderBreadcrumbs: PropTypes.func.isRequired,
    setNavigationSectionalTabs: PropTypes.func.isRequired,
    theme: PropTypes.shape({
        breakpoints: PropTypes.shape({
            only: PropTypes.func.isRequired,
        }),
    }).isRequired,
    toggleNavigationCollapse: PropTypes.func.isRequired,
};

const defaultProps = {
    currentScrollPosition: undefined,
};

const mapStateToProps = (state) => {
    const {
        appHeader: {
            appHeaderTitle,
        },
        navigation,
    } = state;

    return {
        appHeaderTitle,
        disableNavigation: navigation.disableNavigation,
        isMenuButtonDisabled: navigation.isMenuButtonDisabled,
        isNavigationCollapsed: navigation.isNavigationCollapsed,
        items: navigation.items,
        navigationBreadcrumbs: navigation.navigationBreadcrumbs,
        previousMdCollapsedState: navigation.previousMdCollapsedState,
        sectionalTabsItems: navigation.sectionalTabsItems,
    };
};

const mapDispatchToProps = {
    setAppHeaderTitle,
    setIsNavigationCollapsed,
    setItems,
    setNavigationBreadcrumbs,
    setNavigationHeaderBreadcrumbs,
    setNavigationSectionalTabs,
    toggleNavigationCollapse,
};

export const getPermissions = (item) => {
    let permissions;

    if (item.secondLevelItems) {
        permissions = uniq(
            flatMap(
                item.secondLevelItems,
                (secondLevelItem) => (
                    !isNil(secondLevelItem.accessRequires) &&
                    secondLevelItem.accessRequires.permission
                ),
            ),
        );
    }

    return permissions;
};

export const getPathnameItem = (items, itemLevel, pathname) => {
    if (isEmpty(items)) {
        return false;
    }

    if (itemLevel === 'levelTwo') {
        for (let i = 0; i < items.length; i += 1) {
            const item = items[i];
            const secondLevelItem = find(item.secondLevelItems, (innerSecondLevelItem) => {
                const pathnameExistInSecondLevelItem = pathname === innerSecondLevelItem.to;

                if (pathnameExistInSecondLevelItem) {
                    return true;
                }

                const pathnameExistsInSectionalTab = some(
                    innerSecondLevelItem.tabs, { to: pathname },
                );

                if (pathnameExistsInSectionalTab) {
                    return true;
                }

                if (innerSecondLevelItem?.relatedPaths) {
                    const pathnameIsRelated = some(
                        innerSecondLevelItem?.relatedPaths,
                        (relatedPath) => (pathname.startsWith(relatedPath)),
                    );

                    if (pathnameIsRelated) {
                        return true;
                    }
                }

                return false;
            });

            if (secondLevelItem) {
                return secondLevelItem;
            }
        }
    } else if (itemLevel === 'levelOne') {
        const pathnameItem = find(items, (item) => {
            const secondLevelItem = some(item.secondLevelItems, (innerSecondLevelItem) => {
                const pathnameExistInSecondLevelItem = pathname === innerSecondLevelItem.to;

                if (pathnameExistInSecondLevelItem) {
                    return true;
                }

                const pathnameExistsInSectionalTab = some(
                    innerSecondLevelItem.tabs, { to: pathname },
                );

                if (pathnameExistsInSectionalTab) {
                    return true;
                }

                if (innerSecondLevelItem?.relatedPaths) {
                    const pathnameIsRelated = some(
                        innerSecondLevelItem?.relatedPaths,
                        (relatedPath) => (pathname.startsWith(relatedPath)),
                    );

                    if (pathnameIsRelated) {
                        return true;
                    }
                }

                return false;
            });

            return secondLevelItem;
        });

        return pathnameItem;
    }

    return null;
};

const MOBILE_NAV_ROOT_VISIBLE_STATE = -1;

const getSegmentFromPathName = (pathName) => {
    const pathsSegments = pathName.split('/');

    if (pathsSegments.length > 1) {
        if (pathsSegments[1].includes('my-')) {
            return 'my-stuff';
        }

        // This scenario is when the path is Home /
        if (pathsSegments[1] === '') {
            return 'my-stuff';
        }

        return pathsSegments[1];
    }

    return null;
};

let isFirstLoad = true;

export function Navigation(props) {
    const {
        appHeaderTitle,
        currentScrollPosition,
        disableNavigation,
        isMenuButtonDisabled,
        isMobile,
        isNavigationCollapsed,
        items,
        location,
        navigationBreadcrumbs,
        previousMdCollapsedState,
        router,
        sectionalTabsItems,
        setAppHeaderTitle: setAppHeaderTitleAction,
        setIsNavigationCollapsed: setIsNavigationCollapsedAction,
        setItems: setItemsAction,
        setNavigationBreadcrumbs: setNavigationBreadcrumbsAction,
        setNavigationHeaderBreadcrumbs: setNavigationHeaderBreadcrumbsAction,
        setNavigationSectionalTabs: setNavigationSectionalTabsAction,
        toggleNavigationCollapse: toggleNavigationCollapseAction,
    } = props;

    const [activeClickedItem, setActiveClickedItem] = useState(''); // prev as 'activeItem' in state
    const [mainItemLabelHover, setMainItemLabelHover] = useState('');
    const [mobileBreadcrumbsVisibleLevel, setMobileBreadcrumbsVisibleLevel] = useState(-1);
    const [hasSecondLevelItemBeenSet, setHasSecondLevelItemBeenSet] = useState(false);

    const { pathname } = router.location;
    const isEventsSection = pathname.startsWith('/events-central/');
    const isMinistrySection = pathname.startsWith('/my-ministry/');
    const userCollapsedStateSessionKey = getUserCollapsedStateSessionKey();

    const debouncedResetLevelOneActiveItem = useRef(debounce(() => {
        setActiveClickedItem('');
        setMainItemLabelHover('');
    }, DEBOUNCE_TIME_NAVIGATION_ON_SCROLL)).current;

    const previousPathname = useRef(window.location.pathname);

    useEffect(() => { // handles mobile mobile breadcrumbs visible state
        const currentMobileBreadcrumbsIndex = navigationBreadcrumbs.length > 0 ?
            navigationBreadcrumbs.length - 1 :
            MOBILE_NAV_ROOT_VISIBLE_STATE;

        setMobileBreadcrumbsVisibleLevel(currentMobileBreadcrumbsIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationBreadcrumbs, previousPathname.current]);

    const turnMobileBreadcrumbsVisibleLevel = (direction) => {
        if (!direction) {
            return null;
        }

        const canChangeToLeft = mobileBreadcrumbsVisibleLevel >= 0;
        const canChangeToRight = mobileBreadcrumbsVisibleLevel < navigationBreadcrumbs.length - 1;

        if (canChangeToLeft && direction === 'left') {
            setMobileBreadcrumbsVisibleLevel((prevVisibleState) => prevVisibleState - 1);
        }

        if (canChangeToRight && direction === 'right') {
            setMobileBreadcrumbsVisibleLevel((prevVisibleState) => prevVisibleState + 1);
        }

        return null;
    };

    const prevProps = useRef();
    const curScrollPos = useRef();

    const onMainItemEvent = (label) => {
        setMainItemLabelHover(label);
    };

    const mouseEnterAnotherMainItemDebounce = debounce(
        (label) => onMainItemEvent(label), DEBOUNCE_TIME_MOUSE_ENTER_MAIN_ITEM_ANOTHER,
    );
    const mouseEnterMainItemDebounce = debounce(
        (label) => onMainItemEvent(label), DEBOUNCE_TIME_MOUSE_ENTER_MAIN_ITEM,
    );

    const onLogoClick = () => {
        setActiveClickedItem('my-stuff');
        setNavigationBreadcrumbsAction([]);
        setNavigationHeaderBreadcrumbsAction([]);
        setNavigationSectionalTabsAction([]);
        setAppHeaderTitleAction(`${i18n('Home')}`);

        if (isMobile) {
            setIsNavigationCollapsedAction(true);
        }

        if (!isMenuButtonDisabled) {
            setMainItemLabelHover('');
        }

        router.push('/');
    };

    /**
     * Sets the active level one area. Nothing else.
     */
    const onItemClick = (item, wasLevelTwoItemClick) => {
        if (item && item.to && !router.isActive(item.to) && !disableNavigation) {
            setTimeout(() => onItemClick(item, wasLevelTwoItemClick), 50);

            const segment = get(item, 'to', '').split('/')[1];
            const selectedItem = find(items, {
                segment,
                to: null,
            });

            if (selectedItem && !wasLevelTwoItemClick && !disableNavigation) {
                setActiveClickedItem(segment);
            }
        }
    };

    const onMainButtonClick = () => {
        if (isMobile) {
            if (!isMenuButtonDisabled) {
                const currentItemLabel = mainItemLabelHover ? '' : mainItemLabelHover;
                setMainItemLabelHover(currentItemLabel);
            }
        } else {
            toggleNavigationCollapseAction(false);
            setMainItemLabelHover('');
        }
    };

    const onMouseEnterMainItemDelay = (itemLabel, cancel) => {
        if (!cancel && !mainItemLabelHover) {
            mouseEnterMainItemDebounce(itemLabel);
        } else if (!cancel && mainItemLabelHover) {
            mouseEnterAnotherMainItemDebounce(itemLabel);
        } else {
            mouseEnterMainItemDebounce.cancel();
            mouseEnterAnotherMainItemDebounce.cancel();
        }
    };

    const onNavigationMouseLeave = () => {
        const shouldFlyoutMenuDissapear = isMobileDevice &&
            !isMobile &&
            isNavigationCollapsed;

        if (shouldFlyoutMenuDissapear) {
            setMainItemLabelHover('');
        }
    };

    // eslint-disable-next-line consistent-return
    const renderLists = (shouldRenderMobileBreadcrumbs) => {
        if (isEmpty(items)) {
            return false;
        }

        let itemsToRender = items;
        let breadcrumbsTitle;

        if (shouldRenderMobileBreadcrumbs) {
            const {
                title,
                items: breadcrumbsItems,
            } = navigationBreadcrumbs[mobileBreadcrumbsVisibleLevel];

            breadcrumbsTitle = title;
            // reuses level two logic for breadcrumbs using only a level one item
            itemsToRender = [{
                label: breadcrumbsTitle,
                segment: 'my-ministry',
                secondLevelItems: [...breadcrumbsItems],
                to: null,
            }];
        }

        return (
            <div className="navigation-container">
                <div
                    className="navigation-scrollbar"
                    style={{
                        marginRight: -domUtils.getScrollbarWidth(),
                    }}
                >
                    <div className="navigation-scrollbar-inner">
                        <CSSTransitionGroup
                            transitionEnterTimeout={TRANSITIONS_TIME_OUT}
                            transitionLeaveTimeout={TRANSITIONS_TIME_OUT}
                            transitionName={{
                                enter: 'roll-out-enter',
                                enterActive: 'animate-active',
                                leave: 'roll-in-leave',
                                leaveActive: 'animate-active',
                            }}
                        >
                            <NavigationLevelOne
                                activeClickedItem={activeClickedItem}
                                breadcrumbsTitle={breadcrumbsTitle}
                                getPathnameItem={getPathnameItem}
                                getPermissions={getPermissions}
                                isMobile={isMobile}
                                isNavigationCollapsed={isNavigationCollapsed}
                                itemLabelHover={mainItemLabelHover}
                                items={itemsToRender}
                                key="main-menu"
                                mobileBreadcrumbsVisibleLevel={mobileBreadcrumbsVisibleLevel}
                                mouseEnterDelay={onMouseEnterMainItemDelay}
                                navigationBreadcrumbs={navigationBreadcrumbs}
                                onClick={onItemClick}
                                onMainItemEvent={onMainItemEvent}
                                previousPathname={previousPathname.current}
                                setActiveClickedItem={setActiveClickedItem}
                                setMainItemLabelHover={setMainItemLabelHover}
                                setNavigationSectionalTabs={setNavigationSectionalTabsAction}
                                setIsNavigationCollapsed={setIsNavigationCollapsedAction}
                                shouldRenderMobileBreadcrumbs={shouldRenderMobileBreadcrumbs}
                                turnMobileBreadcrumbsVisibleLevel={
                                    turnMobileBreadcrumbsVisibleLevel
                                }
                            />
                        </CSSTransitionGroup>
                    </div>
                </div>
            </div>
        );
    };

    const resetLevelOneActiveItem = () => {
        const isDesktopUsingFlyoutMenu = !isMobile && isNavigationCollapsed;
        if (isDesktopUsingFlyoutMenu) {
            debouncedResetLevelOneActiveItem();
        }
    };

    useComponentDidUpdate(() => { // updates user session collapsed state
        const shouldUpdateUserSessionCollapsedState = !isMobile &&
            isNavigationCollapsed !== JSON.parse(
                localStorage.getItem(userCollapsedStateSessionKey),
            );

        if (shouldUpdateUserSessionCollapsedState) {
            localStorage.setItem(userCollapsedStateSessionKey, isNavigationCollapsed);
        }

        if (!isNavigationCollapsed && !disableNavigation) {
            const section = pathname.split('/')[1];
            let levelOneItem;

            if (!section) { // it's home
                // eslint-disable-next-line prefer-destructuring
                levelOneItem = items[0];
            } else {
                levelOneItem = getPathnameItem(items, 'levelOne', pathname);
            }

            if (levelOneItem) {
                setMainItemLabelHover(levelOneItem.label);
                setActiveClickedItem(levelOneItem.segment);
            }
        }
    }, [isNavigationCollapsed]);

    useEffect(() => {
        const prevIsMobile = get(prevProps, 'current.isMobile');
        const shouldCollapseNavigation = !prevIsMobile && isMobile;
        const shouldCollapseToPreviousMdState = prevIsMobile && !isMobile;

        if (shouldCollapseNavigation) {
            setIsNavigationCollapsedAction(true);
        } else if (shouldCollapseToPreviousMdState) {
            setIsNavigationCollapsedAction(previousMdCollapsedState);
        }

        prevProps.current = {
            ...prevProps.current,
            isMobile: !!isMobile,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    useComponentDidMount(() => {
        setItemsAction();

        const isUserSessionCollapsedStateSet = isCollapsedStateSessionKeySet();
        const userSessionCollapsedState = JSON.parse(
            localStorage.getItem(userCollapsedStateSessionKey),
        );

        if (isUserSessionCollapsedStateSet) {
            setIsNavigationCollapsedAction(userSessionCollapsedState);
        }

        if (isMobile) {
            setIsNavigationCollapsedAction(true);
        } else if (!isUserSessionCollapsedStateSet) {
            setIsNavigationCollapsedAction(false);
        }
    });

    useComponentDidUpdate(() => {
        const {
            currentScrollPosition: prevScrollPosition,
        } = get(prevProps, 'current', {});
        let alreadyDetectedActiveClickedItem = false;

        if (prevScrollPosition !== currentScrollPosition) {
            curScrollPos.current = currentScrollPosition;
        }

        // sets the level one active item on the first load page
        const pathItem = getPathnameItem(items, 'levelOne', pathname);
        const areNavV3ItemsLoaded = get(items, '[0].segment') === 'my-stuff';
        const shouldSetActiveClickedItem = areNavV3ItemsLoaded && isFirstLoad && pathItem;

        if (shouldSetActiveClickedItem) {
            alreadyDetectedActiveClickedItem = true;
            setActiveClickedItem(pathItem.segment);

            if (!isMobile && !isNavigationCollapsed) {
                setMainItemLabelHover(pathItem.label);
            }

            isFirstLoad = false;
        }

        /**
         * Sets the sectional tabs for the first page load and when going back/forward with the browser buttons.
         * Not done in didMount because the navigation v3 items are not loaded yet so
         * didUpdate will watch until the page pathname matches any navigation v3 item.
         */
        if (areNavV3ItemsLoaded) {
            const currentSecondLevelItem = getPathnameItem(items, 'levelTwo', pathname);
            const haveBrowserButtonsBeenClicked = location.action === 'POP' &&
                previousPathname.current !== location.pathname;

            const shouldUpdateAppHeaderTitle = (
                !hasSecondLevelItemBeenSet || haveBrowserButtonsBeenClicked
            ) && currentSecondLevelItem?.label && appHeaderTitle !== currentSecondLevelItem.label;

            if (shouldUpdateAppHeaderTitle) {
                setAppHeaderTitleAction(currentSecondLevelItem.label || '');
            }

            const currentSectionalTabs = parseTabsToSectionalTabsItems(
                currentSecondLevelItem?.tabs || [], router.push,
            );

            const shouldSetSectionalTabs = !hasSecondLevelItemBeenSet || ( // first load
                haveBrowserButtonsBeenClicked &&
                (!isMinistrySection || !isEventsSection) && // these sections handle their stuff by their own
                (!isMobile || (isMobile && (
                    mobileBreadcrumbsVisibleLevel === -1 || // prevents unnecesary render calls when changing mobile navigation breadcrumbs page (turning level to left/right)
                    previousPathname.current !== pathname
                ))) && !areSectionalTabsEqual(sectionalTabsItems, currentSectionalTabs)
            );

            if (shouldSetSectionalTabs) {
                const levelTwoBreadcrumbs = getLevelTwoBreadcrumbs(items, currentSecondLevelItem); // mobile nav breadcrumbs
                setNavigationSectionalTabsAction(currentSectionalTabs);
                setNavigationBreadcrumbsAction(levelTwoBreadcrumbs);
            }

            if (!hasSecondLevelItemBeenSet) {
                setHasSecondLevelItemBeenSet(true);
            }
        }

        prevProps.current = {
            ...prevProps.current,
            currentScrollPosition,
        };

        const previousSegment = getSegmentFromPathName(previousPathname.current);
        const currentSegment = getSegmentFromPathName(location.pathname);

        if (!alreadyDetectedActiveClickedItem && previousSegment !== currentSegment) {
            setActiveClickedItem(currentSegment);
            setMainItemLabelHover('');
        }

        previousPathname.current = location.pathname;
    }, [
        previousPathname.current,
        location.pathname,
        items,
    ]);

    const closeButton = isMobile && (
        <div
            data-testid="navigation-close-button"
            className="close-button"
            onClick={() => setIsNavigationCollapsedAction(true)}
            role="presentation"
        >
            {i18n('CLOSE')}
        </div>
    );

    const navigationClassNames = ClassNames(
        'navigation',
        'navigation-v3',
        {
            'navigation-md': !isMobile,
            'navigation-sm': isMobile,
            'navigation-open': !isNavigationCollapsed,
            'navigation-closed': isNavigationCollapsed,
        },
    );

    const shouldRenderMobileBreadcrumbs = isMobile &&
        mobileBreadcrumbsVisibleLevel > 0 &&
        mobileBreadcrumbsVisibleLevel < navigationBreadcrumbs.length;

    return (
        <nav
            className={navigationClassNames}
            onMouseLeave={onNavigationMouseLeave}
            onScroll={resetLevelOneActiveItem}
        >
            <Logo
                isMobile={isMobile}
                isNavigationCollapsed={isNavigationCollapsed}
                onClick={onLogoClick}
            />

            {closeButton}

            {(!isMobile && !shouldRenderMobileBreadcrumbs) && (
                <MainButton
                    getPathnameItem={getPathnameItem}
                    isMenuButtonDisabled={isMenuButtonDisabled}
                    isMobile={isMobile}
                    isNavigationCollapsed={isNavigationCollapsed}
                    items={items}
                    onClick={onMainButtonClick}
                />
            )}

            { renderLists(shouldRenderMobileBreadcrumbs) }
        </nav>
    );
}

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    withRouter(
        withTheme(
            Navigation,
        ),
    ),
);
