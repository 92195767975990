import { isEmpty } from 'lodash';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

export const pagedEntriesList = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    canLoadMore: false,
    entries: [],
    isFetching: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedEntriesList.getPageSize(),
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    const first = !isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        case 'ConnectionFormEntriesListPageActions.ON_BEFORE_SEARCHANDBROWSEENTRIES':
            if (first) {
                return { ...state, isFetching: true };
            }

            return state;

        case 'ConnectionFormEntriesListPageActions.ON_SEARCHANDBROWSEENTRIES':
        {
            const {
                resultCount,
                results,
            } = actionResult;

            pagedEntriesList.loadPage(results, resultCount, first);
            const entries = pagedEntriesList.getAll(true);

            return {
                ...state,
                entries,
                canLoadMore: pagedEntriesList.canLoadMore(),
                isFetching: false,
                needsToRequest: pagedEntriesList.needsToLoadPage(),
                pageNumber: pagedEntriesList.getCurrentPageNumber(),
                pageSize: pagedEntriesList.getPageSize(),
                total: first ? pagedEntriesList.getTotalCount() : state.total,
            };
        }

        case 'ConnectionFormEntriesListPageActions.ON_SEARCH_AND_BROWSE_ENTRIES_NEXT_PAGE':
        {
            const entries = pagedEntriesList.getAll(true);

            return {
                ...state,
                entries,
                canLoadMore: pagedEntriesList.canLoadMore(),
                needsToRequest: pagedEntriesList.needsToLoadPage(),
            };
        }

        case 'ConnectionFormEntriesListPageActions.RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
