import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

export const localCache = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    data: [],
    isFetching: false,
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: localCache.getPageSize(),
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PossibleDuplicatesActions.ON_BEFORE_GET': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'PossibleDuplicatesActions.ON__GET': {
            localCache.loadPage(
                action.result.results,
                action.result.resultCount,
                action.callbackParams.first,
            );

            return {
                ...state,
                isFetching: false,
                data: localCache.getAll(true),
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'PossibleDuplicatesActions.ON_NEXTPAGE': {
            return {
                ...state,
                data: localCache.getAll(true),
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'PossibleDuplicatesActions.ON_RESET': {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
