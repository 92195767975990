import {
    Button,
    Grid,
    Icon,
    Input,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
    map,
    noop,
} from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import {
    clearSearchTerm,
    reset,
    updateAddress,
    updateCity,
    updateEmail,
    updateFirstName,
    updateLastName,
    updateNickName,
    updatePhone,
    updateSearchTerm,
    updateZipCode,
} from './peopleSearchAdvancedFields.actions.js';
import { isSearchQueryValid } from './queryUtils/queryUtils.js';
import { isEnterKeyCode } from '../../../global/utils/utils.js';
import { i18n } from '../../../global/constants.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import isChildToAgeCategory from './utils';
import peopleSearchDrawerActions from './peopleSearchDrawer.actions.js';
import personActions from '../../actions_old/person.actions';
import UserAccessStore from '../../../global/userAccessStore.js';

const propTypes = {
    advancedSearchFields: PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        nickName: PropTypes.string,
        phone: PropTypes.string,
        searchTerm: PropTypes.string,
        zipCode: PropTypes.string,
    }),
    apiParams: PropTypes.shape({}),
    classes: PropTypes.shape({
        formStyle: PropTypes.string,
    }),
    clearSearchTerm: PropTypes.func,

    /**
     * Optional filter passed to the person search.
     * Can be null | false | true
     * null => do not filter by record type
     * false => only include Adults and Students (not Children)
     * true => only include Children
     * TODO: Consider removing this `isChild` and it can be part of `apiParams`
     */
    isChild: PropTypes.bool,

    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    personSearchFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    reset: PropTypes.func,
    showAdvancedSearchFields: PropTypes.bool.isRequired,
    updateAddress: PropTypes.func,
    updateCity: PropTypes.func,
    updateEmail: PropTypes.func,
    updateFirstName: PropTypes.func,
    updateLastName: PropTypes.func,
    updateNickName: PropTypes.func,
    updatePhone: PropTypes.func,
    updateSearchTerm: PropTypes.func,
    updateZipCode: PropTypes.func,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    advancedSearchFields: {},
    apiParams: {},
    classes: {},
    clearSearchTerm: noop,
    isChild: null,
    reset: noop,
    updateAddress: noop,
    updateCity: noop,
    updateEmail: noop,
    updateFirstName: noop,
    updateLastName: noop,
    updateNickName: noop,
    updatePhone: noop,
    updateSearchTerm: noop,
    updateZipCode: noop,
};

const mapDispatchToProps = {
    clearSearchTerm,
    reset,
    updateAddress,
    updateCity,
    updateEmail,
    updateFirstName,
    updateLastName,
    updateNickName,
    updatePhone,
    updateSearchTerm,
    updateZipCode,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        people: {
            searchV2: {
                advancedSearchFields,
                peopleList: {
                    pageNumber,
                    pageSize,
                },
            },
        },
        person,
    } = state;

    return {
        advancedSearchFields,
        pageNumber,
        pageSize,
        personSearchFields: person.searchFields,
        userAccess,
    };
};

const styles = () => ({
    formStyle: {
        paddingTop: 0,
    },
});

export const BEM_BLOCK_NAME = 'person_search_form';
export class PeopleSearchDrawerSearchForm extends React.Component {
    constructor() {
        super();

        this.onAdvancedSearchClick = this.onAdvancedSearchClick.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchClearClick = this.onSearchClearClick.bind(this);
        this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
        this.onUpdateSearchAddress = this.onUpdateSearchAddress.bind(this);
        this.onUpdateSearchCity = this.onUpdateSearchCity.bind(this);
        this.onUpdateSearchEmail = this.onUpdateSearchEmail.bind(this);
        this.onUpdateSearchFirstName = this.onUpdateSearchFirstName.bind(this);
        this.onUpdateSearchLastName = this.onUpdateSearchLastName.bind(this);
        this.onUpdateSearchNickName = this.onUpdateSearchNickName.bind(this);
        this.onUpdateSearchPhone = this.onUpdateSearchPhone.bind(this);
        this.onUpdateSearchZipCode = this.onUpdateSearchZipCode.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        const {
            personSearchFields,
        } = this.props;

        // Load person search fields if it hasn't already
        if (personSearchFields.length === 0) {
            personActions.getSearchFields();
        }

        this.onSearchClearClick();
    }

    componentWillUnmount() {
        const {
            reset: resetSearchAction,
        } = this.props;

        resetSearchAction();
    }

    onAdvancedSearchClick() {
        this.searchHandler(true);
    }

    onSearchChange(searchQuery) {
        const {
            updateSearchTerm: updateSearchTermAction,
        } = this.props;

        updateSearchTermAction(searchQuery);
    }

    onSearchClearClick() {
        const {
            clearSearchTerm: clearSearchTermAction,
        } = this.props;

        clearSearchTermAction();
        peopleSearchDrawerActions.resetCoreMilestonesByPersonIds();
        peopleSearchDrawerActions.resetPeople();
    }

    onSearchKeyDown(event) {
        if (isEnterKeyCode(event)) {
            this.searchHandler(true);
        }
    }

    onUpdateSearchAddress(value) {
        const {
            updateAddress: updateAddressAction,
        } = this.props;

        updateAddressAction(value);
    }

    onUpdateSearchCity(value) {
        const {
            updateCity: updateCityAction,
        } = this.props;

        updateCityAction(value);
    }

    onUpdateSearchEmail(value) {
        const {
            updateEmail: updateEmailAction,
        } = this.props;

        updateEmailAction(value);
    }

    onUpdateSearchFirstName(value) {
        const {
            updateFirstName: updateFirstNameAction,
        } = this.props;

        updateFirstNameAction(value);
    }

    onUpdateSearchLastName(value) {
        const {
            updateLastName: updateLastNameAction,
        } = this.props;

        updateLastNameAction(value);
    }

    onUpdateSearchNickName(value) {
        const {
            updateNickName: updateLastNameAction,
        } = this.props;

        updateLastNameAction(value);
    }

    onUpdateSearchPhone(value) {
        const {
            updatePhone: updatePhoneAction,
        } = this.props;

        updatePhoneAction(value);
    }

    onUpdateSearchZipCode(value) {
        const {
            updateZipCode: updateZipCodeAction,
        } = this.props;

        updateZipCodeAction(value);
    }

    searchHandler(firstPage) {
        const {
            showAdvancedSearchFields,
            advancedSearchFields: {
                address1,
                city,
                email,
                firstName,
                lastName,
                nickName,
                phone,
                searchTerm,
                zipCode,
            },
        } = this.props;

        let query = '';

        if (showAdvancedSearchFields) {
            if (!isEmpty(firstName)) {
                query = `first-name:"${firstName}"`;
            }

            if (!isEmpty(lastName)) {
                query += ` last-name:"${lastName}"`;
            }

            if (!isEmpty(nickName)) {
                query += ` nickname:"${nickName}"`;
            }

            if (!isEmpty(phone)) {
                query += ` phone.number:"${phone.replace(/[^\d]/gi, '')}"`;
            }

            if (!isEmpty(email)) {
                query += ` email:"${email}"`;
            }

            if (!isEmpty(address1)) {
                query += ` address.address1:"${address1}"`;
            }

            if (!isEmpty(city)) {
                query += ` address.city:"${city}"`;
            }

            if (!isEmpty(zipCode)) {
                query += ` address.postal-code:"${zipCode}"`;
            }
        } else {
            query = searchTerm;
        }

        if (query) {
            this.search(query, firstPage);
        }
    }

    search(query, first) {
        const {
            personSearchFields,
            userAccess,
        } = this.props;

        if (!isSearchQueryValid(query, personSearchFields)) {
            // eslint-disable-next-line no-console
            console.warn('Person search query was not valid.  Bailing out.  query:', query);
            return;
        }

        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(() => {
            const {
                apiParams,
                isChild,
                pageNumber,
                pageSize,
            } = this.props;

            peopleSearchDrawerActions.getPeople({
                ...apiParams,
                ageCategory: isChildToAgeCategory(isChild),
                checkPublicContacts: false,
                pageNumber: first ? 0 : pageNumber + 1,
                pageSize,
                q: query,
            }, { first }).then((res) => {
                const hasCoreMilestonesPermissions = userAccess.hasPermission(
                    USER_PERMISSIONS.readPersonMilestones,
                );

                if (hasCoreMilestonesPermissions && res && !isEmpty(res.results)) {
                    const personIds = map(res.results, 'id');
                    peopleSearchDrawerActions.getCoreMilestonesByPersonIds({
                        personIds,
                    });
                }

                peopleSearchDrawerActions.onToggleActiveLoadMore(true);
            });
        }, 166);
    }

    render() {
        const {
            advancedSearchFields: {
                searchTerm,
            },
            classes,
            showAdvancedSearchFields,
        } = this.props;

        const hasValue = !isEmpty(searchTerm);

        return (
            <div id={BEM_BLOCK_NAME}>
                {!showAdvancedSearchFields && (
                    <Input
                        autoFocus
                        fluid
                        icon={hasValue ? (
                            <Icon
                                compact
                                id={`${BEM_BLOCK_NAME}--clear_search_icon`}
                                onClick={this.onSearchClearClick}
                                title={i18n('Clear Search')}
                                type="times"
                            />
                        ) : (
                            <Icon
                                compact
                                title={i18n('Search')}
                                type="search"
                            />
                        )}
                        id={`${BEM_BLOCK_NAME}--search_input`}
                        onChange={this.onSearchChange}
                        onKeyDown={this.onSearchKeyDown}
                        placeholder={i18n('Search by Name or Phone Number')}
                        value={searchTerm}
                    />
                )}

                {showAdvancedSearchFields && (
                    <Grid spacing={2}>
                        <Grid.RowDeprecated columns={3}>
                            <Grid.Column
                                className={classes.formStyle}
                            >
                                <Input
                                    autoFocus
                                    fluid
                                    id={`${BEM_BLOCK_NAME}--firstname_input`}
                                    label={i18n('First Name')}
                                    onChange={this.onUpdateSearchFirstName}
                                    onKeyDown={this.onSearchKeyDown}
                                />
                            </Grid.Column>

                            <Grid.Column
                                className={classes.formStyle}
                            >
                                <Input
                                    fluid
                                    id={`${BEM_BLOCK_NAME}--lastname_input`}
                                    label={i18n('Last Name')}
                                    onChange={this.onUpdateSearchLastName}
                                    onKeyDown={this.onSearchKeyDown}
                                />
                            </Grid.Column>

                            <Grid.Column
                                className={classes.formStyle}
                            >
                                <Input
                                    fluid
                                    id={`${BEM_BLOCK_NAME}--nickname_input`}
                                    label={i18n('Nick Name')}
                                    onChange={this.onUpdateSearchNickName}
                                    onKeyDown={this.onSearchKeyDown}
                                />
                            </Grid.Column>
                        </Grid.RowDeprecated>

                        <Grid.RowDeprecated columns={3} verticalAlign="bottom">
                            <Grid.Column
                                className={classes.formStyle}
                            >
                                <Input
                                    fluid
                                    id={`${BEM_BLOCK_NAME}--address_input`}
                                    label={i18n('Address')}
                                    onChange={this.onUpdateSearchAddress}
                                    onKeyDown={this.onSearchKeyDown}
                                />
                            </Grid.Column>

                            <Grid.Column
                                className={classes.formStyle}
                            >
                                <Input
                                    fluid
                                    id={`${BEM_BLOCK_NAME}--city_input`}
                                    key="simple-city"
                                    label={i18n('City')}
                                    onChange={this.onUpdateSearchCity}
                                    onKeyDown={this.onSearchKeyDown}
                                />
                            </Grid.Column>

                            <Grid.Column
                                className={classes.formStyle}
                            >
                                <Input
                                    fluid
                                    id={`${BEM_BLOCK_NAME}--zipcode_input`}
                                    key="simple-zip"
                                    label={i18n('Zip')}
                                    onChange={this.onUpdateSearchZipCode}
                                    onKeyDown={this.onSearchKeyDown}
                                />
                            </Grid.Column>
                        </Grid.RowDeprecated>

                        <Grid.RowDeprecated columns={3} verticalAlign="bottom">
                            <Grid.Column
                                className={classes.formStyle}
                            >
                                <Input
                                    fluid
                                    id={`${BEM_BLOCK_NAME}--email_input`}
                                    label={i18n('Email')}
                                    onChange={this.onUpdateSearchEmail}
                                    onKeyDown={this.onSearchKeyDown}
                                    type="email"
                                />
                            </Grid.Column>

                            <Grid.Column
                                className={classes.formStyle}
                            >
                                <Input
                                    id={`${BEM_BLOCK_NAME}--phone_input`}
                                    key="simple-phone"
                                    label={i18n('Phone')}
                                    onChange={this.onUpdateSearchPhone}
                                    onKeyDown={this.onSearchKeyDown}
                                    type="tel"
                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Grid.RowDeprecated horizontalAlign="right">
                                    <Button
                                        id={`${BEM_BLOCK_NAME}--search_btn`}
                                        onClick={this.onAdvancedSearchClick}
                                    >
                                        {i18n('Search')}
                                    </Button>
                                </Grid.RowDeprecated>
                            </Grid.Column>
                        </Grid.RowDeprecated>
                    </Grid>
                )}
            </div>
        );
    }
}

PeopleSearchDrawerSearchForm.defaultProps = defaultProps;
PeopleSearchDrawerSearchForm.propTypes = propTypes;

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(PeopleSearchDrawerSearchForm),
);
