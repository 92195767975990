import {
    get,
    isNull,
    kebabCase,
    map,
    some,
} from 'lodash';
import {
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import { isMobile as isMobileDevice } from 'react-device-detect';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Features from '../../global/features.js';
import UserPermission from '../../global/userPermission.js';

const propTypes = {
    getDimensions: PropTypes.func.isRequired,
    getPermissions: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isMobileDeviceTesting: PropTypes.bool, // used inside unit test because we can't simulate a phisycal mobile device with enzyme
    isNavigationCollapsed: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        segment: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        secondLevelItems: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    segment: PropTypes.string,
    shouldRenderMobileBreadcrumbs: PropTypes.bool.isRequired,
    setIsNavigationCollapsed: PropTypes.func.isRequired,
    setMainItemLabelHover: PropTypes.func.isRequired,
    style: PropTypes.shape({}),
};

const defaultProps = {
    segment: undefined,
    style: {},
    isMobileDeviceTesting: false,
};

const mapStateToProps = (state) => {
    const {
        appHeader: {
            appHeaderTitle,
        },
        navigation: {
            sectionalTabsItems,
            isNavigationCollapsed,
        },
    } = state;

    return {
        appHeaderTitle,
        isNavigationCollapsed,
        sectionalTabsItems,
    };
};

const LEVEL_TWO_ITEM_OFFSET = 10;

export function NavigationLevelTwo(props) {
    const {
        getPermissions,
        getDimensions,
        isMobile,
        isMobileDeviceTesting,
        isNavigationCollapsed,
        item,
        onClick: onClickFromParent,
        segment,
        setIsNavigationCollapsed,
        setMainItemLabelHover,
        style,
        shouldRenderMobileBreadcrumbs,
    } = props;

    const onClick = (clickedItem, event) => {
        if (isMobile) {
            setIsNavigationCollapsed(true);
        }

        if (event) {
            event.stopPropagation();
        }

        if (clickedItem) {
            onClickFromParent({
                item: clickedItem,
                itemLabel: null,
                wasLevelTwoItemClick: true,
                segment,
            });
        }

        const shouldHideFlyoutMenu = (isMobileDevice || isMobileDeviceTesting) &&
            isNavigationCollapsed &&
            !isMobile;

        if (shouldHideFlyoutMenu) {
            setMainItemLabelHover('');
        }
    };

    const renderLevelTwo = () => {
        const {
            pathname,
        } = window.location;

        let levelTwoItemTotal = 0;

        const subSection = map(item.secondLevelItems, (secondLevelItem) => {
            const isActive = (() => {
                if (pathname === secondLevelItem.to) {
                    return true;
                }

                // pathname exists in sectional tab
                if (!secondLevelItem.to && some(get(secondLevelItem, 'tabs', []), ({ to }) => (to === pathname))) {
                    return true;
                }

                // pathname is automatically related to a second level item
                if (pathname !== '/' &&
                    secondLevelItem.to && secondLevelItem.to !== '/' &&
                    !secondLevelItem.to.split('/').length === 2 && // it ain't a root section
                    pathname.startsWith(secondLevelItem.to)) {
                    return true;
                }

                // pathname is manually related to a second level item
                if (secondLevelItem?.relatedPaths && some(
                    secondLevelItem.relatedPaths,
                    (relatedPath) => (pathname.startsWith(relatedPath)),
                )) {
                    return true;
                }

                return false;
            })();

            const subSectionItemContainerClasses = ClassNames('navigation-sub-section-item', {
                'is-active': isActive,
            });

            const accessRequires = secondLevelItem.accessRequires || {};
            const requiredFeatures = accessRequires.feature || [];

            if (secondLevelItem.total) {
                levelTwoItemTotal += secondLevelItem.total;
            }

            return (
                <Features
                    key={`navigation-sub-section-item-${kebabCase(secondLevelItem.label)}`}
                    requiredFeatures={requiredFeatures}
                >
                    <UserPermission
                        anyPermissions={accessRequires.anyPermissions}
                        churchEntityId={
                            accessRequires.churchEntityId && +accessRequires.churchEntityId
                        }
                        ministryId={accessRequires.ministryId && +accessRequires.ministryId}
                        permission={accessRequires.permission}
                    >
                        <li
                            role="presentation"
                            className={subSectionItemContainerClasses}
                            onClick={(e) => onClick(secondLevelItem, e)}
                        >
                            <Typography
                                className="navigation-sub-section-item-label"
                                component="div"
                                variant="body2"
                            >
                                {secondLevelItem.label}
                                {secondLevelItem.isBeta && (
                                    <Typography
                                        className="navigation-sub-section-item-beta-tag"
                                        component="div"
                                        variant="body2"
                                    >
                                        (beta)
                                    </Typography>
                                )}
                            </Typography>
                            {!isNull(secondLevelItem.total) && (
                                <Typography
                                    className="navigation-sub-section-item-total"
                                    variant="body2"
                                >
                                    {secondLevelItem?.total?.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                    })}
                                </Typography>

                            )}
                        </li>
                    </UserPermission>
                </Features>
            );
        });

        const secondLevelItemClasses = ClassNames(
            'navigation-sub-item',
            { 'vertically-expanded': shouldRenderMobileBreadcrumbs },
        );

        const secondLevelItemLabel = (!isMobile && isNavigationCollapsed) && (
            <Typography
                className="ui header"
            >
                <Typography
                    className="navigation-sub-item-label"
                    component="span"
                >
                    {item.label}
                </Typography>

                {levelTwoItemTotal > 0 && (
                    <Typography
                        className="navigation-sub-item-total"
                        component="span"
                    >
                        {levelTwoItemTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                        })}
                    </Typography>
                )}
            </Typography>
        );

        return (
            <UserPermission
                anyPermissions
                permission={getPermissions(item)}
            >
                <li
                    className={secondLevelItemClasses}
                    role="presentation"
                    ref={(innerRef) => {
                        const {
                            height,
                        } = innerRef?.getBoundingClientRect() || {};

                        getDimensions( // used for the fly out menu
                            item.segment,
                            height + LEVEL_TWO_ITEM_OFFSET, // no necessity to be too precise
                        );
                    }}
                >
                    {secondLevelItemLabel}
                    <ul className="navigation-sub-section-items">
                        {subSection}
                    </ul>
                </li>
            </UserPermission>
        );
    };

    const subItemsContainerClasses = ClassNames(
        'navigation-sub-items',
        item.segment,
        { 'vertically-expanded': shouldRenderMobileBreadcrumbs },
    );

    return (
        <ul
            className={subItemsContainerClasses}
            style={style}
        >
            { renderLevelTwo() }
        </ul>
    );
}

NavigationLevelTwo.propTypes = propTypes;
NavigationLevelTwo.defaultProps = defaultProps;

export default connect(mapStateToProps)(NavigationLevelTwo);
