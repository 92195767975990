import { isNil } from 'lodash';
import { Currency } from '../../../global/models';
import { Gift } from './models';
import ActionType from './detailsDrawer.actionTypes';

export const DEFAULT_STATE: Gift = {
    amount: {
        amount: null,
        currency: Currency.USD,
    },
    appliedTo: null,
    channelId: null,
    channelName: null,
    chargeback: null,
    depositedDateUtc: null,
    designation: null,
    designationCampus: null,
    designationCampusId: null,
    designationId: null,
    giverAddress: null,
    giverAge: null,
    giverCampus: null,
    giverEmail: null,
    giverFirstName: null,
    giverGender: null,
    giverLastName: null,
    giverMaritalStatus: null,
    giverName: null,
    giverNickName: null,
    giverPersonUniqueId: null,
    giverPhone: null,
    giverPictureUrl: null,
    giverSuffix: null,
    id: null,
    modifiedOnUtc: null,
    modifier: null,
    originalAmount: null,
    paymentDateUtc: null,
    paymentDetails: null,
    paymentMethod: null,
    paymentPlan: null,
    paymentProviderGiftId: null,
    paymentStatus: null,
    personUniqueIdChangeCount: null,
    refund: null,
    status: null,
    type: null,
};

export default (
    state: Gift | undefined,
    action: any,
): Gift => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.RESET: {
                return DEFAULT_STATE;
            }

            case ActionType.SET_DATA: {
                const data = action.value;

                return {
                    ...state,
                    ...data,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
