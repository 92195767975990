import PropTypes from 'prop-types';

export const ACTION_TYPES_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
}));

export const ACTION_TYPES_FOR_SELECT_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
}));

export const BEM_BLOCK_NAME = 'follow_up_rule_editor_drawer';

// TODO/FIXME: Maybe we should consider define some global constant that
//             represents the prop type an array of Select/Drodown options
export const CHURCH_CAMPUS_OPTIONS_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.number,
}));

export const CONDITIONS_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
}));

export const CONDITIONS_FOR_SELECT_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
}));

export const DELAYED_ACTION_DEPENDENCIES_TYPE = Object.freeze({
    Milestone: 'milestone',
});

export const EDITOR_MODE = Object.freeze({
    add: Symbol('Add'),
    edit: Symbol('Edit'),
});

export const EDITOR_MODE_PROP_TYPE = PropTypes.oneOf([
    EDITOR_MODE.add,
    EDITOR_MODE.edit,
]);

export const EDITOR_STATE = Object.freeze({
    nothingSelected: Symbol('Nothing Selected'),
    basicMetadataSelected: Symbol('Basic Metadata Selected'),
    triggerDetailsSelected: Symbol('Trigger Details Selected'),
    entityConditionDetailsSelected: Symbol('Entity Condition Details Selected'),
    attributeConditionDetailsSelected: Symbol('Attribute Condition Details Selected'),
    actionDetailsSelected: Symbol('Action Details Selected'),
    taskActionDetailsSelected: Symbol('Task Action Details Selected'),
    emailActionDetailsSelected: Symbol('Email Action Details Selected'),
    cancelActionSectionSelected: Symbol('Cancel Action Section Selected'),
    cancelDelayedActionsActionDetailsSelected: Symbol('Cancel Delayed Actions Action Details Selected'),
});

export const EDITOR_STATE_PROP_TYPE = PropTypes.oneOf([
    EDITOR_STATE.nothingSelected,
    EDITOR_STATE.basicMetadataSelected,
    EDITOR_STATE.triggerDetailsSelected,
    EDITOR_STATE.entityConditionDetailsSelected,
    EDITOR_STATE.attributeConditionDetailsSelected,
    EDITOR_STATE.actionDetailsSelected,
    EDITOR_STATE.taskActionDetailsSelected,
    EDITOR_STATE.emailActionDetailsSelected,
    EDITOR_STATE.cancelDelayedActionsActionDetailsSelected,
]);

export const MILESTONE_OPTIONS_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
}));

export const WORKFLOW_ENTITY_PROP_TYPE = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    typeFriendlyName: PropTypes.string,
});
