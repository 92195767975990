import { combineReducers } from 'redux';
import personForm from './global/addPersonRecordReducer.js';
import childrenCheckIn from './global/childrenCheckInReducer.js';
import commonlyAttendedService from './global/commonlyAttendedServiceReducer.js';
import contactFormDrawer from './global/contactFormDrawerReducer.js';
import coreMilestones from './global/coreMilestonesReducer.js';
import emergencyContactForm from './global/emergencyContactFormReducer.js';
import notesCategories from './global/personNotesCategoriesReducer.js';
import notesFilter from './global/personNotesFilterReducer.js';
import noteForm from './global/personNotesFormReducer.js';
import notes from './global/personNotesReducer.js';
import index from './global/personRecordReducer.js';
import person from './global/personReducer.js';
import possibleDuplicatesAddPerson from './global/possibleDuplicatesAddPersonReducer.js';
import relationships from './global/relationshipConnections.reducer';

const reducers = combineReducers({
    childrenCheckIn,
    commonlyAttendedService,
    contactFormDrawer,
    coreMilestones,
    emergencyContactForm,
    index,
    person,
    personForm,
    noteForm,
    notes,
    notesFilter,
    notesCategories,
    possibleDuplicatesAddPerson,
    relationships,
});

export default reducers;
