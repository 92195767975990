const DEFAULT_STATE = {
    actionBar: {},
    blockType: 'page',
    canReadPersonNotes: false,
    detailsWindow: {
        limitedPersonalInfo: false,
        md: {
            columns: undefined,
            height: undefined,
            removeTransitionStyle: false,
        },
        sm: {
            columns: undefined,
            height: undefined,
            removeTransitionStyle: false,
        },
    },
    elements: {
        dw: null,
        dwBottomShadow: null,
        dwCoreMilestonesCol: null,
        dwInner: null,
        dwPersonAvatar: null,
        dwPersonAvatarCol: null,
        dwPersonInfo: null,
        dwPersonInfoRowLast: null,
        dwSubNav: null,
        spPersonal: null,
        spPersonalInner: null,
        spPersonalRc: null,
    },
    isNotesDrawerExpanded: false,
    isNotesDrawerOpen: false,
    lifeStageType: '',
};

export default (state = DEFAULT_STATE, action) => {
    const actionType = 'RecordActions';

    switch (action.type) {
        case `${actionType}.ON_RESET`: {
            return {
                ...DEFAULT_STATE,
            };
        }

        case `${actionType}.ON_RESET_ACTION_BAR`: {
            return {
                ...state,
                actionBar: DEFAULT_STATE.actionBar,
            };
        }

        case `${actionType}.ON_RESET_DETAILS_WINDOW`: {
            return {
                ...state,
                detailsWindow: DEFAULT_STATE.detailsWindow,
            };
        }

        case `${actionType}.ON_SET_ACTION_BAR`: {
            return {
                ...state,
                actionBar: action.result,
            };
        }

        case `${actionType}.ON_SET_BLOCK_TYPE`: {
            return {
                ...state,
                blockType: action.result,
            };
        }

        case `${actionType}.ON_SET_CAN_READ_PERSON_NOTES`: {
            return {
                ...state,
                canReadPersonNotes: action.result,
            };
        }

        case `${actionType}.ON_SET_DETAILS_WINDOW`: {
            return {
                ...state,
                detailsWindow: action.result,
            };
        }

        case `${actionType}.ON_SET_ELEMENTS`: {
            return {
                ...state,
                elements: {
                    ...state.elements,
                    ...action.result,
                },
            };
        }

        case `${actionType}.ON_SET_LIFE_STAGE_TYPE`: {
            return {
                ...state,
                lifeStageType: action.result,
            };
        }

        case `${actionType}.ON_SET_IS_NOTES_DRAWER_EXPANDED`: {
            return {
                ...state,
                isNotesDrawerExpanded: action.result,
            };
        }

        case `${actionType}.ON_SET_IS_NOTES_DRAWER_OPEN`: {
            return {
                ...state,
                isNotesDrawerOpen: action.result,
            };
        }

        default:
            return state;
    }
};
