import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const _localCache = new ChunkedPaginationUtils(25, 50);

export const DEFAULT_STATE = {
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: _localCache.getPageSize(),
    results: [],
    total: 0,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryNotificationActions.ON_SMSNOTIFICATIONSBYLOCATION':
            const { callbackParams, result } = action;

            if (result && result.list && result && result.count && callbackParams && callbackParams.first) {
                _localCache.loadPage(result.list, result.count, callbackParams.first);
            }

            return {
                canLoadMore: _localCache.canLoadMore(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageNumber: _localCache.getCurrentPageNumber(),
                pageSize: _localCache.getPageSize(),
                results: _localCache.getAll(true),
                total: _localCache.getTotalCount(),
            };

        case 'MinistryNotificationActions.ON_SMSNOTIFICATIONSBYLOCATION_NEXTPAGE':
            return {
                canLoadMore: _localCache.canLoadMore(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageNumber: _localCache.getCurrentPageNumber(),
                pageSize: _localCache.getPageSize(),
                results: _localCache.getAll(true),
                total: _localCache.getTotalCount(),
            };
    }

    return state;
}
