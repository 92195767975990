import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GROUPS_PROP_TYPES } from './constants';
import ImageUploaderContent from './imageUploaderContent.jsx';

const propTypes = {
    allOptions: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    filename: PropTypes.string,
    groups: GROUPS_PROP_TYPES.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

const defaultProps = {
    allOptions: true,
    disabled: false,
    classes: {},
    filename: '',
    readOnly: false,
};

const mapStateToProps = (state) => {
    const {
        global: {
            imageUploader,
        },
    } = state;

    return {
        imageUploader,
    };
};

const BLOCK_CLASS_NAME = 'image_uploader_groups';

class ImageUploaderGroupsMobile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(group) {
        const { onChange } = this.props;
        onChange(group);
    }

    render() {
        const {
            allOptions,
            classes,
            disabled,
            filename,
            groups,
            readOnly,
        } = this.props;

        return (
            <div>
                {
                    groups.map((group, index) => (
                        <React.Fragment>
                            <ImageUploaderContent
                                allOptions={allOptions}
                                classes={classes}
                                disabled={disabled}
                                fileName={filename}
                                group={group}
                                id={`${BLOCK_CLASS_NAME}--${group.id}`}
                                index={index}
                                key={`${BLOCK_CLASS_NAME}--${group.id}`}
                                onChange={this.onChange}
                                previewUrl={group.imageContainer.src}
                                readOnly={readOnly}
                                width="100%"
                            />
                        </React.Fragment>
                    ))
                }
            </div>
        );
    }
}

ImageUploaderGroupsMobile.propTypes = propTypes;
ImageUploaderGroupsMobile.defaultProps = defaultProps;

export default connect(mapStateToProps)(ImageUploaderGroupsMobile);
