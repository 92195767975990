import {
    isNil,
    isEmpty,
    map,
    some,
} from 'lodash';
import isAbleToSaveCancelDelayedActions from './ruleActions/cancelDelayedActionsAction/cancelDelayedActionsActionUtils.js';
import { ACTION_TYPE } from './ruleActions/constants.js';
import isAbleToSaveEmailTemplate from './ruleActions/emailAction/emailActionUtils.js';
import { isAbleToSaveFollowUpTemplate } from './ruleActions/followUpAction/followUpActionUtils.js';
import {
    isAbleToSaveRuleActions,
    updateRuleAction,
    updateRuleActionsWithCampus,
} from './ruleActions/ruleActionsReducerTransforms.js';
import {
    isAbleToSaveBasicInfo,
    updateRuleBasicInfo,
} from './ruleBasicInfo/ruleBasicInfoReducerTransforms.js';
import { isRuleAttributeConditionValid } from './ruleTrigger/attributeConditionUtils.js';
import { ATTRIBUTE_TYPE } from './ruleTrigger/attributeConstants.js';
import { isRuleEntityConditionValid } from './ruleTrigger/entityConditionUtils.js';
import {
    isAbleToSaveRuleTrigger,
    updateRuleTrigger,
} from './ruleTrigger/ruleTriggerReducerTransforms.js';

// default state when we're initialized but are not yet adding or editing a rule
export const INITIALIZED_DEFAULT_STATE = {
    ableToSaveRule: false,
    ableToSaveRuleActions: false,
    ableToSaveRuleBasicInfo: false,
    ableToSaveRuleTrigger: false,
    editingActionId: null,
    editingAttributeConditionId: null,
    editingEntityConditionId: null,
    isDirty: false,
    isFetching: false,
    isSaving: false,
    rule: {},
    ruleActionDetailEditorMode: null,
    ruleTriggerDetailEditorMode: null,
};

// i.e. _uninitialized_ state; truly the default state
export const DEFAULT_STATE = {
    ...INITIALIZED_DEFAULT_STATE,
    defaultRuleChurchEntityId: null,
    defaultRuleChurchEntityName: null,
    domainSpecificLogic: null,
    isInitialized: false,
    isUnsupportedEntityType: false,
    primaryEntity: {},
    secondaryEntity: null,
};

export const setDefaultAddNewRuleState = (existingState) => {
    const {
        defaultRuleChurchEntityId,
        defaultRuleChurchEntityName,
        domainSpecificLogic,
        isUnsupportedEntityType,
        primaryEntity,
        secondaryEntity,
    } = existingState;

    if (isUnsupportedEntityType) {
        return existingState;
    }

    const newRule = domainSpecificLogic.getDefaultNewRule({
        primaryEntity,
        secondaryEntity,
        defaultRuleChurchEntityId,
        defaultRuleChurchEntityName,
    });

    return {
        ...existingState,
        // Not able to save overall rule
        ableToSaveRule: false,
        // Not able to save Rule Actions in particular until we have at least one valid action
        ableToSaveRuleActions: false,
        // Not able to save Rule Basic Info in particular until Name, Description and Campus have been specified
        ableToSaveRuleBasicInfo: false,
        // Compute ability to save trigger (*SHOULD* be true ... default condition(s) should be valid)
        ableToSaveRuleTrigger: isAbleToSaveRuleTrigger(newRule, domainSpecificLogic),
        // New Rule is dirty
        isDirty: true,
        // And ... drumroll please ... here's da Rule! :-)
        rule: newRule,
    };
};

const WorkflowRuleEditor = (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        case 'WorkflowRuleEditorActions.ON_INITIALIZE':
        {
            const { isInitialized } = state;
            if (isInitialized) {
                return state;
            }

            const {
                domainSpecificLogic,
                defaultRuleChurchEntityId,
                defaultRuleChurchEntityName,
                primaryEntity,
                secondaryEntity,
            } = action;

            /* istanbul ignore next */
            if (isEmpty(primaryEntity)) {
                // eslint-disable-next-line no-console
                console.warn('[rule editor reducer] primaryEntity is not populated!  Cannot initialize!');
                return state;
            }

            const isUnsupportedEntityType = isNil(domainSpecificLogic);

            /* istanbul ignore if  */
            if (isUnsupportedEntityType) {
                // eslint-disable-next-line no-console
                console.warn('[rule editor reducer] Domain-specific logic was null.  Typically this means that building Rules for the current entity type is not presently supported.');
            }

            return {
                ...state,
                defaultRuleChurchEntityId,
                defaultRuleChurchEntityName,
                domainSpecificLogic,
                isUnsupportedEntityType,
                primaryEntity,
                secondaryEntity,
                isInitialized: true,
            };
        }

        case 'WorkflowRulesListActions.ON_ADD_RULE':
            return setDefaultAddNewRuleState(state, action);

        case 'WorkflowRuleEditorActions.ON_BEFORE_GETWORKFLOWRULEDETAILS':
            return { ...state,
                isFetching: true };

        case 'WorkflowRuleEditorActions.ON_GETWORKFLOWRULEDETAILS':
        case 'WorkflowRuleEditorActions.ON_SET_RULE':
        {
            // TODO/FIXME: Should of of this work (computing 'able to save' state)
            //             be delegated to transforms in each area?

            // compute ability to save Rule Basic Info
            const ableToSaveRuleBasicInfo = isAbleToSaveBasicInfo(actionResult);

            // compute ability save each Entity Condition
            actionResult.entityConditions = map(
                actionResult.entityConditions,
                (ec) => ({
                    ...ec,
                    ableToSave: isRuleEntityConditionValid(ec),
                }),
            );

            // compute ability save each Attribute Condition
            actionResult.attributeConditions = map(
                actionResult.attributeConditions,
                (ac) => ({
                    ...ac,
                    ableToSave: isRuleAttributeConditionValid(ac),
                }),
            );

            // compute ability to save Rule Trigger
            const ableToSaveRuleTrigger =
                isAbleToSaveRuleTrigger(actionResult, state.domainSpecificLogic);

            // compute ability to save each Action
            actionResult.actions = map(
                actionResult.actions,
                (a) => {
                    switch (a.actionType) {
                        case ACTION_TYPE.CreateFollowUp:
                            return {
                                ...a,
                                ableToSave: isAbleToSaveFollowUpTemplate(a.actionTemplate),
                            };

                        case ACTION_TYPE.SendEmail:
                            return {
                                ...a,
                                ableToSave: isAbleToSaveEmailTemplate(a.actionSettings),
                            };

                        case ACTION_TYPE.CancelDelayedActions:
                            return {
                                ...a,
                                ableToSave: isAbleToSaveCancelDelayedActions(
                                    a.actionSettings,
                                    state.primaryEntity.type,
                                ),
                            };

                        /* istanbul ignore next */
                        default:
                            return {
                                ...a,
                                ableToSave: true,
                            };
                    }
                },
            );

            const ableToSaveRuleActions = isAbleToSaveRuleActions(actionResult);

            const ableToSaveRule =
                ableToSaveRuleBasicInfo &&
                ableToSaveRuleTrigger &&
                ableToSaveRuleActions;

            return {
                ...state,
                ableToSaveRule,
                ableToSaveRuleActions,
                ableToSaveRuleBasicInfo,
                ableToSaveRuleTrigger,
                isFetching: false,
                rule: actionResult,
            };
        }

        case 'WorkflowRuleEditorActions.ON_UPDATE_RULE_CHURCH_ENTITY':
        {
            const updatedState = updateRuleBasicInfo(state, actionType, actionValue);
            const { rule } = updatedState;
            const updatedRule = updateRuleActionsWithCampus(rule, rule.churchEntityId);

            const ableToSaveRuleTrigger =
                isAbleToSaveRuleTrigger(updatedRule, state.domainSpecificLogic);

            const ableToSaveRule = updatedState.ableToSaveRuleBasicInfo &&
                ableToSaveRuleTrigger &&
                state.ableToSaveRuleActions;

            let hasChurchEntityAttributeConditionIfNeeded = true;

            if (ableToSaveRule &&
                state.domainSpecificLogic.requiresChurchEntityAttributeCondition()) {
                hasChurchEntityAttributeConditionIfNeeded = some(
                    updatedRule.attributeConditions,
                    (ac) => ac.attribute === ATTRIBUTE_TYPE.Campus,
                );
            }

            return {
                ...updatedState,
                rule: updatedRule,
                ableToSaveRule: ableToSaveRule && hasChurchEntityAttributeConditionIfNeeded,
                ableToSaveRuleTrigger,
            };
        }

        case 'WorkflowRuleEditorActions.ON_UPDATE_RULE_DESCRIPTION':
        case 'WorkflowRuleEditorActions.ON_UPDATE_RULE_NAME':
        case 'WorkflowRuleEditorActions.ON_UPDATE_RULE_STATUS':
        {
            const updatedState = updateRuleBasicInfo(state, actionType, actionValue);

            return {
                ...updatedState,
                ableToSaveRule: updatedState.ableToSaveRuleBasicInfo &&
                    state.ableToSaveRuleTrigger &&
                    state.ableToSaveRuleActions,
            };
        }

        case 'WorkflowRuleTriggerActions.ON_ADD_ATTRIBUTE_CONDITION':
        case 'WorkflowRuleTriggerActions.ON_DONE_EDITING_ATTRIBUTE_CONDITION':
        case 'WorkflowRuleTriggerActions.ON_DONE_EDITING_ENTITY_CONDITION':
        case 'WorkflowRuleTriggerActions.ON_EDIT_ATTRIBUTE_CONDITION':
        case 'WorkflowRuleTriggerActions.ON_EDIT_ENTITY_CONDITION':
        case 'WorkflowRuleTriggerActions.ON_REMOVE_ATTRIBUTE_CONDITION':
        case 'WorkflowRuleTriggerActions.ON_UPDATE_ATTRIBUTE_CONDITION_ATTRIBUTE':
        case 'WorkflowRuleTriggerActions.ON_UPDATE_ATTRIBUTE_CONDITION_CONDITION_TYPE':
        case 'WorkflowRuleTriggerActions.ON_UPDATE_ATTRIBUTE_CONDITION_PARAMETER':
        case 'WorkflowRuleTriggerActions.ON_UPDATE_ATTRIBUTE_CONDITION_ENTITY_REFERENCE':
        case 'WorkflowRuleTriggerActions.ON_UPDATE_ENTITY_CONDITION_CONDITION_TYPE':
        case 'WorkflowRuleTriggerActions.ON_UPDATE_ENTITY_CONDITION_PARAMETER':
        {
            const updatedState = updateRuleTrigger(state, actionType, actionValue);

            return {
                ...updatedState,
                ableToSaveRule: state.ableToSaveRuleBasicInfo &&
                    updatedState.ableToSaveRuleTrigger &&
                    state.ableToSaveRuleActions,
            };
        }

        case 'WorkflowRuleActionActions.ON_ADD_RULE_ACTION':
        case 'WorkflowRuleActionActions.ON_EDIT_RULE_ACTION':
        case 'WorkflowRuleActionActions.ON_REMOVE_RULE_ACTION':
        case 'WorkflowRuleActionActions.ON_UPDATE_RULE_ACTION_DELAY':
        case 'WorkflowRuleActionActions.ON_UPDATE_RULE_ACTION_DELAYED_ACTION_DEPENDENCIES':
        case 'WorkflowRuleActionActions.ON_UPDATE_RULE_ACTION_STATUS':
        case 'WorkflowRuleActionActions.ON_UPDATE_ABLE_TO_SAVE_ACTION_TEMPLATE':
        case 'WorkflowRuleActionActions.ON_UPSERT_RULE_ACTION_SETTING':
        case 'FollowUpActionEditorActions.ON_REMOVE_ASSIGNEE':
        case 'FollowUpActionEditorActions.ON_REMOVE_SUPERVISOR':
        case 'FollowUpActionEditorActions.ON_UPDATE_ASSIGNEE_TEAM':
        case 'FollowUpActionEditorActions.ON_UPDATE_ASSIGNEE_USER':
        case 'FollowUpActionEditorActions.ON_UPDATE_DUE_DATE_TIME_LIMIT':
        case 'FollowUpActionEditorActions.ON_UPDATE_FOLLOW_UP_TITLE':
        case 'FollowUpActionEditorActions.ON_UPDATE_FOLLOW_UP_INSTRUCTIONS':
        case 'FollowUpActionEditorActions.ON_UPDATE_SUPERVISOR':
        case 'FollowUpActionEditorActions.ON_UPDATE_CATEGORY':
        case 'FollowUpActionEditorActions.ON_UPDATE_TASK_NOTIFICATION_TEMPLATE_ID':
        case 'FollowUpActionEditorActions.ON_UPDATE_TASK_NOTIFICATION_TYPE':
        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_FROM_DISPLAY_NAME':
        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_FROM_EMAIL':
        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_REPLY_TO_EMAIL':
        case 'EmailTemplateEditorActions.ON_UPDATE_FROM_EMAIL_USER':
        case 'EmailTemplateEditorActions.ON_UPDATE_REPLY_TO_DISPLAY_NAME':
        case 'EmailTemplateEditorActions.ON_UPDATE_REPLY_TO_EMAIL_USER':
        case 'EmailTemplateEditorActions.ON_UPDATE_EMAIL_TEMPLATE':
        case 'EmailTemplateEditorActions.ON_REMOVE_EMAIL_TEMPLATE':
        {
            const updatedState = updateRuleAction(state, actionType, actionValue);
            const ableToSaveRule = state.ableToSaveRuleBasicInfo &&
                state.ableToSaveRuleTrigger &&
                !isEmpty(updatedState.rule.actions) &&
                updatedState.ableToSaveRuleActions;

            return {
                ...updatedState,
                ableToSaveRule,
            };
        }

        case 'CommunicationTemplateEditorActions.ON_UPDATE':
            return updateRuleAction(state, actionType, actionResult);

        case 'WorkflowRuleEditorActions.ON_BEFORE_CREATEWORKFLOWRULE':
        case 'WorkflowRuleEditorActions.ON_BEFORE_UPDATEWORKFLOWRULE':
        case 'WorkflowRuleEditorActions.ON_BEFORE_DELETEWORKFLOWRULE':
            return {
                ...state,
                isSaving: true,
            };

        case 'WorkflowRuleEditorActions.ON_CREATEWORKFLOWRULE':
        case 'WorkflowRuleEditorActions.ON_UPDATEWORKFLOWRULE':
        case 'WorkflowRuleEditorActions.ON_DELETEWORKFLOWRULE':
            return {
                ...state,
                isDirty: false,
                isSaving: false,
            };

        case 'WorkflowRuleEditorActions.ON_SAVE_ERROR':
            return {
                ...state,
                isSaving: false,
            };

        case 'WorkflowRuleEditorActions.ON_CANCEL_RULE_EDITOR':
            return {
                ...state,
                ...INITIALIZED_DEFAULT_STATE,
            };

        // Reset all state to default
        case 'WorkflowRuleEditorActions.RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default WorkflowRuleEditor;
