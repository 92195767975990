/**
* This enumeration contains the Action Type string constants for Gift Editor
*/
enum ActionType {
    RESET = 'GiftEditorActions.ON_RESET_FILTERS',
    SET_GIFT_AMOUNT = 'GiftEditorActions.ON_SET_GIFT_AMOUNT',
    SET_GIFT_PAYMENT_DATE = 'GiftEditorActions.ON_SET_GIFT_PAYMENT_DATE',
    SET_DESIGNATION_CAMPUS_ID = 'GiftEditorActions.ON_SET_DESIGNATION_CAMPUS_ID',
    SET_CHANNEL_ID = 'GiftEditorActions.ON_SET_CHANNEL_ID',
    SET_DESIGNATION_ID = 'GiftEditorActions.ON_SET_DESIGNATION_ID',
    SET_GIFT_EDITOR_DATA = 'GiftEditorActions.ON_SET_GIFT_EDITOR_DATA',
    SET_GIVER_PAYMENT_FIRST_NAME = 'GiftEditorActions.ON_SET_GIVER_PAYMENT_FIRST_NAME',
    SET_GIVER_PAYMENT_LAST_NAME = 'GiftEditorActions.ON_SET_GIVER_PAYMENT_LAST_NAME',
    SET_GIVER_PAYMENT_METHOD_TYPE = 'GiftEditorActions.ON_SET_GIVER_PAYMENT_METHOD_TYPE',
    SET_GIVER_PERSON_UNIQUE_ID = 'GiftEditorActions.ON_SET_GIVER_PERSON_UNIQUE_ID',
}

export default ActionType;
