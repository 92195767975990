import {
    cloneDeep,
    filter,
    find,
    forEach,
    isNil,
    isString,
    remove,
    sortBy,
} from 'lodash';
import {
    selectBoxTransform,
} from '../../../../global/utils';
import ChunkedPaginationUtils from '../../../../global/chunkedPaginationUtils.js';

const pagedTeamRosterMembers = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    all: [],
    allForSelect: [],
    byCampus: [],
    roster: {},
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'AdminTeamActions.ON_GETALL':
            return {
                ...state,
                all: action.result,
                allForSelect: selectBoxTransform(action, 'id', 'name'),
            };

        case 'AdminTeamActions.ON_GETALLBYCAMPUS':
            return { ...state, byCampus: action.result };

        case 'AdminTeamActions.ON_GETMEMBERS':
            pagedTeamRosterMembers.loadPage(
                action.result.results, action.result.resultCount, action.params.pageNumber === 0,
            );

            return {
                ...state,
                roster: {
                    results: pagedTeamRosterMembers.getAll(true),
                    total: pagedTeamRosterMembers.getTotalCount(),
                    canLoadMore: pagedTeamRosterMembers.canLoadMore(),
                    needsToRequest: pagedTeamRosterMembers.needsToLoadPage(),
                    pageNumber: pagedTeamRosterMembers.getCurrentPageNumber(),
                    pageSize: pagedTeamRosterMembers.getPageSize(),
                },
            };

        case 'AdminTeamActions.ON_GETMEMBERSNEXTPAGE':
            return {
                ...state,
                roster: {
                    results: pagedTeamRosterMembers.getAll(true),
                    total: pagedTeamRosterMembers.getTotalCount(),
                    canLoadMore: pagedTeamRosterMembers.canLoadMore(),
                    needsToRequest: pagedTeamRosterMembers.needsToLoadPage(),
                    pageNumber: pagedTeamRosterMembers.getCurrentPageNumber(),
                    pageSize: pagedTeamRosterMembers.getPageSize(),
                },
            };

        case 'AdminTeamActions.ON_CREATE':
        {
            const newTeam = action.result;
            const newAllTeams = sortBy(state.byCampus.concat([newTeam]), ['name', 'churchEntityName']);
            const newTeamsByCampus = sortBy(state.byCampus.concat([newTeam]), 'name');

            return {
                ...state,
                all: newAllTeams,
                byCampus: newTeamsByCampus,
            };
        }

        case 'AdminTeamActions.ON_UPDATE':
        {
            const newTeam = action.result;
            const newAllTeams = cloneDeep(state.all);
            const newTeamsByCampus = cloneDeep(state.byCampus);

            const existingTeamFromAllTeams = find(newAllTeams, (t) => t.id === newTeam.id);

            if (!isNil(existingTeamFromAllTeams)) {
                Object.assign(existingTeamFromAllTeams, newTeam);
            }

            const existingTeamFromByCampus = find(newTeamsByCampus, (t) => t.id === newTeam.id);

            if (!isNil(existingTeamFromByCampus)) {
                Object.assign(existingTeamFromByCampus, newTeam);
            }

            return {
                ...state,
                all: newAllTeams,
                byCampus: newTeamsByCampus,
            };
        }

        case 'AdminTeamActions.ON_DELETE':
        {
            const newAllTeams = filter(state.all, (t) => t.id !== action.params.teamId);
            const newTeamsByCampus = filter(state.byCampus, (t) => t.id !== action.params.teamId);

            return {
                ...state,
                all: newAllTeams,
                byCampus: newTeamsByCampus,
            };
        }

        case 'AdminTeamActions.ON_ADDMEMBERS':
        {
            const { teamId, personIds } = action.params;
            const personIdString = isString(personIds) ? personIds : personIds.toString();
            const personIdsSplit = personIdString.split(',');
            const numPersonIds = personIdsSplit.length;
            const newAllTeams = cloneDeep(state.all);
            const newTeamsByCampus = cloneDeep(state.byCampus);

            const existingTeamFromAllTeams = find(newAllTeams, (t) => t.id === teamId);

            if (!isNil(existingTeamFromAllTeams)) {
                existingTeamFromAllTeams.memberCount += numPersonIds;
            }

            const existingTeamFromByCampus = find(newTeamsByCampus, (t) => t.id === teamId);

            if (!isNil(existingTeamFromByCampus)) {
                existingTeamFromByCampus.memberCount += numPersonIds;
            }

            return {
                ...state,
                all: newAllTeams,
                byCampus: newTeamsByCampus,
            };
        }

        case 'AdminTeamActions.ON_REMOVEMEMBERS':
        {
            const { teamId, personIds } = action.params;
            const personIdString = isString(personIds) ? personIds : personIds.toString();
            const personIdsSplit = personIdString.split(',');
            const numPersonIds = personIdsSplit.length;
            const newAllTeams = cloneDeep(state.all);
            const newTeamsByCampus = cloneDeep(state.byCampus);

            const existingTeamFromAllTeams = find(newAllTeams, (t) => t.id === teamId);

            if (!isNil(existingTeamFromAllTeams)) {
                existingTeamFromAllTeams.memberCount -= numPersonIds;
            }

            const existingTeamFromByCampus = find(newTeamsByCampus, (t) => t.id === teamId);

            if (!isNil(existingTeamFromByCampus)) {
                existingTeamFromByCampus.memberCount -= numPersonIds;
            }

            const rosterResults = cloneDeep(state.roster.results);

            forEach(personIdsSplit, (id) => {
                remove(rosterResults, (person) => person.id === id * 1);
            });

            return {
                ...state,
                all: newAllTeams,
                byCampus: newTeamsByCampus,
                roster: { ...state.roster, results: rosterResults },
            };
        }

        case 'AdminTeamActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
