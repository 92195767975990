import { combineReducers } from 'redux';
import digitalProgramButtonForm from './builder/digitalProgramButtonForm.reducer';
import digitalProgramButtonsInfo from './builder/digitalProgramButtonsInfo.reducer';

const reducers = combineReducers({
    buttonForm: digitalProgramButtonForm,
    buttonsInfo: digitalProgramButtonsInfo,
});

export default reducers;
