export const NAVIGATION_DISABLE = 'NAVIGATION_DISABLE';
export const NAVIGATION_RESET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER = 'NAVIGATION_RESET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER';
export const NAVIGATION_SET_BREADCRUMBS = 'NAVIGATION_SET_BREADCRUMBS';
export const NAVIGATION_SET_HEADER_BREADCRUMBS = 'NAVIGATION_SET_HEADER_BREADCRUMBS';
export const NAVIGATION_SET_IS_COLLAPSED = 'NAVIGATION_SET_MENU_COLLAPSE';
export const NAVIGATION_SET_ITEMS = 'NAVIGATION_SET_ITEMS';
export const NAVIGATION_SET_MY_MINISTRY = 'NAVIGATION_SET_MY_MINISTRY';
export const NAVIGATION_SET_SECTIONAL_TABS = 'NAVIGATION_SET_SECTIONAL_TABS';
export const NAVIGATION_SET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER = 'NAVIGATION_SET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER';
export const NAVIGATION_TOGGLE_COLLAPSE = 'NAVIGATION_TOGGLE_COLLAPSE';
