import {
    HealthyChurchEnvironment,
} from '../../global/api/models';
import {
    isCmDemoEnv,
    isCmDevelopmentEnv,
    isCmFeatureEnv,
    isCmLocalUiEnv,
    isCmProductionEnv,
    isCmQaEnv,
    isCmStagingEnv,
    isCmStaging2Env,
} from '../../global/utils/environments.js';

// eslint-disable-next-line import/prefer-default-export
export const determineAppEnvironment = (): HealthyChurchEnvironment => {
    if (isCmProductionEnv) {
        console.log('Healthy Church API: Using Production configuration'); // eslint-disable-line no-console
        return HealthyChurchEnvironment.Production;
    }

    if (isCmDemoEnv) {
        console.log('Healthy Church API: Using Demo configuration'); // eslint-disable-line no-console
        return HealthyChurchEnvironment.Demo;
    }

    if (isCmStagingEnv) {
        console.log('Healthy Church API: Using Staging configuration'); // eslint-disable-line no-console
        return HealthyChurchEnvironment.Staging;
    }

    if (isCmStaging2Env) {
        console.log('Healthy Church API: Using Staging 2 configuration'); // eslint-disable-line no-console
        return HealthyChurchEnvironment.Staging2;
    }

    if (isCmQaEnv) {
        console.log('Healthy Church API: Using QA configuration'); // eslint-disable-line no-console
        return HealthyChurchEnvironment.QA;
    }

    if (isCmFeatureEnv) {
        console.log('Healthy Church API: Using Feature configuration'); // eslint-disable-line no-console
        return HealthyChurchEnvironment.Feature;
    }

    if (isCmDevelopmentEnv) {
        console.log('Healthy Church API: Using Development configuration'); // eslint-disable-line no-console
        return HealthyChurchEnvironment.Dev;
    }

    if (isCmLocalUiEnv) {
        console.log('Healthy Church API: Local UI Only; Using Development API configuration'); // eslint-disable-line no-console
        return HealthyChurchEnvironment.Dev;
    }

    console.log('Healthy Church API: Using Local configuration'); // eslint-disable-line no-console
    return HealthyChurchEnvironment.Local;
};
