/**
* This enumeration contains the Action Type string constants for Event Detail Info
*/
enum ActionType {
    // Action Type for Event Description
    SET_EVENT_DESCRIPTION = 'EventEditActions.ON_SET_EVENT_DESCRIPTION',
    SET_EVENT_TAGS = 'EventEditActions.ON_SET_EVENT_TAGS',
    // Action Type for Event Contact
    SET_EVENT_PUBLISHED_CONTACT_NAME = 'EventEditActions.ON_SET_EVENT_PUBLISHED_CONTACT_NAME',
    SET_EVENT_PUBLISHED_EMAIL = 'EventEditActions.ON_SET_EVENT_PUBLISHED_EMAIL',
    SET_EVENT_PUBLISHED_PHONE = 'EventEditActions.ON_SET_EVENT_PUBLISHED_PHONE',
    // Action Type for Event Imagery
    SET_EVENT_IMAGE_UPLOADER_DATA = 'EventEditActions.ON_SET_EVENT_IMAGE_UPLOADER_DATA',
    SET_EVENT_IMAGE_UPLOADER_IN_PROGRESS = 'EventEditActions.ON_SET_EVENT_IMAGE_UPLOADER_IN_PROGRESS',
    SET_EVENT_COMPANION_APP_HEADER = 'EventEditActions.ON_SET_EVENT_COMPANION_APP_HEADER',
    SET_EVENT_COMPANION_APP_HEADER_URL = 'EventEditActions.ON_SET_EVENT_COMPANION_APP_HEADER_URL',
    SET_EVENT_WEB_HEADER = 'EventEditActions.ON_SET_EVENT_WEB_HEADER',
    SET_EVENT_WEB_HEADER_URL = 'EventEditActions.ON_SET_EVENT_WEB_HEADER_URL',
    SET_EVENT_THUMBNAIL = 'EventEditActions.ON_SET_EVENT_THUMBNAIL',
    SET_EVENT_THUMBNAIL_URL = 'EventEditActions.ON_SET_EVENT_THUMBNAIL_URL',
}

export default ActionType;
