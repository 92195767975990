import {
    find,
    isEmpty,
    isNil,
    map,
} from 'lodash';
import { Page } from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useState,
} from 'react';
import moment from 'moment-timezone';
import { CELL_PHONE_TYPE_ID } from '../../js/constants/Person/PersonPhoneTypeConstants.js';
import domUtils from '../../global/domUtils.js';
import {
    personDefaultProps,
    personPropTypes,
} from './global/personPropTypes.js';
import PersonEmergencyContactUtils from '../global/personEmergencyContactUtils.js';
import { translationFactory } from '../../global/i18nUtils.js';
import UserAccessStore from '../../global/userAccessStore.js';
import { USER_PERMISSIONS } from '../../global/userPermissionConstants.js';
import { getPersonalSMSConsent } from './service/record.service';

const propTypes = {
    churchEntities: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    commonlyAttendedServiceList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    personData: personPropTypes,
    isNotesDrawerExpanded: PropTypes.bool.isRequired,
    isNotesDrawerOpen: PropTypes.bool.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    churchEntities: [],
    commonlyAttendedServiceList: [],
    personData: personDefaultProps,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        global: { churchCampuses },
        people: {
            record: {
                index: {
                    isNotesDrawerExpanded,
                    isNotesDrawerOpen,
                },
                person: {
                    data: personData,
                },
                commonlyAttendedService: {
                    data: commonlyAttendedServiceList,
                },
            },
        },
    } = state;

    return {
        churchEntities: churchCampuses.getAllCampuses(),
        commonlyAttendedServiceList,
        isNotesDrawerExpanded,
        isNotesDrawerOpen,
        personData,
        userAccess,
    };
};

const i18n = translationFactory('Person.Record.SubPages.Personal.PersonalInfo');

function preferredServiceValue(preferredService) {
    if (!preferredService) {
        return null;
    }

    const {
        schedule: {
            sequences,
        },
    } = preferredService;
    const { startTime, dayOfWeek } = sequences[0];
    const momentOnDayOfWeek = moment().day(dayOfWeek);
    const dayOfWeekName = momentOnDayOfWeek.format('ddd');
    const formattedTimeOfDay = moment()
        .startOf('day')
        .add(moment.duration(startTime))
        .format('h:mm A');

    return `${dayOfWeekName} ${formattedTimeOfDay} ${i18n('Service')}`;
}

function useSMSConsentValue(personId, hasPermission) {
    const [smsConsent, setSmsConsent] = useState(false);

    useEffect(() => {
        if (hasPermission && !isNil(personId)) {
            getPersonalSMSConsent(personId)
                .then((response) => {
                    setSmsConsent(response?.hasConsented ?? false);
                })
                // eslint-disable-next-line no-console
                .catch((error) => { console.log(error); });
        }
    }, [personId, hasPermission]);

    return smsConsent ? i18n('Given') : i18n('Not Given');
}

export function TabsContentPersonalPersonInfo(props) {
    const daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const {
        churchEntities,
        commonlyAttendedServiceList,
        isNotesDrawerOpen,
        isNotesDrawerExpanded,
        personData,
        userAccess,
    } = props;

    const canAccessDateOfBirth = userAccess.hasPermission(
        USER_PERMISSIONS.accessDateOfBirth,
    );

    const canReadPersonContactData = userAccess.hasPermission(
        USER_PERMISSIONS.readPersonContactData,
    );

    const smsConsent = useSMSConsentValue(personData.id, canReadPersonContactData);

    if (isEmpty(personData)) {
        return null;
    }

    const {
        addresses,
        allergies,
        birthDate,
        churchEntityName,
        contactPreferences,
        deceasedDate,
        emails,
        emergencyContacts,
        occupations,
        phones,
        preferredService,
        gender,
        gradeLevel,
        isAdult,
        isChild,
        prefix,
        firstName,
        lastName,
        middleName,
        nickName,
        suffix,
        maritalStatus,
    } = personData;

    const {
        doNotContact: isDoNotContact,
        doNotEmail: isDoNotEmail,
        doNotMail: isDoNotMail,
        doNotPhone: isDoNotPhone,
        doNotText: isDoNotText,
        preferredMethod,
    } = contactPreferences ?? {};

    const bemBlockClass = 'person_record';
    const bemElementClass = 'data_group_person_details';
    const mainDataGroupContainerClasses = ClassNames(
        `${bemBlockClass}--${bemElementClass}`,
        {
            'notes-visible': isNotesDrawerOpen && !isNotesDrawerExpanded,
            'notes-visible-expanded': isNotesDrawerOpen && isNotesDrawerExpanded,
        },
    );
    const containerClasses = `${bemBlockClass}--${bemElementClass}`;
    const containerClassesPersonal = `${bemBlockClass}--${bemElementClass}_personal`;
    const containerClassesContact = `${bemBlockClass}--${bemElementClass}_contact`;
    const containerClassesOccupation = `${bemBlockClass}--${bemElementClass}_occupation`;
    const containerClassesEmergencyContact = `${bemBlockClass}--${bemElementClass}_emergency_contact`;
    const isStudent = !isAdult && !isChild;

    let genderText;
    let gradeLevelText;
    let personPreferredContactMethodText;
    const personContactInfo = find(phones, 'isPrimary');
    const personEmailInfo = find(emails, 'isPrimary');
    const personAddressInfo = find(addresses, 'isPrimary');
    const personOccupationInfo = find(occupations, 'isPrimary');
    const personEmergencyContactInfo = find(emergencyContacts, 'isPrimary');

    switch (preferredMethod) {
        case 'email':
            personPreferredContactMethodText = 'Email';

            break;
        case 'phone':
            personPreferredContactMethodText = 'Phone';

            break;
        case 'text-message':
            personPreferredContactMethodText = 'Text';

            break;
        case 'mail':
            personPreferredContactMethodText = 'Letter';

            break;
        default:
            personPreferredContactMethodText = 'No Contact Preference';

            break;
    }

    switch (gender) {
        case 'F':
            genderText = 'Female';

            break;
        case 'M':
            genderText = 'Male';

            break;

        default:
            genderText = '';
    }

    switch (gradeLevel) {
        case 'PreK':
            gradeLevelText = 'PK';

            break;

        case 'Kindergarten':
            gradeLevelText = 'K';

            break;

        case 'First':
            gradeLevelText = '1st';

            break;

        case 'Second':
            gradeLevelText = '2nd';

            break;

        case 'Third':
            gradeLevelText = '3rd';

            break;

        case 'Fourth':
            gradeLevelText = '4th';

            break;

        case 'Fifth':
            gradeLevelText = '5th';

            break;

        case 'Sixth':
            gradeLevelText = '6th';

            break;

        case 'Seventh':
            gradeLevelText = '7th';

            break;

        case 'Eighth':
            gradeLevelText = '8th';

            break;

        case 'Ninth':
            gradeLevelText = '9th';

            break;

        case 'Tenth':
            gradeLevelText = '10th';

            break;

        case 'Eleventh':
            gradeLevelText = '11th';

            break;

        case 'Twelfth':
            gradeLevelText = '12th';

            break;

        default:
            gradeLevelText = gradeLevel;
    }

    let emergencyContactPreferMethod;
    let emergencyContactPreferredInfo;
    if (!isEmpty(personEmergencyContactInfo)) {
        const dncListEmergencyContact =
            PersonEmergencyContactUtils.getPersonDoNotContactMethods(
                personEmergencyContactInfo.emergencyContactPreference,
            );
        emergencyContactPreferredInfo = PersonEmergencyContactUtils.getPersonPreferredContactMethod(
            personEmergencyContactInfo,
        );
        emergencyContactPreferMethod = personEmergencyContactInfo.emergencyContactPreference.preferredMethod !== 'none' ?
            emergencyContactPreferredInfo.method : `(${i18n('NoPreference')})`;

        if (!isEmpty(dncListEmergencyContact.doNotContactMethodList) &&
            !dncListEmergencyContact.doNotContact
        ) {
            emergencyContactPreferMethod += ` (${i18n('DNCVia')} ${dncListEmergencyContact.doNotContactMethodList.join(', ')})`;
        }
    }

    let mainData = {
        allergies,
        cell: personContactInfo && personContactInfo.phoneTypeId === CELL_PHONE_TYPE_ID ? personContactInfo.displayPhoneNumber : 'N/A',
        commonlyAttendedServiceList,
        deceasedDate: deceasedDate ? moment.unix(deceasedDate).utc().format('MM/DD/YYYY') : 'N/A',
        email: personEmailInfo && personEmailInfo.email ? personEmailInfo.email : 'N/A',
        gradeLevel: gradeLevelText,
        homeCampus: churchEntityName,
        preferredMethod: personPreferredContactMethodText,
        preferredService: preferredServiceValue(preferredService),
        addresses: personAddressInfo,
        placeOfEmployment: personOccupationInfo && personOccupationInfo.company ? personOccupationInfo.company : 'N/A',
        occupationCategory: personOccupationInfo && personOccupationInfo.category ? personOccupationInfo.category : 'N/A',
        occupationTitle: personOccupationInfo && personOccupationInfo.title ? personOccupationInfo.title : 'N/A',
        occupationType: personOccupationInfo && personOccupationInfo.type ? personOccupationInfo.type : 'N/A',
        isOccupationPrimary: personOccupationInfo && personOccupationInfo.isPrimary ? 'Yes' : 'No',
        isStaff: personOccupationInfo && personOccupationInfo.isStaff ? 'Yes' : 'No',
        emergencyContactName: personEmergencyContactInfo &&
            `${personEmergencyContactInfo.emergencyContactPerson.firstName} ${personEmergencyContactInfo.emergencyContactPerson.lastName}`,
        emergencyContactRelation: personEmergencyContactInfo &&
            personEmergencyContactInfo.emergencyContactRelationshipName,
        emergencyContactPreferMethod,
        emergencyContactPreferMethodText: emergencyContactPreferredInfo &&
            emergencyContactPreferredInfo.text,
        prefix: prefix || '',
        firstName: firstName || '',
        lastName: lastName || '',
        middleName: middleName || '',
        nickName: nickName || '',
        suffix: suffix || '',
        gender: genderText,
        maritalStatus: maritalStatus || '',
        isDoNotEmail,
        isDoNotMail,
        isDoNotPhone,
        isDoNotText,
        isDoNotContact,
        smsConsent,
    };

    const mainCoulumn = [];

    let commonlyAttendedServiceContractedRow = [];
    let commonlyAttendedServiceExpandableRow = [];
    let preferredServiceContractedRow = [];
    let preferredServiceExpandableRow = [];
    let personGradeLevelRow = [];
    let personAllergiesContractedRow = [];
    let personAllergiesExpandableRow = [];
    let maritalStatusExpandableRow = [];
    let deceasedDateRow = [];
    let birthDayRow = [];
    let birthDayExpandableRow = [];

    if (deceasedDate) {
        deceasedDateRow = [{
            accessor: 'deceasedDate',
            fieldName: 'Deceased',
        }];
    }

    if (canAccessDateOfBirth) {
        mainData = {
            ...mainData,
            birthday: birthDate ? moment.unix(birthDate).utc().format('MM/DD/YYYY') : 'N/A',
        };
        birthDayRow = [{
            accessor: 'birthday',
            fieldName: 'Birthday',
        }];
        birthDayExpandableRow = [{
            accessor: 'birthday',
            fieldName: i18n('Birthday'),
            iconType: 'birthday-cake',
            iconColor: 'alert',
        }];
    }

    if (isStudent || isChild) {
        personGradeLevelRow = [{
            accessor: 'gradeLevel',
            fieldName: 'Grade',
        }];

        personAllergiesContractedRow = [{
            accessor: 'allergies',
            fieldName: 'Allergies',
        }];

        personAllergiesExpandableRow = [{
            accessor: 'allergies',
            fieldName: 'Allergies',
            iconType: 'plus-circle',
            iconColor: 'alert',
        }];
    }

    if (isChild && !isEmpty(commonlyAttendedServiceList)) {
        const commonlyAttendedService = (
            <p style={{ margin: 0 }} className={`${containerClassesPersonal}_commonly_attended_service_list`}>
                {
                    map(
                        commonlyAttendedServiceList,
                        (item) => (
                            item &&
                            item.id &&
                            item.schedule &&
                            item.schedule.sequences &&
                            item.schedule.sequences.length > 0 && (
                                <span
                                    key={`commonly_attended_service-${item.id}`}
                                >
                                    {daysOfTheWeek[item.schedule.sequences[0].dayOfWeek]}
                                    {' '}
                                    {item.schedule.sequences[0].startTime ? moment(item.schedule.sequences[0].startTime, ['HH.mm:ss']).format('h:mm a') : null}
                                    ,
                                    {' '}
                                    {/* eslint-disable-next-line max-len */}
                                    {churchEntities.find((c) => c.id === item.churchEntityId)?.name ?? null}
                                </span>
                            )),
                    )
                }
            </p>
        );

        commonlyAttendedServiceContractedRow = [{
            accessor: () => commonlyAttendedService,
            fieldName: 'Commonly Attended Services',
            className: `${containerClassesPersonal}_commonly_attended_service`,
        }];
        commonlyAttendedServiceExpandableRow = [{
            accessor: () => commonlyAttendedService,
            fieldName: 'Commonly Attended Services',
            className: `${containerClassesPersonal}_commonly_attended_service`,
            iconType: 'preferred-service',
            iconColor: 'alert',
        }];
    }

    if (!isChild) {
        preferredServiceContractedRow = [{
            accessor: 'preferredService',
            fieldName: 'Preferred Service',
        }];
        preferredServiceExpandableRow = [{
            accessor: 'preferredService',
            fieldName: 'Preferred Service',
            iconType: 'preferred-service',
            iconColor: 'alert',
        }];
    }

    if (isAdult) {
        maritalStatusExpandableRow = [{
            accessor: 'maritalStatus',
            fieldName: 'Marital Status',
            iconType: 'marital-status',
            iconColor: 'alert',
        }];
    }

    const personalColumnInfo = {
        className: containerClassesPersonal,
        isExpandable: true,
        header: 'Personal',
        rows: [
            ...birthDayRow,
            ...personGradeLevelRow,
            {
                accessor: 'homeCampus',
                fieldName: 'Home Campus',
            },
            ...preferredServiceContractedRow,
            ...commonlyAttendedServiceContractedRow,
            ...deceasedDateRow,
            ...personAllergiesContractedRow,
        ],
        expandableSections: [
            {
                header: 'Basic',
                rows: [
                    {
                        accessor: 'prefix',
                        fieldName: 'Title',
                        iconType: 'person',
                        iconColor: 'alert',
                    },
                    {
                        accessor: 'firstName',
                        fieldName: 'First Name',
                    },
                    {
                        accessor: 'middleName',
                        fieldName: 'Middle Name',
                    },
                    {
                        accessor: 'lastName',
                        fieldName: 'Last Name',
                    },
                    {
                        accessor: 'nickName',
                        fieldName: 'Nick Name',
                    },
                    {
                        accessor: 'suffix',
                        fieldName: 'Suffix',
                    },
                    {
                        accessor: 'gender',
                        fieldName: 'Gender',
                        iconType: 'gender',
                        iconColor: 'alert',
                    },
                    ...maritalStatusExpandableRow,
                    ...birthDayExpandableRow,
                    ...personGradeLevelRow,
                ],
            }, {
                header: 'Misc',
                rows: [
                    {
                        accessor: 'homeCampus',
                        fieldName: 'Home Campus',
                        iconType: 'church',
                        iconColor: 'alert',
                    },
                    ...preferredServiceExpandableRow,
                    ...commonlyAttendedServiceExpandableRow,
                    ...personAllergiesExpandableRow,
                ],
            },
        ],
    };

    mainCoulumn.push(personalColumnInfo);

    /* eslint-disable react/destructuring-assignment */
    let personPhonesExpandableRows;
    if (!isEmpty(phones)) {
        personPhonesExpandableRows = map(phones, (item) => {
            let fieldName;
            let iconType;

            switch (item.phoneTypeId) {
                case 1:
                    fieldName = 'Home';
                    iconType = 'phone-home';

                    break;
                case 2:
                    fieldName = 'Work';
                    iconType = 'phone-work';

                    break;
                default:
                    fieldName = 'Cell';
                    iconType = 'phone-cell';
            }

            if (item.isPrimary) {
                fieldName += ' - Primary';
            }

            return {
                accessor: (data) => {
                    const phoneField = `phone-${item.id}`;

                    return (
                        <span
                            className="color-link font-weight-semibold"
                            onClick={(event) => domUtils.onTelLinkClick(event, data[phoneField])}
                            role="presentation"
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {data[phoneField]}
                        </span>
                    );
                },
                fieldName,
                iconType,
                iconColor: 'warning',
                className: ClassNames(`${containerClasses}_contact_phones`, {
                    [`${containerClasses}_contact_phones-not_primary`]: !item.isPrimary,
                    [`${containerClasses}_contact_phones-primary`]: item.isPrimary,
                }),
            };
        });

        map(phones, (item) => {
            const personPhoneExpandableData = {
                [`phone-${item.id}`]: item && item.displayPhoneNumber ? item.displayPhoneNumber : 'N/A',
            };
            mainData = {
                ...mainData,
                ...personPhoneExpandableData,
            };
        });
    }

    let personEmailsExpandableRows;
    if (!isEmpty(emails)) {
        personEmailsExpandableRows = map(emails, (item) => (
            {
                accessor: (data) => {
                    const emailField = `email-${item.id}`;

                    return (
                        <span
                            className="color-link font-weight-semibold"
                            onClick={(event) => domUtils.onMailToLinkClick(event, data[emailField])}
                            role="presentation"
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {data[emailField]}
                        </span>
                    );
                },
                fieldName: item.isPrimary ? 'Primary' : 'Additional',
                iconType: 'email',
                iconColor: 'warning',
                className: ClassNames(`${containerClasses}_contact_emails`, {
                    [`${containerClasses}_contact_emails-not_primary`]: !item.isPrimary,
                    [`${containerClasses}_contact_emails-primary`]: item.isPrimary,
                }),
            }
        ));

        map(emails, (item) => {
            const personEmailExpandableData = {
                [`email-${item.id}`]: item && item.email ? item.email : 'N/A',
            };
            mainData = {
                ...mainData,
                ...personEmailExpandableData,
            };
        });
    }

    let personAddressesExpandableRows;
    if (!isEmpty(addresses)) {
        personAddressesExpandableRows = map(addresses, (item) => (
            {
                accessor: (d) => {
                    const addressInfo = d[`address-${item.id}`] || {};

                    return (
                        <p style={{ margin: 0 }} className="person-record-address">
                            <span>{addressInfo.address1}</span>
                            <br />
                            {addressInfo.address2 && (
                                <span>{addressInfo.address2}</span>
                            )}
                            <span>{`${addressInfo.city}, ${addressInfo.countryAlpha2 === 'US' ? addressInfo.regionCode : addressInfo.region || ''} ${addressInfo.postalCode || ''}`}</span>
                            <br />
                            <span>{addressInfo.country}</span>
                        </p>
                    );
                },
                fieldName: item.isPrimary ? 'Primary' : 'Additional',
                iconType: 'map-marker',
                iconColor: 'warning',
                className: ClassNames(`${containerClasses}_contact_addresses`, {
                    [`${containerClasses}_contact_addresses-not_primary`]: !item.isPrimary,
                    [`${containerClasses}_contact_addresses-primary`]: item.isPrimary,
                }),
            }
        ));

        map(addresses, (item) => {
            const personAddressExpandableData = {
                [`address-${item.id}`]: item,
            };
            mainData = {
                ...mainData,
                ...personAddressExpandableData,
            };
        });
    }

    const personContactPreferences = [];
    let personDonotContactExpandableRows;

    if (!isDoNotContact && (isDoNotEmail || isDoNotMail || isDoNotPhone || isDoNotText)) {
        personDonotContactExpandableRows = {
            accessor: (d) => (
                <p style={{ margin: 0 }} className={`${containerClassesContact}_dnc_method`}>
                    {(d.isDoNotEmail || d.isDoNotContact) && (
                        <span>Email</span>
                    )}
                    {(d.isDoNotMail || d.isDoNotContact) && (
                        <span>Mail</span>
                    )}
                    {(d.isDoNotPhone || d.isDoNotContact) && (
                        <span>Phone</span>
                    )}
                    {(d.isDoNotText || d.isDoNotContact) && (
                        <span>Text</span>
                    )}
                </p>
            ),
            fieldName: 'Do Not Contact Via',
            iconType: 'ban',
            iconColor: 'warning',
            className: `${containerClasses}_contact_preferences`,
        };

        personContactPreferences.push(personDonotContactExpandableRows);
    }

    let personPreferredMethodExpandableRows;
    if (!isDoNotContact && personPreferredContactMethodText) {
        personPreferredMethodExpandableRows = {
            accessor: 'preferredMethod',
            fieldName: 'Preferred Method',
            iconType: 'comment-lines',
            iconColor: 'warning',
            className: `${containerClasses}_contact_preferences`,
        };
        personContactPreferences.push(personPreferredMethodExpandableRows);
    }

    let personContactSMSConsentExpandableRows;

    if (!isDoNotContact) {
        personContactSMSConsentExpandableRows = {
            accessor: 'smsConsent',
            fieldName: i18n('SMS Consent'),
            iconType: 'sms',
            iconColor: 'warning',
            className: `${containerClasses}_contact_preferences`,
        };
        personContactPreferences.push(personContactSMSConsentExpandableRows);
    }

    if (canReadPersonContactData && !isDoNotContact && !isChild) {
        const contactColumnInfo = {
            className: containerClassesContact,
            header: 'Contact',
            isExpandable: true,
            rows: [
                {
                    accessor: (data) => (
                        <span
                            className={data.cell !== 'N/A' ? 'color-link font-weight-semibold' : {}}
                            onClick={(event) => {
                                if (data.cell !== 'N/A') {
                                    domUtils.onTelLinkClick(event, data.cell);
                                }
                            }}
                            role="presentation"
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {data.cell}
                        </span>
                    ),
                    fieldName: 'Cell',
                }, {
                    accessor: (data) => (
                        <span
                            className="color-link font-weight-semibold"
                            onClick={(event) => domUtils.onMailToLinkClick(event, data.email)}
                            role="presentation"
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            {data.email}
                        </span>
                    ),
                    fieldName: 'Email',
                }, {
                    accessor: 'preferredMethod',
                    fieldName: 'Preferred Method',
                }, {
                    accessor: 'smsConsent',
                    fieldName: i18n('SMS Consent'),
                }, {
                    accessor: (d) => (
                        d.addresses ? (
                            <p style={{ margin: 0 }} className="person-record--address">
                                <span>{d.addresses.address1}</span>
                                <br />
                                {d.addresses.address2 && (
                                    <span>{d.addresses.address2}</span>
                                )}
                                <span>{`${d.addresses.city}, ${d.addresses.countryAlpha2 === 'US' ? d.addresses.regionCode : d.addresses.region || ''} ${d.addresses.postalCode || ''}`}</span>
                                <br />
                                <span>{d.addresses.country}</span>
                            </p>
                        ) : 'N/A'
                    ),
                    fieldName: 'Address',
                },
            ],
            expandableSections: [
                {
                    header: 'Preferences',
                    rows: personContactPreferences,
                }, {
                    header: 'Phone',
                    rows: personPhonesExpandableRows,
                }, {
                    header: 'Email',
                    rows: personEmailsExpandableRows,
                }, {
                    header: 'Addresses',
                    rows: personAddressesExpandableRows,
                },
            ],
        };
        /* eslint-enable react/destructuring-assignment */

        mainCoulumn.push(contactColumnInfo);
    }

    if (!isEmpty(personOccupationInfo) && !isChild) {
        let occupationsExpandableSection;

        if (!isEmpty(occupations)) {
            occupationsExpandableSection = map(occupations, (item) => (
                {
                    header: item.type,
                    rows: [
                        {
                            accessor: `placeOfEmployment-${item.id}`,
                            fieldName: 'Place of Employment',
                            iconType: 'briefcase',
                            iconColor: 'primary',
                            className: `${containerClasses}_contact_occupation`,
                        }, {
                            accessor: `occupationCategory-${item.id}`,
                            fieldName: 'Industry',
                        }, {
                            accessor: `occupationTitle-${item.id}`,
                            fieldName: 'Job Title',
                        }, {
                            accessor: `occupationType-${item.id}`,
                            fieldName: 'Type',
                        }, {
                            accessor: `isOccupationPrimary-${item.id}`,
                            fieldName: 'Primary',
                        }, {
                            accessor: `isStaff-${item.id}`,
                            fieldName: 'Staff',
                        },
                    ],
                }
            ));

            map(occupations, (item) => {
                const occupationExpandableData = {
                    [`placeOfEmployment-${item.id}`]: item && item.company ? item.company : 'N/A',
                    [`occupationCategory-${item.id}`]: item && item.category ? item.category : 'N/A',
                    [`occupationTitle-${item.id}`]: item && item.title ? item.title : 'N/A',
                    [`occupationType-${item.id}`]: item && item.type ? item.type : 'N/A',
                    [`isOccupationPrimary-${item.id}`]: item && item.isPrimary ? 'Yes' : 'No',
                    [`isStaff-${item.id}`]: item && item.isStaff ? 'Yes' : 'No',
                };
                mainData = {
                    ...mainData,
                    ...occupationExpandableData,
                };
            });
        }

        const occupationColumnInfo = {
            className: containerClassesOccupation,
            header: 'Occupation',
            isExpandable: true,
            rows: [
                {
                    accessor: 'placeOfEmployment',
                    fieldName: 'Place of Employment',
                }, {
                    accessor: 'occupationCategory',
                    fieldName: 'Industry',
                }, {
                    accessor: 'occupationTitle',
                    fieldName: 'Job Title',
                }, {
                    accessor: 'occupationType',
                    fieldName: 'Type',
                }, {
                    accessor: 'isOccupationPrimary',
                    fieldName: 'Primary',
                }, {
                    accessor: 'isStaff',
                    fieldName: 'Staff',
                },
            ],
            expandableSections: occupationsExpandableSection,
        };

        mainCoulumn.push(occupationColumnInfo);
    }

    if (!isEmpty(personEmergencyContactInfo)) {
        let emergencyContactsExpandableSection;

        if (!isEmpty(emergencyContacts)) {
            emergencyContactsExpandableSection = map(emergencyContacts, (item) => {
                let preferMethodExpandableSection;
                const dncList = PersonEmergencyContactUtils.getPersonDoNotContactMethods(item);
                const preferMethodTextExpandableSection =
                    PersonEmergencyContactUtils.getPersonPreferredContactMethod(item);
                preferMethodExpandableSection = item.emergencyContactPreference.preferredMethod !== 'none' ?
                    preferMethodTextExpandableSection.method : `(${i18n('NoPreference')})`;
                if (!isEmpty(dncList.doNotContactMethodList) && !dncList.doNotContact) {
                    preferMethodExpandableSection += ` (${i18n('DNCVia')} ${dncList.doNotContactMethodList.join(', ')})`;
                }

                return {
                    header: item.isPrimary ? 'Primary' : 'Additional',
                    rows: [
                        {
                            accessor: `emergencyContactName-${item.emergencyContactId}`,
                            fieldName: 'Name',
                            iconType: 'person',
                            iconColor: 'alert',
                            className: `${containerClasses}_emergency_contact`,
                        }, {
                            accessor: `emergencyContactRelation-${item.emergencyContactId}`,
                            fieldName: 'Relationship',
                        }, {
                            accessor: `emergencyContactPreferMethodText-${item.emergencyContactId}`,
                            fieldName: preferMethodExpandableSection,
                        },
                    ],
                };
            });

            map(emergencyContacts, (item) => {
                const preferMethodTextExpandableSection =
                    PersonEmergencyContactUtils.getPersonPreferredContactMethod(item);
                const emergencyContactExpandableData = {
                    [`emergencyContactName-${item.emergencyContactId}`]: `${item.emergencyContactPerson.firstName} ${item.emergencyContactPerson.lastName}`,
                    [`emergencyContactRelation-${item.emergencyContactId}`]: item && item.emergencyContactRelationshipName ? item.emergencyContactRelationshipName : 'N/A',
                    [`emergencyContactPreferMethodText-${item.emergencyContactId}`]: preferMethodTextExpandableSection.text,
                };
                mainData = {
                    ...mainData,
                    ...emergencyContactExpandableData,
                };
            });
        }

        const emergencyContactColumnInfo = {
            className: containerClassesEmergencyContact,
            header: 'Emergency Contact',
            isExpandable: true,
            rows: [
                {
                    accessor: 'emergencyContactName',
                    fieldName: 'Name',
                }, {
                    accessor: 'emergencyContactRelation',
                    fieldName: 'Relation',
                }, {
                    accessor: 'emergencyContactPreferMethodText',
                    fieldName: emergencyContactPreferMethod,
                },
            ],
            expandableSections: emergencyContactsExpandableSection,
        };

        mainCoulumn.push(emergencyContactColumnInfo);
    }

    return (
        <Page.DataGroups
            className={mainDataGroupContainerClasses}
            columns={mainCoulumn}
            data={mainData}
        />
    );
}

TabsContentPersonalPersonInfo.propTypes = propTypes;
TabsContentPersonalPersonInfo.defaultProps = defaultProps;

export default connect(mapStateToProps)(TabsContentPersonalPersonInfo);
