import _ from 'lodash';

export const DEFAULT_STATE = null;

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventRoomCheckInActions.ON_GETBYSO':
            return _.get(action.result, 'rooms', []);

        case 'EventRoomCheckInActions.ON_BEFORE_GETBYSO':
            return DEFAULT_STATE;

        case 'EventRoomCheckInActions.ON_FAILED_GETBYSO':
            return [];

        default:
            return state;
    }
}
