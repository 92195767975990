import { combineReducers } from 'redux';

import checkInSearch from './checkInSearch.reducer';
import checkInVolunteers from './checkInVolunteers.reducer';

const reducers = combineReducers({
    checkInSearch,
    checkInVolunteers,
});

export default reducers;
