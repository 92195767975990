import { Action } from '../../../global/models';
import ActionType from '../../actionTypes/communicationTemplates.actionTypes';

export const INITIAL_STATE = {
    isLoading: false,
    communicationTemplates: [],
    count: 0,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.RESET_COMMUNICATION_TEMPLATES:
            return INITIAL_STATE;
        case ActionType.SET_COMMUNICATION_TEMPLATES:
            return {
                ...state,
                communicationTemplates: [
                    ...state.communicationTemplates,
                    ...action.payload.communicationTemplates,
                ],
                count: action.payload.count,
            };
        case ActionType.SET_COMMUNICATION_TEMPLATES_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};
