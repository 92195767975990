import { Action } from '../../../global/models';
import ActionType from '../../actionTypes/communicationPreviewer.actionTypes';

export const INITIAL_STATE = {
    html: '',
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_COMMUNICATION_PREVIEWER:
            return action.payload;
        case ActionType.RESET_COMMUNICATION_PREVIEWER:
            return INITIAL_STATE;
        default:
            return state;
    }
};
