enum ActionType {
    ON_BEFORE_GETVOLUNTEERLIST = 'CheckInActions.ON_BEFORE_GETVOLUNTEERLIST',
    ON_FAILED_GETVOLUNTEERLIST = 'CheckInActions.ON_FAILED_GETVOLUNTEERLIST',
    ON_RESET_VOLUNTEERLIST = 'CheckInActions.ON_RESET_VOLUNTEERLIST',
    ON_GETVOLUNTEERLIST = 'CheckInActions.ON_GETVOLUNTEERLIST',

    ON_BEFORE_SEARCH = 'CheckInActions.ON_BEFORE_SEARCH',
    ON_RESET_SEARCH = 'CheckInActions.ON_RESET_SEARCH',
    ON_SEARCH = 'CheckInActions.ON_SEARCH',
}

export default ActionType;
