import {
    EVENT_CATEGORY_ID_WORSHIP_SERVICE,
    EVENTS_CENTRAL_SECTION_MY_EVENT,
    EVENTS_CENTRAL_SECTION_EVENT,
} from './constants.js';
import { i18n } from '../../global/constants.js';

const navigationItems = (event, isMyEvent) => {
    const eventSubsection = isMyEvent ?
        EVENTS_CENTRAL_SECTION_MY_EVENT :
        EVENTS_CENTRAL_SECTION_EVENT;

    const eventSection = isMyEvent ? 'my-dashboard' : 'events-central';

    const sectionItems = [
        {
            label: i18n('Overview'),
            to: `/${eventSection}/${eventSubsection}/${event.id}/overview`,
            accessRequires: { permission: 'bypass' },
        },
        {
            label: i18n('Event Details'),
            to: `/${eventSection}/${eventSubsection}/${event.id}/edit`,
            accessRequires: { permission: 'bypass' },
        },
        {
            label: i18n('Occurrence Schedule'),
            accessRequires: { permission: ['readEvent', 'manageEventAttendees'] },
            to: `/${eventSection}/${eventSubsection}/${event.id}/occurrence-schedule`,
            total: null,
        },
    ];

    if (event.eventCategoryId !== EVENT_CATEGORY_ID_WORSHIP_SERVICE) {
        sectionItems.push({
            label: i18n('Registration Roster'),
            accessRequires: { permission: ['readEvent', 'manageEventAttendees'] },
            to: `/${eventSection}/${eventSubsection}/${event.id}/registration-roster`,
            total: null,
        });
    }

    sectionItems.push({
        label: i18n('Serving Opps'),
        accessRequires: { permission: ['readEvent', 'manageMinistryInvitations'] },
        to: `/${eventSection}/${eventSubsection}/${event.id}/serving-opps`,
        total: null,
    });

    sectionItems.push({
        label: i18n('Communications'),
        accessRequires: { permission: ['readEvent'] },
        to: `/${eventSection}/${eventSubsection}/${event.id}/communications`,
        total: null,
    });

    return [
        {
            label: 'Manage',
            sectionItems,
        },
    ];
};

export default navigationItems;
