export const DEFAULT_STATE = null;

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventAttendeeActions.ON_GETBYID':
            return action.result;
    }

    return state;
}
