import request from 'axios';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import ApiUtils from '../../../global/apiUtils.js';
import { getPeopleSearchFormattedQuery } from './queryUtils/queryUtils.js';

class PeopleSearchDrawerActions extends ApiUtils {
    onToggleActiveLoadMore(isActiveLoadMore) {
        appReduxStore.dispatch({
            isActiveLoadMore,
            type: 'PeopleSearchDrawerActions.ON_TOGGLE_ACTIVE_LOAD_MORE',
        });
    }

    getPeople(options, callbackParams) {
        const query = getPeopleSearchFormattedQuery(options);

        return this.internal_getPeople({ query }, null, null, callbackParams);
    }

    getPeopleNextPage() {
        appReduxStore.dispatch({ type: 'PeopleSearchDrawerActions.ON_GET_PEOPLE_NEXT_PAGE' });
    }

    resetPeople() {
        appReduxStore.dispatch({ type: 'PeopleSearchDrawerActions.ON_RESET_PEOPLE' });
    }

    resetCoreMilestonesByPersonIds() {
        appReduxStore.dispatch({
            type: 'PeopleSearchDrawerActions.ON_RESET_CORE_MILESTONES_BY_PERSON_IDS',
        });
    }
}

const actions = new PeopleSearchDrawerActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'internal_getPeople',
        route: 'v2/person/search{query}',
        rq: request.get,
    },
    {
        name: 'getCoreMilestonesByPersonIds',
        route: 'insights/person/core-milestone?personIds={personIds}',
        rq: request.get,
    },
);

export default actions;
