import { combineReducers } from 'redux';

import filteredMembers from './filteredMembersReducer.js';
import filters from './filtersReducer.js';
import member from './volunteerDetails/memberReducers.js';
import membersList from './membersListReducer.js';

const reducers = combineReducers({
    filteredMembers,
    filters,
    member,
    membersList,
});

export default reducers;
