import {
    LOCAL_STORAGE_CMS_PREVIEW_KEY,
} from './global/constants';
import ActionType from './preferences.actionTypes';

type ConnectionFormPublicState = {
    isCmsPreviewEnabled: boolean;
};

const DEFAULT_STATE: ConnectionFormPublicState = {
    isCmsPreviewEnabled: JSON.parse(localStorage.getItem(LOCAL_STORAGE_CMS_PREVIEW_KEY)),
};

interface SetCmsPreview {
    type: ActionType.SET_CMS_PREVIEW;
}

type KnownActionType = SetCmsPreview;

export default (
    state: ConnectionFormPublicState | undefined,
    incomingAction: any,
): ConnectionFormPublicState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;
    let newIsCmsPreviewEnabled;

    switch (action.type) {
        case ActionType.SET_CMS_PREVIEW:
            newIsCmsPreviewEnabled = !state.isCmsPreviewEnabled;

            localStorage.setItem(
                LOCAL_STORAGE_CMS_PREVIEW_KEY,
                newIsCmsPreviewEnabled,
            );

            return {
                ...state,
                isCmsPreviewEnabled: newIsCmsPreviewEnabled,
            };

        default:
    }

    return state;
};
