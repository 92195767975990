// exported for unit testing purposes
export const DEFAULT_STATE = {
    campuses: {
        isFetching: false,
        results: [],
    },
    formTemplates: {
        isFetching: false,
        results: [],
    },
    exportPreview: {
        isFetching: false,
        numberOfWeeks: 0,
        previewDataRows: [],
        totalRowCount: 0,
    },
};

const connectionForms = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ConnectionFormsExportActions.ON_BEFORE_GETCAMPUSESWITHFORMS':
            return {
                ...state,
                campuses: {
                    ...state.campuses,
                    isFetching: true,
                },
            };

        case 'ConnectionFormsExportActions.ON_GETCAMPUSESWITHFORMS':
            return {
                ...state,
                campuses: {
                    ...state.campuses,
                    isFetching: false,
                    results: action.result,
                },
            };

        case 'ConnectionFormsExportActions.ON_BEFORE_GETFORMTEMPLATESBYCAMPUS':
            return {
                ...state,
                formTemplates: {
                    ...state.formTemplates,
                    isFetching: true,
                },
            };

        case 'ConnectionFormsExportActions.ON_GETFORMTEMPLATESBYCAMPUS':
            return {
                ...state,
                formTemplates: {
                    ...state.formTemplates,
                    isFetching: false,
                    results: action.result.forms,
                },
            };

        case 'ConnectionFormsExportActions.REQUEST_GETPREVIEW':
            return {
                ...state,
                exportPreview: {
                    ...state.exportPreview,
                    isFetching: true,
                },
            };

        case 'ConnectionFormsExportActions.ON_GETPREVIEW':
        {
            const {
                result: {
                    resultCount,
                    results,
                    weekCount,
                },
            } = action;

            return {
                ...state,
                exportPreview: {
                    ...state.exportPreview,
                    isFetching: false,
                    numberOfWeeks: weekCount,
                    previewDataRows: results,
                    totalRowCount: resultCount,
                },
            };
        }

        case 'ConnectionFormsExportActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default connectionForms;
