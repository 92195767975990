import {
    NAVIGATION_RESET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER,
    NAVIGATION_SET_BREADCRUMBS,
    NAVIGATION_SET_HEADER_BREADCRUMBS,
    NAVIGATION_SET_IS_COLLAPSED,
    NAVIGATION_SET_ITEMS,
    NAVIGATION_SET_MY_MINISTRY,
    NAVIGATION_SET_SECTIONAL_TABS,
    NAVIGATION_SET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER,
    NAVIGATION_TOGGLE_COLLAPSE,
} from './navigationTypes.js';
import { items } from './navigationUtils.jsx';
import { getMinistrySectionItems } from '../../ministry/navigationUtils.js';

export const resetNavigationSectionTabsBeforeChangeHandler = () => (dispatch) => {
    dispatch({ type: NAVIGATION_RESET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER });
};

export const setNavigationBreadcrumbs = (navigationBreadcrumbs) => (dispatch) => {
    dispatch({ type: NAVIGATION_SET_BREADCRUMBS, navigationBreadcrumbs });
};

export const setNavigationHeaderBreadcrumbs = (navigationHeaderBreadcrumbs) => (dispatch) => {
    dispatch({ type: NAVIGATION_SET_HEADER_BREADCRUMBS, navigationHeaderBreadcrumbs });
};

export const setIsNavigationCollapsed = (isCollapsed) => (dispatch) => {
    dispatch({ type: NAVIGATION_SET_IS_COLLAPSED, isCollapsed });
};

export const setItems = () => (dispatch) => {
    dispatch({ type: NAVIGATION_SET_ITEMS, items });
};

export const setMyMinistry = (
    ministryId: number,
    locationId: number,
) => (dispatch) => {
    const myMinistrySectionItems = (ministryId && locationId) ?
        getMinistrySectionItems(ministryId, locationId) :
        [];

    dispatch({
        type: NAVIGATION_SET_MY_MINISTRY,
        ministryId,
        locationId,
        myMinistrySectionItems,
    });
};

export const setNavigationSectionalTabs = (sectionalTabsItems) => (dispatch) => {
    dispatch({ type: NAVIGATION_SET_SECTIONAL_TABS, sectionalTabsItems });
};

export const setNavigationSectionTabsBeforeChangeHandler =
    (sectionalTabsOnBeforeChange) => (dispatch) => {
        dispatch({
            type: NAVIGATION_SET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER,
            sectionalTabsOnBeforeChange,
        });
    };

export const toggleNavigationCollapse = (isMobile) => (dispatch) => {
    // isMobile is used to know if we need to preserve the previous collapsed state for md viewport
    dispatch({ type: NAVIGATION_TOGGLE_COLLAPSE, isMobile });
};
