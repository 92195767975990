import ActionType from './digitalProgramButtonForm.types';
import ButtonFormState from './models/buttonFormState.model';
import Entities from './models/entities.model';

export const DEFAULT_STATE: ButtonFormState = {
    buttonLabel: null,
    campusId: null,
    description: null,
    entityIds: [],
    entityType: null,
    entities: [],
    iconColorInHex: null,
    iconName: null,
    imageUrl: null,
    title: '',
    url: null,
};

type Action = {
    type: ActionType;
    entities?: Entities;
    entityIds?: number[];
    field?: string;
    state?: ButtonFormState;
    value?: string | Selection;
};

export default (state = DEFAULT_STATE, action: Action) => {
    switch (action?.type) {
        case ActionType.SetField:
            return {
                ...state,
                [action.field]: action.value,
            };

        case ActionType.SetEntities:
            return {
                ...state,
                entities: action.entities,
                entityIds: action.entityIds,
            };

        case ActionType.ResetForm:
            return { ...DEFAULT_STATE };

        case ActionType.SetButtonFormState:
            return {
                ...state,
                ...action.state,
            };

        default:
            return state;
    }
};
