// Generated from ../tools/ANTLR/DSL-Parser/Dsl.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class DslLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly BOOL_OR = 5;
	public static readonly BOOL_AND = 6;
	public static readonly BOOL_NOT = 7;
	public static readonly STAR = 8;
	public static readonly STRING = 9;
	public static readonly NESTED_ID = 10;
	public static readonly OPEN_RANGE = 11;
	public static readonly CLOSE_RANGE = 12;
	public static readonly DATE = 13;
	public static readonly NUMBER = 14;
	public static readonly TERM_WITH_OPTIONS = 15;
	public static readonly TERM = 16;
	public static readonly WS = 17;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"T__0", "T__1", "T__2", "T__3", "BOOL_OR", "BOOL_AND", "BOOL_NOT", "STAR", 
		"STRING", "NESTED_ID", "OPEN_RANGE", "CLOSE_RANGE", "ID", "SUB_ID", "TERM_START_CHAR", 
		"TERM_CHAR", "ESC", "UNSIGNED", "DATE", "NUMBER", "TERM_WITH_OPTIONS", 
		"TERM", "WS",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'('", "')'", "':'", "'TO'", undefined, undefined, undefined, 
		"'*'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, "BOOL_OR", "BOOL_AND", 
		"BOOL_NOT", "STAR", "STRING", "NESTED_ID", "OPEN_RANGE", "CLOSE_RANGE", 
		"DATE", "NUMBER", "TERM_WITH_OPTIONS", "TERM", "WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(DslLexer._LITERAL_NAMES, DslLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return DslLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(DslLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "Dsl.g4"; }

	// @Override
	public get ruleNames(): string[] { return DslLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return DslLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return DslLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return DslLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\x13\xE5\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x03\x02\x03\x02\x03\x03\x03\x03\x03\x04\x03\x04\x03" +
		"\x05\x03\x05\x03\x05\x03\x06\x03\x06\x03\x06\x03\x06\x05\x06?\n\x06\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x05\x07F\n\x07\x03\b\x03\b\x03\b" +
		"\x03\b\x05\bL\n\b\x03\t\x03\t\x03\n\x03\n\x03\n\x07\nS\n\n\f\n\x0E\nV" +
		"\v\n\x03\n\x03\n\x03\n\x05\n[\n\n\x03\v\x03\v\x03\v\x07\v`\n\v\f\v\x0E" +
		"\vc\v\v\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x07\x0Ek\n\x0E\f\x0E\x0E" +
		"\x0En\v\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x07\x0Ft\n\x0F\f\x0F\x0E\x0F" +
		"w\v\x0F\x03\x0F\x03\x0F\x05\x0F{\n\x0F\x03\x10\x03\x10\x05\x10\x7F\n\x10" +
		"\x03\x11\x03\x11\x05\x11\x83\n\x11\x03\x12\x03\x12\x03\x12\x03\x13\x06" +
		"\x13\x89\n\x13\r\x13\x0E\x13\x8A\x03\x13\x03\x13\x06\x13\x8F\n\x13\r\x13" +
		"\x0E\x13\x90\x05\x13\x93\n\x13\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14" +
		"\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x15\x05\x15\xA1\n" +
		"\x15\x03\x15\x03\x15\x03\x16\x05\x16\xA6\n\x16\x03\x16\x03\x16\x03\x17" +
		"\x03\x17\x07\x17\xAC\n\x17\f\x17\x0E\x17\xAF\v\x17\x03\x17\x03\x17\x05" +
		"\x17\xB3\n\x17\x05\x17\xB5\n\x17\x03\x17\x03\x17\x05\x17\xB9\n\x17\x03" +
		"\x17\x03\x17\x05\x17\xBD\n\x17\x03\x17\x03\x17\x05\x17\xC1\n\x17\x05\x17" +
		"\xC3\n\x17\x03\x17\x03\x17\x05\x17\xC7\n\x17\x03\x17\x03\x17\x03\x17\x05" +
		"\x17\xCC\n\x17\x05\x17\xCE\n\x17\x03\x17\x03\x17\x05\x17\xD2\n\x17\x03" +
		"\x17\x03\x17\x03\x17\x05\x17\xD7\n\x17\x05\x17\xD9\n\x17\x03\x17\x03\x17" +
		"\x05\x17\xDD\n\x17\x05\x17\xDF\n\x17\x03\x18\x06\x18\xE2\n\x18\r\x18\x0E" +
		"\x18\xE3\x02\x02\x02\x19\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06" +
		"\v\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19" +
		"\x02\x0E\x1B\x02\x02\x1D\x02\x02\x1F\x02\x02!\x02\x02#\x02\x02%\x02\x02" +
		"\'\x02\x0F)\x02\x10+\x02\x11-\x02\x12/\x02\x13\x03\x02\x13\x04\x02QQq" +
		"q\x04\x02TTtt\x04\x02CCcc\x04\x02PPpp\x04\x02FFff\x04\x02VVvv\x04\x02" +
		"$$^^\x04\x02]]}}\x04\x02__\x7F\x7F\x04\x02C\\c|\x07\x02//2;C\\aac|\x05" +
		"\x022;C\\c|\x0F\x02\v\f\x0F\x0F\"$*+--//11<<AA]`}}\x7F\x80\u3002\u3002" +
		"\x04\x02--//\x06\x02\v\f\x0F\x0F\"\"\u3002\u3002\x03\x022;\x03\x0224\x02" +
		"\u0101\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02\x02\x07\x03\x02" +
		"\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02\x02" +
		"\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02\x02" +
		"\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03\x02\x02" +
		"\x02\x02\'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02" +
		"-\x03\x02\x02\x02\x02/\x03\x02\x02\x02\x031\x03\x02\x02\x02\x053\x03\x02" +
		"\x02\x02\x075\x03\x02\x02\x02\t7\x03\x02\x02\x02\v>\x03\x02\x02\x02\r" +
		"E\x03\x02\x02\x02\x0FK\x03\x02\x02\x02\x11M\x03\x02\x02\x02\x13O\x03\x02" +
		"\x02\x02\x15\\\x03\x02\x02\x02\x17d\x03\x02\x02\x02\x19f\x03\x02\x02\x02" +
		"\x1Bh\x03\x02\x02\x02\x1Dz\x03\x02\x02\x02\x1F~\x03\x02\x02\x02!\x82\x03" +
		"\x02\x02\x02#\x84\x03\x02\x02\x02%\x88\x03\x02\x02\x02\'\x94\x03\x02\x02" +
		"\x02)\xA0\x03\x02\x02\x02+\xA5\x03\x02\x02\x02-\xDE\x03\x02\x02\x02/\xE1" +
		"\x03\x02\x02\x0212\x07*\x02\x022\x04\x03\x02\x02\x0234\x07+\x02\x024\x06" +
		"\x03\x02\x02\x0256\x07<\x02\x026\b\x03\x02\x02\x0278\x07V\x02\x0289\x07" +
		"Q\x02\x029\n\x03\x02\x02\x02:;\t\x02\x02\x02;?\t\x03\x02\x02<=\x07~\x02" +
		"\x02=?\x07~\x02\x02>:\x03\x02\x02\x02><\x03\x02\x02\x02?\f\x03\x02\x02" +
		"\x02@A\t\x04\x02\x02AB\t\x05\x02\x02BF\t\x06\x02\x02CD\x07(\x02\x02DF" +
		"\x07(\x02\x02E@\x03\x02\x02\x02EC\x03\x02\x02\x02F\x0E\x03\x02\x02\x02" +
		"GH\t\x05\x02\x02HI\t\x02\x02\x02IL\t\x07\x02\x02JL\x07#\x02\x02KG\x03" +
		"\x02\x02\x02KJ\x03\x02\x02\x02L\x10\x03\x02\x02\x02MN\x07,\x02\x02N\x12" +
		"\x03\x02\x02\x02OT\x07$\x02\x02PS\x05#\x12\x02QS\n\b\x02\x02RP\x03\x02" +
		"\x02\x02RQ\x03\x02\x02\x02SV\x03\x02\x02\x02TR\x03\x02\x02\x02TU\x03\x02" +
		"\x02\x02UW\x03\x02\x02\x02VT\x03\x02\x02\x02WZ\x07$\x02\x02XY\x07`\x02" +
		"\x02Y[\x05%\x13\x02ZX\x03\x02\x02\x02Z[\x03\x02\x02\x02[\x14\x03\x02\x02" +
		"\x02\\a\x05\x1B\x0E\x02]^\x070\x02\x02^`\x05\x1D\x0F\x02_]\x03\x02\x02" +
		"\x02`c\x03\x02\x02\x02a_\x03\x02\x02\x02ab\x03\x02\x02\x02b\x16\x03\x02" +
		"\x02\x02ca\x03\x02\x02\x02de\t\t\x02\x02e\x18\x03\x02\x02\x02fg\t\n\x02" +
		"\x02g\x1A\x03\x02\x02\x02hl\t\v\x02\x02ik\t\f\x02\x02ji\x03\x02\x02\x02" +
		"kn\x03\x02\x02\x02lj\x03\x02\x02\x02lm\x03\x02\x02\x02mo\x03\x02\x02\x02" +
		"nl\x03\x02\x02\x02op\t\r\x02\x02p\x1C\x03\x02\x02\x02qu\t\v\x02\x02rt" +
		"\t\f\x02\x02sr\x03\x02\x02\x02tw\x03\x02\x02\x02us\x03\x02\x02\x02uv\x03" +
		"\x02\x02\x02vx\x03\x02\x02\x02wu\x03\x02\x02\x02x{\t\r\x02\x02y{\x07," +
		"\x02\x02zq\x03\x02\x02\x02zy\x03\x02\x02\x02{\x1E\x03\x02\x02\x02|\x7F" +
		"\n\x0E\x02\x02}\x7F\x05#\x12\x02~|\x03\x02\x02\x02~}\x03\x02\x02\x02\x7F" +
		" \x03\x02\x02\x02\x80\x83\x05\x1F\x10\x02\x81\x83\t\x0F\x02\x02\x82\x80" +
		"\x03\x02\x02\x02\x82\x81\x03\x02\x02\x02\x83\"\x03\x02\x02\x02\x84\x85" +
		"\x07^\x02\x02\x85\x86\n\x10\x02\x02\x86$\x03\x02\x02\x02\x87\x89\t\x11" +
		"\x02\x02\x88\x87\x03\x02\x02\x02\x89\x8A\x03\x02\x02\x02\x8A\x88\x03\x02" +
		"\x02\x02\x8A\x8B\x03\x02\x02\x02\x8B\x92\x03\x02\x02\x02\x8C\x8E\x070" +
		"\x02\x02\x8D\x8F\t\x11\x02\x02\x8E\x8D\x03\x02\x02\x02\x8F\x90\x03\x02" +
		"\x02\x02\x90\x8E\x03\x02\x02\x02\x90\x91\x03\x02\x02\x02\x91\x93\x03\x02" +
		"\x02\x02\x92\x8C\x03\x02\x02\x02\x92\x93\x03\x02\x02\x02\x93&\x03\x02" +
		"\x02\x02\x94\x95\t\x11\x02\x02\x95\x96\t\x11\x02\x02\x96\x97\t\x11\x02" +
		"\x02\x97\x98\t\x11\x02\x02\x98\x99\x07/\x02\x02\x99\x9A\t\x11\x02\x02" +
		"\x9A\x9B\t\x11\x02\x02\x9B\x9C\x07/\x02\x02\x9C\x9D\t\x11\x02\x02\x9D" +
		"\x9E\t\x11\x02\x02\x9E(\x03\x02\x02\x02\x9F\xA1\t\x0F\x02\x02\xA0\x9F" +
		"\x03\x02\x02\x02\xA0\xA1\x03\x02\x02\x02\xA1\xA2\x03\x02\x02\x02\xA2\xA3" +
		"\x05%\x13\x02\xA3*\x03\x02\x02\x02\xA4\xA6\t\x0F\x02\x02\xA5\xA4\x03\x02" +
		"\x02\x02\xA5\xA6\x03\x02\x02\x02\xA6\xA7\x03\x02\x02\x02\xA7\xA8\x05-" +
		"\x17\x02\xA8,\x03\x02\x02\x02\xA9\xAD\x05\x1F\x10\x02\xAA\xAC\x05!\x11" +
		"\x02\xAB\xAA\x03\x02\x02\x02\xAC\xAF\x03\x02\x02\x02\xAD\xAB\x03\x02\x02" +
		"\x02\xAD\xAE\x03\x02\x02\x02\xAE\xB4\x03\x02\x02\x02\xAF\xAD\x03\x02\x02" +
		"\x02\xB0\xB2\x07\x80\x02\x02\xB1\xB3\t\x12\x02\x02\xB2\xB1\x03\x02\x02" +
		"\x02\xB2\xB3\x03\x02\x02\x02\xB3\xB5\x03\x02\x02\x02\xB4\xB0\x03\x02\x02" +
		"\x02\xB4\xB5\x03\x02\x02\x02\xB5\xB8\x03\x02\x02\x02\xB6\xB7\x07`\x02" +
		"\x02\xB7\xB9\x05%\x13\x02\xB8\xB6\x03\x02\x02\x02\xB8\xB9\x03\x02\x02" +
		"\x02\xB9\xDF\x03\x02\x02\x02\xBA\xBC\x05)\x15\x02\xBB\xBD\x07,\x02\x02" +
		"\xBC\xBB\x03\x02\x02\x02\xBC\xBD\x03\x02\x02\x02\xBD\xC2\x03\x02\x02\x02" +
		"\xBE\xC0\x07\x80\x02\x02\xBF\xC1\t\x12\x02\x02\xC0\xBF\x03\x02\x02\x02" +
		"\xC0\xC1\x03\x02\x02\x02\xC1\xC3\x03\x02\x02\x02\xC2\xBE\x03\x02\x02\x02" +
		"\xC2\xC3\x03\x02\x02\x02\xC3\xC6\x03\x02\x02\x02\xC4\xC5\x07`\x02\x02" +
		"\xC5\xC7\x05%\x13\x02\xC6\xC4\x03\x02\x02\x02\xC6\xC7\x03\x02\x02\x02" +
		"\xC7\xDF\x03\x02\x02\x02\xC8\xCD\x05\x15\v\x02\xC9\xCB\x07\x80\x02\x02" +
		"\xCA\xCC\t\x12\x02\x02\xCB\xCA\x03\x02\x02\x02\xCB\xCC\x03\x02\x02\x02" +
		"\xCC\xCE\x03\x02\x02\x02\xCD\xC9\x03\x02\x02\x02\xCD\xCE\x03\x02\x02\x02" +
		"\xCE\xD1\x03\x02\x02\x02\xCF\xD0\x07`\x02\x02\xD0\xD2\x05%\x13\x02\xD1" +
		"\xCF\x03\x02\x02\x02\xD1\xD2\x03\x02\x02\x02\xD2\xDF\x03\x02\x02\x02\xD3" +
		"\xD8\x05\'\x14\x02\xD4\xD6\x07\x80\x02\x02\xD5\xD7\t\x12\x02\x02\xD6\xD5" +
		"\x03\x02\x02\x02\xD6\xD7\x03\x02\x02\x02\xD7\xD9\x03\x02\x02\x02\xD8\xD4" +
		"\x03\x02\x02\x02\xD8\xD9\x03\x02\x02\x02\xD9\xDC\x03\x02\x02\x02\xDA\xDB" +
		"\x07`\x02\x02\xDB\xDD\x05%\x13\x02\xDC\xDA\x03\x02\x02\x02\xDC\xDD\x03" +
		"\x02\x02\x02\xDD\xDF\x03\x02\x02\x02\xDE\xA9\x03\x02\x02\x02\xDE\xBA\x03" +
		"\x02\x02\x02\xDE\xC8\x03\x02\x02\x02\xDE\xD3\x03\x02\x02\x02\xDF.\x03" +
		"\x02\x02\x02\xE0\xE2\t\x10\x02\x02\xE1\xE0\x03\x02\x02\x02\xE2\xE3\x03" +
		"\x02\x02\x02\xE3\xE1\x03\x02\x02\x02\xE3\xE4\x03\x02\x02\x02\xE40\x03" +
		"\x02\x02\x02$\x02>EKRTZaluz~\x82\x8A\x90\x92\xA0\xA5\xAD\xB2\xB4\xB8\xBC" +
		"\xC0\xC2\xC6\xCB\xCD\xD1\xD6\xD8\xDC\xDE\xE3\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!DslLexer.__ATN) {
			DslLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(DslLexer._serializedATN));
		}

		return DslLexer.__ATN;
	}

}

