import {
    isEmpty,
} from 'lodash';

export const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'MinistryServingOpportunityActions.ON_UPDATE':
        case 'MinistryServingOpportunityActions.ON_CLOSE':
            return {
                ...action.result.servingOpportunity,
            };

        case 'MinistryServingOpportunityActions.ON_GET':
            return {
                ...(isEmpty(action.result) ? DEFAULT_STATE : action.result),
            };

        case 'MinistryServingOpportunityActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
