import { selectBoxTransform } from '../../../global/utils';

export const DEFAULT_STATE = {
    accommodations: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'AccommodationActions.ON_GETALL':
            return { ...state, accommodations: selectBoxTransform(action, 'id', 'name') };

        default:
            return state;
    }
};
