import { combineReducers } from 'redux';

import entries from './entries/entries.reducers.js';
import publicForm from './public/publicConnectionForm.reducers';
import templates from './templates/templates.reducers.js';

const reducers = combineReducers({
    entries,
    publicForm,
    templates,
});

export default reducers;
