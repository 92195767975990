import _ from 'lodash';
import ChunkedBidirectionalPaginationUtils from '../../../../global/chunkedBidirectionalPaginationUtils';

const localCache = new ChunkedBidirectionalPaginationUtils(50, 50);

export const DEFAULT_STATE = {
    results: [],
    canLoadMoreBackward: false,
    canLoadMoreForward: false,
    pageNumberBackward: 0,
    pageNumberForward: 0,
    pageSize: localCache.getPageSize(),
};

export default function (state = DEFAULT_STATE, action) {
    let first;
    let forward;

    switch (action.type) {
        case 'EventAttendeeActions.ON_GETFILTEREDATTENDANCE':
            first = _.get(action, 'callbackParams.first', true);
            forward = _.get(action, 'callbackParams.forward', true);

            localCache.loadPage(
                action.result,
                forward,
                first,
            );

        // eslint-disable-next-line no-fallthrough
        case 'EventAttendeeActions.ON_GET_ATTENDANCENEXPAGE': {
            return {
                results: localCache.getAll(forward, true),
                canLoadMoreBackward: localCache.canLoadMoreBackward(),
                canLoadMoreForward: localCache.canLoadMoreForward(),
                pageNumberBackward: -localCache.getCurrentChunkBackward(), // the backward page is a negative chunk number
                pageNumberForward: localCache.getCurrentChunkForward(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'EventAttendeeActions.ON_CLEARATTENDANCE': {
            localCache.reset();

            return DEFAULT_STATE;
        }

        default:
            return state;
    }
}
