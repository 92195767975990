import _ from 'lodash';
import moment from 'moment-timezone';

export const ALL_TOPICS = 'All Topics';

function mapSubCategories(auditLog) {
    const subCategories = [];

    const group = auditLog.details[0].category !== 'Milestones' ?
        auditLog.categories.length === 1 ?
            _.concat(auditLog.details[0].additions, auditLog.details[0].changes, auditLog.details[0].removals) :
            null :
        auditLog.details[0].milestones;

    _.each(group, (mod) => {
        if (!_.isEmpty(mod)) {
            const subCategory = `${mod.fieldName ? mod.fieldName : mod.type ? mod.type : mod.name ? mod.name : mod.company ? mod.company : mod.category ? mod.category : ''}`;
            subCategories.push(subCategory);
        }
    });

    return { ...auditLog, subCategories };
}

export function appendSavedNote(
    auditLogCollection,
    auditLogId,
    savedNoteText,
    noteCreatorName,
) {
    // This is intended to produce mutated audit log collection with the new
    // note prepended to the appropriate entry.

    const updatedAuditLogCollection = _.map(auditLogCollection, (auditLogEntry) => {
        if (auditLogEntry.id === auditLogId) {
            const updatedEntry = _.cloneDeep(auditLogEntry);
            updatedEntry.notes = updatedEntry.notes || [];

            const newNoteObject = {
                createDate: moment().unix(),
                createdBy: noteCreatorName,
                note: savedNoteText,
            };

            updatedEntry.notes.unshift(newNoteObject);
            return updatedEntry;
        }

        return auditLogEntry;
    });

    return updatedAuditLogCollection;
}

export function computeCategoryStatistics(auditLogEntries) {
    const shortListCategories = [{ entityType: ALL_TOPICS, count: 0, subCategories: [] }];
    const shortListSubCategories = [];

    _.each(auditLogEntries, (entry) => {
        const categoryType = entry.categories[0]; // TODO/FIXME: What if we have _multiple_ categories...???
        const index = _.findIndex(shortListCategories, { entityType: categoryType });

        if (index > 0) {
            shortListCategories[index].count++;
        } else {
            shortListCategories.push({ entityType: categoryType, count: 1 });
        }

        if (categoryType !== 'Households' && categoryType !== 'Occupation' && categoryType !== 'Membership') {
            _.each(entry.subCategories, (subCategory) => {
                const index = _.findIndex(shortListSubCategories, { entityType: categoryType, subCategory });

                if (index >= 0) {
                    shortListSubCategories[index].count++;
                } else {
                    shortListSubCategories.push({ entityType: categoryType, count: 1, subCategory });
                }
            });
        }

        shortListCategories[0].count++; // Increase the "All Topics" count
    });

    return {
        categories: shortListCategories,
        subCategories: shortListSubCategories,
    };
}

export function filterAuditLogsByCategory(unfilteredAuditLogs, filters) {
    const { category, subCategory } = filters;
    let filteredLogs;

    if (category !== ALL_TOPICS) {
        filteredLogs = _.filter(unfilteredAuditLogs, (a) => (a.categories[0] === category) &&
                (subCategory === 'ALL' || _.includes(a.subCategories, subCategory)));
    } else {
        filteredLogs = unfilteredAuditLogs;
    }

    return filteredLogs;
}

export function filterAuditLogsByDate(unfilteredAuditLogs, filters) {
    const { startDate, endDate } = filters;
    const hasStartDate = !_.isNil(startDate);
    const hasEndDate = !_.isNil(endDate);

    if (!hasStartDate && !hasEndDate) {
        return unfilteredAuditLogs;
    }
    const filterRangeStartUtcUnix = moment(startDate).utc().unix();
    const filterRangeEndUtcUnix = hasEndDate ?
        moment(endDate).endOf('day').utc().unix() :
        moment(startDate).endOf('day').utc().unix();

    return _.filter(
        unfilteredAuditLogs,
        (a) => a.createDate >= filterRangeStartUtcUnix && a.createDate <= filterRangeEndUtcUnix,
    );
}

export function transformAuditLogs(auditLogEntries) {
    const transformedAuditLogs = _.map(auditLogEntries, (entry) => mapSubCategories(entry));
    return transformedAuditLogs;
}
