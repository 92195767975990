import ClassNames from 'classnames';
import {
    debounce,
    get,
    kebabCase,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Collapse,
    Typography,
    Breadcrumbs,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import { makeStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import { withRouter } from 'react-router';
import { isMobile as isMobileDevice } from 'react-device-detect';
import NavigationLevelTwo from './navigationLevelTwo.jsx';
import {
    getLevelTwoBreadcrumbs,
    parseTabsToSectionalTabsItems,
    svg,
} from './navigationUtils.jsx';
import {
    setNavigationBreadcrumbs,
    setNavigationHeaderBreadcrumbs,
    setNavigationSectionalTabs,
} from './navigation.actions';
import {
    setAppHeaderTitle,
} from '../appHeader/appHeader.actions.js';
import Features from '../../global/features.js';
import UserPermission, {
    hasUserPermission,
} from '../../global/userPermission.js';
import UserAccessStore from '../../global/userAccessStore.js';
import UserIdentityStore from '../../global/userIdentityStore';

const DEBOUNCE_TIME_MOUSE_LEAVE_MAIN_ITEM = 333.3333333333;

const propTypes = {
    activeClickedItem: PropTypes.string,
    breadcrumbsTitle: PropTypes.string,
    getPathnameItem: PropTypes.func.isRequired,
    getPermissions: PropTypes.func.isRequired,
    isNavigationCollapsed: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    itemLabelHover: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    mobileBreadcrumbsVisibleLevel: PropTypes.number.isRequired,
    mouseEnterDelay: PropTypes.func.isRequired,
    navigationBreadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    isBeta: PropTypes.bool,
                    label: PropTypes.string,
                    accessRequires: PropTypes.shape({}),
                    to: PropTypes.string,
                }),
            ),
            parentItem: PropTypes.shape({
                segment: PropTypes.string,
            }),
        }),
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    onMainItemEvent: PropTypes.func.isRequired,
    previousPathname: PropTypes.string.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }),
    }).isRequired,
    setActiveClickedItem: PropTypes.func.isRequired,
    setAppHeaderTitle: PropTypes.func.isRequired,
    setIsNavigationCollapsed: PropTypes.func.isRequired,
    setNavigationBreadcrumbs: PropTypes.func.isRequired,
    setNavigationHeaderBreadcrumbs: PropTypes.func.isRequired,
    setNavigationSectionalTabs: PropTypes.func.isRequired,
    setMainItemLabelHover: PropTypes.func.isRequired,
    shouldRenderMobileBreadcrumbs: PropTypes.bool.isRequired,
    turnMobileBreadcrumbsVisibleLevel: PropTypes.func.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
    userIdentity: PropTypes.instanceOf(UserIdentityStore).isRequired,
};

const defaultProps = {
    activeClickedItem: undefined,
    breadcrumbsTitle: '',
    itemLabelHover: undefined,
};

const mapStateToProps = (state) => {
    const {
        appHeader: {
            appHeaderTitle,
        },
        bootstrap: {
            securityContext: {
                userAccess,
                userIdentity,
            },
        },
        navigation: {
            sectionalTabsItems,
            isNavigationCollapsed,
        },
    } = state;

    return {
        appHeaderTitle,
        isNavigationCollapsed,
        sectionalTabsItems,
        userAccess,
        userIdentity,
    };
};

const mapDispatchToProps = {
    setAppHeaderTitle,
    setNavigationBreadcrumbs,
    setNavigationHeaderBreadcrumbs,
    setNavigationSectionalTabs,
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .navigation_breadcrumbs--list': {
            padding: '0px 5px !important',
        },
        '& .navigation_breadcrumbs--breadcrumb_title_typography': {
            color: `${get(theme, 'palette.cyan[500]')} !important`,
            fontSize: '12px !important',
            fontWeight: `${get(theme, 'typography.fontWeightMedium')} !important`,
        },
        '& .navigation_breadcrumbs--breadcrumb-divider_first': {
            display: 'none',
        },
    },
    breadcrumbsTitle: {
        backgroundColor: get(theme, 'palette.common.black'),
        color: get(theme, 'palette.text.secondary'),
        fontWeight: get(theme, 'typography.fontWeightBold'),
        padding: '10px 11px 10px 22px',
        whiteSpace: 'nowrap',
    },
    navigationBreadcrumbsContainer: {
        padding: '10px 10px 10px 20px',
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'baseline',
    },
}));

export function NavigationLevelOne(props) {
    const {
        activeClickedItem,
        breadcrumbsTitle,
        getPathnameItem,
        getPermissions: getPermissionsParent,
        isMobile,
        isNavigationCollapsed,
        itemLabelHover,
        items,
        mobileBreadcrumbsVisibleLevel,
        mouseEnterDelay,
        navigationBreadcrumbs,
        onClick: onClickParent,
        onMainItemEvent: onMainItemEventParent,
        previousPathname,
        router,
        setActiveClickedItem,
        setAppHeaderTitle: setAppHeaderTitleAction,
        setNavigationBreadcrumbs: setNavigationBreadcrumbsAction,
        setNavigationHeaderBreadcrumbs: setNavigationHeaderBreadcrumbsAction,
        setNavigationSectionalTabs: setNavigationSectionalTabsAction,
        setIsNavigationCollapsed,
        setMainItemLabelHover,
        shouldRenderMobileBreadcrumbs,
        turnMobileBreadcrumbsVisibleLevel,
        userAccess,
        userIdentity,
    } = props;

    const classes = useStyles(props);
    const [browserHeight, setBrowserHeight] = useState(window?.innerHeight);
    const pathname = router?.location?.pathname ?? '';

    const onMainItemEvent = () => {
        onMainItemEventParent();
    };

    const mouseLeaveMainItemDebounce = debounce(
        () => onMainItemEvent(), DEBOUNCE_TIME_MOUSE_LEAVE_MAIN_ITEM,
    );

    let mouseLeaveDelayStarted = false;
    const itemsTopPositions = useRef({});

    useEffect(() => {
        const handleResize = () => {
            setBrowserHeight(window?.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getPermissions = (item) => (
        getPermissionsParent(item)
    );

    const onClick = ({
        item,
        itemLabel,
        wasLevelTwoItemClick,
        segment,
    } = {
        item: {},
        itemLabel: '',
        wasLevelTwoItemClick: false,
        segment: '',
    }) => {
        const itemsWithPermission = item.secondLevelItems?.filter((levelItem) => (
            hasUserPermission({
                anyPermissions: true,
                hasAnyMinistries: levelItem.label === 'My Ministry',
                permission: levelItem.accessRequires.permission,
                userAccess,
                userIdentity,
            })
        ));

        const newItem = !wasLevelTwoItemClick ? itemsWithPermission[0] : item;
        const routeTo = newItem.to ?? newItem.tabs[0].to;

        const hasPermission = hasUserPermission({
            anyPermissions: true,
            hasAnyMinistries: newItem.label === 'My Ministry',
            permission: newItem.accessRequires.permission,
            userAccess,
            userIdentity,
        });

        if (routeTo && hasPermission) {
            if (isMobile) {
                setIsNavigationCollapsed(true);
            }

            router.push(routeTo);

            onClickParent(newItem, wasLevelTwoItemClick);

            /**
             * Update App Bar's Heading and Breadcrumbs.
             */
            const appHeadingTitle = newItem.label ?? newItem.tabs[0].label;

            setAppHeaderTitleAction(appHeadingTitle);

            const sectionalTabs = parseTabsToSectionalTabsItems(newItem.tabs, router.push);

            setNavigationSectionalTabsAction(sectionalTabs);
            setNavigationHeaderBreadcrumbsAction([]);

            if (sectionalTabs.length > 0) {
                const onFirstTabClick = get(sectionalTabs, '[0].onClick', () => {});
                const levelTwoBreadcrumbs = getLevelTwoBreadcrumbs(items, newItem);

                onFirstTabClick();
                setNavigationBreadcrumbsAction(levelTwoBreadcrumbs, 'levelTwoOnClick');
            } else if (!shouldRenderMobileBreadcrumbs) {
                /**
                 * A mobile navigation breadcrumb item will never have tabs.
                 */
                setNavigationBreadcrumbsAction([]);
            }
        }

        if (itemLabel) {
            const updatedItemLabelHover = (!itemLabelHover || itemLabelHover !== itemLabel) ?
                itemLabel :
                null;

            onMainItemEventParent(updatedItemLabelHover);
        }

        if (segment) {
            setActiveClickedItem(segment);
        }
    };

    const onMouseEnter = (itemLabel) => {
        const shouldTriggerEvent = !isMobile && isNavigationCollapsed && !isMobileDevice &&
            mouseLeaveDelayStarted && itemLabelHover && itemLabelHover === itemLabel;

        if (shouldTriggerEvent) {
            mouseLeaveDelayStarted = false;
            mouseLeaveMainItemDebounce.cancel();
        }
    };

    const onMouseLeave = (itemLabel) => {
        if (!isMobile && isNavigationCollapsed && !isMobileDevice) {
            if (itemLabelHover !== itemLabel) {
                mouseEnterDelay(null, true);
            }

            if (itemLabelHover) {
                mouseLeaveDelayStarted = true;
                mouseLeaveMainItemDebounce();
            }
        }
    };

    const onMouseMove = (itemLabel) => {
        const shouldTriggerEvent = !isMobile && isNavigationCollapsed && !isMobileDevice &&
            (!itemLabelHover || itemLabelHover !== itemLabel);

        if (shouldTriggerEvent) {
            if (mouseLeaveDelayStarted && itemLabelHover) {
                mouseLeaveDelayStarted = false;
                mouseLeaveMainItemDebounce.cancel();
            }
            mouseEnterDelay(itemLabel);
        }
    };

    const renderItems = () => (
        items.map((item) => {
            const hoverLabel = item.isBeta ? `${item.label} (beta)` : item.label;
            const isActive = activeClickedItem === item.segment;
            const isHover = itemLabelHover === hoverLabel;

            const mainItemContainerClasses = ClassNames(
                'navigation-main-item',
                {
                    'is-active': isActive,
                    'is-hover': isHover,
                    'vertically-expanded': shouldRenderMobileBreadcrumbs,
                },
            );

            const isMinistry = item.label === 'My Ministry';
            const accessRequires = item.accessRequires || {};

            const {
                height: itemHeight,
                top: itemTop,
            } = itemsTopPositions.current[item.segment] || {};

            const isItemBottomHidden = (itemTop + itemHeight) > browserHeight;
            // used for the desktop fly out menu
            const subItemsinlineStyles = ((!isMobile && isNavigationCollapsed)) ?
                {
                    overflow: 'hidden',
                    ...(isItemBottomHidden ? { // renders the level two items from bottom to top
                        bottom: 0,
                        top: Math.abs(browserHeight - itemHeight),
                    } : {
                        top: itemTop,
                    }),
                } :
                {};

            let navigationLevelTwoItems = (
                <NavigationLevelTwo
                    getPathnameItem={getPathnameItem}
                    getPermissions={getPermissions}
                    isMobile={isMobile}
                    isNavigationCollapsed={isNavigationCollapsed}
                    item={item}
                    itemLabelHover={itemLabelHover}
                    mobileBreadcrumbsVisibleLevel={mobileBreadcrumbsVisibleLevel}
                    onClick={onClick}
                    previousPathname={previousPathname}
                    setIsNavigationCollapsed={setIsNavigationCollapsed}
                    segment={item.segment}
                    setActiveClickedItem={setActiveClickedItem}
                    setMainItemLabelHover={setMainItemLabelHover}
                    shouldRenderMobileBreadcrumbs={shouldRenderMobileBreadcrumbs}
                    style={subItemsinlineStyles}
                    getDimensions={(levelOneItemSegment, height) => {
                        const currentItemDimensions = itemsTopPositions.current[
                            levelOneItemSegment
                        ] || {};
                        itemsTopPositions.current = {
                            ...itemsTopPositions.current,
                            [levelOneItemSegment]: {
                                ...currentItemDimensions,
                                height,
                            },
                        };
                    }}
                />
            );

            const shouldWrapInsideCollapse = !shouldRenderMobileBreadcrumbs &&
                (isMobile || (!isMobile && !isNavigationCollapsed));

            if (shouldWrapInsideCollapse) {
                navigationLevelTwoItems = (
                    <Collapse
                        in={isActive}
                        timeout={{
                            appear: 175,
                            enter: 175,
                            exit: 175,
                        }}
                        className={`navigation-level-one-collapse-${item.segment}`}
                    >
                        {navigationLevelTwoItems}
                    </Collapse>
                );
            }

            const shouldRenderBreadcrumbsForwardButton = isMobile &&
                navigationBreadcrumbs.length > 1 &&
                (
                    navigationBreadcrumbs[1]?.parentItem?.segment === item.segment ||
                    (
                        item.segment === 'my-stuff' && (
                            pathname.startsWith('/my-ministry/') ||
                            pathname.startsWith('/my-dashboard/') ||
                            pathname.startsWith('/my-serving-opps/')
                        )
                    )
                );

            const breadcrumbsForwardButton = shouldRenderBreadcrumbsForwardButton && (
                <Icon
                    type="chevron-right"
                    size="small"
                    inverse
                    onClick={(event) => {
                        event.stopPropagation();
                        turnMobileBreadcrumbsVisibleLevel('right');
                    }}
                    className="breadcrumbs-forward-button"
                />
            );

            return (
                <Features
                    key={`navigation-main-item-${item.id}`}
                    requiredFeatures={accessRequires.feature || []}
                >
                    <UserPermission
                        anyPermissions
                        hasAnyMinistries={isMinistry}
                        permission={getPermissions(item)}
                    >
                        <li
                            role="presentation"
                            className={mainItemContainerClasses}
                            onClick={() => onClick({ item, itemLabel: hoverLabel })}
                            onMouseEnter={() => onMouseEnter(hoverLabel)}
                            onMouseLeave={() => onMouseLeave(hoverLabel)}
                            onMouseMove={() => onMouseMove(hoverLabel)}
                            style={{
                                cursor: item.to ? 'pointer' : null,
                            }}
                            ref={(ref) => {
                                const currentItemDimensions = itemsTopPositions.current[
                                    item?.segment
                                ] || {};

                                itemsTopPositions.current = {
                                    ...itemsTopPositions.current,
                                    [item?.segment]: {
                                        ...currentItemDimensions,
                                        top: ref?.getBoundingClientRect().top,
                                    },
                                };
                            }}
                        >
                            {!shouldRenderMobileBreadcrumbs && (
                                <div className="section-label-container">
                                    <div className="section-label-icon">
                                        {svg(kebabCase(item.label))}
                                    </div>

                                    <Typography className="section-label">
                                        {item.label}

                                        {item.isBeta && (
                                            <Typography
                                                className="section-beta-tag"
                                                component="span"
                                            >
                                                (beta)
                                            </Typography>
                                        )}
                                    </Typography>
                                    {breadcrumbsForwardButton}
                                </div>
                            )}
                            {navigationLevelTwoItems}
                        </li>
                    </UserPermission>
                </Features>
            );
        })
    );

    const navigationBreadcrumbsItems = navigationBreadcrumbs
        .filter((breadcrumbInfo, index) => index < mobileBreadcrumbsVisibleLevel)
        .map((breadcrumbInfo) => ({
            to: breadcrumbInfo.title,
            title: breadcrumbInfo.title,
            onBreadcrumbClick: () => {
                breadcrumbInfo.onClick();
                setIsNavigationCollapsed(true);
            },
        }));

    const shouldShowBreadcrumbsLeftIcon = mobileBreadcrumbsVisibleLevel > 0;
    const shouldShowBreadcrumbsRightIcon =
        mobileBreadcrumbsVisibleLevel < navigationBreadcrumbs.length - 1;

    const breadcrumbsTitlesLenght = navigationBreadcrumbsItems.length > 1 ? 10 : 100;
    const shouldExpandVertically =
        navigationBreadcrumbs[mobileBreadcrumbsVisibleLevel]?.items.length === 0;

    const navigationLevelOneClasses = ClassNames(
        'navigation-main-items',
        classes.root,
    );

    return (
        <ul data-testid="navigation-level-one" className={navigationLevelOneClasses}>
            {shouldRenderMobileBreadcrumbs && (
                <React.Fragment>
                    <div
                        className={ClassNames(
                            classes.navigationBreadcrumbsContainer,
                            'navigation--level-one--breadcrumbs-container',
                        )}
                    >
                        {shouldShowBreadcrumbsLeftIcon && (
                            <Icon
                                type="chevron-left"
                                inverse
                                compact
                                size="xxsmall"
                                onClick={() => turnMobileBreadcrumbsVisibleLevel('left')}
                                className="navigation--level-one--breadcrumbs-icon_left"
                            />
                        )}
                        <Breadcrumbs
                            router={router}
                            staticCrumbs={navigationBreadcrumbsItems || []}
                            titlesMaxLength={breadcrumbsTitlesLenght}
                        />
                        {shouldShowBreadcrumbsRightIcon && (
                            <Icon
                                type="chevron-right"
                                inverse
                                compact
                                size="xxsmall"
                                onClick={() => turnMobileBreadcrumbsVisibleLevel('right')}
                                className="navigation--level-one--breadcrumbs-icon_right"
                            />
                        )}
                    </div>
                    <div
                        className={ClassNames(
                            classes.breadcrumbsTitle,
                            'navigation--level-one--breadcrumbs-title',
                            { 'vertically-expanded': shouldExpandVertically },
                        )}
                    >
                        {breadcrumbsTitle}
                    </div>
                </React.Fragment>
            )}
            { renderItems() }
        </ul>
    );
}

NavigationLevelOne.propTypes = propTypes;
NavigationLevelOne.defaultProps = defaultProps;

const NavigationLevelOneConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavigationLevelOne);

export const NavigationLevelOneWithRouter = withRouter(NavigationLevelOneConnected);
