/**
* This enumeration contains the Action Type string constants for campaign editor
*/
enum ActionType {
    // Action Type for Basic Info of Campaign
    SET_NAME = 'CampaignEditorActions.ON_UPDATE_NAME',
    SET_DESCRIPTION = 'CampaignEditorActions.ON_UPDATE_DESCRIPTION',
    SET_CAMPAIGN_CONTACT_EMAIL = 'CampaignEditorActions.ON_UPDATE_CAMPAIGN_CONTACT_EMAIL',
    SET_CAMPAIGN_GOAL = 'CampaignEditorActions.ON_UPDATE_CAMPAIGN_GOAL',
    SET_CAMPAIGN_IS_ACTIVE = 'CampaignEditorActions.ON_UPDATE_CAMPAIGN_IS_ACTIVE',
    SET_CAMPAIGN_IS_FEATURED = 'CampaignEditorActions.ON_UPDATE_CAMPAIGN_IS_FEATURED',
    SET_SHORT_DESCRIPTION = 'CampaignEditorActions.ON_UPDATE_SHORT_DESCRIPTION',
    // Action Type for Image Uploader of Campaign
    SET_COMPANION_APP_HEADER_URL = 'CampaignEditorActions.ON_UPDATE_COMPANION_APP_HEADER_URL',
    SET_THUMBNAIL_URL = 'CampaignEditorActions.ON_UPDATE_THUMBNAIL_URL',
    SET_WEB_HEADER_URL = 'CampaignEditorActions.ON_UPDATE_WEB_HEADER_URL',
    // Action Type for Publishing of Campaign
    SET_PUBLISHED_END_DATE = 'CampaignEditorActions.ON_UPDATE_PUBLISHED_END_DATE',
    SET_PUBLISHED_END_TIME = 'CampaignEditorActions.ON_UPDATE_PUBLISHED_END_TIME',
    SET_PUBLISHED_START_DATE = 'CampaignEditorActions.ON_UPDATE_PUBLISHED_START_DATE',
    SET_PUBLISHED_START_TIME = 'CampaignEditorActions.ON_UPDATE_PUBLISHED_START_TIME',
    SET_PUBLISHED_TIMEZONE = 'CampaignEditorActions.ON_UPDATE_PUBLISHED_TIMEZONE',
    // Action Type for Designation Info of Campaign
    SET_DEFAULT_DESIGNATION = 'CampaignEditorActions.ON_UPDATE_DEFAULT_DESIGNATION',
    SET_DESIGNATION = 'CampaignEditorActions.ON_UPDATE_DESIGNATION',
    REMOVE_DESIGNATION = 'CampaignEditorActions.ON_REMOVE_DESIGNATION',
    // Action Type for Campaign Editor
    RESET_DATA = 'CampaignEditorActions.ON_RESET',
    SET_CAMPAIGN_EDITOR_FORM_DATA = 'CampaignEditorActions.ON_UPDATE_CAMPAIGN_EDITOR_FORM_DATA',
}

export default ActionType;
