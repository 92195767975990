import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { svg } from './navigationUtils.jsx';
import { i18n } from '../../global/constants.js';

const propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

function MainButton(props) {
    const {
        isMobile,
        onClick,
    } = props;

    const item = { label: i18n('Collapse Menu') };

    return (
        <div
            data-testid="navigation-main-button"
            role="presentation"
            className="navigation-main-button"
            onClick={onClick}
        >
            <CSSTransitionGroup
                className="icon-transition"
                transitionAppear
                transitionAppearTimeout={150}
                transitionEnterTimeout={150}
                transitionLeaveTimeout={150}
                transitionName={{
                    appear: 'fade-in-enter',
                    appearActive: 'animate-active',
                    enter: 'fade-in-enter',
                    enterActive: 'animate-active',
                    leave: 'fade-out-leave',
                    leaveActive: 'animate-active',
                }}
            >
                {svg(kebabCase(item.label), isMobile)}
            </CSSTransitionGroup>

            <span className="section-label">
                { item.label }
            </span>
        </div>
    );
}

MainButton.propTypes = propTypes;

export default MainButton;
