import _ from 'lodash';

export const DEFAULT_STATE = {
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunityActions.ON_LIST':
            if (_.isEmpty(action.result)) {
                return DEFAULT_STATE;
            }
            const map = _.reduce(action.result, (obj, param) => {
                obj[param.id] = param;
                return obj;
            }, {});
            return map;
    }
    return state;
}
