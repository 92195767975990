import { selectBoxTransform } from '../../../global/utils';

export const DEFAULT_STATE = {
    categories: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'EventCategoryActions.ON_GETALL':
            return { ...state, categories: selectBoxTransform(action, 'id', 'name') };

        default:
            return state;
    }
};
