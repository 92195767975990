import request from 'axios';
import ApiUtils from '../../global/apiUtils.js';

class AddPersonActions extends ApiUtils {

}

const actions = new AddPersonActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'savePerson',
        route: 'person',
        rq: request.post,
    },
    {
        name: 'saveContactpreferences',
        route: 'person/{personId}/contactpreferences',
        rq: request.post,
    },
    {
        name: 'savePersonPhoto',
        route: 'person/photo',
        rq: request.post,
    },
);

export default actions;
