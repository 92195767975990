import {
    isFunction,
    isNil,
} from 'lodash';
import axios, { AxiosRequestConfig } from 'axios';
import Utils from '../utils/utils.js';
import {
    ApiMethodType,
    ServiceType,
} from './models';
import {
    AuditLogSourceType,
} from '../../people/global/models';
import apiConfig from './apiConfig';
import hcAuthUtils from '../utils/hcAuthUtils';

type Headers = {
    'Content-Type'?: string;
    'X-Referrer': string;
    'X-Source'?: string;
    Authorization?: string;
};

type Data = object | null;

const getBaseUrl = ({
    url,
    service,
}: {
    url: string;
    service: ServiceType;
}) => apiConfig.get(service) + url;

const getBaseConfig = ({
    auditLogSourceOverride = null,
    cancelToken,
    contentType = null,
    data = null,
    method,
    onUploadProgress = null,
    params = null,
    service = ServiceType.Core,
    url,
}: {
    cancelToken?: Function;
    auditLogSourceOverride?: AuditLogSourceType | null;
    contentType?: string | null;
    data?: Data;
    method: ApiMethodType;
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
    params?: object | null;
    service?: ServiceType;
    url: string;
}) => {
    /**
     * 1) Setup headers
     */
    let headers: Headers = {
        /**
         * For audit logging of certain kinds of creates and updates
         */
        'X-Referrer': window.location.href,
    };

    /**
     * 1a) Add X-Source
     *
     * Note: For audit logging
     */
    if (!isNil(auditLogSourceOverride)) {
        /**
         * Specify the source header
         */
        headers = {
            ...headers,
            'X-Source': auditLogSourceOverride,
        };
    }

    /**
     * 1b) Add Authorization to headers.
     */
    const accessToken: string | null = hcAuthUtils.getAccessToken();

    if (!isNil(accessToken)) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
        };
    }

    /**
     * 1c) Add Content-Type.
     */
    if (!isNil(contentType)) {
        headers = {
            ...headers,
            'Content-Type': contentType,
        };
    }

    /**
     * 2) Start constructing for axio's config.
     *
     * 2a) Pass the cancel token to the callback
     */
    const cancelSource = axios.CancelToken.source();

    if (isFunction(cancelToken)) {
        cancelToken(cancelSource);
    }

    /**
     * 2b) Build api's url
     */
    const getUrl = Utils.buildUrl(getBaseUrl({ service, url }), params);

    /**
     * 2c) Build the config
     */
    let config: AxiosRequestConfig = {
        cancelToken: cancelSource.token,
        headers,
        method,
        url: getUrl,
    };

    /**
     * 2d) Are you POST or PUT method? Yes, let's pass in the data.
     */
    if (method === ApiMethodType.Post || method === ApiMethodType.Put) {
        const getData: Data = data;

        config = {
            ...config,
            data: getData,
        };
    }

    /**
     * 2e) Does this method need an upload progress? Yes, let's pass in the uploadProgress.
     */
    if (!isNil(onUploadProgress)) {
        config = {
            ...config,
            onUploadProgress,
        };
    }

    /**
     * 3) Boom!
     */
    return config;
};

export default getBaseConfig;
