import request from 'axios';
import apiConfig from '../../global/api/apiConfig';
import {
    cmAuthUtils,
} from '../../global/utils/utils.js';

const coreApiHost = apiConfig.get();
const url = `${coreApiHost}/api/promotion/release-notes`;

export const getByContentItemId = ({
    contentItemId,
    contentItemVersionId,
}) => () => new Promise((resolve, reject) => {
    request
        .get(`${url}/${contentItemId}?versionId=${contentItemVersionId}`, {
            headers: {
                Authorization: `Bearer ${cmAuthUtils.getAccessToken()}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            resolve(response);
        })
        .catch(({ response }) => {
            reject(response);
        });
});

export const getLatest = () => () => new Promise((resolve, reject) => {
    request
        .get(`${url}/latest`, {
            headers: {
                Authorization: `Bearer ${cmAuthUtils.getAccessToken()}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            resolve(response);
        })
        .catch(({ response }) => {
            reject(response);
        });
});
