import {
    DropdownButton,
    Icon,
    Image,
} from '@saddlebackchurch/react-cm-ui';
import {
    connect,
} from 'react-redux';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';
import {
    i18n,
} from '../../global/constants.js';
import {
    USER_PERMISSIONS,
} from '../../global/userPermissionConstants.js';
import {
    RELEASE_NOTES_OPTION_ID,
} from './constants';
import {
    OnUserMenuChange,
} from './models';
import UserAccessStore from '../../global/userAccessStore.js';

type PropTypes = {
    isCmsPreviewEnabled?: boolean;
    isConnected?: boolean;
    onUserMenuChange: OnUserMenuChange;
    userAccess: UserAccessStore;
    userFullName?: string | null;
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
                userIdentity,
            },
        },
        myAccount: {
            preferences: {
                isCmsPreviewEnabled,
            },
        },
        notification: {
            pushNotifications,
        },
    } = state;

    return {
        isCmsPreviewEnabled,
        isConnected: pushNotifications && pushNotifications.connected,
        userAccess,
        userFullName: userIdentity.getFullName(),
    };
};

const useStyles = makeStyles(({
    palette,
}) => ({
    connectedIcon: {
        left: 26,
        position: 'absolute',
        top: 0,
    },
    root: {
        textAlign: 'right',
        '& .image-avatar-user': {
            '& .icon-use-path': {
                fill: `${palette.grey[400]} !important`,
            },
        },
        '& .icon-caret': {
            display: 'none',
        },
    },
}));

export function UserMenu({
    isCmsPreviewEnabled = false,
    isConnected = false,
    onUserMenuChange,
    userAccess,
    userFullName = null,
}: PropTypes) {
    const classes = useStyles();

    const onUserOptionClick = (event, id) => {
        onUserMenuChange({ id });
    };

    const hasBrowseInDraftMode = userAccess.hasPermission(
        USER_PERMISSIONS.browseInDraftMode,
    );

    return (
        <div
            className={classes.root}
        >
            <DropdownButton
                className="user-dropdown"
                designVersion={2}
                id="user-dropdown"
                iconType={false}
                label={(
                    <div className="user-menu-avatar_container">
                        <span>
                            <Image
                                name={userFullName}
                                size={44}
                                style={{ borderRadius: '50%' }}
                                type="person"
                            />

                            {!isConnected && (
                                <Icon
                                    className={classes.connectedIcon}
                                    color="warning"
                                    size="xxsmall"
                                    title={i18n('NoConnection')}
                                    type="arrow-sort"
                                />
                            )}
                        </span>
                    </div>
                )}
                optionsTheme="light"
                variant="text"
            >
                <DropdownButton.Option
                    id="help"
                    onClick={onUserOptionClick}
                >
                    {i18n('Help')}
                </DropdownButton.Option>

                <DropdownButton.Option
                    id="notificationPreferences"
                    onClick={onUserOptionClick}
                >
                    {i18n('Notification Preferences')}
                </DropdownButton.Option>

                <DropdownButton.Option
                    id="previewFeature"
                    onClick={onUserOptionClick}
                >
                    {i18n('Preview Feature')}
                </DropdownButton.Option>

                <DropdownButton.Option
                    id="preferences"
                    onClick={onUserOptionClick}
                >
                    {i18n('Preferences')}
                </DropdownButton.Option>

                <DropdownButton.Option
                    id="changePassword"
                    onClick={onUserOptionClick}
                >
                    {i18n('Change Password')}
                </DropdownButton.Option>

                {hasBrowseInDraftMode && isCmsPreviewEnabled ? (
                    <DropdownButton.Option
                        id={RELEASE_NOTES_OPTION_ID}
                        onClick={onUserOptionClick}
                    >
                        {i18n('Release Notes')}
                    </DropdownButton.Option>
                ) : null}

                <DropdownButton.Option
                    id="logOut"
                    onClick={onUserOptionClick}
                >
                    {i18n('Log Out')}
                </DropdownButton.Option>
            </DropdownButton>
        </div>
    );
}

export default connect(mapStateToProps)(UserMenu);
