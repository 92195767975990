import './timeZoneDropdown.scss';

import { isEqual } from 'lodash';
import { Dropdown } from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from './constants.js';
import DateUtils from './dateUtils.js';
import EnumerationStore from '../js/stores/EnumerationStore.js';

const propTypes = {
    churchSettings: PropTypes.shape({}),
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    matchProp: PropTypes.oneOf(['any', 'label', 'value']),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const defaultProps = {
    churchSettings: undefined,
    disabled: false,
    id: undefined,
    label: null,
    matchProp: 'label',
    placeholder: i18n('Select a Time Zone'),
    required: false,
    value: null,
};

const mapStateToProps = (state) => {
    const {
        global: { churchCampus: churchSettings },
    } = state;

    return {
        churchSettings,
    };
};

class TimeZoneDropdown extends React.Component {
    constructor(props) {
        super(props);
        const { churchSettings } = props;
        this.state = {
            initialValue: props.value || DateUtils.getDefaultTimeZone(churchSettings),
            options: EnumerationStore.getTimeZonesSelect(),
        };
    }

    componentDidMount() {
        const { onChange, value } = this.props;
        const { initialValue } = this.state;

        if (!value) {
            onChange(initialValue);
        }
    }

    componentDidUpdate(prevProps) {
        const { churchSettings: prevChurchSettings } = prevProps;
        const { churchSettings: nextChurchSettings } = this.props;

        if (!isEqual(nextChurchSettings, prevChurchSettings)) {
            this.setState({
                initialValue: DateUtils.getDefaultTimeZone(nextChurchSettings),
            });
        }
    }

    render() {
        const {
            disabled,
            id,
            label,
            matchProp,
            onChange,
            placeholder,
            required,
            value,
        } = this.props;

        const { options, initialValue } = this.state;
        const tz = value || initialValue;

        return (
            <div className="timezone-dropdown">
                <Dropdown
                    autoScrollSelection
                    clearable={false}
                    disable={disabled}
                    fluid
                    id={id}
                    label={label}
                    menuMaxHeight={448}
                    onChange={onChange}
                    options={options}
                    placeholder={placeholder}
                    selectionRequired={required}
                    selection
                    selectionMatchProp={matchProp}
                    tabIndex={0}
                    value={tz}
                />
            </div>
        );
    }
}

TimeZoneDropdown.propTypes = propTypes;
TimeZoneDropdown.defaultProps = defaultProps;

export default connect(mapStateToProps)(TimeZoneDropdown);
