enum ActionType {
    RESET_EVENT_OPTIONS = 'CommunicationsCentral.RESET_EVENT_OPTIONS',
    RESET_INLINE_EDITOR = 'CommunicationsCentral.RESET_INLINE_EDITOR',
    SET_INLINE_EDITOR_IS_EDITING = 'CommunicationsCentral.SET_INLINE_EDITOR_IS_EDITING',
    SET_INLINE_EDITOR_IS_RESET_MODE = 'CommunicationsCentral.SET_INLINE_EDITOR_IS_RESET_MODE',
    SET_INLINE_EDITOR_IS_SAVING = 'CommunicationsCentral.SET_INLINE_EDITOR_IS_SAVING',
    SET_INLINE_EDITOR_IS_VALID = 'CommunicationsCentral.SET_INLINE_EDITOR_IS_VALID',
    SET_INLINE_EDITOR_SECTION_TYPE = 'CommunicationsCentral.SET_INLINE_EDITOR_SECTION_TYPE',
    SET_ADDITIONAL_ENGAGEMENT = 'CommunicationsCentral.SET_ADDITIONAL_ENGAGEMENT',
    SET_DEMOGRAPHIC = 'CommunicationsCentral.SET_DEMOGRAPHIC',
    SET_EVENT_OPTIONS = 'CommunicationsCentral.SET_EVENT_OPTIONS',
    SET_MILESTONES = 'CommunicationsCentral.SET_MILESTONES',
    SET_MILESTONES_GROUP_OPERATOR = 'CommunicationsCentral.SET_MILESTONES_GROUP_OPERATOR',
}

export default ActionType;
