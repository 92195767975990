import PropTypes from 'prop-types';
import { i18n } from '../../../global/constants.js';

export const BEM_BLOCK_NAME = 'task_list';

export const FilterPropType = PropTypes.shape({
    filterStatuses: PropTypes.arrayOf(PropTypes.string),
    searchQuery: PropTypes.string,
    sort: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
});

export const SORT_UNCLAIMED_OPTIONS = [
    {
        label: i18n('Name (Ascending)'),
        value: 'name',
    }, {
        label: i18n('Name (Descending)'),
        value: '-name',
    }, {
        label: i18n('Received (Ascending)'),
        value: 'received',
    }, {
        label: i18n('Received (Descending)'),
        value: '-received',
    },
];

export const SORT_IN_PROGRESS_OPTIONS = [
    {
        label: i18n('Name (Ascending)'),
        value: 'name',
    }, {
        label: i18n('Name (Descending)'),
        value: '-name',
    }, {
        label: i18n('Received (Ascending)'),
        value: 'received',
    }, {
        label: i18n('Received (Descending)'),
        value: '-received',
    }, {
        label: i18n('Status (Ascending)'),
        value: 'status',
    }, {
        label: i18n('Status (Descending)'),
        value: '-status',
    },
];

export const TASK_STATUS_OPTIONS = [
    {
        label: i18n('Open'),
        value: 'Open',
    },
    {
        label: i18n('On Hold'),
        value: 'Blocked',
    },
];

export const TASK_STATUS_OPTIONS_CLOSED = [
    {
        label: i18n('Successful'),
        value: 'Successful',
    },
    {
        label: i18n('Unsuccessful'),
        value: 'Unsuccessful',
    },
];
