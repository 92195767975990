import {
    concat,
    filter,
    map,
} from 'lodash';

const DEFAULT_STATE = {
    details: {},
    isFetching: false,
};

const task = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'TaskActions.ON_BEFORE_GETTASK':
            return {
                ...state,
                isFetching: true,
            };

        case 'TaskActions.ON_GETTASK':
            return {
                ...state,
                isFetching: action.isFetching,
                details: action.result,
            };

        case 'TaskActions.ON_RESETTASK':
            return {
                ...state,
                details: DEFAULT_STATE.details,
                isFetching: DEFAULT_STATE.isFetching,
            };

        case 'TaskActions.ON_POSTCOMMENT':
        {
            const updatedComments = concat(state.details.comments || [], action.result);

            return {
                ...state,
                details: { ...state.details, comments: updatedComments },
            };
        }

        case 'TaskActions.ON_UPDATECOMMENT':
        {
            // Update corresponding comment in our task details state
            const {
                id: commentId,
                comment: updatedComment,
            } = action.result;

            const updatedComments = map(
                state.details.comments,
                (c) => (c.id === commentId ? { ...c, comment: updatedComment } : c),
            );

            return {
                ...state,
                details: { ...state.details, comments: updatedComments },
            };
        }

        case 'TaskActions.ON_DELETECOMMENT':
        {
            // Remove corresponding comment in Task object's comments collection in state
            const {
                commentId: deletedCommentId,
            } = action.callbackParams;

            const updatedComments = filter(
                state.details.comments,
                (c) => c.id !== deletedCommentId,
            );

            return {
                ...state,
                details: { ...state.details, comments: updatedComments },
            };
        }

        default:
            return state;
    }
};

export default task;
