import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

// NOTE: This reducer holds the chunked pagination list state
//       of ministry members that could be added to a Group OR Role.

const _pagedMembers = new ChunkedPaginationUtils();

const DEFAULT_STATE = {
    canLoadMore: false,
    isFetching: false,
    members: [],
    needsToRequest: false,
    pageNumber: 0,
    pageSize: _pagedMembers.getPageSize(),
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'MinistryGroupActions.ON_BEFORE_GETPOSSIBLEGROUPMEMBERS':
        case 'MinistryRoleActions.ON_BEFORE_GETPOSSIBLEROLEMEMBERS':
            return { ...state, isFetching: true };

        case 'MinistryGroupActions.ON_GETPOSSIBLEGROUPMEMBERS':
        case 'MinistryRoleActions.ON_GETPOSSIBLEROLEMEMBERS':
            _pagedMembers.loadPage(actionResult.members, actionResult.total, callbackParams.first);

            // Fall through to next case

        case 'MinistryGroupActions.ON_POSSIBLE_GROUP_MEMBERS_NEXTPAGE':
            return {
                ...state,
                canLoadMore: _pagedMembers.canLoadMore(),
                members: _pagedMembers.getAll(true),
                needsToRequest: _pagedMembers.needsToLoadPage(),
                pageNumber: _pagedMembers.getCurrentPageNumber(),
                pageSize: _pagedMembers.getPageSize(),
                total: _pagedMembers.getTotalCount(),
            };

        case 'MinistryGroupActions.ON_RESET_POSSIBLE_GROUP_MEMBERS':
            return DEFAULT_STATE;
    }

    return state;
};
