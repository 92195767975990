import { map } from 'lodash';

const DEFAULT_STATE = {
    categories: [],
    isFetching: false,
};

const taskCategory = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'TaskCategoryActions.ON_GETTASKCATEGORIES': {
            const categories = map(
                action.result, (cat) => ({
                    value: cat.id,
                    label: cat.name,
                }),
            ) || [];

            return {
                ...state,
                isFetching: DEFAULT_STATE.isFetching,
                categories,
            };
        }

        case 'TaskCategoryActions.ON_BEFORE_GETTASKCATEGORIES': {
            return {
                ...state,
                isFetching: true,
            };
        }

        default:
            return state;
    }
};

export default taskCategory;
