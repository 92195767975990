import Utils from '../../global/utils/utils.js';
import BannerConstants from '../constants/BannerConstants.js';

export default class BannerAction {
    static addBanner(banner, timeout) {
        return (dispatch) => {
            /* eslint-disable no-param-reassign */
            banner.id = banner.id || `banner-${Utils.getIncreasingUniqueKey()}`;

            if (!!timeout) { // eslint-disable-line no-extra-boolean-cast
                banner.delay = typeof timeout === 'number' ? timeout : 4000;
                banner.cb = () => dispatch(BannerAction.removeBanner(banner.id));
            }

            dispatch({ type: BannerConstants.ADD_BANNER, banner });

            return banner.id;
            /* eslint-enable no-param-reassign */
        };
    }

    static closeBanner(id) {
        return { type: BannerConstants.CLOSE_BANNER, id };
    }

    static removeBanner(id) {
        return { type: BannerConstants.REMOVE_BANNER, id };
    }

    static updateBanner(id, banner) {
        return { type: BannerConstants.UPDATE_BANNER, id, banner };
    }
}
