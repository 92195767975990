import _ from 'lodash';

const DEFAULT_STATE = {
    data: {
        congregationDate: '',
        firstContactDate: '',
        hasAcceptedChrist: false,
        hasAttendedClass101: false,
        hasAttendedClass201: false,
        hasAttendedClass301: false,
        hasAttendedClass401: false,
        hasSignedMembershipAgreement: false,
        hasSignedMaturityCovenant: false,
        hasSignedMinistryCovenant: false,
        hasSignedMissionCovenant: false,
        isBaptised: false,
        isInMinistry: false,
        isInSmallGroup: false,
        isPEACE: false,
    },
    dataByPersonIds: [],
    isFetching: false,
    isFetchingByPersonIds: false,
};

export default (state = DEFAULT_STATE, action) => {
    const actionType = 'CoreMilestonesActions';

    switch (action.type) {
        case `${actionType}.ON_BEFORE_GETCOREMILESTONESREQUEST`: {
            return {
                ...state,
                isFetching: true,
            };
        }

        case `${actionType}.ON_GETCOREMILESTONESREQUEST`: {
            return {
                ...state,
                isFetching: false,
                data: {
                    ...state.data,
                    ...action.result,
                },
            };
        }

        case `${actionType}.ON_GETPERSONGROUPSREQUEST`: {
            const isInSmallGroup = !_.isEmpty(action.result);

            return {
                ...state,
                isFetching: false,
                data: {
                    ...state.data,
                    isInSmallGroup,
                },
            };
        }

        case `${actionType}.ON_BEFORE_GETCOREMILESTONESBYPERSONIDS`: {
            return {
                ...state,
                isFetchingByPersonIds: true,
            };
        }

        case `${actionType}.ON_GETCOREMILESTONESBYPERSONIDS`: {
            return {
                ...state,
                isFetchingByPersonIds: false,
                dataByPersonIds: [
                    ...state.dataByPersonIds,
                    ...action.result,
                ],
            };
        }

        case `${actionType}.ON_RESET_CORE_MILESTONES_BY_PERSON_IDS`: {
            return {
                ...state,
                isFetchingByPersonIds: DEFAULT_STATE.isFetchingByPersonIds,
                dataByPersonIds: DEFAULT_STATE.dataByPersonIds,
            };
        }

        case `${actionType}.ON_RESET_CORE_MILESTONES`: {
            return {
                ...state,
                data: DEFAULT_STATE.data,
                isFetching: DEFAULT_STATE.isFetching,
            };
        }

        default:
            return state;
    }
};
