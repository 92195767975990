import { combineReducers } from 'redux';
import list from './list/list.reducer';
import couponEditor from './couponEditor/couponEditor.reducer';

const reducers = combineReducers({
    couponEditor,
    list,
});

export default reducers;
