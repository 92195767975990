import onClickOutside from 'react-onclickoutside';
import React from 'react';
import PropTypes from 'prop-types';
import ScrollBar from 'react-custom-scrollbars';

import Button from './Button.react';
import Icon from './Icon.react';

import i18nUtils from '../../global/i18nUtils.js';

const _i = i18nUtils.translationFactory('Modal.Drawer');

export class ModalDrawerContent extends React.Component {
    render() {
        const {
            boxShadow, closeIcon, hideCloseIcon, maxWidth,
            title, titleIcon,
        } = this.props;

        return (
            <div className="modal-content" style={{ boxShadow, maxWidth }}>
                <ScrollBar autoHide>
                    <div className="modal-content-container">
                        <header>
                            {title || titleIcon ? (
                                <h3 className="title">
                                    {titleIcon ? (
                                        <Icon
                                            size="m"
                                            color="static"
                                            type={titleIcon}
                                        />
                                    ) : null}

                                    {title ? (
                                        <span className="copy">{title}</span>
                                    ) : null}
                                </h3>
                            ) : null}

                            {!hideCloseIcon ? (
                                <Button
                                    boxType="inline"
                                    className="btn-close"
                                    onClick={this._onClose.bind(this)}
                                    color="primary"
                                    style={{ padding: '0 11px' }}
                                    title={_i('Close')}
                                >
                                    <Icon
                                        size="s"
                                        color="inverse"
                                        type={closeIcon || 'new-chevron-right'}
                                    />
                                </Button>
                            ) : null}
                        </header>

                        {this.props.children}
                    </div>
                </ScrollBar>
            </div>
        );
    }

    handleClickOutside() {
        this._onClose();
    }

    _onClose() {
        this.props.onClose();
    }
}

export default onClickOutside(ModalDrawerContent);

ModalDrawerContent.propTypes = {
    boxShadow: PropTypes.string,
    closeIcon: PropTypes.string,
    hideCloseIcon: PropTypes.bool,
    maxWidth: PropTypes.string,
    onClose: PropTypes.func,
    outsideClickIgnoreClass: PropTypes.string,
    title: PropTypes.string,
    titleIcon: PropTypes.string,
};
