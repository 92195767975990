import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

// NOTE: This reducer holds the chunked pagination list state
//       of the _current_ roster of a single Group OR Role.

const pagedMembers = new ChunkedPaginationUtils();

const DEFAULT_STATE = {
    canLoadMore: false,
    isFetching: false,
    members: [],
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedMembers.getPageSize(),
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'MinistryGroupActions.ON_BEFORE_GETGROUPMEMBERS':
        case 'MinistryRoleActions.ON_BEFORE_GETROLEMEMBERS':
            return { ...state, isFetching: true };

        case 'MinistryGroupActions.ON_GETGROUPMEMBERS':
        case 'MinistryRoleActions.ON_GETROLEMEMBERS':
            pagedMembers.loadPage(actionResult.members, actionResult.total, callbackParams.first);

            // Fall through to next case

        case 'MinistryGroupActions.ON_GROUP_MEMBERS_NEXTPAGE':
            return {
                ...state,
                canLoadMore: pagedMembers.canLoadMore(),
                members: pagedMembers.getAll(true),
                needsToRequest: pagedMembers.needsToLoadPage(),
                pageNumber: pagedMembers.getCurrentPageNumber(),
                pageSize: pagedMembers.getPageSize(),
                total: pagedMembers.getTotalCount(),
            };

        case 'MinistryGroupActions.ON_RESET_GROUP_MEMBERS':
            return DEFAULT_STATE;
        default:
    }

    return state;
};
