import React from 'react';
import BannerAction from '../BannerAction.js';
import NotificationBannerInner from '../../../global/notification/notificationBannerInner.jsx';
import SummaryBannerInner from '../../../global/notification/summaryBannerInner.jsx';
import i18nUtils from '../../../global/i18nUtils.js';
import Utils from '../../../global/utils/utils.js';
import BannerConstants from '../../constants/BannerConstants.js';
import ActivityConstants from '../../constants/Notification/ActivityConstants.js';
import NotificationBannerConstants from '../../constants/Notification/NotificationBannerConstants.js';
import ModalStore from '../../stores/ModalStore.js';
import ActivityDrawer from '../../components/ActivityDrawer/ActivityDrawer.react.js';
import ModalDrawer from '../../components/ModalDrawer.react.js';

const _i = i18nUtils.translationFactory('Notification.BannerNotificationStore');

let taskViewDrawer;

function showBanner(closeBanner) {
    return (dispatch, getState) => {
        const { notification, banners } = getState();
        const banner = {
            level: BannerConstants.BANNER_SUCCESS,
            children: closeBanner,
            iconType: 'old-icon-mail',
            closeButton: true,
        };
        const existingBannerId = notification.notificationBanner.bannerId;
        if (existingBannerId && _.find(banners, (v) => v.id === existingBannerId)) {
            dispatch(BannerAction.updateBanner(existingBannerId, banner));
        } else {
            const id = dispatch(BannerAction.addBanner(banner, true, 8000));
            dispatch({ type: NotificationBannerConstants.SET_NOTIFICATION_BANNER, id });
        }
    };
}

function closeBanner() {
    return (dispatch, getState) => {
        const { notification } = getState();
        if (notification.notificationBanner.bannerId) {
            dispatch(BannerAction.removeBanner(notification.notificationBanner.bannerId));
            dispatch({ type: NotificationBannerConstants.SET_NOTIFICATION_BANNER });
        }
    };
}

function showDrawer(id, pathname) {
    return (dispatch) => {
        dispatch(closeBanner());

        const modal = {
            children: (
                <ModalDrawer
                    boxShadow="-2px 0 7px 0 rgba(0, 0, 0, .17)"
                    closeIcon="cancel"
                    maxWidth="757px"
                    title="Task Details" // This needs to be dynamic for other types
                >
                    <ActivityDrawer
                        claimable={false}
                        closeModal={() => dispatch(closeDrawer())}
                        taskId={id}
                        link={pathname}
                        type="task"
                    />
                </ModalDrawer>
            ),
        };
        taskViewDrawer = ModalStore.addModal(modal);
    };
}

function showMemberActivityDrawer(notification) {
    return (dispatch) => {
        dispatch(closeBanner());

        const modal = {
            children: (
                <ModalDrawer
                    boxShadow="-2px 0 7px 0 rgba(0, 0, 0, .17)"
                    closeIcon="cancel"
                    maxWidth="757px"
                    title="Details"
                >
                    <ActivityDrawer
                        closeModal={() => dispatch(closeDrawer())}
                        data={notification}
                        type="ministry-member"
                    />
                </ModalDrawer>
            ),
        };
        taskViewDrawer = ModalStore.addModal(modal);
    };
}

function closeDrawer() {
    ModalStore.removeModal(taskViewDrawer);
    taskViewDrawer = null;
}

export default class NotificationBannerActions {
    static getDeeplink(payload) {
        let pathname;
        if (payload && payload.RelativeDeepLink && payload.RelativeDeepLink.length) {
            pathname = payload.RelativeDeepLink;
            if (pathname[0] !== '/') {
                pathname = `/${pathname}`;
            }
        }
        return pathname;
    }

    static showNotification(notification) {
        const payload = notification.payload ? JSON.parse(notification.payload) : null;
        const deeplink = NotificationBannerActions.getDeeplink(payload);
        const memberActivity = payload && (notification.sourceType === ActivityConstants.MINISTRY_MEMBER_STATUS_CHANGED ||
            notification.sourceType === ActivityConstants.MINISTRY_MEMBER_NOTE_CREATED ||
            notification.sourceType === ActivityConstants.MINISTRY_MEMBER_INTERESTED ||
            notification.sourceType === ActivityConstants.MINISTRY_MEMBER_EMAIL ||
            notification.sourceType === ActivityConstants.DISPATCH_MESSAGE);

        return (dispatch) => {
            let onClick;
            if (memberActivity) {
                const data = { summary: notification.summary, payload: notification.payload };
                onClick = () => dispatch(showMemberActivityDrawer(data));
            } else {
                onClick = () => dispatch(closeBanner());
            }

            const closeBanner = (
                <NotificationBannerInner
                    pathname={deeplink}
                    isDashboard={!memberActivity && !deeplink}
                    message={notification.summary}
                    onClick={onClick}
                />
            );
            dispatch(showBanner(closeBanner));
        };
    }

    static showSummary(summary) {
        const s = Utils.format(_i('SummaryTitle'), summary.unreadMessagesCount);

        return (dispatch) => {
            const closeBanner = (
                <SummaryBannerInner
                    message={s}
                    onLink={() => dispatch(closeBanner())}
                />
            );
            dispatch(showBanner(closeBanner));
        };
    }

    static reset() {
        return (dispatch) => {
            dispatch(closeBanner());
        };
    }
}
