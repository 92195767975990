import _ from 'lodash';
import LoginConstants from '../constants/Login/LoginConstants';
import BannerConstants from '../constants/BannerConstants';

export const DEFAULT_STATE = [];

export function resetState(state) {
    state.forEach((v) => clearTimeout(v.timerId));
    return DEFAULT_STATE;
}

export function addBanner(state, banner) {
    if (_.isEmpty(banner) || !banner.id || _.find(state, (v) => v.id === banner.id)) {
        return state;
    }
    if (!banner.isOpen) {
        banner.isOpen = true;
    }
    if (banner.timerId) {
        return state;
    }
    if (banner.delay && typeof banner.cb === 'function') {
        banner.timerId = setTimeout(banner.cb, banner.delay);
    } else {
        delete banner.delay;
        delete banner.cb;
    }

    return [...state, banner];
}

export function closeBanner(state, id) {
    if (!id) {
        return state;
    }

    if (_.find(state, (v) => v.id === id)) {
        const newState = [...state];
        const newBanner = _.find(newState, (v) => v.id === id);
        newBanner.isOpen = false;

        return newState;
    }
    return state;
}

export function updateBanner(state, id, banner) {
    if (_.isEmpty(banner) || !id) {
        return state;
    }

    if (banner.timerId) {
        return state;
    }

    if (_.find(state, (v) => v.id === id)) {
        const newState = [...state];
        const newBanner = _.find(newState, (v) => v.id === id);

        Object.assign(newBanner, banner);

        if (newBanner.timerId) {
            clearTimeout(newBanner.timerId);
            newBanner.timerId = setTimeout(newBanner.cb, newBanner.delay);
        }

        return newState;
    }
    return state;
}

export function removeBanner(state, id) {
    if (!id) {
        return state;
    }
    const banner = _.find(state, (v) => v.id === id);
    if (banner) {
        if (banner.timerId) {
            clearTimeout(banner.timerId);
        }
        return _.without(state, banner);
    }
    return state;
}

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return resetState(state);

        case BannerConstants.ADD_BANNER:
            return addBanner(state, action.banner);

        case BannerConstants.CLOSE_BANNER:
            return closeBanner(state, action.id, action.banner);

        case BannerConstants.REMOVE_BANNER:
            return removeBanner(state, action.id);

        case BannerConstants.UPDATE_BANNER:
            return updateBanner(state, action.id, action.banner);
    }
    return state;
}
