import { appReduxStore } from '../../global/configureReduxStore.js';

const actionType = 'ContactFormActions';

export function resetData() {
    appReduxStore.dispatch({
        type: `${actionType}.ON_RESET_DATA`,
    });
}

export function setAddress1(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_ADDRESS_1`,
    });
}

export function setAddress2(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_ADDRESS_2`,
    });
}

export function setCity(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_CITY`,
    });
}

export function setContactData(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_CONTACT_DATA`,
    });
}

export function setCountry(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_COUNTRY`,
    });
}

export function setEmail(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_EMAIL`,
    });
}

export function setPhone(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_PHONE`,
    });
}

export function setPhoneExtension(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_PHONE_EXTENSION`,
    });
}

export function setPhoneType(value) {
    appReduxStore.dispatch({
        result: {
            isHome: value.isHome,
            isMobile: value.isMobile,
            isWork: value.isWork,
        },
        type: `${actionType}.ON_UPDATE_PHONE_TYPE`,
    });
}

export function setPostalCode(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_POSTAL_CODE`,
    });
}

export function setRegion(value) {
    appReduxStore.dispatch({
        result: value,
        type: `${actionType}.ON_UPDATE_REGION`,
    });
}
