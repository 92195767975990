import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';

class NotificationPreferenceActions extends ApiUtils {
    constructor(baseUrl) {
        super(baseUrl, 'Notifications');
    }

    updateCheckboxStatus(preferenceId, channelId) {
        appReduxStore.dispatch({ type: 'NotificationPreferenceActions.ON_UPDATE_CHECKBOX', preferenceId, channelId });
    }

    selectUnselectAllCheckbox(channelId, checkboxOperation) {
        appReduxStore.dispatch({ type: 'NotificationPreferenceActions.ON_SELECT_UNSELECT_ALL_CHECKBOX', channelId, checkboxOperation });
    }
}

const actions = new NotificationPreferenceActions('/api/user/notification-preference');

actions.buildReduxActionClass(
    { name: 'get', rq: request.get },
    { name: 'save', rq: request.post },
);

export default actions;
