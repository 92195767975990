import {
    isEqual,
    without,
} from 'lodash';
import { SEARCH_TYPE_ACTIVE } from '../../global/constants.js';
import {
    SERVING_OPPORTUNITY_SORT_OPTIONS,
    STATUS_RADIO_FILTER,
} from '../../constants.js';

export const defaultFilters = {
    hasServed: null,
    isCheckedIn: null,
    selectedCampusIds: [],
    selectedGroupIds: [],
    selectedMembershipIds: [],
    selectedMissingRequirementIds: [],
    selectedRoleIds: [],
    sort: SERVING_OPPORTUNITY_SORT_OPTIONS[2], // sort by last name (ascending)
};

export const DEFAULT_STATE = {
    appliedFilters: { ...defaultFilters },
    areFiltersDirty: false,
    defaultFilters: { ...defaultFilters },
    dirtyFilters: { ...defaultFilters },
    hasServed: null,
    isCheckedIn: null,
    isFiltering: false,
    searchTerm: '',
    searchType: SEARCH_TYPE_ACTIVE,
    sortValue: 'endingfirst',
    selectedOpportunities: [],
    radioStatusSelectedItem: STATUS_RADIO_FILTER.upcoming,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ScheduleFilterActions.ON_APPLY_FILTERS':
        {
            const { dirtyFilters } = state;
            const isFiltering = !isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'ScheduleFilterActions.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(state, { ...defaultFilters });

        case 'ScheduleFilterActions.ON_CLEAR_SEARCH_TERM': {
            return {
                ...state,
                searchTerm: DEFAULT_STATE.searchTerm,
            };
        }

        case 'ScheduleFilterActions.ON_DIRTY_MINISTRY_MEMBERSHIP_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedMembershipIds: action.value },
            );
        }

        case 'ScheduleFilterActions.ON_DIRTY_MISSING_REQUIREMENT_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedMissingRequirementIds: action.value },
            );
        }

        case 'ScheduleFilterActions.ON_DIRTY_CAMPUSES_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedCampusIds: action.value },
            );
        }

        case 'ScheduleFilterActions.ON_DIRTY_GROUPS_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedGroupIds: action.value },
            );
        }

        case 'ScheduleFilterActions.ON_DIRTY_ROLES_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedRoleIds: action.value },
            );
        }

        case 'ScheduleFilterActions.ON_DIRTY_SORT_ORDER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, sort: action.value },
            );
        }

        case 'ScheduleFilterActions.ON_DIRTY_CHECKEDIN_STATUS_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, isCheckedIn: action.value },
            );
        }

        case 'ScheduleFilterActions.ON_DIRTY_SERVED_STATUS_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, hasServed: action.value },
            );
        }

        case 'ScheduleFilterActions.ON_MINISTRY_MEMBERSHIP_FILTER_CHANGED_AND_APPLIED':
        {
            const { selectedMembershipId, isChecked } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedMembershipIds: isChecked ?
                    [...state.appliedFilters.selectedMembershipIds, selectedMembershipId] :
                    without(
                        state.appliedFilters.selectedMembershipIds,
                        selectedMembershipId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ScheduleFilterActions.ON_MISSING_REQUIREMENT_FILTER_CHANGED_AND_APPLIED':
        {
            const { selectedMissingRequirementId, isChecked } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedMissingRequirementIds: isChecked ?
                    [...state.appliedFilters.selectedMissingRequirementIds,
                        selectedMissingRequirementId] :
                    without(
                        state.appliedFilters.selectedMissingRequirementIds,
                        selectedMissingRequirementId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ScheduleFilterActions.ON_RESET': {
            return DEFAULT_STATE;
        }

        case 'ScheduleFilterActions.ON_CAMPUSES_FILTER_CHANGED_AND_APPLIED':
        {
            const { selectedCampusId, isChecked } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedCampusIds: isChecked ?
                    [...state.appliedFilters.selectedCampusIds, selectedCampusId] :
                    without(
                        state.appliedFilters.selectedCampusIds,
                        selectedCampusId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ScheduleFilterActions.ON_GROUPS_FILTER_CHANGED_AND_APPLIED':
        {
            const { selectedGroupId, isChecked } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedGroupIds: isChecked ?
                    [...state.appliedFilters.selectedGroupIds, selectedGroupId] :
                    without(
                        state.appliedFilters.selectedGroupIds,
                        selectedGroupId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ScheduleFilterActions.ON_ROLES_FILTER_CHANGED_AND_APPLIED':
        {
            const { selectedRoleId, isChecked } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedRoleIds: isChecked ?
                    [...state.appliedFilters.selectedRoleIds, selectedRoleId] :
                    without(
                        state.appliedFilters.selectedRoleIds,
                        selectedRoleId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ScheduleFilterActions.ON_SET_SEARCH_TERM': {
            return {
                ...state,
                searchTerm: action.value,
            };
        }

        case 'ScheduleFilterActions.ON_SET_SERVING_OPPS_SEARCH_TYPE': {
            return {
                ...state,
                searchType: action.value,
            };
        }

        case 'ScheduleFilterActions.ON_SET_SERVING_OPPS_SORT_VALUE': {
            return {
                ...state,
                sortValue: action.value,
            };
        }

        case 'ScheduleFilterActions.ON_SET_SERVING_OPPS_CHECKEDIN_STATUS': {
            return {
                ...state,
                isCheckedIn: action.value,
            };
        }

        case 'ScheduleFilterActions.ON_SET_SERVING_OPPS_SERVED_STATUS': {
            return {
                ...state,
                hasServed: action.value,
            };
        }

        case 'ScheduleFilterActions.ON_SET_SERVING_OPPS_SELECT_OPPORTUNITIES': {
            return {
                ...state,
                selectedOpportunities: action.value,
            };
        }

        case 'ScheduleFilterActions.ON_SET_SERVING_OPPS_RADIO_STATUS_SELECTED_ITEM': {
            return {
                ...state,
                radioStatusSelectedItem: action.value,
            };
        }

        case 'ScheduleFilterActions.ON_SORT_ORDER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, sort: action.value };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ScheduleFilterActions.ON_CHECKEDIN_STATUS_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, isCheckedIn: action.value };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ScheduleFilterActions.ON_SERVED_STATUS_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, hasServed: action.value };
            return updateAppliedFilterState(state, updatedFilters);
        }

        default:
            return state;
    }
};
