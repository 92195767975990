import _ from 'lodash';
import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const pagedRecipients = new ChunkedPaginationUtils();

// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_STATE = {
    canLoadMore: false,
    isFetching: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedRecipients.getPageSize(),
    recipients: [],
    total: 0,
};

export default function (state = DEFAULT_STATE, action) {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    const first = !_.isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        case 'MinistryServingOpportunityActions.RESET_MULTIPLE_OPPORTUNITY_VOLUNTEERS':
            return DEFAULT_STATE;

        case 'MinistryServingOpportunityActions.ON_BEFORE_GETMULTIPLEOPPORTUNITYVOLUNTEERSINTERNAL':
            return { ...state, isFetching: true };

        case 'MinistryServingOpportunityActions.ON_GETMULTIPLEOPPORTUNITYVOLUNTEERSINTERNAL':
        {
            const { results, resultCount } = actionResult;
            pagedRecipients.loadPage(results, resultCount, first);
            // Fall through to next page case
        }

        /* eslint-disable no-fallthrough */
        case 'MinistryServingOpportunityActions.ON_GET_MULTIPLE_OPPORTUNITY_VOLUNTEERS_NEXTPAGE':
        {
            const recipients = pagedRecipients.getAll(true);

            return {
                ...state,
                canLoadMore: pagedRecipients.canLoadMore(),
                isFetching: false,
                needsToRequest: pagedRecipients.needsToLoadPage(),
                pageNumber: pagedRecipients.getCurrentPageNumber(),
                pageSize: pagedRecipients.getPageSize(),
                recipients,
                total: first ? pagedRecipients.getTotalCount() : state.total,
            };
            /* eslint-enable no-fallthrough */
        }

        default:
            // no-op
    }

    return state;
}
