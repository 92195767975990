import {
    cloneDeep,
    isNil,
    startCase,
    toLower,
} from 'lodash';
import {
    apiHostsDirectory,
    getTitleCasedEnvironmentName,
} from '../../global/api/apiHostsDirectory';
import {
    EnvironmentVersionInfo,
    HealthyChurchComponent,
    ServiceVersionInfo,
} from '../models';
import {
    HealthyChurchEnvironment,
} from '../../global/api/models';

type EnvironmentsState = {
    environments: Map<HealthyChurchEnvironment, EnvironmentVersionInfo>;
};

const initializeEnvironmentVersionState =
    (): Map<HealthyChurchEnvironment, EnvironmentVersionInfo> => {
        const initialVersionStateMap: Map<HealthyChurchEnvironment, EnvironmentVersionInfo> =
            new Map();

        Object.keys(HealthyChurchEnvironment).forEach((environmentName: string) => {
            const environment: HealthyChurchEnvironment =
                environmentName as HealthyChurchEnvironment;

            const envVersionInfo = new EnvironmentVersionInfo(environmentName);

            Object.keys(HealthyChurchComponent).forEach((componentName: string) => {
                const component: HealthyChurchComponent = componentName as HealthyChurchComponent;
                let componentForApiHost: string | null;

                switch (component) {
                    case HealthyChurchComponent.ChurchInsights:
                        componentForApiHost = HealthyChurchComponent.Core;
                        break;

                    case HealthyChurchComponent.UI:
                        componentForApiHost = null;
                        break;

                    default:
                        componentForApiHost = component;
                }

                const apiHost: string | null = !isNil(componentForApiHost) ?
                    apiHostsDirectory[environment][componentForApiHost] :
                    null;

                envVersionInfo.upsertService(
                    component,
                    new ServiceVersionInfo(componentName, apiHost),
                );
            });

            initialVersionStateMap.set(environment, envVersionInfo);
        });

        return initialVersionStateMap;
    };

const DEFAULT_STATE = { environments: initializeEnvironmentVersionState() };

const environmentsReducer = (
    state: EnvironmentsState = DEFAULT_STATE,
    action: { error?: any; result?: any; type: string; },
): EnvironmentsState => {
    const { result: actionResult, type: actionType } = action;

    if (actionType) {
        if (actionType.startsWith('EnvironmentsActions.')) {
            const actionTypeSegments = actionType.split('_');

            const isFetchingStartedAction = actionTypeSegments.includes('STARTED');

            const isFetchingCompletedAction = !isFetchingStartedAction &&
                actionTypeSegments.includes('COMPLETED');

            const isServiceVersionAction = !isFetchingStartedAction &&
                !isFetchingCompletedAction &&
                actionTypeSegments.includes('VERSION');

            let environmentNameSegmentIndex;

            if (isFetchingStartedAction || isFetchingCompletedAction) {
                environmentNameSegmentIndex = actionTypeSegments.length - 1;
            } else if (isServiceVersionAction) {
                environmentNameSegmentIndex = actionTypeSegments.length - 2;
            } else {
                // eslint-disable-next-line no-console
                console.warn('Environments Reducer: Invalid action type:', actionType);
                return state;
            }

            const environmentName = getTitleCasedEnvironmentName(
                actionTypeSegments[environmentNameSegmentIndex],
            );

            const updatedEnvironments = cloneDeep(state.environments);

            const updatedEnvironment = updatedEnvironments.get(
                environmentName as HealthyChurchEnvironment,
            );

            if (isFetchingStartedAction) {
                updatedEnvironment.isFetching = true;
            } else if (isFetchingCompletedAction) {
                updatedEnvironment.attemptedDataFetch = true;
                updatedEnvironment.isFetching = false;
            } else { // presumably a service version info action
                let serviceName = actionTypeSegments[actionTypeSegments.length - 1];

                switch (serviceName) {
                    case 'UI':
                        break;
                    case 'CHURCHINSIGHTS':
                        serviceName = 'ChurchInsights';
                        break;
                    case 'SMALLGROUPS':
                        serviceName = 'SmallGroups';
                        break;
                    default:
                        serviceName = startCase(toLower(serviceName));
                }

                let svcVersionData;

                if (serviceName === 'UI') {
                    svcVersionData = new ServiceVersionInfo(
                        serviceName,
                        '',
                        actionResult.version,
                        actionResult.branch,
                        false,
                    );
                } else {
                    svcVersionData = updatedEnvironment.getService(
                        serviceName as HealthyChurchComponent,
                    );

                    if (!isNil(actionResult)) {
                        svcVersionData.version = actionResult.apiVersion;
                        svcVersionData.branch = actionResult.branch || '(unknown)';
                        svcVersionData.isLoading = false;
                    } else if (!isNil(action.error)) {
                        svcVersionData.error = `Error (${action.error.status} ${action.error.statusText})`;
                        svcVersionData.isLoading = false;
                    } else {
                        svcVersionData.error = 'Error (No Response)';
                        svcVersionData.isLoading = false;
                    }
                }

                updatedEnvironment.upsertService(
                    serviceName as HealthyChurchComponent,
                    svcVersionData,
                );
            }

            return {
                ...state,
                environments: updatedEnvironments,
            };
        }
    }

    return state;
};

export default environmentsReducer;
