import _ from 'lodash';

export const DEFAULT_STATE = 0;

export default function (state = DEFAULT_STATE, action) {
    if (action.type === 'AdminImportHistoryCountActions.ON_GET') {
        return action.result;
    }
    return state;
}
