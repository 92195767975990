const DEFAULT_STATE = {
    isFetching: false,
    data: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'RelationshipActions.ON_BEFORE_GETRELATION': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'RelationshipActions.ON_GETRELATION': {
            return {
                ...state,
                isFetching: false,
                data: action.result,
            };
        }

        case 'RelationshipActions.ON_RESET_RELATION': {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
