const DEFAULT_STATE = {
    dates: {},
    isFetching: true,
    statistics: {},
    taskGroups: [],
    lastUpdated: null,
    view: 'list',
};

const TaskGroups = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'TaskGroupsActions.ON_GETWORKFLOWSVIEW':
            return {
                ...state,
                view: action.view,
            };

        case 'TaskGroupsActions.ON_BEFORE_GETINPROGRESSTASKGROUPS':
            return {
                ...state,
                dates: {},
                isFetching: true,
                statistics: {},
                taskGroups: [],
                lastUpdated: null,
            };

        case 'TaskGroupsActions.ON_GETINPROGRESSTASKGROUPS':
            return {
                ...state,
                dates: action.result.dates,
                isFetching: false,
                statistics: action.result.statistics,
                taskGroups: action.result.tasks,
                lastUpdated: Date.now(),
            };

        case 'TaskGroupsActions.ON_BEFORE_GETCLOSEDTASKGROUPS':
            return {
                ...state,
                dates: {},
                isFetching: true,
                statistics: {},
                taskGroups: [],
                lastUpdated: null,
            };

        case 'TaskGroupsActions.ON_GETCLOSEDTASKGROUPS':
            return {
                ...state,
                dates: action.result.dates,
                isFetching: false,
                statistics: action.result.statistics,
                taskGroups: action.result.tasks,
                lastUpdated: Date.now(),
            };

        case 'TaskGroupsActions.ON_BEFORE_GETESCALATEDTASKGROUPS':
            return {
                ...state,
                dates: {},
                isFetching: true,
                statistics: {},
                taskGroups: [],
                lastUpdated: null,
            };

        case 'TaskGroupsActions.ON_GETESCALATEDTASKGROUPS':
            return {
                ...state,
                dates: action.result.dates,
                isFetching: false,
                statistics: action.result.statistics,
                taskGroups: action.result.tasks,
                lastUpdated: Date.now(),
            };

        case 'TaskGroupsActions.ON_BEFORE_GETUNCLAIMEDTASKGROUPS':
            return {
                ...state,
                dates: {},
                isFetching: true,
                statistics: {},
                taskGroups: [],
                lastUpdated: null,
            };

        case 'TaskGroupsActions.ON_GETUNCLAIMEDTASKGROUPS':
            return {
                ...state,
                dates: action.result.dates,
                isFetching: false,
                statistics: action.result.statistics,
                taskGroups: action.result.tasks,
                lastUpdated: Date.now(),
            };

        default:
            return state;
    }
};

export default TaskGroups;
