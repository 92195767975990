import PropTypes from 'prop-types';
import React from 'react';
import ActivityDrawerMinistryMember from './ActivityDrawerMinistryMember.react';
import AppStore from '../../stores/AppStore.js';
import If from '../../../global/if.js';
import ModalStore from '../../stores/ModalStore.js';

export default class ActivityDrawer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            key: 0,
        };
    }

    componentWillMount() {
        AppStore.toggleBodyClass(false);
    }

    componentWillUnmount() {
        const { taskListModalDrawer } = this.state;

        if (taskListModalDrawer) {
            ModalStore.closeModal(taskListModalDrawer);
        }
    }

    render() {
        return (
            <div className="dashboard" key={this.state.key}>
                <div className="dashboard-activity-list">
                    <If predicate={this.props.type === 'ministry-member'}>
                        <ActivityDrawerMinistryMember
                            closeModal={this.props.closeModal}
                            data={this.props.data}
                        />
                    </If>
                </div>
            </div>
        );
    }
}

ActivityDrawer.propTypes = {
    claimable: PropTypes.bool,
    closeModal: PropTypes.func,
    data: PropTypes.object,
    link: PropTypes.string,
    taskId: PropTypes.number,
    type: PropTypes.string.isRequired,
};
