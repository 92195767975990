import {
    Box,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { isNil } from 'lodash';
import React from 'react';
import { i18n } from '../constants.js';
import errorSatellite from '../../images/error_satellite.svg';

type PropTypes = {
    caption?: string | null;
    image?: string | null;
    pt?: number | null;
    sx?: object | null;
    title?: string;
};

const bemBlockName = 'error_status';

function NoResults({
    caption = null,
    image = errorSatellite,
    pt = null,
    title = i18n('No Results'),
    sx = null,
}: PropTypes) {
    return (
        <Box
            alignItems="center"
            className={bemBlockName}
            data-testid="no_results"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pt={pt ?? 8}
            sx={{
                ...sx,
            }}
            textAlign="center"
        >
            <Box
                maxWidth={500}
            >
                <Box
                    mb={2}
                >
                    <img
                        alt={i18n('Satellite')}
                        className={`${bemBlockName}--image`}
                        height={186}
                        src={image}
                        width={164}
                    />
                </Box>

                <Box
                    mb={1}
                >
                    <Typography
                        component="div"
                    >
                        <Box
                            className={`${bemBlockName}--title`}
                            fontSize={33}
                            fontWeight="fontWeightMedium"
                            sx={{
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {title}
                        </Box>
                    </Typography>
                </Box>

                {!isNil(caption) ? (
                    <Typography
                        component="div"
                        data-testid="caption"
                    >
                        <Box
                            className={`${bemBlockName}--caption`}
                            fontSize={18}
                            sx={{
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {caption}
                        </Box>
                    </Typography>
                ) : null}
            </Box>
        </Box>
    );
}

export default NoResults;
