import {
    Button,
    dateUtils,
    Header,
    Icon,
    Image,
} from '@saddlebackchurch/react-cm-ui';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

const propTypes = {
    notificationDetailData: PropTypes.shape({}).isRequired,
    onCloseDrawer: PropTypes.func.isRequired,
    onWingToggle: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            isMobile,
        },
        bootstrap,
        quickNotifications,
    } = state;

    return {
        notificationDetail: quickNotifications.notificationDetail,
        isDetailFetching: quickNotifications.isDetailFetching,
        isMobile,
        membershipStatuses: bootstrap.enumerations.enumerations.ministryMemberStatuses,
    };
};

class QuickNotificationDetail extends React.Component {
    static getButtonLabel(category) {
        let label = 'Go To ';

        switch (category) {
            case 'Ministry':
                label += 'Ministry';
                break;

            case 'Workflow':
                label += 'Workflow';
                break;

            case 'Discipleship':
                label += 'Discipleship';
                break;

            case 'Events':
                label += 'Events';
                break;

            case 'System':
                label += 'System';
                break;

            default:
                break;
        }

        return label;
    }

    constructor() {
        super();

        this.navigateToSource = this.navigateToSource.bind(this);
        this.getDefaultDetailHeader = this.getDefaultDetailHeader.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
    }

    getDefaultDetailHeader() {
        const { notificationDetailData } = this.props;
        return (
            <div className="notification-detail-headers">
                <Header className="font-size-large font-weight-bold">{notificationDetailData.summary}</Header>
                <span className="color-static">{notificationDetailData.category}</span>
            </div>
        );
    }

    getDetailHeader(sourceType) {
        const { membershipStatuses, notificationDetail } = this.props;
        const { content } = notificationDetail;
        const notificationDetailData = notificationDetail.notification;
        let data = {};

        switch (sourceType) {
            case 'MinistryMemberInterested':
                data = {
                    summary: notificationDetailData.summary,
                    category: notificationDetailData.category,
                    categoryName: content.ministryName,
                    additionalField: [
                        {
                            label: 'Location',
                            value: content.ministryLocation,
                        },
                        {
                            label: 'Interested Person',
                            value: content.interestedMemberName,
                        },
                        {
                            label: 'Added Time',
                            value: dateUtils.getLocalDateTime(content.notificationCreateDate),
                        },
                    ],
                };
                break;
            case 'MinistryMemberStatusChanged':
                const previousStatus = _.find(membershipStatuses, { id: content.previousStatus }) || {};
                const newStatus = _.find(membershipStatuses, { id: content.newStatus }) || {};
                data = {
                    summary: notificationDetailData.summary,
                    category: notificationDetailData.category,
                    categoryName: content.ministryName,
                    additionalField: [
                        {
                            label: 'Location',
                            value: content.ministryLocation,
                        },
                        {
                            label: 'Ministry Member Name',
                            value: content.ministryMemberName,
                        },
                        {
                            label: 'Status',
                            value: `Changed From ${previousStatus.label} to ${newStatus.label}`,
                        },
                        {
                            label: 'Added Time',
                            value: dateUtils.getLocalDateTime(content.statusChangeDate),
                        },
                    ],
                };
                break;
            case 'AddedToMinistryAsLeader':
                data = {
                    summary: notificationDetailData.summary,
                    category: notificationDetailData.category,
                    categoryName: content.ministryName,
                    additionalField: [
                        {
                            label: 'Leadership Role',
                            value: content.leadershipRoleName,
                        },
                        {
                            label: 'Assigned Leadership Time',
                            value: dateUtils.getLocalDateTime(content.leadershipModifiedDate),
                        },
                    ],
                };
                break;
            case 'ServingOpportunityVolunteerInterested':
                data = {
                    summary: notificationDetailData.summary,
                    category: notificationDetailData.category,
                    categoryName: content.ministryName,
                    additionalField: [
                        {
                            label: 'Serving Opportunity',
                            value: content.servingOpportunityName,
                        },
                        {
                            label: 'Interested Person',
                            value: content.interestedMemberName,
                        },
                        {
                            label: 'Added Time',
                            value: dateUtils.getLocalDateTime(content.notificationCreateDate),
                        },
                    ],
                };
                break;
            case 'MinistryMemberNoteCreated':
                data = {
                    summary: notificationDetailData.summary,
                    category: notificationDetailData.category,
                    categoryName: content.ministryName,
                    additionalField: [
                        {
                            label: 'Location',
                            value: content.ministryLocation,
                        },
                        {
                            label: 'Ministry Member Name',
                            value: content.ministryMemberName,
                        },
                        {
                            label: 'Created By',
                            value: content.noteCreaterUserName,
                        },
                        {
                            label: 'Added Time',
                            value: dateUtils.getLocalDateTime(content.noteCreateDate),
                        },
                    ],
                };
                break;

            default:
                data = {
                    summary: notificationDetailData.summary,
                    category: notificationDetailData.category,
                    categoryName: '',
                    additionalField: [],
                };
                break;
        }

        return (
            <div className="notification-detail-headers">
                <Header className="font-size-large font-weight-bold">{data.summary}</Header>
                <span className="color-static">{data.category}</span>
                <span className="font-weight-semibold" style={{ display: 'block' }}>{data.categoryName}</span>
                {
                    _.map(data.additionalField, (item, index) => (
                        <div key={index} style={{ marginTop: 10 }}>
                            <span className="color-static">{item.label}</span>
                            <span className="font-weight-semibold" style={{ display: 'block' }}>{item.value}</span>
                        </div>
                    ))
                }
            </div>
        );
    }

    navigateToSource() {
        const {
            notificationDetailData,
            onCloseDrawer,
            router,
        } = this.props;

        const navLink = JSON.parse(notificationDetailData.payload);
        const navLinkUrl = navLink.RelativeDeepLink.startsWith('/') ? navLink.RelativeDeepLink : `/${navLink.RelativeDeepLink}`;

        router.push(navLinkUrl);

        onCloseDrawer();
    }

    handleTouchStart(evt) {
        this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
        // Set a flag that the view is not being dragged
        this.dragging = false;
    }

    handleTouchMove(evt) {
        // Set a flag that the view is being dragged
        if (!this.xDown || !this.yDown) {
            return;
        }

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = this.xDown - xUp;
        const yDiff = this.yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) { /* most significant */
            if (xDiff < 0) {
                this.props.onWingToggle();
            }
        }

        /* reset values */
        this.xDown = null;
        this.yDown = null;
    }

    render() {
        const {
            isMobile,
            notificationDetail,
            notificationDetailData,
            onWingToggle,
        } = this.props;

        return (
            <div
                className="notification-detail"
                onTouchMove={this.handleTouchMove}
                onTouchStart={this.handleTouchStart}
            >
                {
                    isMobile ? (
                        <div className="back-icon">
                            <Icon
                                id="quick_notification_detail--ui_icon-on_wing_toggle"
                                onClick={onWingToggle}
                                size="large"
                                title="Close"
                                type="chevron-left"
                            />
                        </div>
                    ) : null
                }

                <div className="notification-detail-wrapper" style={{ margin: '0 11px' }}>

                    { !_.isEmpty(notificationDetail) ? (
                        <div>
                            {this.getDetailHeader(notificationDetailData.sourceType)}
                            <div className="notification-detail-content">
                                <div className="detail-avatar-image">
                                    <Image
                                        name={notificationDetailData.senderContact ? notificationDetailData.senderContact.senderName : 'Healthy Church'}
                                        size={44}
                                        src={notificationDetailData.senderContact ? notificationDetailData.senderContact.avatarUrl : null}
                                        type="user"
                                    />
                                </div>
                                <div className="font-weight-semibold detail-sender-info">
                                    <Header size="medium" style={{ margin: 0 }}>
                                        {notificationDetailData.senderContact && notificationDetailData.senderContact.senderName ? notificationDetailData.senderContact.senderName : 'Healthy Church'}
                                    </Header>
                                    <span className="color-static font-size-xxsmall">
                                        {moment.unix(notificationDetailData.createDate, 'YYYYMMDD').calendar()}
                                    </span>
                                </div>
                            </div>
                            <div style={{ width: '100%' }}>
                                <span dangerouslySetInnerHTML={{ __html: notificationDetail.contentBody }} />
                            </div>

                            {
                                notificationDetailData.payload ? (
                                    <Button
                                        className="drawer-close-button animated bounceInDown"
                                        color="primary"
                                        id="quick_notification_detail--ui_button-navigate_to_source"
                                        onClick={this.navigateToSource}
                                        style={{ marginTop: 15 }}
                                    >
                                        { QuickNotificationDetail.getButtonLabel(notificationDetailData.category) }
                                    </Button>
                                ) : null
                            }
                        </div>
                    ) : (
                        <div>
                            {this.getDefaultDetailHeader()}
                            <div className="notification-detail-content">
                                <div className="detail-avatar-image">
                                    <Image
                                        name={notificationDetailData.senderContact ? notificationDetailData.senderContact.senderName : 'Healthy Church'}
                                        size={44}
                                        src={notificationDetailData.senderContact ? notificationDetailData.senderContact.avatarUrl : null}
                                        type="user"
                                    />
                                </div>
                                <div className="detail-sender-info">
                                    <Header className="font-weight-normal" style={{ margin: 0 }}>
                                        {notificationDetailData.senderContact && notificationDetailData.senderContact.senderName ? notificationDetailData.senderContact.senderName : 'Healthy Church'}
                                    </Header>
                                    <span className="color-static font-size-xxsmall">
                                        {moment.unix(notificationDetailData.createDate, 'YYYYMMDD').calendar()}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

QuickNotificationDetail.propTypes = propTypes;

export default withRouter(connect(mapStateToProps)(QuickNotificationDetail));
