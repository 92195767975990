/**
 * This enumeration contains Designation Filter by types
 */
enum DesignationFilterTypeId {
    General = 'designation_filter--type_general',
    Fund = 'designation_filter--type_fund',
    Project = 'designation_filter--type_project',
}

export default DesignationFilterTypeId;
