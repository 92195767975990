import { combineReducers } from 'redux';
import details from './detailsDrawer/detailsDrawer.reducer';
import filters from './listPage/filters.reducer';
import giftEditor from './giftEditor/giftEditor.reducer';

const reducers = combineReducers({
    details,
    filters,
    giftEditor,
});

export default reducers;
