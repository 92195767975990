import { combineReducers } from 'redux';
import designationEditor from './designationEditor/designationEditor.reducer';
import filters from './listPage/filters.reducer';

const reducers = combineReducers({
    designationEditor,
    filters,
});

export default reducers;
