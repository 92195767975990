import _ from 'lodash';

export const DEFAULT_STATE = {
    personSubscriptions: [],
    personSubscriptionsView: [],
    masterSubscriptions: [],
};

const classSegmentId = -1;
const smallGroupSegmentId = -2;
const MANAGED_SEGMENT_METADATA =
  // groups of segment IDs / totally Saddleback specific
  // TODO: externalize this data (to the database or configuration)
  [
      {
          GroupName: 'Communications',
          SegmentIds: [smallGroupSegmentId, classSegmentId, 1149172, 1196108, 1217866],
      },
      {
          GroupName: 'Campus e-News',
          SegmentIds: [1162163, 1162164, 1162165, 1136307, 1136293, 1162129, 1136309, 1162156, 1162161, 1160929, 1162162, 1168139, 1168131, 1177451, 1214658, 1244218, 1262077, 1489903, 1516827, 1136311, 1200532, 1774560, 1773425],
      },
      {
          GroupName: 'Ministry',
          SegmentIds: [1160773, 1163139, 1197974, 1161463, 1167413, 1216127, 1216128, 1229088, 1226309, 1242413],
      },
      {
          GroupName: 'Others',
          SegmentIds: [],
      },
  ];

function _getSegmentsByGroup(masterSubscriptions, personSubscriptions) {
    // given the "managed" segments, the currently subscribed segments, and the group metadata,
    // construct a list of segments by group
    // group/segments/isSubscribed flag
    if (!masterSubscriptions) return null;

    const masterSubs = _.cloneDeep(masterSubscriptions);

    const combinedSegmentIds = MANAGED_SEGMENT_METADATA.map((x) => x.SegmentIds).reduce((acc, c) => acc.concat(c));

    const allSubscriptionIds = personSubscriptions.map((x) => x.id);

    // 2. break out the segments into the category lists
    const segmentsGrouped = MANAGED_SEGMENT_METADATA.map((segmentGroup) => {
        let filteredSegments = [];

        if (segmentGroup.GroupName == 'Others') {
        // add all of the others go in here (these are subscribed already)
            filteredSegments = personSubscriptions.filter((segment) => {
                if (combinedSegmentIds.indexOf(segment.id) == -1) {
                    segment.isSubscribed = true;
                    return segment;
                }
            });
        } else {
            filteredSegments = masterSubs.filter((segment) => {
                if (segmentGroup.SegmentIds.indexOf(segment.id) > -1) {
                    segment.isSubscribed = (allSubscriptionIds.indexOf(segment.id) > -1);
                    return segment;
                }
            });
        }

        return {
            GroupName: segmentGroup.GroupName,
            Segments: filteredSegments,
        };
    });

    return segmentsGrouped;
}

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'SubscriptionActions.RESET':
            return {
                ...state,
                personSubscriptions: [],
                personSubscriptionsView: [],
            };
        case 'SubscriptionActions.ON_GETPERSONSUBSCRIPTIONS':
            return {
                ...state,
                personSubscriptions: action.result,
                personSubscriptionsView: action.result.map((x) => ({
                    email: x.email,
                    subscriptions: _getSegmentsByGroup(state.masterSubscriptions, x.subscriptions),
                })),
            };
        case 'SubscriptionActions.ON_GETMANAGEDSUBSCRIPTIONS':
            return { ...state, masterSubscriptions: action.result };
        case 'SubscriptionActions.ON_SAVE':
            console.log('SubscriptionActions.ON_SAVE', action.callbackParams);
            return { ...state, list: state.list.concat([action.result]) };
    }
}
