import _ from 'lodash';
import moment from 'moment-timezone';
import { dateUtils } from '@saddlebackchurch/react-cm-ui';
import EnumerationStore from '../js/stores/EnumerationStore.js';
import UserPreferenceStore from '../js/stores/User/UserPreferenceStore.js';

const DEFAULT_TIME_ZONE_ID = 'America/Los_Angeles';

// TODO/FIXME:
// * Consolidate me with React CM UI's `DateUtils`
//     Most "common" or "shared" Date utility functions should be defined in
//     React CM UI's `DateUtils` class and imported and used in this app.
//     This class should only provide "extensions" that are specific to the
//     Healthy Church Back Office application.
// * Better l10n / i18n support for formatting Date/Time values
//     Instead of hard-coding patterns, define generic patterns
//     (long date, short date, etc.) and ensure we "do the right thing" for each
//     per the user's locale.  We want something sorta similar to what the .NET
//     framework does in this respect:
//     https://docs.microsoft.com/en-us/dotnet/standard/base-types/standard-date-and-time-format-strings
//     We want to let moment do most of the work here for us; see:
//     https://momentjs.com/docs/#/displaying/format/ ("Localized Formats" section)
// * Make this class (or the React CM UI one) the goto hub for Date/Time formatting!
//    Do not do any formatting in specific components! (unless there is a
//    _super compelling_ case for it)
//    Instead, centralize the various formatting functions in one shared spot
//    and reuse them!  That way we stay D.R.Y. and consistent!
export default class DateUtils {
    static getDefaultTimeZone(churchSettings) {
        const timeZones = EnumerationStore.getTimeZonesSelect();
        const timeZoneId =
            churchSettings && churchSettings.timeZone ?
                churchSettings.timeZone :
                DateUtils.getPreferredTimeZone() || DEFAULT_TIME_ZONE_ID;

        const timeZoneOption =
            _.find(timeZones, (v) => v.value === timeZoneId) ||
            _.find(timeZones, (v) => v.value === DEFAULT_TIME_ZONE_ID);

        return timeZoneOption;
    }

    // HC DateUtils Extention Candidate
    static getPreferredTimeZone() {
        return UserPreferenceStore.getTimeZoneId() || dateUtils.getDetectedTimeZone();
    }

    /**
     * TODO: Remove - in react-cm-ui's DateUtils
     * This function is used in a lots of different areas (86, i believe) throughout the webapp.
     */
    static format(data) {
        if (_.isNil(data)) {
            return null;
        }

        if (_.isNumber(data)) {
            return moment.unix(data).utc().format('L');
        }
        return moment.utc(data, 'YYYY-MM-DDTHH:mm:ss').format('L');
    }

    // HC DateUtils Extention Candidate
    static logMoment(label, momentObj, userTimeZone) {
        const timeZone = userTimeZone || dateUtils.getDetectedTimeZone();
        console.log(label); // eslint-disable-line no-console
        console.log('  Moment Obj:', momentObj); // eslint-disable-line no-console
        console.log(`       Local: ${momentObj ? moment(momentObj).tz(timeZone).format('dddd, MMMM Do YYYY, h:mm:ss a zz') : null}`); // eslint-disable-line no-console
        console.log(`         UTC: ${momentObj ? moment(momentObj).utc().format('dddd, MMMM Do YYYY, h:mm:ss a zz') : null}`); // eslint-disable-line no-console
        console.log(`        UNIX: ${momentObj ? moment(momentObj).utc().unix() : null}`); // eslint-disable-line no-console
    }

    // HC DateUtils Extention Candidate
    static logTimeStamp(label, timestamp, userTimeZone) {
        const timeZone = userTimeZone || dateUtils.getPreferredTimeZone();
        console.log(label); // eslint-disable-line no-console
        console.log(`    Raw/UNIX: ${timestamp}`); // eslint-disable-line no-console
        console.log(`       Local: ${timestamp ? moment.unix(timestamp).tz(timeZone).format('dddd, MMMM Do YYYY, h:mm:ss a zz') : null}`); // eslint-disable-line no-console
        console.log(`         UTC: ${timestamp ? moment.unix(timestamp).utc().format('dddd, MMMM Do YYYY, h:mm:ss a zz') : null}`); // eslint-disable-line no-console
    }
}
