import {
    cloneDeep,
    forEach,
    keyBy,
    remove,
    some,
} from 'lodash';

export const DEFAULT_STATE = {};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'VenueCacheActions.ON_RESET':
            if (action.id) {
                const newState = cloneDeep(state);
                delete newState[action.id];
                return newState;
            }

            return DEFAULT_STATE;

        case 'VenueCacheActions.ON_GETALLBYCAMPUS':
        case 'VenueCacheActions.ON_GET': {
            forEach(action.result, (v) => {
                if (some(v.subVenues, (s) => s.subVenueTypeId === 1)) {
                    remove(v.subVenues, (s) => s.subVenueTypeId !== 1);
                }

                // TODO: Debug why this is needed and/or why was it done this way
                // eslint-disable-next-line no-param-reassign
                v.venueId = v.id;
            });

            const venuesById = keyBy(action.result, 'id');

            return {
                ...state,
                ...venuesById,
            };
        }

        default:
            return state;
    }
}
