import '../../scss/components/Icon.scss';

import {
    includes,
} from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class Icon extends React.Component {
    render() {
        const {
            className, color, size, style, type,
        } = this.props;
        let iconType;

        switch (type) {
            case 'accordion':
                iconType = 'accordion';
                break;
            case 'add':
                iconType = 'add';
                break;
            case 'attach':
                iconType = 'attach';
                break;
            case 'back':
                iconType = 'arrow-left';
                break;
            case 'cancel':
            case 'removal':
                iconType = 'cancel';
                break;
            case 'change':
                iconType = 'change';
                break;
            case 'edit':
                iconType = 'edit-pencil';
                break;
            case 'help':
                iconType = 'help';
                break;
            case 'arrow-right':
            case 'forward':
                iconType = 'arrow-right';
                break;
            case 'link':
                iconType = 'link';
                break;
            case 'lock':
                iconType = 'lock';
                break;
            case 'notes':
                iconType = 'comment';
                break;
            case 'person-add':
                iconType = 'person-add';
                break;
            case 'close':
                iconType = 'close';
                break;
            case 'save':
                iconType = 'save';
                break;
            case 'sent':
                iconType = 'sent';
                break;
            case 'static':
                iconType = 'static';
                break;
            case 'success':
                iconType = 'check-2';
                break;
            case 'upload':
                iconType = 'upload';
                break;
            case 'phone-2':
                iconType = 'phone-2';
                break;
            case 'trash':
                iconType = 'trash';
                break;
            case 'unlock':
                iconType = 'unlock';
                break;
            case 'view':
                iconType = 'view';
                break;
            case 'new-close':
                iconType = 'new-close';
                break;
            case 'new-done':
            case 'new-closed':
                iconType = 'new-closed';
                break;
            case 'new-block':
            case 'new-blocked':
                iconType = 'new-blocked';
                break;
            case 'new-email':
                iconType = 'new-email';
                break;
            case 'new-fullscreen':
                iconType = 'new-fullscreen';
                break;
            case 'new-reassigned':
            case 'new-reassign':
                iconType = 'new-reassign';
                break;
            case 'new-address':
                iconType = 'new-address';
                break;
            case 'new-record':
                iconType = 'new-record';
                break;
            case 'new-folder':
                iconType = 'new-folder';
                break;
            case 'new-open':
            case 'new-opened':
                iconType = 'new-open';
                break;
            case 'new-activity':
                iconType = 'new-activity';
                break;
            case 'new-content':
                iconType = 'new-content';
                break;
            case 'new-star':
                iconType = 'new-star';
                break;
            case 'new-message':
                iconType = 'new-message';
                break;
            case 'new-download':
                iconType = 'new-download';
                break;
            case 'new-warning':
                iconType = 'new-warning';
                break;
            case 'new-workflow':
                iconType = 'new-workflow';
                break;
            case 'new-time':
                iconType = 'new-time';
                break;
            case 'new-chevron-right':
                iconType = 'new-chevron-right';
                break;
            case 'new-chevron-left':
                iconType = 'new-chevron-left';
                break;
            case 'new-mention':
                iconType = 'new-mention';
                break;
            case 'new-more':
                iconType = 'new-more';
                break;
            case 'new-search':
                iconType = 'new-search';
                break;
            case 'new-notification':
                iconType = 'new-notification';
                break;
            case 'new-select-arrow':
                iconType = 'new-select-arrow';
                break;
            case 'new-cards':
                iconType = 'new-cards';
                break;
            case 'new-todo':
            case 'new-task':
            case 'new-check':
                iconType = 'new-check';
                break;
            case 'checkbox':
                iconType = 'checkbox';
                break;
            default:
                console.warn(`Icon.react.js was provided with a wrong type property, "${type}". Please correct this in order for the component to work properly.`);
                break;
        }

        const iconSize = size ? `old-icon-size-${size}` : null;

        const iconClasses = ClassNames(`old-icon-${iconType}`, iconSize, className, {
            'old-icon-color-alert': includes(color, 'alert'),
            'old-icon-color-blocked': includes(color, 'blocked'),
            'old-icon-color-message': includes(color, 'message') || includes(color, 'comment'),
            'old-icon-color-closed': includes(color, 'closed'),
            'old-icon-color-mention': includes(color, 'mention'),
            'old-icon-color-notification': includes(color, 'notification'),
            'old-icon-color-static': includes(color, 'static'),
            'old-icon-color-secondary': includes(color, 'secondary'),
            'old-icon-color-success': includes(color, 'success'),
            'old-icon-color-primary': includes(color, 'primary'),
            'old-icon-color-dark': includes(color, 'dark'),
            'old-icon-color-old-primary': includes(color, 'text'),
            'old-icon-color-todo': includes(color, 'todo') || includes(color, 'task') || includes(color, 'open'),
            'old-icon-color-reassign': includes(color, 'reassign'),
            'old-icon-color-warning': includes(color, 'warning'),
            'old-icon-color-inverse': includes(color, 'inverse'),
        });

        return (
            <i className={iconClasses} style={style} />
        );
    }
}

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
};
