const DEFAULT_STATE = {
    result: {},
};

const download = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'CommonExportActions.EXPORT_COMPLETED':
        case 'CommonExportActions.EXPORT_FAILED':
        case 'CommonExportActions.EXPORT_INITIATED': {
            const { result } = action;
            console.log(`Received export completion event in reducer (Type: '${result.type}')`); // eslint-disable-line no-console
            // note: clear out claim check so future signalr reconnects don't try to use it
            const updatedState = { ...state, result };
            console.log('Updated state:', updatedState, action); // eslint-disable-line no-console
            return updatedState;
        }

        case 'CommonExportActions.ON_RESETEXPORT':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default download;
