import { combineReducers } from 'redux';
import attendee from './attendee';
import attendeeAttendance from './attendeeAttendance';
import attendeeExisting from './attendeeExisting';
import attendeeNotes from './attendeeNotes';
import attendeeOccurrences from './attendeeOccurrences';
import attendees from './attendees';
import attendeeStatistics from './attendeeStatistics';

const reducers = combineReducers({
    attendee,
    attendeeAttendance,
    attendeeExisting,
    attendeeNotes,
    attendeeOccurrences,
    attendeeStatistics,
    attendees,
});

export default reducers;
