import _ from 'lodash';
import ChunkedPaginationUtils from '../global/chunkedPaginationUtils.js';

const _localCache = new ChunkedPaginationUtils();
const DEFAULT_STATE = {
    canLoadMore: false,
    counts: {},
    isFetching: false,
    isDetailFetching: false,
    needsToRequest: false,
    pageNumber: 0,
    total: 0,
    notifications: [],
    discipleship: {},
    ministry: {},
    workflow: {},
    system: {},
    events: {},
    summary: {},
    notificationDetail: {},
    pageSize: _localCache.getPageSize(),
    searchQuery: '',
    sortBy: 'createdate,DESC',
    sourceCategoryFilter: '',
    timeline: 0,
    notificationUnreadCount: 0,
};

const quickNotification = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'QuickNotificationActions.ON_BEFORE_GETUSERNOTIFICATIONS':
            return { ...state, isFetching: true };

        case 'QuickNotificationActions.ON_GETUSERNOTIFICATIONS':
            const data = action.result;
            const { counts } = data;

            if (_.isNil(counts)) {
                return state;
            }

            _localCache.loadPage(data.notifications, counts.summary.total, action.callbackParams.first);
            return {
                ...state,
                canLoadMore: _localCache.canLoadMore(),
                pageNumber: _localCache.getCurrentPageNumber(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageSize: _localCache.getPageSize(),
                isFetching: false,
                sourceCategoryFilter: action.params.category,
                sortBy: action.params.sortBy,
                total: _localCache.getTotalCount(),
                timeline: action.params.days,
                notifications: _localCache.getAll(true),
                discipleship: counts.discipleship,
                counts,
                notificationUnreadCount: counts.summary.total,
            };

        case 'QuickNotificationActions.ON_GETNOTIFICATION_NEXTPAGE':
            return {
                ...state,
                isFetching: false,
                canLoadMore: _localCache.canLoadMore(),
                pageNumber: _localCache.getCurrentPageNumber(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageSize: _localCache.getPageSize(),
                total: _localCache.getTotalCount(),
                notifications: _localCache.getAll(true),
            };

        case 'NotificationCenterDetailActions.ON_BEFORE_GETNOTIFICATIONDETAIL':
            return {
                ...state,
                isDetailFetching: true,
                notificationDetail: {},
            };
        case 'NotificationCenterDetailActions.ON_RESET_NOTIFICATIONDETAIL':
            return { ...state, notificationDetail: {} };

        case 'NotificationCenterDetailActions.ON_GETNOTIFICATIONDETAIL':
            return {
                ...state,
                isDetailFetching: false,
                notificationDetail: action.result,
            };
        case 'QuickNotificationActions.ON_UPDATE_UNREAD_NOTIFICATION_COUNT':
            return { ...state, notificationUnreadCount: action.unreadCount };

        default:
            return state;
    }
};

export default quickNotification;
