import {
    cmAuthUtils,
} from '../../../global/utils/utils.js';
import LoginConstants from '../../constants/Login/LoginConstants.js';

const DEFAULT_STATE = {
    isLoggedIn: cmAuthUtils.isLoggedIn(), // eslint-disable-line no-undef
    nextPage: '',
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT:
        case LoginConstants.LOGIN_FAILED:
            return { ...state, isLoggedIn: false };

        case LoginConstants.LOGIN_SUCCEEDED:
            return { ...state, isLoggedIn: true };

        case LoginConstants.LOGIN_NEXT_PAGE:
            return { ...state, nextPage: action.nextPage };

        default:
    }
    return state;
}
