import request from 'axios';
import { Reminder } from '../../global/models';
import EventsApiUtils from '../../../global/eventsApiUtils.js';

type AttachBodyParams = object;

class EventReminderActions extends EventsApiUtils {
    add: (
        params: object | null,
        bodyParams: Reminder,
    ) => Promise<void>;

    attach: (params: object | null, bodyParams: AttachBodyParams) => Promise<{
        url: string;
    }>;

    delete: (
        params: {
            id: number;
        },
        bodyParams?: Reminder,
    ) => Promise<void>;

    getRemindersByOccurrence: (
        param: {
            occurrenceId: number;
        },
    ) => Promise<Reminder[]>;

    getRemindersBySchedule: (
        param: {
            scheduleId: number;
        },
    ) => Promise<Reminder[]>;

    getSmsVariables: () => Promise<void>;

    update: (
        params: object | null,
        bodyParams: Reminder,
    ) => Promise<void>;

    constructor(url) {
        super(url);

        Object.defineProperty(this, 'SO_SCHEDULE_ENTITY_TYPE_ID', {
            value: 2,
            writable: false,
            enumerable: false,
            configurable: false,
        });

        Object.defineProperty(this, 'EVENT_SCHEDULE_ENTITY_TYPE_ID', {
            value: 3,
            writable: false,
            enumerable: false,
            configurable: false,
        });
    }

    getURL() {
        const url = `${this.baseUrl}attachment`;
        return url;
    }

    async requestAttach(params: object | null, bodyParams: AttachBodyParams) {
        const results = await this.attach(params, bodyParams);

        return results;
    }
}

const actions = new EventReminderActions('/api/reminder/');

actions.buildReduxActionClass(
    {
        name: 'getEmailVariables',
        rq: request.get,
        route: 'email-variables?scheduleEntityTypeId={scheduleEntityTypeId}',
    }, {
        name: 'getRemindersByOccurrence',
        rq: request.get,
        route: 'occurrence/{occurrenceId}?includeRecurring=true',
    }, {
        name: 'getRemindersBySchedule',
        rq: request.get,
        route: 'schedule/{scheduleId}',
    }, {
        name: 'getSmsVariables',
        rq: request.get,
        route: 'sms-variables',
    }, {
        name: 'add',
        rq: request.post,
        route: '',
        suppressError: true,
    }, {
        name: 'update',
        rq: request.put,
        route: '{id}',
        suppressError: true,
    }, {
        name: 'delete',
        rq: request.delete,
        route: '{id}',
    }, {
        name: 'attach',
        rq: request.post,
        contentType: 'multipart/form-data',
        route: 'attachment',
    }, {
        name: 'get',
        rq: request.get,
        route: '{id}',
    }, {
        name: 'getReminders',
        rq: request.get,
        route: '',
        suppressError: true,
    },
);

export default actions;
