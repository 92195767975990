import {
    includes,
    isEmpty,
} from 'lodash';
import validator from 'validator';

const DEFAULT_STATE = {
    data: {
        address: {
            address1: '',
            address2: '',
            city: '',
            isAddressValid: false,
            isPostalCodeValid: false,
            postalCode: null,
            isPostalCodeRequired: true,
            country: 'United States of America',
            countryAlpha2: 'US',
            countryAlpha3: 'USA',
            region: '',
            regionCode: '',
        },
        email: {
            value: '',
            isEmailValid: false,
        },
        phone: {
            countryCode: '',
            displayPhoneNumber: '',
            extension: null,
            isHome: true,
            isMobile: false,
            isPhoneValid: false,
            isWork: false,
            phoneNumber: null,
            phoneType: 'Home Phone',
            phoneTypeId: 1,
        },
        isRequirementFullfilled: false,
    },
};

function checkValidity(
    hasAddresssValues,
    isAddressValid,
    emailValue,
    isEmailValid,
    numberValue,
    isPhoneValid,
) {
    if (hasAddresssValues && !!emailValue && !!numberValue) {
        return isAddressValid && isEmailValid && isPhoneValid;
    }

    if (hasAddresssValues && !emailValue && !!numberValue) {
        return isAddressValid && isPhoneValid;
    }

    if (hasAddresssValues && !!emailValue && !numberValue) {
        return isAddressValid && isEmailValid;
    }

    if (!hasAddresssValues && !!emailValue && !!numberValue) {
        return isEmailValid && isPhoneValid;
    }

    if (!hasAddresssValues && !emailValue && !!numberValue) {
        return isPhoneValid;
    }

    if (!hasAddresssValues && !!emailValue && !numberValue) {
        return isEmailValid;
    }

    return isAddressValid;
}

export default (state = DEFAULT_STATE, action) => {
    const actionType = 'ContactFormActions';

    const {
        address,
        email,
        phone,
    } = state.data;

    const {
        address1,
        city,
        country,
        countryAlpha2,
        postalCode,
        isPostalCodeRequired,
        regionCode,
    } = address;

    const {
        value: emailValue,
    } = email;

    const {
        phoneNumber,
    } = phone;

    switch (action.type) {
        case `${actionType}.ON_RESET_DATA`: {
            return {
                ...state,
                data: {
                    ...DEFAULT_STATE.data,
                },
            };
        }

        case `${actionType}.ON_UPDATE_ADDRESS_1`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;

            const isAddressValid = !!city &&
                !!country &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!regionCode &&
                !!action.result;

            const hasAddresssValues = !!city ||
                !!postalCode ||
                !!regionCode ||
                !!action.result;

            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                phoneNumber,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        address1: action.result,
                        isAddressValid,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_ADDRESS_2`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        address2: action.result,
                    },
                },
            };
        }

        case `${actionType}.ON_UPDATE_CITY`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;

            const isAddressValid = !!address1 &&
                !!country &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!regionCode &&
                !!action.result;

            const hasAddresssValues = !!address1 ||
                !!postalCode ||
                !!regionCode ||
                !!action.result;

            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                phoneNumber,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        city: action.result,
                        isAddressValid,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_CONTACT_DATA`: {
            const {
                address: {
                    address1: address1Value,
                    address2: address2Value,
                    city: cityValue,
                    postalCode: postalCodeValue,
                    country: countryValue,
                    countryAlpha2: countryAlpha2Value,
                    countryAlpha3: countryAlpha3Value,
                    region: regionValue,
                    regionCode: regionCodeValue,
                },
                email: newEmailValue,
                phone: {
                    countryCode,
                    displayPhoneNumber,
                    extension,
                    isHome,
                    isMobile,
                    isValid,
                    isWork,
                    phoneNumber: newPhoneNumber,
                    phoneType,
                    phoneTypeId,
                },
            } = action.result;

            const newIsEmailValid = !!newEmailValue && validator.isEmail(newEmailValue);
            const newIsPhoneValid = !!newPhoneNumber && isValid;

            let newIsPostalCodeValid = true;
            let newIsPostalCodeRequired = false;

            if (!isEmpty(postalCodeValue) &&
                includes(validator.isPostalCodeLocales, countryAlpha2Value)) {
                newIsPostalCodeValid = validator.isPostalCode(postalCodeValue, countryAlpha2Value);
                newIsPostalCodeRequired = true;
            }

            const isAddressValid = !!cityValue &&
                !!countryAlpha3Value &&
                (
                    (isPostalCodeRequired && !!postalCodeValue) ||
                    (!isPostalCodeRequired)
                ) &&
                !!regionCodeValue &&
                !!address1Value;

            const hasAddresssValues = !!address1Value ||
                !!cityValue ||
                !!postalCodeValue ||
                !!regionValue ||
                !!countryAlpha3Value;

            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                newEmailValue,
                newIsEmailValid,
                newPhoneNumber,
                newIsPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    email: {
                        ...state.data.email,
                        isEmailValid: newIsEmailValid,
                        value: newEmailValue,
                    },
                    phone: {
                        ...state.data.phone,
                        countryCode,
                        displayPhoneNumber,
                        extension,
                        phoneNumber: newPhoneNumber,
                        isPhoneValid: newIsPhoneValid,
                        isHome,
                        isMobile,
                        isWork,
                        phoneType,
                        phoneTypeId,
                    },
                    address: {
                        address1: address1Value,
                        address2: address2Value,
                        city: cityValue,
                        country: countryValue,
                        countryAlpha2: countryAlpha2Value,
                        countryAlpha3: countryAlpha3Value,
                        isPostalCodeValid: newIsPostalCodeValid,
                        isPostalCodeRequired: newIsPostalCodeRequired,
                        isAddressValid,
                        postalCode: postalCodeValue,
                        region: regionValue,
                        regionCode: regionCodeValue,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_COUNTRY`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;
            const newCountry = (
                action.result && action.result.label
            ) || '';
            const newCountryAlpha2 = (
                action.result && action.result.code
            ) || '';
            const newCountryAlpha3 = (
                action.result && action.result.value
            ) || '';
            const isAddressValid = !!address1 &&
                !!city &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!regionCode &&
                !!newCountry;
            const hasAddresssValues = !!address1 ||
                !!city ||
                !!postalCode ||
                !!regionCode ||
                !!newCountry;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                phoneNumber,
                isPhoneValid,
            );
            let newIsPostalCodeRequired = false;

            if (includes(validator.isPostalCodeLocales, newCountryAlpha2)) {
                newIsPostalCodeRequired = true;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        country: newCountry,
                        countryAlpha2: newCountryAlpha2,
                        countryAlpha3: newCountryAlpha3,
                        isAddressValid,
                        isPostalCodeRequired: newIsPostalCodeRequired,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_EMAIL`: {
            const { isAddressValid } = address;
            const { isPhoneValid } = phone;
            const { result: newEmailValue } = action;
            const newIsEmailValid = !!newEmailValue && validator.isEmail(newEmailValue);
            const hasAddresssValues = !!address1 ||
                !!city ||
                !!postalCode ||
                !!regionCode;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                newEmailValue,
                newIsEmailValid,
                phoneNumber,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    email: {
                        ...state.data.email,
                        isEmailValid: newIsEmailValid,
                        value: newEmailValue,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_PHONE`: {
            const { isAddressValid } = address;
            const { isEmailValid } = email;

            const {
                countryCode,
                displayPhoneNumber,
                phoneNumber: newPhoneNumber,
            } = action.result;
            const newIsPhoneValid = !!newPhoneNumber && action.result.isValid;
            const hasAddresssValues = !!address1 ||
                !!city ||
                !!postalCode ||
                !!regionCode;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                newPhoneNumber,
                newIsPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    phone: {
                        ...state.data.phone,
                        countryCode,
                        displayPhoneNumber,
                        phoneNumber: newPhoneNumber,
                        isPhoneValid: newIsPhoneValid,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_PHONE_TYPE`: {
            let newIsHome;
            let newIsMobile;
            let newIsWork;
            let newPhoneType;
            let newPhoneTypeId;

            if (action.result.isHome) {
                newIsHome = true;
                newIsMobile = false;
                newIsWork = false;
                newPhoneType = DEFAULT_STATE.data.phone.phoneType;
                newPhoneTypeId = DEFAULT_STATE.data.phone.phoneTypeId;
            }

            if (action.result.isMobile) {
                newIsHome = false;
                newIsMobile = true;
                newIsWork = false;
                newPhoneType = 'Cell Phone';
                newPhoneTypeId = 3;
            }

            if (action.result.isWork) {
                newIsHome = false;
                newIsMobile = false;
                newIsWork = true;
                newPhoneType = 'Work Phone';
                newPhoneTypeId = 2;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    phone: {
                        ...state.data.phone,
                        isHome: newIsHome,
                        isMobile: newIsMobile,
                        isWork: newIsWork,
                        phoneType: newPhoneType,
                        phoneTypeId: newPhoneTypeId,
                    },
                },
            };
        }

        case `${actionType}.ON_UPDATE_PHONE_EXTENSION`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    phone: {
                        ...state.data.phone,
                        extension: action.result,
                    },
                },
            };
        }

        case `${actionType}.ON_UPDATE_POSTAL_CODE`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;
            let newIsPostalCodeValid = true;
            let newIsPostalCodeRequired = false;

            if (!isEmpty(action.result) &&
                includes(validator.isPostalCodeLocales, countryAlpha2)) {
                newIsPostalCodeValid = validator.isPostalCode(action.result, countryAlpha2);
                newIsPostalCodeRequired = true;
            }

            const isAddressValid = !!address1 &&
                !!city &&
                !!country &&
                !!regionCode &&
                newIsPostalCodeValid;

            const hasAddresssValues = !!address1 ||
                !!city ||
                !!regionCode ||
                !!action.result;

            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                phoneNumber,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        isAddressValid,
                        isPostalCodeValid: newIsPostalCodeValid,
                        postalCode: action.result,
                        isPostalCodeRequired: newIsPostalCodeRequired,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_REGION`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;

            const newRegion = (
                action.result && action.result.label
            ) || DEFAULT_STATE.data.address.region;

            const newRegionCode = (
                action.result && action.result.value
            ) || DEFAULT_STATE.data.address.regionCode;

            const isAddressValid = !!address1 &&
                !!city &&
                !!country &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!newRegion;

            const hasAddresssValues = !!address1 ||
                !!city ||
                !!postalCode ||
                !!newRegion;

            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                phoneNumber,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        isAddressValid,
                        region: newRegion,
                        regionCode: newRegionCode,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        default:
            return state;
    }
};
