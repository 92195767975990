import { CommunicationsTemplate } from '../../../../communicationsCentral/models';

const DEFAULT_STATE = {
    followUpTaskPushNotificationTemplates: [],
    isFetching: false,
};

const followUpTaskPushNotificationTemplates = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'FollowUpTaskPushNotificationTemplateActions.ON_GETPUSHNOTIFICATIONTEMPLATES': {
            const taskNotificationTemplates =
            action.result?.communicationTemplates?.map((template: CommunicationsTemplate) => ({
                value: template.id,
                label: template.name,
            }));

            return {
                ...state,
                followUpTaskPushNotificationTemplates: taskNotificationTemplates,
                isFetching: DEFAULT_STATE.isFetching,
            };
        }

        case 'FollowUpTaskPushNotificationTemplateActions.ON_BEFORE_GETPUSHNOTIFICATIONTEMPLATES': {
            return {
                ...state,
                isFetching: true,
            };
        }

        default:
            return state;
    }
};

export default followUpTaskPushNotificationTemplates;
