import {
    noop,
} from 'lodash';
import {
    Icon,
    PersonPanel,
    PersonPanelDetails,
    PersonPanelSummary,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import {
    makeStyles,
} from '@saddlebackchurch/react-cm-ui/core/styles'; // eslint-disable-line import/extensions
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { personPropTypes } from '../record/global/personPropTypes.js';
import { coreMilestonesDefaultProps, coreMilestonesPropTypes } from '../record/global/coreMilestonesPropTypes.js';
import { i18n } from '../../global/constants.js';
import { MIN_RELEVANCE_THRESHOLD } from './constants.js';
import { getModifiedDataForPersonPanel } from './personPanelUtils.js';

const propTypes = {
    coreMilestonesData: coreMilestonesPropTypes,
    isExclamationColumnVisible: PropTypes.bool,
    isMatchPercentageVisible: PropTypes.bool,
    isPrimaryInfoOnlyVisible: PropTypes.bool,
    isSelectRecordVisible: PropTypes.bool,
    isViewRecordVisible: PropTypes.bool,
    onSelect: PropTypes.func,
    otherDataGroups: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.func,
    ]),
    person: personPropTypes,
    promptSelectRecord: PropTypes.bool,
    promptViewRecord: PropTypes.bool,
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    selectRecordButtonDisable: PropTypes.bool,
    selectRecordButtonLabel: PropTypes.string,
};

const defaultProps = {
    coreMilestonesData: coreMilestonesDefaultProps,
    isExclamationColumnVisible: false,
    isMatchPercentageVisible: false,
    isPrimaryInfoOnlyVisible: false,
    isSelectRecordVisible: false,
    isViewRecordVisible: false,
    onSelect: noop,
    otherDataGroups: null,
    person: {},
    promptSelectRecord: true,
    promptViewRecord: true,
    selectRecordButtonDisable: false,
    selectRecordButtonLabel: null,
};

const useStyles = makeStyles((theme) => {
    const rightColumnWidth = 27;
    const matchColumnWidth = 50;
    const rightColumnMobileWidth = 30;

    return {
        rightColumn: ({
            isMatchPercentageVisible,
        }) => ({
            alignItems: 'center',
            display: 'flex',
            paddingLeft: '11px',
            width: isMatchPercentageVisible ? matchColumnWidth : rightColumnWidth,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '8px',
                width: isMatchPercentageVisible ? rightColumnMobileWidth : rightColumnWidth,
            },
        }),
        personPanelDetails: ({
            isExclamationColumnVisible,
            isMatchPercentageVisible,
        }) => {
            let margin = 0;
            let width = '100%';

            if (isExclamationColumnVisible) {
                margin = `0 ${rightColumnWidth}px 0 0`;
                width = `calc(100% - ${rightColumnWidth}px)`;
            }

            if (isMatchPercentageVisible) {
                margin = `0 ${matchColumnWidth}px 0 0`;
                width = `calc(100% - ${matchColumnWidth}px)`;
            }

            if (theme.breakpoints.down('sm')) {
                if (isMatchPercentageVisible) {
                    margin = `0 ${rightColumnMobileWidth}px 0 0`;
                    width = `calc(100% - ${rightColumnMobileWidth}px)`;
                }
            }

            return {
                margin,
                width,
            };
        },
        personPanelSummary: ({
            isExclamationColumnVisible,
            isMatchPercentageVisible,
        }) => {
            let flexBasis = '100';

            if (isExclamationColumnVisible) {
                flexBasis = `calc(100% - ${rightColumnWidth}px)`;
            }

            if (isMatchPercentageVisible) {
                flexBasis = `calc(100% - ${matchColumnWidth}px)`;
            }

            return {
                flexBasis,
            };
        },
        root: {},
        successText: {
            color: theme.palette.success.main,
        },
    };
});

function PersonPanelAccordionItem(props) {
    const {
        coreMilestonesData,
        isExclamationColumnVisible,
        isPrimaryInfoOnlyVisible,
        isSelectRecordVisible,
        isViewRecordVisible,
        isMatchPercentageVisible,
        onSelect,
        otherDataGroups,
        person,
        promptSelectRecord,
        promptViewRecord,
        selectRecordButtonDisable,
        selectRecordButtonLabel,
    } = props;

    const classes = useStyles(props);

    const personPanelData = getModifiedDataForPersonPanel(
        person,
        coreMilestonesData,
        isPrimaryInfoOnlyVisible,
    );

    const {
        detailsData,
        summaryData,
    } = personPanelData;

    let relevanceMatchText;

    if (person.relevance >= MIN_RELEVANCE_THRESHOLD.best) {
        relevanceMatchText = i18n('BEST');
    } else if (person.relevance >= MIN_RELEVANCE_THRESHOLD.good) {
        relevanceMatchText = i18n('GOOD');
    } else if (person.relevance >= MIN_RELEVANCE_THRESHOLD.average) {
        relevanceMatchText = i18n('AVG');
    } else {
        relevanceMatchText = i18n('BAD');
    }

    const onSelectYesClick = (selectedPerson) => {
        onSelect(selectedPerson);
    };

    const onViewRecordYesClick = (personId) => {
        const {
            router,
        } = props;
        router.push(`/people/search-or-add/record/${personId}`);
    };

    const viewRecordProps = {
        ...(isViewRecordVisible && {
            viewRecordButtonProps: {
                id: 'person_panel--view_record_btn',
                prompt: promptViewRecord,
                onClick: promptViewRecord ? undefined : () => onViewRecordYesClick(person.id),
                onYesClick: () => onViewRecordYesClick(person.id),
            },
        }),
    };

    const selectRecordProps = {
        ...(isSelectRecordVisible && {
            selectButtonProps: {
                id: 'person_panel--select_record_btn',
                prompt: promptSelectRecord,
                onClick: promptSelectRecord ? undefined : () => onSelectYesClick(person),
                onYesClick: () => onSelectYesClick(person),
                disable: selectRecordButtonDisable,
                ...(selectRecordButtonLabel && {
                    label: selectRecordButtonLabel,
                }),
            },
        }),
    };

    return (
        <PersonPanel
            classes={{
                root: classes.root,
            }}
        >
            <PersonPanelSummary
                classes={{
                    root: classes.personPanelSummary,
                }}
                data={summaryData}
            />

            {isMatchPercentageVisible && (
                <div
                    className={classes.rightColumn}
                >
                    <Typography
                        className={classes.successText}
                    >
                        {relevanceMatchText}
                    </Typography>
                </div>
            )}

            {isExclamationColumnVisible && (
                <div
                    className={classes.rightColumn}
                >
                    {!person?.eligibility?.isEligible && (
                        <Icon
                            color="warning"
                            compact
                            type="exclamation"
                        />
                    )}
                </div>
            )}

            <PersonPanelDetails
                classes={{
                    root: classes.personPanelDetails,
                }}
                data={{
                    ...detailsData,
                    hasTakenClass101: detailsData.hasAttendedClass101,
                    hasTakenClass201: detailsData.hasAttendedClass201,
                    hasTakenClass301: detailsData.hasAttendedClass301,
                    hasTakenClass401: detailsData.hasAttendedClass401,
                }}
                otherDataGroups={otherDataGroups}
                {...selectRecordProps} // eslint-disable-line react/jsx-props-no-spreading
                {...viewRecordProps} // eslint-disable-line react/jsx-props-no-spreading
            />
        </PersonPanel>
    );
}

PersonPanelAccordionItem.propTypes = propTypes;
PersonPanelAccordionItem.defaultProps = defaultProps;

export default withRouter(PersonPanelAccordionItem);
