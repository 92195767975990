import {
    applyMiddleware,
    legacy_createStore as createStore,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    composeWithDevTools,
} from '@redux-devtools/extension';
import multi from 'redux-multi';
import rootReducer from '../reducers';
import {
    reduxTrackingMiddleware,
} from '../tools/analytics/index.js';
import {
    isCmLocalEnv,
    isCmLocalUiEnv,
    isCmProductionEnv,
} from './utils/environments.js';

const middlewares = [thunkMiddleware, reduxTrackingMiddleware, multi];

let enhancer;

if (isCmLocalEnv || isCmLocalUiEnv) {
    /**
     * Uncomment this in case, if you want to see the combined log of app and redux
     * const createLogger = require('redux-logger');
     * middlewares.push(createLogger());
     */

    enhancer = composeWithDevTools(
        applyMiddleware(...middlewares),
    );
} else {
    enhancer = applyMiddleware(...middlewares);
}

/**
 * Factory to create the Redux Store
 * @returns Redux Store
 */
export function configureReduxStore() {
    const store = createStore(rootReducer, enhancer);

    if (!isCmProductionEnv && module.hot) {
        module.hot.accept('../reducers.ts', () => {
            store.replaceReducer(rootReducer, enhancer);
        });
    }

    return store;
}

// eslint-disable-next-line import/prefer-default-export
export const appReduxStore = configureReduxStore();
