import { every, includes } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.element.isRequired,
    featuresEnabled: PropTypes.arrayOf(PropTypes.string).isRequired,
    requiredFeatures: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    requiredFeatures: [],
};

const mapStateToProps = (state) => {
    const { featureToggles } = state.bootstrap;

    return {
        featuresEnabled: featureToggles.getEnabledFeatureNames(),
    };
};

function Features(props) {
    const {
        children,
        featuresEnabled,
        requiredFeatures,
    } = props;

    if (every(requiredFeatures, (r) => includes(featuresEnabled, r))) {
        return children;
    }

    return null;
}

Features.propTypes = propTypes;
Features.defaultProps = defaultProps;

export default connect(mapStateToProps)(Features);
