const DEFAULT_STATE = {
    entries: {
        count: 0,
        data: [],
        isFetching: false,
    },
    entry: {
        data: {},
        isFetching: false,
    },
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'ConnectionFormEntriesByPersonActions.ON_BEFORE_GETENTRIESLIST':
            return {
                ...state,
                entries: {
                    ...state.entries,
                    isFetching: true,
                },
            };

        case 'ConnectionFormEntriesByPersonActions.ON_GETENTRIESLIST':
            return {
                ...state,
                entries: {
                    ...state.entries,
                    count: actionResult.resultCount,
                    data: actionResult.results,
                    isFetching: false,
                },
            };

        case 'ConnectionFormEntriesByPersonActions.ON_BEFORE_GETENTRY':
            return {
                ...state,
                entry: {
                    ...state.entry,
                    isFetching: true,
                },
            };

        case 'ConnectionFormEntriesByPersonActions.ON_GETENTRY':
            return {
                ...state,
                entry: {
                    ...state.entry,
                    data: actionResult,
                    isFetching: false,
                },
            };

        case 'ConnectionFormEntriesByPersonActions.RESET_ALL':
            return DEFAULT_STATE;

        case 'ConnectionFormEntriesByPersonActions.RESET_ENTRIES_LIST':
            return {
                ...state,
                entries: { ...DEFAULT_STATE.entries },
            };

        case 'ConnectionFormEntriesByPersonActions.RESET_SINGLE_ENTRY':
            return {
                ...state,
                entry: { ...DEFAULT_STATE.entry },
            };

        default:
            return state;
    }
};
