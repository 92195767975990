import _ from 'lodash';

export const DEFAULT_STATE = {};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'PersonCacheActions.ON_RESET':
            if (action.id) {
                const newState = _.cloneDeep(state);
                delete newState[action.id];
                return newState;
            }
            return DEFAULT_STATE;

        case 'PersonCacheActions.ON_BEFORE_GET':
            return {
                ...state,
                [action.params.id]: null,
            };

        case 'PersonCacheActions.ON_GET':
            return {
                ...state,
                [action.result.id]: action.result,
            };

        default:
            return state;
    }
}
