import addOrReplaceBy from '../../../global/utils/addOrReplaceBy';
import LoginConstants from '../../constants/Login/LoginConstants.js';
import NotificationBannerConstants from '../../constants/Notification/NotificationBannerConstants.js';
import PushNotificationConstants from '../../constants/Notification/PushNotificationConstants.js';
import notificationBanner, { DEFAULT_STATE as DEFAULT_STATE_NOTIFICATION_BANNER } from './notificationBanner.js';
import notificationPreferences, { DEFAULT_STATE as DEFAULT_STATE_NOTIFICATION_PREFERENCES } from './notificationPreferences.js';
import notificationSourceTypes, { DEFAULT_STATE as DEFAULT_STATE_NOTIFICATION_SOURCE_TYPES } from './notificationSourceTypes.js';
import pushNotifications, { DEFAULT_STATE as DEFAULT_STATE_PUSH_NOTIFICATIONS } from './pushNotifications.js';

export const DEFAULT_STATE = {
    notificationBanner: DEFAULT_STATE_NOTIFICATION_BANNER,
    pushNotifications: DEFAULT_STATE_PUSH_NOTIFICATIONS,
    notificationPreferences: DEFAULT_STATE_NOTIFICATION_PREFERENCES,
    notificationSourceTypes: DEFAULT_STATE_NOTIFICATION_SOURCE_TYPES,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case PushNotificationConstants.CONNECT:
            return {
                ...state,
                pushNotifications: pushNotifications(state.pushNotifications, action),
            };

        case NotificationBannerConstants.SET_NOTIFICATION_BANNER:
            return {
                ...state,
                notificationBanner: notificationBanner(state.notificationBanner, action),
            };

        case 'NotificationPreferenceActions.ON_GET':
        case 'NotificationPreferenceActions.ON_SAVE':
            return {
                ...state,
                notificationPreferences: notificationPreferences(
                    state.notificationPreferences,
                    action,
                ),
            };

        case 'NotificationPreferenceActions.ON_UPDATE_CHECKBOX': {
            const updatedPreferences = state.notificationPreferences.map((item) => {
                if (item.sourceType === action.preferenceId) {
                    return {
                        ...item,
                        channels: addOrReplaceBy(
                            item.channels,
                            (channel) => channel.notificationChannelType === action.channelId,
                            (existing) => ({
                                ...existing,
                                value: !existing.value,
                            }),
                        ),
                    };
                }

                return item;
            });

            return {
                ...state,
                notificationPreferences: updatedPreferences,
            };
        }

        case 'NotificationPreferenceActions.ON_SELECT_UNSELECT_ALL_CHECKBOX': {
            const isChecked = action.checkboxOperation === 'checkAll';

            return {
                ...state,
                notificationPreferences: state.notificationPreferences.map((item) => ({
                    ...item,
                    channels: addOrReplaceBy(
                        item.channels,
                        (channel) => channel.notificationChannelType === action.channelId,
                        (existing) => ({
                            ...existing,
                            value: existing.value !== null ? isChecked : null,
                        }),
                    ),
                })),
            };
        }

        case 'NotificationSourceTypesActions.ON_GET':
            return {
                ...state,
                notificationSourceTypes: notificationSourceTypes(
                    state.notificationSourceTypes,
                    action,
                ),
            };

        default:
            return state;
    }
};
