import { selectBoxTransform } from '../../global/utils';

export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
    if (action.type === 'MinistryServingOpportunityTagActions.ON__GETALL') {
        return selectBoxTransform(action, 'id', 'name');
    }

    return state;
};
