import {
    filter,
    find,
    isEmpty,
    map,
} from 'lodash';
import {
    getPersonAvatarUrl,
} from './utils.js';

// eslint-disable-next-line import/prefer-default-export
export function getModifiedDataForPersonPanel(
    person,
    coreMilestones,
    isPrimaryInfoOnlyVisible = false,
) {
    if (isEmpty(person)) {
        return null;
    }

    let addresses = map(person.addresses, (address) => ({
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        country: address.country,
        countryAlpha2: address.countryAlpha2,
        isPrimary: address.isPrimary,
        postalCode: address.postalCode,
        region: address.region,
        regionCode: address.regionCode,
    }));

    let emails = map(person.emails, (email) => ({
        isPrimary: email.isPrimary,
        value: email.email,
    }));

    let phones = map(person.phones, (phone) => ({
        type: phone.phoneTypeId || phone.type,
        isPrimary: phone.isPrimary,
        value: phone.displayPhoneNumber || phone.number,
    }));

    const primaryEmergencyContact = find(person.emergencyContacts, 'isPrimary');
    let recordType;

    if (person.isChild) {
        recordType = 'child';
    } else if (person.isStudent) {
        recordType = 'student';
    } else {
        recordType = 'adult';
    }

    let emergencyContactFirstName;
    let emergencyContactLastName;
    let emergencyContactPhones;
    let emergencyContactEmails;
    let emergencyContactRelationshipName;
    let emergencyContactPreferMethod;

    const emergencyContactAddresses = [];

    if (!isEmpty(primaryEmergencyContact)) {
        emergencyContactEmails = map(primaryEmergencyContact.emergencyContactEmails, (item) => (
            {
                isPrimary: item.isPrimary,
                value: item.email,
            }
        ));
        emergencyContactPhones = map(primaryEmergencyContact.emergencyContactPhones, (item) => (
            {
                isPrimary: item.isPrimary,
                value: item.phoneNumber,
            }
        ));
        emergencyContactFirstName = primaryEmergencyContact.emergencyContactPerson.firstName;
        emergencyContactLastName = primaryEmergencyContact.emergencyContactPerson.lastName;
        emergencyContactRelationshipName = primaryEmergencyContact.emergencyContactRelationshipName;
        emergencyContactPreferMethod = primaryEmergencyContact.emergencyContactPreference;
    }

    if (isPrimaryInfoOnlyVisible) {
        addresses = filter(addresses, (item) => item.isPrimary === true);
        emails = filter(emails, (item) => item.isPrimary === true);
        phones = filter(phones, (item) => item.isPrimary === true);
    }

    let detailsData = {
        addresses,
        allergies: person.allergies,
        birthdate: person.birthDate,
        campus: person.churchEntityName,
        commonlyAttendedService: person.commonlyAttendedService,
        emails,
        emergencyContactAddresses,
        emergencyContactEmails,
        emergencyContactName: `${emergencyContactFirstName} ${emergencyContactLastName}`,
        emergencyContactPhones,
        emergencyContactPreferMethod,
        emergencyContactRelation: emergencyContactRelationshipName,
        gender: person.gender,
        gradeLevel: person.gradeLevel,
        isDoNotContact: person.contactPreferences?.doNotContact,
        phones,
        preferredService: person.preferredService?.name,
        recordType,
    };

    const summaryData = {
        avatar: getPersonAvatarUrl(person),
        birthdate: person.birthDate,
        campus: person.churchEntityName,
        emails,
        emergencyContactEmail: !isEmpty(emergencyContactEmails) ? find(emergencyContactEmails, 'isPrimary').value : null,
        emergencyContactPhone: !isEmpty(emergencyContactPhones) ? find(emergencyContactPhones, 'isPrimary').value : null,
        emergencyContactPreferredMethod: emergencyContactPreferMethod?.preferredMethod,
        emergencyContactRelationshipName,
        firstName: person.firstName,
        gender: person.gender,
        gradeLevel: person.gradeLevel,
        isDoNotContact: person.contactPreferences?.doNotContact ?? false,
        isDoNotEmail: person.contactPreferences?.doNotEmail ?? false,
        isDoNotMail: person.contactPreferences?.doNotMail ?? false,
        isDoNotPhone: person.contactPreferences?.doNotPhone ?? false,
        isDoNotText: person.contactPreferences?.doNotText ?? false,
        lastName: person.lastName,
        maritalStatus: person.maritalStatus,
        nickName: person.nickName,
        personId: person.id,
        phones,
        preferredMethod: person.contactPreferences?.preferredMethod ?? 'none',
        prefix: person.prefix,
        recordType,
        suffix: person.suffix,
    };

    if (!isEmpty(coreMilestones)) {
        detailsData = {
            ...detailsData,
            ...coreMilestones,
        };
    }

    return {
        detailsData,
        summaryData,
    };
}
