/**
* This enumeration contains the Action Type string constants for Event Details Registration Info
*/
enum ActionType {
    // Action Type for Event Registration
    SET_EVENT_REGISTRATION = 'EventEditActions.ON_SET_EVENT_REGISTRATION',
    SET_CAN_REGISTER_ON_EVENT = 'EventEditActions.ON_SET_CAN_REGISTER_ON_EVENT',
    // Action Type for Event Registration Window
    SET_EVENT_REGISTRATION_END_DATE = 'EventEditActions.ON_SET_EVENT_REGISTRATION_END_DATE',
    SET_EVENT_REGISTRATION_START_DATE = 'EventEditActions.ON_SET_EVENT_REGISTRATION_START_DATE',
    SET_EVENT_IS_CUSTOM_REGISTRATION = 'EventEditActions.ON_SET_EVENT_IS_CUSTOM_REGISTRATION',
    SET_EVENT_IS_REGISTRATION_CLOSED = 'EventEditActions.ON_SET_EVENT_IS_REGISTRATION_CLOSED',
    // Action Type for Event Allow Guest Registration
    SET_EVENT_ALLOW_GUEST_REGISTRATION = 'EventEditActions.ON_SET_EVENT_ALLOW_GUEST_REGISTRATION',
    // Action Type for Event Allow Group Registration
    SET_EVENT_ALLOW_GROUP_REGISTRATION = 'EventEditActions.ON_SET_EVENT_ALLOW_GROUP_REGISTRATION',
    SET_EVENT_MAX_GROUP_PARTY_SIZE = 'EventEditActions.ON_SET_EVENT_MAX_GROUP_PARTY_SIZE',
    // Action Type for Event Confirmation Email
    SET_EVENT_CUSTOM_REGISTRATION_NOTIFICATION_SETTINGS = 'EventEditActions.ON_SET_EVENT_CUSTOM_REGISTRATION_NOTIFICATION_SETTINGS',
    // Action Type for Event Registration Question
    SET_EVENT_CUSTOM_QUESTIONS = 'EventEditActions.ON_SET_EVENT_CUSTOM_QUESTIONS',
}

export default ActionType;
