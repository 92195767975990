import { combineReducers } from 'redux';
import newChildPersonForm from './newPerson/newChildPerson.reducer.js';
import occurrenceRosterFilter from './occurrenceRoster/searchFilter.reducer.js';
import occurrenceRosterSelection from './occurrenceRoster/selection.reducer.js';
import personSearchFilter from './personSearch/searchFilter.reducer.js';
import personSearchSelection from './personSearch/selection.reducer.js';

const reducers = combineReducers({
    newChildPersonForm,
    occurrenceRosterFilter,
    occurrenceRosterSelection,
    personSearchFilter,
    personSearchSelection,
});

export default reducers;
