const DEFAULT_STATE = {
    isFetching: false,
    numberOfWeeks: 0,
    previewDataRows: [],
    totalRowCount: 0,
};

const people = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PeopleExportActions.REQUEST_GETPREVIEW':
            return { ...state, isFetching: true };

        case 'PeopleExportActions.ON_GETPREVIEW':
        {
            const {
                result: {
                    resultCount,
                    results,
                    weekCount,
                },
            } = action;

            return {
                ...state,
                isFetching: false,
                numberOfWeeks: weekCount,
                previewDataRows: results,
                totalRowCount: resultCount,
            };
        }

        case 'PeopleExportActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default people;
