import {
    every,
    filter,
    isEmpty,
    isNil,
    map,
    maxBy,
} from 'lodash';
import { addOrReplaceBy } from '../../../global/utils';
import { EDITOR_MODE } from '../constants.js';
import isAbleToSaveCancelDelayedActions from './cancelDelayedActionsAction/cancelDelayedActionsActionUtils.js';
import { ACTION_TYPE } from './constants.js';
import { updateRuleEmailAction } from './emailAction/emailActionEditor.reducer.js';
import isAbleToSaveEmailTemplate from './emailAction/emailActionUtils.js';
import { updateRuleFollowUpAction } from './followUpAction/followUpActionsReducerTransforms.js';
import { isAbleToSaveFollowUpTemplate } from './followUpAction/followUpActionUtils.js';

export const isAbleToSaveRuleActions = (rule) => (
    every(
        rule.actions,
        (ruleAction) => !!ruleAction.ableToSave,
    )
);

let nextRuleActionId = -1;

export const getNextRuleActionSequentialId =
    () => (nextRuleActionId--); // eslint-disable-line no-plusplus

export const recallPreviousRuleActionSequentialId =
    () => (nextRuleActionId + 1);

export const updateIndividualRuleAction = (existingRule, ruleActionId, objWithUpdates) => ({
    ...existingRule,
    actions: filter(
        addOrReplaceBy(
            existingRule.actions,
            (a) => a.id === ruleActionId,
            (existingItem) => {
                if (!isNil(existingItem)) {
                    return {
                        ...existingItem,
                        ...objWithUpdates,
                    };
                }

                return null;
            },
        ),
        (a) => !isNil(a),
    ),
});

export const upsertRuleActionSetting = (
    existingRule,
    ruleActionId,
    actionSettingName,
    actionSettingValue,
) => ({
    ...existingRule,
    actions: filter(
        addOrReplaceBy(
            existingRule.actions,
            (a) => a.id === ruleActionId,
            (existingItem) => {
                if (!isNil(existingItem)) {
                    return {
                        ...existingItem,
                        actionSettings: addOrReplaceBy(
                            existingItem.actionSettings,
                            (as) => as.name === actionSettingName,
                            (existingActionSetting) => ({
                                ...existingActionSetting,
                                name: actionSettingName,
                                value: actionSettingValue,
                            }),
                        ),
                    };
                }

                return null;
            },
        ),
        (a) => !isNil(a),
    ),
});

export const upsertRuleActionDelayDependencies = (
    existingRule,
    ruleActionId,
    actionSettingName,
    actionSettingValue,
    dependencyType,
) => ({
    ...existingRule,
    actions: filter(
        addOrReplaceBy(
            existingRule.actions,
            (a) => a.id === ruleActionId,
            (existingItem) => {
                if (!isNil(existingItem)) {
                    const filteredActionSettingsExceptDelayedDependencies = filter(
                        existingItem.actionSettings, (item) => item.name !== actionSettingName,
                    );

                    const delayedDependenciesActionSettings = filter(
                        existingItem.actionSettings, (item) => item.name === actionSettingName,
                    );

                    let filteredDelayDependenciesActionSettings = [];
                    let combinedDelayedActionDependenciesActionSettings = [];

                    if (!isNil(delayedDependenciesActionSettings)) {
                        filteredDelayDependenciesActionSettings = filter(
                            delayedDependenciesActionSettings.value,
                            (item) => item.entityType !== dependencyType,
                        );
                    }

                    if (actionSettingValue[0]?.entityId) {
                        combinedDelayedActionDependenciesActionSettings = [
                            ...filteredDelayDependenciesActionSettings,
                            ...actionSettingValue,
                        ];
                    } else {
                        combinedDelayedActionDependenciesActionSettings = [
                            ...filteredDelayDependenciesActionSettings,
                        ];
                    }

                    const delayedActionSettings = {
                        name: actionSettingName,
                        value: !isEmpty(combinedDelayedActionDependenciesActionSettings) ?
                            JSON.stringify(combinedDelayedActionDependenciesActionSettings) : '',
                    };

                    const actionSettings = [
                        ...filteredActionSettingsExceptDelayedDependencies,
                        delayedActionSettings,
                    ];

                    let ableToSave = false;

                    if (existingItem.actionType === ACTION_TYPE.SendEmail) {
                        ableToSave = isAbleToSaveEmailTemplate(actionSettings);
                    } else if (existingItem.actionType === ACTION_TYPE.CreateFollowUp) {
                        ableToSave = isAbleToSaveFollowUpTemplate(existingItem.actionTemplate);
                    } else {
                        ableToSave = isAbleToSaveCancelDelayedActions(
                            actionSettings,
                            dependencyType,
                        );
                    }

                    return {
                        ...existingItem,
                        ableToSave,
                        actionSettings,
                    };
                }

                return null;
            },
        ),
        (a) => !isNil(a),
    ),
});

export const updateRuleActionState = (state, updatedRule) => {
    const ableToSaveRuleActions = isAbleToSaveRuleActions(updatedRule);

    return {
        ...state,
        ableToSaveRuleActions,
        isDirty: true,
        rule: updatedRule,
    };
};

export const updateRuleActionsWithCampus = (existingRule, churchEntityId) => ({
    ...existingRule,
    actions: map(existingRule.actions, (action) => {
        if (action.actionType === ACTION_TYPE.CreateFollowUp) {
            return {
                ...action,
                actionTemplate: {
                    ...action.actionTemplate,
                    churchEntityId,
                },
            };
        }

        return action;
    }),
});

export const updateRuleAction = (state, actionType, actionValue) => {
    switch (actionType) {
        case 'WorkflowRuleActionActions.ON_ADD_RULE_ACTION':
        {
            const { rule: existingRule } = state;

            const id = getNextRuleActionSequentialId();

            const order = !isEmpty(existingRule.actions) ?
                maxBy(
                    existingRule.actions,
                    (a) => a.order,
                ).order + 1 :
                1;

            const {
                churchEntityId,
                ruleActionType,
            } = actionValue;

            return {
                ...state,
                ableToSaveRuleActions: false,
                editingActionId: id,
                rule: {
                    ...existingRule,
                    actions: [
                        ...existingRule.actions,
                        {
                            id,
                            order,
                            ableToSave: false,
                            actionType: ruleActionType,
                            actionSettings: [],
                            actionTemplate: {
                                churchEntityId,
                            },
                            isActive: true,
                        },
                    ],
                },
                ruleActionDetailEditorMode: EDITOR_MODE.add,
            };
        }

        case 'WorkflowRuleActionActions.ON_EDIT_RULE_ACTION':
        {
            return {
                ...state,
                editingActionId: actionValue,
            };
        }

        case 'WorkflowRuleActionActions.ON_REMOVE_RULE_ACTION':
        {
            const { rule: existingRule } = state;

            const updatedRule = {
                ...existingRule,
                actions: map(
                    filter(
                        existingRule.actions,
                        (a) => a.id !== actionValue,
                    ),
                    (a, index) => ({
                        ...a,
                        order: index + 1,
                    }),
                ),
            };

            const updatedState = updateRuleActionState(state, updatedRule);

            return {
                ...updatedState,
                editingActionId: null,
            };
        }

        case 'WorkflowRuleActionActions.ON_UPDATE_RULE_ACTION_DELAY':
        {
            const { rule: existingRule } = state;

            const {
                ruleActionId,
                executionDelay,
            } = actionValue;

            const updates = { executionDelay };

            const updatedRule = updateIndividualRuleAction(
                existingRule,
                ruleActionId,
                updates,
            );

            return updateRuleActionState(state, updatedRule);
        }

        case 'WorkflowRuleActionActions.ON_UPDATE_RULE_ACTION_DELAYED_ACTION_DEPENDENCIES':
        {
            const { rule: existingRule } = state;

            const {
                ruleActionId,
                actionSettingName,
                actionSettingValue,
                dependencyType,
            } = actionValue;

            const updatedRule = upsertRuleActionDelayDependencies(
                existingRule,
                ruleActionId,
                actionSettingName,
                actionSettingValue,
                dependencyType,
            );

            return updateRuleActionState(state, updatedRule);
        }

        case 'WorkflowRuleActionActions.ON_UPDATE_RULE_ACTION_STATUS':
        {
            const { rule: existingRule } = state;

            const {
                ruleActionId,
                isActive,
            } = actionValue;

            const updates = { isActive };

            const updatedRule = updateIndividualRuleAction(
                existingRule,
                ruleActionId,
                updates,
            );

            return updateRuleActionState(state, updatedRule);
        }

        case 'WorkflowRuleActionActions.ON_UPSERT_RULE_ACTION_SETTING':
        {
            const { rule: existingRule } = state;

            const {
                ruleActionId,
                actionSettingName,
                actionSettingValue,
            } = actionValue;

            const updatedRule = upsertRuleActionSetting(
                existingRule,
                ruleActionId,
                actionSettingName,
                actionSettingValue,
            );

            return updateRuleActionState(state, updatedRule);
        }

        case 'FollowUpActionEditorActions.ON_REMOVE_ASSIGNEE':
        case 'FollowUpActionEditorActions.ON_REMOVE_SUPERVISOR':
        case 'FollowUpActionEditorActions.ON_UPDATE_ASSIGNEE_TEAM':
        case 'FollowUpActionEditorActions.ON_UPDATE_ASSIGNEE_USER':
        case 'FollowUpActionEditorActions.ON_UPDATE_DUE_DATE_TIME_LIMIT':
        case 'FollowUpActionEditorActions.ON_UPDATE_FOLLOW_UP_TITLE':
        case 'FollowUpActionEditorActions.ON_UPDATE_FOLLOW_UP_INSTRUCTIONS':
        case 'FollowUpActionEditorActions.ON_UPDATE_SUPERVISOR':
        case 'FollowUpActionEditorActions.ON_UPDATE_CATEGORY':
        case 'FollowUpActionEditorActions.ON_UPDATE_TASK_NOTIFICATION_TEMPLATE_ID':
        case 'FollowUpActionEditorActions.ON_UPDATE_TASK_NOTIFICATION_TYPE':
        {
            const { rule: existingRule } = state;
            const updatedRule = updateRuleFollowUpAction(existingRule, actionType, actionValue);
            return updateRuleActionState(state, updatedRule);
        }

        case 'EmailTemplateEditorActions.ON_REMOVE_EMAIL_TEMPLATE':
        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_FROM_DISPLAY_NAME':
        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_FROM_EMAIL':
        case 'EmailTemplateEditorActions.ON_UPDATE_REPLY_TO_DISPLAY_NAME':
        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_REPLY_TO_EMAIL':
        case 'EmailTemplateEditorActions.ON_UPDATE_FROM_EMAIL_USER':
        case 'EmailTemplateEditorActions.ON_UPDATE_REPLY_TO_EMAIL_USER':
        case 'EmailTemplateEditorActions.ON_UPDATE_EMAIL_TEMPLATE':
        {
            const { rule: existingRule } = state;
            const updatedRule = updateRuleEmailAction(existingRule, actionType, actionValue);
            return updateRuleActionState(state, updatedRule);
        }

        case 'CommunicationTemplateEditorActions.ON_UPDATE':
        {
            const {
                rule,
                editingActionId,
            } = state;

            if (!isNil(editingActionId)) {
                const updatedRule = {
                    ...rule,
                    actions: addOrReplaceBy(
                        rule.actions,
                        (a) => a.id === editingActionId,
                        (a) => ({
                            ...a,
                            actionTemplate: actionValue,
                        }),
                    ),
                };

                return {
                    ...state,
                    rule: updatedRule,
                };
            }

            return state;
        }

        default:
            return state;
    }
};
