import {
    APP_HEADER_SET_APP_HEADER_TITLE,
} from './appHeaderTypes.js';

const DEFAULT_STATE = {
    subTitle: '',
    title: '',
    appHeaderTitle: '', // this is used only when navigation v3 is enabled
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'AppHeaderActions.ON_UPDATETITLE':
            return {
                ...state,
                subTitle: action.result.subTitle,
                title: action.result.title,
            };
        case APP_HEADER_SET_APP_HEADER_TITLE:
            return {
                ...state,
                appHeaderTitle: action.appHeaderTitle,
            };
        default:
            return state;
    }
}
