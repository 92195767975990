import '../../scss/components/ModalDrawer.scss';

import ClassNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';

import ModalDrawerContent from './ModalDrawerContent.react';

import ModalStore from '../stores/ModalStore.js';

export default class ModalDrawer extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: false };
    }

    render() {
        const themeClass = this.props.theme === 'dark' ? 'modal-drawer-dark' : 'modal-drawer-light';
        const containerClasses = ClassNames('modal-drawer-container', themeClass, this.props.className);
        let modalContent;

        if (this.state.isOpen) {
            modalContent = (
                <div className={containerClasses} style={{ zIndex: `9000${this.props.layerIndex}` }}>
                    <ModalDrawerContent
                        boxShadow={this.props.boxShadow}
                        closeIcon={this.props.closeIcon}
                        disableOnClickOutside={this.props.disableOnClickOutside}
                        hideCloseIcon={this.props.hideCloseIcon}
                        maxWidth={this.props.maxWidth}
                        onClose={this._onClose.bind(this)}
                        outsideClickIgnoreClass={this.props.outsideClickIgnoreClass}
                        subTitle={this.props.subTitle}
                        title={this.props.title}
                        titleIcon={this.props.titleIcon}
                    >
                        {this.props.children}
                    </ModalDrawerContent>
                </div>
            );
        }

        return (
            <CSSTransitionGroup
                className="modal-drawer"
                component="div"
                id={this.props.modalId}
                transitionAppear
                transitionAppearTimeout={250}
                transitionEnterTimeout={250}
                transitionLeaveTimeout={250}
                transitionName={{
                    appear: 'animate-fly-in-appear',
                    appearActive: 'animate-active',
                    enter: 'animate-fly-in-enter',
                    enterActive: 'animate-active',
                    leave: 'animate-fly-out-leave',
                    leaveActive: 'animate-active',
                }}
            >
                {modalContent}
            </CSSTransitionGroup>
        );
    }

    componentDidMount() {
        this.setState({ isOpen: true });
    }

    _onClose() {
        this.setState({ isOpen: false });

        setTimeout(() => {
            ModalStore.removeModal(this.props.modalId);
        }, 300);
    }
}

ModalDrawer.propTypes = {
    boxShadow: PropTypes.string,
    className: PropTypes.string,
    closeIcon: PropTypes.string,
    disableOnClickOutside: PropTypes.bool,
    hideCloseIcon: PropTypes.bool,
    layerIndex: PropTypes.number,
    maxWidth: PropTypes.string,
    modalId: PropTypes.string,
    outsideClickIgnoreClass: PropTypes.string,
    subTitle: PropTypes.string,
    theme: PropTypes.string,
    title: PropTypes.string,
    titleIcon: PropTypes.string,
};
