import PropTypes from 'prop-types';

export const BEM_BLOCK_NAME = 'person_add';
export const CHURCH_ENTITY_NAME_UNKNOWN = 'Unknown';

export const PERSON_RECORD_TYPES = {
    adult: 'adult',
    child: 'child',
    student: 'student',
};

export const personRecordPropTypes = PropTypes.oneOf([
    PERSON_RECORD_TYPES.adult,
    PERSON_RECORD_TYPES.child,
    PERSON_RECORD_TYPES.student,
]);

export const POSSSIBLE_DUPLICATES_BANNER_ID = `${BEM_BLOCK_NAME}--view_possible_duplicates_banner`;
