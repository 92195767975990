import {
    clone,
    filter,
    find,
    forEach,
    get,
    map,
    size,
    some,
} from 'lodash';
import moment from 'moment-timezone';
import { selectBoxTransform } from '../../../global/utils';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';
import MomentUtils from '../../../global/momentUtils.js';

const occurrencesCache = new ChunkedPaginationUtils(25, 50);
const searchCache = new ChunkedPaginationUtils(25, 50);
const notificationCache = new ChunkedPaginationUtils(25, 50);

export const DEFAULT_STATE = {
    event: null,
    eventByCampus: [],
    eventOccurrencesExcludeDates: [],
    eventOccurrencesForRange: null,
    eventOccurrencesForRangeDates: null,
    invites: {
        canLoadMore: false,
        results: [],
        total: 0,
    },
    isFetching: false,
    ministryLocation: [],
    notifications: {
        canLoadMore: notificationCache.canLoadMore(),
        needsToRequest: notificationCache.needsToLoadPage(),
        pageNumber: notificationCache.getCurrentPageNumber(),
        pageSize: notificationCache.getPageSize(),
        results: [],
    },
    occurrencesCanLoadMore: false,
    occurrencesDate: null,
    occurrencesEvents: {},
    occurrencesForDate: [],
    occurrencesNeedsToRequest: false,
    occurrencesPageNumber: 0,
    occurrencesPageSize: occurrencesCache.getPageSize(),
    search: {
        canLoadMore: searchCache.canLoadMore(),
        needsToRequest: searchCache.needsToLoadPage(),
        pageNumber: searchCache.getCurrentPageNumber(),
        pageSize: searchCache.getPageSize(),
        results: [],
    },
    statisticsForDate: {},
    statisticsForRange: {},
    webHeaderURL: null,
    companionAppHeaderURL: null,
    thumbnailURL: null,
    nextOccurrence: null,
};

export const normalizeEvent = (event, forRead = true) => {
    // In UI we use date range with included end. But backend works always with
    // excluded end. So, if you need to finish event occurrences in some day,
    // you need to send to backend start of the next day in EndDate property.
    forEach(event.schedule?.sequences, (s) => {
        if (s.endDate) {
            // eslint-disable-next-line no-param-reassign
            s.endDate += forRead ?
                -MomentUtils.SECONDS_PER_DAY :
                MomentUtils.SECONDS_PER_DAY;
        }
    });
};

export const normalizeEvents = (events, forRead = true) => {
    forEach(events, (v) => normalizeEvent(v, forRead));
};

const transformOccurrences = (state, result, date) => {
    const newOccurrences = [];
    forEach(result, (v) => {
        let group = find(newOccurrences, { date: v.occurrence.actualDate });

        if (!group) {
            group = {
                date: v.occurrence.actualDate,
                occurrences: [],
            };
            newOccurrences.push(group);
        }

        const event = clone(v);
        const { occurrence } = event;
        delete event.occurrence;
        occurrence.event = event;

        group.occurrences.push(occurrence);
    });

    const newState = {
        ...state,
        occurrencesDate: date,
        occurrencesEvents: {},
        occurrencesForDate: newOccurrences,
    };

    forEach(newState.occurrencesForDate, (v) => {
        const events = {};
        forEach(v.occurrences, (occurrence) => {
            const occurrenceDate = moment.unix(occurrence.actualStartDateTime).utc();
            const hour = occurrenceDate.hour();

            if (!events[hour]) {
                events[hour] = [];
            }

            const existingEvents = events[hour];
            existingEvents.push({
                occurrenceDate,
                occurrence,
            });
        });

        newState.occurrencesEvents[v.date] = events;
    });

    return newState;
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'EventActions.ON_RESET':
            return DEFAULT_STATE;

        case 'EventActions.ON_BEFORE_GET':
            return {
                ...state,
                isFetching: true,
            };

        case 'EventActions.ON_GET': {
            const event = action.result;
            normalizeEvent(event);

            return {
                ...state,
                event,
                isFetching: false,
            };
        }

        case 'EventActions.ON_GETSERIES':
        case 'EventActions.ON_GETOCCURRENCES': {
            const first = get(action, 'callbackParams.first', true);
            const date = get(action, 'callbackParams.date');

            normalizeEvents(action.result);
            occurrencesCache.loadPage(action.result, 0, first);

            const newState = {
                occurrencesCanLoadMore: occurrencesCache.canLoadMore(),
                occurrencesNeedsToRequest: occurrencesCache.needsToLoadPage(),
                occurrencesPageNumber: occurrencesCache.getCurrentPageNumber(),
                occurrencesPageSize: occurrencesCache.getPageSize(),
            };

            return transformOccurrences(
                { ...state, ...newState },
                occurrencesCache.getAll(true),
                date,
            );
        }

        case 'EventActions.ON_GETOCCURRENCES_NEXTPAGE': {
            const date = state.occurrencesDate;

            const newState = {
                occurrencesCanLoadMore: occurrencesCache.canLoadMore(),
                occurrencesNeedsToRequest: occurrencesCache.needsToLoadPage(),
                occurrencesPageNumber: occurrencesCache.getCurrentPageNumber(),
                occurrencesPageSize: occurrencesCache.getPageSize(),
            };

            return transformOccurrences(
                { ...state, ...newState },
                occurrencesCache.getAll(true),
                date,
            );
        }

        case 'EventActions.ON_GETSTATISTICS': {
            const { date } = action.callbackParams;

            if (date) {
                return {
                    ...state,
                    statisticsForDate: action.result,
                };
            }

            return {
                ...state,
                statisticsForRange: action.result,
            };
        }

        case 'EventActions.ON_GETEVENTOCCURRENCESBYRANGE': {
            const startDate = get(action, 'callbackParams.startDate');
            const endDate = get(action, 'callbackParams.endDate');
            const activeOnly = get(action, 'callbackParams.activeOnly');
            const result = activeOnly ? filter(action.result, (v) => v.isActive) : action.result;

            const eventOccurrencesExcludeDates = [];
            const eventOccurrencesForRangeDates = map(
                result,
                (v) => moment.unix(v.actualDate).utc(),
            );

            if (startDate && endDate) {
                for (
                    let i = startDate;
                    i <= endDate;
                    i += MomentUtils.SECONDS_PER_DAY
                ) {
                    const date = moment.unix(i).utc();

                    if (
                        !some(eventOccurrencesForRangeDates, (v) => v.isSame(date, 'day'))
                    ) {
                        eventOccurrencesExcludeDates.push(date);
                    }
                }
            }

            return {
                ...state,
                eventOccurrencesExcludeDates,
                eventOccurrencesForRange: result,
                eventOccurrencesForRangeDates,
            };
        }

        case 'EventActions.ON_CLEAREVENTOCCURRENCESBYRANGE':
            return {
                ...state,
                eventOccurrencesExcludeDates: [],
                eventOccurrencesForRange: null,
                eventOccurrencesForRangeDates: null,
            };

        case 'EventActions.ON_GETINVITES':
            return {
                ...state,
                invites: {
                    canLoadMore: false,
                    results: action.result,
                    total: size(action.result),
                },
            };

        case 'EventImageHandlerActions.ON_GETWEBHEADER':
            return {
                ...state,
                webHeaderURL: action.result,
            };

        case 'EventImageHandlerActions.ON_GETCOMPANIONAPPHEADER':
            return {
                ...state,
                companionAppHeaderURL: action.result,
            };

        case 'EventImageHandlerActions.ON_GETTHUMBNAIL':
            return {
                ...state,
                thumbnailURL: action.result,
            };

        case 'EventActions.ON_GETPREFERREDEVENT':
            return {
                ...state,
                eventByCampus: action.result,
            };

        case 'EventActions.ON_GETMINISTRYLOCATION':
            return {
                ...state,
                ministryLocation: selectBoxTransform(action, 'id', 'name'),
            };

        case 'EventActions.ON__SEARCH':
            searchCache.loadPage(
                action.result,
                0,
                get(action, 'callbackParams.first', true),
            );

        // eslint-disable-next-line no-fallthrough
        case 'EventActions.ON_SEARCH_NEXTPAGE':
            return {
                ...state,
                search: {
                    ...state.search,
                    canLoadMore: searchCache.canLoadMore(),
                    needsToRequest: searchCache.needsToLoadPage(),
                    pageNumber: searchCache.getCurrentPageNumber(),
                    pageSize: searchCache.getPageSize(),
                    results: searchCache.getAll(true),
                },
            };

        case 'EventActions.ON_SEARCH_RESET': {
            return {
                ...state,
                search: DEFAULT_STATE.search,
            };
        }

        case 'EventActions.ON_GETNOTIFICATIONS':
            notificationCache.loadPage(
                action.result.list,
                action.result.count,
                action.callbackParams.first,
            );

        // eslint-disable-next-line no-fallthrough
        case 'EventActions.ON_GETNOTIFICATIONS_NEXTPAGE':
            return {
                ...state,
                notifications: {
                    results: notificationCache.getAll(true),
                    total: notificationCache.getTotalCount(),
                    canLoadMore: notificationCache.canLoadMore(),
                    needsToRequest: notificationCache.needsToLoadPage(),
                    pageNumber: notificationCache.getCurrentPageNumber(),
                    pageSize: notificationCache.getPageSize(),
                },
            };

        case 'EventActions.ON_BEFORE_GETNEXTOCCURRENCE':
            return {
                ...state,
                isFetching: true,
            };

        case 'EventActions.ON_GETNEXTOCCURRENCE': {
            const { nextOccurrence, venues, subVenues } = action.result;
            return {
                ...state,
                nextOccurrence: {
                    occurrence: nextOccurrence,
                    venues,
                    subVenues,
                },
                isFetching: false,
            };
        }

        case 'EventActions.ON__UPDATE': {
            const { result } = action;

            return {
                ...state,
                event: result,
            };
        }

        default:
            return state;
    }
};
