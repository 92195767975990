import moment from 'moment-timezone';
import NoteReminderOpenSource from './models/NoteReminderOpenSource.model';
import NoteReminderType from './models/NoteReminderType.model';
import {
    ReminderFormField,
    DateTypeOption,
} from './models';
import { PersonNote } from '../models';

export type ResetReminderForm = () => void;

export type SetIsReminderFormModalOpen = (
    isReminderFormModalOpen: boolean,
    reminderModalOpenFrom?: NoteReminderOpenSource,
) => void;

export type SetHasCreateReminderButtonBeenClicked = (
    hasCreateReminderButtonBeenClicked: boolean
) => void;

export type SetReminderFormData = (
    field: ReminderFormField,
    value: DateTypeOption | moment.MomentInput | null,
) => void;

export type SetSelectedReminder = (selectedReminder?: any) => void;

export const resetReminderForm: ResetReminderForm = () => (dispatch) => {
    dispatch({
        type: NoteReminderType.ResetReminderForm,
    });
};

export const setHasCreateReminderButtonBeenClicked: SetHasCreateReminderButtonBeenClicked = (
    hasCreateReminderButtonBeenClicked,
) => (dispatch) => {
    dispatch({
        type: NoteReminderType.SetCreateReminderButtonHasBeenClicked,
        hasCreateReminderButtonBeenClicked,
    });
};

export const setMobileNextWingType: (
    mobileNextWingType: string
) => void = (mobileNextWingType) => (dispatch) => {
    dispatch({
        type: NoteReminderType.SetMobileNextWingType,
        mobileNextWingType,
    });
};

export const setReminderElementOpenFrom: (
    reminderModalOpenFrom: NoteReminderOpenSource,
) => void = (reminderModalOpenFrom) => (dispatch) => {
    dispatch({
        type: NoteReminderType.SetReminderElementOpenSource,
        reminderModalOpenFrom,
    });
};

export const setReminderFormData: SetReminderFormData = (field, value) => (dispatch) => {
    dispatch({
        type: NoteReminderType.SetReminderFormData,
        field,
        value,
    });
};

export const setIsReminderFormModalOpen: SetIsReminderFormModalOpen = (
    isReminderFormModalOpen,
    reminderModalOpenFrom,
) => (dispatch) => {
    dispatch({
        type: NoteReminderType.SetReminderFormModalOpen,
        isReminderFormModalOpen,
        reminderModalOpenFrom,
    });
};

export const setSelectedNote: (
    selectedNote: PersonNote,
) => void = (selectedNote) => (dispatch) => {
    dispatch({
        type: NoteReminderType.SetSelectedNote,
        selectedNote,
    });
};

export const setSelectedReminder: SetSelectedReminder = (selectedReminder) => (dispatch) => {
    dispatch({
        type: NoteReminderType.SetSelectedReminder,
        selectedReminder,
    });
};
