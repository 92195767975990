enum JourneyCatalogTextButton {
    AttendAnEvent = 'Select Event',
    AttendAServingOpportunity = 'Select Serving Opps',
    FillOutAForm = 'Select Form',
    FillOutAFormApproval = 'Select Form',
    ListenToAudio = 'Select or Add Audio',
    ReadADocument = 'Select a PDF',
    ReadText = 'Add Text',
    RegisterForAnEvent = 'Select Event',
    RegisterForAMinistry = 'Select Ministry',
    RegisterForAServingOpportunity = 'Select Serving Opp',
    TakeAQuiz = 'Select Quiz',
    WatchAVideo = 'Attach Video',
}

export default JourneyCatalogTextButton;
