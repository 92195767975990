import _ from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.shape({}),
};

const defaultProps = {
    children: undefined,
    className: undefined,
    style: undefined,
};

class Sticky extends React.PureComponent {
    constructor() {
        super();

        this.onResizeDebounce = _.debounce(() => this.onResize(), 80);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.onResizeDebounce);
        this.setPlaceholderStyle();
        this.setContentContainerStyles();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onResize() {
        this.setPlaceholderStyle();
        this.setContentContainerStyles();
    }

    onScroll() {
        this.setContentContainerStyles();
    }

    setContentContainerStyles() {
        if (!this.contentContainerRef || !this.placeholderRef) {
            return;
        }

        const placeholderTopPosition = this.placeholderRef.getBoundingClientRect().top;

        if (placeholderTopPosition <= 0) {
            this.contentContainerRef.style.top = 0;
        } else {
            this.contentContainerRef.style.top = `${this.placeholderRefTopPosition}px`;
        }
    }

    setPlaceholderStyle() {
        if (this.placeholderRef) {
            this.placeholderRef.style.paddingTop = `${this.contentRef.getBoundingClientRect().height}px`;
        }
    }

    render() {
        const { children, className, style } = this.props;
        const containerClasses = ClassNames('ui', 'sticky', className);
        const childrenCount = React.Children.count(children);

        if (childrenCount === 0 || childrenCount > 1) {
            // eslint-disable-next-line no-console
            console.error('Please wrap Sticky\'s children in an enclosing tag');

            return null;
        }

        return (
            <React.Fragment>
                <div ref={(ref) => { this.placeholderRef = ref; }} />

                <div
                    className={containerClasses}
                    ref={(ref) => { this.contentContainerRef = ref; }}
                    style={{
                        left: 0,
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        style,
                    }}
                >
                    <div
                        ref={(ref) => { this.contentRef = ref; }}
                    >
                        {children}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Sticky.propTypes = propTypes;
Sticky.defaultProps = defaultProps;

export default Sticky;
