import _ from 'lodash';

export const DEFAULT_STATE = {
    servingOpportunity: {},
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunityDraftActions.ON_GET':
        case 'MinistryServingOpportunityDraftActions.ON_ADD':
        case 'MinistryServingOpportunityDraftActions.ON_UPDATE':
            return { ...(_.isEmpty(action.result) ? DEFAULT_STATE : action.result) };
    }
    return state;
}
