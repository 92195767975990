import _ from 'lodash';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';
import LoginConstants from '../../constants/Login/LoginConstants';

const _permissionUsageUsersCache = new ChunkedPaginationUtils(25, 25);

export const DEFAULT_STATE = {
    flatList: [],
    hierarchy: [],
    usage: {},
};

function selectBoxTransform(permissions) {
    return _.map(permissions, (p) => ({ label: p.name, sensitive: p.isSensitive, value: p.id }));
}

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
        case 'PermissionActions.RESET':
            return DEFAULT_STATE;

        case 'PermissionActions.ON_GETALL':
            return { ...state, flatList: selectBoxTransform(action.result) };

        case 'PermissionActions.ON_GETHIERARCHY':
            return { ...state, hierarchy: action.result };

        case 'PermissionActions.ON_GETUSAGEINROLES':
            return {
                ...state,
                usage: {
                    ...state.usage,
                    roleTotal: action.result.totalCount,
                    roles: action.result.roles,
                    users: state.usage.users || {},
                },
            };

        case 'PermissionActions.ON_GETUSAGEAMONGUSERS':
            _permissionUsageUsersCache.loadPage(
                action.result.users,
                action.result.count,
                action.params.pageNumber === 0,
            );

            // Deliberately falling through to next case

        case 'PermissionActions.ON_USAGEAMONGUSERSNEXTPAGE':
            return {
                ...state,
                usage: {
                    ...state.usage,
                    users: {
                        canLoadMore: _permissionUsageUsersCache.canLoadMore(),
                        needsToRequest: _permissionUsageUsersCache.needsToLoadPage(),
                        pageNumber: _permissionUsageUsersCache.getCurrentPageNumber(),
                        pageSize: _permissionUsageUsersCache.getPageSize(),
                        results: _permissionUsageUsersCache.getAll(true),
                        total: _permissionUsageUsersCache.getTotalCount(),
                    },
                },
            };

        case 'PermissionActions.RESET_USAGE':
            return { ...state, usage: {} };
    }

    return state;
}
