import React, {
    createRef,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import {
    Button,
    Drawer,
    Icon,
    Image,
    Page,
    PersonCoreMilestones,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import {
    debounce,
    get,
    isEqual,
    isFunction,
    isNil,
} from 'lodash';
import Scrollbar from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import Person from '../../connectionForms/public/models/personDetails.model';
import { translationFactory } from '../../global/i18nUtils.js';
import UserAccessStore from '../../global/userAccessStore.js';
import { USER_PERMISSIONS } from '../../global/userPermissionConstants.js';
import CoreMilestones from '../global/models/personCoreMilestonesData.model';
import DetailsWindowPersonInfo from './detailsWindowPersonInfo.jsx';
import personNotesActions from './global/personNotesActions.js';
import * as personRecordActions from './global/personRecord.actions.js';
import { setIsNotesDrawerOpen as setIsNotesDrawerOpenAction } from './global/personRecord.actions.js';
import { getRecordType } from './global/utils.js';
import {
    DetailsWindowType,
    Tab,
} from './models';
import Tabs from './tabs';

type PropTypes = {
    blockSize?: number;
    blockType: string;
    coreMilestonesData?: CoreMilestones;
    detailsWindow: DetailsWindowType;
    isDrawerChild: boolean;
    isMobile: boolean;
    isNotesDrawerOpen: boolean;
    noteFormStateChange: boolean;
    onTabClick: ({ selectedTabIndex } : { selectedTabIndex: number; }) => void;
    /**
     * data passed down directly to enable anonymous mode,
     * if we don't have a person id, 'personData' prop will be empty,
     * so we take 'person' prop to retrieve some info needed for <DetailsWindowPersonInfo />
     */
    person: Person | null;
    /**
     * from redux, record.jsx fetches this data using the personId (in anonymous mode this will be empty).
     */
    personData: Person | null;
    personId: number;
    selectedTabIndex: number;
    setIsNotesDrawerOpen: (isNotesDrawerOpen: boolean) => void;
    tabs: Tab[];
    userAccess: UserAccessStore;
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        breakpoint: {
            isMobile,
        },
        people: {
            record: {
                coreMilestones: {
                    data: coreMilestonesData,
                },
                index: {
                    blockType,
                    detailsWindow,
                    isNotesDrawerOpen,
                },
                person,
                notes: {
                    noteFormStateChange,
                },
            },
        },
    } = state;

    return {
        blockType,
        coreMilestonesData,
        detailsWindow,
        isMobile,
        isNotesDrawerOpen,
        noteFormStateChange,
        personData: person.data,
        userAccess,
    };
};

const mapDispatchToProps = {
    setIsNotesDrawerOpen: setIsNotesDrawerOpenAction,
};

const i18n = translationFactory('Person.Record.DetailsWindow');
const LG_DW_SHADOW_HEIGHT = 8;
const SCROLLBAR_CHANGE_DEBOUNCE_TIME = 50;

function DetailsWindow(props: PropTypes) {
    const {
        blockSize,
        blockType,
        coreMilestonesData,
        detailsWindow,
        isDrawerChild,
        isMobile,
        isNotesDrawerOpen,
        noteFormStateChange,
        onTabClick,
        person,
        personData,
        personId,
        selectedTabIndex,
        setIsNotesDrawerOpen,
        tabs,
        userAccess,
    } = props;

    const detailsWindowRef: React.RefObject<HTMLDivElement> = createRef();
    const horizontalThumbWidth = useRef();
    const [isHorizontalScrollbarActive, setIsHorizontalScrollbarActive] = useState(false);
    const scrollBar = createRef();
    const shadowRef: React.RefObject<HTMLDivElement> = createRef();
    const prevDetailsWindowRef = useRef<HTMLDivElement>();
    const prevShadowRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const { current: dwEl } = detailsWindowRef;
        const { current: dwBottomShadow } = shadowRef;
        const shouldUpdateDetailsWindowElements = dwEl && dwBottomShadow &&
            !isEqual(prevDetailsWindowRef.current, dwEl) &&
            !isEqual(prevShadowRef.current, dwBottomShadow) &&
            (
                dwEl.querySelector('.drawer--details_window_columns_container > div') ||
                dwEl.querySelector('.page--details_window_columns_container > div')
            );

        if (shouldUpdateDetailsWindowElements) {
            const blockName = 'person_record';
            const dwElName = 'details_window';
            const perAvElName = 'person_avatar';

            personRecordActions.setElements({
                dw: dwEl,
                dwBottomShadow,
                dwCoreMilestonesCol: dwEl.querySelector(`.${blockName}--${dwElName}_core_milestones`)?.parentElement?.parentElement,
                dwInner: dwEl.querySelector(`.${blockName}--${dwElName} > div`),
                dwPersonAvatar: dwEl.querySelector(`.${blockName}--${perAvElName}`),
                dwPersonAvatarCol: dwEl.querySelector(`.${blockName}--${perAvElName}`).parentElement.parentElement,
                dwSubNav: dwEl.querySelector(`.${blockName}--${dwElName}_sub_nav`).parentElement.parentElement,
            });

            prevDetailsWindowRef.current = dwEl;
            prevShadowRef.current = dwBottomShadow;
        }
    }, [detailsWindowRef, shadowRef]);

    const setIsScrollbarActiveDebounced = debounce((isScrollActive) => {
        setIsHorizontalScrollbarActive(isScrollActive);
    }, SCROLLBAR_CHANGE_DEBOUNCE_TIME);

    useEffect(() => {
        const currentHorizontalThumbWidth = get(scrollBar, 'current.thumbHorizontal.clientWidth', 0);
        if (horizontalThumbWidth.current !== currentHorizontalThumbWidth) {
            // @ts-ignore
            horizontalThumbWidth.current = currentHorizontalThumbWidth;
            const isScrollActive = currentHorizontalThumbWidth > 0;
            setIsScrollbarActiveDebounced(isScrollActive);
        }
    });

    const onNotesDrawerToggle = useCallback(() => {
        personNotesActions.setWingType();
        setIsNotesDrawerOpen(!isNotesDrawerOpen);
    }, [isNotesDrawerOpen, setIsNotesDrawerOpen]);

    if (isNil(blockSize)) {
        return null;
    }

    const {
        gender,
        isAdult,
        isChild,
        deceasedDate,
    } = personData;

    const isBlockWidthMdUp = blockSize >= 768;
    const blockClassName = 'person_record--details_window';
    const isStudent = !isAdult && !isChild;

    const containerClasses = ClassNames(blockClassName, {
        [`${blockClassName}-gender_child`]: isChild,
        [`${blockClassName}-gender_student`]: isStudent,
        [`${blockClassName}-gender_adult_female`]: gender === 'F' && isAdult,
        [`${blockClassName}-gender_adult_male`]: gender === 'M' && isAdult,
        [`${blockClassName}-gender_system_undefined`]:
            personData.gender !== 'F' && gender !== 'M',
        [`${blockClassName}-disabled`]: !isNil(deceasedDate),
    });

    const isBlockTypePage = blockType === 'page';
    const isBlock375Thru767 = blockSize < 768;
    const isBlock518Thru767 = blockSize >= 518 && blockSize <= 767;
    const isBlockMd = blockSize >= 768;

    const canReadPersonNote = userAccess.hasPermission(
        USER_PERMISSIONS.readPersonNotes,
    );

    let avatarStyle: React.CSSProperties = {
        width: 'auto',
    };

    let coreMilestonesColumnStyle: React.CSSProperties = {
        width: 'auto',
    };

    let subNavigationStyle: React.CSSProperties = {
        margin: '0 -11px',
        width: 'calc(100% + 22px)',
    };

    const notesActionBtnStyle = {
        alignItems: 'flex-start',
        display: 'inline-flex',
        flexBasis: '54px',
        justifyContent: 'flex-end',
        padding: '20px 11px 0',
        marginRight: '-22px',
    };

    if (isBlockTypePage && !isMobile && isBlock518Thru767) {
        avatarStyle = {
            ...avatarStyle,
            paddingLeft: '11px',
        };

        coreMilestonesColumnStyle = {
            ...coreMilestonesColumnStyle,
            marginTop: '-22px',
            padding: '0 11px 33px',
        };

        subNavigationStyle = {
            ...subNavigationStyle,
            padding: '6px 0',
        };
    } else if (isBlock375Thru767) {
        coreMilestonesColumnStyle = {
            ...coreMilestonesColumnStyle,
            marginTop: '-11px',
            padding: '0 11px 22px',
        };

        subNavigationStyle = {
            ...subNavigationStyle,
            padding: 0,
        };
    } else if (isBlockMd) {
        avatarStyle = {
            ...avatarStyle,
            paddingLeft: '11px',
        };

        coreMilestonesColumnStyle = {
            ...coreMilestonesColumnStyle,
            display: 'flex',
            justifyContent: 'flex-end',
            flexBasis: '222px',
            minHeight: '84px',
            padding: '0 11px',
        };

        subNavigationStyle = {
            ...subNavigationStyle,
            margin: 0,
            padding: '0 11px',
            width: 'calc(100% - 32px)',
        };
    }

    const isBlockTypeDrawer = blockType === 'drawer';

    if (isBlockTypeDrawer && isBlockMd) {
        subNavigationStyle = {
            ...subNavigationStyle,
            width: 'calc(100% - 54px)',
        };
    }

    const coreMilestones = {
        accessor: () => (
            <PersonCoreMilestones
                backgroundTransparent
                className={`${blockClassName}_core_milestones`}
                data={{
                    ...coreMilestonesData,
                    gender: personData.gender,
                    hasTakenClass101: coreMilestonesData.hasAttendedClass101,
                    hasTakenClass201: coreMilestonesData.hasAttendedClass201,
                    hasTakenClass301: coreMilestonesData.hasAttendedClass301,
                    hasTakenClass401: coreMilestonesData.hasAttendedClass401,
                    recordType: getRecordType(personData),
                }}
                iconColor="#fff"
                iconSize={isMobile ? 16 : 24}
                id={`${blockClassName}_core_milestones`}
                inverse
                isMobile={isMobile || !isBlockMd}
                parentConsumer="personRecord"
            />
        ),
        header: null,
        id: `${blockClassName}_core_milestones`,
        style: coreMilestonesColumnStyle,
    };

    const scrollableTabsClasses = ClassNames(
        `${blockClassName}--custom_scrollbar`,
        {
            [`${blockClassName}--custom_scrollbar-active`]: isHorizontalScrollbarActive,
            [`${blockClassName}--custom_scrollbar-mobile`]: isMobile,
            [`${blockClassName}--custom_scrollbar-desktop`]: !isMobile,
        },
    );

    const subNavigation = {
        accessor: () => (
            <Scrollbar
                autoHeight
                autoHide
                autoHideTimeout={300}
                className={scrollableTabsClasses}
                ref={scrollBar}
                renderView={(scrollBarProps) => (
                    <div
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...scrollBarProps}
                        className={`${blockClassName}--custom_scrollbar-view`}
                        style={{
                            ...scrollBarProps.style,
                            paddingTop: 0,
                        }}
                    />
                )}
            >
                <Tabs
                    isDrawerChild={isDrawerChild}
                    onTabClick={onTabClick}
                    selectedTabIndex={selectedTabIndex}
                    tabs={tabs}
                />
            </Scrollbar>
        ),
        id: `${blockClassName}_sub_navigation`,
        style: subNavigationStyle,
    };

    let notesActionButton = [];

    if (canReadPersonNote) {
        notesActionButton = [{
            accessor: () => (
                <React.Fragment>
                    {!isNotesDrawerOpen && (
                        <Button
                            className="person_record--details_window_notes_action_btn"
                            color={noteFormStateChange ? 'warning' : 'light'}
                            icon
                            id="ui-button--details_window_notes_action_btn"
                            onClick={onNotesDrawerToggle}
                            outlined={!noteFormStateChange}
                        >
                            <Icon
                                size="medium"
                                title={i18n('Notes')}
                                type="text-edit"
                            />
                        </Button>
                    )}
                </React.Fragment>
            ),
            id: `${blockClassName}_notes_action_btn`,
            style: notesActionBtnStyle,
        }];
    }

    let columnsMd = [];
    let columnsSm = [];

    const defaultColumns = [
        {
            accessor: (d) => {
                const {
                    firstName,
                    lastName,
                    profilePictureUrl,
                    profilePhotoUrl,
                } = d;

                const personAvatar = profilePictureUrl || profilePhotoUrl;
                const personFullName = personId ? `${firstName} ${lastName}` : null;

                return (
                    <Image
                        border={3}
                        borderInverse
                        className="person_record--person_avatar"
                        name={personFullName}
                        size={100}
                        src={personAvatar}
                        style={{
                            boxShadow: '0 8px 25px 0 rgba(0, 0, 0, .33)',
                            fontSize: '24px',
                        }}
                        type="person"
                    />
                );
            },
            style: avatarStyle,
        }, {
            accessor: () => (
                <DetailsWindowPersonInfo
                    person={person}
                    personId={personId}
                />
            ),
            fontWeight: 'bold',
            header: null,
            style: {
                flexBasis: '231px',
                flexGrow: 1,
                paddingLeft: '6px',
                paddingRight: isBlockTypePage && !isMobile && isBlock518Thru767 && '11px',
            },
        },
    ];

    if (isBlockMd) {
        columnsMd = [
            ...defaultColumns,
            coreMilestones,
            {
                style: {
                    height: 0,
                    padding: 0,
                    width: '100%',
                }, // This is an empty column to break all columns after to new row.
            },
            subNavigation,
            ...notesActionButton,
        ];
    } else {
        columnsSm = [
            ...defaultColumns,
            coreMilestones,
            subNavigation,
        ];
    }

    if (
        detailsWindow &&
        detailsWindow.md &&
        isFunction(detailsWindow.md.columns)
    ) {
        columnsMd = detailsWindow.md.columns({
            defaultColumns: columnsMd,
            detailsWindowProps: props,
        });
    }

    if (
        detailsWindow &&
        detailsWindow.sm &&
        isFunction(detailsWindow.sm.columns)
    ) {
        columnsSm = detailsWindow.sm.columns({
            defaultColumns: columnsSm,
            detailsWindowProps: props,
        });
    }

    const RecordDetailsWindow = isDrawerChild ? Drawer.DetailsWindow : Page.DetailsWindow;

    return (
        <React.Fragment>
            <RecordDetailsWindow
                className={containerClasses}
                columnProps={{ horizontalSpacing: 11 }}
                columns={isBlockMd ? columnsMd : columnsSm}
                data={personData}
                ref={detailsWindowRef}
            />

            <div
                className={`${blockClassName}_bottom_shadow`}
                ref={shadowRef}
                style={{
                    height: `${LG_DW_SHADOW_HEIGHT}px`,
                    opacity: isBlockWidthMdUp && 0,
                }}
            />
        </React.Fragment>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsWindow) as any;
