import _ from 'lodash';
import ChunkedPaginationUtils from '../../../../global/chunkedPaginationUtils.js';

const localCache = new ChunkedPaginationUtils(25, 50);

export const DEFAULT_STATE = {
    results: [],
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: localCache.getPageSize(),
};

export default function (state = DEFAULT_STATE, action) {
    let first;

    switch (action.type) {
        case 'EventAttendeeActions.ON_GETFILTEREDHISTORIC':
            first = _.get(action, 'callbackParams.first', true);

            localCache.loadPage(
                action.result.attendees,
                action.result.total,
                first,
            );

        // eslint-disable-next-line no-fallthrough
        case 'EventAttendeeActions.ON_GET_HISTORICNEXTPAGE':
            return {
                results: localCache.getAll(true),
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };

        case 'EventAttendeeActions.ON_CLEARHISTORICATTENDEES':
            return DEFAULT_STATE;

        default:
    }

    return state;
}
