import {
    isEqual,
    isNil,
} from 'lodash';
import {
    SORT_OPTIONS,
} from './constants';
import {
    DesignationFilterStatusId,
    DesignationFilterPublishedStatusId,
    DesignationFilterTypeId,
    Filters,
    FilterState,
} from './models';
import {
    getFilteredArrayByIsChecked,
} from './utils';
import ActionType from './listPage.actionTypes';

export const DEFAULT_FILTER_STATE: Filters = {
    designationStatuses: [
        DesignationFilterStatusId.Active,
        DesignationFilterStatusId.Inactive,
    ],
    designationTypes: [
        DesignationFilterTypeId.General,
        DesignationFilterTypeId.Fund,
        DesignationFilterTypeId.Project,
    ],
    publishedStatuses: [
        DesignationFilterPublishedStatusId.Yes,
        DesignationFilterPublishedStatusId.No,
    ],
    sort: SORT_OPTIONS[0],
};

export const DEFAULT_STATE: FilterState = {
    appliedFilters: { ...DEFAULT_FILTER_STATE },
    areFiltersDirty: false,
    dirtyFilters: { ...DEFAULT_FILTER_STATE },
    isFiltering: false,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, DEFAULT_FILTER_STATE);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (
    state: FilterState | undefined,
    action: any,
): FilterState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.SET_APPLY_FILTERS: {
                const { dirtyFilters } = state;
                const isFiltering = !isEqual(dirtyFilters, DEFAULT_FILTER_STATE);

                return {
                    ...state,
                    appliedFilters: { ...dirtyFilters },
                    areFiltersDirty: false,
                    isFiltering,
                };
            }

            case ActionType.SET_CLEAR_FILTERS: {
                return updateDirtyFilterState(state, { ...DEFAULT_FILTER_STATE });
            }

            case ActionType.SET_APPLY_SORT_ORDER: {
                const nextAppliedFilters = { ...state.appliedFilters, sort: action.value };
                return updateAppliedFilterState(state, nextAppliedFilters);
            }

            case ActionType.SET_AND_APPLY_DESIGNATION_STATUS_FILTER_CHANGE: {
                const { designationStatusId, isChecked } = action.value;

                const updatedFilters = {
                    ...state.appliedFilters,
                    designationStatuses: getFilteredArrayByIsChecked(
                        state.appliedFilters.designationStatuses,
                        DEFAULT_FILTER_STATE.designationStatuses,
                        designationStatusId,
                        isChecked,
                    ),
                };

                return updateAppliedFilterState(state, updatedFilters);
            }

            case ActionType.SET_AND_APPLY_DESIGNATION_PUBLISHED_STATUS_FILTER_CHANGE: {
                const { designationPublishedStatusId, isChecked } = action.value;

                const updatedFilters = {
                    ...state.appliedFilters,
                    publishedStatuses: getFilteredArrayByIsChecked(
                        state.appliedFilters.publishedStatuses,
                        DEFAULT_FILTER_STATE.publishedStatuses,
                        designationPublishedStatusId,
                        isChecked,
                    ),
                };

                return updateAppliedFilterState(state, updatedFilters);
            }

            case ActionType.SET_AND_APPLY_DESIGNATION_TYPE_FILTER_CHANGE: {
                const { designationTypeId, isChecked } = action.value;

                const updatedFilters = {
                    ...state.appliedFilters,
                    designationTypes: getFilteredArrayByIsChecked(
                        state.appliedFilters.designationTypes,
                        DEFAULT_FILTER_STATE.designationTypes,
                        designationTypeId,
                        isChecked,
                    ),
                };

                return updateAppliedFilterState(state, updatedFilters);
            }

            case ActionType.SET_DIRTY_SORT_ORDER: {
                return updateDirtyFilterState(
                    state,
                    { ...state.dirtyFilters, sort: action.value },
                );
            }

            case ActionType.SET_DIRTY_DESIGNATION_STATUS_FILTER_CHANGE: {
                const { designationStatusId, isChecked } = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    designationStatuses: getFilteredArrayByIsChecked(
                        state.dirtyFilters.designationStatuses,
                        DEFAULT_FILTER_STATE.designationStatuses,
                        designationStatusId,
                        isChecked,
                    ),
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_DESIGNATION_PUBLISHED_STATUS_FILTER_CHANGE: {
                const { designationPublishedStatusId, isChecked } = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    publishedStatuses: getFilteredArrayByIsChecked(
                        state.dirtyFilters.publishedStatuses,
                        DEFAULT_FILTER_STATE.publishedStatuses,
                        designationPublishedStatusId,
                        isChecked,
                    ),
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_DESIGNATION_TYPE_FILTER_CHANGE: {
                const { designationTypeId, isChecked } = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    designationTypes: getFilteredArrayByIsChecked(
                        state.dirtyFilters.designationTypes,
                        DEFAULT_FILTER_STATE.designationTypes,
                        designationTypeId,
                        isChecked,
                    ),
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            default:
                return state;
        }
    }

    return state;
};
