import {
    filter,
    isEmpty,
    isEqual,
    isNumber,
    isString,
    map,
    orderBy,
    size,
    split,
    trim,
} from 'lodash';
import {
    calculateSearchRelevancyScore,
    SEARCH_RELEVANCY_THRESHOLD,
} from '../../../../global/utils';

const DEFAULT_FILTER_OPTIONS = {
    category: null,
    sortBy: 'name',
    status: null,
};

function sortRooms(state, rooms) {
    let field;
    let order;
    const options = split(state.filterOptions.sortBy, '-');

    if (size(options) > 1) {
        [, field] = options;
        order = 'desc';
    } else {
        [field] = options;
        order = 'asc';
    }

    return orderBy(rooms, [field], order);
}

function applySearchQuery(state, subVenueCache, venueCache) {
    const searchQuery = trim(state.searchQuery);

    const scoredRooms = map(state.originRooms, (v) => {
        const subVenue = subVenueCache[v.subVenueId];
        const venue = venueCache[subVenue?.venueId];
        let spaceNameScore = 1.0;
        let venueNameScore = 1.0;

        if (!isEmpty(searchQuery)) {
            if (!isEmpty(subVenue?.name)) {
                spaceNameScore = calculateSearchRelevancyScore(subVenue?.name, searchQuery);
            }

            if (!isEmpty(venue?.name)) {
                venueNameScore = calculateSearchRelevancyScore(venue?.name, searchQuery);
            }
        }

        return {
            ...v,
            name: subVenue?.name,
            spaceNameScore,
            venueNameScore,
        };
    });

    return filter(
        scoredRooms,
        (v) => v.spaceNameScore >= SEARCH_RELEVANCY_THRESHOLD ||
            v.venueNameScore >= SEARCH_RELEVANCY_THRESHOLD,
    );
}

export const DEFAULT_STATE = {
    areFiltersDirty: false,
    filterOptions: DEFAULT_FILTER_OPTIONS,
    searchQuery: '',
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'EventRoomCheckInActions.ON_GET':
            return {
                ...state,
                ...action.result,
                originRooms: action.result.rooms,
                rooms: sortRooms(state, action.result.rooms),
            };

        case 'EventRoomCheckInActions.ON_RESET':
            return DEFAULT_STATE;

        case 'EventRoomCheckInActions.ON_SEARCH_QUERY':
            return {
                ...state,
                searchQuery: action.query,
            };

        case 'EventRoomCheckInActions.ON_FILTERS':
            return {
                ...state,
                areFiltersDirty: !isEqual(action.filters, DEFAULT_FILTER_OPTIONS),
                filterOptions: action.filters,
            };

        case 'EventRoomCheckInActions.ON_APPLY_FILTERS': {
            const {
                subVenueCache,
                venueCache,
            } = action;

            let rooms = applySearchQuery(state, subVenueCache, venueCache);

            if (isNumber(state.filterOptions.category)) {
                rooms = filter(rooms, { roomCategoryId: state.filterOptions.category });
            }

            if (isString(state.filterOptions.status)) {
                if (state.filterOptions.status === 'open') {
                    rooms = filter(rooms, (v) => v.openTime && !v.closeTime);
                } else {
                    rooms = filter(rooms, (v) => v.closeTime || !v.openTime);
                }
            }

            return {
                ...state,
                rooms: sortRooms(state, rooms),
            };
        }

        case 'EventRoomCheckInActions.ON_CLEAR_FILTERS':
            return {
                ...state,
                ...DEFAULT_STATE,
                rooms: sortRooms(DEFAULT_STATE, state.originRooms),
            };

        default:
            return state;
    }
};
