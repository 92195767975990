import { i18n } from '../../../../global/constants.js';
import {
    ACTION_NOTIFY_ATTENDEES_ID,
    ACTION_NOTIFY_EVERYONE_ID,
    ACTION_NOTIFY_PARENTS_ID,
    ACTION_NOTIFY_VOLUNTEERS_ID,
} from '../constants.js';

export const TEMPLATE_NAME_CUSTOM_MESSAGE = 'Custom Message';
export const TEMPLATE_NAME_EMERGENCY_EVACUATION_ATTENDEES_VOLUNTEERS = 'Emergency Evacuation Attendees / Volunteers';
export const TEMPLATE_NAME_EMERGENCY_EVACUATION_ADULTS_PARENTS = 'Emergency Evacuation Adults / Parents';
export const TEMPLATE_NAME_ENJOY_THE_SERVICE = 'Doing Great';

export const MESSAGE_TYPE_OPTIONS = {
    [ACTION_NOTIFY_ATTENDEES_ID]: [
        {
            label: i18n(TEMPLATE_NAME_CUSTOM_MESSAGE),
            text: '',
            value: TEMPLATE_NAME_CUSTOM_MESSAGE,
        },
        {
            label: i18n(TEMPLATE_NAME_EMERGENCY_EVACUATION_ATTENDEES_VOLUNTEERS),
            text: '',
            value: TEMPLATE_NAME_EMERGENCY_EVACUATION_ATTENDEES_VOLUNTEERS,
        },
    ],
    [ACTION_NOTIFY_EVERYONE_ID]: [
        {
            label: i18n(TEMPLATE_NAME_CUSTOM_MESSAGE),
            text: '',
            value: TEMPLATE_NAME_CUSTOM_MESSAGE,
        },
        {
            label: i18n(TEMPLATE_NAME_EMERGENCY_EVACUATION_ATTENDEES_VOLUNTEERS),
            text: '',
            value: TEMPLATE_NAME_EMERGENCY_EVACUATION_ATTENDEES_VOLUNTEERS,
        },
    ],
    [ACTION_NOTIFY_PARENTS_ID]: [
        {
            label: i18n(TEMPLATE_NAME_CUSTOM_MESSAGE),
            text: '[[ChildName]] [[RoomName]]',
            value: TEMPLATE_NAME_CUSTOM_MESSAGE,
        },
        {
            label: i18n(TEMPLATE_NAME_EMERGENCY_EVACUATION_ADULTS_PARENTS),
            text: '',
            value: TEMPLATE_NAME_EMERGENCY_EVACUATION_ADULTS_PARENTS,
        },
        {
            label: i18n(TEMPLATE_NAME_ENJOY_THE_SERVICE),
            text: '',
            value: TEMPLATE_NAME_ENJOY_THE_SERVICE,
        },
    ],
    [ACTION_NOTIFY_VOLUNTEERS_ID]: [
        {
            label: i18n(TEMPLATE_NAME_CUSTOM_MESSAGE),
            text: '',
            value: TEMPLATE_NAME_CUSTOM_MESSAGE,
        },
        {
            label: i18n(TEMPLATE_NAME_EMERGENCY_EVACUATION_ATTENDEES_VOLUNTEERS),
            text: '',
            value: TEMPLATE_NAME_EMERGENCY_EVACUATION_ATTENDEES_VOLUNTEERS,
        },
    ],
};
