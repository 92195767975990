enum ActionType {
    AddButton = 'DigitalProgram.ADD_BUTTON',
    CancelButtonOngoingChanges = 'DigitalProgram.CANCEL_BUTTON_ONGOING_CHANGES',
    ResetForm = 'DigitalProgram.RESET_FORM',
    SetButtonFormState = 'DigitalProgram.SET_BUTTON_FORM_STATE',
    SetCampuses = 'DigitalProgram.SET_CAMPUSES',
    SetCampusServices = 'DigitalProgram.SET_CAMPUS_SERVICES',
    SetEntities = 'DigitalProgram.SET_ENTITIES',
    SetField = 'DigitalProgram.SET_FIELD',
    SetInfoField = 'DigitalProgram.SET_INFO_FIELD',
    SetInitialState = 'DigitalProgram.SET_INITIAL_STATE',
    SetSelectedCampus = 'DigitalProgram.SET_SELECTED_CAMPUS',
    SetSelectedServices = 'DigitalProgram.SET_SELECTED_SERVICES',
    SetStep = 'DigitalProgram.SET_STEP',
    UpdateButtonAfterUpsert = 'DigitalProgram.UPDATE_BUTTON_AFTER_UPSERT',
}

export default ActionType;
