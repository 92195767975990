import { combineReducers } from 'redux';
import list from './list/list.reducer';
import ticketEditor from './ticketEditor/ticketEditor.reducer';

const reducers = combineReducers({
    ticketEditor,
    list,
});

export default reducers;
