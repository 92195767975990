import { get } from 'lodash';

export const DEFAULT_STATE = null;

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventAttendeeCheckInActions.ON_GETROOMATTENDEES': {
            const cached = get(action, 'callbackParams.cached', true);
            return cached ? get(action.result, 'attendees', []) : state;
        }

        case 'EventAttendeeCheckInActions.ON_BEFORE_GETROOMATTENDEES': {
            const cached = get(action, 'callbackParams.cached', true);
            return cached ? DEFAULT_STATE : state;
        }

        case 'EventAttendeeCheckInActions.ON_FAILED_GETROOMATTENDEES': {
            const cached = get(action, 'callbackParams.cached', true);
            return cached ? [] : state;
        }

        default:
            return state;
    }
}
