import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';

class EmergencyContactFormActions extends ApiUtils {
    resetSelectedEmergencyContact() {
        appReduxStore.dispatch({
            type: 'EmergencyContactFormActions.ON_RESET_SELECTED_EMERGENCY_CONTACT',
        });
    }

    addNewSelectedEmergencyContact(selectedPerson) {
        appReduxStore.dispatch({
            type: 'EmergencyContactFormActions.ON_ADD_NEW_EMERGENCY_CONTACT',
            value: selectedPerson,
        });
    }

    updateRelationshipEmergencyContact(selectedPerson) {
        appReduxStore.dispatch({
            type: 'EmergencyContactFormActions.ON_CHANGE_RELATIONSHIP_EMERGENCY_CONTACT',
            value: selectedPerson,
        });
    }

    removeSelectedEmergencyContact(personId) {
        appReduxStore.dispatch({
            type: 'EmergencyContactFormActions.ON_REMOVE_SELECTED_EMERGENCY_CONTACT',
            value: personId,
        });
    }
}

const actions = new EmergencyContactFormActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'get',
        route: 'person/{personId}/emergency-contact',
        rq: request.get,
    }, {
        name: 'save',
        route: 'person/{personId}/emergency-contact',
        rq: request.post,
    }, {
        name: 'delete',
        route: 'person/{personId}/emergency-contact/delete',
        rq: request.post,
    }, {
        name: 'getExistingRelationship',
        route: 'person/{personId}/relationship',
        rq: request.get,
    }, {
        name: 'getRelation',
        route: 'person/relation',
        rq: request.get,
    },
);

export default actions;
