import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

export const chunkLoader = new ChunkedPaginationUtils(25, 50);

export const DEFAULT_STATE = {
    results: [],
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: chunkLoader.getPageSize(),
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHRECIPIENTS':
            chunkLoader.loadPage(
                action.result.volunteers, action.result.total, action.callbackParams.first,
            );
            return {
                results: chunkLoader.getAll(true),
                total: chunkLoader.getTotalCount(),
                canLoadMore: chunkLoader.canLoadMore(),
                needsToRequest: chunkLoader.needsToLoadPage(),
                pageNumber: chunkLoader.getCurrentPageNumber(),
                pageSize: chunkLoader.getPageSize(),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHRECIPIENTS_NEXTPAGE':
            return {
                results: chunkLoader.getAll(true),
                total: chunkLoader.getTotalCount(),
                canLoadMore: chunkLoader.canLoadMore(),
                needsToRequest: chunkLoader.needsToLoadPage(),
                pageNumber: chunkLoader.getCurrentPageNumber(),
                pageSize: chunkLoader.getPageSize(),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_SEARCH_RECIPIENTS':
            return DEFAULT_STATE;

        default:
            return state;
    }
}
