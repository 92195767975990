const DEFAULT_STATE = {
    isFetching: false,
    person: {},
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'PersonActions.ON_BEFORE_GET':
            return { ...state, isFetching: true };

            break;
        case 'PersonActions.ON_GET':
            return {
                ...state,
                isFetching: DEFAULT_STATE.isFetching,
                person: actionResult,
            };
            break;

        case 'PersonActions.ON_RESET':
            return {
                ...state,
                isFetching: DEFAULT_STATE.isFetching,
                person: DEFAULT_STATE.person,
            };

            break;
    }

    return state;
};
