import NoteReminderType from './noteReminders/models/NoteReminderType.model';

export const DEFAULT_STATE = {
    formData: {
        categoryId: null,
        purpose: '',
        noteId: null,
        subject: '',
        noteText: '',
        reminder: null,
    },
    hasCreateReminderButtonBeenClicked: false,
    isReminderFormModalOpen: false,
    mobileNextWingType: null,
    reminderFormData: null,
    reminderModalOpenFrom: null,
    selectedNote: null,
    selectedReminder: null,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PersonNotesActions.ON_UPDATE_CATEGORY': {
            const { value: categoryId } = action;
            return {
                ...state,
                formData: {
                    ...state.formData,
                    categoryId,
                },
            };
        }

        case 'PersonNotesActions.ON_UPDATE_PURPOSE': {
            const { value: purpose } = action;
            return {
                ...state,
                formData: {
                    ...state.formData,
                    purpose,
                },
            };
        }

        case 'PersonNotesActions.ON_UPDATE_SUBJECT': {
            const { value: subject } = action;
            return {
                ...state,
                formData: {
                    ...state.formData,
                    subject,
                },
            };
        }

        case 'PersonNotesActions.ON_UPDATE_NOTE_TEXT': {
            const { value: noteText } = action;
            return {
                ...state,
                formData: {
                    ...state.formData,
                    noteText,
                },
            };
        }

        case 'PersonNotesActions.ON_GETNOTE': {
            return {
                ...state,
                selectedNote: action.result,
            };
        }

        case NoteReminderType.ResetReminderForm: {
            return {
                ...state,
                reminderFormData: null,
                isReminderFormModalOpen: false,
                hasCreateReminderButtonBeenClicked: false,
            };
        }

        case NoteReminderType.SetReminderFormData: {
            return {
                ...state,
                reminderFormData: {
                    ...state.reminderFormData,
                    [action.field]: action.value,
                },
            };
        }

        case NoteReminderType.SetReminderFormModalOpen: {
            const {
                isReminderFormModalOpen,
                reminderModalOpenFrom,
            } = action;
            const openFrom = isReminderFormModalOpen ? reminderModalOpenFrom : null;
            return {
                ...state,
                isReminderFormModalOpen: action.isReminderFormModalOpen,
                reminderModalOpenFrom: openFrom,
            };
        }

        case NoteReminderType.SetCreateReminderButtonHasBeenClicked: {
            return {
                ...state,
                hasCreateReminderButtonBeenClicked: action.hasCreateReminderButtonBeenClicked,
            };
        }

        case NoteReminderType.SetSelectedReminder: {
            return {
                ...state,
                selectedReminder: action.selectedReminder,
            };
        }

        case NoteReminderType.SetMobileNextWingType: {
            return {
                ...state,
                mobileNextWingType: action.mobileNextWingType,
            };
        }

        case NoteReminderType.SetSelectedNote: {
            return {
                ...state,
                selectedNote: action.selectedNote,
            };
        }

        case NoteReminderType.SetReminderElementOpenSource: {
            return {
                ...state,
                reminderModalOpenFrom: action.reminderModalOpenFrom,
            };
        }

        case 'PersonNotesActions.ON_SET_NOTE_FORM_DATA': {
            const {
                value: {
                    categoryId,
                    id,
                    noteText,
                    purpose,
                    reminderId,
                    subject,
                },
            } = action;
            return {
                ...state,
                formData: {
                    categoryId,
                    purpose,
                    noteId: id,
                    subject,
                    noteText,
                    reminderId,
                },
            };
        }

        case 'PersonNotesActions.ON_RESET_NOTE_FORM':
            return {
                ...state,
                formData: {
                    ...DEFAULT_STATE.formData,
                },
            };

        default:
            return state;
    }
};
