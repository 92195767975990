import ActionType from './quiz.actionTypes';
import {
    Action,
} from '../../global/models';
import {
    DEFAULT_QUESTION,
} from './global/constants';

const INITIAL_STATE = {
    title: '',
    questions: [DEFAULT_QUESTION],
    storage: [{
        title: '',
        questions: [DEFAULT_QUESTION],
    }],
    storageIndex: 0,
};

const reducer = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SetTitle:
            return { ...state, title: action.payload };
        case ActionType.SetQuestions:
            return { ...state, questions: action.payload };
        case ActionType.SetQuizIds:
            return { ...state, ...action.payload };
        case ActionType.SetStorage:
            return { ...state, storage: action.payload };
        case ActionType.SetStorageIndex:
            return { ...state, storageIndex: action.payload };
        default:
            return state;
    }
};

export default reducer;
