import { map } from 'lodash';
import LoginConstants from '../../js/constants/Login/LoginConstants.js';

export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'WarehouseDashboardActions.ON_GETCAMPUSESBYREGION':
            return map(action.result, (c) => ({ country: c.country, label: c.name, value: c.id }));

        default:
            return state;
    }
};
