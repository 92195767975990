import _ from 'lodash';
import { Button, Checkbox, Divider } from '@saddlebackchurch/react-cm-ui';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import AppSettingsActions from './appSettingsActions.js';
import bootstrapActions from './bootstrapActions.js';
import EnumerationStore from '../js/stores/EnumerationStore.js';
import PersonMilestoneAPIUtils from '../js/utils/Person/Manager/PersonMilestoneAPIUtils.js';

const DATA_COVENANT_MILESTONE_NAME = 'Healthy Church Data Covenant'; // TODO/FIXME: What if a customer tweaks this label?
const DATA_COVENANT_MILESTONE_LOCATION_TEXT = 'Healthy Church Application Login';

const propTypes = {
    closeModal: PropTypes.func.isRequired,
    dataCovenantAgreement: PropTypes.shape({
        active: PropTypes.bool,
        content: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        predecessorId: PropTypes.number,
        title: PropTypes.string,
        version: PropTypes.number,
    }),
    personId: PropTypes.number,
};

const defaultProps = {
    dataCovenantAgreement: {},
    personId: 0,
};

class DataCovenantModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { accepted: false, isAccepting: false };

        this.onAcceptanceCheckChange = this.onAcceptanceCheckChange.bind(this);
        this.onClickAccept = this.onClickAccept.bind(this);
    }

    onAcceptanceCheckChange(id, checked) {
        this.setState({ accepted: checked });
    }

    onClickAccept() {
        const {
            closeModal,
            dataCovenantAgreement: {
                id: agreementId,
            },
            personId,
        } = this.props;

        const dataCovanentMilestoneType = _.find(
            EnumerationStore.getMilestoneTypes(),
            (ms) => ms.name === DATA_COVENANT_MILESTONE_NAME,
        );

        const newDataCovanentMilestone = {
            id: dataCovanentMilestoneType.id,
            dates: [{
                date: moment().utc().startOf('day').unix(),
                location: DATA_COVENANT_MILESTONE_LOCATION_TEXT,
            }],
        };

        this.setState({ isAccepting: true }, () => {
            const createMilestone = PersonMilestoneAPIUtils.create({
                personId,
                sourceOverride: DATA_COVENANT_MILESTONE_LOCATION_TEXT,
            }, newDataCovanentMilestone, null, {
                milestoneId: dataCovanentMilestoneType.id,
            });

            const consentToAgreement = AppSettingsActions.consentToAgreement(
                { personId },
                { agreementId, personId },
            );

            Promise.all([
                createMilestone,
                consentToAgreement,
            ])
                .then(() => {
                    bootstrapActions.getBootstrap().then(() => {
                        closeModal();
                    });
                })
                .catch(() => {
                    this.setState({ isAccepting: false });
                });
        });
    }

    render() {
        const { dataCovenantAgreement } = this.props;
        const { accepted, isAccepting } = this.state;

        return (
            <div>
                {/* eslint-disable react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: dataCovenantAgreement.content }} />
                {/* eslint-enable react/no-danger */}

                <Divider relaxed="very" />

                <Checkbox
                    checked={accepted}
                    id="accept-data-covenant"
                    label="I agree to abide by the above Data Covenant when using the Healthy Church software"
                    onChange={this.onAcceptanceCheckChange}
                />

                <div style={{ marginTop: 33 }}>
                    <Button disabled={isAccepting || !accepted} onClick={this.onClickAccept}>
                        <span>Accept</span>
                    </Button>
                </div>
            </div>
        );
    }
}

DataCovenantModal.propTypes = propTypes;
DataCovenantModal.defaultProps = defaultProps;

export default DataCovenantModal;
