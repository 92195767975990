import AppDispatcher from '../../../dispatcher/AppDispatcher';
import PersonManagerConstants from '../../../constants/Person/Manager/PersonManagerConstants';

export default class PersonManagerActionCreators {
    static getPersonCompleted(person) {
        AppDispatcher.dispatch({
            actionType: PersonManagerConstants.GET_PERSON,
            person,
        });
    }

    static goToPerson(newPersonId) {
        AppDispatcher.dispatch({
            actionType: PersonManagerConstants.GO_TO_PERSON,
            newPersonId,
        });
    }

    static savePersonCompleted(newPerson) {
        AppDispatcher.dispatch({
            actionType: PersonManagerConstants.SAVE_PERSON_COMPLETED,
            person: newPerson,
        });
    }

    static saveMembershipStatusCompleted(newPerson) {
        AppDispatcher.dispatch({
            actionType: PersonManagerConstants.SAVE_MEMBERSHIP_STATUS_COMPLETED,
            person: newPerson,
        });
    }
}
