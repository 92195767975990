import moment from 'moment-timezone';

export default class MomentUtils {
    static get SECONDS_PER_DAY() {
        return 86400;
    }

    static areDurationsEqual(durationA, durationB) {
        const durationAHasValue = moment.isDuration(durationA);
        const durationBHasValue = moment.isDuration(durationB);

        if (!durationAHasValue && !durationBHasValue) {
            return true;
        } if (!durationAHasValue) {
            return false;
        } if (!durationBHasValue) {
            return false;
        } // both are valid moment duration objects
        return durationA.valueOf() === durationB.valueOf();
    }

    static areMomentsEqual(moment1, moment2, timeUnit = 'day') {
        const moment1HasValue = moment.isMoment(moment1);
        const moment2HasValue = moment.isMoment(moment2);

        if (moment1HasValue && moment2HasValue) {
            return moment1.isSame(moment2, timeUnit);
        }
        return !moment1HasValue && !moment2HasValue;
    }

    /**
     * Converts a Moment.JS Duration object into a string format suitable for
     * JSON serialization to/deserialization from the ASP.NET/C# TimeSpan type.
     *
     * Prime use case for this method is using the React CM UI
     * <DurationPicker /> component in the UI to allow a user to specify the
     * duration amount by various units of time, and then converting that value
     * into a TimeSpan string for persistence in the back-end.
     *
     * @example
     * // returns '7.05:23:34'
     * MomentUtils.durationToTimeSpanString(moment.duration{
     *     days: 7,
     *     hours: 5,
     *     minutes: 23,
     *     seconds: 34,
     * });
     */
    static durationToTimeSpanString(duration) {
        if (!moment.isDuration(duration)) {
            return null;
        }

        const rawHours = duration.hours();
        const rawMinutes = duration.minutes();
        const rawSeconds = duration.seconds();

        const hours = rawHours < 10 ? `0${rawHours}` : `${rawHours}`;
        const minutes = rawMinutes < 10 ? `0${rawMinutes}` : `${rawMinutes}`;
        const seconds = rawSeconds < 10 ? `0${rawSeconds}` : `${rawSeconds}`;

        return `${Math.floor(duration.asDays())}.${hours}:${minutes}:${seconds}`;
    }

    /**
     * Converts a ASP.NETC# TimeSpan string value (e.g. 'DDD.hh:mm:ss')
     * to a Moment.JS Duration object.
     *
     * Prime use case for this method is converting from a back-end data value
     * representing a length of time into a Moment.Js Duration object that can
     * be used as the `value` prop for a React CM UI <DurationPicker />
     * component.
     *
     * By default, `moment.duration()` is capable of this conversion out of the
     * box; however, durations originally specified as N Months will not
     * round-trip correctly, so we had to customize the conversion a bit.
     *
     * Some code borrowed and adapted from Moment.JS source:
     * https://github.com/moment/moment
     *
     * @example
     * // returns moment#duration { months: 1 }
     * MomentUtils.timeSpanStringToDuration('30.00.00.00')
     */
    static timeSpanStringToDuration(timeSpanString) {
        const timeSpanStringRegex = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/;
        const match = timeSpanStringRegex.exec(timeSpanString);

        if (match) {
            // For now, we don't really need/want to deal w/ negative durations;
            // just accept whatever `moment.duration()` says for them! =)
            if (match[1] === '-') {
                return moment.duration(timeSpanString);
            }

            const days = MomentUtils.toInt(match[2]);
            const hours = MomentUtils.toInt(match[3]);
            const minutes = MomentUtils.toInt(match[4]);
            const seconds = MomentUtils.toInt(match[5]);
            const milliseconds = MomentUtils.toInt(MomentUtils.absRound(match[6] * 1000)); // the millisecond decimal point is included in the match

            const years = MomentUtils.absFloor(days / 365);
            let remainingDays = days % 365;
            const lengthOfMonthInDays = 365 / 12;
            let months = MomentUtils.absFloor(remainingDays / lengthOfMonthInDays);
            remainingDays -= MomentUtils.absRound(months * lengthOfMonthInDays);

            // Deal with occasional "rounding error" on days/month threshold.
            // "1 month" is considered 365 / 12 = 30.41666... days within the
            // Moment library for purposes of the days to months threshold.
            // However, we never want to see durations as N months + 30 days;
            // we always want these specified as N + 1 months instead.
            if (remainingDays >= 30) {
                months += 1;
                remainingDays -= 30;
            }

            return moment.duration({
                milliseconds,
                seconds,
                minutes,
                hours,
                days: remainingDays,
                months,
                years,
            });
        }

        // eslint-disable-next-line no-console
        console.warn(`Input value '${timeSpanString}' is not a valid time span/duration.`);
        return null;
    }

    /**
     * Helper function from Moment.JS library
     * https://github.com/moment/moment/blob/2.29.1/src/lib/utils/abs-floor.js
     */
    static absFloor(number) {
        if (number < 0) {
            // -0 -> 0
            return Math.ceil(number) || 0;
        }

        return Math.floor(number);
    }

    /**
     * Helper function from Moment.JS library
     * https://github.com/moment/moment/blob/2.29.1/src/lib/utils/abs-round.js
     */
    static absRound(number) {
        if (number < 0) {
            return Math.round(-1 * number) * -1;
        }

        return Math.round(number);
    }

    /**
     * Helper function from Moment.JS library
     * https://github.com/moment/moment/blob/2.29.1/src/lib/utils/to-int.js
     */
    static toInt(argumentForCoercion) {
        const coercedNumber = +argumentForCoercion;
        let value = 0;

        if (coercedNumber !== 0 && Number.isFinite(coercedNumber)) {
            value = MomentUtils.absFloor(coercedNumber);
        }

        return value;
    }

    static withFutureDayOfWeek(date, dayOfWeek) {
        const days = dayOfWeek - date.day();

        if (days === 0) {
            return date;
        }

        if (days > 0) {
            return moment(date).add(days, 'days');
        }

        return moment(date).add(days + 7, 'days');
    }

    static withPastDayOfWeek(date, dayOfWeek) {
        const days = dayOfWeek - date.day();

        if (days === 0) {
            return date;
        }

        if (days < 0) {
            return moment(date).add(days, 'days');
        }

        return moment(date).add(days - 7, 'days');
    }

    static getStartOfWeek(date, weekDay, weekTime) {
        const timeOfDay = moment.duration(weekTime);
        const srcDate = moment(date).startOf('day');
        const srcTime = moment.duration(date.diff(srcDate));
        return date.day() === weekDay && srcTime < timeOfDay ?
            srcDate.add(-7, 'days').add(timeOfDay) :
            this.withPastDayOfWeek(srcDate, weekDay).add(timeOfDay);
    }

    static getEndOfWeek(date, weekDay, weekTime) {
        const timeOfDay = moment.duration(weekTime);
        const srcDate = moment(date).startOf('day');
        const srcTime = moment.duration(date.diff(srcDate));
        return date.day() === weekDay && srcTime >= timeOfDay ?
            srcDate.add(7, 'days').add(timeOfDay) :
            this.withFutureDayOfWeek(srcDate, weekDay).add(timeOfDay);
    }
}
