const DEFAULT_STATE = {
    filterData: {
        sortBy: {
            label: 'Date (Descending)',
            sortField: 'createdate',
            value: 'desc',
        },
        isPinned: true,
        selectedCategories: [],
        isRemindersChecked: false,
    },
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PersonNotesActions.ON_FILTER_NOTES_SORTBY': {
            const { value: sortBy } = action;
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    sortBy,
                },
            };
        }

        case 'PersonNotesActions.ON_FILTER_NOTES_PINNED': {
            const { value: isPinned } = action;
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    isPinned,
                },
            };
        }

        case 'PersonNotesActions.ON_FILTER_NOTES_TOGGLE_REMINDERS': {
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    isRemindersChecked: !state.filterData.isRemindersChecked,
                },
            };
        }

        case 'PersonNotesActions.ON_FILTER_NOTES_CATEGORIES': {
            const {
                value: selectedCategories,
            } = action;
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    selectedCategories,
                },
            };
        }

        case 'PersonNotesActions.ON_FILTER_NOTES_SET': {
            const {
                value: filterData,
            } = action;
            return {
                ...state,
                filterData,
            };
        }

        case 'PersonNotesActions.ON_FILTER_NOTES_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
