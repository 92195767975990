import _ from 'lodash';

export const DEFAULT_STATE = {
};

export default function (state = DEFAULT_STATE, action) {
    if (action.type === 'AdminImportFieldMappingActions.ON_GET') {
        return _.cloneDeep(action.result[0]);
    }
    return state;
}
