import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

export const localCache = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    canLoadMore: false,
    data: [],
    isFetching: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: localCache.getPageSize(),
    details: {},
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'CommunicationTemplatesDrawerActions.ON_BEFORE_GET': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'CommunicationTemplatesDrawerActions.ON_GET': {
            localCache.loadPage(
                action.result.communicationTemplates,
                action.result.count,
                action.callbackParams.first,
            );

            // fall-through to CommunicationTemplatesDrawerActions.ON_NEXTPAGE case
        }

        // eslint-disable-next-line no-fallthrough
        case 'CommunicationTemplatesDrawerActions.ON_NEXTPAGE': {
            return {
                ...state,
                isFetching: false,
                data: localCache.getAll(true),
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'CommunicationTemplatesDrawerActions.ON_GETBYID': {
            return {
                ...state,
                details: action.result,
            };
        }

        case 'CommunicationTemplatesDrawerActions.ON_RESET_TEMPLATE_DETAIL': {
            return {
                ...state,
                details: DEFAULT_STATE.details,
            };
        }

        case 'CommunicationTemplatesDrawerActions.ON_RESET': {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
