import EventEmitter from 'events';
import AppDispatcher from '../../../dispatcher/AppDispatcher';
import PersonManagerConstants from '../../../constants/Person/Manager/PersonManagerConstants';
import PersonMilestoneAPIUtils from '../../../utils/Person/Manager/PersonMilestoneAPIUtils.js';
import PersonNoteAPIUtils from '../../../utils/Person/Manager/PersonNoteAPIUtils.js';
import LoginConstants from '../../../constants/Login/LoginConstants';

const CHANGE_EVENT = 'change';
const SAVE_EVENT = 'save';
const GOTO_EVENT = 'goto';

class PersonManagerStoreClass extends EventEmitter {
    constructor() {
        super();
        this._person = {};
        this._previousIds = [];
    }

    getPerson() {
        return this._person;
    }

    getPersonPreviousIds() {
        return this._previousIds;
    }

    updatePerson(person) {
        this._person = person;
    }

    updatePreviousIds(ids) {
        this._previousIds = ids;
    }

    updatePersonMilestones(milestones) {
        this._person.milestones = milestones;
    }

    reset() {
        this._person = {};
        this._previousIds = [];
    }

    emitChange(isMilestone) {
        this.emit(CHANGE_EVENT, isMilestone);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    emitSave(person) {
        this.emit(SAVE_EVENT, person);
    }

    addSaveListener(callback) {
        this.on(SAVE_EVENT, callback);
    }

    removeSaveListener(callback) {
        this.removeListener(SAVE_EVENT, callback);
    }

    emitGoTo(newPersonId) {
        this.emit(GOTO_EVENT, newPersonId);
    }

    addGoToListener(callback) {
        this.on(GOTO_EVENT, callback);
    }

    removeGoToListener(callback) {
        this.removeListener(GOTO_EVENT, callback);
    }
}

AppDispatcher.register((payload) => {
    switch (payload.actionType) {
        case PersonManagerConstants.GET_PERSON:
            PersonManagerStore.updatePerson(payload.person);
            PersonManagerStore.emitChange(false);
            break;

        case PersonManagerConstants.GO_TO_PERSON:
            PersonManagerStore.emitGoTo(payload.newPersonId);
            break;

        case PersonMilestoneAPIUtils.ON_GET:
            PersonManagerStore.updatePersonMilestones(payload.result);
            PersonManagerStore.emitChange(true);
            break;

        case PersonManagerConstants.SAVE_PERSON_COMPLETED:
        case PersonManagerConstants.SAVE_MEMBERSHIP_STATUS_COMPLETED:
            PersonManagerStore.updatePerson(payload.person);
            PersonManagerStore.emitSave(payload.person);

            if (payload.actionType === PersonManagerConstants.SAVE_MEMBERSHIP_STATUS_COMPLETED) PersonNoteAPIUtils.get({ personId: payload.person.id });
            break;

        case LoginConstants.LOGOUT:
            PersonManagerStore.reset();
            PersonManagerStore.emitChange(false);
            break;

        default:
        // no op
    }
});

let PersonManagerStore = new PersonManagerStoreClass();
export default PersonManagerStore;
