import ChunkedPaginationUtils from '../../../../global/chunkedPaginationUtils.js';

const localCache = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    attendees: [],
    canLoadMore: false,
    femaleCount: 0,
    isFetching: false,
    maleCount: 0,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: localCache.getPageSize(),
    roomStatistics: [],
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'EventAttendeeCheckInActions.ON_SEARCH': {
            localCache.loadPage(
                action.result.attendees,
                action.result.total,
                action.callbackParams?.first,
            );

            // fall-through to EventAttendeeCheckInActions.ON_SEARCH_NEXTPAGE case
        }

        // eslint-disable-next-line no-fallthrough
        case 'EventAttendeeCheckInActions.ON_SEARCH_NEXTPAGE': {
            return {
                ...state,
                attendees: localCache.getAll(true),
                canLoadMore: localCache.canLoadMore(),
                femaleCount: action?.result?.femaleCount || 0,
                isFetching: false,
                maleCount: action?.result?.maleCount || 0,
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                roomStatistics: action?.result?.roomStatistics || [],
                total: localCache.getTotalCount(),
            };
        }

        case 'EventAttendeeCheckInActions.ON_BEFORE_SEARCH':
            return {
                ...(action.params.pageNumber > 0 ? state : DEFAULT_STATE),
                isFetching: true,
            };

        case 'EventAttendeeCheckInActions.ON_RESET_SEARCH':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
