import React from 'react';
import {
    Box,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { isEmpty } from 'lodash';
import ChunkedPaginationUtils, {
    defaultChunkSize,
    defaultMaxPages,
    defaultPageSize,
} from '../chunkedPaginationUtils.js';
import {
    useComponentDidMount,
    useComponentDidUpdate,
    useComponentWillUnmount,
} from '../lifeCycleHooks';
import { InfiniteScrollData } from './models';

export type FetchDataParams = {
    pageNumber: number;
    pageSize: number;
};

type PropTypes = {
    /**
     * The content of the component.
     */
    children: React.ReactNode;
    /**
     * Paginated chunk size. Smaller, UI-side subset of results (e.g. 25 things at a time)
     * @default 25
     */
    chunkSize?: number;
    /**
     * Callback fired when InfiniteScroll asks for a page of data.
     */
    fetchData: (params: FetchDataParams) => Promise<any>;
    /**
     * Paginated max pages.
     * @default -1
     */
    maxPages?: number;
    /**
     * Paginated page size. Larger, back-end pages of data from the API (e.g. 150 things a time)
     * @default 150
     */
    pageSize?: number;
    /**
     * IntersectionObserver options.
     * @defaults { root: null, rootMargin: '30px', threshold: 1 }
     */
    observerOptions?: {
        root: null;
        rootMargin: string;
        threshold: number;
    };
    /**
     * Callback fired when the component has paginated data to give to the parent.
     */
    onNewDataAvailable: Function;
    /**
     * If `true`, InfiniteScroll will reset all its internal data and refire callbacks.
     * @default false
     */
    shouldReset?: boolean;
};

enum FetchState {
    inactive = 1,
    loading = 2,
    finished = 3,
}

const BEM_BLOCK_NAME = 'infinite_scroll';

const InfiniteScroll = ({
    children,
    chunkSize = defaultChunkSize,
    fetchData,
    maxPages = defaultMaxPages,
    pageSize = defaultPageSize,
    observerOptions = {
        root: null,
        rootMargin: '30px',
        threshold: 0,
    },
    onNewDataAvailable,
    shouldReset = false,
}: PropTypes) => {
    const pagedChunkedData = React.useRef(new ChunkedPaginationUtils());

    const [isRequestingData, setIsRequestingData] = React.useState<boolean>(false);
    const [isResetting, setIsResetting] = React.useState<boolean>(false);
    const [loadingState, setLoadingState] = React.useState<FetchState>(FetchState.inactive);
    const [needsFirstRequest, setNeedsFirstRequest] = React.useState<boolean>(true);
    const [data, setData] = React.useState<InfiniteScrollData>({
        results: [],
        total: 0,
    });

    const bottomElementRef = React.useRef(null);

    const observer = React.useRef(
        new IntersectionObserver((entries) => {
            const firstEntry = entries[0];

            if (firstEntry.isIntersecting && loadingState === FetchState.inactive) {
                setLoadingState(FetchState.loading);
            }
        }, observerOptions),
    );

    const fetchDataAsync = async () => {
        /**
         * Request parent to give us a page of results
         */

        const currentChunkedData = pagedChunkedData.current;
        const pageNumber = needsFirstRequest ?
            0 :
            currentChunkedData.getCurrentPageNumber() + 1;

        const returnedData = await fetchData(
            {
                pageNumber,
                pageSize: currentChunkedData.getPageSize(),
            },
        );

        if (needsFirstRequest) {
            setNeedsFirstRequest(false);
        } else {
            setIsRequestingData(true);
        }

        setData({
            results: returnedData.results,
            total: returnedData.total,
        });
    };

    useComponentDidMount(() => {
        const currentChunkedData = pagedChunkedData.current;
        currentChunkedData.updateDefaults({
            chunkSize,
            pageSize,
            maxPages,
        });

        fetchDataAsync();
    });

    useComponentWillUnmount(() => {
        const currentChunkedData = pagedChunkedData.current;
        const currentElement = bottomElementRef.current;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.unobserve(currentElement);
        }

        currentChunkedData.reset();
    });

    useComponentDidUpdate(() => {
        const currentChunkedData = pagedChunkedData.current;
        const needsToRequestMoreData = currentChunkedData.needsToLoadPage() && !isRequestingData;

        if (
            loadingState === FetchState.loading &&
            (
                (
                    isResetting &&
                    needsFirstRequest
                ) ||
                needsToRequestMoreData
            )
        ) {
            fetchDataAsync();
        }
    }, [
        isResetting,
        loadingState,
        needsFirstRequest,
    ]);

    useComponentDidUpdate(() => {
        const currentChunkedData = pagedChunkedData.current;
        if (loadingState === FetchState.loading) {
            const afterFirstRequestWithResults = !needsFirstRequest &&
            currentChunkedData.getCurrentCount() <= 0 &&
                !isEmpty(data.results);
            const loadAnotherChunk = currentChunkedData.getCurrentCount() >= 1 &&
                !currentChunkedData.needsToLoadPage() && currentChunkedData.canLoadMore();
            const hasRequestedMoreData = currentChunkedData.needsToLoadPage() && isRequestingData;

            if (
                afterFirstRequestWithResults ||
                hasRequestedMoreData ||
                loadAnotherChunk
            ) {
                /**
                 * We have the latest requested data, so now we'll set the pagination data.
                 */

                if (afterFirstRequestWithResults || hasRequestedMoreData) {
                    currentChunkedData.loadPage(
                        data.results,
                        data.total,
                        afterFirstRequestWithResults, // reset
                    );
                }

                const paginatedResults = currentChunkedData.getAll(true);

                onNewDataAvailable({
                    results: paginatedResults,
                });

                setIsRequestingData(false);
                setIsResetting(false);
                setLoadingState(FetchState.finished);
            } else if (
                !currentChunkedData.canLoadMore() ||
                (
                    isResetting &&
                    !needsFirstRequest &&
                    currentChunkedData.getCurrentCount() <= 0 &&
                    isEmpty(data.results)
                )
            ) {
                /**
                 * Everytime we observe the bottom has been reached we'll need to set
                 * `isLoadNextChunk` from `loading` to `finished`.
                 */
                setLoadingState(FetchState.finished);
                setIsResetting(false);
            }
        }
    }, [
        data,
        loadingState,
    ]);

    useComponentDidUpdate(() => {
        const currentChunkedData = pagedChunkedData.current;
        if (shouldReset) {
            currentChunkedData.reset();
            setNeedsFirstRequest(true);
            setIsResetting(true);
            setLoadingState(FetchState.loading);
        }
    }, [
        shouldReset,
    ]);

    useComponentDidUpdate(() => {
        const currentElement = bottomElementRef.current;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }
    }, [
        bottomElementRef.current,
        loadingState,
    ]);

    return (
        <React.Fragment>
            {children}

            <div
                className={`${BEM_BLOCK_NAME}--bottom_element`}
                data-testid={`${BEM_BLOCK_NAME}--bottom_element`}
                ref={bottomElementRef}
            />

            {(needsFirstRequest || !isEmpty(data.results)) &&
            loadingState === FetchState.loading ?
                (
                    <Box
                        data-testid={`${BEM_BLOCK_NAME}--loading`}
                        textAlign="center"
                    >
                        <Typography>
                            Loading...
                        </Typography>
                    </Box>
                ) : null}
        </React.Fragment>
    );
};

export default InfiniteScroll;
