/**
* This enumeration contains the Action Type string constants for ticket editor
*/
enum ActionType {
    // Action Type for Basic Info of Ticket
    SET_NAME = 'TicketEditorActions.ON_UPDATE_NAME',
    SET_DESCRIPTION = 'TicketEditorActions.ON_UPDATE_DESCRIPTION',
    SET_REGULAR_PRICE = 'TicketEditorActions.ON_UPDATE_REGULAR_PRICE',
    SET_AVAILABLE_QUANTITY = 'TicketEditorActions.ON_UPDATE_AVAILABLE_QUANTITY',
    SET_MAX_TICKETS_PER_ORDER = 'TicketEditorActions.ON_UPDATE_MAX_TICKETS_PER_ORDER',
    // Action Type for Period of Sale of Ticket
    SET_GENERAL_AVAILABLE_END_DATE = 'TicketEditorActions.ON_UPDATE_GENERAL_AVAILABLE_END_DATE',
    SET_GENERAL_AVAILABLE_END_TIME = 'TicketEditorActions.ON_UPDATE_GENERAL_AVAILABLE_END_TIME',
    SET_GENERAL_AVAILABLE_START_DATE = 'TicketEditorActions.ON_UPDATE_GENERAL_AVAILABLE_START_DATE',
    SET_GENERAL_AVAILABLE_START_TIME = 'TicketEditorActions.ON_UPDATE_GENERAL_AVAILABLE_START_TIME',
    // Action Type for Pricing of Ticket
    REMOVE_GROUP_PRICING_OPTION_RANGE = 'TicketEditorActions.ON_REMOVE_GROUP_PRICING_OPTION_RANGE',
    RESET_GROUP_PRICING_OPTION = 'TicketEditorActions.ON_RESET_GROUP_PRICING_OPTION',
    SET_PRICING_TYPE = 'TicketEditorActions.ON_UPDATE_PRICING_TYPE',
    SET_ADD_NEW_GROUP_PRICING_OPTION_RANGE = 'TicketEditorActions.ON_UPDATE_ADD_NEW_GROUP_PRICING_OPTION_RANGE',
    SET_GROUP_PRICE_OPTION = 'TicketEditorActions.ON_UPDATE_GROUP_PRICING_OPTION',
    // Action Type for Visibility Type of Ticket
    SET_VISIBILITY_TYPE = 'TicketEditorActions.ON_UPDATE_VISIBILITY_TYPE',
    SET_TICKET_ACCESS_CODE = 'TicketEditorActions.ON_UPDATE_TICKET_ACCESS_CODE',
    // Action Type for Ticket Editor
    RESET_DATA = 'TicketEditorActions.ON_RESET',
    SET_TICKET_EDITOR_FORM = 'TicketEditorActions.ON_UPDATE_TICKET_EDITOR_FORM',
}

export default ActionType;
