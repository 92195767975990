import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

export const pagedPeople = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    activeLoadMore: true,
    canLoadMore: false,
    isFetching: false,
    isFetchingCoreMilestonesByPersonIds: false,
    coreMilestonesByPersonIds: [],
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedPeople.getPageSize(),
    people: [],
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'PeopleSearchDrawerActions.ON_BEFORE_INTERNAL_GETPEOPLE': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'PeopleSearchDrawerActions.ON_INTERNAL_GETPEOPLE': {
            pagedPeople.loadPage(
                actionResult.results,
                actionResult.resultCount,
                callbackParams.first,
            );

            // fall-through to PeopleSearchDrawerActions.ON_GET_PEOPLE_NEXT_PAGE case
        }

        // eslint-disable-next-line no-fallthrough
        case 'PeopleSearchDrawerActions.ON_GET_PEOPLE_NEXT_PAGE': {
            return {
                ...state,
                people: pagedPeople.getAll(true),
                isFetching: false,
                canLoadMore: pagedPeople.canLoadMore(),
                needsToRequest: pagedPeople.needsToLoadPage(),
                pageNumber: pagedPeople.getCurrentPageNumber(),
                pageSize: pagedPeople.getPageSize(),
                total: pagedPeople.getTotalCount(),
            };
        }

        case 'PeopleSearchDrawerActions.ON_BEFORE_GETCOREMILESTONESBYPERSONIDS': {
            return {
                ...state,
                isFetchingCoreMilestonesByPersonIds: true,
            };
        }

        case 'PeopleSearchDrawerActions.ON_GETCOREMILESTONESBYPERSONIDS': {
            return {
                ...state,
                isFetchingCoreMilestonesByPersonIds: false,
                coreMilestonesByPersonIds: [
                    ...state.coreMilestonesByPersonIds,
                    ...action.result,
                ],
            };
        }

        case 'PeopleSearchDrawerActions.ON_RESET_CORE_MILESTONES_BY_PERSON_IDS': {
            return {
                ...state,
                isFetchingCoreMilestonesByPersonIds: false,
                coreMilestonesByPersonIds: DEFAULT_STATE.coreMilestonesByPersonIds,
            };
        }

        case 'PeopleSearchDrawerActions.ON_RESET_PEOPLE': {
            return DEFAULT_STATE;
        }

        case 'PeopleSearchDrawerActions.ON_TOGGLE_ACTIVE_LOAD_MORE': {
            return { ...state, activeLoadMore: action.isActiveLoadMore };
        }

        default:
            return state;
    }
};
