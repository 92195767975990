import {
    isEqual,
    isNil,
} from 'lodash';
import { SHARED_ACCOUNT_SORT_OPTION_DEFAULT } from './constants';
import {
    AccountFilterStatusId,
    Filters,
    SharedAccountFilterState,
} from './models';
import { getFilteredArrayByIsChecked } from './utils';
import ActionType from './listPage.actionTypes';

export const DEFAULT_FILTER_STATE: Filters = {
    accountStatuses: [
        AccountFilterStatusId.Active,
        AccountFilterStatusId.Closed,
    ],
    accountSetupDateEnd: null,
    accountSetupDateStart: null,
    sort: SHARED_ACCOUNT_SORT_OPTION_DEFAULT,
};

export const DEFAULT_STATE: SharedAccountFilterState = {
    appliedFilters: { ...DEFAULT_FILTER_STATE },
    areFiltersDirty: false,
    dirtyFilters: { ...DEFAULT_FILTER_STATE },
    isFiltering: false,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, DEFAULT_FILTER_STATE);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (
    state: SharedAccountFilterState | undefined,
    action: any,
): SharedAccountFilterState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.RESET_FILTERS: {
                return DEFAULT_STATE;
            }

            case ActionType.SET_APPLY_FILTERS: {
                const { dirtyFilters } = state;
                const isFiltering = !isEqual(dirtyFilters, DEFAULT_FILTER_STATE);

                return {
                    ...state,
                    appliedFilters: { ...dirtyFilters },
                    areFiltersDirty: false,
                    isFiltering,
                };
            }

            case ActionType.SET_CLEAR_FILTERS: {
                return updateDirtyFilterState(state, { ...DEFAULT_FILTER_STATE });
            }

            case ActionType.SET_APPLY_SORT_ORDER: {
                const nextAppliedFilters = { ...state.appliedFilters,
                    sort: action.value };
                return updateAppliedFilterState(state, nextAppliedFilters);
            }

            case ActionType.SET_AND_APPLY_ACCOUNT_STATUS_FILTER_CHANGE: {
                const { accountStatusId, isChecked } = action.value;

                const updatedFilters = {
                    ...state.appliedFilters,
                    accountStatuses: getFilteredArrayByIsChecked(
                        state.appliedFilters.accountStatuses,
                        DEFAULT_FILTER_STATE.accountStatuses,
                        accountStatusId,
                        isChecked,
                    ),
                };

                return updateAppliedFilterState(state, updatedFilters);
            }

            case ActionType.SET_AND_APPLY_ACCOUNT_SETUP_END_DATE_FILTER_CHANGE: {
                const accountSetupDateEnd = action.value;

                const updatedFilters = {
                    ...state.appliedFilters,
                    accountSetupDateEnd,
                };

                return updateAppliedFilterState(state, updatedFilters);
            }

            case ActionType.SET_AND_APPLY_ACCOUNT_SETUP_START_DATE_FILTER_CHANGE: {
                const accountSetupDateStart = action.value;

                const updatedFilters = {
                    ...state.appliedFilters,
                    accountSetupDateStart,
                };

                return updateAppliedFilterState(state, updatedFilters);
            }

            case ActionType.SET_DIRTY_SORT_ORDER: {
                return updateDirtyFilterState(
                    state,
                    { ...state.dirtyFilters,
                        sort: action.value },
                );
            }

            case ActionType.SET_DIRTY_ACCOUNT_STATUS_FILTER_CHANGE: {
                const { accountStatusId, isChecked } = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    accountStatuses: getFilteredArrayByIsChecked(
                        state.dirtyFilters.accountStatuses,
                        DEFAULT_FILTER_STATE.accountStatuses,
                        accountStatusId,
                        isChecked,
                    ),
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_ACCOUNT_SETUP_END_DATE_FILTER_CHANGE: {
                const accountSetupDateEnd = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    accountSetupDateEnd,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            case ActionType.SET_DIRTY_ACCOUNT_SETUP_START_DATE_FILTER_CHANGE: {
                const accountSetupDateStart = action.value;

                const dirtyFilters = {
                    ...state.dirtyFilters,
                    accountSetupDateStart,
                };

                return updateDirtyFilterState(state, dirtyFilters);
            }

            default:
                return state;
        }
    }

    return state;
};
