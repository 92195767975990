import './personNoteForm.scss';

import {
    Button,
    Dropdown,
    Grid,
    Input,
    TextArea,
} from '@saddlebackchurch/react-cm-ui';
import {
    withStyles,
} from '@saddlebackchurch/react-cm-ui/core/styles';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import Classnames from 'classnames';
import {
    notePropTypes, noteFormDefaultProps, noteFormPropTypes,
} from './notePropTypes.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import { translationFactory } from '../../../global/i18nUtils.js';
import PersonNotesActions from './personNotesActions.js';
import UserAccessStore from '../../../global/userAccessStore.js';
import NoteReminderIconHandler from './noteReminders/noteReminderIcon';
import {
    setIsReminderFormModalOpen,
    setMobileNextWingType,
    setReminderElementOpenFrom,
    resetReminderForm,
} from './noteReminders/noteReminderActions';
import {
    OPEN_FROM_NOTE_FORM,
    OPEN_FROM_NOTE_FORM_EDIT,
    OPEN_FROM_NOTE_FORM_EDIT_MOBILE,
    OPEN_FROM_NOTE_FORM_MOBILE,
} from './noteReminders/noteReminderConstants';

const i18n = translationFactory('Person.Record.Notes.Form');

const propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    classes: PropTypes.shape({
        noteForm: PropTypes.shape({}),
    }).isRequired,
    data: noteFormPropTypes,
    disableNoteSave: PropTypes.bool,
    hasCreateReminderButtonBeenClicked: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool,
    loggedInUserPersonId: PropTypes.number.isRequired,
    noteDefaultCategoryId: PropTypes.number,
    noteDrawerHeight: PropTypes.number.isRequired,
    noteFormStateChange: PropTypes.bool.isRequired,
    notesList: PropTypes.arrayOf(
        notePropTypes,
    ),
    onNoteSaveClick: PropTypes.func,
    selectedReminder: PropTypes.func.isRequired,
    reminderFormData: PropTypes.shape({}),
    resetReminderForm: PropTypes.func.isRequired,
    setIsReminderFormModalOpen: PropTypes.func.isRequired,
    setMobileNextWingType: PropTypes.func.isRequired,
    setReminderElementOpenFrom: PropTypes.func.isRequired,
    setWingType: PropTypes.func.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    categories: [],
    disableNoteSave: true,
    data: noteFormDefaultProps,
    isMobile: false,
    noteDefaultCategoryId: undefined,
    notesList: [],
    onNoteSaveClick: undefined,
    reminderFormData: undefined,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
                userIdentity,
            },
        },
        people: {
            record: {
                notes: {
                    data: notesList,
                    noteFormStateChange,
                },
                notesCategories: {
                    data: categories,
                },
                noteForm: {
                    formData: data,
                    hasCreateReminderButtonBeenClicked,
                    mobileNextWingType,
                    reminderFormData,
                    selectedReminder,
                },
            },
        },
    } = state;

    const loggedInUserPersonId = userIdentity.getPersonId();

    return {
        categories,
        data,
        hasCreateReminderButtonBeenClicked,
        loggedInUserPersonId,
        mobileNextWingType,
        noteFormStateChange,
        notesList,
        reminderFormData,
        selectedReminder,
        userAccess,
    };
};

const mapDispatchToProps = {
    resetReminderForm,
    setIsReminderFormModalOpen,
    setMobileNextWingType,
    setReminderElementOpenFrom,
};

const useStyles = () => ({
    noteForm: {
        '& textarea': {
            padding: '10px 10px 50px 10px !important',
        },
        '& .text-area+*': {
            marginTop: 0,
        },
    },
});

const blockClassName = 'person_notes_drawer_note_form';

class PersonNoteForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            noteTextAreaMinHeight: null,
        };

        this.onNoteCancel = this.onNoteCancel.bind(this);
        this.onReminderIconClick = this.onReminderIconClick.bind(this);
    }

    componentDidMount() {
        const { noteDefaultCategoryId, noteDrawerHeight } = this.props;
        if (noteDefaultCategoryId) {
            PersonNotesActions.updateCategory(noteDefaultCategoryId);
        }
        let noteTextAreaHeight;
        const noteTextAreaGridDOMEL = document.querySelector('.person_notes_drawer_note_form--grid_note_textarea');
        if (noteTextAreaGridDOMEL) {
            const noteTextAreaGridHeight = document.querySelector('.person_notes_drawer_note_form--grid_note_textarea').offsetTop;
            // 55px(Title) + 55px(Form action buttons) + 33px(Padding & margin)
            noteTextAreaHeight = noteDrawerHeight - noteTextAreaGridHeight - 110 - 33;
            this.setState({ noteTextAreaMinHeight: noteTextAreaHeight });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            data: {
                categoryId,
                subject,
                noteText,
            },
        } = this.props;

        if ((categoryId !== null || subject !== '' || noteText !== '') && !prevProps.noteFormStateChange) {
            PersonNotesActions.setNoteFormChangeStatus(true);
        }

        if (categoryId === null && subject === '' && noteText === '' && prevProps.noteFormStateChange) {
            PersonNotesActions.setNoteFormChangeStatus();
        }
    }

    static onChangeCategory(option) {
        PersonNotesActions.updateCategory(option.value);
    }

    static onChangeSubject(value) {
        PersonNotesActions.updateSubject(value);
    }

    static onChangeNoteText(value) {
        PersonNotesActions.updateNoteText(value);
    }

    onNoteCancel() {
        const {
            resetReminderForm: resetReminderFormAction,
        } = this.props;

        resetReminderFormAction();
        PersonNotesActions.resetNoteForm();
        PersonNotesActions.setWingType();
        PersonNotesActions.setNoteFormChangeStatus();
    }

    onReminderIconClick() {
        const {
            data,
            isMobile,
            reminderFormData,
            selectedReminder,
            setIsReminderFormModalOpen: setIsReminderFormModalOpenAction,
            setMobileNextWingType: setMobileNextWingTypeAction,
            setReminderElementOpenFrom: setReminderElementOpenFromAction,
            setWingType,
        } = this.props;

        if (isMobile) {
            const reminderData = selectedReminder || reminderFormData;
            if (_.get(reminderData, 'id')) {
                setReminderElementOpenFromAction(OPEN_FROM_NOTE_FORM_EDIT_MOBILE);
            } else {
                setReminderElementOpenFromAction(OPEN_FROM_NOTE_FORM_MOBILE);
            }

            if (reminderData) {
                setWingType('note_reminder_detail');
            } else {
                setWingType('new_reminder_form');
            }

            setMobileNextWingTypeAction('new_note_form');
        } else if (data.noteId) {
            setIsReminderFormModalOpenAction(true, OPEN_FROM_NOTE_FORM_EDIT);
        } else {
            setIsReminderFormModalOpenAction(true, OPEN_FROM_NOTE_FORM);
        }
    }

    render() {
        const {
            categories,
            classes,
            data: {
                categoryId,
                noteId,
                subject,
                noteText,
            },
            disableNoteSave,
            hasCreateReminderButtonBeenClicked,
            isMobile,
            loggedInUserPersonId,
            notesList,
            onNoteSaveClick,
            reminderFormData,
            selectedReminder,
            userAccess,
        } = this.props;

        const reminderData = selectedReminder || reminderFormData;
        const {
            noteTextAreaMinHeight,
        } = this.state;

        const authorizedCreateNoteCategoryIds = userAccess.getAuthzdNotesCategoryIdsByPermission(
            USER_PERMISSIONS.createPersonNotes,
        );
        const authorizedUpdateNoteCategoryIds = userAccess.getAuthzdNotesCategoryIdsByPermission(
            USER_PERMISSIONS.updatePersonNotes,
        );

        const categoriesPermission = noteId ?
            authorizedUpdateNoteCategoryIds : authorizedCreateNoteCategoryIds;
        let disableEditCategory = false;

        if (noteId) {
            const noteData = _.find(notesList, { id: noteId });
            // reminder filter active, delete the reminder, note shouldn't return
            disableEditCategory = ((loggedInUserPersonId === _.get(noteData, 'createdBy') && categoryId === 8) || noteData.entityType === 'ministry_member' || noteData.entityType === 'task');
        }

        let categoriesOptions = [];

        categoriesOptions = _.map(categories, (item) => ({
            id: item.id,
            label: item.name,
            value: item.id,
        }));

        categoriesOptions = _.filter(categoriesOptions, (item) => item.value !== 3);
        _.remove(categoriesOptions, (item) => !categoriesPermission.includes(item.id));

        const categoryInputProps = {
            ...(isMobile && { label: i18n('Category') }),
            ...(!isMobile && { placeholder: i18n('Category') }),
        };

        const subjectInputProps = {
            ...(isMobile && { label: i18n('Subject') }),
            ...(!isMobile && { placeholder: i18n('Subject') }),
        };

        const noteTextInputProps = {
            ...(isMobile && { label: i18n('Note') }),
            ...(!isMobile && { placeholder: i18n('Note') }),
        };
        const noteFormClasses = Classnames(
            blockClassName,
            classes.noteForm,
        );

        return (
            <Grid
                className={noteFormClasses}
                spacing={2}
            >
                <Grid.RowDeprecated columns={1}>
                    <Grid.Column>
                        <Dropdown
                            clearable={false}
                            disable={disableEditCategory}
                            fluid
                            id={`${blockClassName}--category_select`}
                            onChange={PersonNoteForm.onChangeCategory}
                            options={categoriesOptions}
                            selection
                            value={categoryId}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...categoryInputProps}
                        />
                    </Grid.Column>
                </Grid.RowDeprecated>
                <Grid.RowDeprecated columns={1}>
                    <Grid.Column>
                        <Input
                            fluid
                            id={`${blockClassName}--subject`}
                            onChange={PersonNoteForm.onChangeSubject}
                            required
                            value={subject}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...subjectInputProps}
                        />
                    </Grid.Column>
                </Grid.RowDeprecated>
                <Grid.RowDeprecated className={`${blockClassName}--grid_note_textarea`} columns={1}>
                    <Grid.Column>
                        <TextArea
                            autoHeight
                            fluid
                            id={`${blockClassName}--note_textarea`}
                            minHeight={
                              !isMobile && noteTextAreaMinHeight ?
                                  noteTextAreaMinHeight :
                                  422
                            }
                            required
                            onChange={PersonNoteForm.onChangeNoteText}
                            value={noteText}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...noteTextInputProps}
                        />
                        <NoteReminderIconHandler
                            isInsideTextArea
                            onClick={this.onReminderIconClick}
                            renderedFrom={selectedReminder ? 'noteFormEdit' : 'noteForm'}
                            reminderData={reminderData}
                            hasCreateReminderButtonBeenClicked={hasCreateReminderButtonBeenClicked}
                            shouldDisableOnClick={!!selectedReminder} // it means that is rendered from edit note form
                        />
                    </Grid.Column>
                </Grid.RowDeprecated>
                {!isMobile && (
                    <Grid.RowDeprecated columns={1} horizontalAlign="right">
                        <Grid.Column>
                            <div className={`${blockClassName}_action_buttons`}>
                                <Button
                                    color="alternate"
                                    id={`${blockClassName}--cancel`}
                                    onClick={this.onNoteCancel}
                                >
                                    {i18n('Cancel')}
                                </Button>
                                <Button
                                    color="success"
                                    disabled={disableNoteSave}
                                    id={`${blockClassName}--save`}
                                    onClick={onNoteSaveClick}
                                >
                                    {i18n('Save')}
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid.RowDeprecated>
                )}
            </Grid>
        );
    }
}

PersonNoteForm.propTypes = propTypes;
PersonNoteForm.defaultProps = defaultProps;

const PersonNoteFormWithStyles = withStyles(useStyles)(PersonNoteForm);
export default connect(mapStateToProps, mapDispatchToProps)(PersonNoteFormWithStyles);
