import request from 'axios';
import ApiUtils from '../../global/apiUtils.js';
import { appReduxStore } from '../../global/configureReduxStore.js';

class PersonalFormActions extends ApiUtils {
    updateBirthDate(birthDate) {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_UPDATE_BIRTHDATE',
            value: birthDate,
        });
    }

    updateCampus(campus) {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_UPDATE_CAMPUS',
            value: campus,
        });
    }

    updateFirstName(firstName) {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_UPDATE_FIRSTNAME',
            value: firstName,
        });
    }

    updateGender(gender) {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_UPDATE_GENDER',
            value: gender,
        });
    }

    updateGrade(grade) {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_UPDATE_GRADE',
            value: grade,
        });
    }

    updateLastName(lastName) {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_UPDATE_LASTNAME',
            value: lastName,
        });
    }

    updatePersonalData(personalData) {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_UPDATE_PERSONAL_DATA',
            value: personalData,
        });
    }

    updateRecordType(recordType) {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_UPDATE_RECORD_TYPE',
            value: recordType,
        });
    }

    resetData() {
        appReduxStore.dispatch({
            type: 'PersonalFormActions.ON_RESET_DATA',
        });
    }
}

const actions = new PersonalFormActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'getChurchEntity',
        route: 'churchentity?includeInactive=true',
        rq: request.get,
    },
);

export default actions;
