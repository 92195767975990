import {
    isNil,
} from 'lodash';
import css from 'dom-css';

export default class domUtils {
    static onMailToLinkClick(event, emailAddress) {
        window.location.href = `mailto:${emailAddress}`;

        event?.stopPropagation();
    }

    static onTelLinkClick(event, phoneNumber) {
        const parsedPhoneNumber = phoneNumber.includes('tel:') ?
            phoneNumber :
            `tel:${phoneNumber}`;

        window.location.href = parsedPhoneNumber;

        event.stopPropagation();
    }

    static browserDetect() {
        // https://github.com/mbasso/react-browser-detection/blob/master/src/index.js
        const isOpera =
            (!!window.opr && !!window.opr.addons) ||
            !!window.opera ||
            navigator.userAgent.indexOf(' OPR/') >= 0;
        const isFirefox = typeof InstallTrigger !== 'undefined';
        const isChrome =
            !!window.chrome &&
            !!window.chrome.webstore &&
            navigator.userAgent.toLowerCase().indexOf('googlebot') === -1;
        const isSafari = !isChrome && navigator.userAgent.toLowerCase().indexOf('safari') !== -1;
        const isIE = /* @cc_on!@ */false || !!document.documentMode;
        const isEdge = !(isIE) && !!window.StyleMedia;
        const isBlink = (isChrome || isOpera) && !!window.CSS;
        const isGoogleBot = navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1;

        if (isOpera) {
            return 'opera';
        }

        if (isFirefox) {
            return 'firefox';
        }

        if (isSafari) {
            return 'safari';
        }

        if (isIE) {
            return 'ie';
        }

        if (isEdge) {
            return 'edge';
        }

        if (isChrome) {
            return 'chrome';
        }

        if (isBlink) {
            return 'blink';
        }

        if (isGoogleBot) {
            return 'googlebot';
        }

        return 'unknown';
    }

    static getScrollbarWidth() {
        let scrollbarWidth = false;

        if (typeof document !== 'undefined') {
            const div = document.createElement('div');

            css(div, {
                width: 100,
                height: 100,
                position: 'absolute',
                top: -9999,
                overflow: 'scroll',
                MsOverflowStyle: 'scrollbar',
            });

            document.body.appendChild(div);

            scrollbarWidth = (div.offsetWidth - div.clientWidth);

            document.body.removeChild(div);
        } else {
            scrollbarWidth = 0;
        }
        return scrollbarWidth || 0;
    }

    static hasClass(el, cl) {
        return (` ${el.className} `).indexOf(` ${cl} `) > -1;
    }

    static isIOS() {
        return (!!navigator.userAgent.match(/iPhone|iPad|iPod/i));
    }

    static isTouch() {
        return 'ontouchstart' in document.documentElement;
    }

    static scrollPos() {
        return (window.scrollY || window.pageYOffset);
    }

    static breakpoint(breakpoint) {
        let bp;

        switch (breakpoint) {
            case 'xs':
                bp = '(max-width: 639px)';
                break;
            case 's':
                bp = '(min-width: 640px)';
                break;
            case 'm':
                bp = '(min-width: 768px)';
                break;
            case 'l':
                bp = '(min-width: 960px)';
                break;
            case 'xl':
                bp = '(min-width: 1080px)';
                break;
            case '<xl':
                bp = '(max-width: 1079px)';
                break;
            case 'xxxl':
                bp = '(min-width: 1400px)';
                break;
            case '<xxxl':
                bp = '(max-width: 1399px)';
                break;
            case 'xxxxl':
                bp = '(min-width: 1720px)';
                break;
            case '<xxxxl':
                bp = '(max-width: 1719px)';
                break;
        }

        return window.matchMedia(bp).matches;
    }

    static scrollTo(to, duration, parentEl, onScrolled = null) {
        // Defaults
        const o = {
            duration: duration || 250,
            parentEl: parentEl || null,
            to: to || 0,
        };

        const SCROLL_INCREMENT_MS = 10;

        let currentViewPortPosistion = !o.parentEl ? document.body.scrollTop || document.documentElement.scrollTop : o.parentEl.scrollTop;
        let difference = o.to - currentViewPortPosistion;
        const numSteps = o.duration / SCROLL_INCREMENT_MS;
        const increment = difference / numSteps;

        // let startTime = Date.now();
        // console.log('start: ' + startTime);

        const scrollInterval = setInterval(() => {
            currentViewPortPosistion = !o.parentEl ? document.body.scrollTop || document.documentElement.scrollTop : o.parentEl.scrollTop;
            difference = o.to - currentViewPortPosistion;

            const interval = Math.abs(difference) < Math.abs(increment) ? difference : increment;
            const newViewPortPosistion = currentViewPortPosistion + interval;

            if (!parentEl) {
                document.body.scrollTop = newViewPortPosistion;
                document.documentElement.scrollTop = newViewPortPosistion;
            } else {
                parentEl.scrollTop = newViewPortPosistion;
            }

            if (newViewPortPosistion === o.to || (!parentEl && (window.innerHeight + (window.scrollY || window.pageYOffset) >= document.body.scrollHeight))) {
                // console.log('ellapsed: ' + parseInt(startTime - Date.now()));
                clearInterval(scrollInterval);
                if (onScrolled) {
                    onScrolled();
                }
            }
        }, SCROLL_INCREMENT_MS);
    }

    static copyStringToClipboard(str) {
        if (!isNil(document) && !isNil(document.body)) {
            const tempInput = document.createElement('input');
            tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
            tempInput.value = str;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
        }
    }
}
