/**
* This enumeration contains the Action Type string constants for coupon editor
*/
enum ActionType {
    // Action Type for Basic Info of Coupon
    SET_COUPON_CODE = 'CouponEditorActions.ON_SET_COUPON_CODE',
    SET_USAGE_TYPE = 'CouponEditorActions.ON_SET_USAGE_TYPE',
    SET_USAGE_LIMIT = 'CouponEditorActions.ON_SET_USAGE_LIMIT',
    SET_DISCOUNT_AMOUNT = 'CouponEditorActions.ON_SET_DISCOUNT_AMOUNT',
    SET_DISCOUNT_PERCENT = 'CouponEditorActions.ON_SET_DISCOUNT_PERCENT',
    // Action Type for Promo Code Starts of Coupon
    SET_PROMO_START_TYPE = 'CouponEditorActions.ON_SET_PROMO_START_TYPE',
    SET_PROMO_START_DATE = 'CouponEditorActions.ON_SET_PROMO_START_DATE',
    SET_PROMO_START_TIME = 'CouponEditorActions.ON_SET_PROMO_START_TIME',
    // Action Type for Promo Code Ends of Coupon
    SET_PROMO_END_TYPE = 'CouponEditorActions.ON_SET_PROMO_END_TYPE',
    SET_PROMO_END_DATE = 'CouponEditorActions.ON_SET_PROMO_END_DATE',
    SET_PROMO_END_TIME = 'CouponEditorActions.ON_SET_PROMO_END_TIME',
    // Action Type for Apply Promo Code to Ticket of Coupon
    SET_APPLY_CODE_TYPE = 'CouponEditorActions.ON_APPLY_CODE_TYPE',
    SET_PROMO_CODE_TO_TICKET = 'CouponEditorActions.ON_ADD_PROMO_CODE_TO_TICKET',
    REMOVE_PROMO_CODE_FROM_TICKET = 'CouponEditorActions.ON_REMOVE_PROMO_CODE_FROM_TICKET',
    // Action Type for Coupon Editor
    RESET_DATA = 'CouponEditorActions.ON_RESET',
    SET_COUPON_EDITOR_FORM = 'CouponEditorActions.ON_SET_COUPON_EDITOR_FORM',
}

export default ActionType;
