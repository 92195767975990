import PropTypes from 'prop-types';

export const personPersonalFormPropTypes = PropTypes.shape({
    birthDate: PropTypes.number,
    campus: PropTypes.shape({}),
    firstName: PropTypes.string,
    gender: PropTypes.shape({}),
    grade: PropTypes.shape({}),
    lastName: PropTypes.string,
});

export const personPersonalFormDefaultProps = {
    firstName: '',
    lastName: '',
    birthDate: null,
    gender: null,
    grade: null,
    campus: null,
};

export const personContactFormPropTypes = PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.shape({
        code: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
    }),
    email: PropTypes.string,
    phone: PropTypes.string,
    phoneType: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
});

export const personContactFormDefaultProps = {
    address1: '',
    address2: '',
    city: '',
    country: null,
    email: '',
    phone: '',
    phoneType: '',
    postalCode: '',
    region: null,
};
