export const DEFAULT_STATE = {
    isDrawerOpen: false,
};

export default (state = DEFAULT_STATE, action) => {
    const actionType = 'PersonEmergencyContactActions';

    switch (action.type) {
        case `${actionType}.ON_SET_IS_DRAWER_OPEN`: {
            return {
                ...state,
                isDrawerOpen: action.result,
            };
        }

        default:
            return state;
    }
};
