import { keyBy } from 'lodash';
import { Campus } from '../models/campus.model';

type CampusCacheStateType = { [id: number]: Campus };
export const DEFAULT_STATE: CampusCacheStateType = {};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ChurchStructureActions.ON_GETCAMPUSES':
            return keyBy(action.result as Campus[], 'id');

        case 'ChurchStructureActions.ON_RESET_CAMPUSES':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
