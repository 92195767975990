import { i18n } from '../global/constants.js';

export const MY_MINISTRIES_TITLE = i18n('My Ministries');

export const MINISTRIES_SECTIONS = {
    allMinistries: '/ministries-central',
    myMinistries: '/my-ministry',
    servingOpps: '/serving-opportunities',
    servingOpp: '/serving-opportunity',
};

export const MY_MINISTRIES_PATH = '/my-ministry';
