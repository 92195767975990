import {
    cloneDeep,
    forEach,
    isArray,
} from 'lodash';

export const DEFAULT_STATE = {};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'AttendeeNotesCacheActions.ON_RESET':
            if (action.id) {
                const newState = cloneDeep(state);

                const ids = isArray(action.id) ? action.id : [action.id];

                forEach(ids, (id) => {
                    delete newState[id];
                });

                return newState;
            }

            return DEFAULT_STATE;

        case 'AttendeeNotesCacheActions.ON_BEFORE_GET': {
            const newState = { ...state };

            forEach(action.bodyParams, (id) => {
                newState[id] = -1; // a special value, indicated that this ID was requested
            });

            return newState;
        }

        case 'AttendeeNotesCacheActions.ON_GET': {
            const newState = { ...state };

            forEach(action.result, (v) => {
                newState[v.personId] = v.notesCount;
            });

            return newState;
        }

        default:
            return state;
    }
}
