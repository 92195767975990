import {
    Select,
} from '@saddlebackchurch/react-cm-ui';
import {
    map,
} from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from './i18nUtils.js';

const i18n = translationFactory('ConnectionCards.Global.EntryDrawerPersonalContent');

const propTypes = {
    className: PropTypes.string,
    clearable: PropTypes.bool,
    countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    disable: PropTypes.bool,
    id: PropTypes.string,
    onCountryChange: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.shape({}),
    value: PropTypes.oneOf([
        PropTypes.shape({}),
        PropTypes.string,
    ]),
};

const defaultProps = {
    className: undefined,
    clearable: false,
    disable: false,
    id: undefined,
    onCountryChange: undefined,
    required: false,
    style: undefined,
    value: undefined,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            enumerations: {
                enumerations: {
                    countries,
                },
            },
        },
    } = state;

    return {
        countries,
    };
};

class SelectCountry extends React.PureComponent {
    constructor(props) {
        super(props);

        const { countries } = props;
        this.options = map(countries, (country) => ({
            code: country.code,
            value: country.shortName,
            label: country.longName,
        }));
    }

    render() {
        const {
            className,
            clearable,
            disable,
            id,
            onCountryChange,
            required,
            style,
            value,
        } = this.props;

        return (
            <Select
                className={className}
                clearable={clearable}
                disable={disable}
                fluid
                id={id}
                label={i18n('Country')}
                onChange={onCountryChange}
                options={this.options}
                matchProp="any"
                placeholder={i18n('Select')}
                required={required}
                searchable
                style={style}
                value={value}
            />
        );
    }
}

SelectCountry.propTypes = propTypes;
SelectCountry.defaultProps = defaultProps;

export default connect(mapStateToProps)(SelectCountry);
