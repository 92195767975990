import React, { useState } from 'react';
import {
    Drawer,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import { connect } from 'react-redux';
import ActivityConstants from '../../js/constants/Notification/ActivityConstants.js';
import QuickNotificationCenter from '../../quickNotificationCenter/quickNotification.jsx';
import QuickNotificationDetailDrawer from '../../quickNotificationCenter/quickNotificationDetailDrawer.jsx';

type PropTypes = {
    isMobile?: boolean;
    notificationUnreadCount?: number;
};

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            isMobile,
        },
        quickNotifications: {
            notificationUnreadCount,
        },
    } = state;

    return {
        isMobile,
        notificationUnreadCount,
    };
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        height: spacing(4),
    },
    icon: {
        height: 18,
        '& .ui.icon.has-notification .icon-use-path': {
            fill: 'pink',
        },
    },
}));

export function QuickNotification({
    isMobile = false,
    notificationUnreadCount = 0,
}: PropTypes) {
    const [
        isQuickNotificationDrawerWingOpen,
        setIsQuickNotificationDetailDrawerOpen,
    ] = useState<boolean>(false);

    const [isQuickNotificationDrawerOpen, setIsQuickNotificationDrawerOpen] =
        useState<boolean>(false);

    const [notificationDetailData, setNotificationDetailData] = useState<object>({});

    const classes = useStyles();

    const onNotificationDetailsWingClose = () => {
        setIsQuickNotificationDetailDrawerOpen(false);
    };

    const onQuickNotificationDrawerOpen = () => {
        setIsQuickNotificationDrawerOpen(true);
    };

    const onQuickNotificationDrawerClose = () => {
        if (isQuickNotificationDrawerOpen) {
            setIsQuickNotificationDetailDrawerOpen(false);
        }

        setIsQuickNotificationDrawerOpen(false);
    };

    const onQuickNotificationDrawerWingToggle = (item, prevActiveIndex, index) => {
        setIsQuickNotificationDetailDrawerOpen((prev) => {
            if (item.sourceType === ActivityConstants.EXPORT_SERVICE) {
                return false;
            }

            if (prevActiveIndex === index) {
                return !prev;
            }

            return true;
        });

        if (item.sourceType !== ActivityConstants.EXPORT_SERVICE) {
            setNotificationDetailData(item);
        }
    };

    return (
        <div
            className={classes.root}
        >
            <div
                className={classes.icon}
            >
                <Icon
                    className={
                        notificationUnreadCount && !isQuickNotificationDrawerOpen ?
                            'has-notification' :
                            ''
                    }
                    color={isQuickNotificationDrawerOpen ? 'highlight' : 'static'}
                    compact
                    onClick={onQuickNotificationDrawerOpen}
                    size="medium"
                    type="bell"
                />
            </div>

            <Drawer
                className={
                    `quick-notification-drawer ${isQuickNotificationDrawerWingOpen ?
                        'quick-notification-drawer-has-wings' :
                        null}`
                }
                isOpen={isQuickNotificationDrawerOpen}
                maxWidth={!isMobile ? 320 : null}
                onClose={onQuickNotificationDrawerClose}
                positionX="right"
                positionY="bottom"
                shouldCloseOnClickOutside={!isMobile || false}
                wing={(
                    <Drawer.Wing
                        isOpen={isQuickNotificationDrawerWingOpen}
                        width={!isMobile ? '320px' : window.innerWidth}
                    >
                        <QuickNotificationDetailDrawer
                            notificationDetailData={notificationDetailData}
                            onCloseDrawer={onQuickNotificationDrawerClose}
                            onWingToggle={onQuickNotificationDrawerWingToggle}
                        />
                    </Drawer.Wing>
                )}
            >
                <QuickNotificationCenter
                    hideNotificationDetailsWing={onNotificationDetailsWingClose}
                    isWingOpen={isQuickNotificationDrawerWingOpen}
                    onCloseDrawer={onQuickNotificationDrawerClose}
                    onWingToggle={onQuickNotificationDrawerWingToggle}
                />
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps)(QuickNotification);
