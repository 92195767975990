export const applyFilters = () => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_APPLY_FILTERS',
    });
};

export const clearFilters = () => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_CLEAR_FILTERS',
    });
};

export const clearSearchTerm = () => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_CLEAR_SEARCH_TERM',
    });
};

export const reset = () => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_RESET',
    });
};

export const setAndApplySortOrder = (selectedSortOption) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_SORT_ORDER_CHANGED_AND_APPLIED',
        value: selectedSortOption,
    });
};

export const setDirtySortOrder = (selectedSortOption) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_DIRTY_SORT_ORDER_CHANGED',
        value: selectedSortOption,
    });
};

export const setAndApplyDeceasedFilter = (deceased) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_DECEASED_FILTER_CHANGED_AND_APPLIED',
        value: deceased,
    });
};

export const setDirtyDeceasedFilter = (deceased) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_DIRTY_DECEASED_FILTER_CHANGED',
        value: deceased,
    });
};

export const updateSearchTerm = (searchTerm) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchFilters.ON_UPDATE_SEARCH_TERM',
        value: searchTerm,
    });
};
