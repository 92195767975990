import {
    includes,
    map,
    orderBy,
} from 'lodash';
import {
    AbstractParseTreeVisitor,
} from 'antlr4ts/tree/AbstractParseTreeVisitor';
import distance from 'jaro-winkler';
import {
    DslVisitor,
} from './dslParser/DslVisitor';

export default class Visitor extends AbstractParseTreeVisitor<void> implements DslVisitor<void> {
    fields: string[];
    error: string | null;

    constructor(fields) {
        super();
        this.fields = fields;
        this.error = null;
    }

    defaultResult() {

    }

    visitItem(context) {
        if (context.NESTED_ID() != null) {
            const property = context.NESTED_ID().symbol.text;
            const found = includes(this.fields, property);

            if (!found) {
                this.error = `Invalid property name: '${property}'.`;

                if (property.length > 1 && this.fields.length > 0) {
                    const suggestions = orderBy(
                        map(this.fields, (n) => ({
                            n,
                            d: distance(n, property),
                        })),
                        'd',
                        'desc',
                    );

                    this.error += ` Did you mean '${suggestions[0].n}'?`;
                }
            }
        }
    }
}
