import {
    find,
    get,
    groupBy,
    sortBy,
} from 'lodash';

export const matchingFunction = (article, articleProperty, searchWord) => {
    const isThereEnoughInfo = article && articleProperty && searchWord;
    if (!isThereEnoughInfo) {
        return false;
    }
    return get(article, `${articleProperty}`, '')
        .toLowerCase()
        .includes(searchWord.toLowerCase());
};

export const filterArticles = (articles, articleProperty, searchWord) => {
    if (!articles) {
        return [];
    }
    const isThereEnoughInfo = searchWord && articleProperty;
    if (!isThereEnoughInfo) {
        return articles;
    }
    // eslint-disable-next-line arrow-body-style
    const filteredArticles = articles.filter((module) => {
        // eslint-disable-next-line max-len
        return find(module.articles, (article) => (matchingFunction(article, articleProperty, searchWord)));
    }).map((module) => {
        // eslint-disable-next-line arrow-body-style
        const foundArticles = module.articles.filter((article) => {
            return matchingFunction(article, articleProperty, searchWord);
        });
        return {
            ...module,
            articles: foundArticles,
        };
    });
    return filteredArticles;
};

export const parseArticle = (article) => {
    const parsedArticle = {
        ...article,
        ...article.general,
        url: article.contentItemId,
    };
    delete parsedArticle.general;
    return parsedArticle;
};

export const sortArticles = (articles, articleProperty) => {
    if (!articles) {
        return [];
    }

    if (!articleProperty) {
        return articles;
    }

    const sortedArticlesByModule = sortBy(articles, [`${articleProperty}`]);
    const groupedArticlesByModule = groupBy(sortedArticlesByModule, `${articleProperty}`);
    const sortedArticles = [];
    const groupedArticlesKeys = Object.keys(groupedArticlesByModule);

    for (let i = 0; i < groupedArticlesKeys.length; i += 1) {
        const key = groupedArticlesKeys[i];
        const currentModule = groupedArticlesByModule[key];
        const parsedArticles = currentModule.map((article) => (parseArticle(article)));
        const orderedArticlesNumerically =
                sortBy(parsedArticles.filter((article) => article.order), ['order']);
        const orderedArticlesAlphabetically =
            sortBy(parsedArticles.filter((article) => !article.order), ['title']);

        sortedArticles.push({
            name: key,
            articles: [
                ...orderedArticlesNumerically,
                ...orderedArticlesAlphabetically,
            ],
        });
    }

    return sortedArticles;
};
