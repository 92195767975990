import './contextualHelp.scss';

import {
    connect,
} from 'react-redux';
import { withRouter } from 'react-router';
import { DragDropContext } from 'react-dnd';
import {
    flow,
    isEmpty,
} from 'lodash';
import PropTypes from 'prop-types';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';
import MultiBackend from 'react-dnd-multi-backend';
import React from 'react';
import HelpContextualFABContainer from './contextualHelpFABContainer.jsx';
import helpActions from '../help.actions.js';

const propTypes = {
    allArticles: PropTypes.arrayOf(PropTypes.shape({})),
    article: PropTypes.shape({}),
    breakpoint: PropTypes.shape({}).isRequired,
    filteredArticles: PropTypes.arrayOf(PropTypes.shape({})),
    isCmsPreviewEnabled: PropTypes.bool,
    routes: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
    })).isRequired,
};

const defaultProps = {
    allArticles: undefined,
    article: undefined,
    filteredArticles: undefined,
    isCmsPreviewEnabled: false,
};

const mapStateToProps = (state) => {
    const {
        breakpoint,
        help: {
            articles,
            article,
            filteredArticles,
        },
        myAccount: {
            preferences: {
                isCmsPreviewEnabled,
            },
        },
    } = state;

    return {
        allArticles: articles,
        article,
        breakpoint,
        filteredArticles,
        isCmsPreviewEnabled,
    };
};
class HelpContextualDNDContextProvider extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            isContainerOpen: false,
        };

        this.onButtonClick = this.onButtonClick.bind(this);
    }

    componentDidMount() {
        const {
            allArticles,
            article,
        } = this.props;

        if (isEmpty(allArticles)) {
            helpActions.getAllHelpArticles();
        }

        if (!isEmpty(article)) {
            this.setState({
                isContainerOpen: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            article: nextArticleProp,
            isCmsPreviewEnabled,
            routes,
        } = this.props;

        const {
            isCmsPreviewEnabled: prevIsCmsPreviewEnabled,
            routes: prevRoutes,
            filteredArticles: prevFilteredArticles,
        } = prevProps;

        const {
            isContainerOpen,
        } = this.state;

        if (!prevState.isContainerOpen && !isContainerOpen && !isEmpty(nextArticleProp)) {
            this.setState({
                isContainerOpen: true,
            });
        }

        if (prevIsCmsPreviewEnabled !== isCmsPreviewEnabled) {
            helpActions.getAllHelpArticles();
        }

        if (routes[1].path !== prevRoutes[1].path && !isEmpty(prevFilteredArticles)) {
            helpActions.resetFilteredHelpArticles();
        }
    }

    onButtonClick() {
        const { isContainerOpen } = this.state;

        this.setState({
            isContainerOpen: !isContainerOpen,
        });
    }

    render() {
        const {
            allArticles,
            article,
            breakpoint,
            filteredArticles,
        } = this.props;

        const { isContainerOpen } = this.state;

        return (
            <HelpContextualFABContainer
                allArticles={allArticles}
                article={article}
                breakpoint={breakpoint}
                filteredArticles={filteredArticles}
                isContainerOpen={isContainerOpen}
                onClick={this.onButtonClick}
            />
        );
    }
}

HelpContextualDNDContextProvider.propTypes = propTypes;
HelpContextualDNDContextProvider.defaultProps = defaultProps;

export default flow(
    connect(mapStateToProps),
    DragDropContext(MultiBackend(HTML5toTouch)),
)(withRouter(HelpContextualDNDContextProvider));
