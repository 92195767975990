import {
    Select,
} from '@saddlebackchurch/react-cm-ui';
import {
    isFunction,
    map,
    some,
} from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from './i18nUtils.js';
import bootstrapActions from '../app/bootstrapActions.js';

const i18n = translationFactory('ConnectionCards.Global.EntryDrawerPersonalContent');

const propTypes = {
    className: PropTypes.string,
    clearable: PropTypes.bool,
    countryAlpha3: PropTypes.string,
    disable: PropTypes.bool,
    id: PropTypes.string,
    onRegionChange: PropTypes.func,
    regionsByCountry: PropTypes.arrayOf(PropTypes.shape({})),
    required: PropTypes.bool,
    style: PropTypes.shape({}),
    value: PropTypes.oneOf([
        PropTypes.shape({}),
        PropTypes.string,
    ]),
};

const defaultProps = {
    className: undefined,
    clearable: false,
    countryAlpha3: '',
    disable: false,
    id: undefined,
    onRegionChange: undefined,
    regionsByCountry: [],
    required: false,
    style: undefined,
    value: undefined,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            regionsByCountry,
        },
    } = state;

    return {
        regionsByCountry,
    };
};

export class SelectRegion extends React.Component {
    constructor() {
        super();

        this.state = {
            regionOptions: [],
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { countryAlpha3 } = this.props;

        if (countryAlpha3) {
            this.getRegions();
        }
    }

    componentDidUpdate(prevProps) {
        const { countryAlpha3: prevCountryAlpha3 } = prevProps;
        const { countryAlpha3 } = this.props;

        if (prevCountryAlpha3 !== countryAlpha3) {
            if (countryAlpha3) {
                this.getRegions();
            } else {
                this.setRegionOptions([]);
            }
        }
    }

    onChange(value) {
        const { onRegionChange } = this.props;

        if (isFunction(onRegionChange)) {
            onRegionChange(value);
        }
    }

    getRegions() {
        const {
            countryAlpha3,
        } = this.props;

        bootstrapActions.getRegionsByCountryCode({
            countryCode: countryAlpha3,
        }).then(() => {
            const {
                regionsByCountry,
            } = this.props;

            const newRegionOptions = map(regionsByCountry, (region) => ({
                value: region.shortName,
                label: region.longName,
                country: region.country,
            }));

            this.setRegionOptions(newRegionOptions);
        });
    }

    setRegionOptions(regionOptions) {
        const {
            countryAlpha3,
            value: regionCode,
        } = this.props;

        this.setState({
            regionOptions,
        }, () => {
            const regionExists = some(
                regionOptions,
                (item) => item.value === regionCode && item.country === countryAlpha3,
            );

            if (!regionExists) {
                this.onChange(null);
            }
        });
    }

    render() {
        const {
            className,
            clearable,
            disable,
            id,
            onRegionChange,
            required,
            style,
            value,
        } = this.props;

        const {
            regionOptions,
        } = this.state;

        return (
            <Select
                className={className}
                clearable={clearable}
                disable={disable}
                fluid
                id={id}
                label={i18n('Region')}
                onChange={onRegionChange}
                options={regionOptions}
                matchProp="any"
                placeholder={i18n('Select')}
                required={required}
                searchable
                style={style}
                value={value}
            />
        );
    }
}

SelectRegion.propTypes = propTypes;
SelectRegion.defaultProps = defaultProps;

export default connect(mapStateToProps)(SelectRegion);
