import AppDispatcher from '../../dispatcher/AppDispatcher';
import NotificationImportProgressConstants from '../../constants/Notification/NotificationImportProgressConstants';

export default class NotificationImportProgressActionCreators {
    static onReceive(importProgress) {
        AppDispatcher.dispatch({
            actionType: NotificationImportProgressConstants.ON_GET_NOTIFICATION_IMPORT_PROGRESS,
            importProgress,
        });
    }
}
