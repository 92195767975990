import { selectBoxTransform } from '../../../global/utils';

export const DEFAULT_STATE = {
    tags: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'EventTagActions.ON_GETALLCATEGORIES':
            return {
                ...state,
                tags: selectBoxTransform(action, 'id', 'name'),
            };

        default:
            return state;
    }
};
