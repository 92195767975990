import { sortBy } from 'lodash';
import {
    addOrReplaceBy,
    selectBoxTransform,
} from '../global/utils';
import AppDispatcher from '../js/dispatcher/AppDispatcher.js';
import BootstrapConstants from '../js/constants/BootstrapConstants.js';
import FeatureToggleStore from '../global/featureToggleStore.js';
import UserAccessStore from '../global/userAccessStore.js';
import UserIdentityStore from '../global/userIdentityStore';

export const DEFAULT_STATE = {
    apiVersion: '',
    appSettings: {},
    // TODO/FIXME: Fix this silly two-level "enumerations" issue
    enumerations: {
        enumerations: {},
    },
    eventCheckInLabels: [],
    eventContactPositions: [],
    featureToggles: new FeatureToggleStore(),
    hasCoreBootstrapLoaded: false,
    regionsByCountry: [],
    securityContext: {
        userAccess: new UserAccessStore(),
        userIdentity: new UserIdentityStore(),
    },
    servingOpportunityOptions: {},
    uploadingOptions: {},
    userPreference: {},
};

let featuresInternal;
let previewFeaturesInternal;

const bootstrap = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'CoreBootstrapActions.ON_GETAPIVERSION':
            return {
                ...state,
                apiVersion: action.result.apiVersion,
            };

        case 'CoreBootstrapActions.ON_GETBOOTSTRAP':
        {
            // Dispatch for legacy Flux based infrastructure
            // TODO: Refactor the below dispatch not be used one day.
            AppDispatcher.dispatch({
                actionType: BootstrapConstants.GET_BOOTSTRAP,
                data: action.result,
            });

            const {
                result: {
                    apiVersion,
                    appSettings,
                    features,
                    previewFeatures,
                    securityContext,
                    servingOpportunityOptions,
                    uploadingOptions,
                    userPreference,
                },
            } = action;

            featuresInternal = features;
            previewFeaturesInternal = previewFeatures;

            return {
                ...state,
                apiVersion,
                appSettings,
                featureToggles: new FeatureToggleStore(featuresInternal, previewFeaturesInternal),
                hasCoreBootstrapLoaded: true,
                securityContext: {
                    userAccess: new UserAccessStore(securityContext),
                    userIdentity: new UserIdentityStore(securityContext),
                },
                servingOpportunityOptions,
                uploadingOptions,
                userPreference,
            };
        }

        case 'CoreBootstrapActions.ON_GETENUMERATIONS':
        case 'EventsBootstrapActions.ON_GETENUMERATIONS':
        {
            // TODO/FIXME: Fix this silly two-level "enumerations" issue
            const enumerations = {
                enumerations: {
                    ...state.enumerations.enumerations,
                    ...action.result.enumerations,
                },
            };

            // Dispatch for legacy Flux based infrastructure
            // TODO: Refactor the below dispatch not be used one day.
            AppDispatcher.dispatch({
                actionType: BootstrapConstants.GET_ENUMERATIONS,
                data: enumerations,
            });

            return {
                ...state,
                enumerations,
            };
        }

        case 'CoreBootstrapActions.ON_GETREGIONSBYCOUNTRYCODE':
            // Dispatch for legacy Flux based infrastructure
            // TODO: Refactor the below dispatch not be used one day.
            AppDispatcher.dispatch({
                actionType: BootstrapConstants.GET_REGIONS_BY_COUNTRY_COMPLETED,
                data: action.result,
            });

            return {
                ...state,
                regionsByCountry: selectBoxTransform(action, 'shortName', 'longName'),
            };

        case 'CoreBootstrapActions.ON_TOGGLEPREVIEWFEATURE':
        {
            const { result: updatedFeature } = action;
            const updatedPreviewFeatures = addOrReplaceBy(
                previewFeaturesInternal,
                (feature) => feature.id === updatedFeature.id,
                () => updatedFeature,
            );

            previewFeaturesInternal = updatedPreviewFeatures;

            return {
                ...state,
                featureToggles: new FeatureToggleStore(featuresInternal, previewFeaturesInternal),
            };
        }

        case 'EventsBootstrapActions.ON_GETCHECKINLABELS':
            return {
                ...state,
                eventCheckInLabels: sortBy(action.result, 'uiOrder'),
            };

        default:
            // no-op
    }

    return state;
};

export default bootstrap;
