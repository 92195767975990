import { combineReducers } from 'redux';
import globalPerson from './globalPersonAuditLogReducer';
import singleEntity from './singleEntityAuditLogReducer';

const reducers = combineReducers({
    singleEntity,
    globalPerson,
});

export default reducers;
