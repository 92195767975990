import PushNotificationConstants from '../../constants/Notification/PushNotificationConstants';

export default class PushNotificationActions {
    static connect(connected) {
        return {
            type: PushNotificationConstants.CONNECT,
            connected,
        };
    }
}
