enum JourneyIconStepTypes {
    AttendAnEvent = 'calendar',
    AttendAServingOpportunity = 'volunteer',
    FillOutAForm = 'connection-cards',
    FillOutAFormApproval = 'connection-cards',
    ListenToAudio = 'audio',
    ReadADocument = 'document',
    ReadText = 'document',
    RegisterForAnEvent = 'calendar',
    RegisterForAMinistry = 'ministry',
    RegisterForAServingOpportunity = 'volunteer',
    TakeAQuiz = 'quiz',
    VisitALink = 'external-link',
    WatchAVideo = 'media',
}

export default JourneyIconStepTypes;
