const DEFAULT_STATE = {
    avatarImage: {
        filename: '',
        url: '',
    },
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'AvatarManagerActions.ON_UPDATE_AVATAR': {
            const {
                value: {
                    filename,
                    url,
                },
            } = action;
            return {
                ...state,
                avatarImage: {
                    ...state.avatarImage,
                    filename,
                    url,
                },
            };
        }

        case 'AvatarManagerActions.ON_RESET_AVATAR': {
            return {
                ...state,
                avatarImage: DEFAULT_STATE.avatarImage,
            };
        }

        default:
            return state;
    }
};
