import { appReduxStore } from '../../global/configureReduxStore.js';
import BannerAction from '../reduxActions/BannerAction.js';
import BannerConstants from '../constants/BannerConstants.js';

export default class BannerUtils {
    static addBanner(obj) {
        let newLevel;

        switch (obj.level) {
            case 'error':
                newLevel = BannerConstants.BANNER_ERROR;
                break;
            case 'success':
                newLevel = BannerConstants.BANNER_SUCCESS;
                break;
            case 'warning':
                newLevel = BannerConstants.BANNER_WARNING;
                break;
            case 'secondary':
            default:
                newLevel = BannerConstants.BANNER_SECONDARY;
        }

        return appReduxStore.dispatch(BannerAction.addBanner({
            children: obj.children,
            className: obj.className,
            id: obj.id,
            level: newLevel,
            levelIcon: obj.levelIcon,
            message: obj.message,
            title: obj.title,
            type: obj.type,
            onClose: obj.onClose,
        }, obj.timeout));
    }

    static closeBanner(id) {
        return appReduxStore.dispatch(BannerAction.closeBanner(id));
    }

    static updateBanner(id, obj) {
        return appReduxStore.dispatch(BannerAction.updateBanner(id, obj));
    }
}
