import './personNotesDrawer.scss';

import {
    clone,
    filter,
    find,
    get,
    isEmpty,
    isEqual,
    kebabCase,
    map,
    remove,
    snakeCase,
    some,
} from 'lodash';
import {
    ActivityIndicator,
    Button,
    Checkbox,
    Drawer,
    Icon,
    List,
    Select,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import moment from 'moment-timezone';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import ScrollBar from 'react-custom-scrollbars';
import withStyles from '@saddlebackchurch/react-cm-ui/core/styles/withStyles';
import { borderColor } from '../../../global/styles/colors.scss';
import {
    notePropTypes,
    noteFormDefaultProps,
    noteFormPropTypes,
} from './notePropTypes.js';
import {
    personDefaultProps,
    personPropTypes,
} from './personPropTypes.js';
import { translationFactory } from '../../../global/i18nUtils.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import * as personRecordActions from './personRecord.actions.js';
import { setIsNotesDrawerOpen as setIsNotesDrawerOpenAction } from './personRecord.actions.js';
import BannerUtils from '../../../js/utils/BannerUtils.js';
import LoadMoreTrigger from '../../../js/components/loadMoreTrigger.jsx';
import PersonNoteForm from './personNoteForm.jsx';
import personNotesActions from './personNotesActions.js';
import PersonNotesDrawerNote from './personNotesDrawerNote.jsx';
import PersonNotesDrawerWing from './personNotesDrawerWing.jsx';
import UserAccessStore from '../../../global/userAccessStore.js';
import {
    buildCalendarSchedulePayload,
    buildReminderPayload,
    getNotesWithExpiredReminders,
} from './noteReminders/noteRemindersUtils';
import {
    resetReminderForm,
    setIsReminderFormModalOpen,
    setSelectedNote,
    setSelectedReminder,
} from './noteReminders/noteReminderActions';
import eventReminderActions from '../../../event/eventsCentral/actions/eventReminder.actions';
import { NoteReminderModalConnected as NoteReminderModal } from './noteReminders/noteReminderModal';
import UserIdentityStore from '../../../global/userIdentityStore';
import { NOTE_REMINDER_DEFAULT_FILTER_VALUE } from './noteReminders/noteReminderConstants';

const i18n = translationFactory('Person.Record.Notes');

const BLOCK_CLASSNAME = 'person_record--notes_drawer';

const DEFAULT_SORT_BY = {
    label: 'Date (Descending)',
    sortField: 'createdate',
    value: 'desc',
};

const DEFAULT_FILTERS = {
    sortBy: DEFAULT_SORT_BY,
    isPinned: true,
    isRemindersChecked: false,
    selectedCategories: [],
};

const BREAKPOINT_DOWN = 864;
const BREAKPOINT_UP = 865;
const NOTES_DRAWER_FILTER_RAILS_WIDTH = 250;
export const DRAWER_MAX_WIDTH_CONTRACT_DESKTOP = 320;
const DRAWER_MIN_HEIGHT = 382;
const DEFAULT_DRAWER_WIDTH = 768;
const NOTE_CATEGORY_SENSITIVE_ID = 2;

const propTypes = {
    canLoadMore: PropTypes.bool.isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    classes: PropTypes.shape({
        root: PropTypes.string,
        scrollbarContentView: PropTypes.string,
    }),
    clientHeight: PropTypes.number.isRequired,
    clientWidth: PropTypes.number.isRequired,
    entityType: PropTypes.string,
    entityId: PropTypes.number,
    filters: PropTypes.shape({
        isPinned: PropTypes.bool,
        isRemindersChecked: PropTypes.bool.isRequired,
        selectedCategories: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
        sortBy: PropTypes.shape({
            label: PropTypes.string,
            sortField: PropTypes.string,
            value: PropTypes.string,
        }),
    }).isRequired,
    hasCreateReminderButtonBeenClicked: PropTypes.bool.isRequired,
    hasPinnedNotes: PropTypes.bool.isRequired,
    isDrawerChild: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    isNotesDrawerExpanded: PropTypes.bool.isRequired,
    isNotesDrawerOpen: PropTypes.bool.isRequired,
    isNotesFetching: PropTypes.bool.isRequired,
    needsToRequest: PropTypes.bool.isRequired,
    noteDefaultCategoryId: PropTypes.number,
    noteFormData: noteFormPropTypes,
    notesList: PropTypes.arrayOf(
        notePropTypes,
    ),
    openSelectedNote: PropTypes.bool,
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    params: PropTypes.shape({}),
    personData: personPropTypes,
    personId: PropTypes.number,
    reminderFormData: PropTypes.shape({}).isRequired,
    resetReminderForm: PropTypes.func.isRequired,
    selectedNote: notePropTypes,
    selectedReminder: PropTypes.shape({
        id: PropTypes.number,
    }),
    setIsNotesDrawerOpen: PropTypes.func.isRequired,
    setSelectedNote: PropTypes.func.isRequired,
    setSelectedReminder: PropTypes.func.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
    userIdentity: PropTypes.instanceOf(UserIdentityStore).isRequired,
    wingOpenType: PropTypes.oneOf(['details', 'new_note_form', 'new_reminder_form']),
};

const defaultProps = {
    categories: [],
    classes: {},
    entityType: 'person',
    entityId: null,
    isDrawerChild: false,
    noteDefaultCategoryId: undefined,
    noteFormData: noteFormDefaultProps,
    notesList: [],
    openSelectedNote: false,
    params: undefined,
    personData: personDefaultProps,
    personId: null,
    selectedNote: {},
    selectedReminder: {},
    wingOpenType: false,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
                userIdentity,
            },
        },
        breakpoint: {
            clientWidth,
            clientHeight,
        },
        people: {
            record: {
                index: {
                    isNotesDrawerExpanded,
                    isNotesDrawerOpen,
                },
                notes: {
                    data: notesList,
                    hasPinnedNotes,
                    canLoadMore,
                    isFetching: isNotesFetching,
                    needsToRequest,
                    pageNumber,
                    pageSize,
                    wingType: wingOpenType,
                },
                notesCategories: {
                    data: categories,
                },
                noteForm: {
                    formData: noteFormData,
                    hasCreateReminderButtonBeenClicked,
                    isReminderFormModalOpen,
                    reminderFormData,
                    selectedNote,
                    selectedReminder,
                },
                notesFilter: {
                    filterData: filters,
                },
                person: {
                    data: personData,
                },
            },
        },
    } = state;

    return {
        canLoadMore,
        categories,
        clientHeight,
        clientWidth,
        hasCreateReminderButtonBeenClicked,
        isNotesDrawerExpanded,
        isNotesDrawerOpen,
        personData,
        filters,
        hasPinnedNotes,
        isNotesFetching,
        isReminderFormModalOpen,
        needsToRequest,
        notesList,
        noteFormData,
        pageNumber,
        pageSize,
        reminderFormData,
        selectedNote,
        selectedReminder,
        userAccess,
        userIdentity,
        wingOpenType,
    };
};

const mapDispatchToProps = {
    resetReminderForm,
    setIsNotesDrawerOpen: setIsNotesDrawerOpenAction,
    setIsReminderFormModalOpen,
    setSelectedNote,
    setSelectedReminder,
};

const styles = (theme) => ({
    root: {
        '&.person_record--notes_drawer-is_not_drawer_child': {
            zIndex: 'unset !important',
        },
        '& .person_record--notes_drawer--filter_rails': {
            zIndex: 10001,
            '& .ui.rail': {
                padding: '2px 0',
            },
            '&_filter-label': {
                fontWeight: theme.typography.h3.fontWeight,
                lineHeight: theme.typography.pxToRem(16),
                marginBottom: theme.typography.pxToRem(16),
                '&:not(:first-child)': {
                    margin: 'calc(2rem - .14285em) 0 1rem',
                    position: 'relative',
                },
            },
        },
        '& .select': {
            width: '100%',
        },
    },
    scrollbarContentView: {
        padding: '20px 11px 20px 22px',
    },
});

export class PersonNotesDrawer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeLoadMore: false,
            dirtyFilters: DEFAULT_FILTERS,
            isNotePinDisabled: false,
            isNoteSaveDisabled: false,
            selectedNote: {},
            isFiltersDrawerOpen: false,
        };

        this.addReminderToNote = this.addReminderToNote.bind(this);
        this.onCloseNotesDrawerForWing = this.onCloseNotesDrawerForWing.bind(this);
        this.onNoteEditClick = this.onNoteEditClick.bind(this);
        this.onNotePinClick = this.onNotePinClick.bind(this);
        this.onNoteRemoveClick = this.onNoteRemoveClick.bind(this);
        this.onNoteSaveClick = this.onNoteSaveClick.bind(this);
        this.onViewNoteDetails = this.onViewNoteDetails.bind(this);
        this.onApplyFiltersDrawerClick = this.onApplyFiltersDrawerClick.bind(this);
        this.onClearFiltersDrawerClick = this.onClearFiltersDrawerClick.bind(this);
        this.onFilterCategoryCheckboxChange = this.onFilterCategoryCheckboxChange.bind(this);
        this.onFilterCategoryNestedToggles = this.onFilterCategoryNestedToggles.bind(this);
        this.onFiltersDrawerToggle = this.onFiltersDrawerToggle.bind(this);
        this.onFiltersDropdownChange = this.onFiltersDropdownChange.bind(this);
        this.onFiltersToggleChange = this.onFiltersToggleChange.bind(this);
        this.onNewNoteClick = this.onNewNoteClick.bind(this);
        this.onNotesDrawerExpandToggle = this.onNotesDrawerExpandToggle.bind(this);
        this.onNotesDrawerToggle = this.onNotesDrawerToggle.bind(this);
        this.onRemindersFilterToggle = this.onRemindersFilterToggle.bind(this);
        this.removeReminderFromNote = this.removeReminderFromNote.bind(this);
        this.search = this.search.bind(this);
        this.onLoadMoreChange = this.onLoadMoreChange.bind(this);
    }

    componentDidMount() {
        const {
            isMobile,
            openSelectedNote,
            params,
            selectedNote,
        } = this.props;

        const coreAppContainer = document.querySelector('#coreApp');

        if (coreAppContainer) {
            coreAppContainer.style.zIndex = 'unset';
        }

        const defaultFilter = get(params, 'filterType');

        if (defaultFilter === NOTE_REMINDER_DEFAULT_FILTER_VALUE) {
            personRecordActions.setIsNotesDrawerExpanded(true);

            personNotesActions.toggleIsRemindersChecked()
                .then(() => {
                    this.search();
                });

            this.setState((prevState) => ({
                dirtyFilters: {
                    ...prevState.dirtyFilters,
                    isRemindersChecked: !prevState.dirtyFilters.isRemindersChecked,
                },
            }));
        } else {
            this.search();
        }

        personNotesActions.getCategories();

        if (isMobile && openSelectedNote) {
            this.onNotesDrawerToggle().then(() => {
                this.setState({
                    selectedNote,
                }, () => {
                    PersonNotesDrawer.onOpenNoteWing('details');
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            notesList: prevNotesList,
            personId: prevPersonId,
        } = prevProps;

        const {
            notesList,
            params,
            personId,
        } = this.props;

        const shouldRequestNotes = prevPersonId !== personId;
        const shouldEvaluateExpiredReminders = prevNotesList.length !== notesList.length;

        if (shouldRequestNotes) {
            const defaultFilter = get(params, 'filterType');

            if (defaultFilter === NOTE_REMINDER_DEFAULT_FILTER_VALUE) {
                personRecordActions.setIsNotesDrawerExpanded(true);

                personNotesActions.toggleIsRemindersChecked()
                    .then(() => {
                        this.search();
                    });

                this.setState((prevState) => ({
                    dirtyFilters: {
                        ...prevState.dirtyFilters,
                        isRemindersChecked: !prevState.dirtyFilters.isRemindersChecked,
                    },
                }));
            } else {
                this.search();
            }
        } else if (shouldEvaluateExpiredReminders) {
            const filteredNotesList = notesList.filter((note) => note.categoryName !== 'Audit Log');
            const notesWithReminders = filteredNotesList
                .filter((note) => note.reminderId);
            const reminderIds = `${notesWithReminders.map((note) => note.reminderId).join(',')}`;

            eventReminderActions.getReminders({
                ids: reminderIds,
            }).then((reminders) => {
                const notesWithExpiredReminders = getNotesWithExpiredReminders(
                    notesWithReminders,
                    reminders,
                );

                notesWithExpiredReminders.forEach((note, index) => {
                    const shouldRefetchAfterLastUpdatedNote = index ===
                        notesWithExpiredReminders.length - 1;
                    const callback = shouldRefetchAfterLastUpdatedNote && this.search;
                    this.removeReminderFromNote(note, note.expiredReminder, callback);
                });
            });
        }
    }

    componentWillUnmount() {
        const coreAppContainer = document.querySelector('#coreApp');

        if (coreAppContainer) {
            coreAppContainer.style = undefined;
        }
    }

    onCloseNotesDrawerForWing() {
        const {
            wingOpenType,
        } = this.props;

        if (wingOpenType !== 'new_note_form') {
            personNotesActions.setWingType();
        }

        this.onNotesDrawerToggle();
    }

    onNewNoteClick() {
        const {
            resetReminderForm: resetReminderFormAction,
            setSelectedNote: setSelectedNoteAction,
            setSelectedReminder: setSelectedReminderAction,
        } = this.props;

        setSelectedReminderAction();
        resetReminderFormAction();
        setSelectedNoteAction();
        personNotesActions.resetNoteForm();
        personNotesActions.resetNoteForm().then(() => {
            personNotesActions.setWingType('new_note_form');
        });
    }

    onNotesDrawerExpandToggle() {
        const {
            isNotesDrawerExpanded,
        } = this.props;

        personRecordActions.setIsNotesDrawerExpanded(!isNotesDrawerExpanded);
    }

    onNotesDrawerToggle() {
        const {
            isNotesDrawerOpen,
            setIsNotesDrawerOpen,
        } = this.props;

        return new Promise((resolve) => {
            personNotesActions.setWingType();
            setIsNotesDrawerOpen(!isNotesDrawerOpen);
            resolve();
        });
    }

    onNoteEditClick(noteId) {
        const {
            notesList,
        } = this.props;
        const noteData = find(notesList, { id: noteId });

        personNotesActions.setNoteFormData(noteData);
        personNotesActions.setWingType('new_note_form');
    }

    onNotePinClick(noteId, isPinned) {
        const {
            isMobile,
            notesList,
            personId,
        } = this.props;
        const api = isPinned ? personNotesActions.unpinNote : personNotesActions.pinNote;

        this.setState({
            isNotePinDisabled: true,
        }, () => {
            api({
                personId,
            }, [noteId]).then(() => {
                if (!isPinned) {
                    BannerUtils.addBanner({
                        children: (
                            <p style={{ margin: 0 }}>
                                {i18n('PinNoteSuccessMsg')}
                            </p>
                        ),
                        id: `${BLOCK_CLASSNAME}--pin_note_banner_success`,
                        level: 'success',
                        timeout: true,
                        type: 'notification',
                    });
                } else {
                    BannerUtils.addBanner({
                        children: (
                            <p style={{ margin: 0 }}>
                                {i18n('UnPinNoteSuccessMsg')}
                            </p>
                        ),
                        id: `${BLOCK_CLASSNAME}--pin_note_banner_success`,
                        level: 'success',
                        timeout: true,
                        type: 'notification',
                    });
                }
                this.setState({
                    isNotePinDisabled: false,
                });

                if (isMobile) {
                    this.search();
                    const note = find(notesList, { id: noteId });
                    this.setState({ selectedNote: note });
                    personNotesActions.setWingType();
                } else {
                    this.search();
                }
            }).catch(() => {
                this.setState({ isNotePinDisabled: false });
            });
        });
    }

    onNoteRemoveClick(noteId) {
        const { isMobile, personId } = this.props;
        personNotesActions.delete({
            personId,
            noteId,
        }).then(() => {
            BannerUtils.addBanner({
                children: (
                    <p style={{ margin: 0 }}>
                        {i18n('RemoveNoteSuccessMsg')}
                    </p>
                ),
                id: `${BLOCK_CLASSNAME}--remove_person_note_success`,
                level: 'success',
                timeout: true,
                type: 'notification',
            });

            if (isMobile) {
                this.search();
                personNotesActions.setWingType();
            } else {
                this.search();
            }
        });
    }

    static onOpenNoteWing(type) {
        personNotesActions.setWingType(type);
    }

    onNoteSaveClick() {
        const {
            categories,
            entityType,
            entityId,
            hasCreateReminderButtonBeenClicked,
            isMobile,
            noteFormData: {
                categoryId,
                noteId,
                subject,
                noteText,
            },
            personId,
            reminderFormData,
            selectedReminder,
        } = this.props;

        this.setState({
            isNoteSaveDisabled: true,
        }, () => {
            const mode = noteId ? 'update' : 'create';
            const api = noteId ?
                personNotesActions.update : personNotesActions.create;
            const apiParams = {
                personId,
                ...(noteId && { noteId }),
            };
            const selectedCategory = find(categories, (item) => item.id === categoryId);
            const data = {
                CategoryId: categoryId,
                entityId: entityId || personId,
                entityType,
                isSensitive: categoryId === NOTE_CATEGORY_SENSITIVE_ID,
                subject,
                noteText,
                purpose: selectedCategory.name,
                ...(noteId && { id: noteId }),
                reminderId: selectedReminder?.id,
            };

            api({
                ...apiParams,
            }, data).then((res) => {
                if (hasCreateReminderButtonBeenClicked) {
                    this.addReminderToNote(res, reminderFormData, isMobile, mode);
                }

                BannerUtils.addBanner({
                    children: (
                        <p style={{ margin: 0 }}>
                            {noteId ? i18n('UpdatedNote') : i18n('AddedNote')}
                        </p>
                    ),
                    id: noteId ? 'ui-banner--successfully_updated_person_note' : 'ui-banner--successfully_added_person_note',
                    level: 'success',
                    timeout: true,
                    type: 'notification',
                });

                this.setState({
                    isNoteSaveDisabled: false,
                });

                if (isMobile) {
                    this.setState({
                        selectedNote: res,
                    }, () => {
                        this.search();
                        personNotesActions.setWingType('details');
                    });
                } else {
                    this.search();
                    personNotesActions.setWingType();
                }
                personNotesActions.setNoteFormChangeStatus().then(() => {
                    personNotesActions.resetNoteForm();
                });
            }).catch(() => {
                this.setState({
                    isNoteSaveDisabled: false,
                });
            });
        });
    }

    onViewNoteDetails(note) {
        this.setState({
            selectedNote: note,
        });
    }

    onApplyFiltersDrawerClick() {
        const {
            dirtyFilters,
        } = this.state;

        personNotesActions.setNoteFilterData(dirtyFilters).then(() => {
            this.search();
            this.onFiltersDrawerToggle();
        });
    }

    onClearFiltersDrawerClick() {
        this.setState({
            dirtyFilters: DEFAULT_FILTERS,
        });
        personNotesActions.resetNoteFilters().then(() => {
            this.search();
            this.onFiltersDrawerToggle();
        });
    }

    onFiltersToggleChange() {
        const {
            isMobile,
            filters: {
                isPinned,
            },
        } = this.props;
        if (!isMobile) {
            personNotesActions.updateFilterIsPinned(!isPinned).then(() => {
                this.search();
            });
        }

        this.setState((prevState) => ({
            dirtyFilters: {
                ...prevState.dirtyFilters,
                isPinned: !prevState.dirtyFilters.isPinned,
            },
        }));
    }

    onRemindersFilterToggle() {
        const {
            isMobile,
            filters: {
                isRemindersChecked,
            },
        } = this.props;

        if (!isMobile) {
            personNotesActions.toggleIsRemindersChecked(!isRemindersChecked).then(() => {
                this.search();
            });
        }

        this.setState((prevState) => ({
            dirtyFilters: {
                ...prevState.dirtyFilters,
                isRemindersChecked: !prevState.dirtyFilters.isRemindersChecked,
            },
        }));
    }

    onFiltersDropdownChange(selectedOption) {
        const {
            isMobile,
        } = this.props;
        if (!isMobile) {
            personNotesActions.updateFilterSortBy(selectedOption).then(() => {
                this.search();
            });
        }

        this.setState((prevState) => ({
            dirtyFilters: {
                ...prevState.dirtyFilters,
                sortBy: selectedOption,
            },
        }));
    }

    onFiltersDrawerToggle() {
        const { isFiltersDrawerOpen } = this.state;

        this.setState({
            isFiltersDrawerOpen: !isFiltersDrawerOpen,
        });
    }

    onFilterCategoryNestedToggles(selectedOptions) {
        this.setState((prevState) => ({
            dirtyFilters: {
                ...prevState.dirtyFilters,
                selectedCategories: selectedOptions,
            },
        }));
    }

    onFilterCategoryCheckboxChange(id, checked) {
        const {
            categories,
            filters: {
                selectedCategories,
            },
        } = this.props;
        const filteredNoteCategories = clone(selectedCategories);
        const categoryId = parseInt(id.substring(id.indexOf('_id_') + 4).split('_')[0], 10);
        const selectedCheckboxOption = find(categories, (item) => item.id === categoryId);
        if (!checked) {
            remove(filteredNoteCategories, { value: categoryId });
        } else {
            filteredNoteCategories.push({
                label: selectedCheckboxOption.name,
                value: selectedCheckboxOption.id,
            });
        }
        personNotesActions.updateFilterCategories(filteredNoteCategories).then(() => {
            this.search();
        });
        this.setState((prevState) => ({
            dirtyFilters: {
                ...prevState.dirtyFilters,
                selectedCategories: filteredNoteCategories,
            },
        }));
    }

    onLoadMoreChange(isVisible) {
        if (isVisible) {
            const {
                canLoadMore,
                needsToRequest,
            } = this.props;

            this.setState({ activeLoadMore: false }, () => {
                if (needsToRequest) {
                    this.search(false);
                } else if (canLoadMore) {
                    personNotesActions.nextPage();
                    this.setState({ activeLoadMore: true });
                }
            });
        }
    }

    addReminderToNote(noteData, reminderFormData = {}, shouldSetReminderAsSelected, mode) {
        const {
            userIdentity,
            personId,
            personData,
            resetReminderForm: resetReminderFormAction,
            setSelectedReminder: setSelectedReminderAction,
        } = this.props;

        const userData = {
            senderId: userIdentity.getPersonId(),
            senderName: userIdentity.getFirstName(),
            replyEmail: userIdentity.getPrimaryEmail(),
        };

        const {
            dateTypeOption,
            customDate,
        } = reminderFormData;

        const {
            id: noteId,
        } = noteData;

        const churchEntityId = get(personData, 'churchEntityId') || 0;
        const newSchedulePayload = buildCalendarSchedulePayload(
            churchEntityId,
            dateTypeOption,
            customDate,
        );

        let newReminderPayload = buildReminderPayload(
            userData,
            noteData,
            personData,
            newSchedulePayload,
        );

        newReminderPayload = {
            ...newReminderPayload,
            Schedule: {
                ...newSchedulePayload,
            },
            ReminderDate: newReminderPayload.reminderDate,
            NoteDirectLink: newReminderPayload.noteDirectLink,
        };

        personNotesActions.createReminder({
            personId,
            noteId,
        }, {
            ...newReminderPayload,
        }).then((reminderResult) => {
            if (shouldSetReminderAsSelected) {
                const reminderEndDate = get(reminderResult, 'schedule.endDate');
                const momentDueDate = reminderEndDate && moment(reminderEndDate);
                const newReminder = {
                    ...reminderResult,
                    dueDate: momentDueDate,
                };

                setSelectedReminderAction(newReminder);
            }

            if (mode === 'update') {
                BannerUtils.addBanner({
                    children: (
                        <p style={{ margin: 0 }}>
                            {i18n('Successfully Added Reminder ')}
                        </p>
                    ),
                    id: 'ui-banner--successfully_note_created',
                    level: 'success',
                    timeout: true,
                    type: 'notification',
                });
            }

            resetReminderFormAction();
            this.search();
        });
    }

    removeReminderFromNote(noteData, reminderData, callBack) {
        const {
            personId,
            setSelectedReminder: setSelectedReminderAction,
        } = this.props;

        const {
            id: noteId,
        } = noteData;

        const {
            id: reminderId,
        } = reminderData;

        eventReminderActions.update({
            id: reminderId,
        }, {
            ...reminderData,
            id: reminderId,
            enabled: false,
            schedule: null,
        }).then(() => {
            personNotesActions.update({
                personId,
                noteId,
            }, {
                ...noteData,
                reminderId: null,
            }).then(() => {
                setSelectedReminderAction();
                this.search();

                if (typeof callBack === 'function') {
                    callBack();
                }
            });
        });
    }

    search(first = true) {
        const {
            entityId,
            entityType,
            filters: {
                isPinned,
                selectedCategories,
                sortBy: {
                    sortField,
                    value: sortFieldOrder,
                },
                isRemindersChecked,
            },
            pageNumber,
            pageSize,
            personId,
            userAccess,
        } = this.props;

        const authorizedReadNoteCategoryIds = userAccess.getAuthzdNotesCategoryIdsByPermission(
            USER_PERMISSIONS.readPersonNotes,
        );

        if (!isEmpty(authorizedReadNoteCategoryIds)) {
            const selectedCategoryIds = map(selectedCategories, (item) => item.value);
            const noteCategoryFilter = selectedCategoryIds.join();
            const sortQuery = `${(isPinned === true ? '-ispinned,' : '')}${(sortFieldOrder === 'desc' ? '-' : '')}${sortField}`;
            const params = {
                entityId: entityId ?? personId,
                entityType: entityType ?? 'person',
                noteCategoryFilter,
                pageNumber: first ? 0 : pageNumber + 1,
                pageSize,
                reminderFilter: isRemindersChecked,
                sortQuery,
                sortFieldOrder,
            };

            if (params && params.entityId) {
                personNotesActions.search(params, null, null, { first }).then(
                    () => {
                        this.setState({ activeLoadMore: true });
                    },
                );
            }
        }
    }

    render() {
        const {
            canLoadMore,
            categories,
            classes,
            clientHeight,
            clientWidth,
            filters,
            hasPinnedNotes,
            isDrawerChild,
            isMobile,
            isNotesDrawerExpanded,
            isNotesDrawerOpen,
            isNotesFetching,
            noteDefaultCategoryId,
            notesList,
            noteFormData: {
                categoryId,
                noteId,
                noteText,
            },
            personData,
            personId,
            userAccess,
            wingOpenType,
        } = this.props;

        if (!personId) {
            return null;
        }

        let noteDrawerTitle = i18n('Notes');

        const {
            activeLoadMore,
            dirtyFilters,
            dirtyFilters: {
                isPinned,
                selectedCategories,
                sortBy,
                isRemindersChecked,
            },
            isNoteSaveDisabled,
            isNotePinDisabled,
            isFiltersDrawerOpen,
            selectedNote,
        } = this.state;

        const {
            firstName,
        } = personData;
        const blockClassName = BLOCK_CLASSNAME;
        const blockClassNameWing = `${BLOCK_CLASSNAME}_wing`;
        const blockClassNamesWing = ClassNames(blockClassNameWing, {
            [`${blockClassNameWing}_note_form`]: wingOpenType && wingOpenType === 'new_note_form',
            [`${blockClassNameWing}_note_details`]: wingOpenType && wingOpenType === 'details',
        });
        const blockClassNameFilterRails = `${blockClassName}--filter_rails`;
        const navEl = document.querySelector('.navigation');
        const navWidth = navEl ? navEl.clientWidth : 0;
        const drawerWidthOnExpand = !isDrawerChild ?
            clientWidth - navWidth - 22 : DEFAULT_DRAWER_WIDTH - 22;
        let drawerWidth;

        if (isDrawerChild && isNotesDrawerExpanded) {
            drawerWidth = DEFAULT_DRAWER_WIDTH - 22;
        } else if (isNotesDrawerExpanded) {
            drawerWidth = drawerWidthOnExpand;
        } else {
            drawerWidth = DRAWER_MAX_WIDTH_CONTRACT_DESKTOP;
        }

        const notesContentDrawerWidthOnExpand =
            drawerWidthOnExpand - NOTES_DRAWER_FILTER_RAILS_WIDTH;
        let drawerHeight;
        const personAvatarDesktopDOMEL = document.querySelector('.person_record--person_avatar');
        const AuditLogNoteCategoryId = 3;

        if (personAvatarDesktopDOMEL) {
            const personAvatarOffsetTop = personAvatarDesktopDOMEL.offsetTop;
            drawerHeight = clientHeight - personAvatarOffsetTop - 105 - 140;
        }

        const expandAndContractIconType = isNotesDrawerExpanded ? 'contract' : 'expand';
        const disableNoteSave = (
            isNoteSaveDisabled || !categoryId || isEmpty(noteText)
        );
        const canCreatePersonNotes = userAccess.hasPermission(
            USER_PERMISSIONS.createPersonNotes,
        );

        const authorizedCreateNoteCategoryIds = userAccess.getAuthzdNotesCategoryIdsByPermission(
            USER_PERMISSIONS.createPersonNotes,
        );

        const authorizedReadNoteCategoryIds = userAccess.getAuthzdNotesCategoryIdsByPermission(
            USER_PERMISSIONS.readPersonNotes,
        );

        let categoriesOptions = [];
        if (!isEmpty(authorizedReadNoteCategoryIds)) {
            categoriesOptions = map(categories, (item) => ({
                label: item.name,
                value: item.id,
            }));

            categoriesOptions = filter(
                categoriesOptions, (item) => item.value !== AuditLogNoteCategoryId,
            );
            remove(categoriesOptions, (item) => !authorizedReadNoteCategoryIds.includes(
                item.value,
            ));
        }

        const sortByFilterOptions = [
            {
                label: 'Date (Ascending)',
                sortField: 'createdate',
                value: 'asc',
            }, {
                label: 'Date (Descending)',
                sortField: 'createdate',
                value: 'desc',
            },
        ];

        const filteredNotesList = notesList.filter((note) => note.categoryName !== 'Audit Log');
        const personNotesList = map(filteredNotesList, (note) => (
            <PersonNotesDrawerNote
                addReminderToNote={this.addReminderToNote}
                data={note}
                isExpanded={isNotesDrawerExpanded}
                isMobile={isMobile}
                key={`person-notes-list-${note.id}`}
                onNoteEditClick={this.onNoteEditClick}
                onNotePinClick={this.onNotePinClick}
                onNoteRemoveClick={this.onNoteRemoveClick}
                onOpenNoteWing={PersonNotesDrawer.onOpenNoteWing}
                onViewNoteDetails={this.onViewNoteDetails}
            />
        ));

        const mobileDrawerWing = (
            <Drawer.Wing
                className={blockClassNamesWing}
                isOpen={wingOpenType && !(wingOpenType === 'details' && !selectedNote)}
            >
                <PersonNotesDrawerWing
                    addReminderToNote={this.addReminderToNote}
                    className={blockClassNameWing}
                    disableNoteSave={disableNoteSave}
                    isOpen={!!wingOpenType}
                    isNotePinDisabled={isNotePinDisabled}
                    noteData={selectedNote}
                    onCloseNotesDrawerForWing={this.onCloseNotesDrawerForWing}
                    onOpenNoteWing={PersonNotesDrawer.onOpenNoteWing}
                    onNoteEditClick={this.onNoteEditClick}
                    onNotePinClick={this.onNotePinClick}
                    onNoteRemoveClick={this.onNoteRemoveClick}
                    onNoteSaveClick={this.onNoteSaveClick}
                    removeReminderFromNote={this.removeReminderFromNote}
                    setWingType={personNotesActions.setWingType}
                    wingOpenType={wingOpenType}
                    noteDefaultCategoryId={noteDefaultCategoryId}
                />
            </Drawer.Wing>
        );

        const showLoaderEmptyNotesMsg = (
            isNotesFetching ? (
                <div className={`${blockClassName}--activity_indicator_loader`}>
                    <ActivityIndicator
                        color="backgroundColorStatic"
                        size={48}
                    />
                </div>
            ) : (
                <div className={`${blockClassName}--empty_notes_message_section`}>
                    <div className={`${blockClassName}--empty_notes_message`}>
                        {`${firstName} ${i18n('doesn\'t have any notes yet')}`}
                    </div>
                </div>
            )
        );

        const showPersonNotesList = (
            (isNotesFetching || isEmpty(filteredNotesList)) ? (
                showLoaderEmptyNotesMsg
            ) :
                (
                    <div className={`${blockClassName}--notes_list`}>
                        {personNotesList}
                        {canLoadMore ? (
                            <div
                                className="margin-top text-center"
                                style={{ marginBottom: !isMobile ? '88px' : '0' }}
                            >
                                <LoadMoreTrigger
                                    active={activeLoadMore}
                                    onChange={this.onLoadMoreChange}
                                />
                            </div>
                        ) : null}
                    </div>
                ));

        if (wingOpenType === 'new_note_form') {
            noteDrawerTitle = noteId ? i18n('EditNote') : i18n('NewNote');
        }

        const isDirty = !isEqual(filters, dirtyFilters);
        const isFiltering = !isEqual(filters, DEFAULT_FILTERS);

        const categoriesFilterRailsCheckboxItems = map(categoriesOptions, (item) => {
            const isSelected = some(selectedCategories, (sc) => sc.value === item.value);

            return (
                <List.Item
                    key={`${blockClassNameFilterRails}_category-${kebabCase(item.value)}`}
                >
                    <Checkbox
                        checked={isSelected}
                        id={`${blockClassNameFilterRails}_category_checkbox_id_${item.value}_${snakeCase(item.label)}`}
                        label={item.label}
                        labelWeight="semibold"
                        onChange={this.onFilterCategoryCheckboxChange}
                        size="small"
                    />
                </List.Item>
            );
        });

        let drawerContent;

        if (!isMobile && wingOpenType === 'new_note_form') {
            drawerContent = (
                <PersonNoteForm
                    disableNoteSave={disableNoteSave}
                    onNoteSaveClick={this.onNoteSaveClick}
                    onNoteCancelClick={PersonNotesDrawer.onOpenNoteWing}
                    noteDrawerHeight={drawerHeight}
                    noteDefaultCategoryId={noteDefaultCategoryId}
                />
            );
        } else {
            drawerContent = showPersonNotesList;
        }

        const personNotesDrawerClasses = ClassNames(
            blockClassName,
            classes.root,
            {
                [`${blockClassName}-is_not_drawer_child`]: !isDrawerChild,
            },
        );

        return (
            <Drawer
                className={personNotesDrawerClasses}
                dimmer={isMobile || false}
                isOpen={isNotesDrawerOpen}
                maxHeight={!isMobile ? drawerHeight : undefined}
                maxWidth={!isMobile ? drawerWidth : undefined}
                onClose={this.onNotesDrawerToggle}
                positionY={!isMobile ? 'bottom' : null}
                style={{
                    minHeight: `${DRAWER_MIN_HEIGHT}px`,
                    minWidth: !isMobile && isNotesDrawerExpanded ?
                        `${DRAWER_MAX_WIDTH_CONTRACT_DESKTOP + NOTES_DRAWER_FILTER_RAILS_WIDTH}px` : `${DRAWER_MAX_WIDTH_CONTRACT_DESKTOP}px`,
                }}
                wing={!isMobile ? null : mobileDrawerWing}
            >
                <Drawer.FiltersRail
                    breakpointUp={BREAKPOINT_UP}
                    isOpen={isNotesDrawerExpanded && isEmpty(wingOpenType)}
                    className={blockClassNameFilterRails}
                >
                    <ScrollBar
                        autoHide
                        autoHeight
                        autoHeightMax={drawerHeight}
                        renderView={(props) => (
                            <div
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...props}
                                className={classes.scrollbarContentView}
                            />
                        )}
                    >
                        <Typography
                            className={`${blockClassNameFilterRails}_filter-label`}
                        >
                            {i18n('Sort By')}
                        </Typography>

                        <Select
                            className={`${blockClassNameFilterRails}_filter_sort_by`}
                            clearable={false}
                            fluid
                            onChange={this.onFiltersDropdownChange}
                            options={sortByFilterOptions}
                            placeholder={i18n('Sort By')}
                            selection
                            underline
                            value={sortBy}
                        />

                        <Typography
                            className={`${blockClassNameFilterRails}_filter-label`}
                        >
                            {i18n('Type')}
                        </Typography>

                        <div className={`${blockClassNameFilterRails}_filter_pinned`}>
                            <Icon
                                className={`${blockClassNameFilterRails}_filter_pinned_indicator`}
                                color="highlight"
                                type="pin"
                                role="button"
                            />
                            <Checkbox
                                align="right"
                                checked={hasPinnedNotes ? isPinned : false}
                                disabled={!hasPinnedNotes}
                                className={`${blockClassNameFilterRails}_filter_pinned_toggle`}
                                fluid
                                id="note-is-pinned_768"
                                label={i18n('Pinned')}
                                onChange={this.onFiltersToggleChange}
                                toggle
                            />
                        </div>

                        <Typography
                            className={`${blockClassNameFilterRails}_filter-label`}
                        >
                            {i18n('Reminders')}
                        </Typography>

                        <Checkbox
                            checked={filters.isRemindersChecked}
                            id={`${blockClassNameFilterRails}_tbd_checkbox_id_reminders`}
                            label="My Reminders"
                            labelWeight="semibold"
                            onChange={() => {
                                personNotesActions.toggleIsRemindersChecked()
                                    .then(() => {
                                        this.search();
                                    });
                            }}
                            size="small"
                        />

                        <Typography
                            className={`${blockClassNameFilterRails}_filter-label`}
                        >
                            {i18n('Categories')}
                        </Typography>

                        <List
                            className={`${blockClassNameFilterRails}_category_list`}
                            fluid
                        >
                            {categoriesFilterRailsCheckboxItems}
                        </List>
                    </ScrollBar>
                </Drawer.FiltersRail>

                <Drawer.TitleBar
                    className={`${blockClassName}--title_bar`}
                    style={{
                        borderBottom: wingOpenType !== 'new_note_form' && 'none',
                        left: !isMobile && isNotesDrawerExpanded && isEmpty(wingOpenType) ? '250px' : 0,
                        width: !isMobile && isNotesDrawerExpanded && isEmpty(wingOpenType) ? `${notesContentDrawerWidthOnExpand}px` : '100%',
                    }}
                    closeButton={(
                        <React.Fragment>
                            {!isMobile && (
                                <Icon
                                    align="right"
                                    className={`${blockClassName}--expand_and_contract_toggle`}
                                    id={`${blockClassName}--expand_and_contract_toggle`}
                                    onClick={this.onNotesDrawerExpandToggle}
                                    type={expandAndContractIconType}
                                />
                            )}

                            <Icon
                                align="right"
                                className={`${blockClassName}--close`}
                                id={`${blockClassName}--close`}
                                type={!isMobile ? 'times' : 'contract'}
                                onClick={this.onNotesDrawerToggle}
                            />
                        </React.Fragment>
                    )}
                    title={noteDrawerTitle}
                />

                {isMobile && wingOpenType !== 'new_note_form' && (
                    <Drawer.ActionBar
                        className={`${blockClassName}--mobile_action_bar`}
                        columns={[
                            {
                                button: {
                                    color: 'transparent',
                                    divide: false,
                                    iconType: 'chevron-left',
                                    onClick: this.onNotesDrawerToggle,
                                },
                                flexGrow: 1,
                            },
                            {
                                list: [
                                    {
                                        flexGrow: 1,
                                        jsx: (
                                            <Icon
                                                align="right"
                                                className={`${blockClassName}--filter_icon`}
                                                color={isFiltersDrawerOpen || isFiltering ? 'highlight' : null}
                                                onClick={this.onFiltersDrawerToggle}
                                                title="Filter"
                                                type="filter"
                                            />
                                        ),
                                    }, {
                                        jsx: (
                                            (canCreatePersonNotes &&
                                                !isEmpty(authorizedCreateNoteCategoryIds)) && (
                                                <Button
                                                    className={`${blockClassName}--new_note`}
                                                    color="success"
                                                    id={`${blockClassName}--new_note_375`}
                                                    icon
                                                    onClick={this.onNewNoteClick}
                                                >
                                                    <Icon color="success" type="plus" />
                                                </Button>
                                            )
                                        ),
                                        divide: true,
                                    },
                                ],
                            },
                        ]}
                        style={{
                            borderTop: `1px solid ${borderColor}`,
                        }}
                    />
                )}

                <Drawer.Container>
                    <Drawer.FiltersDrawer
                        breakpointDown={BREAKPOINT_DOWN}
                        className={`${blockClassName}--filter_drawers`}
                        isDirty={isDirty}
                        isFiltering={isFiltering}
                        isOpen={isFiltersDrawerOpen}
                        onApply={this.onApplyFiltersDrawerClick}
                        onClear={this.onClearFiltersDrawerClick}
                        onClose={this.onFiltersDrawerToggle}
                        rows={[
                            {
                                header: i18n('SortBy'),
                                items: [
                                    {
                                        dropdown: {
                                            onChange: this.onFiltersDropdownChange,
                                            options: sortByFilterOptions,
                                            value: sortBy,
                                        },
                                    },
                                ],
                            }, {
                                header: i18n('Type'),
                                items: [
                                    {
                                        toggle: {
                                            checked: hasPinnedNotes ? isPinned : false,
                                            diabled: !hasPinnedNotes,
                                            label: i18n('Pinned'),
                                            labelIconColor: 'highlight',
                                            labelIconType: 'pin',
                                            onChange: hasPinnedNotes ?
                                                this.onFiltersToggleChange :
                                                null,
                                        },
                                    },
                                ],
                            }, {
                                header: i18n('My Reminders'),
                                items: [
                                    {
                                        checkbox: {
                                            checked: isRemindersChecked,
                                            label: i18n('My Reminders'),
                                            onChange: this.onRemindersFilterToggle,
                                        },
                                    },
                                ],
                            }, {
                                header: i18n('Filters'),
                                items: [
                                    {
                                        nestedToggles: {
                                            label: i18n('Categories'),
                                            onChange: this.onFilterCategoryNestedToggles,
                                            options: categoriesOptions,
                                            value: selectedCategories,
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                    <Drawer.Content
                        className={`${blockClassName}--content`}
                        style={{
                            left: !isMobile && isNotesDrawerExpanded && isEmpty(wingOpenType) ? '250px' : 0,
                            width: !isMobile && isNotesDrawerExpanded && isEmpty(wingOpenType) ? `${notesContentDrawerWidthOnExpand}px` : '100%',
                        }}
                    >
                        {drawerContent}
                        {!isMobile && (
                            <NoteReminderModal
                                addReminderToNote={this.addReminderToNote}
                                removeReminderFromNote={this.removeReminderFromNote}
                            />
                        )}
                    </Drawer.Content>
                    {!isMobile && isEmpty(wingOpenType) && (
                        (canCreatePersonNotes &&
                            !isEmpty(authorizedCreateNoteCategoryIds)) && (
                            <div
                                className={`${blockClassName}--footer_section`}
                                style={{
                                    left: isNotesDrawerExpanded ? '250px' : 0,
                                    width: isNotesDrawerExpanded ? `${notesContentDrawerWidthOnExpand}px` : '100%',
                                }}
                            >
                                <div className={`${blockClassName}--footer_top_border`} />
                                <div className={`${blockClassName}--new_note_wrapper`}>
                                    <Button
                                        className={`${blockClassName}--new_note`}
                                        color="success"
                                        id={`${blockClassName}--new_note_768`}
                                        icon
                                        onClick={this.onNewNoteClick}
                                    >
                                        <Icon color="success" type="plus" />
                                    </Button>
                                </div>
                            </div>
                        )
                    )}
                </Drawer.Container>
            </Drawer>
        );
    }
}

PersonNotesDrawer.propTypes = propTypes;
PersonNotesDrawer.defaultProps = defaultProps;

const PersonNotesDrawerWithStyles = withStyles(styles, { useTheme: true })(PersonNotesDrawer);

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps,
)(PersonNotesDrawerWithStyles));
