import _ from 'lodash';
import ChunkedPaginationUtils from '../global/chunkedPaginationUtils.js';

const pagedRecipients = new ChunkedPaginationUtils();

const DEFAULT_STATE = {
    canLoadMore: false,
    isFetching: false,
    members: [],
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedRecipients.getPageSize(),
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    const first = !_.isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        case 'MultiMinistryCommunicationsActions.ON_BEFORE_GETRECIPIENTSINTERNAL':
            return { ...state, isFetching: true };

        case 'MultiMinistryCommunicationsActions.ON_GETRECIPIENTSINTERNAL':
        {
            const { results, resultCount } = actionResult;
            pagedRecipients.loadPage(results, resultCount, first);

            // Fall through to next page case
        }

        /* eslint-disable no-fallthrough */
        case 'MultiMinistryCommunicationsActions.ON_GET_RECIPIENTS_NEXT_PAGE':
            return {
                ...state,
                canLoadMore: pagedRecipients.canLoadMore(),
                isFetching: false,
                members: pagedRecipients.getAll(true),
                needsToRequest: pagedRecipients.needsToLoadPage(),
                pageNumber: pagedRecipients.getCurrentPageNumber(),
                pageSize: pagedRecipients.getPageSize(),
                total: first ? pagedRecipients.getTotalCount() : state.total,
            };
            /* eslint-enable no-fallthrough */

        case 'MultiMinistryCommunicationsActions.ON_RESET_RECIPIENTS':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};
