import { i18n } from '../../../../../global/constants.js';

export enum StudentAgeGroup {
    AdultsOnly = 'AdultsOnly',
    IncludeStudents = 'IncludeStudents',
    None = 'None',
    StudentsOnly = 'StudentsOnly',
}

export enum StudentAgeGroupLabel {
    AdultsOnly = 'Adults Only',
    IncludeStudents = 'Include Students',
    None = 'N/A',
    StudentsOnly = 'Only Students',
}

export const STUDENT_OPTIONS = [
    {
        label: i18n('Only Adults'),
        value: StudentAgeGroup.AdultsOnly,
    },
    {
        label: i18n('Include Students'),
        value: StudentAgeGroup.IncludeStudents,
    },
    {
        label: i18n('Only Students'),
        value: StudentAgeGroup.StudentsOnly,
    },
];

export const STUDENT_GRADE_OPTIONS = [
    {
        label: 'Grade 6',
        value: 'Sixth',
    },
    {
        label: 'Grade 7',
        value: 'Seventh',
    },
    {
        label: 'Grade 8',
        value: 'Eighth',
    },
    {
        label: 'Grade 9',
        value: 'Ninth',
    },
    {
        label: 'Grade 10',
        value: 'Tenth',
    },
    {
        label: 'Grade 11',
        value: 'Eleventh',
    },
    {
        label: 'Grade 12',
        value: 'Twelfth',
    },
];
