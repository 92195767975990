import {
    Button,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import {
    makeStyles,
} from '@saddlebackchurch/react-cm-ui/core/styles';
import ClassNames from 'classnames';
import {
    isEmpty,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from './constants.js';
import './modalPrompt.scss';
import {
    color,
    colorStatic,
} from './styles/colors.scss';

export const modalPromptPropTypes = {
    classes: PropTypes.shape({
        modalWrapper: PropTypes.string,
        warningIndicator: PropTypes.string,
        title: PropTypes.string,
        subTitle: PropTypes.string,
        actionBtns: PropTypes.string,
        confirmActionBtn: PropTypes.string,
        buttons: PropTypes.string,
    }),
    cancelBtn: PropTypes.bool,
    cancelBtnColor: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    confirmBtnColor: PropTypes.string,
    confirmBtnLabel: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
    iconType: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    secondaryConfirmBtn: PropTypes.node,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.shape({}),
};

export const modalPromptDefaultProps = {
    classes: {},
    cancelBtn: true,
    cancelBtnColor: 'alternate',
    cancelBtnLabel: '',
    confirmBtnColor: 'success',
    iconColor: 'warning',
    iconType: 'urgent',
    secondaryConfirmBtn: null,
    subTitle: '',
    title: 'Are You Sure?',
    style: {},
};

const useStyles = makeStyles({
    buttons: {
        margin: [[11, 0, 0]],
        width: 222,
        '&:first-child': {
            marginTop: 0,
        },
    },
    modalWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    actionBtns: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    warningIndicator: {
        justifyContent: 'center',
        marginBottom: '22px !important',
        marginRight: '0 !important',
        width: '100% !important',
    },
    title: {
        color,
        display: 'inline-flex',
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        justifyContent: 'center',
        lineHeight: '20px',
        marginBottom: '11px',
    },
    subTitle: {
        color: colorStatic,
        display: 'inline-flex',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '20px',
        marginBottom: '66px',
    },
});

const blockClassName = 'modal_prompt';

function ModalPromptBody(props) {
    const {
        cancelBtn,
        cancelBtnColor,
        cancelBtnLabel,
        confirmBtnColor,
        confirmBtnLabel,
        iconColor,
        iconType,
        secondaryConfirmBtn,
        onCancel,
        onConfirm,
        subTitle,
        title,
        style,
    } = props;

    const classes = useStyles(props);

    return (
        <div className={classes.modalWrapper} style={style}>
            <Icon
                className={classes.warningIndicator}
                color={iconColor}
                size={64}
                type={iconType}
            />

            <div
                className={ClassNames(
                    classes.title,
                    `${blockClassName}--title_container`,
                )}
            >
                {title}
            </div>

            {subTitle && (
                <div
                    className={ClassNames(
                        classes.subTitle,
                        `${blockClassName}--subtitle_container`,
                    )}
                >
                    {subTitle}
                </div>
            )}

            <div className={classes.actionBtns}>
                <Button
                    className={ClassNames(
                        classes.confirmActionBtn,
                        classes.buttons,
                    )}
                    color={confirmBtnColor}
                    id={`${blockClassName}--confirm_action`}
                    onClick={onConfirm}
                    tabIndex={0}
                >
                    {confirmBtnLabel}
                </Button>

                {secondaryConfirmBtn && React.cloneElement(secondaryConfirmBtn, {
                    className: classes.buttons,
                })}

                {cancelBtn && (
                    <Button
                        className={classes.buttons}
                        color={cancelBtnColor}
                        id={`${blockClassName}--cancel_action`}
                        onClick={onCancel}
                        tabIndex={0}
                    >
                        {(isEmpty(cancelBtnLabel) ? i18n('Cancel') : cancelBtnLabel)}
                    </Button>
                )}
            </div>
        </div>
    );
}

ModalPromptBody.propTypes = modalPromptPropTypes;
ModalPromptBody.defaultProps = modalPromptDefaultProps;

export default ModalPromptBody;
