export const DEFAULT_STATE = {
    anonymousPerson: false,
    eventOccurrence: {},
    mode: null,
    person: {},
    worshipService: {},
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'ConnectionFormEntryEditorActions.ON_SET_ANONYMOUS_PERSON':
        {
            return {
                ...state,
                anonymousPerson: actionResult,
            };
        }
        case 'ConnectionFormEntryEditorActions.ON_SET_EVENT_OCCURRENCE':
        {
            return {
                ...state,
                eventOccurrence: actionResult,
            };
        }

        case 'ConnectionFormEntryEditorActions.ON_SET_FORM_ENTRY_EDITOR_MODE':
        {
            return {
                ...state,
                mode: actionResult,
            };
        }

        case 'ConnectionFormEntryEditorActions.ON_SET_SELECTED_ENTRY_PERSON':
        {
            return {
                ...state,
                person: actionResult,
            };
        }

        case 'ConnectionFormEntryEditorActions.ON_SET_WORSHIP_SERVICE':
        {
            return {
                ...state,
                worshipService: actionResult,
            };
        }

        case 'ConnectionFormEntryEditorActions.ON_RESET_ANONYMOUS_PERSON':
        {
            return {
                ...state,
                anonymousPerson: false,
            };
        }

        case 'ConnectionFormEntryEditorActions.ON_RESET_EVENT_OCCURRENCE':
        {
            return {
                ...state,
                eventOccurrence: {},
            };
        }

        case 'ConnectionFormEntryEditorActions.ON_RESET_SELECTED_ENTRY_PERSON':
        {
            return {
                ...state,
                person: {},
            };
        }

        case 'ConnectionFormEntryEditorActions.ON_RESET_FORM_ENTRY_EDITOR_MODE':
        {
            return {
                ...state,
                mode: null,
            };
        }

        case 'ConnectionFormEntryEditorActions.ON_RESET_WORSHIP_SERVICE':
        {
            return {
                ...state,
                worshipService: {},
            };
        }

        default:
            return state;
    }
};
