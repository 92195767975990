import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
    find,
    isEmpty,
    isNil,
    map,
    orderBy,
} from 'lodash';
import { connect } from 'react-redux';
import { Typography } from '@saddlebackchurch/react-cm-ui';
import moment from 'moment-timezone';
import React from 'react';
import Slider from 'react-slick';
import { FORM_TYPE_WORSHIP_SERVICE } from '../../../connectionForms/entries/listPage/constants.js';
import { i18n } from '../../../global/constants.js';
import connectionCardsActions from '../../../connectionCards/global/connectionCardsActions.js';
import connectionFormEntriesByPersonActions from '../../../connectionForms/entries/global/entriesByPerson/entriesByPerson.actions.js';
import FormEntryCard from './formEntryCard.jsx';
import FormEntryDrawer from './formEntryDrawer.jsx';
import FormEntryType from './formEntryType';
import ConnectionCardEntry from '../../../connectionCards/global/models/connectionCardEntry.model';
import ConnectionFormEntry from '../../../connectionForms/models/connectionFormEntry.model';
import {
    DateISOString,
    TimeZoneString,
} from '../../../global/models';

type PropTypes = {
    blockSize: number;
    connectionCardEntries: ConnectionCardEntry[];
    connectionFormEntries: ConnectionFormEntry[];
    isDrawerChild?: boolean;
    isNotesDrawerExpanded: boolean;
    isNotesDrawerOpen: boolean;
    personId: number;
    style: React.CSSProperties;
};

type CombinedFormEntry = {
    churchEntityName: string;
    containsAgreements?: boolean | null;
    formEntry: ConnectionFormEntry | ConnectionCardEntry;
    formEntryDateTime: DateISOString;
    formEntryEventId?: number;
    formEntryId: number;
    formEntryIsForWorshipService: boolean;
    formEntryTimeZone: TimeZoneString;
    formEntryType: FormEntryType;
    formTemplateId: number;
    formTemplateName: string;
    sortKey: number;
};

type StateTypes = {
    combinedFormEntries: CombinedFormEntry[];
    isEntryOnClickPrevented: boolean;
    isFormEntryDrawerOpen: boolean;
    selectedFormEntry: CombinedFormEntry;
};

const mapStateToProps = (state) => {
    const {
        connectionCards: {
            global: {
                entries: {
                    data: connectionCardEntries,
                },
            },
        },
        connectionForms: {
            entries: {
                entriesByPerson: {
                    entries: {
                        data: connectionFormEntries,
                    },
                },
            },
        },
        people: {
            record: {
                index: {
                    isNotesDrawerExpanded,
                    isNotesDrawerOpen,
                },
            },
        },
    } = state;

    return {
        connectionCardEntries,
        connectionFormEntries,
        isNotesDrawerExpanded,
        isNotesDrawerOpen,
    };
};

class FormEntriesCarousel extends React.PureComponent<PropTypes, StateTypes> {
    containerRef: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.state = {
            combinedFormEntries: [],
            isEntryOnClickPrevented: false,
            isFormEntryDrawerOpen: false,
            selectedFormEntry: null,
        };

        this.containerRef = React.createRef();

        this.onFormEntryCardClicked = this.onFormEntryCardClicked.bind(this);

        this.onFormEntryDrawerSelectedFormEntryChanged =
            this.onFormEntryDrawerSelectedFormEntryChanged.bind(this);

        this.onFormEntryDrawerToggle = this.onFormEntryDrawerToggle.bind(this);
        this.onSliderAfterChange = this.onSliderAfterChange.bind(this);
        this.onSliderBeforeChange = this.onSliderBeforeChange.bind(this);
    }

    componentDidMount() {
        const { personId } = this.props;

        if (personId) {
            this.fetchFormData(personId);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            blockSize: prevBlockSize,
            isNotesDrawerExpanded: prevIsNotesDrawerExpanded,
            isNotesDrawerOpen: prevIsNotesDrawerOpen,
            personId: prevPersonId,
        } = prevProps;

        const {
            blockSize,
            isNotesDrawerExpanded,
            isNotesDrawerOpen,
            personId,
        } = this.props;

        if (prevBlockSize !== blockSize ||
            prevIsNotesDrawerOpen !== isNotesDrawerOpen ||
            prevIsNotesDrawerExpanded !== isNotesDrawerExpanded
        ) {
            this.updateSlider();
        }

        if (personId && prevPersonId !== personId) {
            this.fetchFormData(personId);
        }
    }

    componentWillUnmount() {
        connectionCardsActions.resetEntries();
    }

    onFormEntryCardClicked(formEntryType, formEntryId) {
        this.setState((prevState) => {
            const {
                combinedFormEntries,
            } = prevState;

            const selectedFormEntry = find(
                combinedFormEntries,
                (f) => f.formEntryType === formEntryType && f.formEntryId === formEntryId,
            );

            if (!isNil(selectedFormEntry)) {
                return {
                    isFormEntryDrawerOpen: true,
                    selectedFormEntry,
                };
            }

            return {
                ...prevState,
            };
        });
    }

    onFormEntryDrawerSelectedFormEntryChanged(selectedFormEntry) {
        this.setState({ selectedFormEntry });
    }

    onFormEntryDrawerToggle() {
        this.setState(({ isFormEntryDrawerOpen }) => ({
            isFormEntryDrawerOpen: !isFormEntryDrawerOpen,
        }));
    }

    onSliderAfterChange() {
        this.setState({ isEntryOnClickPrevented: false });
        this.updateSlider();
    }

    onSliderBeforeChange() {
        this.setState({ isEntryOnClickPrevented: true });
    }

    collateFormEntries() {
        const {
            connectionCardEntries,
            connectionFormEntries,
        } = this.props;

        const mappedConnectionCards = map(
            connectionCardEntries,
            (cce) => {
                const {
                    churchEntityName,
                    id: formEntryId,
                    occurrenceDateTime,
                    occurrenceTimeZone: formEntryTimeZone,
                    responseCardId: formTemplateId,
                    responseCardName: formTemplateName,
                } = cce;

                const formEntryDateTimeMoment = occurrenceDateTime ?
                    moment.unix(occurrenceDateTime) :
                    null;

                const hasDateTime = !isNil(formEntryDateTimeMoment);

                return {
                    churchEntityName,
                    formEntry: cce,
                    formEntryDateTime: hasDateTime ? formEntryDateTimeMoment.format('YYYY-MM-DD hh:mm:ss') : null,
                    formEntryId,
                    formEntryIsForWorshipService: hasDateTime, // Connection Card 1.0 Entries can _ONLY_ be associated to Worship Services, not other kinds of Events
                    formEntryTimeZone,
                    formEntryType: FormEntryType.ConnectionCard_V1,
                    formTemplateId,
                    formTemplateName,
                    sortKey: cce.occurrenceDateTime,
                };
            },
        );

        const mappedConnectionForms = map(
            connectionFormEntries,
            (cfe) => {
                const {
                    connectionFormTemplateId: formTemplateId,
                    containsAgreements,
                    entryDateTime,
                    entryTimeZone,
                    eventDateTime,
                    eventTimeZone,
                    formTemplateChurchEntityName: churchEntityName,
                    formTemplateName,
                    formTemplateType,
                    id: formEntryId,
                } = cfe;

                const hasEventAssociation = !isNil(eventDateTime);

                const formEntryDateTimeMoment = hasEventAssociation ?
                    moment.tz(eventDateTime, eventTimeZone) :
                    moment.tz(entryDateTime, entryTimeZone);

                const formEntryIsForWorshipService = hasEventAssociation &&
                    formTemplateType === FORM_TYPE_WORSHIP_SERVICE;

                return {
                    churchEntityName,
                    containsAgreements,
                    formEntry: cfe,
                    formEntryDateTime: formEntryDateTimeMoment.format('YYYY-MM-DD HH:mm:ss'),
                    formEntryEventId: cfe.eventId,
                    formEntryId,
                    formEntryIsForWorshipService,
                    formEntryTimeZone: hasEventAssociation ? eventTimeZone : entryTimeZone,
                    formEntryType: FormEntryType.ConnectionForm_V2,
                    formTemplateId,
                    formTemplateName,
                    sortKey: formEntryDateTimeMoment.unix(),
                };
            },
        );

        const combinedFormEntries = orderBy(
            [
                ...mappedConnectionCards,
                ...mappedConnectionForms,
            ],
            ['sortKey'],
            ['desc'],
        );

        this.setState({ combinedFormEntries });
    }

    fetchFormData(personId) {
        const connectionCardsFetchPromise =
            (connectionCardsActions as any).getEntries({ personId });

        const connectionFormsFetchPromise = (connectionFormEntriesByPersonActions as any)
            .getEntriesList({ personId });

        Promise.all([
            connectionCardsFetchPromise,
            connectionFormsFetchPromise,
        ])
            .then(() => {
                this.updateSlider();
                this.collateFormEntries();
            });
    }

    updateSlider() {
        if (this.containerRef.current) {
            const {
                blockSize,
                isNotesDrawerExpanded,
                isNotesDrawerOpen,
            } = this.props;

            const slideEls = this.containerRef.current.querySelectorAll('.slick-slide');
            let notesWidth = 0;

            if (isNotesDrawerOpen) {
                notesWidth = isNotesDrawerExpanded ? 768 : 320;
            }

            map(slideEls, (slide) => {
                slide.querySelector('.connection_cards_entry_card').classList
                    .toggle(
                        'connection_cards_entry_card-transparent',
                        blockSize >= 768 && (
                            slide.getBoundingClientRect().left - 250 < 0 ||
                            slide.getBoundingClientRect().right - 250 > blockSize - notesWidth
                        ),
                    );
            });
        }
    }

    render() {
        const {
            isDrawerChild,
            style,
        } = this.props;

        const {
            combinedFormEntries,
            isEntryOnClickPrevented,
            isFormEntryDrawerOpen,
            selectedFormEntry,
        } = this.state;

        if (isEmpty(combinedFormEntries)) {
            return null;
        }

        const containerClasses = 'person_record--connection_card_entries';
        const connectionEntriesCarousel = map(combinedFormEntries, (entry) => {
            const {
                churchEntityName,
                containsAgreements,
                formEntryEventId,
                formEntryId,
                formEntryIsForWorshipService,
                formEntryDateTime,
                formEntryTimeZone,
                formEntryType,
                formTemplateId,
                formTemplateName,
            } = entry;

            const hasDateTime = !isNil(formEntryDateTime);

            const formEntryDateTimeMoment = hasDateTime ?
                moment.tz(formEntryDateTime, formEntryTimeZone) :
                null;

            const formEntryDate = hasDateTime ? formEntryDateTimeMoment.format('L') : null;

            const formEntryTimeParts = [];

            if (hasDateTime) {
                formEntryTimeParts.push(formEntryDateTimeMoment.format('LT'));
            }

            if (formEntryIsForWorshipService) {
                formEntryTimeParts.push('Service');
            } else if (formEntryEventId) {
                formEntryTimeParts.push('Event');
            }

            const formEntryTime = formEntryTimeParts.join(' ');

            const data = {
                churchEntityName,
                containsAgreements,
                formEntryDate,
                formEntryId,
                formEntryTime,
                formEntryType,
                formTemplateId,
                formTemplateName,
            };

            const entryType = formEntryType === FormEntryType.ConnectionCard_V1 ? 'v1' : 'v2';

            return (
                <FormEntryCard
                    data={data}
                    key={`person-connection-entries-slide-card-entry-${entryType}-${formEntryId}`}
                    disableCardClick={isEntryOnClickPrevented}
                    onCardClicked={this.onFormEntryCardClicked}
                />
            );
        });

        return (
            <React.Fragment>
                <div
                    className={containerClasses}
                    ref={this.containerRef}
                    style={style}
                >
                    <Typography
                        className="person_record--connection_card_entries_header_title"
                        variant="h2"
                    >
                        {i18n('Connection Card Entries')}
                    </Typography>

                    <Slider
                        afterChange={this.onSliderAfterChange}
                        arrows={false}
                        beforeChange={this.onSliderBeforeChange}
                        className="person_record--connection_card_entries_react_slick"
                        infinite={false}
                        responsive={[
                            {
                                breakpoint: 1450,
                                settings: {
                                    slidesToScroll: 1,
                                    slidesToShow: 6,
                                },
                            },
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToScroll: 1,
                                    slidesToShow: 4,
                                },
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToScroll: 1,
                                    slidesToShow: 2,
                                },
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToScroll: 1,
                                    slidesToShow: 1,
                                },
                            },
                        ]}
                        slideSpacing={55}
                        slidesToScroll={1}
                        slidesToShow={isDrawerChild ? 2 : 7}
                        speed={500}
                        swipeToSlide
                        variableWidth
                    >
                        {connectionEntriesCarousel}
                    </Slider>
                </div>

                <FormEntryDrawer
                    entriesCount={combinedFormEntries.length}
                    entriesData={combinedFormEntries}
                    isOpen={isFormEntryDrawerOpen}
                    onClose={this.onFormEntryDrawerToggle}
                    onSelectedEntryChanged={this.onFormEntryDrawerSelectedFormEntryChanged}
                    selectedEntry={selectedFormEntry}
                />
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(FormEntriesCarousel) as any;
