const DEFAULT_STATE = {
    filename: '',
    url: '',
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ImageUploaderActions.ON_UPDATE_IMAGE_UPLOADER': {
            const {
                value: {
                    filename,
                    url,
                },
            } = action;
            return {
                filename,
                url,
            };
        }

        case 'ImageUploaderActions.ON_RESET_IMAGE_UPLOADER': {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
