import _, {
    isEmpty,
    isNil,
} from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { createRef, useEffect } from 'react';
import moment from 'moment-timezone';
import domUtils from '../../global/domUtils.js';
import { translationFactory } from '../../global/i18nUtils.js';
import { personDefaultProps, personPropTypes } from './global/personPropTypes.js';
import { setElements } from './global/personRecord.actions.js';
import PersonEmergencyContactUtils from '../global/personEmergencyContactUtils.js';
import UserAccessStore from '../../global/userAccessStore.js';
import { USER_PERMISSIONS } from '../../global/userPermissionConstants.js';

const propTypes = {
    limitedPersonalInfo: PropTypes.bool.isRequired,
    person: PropTypes.shape({}),
    personData: personPropTypes,
    personId: PropTypes.number,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    person: null,
    personData: personDefaultProps,
    personId: null,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: { userAccess },
        },
        people: {
            record: {
                index: {
                    detailsWindow,
                },
                person: {
                    data: personData,
                },
            },
        },
    } = state;

    return {
        limitedPersonalInfo: detailsWindow?.limitedPersonalInfo ?? false,
        personData,
        userAccess,
    };
};

const i18n = translationFactory('Person.Record.TopLevel.PersonalInformation');

function DetailsWindowPersonInfo(props) {
    const detailsWindowPersonInfoRef = createRef();
    const {
        limitedPersonalInfo,
        person,
        personData,
        personId,
        userAccess,
    } = props;

    const hasAccessDateOfBirth = userAccess.hasPermission(
        USER_PERMISSIONS.accessDateOfBirth,
    );

    useEffect(() => {
        const { current: dwPersonInfoEl } = detailsWindowPersonInfoRef;

        if (dwPersonInfoEl) {
            setElements({
                dwPersonInfo: dwPersonInfoEl,
                dwPersonInfoRowLast: dwPersonInfoEl.querySelector('.person_record--details_window_person_info_row_last'),
            });
        }
    }, [detailsWindowPersonInfoRef]);

    let personInfo = {
        contactPreferences: {},
    };

    if (!isNil(personId) && !isEmpty(personData)) {
        personInfo = personData;
    } else if (isNil(personId) && !isEmpty(person)) {
        personInfo = person;
    }

    const {
        addresses,
        birthDate,
        churchEntityId,
        churchEntityKnown,
        churchEntityName,
        contactPreferences,
        emails,
        emergencyContacts,
        firstName,
        gender,
        gradeLevel,
        isAdult,
        isChild,
        isStudent,
        lastName,
        maritalStatus,
        nickName,
        phones,
        suffix,
    } = personInfo;

    const {
        doNotContact: isDoNotContact,
        doNotEmail: isDoNotEmail,
        doNotMail: isDoNotMail,
        doNotPhone: isDoNotPhone,
        doNotText: isDoNotText,
        preferredMethod,
    } = contactPreferences ?? {};

    const bemBlockClass = 'person_record';
    const bemElementClass = 'details_window';
    const containerClassName = `${bemBlockClass}--${bemElementClass}_person_info`;
    const fullNameText = `${firstName} ${(nickName && `(${nickName})`) || ''} ${lastName} ${suffix || ''}`;
    const primaryEmail = _.find(emails, 'isPrimary');
    const primaryPhone = _.find(phones, 'isPrimary');
    const primaryAddress = _.find(addresses, 'isPrimary');
    const primaryEmergencyContact = _.find(emergencyContacts, 'isPrimary');
    const emailText = primaryEmail && (
        <span
            onClick={(event) => domUtils.onMailToLinkClick(event, primaryEmail.email)}
            role="presentation"
            style={{
                cursor: 'pointer',
            }}
        >
            {primaryEmail.email}
        </span>
    );
    const phoneText = primaryPhone && (
        <span
            onClick={(event) => {
                domUtils.onTelLinkClick(event, primaryPhone.displayPhoneNumber);
            }}
            role="presentation"
            style={{
                cursor: 'pointer',
            }}
        >
            {primaryPhone.displayPhoneNumber}
        </span>
    );
    const addressText = primaryAddress && (
        <p
            style={{
                margin: 0,
            }}
        >
            <span>{primaryAddress.address1}</span>
            {primaryAddress.address2 && (
                <span>{`, ${primaryAddress.address2}`}</span>
            )}
            <br />
            <span>{`${primaryAddress.city}, ${primaryAddress.countryAlpha2 === 'US' ? primaryAddress.regionCode : primaryAddress.region || ''} ${primaryAddress.postalCode || ''}`}</span>
            <span>{`, ${primaryAddress.country}`}</span>
        </p>
    );
    let churchEntityNameText;
    let contactMethodText;
    let genderText;
    let preferredContactInfoText;

    if (_.isNil(churchEntityId)) {
        churchEntityNameText = !churchEntityKnown ? i18n('Unknown') : i18n('DoesNotAttend');
    } else {
        churchEntityNameText = churchEntityName;
    }

    switch (gender) {
        case 'F':
            genderText = 'Female';

            break;
        case 'M':
            genderText = 'Male';

            break;

        default:
            genderText = '';
    }

    if (isDoNotContact) {
        contactMethodText = i18n('DoNotContactThisIndividual');
    } else {
        switch (preferredMethod) {
            case 'email':
                contactMethodText = i18n('PrefersEmail');

                if (emailText) {
                    preferredContactInfoText = emailText;
                }

                break;
            case 'phone':
                contactMethodText = i18n('PrefersPhone');

                if (phoneText) {
                    preferredContactInfoText = phoneText;
                }

                break;
            case 'text-message':
                contactMethodText = i18n('PrefersText');

                if (primaryPhone) {
                    preferredContactInfoText = primaryPhone.displayPhoneNumber;
                }

                break;
            case 'mail':
                contactMethodText = i18n('prefers letter');

                if (primaryAddress) {
                    preferredContactInfoText = addressText;
                }

                break;
            default:
                if (phoneText) {
                    preferredContactInfoText = phoneText;
                } else if (emailText) {
                    preferredContactInfoText = emailText;
                }
        }

        if (isDoNotEmail || isDoNotMail || isDoNotPhone || isDoNotText) {
            if (contactMethodText) {
                contactMethodText += `, ${i18n('DNCVia')} `;
            } else {
                contactMethodText = `${i18n('DNCVia')} `;
            }

            if (isDoNotEmail) {
                contactMethodText += `${i18n('Email')}, `;
            }

            if (isDoNotMail) {
                contactMethodText += `${i18n('Mail')}, `;
            }

            if (isDoNotPhone) {
                contactMethodText += `${i18n('Phone')}, `;
            }

            if (isDoNotText) {
                contactMethodText += `${i18n('Text')}`;
            }

            contactMethodText = _.trimEnd(contactMethodText, ', ');
        }
    }

    let age;
    let birthdayDate;
    let gradeLevelText;
    if (_.isEmpty(birthDate)) {
        birthdayDate = moment.unix(birthDate).utc().format('MM/DD/YY');
        age = moment().diff(birthdayDate, 'years');
    }

    switch (gradeLevel) {
        case 'PreK':
            gradeLevelText = 'PK';

            break;

        case 'Kindergarten':
            gradeLevelText = 'K';

            break;

        case 'First':
            gradeLevelText = '1';

            break;

        case 'Second':
            gradeLevelText = '2';

            break;

        case 'Third':
            gradeLevelText = '3';

            break;

        case 'Fourth':
            gradeLevelText = '4';

            break;

        case 'Fifth':
            gradeLevelText = '5';

            break;

        case 'Sixth':
            gradeLevelText = '6';

            break;

        case 'Seventh':
            gradeLevelText = '7';

            break;

        case 'Eighth':
            gradeLevelText = '8';

            break;

        case 'Ninth':
            gradeLevelText = '9';

            break;

        case 'Tenth':
            gradeLevelText = '10';

            break;

        case 'Eleventh':
            gradeLevelText = '11';

            break;

        case 'Twelfth':
            gradeLevelText = '12';

            break;

        default:
            gradeLevelText = gradeLevel;
    }

    let metaInfoText = '';

    if (genderText) {
        metaInfoText += genderText;
    }

    if (!limitedPersonalInfo && isAdult && maritalStatus) {
        metaInfoText += ` | ${maritalStatus}`;
    }

    if (hasAccessDateOfBirth && (isChild || isStudent) && birthDate) {
        metaInfoText += ` | ${age}yr ${birthdayDate}`;
    }

    if ((isChild || isStudent) && gradeLevel) {
        metaInfoText += ` | gr ${gradeLevelText}`;
    }

    if (churchEntityNameText) {
        metaInfoText += ` | ${churchEntityNameText}`;
    }

    metaInfoText = _.trimStart(metaInfoText, '| ');

    let emergencyContactMethodText;
    let emergencyContactInfoText;
    const emergencyContactPreferredInfo = PersonEmergencyContactUtils
        .getPersonPreferredContactMethod(
            primaryEmergencyContact,
        );

    if (primaryEmergencyContact && !_.isEmpty(primaryEmergencyContact.emergencyContactPreference)) {
        if (primaryEmergencyContact.emergencyContactPreference.preferredMethod === 'none') {
            emergencyContactMethodText = `(${i18n('NoPreference')})`;
        } else {
            emergencyContactMethodText =
                `${primaryEmergencyContact.emergencyContactRelationshipName}'s ${emergencyContactPreferredInfo.method}`;
        }

        emergencyContactInfoText = emergencyContactPreferredInfo.text;
    }

    const showEmergencyContactForStudent = isStudent && preferredMethod === 'none' && _.isEmpty(contactMethodText);

    return (
        <div
            className={containerClassName}
            ref={detailsWindowPersonInfoRef}
        >
            <div className={`${containerClassName}_row_first`}>
                <div className={`${containerClassName}_full_name`}>
                    {fullNameText}
                </div>

                {(gender || maritalStatus || churchEntityNameText) && (
                    <div
                        className={`${containerClassName}_gender_church_marital`}
                    >
                        {metaInfoText}
                    </div>
                )}
            </div>

            {!isChild && (contactMethodText || preferredMethod !== 'none') && (
            <div className={`${containerClassName}_row_last`}>
                {contactMethodText && (
                <div className={`${containerClassName}_preferred_contact_method`}>
                    {`(${contactMethodText})`}
                </div>
                )}

                {preferredMethod !== 'none' && preferredContactInfoText && (
                <div className={`${containerClassName}_preferred_method`}>
                    {preferredContactInfoText}
                </div>
                )}
            </div>
            )}

            {(
                isChild || showEmergencyContactForStudent
            ) && (
                emergencyContactMethodText || emergencyContactInfoText
            ) && (
            <div className={`${containerClassName}_row_last`}>
                {emergencyContactMethodText && (
                <div className={`${containerClassName}_emergency_contact_method`}>
                    {`${emergencyContactMethodText}`}
                </div>
                )}

                {emergencyContactInfoText && (
                <div className={`${containerClassName}_emergency_method`}>
                    {emergencyContactInfoText}
                </div>
                )}
            </div>
            )}
        </div>
    );
}

DetailsWindowPersonInfo.propTypes = propTypes;
DetailsWindowPersonInfo.defaultProps = defaultProps;

export default connect(mapStateToProps)(DetailsWindowPersonInfo);
