import {
    map,
    noop,
} from 'lodash';
import {
    NAVIGATION_RESET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER,
    NAVIGATION_SET_BREADCRUMBS,
    NAVIGATION_SET_HEADER_BREADCRUMBS,
    NAVIGATION_DISABLE,
    NAVIGATION_SET_IS_COLLAPSED,
    NAVIGATION_SET_ITEMS,
    NAVIGATION_SET_MY_MINISTRY,
    NAVIGATION_SET_SECTIONAL_TABS,
    NAVIGATION_SET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER,
    NAVIGATION_TOGGLE_COLLAPSE,
} from './navigation/navigationTypes.js';

export const DEFAULT_STATE = {
    isNavigationCollapsed: true,
    items: [],
    myMinistryId: null,
    myMinistryLocationId: null,
    myMinistryName: '',
    isMenuButtonDisabled: false,
    navigationBreadcrumbs: [],
    navigationHeaderBreadcrumbs: [],
    previousMdCollapsedState: false,
    sectionalTabsItems: [],
    sectionalTabsOnBeforeChange: noop,
    disableNavigation: false,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case NAVIGATION_SET_ITEMS:
            return {
                ...state,
                items: action.items,
            };

        case NAVIGATION_SET_MY_MINISTRY: {
            const {
                locationId,
                ministryId,
                myMinistrySectionItems,
            } = action;

            let myMinistrySubItems = [];

            const {
                sectionItemMembers,
                sectionItemInterested,
                sectionItemServingOppsActive,
                sectionItemServingOppsClosed,
                sectionItemSettingsMinistry,
                sectionItemSettingsMembership,
            } = myMinistrySectionItems;

            const shouldUpdateMyMinistry = ministryId && locationId;

            if (shouldUpdateMyMinistry) {
                myMinistrySubItems = [{
                    id: 1,
                    label: 'Roster',
                    sectionItems: [
                        sectionItemMembers,
                        sectionItemInterested,
                    ],
                }, {
                    id: 2,
                    label: 'Serving Opportunities',
                    sectionItems: [
                        sectionItemServingOppsActive,
                        sectionItemServingOppsClosed,
                    ],
                }, {
                    id: 3,
                    label: 'Settings & Preferences',
                    sectionItems: [
                        sectionItemSettingsMinistry,
                        sectionItemSettingsMembership,
                    ],
                }];
            }

            return {
                ...state,
                items: map(state.items, (item) => {
                    if (item.label === 'My Ministry') {
                        return {
                            id: item.id,
                            isBeta: item.isBeta,
                            label: item.label,
                            segment: item.segment,
                            subItems: myMinistrySubItems,
                            to: item.to,
                        };
                    }

                    return item;
                }),
                myMinistryId: ministryId,
                myMinistryLocationId: locationId,
            };
        }

        case NAVIGATION_TOGGLE_COLLAPSE:
            return {
                ...state,
                isNavigationCollapsed: !state.isNavigationCollapsed,
                previousMdCollapsedState: !state.isNavigationCollapsed,
            };

        case NAVIGATION_RESET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER:
            return {
                ...state,
                sectionalTabsOnBeforeChange: DEFAULT_STATE.sectionalTabsOnBeforeChange,
            };

        case NAVIGATION_SET_IS_COLLAPSED:
            return {
                ...state,
                isNavigationCollapsed: action.isCollapsed,
            };

        case NAVIGATION_SET_SECTIONAL_TABS: {
            return {
                ...state,
                sectionalTabsItems: action.sectionalTabsItems,
                sectionalTabsOnBeforeChange: action.sectionalTabsOnBeforeChange ??
                    state.sectionalTabsOnBeforeChange,
            };
        }

        case NAVIGATION_SET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER: {
            return {
                ...state,
                sectionalTabsOnBeforeChange: action.sectionalTabsOnBeforeChange ??
                    state.sectionalTabsOnBeforeChange,
            };
        }

        case NAVIGATION_SET_HEADER_BREADCRUMBS: {
            return {
                ...state,
                navigationHeaderBreadcrumbs: action.navigationHeaderBreadcrumbs,
            };
        }

        case NAVIGATION_SET_BREADCRUMBS: {
            return {
                ...state,
                navigationBreadcrumbs: action.navigationBreadcrumbs,
            };
        }

        case NAVIGATION_DISABLE: {
            return {
                ...state,
                disableNavigation: action.disableNavigation,
            };
        }

        default:
            return state;
    }
};
