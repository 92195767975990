import PropTypes from 'prop-types';
import FormEntryType from './formEntryType';

/**
 * Represents a unified shape for Form Entries of both types
 * (Connection Cards 1.0 and Conneciton Forms 2.0)
 */
const FormEntryPropType = PropTypes.shape({
    /**
     * Campus Name (of Form Template)
     */
    churchEntityName: PropTypes.string,

    /**
     * Original Form Entry object (shape varies between Connection Cards 1.0 Entries and Connection Forms 2.0 Entries)
     */
    formEntry: PropTypes.shape({}),

    /**
     * Date/Time for the Form Entry (usually of the Worship Service/Event to which it is associated)
     * in YYYY-MM-DD hh:mm:ss format; might be null if no Event association.
     */
    formEntryDateTime: PropTypes.string,

    /**
     * Form Entry ID.  Integer.
     */
    formEntryId: PropTypes.number,

    /**
     * Whether or not the Event associated to the Form Entry is a Worship Service (or another kind of Event)
     */
    formEntryIsForWorshipService: PropTypes.bool,

    /**
     * Time Zone ID for Form Entry (of the Worship Service/Event to which it is associated); might be null if no Event association.
     */
    formEntryTimeZone: PropTypes.string,

    /**
     * Which kind of Form Entry
     */
    formEntryType: PropTypes.oneOf(Object.values(FormEntryType)),

    /**
     * ID of Connection Card / Connection Form Template.  Integer.
     */
    formTemplateId: PropTypes.number,

    /**
     * Name of Connection Card / Connection Form Template
     */
    formTemplateName: PropTypes.string,
});

export default FormEntryPropType;
