import { combineReducers } from 'redux';

import attendeeNotesCache from './attendeeNotesCache.js';
import attendeeSearch from './attendeeSearch.js';
import attendeeSearchFilter from './attendeeSearchFilter.js';
import checkInOccurrence from './checkInOccurrence.js';
import coreMilestonesCache from './coreMilestonesCache.js';
import eventRoom from './eventRoom.js';
import eventRoomBySO from './eventRoomBySO.js';
import ministrySearch from './ministrySearch.js';
import notificationTemplate from './notificationTemplate.js';
import searchPersonDrawer from '../searchPersonDrawer/index.reducer.js';
import sendNotificationResults from './sendNotification.reducer.js';
import servingOppByMinistry from './servingOppByMinistry.js';
import personCache from './personCache.js';
import personNotesCache from './personNotesCache.js';
import roomAttendees from './roomAttendees.js';

const reducers = combineReducers({
    attendeeNotesCache,
    attendeeSearch,
    attendeeSearchFilter,
    checkInOccurrence,
    coreMilestonesCache,
    eventRoom,
    eventRoomBySO,
    ministrySearch,
    notificationTemplate,
    personCache,
    personNotesCache,
    roomAttendees,
    searchPersonDrawer,
    sendNotificationResults,
    servingOppByMinistry,
});

export default reducers;
