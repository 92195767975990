import {
    Checkbox,
    Drawer,
    Select,
    Grid,
    Page,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import {
    applyFilters,
    clearFilters,
    setAndApplySortOrder,
    setDirtySortOrder,
    setAndApplyDeceasedFilter,
    setDirtyDeceasedFilter,
} from './peopleSearchFilters.actions.js';
import {
    BEM_BLOCK_NAME,
    sortFilterOptions as sortOptions,
} from './searchConstants';
import { i18n } from '../../global/constants.js';

const filterPropTypes = PropTypes.shape({
    deceased: PropTypes.bool,
    sort: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
});

const propTypes = {
    appliedFilters: filterPropTypes.isRequired,
    applyFilters: PropTypes.func,
    areFiltersDirty: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
        deceasedCheckFilter: PropTypes.string,
        deceasedCheckFilterMobile: PropTypes.string,
        filterRails: PropTypes.string,
        filtersDrawer: PropTypes.string,
        otherFilterHeader: PropTypes.string,
        sortFilter: PropTypes.string,
        sortFilterHeader: PropTypes.string,
    }),
    clearFilters: PropTypes.func,
    dirtyFilters: filterPropTypes.isRequired,
    isDrawerChild: PropTypes.bool.isRequired,
    isFiltering: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setAndApplySortOrder: PropTypes.func,
    setDirtySortOrder: PropTypes.func,
    setAndApplyDeceasedFilter: PropTypes.func,
    setDirtyDeceasedFilter: PropTypes.func,
};

const defaultProps = {
    applyFilters: undefined,
    classes: {},
    clearFilters: undefined,
    setAndApplySortOrder: undefined,
    setDirtySortOrder: undefined,
    setAndApplyDeceasedFilter: undefined,
    setDirtyDeceasedFilter: undefined,
};

const mapStateToProps = (state) => {
    const {
        people: {
            searchV2: {
                searchFilters: {
                    appliedFilters,
                    areFiltersDirty,
                    dirtyFilters,
                    isFiltering,
                },
            },
        },
    } = state;

    return {
        appliedFilters,
        areFiltersDirty,
        dirtyFilters,
        isFiltering,
    };
};

const mapDispatchToProps = {
    applyFilters,
    clearFilters,
    setAndApplySortOrder,
    setDirtySortOrder,
    setAndApplyDeceasedFilter,
    setDirtyDeceasedFilter,
};

const styles = ({ palette, typography }) => ({
    deceasedCheckFilter: {
        '& .checkbox-label-text': {
            color: palette.text.secondary,
        },
    },
    deceasedCheckFilterMobile: {
        '& .checkbox-label-text': {
            fontSize: '16px !important',
        },
    },
    filterRails: {
        minHeight: 'calc(100vh - 140px)',
        '& .page--filters_rail_inner_container': {
            padding: '33px 22px !important',
        },
    },
    filtersDrawer: {
        '& .drawer--content': {
            padding: '22px !important',
        },
        '& .page_filters_drawer--row': {
            marginBottom: '33px !important',
        },
    },
    otherFilterHeader: {
        fontWeight: [typography.fontWeightBold, '!important'],
        lineHeight: '16px',
        marginBottom: '22px',
    },
    sortFilter: {
        marginBottom: '11px',
        '& .Select-value-label': {
            fontWeight: typography.fontWeightMedium,
        },
    },
    sortFilterHeader: {
        fontWeight: [typography.fontWeightBold, '!important'],
        lineHeight: '16px',
        marginBottom: '11px',
    },
});

const BLOCK_CLASS_NAME = `${BEM_BLOCK_NAME}--filters`;

export class PeopleSearchFilters extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onApplyFilters = this.onApplyFilters.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.onDeceasedCheckChangeForDesktop = this.onDeceasedCheckChangeForDesktop.bind(this);
        this.onDeceasedCheckChangeForMobile = this.onDeceasedCheckChangeForMobile.bind(this);
        this.onSortByForDesktopChange = this.onSortByForDesktopChange.bind(this);
        this.onSortByForMobileChange = this.onSortByForMobileChange.bind(this);
    }

    onApplyFilters() {
        const {
            applyFilters: applyFiltersAction,
            onClose,
        } = this.props;

        applyFiltersAction();
        onClose();
    }

    onClearFilters() {
        const {
            clearFilters: clearFiltersAction,
        } = this.props;

        clearFiltersAction();
    }

    onSortByForDesktopChange(selectedSortOption) {
        const {
            setAndApplySortOrder: setAndApplySortOrderAction,
        } = this.props;

        setAndApplySortOrderAction(selectedSortOption);
    }

    onSortByForMobileChange(selectedSortOption) {
        const {
            setDirtySortOrder: setDirtySortOrderAction,
        } = this.props;

        setDirtySortOrderAction(selectedSortOption);
    }

    onDeceasedCheckChangeForDesktop(value, isChecked) {
        const {
            setAndApplyDeceasedFilter: setAndApplyDeceasedFilterAction,
        } = this.props;

        setAndApplyDeceasedFilterAction(isChecked);
    }

    onDeceasedCheckChangeForMobile(value, isChecked) {
        const {
            setDirtyDeceasedFilter: setDirtyDeceasedFilterAction,
        } = this.props;

        setDirtyDeceasedFilterAction(isChecked);
    }

    getMobileFiltersDrawerRows() {
        const {
            classes,
            dirtyFilters: {
                deceased: dirtyDeceased,
                sort: dirtySort,
            },
        } = this.props;

        return [
            {
                header: i18n('Sort By'),
                items: [
                    {
                        dropdown: {
                            onChange: this.onSortByForMobileChange,
                            options: sortOptions,
                            value: dirtySort,
                        },
                    },
                ],
            }, {
                header: i18n('Other'),
                items: [
                    {
                        checkbox: {
                            className: classes.deceasedCheckFilterMobile,
                            checked: dirtyDeceased,
                            id: `${BLOCK_CLASS_NAME}--deceased_checkbox_mobile`,
                            label: i18n('Include Deceased'),
                            onChange: this.onDeceasedCheckChangeForMobile,
                        },
                    },
                ],
            },
        ];
    }

    renderFiltersRailContent() {
        const {
            appliedFilters: {
                deceased: selectedDeceasedValue,
                sort: selectedSortOption,
            },
            classes,
        } = this.props;

        return (
            <Grid columns={1} spacing={2}>
                <Grid.Column>
                    <Typography
                        className={classes.sortFilterHeader}
                        variant="h4"
                    >
                        {i18n('Sort By')}
                    </Typography>
                    <Select
                        className={classes.sortFilter}
                        fluid
                        id={`${BLOCK_CLASS_NAME}--_rail_sort_dropdown`}
                        onChange={this.onSortByForDesktopChange}
                        options={sortOptions}
                        underline
                        value={selectedSortOption}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Typography
                        className={classes.otherFilterHeader}
                        variant="h4"
                    >
                        {i18n('Other')}
                    </Typography>
                    <Checkbox
                        className={classes.deceasedCheckFilter}
                        checked={selectedDeceasedValue}
                        fluid
                        id={`${BLOCK_CLASS_NAME}--deceased_checkbox`}
                        label={i18n('Include Deceased')}
                        labelClassName={`${BLOCK_CLASS_NAME}--deceased_label`}
                        onChange={this.onDeceasedCheckChangeForDesktop}
                    />
                </Grid.Column>
            </Grid>
        );
    }

    render() {
        const {
            areFiltersDirty,
            classes,
            isDrawerChild,
            isFiltering,
            isOpen,
            onClose,
        } = this.props;

        const mobileFilterDrawerRows = this.getMobileFiltersDrawerRows();

        let FiltersRail = Drawer.FiltersRail; // eslint-disable-line prefer-destructuring
        let FiltersDrawer = Drawer.FiltersDrawer; // eslint-disable-line prefer-destructuring

        if (!isDrawerChild) {
            FiltersRail = Page.FiltersRail;
            FiltersDrawer = Page.FiltersDrawer;
        }

        return (
            <React.Fragment>
                <FiltersDrawer
                    className={classes.filtersDrawer}
                    isDirty={areFiltersDirty}
                    isFiltering={isFiltering}
                    isOpen={isOpen}
                    onApply={this.onApplyFilters}
                    onClear={this.onClearFilters}
                    onClose={onClose}
                    rows={mobileFilterDrawerRows}
                />

                <FiltersRail
                    className={classes.filterRails}
                    id={`${BLOCK_CLASS_NAME}_rails`}
                    isOpen={isOpen}
                >
                    {this.renderFiltersRailContent()}
                </FiltersRail>
            </React.Fragment>
        );
    }
}

PeopleSearchFilters.propTypes = propTypes;
PeopleSearchFilters.defaultProps = defaultProps;

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(PeopleSearchFilters),
);
