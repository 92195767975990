enum DigitalProgramInfoField {
    buttons = 'buttons',
    campusServices = 'campusServices',
    selectedButtonId = 'selectedButtonId',
    selectedButtonType = 'selectedButtonType',
    selectedCampus = 'selectedCampus',
    selectedServices = 'selectedServices',
    showUnsavedModal = 'showUnsavedModal',
    step = 'step',
}

export default DigitalProgramInfoField;
