const DEFAULT_STATE = {
};

export default (state = DEFAULT_STATE, action) => {
    const actionType = 'RecordActions';

    const {
        type,
    } = action;

    switch (type) {
        case `${actionType}.ON_ADD_CHILD_FOR_CHECK_IN`: {
            const personId = action?.person?.personId || action?.person?.id;

            if (personId) {
                const newState = {
                    ...state,
                    [personId]: action.person,
                };

                return newState;
            }

            return state;
        }

        case `${actionType}.ON_REMOVE_CHILD_FOR_CHECK_IN`: {
            const personId = action?.person?.personId || action?.person?.id;
            const newState = { ...state };
            delete newState[personId];
            return newState;
        }

        case `${actionType}.ON_RESET_CHECK_IN`: {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
