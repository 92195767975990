enum NoteReminderType {
    ResetReminderForm = 'PersonNotesActions.RESET_REMINDER_FORM',
    SetCreateReminderButtonHasBeenClicked = 'PersonNotesActions.SET_HAS_CREATE_REMINDER_BUTTON_BEEN_CLICKED',
    SetMobileNextWingType = 'PersonNotesActions.SET_MOBILE_NEXT_WING_TYPE',
    SetReminderElementOpenSource = 'PersonNotesActions.SET_REMINDER_ELEMENT_OPEN_FROM',
    SetReminderFormData = 'PersonNotesActions.SET_REMINDER_FORM_DATA',
    SetReminderFormModalOpen = 'PersonNotesActions.SET_REMINDER_FORM_MODAL_OPEN',
    SetSelectedNote = 'PersonNotesActions.SET_SELECTED_NOTE',
    SetSelectedReminder = 'PersonNotesActions.SET_SELECTED_REMINDER',
}

export default NoteReminderType;
