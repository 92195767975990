import './helpLayout.scss';

import {
    Dropdown,
    Image,
    Page,
    SubNavigation,
    TitleBar,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
    cmAuthUtils,
} from '../global/utils/utils.js';
import {
    appReduxStore,
} from '../global/configureReduxStore.js';
import { translationFactory } from '../global/i18nUtils.js';
import LoginConstants from '../js/constants/Login/LoginConstants.js';

const i18n = translationFactory('App');

class HelpLayout extends React.Component {
    constructor() {
        super();

        this.onHelpCenterClick = this.onHelpCenterClick.bind(this);
        this.onUserMenuChange = this.onUserMenuChange.bind(this);
    }

    onHelpCenterClick() {
        this.context.router.push('/help');
    }

    onUserMenuChange(selectedOption) {
        switch (selectedOption.id) {
            case 'logOut':
                cmAuthUtils.logout(() => {
                    appReduxStore.dispatch({ type: LoginConstants.LOGOUT });
                });
                break;
        }
    }

    render() {
        const { children, userFullName } = this.props;
        const containerClasses = ClassNames('layout');
        const { pathname } = window.location;
        const pathnameSegments = pathname.split('/');
        const pathnameHelpIndex = pathnameSegments[2] ? 1 : 0;

        return (
            <Page className={containerClasses}>
                <TitleBar
                    title="HC Help"
                >
                    <Dropdown
                        collapseMenuOnChange
                        onChange={this.onUserMenuChange}
                        style={{ height: '44px' }}
                        text={(
                            <div>
                                <span>
                                    <Image style={{ marginRight: '11px' }} type="user" />
                                </span>

                                <span
                                    style={{
                                        color: '#1c2530',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                    }}
                                >
                                    {userFullName}
                                </span>
                            </div>
                        )}
                    >
                        <Dropdown.Item id="logOut" label={i18n('LogOut')} />
                    </Dropdown>
                </TitleBar>

                <SubNavigation
                    selected={pathnameHelpIndex}
                    style={{ margin: 0 }}
                >
                    <SubNavigation.Item
                        label="Help Center"
                        onClick={this.onHelpCenterClick}
                    />
                </SubNavigation>

                <Page.Container>
                    {children}
                </Page.Container>
            </Page>
        );
    }
}

HelpLayout.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default HelpLayout;
