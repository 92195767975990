// TODO/FIXME: Caching support for `getV2Lite()` request results
export const DEFAULT_STATE = {};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PersonCacheActions.ON_RESET':
            return DEFAULT_STATE;

        case 'PersonCacheActions.ON_GET':
            return {
                ...state,
                [action.result.id]: action.result,
            };

        default:
            return state;
    }
};
