import request from 'axios';
import ApiUtils from '../global/apiUtils.js';
import { appReduxStore } from '../global/configureReduxStore.js';

class NotificationCenterDetailActions extends ApiUtils {
    resetNotificationDetail() {
        appReduxStore.dispatch({ type: 'NotificationCenterDetailActions.ON_RESET_NOTIFICATIONDETAIL' });
    }
}

const actions = new NotificationCenterDetailActions('/api/notification-center/');

actions.buildReduxActionClass(
    {
        name: 'getNotificationDetail',
        route: '{id}',
        rq: request.get,
    },
);

export default actions;
