import './contactForm.scss';

import _ from 'lodash';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import validator from 'validator';
import {
    colorAlert,
} from '../../../global/styles/colors.scss';
import bootstrapActions from '../../../app/bootstrapActions.js';
import { translationFactory } from '../../../global/i18nUtils.js';
import { personContactFormPropTypes, personContactFormDefaultProps } from './personFormPropTypes.js';
import Form from '../../../global/form';
import AddPersonRecordActions from './addPersonRecordActions.js';

const propTypes = {
    className: PropTypes.string,
    countries: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    contactFormData: personContactFormPropTypes,
    isEmergencyContactInfoSet: PropTypes.bool,
    isRegionRequired: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onSetRegionRequired: PropTypes.func.isRequired,
    required: PropTypes.bool,
    resetOnUnmount: PropTypes.bool,
    style: PropTypes.shape({}),
};

const defaultProps = {
    className: undefined,
    countries: [],
    contactFormData: personContactFormDefaultProps,
    onBlur: undefined,
    onFocus: undefined,
    required: false,
    isEmergencyContactInfoSet: false,
    isRegionRequired: true,
    resetOnUnmount: true,
    style: {},
};

const i18n = translationFactory();
const BEM_BLOCK_NAME = 'person_add_contact_form';
const BEM_BLOCK_ELEMENT_NAME = 'person_add_contact_form--field';
const HOME_PHONE_TYPE_ID = 1;
const WORK_PHONE_TYPE_ID = 2;
const CELL_PHONE_TYPE_ID = 3;

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            enumerations: {
                enumerations: {
                    countries,
                },
            },
        },
        people: {
            record: {
                personForm: {
                    contactFormData,
                },
            },
        },
    } = state;

    return {
        contactFormData,
        countries,
    };
};

class ContactForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            defaultCountrySet: true,
        };

        this.onChangeCountry = this.onChangeCountry.bind(this);
    }

    componentDidMount() {
        const {
            countries,
        } = this.props;
        const defaultCountry = _.find(countries, (item) => item.code === 'US');

        if (!_.isEmpty(defaultCountry)) {
            AddPersonRecordActions.updateCountry({
                code: defaultCountry.code,
                value: defaultCountry.shortName,
                label: defaultCountry.longName,
            });
        }
    }

    componentWillUnmount() {
        const {
            resetOnUnmount,
        } = this.props;

        if (resetOnUnmount) {
            AddPersonRecordActions.resetContactForm();
        }
    }

    static onChangeEmail(value) {
        AddPersonRecordActions.updateEmail(value);
    }

    static onChangeAddressOne(value) {
        AddPersonRecordActions.updateAddressOne(value);
    }

    static onChangeAddressTwo(value) {
        AddPersonRecordActions.updateAddressTwo(value);
    }

    static onChangeCity(value) {
        AddPersonRecordActions.updateCity(value);
    }

    onChangeCountry(value) {
        const { onSetRegionRequired } = this.props;

        AddPersonRecordActions.updateCountry(value);
        this.setState({ defaultCountrySet: false });
        AddPersonRecordActions.resetRegion();
        onSetRegionRequired(true);
        bootstrapActions.getRegionsByCountryCode({
            countryCode: value.code,
        }).then((regions) => {
            onSetRegionRequired(!_.isEmpty(regions));
        });
    }

    static onChangePhone(value, formattedNumber, dialCode, countryCode, isValid) {
        const phone = {
            countryCode: countryCode || 'US',
            isValidPhoneNumber: isValid,
            phoneNumber: value,
        };
        AddPersonRecordActions.updatePhone(phone);
    }

    static onPhoneExtensionChange(value) {
        AddPersonRecordActions.updatePhoneExtension(value);
    }

    static onPhoneTypeChange(value) {
        if (value === `${BEM_BLOCK_ELEMENT_NAME}_phone_type_home`) {
            AddPersonRecordActions.updatePhoneType({
                phoneType: 'Home Phone',
                phoneTypeId: HOME_PHONE_TYPE_ID,
            });
        } else if (value === `${BEM_BLOCK_ELEMENT_NAME}_phone_type_mobile`) {
            AddPersonRecordActions.updatePhoneType({
                phoneType: 'Cell Phone',
                phoneTypeId: CELL_PHONE_TYPE_ID,
            });
        } else {
            AddPersonRecordActions.updatePhoneType({
                phoneType: 'Work Phone',
                phoneTypeId: WORK_PHONE_TYPE_ID,
            });
        }
    }

    static onChangePhoneType(value) {
        AddPersonRecordActions.updatePhoneType(value);
    }

    static onChangePostalCode(value) {
        AddPersonRecordActions.updatePostalCode(value);
    }

    static onChangeRegion(value) {
        AddPersonRecordActions.updateRegion(value);
    }

    render() {
        const {
            className,
            contactFormData,
            isEmergencyContactInfoSet,
            isRegionRequired,
            onBlur,
            onFocus,
            required,
            style,
        } = this.props;

        const {
            defaultCountrySet,
        } = this.state;

        const {
            address1,
            address2,
            city,
            country,
            email,
            phone,
            postalCode,
            region,
        } = contactFormData;

        const containerClasses = ClassNames(BEM_BLOCK_NAME, className);

        const regionFieldProps = {
            countryAlpha3: !_.isEmpty(country) ? country.code : 'US',
        };

        const phoneFieldProps = {
            extension: phone.extension,
            isHome: phone.phoneTypeId === HOME_PHONE_TYPE_ID,
            isMobile: phone.phoneTypeId === CELL_PHONE_TYPE_ID,
            isWork: phone.phoneTypeId === WORK_PHONE_TYPE_ID,
            onPhoneExtensionChange: ContactForm.onPhoneExtensionChange,
            onPhoneTypeChange: ContactForm.onPhoneTypeChange,
        };

        let isPostalCodeRequired = false;

        if (!_.isEmpty(country) && _.includes(validator.isPostalCodeLocales, country.code)) {
            isPostalCodeRequired = true;
        }

        const isAddressValid = !!address1 &&
                !!city &&
                !!country &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!region;

        const hasAddresssValues = !!address1 ||
            !!city ||
            (
                !!country && !defaultCountrySet
            ) ||
            (
                (isPostalCodeRequired && !!postalCode)
            ) ||
            !!region;

        const isAddressFieldsRequired = isAddressValid || hasAddresssValues;

        return (
            <Form className={containerClasses} style={style}>
                <Form.Fieldset
                    className={`${BEM_BLOCK_NAME}--field_set`}
                    id={`${BEM_BLOCK_NAME}--field_set`}
                    title={(
                        <React.Fragment>
                            {i18n('Contact')}
                            {required && !isEmergencyContactInfoSet && (
                                <span style={{ color: colorAlert }}> *</span>
                            )}
                        </React.Fragment>
                    )}
                >
                    <Form.Legend
                        className={`${BEM_BLOCK_NAME}--field_legend`}
                        value={i18n('One form of contact is required.')}
                    />

                    <Form.Field
                        className={`${BEM_BLOCK_ELEMENT_NAME}_email`}
                        error={!!email && !validator.isEmail(email)}
                        fieldType="inputText"
                        label={i18n('Email')}
                        onBlur={() => onBlur('email', email)}
                        onFocus={() => onFocus('email', email)}
                        onChange={ContactForm.onChangeEmail}
                        value={email}
                    />
                    <Form.Field
                        className={`${BEM_BLOCK_ELEMENT_NAME}_phone`}
                        error={!!phone.phoneNumber && !phone.isValidPhoneNumber}
                        fieldId={`${BEM_BLOCK_ELEMENT_NAME}_phone_type`}
                        fieldProps={phoneFieldProps}
                        fieldType="inputTel"
                        label={i18n('Phone')}
                        onBlur={() => onBlur('phoneNumber', phone.phoneNumber)}
                        onChange={ContactForm.onChangePhone}
                        value={phone.phoneNumber}
                    />
                    <Form.Field
                        className={`${BEM_BLOCK_ELEMENT_NAME}_address1`}
                        fieldType="inputText"
                        label={i18n('Address 1')}
                        onBlur={() => onBlur('address1', address1)}
                        onFocus={() => onFocus('address1', address1)}
                        onChange={ContactForm.onChangeAddressOne}
                        required={isAddressFieldsRequired}
                        value={address1}
                    />
                    <Form.Field
                        className={`${BEM_BLOCK_ELEMENT_NAME}_address2`}
                        fieldType="inputText"
                        label={i18n('Address 2')}
                        onChange={ContactForm.onChangeAddressTwo}
                        value={address2}
                    />
                    <Form.Field
                        className={`${BEM_BLOCK_ELEMENT_NAME}_city`}
                        fieldType="inputText"
                        label={i18n('City')}
                        medium={6}
                        onBlur={() => onBlur('city', city)}
                        onFocus={() => onFocus('city', city)}
                        onChange={ContactForm.onChangeCity}
                        required={isAddressFieldsRequired}
                        value={city}
                    />
                    <Form.Field
                        className={`${BEM_BLOCK_ELEMENT_NAME}_country`}
                        fieldType="selectCountry"
                        fluid
                        label={i18n('Country')}
                        medium={6}
                        onChange={this.onChangeCountry}
                        required={isAddressFieldsRequired}
                        value={country}
                    />
                    <Form.Field
                        className={`${BEM_BLOCK_ELEMENT_NAME}_postal_code`}
                        fieldType="inputText"
                        label={i18n('Postal Code')}
                        medium={6}
                        onBlur={() => onBlur('postalCode', postalCode)}
                        onFocus={() => onFocus('postalCode', postalCode)}
                        onChange={ContactForm.onChangePostalCode}
                        required={isPostalCodeRequired && isAddressFieldsRequired}
                        value={postalCode}
                    />
                    <Form.Field
                        className={`${BEM_BLOCK_ELEMENT_NAME}_region`}
                        fieldType="selectRegion"
                        fieldProps={regionFieldProps}
                        fluid
                        label={i18n('Region')}
                        medium={6}
                        onChange={ContactForm.onChangeRegion}
                        required={isRegionRequired && isAddressFieldsRequired}
                        value={region}
                    />
                </Form.Fieldset>
            </Form>
        );
    }
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default connect(mapStateToProps)(ContactForm);
