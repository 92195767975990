import {
    isEmpty,
    isNil,
    isNumber,
} from 'lodash';
import {
    ANTLRInputStream,
    CommonTokenStream,
} from 'antlr4ts';
import { dateUtils } from '@saddlebackchurch/react-cm-ui';
import moment from 'moment-timezone';
import { DslLexer } from './dslParser/DslLexer';
import { DslParser } from './dslParser/DslParser';
import ErrorListener from './errorListener.js';
import Visitor from './visitor';

const dateFormats = dateUtils.getAllowedDateFormats();

export function formatSearchTerms(rawQuery) {
    const dateRegex = /(\b(0?[1-9]|[12]\d|30|31)[^\w\d\r\n:](0?[1-9]|1[0-2])[^\w\d\r\n:](\d{4}|\d{2})\b)|(\b(0?[1-9]|1[0-2])[^\w\d\r\n:](0?[1-9]|[12]\d|30|31)[^\w\d\r\n:](\d{4}|\d{2})\b)|(\b(\d{4})[^\w\d\r\n:](0[1-9]|1[0-2])[^\w\d\r\n:](0[1-9]|[12]\d|30|31)\b)|(\b(\d{4})[^\w\d\r\n:](0[1-9]|[12]\d|30|31)[^\w\d\r\n:](0[1-9]|1[0-2])\b)/g;
    return rawQuery.replace(dateRegex, (match) => {
        const parsed = moment(match, dateFormats, moment.locale(), true);
        return parsed.format('YYYY-MM-DD');
    });
}

const replacerRange = (match, first, second) => `${first} TO ${second}`;

const replacerDate = (match, before, date, none, after) => {
    const momentDate = moment(date);

    return (before || '') + momentDate.format('YYYY-MM-DD') + (after || '');
};

const processQuery = (query) => {
    if (isEmpty(query)) {
        return '';
    }

    // TODO/FIXME:
    // For now I am suppressing ESLINT warnings about "useless escapes".
    // At some point we should consider seeing if we can simplify these regexes
    // and remove the ESLINT suppression.
    let result = query.replace(/(\[[-+]?[0-9]+\.?[0-9]*)\s*-\s*([-+]?[0-9]+\.?[0-9]*\])/g, replacerRange);
    result = result.replace(/(\D|^)(\d{1,2}[-/]\d{1,2}[-/](\d{2}|\d{4}))(\D|$)/g, replacerDate);

    return result;
};

/**
 *
 * Validates that the search text won't break api search end point
 * and that search fields are valid and contain correct value format
 *
 * @param {String} searchTerm Text that will be sent to the api endpoint as query parameter
 * @param {[String]} searchFields Available fields returned by the entity api search-fields endpoint
 * @returns {boolean} is search query valid?
 *
 */
export function isSearchQueryValid(searchTerm, searchFields = []) {
    let isValid = true;

    if (isEmpty(searchTerm)) {
        return false;
    }

    const query = processQuery(searchTerm);
    const inputStream = new ANTLRInputStream(query);
    const lexer = new DslLexer(inputStream);
    const tokenStream = new CommonTokenStream(lexer);
    const parser = new DslParser(tokenStream);
    parser.buildParseTrees = true;
    const errorListener = new ErrorListener();
    parser.addErrorListener(errorListener);
    const tree = parser.dsl();

    if (errorListener.error) {
        isValid = false;
    } else {
        const visitor = new Visitor(searchFields);
        visitor.visit(tree);

        if (visitor.error) {
            isValid = false;
        }
    }

    return isValid;
}

export const getPeopleSearchFormattedQuery = (options) => {
    const formatedSearchTerm = formatSearchTerms(options.q);
    let query = `?q=${encodeURIComponent(formatedSearchTerm)}`;

    if (options.pageSize) {
        query += `&pageSize=${options.pageSize}`;
    }

    if (options.pageNumber) {
        query += `&pageNumber=${options.pageNumber}`;
    }

    if (options.includeDeceasedPersons) {
        query += '&includeDeceased=true';
    }

    if (options.opportunityId) {
        query += `&servingOpportunityId=${options.opportunityId}`;
    } else if (options.churchEntityId && options.ministryId) {
        query += `&churchEntityId=${options.churchEntityId}&ministryId=${options.ministryId}`;
    }

    if (isNumber(options.refPersonId)) {
        query += `&refPersonId=${options.refPersonId}`;
    }

    if (options.sortField && options.sortFieldOrder) {
        query += `&sort=${options.sortField.toLowerCase()},${options.sortFieldOrder.toLowerCase()}`;
    }

    if (!isNil(options.checkPublicContacts)) {
        query += `&checkPublicContacts=${options.checkPublicContacts}`;
    }

    if (options.isCheckInPersonSearch) {
        query += '&isCheckInPersonSearch=true';
    }

    if (!isNil(options.checkMembership)) {
        query += `&checkMembership=${options.checkMembership}`;
    }

    if (!isNil(options.eventId)) {
        query += `&eventId=${options.eventId}`;
    }

    if (!isNil(options.occurrenceId)) {
        query += `&occurrenceId=${options.occurrenceId}`;
    }

    if (!isNil(options.subVenueId)) {
        query += `&subVenueId=${options.subVenueId}`;
    }

    if (!isNil(options.checkInType)) {
        query += `&checkInType=${options.checkInType}`;
    }

    if (!isNil(options.ageCategory)) {
        query += `&ageCategory=${options.ageCategory}`;
    }

    if (!isNil(options.includeNoteCount)) {
        query += `&includeNoteCount=${options.includeNoteCount}`;
    }

    return query;
};
