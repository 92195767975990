export const DEFAULT_STATE = {
    searchTerm: '',
    draftFilterOptions: {},
    filterOptions: {},
    isFilterActive: false,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        case 'EventsSearchFilters.ON_APPLY_FILTERS':
        {
            return {
                ...state,
                filterOptions: actionValue.filterOptions,
                draftFilterOptions: actionValue.draftFilterOptions,
                isFilterActive: actionValue.isFilterActive,
            };
        }

        case 'EventsSearchFilters.ON_FILTER':
        {
            return {
                ...state,
                ...actionValue,
            };
        }

        case 'EventsSearchFilters.ON_SEARCH':
        {
            return {
                ...state,
                filterOptions: actionValue,
            };
        }

        case 'EventsSearchFilters.ON_UPDATE_SEARCH_TERM':
        {
            return {
                ...state,
                searchTerm: actionValue,
            };
        }

        case 'EventsSearchFilters.ON_UPDATE_IS_FILTER_ACTIVE':
        {
            return {
                ...state,
                isFilterActive: actionValue,
            };
        }

        case 'EventsSearchFilters.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
