import noteCategories, { DEFAULT_STATE as NOTE_CATEGORIES_DEFAULT_STATE } from './noteCategories';
import permissions, { DEFAULT_STATE as PERMISSIONS_DEFAULT_STATE } from './permissions';
import regions, { DEFAULT_STATE as REGIONS_DEFAULT_STATE } from './regions';
import roles, { DEFAULT_STATE as ROLES_DEFAULT_STATE } from './roles';
import scopes, { DEFAULT_STATE as SCOPES_DEFAULT_STATE } from './scopes';
import users, { DEFAULT_STATE as USERS_DEFAULT_STATE } from './users';
import LoginConstants from '../../constants/Login/LoginConstants';
import userSearch, { DEFAULT_STATE as USER_SEARCH_DEFAULT_STATE } from '../../../system/users/global/userSearchReducer.js';
import teamSearch, { DEFAULT_STATE as TEAM_SEARCH_DEFAULT_STATE } from '../../../system/users/global/teamSearchReducer.js';

const DEFAULT_STATE = {
    noteCategories: NOTE_CATEGORIES_DEFAULT_STATE,
    permissions: PERMISSIONS_DEFAULT_STATE,
    regions: REGIONS_DEFAULT_STATE,
    roles: ROLES_DEFAULT_STATE,
    scopes: SCOPES_DEFAULT_STATE,
    teamSearch: TEAM_SEARCH_DEFAULT_STATE,
    users: USERS_DEFAULT_STATE,
    userSearch: USER_SEARCH_DEFAULT_STATE,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'NoteCategoryActions.ON_GET':
        case 'NoteCategoryActions.ON_GETALL':
        case 'NoteCategoryActions.ON_CREATE':
        case 'NoteCategoryActions.ON_UPDATE':
        case 'NoteCategoryActions.RESET':
            return { ...state, noteCategories: noteCategories(state.noteCategories, action) };

        case 'PermissionActions.ON_GETALL':
        case 'PermissionActions.ON_GETHIERARCHY':
        case 'PermissionActions.ON_GETUSAGEINROLES':
        case 'PermissionActions.ON_GETUSAGEAMONGUSERS':
        case 'PermissionActions.RESET':
        case 'PermissionActions.RESET_USAGE':
        case 'PermissionActions.ON_USAGEAMONGUSERSNEXTPAGE':
            return { ...state, permissions: permissions(state.permissions, action) };

        case 'RegionActions.ON_GETALL':
            return { ...state, regions: regions(state.regions, action) };

        case 'RoleActions.ON_GET':
        case 'RoleActions.ON_GETALL':
        case 'RoleActions.ON_CREATE':
        case 'RoleActions.ON_UPDATE':
        case 'RoleActions.RESET':
            return { ...state, roles: roles(state.roles, action) };

        case 'ScopeActions.ON_UPDATE':
            return { ...state, scopes: scopes(state.scopes, action) };

        case 'TeamSearchActions.ON_APPLY_FILTERS':
        case 'TeamSearchActions.ON_BEFORE_GET':
        case 'TeamSearchActions.ON_CHURCH_ENTITY_FILTER_CHANGED_AND_APPLIED':
        case 'TeamSearchActions.ON_CLEAR_FILTERS':
        case 'TeamSearchActions.ON_CLEAR_SEARCH_TERM':
        case 'TeamSearchActions.ON_DIRTY_CHURCH_ENTITY_FILTER_CHANGED':
        case 'TeamSearchActions.ON_DIRTY_SORT_ORDER_CHANGED':
        case 'TeamSearchActions.ON_GET':
        case 'TeamSearchActions.ON_NEXTPAGE':
        case 'TeamSearchActions.ON_RESET':
        case 'TeamSearchActions.ON_SET_SEARCH_TERM':
        case 'TeamSearchActions.ON_SORT_ORDER_CHANGED_AND_APPLIED':
            return { ...state, teamSearch: teamSearch(state.teamSearch, action) };

        case 'UserActions.ON_GET':
        case 'UserActions.ON_GETALL':
        case 'UserActions.ON_GETBYROLE':
        case 'UserActions.ON_CREATE':
        case 'UserActions.ON_UPDATE':
        case 'UserActions.ON_SENDNEWACCOUNTEMAIL':
        case 'UserActions.ON_SENDRESETPASSWORD':
        case 'UserActions.ON_SETNEWPASSWORD':
        case 'UserActions.RESET':
        case 'UserActions.ON_SEARCH':
        case 'UserActions.ON_SEARCHUSER':
        case 'UserActions.ON_SEARCHNEXTPAGE':
        case 'UserActions.ON_SELECT_ALL_CHECKBOXES':
        case 'UserActions.ON_CLEAR_ALL_CHECKBOXES':
        case 'UserActions.ON_SELECT_CHECKBOX':
        case 'UserActions.ON_UNSELECT_CHECKBOX':
            return { ...state, users: users(state.users, action) };

        case 'UserSearchActions.ON_APPLY_FILTERS':
        case 'UserSearchActions.ON_BEFORE_GET':
        case 'UserSearchActions.ON_CHURCH_ENTITY_FILTER_CHANGED_AND_APPLIED':
        case 'UserSearchActions.ON_CLEAR_FILTERS':
        case 'UserSearchActions.ON_CLEAR_SEARCH_TERM':
        case 'UserSearchActions.ON_DIRTY_CHURCH_ENTITY_FILTER_CHANGED':
        case 'UserSearchActions.ON_DIRTY_SORT_ORDER_CHANGED':
        case 'UserSearchActions.ON_GET':
        case 'UserSearchActions.ON_NEXTPAGE':
        case 'UserSearchActions.ON_RESET':
        case 'UserSearchActions.ON_SET_SEARCH_TERM':
        case 'UserSearchActions.ON_SORT_ORDER_CHANGED_AND_APPLIED':
            return { ...state, userSearch: userSearch(state.userSearch, action) };
    }

    return state;
}
