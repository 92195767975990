import {
    Modal,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
} from 'lodash';
import ClassNames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import {
    BEM_BLOCK_NAME,
    SM_BP_CONTAINER_MAX_WIDTH,
    STEPS_PROP_TYPE,
} from './constants.js';
import {
    HCUI_CLASS_NAME,
} from '../constants.js';
import EducationalDialogActions from './educationalDialogActions.jsx';
import EducationalDialogImagesSmallBreakpoint from './educationalDialogImagesSmallBreakpoint.jsx';
import EducationalDialogImagesMediumBreakpoint from './educationalDialogImagesMediumBreakpoint.jsx';

const propTypes = {
    activeStep: PropTypes.number,
    className: PropTypes.string,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onCloseComplete: PropTypes.func,
    steps: STEPS_PROP_TYPE.isRequired,
};

const defaultProps = {
    activeStep: 0,
    className: undefined,
    id: undefined,
    isOpen: false,
    onCloseComplete: undefined,
};

const useStyles = makeStyles(({
    breakpoints,
    spacing,
}) => ({
    body: {
        margin: [[22, 0, 0]],
        textAlign: 'center',
        '& > p': {
            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
    content: {
        [breakpoints.only('sm')]: {
            maxWidth: SM_BP_CONTAINER_MAX_WIDTH,
            margin: [[0, 'auto']],
        },
    },
    heading: {
        marginTop: `${spacing(2)}px !important`,
    },
    root: {},
}));

function EducationalDialog(props) {
    const {
        activeStep: activeStepProp,
        className,
        id,
        isOpen,
        onCloseComplete,
        onClose,
        steps,
    } = props;

    // Hooks
    const classes = useStyles(props);
    const [activeStep, setActiveStep] = React.useState(activeStepProp);

    if (isEmpty(steps)) {
        return null;
    }

    const maxSteps = steps.length;
    const step = steps[activeStep];

    const onBackClick = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const onNextClick = () => {
        if (activeStep < maxSteps - 1) {
            setActiveStep(activeStep + 1);
        }
    };

    const rootClasses = ClassNames(
        HCUI_CLASS_NAME,
        BEM_BLOCK_NAME,
        classes.root,
        className,
    );

    const modalWidth = 710;

    return (
        <Modal
            className={rootClasses}
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={onCloseComplete}
            width={modalWidth}
        >
            <Modal.Content
                alignItems="center"
                className={classes.content}
            >
                <EducationalDialogImagesSmallBreakpoint
                    images={step.images}
                />

                <EducationalDialogImagesMediumBreakpoint
                    images={step.images}
                />

                <Typography
                    classes={{
                        root: classes.heading,
                    }}
                    variant="h2"
                >
                    {step.heading}
                </Typography>

                <Typography
                    classes={{
                        root: classes.body,
                    }}
                    color="textSecondary"
                    component="div"
                    variant="body1"
                >
                    {parse(step.body)}
                </Typography>
            </Modal.Content>

            <EducationalDialogActions
                activeStep={activeStep}
                maxSteps={maxSteps}
                onBackClick={onBackClick}
                onDoneClick={onClose}
                onNextClick={onNextClick}
                step={step}
            />
        </Modal>
    );
}

EducationalDialog.propTypes = propTypes;
EducationalDialog.defaultProps = defaultProps;

export default EducationalDialog;
