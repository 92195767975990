import request from 'axios';
import ApiUtils from '../../../../global/apiUtils.js';

class PersonNoteAPIUtils extends ApiUtils {
}

const personNoteAPIUtils = new PersonNoteAPIUtils(
    '/api/person/',
);

personNoteAPIUtils.buildClass(
    { name: 'get', rq: request.get, route: '{personId}/note' },
    { name: 'create', rq: request.post, route: '{personId}/note' },
    { name: 'update', rq: request.put, route: '{personId}/note/{noteId}' },
    { name: 'delete', rq: request.delete, route: '{personId}/note/{noteId}' },
);

export default personNoteAPIUtils;
