import _ from 'lodash';

export const DEFAULT_STATE = {
    list: [],
    saveNoteRequested: false,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'OccupationActions.RESET':
            return DEFAULT_STATE;
        case 'OccupationActions.ON_GET':
            return { ...state, list: action.result };
        case 'OccupationActions.ON_CREATE':
            return { ...state, list: state.list.concat([action.result]) };
        case 'OccupationActions.ON_UPDATE':
            const otherOccupations = _.filter(state.list, (o) => o.id !== action.result.id);
            const finalList = otherOccupations.concat([action.result]);

            return { ...state, list: finalList };
        case 'OccupationActions.ON_DELETE':
            return { ...state, list: _.filter(state.list, (o) => o.id !== action.params.occupationId) };
        case 'OccupationActions.ON_REQUESTAUDITNOTE':
            return { ...state, saveNoteRequested: true };
        case 'OccupationActions.ON_CLEARNOTEREQUEST':
            return { ...state, saveNoteRequested: false };
    }
}
