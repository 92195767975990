import React, { useCallback } from 'react';
import { Banner } from '@saddlebackchurch/react-cm-ui';
import { useSelector } from 'react-redux';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import { RootState } from 'reducers';
import { i18n } from '../../global/constants.js';
import ApiConfig from '../../global/api/apiConfig';
import CommonExportActions from '../../system/migrationSettings/export/global/export.actions.js';
import Utils from '../../global/utils/utils.js';
import { DownloadFormat } from './constants';

const useStyles = makeStyles(({
    spacing,
    typography,
}) => ({
    banner: {
        '& .font-size-xxsmall': {
            fontSize: typography.pxToRem(14),
        },
    },
    link: {
        display: 'flex',
        fontSize: typography.pxToRem(14),
        marginTop: spacing(1),
    },
}));

const ExportDownloadBanner = () => {
    const classes = useStyles();

    const download = useSelector((state: RootState) => (
        state.export.download
    ));

    const dataType = download.result.type;
    const format = DownloadFormat[download.result.fileName?.split('.').pop().toUpperCase()];

    const getDownloadUrl = useCallback(() => {
        const parameters = {
            file: download.result.fileName,
            token: download.result.token,
            format,
        };

        return `${ApiConfig.get('Core')}/api/export/${dataType}${Utils.buildQuery(parameters)}`;
    }, [dataType, download, format]);

    const resetDownload = useCallback(() => {
        CommonExportActions.resetExport();
    }, []);

    return (
        <React.Fragment>
            {download.result.isCompleted && download.result.type === 'Segment' && (
                <div>
                    <Banner
                        className={classes.banner}
                        isOpen
                        level="success"
                        message={i18n('Your file is ready for download')}
                        onClose={resetDownload}
                        type="notification"
                    >
                        <a
                            className={classes.link}
                            href={getDownloadUrl()}
                            key="link"
                            onClick={resetDownload}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {i18n('Download')}
                        </a>
                    </Banner>
                </div>
            )}
        </React.Fragment>
    );
};

export default ExportDownloadBanner;
