import jwt_decode from 'jwt-decode';

export default function getJwtClaim(jwt: string, claim: string): string | null {
    try {
        const decoded = jwt_decode(jwt);
        return decoded[claim] ?? null;
    } catch (err) {
        console.error('Bad JWT', err);
        return null;
    }
}
