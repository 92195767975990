import {
    filter,
    map,
} from 'lodash';
import {
    SubNavigation,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import React from 'react';
import withStyles from '@saddlebackchurch/react-cm-ui/core/styles/withStyles';
import {
    BEM_BLOCK_NAME,
} from './recordConstants';
import { translationFactory } from '../../global/i18nUtils.js';
import UserAccessStore from '../../global/userAccessStore.js';
import {
    USER_PERMISSIONS,
} from '../../global/userPermissionConstants.js';
import Tab from './models/tab.model';

const i18n = translationFactory('Person.Record.TopLevel.SubNavigation');

type PropTypes = {
    classes?: {
        root?: string;
    };
    isDrawerChild?: boolean;
    onTabClick: ({ selectedTabIndex } : { selectedTabIndex: number; }) => void;
    selectedTabIndex: number;
    tabs: Tab[];
    userAccess: UserAccessStore;
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        people: {
            record: {
                index: {
                    subPageIndex,
                },
            },
        },
    } = state;

    return {
        subPageIndex,
        userAccess,
    };
};

const styles = ({ spacing }) => ({
    root: {
        '& .sub-navigation-item-container button': {
            margin: [[0, 4]],
            '&:first-child': {
                marginLeft: spacing(2),
            },
            '&:last-child': {
                marginRight: spacing(2),
            },
            '& .button-is-active-indicator': {
                bottom: -3,
            },
        },
    },
});

class Tabs extends React.PureComponent<PropTypes> {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(index: number) {
        const {
            onTabClick,
        } = this.props;

        onTabClick({ selectedTabIndex: index });
    }

    render() {
        const {
            classes,
            isDrawerChild,
            selectedTabIndex,
            tabs,
            userAccess,
        } = this.props;

        const rootClasses = ClassNames(
            `${BEM_BLOCK_NAME}--details_window_sub_nav`,
            classes.root,
        );

        const filteredTabs = filter(tabs, (tab) => {
            const permission = tab.permission ?? USER_PERMISSIONS.bypass;
            const hasPermission = userAccess.hasPermission(
                permission,
            );

            const removeFromDrawer = isDrawerChild && tab.removeFromDrawer;

            return hasPermission && !removeFromDrawer;
        });

        let tabKey = 1;

        return (
            <SubNavigation
                className={rootClasses}
                onClick={this.onClick}
                selected={selectedTabIndex}
            >
                {map(filteredTabs, (tab) => {
                    tabKey += 1;

                    return (
                        <SubNavigation.Item
                            key={`tab-${tabKey}`}
                            label={i18n(tab.label)}
                        />
                    );
                })}
            </SubNavigation>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Tabs)) as any;
