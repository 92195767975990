import { isEqual } from 'lodash';
import { SORT_OPTIONS } from './constants.js';

export const defaultFilters = {
    anonymousFilter: null,
    eventDateFilter: null,
    eventTimeFilter: null,
    sort: SORT_OPTIONS[0],
};

export const DEFAULT_STATE = {
    appliedFilters: { ...defaultFilters },
    areFiltersDirty: false,
    defaultFilters: { ...defaultFilters },
    dirtyFilters: { ...defaultFilters },
    isFiltering: false,
    searchTerm: null,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        case 'ConnectionFormEntriesListPageActions.ON_SET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: actionValue,
            };

        case 'ConnectionFormEntriesListPageActions.ON_CLEAR_SEARCH_TERM':
            return {
                ...state,
                searchTerm: null,
            };

        case 'ConnectionFormEntriesListPageActions.ON_APPLY_FILTERS':
        {
            const { dirtyFilters } = state;
            const isFiltering = !isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'ConnectionFormEntriesListPageActions.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(state, { ...defaultFilters });

        case 'ConnectionFormEntriesListPageActions.ON_ANONYMOUS_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, anonymousFilter: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ConnectionFormEntriesListPageActions.ON_EVENT_DATE_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, eventDateFilter: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ConnectionFormEntriesListPageActions.ON_EVENT_TIME_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, eventTimeFilter: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ConnectionFormEntriesListPageActions.ON_SORT_ORDER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, sort: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ConnectionFormEntriesListPageActions.ON_DIRTY_ANONYMOUS_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, anonymousFilter: actionValue },
            );
        }

        case 'ConnectionFormEntriesListPageActions.ON_DIRTY_EVENT_DATE_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, eventDateFilter: actionValue },
            );
        }

        case 'ConnectionFormEntriesListPageActions.ON_DIRTY_EVENT_TIME_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;

            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, eventTimeFilter: actionValue },
            );
        }

        case 'ConnectionFormEntriesListPageActions.ON_DIRTY_SORT_ORDER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(state, { ...dirtyFilters, sort: actionValue });
        }

        case 'ConnectionFormEntriesListPageActions.RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
