import _ from 'lodash';
import {
    Button,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import BannerUtils from '../../js/utils/BannerUtils.js';
import { i18n } from '../../global/constants.js';
import {
    personPersonalFormPropTypes,
    personPersonalFormDefaultProps,
    personContactFormPropTypes,
    personContactFormDefaultProps,
} from '../record/global/personFormPropTypes.js';
import { USER_PERMISSIONS } from '../../global/userPermissionConstants.js';
import PersonalForm from './personalForm.jsx';
import ContactForm from './contactForm.jsx';
import AddPersonActions from './addPersonActions.js';
import AvatarManager from '../../global/avatarManager.jsx'; // eslint-disable-line import/no-named-as-default
import PersonalFormActions from './personalFormActions.js';
import { resetData as contactFormResetData } from './contactFormActions.js';
import PossibleDuplicatesDrawer from './possibleDuplicatesDrawer.jsx'; // eslint-disable-line import/no-named-as-default
import PossibleDuplicatesActions from './possibleDuplicatesActions.js';
import CoreMilestonesActions from '../record/global/coreMilestones.actions';
import UserAccessStore from '../../global/userAccessStore.js';

const propTypes = {
    avatarImage: PropTypes.shape({
        filename: PropTypes.string,
        url: PropTypes.string,
    }),
    canSave: PropTypes.func,
    contactFormData: personContactFormPropTypes,
    isOpenPossibleDuplicatesDrawer: PropTypes.bool.isRequired,
    onPossibleDuplicatesDrawerToggle: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    personalFormData: personPersonalFormPropTypes,
    possibleDuplicatesList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    recordType: PropTypes.oneOf(['adult', 'child', 'student']),
    saveNow: PropTypes.bool,
    useThisRecord: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    avatarImage: {},
    canSave: undefined,
    contactFormData: personContactFormDefaultProps,
    personalFormData: personPersonalFormDefaultProps,
    possibleDuplicatesList: [],
    recordType: 'adult',
    saveNow: false,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: { userAccess },
        },
        people: {
            addPerson: {
                avatar: {
                    avatarImage,
                },
                contactForm: {
                    data: contactFormData,
                },
                personalForm: {
                    churchEntities: churchEntitiesList,
                    data: personalFormData,
                    recordType,
                },
                possibleDuplicates: {
                    data: possibleDuplicatesList,
                    isFetching: isFetchingPossibleDuplicates,
                    pageNumber,
                    pageSize,
                    total,
                },
            },
        },
    } = state;

    return {
        avatarImage,
        churchEntitiesList,
        contactFormData,
        isFetchingPossibleDuplicates,
        pageNumber,
        pageSize,
        personalFormData,
        possibleDuplicatesList,
        recordType,
        total,
        userAccess,
    };
};

const POSSSIBLE_DUPLICATES_BANNER = 'ui-banner--possible_duplicates_view';

class AddPerson extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeLoadMorePossibleDuplicates: false,
            personFocus: {},
        };

        this.onBlurFieldCalcPB = this.onBlurFieldCalcPB.bind(this);
        this.onFocusFieldCalcPB = this.onFocusFieldCalcPB.bind(this);
        this.onNextPagePossibleDuplicates = this.onNextPagePossibleDuplicates.bind(this);
        this.onOpenPossibleDuplicatesDrawer = this.onOpenPossibleDuplicatesDrawer.bind(this);
        this.onSavePerson = this.onSavePerson.bind(this);
        this.onSavePersonPhoto = this.onSavePersonPhoto.bind(this);
        this.onSetActiveLoadMore = this.onSetActiveLoadMore.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            canSave,
            contactFormData,
            personalFormData,
            saveNow,
        } = this.props;

        const {
            firstName,
            lastName,
            campus,
        } = personalFormData;

        const {
            isRequirementFullfilled: isContactRequirementFullfilled,
        } = contactFormData;

        if (
            prevProps.contactFormData !== contactFormData ||
            prevProps.personalFormData !== personalFormData
        ) {
            const disableSavePerson = (!firstName || !lastName || _.isEmpty(campus)) ||
                    !isContactRequirementFullfilled;
            canSave(!disableSavePerson);
        }

        if (prevProps.saveNow !== saveNow && saveNow) {
            this.onSavePerson();
        }
    }

    componentWillUnmount() {
        PersonalFormActions.resetData();
        contactFormResetData();
        PossibleDuplicatesActions.reset();
        PossibleDuplicatesActions.resetPersonalDetails();
    }

    onNextPagePossibleDuplicates() {
        const {
            pageNumber,
            pageSize,
            personalFormData,
            contactFormData,
            userAccess,
        } = this.props;

        const {
            firstName,
            lastName,
        } = personalFormData;

        const {
            email: {
                value: email,
            },
            address: {
                address1,
                address2,
                city,
                postalCode,
            },
        } = contactFormData;

        const dataPersonal = {
            firstName,
            lastName,
            address1,
            address2,
            city,
            email,
            postalCode,
        };

        const fields = [
            'firstName',
            'lastName',
            'nickName',
            'address1',
            'city',
            'postalCode',
            'homePhone',
            'workPhone',
            'cellPhone',
            'email',
        ];

        const first = false;
        const fieldsWithValue = _.chain(Object.keys(dataPersonal))
            .filter((f) => !!dataPersonal[f])
            .intersection(fields)
            .value();
        PossibleDuplicatesActions.get(
            fieldsWithValue, dataPersonal, pageSize, (pageNumber + 1), { first },
        ).then((res) => {
            AddPerson.getCoreMilestonesByPersonIds(res, userAccess);
            this.setState({ activeLoadMorePossibleDuplicates: true });
        });
    }

    onSetActiveLoadMore(value) {
        return new Promise((resolve) => {
            this.setState({ activeLoadMorePossibleDuplicates: value }, () => {
                resolve();
            });
        });
    }

    onBlurFieldCalcPB(field, value) {
        const { personFocus } = this.state;

        const {
            pageSize,
            personalFormData,
            contactFormData,
            userAccess,
        } = this.props;

        const {
            firstName,
            lastName,
        } = personalFormData;

        const {
            email: {
                value: email,
                isEmailValid,
            },
            address: {
                address1,
                address2,
                city,
                postalCode,
                isPostalCodeValid,
            },
        } = contactFormData;

        const dataPersonal = {
            firstName,
            lastName,
            address1,
            address2,
            city,
            email,
            postalCode,
        };

        if (field === 'email' && !isEmailValid) {
            return;
        }

        if (field === 'postalCode' && !isPostalCodeValid) {
            return;
        }

        const fields = [
            'firstName',
            'lastName',
            'nickName',
            'address1',
            'city',
            'postalCode',
            'homePhone',
            'workPhone',
            'cellPhone',
            'email',
        ];

        if (!fields.includes(field)) {
            return;
        }
        const first = true;
        const hasData = (f) => typeof dataPersonal[f] !== 'undefined' && dataPersonal[f] !== '';

        const hasMutated = (f) => personFocus[f] !== value;

        const hasPassedThreshold = () => {
            const distinctMap = {
                address1: 0.400199101,
                cellPhone: 0.170175613,
                city: 0.022184444,
                email: 0.867449747,
                firstName: 0.053948842,
                homePhone: 0.080386438,
                lastName: 0.116226771,
                nickName: 0.012355428,
                postalCode: 0.015079807,
                workPhone: 0.042059726,
            };

            const weight = _.chain(Object.keys(dataPersonal))
                .filter((key) => !!dataPersonal[key])
                .map((key) => distinctMap[key] || 0)
                .reduce((total, n) => total + n)
                .value();

            return weight >= distinctMap.firstName + distinctMap.lastName;
        };

        if (hasData(field) && hasMutated(field) && hasPassedThreshold(field)) {
            const fieldsWithValue = _.chain(Object.keys(dataPersonal))
                .filter((f) => !!dataPersonal[f])
                .intersection(fields)
                .value();

            PossibleDuplicatesActions.get(
                fieldsWithValue, dataPersonal, pageSize, 0, { first },
            ).then((res) => {
                const {
                    possibleDuplicatesList,
                    total,
                } = this.props;

                AddPerson.getCoreMilestonesByPersonIds(res, userAccess);
                this.setState({ activeLoadMorePossibleDuplicates: true });

                if (!_.isEmpty(possibleDuplicatesList)) {
                    BannerUtils.addBanner({
                        children: (
                            <p style={{ margin: 0 }}>
                                {`${total} ${i18n('Possible duplicates were found in our system')}`}
                                <div
                                    style={{
                                        marginTop: '11px',
                                    }}
                                >
                                    <Button
                                        color="outline"
                                        inverse
                                        onClick={this.onOpenPossibleDuplicatesDrawer}
                                        style={{
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        <span>{i18n('View')}</span>
                                    </Button>
                                    <Button
                                        color="outline"
                                        inverse
                                        onClick={
                                            AddPerson.onDismissPossibleDuplicatesBanner
                                        }
                                        style={{
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        <span>{i18n('Dismiss')}</span>
                                    </Button>
                                </div>
                            </p>
                        ),
                        id: POSSSIBLE_DUPLICATES_BANNER,
                        level: 'warning',
                        levelIcon: 'users',
                        timeout: false,
                        title: i18n('Warning'),
                        type: 'notification',
                    });
                }
            });
        }
    }

    static onDismissPossibleDuplicatesBanner() {
        BannerUtils.closeBanner(POSSSIBLE_DUPLICATES_BANNER);
    }

    static getCoreMilestonesByPersonIds(data, userAccess) {
        const hasPermissions = userAccess.hasPermission(
            USER_PERMISSIONS.readPersonMilestones,
        );

        if (hasPermissions && !_.isEmpty(data) && !_.isEmpty(data.results)) {
            const personIds = _.map(data.results, 'id');
            CoreMilestonesActions.getCoreMilestonesByPersonIds({
                personIds,
            });
        }
    }

    onOpenPossibleDuplicatesDrawer() {
        const {
            onPossibleDuplicatesDrawerToggle,
        } = this.props;
        BannerUtils.closeBanner(POSSSIBLE_DUPLICATES_BANNER);
        onPossibleDuplicatesDrawerToggle();
    }

    onFocusFieldCalcPB(field, value) {
        const {
            personFocus,
        } = this.state;
        personFocus[field] = value;
        this.setState({ personFocus });
    }

    onSavePerson() {
        const {
            avatarImage,
            recordType,
            onSave,
            canSave,
        } = this.props;

        const personalProcessedData = this.getPersonalFormProcessedData();
        const contactProcessedData = this.getContactFormProcessedData();
        const data = {
            ...personalProcessedData,
            ...(recordType !== 'child' && contactProcessedData),
        };
        const preferredContactData = {
            doNotMail: false,
            doNotPhone: false,
            doNotText: false,
            doNotEmail: false,
            doNotContact: false,
        };

        canSave(false);

        AddPersonActions.savePerson({}, data).then((res) => {
            const promises = [];

            if (!_.isEmpty(avatarImage) && avatarImage.url) {
                promises.push(this.onSavePersonPhoto(res.id));
            }

            if (!_.isEmpty(data) &&
                !_.isEmpty(data.emails) &&
                data.emails[0].email &&
                _.isEmpty(data.phones)) {
                promises.push(AddPersonActions.saveContactpreferences({
                    personId: res.id,
                }, {
                    ...preferredContactData,
                    preferredMethod: 'email',
                }));
            } else if (!_.isEmpty(data) &&
                !_.isEmpty(data.phones) &&
                data.phones[0].phoneNumber &&
                _.isEmpty(data.emails)) {
                promises.push(AddPersonActions.saveContactpreferences({
                    personId: res.id,
                }, {
                    ...preferredContactData,
                    preferredMethod: 'phone',
                }));
            }

            Promise.all(promises).then(() => {
                onSave(res);
                PersonalFormActions.resetData();
                contactFormResetData();
            });
        }).catch(() => {
            canSave(false);
        });
    }

    onSavePersonPhoto(personId) {
        const {
            avatarImage: {
                url,
            },
        } = this.props;

        return new Promise((resolve, reject) => {
            fetch(url).then(
                (imageRes) => imageRes.blob(),
            ).then((blob) => {
                const fd = new FormData();
                fd.append('personId', personId);
                fd.append('file', blob);
                AddPersonActions.savePersonPhoto({
                }, fd).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            }).catch(() => {
                reject();
            });
        });
    }

    getPersonalFormProcessedData() {
        const {
            personalFormData,
            recordType,
        } = this.props;

        const {
            birthDate,
            firstName,
            grade,
            gender,
            lastName,
            campus,
        } = personalFormData;

        const personalData = {
            birthDate,
            churchEntityId: campus.value,
            churchEntityKnown: campus.label !== 'Unkwon',
            churchEntityName: campus.label,
            firstName,
            gender: !_.isEmpty(gender) ? gender.value : '',
            ...(recordType !== 'adult' && { gradeLevel: grade.value }),
            lastName,
        };
        return personalData;
    }

    getContactFormProcessedData() {
        const {
            contactFormData,
        } = this.props;

        const {
            email: {
                value: emailValue,
                isEmailValid,
            },
            address: {
                address1,
                address2,
                city,
                countryAlpha3,
                isAddressValid,
                isPostalCodeValid,
                postalCode,
                regionCode,
            },
            phone: {
                countryCode,
                extension,
                isHome,
                isMobile,
                isPhoneValid,
                isWork,
                phoneNumber,
                phoneType,
                phoneTypeId,
            },
        } = contactFormData;

        let addresses;
        let contactData = {};
        let emails;
        let phones;

        if (isAddressValid && isPostalCodeValid) {
            addresses = [{
                address1,
                address2,
                city,
                countryAlpha3,
                isBadContact: false,
                isPrimary: true,
                postalCode,
                regionCode,
            }];
            contactData = { ...contactData, addresses };
        }

        if (isPhoneValid) {
            phones = [{
                countryCode,
                extension,
                isHome,
                isMobile,
                isPrimary: true,
                isValidPhoneNumber: isPhoneValid,
                isWork,
                phoneNumber,
                phoneType,
                phoneTypeId,
            }];
            contactData = { ...contactData, phones };
        }

        if (emailValue && isEmailValid) {
            emails = [{
                email: emailValue,
                isBadContact: false,
                isPrimary: true,
            }];
            contactData = { ...contactData, emails };
        }

        return contactData;
    }

    render() {
        const {
            isOpenPossibleDuplicatesDrawer,
            onPossibleDuplicatesDrawerToggle,
            personalFormData,
            recordType,
            useThisRecord,
        } = this.props;

        const {
            firstName,
            lastName,
        } = personalFormData;

        const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

        const {
            activeLoadMorePossibleDuplicates,
        } = this.state;

        return (
            <React.Fragment>
                <AvatarManager
                    fullName={fullName}
                />
                <PersonalForm
                    excludeGradeByRecordType="child"
                    isRecordTypeLabelEnabled
                    onBlur={this.onBlurFieldCalcPB}
                    onFocus={this.onFocusFieldCalcPB}
                    recordType={recordType}
                />
                {recordType !== 'child' && (
                    <ContactForm
                        onBlur={this.onBlurFieldCalcPB}
                        onFocus={this.onFocusFieldCalcPB}
                        recordType={recordType}
                    />
                )}
                <PossibleDuplicatesDrawer
                    activeLoadMore={activeLoadMorePossibleDuplicates}
                    isOpen={isOpenPossibleDuplicatesDrawer}
                    onClose={onPossibleDuplicatesDrawerToggle}
                    onNextPage={this.onNextPagePossibleDuplicates}
                    onSetActiveLoadMore={this.onSetActiveLoadMore}
                    useThisRecord={useThisRecord}
                />
            </React.Fragment>
        );
    }
}

AddPerson.propTypes = propTypes;
AddPerson.defaultProps = defaultProps;

export default connect(mapStateToProps)(AddPerson);
