import PropTypes from 'prop-types';
import { i18n } from '../../../global/constants.js';

export const BEM_BLOCK_NAME = 'connection_form_entries_list_page';

export const FORM_TYPE_REGULAR = 'Regular';
export const FORM_TYPE_WORSHIP_SERVICE = 'WorshipService';

export const FORM_TYPE_PROP_TYPE = PropTypes.oneOf([
    FORM_TYPE_REGULAR,
    FORM_TYPE_WORSHIP_SERVICE,
]);

export const SORT_OPTIONS = [
    {
        label: i18n('Last Updated (Newest - Oldest)'),
        value: '-updatedate',
    },
    {
        label: i18n('Last Updated (Oldest - Newest)'),
        value: 'updatedate',
    },
    {
        label: i18n('Event Date (Newest - Oldest)'),
        value: '-eventdate',
    },
    {
        label: i18n('Event Date (Oldest - Newest)'),
        value: 'eventdate',
    },
    {
        label: i18n('First Name (A - Z)'),
        value: 'firstname',
    },
    {
        label: i18n('First Name (Z - A)'),
        value: '-firstname',
    },
    {
        label: i18n('Last Name (A - Z)'),
        value: 'lastname',
    },
    {
        label: i18n('Last Name (Z - A)'),
        value: '-lastname',
    },
];

export const filterPropTypes = PropTypes.shape({
    anonymousFilter: PropTypes.bool, // (nullable: TRUE => Only Anonymous | FALSE => Only associated to Person Record | NULL => Don't filter by this)
    eventDateFilter: PropTypes.string, // Date as YYYY-MM-DD (nullable)
    eventTimeFilter: PropTypes.string, // Time as HH:mm:ss (nullable)
    sort: PropTypes.oneOf(SORT_OPTIONS), // should always be set to a valid sort option
});

export const ID_ENTRY_TYPE_ANONYMOUS_PERSON = `${BEM_BLOCK_NAME}--entry_type_anonymous_person`;
export const ID_ENTRY_TYPE_EXISTING_PERSON = `${BEM_BLOCK_NAME}--entry_type_existing_person`;
export const ID_ENTRY_TYPE_NEW_PERSON = `${BEM_BLOCK_NAME}--entry_type_new_person`;
