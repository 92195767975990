import LoginConstants from '../../constants/Login/LoginConstants';
import duplicate, { DEFAULT_STATE as DUPLICATE_DEFAULT_STATE } from './duplicate';
import lightPersonCache, { DEFAULT_STATE as LIGHT_PERSON_CACHE_DEFAULT_STATE } from './lightPersonCache';
import merge, { DEFAULT_STATE as MERGE_DEFAULT_STATE } from './merge';
import occupations, { DEFAULT_STATE as OCCUPATIONS_DEFAULT_STATE } from './occupations';
import subscriptions, { DEFAULT_STATE as SUBSCRIPTIONS_DEFAULT_STATE } from './subscriptions';
import personCache, { DEFAULT_STATE as PERSON_CACHE_DEFAULT_STATE } from './personCache';
import users, { DEFAULT_STATE as USERS_DEFAULT_STATE } from './users';
import emergencyContact, { DEFAULT_STATE as EMERGENCY_CONTACT_DEFAULT_STATE } from './emergencyContact.js';

const DEFAULT_STATE = {
    duplicate: DUPLICATE_DEFAULT_STATE,
    emergencyContact: EMERGENCY_CONTACT_DEFAULT_STATE,
    groups: [], // small groups person of which person is a member
    lightPersonCache: LIGHT_PERSON_CACHE_DEFAULT_STATE,
    merge: MERGE_DEFAULT_STATE,
    ministries: [], // ministries of which person is a member
    occupations: OCCUPATIONS_DEFAULT_STATE,
    subscriptions: SUBSCRIPTIONS_DEFAULT_STATE,
    personCache: PERSON_CACHE_DEFAULT_STATE,
    record: {}, // result of a query for a single person record
    users: USERS_DEFAULT_STATE,
    searchFields: [],
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'PersonActions.ON_GET':
            return {
                ...state,
                record: action.result,
            };

        case 'PersonActions.ON_GETGROUPS':
            return {
                ...state,
                groups: action.result,
            };

        case 'PersonActions.ON_GETMINISTRIES':
            return {
                ...state,
                ministries: action.result,
            };

        case 'PersonActions.RESET':
            return {
                ...state,
                groups: [],
                ministries: [],
                record: {},
            };

        case 'PersonActions.ON_GETSEARCHFIELDS':
            return {
                ...state,
                searchFields: action.result,
            };

        case 'PersonMergeActions.ON_GETPEOPLE':
            return {
                ...state,
                merge: merge(state.merge, action),
            };

        case 'PersonDuplicateActions.RESET':
        case 'PersonDuplicateActions.ON_GET':
        case 'PersonDuplicateActions.ON_MARKMISMATCH':
        case 'PersonDuplicateActions.ON__GETSTATISTICS':
        case 'PersonActions.ON_GETDUPLICATES':
            return {
                ...state,
                duplicate: duplicate(state.duplicate, action),
            };

        case 'OccupationActions.RESET':
        case 'OccupationActions.ON_GET':
        case 'OccupationActions.ON_CREATE':
        case 'OccupationActions.ON_UPDATE':
        case 'OccupationActions.ON_DELETE':
        case 'OccupationActions.ON_REQUESTAUDITNOTE':
        case 'OccupationActions.ON_CLEARNOTEREQUEST':
            return {
                ...state,
                occupations: occupations(state.occupations, action),
            };

        case 'SubscriptionActions.RESET':
        case 'SubscriptionActions.ON_GETPERSONSUBSCRIPTIONS':
        case 'SubscriptionActions.ON_GETMANAGEDSUBSCRIPTIONS':
        case 'SubscriptionActions.ON_CREATE':
        case 'SubscriptionActions.ON_UPDATE':
        case 'SubscriptionActions.ON_DELETE':
            return {
                ...state,
                subscriptions: subscriptions(state.subscriptions, action),
            };

        case 'LightPersonCacheActions.ON_GET':
        case 'LightPersonCacheActions.ON_RESET':
            return {
                ...state,
                lightPersonCache: lightPersonCache(state.lightPersonCache, action),
            };

        case 'PersonCacheActions.ON_GET':
        case 'PersonCacheActions.ON_RESET':
            return {
                ...state,
                personCache: personCache(state.personCache, action),
            };

        case 'PersonUsersActions.ON_RESET':
        case 'PersonUsersActions.ON_SEARCH':
        case 'PersonUsersActions.ON_NEXTPAGE':
            return {
                ...state,
                users: users(state.users, action),
            };

        case 'PersonEmergencyContactActions.ON_SET_IS_DRAWER_OPEN':
            return {
                ...state,
                emergencyContact: emergencyContact(state.emergencyContact, action),
            };

        default:
            return state;
    }
}
