import _ from 'lodash';

const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'CustomFieldAnswerActions.ON_GET': {
            _.forEach(action.result, (v) => {
                v.entityType = 'Event';
            });
            return action.result;
        }

        case 'CustomFieldAnswerActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
