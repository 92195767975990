import {
    Box,
    Icon,
    TimelineItem as MuiTimelineItem,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineSeparator,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import moment from 'moment-timezone';
import React from 'react';
import {
    i18n,
} from '../../../global/constants.js';
import {
    InsightsPersonEngagementCategoryName,
} from '../global/models';
import {
    BEM_NAME,
} from '../constants';

type PropTypes = {
    categoryName: InsightsPersonEngagementCategoryName,
    dateUtc: string,
    eventName?: string | null,
    ministryName?: string | null,
    servingOpportunityName?: string | null,
    title: string,
};

const useStyles = makeStyles(({
    palette,
    spacing,
}) => {
    const contentPadding = [[0, spacing(4)]];

    return {
        colorConnection: {},
        colorEvent: {},
        colorGiving: {},
        colorMinistry: {},
        colorPersonRecord: {},
        colorServingOpportunity: {},
        colorSmallGroup: {},
        root: {
            margin: 0,
            '&::before': {
                padding: contentPadding,
            },
            '&$colorConnection $timelineDot': {
                border: [[1, 'solid', palette.orange[500]]],
                '& .icon-use-path': {
                    fill: palette.orange[500],
                },
            },
            '&$colorEvent $timelineDot': {
                border: [[1, 'solid', palette.purple[600]]],
                '& .icon-use-path': {
                    fill: palette.purple[600],
                },
            },
            '&$colorGiving $timelineDot': {
                border: [[1, 'solid', palette.cyan[500]]],
                '& .icon-use-path': {
                    fill: palette.cyan[500],
                },
            },
            '&$colorMinistry $timelineDot': {
                border: [[1, 'solid', palette.green[500]]],
                '& .icon-use-path': {
                    fill: palette.green[500],
                },
            },
            '&$colorPersonRecord $timelineDot': {
                border: [[1, 'solid', palette.grey[400]]],
                '& .icon-use-path': {
                    fill: palette.grey[400],
                },
            },
            '&$colorServingOpportunity $timelineDot': {
                border: [[1, 'solid', palette.sky[500]]],
                '& .icon-use-path': {
                    fill: palette.sky[500],
                },
            },
            '&$colorSmallGroup $timelineDot': {
                border: [[1, 'solid', palette.redOrange[500]]],
                '& .icon-use-path': {
                    fill: palette.redOrange[500],
                },
            },
        },
        timelineContent: {
            padding: contentPadding,
            paddingBottom: spacing(7),
        },
        timelineDot: {
            boxShadow: 'none',
            margin: 0,
            padding: 7,
        },
        timelineConnector: {
            width: 1,
        },
    };
});

export const TIMELINE_ITEM_BEM_NAME = `${BEM_NAME}--timeline_item`;

function TimelineItem({
    categoryName,
    dateUtc,
    eventName = null,
    ministryName = null,
    servingOpportunityName = null,
    title,
}: PropTypes) {
    const classes = useStyles();

    const rootClasses = ClassNames(
        TIMELINE_ITEM_BEM_NAME,
        classes.root,
        {
            [classes.colorConnection]: categoryName === 'Connection',
            [classes.colorEvent]: categoryName === 'Event',
            [classes.colorGiving]: categoryName === 'Giving',
            [classes.colorMinistry]: categoryName === 'Ministry',
            [classes.colorPersonRecord]: categoryName === 'Person Record',
            [classes.colorServingOpportunity]: categoryName === 'Serving Opportunity',
            [classes.colorSmallGroup]: categoryName === 'Small Group',
        },
    );

    let categoryIconType;
    let subTitle;

    switch (categoryName) {
        case 'Connection':
            categoryIconType = 'replied-successful';

            break;
        case 'Event':
            categoryIconType = 'ticket';
            subTitle = eventName;

            break;
        case 'Giving':
            categoryIconType = 'giving';

            break;
        case 'Ministry':
            categoryIconType = 'ministry';
            subTitle = ministryName;

            break;
        case 'Person Record':
            categoryIconType = 'person-record';

            break;
        case 'Serving Opportunity':
            categoryIconType = 'serving-opportunity';
            subTitle = servingOpportunityName;

            break;
        case 'Small Group':
            categoryIconType = 'people';

            break;
        default:
    }

    return (
        <MuiTimelineItem
            className={rootClasses}
        >
            <TimelineSeparator>
                <TimelineDot
                    classes={{
                        root: classes.timelineDot,
                    }}
                    color="inherit"
                    variant="outlined"
                >
                    <Icon
                        compact
                        size={20}
                        title={i18n(categoryName)}
                        type={categoryIconType}
                    />
                </TimelineDot>

                <TimelineConnector
                    classes={{
                        root: classes.timelineConnector,
                    }}
                />
            </TimelineSeparator>

            <TimelineContent
                classes={{
                    root: classes.timelineContent,
                }}
            >
                <Typography
                    variant="h1"
                    component="h2"
                >
                    {i18n(title)}
                </Typography>

                {subTitle ? (
                    <Typography
                        variant="h4"
                    >
                        {i18n(subTitle)}
                    </Typography>
                ) : null}

                <Typography
                    component="div"
                >
                    <Box
                        alignItems="center"
                        display="inline-flex"
                        fontWeight="fontWeightMedium"
                        mb={1}
                        mt={1}
                    >
                        <Icon
                            size={20}
                            title={i18n('Date')}
                            type="date"
                        />

                        {moment(dateUtc).format('L')}
                    </Box>
                </Typography>

                <Typography>
                    {i18n(categoryName)}
                </Typography>
            </TimelineContent>
        </MuiTimelineItem>
    );
}

export default TimelineItem;
