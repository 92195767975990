import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import { i18n } from '../../../global/constants.js';

export const DEFAULT_TABS_VALUES = Object.freeze({
    engagement: 'engagement',
    ministryAndServing: 'ministryAndServing',
    personal: 'personal',
    smallGroups: 'smallGroups',
});

export const DEFAULT_PERSON_RECORD_TABS = [
    {
        contentComponent: null,
        label: i18n('Personal'),
        permission: USER_PERMISSIONS.bypass,
        removeFromDrawer: false,
        value: DEFAULT_TABS_VALUES.personal,
    }, {
        contentComponent: null,
        label: i18n('Ministry & Serving'),
        permission: USER_PERMISSIONS.bypass,
        removeFromDrawer: false,
        value: DEFAULT_TABS_VALUES.ministryAndServing,
    }, {
        contentComponent: null,
        label: i18n('Small Groups'),
        permission: USER_PERMISSIONS.bypass,
        removeFromDrawer: false,
        value: DEFAULT_TABS_VALUES.smallGroups,
    }, {
        contentComponent: null,
        label: i18n('Engagement'),
        permission: USER_PERMISSIONS.readPersonEngagement,
        removeFromDrawer: true,
        value: DEFAULT_TABS_VALUES.engagement,
    },
];
