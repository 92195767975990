import {
    Box,
    Grid,
    DatePickerInput,
} from '@saddlebackchurch/react-cm-ui';
import {
    isNil,
    startCase,
} from 'lodash';
import {
    Controller,
} from 'react-hook-form';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import moment, { Moment } from 'moment-timezone';
import React from 'react';
import {
    DateISOString,
    TimeOfDayString,
} from '../models';
import TimeDropdown from '../timeDropdown.jsx';
import TimeZoneDropdown from '../timeZoneDropdown.jsx';

type PropTypes = {
    control: any;
    dateFromDataTestId?: string | null;
    dateFromLabel?: string | null;
    dateFromName: string;
    dateToDataTestId?: string | null;
    dateToLabel?: string | null;
    dateToName: string;
    endTimeId?: string | null;
    endTimeLabel?: string | null;
    endTimeName: string;
    getValues: Function;
    infoMessagePrefix?: string | null;
    maxDate?: string | null;
    minDate?: string | null;
    rules?: {
        required?: boolean;
        validate?: any;
    };
    startTimeId?: string | null;
    startTimeLabel?: string | null;
    startTimeName: string;
    timeZoneId?: string | null;
    timeZoneLabel?: string | null;
    timeZonePlaceHolder?: string | null;
    timeZoneToName?: string | null;
};

const useStyles = makeStyles(({
    palette,
    spacing,
    typography,
}) => ({
    infoMessage: {
        backgroundColor: palette.hexToRGBA(palette.sky[500], 0.15),
        fontSize: typography.pxToRem(14),
        padding: spacing(1),
    },
}));

const getHourIndex = (time: string) => {
    const newTime = moment(time, 'hh:mm A').format('HH:mm');

    let result: number;
    const quarterOfHour = 15;
    const quartersInHour = 4;
    const hoursInDay = 24;
    const totalOptions = quartersInHour * hoursInDay; // 96

    for (let i = 0; i <= totalOptions; i += 1) {
        const hours = Math.floor(i / quartersInHour);
        const minutes = (i % quartersInHour) * quarterOfHour;
        const date = moment({ hours, minutes });

        if (newTime === date.format('HH:mm')) {
            result = i;

            break;
        }
    }

    return result;
};

const getHourValue = (index) => {
    let result: string;

    for (let i = 0; i <= index; i += 1) {
        const hours = Math.floor(i / 4);
        const minutes = (i % 4) * 15;
        const date = moment({ hours, minutes });

        if (Number(i) === Number(index)) {
            result = date.format('hh:mm A');

            break;
        }
    }

    return result;
};

const toDateTimeFormat = (date: Moment, time: TimeOfDayString, timeZone: string) => {
    if (isNil(date) || isNil(time) || isNil(timeZone)) {
        return null;
    }

    const dateFormat: DateISOString = moment(date).format('YYYY-MM-DD');

    return moment.tz(`${dateFormat} ${time}`, 'YYYY-MM-DD hh:mm A', timeZone).format('YYYY-MM-DDTHH:mm:ss');
};

function FormFieldDatePickerTimezone({
    control,
    dateFromDataTestId = null,
    dateFromLabel = null,
    dateFromName,
    dateToDataTestId = null,
    dateToLabel = null,
    dateToName,
    endTimeId = null,
    endTimeLabel = null,
    endTimeName,
    getValues,
    infoMessagePrefix = null,
    maxDate = null,
    minDate = null,
    rules = {},
    startTimeId = null,
    startTimeLabel = null,
    startTimeName,
    timeZoneId = null,
    timeZoneLabel = null,
    timeZonePlaceHolder = null,
    timeZoneToName,
}: PropTypes) {
    const [dates, setDates] = React.useState<{
        dateFrom: Moment | null;
        dateTo: Moment | null;
    }>({
        dateFrom: null,
        dateTo: null,
    });
    const dateToRef = React.useRef<any>();
    const starTimeRef = React.useRef<any>();
    const endTimeRef = React.useRef<any>();

    const formattedDateFromLabel = dateFromLabel ?
        startCase(dateFromLabel) :
        null;

    const formattedDateToLabel = dateToLabel ?
        startCase(dateToLabel) :
        null;

    const classes = useStyles();

    const momentMaxDate = maxDate ? moment(maxDate) : null;
    const momentMinDate = minDate ? moment(minDate) : null;

    const onDateFromChange = (onChange, { dateFrom }) => {
        onChange(dateFrom);

        const startTime = getValues(startTimeName);
        const timeZone = getValues(timeZoneToName);
        const newDateFrom: Moment = !isNil(startTime) ?
            moment.tz(toDateTimeFormat(dateFrom, startTime, timeZone), timeZone) :
            dateFrom;

        setDates((prevState) => ({
            dateFrom: newDateFrom,
            dateTo: prevState.dateTo,
        }));
    };

    const onDateToChange = (onChange, { dateTo }) => {
        onChange(dateTo);

        const endTime = getValues(endTimeName);
        const timeZone = getValues(timeZoneToName);
        const newDateTo: Moment = !isNil(endTime) ?
            moment.tz(toDateTimeFormat(dateTo, endTime, timeZone), timeZone) :
            dateTo;

        setDates((prevState) => ({
            dateFrom: prevState.dateFrom,
            dateTo: newDateTo,
        }));
    };

    const onStartTimeChange = (onChange, { value }) => {
        const hourValue = getHourValue(value);

        onChange(hourValue);

        if (!isNil(dates.dateFrom)) {
            const timeZone = getValues(timeZoneToName);
            const newDateFrom: Moment = !isNil(dates.dateFrom) && !isNil(hourValue) ?
                moment.tz(toDateTimeFormat(dates.dateFrom, hourValue, timeZone), timeZone) :
                null;

            setDates((prevState) => ({
                dateFrom: newDateFrom ?? prevState.dateFrom,
                dateTo: prevState.dateTo,
            }));
        }
    };

    const onEndTimeChange = (onChange, { value }) => {
        const hourValue = getHourValue(value);

        onChange(hourValue);

        if (!isNil(dates.dateTo)) {
            const timeZone = getValues(timeZoneToName);
            const newDateTo: Moment = !isNil(dates.dateTo) && !isNil(hourValue) ?
                moment.tz(toDateTimeFormat(dates.dateTo, hourValue, timeZone), timeZone) :
                null;

            setDates((prevState) => ({
                dateFrom: prevState.dateFrom,
                dateTo: newDateTo ?? prevState.dateTo,
            }));
        }
    };

    const onTimeZoneChange = (onChange, selectedOption) => {
        const timeZone = selectedOption?.value ?? null;

        onChange(timeZone);

        const endTime = getValues(endTimeName);
        const startTime = getValues(startTimeName);
        const newDateFrom: Moment = !isNil(dates.dateFrom) && !isNil(startTime) ?
            moment.tz(toDateTimeFormat(dates.dateFrom, startTime, timeZone), timeZone) :
            null;
        const newDateTo: Moment = !isNil(dates.dateTo) && !isNil(endTime) ?
            moment.tz(toDateTimeFormat(dates.dateTo, endTime, timeZone), timeZone) :
            null;

        setDates((prevState) => ({
            dateFrom: newDateFrom ?? prevState.dateFrom,
            dateTo: newDateTo ?? prevState.dateTo,
        }));
    };

    const formattedDateFrom = moment.parseZone(dates.dateFrom).format('MM/DD/YYYY');
    const formattedDateTo = moment.parseZone(dates.dateTo).format('MM/DD/YYYY');

    return (
        <React.Fragment>
            <Grid.Column
                sm={5}
            >
                <Controller
                    control={control}
                    name={dateFromName}
                    render={({
                        field: {
                            onChange,
                            value: controllerValue,
                        },
                    }) => {
                        let value: Moment | null = null;

                        if (dates.dateFrom) {
                            value = dates.dateFrom;
                        } else if (moment.isMoment(controllerValue)) {
                            value = controllerValue;
                        }

                        return (
                            <DatePickerInput
                                dataTestId={dateFromDataTestId}
                                dateFrom={value}
                                dateTo={dates.dateTo ?? getValues(dateToName)}
                                fluid
                                id={dateFromName}
                                label={formattedDateFromLabel}
                                maxDate={momentMaxDate}
                                minDate={momentMinDate}
                                onChange={(dateValues) => onDateFromChange(onChange, dateValues)}
                                rangeFrom
                                required={rules?.required}
                                tabIndex={0}
                            />
                        );
                    }}
                    rules={rules}
                />
            </Grid.Column>

            <Grid.Column
                sm={3}
            >
                <Controller
                    control={control}
                    name={startTimeName}
                    render={({
                        field: {
                            onChange,
                            value: controllerValue,
                        },
                    }) => {
                        const value = !isNil(controllerValue) ?
                            getHourIndex(controllerValue) :
                            undefined;

                        return (
                            <TimeDropdown
                                id={startTimeId}
                                label={startTimeLabel}
                                onChange={(e) => onStartTimeChange(onChange, e)}
                                required={rules?.required}
                                value={value}
                                ref={starTimeRef}
                            />
                        );
                    }}
                    rules={rules}
                />
            </Grid.Column>

            <Grid.Column
                sm={4}
            >
                <Controller
                    control={control}
                    name={timeZoneToName}
                    render={({
                        field: {
                            onChange,
                            value: controllerValue,
                        },
                    }) => (
                        <TimeZoneDropdown
                            id={timeZoneId}
                            label={timeZoneLabel}
                            onChange={(e) => onTimeZoneChange(onChange, e)}
                            placeholder={timeZonePlaceHolder}
                            required={rules?.required}
                            value={controllerValue ?? ''}
                        />
                    )}
                    rules={rules}
                />
            </Grid.Column>

            <Grid.Column
                sm={5}
            >
                <Controller
                    control={control}
                    name={dateToName}
                    render={({
                        field: {
                            onChange,
                            value: controllerValue,
                        },
                    }) => {
                        let value: Moment | null = null;

                        if (dates.dateTo) {
                            value = dates.dateTo;
                        } else if (moment.isMoment(controllerValue)) {
                            value = controllerValue;
                        }

                        return (
                            <DatePickerInput
                                dataTestId={dateToDataTestId}
                                dateFrom={dates.dateFrom ?? getValues(dateFromName)}
                                dateTo={value}
                                fluid
                                id={dateToName}
                                label={formattedDateToLabel}
                                maxDate={momentMaxDate}
                                minDate={momentMinDate}
                                onChange={(dateValues) => onDateToChange(onChange, dateValues)}
                                rangeTo
                                ref={dateToRef}
                                required={rules?.required}
                                tabIndex={0}
                            />
                        );
                    }}
                    rules={rules}
                />
            </Grid.Column>

            <Grid.Column
                sm={3}
            >
                <Controller
                    control={control}
                    name={endTimeName}
                    render={({
                        field: {
                            onChange,
                            value: controllerValue,
                        },
                    }) => {
                        const value = !isNil(controllerValue) ?
                            getHourIndex(controllerValue) :
                            undefined;

                        return (
                            <TimeDropdown
                                id={endTimeId}
                                label={endTimeLabel}
                                onChange={(e) => onEndTimeChange(onChange, e)}
                                required={rules?.required}
                                value={value}
                                ref={endTimeRef}
                            />
                        );
                    }}
                    rules={rules}
                />
            </Grid.Column>

            {infoMessagePrefix && dates.dateFrom && dates.dateTo && dateFromName.includes('filter') ? (
                <Grid.Column>
                    <div
                        className={classes.infoMessage}
                    >
                        {`${infoMessagePrefix} `}

                        <Box
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {`${formattedDateFrom} `}
                        </Box>

                        to

                        <Box
                            component="span"
                            fontWeight="fontWeightBold"
                        >
                            {` ${formattedDateTo}`}
                        </Box>
                    </div>
                </Grid.Column>
            ) : null}

        </React.Fragment>
    );
}

export default FormFieldDatePickerTimezone;
