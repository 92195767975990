export const DATE_TYPE_OPTION_CUSTOM_ID = 'dateTypeOptionCustom';
export const DATE_TYPE_OPTIONS = [
    {
        id: 'dateTypeOption1',
        label: '1 day',
    },
    {
        id: 'dateTypeOption3',
        label: '3 days',
    },
    {
        id: 'dateTypeOption7',
        label: '7 days',
    },
    {
        id: DATE_TYPE_OPTION_CUSTOM_ID,
        label: 'Custom',
    },
];
export const NOTE_REMINDER_DUE_HOUR = 8;
export const NOTE_REMINDER_DUE_TIME = `${NOTE_REMINDER_DUE_HOUR}:00`;
export const NOTE_REMINDER_DATE_FORMAT = 'YYYY-MM-DD';
export const NOTE_REMINDER_DEFAULT_FILTER_VALUE = 'reminder';
export const NOTE_REMINDER_DETAIL_DUE_DATE_FORMAT = 'MMMM Do, YYYY';
// *** These constants will be removed once we work under Person Notes Conversion
export const OPEN_FROM_NOTE_FORM = 'openFromNoteForm';
export const OPEN_FROM_NOTE_FORM_MOBILE = 'openFromNoteFormMobile';
export const OPEN_FROM_NOTE_DETAIL = 'openFromNoteDetail';
export const OPEN_FROM_NOTE_DETAIL_MOBILE = 'openFromNoteDetailMobile';
export const OPEN_FROM_NOTE_FORM_EDIT = 'openFromNoteFormEdit';
export const OPEN_FROM_NOTE_FORM_EDIT_MOBILE = 'openFromNoteFormEditMobile';
// ***
export const WING_TYPE_NOTE_REMINDER_DETAIL = 'note_reminder_detail';
export const WING_TYPE_NEW_REMINDER_FORM = 'new_reminder_form';
