import {
    isEmpty,
    isNil,
    map,
    noop,
} from 'lodash';
import moment from 'moment-timezone';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import { ADD_PERSON_SET_IS_SAVE_DISABLED } from './addPersonTypes.js';
import {
    i18n,
    DOES_NOT_ATTEND_CHURCH_ENTITY,
    UNKNOWN_CHURCH_ENTITY,
} from '../../../global/constants.js';
import AddPersonRecordActions from './addPersonRecordActions.js';
import BannerUtils from '../../../js/utils/BannerUtils.js';
import EmergencyContactActions from '../../emergencyContact/global/emergencyContactActions.js';
import RelationshipActions from '../../emergencyContact/global/relationshipActions.js';
import EmergencyContactFormActions from './emergencyContactFormActions.js';
import PossibleDuplicatesAddPersonActions from './possibleDuplicatesAddPersonActions.js';
import CoreMilestonesActions from './coreMilestones.actions';
import {
    BEM_BLOCK_NAME,
    CHURCH_ENTITY_NAME_UNKNOWN,
} from './addPersonV2Constants.js';

export const getFirstContactMilestone = (campus) => {
    const today = moment().utc().startOf('day').unix();
    const churchEntityId = campus?.value;
    const isUnknownCampus = churchEntityId === UNKNOWN_CHURCH_ENTITY;
    const doesNotAttendCampus = churchEntityId === DOES_NOT_ATTEND_CHURCH_ENTITY;
    const isLocationUnknown = isNil(churchEntityId) || isUnknownCampus || doesNotAttendCampus;

    if (isLocationUnknown) {
        return {
            date: today,
            location: CHURCH_ENTITY_NAME_UNKNOWN,
        };
    }

    return {
        churchEntityId,
        date: today,
    };
};

export const parseEmailForPersonFormat = (email) => ([{
    email,
    isBadContact: false, // it has already been  validated on its input change event
    isPrimary: true,
}]);

const getPersonalFormProcessedData = (personalFormData) => {
    const {
        birthDate,
        firstName,
        grade,
        gender,
        lastName,
        campus,
    } = personalFormData;

    const isUnknownCampus = campus.value === UNKNOWN_CHURCH_ENTITY;
    const doesAttendCampus = !isUnknownCampus && campus.value !== DOES_NOT_ATTEND_CHURCH_ENTITY;
    const firstContactMilestone = getFirstContactMilestone(campus);

    const personalData = {
        birthDate,
        churchEntityId: isUnknownCampus || !doesAttendCampus ?
            null :
            campus.value,
        churchEntityKnown: !isUnknownCampus,
        churchEntityName: campus.label,
        firstContact: firstContactMilestone,
        firstName,
        gender: !isEmpty(gender) ? gender.value : '',
        gradeLevel: !isEmpty(grade) ? grade.value : 'None',
        lastName,
    };

    return personalData;
};

const getContactFormProcessedData = (contactFormData) => {
    const {
        address1,
        address2,
        city,
        country,
        email,
        phone,
        postalCode,
        region,
    } = contactFormData;

    let addresses;
    let contactData = {};
    let emails;
    let phones;

    if (address1 && city && !isEmpty(country) && postalCode) {
        addresses = [{
            address1,
            address2,
            city,
            countryAlpha3: country.value,
            isBadContact: false,
            isPrimary: true,
            postalCode,
            regionCode: region && region.value,
        }];
        contactData = { ...contactData, addresses };
    }

    if (!isEmpty(phone) && phone.phoneNumber && phone.phoneTypeId) {
        phones = [phone];
        contactData = { ...contactData, phones };
    }

    if (email) {
        emails = parseEmailForPersonFormat(email);

        contactData = { ...contactData, emails };
    }

    return contactData;
};

const onSaveEmergencyContacts = (personId, selectedEmergencyContacts) => {
    const emergencyContactPromises = [];
    const relationshipPromises = [];

    return new Promise((resolve) => {
        if (!isEmpty(selectedEmergencyContacts)) {
            map(selectedEmergencyContacts, (emergencyContact, index) => {
                const emergencyContactId = emergencyContact.id;
                const emeregencyContactData = {
                    personId,
                    emergencyContactId,
                    isPrimary: index === 0,
                };
                const relationshipData = {
                    personId,
                    relativeId: emergencyContact.id,
                    relativeFullName: `${emergencyContact.firstName} ${emergencyContact.lastName}`,
                    relativeGender: emergencyContact.gender,
                    relativeBirthdate: emergencyContact.birthDate,
                    relativeMembershipStatus: emergencyContact.membershipStatus,
                    relativeDeceasedDate: emergencyContact.deceasedDate,
                    isAddressMatch: emergencyContact.isAddressMatch || false,
                    relationType: emergencyContact.relationType,
                };
                emergencyContactPromises.push(
                    EmergencyContactActions.save({ personId }, emeregencyContactData),
                );
                relationshipPromises.push(RelationshipActions.create({
                    relationshipId: emergencyContact.id,
                }, relationshipData));
            });

            Promise.all(emergencyContactPromises).then(() => {
                Promise.all(relationshipPromises).then(() => {
                    resolve();
                });
            });
        }
    });
};

const onSavePersonPhoto = (personId, avatarImageUrl) => (
    new Promise((resolve, reject) => {
        fetch(avatarImageUrl).then(
            (imageRes) => imageRes.blob(),
        ).then((blob) => {
            const fd = new FormData();
            fd.append('personId', personId);
            fd.append('file', blob);
            AddPersonRecordActions.savePersonPhoto({
            }, fd).then(() => {
                resolve();
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    })
);

export const onSavePerson = (
    callback = noop,
    additionalPersonInfo = {},
    sourceOverride,
) => {
    const {
        dispatch,
        getState,
    } = appReduxStore;

    const store = getState();

    const {
        people: {
            record: {
                personForm: {
                    avatarImage,
                    contactFormData,
                    personalFormData,
                    recordType,
                },
                emergencyContactForm: {
                    selectedEmergencyContacts,
                },
            },
        },
    } = store;

    const personalProcessedData = getPersonalFormProcessedData(personalFormData);
    const contactProcessedData = getContactFormProcessedData(contactFormData);

    const data = {
        ...personalProcessedData,
        ...(recordType !== 'child' && contactProcessedData),
        ...additionalPersonInfo,
    };

    const preferredContactData = {
        doNotMail: false,
        doNotPhone: false,
        doNotText: false,
        doNotEmail: false,
        doNotContact: false,
    };

    dispatch({
        type: ADD_PERSON_SET_IS_SAVE_DISABLED,
        isPersonSaveDisabled: true,
    });

    const params = {
        ...(sourceOverride && {
            sourceOverride,
        }),
    };

    AddPersonRecordActions.savePerson(params, data).then((res) => {
        const promises = [];
        if (!isEmpty(selectedEmergencyContacts)) {
            promises.push(onSaveEmergencyContacts(res.id, selectedEmergencyContacts));
        }

        if (!isEmpty(avatarImage) && avatarImage.url) {
            promises.push(
                onSavePersonPhoto(res.id, avatarImage.url),
            );
        }

        if (!isEmpty(data) &&
            !isEmpty(data.emails) &&
            data.emails[0].email &&
            isEmpty(data.phones)) {
            promises.push(AddPersonRecordActions.saveContactPreferences({
                personId: res.id,
            }, {
                ...preferredContactData,
                preferredMethod: 'email',
            }));
        } else if (!isEmpty(data) &&
            !isEmpty(data.phones) &&
            data.phones[0].phoneNumber &&
            isEmpty(data.emails)) {
            promises.push(AddPersonRecordActions.saveContactPreferences({
                personId: res.id,
            }, {
                ...preferredContactData,
                preferredMethod: 'phone',
            }));
        }

        Promise.all(promises).then(() => {
            BannerUtils.addBanner({
                id: `${BEM_BLOCK_NAME}--successfully_added_person_banner`,
                level: 'success',
                message: i18n('You’ve successfully created and linked a new record'),
                timeout: true,
                title: i18n('Person record added'),
                type: 'notification',
            });

            dispatch({
                type: ADD_PERSON_SET_IS_SAVE_DISABLED,
                isPersonSaveDisabled: false,
            });

            EmergencyContactFormActions.resetSelectedEmergencyContact();
            AddPersonRecordActions.resetPersonalForm();
            AddPersonRecordActions.resetContactForm();

            callback(res);
        });
    }).catch(() => {
        dispatch({
            type: ADD_PERSON_SET_IS_SAVE_DISABLED,
            isPersonSaveDisabled: false,
        });
    });
};

export const resetAddPersonV2 = () => {
    AddPersonRecordActions.resetPersonPhoto();
    AddPersonRecordActions.resetPersonalForm();
    AddPersonRecordActions.resetContactForm();
    EmergencyContactFormActions.resetSelectedEmergencyContact();
    PossibleDuplicatesAddPersonActions.reset();
    PossibleDuplicatesAddPersonActions.resetPersonalDetails();
    CoreMilestonesActions.resetCoreMilestonesByPersonIds();
};
