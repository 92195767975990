import LoginConstants from '../../constants/Login/LoginConstants';
import adminImportHistory, { DEFAULT_STATE as ADMIN_IMPORT_HISTORY_DEFAULT_STATE } from './Import/adminImportHistory';
import adminImportHistoryCount, { DEFAULT_STATE as ADMIN_IMPORT_HISTORY_COUNT_DEFAULT_STATE } from './Import/adminImportHistoryCount';
import adminImportCurrent, { DEFAULT_STATE as ADMIN_IMPORT_CURRENT_DEFAULT_STATE } from './Import/adminImportCurrent';
import adminImportPreview, { DEFAULT_STATE as ADMIN_IMPORT_PREVIEW_DEFAULT_STATE } from './Import/adminImportPreview';
import adminImportParsingResults, { DEFAULT_STATE as ADMIN_IMPORT_PARSING_RESULTS_DEFAULT_STATE } from './Import/adminImportParsingResults';
import adminImportNav, { DEFAULT_STATE as ADMIN_IMPORT_NAV_DEFAULT_STATE } from './Import/adminImportNav';
import adminImportDataSource, { DEFAULT_STATE as ADMIN_IMPORT_DATA_SOURCE_DEFAULT_STATE } from './Import/adminImportDataSource';
import adminImportFieldMapping, { DEFAULT_STATE as ADMIN_IMPORT_FIELD_MAPPING_DEFAULT_STATE } from './Import/adminImportFieldMapping';
import adminImportParsingCounters, { DEFAULT_STATE as ADMIN_IMPORT_PARSING_COUNTERS_DEFAULT_STATE } from './Import/adminImportParsingCounters';
import adminExportHistoryCount, { DEFAULT_STATE as ADMIN_EXPORT_HISTORY_COUNT_DEFAULT_STATE } from './Export/adminExportHistoryCount';
import adminExportHistory, { DEFAULT_STATE as ADMIN_EXPORT_HISTORY_DEFAULT_STATE } from './Export/adminExportHistory';
import departments, { DEFAULT_STATE as DEPARTMENTS_DEFAULT_STATE } from './Departments/departments';
import searchIndex, { DEFAULT_STATE as SEARCH_INDEX_DEFAULT_STATE } from './SearchIndex';
import teams, { DEFAULT_STATE as TEAMS_DEFAULT_STATE } from './Teams/teams';

const DEFAULT_STATE = {
    adminImportCurrent: ADMIN_IMPORT_CURRENT_DEFAULT_STATE,
    adminImportDataSource: ADMIN_IMPORT_DATA_SOURCE_DEFAULT_STATE,
    adminImportFieldMapping: ADMIN_IMPORT_FIELD_MAPPING_DEFAULT_STATE,
    adminImportHistory: ADMIN_IMPORT_HISTORY_DEFAULT_STATE,
    adminImportHistoryCount: ADMIN_IMPORT_HISTORY_COUNT_DEFAULT_STATE,
    adminImportPreview: ADMIN_IMPORT_PREVIEW_DEFAULT_STATE,
    adminImportNav: ADMIN_IMPORT_NAV_DEFAULT_STATE,
    adminImportParsingResults: ADMIN_IMPORT_PARSING_RESULTS_DEFAULT_STATE,
    adminImportParsingCounters: ADMIN_IMPORT_PARSING_COUNTERS_DEFAULT_STATE,
    adminExportHistoryCount: ADMIN_EXPORT_HISTORY_COUNT_DEFAULT_STATE,
    adminExportHistory: ADMIN_EXPORT_HISTORY_DEFAULT_STATE,
    departments: DEPARTMENTS_DEFAULT_STATE,
    searchIndex: SEARCH_INDEX_DEFAULT_STATE,
    teams: TEAMS_DEFAULT_STATE,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'AdminImportHistoryActions.ON_GETALL':
            return { ...state, adminImportHistory: adminImportHistory(state.adminImportHistory, action) };

        case 'AdminImportHistoryCountActions.ON_GET':
            return { ...state, adminImportHistoryCount: adminImportHistoryCount(state.adminImportHistoryCount, action) };

        case 'AdminImportCurrentActions.ON_GETALL':
            return { ...state, adminImportCurrent: adminImportCurrent(state.adminImportCurrent, action) };

        case 'AdminImportPreviewActions.ON_PREVIEW':
            return { ...state, adminImportPreview: adminImportPreview(state.adminImportPreview, action) };

        case 'AdminImportParsingResultsActions.ON_GET':
        case 'AdminImportParsingResultsActions.ON_RESET':
            return { ...state, adminImportParsingResults: adminImportParsingResults(state.adminImportParsingResults, action) };

        case 'AdminImportNavActions.ON_STEP':
        case 'AdminImportNavActions.ON_SHOW_SELECT_FILE':
        case 'AdminImportNavActions.ON_SELECT_FILE':
        case 'AdminImportNavActions.ON_RESET':
            return { ...state, adminImportNav: adminImportNav(state.adminImportNav, action) };

        case 'AdminImportDataSourceActions.ON_GETALL':
            return { ...state, adminImportDataSource: adminImportDataSource(state.adminImportDataSource, action) };

        case 'AdminImportFieldMappingActions.ON_GET':
            return { ...state, adminImportFieldMapping: adminImportFieldMapping(state.adminImportFieldMapping, action) };

        case 'AdminImportParsingCountersActions.ON__GETPERSONBYSTATUS':
        case 'AdminImportParsingCountersActions.ON__GETPERSONBYSTATUSANDNAME':
        case 'AdminImportParsingCountersActions.ON__GETPERSONBYSTATUSANDACTION':
        case 'AdminImportParsingCountersActions.ON__GETPERSONBYACTION':
            return { ...state, adminImportParsingCounters: adminImportParsingCounters(state.adminImportParsingCounters, action) };

        case 'AdminExportHistoryCountActions.ON_GET':
            return { ...state, adminExportHistoryCount: adminExportHistoryCount(state.adminExportHistoryCount, action) };

        case 'AdminExportHistoryActions.ON_GETALL':
            return { ...state, adminExportHistory: adminExportHistory(state.adminExportHistory, action) };
        case 'AdminDepartmentActions.ON_GETALL':
        case 'AdminDepartmentActions.ON_GETALLBYCAMPUS':
        case 'AdminDepartmentActions.ON_CREATE':
        case 'AdminDepartmentActions.ON_DELETE':
        case 'AdminDepartmentActions.ON_RESET':
            return { ...state, departments: departments(state.departments, action) };

        case 'AdminSearchIndexActions.ON_SEARCH':
        case 'AdminSearchIndexActions.ON_GETTYPES':
        case 'AdminSearchIndexActions.ON_NEXTPAGE':
            return { ...state, searchIndex: searchIndex(state.searchIndex, action) };

        case 'AdminTeamActions.ON_ADDMEMBERS':
        case 'AdminTeamActions.ON_CREATE':
        case 'AdminTeamActions.ON_DELETE':
        case 'AdminTeamActions.ON_GETALL':
        case 'AdminTeamActions.ON_GETALLBYCAMPUS':
        case 'AdminTeamActions.ON_GETMEMBERS':
        case 'AdminTeamActions.ON_GETMEMBERSNEXTPAGE':
        case 'AdminTeamActions.ON_REMOVEMEMBERS':
        case 'AdminTeamActions.ON_RESET':
        case 'AdminTeamActions.ON_UPDATE':
            return { ...state, teams: teams(state.teams, action) };
    }
    return state;
}
