import {
    isEmpty,
    isNil,
    without,
} from 'lodash';
import moment, { isMoment } from 'moment-timezone';
import { TimeZoneString } from '../../../global/models';
import {
    Filters,
    GiverFilterTypeId,
    GiverType,
    SearchParams,
    SearchTypeOption,
} from './models';

export const getFilteredArrayByIsChecked = (
    stateFiltersArr: string[],
    defaultFiltersArr: string[],
    filterId: string,
    isChecked: boolean,
) => {
    let newFilteredArr = [];

    if (isChecked) {
        newFilteredArr = [
            ...stateFiltersArr,
            filterId,
        ];
    } else if (stateFiltersArr.length === 1) {
        newFilteredArr = without(
            defaultFiltersArr,
            filterId,
        );

        if (defaultFiltersArr.length > 2) {
            newFilteredArr = [newFilteredArr[0]];
        }
    } else {
        newFilteredArr = without(
            stateFiltersArr,
            filterId,
        );
    }

    return newFilteredArr;
};

export const getSearchParams = (
    filters: Filters,
    searchValue: string,
    searchType: SearchTypeOption,
) => {
    const searchParams: SearchParams = {};
    const timeZone: TimeZoneString = moment.tz.guess();

    const {
        amountRangeEnd,
        amountRangeStart,
        channelTypes,
        designations,
        giverTypes,
        paymentDateEnd,
        paymentDateStart,
        paymentMethodTypes,
        paymentStatuses,
        sort,
    } = filters;

    if (!isEmpty(searchValue)) {
        if (searchType === SearchTypeOption.People) {
            searchParams.personSearchString = searchValue;
        } else if (searchType === SearchTypeOption.Designations) {
            searchParams.designationSearchString = searchValue;
        } else {
            searchParams.paymentProviderGiftId = searchValue;
        }
    }

    if (!isEmpty(giverTypes) && giverTypes.length === 1) {
        if (giverTypes.includes(GiverFilterTypeId.Guest)) {
            searchParams.giverTypes = GiverType.Guest;
        } else {
            searchParams.giverTypes = GiverType.Registered;
        }
    }

    if (!isEmpty(designations)) {
        const appliedDesignationIds = designations.map((option) => option.value);
        searchParams.designationIds = appliedDesignationIds.join(',');
    }

    if (!isEmpty(channelTypes)) {
        searchParams.channelIds = channelTypes.join(',');
    }

    if (!isEmpty(paymentMethodTypes)) {
        searchParams.paymentMethodTypes = paymentMethodTypes.join(',');
    }

    if (!isEmpty(paymentStatuses)) {
        searchParams.paymentStatuses = paymentStatuses.join(',');
    }

    if (!isNil(amountRangeStart)) {
        searchParams['amountRange.start'] = amountRangeStart;
    }

    if (!isNil(amountRangeEnd)) {
        searchParams['amountRange.end'] = amountRangeEnd;
    }

    if (isMoment(paymentDateEnd)) {
        const paymentDateEndUserTimeZone = moment.tz(paymentDateEnd, timeZone);
        searchParams['dateTimeRangeUtc.end'] = paymentDateEndUserTimeZone.endOf('day').utc().format();
    }

    if (isMoment(paymentDateStart)) {
        const paymentDateStartUserTimeZone = moment.tz(paymentDateStart, timeZone);
        searchParams['dateTimeRangeUtc.start'] = paymentDateStartUserTimeZone.startOf('day').utc().format();
    }

    searchParams.sortQuery = sort.value;

    return searchParams;
};
