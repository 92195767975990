import LoginConstants from '../../../constants/Login/LoginConstants';
import types, { DEFAULT_STATE as TYPES_DEFAULT_STATE } from './types';
import search, { DEFAULT_STATE as SEARCH_DEFAULT_STATE } from './search';

export const DEFAULT_STATE = {
    types: TYPES_DEFAULT_STATE,
    search: SEARCH_DEFAULT_STATE,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'AdminSearchIndexActions.ON_NEXTPAGE':
        case 'AdminSearchIndexActions.ON_SEARCH':
            return { ...state, search: search(state.search, action) };

        case 'AdminSearchIndexActions.ON_GETTYPES':
            return { ...state, types: types(state.types, action) };
    }
    return state;
}
