import PropTypes from 'prop-types';
import { i18n } from '../constants.js';
import { ImageType } from '../models';
import { Group } from './models';

export const GROUP_PROP_TYPES = PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageContainer: PropTypes.shape({
        height: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
        width: PropTypes.string.isRequired,
    }).isRequired,
    required: PropTypes.bool,
    title: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
});

export const GROUPS_PROP_TYPES = PropTypes.arrayOf(GROUP_PROP_TYPES);

const allowedTypes = ['image/png', 'image/jpeg'];

export const fileAllowedTypes = allowedTypes.join();

export const inputAllowedTypes = allowedTypes.join(', ');

export const IMAGE_TYPE = {
    event: 'events',
    webHeader: '1',
    appHeader: '2',
    thumbnail: '3',
    // Journeys promotional
    promotional: 'promotional',
};

export const DEFAULT_IMAGE_GROUPS: Group[] =
  [
      {
          id: ImageType.Desktop,
          title: i18n('Web Header'),
          titleIconType: 'template',
          width: '50%',
          imageContainer: {
              fileName: '',
              height: '780',
              id: `${ImageType.Desktop}--container`,
              src: '',
              width: '2800',
          },
      },
      {
          id: ImageType.Mobile,
          width: '25%',
          title: i18n('Companion App Header'),
          titleIconType: 'phone-cell',
          imageContainer: {
              fileName: '',
              height: '250',
              id: `${ImageType.Mobile}--container`,
              src: '',
              width: '375',

          },
      },
      {
          id: ImageType.Thumbnail,
          width: '25%',
          title: i18n('Thumbnail'),
          titleIconType: false,
          imageContainer: {
              fileName: '',
              height: '120',
              id: `${ImageType.Thumbnail}--container`,
              src: '',
              width: '120',
          },
      },
  ];
