import _ from 'lodash';

export default class PersonEmergencyContactUtils {
    static getPersonPreferredContactMethod(emergencyContact) {
        if (!emergencyContact) {
            return {};
        }

        let preferredContactInfoText;
        let preferredContactInfoMethod;

        const {
            emergencyContactPreference,
            emergencyContactPhones,
            emergencyContactEmails,
        } = emergencyContact;

        const primaryEmail = _.find(emergencyContactEmails, 'isPrimary');
        const primaryPhone = _.find(emergencyContactPhones, 'isPrimary');
        const preferredMethod = emergencyContactPreference?.preferredMethod ?? 'none';

        switch (preferredMethod) {
            case 'email':
                if (!_.isEmpty(primaryEmail) && primaryEmail.email) {
                    preferredContactInfoText = primaryEmail.email;
                    preferredContactInfoMethod = preferredMethod;
                }

                break;
            case 'phone':
                if (!_.isEmpty(primaryPhone) && primaryPhone.phoneNumber) {
                    preferredContactInfoText = primaryPhone.phoneNumber;
                    preferredContactInfoMethod = preferredMethod;
                }

                break;
            case 'text-message':
                if (!_.isEmpty(primaryPhone) && primaryPhone.phoneNumber) {
                    preferredContactInfoText = primaryPhone.phoneNumber;
                    preferredContactInfoMethod = 'text';
                }

                break;
            default:
                if (!_.isEmpty(primaryPhone) && primaryPhone.phoneNumber) {
                    preferredContactInfoText = primaryPhone.phoneNumber;
                } else if (!_.isEmpty(primaryEmail) && primaryEmail.email) {
                    preferredContactInfoText = primaryEmail.email;
                }

                preferredContactInfoMethod = preferredMethod;
        }

        return {
            method: preferredContactInfoMethod,
            text: preferredContactInfoText,
        };
    }

    static getPersonDoNotContactMethods(contactPreferences) {
        const doNotContactMethodList = [];

        if (contactPreferences.doNotEmail ||
            contactPreferences.doNotMail ||
            contactPreferences.doNotPhone ||
            contactPreferences.doNotText
        ) {
            if (contactPreferences.doNotEmail) {
                doNotContactMethodList.push('Email');
            }

            if (contactPreferences.doNotMail) {
                doNotContactMethodList.push('Mail');
            }

            if (contactPreferences.doNotPhone) {
                doNotContactMethodList.push('Phone');
            }

            if (contactPreferences.doNotText) {
                doNotContactMethodList.push('SMS');
            }
        }

        return {
            doNotContact: contactPreferences.doNotContact,
            doNotContactMethodList,
        };
    }
}
