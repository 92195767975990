import { trim } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Image } from '@saddlebackchurch/react-cm-ui';
import Utils from '../../../global/utils/utils.js';

const personAvatarImagePropTypes = {
    person: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        personAvatar: PropTypes.string,
        photoUrl: PropTypes.string,
        profilePhotoUrl: PropTypes.string,
    }).isRequired,
};

function PersonAvatarImage(props) {
    const {
        person: {
            id: personId,
            firstName,
            lastName,
            personAvatar,
            profilePhotoUrl,
            photoUrl,
        },
    } = props;

    const safePersonId = useMemo(() => (personId ?? Utils.getIncreasingUniqueKey()), [personId]);

    const personName = firstName === 'Anonymous' && (Utils.isStringNullOrWhiteSpace(lastName) || lastName === 'Anonymous') ?
        null :
        trim(`${firstName} ${lastName}`);

    return (
        <Image
            key={`person-list-${safePersonId}-image_component`}
            name={personName}
            size={44}
            src={personAvatar || profilePhotoUrl || photoUrl}
            style={{ fontSize: '18px' }}
            type="person"
        />
    );
}

PersonAvatarImage.propTypes = personAvatarImagePropTypes;

export default PersonAvatarImage;
