import _ from 'lodash';

export const DEFAULT_STATE = [];

export default function (state = DEFAULT_STATE, action) {
    if (action.type === 'AdminImportParsingResultsActions.ON_GET') {
        return _.concat([], action.result);
    } if (action.type === 'AdminImportParsingResultsActions.ON_RESET') {
        return DEFAULT_STATE;
    }
    return state;
}
