import { find, isEmpty } from 'lodash';
import { ACTION_SETTING } from '../constants.js';

const isAbleToSaveEmailTemplate = (emailTemplateActionSettings) => {
    const fromEmailActionSetting = find(
        emailTemplateActionSettings,
        (as) => as.name === ACTION_SETTING.FromEmail,
    );

    const replyToEmailActionSetting = find(
        emailTemplateActionSettings,
        (as) => as.name === ACTION_SETTING.ReplyToEmail,
    );

    const emailTemplateActionSetting = find(
        emailTemplateActionSettings,
        (as) => as.name === ACTION_SETTING.EmailTemplateId,
    );

    return (!isEmpty(fromEmailActionSetting) && !!fromEmailActionSetting.value) &&
        (!isEmpty(replyToEmailActionSetting) && !!replyToEmailActionSetting.value) &&
        (!isEmpty(emailTemplateActionSetting) && !!emailTemplateActionSetting.value); // TODO: May need to update this to accomodate Form Auto Reply Email use case where we are once again authoring communication template at the same time (versus separately in newer flows for other Follow Up Rules use cases)
};

export default isAbleToSaveEmailTemplate;
