import { noop } from 'lodash';
import { appReduxStore } from '../../global/configureReduxStore.js';
import {
    parseTabsToSectionalTabsItems,
    resetNavigationItems,
    updateNavigationItems,
} from '../../app/navigation/navigationUtils.jsx';
import {
    NAVIGATION_SET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER,
} from '../../app/navigation/navigationTypes.js';
import { i18n } from '../../global/constants.js';
import {
    EVENTS_CENTRAL_SECTION_EVENT,
    EVENTS_CENTRAL_SECTION_MY_EVENT,
    EVENTS_SECTIONS,
} from './constants.js';
import eventSectionItems from './eventDetailsNavigationItems.js';

export const isPathMyEventsSection = (pathname) => (
    pathname.includes(EVENTS_SECTIONS.myEvents)
);

const getEventNavigationConfig = (event, isMyEvent) => {
    const eventsTitle = isMyEvent ? i18n('My Events') : i18n('All Events');
    const eventSection = isMyEvent ? 'my-dashboard' : 'events-central';
    const previousPath = `/${eventSection}/${isMyEvent ? 'my-events' : 'overview'}`;
    const currentPath = `${previousPath}/${event.id}/overview${isMyEvent ? '/my-events' : ''}`;

    return {
        eventsTitle,
        previousPath,
        currentPath,
    };
};

export const getEventNavigationBreadcrumbs = (event, isMyEvent, router) => {
    const {
        eventsTitle,
        previousPath,
    } = getEventNavigationConfig(event, isMyEvent);

    const eventTertiaryNavigationItems = eventSectionItems(event, isMyEvent);

    const eventNavigationBreadcrumbs = [
        {
            items: [],
            parentItem: {},
            title: eventsTitle,
            onClick: () => {
                resetNavigationItems(true, eventsTitle);
                router.push(previousPath);
            },
        },
        {
            items: eventTertiaryNavigationItems[0].sectionItems,
            parentItem: { segment: isMyEvent ? 'my-stuff' : 'events-central' },
            title: event.name,
        },
    ];

    return eventNavigationBreadcrumbs;
};

export const closeEventPage = (props) => {
    const {
        params: { id },
        pathname,
        router,
    } = props;

    const isMyEvent = isPathMyEventsSection(pathname);

    const eventSubsection = isMyEvent ?
        EVENTS_CENTRAL_SECTION_MY_EVENT :
        EVENTS_CENTRAL_SECTION_EVENT;

    const eventSection = isMyEvent ? 'my-dashboard' : 'events-central';

    router.push(`/${eventSection}/${eventSubsection}/${id}/overview`);
};

const updateEventNavigationItems = (event, router, isMyEvent) => {
    const {
        eventsTitle,
        previousPath,
        currentPath,
    } = getEventNavigationConfig(event, isMyEvent);

    const nextNavigationHeaderBreadcrumbs = [
        {
            to: previousPath,
            title: eventsTitle,
            onBreadcrumbClick: () => {
                resetNavigationItems(true, eventsTitle);
                router.push(previousPath);
            },
        }, {
            to: currentPath,
            title: event.name,
            onBreadcrumbClick: noop,
        },
    ];

    const eventTertiaryNavigationItems = eventSectionItems(event, isMyEvent);
    const nextSectionalTabs = parseTabsToSectionalTabsItems(
        eventTertiaryNavigationItems[0].sectionItems, router.push,
    );

    const nextNavigationBreadcrumbs = getEventNavigationBreadcrumbs(event, isMyEvent, router);

    updateNavigationItems(
        nextSectionalTabs,
        nextNavigationHeaderBreadcrumbs,
        nextNavigationBreadcrumbs,
    );
};

export const updateEventNavigationBeforeChangeHandler =
    (sectionalTabsOnBeforeChange) => {
        const { dispatch } = appReduxStore;

        dispatch({
            type: NAVIGATION_SET_SECTIONAL_TABS_BEFORE_CHANGE_HANDLER,
            sectionalTabsOnBeforeChange,
        });
    };

export default updateEventNavigationItems;
