import {
    Drawer,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';
import { i18n } from '../../../global/constants.js';
import PeopleSearch from '../peopleSearch.jsx';

type PropTypes = {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
};

const useStyles = makeStyles({
    root: {
        '& .drawer-container-inner': {
            minHeight: '100%',
        },
    },
    activityIndicator: {
        marginTop: '50%',
        left: 'calc(50% - 35px)',
        position: 'absolute',
    },
});

function PersonSearchDrawer({
    isOpen,
    onClose,
    title = i18n('Person Search'),
}: PropTypes) {
    const classes: {
        root: string;
    } = useStyles();

    return (
        <Drawer
            className={classes.root}
            isOpen={isOpen}
        >
            <Drawer.TitleBar
                closeButton={(
                    <Icon
                        compact
                        onClick={onClose}
                        type="times"
                    />
                )}
                title={title}
            />

            <PeopleSearch
                isDrawerChild
                onDrawerClose={onClose}
            />
        </Drawer>
    );
}

export default PersonSearchDrawer;
