import _ from 'lodash';

export const DEFAULT_STATE = {
    personDuplicates: [],
    groupsCount: 0,
    recordsCount: 0,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'PersonDuplicateActions.RESET':
            return DEFAULT_STATE;

        case 'PersonDuplicateActions.ON_GET':
        case 'PersonActions.ON_GETDUPLICATES': {
            const newState = {};
            if (action.callbackParams && (action.callbackParams.mode === 'group' || action.callbackParams.mode === 'group-callback')) {
                newState.groupsCount = 1;
                newState.recordsCount = action.result.duplicates.length;
                action.result.duplicates = [{
                    duplicates: action.result.duplicates,
                    referenceId: +action.params.personId,
                }];
            }
            _.forEach(action.result.duplicates, (group) => {
                group.duplicates = _.sortBy(group.duplicates, (person) => (person.classification === 'Self' ? Number.MIN_SAFE_INTEGER :
                    (person.isRecommended ? Number.MIN_SAFE_INTEGER + 1 : -person.relevance)));
            });
            newState.personDuplicates = action.result.duplicates;
            return _.assign({}, state, newState);
        }

        case 'PersonDuplicateActions.ON__GETSTATISTICS': {
            return _.assign({}, state, action.result);
        }
    }

    return state;
}
