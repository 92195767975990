import React from 'react';
import {
    Icon,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { makeStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import { get } from 'lodash';
import { getNoteReminderDueTimeInfo } from './noteRemindersUtils';
import { i18n } from '../../../../global/constants.js';
import Utils from '../../../../global/utils/utils.js';
import {
    NoteReminderFormData,
    NoteReminder,
} from './models';

interface PropTypes {
    // eslint-disable-next-line react/no-unused-prop-types
    isInsideTextArea: boolean;
    reminderData: NoteReminder & NoteReminderFormData;
    onClick: () => void;
    renderedFrom: string;
    hasCreateReminderButtonBeenClicked: boolean;
    disableLabel: boolean | null;
    // eslint-disable-next-line react/no-unused-prop-types
    isMobile: boolean | null;
    // eslint-disable-next-line react/no-unused-prop-types
    shouldDisableOnClick: boolean;
    defaultColor: string;
}

const useStyles = makeStyles((theme) => ({
    noteReminderHandler: {
        cursor: ({ shouldDisableOnClick }) => (shouldDisableOnClick ? 'auto' : 'pointer'),
        marginBottom: ({ isInsideTextArea }) => isInsideTextArea && -40,
        padding: ({ renderedFrom, isMobile }) => {
            let paddingString;
            if (renderedFrom.includes('noteForm')) { // noteForm/noteFormEdit
                paddingString = '10px 10px 10px 10px';
            } else if (renderedFrom === 'noteDetailMobile') {
                paddingString = '33px 0 10px 0px';
            } else { // comes from noteDetail
                paddingString = isMobile ? '0 0 10px 0' : '0';
            }
            return paddingString;
        },
        position: ({ isInsideTextArea }) => isInsideTextArea && 'relative',
        top: ({ isInsideTextArea }) => isInsideTextArea && -40,
        width: '100%',
        '& .container-with-label': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '& .MuiTypography-root': {
            display: 'inline',
            fontSize: get(theme, 'typography.pxToRem', () => {})(12),
            color: get(theme, 'palette.grey[400]'),
        },
        '& .icon': {
            marginRight: 5,
            cursor: 'pointer',
            '& title': {
                backgroundColor: 'blue',
            },
        },
    },
}));

function NoteReminderIcon(props: PropTypes) {
    const {
        defaultColor,
        disableLabel,
        hasCreateReminderButtonBeenClicked,
        onClick,
        reminderData,
        renderedFrom,
    } = props;

    const classes = useStyles(props);
    let label = i18n('Reminder');
    let iconTitle = false;
    let iconColor = defaultColor || 'primary';
    const reminderId = get(reminderData, 'id');
    const reminderDueTimeInfo = getNoteReminderDueTimeInfo(reminderData);
    const remainingDays = get(reminderDueTimeInfo, 'remainingDays');
    const reminderExists = hasCreateReminderButtonBeenClicked || reminderId;
    iconColor = (reminderExists && !defaultColor) ? 'highlight' : iconColor;
    const shouldChangeLabel = reminderExists && remainingDays > 0;

    if (shouldChangeLabel) {
        const remainingDaysSufix = remainingDays > 1 ? i18n('Days') : i18n('Day');
        label = Utils.format(i18n('Reminder in {0} {1}'), remainingDays, remainingDaysSufix);
    } else if (remainingDays === 0) {
        label = i18n('Reminder today');
    }

    // displays the tooltip only for the note detail in list
    if (renderedFrom === 'noteDetail') {
        iconTitle = label;
    }

    let taskIcon = (
        <Icon
            color={iconColor}
            title={iconTitle}
            type="clock-period"
        />
    );

    if (!disableLabel) {
        taskIcon = (
            <div className="container-with-label">
                {taskIcon}
                <Typography variant="body2">
                    {label}
                </Typography>
            </div>
        );
    }

    return (
        <div
            id="note-reminder-icon-container"
            className={classes.noteReminderHandler}
            role="presentation"
            onClick={onClick}
        >
            {taskIcon}
        </div>
    );
}

export default NoteReminderIcon;
