import moment from 'moment-timezone';
import request from 'axios';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import ApiUtils from '../../../global/apiUtils.js';

class RegionActions extends ApiUtils {
}

const actions = new RegionActions('/api/church-entity-region/');

actions.buildReduxActionClass(
    {
        name: 'getAll',
        rq: request.get,
        route: '',
    },
);

export default actions;
