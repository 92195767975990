import _ from 'lodash';

const DEFAULT_STATE = {
    isFetching: false,
    member: {},
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'MinistryMemberActions.ON_BEFORE_GETMEMBERDETAILS':
        case 'MinistryMemberActions.ON_BEFORE_GETMEMBERDETAILSBYPERSONID':
            return { ...state, isFetching: true };

        case 'MinistryMemberActions.ON_GETMEMBERDETAILS':
            return {
                ...state,
                isFetching: false,
                member: actionResult,
            };

        case 'MinistryMemberActions.ON_GETMEMBERDETAILSBYPERSONID':
            return {
                ...state,
                isFetching: false,
                member: !_.isEmpty(actionResult.members) ?
                    actionResult.members[0] :
                    {},
            };

        case 'MinistryMemberActions.ON_RESET_MEMBER_DETAILS':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};
