import {
    difference,
    isFinite,
    isNil,
    map,
} from 'lodash';
import { doesConditionRequireParameter } from './conditionUtils.js';
import Utils from '../../../global/utils/utils.js';

export const getRemainingValidAttributes = (
    domainSpecificLogic,
    entity,
    metadata,
    existingAttributeConditions,
) => {
    const allowedAttributes = domainSpecificLogic.getAllowedAttributes(
        entity,
        metadata,
    );

    const alreadyUsedAttributes = map(
        existingAttributeConditions,
        (ac) => ac.attribute,
    );

    return difference(allowedAttributes, alreadyUsedAttributes);
};

export const isRuleAttributeConditionValid = (attributeCondition) => {
    const {
        attribute,
        condition,
        parameter,
        entityType,
        entityId,
        isParameterIncomplete,
    } = attributeCondition;

    let isValid = !isNil(attribute) && !isNil(condition);

    if (!isValid) {
        return false;
    }

    const conditionRequiresRightHandSide = doesConditionRequireParameter(condition);

    if (conditionRequiresRightHandSide) {
        const hasParameter = !Utils.isStringNullOrWhiteSpace(parameter);
        const hasEntityReference = !isNil(entityType) &&
            isFinite(entityId);

        isValid = hasParameter || hasEntityReference;

        if (isValid) {
            isValid = !isParameterIncomplete;
        }
    }

    return isValid;
};
