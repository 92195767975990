import {
    cloneDeep,
    find,
    forEach,
} from 'lodash';
import { MESSAGE_TYPE_OPTIONS } from '../actions/notificationTemplateConstants.js';

const DEFAULT_STATE = cloneDeep(MESSAGE_TYPE_OPTIONS);

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'NotificationTemplateActions.ON_GET': {
            const result = cloneDeep(state);
            const { params: { name: value } } = action;

            forEach(result, (messages) => {
                const entry = find(messages, { value });

                if (entry) {
                    entry.text = action.result.content;
                }
            });

            return result;
        }

        case 'NotificationTemplateActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
}
