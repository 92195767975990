import request from 'axios';
import ApiUtils from '../../../../global/apiUtils.js';

class PersonMilestoneAPIUtils extends ApiUtils {
}

const personMilestoneAPIUtils = new PersonMilestoneAPIUtils(
    '/api/person/',
);

personMilestoneAPIUtils.buildClass(
    { name: 'get', rq: request.get, route: '{personId}/milestone' },
    { name: 'create', rq: request.post, route: '{personId}/milestone' },
    { name: 'update', rq: request.put, route: '{personId}/milestone/{milestoneId}' },
    { name: 'delete', rq: request.delete, route: '{personId}/milestone/{dateId}' },
);

export default personMilestoneAPIUtils;
