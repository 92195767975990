import _ from 'lodash';
import { ID_ALL_AGE_GROUPS } from '../../constants/Admin/AdminWorshipServiceSearchConstants.js';

export const DEFAULT_STATE = {};

const transformServiceMetrics = (serviceMetrics) => {
    const transformedServiceMetrics = {};

    if (!_.isEmpty(serviceMetrics)) {
        const venueGroups = _.groupBy(serviceMetrics, 'venueId');

        _.forEach(venueGroups, (venueGrp, venueId) => {
            transformedServiceMetrics[venueId] = {};
            const transformedVenueGroup = transformedServiceMetrics[venueId];
            const venueMetricGroups = _.groupBy(venueGrp, 'metricCategoryId');

            _.forEach(venueMetricGroups, (venueMetricGrp, venueMetricGrpKey) => {
                const { metricCategory, metricCategoryId } = venueMetricGrp[0];
                transformedVenueGroup[venueMetricGrpKey] = {
                    ageGroups: {},
                    category: {
                        label: metricCategory,
                        value: metricCategoryId,
                    },
                };

                const transformedMetricGroup = transformedVenueGroup[venueMetricGrpKey];

                _.forEach(venueMetricGrp, (metric) => {
                    if (metric.metricAgeGroupId === null) {
                        transformedMetricGroup.ageGroups[ID_ALL_AGE_GROUPS] = {};
                    } else {
                        transformedMetricGroup.ageGroups[metric.metricAgeGroupId] = {
                            id: metric.id,
                            sortOrder: metric.sortOrder,
                        };
                    }
                });
            });
        });
    }

    return transformedServiceMetrics;
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'WorshipServiceMetricsActions.ON_GETALL':
            return transformServiceMetrics(action.result);

        case 'WorshipServiceMetricsActions.ON_ADD_METRIC':
        {
            const { metricKey, venueId } = action.value;
            const updatedServiceMetrics = _.cloneDeep(state);
            let venueMetrics = updatedServiceMetrics[venueId];

            if (_.isNil(venueMetrics)) {
                updatedServiceMetrics[venueId] = {};
                venueMetrics = updatedServiceMetrics[venueId];
            }

            venueMetrics[metricKey] = { ageGroups: {}, category: null };
            venueMetrics[metricKey].ageGroups[ID_ALL_AGE_GROUPS] = {};

            return updatedServiceMetrics;
        }

        case 'WorshipServiceMetricsActions.ON_CHANGE_METRIC_CATEGORY':
        {
            const { metricKey, selectedCategoryOption, venueId } = action.value;
            const updatedServiceMetrics = _.cloneDeep(state);
            const venueMetric = updatedServiceMetrics[venueId][metricKey];
            venueMetric.category = { ...selectedCategoryOption };

            return updatedServiceMetrics;
        }

        case 'WorshipServiceMetricsActions.ON_REMOVE_METRIC':
        {
            const { metricKey, venueId } = action.value;
            const updatedServiceMetrics = _.cloneDeep(state);
            const venueMetrics = updatedServiceMetrics[venueId];
            delete venueMetrics[metricKey];

            return updatedServiceMetrics;
        }

        case 'WorshipServiceMetricsActions.ON_REMOVE_ALL_METRICS_FOR_VENUE':
        {
            const { venueId } = action.value;
            const updatedServiceMetrics = _.cloneDeep(state);
            updatedServiceMetrics[venueId] = [];

            return updatedServiceMetrics;
        }

        case 'WorshipServiceMetricsActions.ON_CHANGE_METRIC_AGE_GROUP':
        {
            const {
                ageGroupId, isSelected, metricKey, venueId,
            } = action.value;
            const updatedServiceMetrics = _.cloneDeep(state);
            const venueMetric = updatedServiceMetrics[venueId][metricKey];

            if (ageGroupId != ID_ALL_AGE_GROUPS &&
                venueMetric.ageGroups[ID_ALL_AGE_GROUPS]) {
                return state;
            }

            if (isSelected) {
                if (ageGroupId == ID_ALL_AGE_GROUPS) {
                    venueMetric.ageGroups = {};
                }

                venueMetric.ageGroups[ageGroupId] = {};
            } else {
                delete venueMetric.ageGroups[ageGroupId];
            }

            return updatedServiceMetrics;
        }
    }

    return state;
}
