import moment from 'moment-timezone';
import {
    Agreement,
} from '../../../models/agreement.model';
import {
    i18n,
} from '../../../../global/constants.js';

// eslint-disable-next-line import/prefer-default-export
export const formatVersion = (
    agreement: Agreement,
    latest: boolean = true,
    showName: boolean = false,
) => {
    const date = moment.utc(agreement.createDate).format('MMM Do, YYYY');
    const suffix = latest ? `(${i18n('latest')})` : '';

    if (showName) {
        return `${agreement.name} | v${agreement.version} ${suffix} | ${date}`;
    }

    return `v${agreement.version} ${suffix} - ${date}`;
};
