import {
    AppBar,
    Box,
    Breadcrumbs,
    Grid,
    SectionalTabs,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import {
    every,
    isNil,
    noop,
    some,
} from 'lodash';
import { connect } from 'react-redux';
import Classnames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React, {
    useEffect,
    useRef,
} from 'react';
import {
    hasUserPermission,
} from '../../global/userPermission.js';
import {
    USER_PERMISSIONS,
} from '../../global/userPermissionConstants.js';
import { PATHS_WITH_HEADER_BREADCRUMBS } from '../navigation/navigationUtils.jsx';
import {
    OnUserMenuChange,
} from './models';
import FeatureToggleStore from '../../global/featureToggleStore.js';
import PersonSearchInput from './personSearchInput';
import QuickNotification from './quickNotification';
import Sticky from '../../global/sticky.jsx';
import UserAccessStore from '../../global/userAccessStore.js';
import UserIdentityStore from '../../global/userIdentityStore';
import UserMenu from './userMenu';

type PropTypes = {
    appHeaderTitle?: string | null;
    featureToggles: FeatureToggleStore;
    navigationHeaderBreadcrumbs: object[];
    onUserMenuChange: OnUserMenuChange;
    router: {
        location: {
            pathname?: string | null;
        };
    };
    sectionalTabsItems: {
        accessRequires?: {
            anyPermissions?: boolean;
            churchEntityId?: number;
            eventId?: number;
            ministryId?: number;
            permission?: string; // change to enum one day.
            feature?: string[];
            connectionFormTemplateId?: number;
        };
        key: string;
        onClick: () => void;
        title: string;
        to: string;
    }[];
    sectionalTabsOnBeforeChange?: () => void;
    selectedSectionalTab?: string | null;
    setMenuDesktopWidth: (clientWidth: number) => void;
    setSelectedSectionalTab: (selectedTabKey: number) => void;
    userAccess: UserAccessStore;
    userIdentity: UserIdentityStore;
    userMenuDesktopWidth: number;
};

const mapStateToProps = (state) => {
    const {
        appHeader,
        bootstrap: {
            featureToggles,
            securityContext: {
                userAccess,
                userIdentity,
            },
        },
        navigation: {
            navigationHeaderBreadcrumbs,
        },
    } = state;

    return {
        appHeaderTitle: appHeader.appHeaderTitle,
        featureToggles,
        navigationHeaderBreadcrumbs,
        userAccess,
        userIdentity,
    };
};

const useStyles = makeStyles(() => ({
    rightColumn: {
        position: 'absolute',
        right: 22,
        height: 50,
        display: 'flex',
        alignItems: 'center',
    },
}));

export function AppHeaderDesktop({
    appHeaderTitle = null,
    featureToggles,
    navigationHeaderBreadcrumbs,
    onUserMenuChange,
    router,
    sectionalTabsItems,
    sectionalTabsOnBeforeChange = noop,
    selectedSectionalTab = null,
    setMenuDesktopWidth,
    setSelectedSectionalTab,
    userAccess,
    userIdentity,
    userMenuDesktopWidth,
}: PropTypes) {
    const classes = useStyles();
    const rightColumnRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const rightColumnNode = rightColumnRef.current;

        if (rightColumnNode?.clientWidth) {
            setMenuDesktopWidth(rightColumnNode.clientWidth);
        }
    }, [
        rightColumnRef,
        setMenuDesktopWidth,
    ]);

    const pathname = router?.location?.pathname;

    const shouldUseBreadcrumbsAsTitle = navigationHeaderBreadcrumbs.length > 0 && some(
        PATHS_WITH_HEADER_BREADCRUMBS,
        (pathWithHeaderBreadcrumbs) => pathname.startsWith(pathWithHeaderBreadcrumbs),
    );

    const allowedSectionalTabs = sectionalTabsItems
        .filter(({ accessRequires: tabAccessRequires }) => {
            if (isNil(tabAccessRequires)) {
                return true;
            }

            const {
                anyPermissions,
                churchEntityId,
                eventId,
                ministryId,
                permission,
                feature,
                connectionFormTemplateId,
            } = tabAccessRequires;

            const hasPermission = hasUserPermission({
                anyPermissions,
                churchEntityId,
                eventId,
                hasAnyMinistries: false,
                ministryId,
                permission,
                userAccess,
                userIdentity,
                connectionFormTemplateId,
            });

            let isFeatureEnabled = true;

            if (!isNil(feature)) {
                isFeatureEnabled = every(feature, (f) => featureToggles.isFeatureEnabled(f));
            }

            return hasPermission && isFeatureEnabled;
        });

    const appHeaderDesktopClasses = Classnames(
        'app-header',
        'app-header-desktop',
    );

    const hasAccessPersonSearch = userAccess.hasPermission(
        USER_PERMISSIONS.accessPersonSearch,
    );

    return (
        <Sticky className={appHeaderDesktopClasses}>
            <AppBar>
                <Box
                    width={`calc(100% - ${userMenuDesktopWidth + 11}px)`}
                >
                    <div>
                        {shouldUseBreadcrumbsAsTitle ? (
                            <Breadcrumbs
                                router={router}
                                staticCrumbs={navigationHeaderBreadcrumbs}
                                titlesMaxLength={100}
                            />
                        ) : (
                            <Typography
                                variant="h4"
                                className="app-header-desktop--title"
                            >
                                {appHeaderTitle}
                            </Typography>
                        )}

                        {allowedSectionalTabs.length > 0 && (
                            <div className="sectional-tabs-container">
                                <SectionalTabs
                                    beforeChange={sectionalTabsOnBeforeChange}
                                    items={allowedSectionalTabs}
                                    onChange={(selectedTabKey) => {
                                        setSelectedSectionalTab(selectedTabKey);
                                    }}
                                    resizeThrottle={10}
                                    selectedTabKey={selectedSectionalTab}
                                />
                            </div>
                        )}
                    </div>
                </Box>

                <div
                    className={classes.rightColumn}
                    ref={rightColumnRef}
                >
                    <Grid
                        spacing={1}
                    >
                        {hasAccessPersonSearch ? (
                            <Grid.Column>
                                <PersonSearchInput />
                            </Grid.Column>
                        ) : null}

                        <Grid.Column>
                            <UserMenu
                                onUserMenuChange={onUserMenuChange}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <QuickNotification />
                        </Grid.Column>
                    </Grid>
                </div>
            </AppBar>
        </Sticky>
    );
}

export default connect(mapStateToProps)(AppHeaderDesktop);
