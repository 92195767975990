import React, {
    useState,
} from 'react';
import {
    Dropdown,
    DatePickerInput,
    Button,
    Grid,
    Header,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import { makeStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import {
    get,
    noop,
} from 'lodash';
import moment from 'moment-timezone';
import {
    ResetReminderForm,
    resetReminderForm,
    SetHasCreateReminderButtonBeenClicked,
    setHasCreateReminderButtonBeenClicked,
    SetIsReminderFormModalOpen,
    setIsReminderFormModalOpen,
    SetReminderFormData,
    setReminderFormData,
} from './noteReminderActions';
import {
    DATE_TYPE_OPTIONS,
    DATE_TYPE_OPTION_CUSTOM_ID,
} from './noteReminderConstants';
import { i18n } from '../../../../global/constants.js';
import NoteReminderOpenSource from './models/NoteReminderOpenSource.model';
import {
    AddToReminderToNote,
} from '../models/PersonNotesDrawer.types';
import NoteReminderFormData from './models/NoteReminderFormData.model';
import { PersonNote } from '../models';
import {
    NoteReminderMode,
    ReminderFormField,
} from './models';

interface PropTypes {
    addReminderToNote: AddToReminderToNote;
    isMobile: boolean | null;
    onCancelClick: () => void;
    onCreateClick: () => void;
    reminderFormData: NoteReminderFormData;
    reminderModalOpenFrom: NoteReminderOpenSource;
    resetReminderForm: ResetReminderForm;
    selectedNote: PersonNote;
    setHasCreateReminderButtonBeenClicked: SetHasCreateReminderButtonBeenClicked;
    setIsReminderFormModalOpen: SetIsReminderFormModalOpen;
    setReminderFormData: SetReminderFormData;
}

const mapStateToProps = (state) => {
    const {
        people: {
            record: {
                noteForm: {
                    reminderFormData,
                    selectedReminder,
                    reminderModalOpenFrom,
                    selectedNote,
                },
            },
        },
    } = state;

    return {
        reminderFormData,
        selectedReminder,
        reminderModalOpenFrom,
        selectedNote,
    };
};

const mapDispatchToProps = {
    resetReminderForm,
    setHasCreateReminderButtonBeenClicked,
    setIsReminderFormModalOpen,
    setReminderFormData,
};

const useStyles = makeStyles((theme) => ({
    newReminder: {
        width: '100%',
        marginBottom: ({ isDropDownOpen, dateOptionId }) => (
            isDropDownOpen && dateOptionId !== DATE_TYPE_OPTION_CUSTOM_ID ? 80 : 0
        ),
        '& .input-icon': {
            width: '100%',
        },
        '& .dropdown .icon': {
            marginLeft: 'auto !important',
        },
        '& .dropdown-button': {
            padding: '0 11px !important',
            '& .dropdown-menu': {
                width: '100%',
            },
        },
        '& .MuiTypography-body2': {
            color: get(theme, 'palette.grey[400]'),
        },
    },
}));

const dropDownOptions = DATE_TYPE_OPTIONS.map((op) => (
    <Dropdown.Item id={op.id} key={op.id} label={op.label} />
));

export function NoteReminderForm(props: PropTypes) {
    const {
        addReminderToNote,
        isMobile,
        onCancelClick = noop,
        onCreateClick = noop,
        reminderFormData,
        resetReminderForm: resetReminderFormAction,
        reminderModalOpenFrom,
        selectedNote,
        setHasCreateReminderButtonBeenClicked: setHasCreateReminderButtonBeenClickedAction,
        setIsReminderFormModalOpen: setIsReminderFormModalOpenAction,
        setReminderFormData: setReminderFormDataAction,
    } = props;

    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const dateTypeOption = get(reminderFormData, 'dateTypeOption');
    const customDate = get(reminderFormData, 'customDate');
    const shouldShowDatePicker = get(dateTypeOption, 'id', '') === DATE_TYPE_OPTION_CUSTOM_ID;

    const setSelectedDate = (date) => {
        setReminderFormDataAction(ReminderFormField.CustomDate, date);
    };

    const setSelectedOption = (option) => {
        setReminderFormDataAction(ReminderFormField.DateTypeOption, option);
    };

    const onInnerCancelClick = () => {
        onCancelClick();
        resetReminderFormAction();
        setHasCreateReminderButtonBeenClickedAction(false);
    };

    const onInnerCreateClick = () => {
        onCreateClick();
        setIsReminderFormModalOpenAction(false);
        setHasCreateReminderButtonBeenClickedAction(true);

        if (reminderModalOpenFrom === 'openFromNoteDetail' ||
            reminderModalOpenFrom === 'openFromNoteDetailMobile') {
            addReminderToNote(
                selectedNote,
                { dateTypeOption, customDate },
                null,
                NoteReminderMode.update,
            );
            resetReminderFormAction();
        }
    };

    const dateOptionId = get(dateTypeOption, 'id');
    const shouldDisableCreateButton = !dateOptionId ||
        (dateOptionId === DATE_TYPE_OPTION_CUSTOM_ID && !customDate);
    const typographyContent = i18n('Give your future self a helping hand by setting a reminder for your note. Reminders are private, so only you can see them.');

    const classes = useStyles({
        ...props,
        isDropDownOpen,
        dateOptionId,
    });

    return (
        <div className={classes.newReminder}>
            <Grid columns={1} spacing={2}>
                <Grid.RowDeprecated>
                    <Grid.Column>
                        <Header size="xsmall" color="static">
                            {i18n('Set Reminder For')}
                            &nbsp;
                            <span className="color-alert">*</span>
                        </Header>
                        <Dropdown
                            button
                            buttonColor="outline"
                            collapseMenuOnChange
                            iconPosition="right"
                            onChange={setSelectedOption}
                            placeholder={i18n('Select')}
                            style={{ width: '100%' }}
                            theme="dark"
                            value={dateTypeOption}
                            onClose={() => { setIsDropDownOpen(false); }}
                            onOpen={() => { setIsDropDownOpen(true); }}
                        >
                            {dropDownOptions}
                        </Dropdown>
                    </Grid.Column>
                </Grid.RowDeprecated>
                {shouldShowDatePicker && (
                    <Grid.RowDeprecated>
                        <Grid.Column>
                            <Header size="xsmall" color="static">
                                {i18n('Date')}
                                &nbsp;
                                <span className="color-alert">*</span>
                            </Header>
                            <DatePickerInput
                                style={{
                                    width: '100%',
                                }}
                                minDate={moment().add(1, 'days')} // from tomorrow and beyond
                                onChange={({ date }) => {
                                    setSelectedDate(date);
                                }}
                                date={customDate}
                            />
                        </Grid.Column>
                    </Grid.RowDeprecated>
                )}
                <Grid.RowDeprecated>
                    <Grid.Column>
                        <Typography variant="body2">
                            {typographyContent}
                        </Typography>
                    </Grid.Column>
                </Grid.RowDeprecated>
                {!isMobile && ( // mobile actions appear inside drawer actions bar
                    <Grid.RowDeprecated>
                        <Grid.Column textAlign="right">
                            <Button
                                color="alternate"
                                id="note-reminder--button_cancel"
                                onClick={onInnerCancelClick}
                            >
                                {i18n('Cancel')}
                            </Button>
                            <Button
                                color="success"
                                disable={shouldDisableCreateButton}
                                id="note-reminder--button_create"
                                onClick={onInnerCreateClick}
                            >
                                {i18n('Create')}
                            </Button>
                        </Grid.Column>
                    </Grid.RowDeprecated>
                )}
            </Grid>
        </div>
    );
}

export const NoteReminderFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NoteReminderForm) as any;
