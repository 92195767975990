import {
    MinistryCommunicationTemplate,
} from '../../global/api/core/models';
import ActionType from './communicationTemplates.actionTypes';

export const DEFAULT_STATE: MinistryCommunicationTemplate[] = [];

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ActionType.SetCommunicationTemplates:
            return [
                ...action.value,
            ];

        case ActionType.Reset:
            return DEFAULT_STATE;

        default:
            return state;
    }
};
