/**
* This enumeration contains the Action Type string constants for filters on shared giving account list
*/
enum ActionType {
    RESET_FILTERS = 'SharedGivingAccountListActions.ON_RESET_FILTERS',
    SET_APPLY_FILTERS = 'SharedGivingAccountListActions.ON_APPLY_FILTERS',
    SET_CLEAR_FILTERS = 'SharedGivingAccountListActions.ON_CLEAR_FILTERS',
    SET_APPLY_SORT_ORDER = 'SharedGivingAccountListActions.ON_SET_AND_APPLY_SORT_ORDER',
    SET_AND_APPLY_ACCOUNT_STATUS_FILTER_CHANGE = 'SharedGivingAccountListActions.ON_SET_AND_APPLY_ACCOUNT_STATUS_FILTER_CHANGE',
    SET_AND_APPLY_ACCOUNT_SETUP_END_DATE_FILTER_CHANGE = 'SharedGivingAccountListActions.SET_AND_APPLY_ACCOUNT_SETUP_END_DATE_FILTER_CHANGE',
    SET_AND_APPLY_ACCOUNT_SETUP_START_DATE_FILTER_CHANGE = 'SharedGivingAccountListActions.SET_AND_APPLY_ACCOUNT_SETUP_START_DATE_FILTER_CHANGE',
    SET_DIRTY_SORT_ORDER = 'SharedGivingAccountListActions.ON_SET_DIRTY_SORT_ORDER',
    SET_DIRTY_ACCOUNT_STATUS_FILTER_CHANGE = 'SharedGivingAccountListActions.ON_SET_DIRTY_ACCOUNT_STATUS_FILTER_CHANGE',
    SET_DIRTY_ACCOUNT_SETUP_END_DATE_FILTER_CHANGE = 'SharedGivingAccountListActions.SET_DIRTY_ACCOUNT_SETUP_END_DATE_FILTER_CHANGE',
    SET_DIRTY_ACCOUNT_SETUP_START_DATE_FILTER_CHANGE = 'SharedGivingAccountListActions.SET_DIRTY_ACCOUNT_SETUP_START_DATE_FILTER_CHANGE',
}

export default ActionType;
