/* eslint class-methods-use-this: 0 */

import _ from 'lodash';
import request from 'axios';
import ApiUtils from '../../global/apiUtils.js';
import { appReduxStore } from '../../global/configureReduxStore.js';

class PossibleDuplicatesActions extends ApiUtils {
    nextPage() {
        appReduxStore.dispatch({ type: 'PossibleDuplicatesActions.ON_NEXTPAGE' });
    }

    reset() {
        appReduxStore.dispatch({
            type: 'PossibleDuplicatesActions.ON_RESET',
        });
    }

    resetPersonalDetails() {
        appReduxStore.dispatch({
            type: 'PossibleDuplicatesActions.ON_RESET_PERSONAL_DETAILS',
        });
    }

    get(args, person, pageSize, pageNumber, callbackParams) {
        const searchQuery = _.map(args, (arg) => {
            const loweredArg = arg.toLowerCase();
            const value = `"${person[arg]}"`;

            switch (loweredArg) {
                case 'firstname':
                case 'nickname':
                    return `(first-name:${value} OR nickname:${value})`;
                case 'lastname':
                    return `last-name:${value}`;
                case 'email':
                    return `email:${value}`;
                case 'address1':
                case 'city':
                    return `address.${loweredArg}:${value}`;
                case 'postalcode':
                    return `address.postal-code:${value}`;
                case 'homephone':
                case 'cellphone':
                case 'workphone':
                    return value.replace(/\D/g, ''); // strip non-numerics and don't specify field for best chance as of now
                default:
                    return `${loweredArg}:${value}`;
            }
        }).join(' ');

        let query = `?q=${encodeURIComponent(searchQuery)}&sort=last-name,asc`;
        query += `&pageSize=${pageSize}&pageNumber=${pageNumber}`;

        // eslint-disable-next-line no-underscore-dangle
        return this._get({ query }, null, null, callbackParams);
    }
}

const actions = new PossibleDuplicatesActions('/api/');

actions.buildReduxActionClass(
    {
        name: '_get',
        route: 'v2/person/search{query}',
        rq: request.get,
    }, {
        name: 'getPersonalDetails',
        route: 'v2/person/{personId}?suppressMergeRedirect=true&expand=address,contactpreference,email,phone,emergencycontacts',
        rq: request.get,
    },
);

export default actions;
