import './helpModal.scss';

import { A } from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import ClassNames from 'classnames';
import { translationFactory } from '../../../global/i18nUtils.js';
import domUtils from '../../../global/domUtils.js';
import Icon from '../../../js/components/Icon.react.js';

const i18n = translationFactory('Person.Search.PersonSearchHelpModal');

export default class PersonSearchHelpModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isTablet: window.innerWidth >= 768 };

        this.sectionsArray = [
            'search-help-overview',
            'search-help-quick-help',
            'search-help-searchable-fields',
            'search-help-targeted-field-search',
            'search-help-term-search',
            'search-help-phrase-search',
            'search-help-date-search',
            'search-help-range-search',
            'search-help-boolean-search',
            'search-help-grouping',
        ];

        this.onNavItemClickBooleanSearch = this.onNavItemClickBooleanSearch.bind(this);
        this.onNavItemClickDateSearch = this.onNavItemClickDateSearch.bind(this);
        this.onNavItemClickGrouping = this.onNavItemClickGrouping.bind(this);
        this.onNavItemClickOverview = this.onNavItemClickOverview.bind(this);
        this.onNavItemClickPhraseSearch = this.onNavItemClickPhraseSearch.bind(this);
        this.onNavItemClickQuickHelp = this.onNavItemClickQuickHelp.bind(this);
        this.onNavItemClickRangeSearch = this.onNavItemClickRangeSearch.bind(this);
        this.onNavItemClickSearchableFields = this.onNavItemClickSearchableFields.bind(this);
        this.onNavItemClickTargetedFieldSearch = this.onNavItemClickTargetedFieldSearch.bind(this);
        this.onNavItemClickTermSearch = this.onNavItemClickTermSearch.bind(this);

        this.onResize = this.onResize.bind(this);
        this.onScroll = this.onScroll.bind(this);

        this.onSectionToggleBooleanSearch = this.onSectionToggleBooleanSearch.bind(this);
        this.onSectionToggleDateSearch = this.onSectionToggleDateSearch.bind(this);
        this.onSectionToggleGrouping = this.onSectionToggleGrouping.bind(this);
        this.onSectionToggleOverview = this.onSectionToggleOverview.bind(this);
        this.onSectionTogglePhraseSearch = this.onSectionTogglePhraseSearch.bind(this);
        this.onSectionToggleQuickHelp = this.onSectionToggleQuickHelp.bind(this);
        this.onSectionToggleSearchableFields = this.onSectionToggleSearchableFields.bind(this);
        this.onSectionToggleTargetedFieldSearch = this.onSectionToggleTargetedFieldSearch.bind(this); // eslint-disable-line max-len
        this.onSectionToggleTermSearch = this.onSectionToggleTermSearch.bind(this);

        this.resizeEmptySpaceDebounced = _.debounce(this.resizeEmptySpace, 500);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        document.querySelector('.person-search-help-modal').addEventListener('scroll', this.onScroll);

        this.onScroll();
        this.onResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        document.querySelector('.person-search-help-modal').removeEventListener('scroll', this.onScroll);
    }

    onNavItemClick(item) {
        /* eslint-disable react/no-find-dom-node */
        const parentElement = ReactDOM.findDOMNode(document.querySelector('.person-search-help-modal'));
        const itemPosition = ReactDOM.findDOMNode(document.getElementById(`search-help-${item}`)).offsetTop;
        /* eslint-enable react/no-find-dom-node */

        domUtils.scrollTo(itemPosition, 250, parentElement);
    }

    onNavItemClickBooleanSearch() {
        this.onNavItemClick('boolean-search');
    }

    onNavItemClickDateSearch() {
        this.onNavItemClick('date-search');
    }

    onNavItemClickGrouping() {
        this.onNavItemClick('grouping');
    }

    onNavItemClickOverview() {
        this.onNavItemClick('overview');
    }

    onNavItemClickPhraseSearch() {
        this.onNavItemClick('phrase-search');
    }

    onNavItemClickQuickHelp() {
        this.onNavItemClick('quick-help');
    }

    onNavItemClickRangeSearch() {
        this.onNavItemClick('range-search');
    }

    onNavItemClickSearchableFields() {
        this.onNavItemClick('searchable-fields');
    }

    onNavItemClickTargetedFieldSearch() {
        this.onNavItemClick('targeted-field-search');
    }

    onNavItemClickTermSearch() {
        this.onNavItemClick('term-search');
    }

    onResize() {
        this.resizeEmptySpaceDebounced();

        this.setState({ isTablet: window.innerWidth >= 768 });
    }

    onScroll() {
        const modalPosition = document.querySelector('.person-search-help-modal').scrollTop + 33; // 33 accounts for the top margin

        _.map(this.sectionsArray, (e, i) => {
            const sectionId = this.sectionsArray[i];

            /* eslint-disable react/no-find-dom-node */
            const sectionElement = ReactDOM.findDOMNode(document.getElementById(sectionId));

            const sectionPosition = sectionElement.offsetTop;
            const sectionHeight = sectionElement.offsetHeight;

            /* eslint-disable react/no-string-refs */
            if (modalPosition >= sectionPosition &&
                modalPosition < (sectionPosition + sectionHeight)) {
                ReactDOM.findDOMNode(this.refs[sectionId]).classList.add('is-active');
            } else {
                ReactDOM.findDOMNode(this.refs[sectionId]).classList.remove('is-active');
            }
            /* eslint-enable react/no-string-refs */
            /* eslint-enable react/no-find-dom-node */
        });
    }

    onSectionToggle(sectionId) {
        const { isTablet } = this.state;

        if (!isTablet) {
            // eslint-disable-next-line react/no-find-dom-node
            const sectionElement = ReactDOM.findDOMNode(document.getElementById(sectionId));

            if (!sectionElement.classList.contains('is-active')) {
                sectionElement.classList.add('is-active');
            } else {
                sectionElement.classList.remove('is-active');
            }
        }
    }

    onSectionToggleBooleanSearch() {
        this.onSectionToggle('search-help-boolean-search');
    }

    onSectionToggleDateSearch() {
        this.onSectionToggle('search-help-date-search');
    }

    onSectionToggleGrouping() {
        this.onSectionToggle('search-help-grouping');
    }

    onSectionToggleOverview() {
        this.onSectionToggle('search-help-overview');
    }

    onSectionTogglePhraseSearch() {
        this.onSectionToggle('search-help-phrase-search');
    }

    onSectionToggleQuickHelp() {
        this.onSectionToggle('search-help-quick-help');
    }

    onSectionToggleSearchableFields() {
        this.onSectionToggle('search-help-searchable-fields');
    }

    onSectionToggleTargetedFieldSearch() {
        this.onSectionToggle('search-help-targeted-field-search');
    }

    onSectionToggleTermSearch() {
        this.onSectionToggle('search-help-term-search');
    }

    resizeEmptySpace() {
        /* eslint-disable react/no-find-dom-node */
        /* eslint-disable react/no-string-refs */
        const modalHeight = ReactDOM.findDOMNode(document.querySelector('.person-search-help-modal')).offsetHeight;
        const emptySpaceHeight = ReactDOM.findDOMNode(this.refs.emptySpace).offsetTop;
        const lastSectionPosition = ReactDOM.findDOMNode(this.refs.lastSection).offsetTop;
        const lastSectionHeight = (emptySpaceHeight - lastSectionPosition) + 66; // 66 accounts for the top and bottom container padding

        ReactDOM.findDOMNode(this.refs.emptySpace).style.height = `${modalHeight - lastSectionHeight}px`;
        /* eslint-enable react/no-string-refs */
        /* eslint-enable react/no-find-dom-node */
    }

    render() {
        const { isTablet } = this.state;

        const mainClasses = ClassNames('content', { 'accordion-v1': !isTablet });
        const sectionClasses = ClassNames({ 'accordion-item': !isTablet });
        const sectionTitleClasses = ClassNames({ 'accordion-item-title': !isTablet });
        const sectionContentClasses = ClassNames({ 'accordion-item-contents': !isTablet });

        /* eslint-disable react/no-string-refs */
        return (
            <div className="inner-wrap" ref="innerWrap">
                <header>
                    <h2 className="text-center">{i18n('AboutSearch')}</h2>
                </header>

                <aside className="navigation">
                    <h2>{i18n('AboutSearch')}</h2>

                    <ul className="ul text-small">
                        <li ref="search-help-overview">
                            <A onClick={this.onNavItemClickOverview}>{i18n('Overview')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-quick-help">
                            <A onClick={this.onNavItemClickQuickHelp}>{i18n('QuickHelp')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-searchable-fields">
                            <A onClick={this.onNavItemClickSearchableFields}>{i18n('SearchableFields')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-targeted-field-search">
                            <A onClick={this.onNavItemClickTargetedFieldSearch}>{i18n('TargetedFieldSearch')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-term-search">
                            <A onClick={this.onNavItemClickTermSearch}>{i18n('TermSearch')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-phrase-search">
                            <A onClick={this.onNavItemClickPhraseSearch}>{i18n('PhraseSearch')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-date-search">
                            <A onClick={this.onNavItemClickDateSearch}>{i18n('DateSearch')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-range-search">
                            <A onClick={this.onNavItemClickRangeSearch}>{i18n('RangeSearch')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-boolean-search">
                            <A onClick={this.onNavItemClickBooleanSearch}>{i18n('BooleanSearch')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>

                        <li ref="search-help-grouping">
                            <A onClick={this.onNavItemClickGrouping}>{i18n('Grouping')}</A>
                            <Icon size="xs" type="arrow-right" />
                        </li>
                    </ul>
                </aside>

                {/* eslint-disable react/jsx-one-expression-per-line */}
                <main className={mainClasses} ref="content">
                    <section className={sectionClasses} id="search-help-overview">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionToggleOverview}
                            role="presentation"
                        >
                            <span>{i18n('Overview')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <p>{i18n('OverviewParagraph1')}</p>
                        </div>
                    </section>

                    <section className={sectionClasses} id="search-help-quick-help">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionToggleQuickHelp}
                            role="presentation"
                        >
                            <span>{i18n('QuickHelp')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <ol>
                                <li>
                                    {i18n('QuickHelpList1')}
                                    <ol type="a">
                                        <li>{i18n('QuickHelpList11')}</li>
                                        <li>{i18n('QuickHelpList12')}</li>
                                    </ol>
                                </li>
                                <li>
                                    {i18n('QuickHelpList2')}
                                    <ol type="a">
                                        <li>{i18n('QuickHelpList21')}</li>
                                    </ol>
                                </li>
                                <li>
                                    {i18n('QuickHelpList3')}
                                </li>
                                <li>
                                    {i18n('QuickHelpList4')}
                                </li>
                                <li>
                                    {i18n('QuickHelpList5')}
                                    <ol type="a">
                                        <li>{i18n('QuickHelpList51')}</li>
                                    </ol>
                                </li>
                            </ol>
                            <p>{i18n('QuickHelpParagraph1')}</p>
                        </div>
                    </section>

                    <section className={sectionClasses} id="search-help-searchable-fields">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionToggleSearchableFields}
                            role="presentation"
                        >
                            <span>{i18n('SearchableFields')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <h5>{i18n('People')}</h5>

                            <ul>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet1')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet2')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet3')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet4')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet5')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet6')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet7')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet8')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet9')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet10')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet11')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet12')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet13')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet14')}&quot;</li>
                                {/* Item 15 ("grade") is not yet implemented */}
                                {/*
                                    <li>&quot;{i18n('SearchableFieldsPeopleBullet15')}&quot;</li>
                                */}
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet16')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet17')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet18')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet19')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet20')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet21')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet22')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet23')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet24')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet25')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet26')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet27')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet28')}&quot;</li>
                                {/* Items 29 through 38 are not yet implemented
                                  * 29-31 relate to contact preferences
                                  * 32 is "staff-member"
                                  * 33-38 relate to occupations
                                  */}
                                {/* <li>&quot;{i18n('SearchableFieldsPeopleBullet29')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet30')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet31')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet32')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet33')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet34')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet35')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet36')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet37')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet38')}&quot;</li> */}
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet39')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet40')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet41')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet42')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet43')}&quot;</li>
                                <li>&quot;{i18n('SearchableFieldsPeopleBullet44')}&quot;</li>
                                {/* Item 45 ("last-give-date") is not yet implemented */}
                                {/*
                                    <li>&quot;{i18n('SearchableFieldsPeopleBullet45')}&quot;</li>
                                */}
                            </ul>
                        </div>
                    </section>

                    <section className={sectionClasses} id="search-help-targeted-field-search">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionToggleTargetedFieldSearch}
                            role="presentation"
                        >
                            <span>{i18n('TargetedFieldSearch')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <p>{i18n('TargetedFieldSearchParagraph1')}</p>

                            <ul>
                                <li>{i18n('TargetedFieldSearchBullet1')}</li>
                            </ul>

                            <p>{i18n('TargetedFieldSearchParagraph2')}</p>

                            <ul>
                                <li>{i18n('TargetedFieldSearchBullet2')}</li>
                            </ul>

                            <p>{i18n('TargetedFieldSearchParagraph3')}</p>

                            <ul>
                                <li>{i18n('TargetedFieldSearchBullet3')}</li>
                            </ul>

                            <p>{i18n('TargetedFieldSearchParagraph4')}</p>

                            <ul>
                                <li>{i18n('TargetedFieldSearchBullet4')}</li>
                            </ul>

                            <p>{i18n('TargetedFieldSearchParagraph5')}</p>
                        </div>
                    </section>

                    <section className={sectionClasses} id="search-help-term-search">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionToggleTermSearch}
                            role="presentation"
                        >
                            <span>{i18n('TermSearch')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <p>{i18n('TermSearchParagraph1')}</p>

                            <ul>
                                <li>{i18n('TermSearchBullet1')}</li>
                            </ul>

                            <p>{i18n('TermSearchParagraph2')}</p>

                            <ul>
                                <li>{i18n('TermSearchBullet2')}</li>
                            </ul>

                            <p>{i18n('TermSearchParagraph3')}</p>

                            <ul>
                                <li>&quot;{i18n('TermSearchBullet3')}&quot;</li>
                            </ul>

                            <p>{i18n('TermSearchParagraph4')}</p>
                        </div>
                    </section>

                    <section className={sectionClasses} id="search-help-phrase-search">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionTogglePhraseSearch}
                            role="presentation"
                        >
                            <span>{i18n('PhraseSearch')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <p>{i18n('PhraseSearchParagraph1')}</p>

                            <ul>
                                <li>&quot;{i18n('PhraseSearchBullet1')}&quot;</li>
                            </ul>

                            <p>{i18n('PhraseSearchParagraph2')}</p>
                        </div>
                    </section>

                    <section className={sectionClasses} id="search-help-date-search">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionToggleDateSearch}
                            role="presentation"
                        >
                            <span>{i18n('DateSearch')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <p>{i18n('DateSearchParagraph1')}</p>

                            <ul>
                                <li>{i18n('DateSearchBullet11')}</li>
                                <li>{i18n('DateSearchBullet12')}</li>
                                <li>{i18n('DateSearchBullet13')}</li>
                                <li>{i18n('DateSearchBullet14')}</li>
                            </ul>

                            <p>{i18n('DateSearchParagraph2')}</p>

                            <h4 id="search-help-range-search">Range Search</h4>

                            <p>{i18n('DateSearchParagraph3')}</p>

                            <ul>
                                <li>{i18n('DateSearchBullet2')}</li>
                            </ul>

                            <p>{i18n('DateSearchParagraph4')}</p>

                            <ul>
                                <li>{i18n('DateSearchBullet3')}</li>
                            </ul>

                            <p>{i18n('DateSearchParagraph5')}</p>

                            <ul>
                                <li>{i18n('DateSearchBullet4')}</li>
                            </ul>

                            <p>{i18n('DateSearchParagraph6')}</p>
                        </div>
                    </section>

                    <section className={sectionClasses} id="search-help-boolean-search">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionToggleBooleanSearch}
                            role="presentation"
                        >
                            <span>{i18n('BooleanSearch')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <p>{i18n('BooleanSearchParagraph1')}</p>

                            <ul>
                                <li>{i18n('BooleanSearchBullet1')}</li>
                            </ul>

                            <p>{i18n('BooleanSearchParagraph2')}</p>

                            <ul>
                                <li>{i18n('BooleanSearchBullet2')}</li>
                            </ul>

                            <p>{i18n('BooleanSearchParagraph3')}</p>

                            <ul>
                                <li>{i18n('BooleanSearchBullet3')}</li>
                            </ul>

                            <p>{i18n('BooleanSearchParagraph4')}</p>

                            <p>{i18n('BooleanSearchParagraph5')}</p>

                            <ul>
                                <li>{i18n('BooleanSearchBullet4')}</li>
                            </ul>

                            <p>{i18n('BooleanSearchParagraph6')}</p>
                        </div>
                    </section>

                    <section className={sectionClasses} id="search-help-grouping" ref="lastSection">
                        <h4
                            className={sectionTitleClasses}
                            onClick={this.onSectionToggleGrouping}
                            role="presentation"
                        >
                            <span>{i18n('Grouping')}</span>
                            <Icon size="s" type="accordion" />
                        </h4>

                        <div className={sectionContentClasses}>
                            <p>{i18n('GroupingParagraph1')}</p>

                            <ul>
                                <li>{i18n('GroupingBullet1')}</li>
                            </ul>

                            <p>{i18n('GroupingParagraph2')}</p>
                        </div>
                    </section>

                    <div className="empty-space" ref="emptySpace" />
                </main>
                {/* eslint-enable react/jsx-one-expression-per-line */}
            </div>
        );
        /* eslint-enable react/no-string-refs */
    }
}
