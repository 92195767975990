import { combineReducers } from 'redux';
import entriesByPerson from './global/entriesByPerson/entriesByPerson.reducer.js';
import entryEditor from './entryEditor/entryEditor.reducers.js';
import listPage from './listPage/listPage.reducers.js';

const reducers = combineReducers({
    entriesByPerson,
    entryEditor,
    listPage,
});

export default reducers;
