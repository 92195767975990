import { combineReducers } from 'redux';

import accommodation from './accommodation.js';
import category from './category.js';
import checkIn from '../checkIn/reducers/index.js';
import event from './event.js';
import eventAttendee from './EventAttendee/index.js';
import eventCache from './eventCache.js';
import eventEdit from '../eventDetails/index.reducers';
import eventOccurrenceVenue from './eventOccurrenceVenue.js';
import eventsSearchFilters from './eventsSearchFilters.js';
import globalEventOccurrenceSearch from '../../global/eventOccurrenceSearchDrawer/eventOccurrenceSearchDrawer.reducers.js';
import reminder from './reminder.js';
import roomCategory from './roomCategory.js';
import tag from './tag.js';
import paidEvent from '../eventDetails/paidEvent/paidEvent.reducers';

const reducers = combineReducers({
    accommodation,
    category,
    checkIn,
    event,
    eventAttendee,
    eventCache,
    eventEdit,
    eventOccurrenceVenue,
    eventsSearchFilters,
    globalEventOccurrenceSearch,
    reminder,
    roomCategory,
    tag,
    paidEvent,
});

export default reducers;
