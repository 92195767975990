import _ from 'lodash';
import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const pagedQuestionList = new ChunkedPaginationUtils();

const defaultFilters = {
    activeFilter: null,
    selectedMilestoneIds: [],
    sort: {
        label: 'Name (A > Z)',
        value: 'name',
    },
};

const DEFAULT_STATE = {
    appliedFilters: { ...defaultFilters },
    areFiltersDirty: false,
    canLoadMore: false,
    defaultFilters: { ...defaultFilters },
    dirtyFilters: { ...defaultFilters },
    isFetching: false,
    isFiltering: false,
    milestoneCounts: [],
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedQuestionList.getPageSize(),
    questions: [],
    searchTerm: null,
    total: 0,
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !_.isEqual(nextAppliedFilters, defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: { ...nextAppliedFilters },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const { appliedFilters } = prevState;
    const areFiltersDirty = !_.isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
        value: actionValue,
    } = action;

    const first = !_.isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        // Fetching Question List Data
        case 'ConnectionQuestionsListActions.ON_BEFORE_INTERNALGETCONNECTIONQUESTIONS':
            if (first) {
                return { ...state, isFetching: true };
            }

            return state;

        case 'ConnectionQuestionsListActions.ON_INTERNALGETCONNECTIONQUESTIONS':
        {
            const { countsByMilestone, resultCount, results } = actionResult;
            pagedQuestionList.loadPage(results, resultCount, first);

            return {
                ...state,
                canLoadMore: pagedQuestionList.canLoadMore(),
                isFetching: false,
                milestoneCounts: first ? countsByMilestone : state.milestoneCounts,
                needsToRequest: pagedQuestionList.needsToLoadPage(),
                pageNumber: pagedQuestionList.getCurrentPageNumber(),
                pageSize: pagedQuestionList.getPageSize(),
                questions: pagedQuestionList.getAll(true),
                total: first ? pagedQuestionList.getTotalCount() : state.total,
            };
        }

        case 'ConnectionQuestionsListActions.ON_LIST_QUESTIONS_NEXT_PAGE':
            return {
                ...state,
                canLoadMore: pagedQuestionList.canLoadMore(),
                needsToRequest: pagedQuestionList.needsToLoadPage(),
                questions: pagedQuestionList.getAll(true),
            };

        // Manipulating Filters and Sorts
        case 'ConnectionQuestionsListActions.ON_SET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: actionValue,
            };

        case 'ConnectionQuestionsListActions.ON_CLEAR_SEARCH_TERM':
            return {
                ...state,
                searchTerm: null,
            };

        case 'ConnectionQuestionsListActions.ON_APPLY_FILTERS':
        {
            const { dirtyFilters } = state;
            const isFiltering = !_.isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: { ...dirtyFilters },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'ConnectionQuestionsListActions.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(state, { ...defaultFilters });

        case 'ConnectionQuestionsListActions.ON_MILESTONE_FILTER_CHANGED_AND_APPLIED':
        {
            const { milestoneId, isChecked } = actionValue;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedMilestoneIds: isChecked ?
                    [...state.appliedFilters.selectedMilestoneIds, milestoneId] :
                    _.without(
                        state.appliedFilters.selectedMilestoneIds,
                        milestoneId,
                    ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ConnectionQuestionsListActions.ON_STATUS_FILTER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, activeFilter: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ConnectionQuestionsListActions.ON_SORT_ORDER_CHANGED_AND_APPLIED':
        {
            const updatedFilters = { ...state.appliedFilters, sort: actionValue };
            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'ConnectionQuestionsListActions.ON_DIRTY_MILESTONE_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedMilestoneIds: actionValue },
            );
        }

        case 'ConnectionQuestionsListActions.ON_DIRTY_SORT_ORDER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(state, { ...dirtyFilters, sort: actionValue });
        }

        case 'ConnectionQuestionsListActions.ON_DIRTY_STATUS_FILTER_CHANGED':
        {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(state, { ...dirtyFilters, activeFilter: actionValue });
        }

        case 'ConnectionQuestionsListActions.RESET':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};
