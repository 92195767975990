import LoginConstants from '../../js/constants/Login/LoginConstants.js';

export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT:
            return DEFAULT_STATE;

        case 'EmbeddedReportActions.ON_GETREPORTS':
            return action.result;

        default:
            return state;
    }
};
