import {
    isEqual,
    isNil,
    map,
} from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
    BEM_BLOCK_NAME,
} from './constants.js';
import {
    usePrevious,
} from '../../global/utils/utils.js';
import {
    useComponentDidMount,
    useComponentDidUpdate,
} from '../../global/lifeCycleHooks';
import EducationalDialog from '../../global/educationalDialog/educationalDialog.jsx';
import {
    getByContentItemId as getByContentItemIdAction,
    getLatest as getLatestAction,
} from './releaseNotesDialog.actions.js';

const propTypes = {
    getByContentItemId: PropTypes.func.isRequired,
    getLatest: PropTypes.func.isRequired,
    onCloseComplete: PropTypes.func.isRequired,
    releaseNoteIds: PropTypes.shape({
        contentItemId: PropTypes.string,
        contentItemVersionId: PropTypes.string,
    }),
};

const defaultProps = {
    releaseNoteIds: null,
};

const mapDispatchToProps = {
    getByContentItemId: getByContentItemIdAction,
    getLatest: getLatestAction,
};
function ReleaseNotesDialog(props) {
    const {
        getByContentItemId,
        getLatest,
        onCloseComplete: onCloseCompleteProp,
        releaseNoteIds,
    } = props;

    const [isOpen, setIsOpen] = React.useState(false);
    const [steps, setSteps] = React.useState([]);
    const prevReleaseNoteIds = usePrevious(releaseNoteIds);

    const getSteps = (result) => map(result.data.items, (step) => {
        let newStep = {
            body: step.body,
            callToAction: {
                label: step.callToAction.text,
                url: step.callToAction.url,
            },
            heading: step.title,
        };

        if (step.images) {
            const newImages = map(step.images, (image) => ({
                url: image.url,
                title: image.text,
            }));

            newStep = {
                ...newStep,
                images: newImages,
            };
        }

        return newStep;
    });

    useComponentDidMount(() => {
        getLatest()
            .then((response) => {
                if (response?.data?.items) {
                    const newSteps = getSteps(response);

                    setSteps(newSteps);
                    setIsOpen(true);
                }
            })
            .catch((response) => {
                // eslint-disable-next-line no-console
                console.log('Error getting Latest Release Notes:', response);
            });
    });

    useComponentDidUpdate(() => {
        if (!isNil(releaseNoteIds) && !isEqual(prevReleaseNoteIds, releaseNoteIds)) {
            getByContentItemId({
                contentItemId: releaseNoteIds.contentItemId,
                contentItemVersionId: releaseNoteIds.contentItemVersionId,
            })
                .then((response) => {
                    if (response?.data?.items) {
                        const newSteps = getSteps(response);

                        setSteps(newSteps);
                        setIsOpen(true);
                    }
                })
                .catch((response) => {
                    // eslint-disable-next-line no-console
                    console.log('Error getting Release Notes By ContentId:', response);
                });
        }
    }, [
        prevReleaseNoteIds,
        releaseNoteIds,
    ]);

    const onCloseComplete = () => {
        setSteps([]);
        onCloseCompleteProp();
    };

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <EducationalDialog
            className={BEM_BLOCK_NAME}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={onCloseComplete}
            steps={steps}
        />
    );
}

ReleaseNotesDialog.propTypes = propTypes;
ReleaseNotesDialog.defaultProps = defaultProps;

export const NotConnectedReleaseNotesDialog = ReleaseNotesDialog;

export default connect(null, mapDispatchToProps)(NotConnectedReleaseNotesDialog);
