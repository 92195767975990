import {
    filter,
    isNil,
} from 'lodash';
import {
    ACTION_SETTING,
} from '../constants.js';
import { addOrReplaceBy } from '../../../../global/utils';
import isAbleToSaveEmailTemplate from './emailActionUtils.js';

export const updateIndividualRuleEmailAction = (
    existingRule, ruleActionId, objWithUpdates, template = null,
) => ({
    ...existingRule,
    actions: filter(
        addOrReplaceBy(
            existingRule.actions,
            (a) => a.id === ruleActionId,
            (existingAction) => {
                if (!isNil(existingAction)) {
                    let filteredExistingActionSettings = [];
                    filteredExistingActionSettings = filter(
                        existingAction.actionSettings, (item) => item.name !== objWithUpdates.name,
                    );

                    let { actionTemplate } = existingAction;

                    const updatedEmailActionSettings = [
                        ...filteredExistingActionSettings,
                        { ...objWithUpdates },
                    ];

                    if (ACTION_SETTING.EmailTemplateId === objWithUpdates.name) {
                        actionTemplate = template;
                    }

                    const ableToSave = isAbleToSaveEmailTemplate(updatedEmailActionSettings);

                    return {
                        ...existingAction,
                        ableToSave,
                        actionSettings: updatedEmailActionSettings,
                        actionTemplate,
                    };
                }

                return null;
            },
        ),
        (a) => !isNil(a),
    ),
});

export const updateRuleEmailAction = (existingRule, actionType, actionValue) => {
    switch (actionType) {
        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_FROM_DISPLAY_NAME':
        {
            const {
                ruleActionId,
                fromDisplayName,
            } = actionValue;

            const updates = {
                name: ACTION_SETTING.FromDisplayName,
                value: fromDisplayName,
            };

            return updateIndividualRuleEmailAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_FROM_EMAIL':
        {
            const {
                ruleActionId,
                freeformFromEmail,
            } = actionValue;

            const updates = {
                name: ACTION_SETTING.FromEmail,
                value: freeformFromEmail,
            };

            return updateIndividualRuleEmailAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'EmailTemplateEditorActions.ON_UPDATE_FREEFORM_REPLY_TO_EMAIL':
        {
            const {
                ruleActionId,
                freeformReplyToEmail,
            } = actionValue;

            const updates = {
                name: ACTION_SETTING.ReplyToEmail,
                value: freeformReplyToEmail,
            };

            return updateIndividualRuleEmailAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'EmailTemplateEditorActions.ON_UPDATE_FROM_EMAIL_USER':
        {
            const {
                ruleActionId,
                fromUser,
            } = actionValue;

            const updates = {
                name: ACTION_SETTING.FromEmail,
                value: fromUser,
            };

            return updateIndividualRuleEmailAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'EmailTemplateEditorActions.ON_UPDATE_REPLY_TO_DISPLAY_NAME':
        {
            const {
                ruleActionId,
                replyToDisplayName,
            } = actionValue;

            const updates = {
                name: ACTION_SETTING.ReplyToDisplayName,
                value: replyToDisplayName,
            };

            return updateIndividualRuleEmailAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'EmailTemplateEditorActions.ON_UPDATE_REPLY_TO_EMAIL_USER':
        {
            const {
                ruleActionId,
                replyToUser,
            } = actionValue;

            const updates = {
                name: ACTION_SETTING.ReplyToEmail,
                value: replyToUser,
            };

            return updateIndividualRuleEmailAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        case 'EmailTemplateEditorActions.ON_UPDATE_EMAIL_TEMPLATE':
        {
            const {
                ruleActionId,
                template,
            } = actionValue;

            const updates = {
                name: ACTION_SETTING.EmailTemplateId,
                value: template.id,
            };

            return updateIndividualRuleEmailAction(
                existingRule,
                ruleActionId,
                updates,
                template,
            );
        }

        case 'EmailTemplateEditorActions.ON_REMOVE_EMAIL_TEMPLATE':
        {
            const {
                ruleActionId,
            } = actionValue;

            const updates = {
                name: ACTION_SETTING.EmailTemplateId,
                value: '',
            };

            return updateIndividualRuleEmailAction(
                existingRule,
                ruleActionId,
                updates,
            );
        }

        default:
            return existingRule;
    }
};
