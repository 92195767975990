import './tabsContentComingSoonMessage.scss';

import { Icon } from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import { translationFactory } from '../../global/i18nUtils.js';

const i18n = translationFactory('Person.Record.SubPages.ComingSoonMessage');

function TabsContentComingSoonMessage() {
    const bemBlockClass = 'person_record';

    return (
        <div className={`${bemBlockClass}--tabs_content ${bemBlockClass}--tabs_content-coming_soon_message`}>
            <div>
                <Icon
                    className={`${bemBlockClass}--currently_working_icon`}
                    color="warning"
                    size={64}
                    type="wrench-screwdriver"
                    style={{ marginRight: 0 }}
                />
            </div>

            <div>
                <p className={`${bemBlockClass}--currently_working`}>
                    {i18n('CurrrentlyWorking')}
                </p>

                <p className={`${bemBlockClass}--check_back_soon`}>
                    {i18n('CheckBackSoon')}
                </p>
            </div>
        </div>
    );
}

export default TabsContentComingSoonMessage;
