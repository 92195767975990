import { combineReducers } from 'redux';
import campuses from './reducers/warehouseCampuses.js';
import followUpsDashboard from './followUpsDashboard/followUpsDashboard.reducer.js';
import regions from './reducers/warehouseRegions.js';
import warehouseClassData from './reducers/warehouseClassData.js';
import warehouseDashboard from './reducers/warehouseDashboard.js';
import warehouseMonthlyTotalData from './reducers/warehouseMonthlyTotalData.js';
import warehouseTotalDataSinceInception from './reducers/warehouseTotalDataSinceInception.js';
import weekendFlashReport from './reducers/weekendFlashReport.js';
import embeddedCategories from './reducers/embeddedCategories';
import embeddedReports from './reducers/embeddedReports';

const reducers = combineReducers({
    campuses,
    followUpsDashboard,
    regions,
    warehouseClassData,
    warehouseDashboard,
    warehouseMonthlyTotalData,
    warehouseTotalDataSinceInception,
    weekendFlashReport,
    embeddedCategories,
    embeddedReports,
});

export default reducers;
