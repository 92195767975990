import {
    filter,
    isNil,
} from 'lodash';
import {
    CampaignForEditor,
} from './models';
import ActionType from './campaignEditor.actionTypes';

export const DEFAULT_STATE: CampaignForEditor = {
    companionAppHeaderUrl: null,
    contactEmail: '',
    id: null,
    description: '',
    designations: [],
    goal: null,
    isActive: true,
    isFeatured: false,
    name: '',
    publishedEndDate: null,
    publishedEndTime: null,
    publishedStartDate: null,
    publishedStartTime: null,
    publishedTimeZone: null,
    shortDescription: '',
    thumbnailUrl: null,
    webHeaderUrl: null,
};

const updateDefaultDesignationAction = (
    existingState,
    designationId,
) => ({
    ...existingState,
    designations: existingState.designations.map(
        (designation) => ({
            ...designation,
            isDefault: designation.id === designationId,
        }),
    ),
});

export default (
    state: CampaignForEditor | undefined,
    action: any,
): CampaignForEditor => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REMOVE_DESIGNATION: {
                const {
                    value: {
                        designationId,
                        isDefault,
                    },
                } = action;

                const filteredDesignations = filter(
                    state.designations,
                    (designation) => designation.id !== designationId,
                );

                // Scenario: When default designation removed, set default to new designation
                if (isDefault && filteredDesignations.length > 0) {
                    filteredDesignations[0].isDefault = true;
                }

                return {
                    ...state,
                    designations: [
                        ...filteredDesignations,
                    ],
                };
            }

            case ActionType.RESET_DATA: {
                return DEFAULT_STATE;
            }

            case ActionType.SET_COMPANION_APP_HEADER_URL: {
                const { value: companionAppHeaderUrl } = action;

                return {
                    ...state,
                    companionAppHeaderUrl,
                };
            }

            case ActionType.SET_CAMPAIGN_CONTACT_EMAIL: {
                const { value: contactEmail } = action;

                return {
                    ...state,
                    contactEmail,
                };
            }

            case ActionType.SET_CAMPAIGN_GOAL: {
                const { value: goal } = action;

                return {
                    ...state,
                    goal,
                };
            }

            case ActionType.SET_CAMPAIGN_EDITOR_FORM_DATA: {
                const { value: formData } = action;

                return {
                    ...state,
                    ...formData,
                };
            }

            case ActionType.SET_CAMPAIGN_IS_ACTIVE: {
                const { value: isActive } = action;

                return {
                    ...state,
                    isActive,
                };
            }

            case ActionType.SET_CAMPAIGN_IS_FEATURED: {
                const { value: isFeatured } = action;

                return {
                    ...state,
                    isFeatured,
                };
            }

            case ActionType.SET_DESCRIPTION: {
                const { value: description } = action;

                return {
                    ...state,
                    description,
                };
            }

            case ActionType.SET_DESIGNATION: {
                const { value: designation } = action;

                return {
                    ...state,
                    designations: [
                        ...state.designations,
                        designation,
                    ],
                };
            }

            case ActionType.SET_DEFAULT_DESIGNATION: {
                const { value: designationId } = action;

                return updateDefaultDesignationAction(state, designationId);
            }

            case ActionType.SET_NAME: {
                const { value: name } = action;

                return {
                    ...state,
                    name,
                };
            }

            case ActionType.SET_PUBLISHED_END_DATE: {
                const { value: publishedEndDate } = action;

                return {
                    ...state,
                    publishedEndDate,
                };
            }

            case ActionType.SET_PUBLISHED_END_TIME: {
                const { value: publishedEndTime } = action;

                return {
                    ...state,
                    publishedEndTime,
                };
            }

            case ActionType.SET_PUBLISHED_START_DATE: {
                const { value: publishedStartDate } = action;

                return {
                    ...state,
                    publishedStartDate,
                };
            }

            case ActionType.SET_PUBLISHED_START_TIME: {
                const { value: publishedStartTime } = action;

                return {
                    ...state,
                    publishedStartTime,
                };
            }

            case ActionType.SET_PUBLISHED_TIMEZONE: {
                const { value: publishedTimeZone } = action;

                return {
                    ...state,
                    publishedTimeZone,
                };
            }

            case ActionType.SET_SHORT_DESCRIPTION: {
                const { value: shortDescription } = action;

                return {
                    ...state,
                    shortDescription,
                };
            }

            case ActionType.SET_THUMBNAIL_URL: {
                const { value: thumbnailUrl } = action;

                return {
                    ...state,
                    thumbnailUrl,
                };
            }

            case ActionType.SET_WEB_HEADER_URL: {
                const { value: webHeaderUrl } = action;

                return {
                    ...state,
                    webHeaderUrl,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
