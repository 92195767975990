enum NoteReminderOpenSource {
    OpenFromNoteForm = 'openFromNoteForm',
    OpenFromNoteFormMobile = 'openFromNoteFormMobile',
    OpenFromNoteDetail = 'openFromNoteDetail',
    OpenFromNoteDetailMobile = 'openFromNoteDetailMobile',
    OpenFromNoteFormEdit = 'openFromNoteFormEdit',
    OpenFromNoteFormEditMobile = 'openFromNoteFormEditMobile',
}

export default NoteReminderOpenSource;
