import { combineReducers } from 'redux';
import answerSources from './global/answerSourceReducer.js';
import details from './details/detailsReducer.js';
import editor from './editorDrawer/editorReducer.js';
import list from './list/listReducer.js';

const reducers = combineReducers({
    answerSources,
    details,
    editor,
    list,
});

export default reducers;
