import { appReduxStore } from '../../global/configureReduxStore.js';

const actionType = 'SearchBlockTypeManagerActions';

export function reset() {
    appReduxStore.dispatch({
        type: `${actionType}.ON_RESET`,
    });
}

export function setBlockSize(block) {
    if (block) {
        appReduxStore.dispatch({
            result: block.offsetWidth,
            type: `${actionType}.ON_SET_BLOCK_SIZE`,
        });
    }
}

export function setBlockType(type) {
    appReduxStore.dispatch({
        result: type,
        type: `${actionType}.ON_SET_BLOCK_TYPE`,
    });
}

export function setIsSearchInitiated(value) {
    return new Promise((resolve) => {
        appReduxStore.dispatch({
            result: value,
            type: `${actionType}.ON_SET_IS_SEARCH_INITIATED`,
        });
        resolve();
    });
}
