enum JourneyCardTypes {
    Color = 'Color',
    Link = 'Link',
    Subtitle = 'Subtitle',
    Symbol = 'Symbol',
    Title = 'Title',
    Type = 'Type',
}

export default JourneyCardTypes;
