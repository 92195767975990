import { addOrReplaceBy } from '../../global/utils';

export const DEFAULT_STATE = {
    occurrences: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'MinistryServingOpportunityVolunteerActions.ON_GETOCCURRENCES':
        {
            return {
                ...state,
                occurrences: action.result,
            };
        }

        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_OCCURRENCES':
            return DEFAULT_STATE;

        case 'MinistryServingOpportunityVolunteerActions.UPDATE_OCCURRENCE':
        {
            const { updatedOccurrence } = action;

            return {
                ...state,
                occurrences: addOrReplaceBy(
                    state.occurrences,
                    (o) => o.scheduleId === updatedOccurrence.scheduleId &&
                        o.actualStartDateTime === updatedOccurrence.actualStartDateTime,
                    () => (updatedOccurrence),
                ),
            };
        }

        default:
            return state;
    }
};
