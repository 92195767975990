import './timeDropdown.scss';

import { Dropdown } from '@saddlebackchurch/react-cm-ui';
import { map, range } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    shouldSetDefaultValue: PropTypes.bool,
    value: PropTypes.number,
};

const defaultProps = {
    className: undefined,
    disabled: undefined,
    id: undefined,
    label: undefined,
    onChange: undefined,
    required: undefined,
    shouldSetDefaultValue: true,
    value: undefined,
};

const totalOptions = 96;

const HOURS = map(range(totalOptions), (i) => {
    const hours = Math.floor(i / 4);
    const minutes = (i % 4) * 15;
    const date = moment({ hours, minutes });

    return {
        value: i,
        label: date.format('hh:mm A'),
    };
});

class TimeDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialValue: null,
            options: HOURS,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {
            shouldSetDefaultValue,
        } = this.props;

        this.setState({
            initialValue: shouldSetDefaultValue ? moment().hour() : null,
        });
    }

    onChange(value) {
        const { onChange } = this.props;

        onChange(value, totalOptions);
    }

    render() {
        const {
            className,
            disabled,
            id,
            label,
            required,
            value,
        } = this.props;

        const { options, initialValue } = this.state;
        const time = value === null ? initialValue : value;
        const selection = time === null ? null : options[time];

        return (
            <div className="time-dropdown">
                <Dropdown
                    autoScrollSelection
                    className={className}
                    clearable={false}
                    disable={disabled}
                    fluid
                    id={id}
                    label={label}
                    menuMaxHeight={148}
                    onChange={this.onChange}
                    options={options}
                    placeholder="HH:MM AM"
                    selectionRequired={required}
                    selection
                    selectionMatchProp="any"
                    value={selection}
                    tabIndex={0}
                />
            </div>
        );
    }
}

TimeDropdown.propTypes = propTypes;
TimeDropdown.defaultProps = defaultProps;

export default TimeDropdown;
