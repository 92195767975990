import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import { ADD_PERSON_SET_IS_SAVE_DISABLED } from './addPersonTypes.js';

class AddPersonRecordActions extends ApiUtils {
    updateBirthDate(birthDate) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_BIRTHDATE',
            value: birthDate,
        });
    }

    updateFirstName(firstName) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_FIRSTNAME',
            value: firstName,
        });
    }

    updateLastName(lastName) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_LASTNAME',
            value: lastName,
        });
    }

    updateGender(gender) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_GENDER',
            value: gender,
        });
    }

    updateGrade(grade) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_GRADE',
            value: grade,
        });
    }

    updateCampus(campus) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_CAMPUS',
            value: campus,
        });
    }

    updateEmail(email) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_EMAIL',
            value: email,
        });
    }

    updatePhone(phone) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_PHONE',
            value: phone,
        });
    }

    updatePhoneType(phoneTypeInfo) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_PHONE_TYPE',
            value: phoneTypeInfo,
        });
    }

    updatePhoneExtension(phoneExtension) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_PHONE_EXTENSION',
            value: phoneExtension,
        });
    }

    updateAddressOne(addressOne) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_ADDRESS_ONE',
            value: addressOne,
        });
    }

    updateAddressTwo(addressTwo) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_ADDRESS_TWO',
            value: addressTwo,
        });
    }

    updateCity(city) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_CITY',
            value: city,
        });
    }

    updateCountry(country) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_COUNTRY',
            value: country,
        });
    }

    updatePersonPhoto(imageInfo) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_AVATAR',
            value: imageInfo,
        });
    }

    updatePostalCode(postalCode) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_POSTAL_CODE',
            value: postalCode,
        });
    }

    updateRegion(region) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_REGION',
            value: region,
        });
    }

    updateRecordType(recordType) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_RECORD_TYPE',
            value: recordType,
        });
    }

    updatePersonalForm(data) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_PERSONAL_FORM',
            value: data,
        });
    }

    updateContactForm(data) {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_UPDATE_CONTACT_FORM',
            value: data,
        });
    }

    resetRegion() {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_RESET_REGION',
        });
    }

    resetPersonalForm() {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_RESET_PERSONAL_FORM',
        });
    }

    resetContactForm() {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_RESET_CONTACT_FORM',
        });
    }

    resetPersonPhoto() {
        appReduxStore.dispatch({
            type: 'AddPersonRecordActions.ON_RESET_AVATAR',
        });
    }

    setIsSaveDisabled(isPersonSaveDisabled) {
        appReduxStore.dispatch({
            type: ADD_PERSON_SET_IS_SAVE_DISABLED,
            isPersonSaveDisabled,
        });
    }
}

const actions = new AddPersonRecordActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'getChurchEntity',
        route: 'churchentity?includeInactive=true',
        rq: request.get,
    }, {
        name: 'savePerson',
        route: 'person',
        rq: request.post,
    }, {
        name: 'saveContactPreferences',
        route: 'person/{personId}/contactpreferences',
        rq: request.post,
    }, {
        name: 'savePersonPhoto',
        route: 'person/photo',
        rq: request.post,
    }, {
        name: 'getPersonalDetails',
        route: 'v2/person/{personId}?suppressMergeRedirect=true&expand=address,contactpreference,email,phone,photo,avatar',
        rq: request.get,
    },
);

export default actions;
