enum StepFormTypes {
    AttendAnEvent = 'AttendAnEvent',
    AttendAServingOpportunity = 'AttendAServingOpportunity',
    FillOutAForm = 'FillOutAForm',
    FillOutAFormApproval = 'FillOutAFormApproval',
    ListenToAudio = 'ListenToAudio',
    ReadADocument = 'ReadADocument',
    ReadText = 'ReadText',
    RegisterForAnEvent = 'RegisterForAnEvent',
    RegisterForAMinistry = 'RegisterForAMinistry',
    RegisterForAServingOpportunity = 'RegisterForAServingOpportunity',
    Section = 'Section',
    TakeAQuiz = 'TakeAQuiz',
    VisitALink = 'VisitALink',
    WatchAVideo = 'WatchAVideo',
}

export default StepFormTypes;
