import { combineReducers } from 'redux';
import churchCampus from './churchCampus';
import churchCampuses from './churchCampuses';
import churchCampusesCache from './churchCampusesCache';
import imageUploader from '../imageUploader/imageUploader.reducer.js';
import worshipServices from './worshipServices.reducer.js';
import userAuthenticationInfo from '../useUserAuthenticationInfo/userAuthenticationInfo.reducer';

const reducers = combineReducers({
    churchCampus,
    churchCampuses,
    churchCampusesCache,
    imageUploader,
    userAuthenticationInfo,
    worshipServices,
});

export default reducers;
