enum SortTypes {
    FirstNameASC = 'firstname',
    FirstNameDSC = '-firstname',
    LastNameASC = 'lastname',
    LastNameDSC = '-lastname',
    DateAddedASC = 'dateadded',
    DateAddedDSC = '-dateadded',
}

export default SortTypes;
