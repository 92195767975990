import {
    Button,
    Drawer,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { i18n } from '../../global/constants.js';
import LoadMoreTrigger from '../../js/components/loadMoreTrigger.jsx';
import PossibleDuplicatesActions from './possibleDuplicatesActions.js';
import { coreMilestonesPropTypes } from '../record/global/coreMilestonesPropTypes.js';
// eslint-disable-next-line import/no-named-as-default
import PersonPanelAccordionItem from './personPanelAccordionItem.jsx';

const propTypes = {
    activeLoadMore: PropTypes.bool.isRequired,
    canLoadMore: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
        actionBtns: PropTypes.shape({}),
        coreMilestonesWrapper: PropTypes.shape({}),
        coreMilestones: PropTypes.shape({}),
    }),
    coreMilestonesDataByPersonIds: PropTypes.arrayOf(
        coreMilestonesPropTypes,
    ),
    isMobile: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    needsToRequest: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onSetActiveLoadMore: PropTypes.func.isRequired,
    possibleDuplicatesList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    useThisRecord: PropTypes.func,
};

const defaultProps = {
    classes: {},
    coreMilestonesDataByPersonIds: [],
    isOpen: false,
    possibleDuplicatesList: [],
    useThisRecord: undefined,
};

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            isMobile,
        },
        people: {
            addPerson: {
                possibleDuplicates: {
                    data: possibleDuplicatesList,
                    isFetching: isFetchingPossibleDuplicates,
                    canLoadMore,
                    needsToRequest,
                    pageNumber,
                    pageSize,
                },
            },
            record: {
                coreMilestones: {
                    dataByPersonIds: coreMilestonesDataByPersonIds,
                },
            },
        },
    } = state;

    return {
        canLoadMore,
        coreMilestonesDataByPersonIds,
        isFetchingPossibleDuplicates,
        isMobile,
        needsToRequest,
        pageNumber,
        pageSize,
        possibleDuplicatesList,
    };
};

export const BEM_BLOCK_NAME = 'possible_duplicates_drawer';
export class PossibleDuplicatesDrawer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onLoadMoreChange = this.onLoadMoreChange.bind(this);
        this.onSelectPerson = this.onSelectPerson.bind(this);
    }

    onLoadMoreChange(isVisible) {
        if (isVisible) {
            const {
                canLoadMore,
                onNextPage,
                onSetActiveLoadMore,
                needsToRequest,
            } = this.props;

            onSetActiveLoadMore(false).then(() => {
                if (needsToRequest) {
                    onNextPage();
                } else if (canLoadMore) {
                    PossibleDuplicatesActions.nextPage();
                    onSetActiveLoadMore(true);
                }
            });
        }
    }

    onViewRecord(personId) {
        const {
            router,
        } = this.props;
        router.push(`/people/search-or-add/record/${personId}`);
    }

    onSelectPerson(person) {
        const {
            useThisRecord,
        } = this.props;
        PossibleDuplicatesActions.getPersonalDetails({
            personId: person.id,
        }).then((res) => {
            useThisRecord(res);
        });
    }

    render() {
        const {
            activeLoadMore,
            canLoadMore,
            coreMilestonesDataByPersonIds,
            isMobile,
            isOpen,
            possibleDuplicatesList,
            onClose,
        } = this.props;

        const personAccordionItemList = _.map(possibleDuplicatesList, (person) => {
            let coreMilestonesData;

            if (!_.isEmpty(coreMilestonesDataByPersonIds)) {
                coreMilestonesData = _.find(
                    coreMilestonesDataByPersonIds, (
                        coremilestone,
                    ) => person.id === coremilestone.uniquePersonID,
                );
            }

            return (
                <PersonPanelAccordionItem
                    coreMilestonesData={coreMilestonesData}
                    person={{
                        ...person,
                        profilePhotoUrl: person.photoUrl,
                        profilePictureUrl: person.avatarUrl,
                    }}
                    isMatchPercentageVisible
                    isSelectRecordVisible
                    onSelect={this.onSelectPerson}
                />
            );
        });

        return (
            <Drawer
                className={BEM_BLOCK_NAME}
                isOpen={isOpen}
                onClose={onClose}
                id={BEM_BLOCK_NAME}
            >
                <Drawer.TitleBar
                    className={`${BEM_BLOCK_NAME}--title_bar`}
                    closeButton={(
                        <Button
                            className={`${BEM_BLOCK_NAME}--close`}
                            color="alternate"
                            icon
                        >
                            <Icon
                                compact
                                onClick={onClose}
                                type="times"
                            />
                        </Button>
                    )}
                    title={i18n('Possible Duplicates')}
                />

                <Drawer.Content>
                    <React.Fragment>
                        {personAccordionItemList}
                        {
                            canLoadMore ? (
                                <div
                                    className="margin-top text-center"
                                    style={{ marginBottom: !isMobile ? '88px' : '0' }}
                                >
                                    <LoadMoreTrigger
                                        active={activeLoadMore}
                                        onChange={this.onLoadMoreChange}
                                    />
                                </div>
                            ) : null
                        }
                    </React.Fragment>
                </Drawer.Content>
            </Drawer>
        );
    }
}

PossibleDuplicatesDrawer.propTypes = propTypes;
PossibleDuplicatesDrawer.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps)(PossibleDuplicatesDrawer));
