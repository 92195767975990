import './appHeader.scss';

import React, {
    createRef,
    useState,
    useEffect,
} from 'react';
import { ModalDeprecated } from '@saddlebackchurch/react-cm-ui';
import { makeStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import {
    get,
    findLast,
    isEmpty,
    noop,
} from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translationFactory } from '../../global/i18nUtils.js';
import { isInMobileAppMode } from '../../global/utils/environments.js';
import ModalOld from '../../js/components/Modal.react.js';
import UserNotificationPreferences from '../../js/components/User/UserNotificationPreferences.jsx';
import { onLogOut } from '../app.actions.js';
import { removeCollapsedStateSessionKey } from '../navigation/navigationUtils.jsx';
import PreviewFeatureModal from '../previewFeatureModal.js';
import AppHeaderDesktop from './appHeaderDesktop';
import AppHeaderMobile from './appHeaderMobile.jsx';
import { RELEASE_NOTES_OPTION_ID } from './constants';

const propTypes = {
    isLoginDisabled: PropTypes.bool,
    isMobile: PropTypes.bool,
    onReleaseNotesVersionSelectDialogOpen: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    sectionalTabsItems: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    sectionalTabsOnBeforeChange: PropTypes.func,
};

const defaultProps = {
    isLoginDisabled: false,
    isMobile: false,
    sectionalTabsItems: [],
    sectionalTabsOnBeforeChange: noop,
};

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            isMobile,
        },
        navigation: {
            sectionalTabsItems,
            sectionalTabsOnBeforeChange,
        },
    } = state;

    return {
        isMobile,
        sectionalTabsItems,
        sectionalTabsOnBeforeChange,
    };
};

const i18n = translationFactory('App');

const useStyles = makeStyles((theme) => ({
    root: {
        '& .app-header-desktop': {
            '& .button_dropdown': {
                margin: 0,
                padding: 0,
                '&-open': {
                    '& .icon-caret-down': {
                        '& .icon-use-path': {
                            fill: `${theme.palette.cyan[500]} !important`,
                        },
                    },
                },
                '& .user-menu_full-name': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    alignItems: 'center',
                },
                '& .user-menu-avatar_container': {
                    display: 'inline-flex',
                },
            },
            '& .app-bar': {
                padding: '10px 22px',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-start',
                position: 'relative',
                margin: 0,
            },
            '&--inner-navigation-container': {
                height: '100%',
            },
            '& .sectional-tabs-container': {
                width: '100%',
                position: 'relative',
                bottom: -7,
            },
            '& .navigation_sectional_tabs--container': {
                width: '100%',
            },
            '&--title': {
                fontSize: 16,
                fontWeight: 700,
            },
            '&--sub_title': {
                fontSize: 16,
                fontWeight: 600,
                color: get(theme, 'palette.text.secondary'),
            },
        },
    },
}));

function AppHeader(props) {
    const {
        isLoginDisabled,
        isMobile,
        onReleaseNotesVersionSelectDialogOpen,
        router,
        sectionalTabsItems,
        sectionalTabsOnBeforeChange,
    } = props;

    const {
        location: {
            pathname,
        },
    } = window;

    const notificationModalRef = createRef();
    const [isPreviewFeatureModalOpen, setIsPreviewFeatureModalOpen] = useState(false);
    const classes = useStyles(props);
    const [selectedSectionalTab, setSelectedSectionalTab] = useState(null);
    const [userMenuDesktopWidth, setMenuDesktopWidth] = useState(230);

    const getSelectedSectionalTab = () => {
        if (sectionalTabsItems?.length > 0) {
            // The item["to"] can be substring of pathname
            // e.g.:
            //       pathname   = /events-central/event/108/occurrence-schedule/797
            //       item["to"] = /events-central/event/108/occurrence-schedule
            const pathnameSectionalTab = findLast(
                sectionalTabsItems, (v) => pathname.includes(v.to),
            );

            if (pathnameSectionalTab) {
                return pathnameSectionalTab.key;
            }

            return get(sectionalTabsItems, '[0].key');
        }

        return null;
    };

    const nextSelectedSectionalTab = getSelectedSectionalTab();

    useEffect(() => {
        if (selectedSectionalTab !== nextSelectedSectionalTab) {
            setSelectedSectionalTab(nextSelectedSectionalTab);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionalTabsItems]);

    const onCloseNotificationPreferences = () => {
        // eslint-disable-next-line no-underscore-dangle
        notificationModalRef.current._closeModal();
    };

    const onPreviewFeatureModalToggle = () => {
        setIsPreviewFeatureModalOpen((prev) => !prev);
    };

    const onUserMenuChange = (selectedOption) => {
        switch (selectedOption.id) {
            case 'changePassword':
                router.push('/my-account/change-password');

                break;
            case 'help':
                window.open('/help/', '_blank');

                break;
            case 'logOut':
                removeCollapsedStateSessionKey();
                onLogOut();

                break;
            case 'notifications':
                router.push('/my-dashboard/notifications');

                break;
            case 'notificationPreferences':
                // eslint-disable-next-line no-underscore-dangle
                notificationModalRef.current._openModal();

                break;
            case 'previewFeature':
                onPreviewFeatureModalToggle();

                break;
            case RELEASE_NOTES_OPTION_ID:
                onReleaseNotesVersionSelectDialogOpen();

                break;
            case 'preferences':
                router.push('/my-account/preferences');

                break;
            default:
        }

        return null;
    };

    if (isLoginDisabled) {
        return null;
    }

    const areSelectedActiveSectionalTabsMismatched = !isEmpty(selectedSectionalTab) &&
        !isEmpty(nextSelectedSectionalTab) &&
        selectedSectionalTab !== nextSelectedSectionalTab;

    if (areSelectedActiveSectionalTabsMismatched) {
        setSelectedSectionalTab(nextSelectedSectionalTab);
    }

    return (
        <div
            className={classes.root}
            data-testid="app_header"
        >
            {isMobile && !isInMobileAppMode ? (
                <AppHeaderMobile
                    onUserMenuChange={onUserMenuChange}
                />
            ) : (
                <AppHeaderDesktop
                    onUserMenuChange={onUserMenuChange}
                    router={router}
                    sectionalTabsItems={sectionalTabsItems}
                    sectionalTabsOnBeforeChange={sectionalTabsOnBeforeChange}
                    selectedSectionalTab={selectedSectionalTab}
                    setMenuDesktopWidth={setMenuDesktopWidth}
                    setSelectedSectionalTab={setSelectedSectionalTab}
                    userMenuDesktopWidth={userMenuDesktopWidth}
                />
            )}

            <ModalOld
                minWidth="550px"
                modalClass="modal-notification-preferences"
                ref={notificationModalRef}
                width="550px"
            >
                <UserNotificationPreferences
                    onClose={onCloseNotificationPreferences}
                />
            </ModalOld>

            <ModalDeprecated
                autoHeight
                className="preview-feature-modal"
                height="auto"
                isOpen={isPreviewFeatureModalOpen}
                minHeight="none"
                onClose={onPreviewFeatureModalToggle}
                title={i18n('Preview Feature')}
                width={350}
            >
                <PreviewFeatureModal />
            </ModalDeprecated>
        </div>
    );
}

AppHeader.propTypes = propTypes;
AppHeader.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps)(AppHeader));
