import { Relationship } from '../models';
import types from './types/relationshipConnection.types';

interface DefaultStateType {
    isFetching: boolean;
    data?: Relationship[];
}

interface RelationshipAction extends DefaultStateType {
    type?: types;
    result?: Relationship[];
}

const DEFAULT_STATE: DefaultStateType = {
    isFetching: true,
    data: [],
};

export default (
    state: DefaultStateType = DEFAULT_STATE,
    action: RelationshipAction,
) => {
    switch (action.type) {
        case types.REQUEST_RELATIONSHIPS: {
            return {
                ...state,
                isFetching: true,
            };
        }

        case types.RECEIVE_RELATIONSHIP: {
            return {
                ...state,
                isFetching: false,
                data: action.result,
            };
        }

        case types.RESET_RELATIONSHIP: {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
