import request from 'axios';
import ApiUtils from '../../../../global/apiUtils.js';
import { appReduxStore } from '../../../../global/configureReduxStore.js';

const STATUS_CHECK_INTERVAL_IN_MILLISECONDS = 2 * 60 * 1000; // 2 mins

class CommonExportActions {
    constructor() {
        this.status = null;
        this.statusCheckerTimer = null;
        this.actions = new ApiUtils(
            '/api/export/status/',
        );

        this.actions.buildReduxActionClass(
            { name: 'checkStatus', rq: request.get, route: '{params}' },
        );

        this.checkStatus = this.checkStatus.bind(this);
    }

    checkStatus(status) {
        console.log('checkStatus', status); // eslint-disable-line no-console
        const params = `?ExpirationDateUtcTicks=${status.expirationDateUtcTicks}&FileName=${status.fileName}&Type=${status.type}`;
        return this.actions.checkStatus({ params })
            .then((progress) => { this.exportProgressUpdated(progress); });
    }

    disableStatusChecker() {
        if (this.statusCheckerTimer) {
            this.statusCheckerTimer = clearInterval(this.statusCheckerTimer);
        }
    }

    enableStatusChecker() {
        if (!this.status?.isCompleted && !this.statusCheckerTimer) {
            this.statusCheckerTimer = setInterval(
                this.checkStatus,
                STATUS_CHECK_INTERVAL_IN_MILLISECONDS,
                this.status,
            );
        }
    }

    exportCompleted(result) {
        console.log('Firing \'Export Completed\' action...'); // eslint-disable-line no-console
        this.disableStatusChecker();
        appReduxStore.dispatch({
            result,
            type: 'CommonExportActions.EXPORT_COMPLETED',
        });
    }

    exportFailed(result) {
        console.log('Firing \'Export Failed\' action...'); // eslint-disable-line no-console
        this.disableStatusChecker();
        appReduxStore.dispatch({
            result,
            type: 'CommonExportActions.EXPORT_FAILED',
        });
    }

    exportInitiated(result) {
        appReduxStore.dispatch({
            result,
            type: 'CommonExportActions.EXPORT_INITIATED',
        });
        console.log('exportInitiated', result); // eslint-disable-line no-console
        this.status = result;
        this.enableStatusChecker();
    }

    exportProgressUpdated(result) {
        console.log('exportProgressUpdated', result); // eslint-disable-line no-console
        if (result.isCompleted) {
            if (result.isSuccessful) {
                this.exportCompleted(result);
            } else {
                this.exportFailed(result);
            }
        } else {
            this.resetStatusChecker();
        }
    }

    resetExport() {
        appReduxStore.dispatch({ type: 'CommonExportActions.ON_RESETEXPORT' });
    }

    resetStatusChecker() {
        console.log('resetStatusChecker', this.status); // eslint-disable-line no-console

        this.disableStatusChecker();
        this.enableStatusChecker();
    }
}

const commonExportActions = new CommonExportActions();

export default commonExportActions;
