export const DEFAULT_STATE = {
    isClosedServingOpportunity: false,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ServingOppSettingsActions.ON_CLOSED_SERVING_OPPORTUNITY':
        {
            return {
                ...state,
                isClosedServingOpportunity: true,
            };
        }

        case 'ServingOppSettingsActions.ON_RESET_SERVING_OPPORTUNITY':
        {
            return {
                ...state,
                isClosedServingOpportunity: false,
            };
        }

        default:
            return state;
    }
};
