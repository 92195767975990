import {
    find,
    isEmpty,
} from 'lodash';
import { ACTION_SETTING } from '../constants.js';

const isAbleToSaveCancelDelayedActions = (actionSettings, dependencyType) => {
    let cancelDelayedDependency = false;

    const cancelDelayedActionsActionSetting = find(
        actionSettings,
        (as) => as.name === ACTION_SETTING.DelayedActionDependencies,
    );

    if (!isEmpty(cancelDelayedActionsActionSetting) &&
    !isEmpty(cancelDelayedActionsActionSetting.value)) {
        const cancelDelayedActionsActionSettingValue = JSON.parse(
            cancelDelayedActionsActionSetting.value,
        );

        cancelDelayedDependency = find(
            cancelDelayedActionsActionSettingValue,
            (item) => item.entityType === dependencyType,
        );
    }

    return !isEmpty(cancelDelayedDependency);
};

export default isAbleToSaveCancelDelayedActions;
