import {
    includes,
    isEmpty,
    union,
    difference,
} from 'lodash';

export const DEFAULT_STATE = {
    isFetching: false,
    dirtyMilestone: {},
    existingMilestone: {},
    mode: 'Add',
};

export default (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        case 'PeopleSettingsMilestones.ON_ACTIVE_CHANGED': {
            let newName;

            if (state.mode === 'Add') {
                newName = state.dirtyMilestone.name;
            } else {
                newName = state.existingMilestone.name;
            }

            return {
                ...state,
                dirtyMilestone: {
                    ...state.dirtyMilestone,
                    isActive: actionValue,
                    name: newName,
                },
            };
        }

        case 'PeopleSettingsMilestones.ON_SET_DRAWER_MODE': {
            return {
                ...state,
                mode: actionValue,
            };
        }

        case 'PeopleSettingsMilestones.ON_SET_NAME': {
            const updateDirtyMilestone = {
                ...state.dirtyMilestone,
                name: actionValue,
            };

            return {
                ...state,
                dirtyMilestone: updateDirtyMilestone,
            };
        }

        case 'PeopleSettingsMilestones.ON_SET_DESCRIPTION': {
            const updateDirtyMilestone = {
                ...state.dirtyMilestone,
                description: actionValue,
            };

            return {
                ...state,
                dirtyMilestone: updateDirtyMilestone,
            };
        }

        case 'PeopleSettingsMilestones.ON_SET_RECORD_TYPE_ADULT': {
            const isAdult = actionValue;
            const { allowablePersonRecordTypes } = state.dirtyMilestone;
            const updateFunc = isAdult ? union : difference;

            const newAllowablePersonRecordTypes = updateFunc(
                allowablePersonRecordTypes,
                ['Adult'],
            );

            return {
                ...state,
                dirtyMilestone: {
                    ...state.dirtyMilestone,
                    allowablePersonRecordTypes: [
                        ...newAllowablePersonRecordTypes,
                    ],
                    isAdultRecordType: actionValue,
                },
            };
        }
        case 'PeopleSettingsMilestones.ON_SET_RECORD_TYPE_STUDENT': {
            const isStudent = actionValue;
            const { allowablePersonRecordTypes } = state.dirtyMilestone;
            const updateFunc = isStudent ? union : difference;

            const newAllowablePersonRecordTypes = updateFunc(
                allowablePersonRecordTypes,
                ['Student'],
            );

            return {
                ...state,
                dirtyMilestone: {
                    ...state.dirtyMilestone,
                    allowablePersonRecordTypes: [
                        ...newAllowablePersonRecordTypes,
                    ],
                    isStudentRecordType: actionValue,
                },
            };
        }

        case 'PeopleSettingsMilestones.ON_SET_RECORD_TYPE_CHILD': {
            const isChild = actionValue;
            const { allowablePersonRecordTypes } = state.dirtyMilestone;
            const updateFunc = isChild ? union : difference;

            const newAllowablePersonRecordTypes = updateFunc(
                allowablePersonRecordTypes,
                ['Child'],
            );

            return {
                ...state,
                dirtyMilestone: {
                    ...state.dirtyMilestone,
                    allowablePersonRecordTypes: [
                        ...newAllowablePersonRecordTypes,
                    ],
                    isChildRecordType: actionValue,
                },
            };
        }

        case 'PeopleSettingsMilestones.ON_SET_MILESTONE': {
            const milestone = actionValue;

            if (!isEmpty(milestone.allowablePersonRecordTypes)) {
                if (includes(milestone.allowablePersonRecordTypes, 'Adult')) {
                    milestone.isAdultRecordType = true;
                }

                if (includes(milestone.allowablePersonRecordTypes, 'Student')) {
                    milestone.isStudentRecordType = true;
                }

                if (includes(milestone.allowablePersonRecordTypes, 'Child')) {
                    milestone.isChildRecordType = true;
                }
            }

            return {
                ...state,
                dirtyMilestone: {
                    ...milestone,
                },
                existingMilestone: {
                    ...milestone,
                },
            };
        }

        case 'PeopleSettingsMilestones.RESET_MILESTONE_DRAWER':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};
