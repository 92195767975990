import { Campus } from '../models/campus.model';

export const DEFAULT_STATE: Campus = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ChurchStructureActions.ON_GETCAMPUS':
            return action.result as Campus ?? null;

        case 'ChurchStructureActions.ON_RESET_CAMPUS':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
