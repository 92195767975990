import {
    camelCase,
    every,
    filter,
    find,
    flatMap,
    forEach,
    get,
    includes,
    intersection,
    isArray,
    isEmpty,
    isNil,
    isNumber,
    keys,
    map,
    memoize,
    size,
    some,
    union,
} from 'lodash';

import {
    DOES_NOT_ATTEND_CHURCH_ENTITY,
    UNKNOWN_CHURCH_ENTITY,
} from './constants.js';

import {
    SCOPE_ALL_MINISTRIES,
    USER_PERMISSIONS,
} from './userPermissionConstants.js';

const defaultPermissions = [{ permission: USER_PERMISSIONS.bypass, scopeIndex: -1 }];

/*
 * This 'store' encapsulates the logged in user's permissions and other
 * authorization/access control restrictions (i.e. permissions scoped by
 * campus, ministry, note category, etc.)
 */
export default class UserAccessStore {
    // Private class fields!
    // Courtesty of:
    // https://www.sitepoint.com/javascript-private-class-fields/
    // https://github.com/tc39/proposal-private-methods
    // https://www.npmjs.com/package/@babel/plugin-proposal-class-properties
    /* eslint-disable @typescript-eslint/lines-between-class-members */
    #userPermissions = [...defaultPermissions];
    #userScopes = [];
    /* eslint-enable @typescript-eslint/lines-between-class-members */

    constructor(boostrapSecurityContext = {}) {
        // this is necessary to handle if argument is explicitly `null`
        const securityCtx = boostrapSecurityContext || {};

        const userPermissions = map(securityCtx.permissions, (p) => ({
            permission: camelCase(p.permission),
            scopeIndex: p.scopeIndex,
        }));

        this.#userPermissions = this.#userPermissions.concat(userPermissions);
        this.#userScopes = securityCtx.scopes;

        // the following lines of code
        // (most of which are unreachable when `checkPermissions` is false)
        // were preserved from original `client\src\js\stores\User\UserPermissionStore.js`,
        // of which this class is part of the replacement infrastructure.
        const checkPermissions = false;

        /* istanbul ignore next */
        if (checkPermissions) {
            const knownPermissions = { ...USER_PERMISSIONS };

            forEach(userPermissions, (p) => {
                if (knownPermissions[p.permission] === undefined) {
                    console.warn(p.permission, 'Not found for Client'); // eslint-disable-line no-console
                }
            });

            forEach(userPermissions, (k, permission) => {
                if (find(userPermissions, { permission }) === undefined) {
                    console.warn(permission, 'Not found for Server'); // eslint-disable-line no-console
                }
            });
        }

        this.filterCampusSelectOptionsPerAuthorization = memoize(
            this.filterCampusSelectOptionsPerAuthorization,
            (allCampuses, permission, intersectPermissions) => `${size(allCampuses)},${permission},${intersectPermissions}`,
        );
    }

    /* ******************************************
     * Simple Permission Checks
     * ******************************************
     */
    hasPermission(permission) {
        return some(this.#userPermissions, (p) => permission === p.permission);
    }

    hasAllPermissions(necessaryPermissions) {
        return every(
            necessaryPermissions,
            (p) => some(this.#userPermissions, (u) => u.permission === p),
        );
    }

    hasAnyPermission(sufficientPermissions) {
        return some(
            sufficientPermissions,
            (p) => some(this.#userPermissions, (u) => u.permission === p),
        );
    }

    /* ******************************************
     * Scoped Permission Checks
     * ******************************************
     */

    /* ******************************************
     * Campus (Church Entity) Scope
     * ******************************************
     */
    filterCampusesPerAuthorization(
        allCampuses,
        permission,
        intersectPermissions = false,
    ) {
        return this.INTERNAL_filterCampusesPerAuthorization(
            allCampuses,
            permission,
            intersectPermissions,
            (campus) => (get(campus, 'id')),
        );
    }

    filterCampusSelectOptionsPerAuthorization(
        allCampuses,
        permission,
        intersectPermissions = false,
    ) {
        return this.INTERNAL_filterCampusesPerAuthorization(
            allCampuses,
            permission,
            intersectPermissions,
            (campus) => (get(campus, 'value')),
        );
    }

    // This method should be considered 'internal'/'private'.
    // Consumers should not clal it directly.
    // Instead they should call `filterCampusesPerAuthorization()` or
    // `filterCampusSelectOptionsPerAuthorization()`.
    // eslint-disable-next-line camelcase
    INTERNAL_filterCampusesPerAuthorization(
        allCampuses,
        permission,
        intersectPermissions,
        getCampusId,
    ) {
        if (isArray(permission)) {
            const authorizedCampuses = map(permission, (p) => (
                this.INTERNAL_filterCampusesPerAuthorization(
                    allCampuses,
                    p,
                    intersectPermissions,
                    getCampusId,
                )
            ));

            return intersectPermissions ?
                intersection(...authorizedCampuses) :
                union(...authorizedCampuses);
        }

        let authorizedCampuses = [];
        const scopesByPermission = filter(this.#userPermissions, { permission });

        forEach(scopesByPermission, (scopeByPermission) => {
            const scope = this.#userScopes[scopeByPermission.scopeIndex];

            if (!isNil(scope)) {
                const authzdCampusesForThisScope = scopeByPermission.isRegionalMode ?
                    scope.extractedChurchEntitiesForRegionalMode :
                    scope.extractedChurchEntities;

                authorizedCampuses = union(authorizedCampuses, authzdCampusesForThisScope);
            }
        });

        if (isArray(allCampuses) && allCampuses.length) {
            return filter(
                allCampuses,
                (c) => {
                    const campusId = getCampusId(c) * 1;
                    return includes(authorizedCampuses, campusId) ||
                        campusId === UNKNOWN_CHURCH_ENTITY ||
                        campusId === DOES_NOT_ATTEND_CHURCH_ENTITY;
                },
            );
        }

        return [];
    }

    isAuthorizedForCampus(churchEntityId, permission, everyPermission = true) {
        if (churchEntityId &&
            (churchEntityId !== UNKNOWN_CHURCH_ENTITY) &&
            (churchEntityId !== DOES_NOT_ATTEND_CHURCH_ENTITY)
        ) {
            if (isArray(permission)) {
                const func = everyPermission ? every : some;

                return func(permission, (p) => (
                    this.isAuthorizedForCampus(churchEntityId, p)
                ));
            }

            const scopesByPermission = filter(this.#userPermissions, { permission });

            return some(scopesByPermission, (scopeByPermission) => {
                const scope = this.#userScopes[scopeByPermission.scopeIndex];

                if (!isNil(scope)) {
                    const churchEntities = scopeByPermission.isRegionalMode ?
                        scope.extractedChurchEntitiesForRegionalMode :
                        scope.extractedChurchEntities;

                    if (some(churchEntities, (ce) => ce * 1 === churchEntityId * 1)) {
                        return true;
                    }
                }

                return false;
            });
        }

        return this.isAuthorizedForPersonsWithNoCampus(permission, everyPermission);
    }

    isAuthorizedForPersonsWithNoCampus(permission, everyPermission = true) {
        if (isArray(permission)) {
            const func = everyPermission ? every : some;

            return func(permission, (p) => (
                this.isAuthorizedForPersonsWithNoCampus(p)
            ));
        }

        const scopesByPermission = filter(this.#userPermissions, { permission });

        return some(scopesByPermission, (scopeByPermission) => {
            const scope = this.#userScopes[scopeByPermission.scopeIndex];

            if (scope.notAssignedChurchEntities) {
                return true;
            }

            return false;
        });
    }

    /* ******************************************
     * Venue Scope
     * ******************************************
     */
    isAuthorizedForVenue(churchEntityId, venueId, permission, everyPermission = true) {
        if (isArray(permission)) {
            const func = everyPermission ? every : some;

            return func(permission, (p) => (
                this.isAuthorizedForVenue(churchEntityId, venueId, p)
            ));
        }

        const scopesByPermission = filter(this.#userPermissions, { permission });
        let foundChurchEntityId = false;

        if (some(scopesByPermission, (scopeByPermission) => {
            const scope = this.#userScopes[scopeByPermission.scopeIndex];

            if (!isNil(scope)) {
                const venueIds = scope.churchEntities[churchEntityId * 1];

                if (venueIds === null) {
                    return true;
                }

                if (venueIds !== undefined) {
                    foundChurchEntityId = true;

                    if (some(venueIds, venueId * 1)) {
                        return true;
                    }
                }
            }

            return false;
        })) {
            return true;
        }

        if (foundChurchEntityId) {
            return false;
        }

        return this.isAuthorizedForCampus(churchEntityId, permission);
    }

    /* ******************************************
     * Ministry Scope
     * ******************************************
     */

    isAuthorizedForMinistryLocation(
        ministryId,
        churchEntityId,
        permission,
        everyPermission = true,
    ) {
        if (isArray(permission)) {
            const func = everyPermission ? every : some;

            return func(permission, (p) => (
                this.isAuthorizedForMinistryLocation(ministryId, churchEntityId, p)
            ));
        }

        const scopesByPermission = filter(this.#userPermissions, { permission });

        if (scopesByPermission) {
            return some(scopesByPermission, (scopeByPermission) => {
                const scope = this.#userScopes[scopeByPermission.scopeIndex];

                if (!isNil(scope?.ministries)) {
                    const ministry = scope.ministries[SCOPE_ALL_MINISTRIES] ?
                        scope.ministries[SCOPE_ALL_MINISTRIES] :
                        scope.ministries[ministryId * 1];

                    if (!isNil(ministry)) {
                        if (some(
                            ministry.extractedChurchEntities,
                            (ce) => ce * 1 === churchEntityId * 1,
                        )) {
                            return true;
                        }
                    }
                }

                return false;
            });
        }

        return false;
    }

    isAuthorizedForMinistry(ministryId, permission, everyPermission = true) {
        if (isArray(permission)) {
            const func = everyPermission ? every : some;

            return func(permission, (p) => (
                this.isAuthorizedForMinistry(ministryId, p)
            ));
        }

        const scopesByPermission = filter(this.#userPermissions, { permission });

        if (!isNil(scopesByPermission)) {
            return some(scopesByPermission, (scopeByPermission) => {
                const scope = this.#userScopes[scopeByPermission.scopeIndex];

                if (scope.ministries) {
                    const ministry = scope.ministries[SCOPE_ALL_MINISTRIES] ?
                        scope.ministries[SCOPE_ALL_MINISTRIES] :
                        scope.ministries[ministryId * 1];

                    if (!isNil(ministry)) {
                        return true;
                    }
                }

                return false;
            });
        }

        return false;
    }

    isAuthorizedForServingOpportunity(
        ministryId,
        churchEntityId,
        servingOpportunityId,
        permission,
        everyPermission = true,
    ) {
        if (isArray(permission)) {
            const func = everyPermission ? every : some;

            return func(permission, (p) => (
                this.isAuthorizedForServingOpportunity(
                    ministryId,
                    churchEntityId,
                    servingOpportunityId,
                    p,
                )));
        }

        const scopesByPermission = filter(this.#userPermissions, { permission });

        if (scopesByPermission) {
            return some(scopesByPermission, (scopeByPermission) => {
                const scope = this.#userScopes[scopeByPermission.scopeIndex];

                if (scope.ministries) {
                    const ministry = scope.ministries[SCOPE_ALL_MINISTRIES] ?
                        scope.ministries[SCOPE_ALL_MINISTRIES] :
                        scope.ministries[ministryId * 1];

                    if (!isNil(ministry)) {
                        if (isEmpty(ministry.servingOpportunities)) {
                            if (some(
                                ministry.extractedChurchEntities,
                                (ce) => ce * 1 === churchEntityId * 1,
                            )) {
                                return true;
                            }
                        } else if (some(
                            ministry.servingOpportunities,
                            (so) => so * 1 === servingOpportunityId * 1,
                        )) {
                            return true;
                        }
                    }
                }

                return false;
            });
        }

        return false;
    }

    /* ******************************************
     * Event Scope
     * ******************************************
     */

    isAuthorizedForEventId(eventId, permission, everyPermission = true) {
        if (isArray(permission)) {
            const func = everyPermission ? every : some;

            return func(permission, (p) => (
                this.isAuthorizedForEventId(eventId, p)
            ));
        }

        const scopesByPermission = filter(this.#userPermissions, { permission });

        if (!isNil(scopesByPermission)) {
            return some(scopesByPermission, (scopeByPermission) => {
                const scope = this.#userScopes[scopeByPermission.scopeIndex];

                if (!isNil(scope?.events) && some(scope.events, (se) => se * 1 === eventId * 1)) {
                    return true;
                }

                return false;
            });
        }

        return false;
    }

    isAuthorizedForEvent(eventId, ministryId, churchEntityId, permission, everyPermission = true) {
        if (isNumber(eventId) && eventId > 0) {
            if (this.isAuthorizedForEventId(eventId, permission, everyPermission)) {
                return true;
            }
        }
        if (isNumber(ministryId) && ministryId > 0) {
            if (this.isAuthorizedForMinistryLocation(
                ministryId,
                churchEntityId,
                permission,
                everyPermission,
            )) {
                return true;
            }
        }
        return this.isAuthorizedForCampus(churchEntityId, permission, everyPermission);
    }

    /* ******************************************
     * Note Category
     * ******************************************
     */

    getAuthzdNotesCategoryIdsByPermission(permission) {
        const permissions = filter(this.#userPermissions, { permission });

        return isEmpty(permissions) ?
            [] :
            union(
                flatMap(permissions, (p) => {
                    const scope = this.#userScopes[p.scopeIndex];
                    return map(
                        keys(scope.noteCategories),
                        (key) => +key,
                    );
                }),
            );
    }

    /* ******************************************
     * Connection Forms
     * ******************************************
     */

    isAuthorizedForForm(churchEntityId, formId, permission) {
        const scopesByPermission = filter(this.#userPermissions, { permission });

        if (isEmpty(scopesByPermission)) {
            return false;
        }

        if (this.isAuthorizedForCampus(churchEntityId, permission, true)) {
            return true;
        }

        const hasFormScopedPermission = some(scopesByPermission, (scopeByPermission) => {
            const scope = this.#userScopes[scopeByPermission.scopeIndex];

            if (isNil(scope?.connectionForms) || scope?.connectionForms?.includes(formId)) {
                return true;
            }

            return false;
        });

        return hasFormScopedPermission;
    }
}
