import request from 'axios';
import ApiUtils from '../global/apiUtils.js';
import { appReduxStore } from '../global/configureReduxStore.js';

class AppSettingsActions extends ApiUtils {
    reset() {
        appReduxStore.dispatch({ type: 'AppSettingsActions.RESET' });
    }
}

const actions = new AppSettingsActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'getAppSettings',
        route: 'app-settings',
        rq: request.get,
    },
    {
        name: 'getGradeAdvancementRules',
        route: 'app-settings/grade-advancement-rules',
        rq: request.get,
    },
    {
        name: 'updateGradeAdvancementRules',
        route: 'app-settings/grade-advancement-rules',
        rq: request.put,
    },
    {
        name: 'getAgreement',
        route: 'agreement/latest?name={agreementName}',
        rq: request.get,
    },
    {
        name: 'consentToAgreement',
        route: 'person/{personId}/agreement',
        rq: request.post,
    },
    {
        name: 'updateAgreement',
        route: 'agreement/{agreementId}',
        rq: request.put,
    },
);

export default actions;
