import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';
import PersonRelationship from '../../global/models/personRelationshipV2.model';
import Relationship from '../models/relationship.model';
import RelationshipConnectionType from './types/relationshipConnection.types';
/**
 * TODO: Need permission checks for each of these baed on user ID for:
 * Personal, Relationships, CoreMilestones and all other implemented endpoints.
 * A hasPermission boolean should be passed from here to APIUtils to fire off an event updating
 * isFetching and a new property (TBD) to explain to the components why isFetching was toggled to
 * false and endpoint was never called.
 */

class RelationshipConnectionsActions extends ApiUtils {
    resetRelationships() {
        appReduxStore.dispatch({
            type: RelationshipConnectionType.RESET_RELATIONSHIP,
        });
    }

    getRelationships: (params: {
        personId: number;
        checkInValidation?: boolean;
        occurrenceId?: number;
        onlyFamily?: boolean;
    }) => Promise<Relationship[]>;

    getRelationshipsV2: (params: {
        personId: number;
    }) => Promise<PersonRelationship[]>;
}

const actions = new RelationshipConnectionsActions('/api/');

actions.buildReduxActionClass(
    {
        name: 'getRelationships',
        route: 'person/{personId}/connection',
        rq: request.get,
    },
    {
        name: 'getRelationshipsV2',
        route: 'v2/person/{personId}/relationship',
        rq: request.get,
    },
);

export default actions;
