const DEFAULT_STATE = {
    has403Error: false,
    has404Error: false,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'ON_403_FORBIDDEN_RECVD':
            return { ...state, has403Error: true };
        case 'CLEAR_403_FORBIDDEN_ERROR':
            return { ...state, has403Error: false };
        case 'ON_404_NOT_FOUND_RECVD':
            return { ...state, has404Error: true };
        case 'CLEAR_404_NOT_FOUND_ERROR':
            return { ...state, has404Error: false };
    }

    return state;
}
