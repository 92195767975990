enum GivingPlanType {
    OneTimeGift = 1,
    OneTimeScheduledGift = 2,
    RecurringMonthlyGift = 3,
    RecurringWeeklyGift = 4,
    RecurringBiWeeklyGift = 5,
    Recurring1stAnd16thGift = 6,
    RecurringYearlyGift = 7,
}

export default GivingPlanType;
