import _ from 'lodash';
import Utils from '../../global/utils/utils.js';

const DEFAULT_STATE = {
    canSave: false,
    dirtyQuestion: {
        active: true,
    },
    existingQuestion: {},
    isSaving: false,
};

const canSaveQuestion = (question) => !_.isNil(question.questionTypeId) &&
    !Utils.isStringNullOrWhiteSpace(question.name) &&
    !Utils.isStringNullOrWhiteSpace(question.title);

export default (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
        result: actionResult,
    } = action;

    switch (actionType) {
        case 'ConnectionQuestionEditorActions.ON_ACTIVE_CHANGED':
            return {
                ...state,
                dirtyQuestion: {
                    ...state.dirtyQuestion,
                    active: actionValue,
                },
            };

        case 'ConnectionQuestionEditorActions.ON_SET_NAME':
        {
            const updatedDirtyQuestion = {
                ...state.dirtyQuestion,
                name: actionValue,
            };

            return {
                ...state,
                canSave: canSaveQuestion(updatedDirtyQuestion),
                dirtyQuestion: updatedDirtyQuestion,
            };
        }

        case 'ConnectionQuestionEditorActions.ON_SET_DESCRIPTION':
            return {
                ...state,
                dirtyQuestion: {
                    ...state.dirtyQuestion,
                    description: actionValue,
                },
            };

        case 'ConnectionQuestionEditorActions.ON_SET_IS_SENSITIVE':
            return {
                ...state,
                dirtyQuestion: {
                    ...state.dirtyQuestion,
                    isSensitive: actionValue,
                },
            };

        case 'ConnectionQuestionEditorActions.ON_SET_MILESTONE':
            return {
                ...state,
                dirtyQuestion: {
                    ...state.dirtyQuestion,
                    milestoneId: actionValue,
                },
            };

        case 'ConnectionQuestionEditorActions.ON_SET_QUESTION_TYPE':
        {
            const updatedDirtyQuestion = {
                ...state.dirtyQuestion,
                questionTypeId: actionValue,
            };

            return {
                ...state,
                canSave: canSaveQuestion(updatedDirtyQuestion),
                dirtyQuestion: updatedDirtyQuestion,
            };
        }

        case 'ConnectionQuestionEditorActions.ON_SET_TITLE':
        {
            const updatedDirtyQuestion = {
                ...state.dirtyQuestion,
                title: actionValue,
            };

            return {
                ...state,
                canSave: canSaveQuestion(updatedDirtyQuestion),
                dirtyQuestion: updatedDirtyQuestion,
            };
        }

        case 'ConnectionQuestionEditorActions.ON_SET_QUESTION_AND_EDIT':
            return {
                ...state,
                canSave: canSaveQuestion(actionValue),
                dirtyQuestion: { ...actionValue },
                existingQuestion: { ...actionValue },
            };

        case 'ConnectionQuestionEditorActions.ON_GETFOREDIT':
            return {
                ...state,
                canSave: canSaveQuestion(actionResult),
                dirtyQuestion: {
                    ...actionResult,
                },
                existingQuestion: {
                    ...actionResult,
                },
            };

        case 'ConnectionQuestionEditorActions.RESET':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};
