import {
    Button,
    Drawer,
    Icon,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import {
    findIndex,
    isNil,
} from 'lodash';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import ClassNames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '../../../global/constants.js';
import ConnectionCardEntryDrawerContent from '../../../connectionCards/global/connectionCardEntryDrawerContent.jsx';
import ConnectionFormEntryDrawerContent from '../../../connectionForms/entries/global/readOnlyViews/personRecordFormEntryDrawerContent.jsx';
import FormEntryPropType from './formEntryPropType.js';
import FormEntryType from './formEntryType';
import Utils from '../../../global/utils/utils.js';

const propTypes = {
    classes: PropTypes.shape({
        drawerActionBarPaginationCount: PropTypes.string,
        drawerTitleBarSubTitle: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    entriesCount: PropTypes.number,
    entriesData: PropTypes.arrayOf(FormEntryPropType),
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSelectedEntryChanged: PropTypes.func.isRequired,
    selectedEntry: FormEntryPropType,
};

const defaultProps = {
    className: undefined,
    entriesCount: 0,
    entriesData: [],
    isOpen: false,
    selectedEntry: null,
};

const styles = ({ palette, typography }) => ({
    drawerActionBarPaginationCount: {
        fontSize: typography.pxToRem(18),
        fontWeight: typography.fontWeightBold,
    },
    drawerTitleBarSubTitle: {
        color: palette.text.secondary,
    },
});

const bemBlockName = 'connection_cards_entry_drawer';

class FormEntryDrawer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isDrawerFullyOpen: false,
            isPersonInfoVisible: true,
            selectedEntryIndex: null,
        };

        this.onDrawerCloseComplete = this.onDrawerCloseComplete.bind(this);
        this.onDrawerOpenComplete = this.onDrawerOpenComplete.bind(this);
        this.onEntryNext = this.onEntryNext.bind(this);
        this.onEntryPrev = this.onEntryPrev.bind(this);
        this.onToggleShowPersonInfo = this.onToggleShowPersonInfo.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            selectedEntry: prevSelectedEntry,
        } = prevProps;

        const {
            entriesData,
            selectedEntry: nextSelectedEntry,
        } = this.props;

        if (prevSelectedEntry !== nextSelectedEntry) {
            const {
                formEntryId,
                formEntryType,
            } = nextSelectedEntry;

            const selectedEntryIndex = findIndex(
                entriesData,
                (e) => e.formEntryType === formEntryType && e.formEntryId === formEntryId,
            );

            this.setState({
                selectedEntryIndex: selectedEntryIndex >= 0 ?
                    selectedEntryIndex :
                    null,
            });
        }
    }

    onDrawerOpenComplete() {
        this.setState({
            isDrawerFullyOpen: true,
        });
    }

    onDrawerCloseComplete() {
        this.setState({
            isDrawerFullyOpen: false,
        });
    }

    onEntryPrev() {
        const { selectedEntryIndex } = this.state;
        this.onSelectedEntryChanged(selectedEntryIndex - 1);
    }

    onEntryNext() {
        const { selectedEntryIndex } = this.state;
        this.onSelectedEntryChanged(selectedEntryIndex + 1);
    }

    onSelectedEntryChanged(nextIndex) {
        const {
            entriesData,
            onSelectedEntryChanged,
        } = this.props;

        const nextSelectedEntry = entriesData[nextIndex];
        onSelectedEntryChanged(nextSelectedEntry);
    }

    onToggleShowPersonInfo() {
        this.setState(({ isPersonInfoVisible }) => ({
            isPersonInfoVisible: !isPersonInfoVisible,
        }));
    }

    render() {
        const {
            classes,
            className,
            entriesCount,
            isOpen,
            onClose,
            selectedEntry,
        } = this.props;

        const {
            isDrawerFullyOpen,
            isPersonInfoVisible,
            selectedEntryIndex,
        } = this.state;

        const containerClassNames = ClassNames(bemBlockName, className);

        const subTitleParts = [];

        if (selectedEntry?.formEntryIsForWorshipService) {
            subTitleParts.push(`${i18n('Service')}:`);
        } else if (selectedEntry?.formEntryEventId) {
            subTitleParts.push(`${i18n('Event')}:`);
        }

        if (!isNil(selectedEntry?.formEntryDateTime)) {
            subTitleParts.push(
                moment.tz(
                    selectedEntry.formEntryDateTime,
                    selectedEntry.formEntryTimeZone,
                ).format('L LT z'),
            );
        }

        const subTitle = subTitleParts.join(' ');

        const currentEntryPositionCount = selectedEntryIndex + 1;
        const isNextEntryButtonDisabled = currentEntryPositionCount === entriesCount;
        const isPrevEntryButtonDisabled = selectedEntryIndex === 0;

        const drawerTitleBarSubTitleClasses = ClassNames(
            `${bemBlockName}--sub_title`,
            classes.drawerTitleBarSubTitle,
        );

        const drawerActionBarPaginationCountClasses = ClassNames(
            `${bemBlockName}--pagination_count`,
            classes.drawerActionBarPaginationCount,
        );

        return (
            <Drawer
                className={containerClassNames}
                isOpen={isOpen}
                onCloseComplete={this.onDrawerCloseComplete}
                onOpenComplete={this.onDrawerOpenComplete}
            >
                <Drawer.TitleBar
                    closeButton={(
                        <Button
                            className={`${bemBlockName}--close_drawer`}
                            color="alternate"
                            icon
                            onClick={onClose}
                            title={i18n('Close Drawer')}
                        >
                            <Icon
                                compact
                                title={false}
                                type="times"
                            />
                        </Button>
                    )}
                    title={(
                        <div className="title">
                            <Typography
                                className={`${bemBlockName}--title`}
                                component="div"
                                variant="h2"
                            >
                                {`${selectedEntry?.formTemplateName} - ${selectedEntry?.churchEntityName}`}
                            </Typography>
                            <Typography
                                className={drawerTitleBarSubTitleClasses}
                                component="div"
                            >
                                {subTitle}
                            </Typography>
                        </div>
                    )}
                />

                <Drawer.ActionBar
                    columns={[
                        {
                            flexGrow: 1,
                            list: [
                                {
                                    jsx: (
                                        <Icon
                                            className={`${bemBlockName}--show_person_info`}
                                            color={isPersonInfoVisible ? 'highlight' : null}
                                            id={`${bemBlockName}--show_person_info`}
                                            onClick={this.onToggleShowPersonInfo}
                                            title={isPersonInfoVisible ? i18n('Hide Personal Information') : i18n('Show Personal Information')}
                                            type="text"
                                        />
                                    ),
                                },
                            ],
                        }, {
                            button: {
                                className: `${bemBlockName}--prev_entry`,
                                color: 'alternate',
                                disabled: isPrevEntryButtonDisabled,
                                iconType: 'chevron-left',
                                id: `${bemBlockName}--prev_entry`,
                                onClick: this.onEntryPrev,
                                title: i18n('Previous Entry'),
                            },
                        }, {
                            button: {
                                className: `${bemBlockName}--next_entry`,
                                color: 'alternate',
                                disabled: isNextEntryButtonDisabled,
                                iconType: 'chevron-right',
                                id: `${bemBlockName}--next_entry`,
                                onClick: this.onEntryNext,
                                title: i18n('Next Entry'),
                            },
                            style: {
                                paddingLeft: 0,
                                paddingRight: 0,
                            },
                        }, {
                            jsx: (
                                <span className={drawerActionBarPaginationCountClasses}>
                                    {Utils.format(i18n('Entry: {0} of {1}'), currentEntryPositionCount, entriesCount)}
                                </span>
                            ),
                        },
                    ]}
                />

                <Drawer.Content>
                    {/* eslint-disable no-nested-ternary */}
                    {
                        !isNil(selectedEntry) ?
                            selectedEntry.formEntryType === FormEntryType.ConnectionCard_V1 ? (
                                <ConnectionCardEntryDrawerContent
                                    formEntryId={selectedEntry.formEntryId}
                                    formTemplateId={selectedEntry.formTemplateId}
                                    isDrawerFullyOpen={isDrawerFullyOpen}
                                    isPersonInfoVisible={isPersonInfoVisible}
                                />
                            ) : (
                                <ConnectionFormEntryDrawerContent
                                    formEntryId={selectedEntry.formEntryId}
                                    isDrawerFullyOpen={isDrawerFullyOpen}
                                    isPersonInfoVisible={isPersonInfoVisible}
                                />
                            ) :
                            null
                    }
                    {/* eslint-enable no-nested-ternary */}
                </Drawer.Content>
            </Drawer>
        );
    }
}

FormEntryDrawer.propTypes = propTypes;
FormEntryDrawer.defaultProps = defaultProps;

export default withStyles(styles)(FormEntryDrawer);
