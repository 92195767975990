import './personEmergencyContact.scss';

import {
    Dropdown,
    Grid,
    Image,
    Prompt,
} from '@saddlebackchurch/react-cm-ui';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import domUtils from '../../global/domUtils.js';
import { translationFactory } from '../../global/i18nUtils.js';
import { personDefaultProps, personPropTypes } from '../record/global/personPropTypes.js';
import EmergencyContactFormActions from '../record/global/emergencyContactFormActions.js';

const propTypes = {
    personalData: personPropTypes,
    relationsList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
};

const defaultProps = {
    personalData: personDefaultProps,
    relationsList: [],
};

const mapStateToProps = (state) => {
    const {
        people: {
            emergencyContact: {
                relation: {
                    data: relationsList,
                },
            },
            record: {
                emergencyContactForm: {
                    selectedEmergencyContacts,
                },
            },
        },
    } = state;

    return {
        relationsList,
        selectedEmergencyContacts,
    };
};

const i18n = translationFactory();

class PersonEmergencyContact extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showPrompt: false,
        };

        this.onChangeRelationType = this.onChangeRelationType.bind(this);
        this.onRemovePromptClick = this.onRemovePromptClick.bind(this);
        this.onRemovePromptNoClick = this.onRemovePromptNoClick.bind(this);
        this.onRemovePromptYesClick = this.onRemovePromptYesClick.bind(this);
    }

    onChangeRelationType(selectedOption) {
        const {
            personalData,
        } = this.props;

        personalData.relationType = selectedOption.value;

        EmergencyContactFormActions.updateRelationshipEmergencyContact(personalData);
    }

    onRemovePromptClick() {
        this.setState({ showPrompt: true });
    }

    onRemovePromptNoClick() {
        this.setState({ showPrompt: false });
    }

    onRemovePromptYesClick() {
        const {
            personalData: {
                id,
            },
        } = this.props;
        EmergencyContactFormActions.removeSelectedEmergencyContact(id);
        this.setState({ showPrompt: false });
    }

    render() {
        const {
            showPrompt,
        } = this.state;

        const {
            personalData,
            relationsList,
        } = this.props;

        const {
            birthDate,
            churchEntityKnown,
            churchEntityId,
            churchEntityName,
            contactPreferences: {
                doNotContact: isDoNotContact,
                doNotEmail: isDoNotEmail,
                doNotMail: isDoNotMail,
                doNotPhone: isDoNotPhone,
                doNotText: isDoNotText,
                preferredMethod,
            },
            emails,
            firstName,
            gender,
            gradeLevel,
            isAdult,
            isStudent,
            isChild,
            lastName,
            maritalStatus,
            phones,
            profilePhotoUrl,
            relationType,
        } = personalData;

        const BLOCK_CLASSNAME = 'person_emergency_contact--section';
        const BLOCK_CLASSNAME_PERSON_INFO = `${BLOCK_CLASSNAME}_person_info`;
        const relationTypeOptions = _.map(relationsList, (rt) => ({ label: rt.name, value: rt.name.replace(/[^A-Z]/gi, '') }));
        const primaryEmail = _.find(emails, 'isPrimary');
        const primaryPhone = _.find(phones, 'isPrimary');
        const emailText = primaryEmail && (
            <span
                onClick={(event) => domUtils.onMailToLinkClick(event, primaryEmail.email)}
                role="presentation"
                style={{
                    cursor: 'pointer',
                }}
            >
                {primaryEmail.email}
            </span>
        );
        const phoneText = primaryPhone && (
            <span
                onClick={(event) => {
                    domUtils.onTelLinkClick(event, primaryPhone.displayPhoneNumber);
                }}
                role="presentation"
                style={{
                    cursor: 'pointer',
                }}
            >
                {primaryPhone.displayPhoneNumber}
            </span>
        );

        let churchEntityNameText;
        let contactMethodText;
        let genderText;
        let preferredContactInfoText;

        if (_.isNil(churchEntityId)) {
            churchEntityNameText = !churchEntityKnown ? i18n('Unknown') : i18n('Does Not Attend');
        } else {
            churchEntityNameText = churchEntityName;
        }

        switch (gender) {
            case 'F':
                genderText = 'Female';

                break;
            case 'M':
                genderText = 'Male';

                break;

            default:
                genderText = '';
        }

        if (isDoNotContact) {
            contactMethodText = i18n('DoNotContactThisIndividual');
        } else {
            switch (preferredMethod) {
                case 'email':
                    contactMethodText = i18n('PrefersEmail');

                    if (emailText) {
                        preferredContactInfoText = emailText;
                    }

                    break;
                case 'phone':
                    contactMethodText = i18n('PrefersPhone');

                    if (phoneText) {
                        preferredContactInfoText = phoneText;
                    }

                    break;
                case 'text-message':
                    contactMethodText = i18n('PrefersText');

                    if (primaryPhone) {
                        preferredContactInfoText = primaryPhone.displayPhoneNumber;
                    }

                    break;
                default:
                    if (phoneText) {
                        preferredContactInfoText = phoneText;
                    } else if (emailText) {
                        preferredContactInfoText = emailText;
                    }
            }

            if (isDoNotEmail || isDoNotMail || isDoNotPhone || isDoNotText) {
                if (contactMethodText) {
                    contactMethodText += `, ${i18n('DNCVia')} `;
                } else {
                    contactMethodText = `${i18n('DNCVia')} `;
                }

                if (isDoNotEmail) {
                    contactMethodText += `${i18n('Email')}, `;
                }

                if (isDoNotMail) {
                    contactMethodText += `${i18n('Mail')}, `;
                }

                if (isDoNotPhone) {
                    contactMethodText += `${i18n('Phone')}, `;
                }

                if (isDoNotText) {
                    contactMethodText += `${i18n('Text')}`;
                }

                contactMethodText = _.trimEnd(contactMethodText, ', ');
            }
        }

        let age;
        let birthdayDate;
        let gradeLevelText;
        if (_.isEmpty(birthDate)) {
            birthdayDate = moment.unix(birthDate).utc().format('MM/DD/YY');
            age = moment().diff(birthdayDate, 'years');
        }

        switch (gradeLevel) {
            case 'PreK':
                gradeLevelText = 'PK';

                break;

            case 'Kindergarten':
                gradeLevelText = 'K';

                break;

            case 'First':
                gradeLevelText = '1';

                break;

            case 'Second':
                gradeLevelText = '2';

                break;

            case 'Third':
                gradeLevelText = '3';

                break;

            case 'Fourth':
                gradeLevelText = '4';

                break;

            case 'Fifth':
                gradeLevelText = '5';

                break;

            case 'Sixth':
                gradeLevelText = '6';

                break;

            case 'Seventh':
                gradeLevelText = '7';

                break;

            case 'Eighth':
                gradeLevelText = '8';

                break;

            case 'Ninth':
                gradeLevelText = '9';

                break;

            case 'Tenth':
                gradeLevelText = '10';

                break;

            case 'Eleventh':
                gradeLevelText = '11';

                break;

            case 'Twelfth':
                gradeLevelText = '12';

                break;

            default:
                gradeLevelText = gradeLevel;
        }

        let selectedRelationType;

        if (!_.isEmpty(relationType)) {
            selectedRelationType = _.find(relationTypeOptions,
                (opt) => opt.value === relationType) || {};
        } else {
            selectedRelationType = {};
        }

        return (
            <div className={BLOCK_CLASSNAME}>
                <Grid spacing={2}>
                    <Grid.RowDeprecated>
                        <Grid.Column width={12} tablet={6} laptop={6}>
                            <div className={`${BLOCK_CLASSNAME}_left_column`}>
                                <Image
                                    className={`${BLOCK_CLASSNAME}_person_avatar`}
                                    name={`${firstName} ${lastName}`}
                                    size={100}
                                    src={profilePhotoUrl}
                                    style={{
                                        fontSize: '24px',
                                    }}
                                    type="person"
                                />
                                <div className={BLOCK_CLASSNAME_PERSON_INFO}>
                                    <div className={`${BLOCK_CLASSNAME_PERSON_INFO}_row_first`}>
                                        <div className={`${BLOCK_CLASSNAME_PERSON_INFO}_full_name`}>
                                            {`${firstName} ${lastName}`}
                                        </div>
                                        <div className={`${BLOCK_CLASSNAME_PERSON_INFO}_gender_church_marital`}>
                                            {genderText}

                                            {isAdult && maritalStatus && ` | ${maritalStatus}`}

                                            {(isChild || isStudent) && birthDate && ` | ${age}yr ${birthdayDate}`}

                                            {(isChild || isStudent) && gradeLevel && ` | gr ${gradeLevelText}`}

                                            {churchEntityNameText && ` | ${churchEntityNameText}`}
                                        </div>
                                    </div>
                                    <div className={`${BLOCK_CLASSNAME_PERSON_INFO}_row_last`}>
                                        {contactMethodText && (
                                            <div className={`${BLOCK_CLASSNAME_PERSON_INFO}_preferred_contact_method`}>
                                                {`(${contactMethodText})`}
                                            </div>
                                        )}

                                        {preferredMethod !== 'none' && preferredContactInfoText && (
                                            <div className={`${BLOCK_CLASSNAME_PERSON_INFO}_preferred_method`}>
                                                {preferredContactInfoText}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={12} tablet={6} laptop={6}>
                            <div className={`${BLOCK_CLASSNAME}_right_column`}>
                                <div
                                    className={`${BLOCK_CLASSNAME}_relationship_label`}
                                >
                                    {i18n('Relationship Type')}
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <Dropdown
                                    clearable={false}
                                    fluid
                                    className={`${BLOCK_CLASSNAME}_relationship_select`}
                                    id={`${BLOCK_CLASSNAME}--relationship_select`}
                                    onChange={this.onChangeRelationType}
                                    options={relationTypeOptions}
                                    required
                                    selection
                                    value={selectedRelationType}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.RowDeprecated>
                    <Grid.RowDeprecated>
                        <Prompt
                            className={`${BLOCK_CLASSNAME}_remove`}
                            id={`${BLOCK_CLASSNAME}--remove_emergency_contact_prompt`}
                            inline
                            inlineMessageColor="alert"
                            message={i18n('Are You Sure?')}
                            onClick={this.onRemovePromptClick}
                            onYesClick={this.onRemovePromptYesClick}
                            onNoClick={this.onRemovePromptNoClick}
                            show={showPrompt}
                        >
                            <span
                                className={`${BLOCK_CLASSNAME}_remove`}
                            >
                                {i18n('Remove')}
                            </span>
                        </Prompt>
                    </Grid.RowDeprecated>
                </Grid>
            </div>
        );
    }
}

PersonEmergencyContact.propTypes = propTypes;
PersonEmergencyContact.defaultProps = defaultProps;

export default connect(mapStateToProps)(PersonEmergencyContact);
