import { combineReducers } from 'redux';
import connectionCards from './connectionCards/connectionCards.reducer.js';
import connectionForms from './connectionForms/connectionForms.reducer.js';
import download from './global/download.reducer.js';
import eventAttendees from './events/eventAttendee.reducer.js';
import people from './people/people.reducer.js';

const reducers = combineReducers({
    connectionCards,
    connectionForms,
    download,
    eventAttendees,
    people,
});

export default reducers;
