import { isEmpty, isNil } from 'lodash';
import Utils from '../../../../global/utils/utils.js';

export const ZERO_TIME_SPAN = '0.00:00:00';

export const isAbleToSaveFollowUpTemplate = (followUpTemplate) => {
    const {
        taskCategory,
        taskInstructions,
        taskName,
        defaultAssigneePerson,
        defaultAssigneeTeam,
        defaultTimeLimit,
        defaultSupervisor,
    } = followUpTemplate;

    // TODO default assignee person could be user or team, and only one of those two must be non-empty
    return !Utils.isStringNullOrWhiteSpace(taskName) &&
        !isNil(taskCategory) && // task category should not be null or undefined
        !Utils.isStringNullOrWhiteSpace(taskInstructions) &&
        !isNil(defaultTimeLimit) && // default time limit (due date policy) is not null or undefined
        defaultTimeLimit !== ZERO_TIME_SPAN && // default time limit (due date policy) is not zero
        !isEmpty(defaultSupervisor) &&
        (!isEmpty(defaultAssigneePerson) || !isEmpty(defaultAssigneeTeam));
};
