import ActionType from './journey.actionTypes';
import {
    Action,
} from '../global/models';
import {
    DEFAULT_FILTER_OPTIONS,
    DEFAULT_FORM_DATA,
    DEFAULT_STEP_FORM_DATA,
    DEFAULT_STEP_GROUP_FORM_DATA,
} from './global/constants';

const INITIAL_STATE = {
    areFiltersDirty: false,
    filterOptions: DEFAULT_FILTER_OPTIONS,
    formData: DEFAULT_FORM_DATA,
    statusPublishedCount: 0,
    statusUnpublishedCount: 0,
    stepFormData: DEFAULT_STEP_FORM_DATA,
    stepGroupFormData: DEFAULT_STEP_GROUP_FORM_DATA,
    stepGroups: [],
    isFiltering: false,
    journey: {},
    journeyList: [],
    ownerSearchQuery: '',
    ownerSearchResults: [],
    searchQuery: '',
    searchResults: {},
    stepTypes: [],
    suggestedJourneys: [],
};

const reducer = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.ClearFilterOptions:
            return {
                ...state,
                filterOptions: DEFAULT_FILTER_OPTIONS,
            };
        case ActionType.ClearSearchQuery:
            return {
                ...state,
                searchQuery: '',
            };
        case ActionType.SetAreFiltersDirty:
            return {
                ...state,
                areFiltersDirty: action.payload,
            };
        case ActionType.SetFilterOptions:
            return {
                ...state,
                filterOptions: action.payload,
            };
        case ActionType.SetFormData:
            return {
                ...state,
                formData: action.payload,
            };
        case ActionType.SetStepFormData:
            return {
                ...state,
                stepFormData: action.payload,
            };
        case ActionType.SetStepGroupFormData:
            return {
                ...state,
                stepGroupFormData: action.payload,
            };
        case ActionType.SetStepGroups:
            return {
                ...state,
                stepGroups: action.payload,
            };
        case ActionType.SetIsFiltering:
            return {
                ...state,
                isFiltering: action.payload,
            };
        case ActionType.SetJourney:
            return {
                ...state,
                journey: action.payload,
            };
        case ActionType.SetJourneyList:
            return {
                ...state,
                journeyList: action.payload,
            };
        case ActionType.SetOwnerSearchQuery:
            return {
                ...state,
                ownerSearchQuery: action.payload,
            };
        case ActionType.SetOwnerSearchResults:
            return {
                ...state,
                ownerSearchResults: action.payload,
            };
        case ActionType.SetSearchQuery:
            return {
                ...state,
                searchQuery: action.payload,
            };
        case ActionType.SetSearchResults:
            return {
                ...state,
                searchResults: action.payload,
            };
        case ActionType.SetStepTypes:
            return {
                ...state,
                stepTypes: action.payload,
            };
        case ActionType.SetSuggestedJourneys:
            return {
                ...state,
                suggestedJourneys: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
