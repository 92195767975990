import './contextualHelp.scss';

import { Portal } from 'react-portal';
import React from 'react';
import HelpContextualDNDContextProvider from './contextualHelpDNDContextProvider.jsx';

class HelpContextual extends React.PureComponent {
    render() {
        return (
            <Portal>
                <div>
                    <HelpContextualDNDContextProvider />
                </div>
            </Portal>
        );
    }
}

export default HelpContextual;
