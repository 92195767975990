import {
    Checkbox,
    DatePickerInput,
    Grid,
    Input,
    PhoneInput,
    Radio,
    Select,
    TextArea,
} from '@saddlebackchurch/react-cm-ui';
import {
    isBoolean,
    isNil,
    isNumber,
    isUndefined,
    map,
    noop,
    startCase,
} from 'lodash';
import ClassNames from 'classnames';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from '../i18nUtils.js';
import SelectCountry from '../selectCountry.jsx';
import SelectGender from '../selectGender.jsx';
import SelectGrade from '../selectGrade.jsx';
import SelectRegion from '../selectRegion.jsx';

const i18n = translationFactory('ConnectionCards.Global.EntryDrawerPersonalContent');

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disable: PropTypes.bool,
    error: PropTypes.bool,
    fieldId: PropTypes.string,
    fieldProps: PropTypes.shape({
        alwaysShowRequiredIndicator: PropTypes.bool,
        // For fieldType: selectGrade
        campus: PropTypes.shape({}),
        // For fieldType: inputCheckbox, inputRadioPillGroup
        checked: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]),

        // For fieldType: inputTel, to default Phone Number Country Code.  Optional.
        countryAlpha2: PropTypes.string,

        // For fieldType: selectRegion
        countryAlpha3: PropTypes.string,

        // For fieldType: selectRegion & selectCountry
        clearable: PropTypes.bool,

        // For fieldType: inputTel
        extension: PropTypes.number,
        isHome: PropTypes.bool,
        isMobile: PropTypes.bool,
        isWork: PropTypes.bool,
        onPhoneExtensionChange: PropTypes.func,
        onPhoneTypeChange: PropTypes.func,

        /**
         * Controls whether or not a numeric input accepts negative and positive numbers, or only positive numbers.
         * For fieldType `inputNumber` only.
         */
        allowNegativeNumbers: PropTypes.bool,

        /**
         * Controls max value of input number
         * For fieldType `inputNumber` only.
         */
        max: PropTypes.number,

        /**
         * Controls min value of input number
         * For fieldType `inputNumber` only.
         */
        min: PropTypes.number,

        /**
         * Determines whether or not the field allows a completely empty value
         * when the Input is also marked `required`.
         * For fieldType `inputNumber` only.
         */
        allowEmptyValueWithRequired: PropTypes.bool,

        /**
         * Adds an icon to an input.
         * For fieldTypes: `inputEmail`, `inputNumber`, `inputText`
         */
        icon: PropTypes.oneOfType([
            React.ReactNode,
            PropTypes.string,
        ]),

        /**
         * For fieldTypes: `inputEmail`, `inputNumber`, `inputText`
         */
        maxLength: PropTypes.number,
        name: PropTypes.string,
        placeholder: PropTypes.string,

        // For fieldType: inputRadioPillGroup, select
        options: PropTypes.arrayOf(PropTypes.shape({})),

        // For fieldType: textarea
        autoHeight: PropTypes.bool,

        // For fieldType: grade
        excludeGradeByRecordType: PropTypes.oneOf(['adult', 'child', 'student']),
        isRecordTypeLabelEnabled: PropTypes.bool,
        recordType: PropTypes.oneOf(['adult', 'child', 'student']),

        // For fieldType: inputDate
        maxDate: MomentPropTypes.momentObj,
        minDate: MomentPropTypes.momentObj,
    }),
    fieldType: PropTypes.oneOf([
        'inputCheckbox',
        'inputDate',
        'inputDateRange',
        'inputEmail',
        'inputNumber',
        'inputTel',
        'inputText',
        'inputRadio',
        'inputRadioPillGroup',
        'multiSelect',
        'select',
        'selectCountry',
        'selectGender',
        'selectRegion',
        'spacer',
        'textarea',
    ]),
    formatLabel: PropTypes.bool,
    id: PropTypes.string,
    isRedacted: PropTypes.bool,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            dateFrom: PropTypes.string,
            dateTo: PropTypes.string,
        }),
    ]),
    medium: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.shape({}),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({}),
        PropTypes.string,
    ]),
    width: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
};

const defaultProps = {
    children: undefined,
    className: undefined,
    disable: false,
    error: false,
    fieldId: undefined,
    fieldProps: {}, // Props and their values are defined in component function.
    fieldType: undefined,
    formatLabel: true,
    id: undefined,
    isRedacted: false,
    label: undefined,
    medium: undefined,
    onBlur: undefined,
    onFocus: undefined,
    onChange: undefined,
    required: false,
    style: {},
    value: undefined,
    width: 12,
};

function FormField(props) {
    const {
        children,
        className,
        disable,
        error,
        fieldId,
        fieldProps: newFieldProps,
        fieldType,
        formatLabel,
        id,
        isRedacted,
        label,
        medium,
        onBlur,
        onFocus,
        onChange,
        required,
        style,
        value,
        width,
    } = props;

    const fieldProps = {
        alwaysShowRequiredIndicator: isBoolean(newFieldProps.alwaysShowRequiredIndicator) ?
            newFieldProps.alwaysShowRequiredIndicator : false,
        allowNegativeNumbers: isBoolean(newFieldProps.allowNegativeNumbers) ?
            newFieldProps.allowNegativeNumbers : true,
        allowEmptyValueWithRequired: isBoolean(newFieldProps.allowEmptyValueWithRequired) ?
            newFieldProps.allowEmptyValueWithRequired : false,
        autoHeight: newFieldProps.autoHeight ?? false,
        campus: newFieldProps.campus ?? undefined,
        checked: isNumber(newFieldProps.checked) ?
            newFieldProps.checked ?? 0 :
            newFieldProps.checked ?? false,
        countryAlpha2: newFieldProps.countryAlpha2 || undefined,
        countryAlpha3: newFieldProps.countryAlpha3 || undefined,
        clearable: newFieldProps.clearable || false,
        excludeGradeByRecordType: newFieldProps.excludeGradeByRecordType || undefined,
        extension: newFieldProps.extension || '',
        icon: newFieldProps.icon ?? undefined,
        isHome: newFieldProps.isHome ?? false,
        isRecordTypeLabelEnabled: newFieldProps.isRecordTypeLabelEnabled ?? false,
        isMobile: newFieldProps.isMobile ?? false,
        isWork: newFieldProps.isWork ?? false,
        max: newFieldProps.max ?? undefined,
        maxDate: newFieldProps.maxDate ?? undefined,
        maxLength: newFieldProps.maxLength ?? undefined,
        min: newFieldProps.min ?? undefined,
        minDate: newFieldProps.minDate ?? undefined,
        name: newFieldProps.name ?? undefined,
        onPhoneExtensionChange: newFieldProps.onPhoneExtensionChange ?? undefined,
        onPhoneTypeChange: newFieldProps.onPhoneTypeChange ?? undefined,
        options: newFieldProps.options ?? undefined,
        placeholder: newFieldProps.placeholder ?? undefined,
        recordType: newFieldProps.recordType ?? undefined,
    };

    const formattedLabel = formatLabel ? startCase(label) : label;
    const bemBlockName = 'form--fields';

    const containerClasses = ClassNames(
        {
            [`${bemBlockName}-input_checkbox`]: fieldType === 'inputCheckbox',
            [`${bemBlockName}-input_date`]: fieldType === 'inputDate',
            [`${bemBlockName}-input_date_range`]: fieldType === 'inputDateRange',
            [`${bemBlockName}-input_email`]: fieldType === 'inputEmail',
            [`${bemBlockName}-input_number`]: fieldType === 'inputNumber',
            [`${bemBlockName}-input_radio`]: fieldType === 'inputRadio',
            [`${bemBlockName}-input_radio_pill_group`]: fieldType === 'inputRadioPillGroup',
            [`${bemBlockName}-input_tel`]: fieldType === 'inputTel',
            [`${bemBlockName}-input_text`]: fieldType === 'inputText',
            [`${bemBlockName}-select_country`]: fieldType === 'selectCountry',
            [`${bemBlockName}-select_gender`]: fieldType === 'selectGender',
            [`${bemBlockName}-select_grade`]: fieldType === 'selectGrade',
            [`${bemBlockName}-select_region`]: fieldType === 'selectRegion',
            [`${bemBlockName}-select`]: fieldType === 'select',
            [`${bemBlockName}-multi_select`]: fieldType === 'multiSelect',
            [`${bemBlockName}-textarea`]: fieldType === 'textarea',
        },
        className,
    );

    const newStyle = {
        ...style,
        paddingBottom: 16.5,
        paddingTop: 16.6,
    };

    switch (fieldType) {
        case 'inputCheckbox': {
            const { checked } = fieldProps;

            if (isUndefined(checked)) {
                // eslint-disable-next-line no-console
                console.warn('fieldProps.checked is required');
            }

            return (
                <Grid.Column
                    fieldType="checkbox"
                    className={containerClasses}
                    id={id}
                    style={{
                        ...newStyle,
                        paddingBottom: 5.5,
                        paddingTop: 5.5,
                    }}
                    md={medium}
                    sm={width}
                >
                    <Checkbox
                        alwaysShowRequiredIndicator={fieldProps.alwaysShowRequiredIndicator}
                        checked={checked}
                        disabled={disable}
                        fluid
                        id={fieldId}
                        isRedacted={isRedacted}
                        label={formattedLabel}
                        onChange={isRedacted ? noop : onChange}
                        required={required}
                        value={value}
                    />
                </Grid.Column>
            );
        }

        case 'inputDate': {
            const {
                maxDate,
                minDate,
            } = fieldProps;

            let momentValue = value;

            if (value && !moment.isMoment(value)) {
                momentValue = moment.unix(value).utc();
            }

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <DatePickerInput
                        alwaysShowRequiredIndicator={fieldProps.alwaysShowRequiredIndicator}
                        date={momentValue}
                        disabled={disable}
                        fluid
                        id={fieldId}
                        isRedacted={isRedacted}
                        maxDate={maxDate}
                        minDate={minDate}
                        onChange={onChange}
                        required={required}
                        label={formattedLabel}
                        tabIndex={0}
                    />
                </Grid.Column>
            );
        }

        case 'inputDateRange': {
            const {
                maxDate,
                minDate,
            } = fieldProps;

            let dateTo = value?.dateTo;
            let dateFrom = value?.dateFrom;

            if (dateTo && !moment.isMoment(dateTo)) {
                dateTo = moment.unix(dateTo).utc();
            }

            if (dateFrom && !moment.isMoment(dateFrom)) {
                dateFrom = moment.unix(dateFrom).utc();
            }

            const formattedDateFromLabel = formattedLabel?.dateFrom ?
                startCase(formattedLabel?.dateFrom) :
                null;

            const formattedDateToLabel = formattedLabel?.dateTo ?
                startCase(formattedLabel?.dateTo) :
                null;

            return (
                <React.Fragment>
                    <Grid.Column
                        md={6}
                        sm={12}
                    >
                        <DatePickerInput
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            disabled={disable}
                            fluid
                            id={fieldId}
                            maxDate={maxDate}
                            minDate={minDate}
                            onChange={onChange}
                            rangeFrom
                            required={required}
                            label={formattedDateFromLabel}
                            tabIndex={0}
                        />
                    </Grid.Column>

                    <Grid.Column
                        md={6}
                        sm={12}
                    >
                        <DatePickerInput
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            disabled={disable}
                            fluid
                            id={fieldId}
                            maxDate={maxDate}
                            minDate={minDate}
                            onChange={onChange}
                            rangeTo
                            required={required}
                            label={formattedDateToLabel}
                            tabIndex={0}
                        />
                    </Grid.Column>
                </React.Fragment>
            );
        }

        case 'inputEmail':
        case 'inputNumber':
        case 'inputText': {
            const {
                allowNegativeNumbers,
                allowEmptyValueWithRequired,
                icon,
                max,
                maxLength,
                min,
                name,
                placeholder,
            } = fieldProps;

            let inputType;
            let safeValue = value || '';

            switch (fieldType) {
                case 'inputEmail':
                    inputType = 'email';
                    break;

                case 'inputNumber': {
                    inputType = 'number';

                    // If the number input allows 0, make sure we can preserve a value of 0
                    if ((isNil(min) || min <= 0) && isNumber(value)) {
                        safeValue = value;
                    }
                    break;
                }

                case 'inputText':
                default:
                    inputType = 'text';
            }

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <Input
                        allowNegativeNumbers={allowNegativeNumbers}
                        allowEmptyValueWithRequired={allowEmptyValueWithRequired}
                        alwaysShowRequiredIndicator={fieldProps.alwaysShowRequiredIndicator}
                        disabled={disable}
                        error={error}
                        fluid
                        icon={icon}
                        id={fieldId}
                        isRedacted={isRedacted}
                        label={formattedLabel}
                        max={max}
                        maxLength={maxLength}
                        min={min}
                        name={name}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={onChange}
                        placeholder={placeholder}
                        required={required}
                        tabIndex={0}
                        type={inputType}
                        value={safeValue}
                    />
                </Grid.Column>
            );
        }

        case 'inputRadio':
            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={{
                        ...newStyle,
                        paddingBottom: 5.5,
                        paddingTop: 5.5,
                    }}
                    md={medium}
                    sm={width}
                >
                    <Radio
                        alwaysShowRequiredIndicator={fieldProps.alwaysShowRequiredIndicator}
                        checked={fieldId === value}
                        disabled={disable}
                        fluid
                        id={fieldId}
                        isRedacted={isRedacted}
                        label={formattedLabel}
                        onChange={isRedacted ? noop : onChange}
                        required={required}
                        value={value}
                    />
                </Grid.Column>
            );

        case 'inputRadioPillGroup': {
            const {
                checked,
                options,
            } = fieldProps;

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <Radio
                        checked={checked}
                        disabled={disable}
                        fluid
                        id={fieldId}
                        label={formattedLabel}
                        onChange={onChange}
                        pill
                        required={required}
                        value={value}
                    >
                        {map(options, (option) => (
                            <Radio.Item
                                id={option.id}
                                key={option.id}
                                label={option.label}
                            />
                        ))}
                    </Radio>
                </Grid.Column>
            );
        }

        case 'inputTel': {
            const {
                countryAlpha2,
                extension,
                isHome,
                isMobile,
                isWork,
                onPhoneExtensionChange,
                onPhoneTypeChange,
            } = fieldProps;

            if (isUndefined(isHome)) {
                // eslint-disable-next-line no-console
                console.warn('fieldProps.isHome is required');
            }

            if (isUndefined(isMobile)) {
                // eslint-disable-next-line no-console
                console.warn('fieldProps.isMobile is required');
            }

            if (isUndefined(isWork)) {
                // eslint-disable-next-line no-console
                console.warn('fieldProps.isWork is required');
            }

            return (
                <React.Fragment>
                    <Grid.Column
                        md={isWork ? 4 : 6}
                        sm={12}
                        style={newStyle}
                    >
                        <PhoneInput
                            country={countryAlpha2}
                            disabled={disable}
                            error={error}
                            fluid
                            id={fieldId}
                            label={formattedLabel}
                            onBlur={onBlur}
                            onChange={onChange ?? noop}
                            required={required}
                            tabIndex={0}
                            value={value || ''}
                        />
                    </Grid.Column>

                    {isWork && (
                        <Grid.Column
                            md={2}
                            sm={12}
                            style={newStyle}
                        >
                            <Input
                                disabled={disable}
                                fluid
                                id={`${fieldId}_extension`}
                                label={i18n('Extension')}
                                maxLength={7}
                                onChange={onPhoneExtensionChange}
                                required={required}
                                tabIndex={0}
                                type="text"
                                value={extension}
                            />
                        </Grid.Column>
                    )}

                    <Grid.Column
                        align="stretch"
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            paddingBottom: 22,
                        }}
                        md={6}
                        sm={12}
                    >
                        <Grid.Column sm={4}>
                            <Radio
                                checked={isHome}
                                disabled={disable}
                                id={`${fieldId}_home`}
                                label={i18n('Home')}
                                name={`${fieldId}_name`}
                                onChange={onPhoneTypeChange}
                            />
                        </Grid.Column>

                        <Grid.Column sm={4}>
                            <Radio
                                checked={isMobile}
                                disabled={disable}
                                id={`${fieldId}_mobile`}
                                label={i18n('Mobile')}
                                name={`${fieldId}_name`}
                                onChange={onPhoneTypeChange}
                            />
                        </Grid.Column>

                        <Grid.Column sm={4}>
                            <Radio
                                checked={isWork}
                                disabled={disable}
                                id={`${fieldId}_work`}
                                label={i18n('Work')}
                                name={`${fieldId}_name`}
                                onChange={onPhoneTypeChange}
                            />
                        </Grid.Column>
                    </Grid.Column>
                </React.Fragment>
            );
        }

        case 'select': {
            const {
                options,
                placeholder,
            } = fieldProps;

            if (isUndefined(options)) {
                // eslint-disable-next-line no-console
                console.warn('fieldProps.options is required');
            }

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <Select
                        alwaysShowRequiredIndicator={fieldProps.alwaysShowRequiredIndicator}
                        disabled={disable}
                        fluid
                        id={fieldId}
                        isRedacted={isRedacted}
                        label={formattedLabel}
                        onChange={onChange}
                        options={options}
                        placeholder={placeholder || i18n('Select')}
                        required={required}
                        searchable
                        tabIndex={0}
                        value={value}
                    />
                </Grid.Column>
            );
        }

        case 'multiSelect': {
            const {
                options,
                placeholder,
            } = fieldProps;

            if (isUndefined(options)) {
                // eslint-disable-next-line no-console
                console.warn('fieldProps.options is required');
            }

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <Select
                        alwaysShowRequiredIndicator={fieldProps.alwaysShowRequiredIndicator}
                        disabled={disable}
                        fluid
                        id={fieldId}
                        label={formattedLabel}
                        multiple
                        onChange={onChange}
                        options={options}
                        placeholder={placeholder || i18n('Select')}
                        required={required}
                        searchable
                        selection
                        selectionRequired={required}
                        tabIndex={0}
                        value={value}
                    />
                </Grid.Column>
            );
        }

        case 'selectCountry': {
            const {
                clearable,
            } = fieldProps;

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <SelectCountry
                        clearable={clearable}
                        disable={disable}
                        fluid
                        id={fieldId}
                        label={formattedLabel}
                        onCountryChange={onChange}
                        required={required}
                        selection
                        tabIndex={0}
                        value={value}
                    />
                </Grid.Column>
            );
        }

        case 'selectGender':
            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <SelectGender
                        disable={disable}
                        fluid
                        id={fieldId}
                        label={formattedLabel}
                        onGenderChange={onChange}
                        required={required}
                        selection
                        tabIndex={0}
                        value={value}
                    />
                </Grid.Column>
            );

        case 'selectGrade': {
            const {
                campus,
                excludeGradeByRecordType,
                isRecordTypeLabelEnabled,
                recordType,
            } = fieldProps;

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <SelectGrade
                        campus={campus}
                        disable={disable}
                        fluid
                        id={fieldId}
                        isRecordTypeLabelEnabled={isRecordTypeLabelEnabled}
                        excludeGradeByRecordType={excludeGradeByRecordType}
                        label={formattedLabel}
                        onGradeChange={onChange}
                        recordType={recordType}
                        selection
                        required={required}
                        tabIndex={0}
                        value={value}
                    />
                </Grid.Column>
            );
        }

        case 'selectRegion': {
            if (isUndefined(fieldProps)) {
                // eslint-disable-next-line no-console
                console.warn('fieldProps is required');
            }

            const {
                clearable,
                countryAlpha3,
            } = fieldProps;

            if (isUndefined(countryAlpha3)) {
                // eslint-disable-next-line no-console
                console.warn('fieldProps.countryAlpha3 is required');
            }

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <SelectRegion
                        clearable={clearable}
                        countryAlpha3={countryAlpha3}
                        disable={disable}
                        fluid
                        id={fieldId}
                        label={formattedLabel}
                        onRegionChange={onChange}
                        required={required}
                        selection
                        tabIndex={0}
                        value={value}
                    />
                </Grid.Column>
            );
        }

        case 'textarea': {
            const {
                autoHeight,
                placeholder,
            } = fieldProps;

            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    <TextArea
                        alwaysShowRequiredIndicator={fieldProps.alwaysShowRequiredIndicator}
                        autoHeight={autoHeight}
                        disabled={disable}
                        fluid
                        id={fieldId}
                        isRedacted={isRedacted}
                        label={formattedLabel}
                        onChange={onChange}
                        placeholder={placeholder}
                        required={required}
                        tabIndex={0}
                        value={value}
                    />
                </Grid.Column>
            );
        }

        case 'spacer':
        default:
            return (
                <Grid.Column
                    className={containerClasses}
                    id={id}
                    style={newStyle}
                    md={medium}
                    sm={width}
                >
                    {children}
                </Grid.Column>
            );
    }
}

FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;

export default FormField;
