export const DEFAULT_STATE = {
    blockSize: null,
    blockType: 'page',
    isDrawerChild: false,
    isSearchInitiated: false,
};

export default (state = DEFAULT_STATE, action) => {
    const actionType = 'SearchBlockTypeManagerActions';

    switch (action.type) {
        case `${actionType}.ON_RESET`: {
            return {
                ...DEFAULT_STATE,
            };
        }

        case `${actionType}.ON_SET_BLOCK_SIZE`: {
            return {
                ...state,
                blockSize: action.result,
            };
        }

        case `${actionType}.ON_SET_BLOCK_TYPE`: {
            return {
                ...state,
                blockType: action.result,
            };
        }

        case `${actionType}.ON_SET_IS_DRAWER_CHILD`: {
            return {
                ...state,
                isDrawerChild: action.result,
            };
        }

        case `${actionType}.ON_SET_IS_SEARCH_INITIATED`: {
            return {
                ...state,
                isSearchInitiated: action.result,
            };
        }

        default:
            return state;
    }
};
