import React from 'react';
import {
    Box,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import {
    isEmpty,
    isNil,
    uniq,
} from 'lodash';
import BannerUtils from '../../js/utils/BannerUtils.js';
import { appReduxStore } from '../configureReduxStore.js';
import { i18n } from '../constants.js';
import { ResponseStatus } from '../models';
import hcAuthUtils from '../utils/hcAuthUtils';
import Utils from '../utils/utils.js';

const serviceError = ({
    response,
    bannerClassName,
    bannerId,
    bannerTimeout,
    bannerTitle,
    withStatusCode = true,
}: {
    response: {
        data?: {
            command?: string[]; // specialized HC API error emssage field for "commands"
            detail?: string; // error message in an RFC 7807 Problem style response
            errors?: Record<string, string[]>; // ASP.NET model state dictionary
            import?: string[]; // specialized HC API error emssage field for Import Service
            message?: string; // error message in an HC API legacy Stadard Error Response
            system?: string[]; // specialized HC API error emssage field for "system" errors
        };
        status: ResponseStatus; // HTTP Response Status Code
        statusText: string; // HTTP Response Status "reason phrase"
    };
    bannerId?: string;
    bannerClassName?: string;
    bannerTimeout?: number;
    bannerTitle?: string;
    withStatusCode?: boolean;
}) => {
    if (!isNil(response)) {
        /**
         * Custom Redirects
         */
        if (response.status === ResponseStatus.UNAUTHORIZED) {
            /**
             * 401 Unauthorized (with really means "unauthenticated", i.e. access token is invalid or has expired).
             * In this case we want to redirect the user to login again.
             */
            console.info('Recv\'d 401 Unauthorized response from API');

            hcAuthUtils.logout();
        } else if (response.status === ResponseStatus.FORBIDDEN) {
            /**
             * 403 Forbidden (which means "not authorized", i.e. does not have permission).
             * Do a Redux dispatch that will redirect user to Sad Dog Page,
             */
            appReduxStore.dispatch({ type: 'ON_403_FORBIDDEN_RECVD' });
        } else {
            /**
             * Handle remaining 400s and 500s
             */
            const responseLevel = Math.floor(response.status / 100);

            /**
             * If we have a response level
             */
            if (responseLevel > 0) {
                let errorTitle = i18n('Application Error');
                let responseMessage = null;
                let validationErrorItemsJsx = null;
                let cmd = false;

                const {
                    data: responseData,
                } = response;

                if (!isNil(responseData)) {
                    const {
                        command: responseDataCommand,
                        detail: responseErrorMessage_DetailField, // eslint-disable-line @typescript-eslint/naming-convention
                        errors: responseDataErrors, // this is expected to be an ASP.NET model state dictionary
                        import: responseDataImport,
                        message: responseErrorMessage_MsgField, // eslint-disable-line @typescript-eslint/naming-convention
                        system: responseDataSystem,
                    } = responseData;

                    const responseErrorMessage =
                        responseErrorMessage_DetailField ?? responseErrorMessage_MsgField;

                    const hasErrorMessage = !isEmpty(responseErrorMessage);
                    const hasErrorDictionary = !isNil(responseDataErrors);

                    const isModelValidationError = response.status === ResponseStatus.BAD_REQUEST &&
                        (hasErrorMessage || hasErrorDictionary);

                    if (isModelValidationError) {
                        errorTitle = i18n('Request is invalid:');
                    }

                    if (hasErrorMessage) {
                        const msg = responseErrorMessage;

                        if (msg.indexOf('|') > 0) { // pipe-delimited list of validation (model state) errors
                            const msgs = msg.split('|');
                            validationErrorItemsJsx = uniq(msgs).map((m) => (<li key={`error-${Utils.getIncreasingUniqueKey()}`}>{m}</li>));
                        } else { // just a normal error message string
                            responseMessage = msg;
                        }
                    } else if (hasErrorDictionary) {
                        validationErrorItemsJsx = Object.values(responseDataErrors).flatMap((e) => (<li key={`error-${Utils.getIncreasingUniqueKey()}`}>{e}</li>));
                    } else if (responseDataCommand) {
                        // eslint-disable-next-line prefer-destructuring
                        responseMessage = responseDataCommand[0];
                        cmd = true;
                    } else if (responseDataImport) {
                        // eslint-disable-next-line prefer-destructuring
                        responseMessage = responseDataImport[0];
                        cmd = true;
                    } else if (responseDataSystem) {
                        responseMessage = responseDataSystem;
                        cmd = true;
                    }
                }

                const hasValidationErrorsList = !isNil(validationErrorItemsJsx);
                const bannerErrorTitle = bannerTitle ?? errorTitle;

                BannerUtils.addBanner({
                    className: bannerClassName,
                    id: bannerId,
                    level: responseLevel === 5 ? 'error' : 'warning',
                    timeout: bannerTimeout,
                    title: bannerErrorTitle,
                    type: 'notification',
                    children: (
                        <React.Fragment>
                            <Typography
                                variant="body2"
                            >
                                {cmd ? `${i18n('Response')} : ${responseMessage}` : responseMessage}
                            </Typography>

                            {hasValidationErrorsList ? (
                                <ul>
                                    {validationErrorItemsJsx}
                                </ul>
                            ) : null}

                            {withStatusCode && !cmd && (
                                <Typography
                                    classes={{
                                        body2: 'text-muted',
                                    }}
                                    variant="body2"
                                >
                                    <Box
                                        fontSize="12px !important"
                                        mt={1}
                                    >
                                        {`${i18n('Status Code')}: ${response.status} ${response.statusText}`}
                                    </Box>
                                </Typography>
                            )}
                        </React.Fragment>
                    ),
                });
            } else {
                console.error('We just got an error message from the API without a Status Code.');
            }

            console.info(response);
        }
    } else {
        console.error('Non-XHR error caught in APIUtils. Response was undefined.');
        console.info(response);
    }
};

export default serviceError;
