import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const pagedUnscheduledVolunteers = new ChunkedPaginationUtils(20, 60);

export const DEFAULT_STATE = {
    isFetching: true,
    results: [],
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedUnscheduledVolunteers.getPageSize(),
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunityVolunteerActions.ON_BEFORE_GETUNSCHEDULEDVOLUNTEERS': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'MinistryServingOpportunityVolunteerActions.ON_GETUNSCHEDULEDVOLUNTEERS':
            pagedUnscheduledVolunteers.loadPage(
                action.result.volunteers,
                action.result.total,
                action.callbackParams.first,
            );
            // Deliberately fall through to 'Next Page' case

        // eslint-disable-next-line no-fallthrough
        case 'MinistryServingOpportunityVolunteerActions.ON_GET_UNSCHEDULED_VOLUNTEERS_NEXTPAGE': {
            return {
                isFetching: false,
                results: pagedUnscheduledVolunteers.getAll(true),
                total: pagedUnscheduledVolunteers.getTotalCount(),
                canLoadMore: pagedUnscheduledVolunteers.canLoadMore(),
                needsToRequest: pagedUnscheduledVolunteers.needsToLoadPage(),
                pageNumber: pagedUnscheduledVolunteers.getCurrentPageNumber(),
                pageSize: pagedUnscheduledVolunteers.getPageSize(),
            };
        }

        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_UNSCHEDULED_VOLUNTEERS': {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
}
