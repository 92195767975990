import { isNil } from 'lodash';
import { doesConditionRequireParameter } from './conditionUtils.js';
import Utils from '../../../global/utils/utils.js';

// eslint-disable-next-line import/prefer-default-export
export const isRuleEntityConditionValid = (entityCondition) => {
    const {
        entityType,
        entityId,
        condition,
        parameter,
        isParameterIncomplete,
    } = entityCondition;

    return !isNil(entityType) &&
        !isNil(entityId) &&
        !isNil(condition) &&
        (
            !doesConditionRequireParameter(condition) ||
            (
                !Utils.isStringNullOrWhiteSpace(parameter) &&
                !isParameterIncomplete
            )
        );
};
