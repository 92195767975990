import { getFollowUpsInsightsData } from './followUpsDashboard.reducerTransforms.js';

const DEFAULT_STATE = {
    followUpsDashboardData: {},
    isFetching: false,
    followUpsDashboardCalculatedData: {},
};

const followUpsDashboard = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'FollowUpsDashboardActions.ON_BEFORE_GETFOLLOWUPSDASHBOARDDATA':
            return { ...state, isFetching: true };

        case 'FollowUpsDashboardActions.ON_GETFOLLOWUPSDASHBOARDDATA':
            return {
                ...state,
                isFetching: false,
                followUpsDashboardData: action.result,
                followUpsDashboardCalculatedData: getFollowUpsInsightsData(action.result),
            };

        default:
            return state;
    }
};

export default followUpsDashboard;
