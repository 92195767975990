import {
    cmAuthUtils,
} from '../global/utils/utils.js';
import { appReduxStore } from '../global/configureReduxStore.js';
import LoginConstants from '../js/constants/Login/LoginConstants.js';

// eslint-disable-next-line import/prefer-default-export
export function onLogOut() {
    return cmAuthUtils.logout(() => {
        appReduxStore.dispatch({ type: LoginConstants.LOGOUT });
    });
}
