import {
    Grid,
    Select,
} from '@saddlebackchurch/react-cm-ui';
import {
    capitalize,
    isEmpty,
    isNil,
    isString,
} from 'lodash';
import {
    Controller,
} from 'react-hook-form';
import ClassNames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';

type PropTypes = {
    className?: string | null;
    control: any;
    disabled?: boolean;
    error?: boolean | string | null;
    formatLabel?: boolean;
    id?: string | null;
    label?: string | null;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
    name: string;
    options: {
        label: string;
        value: number | string;
    }[] | null;
    placeholder?: string | null;
    rules?: {
        required?: boolean;
        validate?: any;
    };
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    root: {
        paddingBottom: spacing(1.5),
        paddingTop: spacing(1.5),
    },
}));

function FormFieldSelect({
    className = null,
    control,
    disabled = false,
    error = null,
    formatLabel = true,
    id = null,
    label = null,
    md = null,
    name,
    options,
    placeholder = null,
    rules = null,
    sm = 12,
}: PropTypes) {
    const classes = useStyles();

    const formattedLabel = formatLabel && isString(formatLabel) ?
        label.replace(/\w+/g, capitalize) :
        label;

    const rootClasses = ClassNames(
        'form--field_select',
        classes.root,
        className,
    );

    return (
        <Grid.Column
            className={rootClasses}
            md={md}
            sm={sm}
        >
            <Controller
                control={control}
                name={name}
                render={({
                    field: {
                        onChange,
                        value,
                    },
                }) => (
                    <Select
                        disabled={disabled}
                        error={error}
                        fluid
                        id={id}
                        label={formattedLabel}
                        onChange={onChange}
                        options={options}
                        placeholder={placeholder}
                        required={!isNil(rules)}
                        searchable
                        tabIndex={0}
                        value={!isEmpty(value) ? value : null}
                    />
                )}
                rules={rules}
            />
        </Grid.Column>
    );
}
export default FormFieldSelect;
