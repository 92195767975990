import _ from 'lodash';

export const DEFAULT_STATE = {
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryCacheActions.ON_RESET':
            return DEFAULT_STATE;

        case 'MinistryCacheActions.ON_GET':
            return { ...state, ..._.keyBy(action.result, 'id') };
    }
    return state;
}
