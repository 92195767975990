/**
 * This component renders a single Section of a Connection Forms 2.0 Form Entry.
 */

import {
    concat,
    filter,
    find,
    includes,
    isNil,
    map,
    sortBy,
} from 'lodash';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
    canUserReadSensitiveConnectionQuestionAnswers,
    doesSectionHaveSensitiveQuestion,
    isAnswerRedacted,
} from '../../entryEditor/utils.js';
import { i18n } from '../../../../global/constants.js';
import Form from '../../../../global/form';
import FormEntryContainer from './formEntryContainer.jsx';
import FormEntryField from './formEntryField.jsx';
import FormFieldType from './formFieldType';
import InformationalIcon from '../../../../global/informationalIcon';

const propTypes = {
    agreements: PropTypes.arrayOf(PropTypes.shape({})),
    answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    bemBlockName: PropTypes.string.isRequired,
    disableFields: PropTypes.bool,
    entryChurchEntityId: PropTypes.number,
    formSection: PropTypes.shape({
        containers: PropTypes.arrayOf(PropTypes.shape({})),
        fields: PropTypes.arrayOf(PropTypes.shape({})),
        name: PropTypes.string,
    }).isRequired,
    hideRedactedAnswers: PropTypes.bool,
};

const defaultProps = {
    agreements: null,
    disableFields: false,
    entryChurchEntityId: undefined,
    hideRedactedAnswers: false,
};

function FormEntrySection({
    agreements,
    answers,
    bemBlockName,
    disableFields,
    entryChurchEntityId,
    formSection,
    hideRedactedAnswers,
}) {
    const {
        containers,
        fields,
        name: sectionTitle,
    } = formSection;

    const userAccess = useSelector((state) => state.bootstrap.securityContext.userAccess);

    const mappedContainers = map(containers, (c) => ({
        ...c,
        type: FormFieldType.Container,
    }));

    const mappedFields = map(fields, (f) => ({
        ...f,
        type: FormFieldType.Field,
    }));

    const combinedFields = sortBy(
        concat(mappedFields, mappedContainers),
        (f) => f.order,
    );

    const canReadSensitiveConnectionQuestionAnswers = canUserReadSensitiveConnectionQuestionAnswers(
        userAccess,
        entryChurchEntityId,
    );

    const hasSensitiveQuestion = doesSectionHaveSensitiveQuestion(formSection);

    const informationalMessage = canReadSensitiveConnectionQuestionAnswers ?
        i18n('Some responses are sensitive. Please treat them with care.') :
        i18n('Some responses are redacted for privacy purposes');

    return (
        <Form.Fieldset
            icon={(
                <InformationalIcon
                    isVisible={hasSensitiveQuestion}
                    message={informationalMessage}
                />
            )}
            title={sectionTitle}
        >
            {map(combinedFields, (f) => {
                if (f.type === FormFieldType.Field) {
                    let answer = find(answers, (a) => f.questionId === a.questionId);

                    if (hideRedactedAnswers && isAnswerRedacted(answer)) {
                        return null;
                    }

                    if (!isNil(f.agreement)) {
                        answer = agreements?.find(
                            (a) => f.agreementUniqueId === a.agreement?.uniqueId,
                        );
                    }

                    return (
                        <FormEntryField
                            answer={answer}
                            bemBlockName={bemBlockName}
                            disable={disableFields}
                            formField={f}
                            key={`field-${f.questionId}`}
                        />
                    );
                }

                // else Container
                const containerFieldQuestionIds = map(f.fields, (cf) => cf.questionId);

                const answersForContainerField = filter(
                    answers,
                    (a) => includes(containerFieldQuestionIds, a.questionId),
                );

                return (
                    <FormEntryContainer
                        answers={answersForContainerField}
                        bemBlockName={bemBlockName}
                        disableFields={disableFields}
                        formContainerField={f}
                        hideRedactedAnswers={hideRedactedAnswers}
                        key={`container-${f.id}`}
                    />
                );
            })}
        </Form.Fieldset>
    );
}

FormEntrySection.propTypes = propTypes;
FormEntrySection.defaultProps = defaultProps;

export default FormEntrySection;
