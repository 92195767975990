import {
    without,
} from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const getFilteredArrayByIsChecked = (
    stateFiltersArr,
    defaultFiltersArr,
    filterId,
    isChecked,
) => {
    let newFilteredArr = [];

    if (isChecked) {
        newFilteredArr = [
            ...stateFiltersArr,
            filterId,
        ];
    } else if (stateFiltersArr.length === 1) {
        newFilteredArr = without(
            defaultFiltersArr,
            filterId,
        );

        if (defaultFiltersArr.length > 2) {
            newFilteredArr = [newFilteredArr[0]];
        }
    } else {
        newFilteredArr = without(
            stateFiltersArr,
            filterId,
        );
    }

    return newFilteredArr;
};
