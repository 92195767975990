import {
    get,
    isEmpty,
} from 'lodash';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

const pagedFormTemplatesList = new ChunkedPaginationUtils(25, 25);

export const DEFAULT_STATE = {
    isFetching: false,
    results: {
        canLoadMore: false,
        forms: [],
        needsToRequest: false,
        pageNumber: 0,
        pageSize: pagedFormTemplatesList.getPageSize(),
        total: 0,
    },
    statistics: null,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    const first = !isEmpty(callbackParams) ? callbackParams.first : null;

    switch (actionType) {
        case 'ConnectionFormTemplatesActions.ON_BEFORE_SEARCH':
            if (first) {
                return { ...state, isFetching: true };
            }

            return state;

        case 'ConnectionFormTemplatesActions.ON_SEARCH':
        {
            const {
                forms,
            } = actionResult;

            // up-to-date statistics are obtained only on the first page
            const statistics = first ? actionResult.statistics : state.statistics;

            const total = get(statistics, 'activeCount', 0) + get(statistics, 'inactiveCount', 0);

            pagedFormTemplatesList.loadPage(forms, total, first);
            const formTemplates = pagedFormTemplatesList.getAll(true);

            return {
                ...state,
                statistics,
                isFetching: false,
                results: {
                    ...state.results,
                    forms: formTemplates,
                    canLoadMore: pagedFormTemplatesList.canLoadMore(),
                    needsToRequest: pagedFormTemplatesList.needsToLoadPage(),
                    pageNumber: pagedFormTemplatesList.getCurrentPageNumber(),
                    pageSize: pagedFormTemplatesList.getPageSize(),
                    total,
                },
            };
        }

        case 'ConnectionFormTemplatesActions.ON_NEXT_PAGE_REQUESTED':
        {
            const forms = pagedFormTemplatesList.getAll(true);

            return {
                ...state,
                results: {
                    ...state.results,
                    forms,
                    canLoadMore: pagedFormTemplatesList.canLoadMore(),
                    needsToRequest: pagedFormTemplatesList.needsToLoadPage(),
                },
            };
        }

        case 'ConnectionFormTemplatesActions.ON_RESET_SEARCH':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
