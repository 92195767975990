import {
    color,
    colorInverse,
    colorHighlight,
} from 'global/styles/colors.scss';

import {
    Grid,
    Image,
    ModalDeprecated,
} from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles,
    withTheme,
} from '@saddlebackchurch/react-cm-ui/core/styles'; // eslint-disable-line import/extensions
import ClassNames from 'classnames';
import AppStore from '../js/stores/AppStore.js';
import BannerUtils from '../js/utils/BannerUtils.js';
import { translationFactory } from './i18nUtils.js';
import AvatarManagerUpload from './avatarManagerUpload.jsx';
import { resetAvatar, updatePersonPhoto } from './avatarManagerActions.js';

const propTypes = {
    avatarImage: PropTypes.shape({
        filename: PropTypes.string,
        url: PropTypes.string,
    }),
    classes: PropTypes.shape({
        root: PropTypes.string,
        imagePreview: PropTypes.string,
        inputFileType: PropTypes.string,
        removePhotoLink: PropTypes.string,
    }),
    fullName: PropTypes.string,
    type: PropTypes.oneOf(['person', 'user']),
};

const defaultProps = {
    avatarImage: {},
    classes: {},
    fullName: '',
    type: 'person',
};

const mapStateToProps = (state) => {
    const {
        people: {
            addPerson: {
                avatar: {
                    avatarImage,
                },
            },
        },
    } = state;

    return {
        avatarImage,
    };
};

const i18n = translationFactory();
const MODAL_WIDTH_FOR_DESKTOP = 294;
export const BLOCK_CLASS_NAME = 'person_add--person_avatar';
const styles = (theme) => ({
    root: {
        marginBottom: '33px !important',
        [`& ${BLOCK_CLASS_NAME}_image.no-image::before`]: {
            color,
            content: '+',
            fontWeight: 700,
        },
        [`& ${BLOCK_CLASS_NAME}_upload_modal`]: {
            '.modal-container': {
                backgroundColor: `${colorInverse} !important`,
            },
            '.modal-container-inner': {
                padding: '22px !important',
            },
            '.modal-children': {
                paddingTop: '44px !important',
            },
            '.modal-close-button': {
                backgroundColor: 'transparent !important',
                minWidth: '32px !important',
                '.icon-use-path': {
                    fill: 'color !important',
                },
            },
            '.modal-header': {
                backgroundColor: 'colorInverse) !important',
                color,
                padding: '22px 22px 11px !important',
            },
        },
    },
    imagePreview: {
        alignItems: 'center',
        display: 'flex',
    },
    uploadPhotoLink: {
        color: colorHighlight,
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: '22px',
    },
    inputFileType: {
        display: 'none',
    },
    removePhotoLink: {
        color: colorHighlight,
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: '22px',
    },
    [theme.breakpoints.up('md')]: {
        [`& ${BLOCK_CLASS_NAME}_upload_modal`]: {
            '.modal-container': {
                minWidth: '294px !important',
            },
            '.modal-container-inner': {
                padding: '22px !important',
            },
            '.modal-children': {
                paddingTop: '44px !important',
            },
            '.modal-close-button': {
                backgroundColor: 'transparent !important',
                '.icon-use-path': {
                    fill: '#000 !important',
                },
            },
            '.modal-header': {
                padding: '22px 22px 11px !important',
            },
        },
    },
});

export class AvatarManager extends React.PureComponent {
    constructor(props) {
        super(props);

        this.uint32Array = new Uint32Array(1);

        this.state = {
            avatarInputKey: crypto.getRandomValues(this.uint32Array)[0],
            isAvatarSaved: false,
            isOpenAvatarImageModal: false,
        };

        this.onCloseAvatarImageModal = this.onCloseAvatarImageModal.bind(this);
        this.onOpenAvatarImageModal = this.onOpenAvatarImageModal.bind(this);
        this.onHandleAvatarImageChange = this.onHandleAvatarImageChange.bind(this);
        this.onRemoveAvatarImage = this.onRemoveAvatarImage.bind(this);
        this.onSetAvatar = this.onSetAvatar.bind(this);
    }

    componentWillUnmount() {
        resetAvatar();
    }

    onOpenAvatarImageModal() {
        this.setState((prevState) => ({
            isOpenAvatarImageModal: !prevState.isOpenAvatarImageModal,
        }));
    }

    onCloseAvatarImageModal() {
        this.setState({
            isOpenAvatarImageModal: false,
            avatarInputKey: crypto.getRandomValues(this.uint32Array)[0],
            isAvatarSaved: false,
        });
    }

    onHandleAvatarImageChange(e, modalOpen = false) {
        e.preventDefault();
        const reader = new FileReader();
        const avatarFile = e.target.files[0];
        const avatarFileName = e.target.files[0].name;
        const fileAllowedTypes = ['image/png', 'image/jpeg'];

        if (!avatarFile) {
            return;
        }

        if (avatarFile.size > AppStore.getUploadingOptions().maxEmailAttachmentSize) {
            BannerUtils.addBanner({
                children: (
                    <p style={{ margin: 0 }}>
                        {i18n('The attachment is too large')}
                    </p>
                ),
                id: 'ui-banner--attachment_too_large',
                level: 'error',
                timeout: true,
                type: 'notification',
            });
            return;
        }

        if (!fileAllowedTypes.includes(avatarFile.type)) {
            BannerUtils.addBanner({
                children: (
                    <p style={{ margin: 0 }}>
                        {i18n('Invalid File Format')}
                    </p>
                ),
                id: 'ui-banner--invalid_file_format',
                level: 'error',
                timeout: true,
                type: 'notification',
            });
            return;
        }

        reader.onloadend = () => {
            updatePersonPhoto({
                filename: avatarFileName,
                url: reader.result,
            });
        };
        reader.readAsDataURL(avatarFile);
        if (modalOpen) {
            this.onOpenAvatarImageModal();
        }
    }

    onRemoveAvatarImage() {
        this.setState({
            avatarInputKey: crypto.getRandomValues(this.uint32Array)[0],
            isAvatarSaved: false,
        });
        resetAvatar();
    }

    onSetAvatar(name, url) {
        this.setState({
            isAvatarSaved: true,
        }, () => {
            updatePersonPhoto({
                filename: name,
                url,
            });
            this.onOpenAvatarImageModal();
        });
    }

    render() {
        const {
            avatarInputKey,
            isAvatarSaved,
            isOpenAvatarImageModal,
        } = this.state;

        const {
            classes,
            fullName,
            avatarImage: {
                filename: avatarFileName,
                url: avatarPreviewUrl,
            },
            type,
        } = this.props;

        const blockClassNameImage = `${BLOCK_CLASS_NAME}_image`;
        const blockClassNamesImage = ClassNames(blockClassNameImage, {
            'no-image': !isAvatarSaved && !fullName,
        });

        return (
            <Grid
                className={classes.root}
                spacing={2}
            >
                <Grid.RowDeprecated columns={1}>
                    <Grid.Column>
                        <div className={classes.imagePreview}>
                            <Image
                                border={isAvatarSaved && avatarPreviewUrl ? 3 : undefined}
                                borderInverse={!!(isAvatarSaved && avatarPreviewUrl)}
                                className={blockClassNamesImage}
                                id={`${BLOCK_CLASS_NAME}--image_preview`}
                                name={fullName || '+'}
                                size={100}
                                src={isAvatarSaved && avatarPreviewUrl ? avatarPreviewUrl : null}
                                style={{
                                    fontSize: '24px',
                                }}
                                type={type}
                            />
                            {avatarPreviewUrl && isAvatarSaved ?
                                (
                                    <span
                                        className={classes.removePhotoLink}
                                        id={`${BLOCK_CLASS_NAME}--remove_photo_link`}
                                        onClick={this.onRemoveAvatarImage}
                                        role="button"
                                    >
                                        {i18n('Remove Profile Photo')}
                                    </span>
                                ) : (
                                    <label
                                        className={classes.uploadPhotoLink}
                                        id={`${BLOCK_CLASS_NAME}--upload_photo_link`}
                                    >
                                        <input
                                            accept="image/png, image/jpeg"
                                            data-test="uploadPhoto"
                                            id={`${BLOCK_CLASS_NAME}--file_upload`}
                                            type="file"
                                            className={classes.inputFileType}
                                            onChange={
                                                (e) => this.onHandleAvatarImageChange(e, true)
                                            }
                                            key={avatarInputKey}
                                        />
                                        {i18n('Upload Profile Photo')}
                                    </label>
                                )}
                        </div>

                        <ModalDeprecated
                            autoHeight
                            className={`${BLOCK_CLASS_NAME}_upload_modal`}
                            id={`${BLOCK_CLASS_NAME}--avatar_upload_modal`}
                            isOpen={isOpenAvatarImageModal}
                            height="auto"
                            onClose={this.onCloseAvatarImageModal}
                            title={i18n('Profile Image')}
                            width={MODAL_WIDTH_FOR_DESKTOP}
                        >
                            <AvatarManagerUpload
                                avatarFileName={avatarFileName}
                                avatarPreviewUrl={avatarPreviewUrl}
                                onHandleAvatarImageChange={this.onHandleAvatarImageChange}
                                onSave={this.onSetAvatar}
                            />
                        </ModalDeprecated>
                    </Grid.Column>
                </Grid.RowDeprecated>
            </Grid>
        );
    }
}

AvatarManager.propTypes = propTypes;
AvatarManager.defaultProps = defaultProps;

export default withStyles(styles)(connect(mapStateToProps)(withTheme(AvatarManager)));
