import {
    Box,
    Divider,
    Grid,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.node,
    id: PropTypes.string,
    divider: PropTypes.bool,
    required: PropTypes.bool,
    style: PropTypes.shape({}),
    subTitle: PropTypes.string,
    title: PropTypes.string,
};

const defaultProps = {
    children: undefined,
    className: undefined,
    divider: true,
    icon: undefined,
    id: undefined,
    required: false,
    style: {},
    subTitle: '',
    title: '',
};

function FormFieldset(props) {
    const {
        children,
        className,
        divider,
        icon,
        id,
        required,
        style,
        subTitle,
        title,
    } = props;

    const bemBlockName = 'form--fieldset';

    const rootClasses = ClassNames(
        bemBlockName,
        className,
    );

    return (
        <Grid.Column
            className={rootClasses}
            id={id}
            sm={12}
            style={{
                ...style,
                paddingBottom: 16.5,
                paddingTop: 16.5,
            }}
        >
            {title && (
                <Grid
                    spacing={2}
                >
                    <Grid.Column
                        className={`${bemBlockName}_title_column`}
                        id={`${bemBlockName}_title_column_${id}`}
                        sm={12}
                        style={{
                            paddingBottom: 16.5,
                            paddingTop: 11,
                        }}
                    >
                        <Typography
                            gutterBottom
                            variant="h3"
                        >
                            <span>{title}</span>

                            {required ? (
                                <Box
                                    color="error.main"
                                    component="span"
                                    fontSize="body2.fontSize"
                                    fontWeight="body2.fontWeight"
                                    ml={0.5}
                                    sx={{
                                        verticalAlign: 'top',
                                    }}
                                >
                                    *
                                </Box>
                            ) : null}

                            {icon}
                        </Typography>

                        {subTitle && (
                            <Typography
                                color="textSecondary"
                                varient="body2"
                            >
                                {subTitle}
                            </Typography>
                        )}
                    </Grid.Column>
                </Grid>
            )}

            {children && (
                <Grid
                    spacing={2}
                >
                    {children}
                </Grid>
            )}

            <Divider
                hidden={!divider}
                style={{ margin: '33px 0 0' }}
            />
        </Grid.Column>
    );
}

FormFieldset.propTypes = propTypes;
FormFieldset.defaultProps = defaultProps;

export default FormFieldset;
