import {
    filter,
    isEmpty,
    map,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
    UX_TYPE_EVENTS,
    UX_TYPE_SERVING_OPPORTUNITIES,
} from './constants.js';

export const DEFAULT_STATE = {
    isTempServingOpportuntiesLoaded: false,
    tempServingOpportunities: [],
};

const localStorageEventsSOKey = 'tempEventsServingOpportunities';
const localStorageSOKey = 'tempEventsServingOpportunities';

const getTempServingOpportunitiesFromStorage = (ux) => {
    switch (ux) {
        case UX_TYPE_EVENTS:
            return JSON.parse(
                localStorage.getItem(localStorageEventsSOKey),
            ) || [];
        case UX_TYPE_SERVING_OPPORTUNITIES:
        default:
            return JSON.parse(
                localStorage.getItem(localStorageSOKey),
            ) || [];
    }
};

const removeTempServingOpportunitiesFromStorage = (ux) => {
    switch (ux) {
        case UX_TYPE_EVENTS:
            return localStorage.removeItem(localStorageEventsSOKey);
        case UX_TYPE_SERVING_OPPORTUNITIES:
        default:
            return localStorage.removeItem(localStorageSOKey);
    }
};

const setTempServingOpportunitiesToStorage = (ux, data) => {
    switch (ux) {
        case UX_TYPE_EVENTS:
            return localStorage.setItem(
                localStorageEventsSOKey,
                JSON.stringify(data),
            );
        case UX_TYPE_SERVING_OPPORTUNITIES:
        default:
            return localStorage.setItem(
                localStorageSOKey,
                JSON.stringify(data),
            );
    }
};

const releaseNotesDialogReducer = (state = DEFAULT_STATE, action) => {
    let newTempServingOpportunities;

    switch (action.type) {
        case 'NewServingOpportunitiesDrawerAction.ON_ADD_TEMP_SERVING_OPPORTUNITY':
            newTempServingOpportunities = getTempServingOpportunitiesFromStorage(action.ux);

            newTempServingOpportunities = [
                ...newTempServingOpportunities,
                {
                    ...action.data,
                    tempId: uuidv4(),
                },
            ];

            setTempServingOpportunitiesToStorage(action.ux, newTempServingOpportunities);

            newTempServingOpportunities = filter(
                newTempServingOpportunities,
                { eventId: action.data.eventId },
            );

            return {
                ...state,
                tempServingOpportunities: [
                    ...newTempServingOpportunities,
                ],
            };
        case 'NewServingOpportunitiesDrawerAction.ON_GET_TEMP_SERVING_OPPORTUNITIES':
            newTempServingOpportunities = getTempServingOpportunitiesFromStorage(action.ux);

            newTempServingOpportunities = filter(
                newTempServingOpportunities, (tso) => tso.eventId === action.eventId,
            );

            return {
                ...state,
                isTempServingOpportuntiesLoaded: true,
                tempServingOpportunities: [
                    ...newTempServingOpportunities,
                ],
            };
        case 'NewServingOpportunitiesDrawerAction.ON_REMOVE_TEMP_SERVING_OPPORTUNITY':
            newTempServingOpportunities = getTempServingOpportunitiesFromStorage(action.ux);

            newTempServingOpportunities = filter(
                newTempServingOpportunities, (tso) => tso.tempId !== action.tempId,
            );

            if (isEmpty(newTempServingOpportunities)) {
                removeTempServingOpportunitiesFromStorage(action.ux);
            } else {
                setTempServingOpportunitiesToStorage(action.ux, newTempServingOpportunities);
            }

            newTempServingOpportunities = filter(
                newTempServingOpportunities,
                { eventId: action.eventId },
            );

            return {
                ...state,
                tempServingOpportunities: [
                    ...newTempServingOpportunities,
                ],
            };
        case 'NewServingOpportunitiesDrawerAction.ON_REMOVE_TEMP_SERVING_OPPORTUNITIES':
            newTempServingOpportunities = getTempServingOpportunitiesFromStorage(action.ux);

            newTempServingOpportunities = filter(
                newTempServingOpportunities, (tso) => tso.eventId !== action.eventId,
            );

            setTempServingOpportunitiesToStorage(action.ux, newTempServingOpportunities);

            return {
                ...state,
                tempServingOpportunities: [
                    ...DEFAULT_STATE.tempServingOpportunities,
                ],
            };
        case 'NewServingOpportunitiesDrawerAction.ON_RESET':
            return {
                ...DEFAULT_STATE,
            };
        case 'NewServingOpportunitiesDrawerAction.ON_UPDATE_TEMP_SERVING_OPPORTUNITY':
            newTempServingOpportunities = getTempServingOpportunitiesFromStorage(action.ux);

            newTempServingOpportunities = map(newTempServingOpportunities, (tso) => {
                if (tso.tempId === action.tempId) {
                    return {
                        ...tso,
                        ...action.data,
                    };
                }

                return tso;
            });

            setTempServingOpportunitiesToStorage(action.ux, newTempServingOpportunities);

            newTempServingOpportunities = filter(
                newTempServingOpportunities,
                { eventId: action.data.eventId },
            );

            return {
                ...state,
                tempServingOpportunities: [
                    ...newTempServingOpportunities,
                ],
            };
        default:
            return state;
    }
};

export default releaseNotesDialogReducer;
