import {
    filter,
    isEmpty,
    isNil,
    isNumber,
    sortBy,
} from 'lodash';
import Utils from '../../global/utils/utils.js';

export const DEFAULT_STATE = {
    ableToSave: false,
    template: {},
};

const isAbleToSaveEmailTemplate = (emailTemplate) => {
    const {
        churchEntityId,
        description,
        emailBodyContent,
        emailSubject,
        name,
    } = emailTemplate;

    const ableToSave =
        // Comm. Template top-level metadata
        !Utils.isStringNullOrWhiteSpace(name) &&
        isNumber(churchEntityId) &&
        !Utils.isStringNullOrWhiteSpace(description) &&
        // Email Subject Nested Template
        !isEmpty(emailSubject) &&
        !Utils.isStringNullOrWhiteSpace(emailSubject.content) &&
        // Email Body Nested Template
        !isEmpty(emailBodyContent) &&
        !Utils.isStringNullOrWhiteSpace(emailBodyContent.content);

    return ableToSave;
};

const updateEmailTemplate = (state, objWithUpdate) => {
    const { template } = state;
    const updatedTemplate = { ...template, ...objWithUpdate };
    const stillAbleToSave = isAbleToSaveEmailTemplate(updatedTemplate);

    return {
        ...state,
        ableToSave: stillAbleToSave,
        template: updatedTemplate,
    };
};

const CommunicationTemplateEditor = (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        case 'CommunicationTemplateEditorActions.ON_ADDATTACHMENT':
        {
            let attachments = [];

            const {
                template: {
                    emailAttachments,
                },
            } = state;

            const { result: newAttachment } = action;

            if (!isEmpty(emailAttachments)) {
                attachments = [...emailAttachments];
            }

            const updatedAttachments = sortBy(
                [...attachments, newAttachment],
                (a) => a.fileName,
            );

            return {
                ...state,
                template: {
                    ...state.template,
                    emailAttachments: updatedAttachments,
                },
            };
        }

        case 'CommunicationTemplateEditorActions.ON_ADD_TEMP_ATTACHMENT':
        {
            let attachments = [];

            const {
                template: {
                    tempEmailAttachments,
                },
            } = state;

            if (!isEmpty(tempEmailAttachments)) {
                attachments = [...tempEmailAttachments];
            }

            const updatedAttachments = sortBy(
                [...attachments, actionValue],
                (a) => a.fileName,
            );

            return {
                ...state,
                template: {
                    ...state.template,
                    tempEmailAttachments: updatedAttachments,
                },
            };
        }

        case 'CommunicationTemplateEditorActions.ON_DELETEATTACHMENT':
        {
            const {
                template: {
                    emailAttachments,
                },
            } = state;

            const { callbackParams: { attachmentId } } = action;

            const updatedAttachments = filter(
                emailAttachments, (a) => a.id !== attachmentId,
            );

            return {
                ...state,
                template: {
                    ...state.template,
                    emailAttachments: updatedAttachments,
                },
            };
        }

        case 'CommunicationTemplateEditorActions.ON_REMOVE_TEMP_ATTACHMENT':
        {
            const {
                template: {
                    tempEmailAttachments,
                },
            } = state;

            const updatedAttachments = filter(
                tempEmailAttachments, (a) => a.id !== actionValue,
            );

            return {
                ...state,
                template: {
                    ...state.template,
                    tempEmailAttachments: updatedAttachments,
                },
            };
        }

        case 'CommunicationTemplateEditorActions.ON_SET_TEMPLATE':
            return {
                ...state,
                ableToSave: isAbleToSaveEmailTemplate(actionValue),
                template: {
                    ...actionValue,
                },
            };

        case 'CommunicationTemplateEditorActions.ON_UPDATE_CAMPUS':
            return updateEmailTemplate(state, { churchEntityId: actionValue });

        case 'CommunicationTemplateEditorActions.ON_UPDATE_DESCRIPTION':
            return updateEmailTemplate(state, { description: actionValue });

        case 'CommunicationTemplateEditorActions.ON_UPDATE_SUBJECT':
        {
            const {
                template: {
                    emailSubject,
                },
            } = state;

            const updatedEmailSubject = {
                ...emailSubject,
                content: actionValue,
                format: 'PlainText',
                type: 'ContentSpot',
            };

            const updatedTemplate = {
                ...state.template,
                emailSubject: updatedEmailSubject,
            };

            const stillAbleToSave = isAbleToSaveEmailTemplate(updatedTemplate);

            return {
                ...state,
                ableToSave: stillAbleToSave,
                template: updatedTemplate,
            };
        }

        case 'CommunicationTemplateEditorActions.ON_UPDATE_MESSAGE':
        {
            const {
                template: {
                    emailBodyContent,
                },
            } = state;

            const {
                designData,
                html,
            } = actionValue;

            const updatedEmailBodyContent = {
                ...emailBodyContent,
                content: html,
                designData: !isNil(designData) ? JSON.stringify(designData) : undefined,
                format: 'Html',
                type: 'CompleteDocument',
            };

            const updatedTemplate = {
                ...state.template,
                emailBodyContent: updatedEmailBodyContent,
            };

            const stillAbleToSave = isAbleToSaveEmailTemplate(updatedTemplate);

            return {
                ...state,
                ableToSave: stillAbleToSave,
                template: updatedTemplate,
            };
        }

        case 'CommunicationTemplateEditorActions.ON_UPDATE_TEMPLATE_NAME':
            return updateEmailTemplate(state, { name: actionValue });

        case 'CommunicationTemplateEditorActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default CommunicationTemplateEditor;
