import '../../../scss/components/Users/UserNotificationPreferences.scss';
import React from 'react';
import {
    Button,
    Checkbox,
    Grid,
    Label,
    Loader,
} from '@saddlebackchurch/react-cm-ui';
import {
    isNil,
    isEmpty,
    sortBy,
} from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translationFactory } from '../../../global/i18nUtils.js';
import NotificationPreferenceActions from '../../reduxActions/Notification/NotificationPreferenceActions.js';
import Icon from '../Icon.react.js';

const i18n = translationFactory('User.UserNotificationPreferences');

const propTypes = {
    onClose: PropTypes.func.isRequired,
    notificationChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    preferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

class UserNotificationPreferences extends React.Component {
    static getChannelStatus(mainChannel, preferencesChannels) {
        const preferenceChannel = preferencesChannels.find(
            (channel) => mainChannel.id === channel.notificationChannelType,
        );

        return !isEmpty(preferenceChannel) ? preferenceChannel.value : null;
    }

    constructor(props) {
        super(props);

        this.state = { isFetching: false };

        this.getAllCheckedStatus = this.getAllCheckedStatus.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCheckChannel = this.onCheckChannel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSelectUnselectAllChannel = this.onSelectUnselectAllChannel.bind(this);
    }

    componentDidMount() {
        this.setState({ isFetching: true }, () => {
            NotificationPreferenceActions
                .get()
                .then(() => {
                    this.setState({ isFetching: false });
                });
        });
    }

    onSelectUnselectAllChannel(channelId) {
        const { preferences } = this.props;

        const isUncheckedChekbox = preferences.some((element) => {
            const checkedChannels = element.channels.some(
                (x) => x.notificationChannelType === channelId &&
                x.value === false,
            );

            return checkedChannels;
        });

        const checkboxOperation = isUncheckedChekbox ? 'checkAll' : 'unCheckAll';
        NotificationPreferenceActions.selectUnselectAllCheckbox(channelId, checkboxOperation);
    }

    onCancel() {
        const { onClose } = this.props;
        onClose();
    }

    onCheckChannel(preferenceId, channelId) {
        NotificationPreferenceActions.updateCheckboxStatus(preferenceId, channelId);
    }

    onSave() {
        const { onClose, preferences } = this.props;
        NotificationPreferenceActions.save(null, preferences);
        onClose();
    }

    getAllCheckedStatus(id, disabled) {
        const { preferences } = this.props;
        let checkedStatus = false;

        if (!disabled) {
            checkedStatus = !preferences.some((element) => {
                const checkedChannels = element.channels.some(
                    (x) => x.notificationChannelType === id &&
                    x.value === false,
                );

                return checkedChannels;
            });
        }

        return checkedStatus;
    }

    render() {
        const { notificationChannels, preferences } = this.props;
        const { isFetching } = this.state;

        if (isFetching) {
            return (
                <Loader fluid />
            );
        }

        return (
            <div className="content">
                <hgroup>
                    <Icon
                        size="xl"
                        title={i18n('Notification')}
                        type="new-notification"
                    />
                    <h3>{i18n('Title')}</h3>
                    <h5
                        className="sub-header text-disabled"
                        style={{ marginBottom: 0 }}
                    >
                        <Grid spacing={1}>
                            <Grid.Column
                                className="left-align-cell"
                                sm={7}
                            >
                                <span>
                                    {i18n('Subtitle1')}
                                </span>
                            </Grid.Column>
                            <Grid.Column sm>
                                <Grid>

                                    {
                                        notificationChannels.map((item) => (
                                            <Grid.Column
                                                key={item.id}
                                                sm
                                            >
                                                {item.longName}
                                            </Grid.Column>
                                        ))
                                    }
                                </Grid>
                            </Grid.Column>
                        </Grid>
                        <Grid spacing={1}>
                            <Grid.Column sm={7}>
                                <Label color="transparent" />
                            </Grid.Column>
                            <Grid.Column sm>
                                <Grid>
                                    {
                                        /* eslint-disable max-len */
                                        notificationChannels.map((item) => (
                                            <Grid.Column
                                                key={item.id}
                                                sm
                                            >
                                                <Checkbox
                                                    checked={this.getAllCheckedStatus(item.id, item.disabled)}
                                                    disabled={item.disabled}
                                                    id={`ui-checkbox-select_all_channels_${item.id}`}
                                                    onChange={() => this.onSelectUnselectAllChannel(item.id)}
                                                />
                                            </Grid.Column>
                                        ))
                                        /* eslint-enable max-len */
                                    }
                                </Grid>
                            </Grid.Column>
                        </Grid>
                    </h5>
                </hgroup>
                {preferences.map((v) => (
                    <Grid
                        key={`option-${v.sourceType}`}
                        spacing={1}
                    >
                        <Grid.Column sm={7}>
                            <Label
                                color="transparent"
                                inverse
                            >
                                {v.sourceTypeName}
                            </Label>
                        </Grid.Column>
                        <Grid.Column
                            className="center-align-cell"
                            sm
                        >
                            <Grid>
                                {/* eslint-disable max-len */}
                                {notificationChannels.map((item) => {
                                    const isChecked = UserNotificationPreferences.getChannelStatus(item, v.channels);

                                    return (
                                        <Grid.Column
                                            key={item.id}
                                            sm
                                        >
                                            {isNil(isChecked) ? (
                                                <React.Fragment>{i18n('N/A')}</React.Fragment>
                                            ) : (
                                                <Checkbox
                                                    checked={isChecked}
                                                    id={`ui-checkbox-notification_type_${v.sourceType}_channel_${item.id}`}
                                                    onChange={() => this.onCheckChannel(v.sourceType, item.id)}
                                                />
                                            )}
                                        </Grid.Column>
                                    );
                                })}
                                {/* eslint-enable max-len */}
                            </Grid>
                        </Grid.Column>
                    </Grid>
                ))}
                <Grid
                    className="action-btn-container"
                    columns={1}
                    justifyContent="center"
                    spacing={2}
                >
                    <Grid.Column>
                        <Button
                            color="success"
                            fluid
                            id="btn-save"
                            onClick={this.onSave}
                            width={200}
                        >
                            {i18n('SaveButton')}
                        </Button>
                    </Grid.Column>

                    <Grid.Column>
                        <Button
                            color="alert"
                            fluid
                            id="btn-cancel"
                            onClick={this.onCancel}
                            width={200}
                        >
                            {i18n('CancelButton')}
                        </Button>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

UserNotificationPreferences.propTypes = propTypes;

const mapStateToProps = (state) => {
    const {
        notification,
        bootstrap,
    } = state;

    const notificationChannels = bootstrap.enumerations.enumerations.notificationChannels.filter(
        (item) => item.id !== 0,
    );

    const updatedNotificationChannels = notificationChannels.map((item) => {
        const isDisabled = notification.notificationPreferences.every((element) => {
            const channel = element.channels.find((x) => x.notificationChannelType === item.id);
            return isNil(channel?.value);
        });

        return {
            ...item,
            disabled: isDisabled,
        };
    });

    return {
        preferences: sortBy(notification.notificationPreferences, 'order'),
        notificationChannels: updatedNotificationChannels,
    };
};

export default connect(mapStateToProps)(UserNotificationPreferences);
