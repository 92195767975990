import { i18n } from '../../global/constants.js';

export const BEM_BLOCK_NAME = 'person_search';

export const sortFilterOptions = [
    {
        label: i18n('Relevance'),
        value: 'relevance',
    }, {
        label: i18n('First Name (A > Z)'),
        value: 'firstname',
    }, {
        label: i18n('First Name (Z > A)'),
        value: '-firstname',
    }, {
        label: i18n('Last Name (A > Z)'),
        value: 'lastname',
    }, {
        label: i18n('Last Name (Z > A)'),
        value: '-lastname',
    }, {
        label: i18n('ID (Ascending)'),
        value: 'id',
    }, {
        label: i18n('ID (Descending)'),
        value: '-id',
    }, {
        label: i18n('Church (A > Z)'),
        value: 'churchentityname',
    }, {
        label: i18n('Church (Z > A)'),
        value: '-churchentityname',
    }, {
        label: i18n('Email (A > Z)'),
        value: 'email',
    }, {
        label: i18n('Email (Z > A)'),
        value: '-email',
    }, {
        label: i18n('Birth Date (Ascending)'),
        value: 'birthdate',
    }, {
        label: i18n('Birth Date (Descending)'),
        value: '-birthdate',
    },
];

export enum CheckInType {
    Attendee = 'Attendee',
    Volunteer = 'Volunteer',
}

export enum SearchAgeCategory {
    Adult = 'Adult',
    Student = 'Student',
    Child = 'Child',
    AdultOrStudent = 'AdultOrStudent',
    ChildOrStudent = 'ChildOrStudent',
}

export interface SearchOptions {
    ageCategory?: SearchAgeCategory;
    checkInType?: CheckInType;
    checkMembership?: boolean;
    checkPublicContacts?: boolean;
    churchEntityId?: number;
    eventId?: number;
    includeDeceasedPersons?: boolean;
    includeNoteCount?: boolean;
    isCheckInPersonSearch?: boolean;
    ministryId?: number;
    occurrenceId?: number;
    opportunityId?: number;
    pageNumber?: number;
    pageSize?: number;
    q: string;
    refPersonId?: number;
    sortField?: string;
    sortFieldOrder?: string;
    subVenueId?: number;
}
