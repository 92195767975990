export const DEFAULT_STATE = {
    isFetching: false,
    isFetchingMilestoneUsage: false,
    milestone: {},
    usageInfo: {},
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'MilestoneDetailsActions.ON_BEFORE_GET':
            return {
                ...state,
                isFetching: true,
            };

        case 'MilestoneDetailsActions.ON_GET':
            return {
                ...state,
                isFetching: false,
                milestone: actionResult,
            };

        case 'MilestoneDetailsActions.ON_BEFORE_GETMILESTONEUSAGE':
            return {
                ...state,
                isFetchingMilestoneUsage: true,
            };

        case 'MilestoneDetailsActions.ON_GETMILESTONEUSAGE':
            return {
                ...state,
                isFetchingMilestoneUsage: false,
                usageInfo: actionResult,
            };

        case 'MilestoneDetailsActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            // no-op
    }

    return state;
};
