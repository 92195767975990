import {
    Accordion,
    Button,
    dateUtils,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    Loader,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import _ from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { translationFactory } from '../../../global/i18nUtils.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import AddressFormatter from '../../../js/components/AddressFormatter.react.js';
import RelationshipActions from '../../emergencyContact/global/relationshipActions.js';
import personRecordUtils from '../../record/global/personRecordUtils.js';
import UserAccessStore from '../../../global/userAccessStore.js';

const _i = translationFactory('Person.Search.PeopleSearchDrawer');

class ExistingRelationshipDrawerContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchingPersonId: null,
            scrollContainer: null,
        };

        this.returnScrollContainer = this.returnScrollContainer.bind(this);
        this.setSelectedPerson = this.setSelectedPerson.bind(this);
        this.getPersonDetails = this.getPersonDetails.bind(this);
    }

    componentDidMount() {
        const {
            personId,
        } = this.props;
        RelationshipActions.get({ personId });
        this.returnScrollContainer();
    }

    componentDidUpdate() {
        const {
            personalInfo,
        } = this.props;
        const {
            fetchingPersonId,
        } = this.state;
        if (!_.isEmpty(personalInfo) && fetchingPersonId && personalInfo.id === fetchingPersonId) {
            this.setState({
                fetchingPersonId: null,
            });
        }
    }

    getPersonDetails(relativeId) {
        this.setState({
            fetchingPersonId: relativeId,
        });
        RelationshipActions.getPersonalDetails({ personId: relativeId });
    }

    render() {
        const {
            existingRelationshipList,
            isFetching,
        } = this.props;
        const {
            scrollContainer,
        } = this.state;
        const blockClassNames = 'people_existing_relationship_drawer';
        const totalCountClasses = `${blockClassNames}--total_count`;
        return (
            <div className={`${blockClassNames}--content`}>

                <div className={totalCountClasses}>
                    {_i('TotalCount') + existingRelationshipList.length}
                </div>

                <div className={`${blockClassNames}--results`}>
                    {isFetching ? (
                        <div style={{ marginTop: '55px', textAlign: 'center' }}>
                            <Loader fluid />
                        </div>
                    ) : (
                        <Grid columns={1} spacing={2}>
                            <Grid.RowDeprecated>
                                <Grid.Column>
                                    <Accordion
                                        id="ui-accordion--people"
                                        scrollContainer={scrollContainer}
                                        scrollContainerMarginHeight={15}
                                    >
                                        {this.renderResults()}
                                    </Accordion>
                                </Grid.Column>
                            </Grid.RowDeprecated>
                        </Grid>
                    )}
                </div>
            </div>
        );
    }

    renderResults() {
        const {
            emergencyContactList,
            existingRelationshipList,
            isFetchingPersonalInfo,
        } = this.props;

        const {
            fetchingPersonId,
        } = this.state;

        return _.chain(existingRelationshipList)
            .each((person) => {
                person.disable = person.relativeId === 5100012;
            })
            .map((person) => {
                const wrapperClass = ClassNames({ deceased: !!person.relativeDeceasedDate });
                const disablePerson = emergencyContactList.some((e) => e.emergencyContactId === person.relativeId);

                return (
                    <Accordion.Item
                        className={wrapperClass}
                        key={`accordion-item-${person.relativeId}`}
                    >
                        <Accordion.Summary
                            className="dashboard-task-item-summary"
                            onClick={() => this.getPersonDetails(person.relativeId)}
                        >
                            <div className="row row-middle">
                                <div className="col avatar-col">
                                    <Image
                                        src={personRecordUtils.returnPhotoUrl(person.relativeId || 0, person.relativeGender)}
                                        style={{ height: '44px', width: '44px' }}
                                        type="person"
                                    />
                                </div>

                                <div className="col col-grow">
                                    <h4 className="no-margins name">{person.relativeFullName}</h4>

                                    <span className="text-xsmall text-static">
                                        {`${_i('ID')}: ${person.relativeId}`}
                                    </span>
                                </div>
                            </div>
                        </Accordion.Summary>

                        <Accordion.Content>
                            {
                                fetchingPersonId && fetchingPersonId === person.relativeId ?
                                    (<Loader fluid />) :
                                    this.renderAccordionContent(disablePerson)
                            }
                        </Accordion.Content>
                    </Accordion.Item>
                );
            })
            .value();
    }

    renderAccordionContent(disablePerson) {
        const {
            personalInfo: person,
            userAccess,
        } = this.props;
        if (_.isEmpty(person)) {
            return null;
        }

        const personPrimaryContactInfo = _.find(person.phones, 'isPrimary');
        const personPrimaryEmailInfo = _.find(person.emails, 'isPrimary');
        const personPrimaryAddressInfo = _.find(person.addresses, 'isPrimary');
        const primaryPhone = !_.isEmpty(personPrimaryContactInfo) ? personPrimaryContactInfo.displayPhoneNumber : '';
        const primaryEmail = !_.isEmpty(personPrimaryEmailInfo) ? personPrimaryEmailInfo.email : '';
        const primaryPhoneType = !_.isEmpty(personPrimaryContactInfo) ? personPrimaryContactInfo.phoneType : '';
        const hasBirthdayPermission = userAccess.hasPermission(USER_PERMISSIONS.accessDateOfBirth);
        const hasContactPermission = userAccess.hasPermission(USER_PERMISSIONS.readPersonContactData);
        const birthDayValueJsx = (
            <span key={`dob-${person.id}-value`}>
                {dateUtils.formatDate(person.birthDate)}
            </span>
        );
        const birthDayJsx = hasBirthdayPermission ?
            [
                <p className="font-size-xsmall text-static no-margin-top no-margin-btm" key={`dob-${person.id}-label`}>{_i('Birthday')}</p>,
                birthDayValueJsx,
            ] : null;

        const canBeAnEmergencyContact = (!_.isEmpty(person.addresses) || !_.isEmpty(person.emails) || !_.isEmpty(person.phones)) && !person.isChild;
        const alert = disablePerson ?
            'Already listed as an Emergency Contact' :
            !canBeAnEmergencyContact ?
                'Person is a Child record or does not have own contact information' :
                `${_i('DoNotContact')}`;
        const contactInfoJsx = hasContactPermission ?
            [
                <Divider key={`contact-${person.id}-divider`} relaxed />,
                person.contactPreferences.doNotContact || disablePerson || !canBeAnEmergencyContact ? (
                    <Header
                        key={`contact-${person.id}-header`}
                        size="small"
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            marginTop: 0,
                        }}
                    >
                        <Icon color="alert" type="ban" />
                        <span className="color-alert">
                            {alert}
                        </span>
                    </Header>
                ) :
                    [
                        <Header key={`contact-${person.id}-header`} size="small" style={{ marginTop: 0 }}>{`${_i('Primary')} ${_i('Contact')}`}</Header>,
                        <Grid
                            key={`contact-${person.id}-grid`}
                            spacing={2}
                        >
                            <Grid.Column style={{ width: '33.333%' }}>
                                <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{_i('Address')}</p>
                                {
                                    _.isNil(personPrimaryAddressInfo) ?
                                        _i('NA') : <AddressFormatter addressObject={personPrimaryAddressInfo} />
                                }
                            </Grid.Column>

                            <Grid.Column style={{ width: '41.666%', wordWrap: 'break-word' }}>
                                <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{_i('Email')}</p>
                                <span>{primaryEmail ? <a href={`mailto:${primaryEmail}`}>{primaryEmail}</a> : _i('NA')}</span>
                            </Grid.Column>

                            <Grid.Column style={{ width: '25%' }}>
                                <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{primaryPhoneType || _i('Phone')}</p>
                                <span>{primaryPhone || _i('NA')}</span>
                            </Grid.Column>
                        </Grid>],
            ] : null;

        // Cycle through arrays and display !isPrimary items
        // Create 3 seperate JSX blocks and plug into Grid

        const extraAddressJSX = [];
        _.map(person.addresses, (address) => {
            if (address.isPrimary === false) {
                extraAddressJSX.push(
                    <Grid.RowDeprecated key={`email-grid-row-${address.address1}`}>
                        <Grid.Column style={{ padding: '11px 0' }}>
                            <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{_i('Address')}</p>
                            <AddressFormatter addressObject={address} />
                        </Grid.Column>
                    </Grid.RowDeprecated>,
                );
            }
        });

        const extraEmailsJSX = [];
        _.map(person.emails, (email) => {
            if (email.isPrimary === false) {
                const { email: emailAddress } = email;
                extraEmailsJSX.push(
                    <Grid.RowDeprecated key={`email-grid-row-${emailAddress}`}>
                        <Grid.Column style={{ padding: '11px 0' }}>
                            <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{_i('Email')}</p>
                            <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                        </Grid.Column>
                    </Grid.RowDeprecated>,
                );
            }
        });

        const extraPhonesJSX = [];
        _.map(person.phones, (phone) => {
            if (phone.isPrimary === false) {
                extraPhonesJSX.push(
                    <Grid.RowDeprecated key={`email-grid-row-${phone.number}`}>
                        <Grid.Column style={{ padding: '11px 0' }}>
                            <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{_.capitalize(phone.type)}</p>
                            {phone.number}
                        </Grid.Column>
                    </Grid.RowDeprecated>,
                );
            }
        });

        const contactInfoExtendedJSX = !_.isEmpty(extraAddressJSX) || !_.isEmpty(extraEmailsJSX) || !_.isEmpty(extraPhonesJSX) ? (
            <div key="person-contacts-extended">
                <Header key={`contact-${person.id}-header`} size="small" style={{ marginBottom: 0, marginTop: '22px' }}>{`${_i('Additional')} ${_i('Contacts')}`}</Header>
                <Grid
                    key={`contact-${person.id}-grid`}
                    spacing={2}
                >
                    <Grid.Column style={{ width: '33.333%' }}>
                        {extraAddressJSX}
                    </Grid.Column>

                    <Grid.Column style={{ width: '41.666%', wordWrap: 'break-word' }}>
                        {extraEmailsJSX}
                    </Grid.Column>

                    <Grid.Column style={{ width: '25%' }}>
                        {extraPhonesJSX}
                    </Grid.Column>
                </Grid>
            </div>
        ) : null;

        return (
            <React.Fragment>
                <Header size="small">{_i('Personal')}</Header>

                <Grid columns={4} spacing={2}>
                    <Grid.Column>
                        <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{_i('FirstName')}</p>
                        <span>{person.firstName}</span>
                    </Grid.Column>

                    <Grid.Column>
                        <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{_i('LastName')}</p>
                        <span>{person.lastName}</span>
                    </Grid.Column>

                    <Grid.Column>
                        {birthDayJsx}
                    </Grid.Column>

                    <Grid.Column>
                        <p className="font-size-xsmall text-static no-margin-top no-margin-btm">{_i('ID')}</p>
                        <span>{person.id}</span>
                    </Grid.Column>
                </Grid>

                {contactInfoJsx}

                {!person.contactPreferences.doNotContact ? contactInfoExtendedJSX : null}

                {disablePerson || !canBeAnEmergencyContact ? (
                    <div>
                        <Divider hidden relaxed="very" />
                        <Button disabled><span>{_i('Select')}</span></Button>
                    </div>
                ) : null}

                {!person.contactPreferences.doNotContact && !disablePerson && canBeAnEmergencyContact ? (
                    <div>
                        <Divider hidden relaxed="very" />

                        <Button
                            id="ui-button--select_person"
                            onClick={() => this.setSelectedPerson(person)}
                        >
                            <span>{_i('Select')}</span>
                        </Button>
                    </div>
                ) : null}
            </React.Fragment>
        );
    }

    returnScrollContainer() {
        const closestClassNames = '.ui.drawer > .drawer-container > div > div:first-child';
        const peopleDrawerNode = ReactDOM.findDOMNode(this);

        this.setState({ scrollContainer: peopleDrawerNode.closest(closestClassNames) });
    }

    setSelectedPerson(person) {
        const { setPerson } = this.props;

        if (!_.isUndefined(setPerson)) {
            setPerson(person);
        }
    }
}

ExistingRelationshipDrawerContent.propTypes = {
    disablePerson: PropTypes.PropTypes.shape({}),
    emergencyContactList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    onClose: PropTypes.func,
    setPerson: PropTypes.func,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        people: {
            emergencyContact: {
                relationship: {
                    data: existingRelationshipList,
                    isFetching,
                    isFetchingPersonalInfo,
                    personalInfo,
                },
            },
        },
    } = state;
    return {
        existingRelationshipList,
        isFetching,
        isFetchingPersonalInfo,
        personalInfo,
        userAccess,
    };
};

export default connect(mapStateToProps)(ExistingRelationshipDrawerContent);
