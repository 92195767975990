import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Icon, Typography } from '@saddlebackchurch/react-cm-ui';
import { makeStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import Utils from '../../../global/utils/utils.js';
import DateUtils from '../../../global/dateUtils.js';

const useStyles = makeStyles(({ palette, spacing }) => ({
    container: {
        paddingBottom: spacing(2),
    },
    contentHeader: {
        marginBottom: spacing(0.75),
    },
    contentTitle: {
        fontSize: '18px',
    },
    contentSubtitle: {
        color: palette.teal[600],
        weight: 700,
        fontSize: '12px',
    },
    activityContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: 100,
        height: 100,
        margin: '0 auto',
    },
    contentInfo: {
        '& > div:not(:last-child)': {
            marginBottom: spacing(0.75),
        },
    },
    contentInfoItem: {
        display: 'flex',
    },
}));

const parseMinistries = (acc, curr) => {
    const {
        churchEntityName,
        dateBecameMinistryMember,
        ministryCategory,
        ministryId,
        ministryName,
    } = curr;
    const existingMinistry = acc.find((ministry) => ministry.ministryId === ministryId);

    if (existingMinistry) {
        const isEarlier = dateBecameMinistryMember &&
            moment(existingMinistry.dateBecameMinistryMember)
                .isBefore(dateBecameMinistryMember);

        existingMinistry.churchEntityNames.push(churchEntityName);
        if (isEarlier) {
            existingMinistry.dateBecameMinistryMember = dateBecameMinistryMember;
        }
    } else {
        acc.push({
            churchEntityNames: [churchEntityName],
            dateBecameMinistryMember,
            ministryCategory,
            ministryId,
            ministryName,
        });
    }

    return acc;
};

const RelationshipPopoverContent = ({ personMinistries }) => {
    const classes = useStyles();
    const timeZoneId = DateUtils.getPreferredTimeZone();
    const parsedPersonMinistries = personMinistries.reduce(parseMinistries, []);

    return parsedPersonMinistries.map(({
        churchEntityNames,
        dateBecameMinistryMember,
        ministryName,
        ministryCategory,
    }) => (
        <div className={classes.container}>
            <div className={classes.contentHeader}>
                <Typography variant="h4" className={classes.contentTitle}>{ministryName}</Typography>
                <Typography variant="h3" className={classes.contentSubtitle}>
                    {ministryCategory}
                </Typography>
            </div>
            <div className={classes.contentInfo}>
                <div className={classes.contentInfoItem}>
                    <Icon color="condition" size={16} type="map-marker" />
                    <span>{churchEntityNames.join(', ')}</span>
                </div>
                {dateBecameMinistryMember && (
                    <div className={classes.contentInfoItem}>
                        <Icon color="condition" size={16} type="membership" />
                        <span>{Utils.format('Joined {0}', moment.utc(dateBecameMinistryMember).tz(timeZoneId).format('L'))}</span>
                    </div>
                )}
            </div>
        </div>
    ));
};

RelationshipPopoverContent.propTypes = {
    personMinistries: PropTypes.arrayOf(PropTypes.shape({})),
};

RelationshipPopoverContent.defaultProps = {
    personMinistries: [],
};

export default RelationshipPopoverContent;
