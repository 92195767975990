import request from 'axios';
import ApiUtils from '../../global/apiUtils.js';
import { appReduxStore } from '../../global/configureReduxStore.js';

class ConnectionCardsActions extends ApiUtils {
    resetEntries() {
        appReduxStore.dispatch({ type: 'ConnectionCardsActions.ON_RESETENTRIES' });
    }

    resetEntry() {
        appReduxStore.dispatch({ type: 'ConnectionCardsActions.ON_RESETENTRY' });
    }

    resetTemplate() {
        appReduxStore.dispatch({ type: 'ConnectionCardsActions.ON_RESETTEMPLATE' });
    }
}

const actions = new ConnectionCardsActions('/api/connection-card/');

actions.buildReduxActionClass(
    {
        name: 'getCampuses',
        route: 'template/church-entities',
        rq: request.get,
    },
    {
        name: 'getEntries',
        route: 'entry/search?personId={personId}&sortField=occurrenceDate&sortFieldOrder=desc&pageNumber=0',
        rq: request.get,
    }, {
        name: 'getEntry',
        route: 'entry/{id}',
        rq: request.get,
    }, {
        name: 'getTemplate',
        route: 'template/{id}',
        rq: request.get,
    },
);

export default actions;
