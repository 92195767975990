enum InsightsPersonEngagementCategoryName {
    Connection = 'Connection',
    Event = 'Event',
    Giving = 'Giving',
    Ministry = 'Ministry',
    PersonRecord = 'Person Record',
    ServingOpportunity = 'Serving Opportunity',
    SmallGroup = 'Small Group',
}

export default InsightsPersonEngagementCategoryName;
