/* eslint no-underscore-dangle: 0 */
import { EventEmitter } from 'events';
import _, {
    isEmpty,
    filter,
    map,
    orderBy,
} from 'lodash';
import { translationFactory } from '../../global/i18nUtils.js';
import BootstrapConstants from '../constants/BootstrapConstants.js';
import LoginConstants from '../constants/Login/LoginConstants.js';
import AppDispatcher from '../dispatcher/AppDispatcher.js';

const i18n = translationFactory('EnumerationStore');

const CHANGE_EVENT = 'change';

class EnumerationStoreClass extends EventEmitter {
    constructor() {
        super();

        this.enumerations = [];
        this._bootstrapLoaded = false;
    }

    updateEnumerations(enumerations) {
        this.enumerations = enumerations;
    }

    updateRegions(regions) {
        if (!_.isEmpty(regions)) {
            const { country } = _.head(regions);

            if (!_.some(this.enumerations.regions, (r) => r.country === country)) {
                this.enumerations.regions = this.enumerations.regions || [];
                this.enumerations.regions = this.enumerations.regions.concat(regions);
            }
        }
    }

    setBootstrapLoaded(b) {
        this._bootstrapLoaded = b;
    }

    getConnectionFormEventTypesSelect() {
        return [
            {
                label: i18n('Regular'),
                value: 'Regular',
            },
            {
                label: i18n('Worship Service'),
                value: 'WorshipService',
            },
        ];
    }

    getCountries() {
        return this.enumerations.countries;
    }

    getCountriesSelect() {
        return _.map(
            this.enumerations.countries,
            (country) => (
                {
                    value: country.shortName,
                    label: country.longName,
                }
            ),
        );
    }

    getGenders() {
        return [
            {
                id: 1,
                shortName: 'M',
                longName: 'Male',
                value: 1,
                label: i18n('Male'),
            },
            {
                id: 2,
                shortName: 'F',
                longName: 'Female',
                value: 1,
                label: i18n('Female'),
            },
        ];
    }

    getMinistryMemberSourceSelect() {
        return _.map(
            this.enumerations.ministryMemberSources,
            (src) => (
                {
                    label: src.longName,
                    value: src.id,
                }
            ),
        );
    }

    getMinistryMemberStatusSelect(statusType) {
        const isInterestedStatus = statusType === 'Interested';

        return _.sortBy(
            _.filter(
                this.enumerations.ministryMemberStatuses,
                (mms) => (statusType ? (mms.type === statusType) : true),
            ),
            (mms) => (isInterestedStatus ? mms.id : mms.label),
        );
    }

    getOpportunityVolunteerStatusSelect() {
        return [
            {
                value: 'Active',
                label: i18n('Active'),
            },
            {
                value: 'Inactive',
                label: i18n('Inactive'),
            },
        ];
    }

    getEventCategoriesSelect() {
        return _.map(this.enumerations.eventCategories,
            (category) => (
                {
                    id: category.id,
                    label: category.name,
                    value: category.name,
                }
            ));
    }

    getEventTypes() {
        return this.enumerations.scheduleEntityTypes;
    }

    getEventTypesSelect() {
        return _.map(
            this.enumerations.scheduleEntityTypes,
            (scheduleEntityType) => (
                {
                    value: scheduleEntityType.id,
                    label: i18n(scheduleEntityType.name),
                }
            ),
        );
    }

    getGendersSelect() {
        return [
            {
                label: i18n('Female'),
                value: 'F',
            },
            {
                label: i18n('Male'),
                value: 'M',
            },
        ];
    }

    getAllergiesSelect() {
        return [
            {
                value: 'Nuts',
                label: i18n('Nuts'),
            },
            {
                value: 'Milk',
                label: i18n('Milk'),
            },
        ];
    }

    getAuditLogCategories() {
        return this.enumerations.auditLogCategories;
    }

    getAuditLogSources() {
        return this.enumerations.auditLogSources;
    }

    getGradeLevels() {
        return this.enumerations.gradeLevels;
    }

    getGradesSelect(personalData, churchEntities) {
        const gradeSchoolYear = churchEntities?.find(
            (c) => c.value === personalData.churchEntityId,
        )?.gradeSchoolYear;

        return [
            {
                label: i18n('None'),
                value: 'None',
            },
            {
                label: i18n(`PreK${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'PreK',
            },
            {
                label: i18n(`Kindergarten${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Kindergarten',
            },
            {
                label: i18n(`Grade 1${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'First',
            },
            {
                label: i18n(`Grade 2${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Second',
            },
            {
                label: i18n(`Grade 3${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Third',
            },
            {
                label: i18n(`Grade 4${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Fourth',
            },
            {
                label: i18n(`Grade 5${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Fifth',
            },
            {
                label: i18n(`Grade 6${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Sixth',
            },
            {
                label: i18n(`Grade 7${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Seventh',
            },
            {
                label: i18n(`Grade 8${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Eighth',
            },
            {
                label: i18n(`Grade 9${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Ninth',
            },
            {
                label: i18n(`Grade 10${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Tenth',
            },
            {
                label: i18n(`Grade 11${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Eleventh',
            },
            {
                label: i18n(`Grade 12${!isEmpty(gradeSchoolYear) ? ` | ${gradeSchoolYear}` : ''}`),
                value: 'Twelfth',
            },
        ];
    }

    getLocales() {
        return this.enumerations.locales;
    }

    getLocalesSelect() {
        return _.map(this.enumerations.locales,
            (locale) => (
                {
                    value: locale.id,
                    label: locale.name,
                }
            ));
    }

    getLocaleById(id) {
        if (!this.enumerations || !this.enumerations.locales ||
            this.enumerations.locales.length === 0) { return {}; }

        return _.chain(this.enumerations.locales)
            .filter((e) => e.id * 1 === id * 1)
            .head()
            .value();
    }

    getLocaleByLCID(lcid) {
        if (!this.enumerations || !this.enumerations.locales ||
            this.enumerations.locales.length === 0) { return {}; }

        return _.chain(this.enumerations.locales)
            .filter((e) => e.lcid === lcid)
            .head()
            .value();
    }

    getMaritalStatus() {
        return this.enumerations.maritalStatuses;
    }

    getMaritalStatusSelect() {
        return _.map(this.enumerations.maritalStatuses,
            (maritalStatus) => (
                {
                    value: maritalStatus.id,
                    label: i18n(maritalStatus.name),
                }
            ));
    }

    getMembershipStatus() {
        return this.enumerations.membershipStatuses;
    }

    getMembershipStatusSelect() {
        return _.map(this.enumerations.membershipStatuses,
            (membershipStatus) => (
                {
                    value: membershipStatus.id,
                    label: i18n(membershipStatus.name),
                    enumName: membershipStatus.name.replace(/[\s-]/g, ''),
                }
            ));
    }

    getMembershipStatusName(membershipStatus) {
        return i18n(membershipStatus);
    }

    getMessagingPlatformName(messagingPlatform) {
        return i18n(messagingPlatform);
    }

    getMessagingPlatforms() {
        return this.enumerations.messagingPlatforms;
    }

    getMessagingPlatformsSelect() {
        return _.map(this.enumerations.messagingPlatforms,
            (mp) => (
                {
                    value: mp.id,
                    label: i18n(mp.longName),
                }
            ));
    }

    getMinistryStatusName(status) {
        return i18n(status);
    }

    getMinistryStatusSelect() {
        return _.map(this.enumerations.ministryStatuses,
            (ms) => (
                {
                    value: ms.shortName,
                    label: i18n(ms.longName),
                }
            ));
    }

    getAuditLogIcon(auditTypeName) {
        switch (auditTypeName) {
            case 'Personal':
                return 'name';
            case 'Contact':
                return 'phone';
            case 'Relationships':
                return 'people';
            case 'Church':
            case 'Membership':
                return 'church';
            case 'Milestones':
                return 'people';
            default:
                return 'audit';
        }
    }

    getMilestoneName(milestoneName) {
        return i18n(milestoneName);
    }

    getMilestoneIcon(milestoneCategory) {
        // These Milestone type categorizations are per my discussion with Rafi on Thurs 03/03/2016 after Daily Scrum
        switch (milestoneCategory) {
            case 'Security':
                return 'security';
            case 'Discipleship':
                return 'book';
            case 'Personal':
            default:
                return 'medal';
        }

        // There is also a "Leadership" category that gets a "shield" icon (I'm not 100% sure what the right class is for it)
        // At this time, none of the Milestone types in the system are assigned to the Leadership category
    }

    getMilestoneTypes() {
        return _.sortBy(this.enumerations.milestones, (ms) => ms.uiOrder);
    }

    getMilestoneTypesSelect() {
        const orderedData = orderBy(
            this.enumerations.milestones,
            [
                'isSystem',
                'uiOrder',
            ],
            [
                'asc',
                'asc',
            ],
        );

        const filteredData = [
            map(
                filter(orderedData, ['isSystem', true]),
                (foo, index) => ({
                    ...foo,
                    uiOrder: index + 1,
                }),
            ),
            filter(orderedData, ['isSystem', false]),
        ];

        return [...filteredData[0], ...filteredData[1]]
            .map((ms) => ({
                label: i18n(ms.name),
                value: ms.id,
                category: ms.category,
            }));
    }

    getOccupationTypes() {
        return _.sortBy(this.enumerations.occupations, (oc) => oc.categoryName);
    }

    getOccupationsTypesSelect() {
        return _.chain(this.enumerations.occupations)
            .sortBy((oc) => oc.categoryName)
            .map((oc) => (
                {
                    label: i18n(oc.categoryName),
                    value: oc.id,
                    disabled: !oc.isActive,
                }
            ))
            .value();
    }

    getPhoneTypes() {
        return this.enumerations.phoneTypes;
    }

    getPhoneTypesSelect() {
        return _.chain(this.enumerations.phoneTypes)
            .filter((pt) => pt.longName !== 'Other') // back-end model enum includes "Other" but UI does not use it right now
            .map((pt) => (
                {
                    value: pt.id,
                    label: i18n(pt.longName),
                }
            ))
            .value();
    }

    getRecurrenceFrequencies() {
        return this.enumerations.recurrenceFrequencies;
    }

    getRecurrenceFrequenciesSelect() {
        return _.map(this.enumerations.recurrenceFrequencies,
            (rf) => (
                {
                    value: rf.id,
                    label: i18n(rf.name),
                }
            ));
    }

    getRegions(countryAlpha3) {
        return _.filter(this.enumerations.regions, (r) => r.country === countryAlpha3);
    }

    getRegionsSelect(countryAlpha3) {
        return _.chain(this.enumerations.regions)
            .filter((region) => region.country === countryAlpha3)
            .map((region) => (
                {
                    value: region.shortName,
                    label: region.longName,
                    country: region.country,
                }
            ))
            .value();
    }

    getReminderIntervalTypes() {
        return [
            {
                value: 'Minutes',
                label: i18n('Minutes'),
            },
            {
                value: 'Hours',
                label: i18n('Hours'),
            },
            {
                value: 'Days',
                label: i18n('Days'),
            },
            {
                value: 'Weeks',
                label: i18n('Weeks'),
            },
        ];
    }

    getQuestionTypes() {
        return [
            {
                value: '3',
                label: 'Checkbox',
            },
            {
                value: '4',
                label: 'Date',
            },
            {
                value: '2',
                label: 'Paragraph Text',
            },
            {
                value: '1',
                label: 'Single Line Text',
            },
        ];
    }

    findTimeZone(id) {
        return _.find(this.enumerations.timeZones, { id });
    }

    getTimeZones() {
        return this.enumerations.timeZones;
    }

    getTimeZonesSelect() {
        return _.map(this.enumerations.timeZones,
            (timeZone) => (
                {
                    value: timeZone.id,
                    label: timeZone.displayName,
                }
            ));
    }

    getWorshipServiceCategoryId() {
        return 1;
    }

    hasBootstrapLoaded() {
        return this._bootstrapLoaded;
    }

    isLoaded() {
        return this._bootstrapLoaded && !_.isEmpty(this.enumerations);
    }

    reset() {
        this.enumerations = [];
        this._bootstrapLoaded = false;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
}

const EnumerationStore = new EnumerationStoreClass();

const dispatcherCallback = (payload) => {
    switch (payload.actionType) {
        case BootstrapConstants.GET_BOOTSTRAP:
            EnumerationStore.setBootstrapLoaded(true);
            EnumerationStore.emitChange();
            break;
        case BootstrapConstants.GET_ENUMERATIONS:
            EnumerationStore.updateEnumerations(payload.data.enumerations);
            EnumerationStore.emitChange();
            break;

        case BootstrapConstants.GET_REGIONS_BY_COUNTRY_COMPLETED:
            EnumerationStore.updateRegions(payload.data);
            EnumerationStore.emitChange();
            break;

        case LoginConstants.LOGOUT:
            EnumerationStore.reset();
            EnumerationStore.emitChange();
            break;

        default:
        // no op
    }
};

export default EnumerationStore;

AppDispatcher.register(dispatcherCallback);
