import { combineReducers } from 'redux';
import ruleEditor from './editorDrawer/editorReducers.js';
import rulesList from './list/list.reducer.js';

const reducers = combineReducers({
    ruleEditor,
    rulesList,
});

export default reducers;
