import {
    map,
} from 'lodash';
import { selectBoxTransform } from '../../../global/utils';
import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';
import LoginConstants from '../../constants/Login/LoginConstants.js';

const usersSearchCache = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    allUsers: [],
    passwordResponse: {},
    savedUser: {},
    searchResults: {
        pageSize: usersSearchCache.getPageSize(),
        results: [],
    },
    user: {},
    usersInRole: [],
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
        case 'UserActions.RESET': // reset when we're told too (e.g. on a consuming component's unmount)
            return DEFAULT_STATE;

        case 'UserActions.ON_GET':
            return {
                ...state,
                user: action.result,
            };

        case 'UserActions.ON_GETALL': {
            const newAction = {
                result: action.result.users,
            };

            return {
                ...state,
                allUsers: selectBoxTransform(newAction, 'id', 'fullName'),
            };
        }

        case 'UserActions.ON_GETBYROLE':
            return {
                ...state,
                usersInRole: action.result.users,
            };

        case 'UserActions.ON_CREATE':
        case 'UserActions.ON_UPDATE':
            return {
                ...state,
                savedUser: action.result,
            };

        case 'UserActions.ON_SENDNEWACCOUNTEMAIL':
        case 'UserActions.ON_SENDRESETPASSWORD':
        case 'UserActions.ON_SETNEWPASSWORD':
            return {
                ...state,
                passwordResponse: action.result,
            };

        case 'UserActions.ON_SEARCH':
            usersSearchCache.loadPage(
                action.result.users,
                action.result.count,
                action.params.pageNumber === 0,
            );
            // fall-through to UserActions.ON_SEARCHNEXTPAGE case
            // eslint-disable-next-line no-fallthrough
        case 'UserActions.ON_SEARCHNEXTPAGE':
            return {
                ...state,
                searchResults: {
                    results: usersSearchCache.getAll(true),
                    total: usersSearchCache.getTotalCount(),
                    canLoadMore: usersSearchCache.canLoadMore(),
                    needsToRequest: usersSearchCache.needsToLoadPage(),
                    pageNumber: usersSearchCache.getCurrentPageNumber(),
                    pageSize: usersSearchCache.getPageSize(),
                },
            };

        case 'UserActions.ON_SELECT_ALL_CHECKBOXES':
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    results: map(state.searchResults.results, (item) => ({
                        ...item,
                        checked: true,
                    })),
                },
            };

        case 'UserActions.ON_CLEAR_ALL_CHECKBOXES':
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    results: map(state.searchResults.results, (item) => ({
                        ...item,
                        checked: false,
                    })),
                },
            };

        case 'UserActions.ON_SELECT_CHECKBOX':
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    results: map(state.searchResults.results, (item) => {
                        if (action.id === item.id) {
                            return { ...item, checked: true };
                        }

                        return item;
                    }),
                },
            };

        case 'UserActions.ON_UNSELECT_CHECKBOX':
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    results: map(state.searchResults.results, (item) => {
                        if (action.id === item.id) {
                            return { ...item, checked: false };
                        }

                        return item;
                    }),
                },
            };

        default:
            return state;
    }
};
