/**
* This enumeration contains the Action Type string constants for filters on giving activity list
*/
enum ActionType {
    RESET_FILTERS = 'GivingActivityListActions.ON_RESET_FILTERS',
    SET_APPLY_FILTERS = 'GivingActivityListActions.ON_APPLY_FILTERS',
    SET_CLEAR_FILTERS = 'GivingActivityListActions.ON_CLEAR_FILTERS',
    SET_DIRTY_SORT_ORDER = 'GivingActivityListActions.ON_SET_DIRTY_SORT_ORDER',
    SET_DIRTY_CHANNEL_TYPE_FILTER_CHANGE = 'GivingActivityListActions.SET_DIRTY_CHANNEL_TYPE_FILTER_CHANGE',
    SET_DIRTY_DESIGNATION_TYPE_IDS_FILTER_CHANGE = 'GivingActivityListActions.SET_DIRTY_DESIGNATION_TYPE_IDS_FILTER_CHANGE',
    SET_DIRTY_GIVER_TYPE_FILTER_CHANGE = 'GivingActivityListActions.ON_SET_DIRTY_GIVER_TYPE_FILTER_CHANGE',
    SET_DIRTY_PAYMENT_AMOUNT_RANGE_END = 'GivingActivityListActions.ON_SET_DIRTY_PAYMENT_AMOUNT_RANGE_END',
    SET_DIRTY_PAYMENT_AMOUNT_RANGE_START = 'GivingActivityListActions.ON_SET_DIRTY_PAYMENT_AMOUNT_RANGE_START',
    SET_DIRTY_PAYMENT_END_DATE_FILTER_CHANGE = 'GivingActivityListActions.SET_DIRTY_PAYMENT_END_DATE_FILTER_CHANGE',
    SET_DIRTY_PAYMENT_METHOD_TYPE = 'GivingActivityListActions.SET_DIRTY_PAYMENT_METHOD_TYPE',
    SET_DIRTY_PAYMENT_START_DATE_FILTER_CHANGE = 'GivingActivityListActions.SET_DIRTY_PAYMENT_START_DATE_FILTER_CHANGE',
    SET_DIRTY_PAYMENT_STATUS_FILTER_CHANGE = 'GivingActivityListActions.SET_DIRTY_PAYMENT_STATUS_FILTER_CHANGE',
}

export default ActionType;
