import _ from 'lodash';

export const DEFAULT_STATE = {
    emailVariables: [],
    reminders: [],
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventReminderActions.ON_GETEMAILVARIABLES':
            return {
                ...state,
                emailVariables: action.result,
            };

        case 'EventReminderActions.ON_GETREMINDERSBYOCCURRENCE':
        case 'EventReminderActions.ON_GETREMINDERSBYSCHEDULE':
            return {
                ...state,
                reminders: _.sortBy(action.result, 'id'),
            };

        default:
            return state;
    }
}
