import React from 'react';
import TabsContentComingSoonMessage from './tabsContentComingSoonMessage.jsx';

function TabsContentMinistryAndServing() {
    return (
        <div className="person_record--tabs_content person_record--tabs_content-small_groups">
            <TabsContentComingSoonMessage />
        </div>
    );
}

export default TabsContentMinistryAndServing;
