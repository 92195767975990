import { appReduxStore } from '../../global/configureReduxStore.js';
import { APP_HEADER_SET_APP_HEADER_TITLE } from './appHeaderTypes.js';

export default class AppHeaderActions {
    static updateTitle(title, subTitle) {
        appReduxStore.dispatch({
            result: {
                subTitle,
                title,
            },
            type: 'AppHeaderActions.ON_UPDATETITLE',
        });
    }
}

export const setAppHeaderTitle = (appHeaderTitle) => (dispatch) => { // for navigation v3
    dispatch({
        type: APP_HEADER_SET_APP_HEADER_TITLE,
        appHeaderTitle,
    });
};
