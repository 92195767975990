import {
    map,
} from 'lodash';
import {
    Button,
    Grid,
    Icon,
    Modal,
    MobileStepper,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles'; // eslint-disable-line import/extensions
import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '../constants.js';
import {
    fileAllowedTypes,
    inputAllowedTypes,
    GROUPS_PROP_TYPES,
} from './constants';
import BannerUtils from '../../js/utils/BannerUtils.js';
import AppStore from '../../js/stores/AppStore.js';
import ImageUploaderManager from './imageUploaderManager.jsx';

const propTypes = {
    classes: PropTypes.shape({
        backButton: PropTypes.string,
        clearAll: PropTypes.string,
        clearAllColor: PropTypes.string,
        imagePreview: PropTypes.string,
        inputTypeFile: PropTypes.string,
        mobileStepper: PropTypes.string,
        mobileStepperColumn: PropTypes.string,
        removePhotoLink: PropTypes.string,
        root: PropTypes.string,
        uploadForAll: PropTypes.string,
        uploadPhotoLink: PropTypes.string,
    }),
    groups: GROUPS_PROP_TYPES.isRequired,
    previewUrl: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

const defaultProps = {
    classes: {},
    previewUrl: '',
};

const mapStateToProps = (state) => {
    const {
        global: {
            imageUploader,
        },
    } = state;

    return {
        imageUploader,
    };
};

const BLOCK_CLASS_NAME = 'image_uploader';

const styles = ({
    breakpoints,
    palette,
    spacing,
}) => ({
    root: {
        marginBottom: spacing(4),
        width: '100%',
    },
    backButton: {
        [breakpoints.down(400)]: {
            marginRight: 3,
        },
    },
    fileUploadSection: {
        alignItems: 'center',
        display: 'flex',
    },
    inputTypeFile: {
        display: 'none',
    },
    uploadPhotoLink: {
        color: palette.text.link,
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: spacing(2),
        [breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    uploadForAll: {
        letterSpacing: '0',
    },
    clearAllColor: {
        color: 'black',
        letterSpacing: '0',
    },
    mobileStepper: {
        justifyContent: 'space-between',
        width: '100%',

    },
    mobileStepperColumn: {
        minHeight: 30,
    },
});

const fileInput = React.createRef();
const reader = new FileReader();

class ImageUploaderActions extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpenImageModal: false,
            activeStep: 0,
            image: null,
            images: props.groups ?? [],
            fileName: '',
        };

        this.onBackClick = this.onBackClick.bind(this);
        this.onClearAll = this.onClearAll.bind(this);
        this.onClickFileInput = this.onClickFileInput.bind(this);
        this.onCloseImageModal = this.onCloseImageModal.bind(this);
        this.onGetImage = this.onGetImage.bind(this);
        this.onHandleImageUploaderChange = this.onHandleImageUploaderChange.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onOpenImageModal = this.onOpenImageModal.bind(this);
        this.onSave = this.onSave.bind(this);
        this.getStepContent = this.getStepContent.bind(this);
    }

    onBackClick() {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
        }));
    }

    onClearAll() {
        const {
            onChange,
            groups,
        } = this.props;

        const newImages = map(groups, (group) => ({
            ...group,
            imageContainer: {
                ...group.imageContainer,
                src: '',
            },
        }));

        this.setState({ images: newImages });

        onChange(newImages);
    }

    onClickFileInput() {
        fileInput.current.click();
    }

    onCloseImageModal() {
        this.setState({
            isOpenImageModal: false,
            activeStep: 0,
            image: null,
            fileName: '',
        });
    }

    onGetImage(imageUrl) {
        const {
            activeStep,
            fileName,
            images,
        } = this.state;

        const newImages = map(images, (image, index) => {
            if (index === activeStep) {
                return {
                    ...image,
                    imageContainer: {
                        ...image.imageContainer,
                        fileName,
                        src: imageUrl,
                    },
                };
            }

            return image;
        });

        this.setState({ images: newImages });
    }

    onHandleImageUploaderChange(e, modalOpen = false) {
        e.preventDefault();
        const file = e.target.files[0];
        const fileName = e.target.files[0].name;

        if (!file) {
            return;
        }

        if (file.size > AppStore.getUploadingOptions().maxEmailAttachmentSize) {
            BannerUtils.addBanner({
                id: `${BLOCK_CLASS_NAME}--attachment_too_large`,
                level: 'error',
                timeout: true,
                title: i18n('The attachment is too large'),
                type: 'notification',
            });
            return;
        }

        if (!fileAllowedTypes.includes(file.type)) {
            BannerUtils.addBanner({
                id: `${BLOCK_CLASS_NAME}--invalid_file_format`,
                level: 'error',
                timeout: true,
                title: i18n('Invalid File Format'),
                type: 'notification',
            });
            return;
        }

        reader.onloadend = () => {
            this.setState({
                fileName,
                image: reader.result,
            });
        };
        e.target.value = '';
        reader.readAsDataURL(file);

        if (modalOpen) {
            this.onOpenImageModal();
        }
    }

    onNextClick() {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1,
        }));
    }

    onOpenImageModal() {
        this.setState((prevState) => ({
            isOpenImageModal: !prevState.isOpenImageModal,
        }));
    }

    onSave() {
        const { images } = this.state;
        const { onChange } = this.props;

        onChange(images);

        this.setState({ activeStep: 0 });
        this.onOpenImageModal();
    }

    getStepContent(activeStep) {
        const {
            image,
            images,
            fileName,
        } =
            this.state;

        const {
            onChange,
            previewUrl,
        } = this.props;

        return (
            <ImageUploaderManager
                fileName={fileName}
                group={images[activeStep]}
                onChange={onChange}
                onGetImages={this.onGetImage}
                onHandleImageUploaderChange={this.onHandleImageUploaderChange}
                onSave={this.onSave}
                previewUrl={image === null ? previewUrl : image}
                title={images[activeStep].title}
                uploadAll
            />
        );
    }

    updateChange() {
        const { onChange } = this.props;
        onChange();
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            activeStep,
            images,
            isOpenImageModal,
        } = this.state;

        const steps = images.length;

        return (
            <Grid.Column
                className={classes.root}
            >
                <div>
                    <input
                        accept={inputAllowedTypes}
                        id={`${BLOCK_CLASS_NAME}--file_upload`}
                        className={classes.inputTypeFile}
                        onChange={
                            (e) => this.onHandleImageUploaderChange(e, true)
                        }
                        ref={fileInput}
                        type="file"
                    />

                    <Button
                        designVersion={2}
                        id={`${BLOCK_CLASS_NAME}--upload_all`}
                        className={classes.uploadForAll}
                        onClick={this.onClickFileInput}
                        variant="outlined"
                    >
                        <Icon type="image" />

                        <span>Upload for all</span>
                    </Button>

                    <Button
                        designVersion={2}
                        id={`${BLOCK_CLASS_NAME}--clear_all_images`}
                        className={classes.clearAll}
                        color="error"
                        onClick={this.onClearAll}
                        variant="outlined"
                    >
                        <Icon type="delete" color="alert" />

                        <span className={classes.clearAllColor}>Clear all</span>
                    </Button>
                </div>

                <Modal
                    className={classes.uploadPhotoLink}
                    isOpen={isOpenImageModal}
                    onClose={this.onCloseImageModal}
                >
                    <Modal.Content>
                        {isOpenImageModal && (this.getStepContent(activeStep))}
                    </Modal.Content>

                    <Modal.Actions>
                        <MobileStepper
                            activeStep={activeStep}
                            backButton={(
                                <Button
                                    className={classes.backButton}
                                    color="primary"
                                    designVersion={2}
                                    disabled={activeStep === 0}
                                    id={`${BLOCK_CLASS_NAME}--back_stepper`}
                                    onClick={this.onBackClick}
                                >
                                    Back
                                </Button>
                            )}
                            classes={{
                                root: classes.mobileStepper,
                            }}
                            id={`${BLOCK_CLASS_NAME}--mobile_stepper`}
                            nextButton={(
                                <React.Fragment>
                                    <div>
                                        <Button
                                            designVersion={2}
                                            id={`${BLOCK_CLASS_NAME}--cancel_stepper`}
                                            color="secondary"
                                            onClick={this.onCloseImageModal}
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            designVersion={2}
                                            id={`${BLOCK_CLASS_NAME}--next_stepper`}
                                            onClick={activeStep === steps - 1 ?
                                                this.onSave :
                                                this.onNextClick}
                                            color={activeStep === steps - 1 ? 'success' : 'primary'}
                                        >
                                            {activeStep === steps - 1 ? 'Done' : 'Next'}
                                        </Button>
                                    </div>

                                </React.Fragment>

                            )}
                            position="static"
                            steps={steps}
                        />
                    </Modal.Actions>
                </Modal>
            </Grid.Column>
        );
    }
}

ImageUploaderActions.defaultProps = defaultProps;
ImageUploaderActions.propTypes = propTypes;

export default withStyles(styles)(connect(mapStateToProps)(ImageUploaderActions));
