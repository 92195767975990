import { combineReducers } from 'redux';
import filters from './filters.reducer.js';
import paginatedData from './paginatedEventOccurrences.reducer.js';

const reducers = combineReducers({
    filters,
    paginatedData,
});

export default reducers;
