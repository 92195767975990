enum TouchPointHover {
    None = 0,
    RegisterForAnEvent = 1,
    AttendAnEvent = 2,
    RegisterForAServingOpp = 3,
    AttendAServingOpp = 4,
    RegisterForAMinistry = 5,
    FillOutAForm = 6,
    WatchAVideo = 7,
    ReadADocument = 8,
    VisitALink = 9,
    TakeAQuiz = 10,
    ReadText = 11,
    ListenToAudio = 12,
}

export default TouchPointHover;
