import moment from 'moment-timezone';
import React, {
    useState,
} from 'react';
import {
    Button,
    Grid,
    Prompt,
} from '@saddlebackchurch/react-cm-ui';
import { makeStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import {
    NOTE_REMINDER_DUE_TIME,
    NOTE_REMINDER_DETAIL_DUE_DATE_FORMAT,
} from './noteReminderConstants';
import { i18n } from '../../../../global/constants.js';

interface PropTypes {
    dueDate: moment.MomentInput & moment.MomentTimezone;
    isMobile?: boolean;
    onCancelReminderClick: () => void;
}

const useStyles = makeStyles(() => ({
    newReminder: {
        width: '100%',
        marginBottom: 10,
        '& .reminder-body': {
            backgroundColor: '#ebfaff',
            borderRadius: 3,
            padding: '10px 10px 10px 10px',
        },
        '& .reminder-detail-emphasis': {
            fontWeight: 700,
        },
    },
}));

function NoteReminderDetail(props: PropTypes) {
    const {
        dueDate,
        isMobile,
        onCancelReminderClick,
    } = props;

    const classes = useStyles();
    const [shouldShowPrompt, setShouldShowPrompt] = useState(false);

    return (
        <div className={classes.newReminder}>
            <Grid columns={1} spacing={2}>
                <Grid.RowDeprecated>
                    <Grid.Column>
                        <div className="reminder-body">
                            {i18n('Here\'s what\'s coming up. You have a reminder set for')}
                            &nbsp;
                            <span className="reminder-detail-emphasis">
                                {dueDate && moment(dueDate)
                                    .format(NOTE_REMINDER_DETAIL_DUE_DATE_FORMAT)}
                            </span>
                            &nbsp;at&nbsp;
                            <span className="reminder-detail-emphasis">
                                {NOTE_REMINDER_DUE_TIME}
                                {i18n('AM')}
                            </span>
                        </div>
                    </Grid.Column>
                </Grid.RowDeprecated>

                {!isMobile && ( // in mobile, cancel is placed in the DrawerWing actionbar
                    <Grid.RowDeprecated>
                        <Grid.Column textAlign="right">
                            <Prompt
                                inline
                                inlineHorizontalAlign="right"
                                inlineMessageColor="warning"
                                message={i18n('Are you sure?')}
                                onClick={() => setShouldShowPrompt(true)}
                                onYesClick={() => onCancelReminderClick()}
                                onNoClick={() => setShouldShowPrompt(false)}
                                show={shouldShowPrompt}
                            >
                                <Button color={shouldShowPrompt ? 'disable' : 'warning'}>
                                    {i18n('Cancel Reminder')}
                                </Button>
                            </Prompt>
                        </Grid.Column>
                    </Grid.RowDeprecated>
                )}
            </Grid>
        </div>
    );
}

export default NoteReminderDetail;
