import { Icon } from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';

type PropTypes = {
    isVisible?: boolean;
    message: string;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    root: {
        marginLeft: spacing(0.5),
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

function InformationalIcon({
    isVisible = true,
    message,
}: PropTypes) {
    const classes = useStyles();

    if (!isVisible) {
        return null;
    }

    return (
        <span
            className={classes.root}
        >
            <Icon
                color="highlight"
                size={16}
                title={message}
                type="info"
            />
        </span>
    );
}

export default InformationalIcon;
