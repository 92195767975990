import {
    lowerCase,
    map,
} from 'lodash';
import { Banner } from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import BannerAction from '../js/reduxActions/BannerAction.js';

const propTypes = {
    banners: PropTypes.arrayOf(
        PropTypes.shape({
            children: PropTypes.node,
            id: PropTypes.string,
            isOpen: PropTypes.bool,
            level: PropTypes.string,
            levelIcon: PropTypes.string,
            message: PropTypes.string,
            onClose: PropTypes.func,
            title: PropTypes.string,
            type: PropTypes.string,
        }),
    ),
    onCloseBanner: PropTypes.func.isRequired,
    onRemoveBanner: PropTypes.func.isRequired,
};

const defaultProps = {
    banners: [],
};

const mapStateToProps = (state) => {
    const {
        banners,
    } = state;

    return {
        banners,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onCloseBanner: (id) => {
        dispatch(BannerAction.closeBanner(id));
    },
    onRemoveBanner: (id) => {
        dispatch(BannerAction.removeBanner(id));
    },
});

function AppBanners(props) {
    const {
        banners,
        onCloseBanner,
        onRemoveBanner,
    } = props;

    const hasTitleBarSelector = document.querySelector('.ui.title-bar');

    return map(banners, (banner) => (
        <Banner
            className={banner.className || 'banner-custom'}
            id={banner.id}
            isOpen={banner.isOpen}
            key={`banner-key-${banner.id}`}
            level={lowerCase(banner.level)}
            levelIcon={banner.levelIcon}
            message={banner.message}
            onAfterClose={onRemoveBanner}
            onClose={(id) => {
                if (banner.onClose) {
                    banner.onClose();
                } else {
                    onCloseBanner(id);
                }
            }}
            onOpen={banner.onOpen}
            title={banner.title}
            topPosition={hasTitleBarSelector ? 88 : null}
            type={banner.type}
        >
            {banner.children}
        </Banner>
    ));
}

AppBanners.propTypes = propTypes;
AppBanners.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppBanners);
