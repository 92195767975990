import {
    isNil,
    without,
} from 'lodash';
import {
    CouponForEditor,
} from './models';
import ActionType from './coupon.actionTypes';

export const DEFAULT_STATE: CouponForEditor = {
    appliesToTickets: [],
    applyCodeToAllTickets: true,
    couponCode: null,
    couponId: null,
    discountAmount: null,
    discountAmountCurrency: null,
    discountPercent: null,
    isActive: false,
    promoEndDate: null,
    promoEndTime: null,
    promoEndsWhenTicketSalesEnd: true,
    promoStartDate: null,
    promoStartsImmediately: true,
    promoStartTime: null,
    usageLimit: null,
    usageType: null,
};

export default (
    state: CouponForEditor | undefined,
    action: any,
): CouponForEditor => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.RESET_DATA: {
                return DEFAULT_STATE;
            }

            case ActionType.REMOVE_PROMO_CODE_FROM_TICKET: {
                const { value: ticketId } = action;

                const filteredDataForCodeAppliedToTickets = without(
                    state.appliesToTickets,
                    ticketId,
                );

                return {
                    ...state,
                    appliesToTickets: [...filteredDataForCodeAppliedToTickets],
                };
            }

            case ActionType.SET_APPLY_CODE_TYPE: {
                const { value: applyCodeToAllTickets } = action;

                return {
                    ...state,
                    applyCodeToAllTickets,
                };
            }

            case ActionType.SET_COUPON_CODE: {
                const { value: couponCode } = action;

                return {
                    ...state,
                    couponCode,
                };
            }

            case ActionType.SET_COUPON_EDITOR_FORM: {
                const { value: couponEditor } = action;

                return {
                    ...state,
                    ...couponEditor,
                };
            }

            case ActionType.SET_DISCOUNT_AMOUNT: {
                const { value: discountAmount } = action;

                return {
                    ...state,
                    discountAmount,
                };
            }

            case ActionType.SET_DISCOUNT_PERCENT: {
                const { value: discountPercent } = action;

                return {
                    ...state,
                    discountPercent,
                };
            }

            case ActionType.SET_PROMO_CODE_TO_TICKET: {
                const { value: ticketId } = action;

                const updatedDataForCodeAppliedToTickets = [...state.appliesToTickets, ticketId];

                return {
                    ...state,
                    appliesToTickets: updatedDataForCodeAppliedToTickets,
                };
            }

            case ActionType.SET_PROMO_END_DATE: {
                const { value: promoEndDate } = action;

                return {
                    ...state,
                    promoEndDate,
                };
            }

            case ActionType.SET_PROMO_END_TIME: {
                const { value: promoEndTime } = action;

                return {
                    ...state,
                    promoEndTime,
                };
            }

            case ActionType.SET_PROMO_END_TYPE: {
                const { value: promoEndsWhenTicketSalesEnd } = action;

                return {
                    ...state,
                    promoEndsWhenTicketSalesEnd,
                };
            }

            case ActionType.SET_PROMO_START_DATE: {
                const { value: promoStartDate } = action;

                return {
                    ...state,
                    promoStartDate,
                };
            }

            case ActionType.SET_PROMO_START_TIME: {
                const { value: promoStartTime } = action;

                return {
                    ...state,
                    promoStartTime,
                };
            }

            case ActionType.SET_PROMO_START_TYPE: {
                const { value: promoStartsImmediately } = action;

                return {
                    ...state,
                    promoStartsImmediately,
                };
            }

            case ActionType.SET_USAGE_LIMIT: {
                const { value: usageLimit } = action;

                return {
                    ...state,
                    usageLimit,
                };
            }

            case ActionType.SET_USAGE_TYPE: {
                const { value: usageType } = action;

                return {
                    ...state,
                    usageType,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
