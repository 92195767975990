import { i18n } from '../../../../global/constants.js';

/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
export default class ErrorListener {
    syntaxError(recognizer, offendingSymbol, line, column, msg, e) {
        this.error = i18n('Syntax Error. See help for details.');
    }

    reportAmbiguity(recognizer, dfa, startIndex, stopIndex, exact, ambigAlts, configs) {}

    reportAttemptingFullContext(recognizer, dfa, startIndex, stopIndex, conflictingAlts, configs) {}

    reportContextSensitivity(recognizer, dfa, startIndex, stopIndex, prediction, configs) {}
}
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
