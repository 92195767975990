export const DEFAULT_STATE = {
    isFetching: false,
    attributes: [],
};

const RuleTriggerMetadata = (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'WorkflowRuleTriggerActions.ON_BEFORE_GETRULEBUILDERMETADATA':
            return {
                ...state,
                isFetching: true,
            };

        case 'WorkflowRuleTriggerActions.ON_GETRULEBUILDERMETADATA':
            return {
                ...state,
                isFetching: false,
                attributes: actionResult,
            };

        case 'WorkflowRuleTriggerActions.ON_RESET_RULE_BUILDER_METADATA':
            return DEFAULT_STATE;

        default:
            return state;
    }
};

export default RuleTriggerMetadata;
