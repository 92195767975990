import { isNil } from 'lodash';
import {
    Ticket,
} from '../models';
import ActionType from './list.actionTypes';

export const DEFAULT_STATE: Ticket[] = [];

export default (
    state: Ticket[] | undefined,
    action: any,
): Ticket[] => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.SET_EVENT_TICKET_LIST: {
                const { value: list } = action;

                return list;
            }

            default:
                return state;
        }
    }

    return state;
};
