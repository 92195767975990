import {
    A,
} from '@saddlebackchurch/react-cm-ui';
import { Link } from 'react-router';
import React from 'react';
import PropTypes from 'prop-types';
import i18nUtils from '../i18nUtils.js';

const _i = i18nUtils.translationFactory('Notification.BannerNotificationStore');

const propTypes = {
    isDashboard: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    pathname: PropTypes.string,
};

const defaultProps = {
    pathname: undefined,
};

const NotificationBannerInner = (props) => {
    const {
        onClick,
        message,
        isDashboard,
        pathname,
    } = props;

    let link;

    if (pathname) {
        link = (
            <Link
                className="link"
                to={pathname}
                onClick={onClick}
            >
                {message}
            </Link>
        );
    } else if (isDashboard) {
        link = (
            <Link
                className="link"
                to="/my-dashboard/notifications"
                onClick={onClick}
            >
                {message}
            </Link>
        );
    } else {
        link = (
            <A
                className="link"
                onClick={onClick}
            >
                {message}
            </A>
        );
    }

    return (
        <div className="no-margin-top no-margin-btm">
            <p className="no-margin-top">
                {_i('NotificationTitle')}
            </p>

            {link}
        </div>
    );
};

NotificationBannerInner.propTypes = propTypes;
NotificationBannerInner.defaultProps = defaultProps;

export default NotificationBannerInner;
