import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const _localCache = new ChunkedPaginationUtils(25, 50);

export const DEFAULT_STATE = {
    results: [],
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: _localCache.getPageSize(),
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryNotificationActions.ON_NOTIFICATIONSBYLOCATION':
            _localCache.loadPage(action.result.list, action.result.count, action.callbackParams.first);
            return {
                results: _localCache.getAll(true),
                total: _localCache.getTotalCount(),
                canLoadMore: _localCache.canLoadMore(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageNumber: _localCache.getCurrentPageNumber(),
                pageSize: _localCache.getPageSize(),
            };

        case 'MinistryNotificationActions.ON_NOTIFICATIONSBYLOCATION_NEXTPAGE':
            return {
                results: _localCache.getAll(true),
                total: _localCache.getTotalCount(),
                canLoadMore: _localCache.canLoadMore(),
                needsToRequest: _localCache.needsToLoadPage(),
                pageNumber: _localCache.getCurrentPageNumber(),
                pageSize: _localCache.getPageSize(),
            };
    }
    return state;
}
