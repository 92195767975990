import ActionType from '../../actionTypes/segments.actionTypes';
import { Action } from '../../../global/models';

export const INITIAL_STATE = {
    isFirstFetching: true,
    isLoading: false,
    containsMoreRecords: false,
    pageCount: 0,
    pagination: {
        pageSize: 10,
        pageNumber: 0,
        sortQuery: '',
    },
    results: [],
    searchValue: '',
    total: 0,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_SEGMENTS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case ActionType.SET_SEGMENTS:
            return {
                ...state,
                isLoading: false,
                isFirstFetching: false,
                containsMoreRecords: action.payload.containsMoreRecords,
                pageCount: action.payload.pageCount,
                pagination: action.payload.pagination,
                results: [...state.results, ...action.payload.results],
                total: action.payload.total,
            };
        case ActionType.RESET_SEGMENTS:
            return {
                ...state,
                ...INITIAL_STATE,
                isFirstFetching: true,
                searchValue: state.searchValue,
            };
        case ActionType.SET_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            };
        default:
            return state;
    }
};
