const DEFAULT_STATE = [];

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventAttendeeActions.ON_GETNOTES':
            return action.result;
    }

    return state;
}
