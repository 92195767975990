enum ActionType {
    CLEAR_FILTER_OPTIONS = 'CommunicationsCentral.CLEAR_FILTER_OPTIONS',
    CLEAR_SEARCH_QUERY = 'CommunicationsCentral.CLEAR_SEARCH_QUERY',
    SET_ARE_FILTERS_DIRTY = 'CommunicationsCentral.SET_ARE_FILTERS_DIRTY',
    SET_BASE_TEMPLATES = 'CommunicationsCentral.SET_BASE_TEMPLATES',
    SET_FILTER_OPTIONS = 'CommunicationsCentral.SET_FILTER_OPTIONS',
    SET_FORM_DATA = 'CommunicationsCentral.SET_FORM_DATA',
    SET_IS_FILTERING = 'CommunicationsCentral.SET_IS_FILTERING',
    SET_SELECTED_BASE_TEMPLATE = 'CommunicationsCentral.SET_SELECTED_BASE_TEMPLATE',
    SET_SEARCH_QUERY = 'CommunicationsCentral.SET_SEARCH_QUERY',
    SET_SEARCH_RESULTS = 'CommunicationsCentral.SET_SEARCH_RESULTS',
    SET_IS_UPLOADING = 'CommunicationsCentral.SET_IS_UPLOADING',
}

export default ActionType;
