export const DEFAULT_STATE = null;

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'EventCheckInActions.ON_GETOCCURRENCE':
            return action.result;

        case 'EventCheckInActions.ON_BEFORE_GETOCCURRENCE':
            return DEFAULT_STATE;

        case 'EventCheckInActions.ON_FAILED_GETOCCURRENCE':
            return [];

        default:
            return state;
    }
}
