import {
    color,
} from 'global/styles/colors.scss';

import {
    Button,
    Grid,
} from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles,
} from '@saddlebackchurch/react-cm-ui/core/styles'; // eslint-disable-line import/extensions
import AvatarEditor from 'react-avatar-editor';
import { translationFactory } from './i18nUtils.js';

const propTypes = {
    avatarFileName: PropTypes.string.isRequired,
    avatarPreviewUrl: PropTypes.string.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.shape({}),
        fileUploadSection: PropTypes.shape({}),
        inputTypeFile: PropTypes.shape({}),
        avatarScaleSliderWrapper: PropTypes.shape({}),
        avatarScaleSlider: PropTypes.shape({}),
        saveAvatar: PropTypes.shape({}),
        avatarImageCanvas: PropTypes.shape({}),
        fileName: PropTypes.shape({}),
    }),
    isMobile: PropTypes.bool.isRequired,
    onHandleAvatarImageChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

const defaultProps = {
    classes: {},
};

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            isMobile,
        },
    } = state;

    return {
        isMobile,
    };
};

const i18n = translationFactory();
const AVATAR_EDITOR_WIDTH_HEIGHT_DESKTOP = 250;
const AVATAR_EDITOR_WIDTH_HEIGHT_MOBILE = 320;
const BLOCK_CLASS_NAME = 'person_avatar_upload';

const styles = {
    root: {
        [`& .${BLOCK_CLASS_NAME}--input_file_button_wrapper`]: {
            marginRight: '11px',
            padding: '8px 22px !important',
        },
        [`& .${BLOCK_CLASS_NAME}--avatar_image canvas`]: {
            borderRadius: '3px !important',
        },
    },
    fileUploadSection: {
        alignItems: 'center',
        display: 'flex',
    },
    inputTypeFile: {
        display: 'none',
    },
    avatarScaleSliderWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatarScaleSlider: {
        width: 'calc(100% - 35px)',
    },
    saveAvatar: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '11px',
    },
    avatarImageCanvas: {
        borderRadius: '3px',
    },
    fileName: {
        color,
    },
};

class AvatarManagerUpload extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            scale: 1.1,
        };
        this.editor = null;
        this.onSaveAvatar = this.onSaveAvatar.bind(this);
        this.onHandleScale = this.onHandleScale.bind(this);
    }

    onHandleScale(e) {
        const scale = parseFloat(e.target.value);
        this.setState({ scale });
    }

    onSaveAvatar() {
        const {
            avatarFileName,
            onSave,
        } = this.props;

        if (this.editor) {
            const imageURL = this.editor.getImageScaledToCanvas().toDataURL('', 1);
            onSave(avatarFileName, imageURL);
        }
    }

    setEditorRef(editor) {
        if (editor) {
            this.editor = editor;
        }
    }

    render() {
        const {
            scale,
        } = this.state;

        const {
            avatarPreviewUrl,
            avatarFileName,
            classes,
            isMobile,
            onHandleAvatarImageChange,
        } = this.props;

        return (
            <Grid
                className={classes.root}
                spacing={2}
            >
                <Grid.RowDeprecated columns={1}>
                    <Grid.Column>
                        <div className={classes.fileUploadSection}>
                            <label
                                className={`ui button button-color-alternate ${BLOCK_CLASS_NAME}--input_file_button_wrapper`}
                            >
                                <input
                                    accept="image/png, image/jpeg"
                                    id={`${BLOCK_CLASS_NAME}--file_upload`}
                                    multiple
                                    type="file"
                                    className={classes.inputTypeFile}
                                    onChange={(e) => onHandleAvatarImageChange(e)}
                                />
                                {i18n('Change File')}
                            </label>
                            <div className={classes.fileName}>
                                {avatarFileName}
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={`${BLOCK_CLASS_NAME}--avatar_image`}>
                            <AvatarEditor
                                ref={(ref) => this.setEditorRef(ref)}
                                border={3}
                                image={avatarPreviewUrl}
                                width={isMobile ? AVATAR_EDITOR_WIDTH_HEIGHT_MOBILE : AVATAR_EDITOR_WIDTH_HEIGHT_DESKTOP}
                                height={isMobile ? AVATAR_EDITOR_WIDTH_HEIGHT_MOBILE : AVATAR_EDITOR_WIDTH_HEIGHT_DESKTOP}
                                color={[255, 255, 255, 0.7]}
                                scale={scale}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={classes.avatarScaleSliderWrapper}>
                            <input
                                className={classes.avatarScaleSlider}
                                name="scale"
                                type="range"
                                onChange={this.onHandleScale}
                                min="1"
                                max="2"
                                step="0.01"
                                defaultValue="1"
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={classes.saveAvatar}>
                            <Button
                                color="success"
                                id={`${BLOCK_CLASS_NAME}--save`}
                                onClick={this.onSaveAvatar}
                            >
                                {i18n('Save')}
                            </Button>
                        </div>
                    </Grid.Column>
                </Grid.RowDeprecated>
            </Grid>
        );
    }
}

AvatarManagerUpload.propTypes = propTypes;
AvatarManagerUpload.defaultProps = defaultProps;

export default withStyles(styles)(connect(mapStateToProps)(AvatarManagerUpload));
