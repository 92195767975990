import { combineReducers } from 'redux';

import followUpRuleCounts from './followUpsPage/followUpRuleCounts.reducer.js';
import details from './details.reducer.js';
import filters from './listPage/filters.reducer.js';
import list from './listPage/list.reducer.js';

const reducers = combineReducers({
    details,
    filters,
    followUpRuleCounts,
    list,
});

export default reducers;
