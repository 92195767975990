import { Card, Header, Icon } from '@saddlebackchurch/react-cm-ui';
import PropTypes from 'prop-types';
import React from 'react';
import ArticleTitle from '../articleTitle.jsx';

const propTypes = {
    article: PropTypes.shape({
        isPublished: PropTypes.bool,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
};

class HelpContextualFABPArticlePreview extends React.PureComponent {
    constructor() {
        super();

        this.onCardClick = this.onCardClick.bind(this);
    }

    onCardClick() {
        const {
            article,
            onClick,
        } = this.props;

        onClick(article);
    }

    render() {
        const {
            article,
        } = this.props;

        const { isPublished } = article;

        return (
            <Card
                className="help-contextual-fab-article-preview"
                compact
                nest
                onClick={this.onCardClick}
            >
                <Header
                    compact
                    icon
                    size="small"
                    sub={!!article.description}
                >
                    <Icon
                        color="warning"
                        type="text-lines"
                    />

                    <span
                        className="color-link"
                    >
                        <ArticleTitle
                            isPublished={isPublished}
                            title={article.title}
                        />
                    </span>

                    {!!article.description && (
                        <Header.Subheader>
                            <span className="color-static">{article.description}</span>
                        </Header.Subheader>
                    )}
                </Header>
            </Card>
        );
    }
}

HelpContextualFABPArticlePreview.propTypes = propTypes;

export default HelpContextualFABPArticlePreview;
