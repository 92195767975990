/**
* This enumeration contains the Action Type string constants for Event Basic Info
*/
enum ActionType {
    // Action Type for Event Type
    SET_EVENT_TYPE = 'EventEditActions.ON_SET_EVENT_TYPE',
    SET_GENERAL_LEDGER_ACCOUNT_ID = 'EventEditActions.ON_SET_GENERAL_LEDGER_ACCOUNT_ID',
    SET_ACCOUNTING_MINISTRY_CODE = 'EventEditActions.ON_SET_ACCOUNTING_MINISTRY_CODE',
    // Action Type for Event Hosting Type
    SET_EVENT_CHURCH_ENTITY = 'EventEditActions.ON_SET_EVENT_CHURCH_ENTITY',
    SET_EVENT_CHURCH_ENTITY_NAME = 'EventEditActions.ON_SET_EVENT_CHURCH_ENTITY_NAME',
    SET_EVENT_HOST_MINISTRY = 'EventEditActions.ON_SET_EVENT_HOST_MINISTRY',
    SET_EVENT_LEADER_IDS = 'EventEditActions.ON_SET_EVENT_LEADER_IDS',
    SET_EVENT_LEADERS = 'EventEditActions.ON_SET_EVENT_LEADERS',
    SET_EVENT_TIMEZONE = 'EventEditActions.ON_SET_EVENT_TIMEZONE',
    SET_EVENT_WHO_SELECTED = 'EventEditActions.ON_SET_EVENT_WHO_SELECTED',
    // Action Type for Event Basic Info
    SET_EVENT_NAME = 'EventEditActions.ON_SET_EVENT_NAME',
    SET_EVENT_CATEGORY_ID = 'EventEditActions.ON_SET_EVENT_CATEGORY_ID',
    // Action Type for Basic Info of Event Contact Ids
    SET_EVENT_CONTACT_IDS = 'EventEditActions.ON_SET_EVENT_CONTACT_IDS',
    // Action Type for Basic Info of Event Date & Time
    SET_EVENT_CUSTOM_OPTIONS = 'EventEditActions.ON_SET_EVENT_CUSTOM_OPTIONS',
    SET_EVENT_END_DATE = 'EventEditActions.ON_SET_EVENT_END_DATE',
    SET_EVENT_END_TIME = 'EventEditActions.ON_SET_EVENT_END_TIME',
    SET_EVENT_START_DATE = 'EventEditActions.ON_SET_EVENT_START_DATE',
    SET_EVENT_START_TIME = 'EventEditActions.ON_SET_EVENT_START_TIME',
    SET_EVENT_REPEATING_LABEL = 'EventEditActions.ON_SET_EVENT_REPEATING_LABEL',
    SET_EVENT_REPEATINGS = 'EventEditActions.ON_SET_EVENT_REPEATINGS',
    SET_EVENT_REPEATING = 'EventEditActions.ON_SET_EVENT_REPEATING',
    // Action Type for Event Location Type On Campus of Event Details
    SET_EVENT_LOCATION_ON_WHERE_CAMPUS = 'EventEditActions.ON_SET_EVENT_LOCATION_ON_WHERE_CAMPUS',
    SET_EVENT_LOCATION_ON_WHERE_SELECTED = 'EventEditActions.ON_SET_EVENT_LOCATION_ON_WHERE_SELECTED',
    SET_EVENT_LOCATION_ON_SUB_VENUES = 'EventEditActions.ON_SET_EVENT_LOCATION_ON_SUB_VENUES',
    SET_EVENT_LOCATION_ON_VENUES = 'EventEditActions.ON_SET_EVENT_LOCATION_ON_VENUES',
    SET_EVENT_LOCATION_ON_VENUE = 'EventEditActions.ON_SET_EVENT_LOCATION_ON_VENUE',
    SET_EVENT_LOCATION_ON_WHERE_VENUES = 'EventEditActions.ON_SET_EVENT_LOCATION_ON_WHERE_VENUES',
    SET_EVENT_LOCATION_ON_CAMPUS_TO_CHANGE = 'EventEditActions.ON_SET_EVENT_LOCATION_ON_CAMPUS_TO_CHANGE',
    SET_EVENT_LOCATION_ON_SHOW_PROMPT_TO_CHANGE_CAMPUS = 'EventEditActions.ON_SET_EVENT_LOCATION_ON_SHOW_PROMPT_TO_CHANGE_CAMPUS',
    // Action Type for Event Location Type Virtual of Event Details
    SET_EVENT_LOCATION_IS_VIRTUAL = 'EventEditActions.ON_SET_EVENT_LOCATION_IS_VIRTUAL',
    SET_EVENT_LOCATION_VIRTUAL_CAPACITY = 'EventEditActions.ON_SET_EVENT_LOCATION_VIRTUAL_CAPACITY',
    SET_EVENT_LOCATION_VIRTUAL_URL = 'EventEditActions.ON_SET_EVENT_LOCATION_VIRTUAL_URL',
    // Action Type for Event Location Type Off Campus of Event Details
    SET_EVENT_LOCATION_OFF_CAMPUS_ADDRESS_ONE = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_ADDRESS_ONE',
    SET_EVENT_LOCATION_OFF_CAMPUS_ADDRESS_TWO = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_ADDRESS_TWO',
    SET_EVENT_LOCATION_OFF_CAMPUS_CAPACITY = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_CAPACITY',
    SET_EVENT_LOCATION_OFF_CAMPUS_CITY = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_CITY',
    SET_EVENT_LOCATION_OFF_CAMPUS_COUNTRY = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_COUNTRY',
    SET_EVENT_LOCATION_OFF_CAMPUS_LATITUDE = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_LATITUDE',
    SET_EVENT_LOCATION_OFF_CAMPUS_LONGITUDE = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_LONGITUDE',
    SET_EVENT_LOCATION_OFF_CAMPUS_NAME = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_NAME',
    SET_EVENT_LOCATION_OFF_CAMPUS_POSTAL_CODE = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_POSTAL_CODE',
    SET_EVENT_LOCATION_OFF_CAMPUS_PRIVATE_ADDRESS = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_PRIVATE_ADDRESS',
    SET_EVENT_LOCATION_OFF_CAMPUS_REGION = 'EventEditActions.ON_SET_EVENT_LOCATION_OFF_CAMPUS_REGION',
    // Action Type for Event Data
    SET_EVENT_DATA = 'EventEditActions.ON_SET_EVENT_DATA',
    RESET_EVENT_DATA = 'EventEditActions.ON_RESET_EVENT_DATA',
}

export default ActionType;
