import { Grid, Typography } from '@saddlebackchurch/react-cm-ui';
import { makeStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    showRequiredIndicator: PropTypes.bool,
    style: PropTypes.shape({}),
    value: PropTypes.string.isRequired,
};

const defaultProps = {
    className: undefined,
    id: undefined,
    showRequiredIndicator: false,
    style: {},
};

const useStyles = makeStyles(({
    palette,
    typography,
}) => ({
    formLegend: {
        color: palette.text.secondary,
        fontSize: typography.pxToRem(14),
    },
    requiredFieldIndicator: {
        color: palette.error.main,
        marginLeft: 5.5,
    },
}));

function FormLegend(props) {
    const {
        className,
        id,
        showRequiredIndicator,
        style,
        value,
    } = props;

    const jssClasses = useStyles();
    const rootClasses = ClassNames(
        'form--legend',
        className,
        jssClasses.formLegend,
    );

    return (
        <Grid.Column
            className={rootClasses}
            fieldType="legend"
            id={id}
            sm={12}
            style={style}
        >
            <Typography
                color="textSecondary"
                variant="body2"
            >
                {value}

                {showRequiredIndicator && (
                    <span className={jssClasses.requiredFieldIndicator}>*</span>
                )}
            </Typography>
        </Grid.Column>
    );
}

FormLegend.propTypes = propTypes;
FormLegend.defaultProps = defaultProps;

export default FormLegend;
