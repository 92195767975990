import {
    filter,
    isNil,
} from 'lodash';
import {
    TicketEditor,
    TicketPricingOption,
    VisibilityTypeOption,
} from './models';
import { addOrReplaceBy } from '../../../../global/utils';
import ActionType from './ticket.actionTypes';

export const DEFAULT_STATE: TicketEditor = {
    accessCode: null,
    availableQuantity: null,
    description: '',
    generalAvailabilityEndDate: null,
    generalAvailabilityEndTime: null,
    generalAvailabilityStartDate: null,
    generalAvailabilityStartTime: null,
    groupPriceOptions: [],
    isActive: true,
    latestTicketSoldDate: null,
    maxTicketsPerOrder: null,
    name: '',
    regularPrice: null,
    pricingType: TicketPricingOption.SinglePrice,
    ticketId: null,
    visibilityType: VisibilityTypeOption.AvailableToEveryone,
};

const updateGroupPriceOptionAction = (
    existingState,
    groupPriceOptionId,
    objWithUpdates,
) => ({
    ...existingState,
    groupPriceOptions: filter(
        addOrReplaceBy(
            existingState.groupPriceOptions,
            (gpo) => gpo.id === groupPriceOptionId,
            (existingGroupPriceOption) => {
                if (!isNil(existingGroupPriceOption)) {
                    return {
                        ...existingGroupPriceOption,
                        ...objWithUpdates,
                    };
                }

                return null;
            },
        ),
        (a) => !isNil(a),
    ),
});

export default (
    state: TicketEditor | undefined,
    action: any,
): TicketEditor => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REMOVE_GROUP_PRICING_OPTION_RANGE: {
                const { value: id } = action;

                const filteredGroupPriceOptions = filter(
                    state.groupPriceOptions,
                    (priceOption) => priceOption.id !== id,
                );

                return {
                    ...state,
                    groupPriceOptions: [
                        ...filteredGroupPriceOptions,
                    ],
                };
            }

            case ActionType.RESET_DATA: {
                return DEFAULT_STATE;
            }

            case ActionType.RESET_GROUP_PRICING_OPTION: {
                return {
                    ...state,
                    groupPriceOptions: [],
                };
            }

            case ActionType.SET_AVAILABLE_QUANTITY: {
                const { value: availableQuantity } = action;

                return {
                    ...state,
                    availableQuantity,
                };
            }

            case ActionType.SET_ADD_NEW_GROUP_PRICING_OPTION_RANGE: {
                const { value: newGroupPricingOptionRange } = action;

                return {
                    ...state,
                    groupPriceOptions: [
                        ...state.groupPriceOptions,
                        newGroupPricingOptionRange,
                    ],
                };
            }

            case ActionType.SET_DESCRIPTION: {
                const { value: description } = action;

                return {
                    ...state,
                    description,
                };
            }

            case ActionType.SET_GENERAL_AVAILABLE_END_DATE: {
                const { value: generalAvailabilityEndDate } = action;

                return {
                    ...state,
                    generalAvailabilityEndDate,
                };
            }

            case ActionType.SET_GENERAL_AVAILABLE_END_TIME: {
                const { value: generalAvailabilityEndTime } = action;

                return {
                    ...state,
                    generalAvailabilityEndTime,
                };
            }

            case ActionType.SET_GENERAL_AVAILABLE_START_DATE: {
                const { value: generalAvailabilityStartDate } = action;

                return {
                    ...state,
                    generalAvailabilityStartDate,
                };
            }

            case ActionType.SET_GENERAL_AVAILABLE_START_TIME: {
                const { value: generalAvailabilityStartTime } = action;

                return {
                    ...state,
                    generalAvailabilityStartTime,
                };
            }

            case ActionType.SET_GROUP_PRICE_OPTION: {
                const { value: groupPriceOption } = action;

                return updateGroupPriceOptionAction(state, groupPriceOption.id, groupPriceOption);
            }

            case ActionType.SET_MAX_TICKETS_PER_ORDER: {
                const { value: maxTicketsPerOrder } = action;

                return {
                    ...state,
                    maxTicketsPerOrder,
                };
            }

            case ActionType.SET_NAME: {
                const { value: name } = action;

                return {
                    ...state,
                    name,
                };
            }

            case ActionType.SET_PRICING_TYPE: {
                const { value: pricingType } = action;

                return {
                    ...state,
                    pricingType,
                };
            }

            case ActionType.SET_REGULAR_PRICE: {
                const { value: regularPrice } = action;

                return {
                    ...state,
                    regularPrice,
                };
            }

            case ActionType.SET_TICKET_ACCESS_CODE: {
                const { value: accessCode } = action;

                return {
                    ...state,
                    accessCode,
                };
            }

            case ActionType.SET_TICKET_EDITOR_FORM: {
                const { value: ticketEditor } = action;

                return {
                    ...state,
                    ...ticketEditor,
                };
            }

            case ActionType.SET_VISIBILITY_TYPE: {
                const { value: visibilityType } = action;

                return {
                    ...state,
                    visibilityType,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
