import {
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    isFocused: PropTypes.bool.isRequired,
    onFocus: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    option: PropTypes.shape({
        counts: PropTypes.number,
        icon: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
};

class SelectionCustomComponent extends React.Component {
    onMouseDown(event) {
        const {
            onSelect,
            option,
        } = this.props;

        onSelect(option, event);

        event.preventDefault();
        event.stopPropagation();
    }

    onMouseEnter(event) {
        const {
            onFocus,
            option,
        } = this.props;

        onFocus(option, event);
    }

    onMouseMove(event) {
        const {
            isFocused,
            onFocus,
            option,
        } = this.props;

        if (isFocused) {
            return;
        }

        onFocus(option, event);
    }

    render() {
        const { option } = this.props;

        return (
            <div
                aria-selected
                className="Select-option"
                onMouseDown={this.onMouseDown.bind(this)}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseMove={this.onMouseMove.bind(this)}
                tabIndex={0}
                title={option.label}
                role="option"
            >
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}
                >
                    {option.icon ? (
                        <Icon
                            color="static"
                            title={false}
                            type={option.icon}
                        />
                    ) : null}

                    <div
                        className="name"
                    >
                        {`${option.label} (${option.counts})`}
                    </div>
                </div>
            </div>
        );
    }
}

SelectionCustomComponent.propTypes = propTypes;

export default SelectionCustomComponent;
