import { selectBoxTransform } from '../../global/utils';

export const DEFAULT_STATE = {
    isFetching: false,
    answerSources: [],
    answerSourceSelectOptions: [],
};

const AnswerSource = (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'AnswerSourceActions.ON_BEFORE_GETANSWERSOURCES':
            return {
                ...state,
                isFetching: true,
            };

        case 'AnswerSourceActions.ON_GETANSWERSOURCES':
            return {
                ...state,
                isFetching: false,
                answerSources: actionResult,
                answerSourceSelectOptions: selectBoxTransform(
                    action,
                    'id',
                    'name',
                ),
            };

        default:
            return state;
    }
};

export default AnswerSource;
