import _ from 'lodash';

export const DEFAULT_STATE = {
    categories: [],
    locationLeaders: {},
    tags: [],
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryActions.ON_GETCATEGORIES':
            return { ...state, categories: action.result };

        case 'MinistryActions.ON_GETTAGS':
            return { ...state, tags: action.result };

        case 'MinistryActions.ON_GETLOCATIONLEADERS':
        {
            const locationLeaders = {};
            _.forEach(action.result, (v) => {
                if (v.leader) {
                    locationLeaders[v.id] = v.leader;
                }
            });
            return { ...state, locationLeaders };
        }

        default:
            // no-op
    }

    return state;
}
