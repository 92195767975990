import './personNotesDrawerNote.scss';

import _ from 'lodash';
import {
    Header,
    Icon,
    Image,
    Prompt,
} from '@saddlebackchurch/react-cm-ui';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Classnames from 'classnames';
import { translationFactory } from '../../../global/i18nUtils.js';
import { noteDefaultProps, notePropTypes } from './notePropTypes.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import UserAccessStore from '../../../global/userAccessStore.js';
import NoteReminderIconHandler from './noteReminders/noteReminderIcon';
import {
    setIsReminderFormModalOpen,
    setSelectedNote,
    setSelectedReminder,
} from './noteReminders/noteReminderActions';
import eventReminderActions from '../../../event/eventsCentral/actions/eventReminder.actions';
import personRecordUtils from './personRecordUtils.js';

const i18n = translationFactory('Person.Record.Notes');

const propTypes = {
    classes: PropTypes.shape({
        desktopNoteActionsContainer: PropTypes.shape({}),
    }).isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    data: notePropTypes,
    loggedInUserPersonId: PropTypes.number.isRequired,
    onNotePinClick: PropTypes.func,
    onNoteEditClick: PropTypes.func,
    onNoteRemoveClick: PropTypes.func,
    onOpenNoteWing: PropTypes.func.isRequired,
    onViewNoteDetails: PropTypes.func.isRequired,
    setIsReminderFormModalOpen: PropTypes.func.isRequired,
    setSelectedNote: PropTypes.func.isRequired,
    setSelectedReminder: PropTypes.func.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    data: noteDefaultProps,
    onNotePinClick: undefined,
    onNoteEditClick: undefined,
    onNoteRemoveClick: undefined,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
                userIdentity,
            },
        },
        people: {
            record: {
                noteForm: {
                    hasCreateReminderButtonBeenClicked,
                    selectedReminder,
                },
            },
        },
    } = state;

    const loggedInUserPersonId = userIdentity.getPersonId();

    return {
        loggedInUserPersonId,
        userAccess,
        hasCreateReminderButtonBeenClicked,
        selectedReminder,
    };
};

const mapDispatchToProps = {
    setIsReminderFormModalOpen,
    setSelectedReminder,
    setSelectedNote,
};

const useStyles = (theme) => ({
    desktopNoteActionsContainer: {
        '& .icon:hover': {
            '& .icon-use-path': {
                fill: theme.palette.cyan[500],
            },
        },
    },
});

class personNotesDrawerNote extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            reminderData: null,
            showPrompt: false,
        };

        this.getReminderData = this.getReminderData.bind(this);
        this.onOpenNoteDetails = this.onOpenNoteDetails.bind(this);
        this.onNoteCreateActionClick = this.onNoteCreateActionClick.bind(this);
        this.onNoteEdit = this.onNoteEdit.bind(this);
        this.onNotePin = this.onNotePin.bind(this);
        this.onNotePromptClick = this.onNotePromptClick.bind(this);
        this.onNotePromptNoClick = this.onNotePromptNoClick.bind(this);
        this.onNotePromptYesClick = this.onNotePromptYesClick.bind(this);
        this.onReminderIconClick = this.onReminderIconClick.bind(this);
    }

    componentDidMount() {
        this.getReminderData();
    }

    componentDidUpdate(prevProps) {
        const currentReminderId = _.get(this, 'props.data.reminderId');
        const shouldRefetchReminderData = _.get(prevProps, 'data.reminderId') !== currentReminderId;
        if (shouldRefetchReminderData) {
            if (currentReminderId) {
                this.getReminderData();
            } else {
                this.setState({
                    reminderData: null,
                });
            }
        }
    }

    onNoteCreateActionClick() {
        const {
            data,
            isMobile,
            setIsReminderFormModalOpen: setIsReminderFormModalOpenAction,
            setSelectedNote: setSelectedNoteAction,
            setSelectedReminder: setSelectedReminderAction,
        } = this.props;

        const {
            reminderData,
        } = this.state;

        if (!isMobile) {
            setIsReminderFormModalOpenAction(true, 'openFromNoteDetail');
            setSelectedNoteAction(data);
            setSelectedReminderAction(reminderData);
        }
    }

    onReminderIconClick() {
        const {
            setSelectedReminder: setSelectedReminderAction,
            setIsReminderFormModalOpen: setIsReminderFormModalOpenAction,
            setSelectedNote: setSelectedNoteAction,
            data,
        } = this.props;

        const {
            reminderData,
        } = this.state;

        setSelectedReminderAction(reminderData);
        setSelectedNoteAction(data);
        setIsReminderFormModalOpenAction(true, 'openFromNoteDetail');
    }

    onNoteEdit() {
        const {
            data,
            onNoteEditClick,
            setSelectedNote: setSelectedNoteAction,
            setSelectedReminder: setSelectedReminderAction,
        } = this.props;

        const {
            reminderData,
        } = this.state;

        onNoteEditClick(data.id);
        setSelectedNoteAction(data);
        setSelectedReminderAction(reminderData);
    }

    onNotePin() {
        const { data, onNotePinClick } = this.props;
        onNotePinClick(data.id, data.isPinned);
    }

    onOpenNoteDetails() {
        const {
            data,
            onOpenNoteWing,
            onViewNoteDetails,
            setSelectedNote: setSelectedNoteAction,
            setSelectedReminder: setSelectedReminderAction,
        } = this.props;

        const {
            reminderData,
        } = this.state;

        onOpenNoteWing('details');
        onViewNoteDetails(data);
        setSelectedNoteAction(data);
        setSelectedReminderAction(reminderData);
    }

    onNotePromptClick() {
        this.setState({ showPrompt: true });
    }

    onNotePromptNoClick() {
        this.setState({ showPrompt: false });
    }

    onNotePromptYesClick() {
        const { data, onNoteRemoveClick } = this.props;
        onNoteRemoveClick(data.id);
        this.setState({ showPrompt: false });
    }

    getReminderData() {
        const {
            data: {
                reminderId,
            },
        } = this.props;
        if (reminderId) {
            eventReminderActions.get({ id: reminderId })
                .then((res) => {
                    const reminderEndDate = _.get(res, 'schedule.endDate');
                    const momentDueDate = reminderEndDate && moment(reminderEndDate);
                    this.setState({
                        reminderData: {
                            ...res,
                            dueDate: momentDueDate,
                        },
                    });
                });
        } else {
            this.setState({
                reminderData: null,
            });
        }
    }

    render() {
        const {
            classes,
            data,
            isExpanded,
            isMobile,
            loggedInUserPersonId,
            userAccess,
        } = this.props;

        const {
            churchEntity,
            createdBy,
            createdByEmail: email,
            createdByName: fullName,
            createdByAvatar: avatarUrl,
            subject,
            ministry,
            noteText,
            isPinned,
            categoryName,
            modifyDate,
            categoryId,
            taskName,
        } = data;
        const {
            reminderData,
            showPrompt,
        } = this.state;

        const blockClassName = 'person_notes_drawer--note';
        const onNotesProps = {
            className: blockClassName,
            ...(isMobile && { onClick: this.onOpenNoteDetails }),
        };

        const canEditPersonNote = userAccess.hasPermission(
            USER_PERMISSIONS.updatePersonNotes,
        );

        const authorizedEditNoteCategoryIds = userAccess.getAuthzdNotesCategoryIdsByPermission(
            USER_PERMISSIONS.updatePersonNotes,
        );

        const hasEditCategoryNotePermission = _.some(authorizedEditNoteCategoryIds,
            (id) => id === categoryId);

        const canDeletePersonNote = userAccess.hasPermission(
            USER_PERMISSIONS.deletePersonNotes,
        );

        const authorizedDeleteNoteCategoryIds = userAccess.getAuthzdNotesCategoryIdsByPermission(
            USER_PERMISSIONS.deletePersonNotes,
        );

        const hasDeleteCategoryNotePermission = _.some(authorizedDeleteNoteCategoryIds,
            (id) => id === categoryId);

        const didLoggedInUserCreatedTheNote = loggedInUserPersonId === createdBy;

        let noteActions;

        const actionsContainerClasses = Classnames(
            `${blockClassName}_actions`,
            [classes.desktopNoteActionsContainer],
        );

        if (!isMobile) {
            noteActions = (
                <div
                    className={actionsContainerClasses}
                    style={{ marginTop: isExpanded ? '6px' : '0px' }}
                >
                    {(didLoggedInUserCreatedTheNote && !reminderData) && (
                        <Icon
                            align="right"
                            id={`${blockClassName}--create_note_reminder`}
                            onClick={this.onNoteCreateActionClick}
                            type="clock-period"
                        />
                    )}
                    {canEditPersonNote && hasEditCategoryNotePermission && (
                        <Icon
                            align="right"
                            id={`${blockClassName}--pin_person_note`}
                            onClick={this.onNotePin}
                            type="pin"
                        />
                    )}
                    {canEditPersonNote &&
                        hasEditCategoryNotePermission && didLoggedInUserCreatedTheNote && (
                            <Icon
                                align="right"
                                id={`${blockClassName}--edit_person_note`}
                                onClick={this.onNoteEdit}
                                type="edit"
                            />
                    )}
                    {canDeletePersonNote && hasDeleteCategoryNotePermission && (
                        <Prompt
                            id={`${blockClassName}--remove_person_note_prompt`}
                            inline
                            inlineHorizontalAlign="right"
                            inlineMessageColor="alert"
                            message={i18n('AreYouSure')}
                            onClick={this.onNotePromptClick}
                            onYesClick={this.onNotePromptYesClick}
                            onNoClick={this.onNotePromptNoClick}
                            show={showPrompt}
                        >
                            <Icon
                                align="right"
                                id={`${blockClassName}--remove_person_note`}
                                type="trash"
                            />
                        </Prompt>
                    )}
                </div>
            );
        }

        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...onNotesProps}>
                <aside className={`${blockClassName}_left_column`}>
                    <Icon
                        className={`${blockClassName}_indicator`}
                        color="warning"
                        type="cards"
                        role="button"
                    />

                    {isPinned && (
                        <Icon
                            className={`${blockClassName}_pinned_indicator`}
                            color="primary"
                            type="pin"
                            role="button"
                        />
                    )}

                    {(didLoggedInUserCreatedTheNote && reminderData) && (
                        <NoteReminderIconHandler
                            defaultColor="primary"
                            renderedFrom="noteDetail"
                            isMobile={isMobile}
                            disableLabel
                            onClick={this.onReminderIconClick}
                            reminderData={reminderData}
                        />
                    )}
                </aside>

                <div className={`${blockClassName}_right_column`}>
                    <div className={`${blockClassName}_header`}>
                        <span className={`${blockClassName}_date`}>
                            {moment.unix(modifyDate).format('MM/DD/YY')}
                        </span>

                        <span className={`${blockClassName}_type`}>
                            {categoryName}
                        </span>
                    </div>

                    <Header
                        className={`${blockClassName}_subject`}
                        size="xsmall"
                        weight="bold"
                    >
                        {_.upperFirst(subject)}
                    </Header>

                    <div
                        className={`${blockClassName}_message`}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: isMobile ?
                                _.truncate(noteText, { length: 85 }) : personRecordUtils.linkify(noteText),
                        }}
                    />

                    {(categoryId === 2 || categoryId === 6) &&
                        !_.isEmpty(ministry) &&
                        !_.isEmpty(churchEntity) && (
                        <div className={`${blockClassName}_ministry_info`}>
                            <span className={`${blockClassName}_ministry_location_name`}>
                                {`${ministry} | ${churchEntity}`}
                            </span>
                        </div>
                    )}

                    {(categoryId === 2 || categoryId === 7) && !_.isEmpty(taskName) && (
                        <div className={`${blockClassName}_task`}>
                            <span className={`${blockClassName}_task_name`}>
                                {taskName}
                            </span>
                        </div>
                    )}

                    {isExpanded && !isMobile ? (
                        <div
                            className={`${blockClassName}_footer`}
                            style={{
                                marginTop: '22px',
                            }}
                        >
                            <div className={`${blockClassName}_footer_meta_data`}>
                                <Image
                                    className={`${blockClassName}_author_image`}
                                    name={fullName}
                                    size={33}
                                    src={avatarUrl}
                                    type="user"
                                />
                                <div
                                    className={`${blockClassName}_author`}
                                    style={{
                                        marginLeft: '11px',
                                    }}
                                >
                                    <span className={`${blockClassName}_author_name`}>
                                        {fullName}
                                    </span>
                                    <span className={`${blockClassName}_author_email`}>
                                        {email}
                                    </span>
                                </div>
                            </div>
                            {noteActions}
                        </div>

                    ) :
                        (
                            <div className={`${blockClassName}_footer`}>
                                <div
                                    className={`${blockClassName}_footer_meta_data`}
                                >
                                    <span className={`${blockClassName}_author_name`}>
                                        {fullName}
                                    </span>
                                </div>
                                {noteActions}
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

personNotesDrawerNote.propTypes = propTypes;
personNotesDrawerNote.defaultProps = defaultProps;

const personNotesDrawerNoteWithStyles = withStyles(
    useStyles, { withTheme: true },
)(personNotesDrawerNote);

export default connect(mapStateToProps, mapDispatchToProps)(personNotesDrawerNoteWithStyles);
