enum PaymentPlanStatus {
    Unknown = 'Unknown',
    Active = 'Active',
    PastDue = 'PastDue',
    Unpaid = 'Unpaid',
    Canceled = 'Canceled',
    Incomplete = 'Incomplete',
    IncompleteExpired = 'IncompleteExpired',
    Trialing = 'Trialing',
    Paused = 'Paused',
}

export default PaymentPlanStatus;
