const DEFAULT_STATE = {
    isFetching: true,
    data: {},
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'CommonlyAttendedServiceActions.ON_BEFORE_GET': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'CommonlyAttendedServiceActions.ON_GET': {
            return {
                ...state,
                isFetching: false,
                data: action.result,
            };
        }

        case 'CommonlyAttendedServiceActions.ON_RESET': {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
