export const DEFAULT_STATE = {
    address1: '',
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    nickName: '',
    phone: '',
    searchTerm: '',
    zipCode: '',
};

export default (state = DEFAULT_STATE, action) => {
    const {
        type: actionType,
        value: actionValue,
    } = action;

    switch (actionType) {
        case 'PeopleSearchAdvancedFields.ON_CLEAR_SEARCH_TERM':
        {
            return {
                ...state,
                searchTerm: '',
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_ADDRESS':
        {
            return {
                ...state,
                address1: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_CITY':
        {
            return {
                ...state,
                city: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_EMAIL':
        {
            return {
                ...state,
                email: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_FIRSTNAME':
        {
            return {
                ...state,
                firstName: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_LASTNAME':
        {
            return {
                ...state,
                lastName: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_NICKNAME':
        {
            return {
                ...state,
                nickName: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_PHONE':
        {
            return {
                ...state,
                phone: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_SEARCH_TERM':
        {
            return {
                ...state,
                searchTerm: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_UPDATE_ZIPCODE':
        {
            return {
                ...state,
                zipCode: actionValue,
            };
        }

        case 'PeopleSearchAdvancedFields.ON_RESET':
            return DEFAULT_STATE;

        default:
        // no-op
    }

    return state;
};
