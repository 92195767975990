import {
    cloneDeep,
    find,
    isEmpty,
    isNil,
    some,
} from 'lodash';
import IUserIdentityStore from './userIdentityStore.interface';
import Utils from './utils/utils.js';

/*
 * This 'store' encapsulates the logged in user's identity.
 */
export default class UserIdentityStore implements IUserIdentityStore {
    // Private class fields!
    // Courtesty of:
    // https://www.sitepoint.com/javascript-private-class-fields/
    // https://github.com/tc39/proposal-private-methods
    // https://www.npmjs.com/package/@babel/plugin-proposal-class-properties
    /* eslint-disable @typescript-eslint/lines-between-class-members */
    #personId = 0;
    #firstName = '';
    #lastName = '';
    #nickname = '';
    #fullName = '';
    #primaryEmail = '';
    #publicEmail = '';
    #ministryLeadership = [];
    #teams = [];
    #hasAcceptedDataCovenant = false;
    /* eslint-enable @typescript-eslint/lines-between-class-members */

    constructor(boostrapSecurityContext: any = {}) {
        // this is necessary to handle if argument is explicitly `null`
        const securityCtx = boostrapSecurityContext || {};

        this.#personId = securityCtx.personId || 0;
        this.#firstName = securityCtx.firstName || '';
        this.#lastName = securityCtx.lastName || '';
        this.#nickname = securityCtx.userNickname || '';
        this.#primaryEmail = securityCtx.email || '';
        this.#publicEmail = securityCtx.publicEmail || '';
        this.#ministryLeadership = securityCtx.ministryLeadership || [];
        this.#teams = securityCtx.teams || [];
        this.#hasAcceptedDataCovenant = securityCtx.acceptedDataCovenant || false;

        // compute full name
        const givenName = Utils.isStringNullOrWhiteSpace(this.#nickname) ?
            this.#firstName :
            this.#nickname;

        this.#fullName = `${givenName} ${this.#lastName}`.trim();
    }

    /*
     * Gets the specified Ministry Location from logged in user's set of ministries (if it exists)
     */
    findMinistryLocation(ministryId, churchEntityId) {
        const ministry = find(
            this.#ministryLeadership,
            // eslint-disable-next-line max-len
            (m) => (m.ministryId * 1 === ministryId * 1) && (m.churchEntityId * 1 === churchEntityId * 1),
        );

        return isNil(ministry) ? null : { ...ministry };
    }

    /*
     * Gets the logged in user's first name
     */
    getFirstName() {
        return this.#firstName;
    }

    /*
     * Gets the logged in user's "full name"
     * (given name and surname concatenated with a space as a separator).
     * For the first or "given" name, this method will use the nickname if specified,
     * colaescing on the first name.
     */
    getFullName() {
        return this.#fullName;
    }

    /*
     * Gets the logged in user's last name
     */
    getLastName() {
        return this.#lastName;
    }

    /*
     * Gets the set of Ministries where the logged in user holds a Leadership Position
     */
    getMinistries() {
        return cloneDeep(this.#ministryLeadership); // enforcing immutability
    }

    /*
     * Gets the logged in user's nickname
     */
    getNickname() {
        return this.#nickname;
    }

    /*
     * Gets the logged in user's Person Unique ID
     */
    getPersonId() {
        return this.#personId;
    }

    /*
     * Gets the logged in user's primary email address
     */
    getPrimaryEmail() {
        return this.#primaryEmail;
    }

    /*
     * Gets the logged in user's public email address
     */
    getPublicEmail() {
        return this.#publicEmail;
    }

    /*
     * Gets the logged in user's public email address, coalescing on the primary email
     * if the public email is not specified.
     */
    getPublicOrPrimaryEmail() {
        return Utils.isStringNullOrWhiteSpace(this.#publicEmail) ?
            this.#primaryEmail :
            this.#publicEmail;
    }

    /*
     * Gets the set of Teams to which the logged in user belongs
     */
    getTeams() {
        return cloneDeep(this.#teams); // enforcing immutability
    }

    /*
     * Returns a Boolean flag indicating whether the logged in user has accepted the
     * "Data Covernant" agreement
     */
    hasAcceptedDataCovenant() {
        return this.#hasAcceptedDataCovenant;
    }

    /*
     * Returns a Boolean flag indicating whether the logged in user holds a
     * local (not global) Leadership Position for any Ministries.
     */
    hasLocalMinistryLeadership() {
        return !isEmpty(this.#ministryLeadership);
    }

    /*
     * Returns a Boolean flag indicating whether the logged in user belongs to
     * the specified team
     */
    isMemberOfTeam(teamId) {
        return some(this.#teams, (t) => t.id === teamId);
    }
}
