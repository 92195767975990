import PushNotificationConstants from '../../constants/Notification/PushNotificationConstants';

export const DEFAULT_STATE = {
    connected: false,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case PushNotificationConstants.CONNECT:
            return { connected: action.connected };
    }
    return state;
}
