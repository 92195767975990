import './addEmergencyContactFromPersonDrawer.scss';
import _ from 'lodash';
import {
    Button,
    Drawer,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { translationFactory } from '../../../global/i18nUtils.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';
import ExistingRelationshipDrawerContent from './existingRelationshipDrawerContent.js';
import PeopleSearchDrawerContent from './peopleSearchDrawerContent.jsx';
import PeopleSearchDrawerSearchForm from './peopleSearchDrawerSearchForm.jsx';
import AddPerson from '../../global/addPerson.jsx';
import PossibleDuplicatesActions from '../../global/possibleDuplicatesActions.js';
import UserAccessStore from '../../../global/userAccessStore.js';

const propTypes = {
    emergencyContactList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    disablePerson: PropTypes.shape({}),
    isMobile: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    personId: PropTypes.number,
    possibleDuplicatesList: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    setPerson: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const defaultProps = {
    emergencyContactList: [],
    disablePerson: {},
    isOpen: false,
    personId: null,
    possibleDuplicatesList: [],
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        breakpoint: {
            isMobile,
        },
        people: {
            addPerson: {
                possibleDuplicates: {
                    data: possibleDuplicatesList,
                    isFetching: isFetchingPossibleDuplicates,
                    pageNumber,
                    pageSize,
                },
            },
        },
    } = state;

    return {
        isFetchingPossibleDuplicates,
        isMobile,
        pageNumber,
        pageSize,
        possibleDuplicatesList,
        userAccess,
    };
};

const i18n = translationFactory('User.UserAddFromPersonDrawer');

class AddEmergencyContactFromPersonDrawer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpenPossibleDuplicatesDrawer: false,
            liteFieldsSatisified: false,
            saveLiteNow: false,
            tabIndividualIndex: 0,
        };

        this.onCloseDrawer = this.onCloseDrawer.bind(this);
        this.onLiteFieldsSatisified = this.onLiteFieldsSatisified.bind(this);
        this.onLiteMoveOn = this.onLiteMoveOn.bind(this);
        this.onNavigationItemClick = this.onNavigationItemClick.bind(this);
        this.onPossibleDuplicatesDrawerToggle = this.onPossibleDuplicatesDrawerToggle.bind(this);
        this.onSelectPerson = this.onSelectPerson.bind(this);
    }

    onCloseDrawer() {
        const { onClose } = this.props;
        onClose();
        PossibleDuplicatesActions.reset();
        PossibleDuplicatesActions.resetPersonalDetails();
    }

    onLiteFieldsSatisified(value) {
        this.setState({
            liteFieldsSatisified: value,
        });
    }

    onLiteMoveOn() {
        this.setState({
            saveLiteNow: true,
        });
    }

    onNavigationItemClick(index) {
        this.setState({
            tabIndividualIndex: index,
        });
    }

    onPossibleDuplicatesDrawerToggle() {
        this.setState((prevState) => ({
            isOpenPossibleDuplicatesDrawer: !prevState.isOpenPossibleDuplicatesDrawer,
        }));
    }

    onSelectPerson(person) {
        const {
            onClose,
            setPerson,
        } = this.props;

        this.setState({
            saveLiteNow: false,
        }, () => {
            setPerson(person);
            onClose();
            this.setState({ isOpenPossibleDuplicatesDrawer: false });
        });
    }

    render() {
        const {
            emergencyContactList,
            isMobile,
            isOpen,
            personId,
            possibleDuplicatesList,
            title,
            userAccess,
        } = this.props;
        const {
            isOpenPossibleDuplicatesDrawer,
            liteFieldsSatisified,
            saveLiteNow,
            tabIndividualIndex,
        } = this.state;

        const blockClassName = 'user_add_from_person_drawer';
        const authzdForPersonSearch = userAccess.hasPermission(
            USER_PERMISSIONS.accessPersonSearch,
        );

        const authzdForPersonAdd = userAccess.hasAllPermissions([
            USER_PERMISSIONS.createPersonalData,
            USER_PERMISSIONS.createPersonContactData,
        ]);

        let drawerActionBarColumns = [];
        let drawerNavigationColumns = [];

        const possibleDuplicatesActionButtonDesktopJSX = (
            <Button
                color="alternate"
                id={`${blockClassName}--view_duplicates_btn`}
                outlined
                onClick={this.onPossibleDuplicatesDrawerToggle}
            >
                <span>{i18n('Possible Duplicates')}</span>
            </Button>
        );

        if (tabIndividualIndex === 0 || tabIndividualIndex === 2) {
            drawerActionBarColumns = [
                {
                    flexGrow: 1,
                    jsx: <PeopleSearchDrawerSearchForm
                        showAdvancedSearchFields={false}
                    />,
                },
            ];
        } else if (tabIndividualIndex === 1) {
            if (!isMobile) {
                drawerActionBarColumns = [
                    !_.isEmpty(possibleDuplicatesList) && {
                        jsx: possibleDuplicatesActionButtonDesktopJSX,
                        flexGrow: !_.isEmpty(possibleDuplicatesList) ? 1 : 0,
                        style: {
                            paddingRight: 0,
                            textAlign: 'right',
                        },
                    },
                    {
                        button: {
                            color: 'alternate',
                            className: `${blockClassName}--close`,
                            id: `${blockClassName}--close_btn`,
                            label: i18n('Cancel'),
                            onClick: this.onCloseDrawer,
                        },
                        flexGrow: _.isEmpty(possibleDuplicatesList) ? 1 : 0,
                        style: {
                            paddingRight: 0,
                            textAlign: 'right',
                        },
                    }, {
                        button: {
                            color: 'success',
                            disabled: !liteFieldsSatisified,
                            className: `${blockClassName}--save`,
                            id: `${blockClassName}--save_btn`,
                            label: i18n('Save'),
                            onClick: this.onLiteMoveOn,
                        },
                    },
                ];
            } else {
                drawerActionBarColumns = [
                    {
                        list: [
                            {
                                flexGrow: 1,
                                jsx: (
                                    <Button
                                        className={`${blockClassName}--close`}
                                        color="alternate"
                                        id={`${blockClassName}--close_375`}
                                        icon
                                        onClick={this.onCloseDrawer}
                                    >
                                        <Icon color="success" type="close" />
                                    </Button>
                                ),
                            }, {
                                jsx: (
                                    <Button
                                        className={`${blockClassName}--save`}
                                        color="success"
                                        disabled={!liteFieldsSatisified}
                                        id={`${blockClassName}--save_375`}
                                        icon
                                        onClick={this.onLiteMoveOn}
                                    >
                                        <Icon type="success" />
                                    </Button>
                                ),
                                divide: false,
                                style: {
                                    paddingLeft: 0,
                                },
                            },
                            !_.isEmpty(possibleDuplicatesList) && {
                                actionsButton: {
                                    header: 'Actions',
                                    options: [
                                        {
                                            iconType: 'users-circle',
                                            id: `${blockClassName}--view_duplicates_btn_375`,
                                            label: i18n('View Possible Duplicates'),
                                            onClick: this.onPossibleDuplicatesDrawerToggle,
                                        },
                                    ],
                                },
                                divide: false,
                                style: {
                                    paddingLeft: 0,
                                },
                            },
                        ],
                    },
                ];
            }
        }

        if (authzdForPersonSearch) {
            drawerNavigationColumns = [
                ...drawerNavigationColumns,
                {
                    label: `${i18n('Person')} ${i18n('Search')}`,
                    onClick: this.onNavigationItemClick,
                },
            ];
        }

        if (authzdForPersonAdd) {
            drawerNavigationColumns = [
                ...drawerNavigationColumns,
                {
                    label: `${i18n('New')} ${i18n('Person')}`,
                    onClick: this.onNavigationItemClick,
                },
            ];
        }

        if (personId) {
            drawerNavigationColumns = [
                ...drawerNavigationColumns,
                {
                    label: `${i18n('Existing Relationships')}`,
                    onClick: this.onNavigationItemClick,
                },
            ];
        }

        return (
            <Drawer
                className={blockClassName}
                isOpen={isOpen}
            >
                <Drawer.TitleBar
                    className={`${blockClassName}--title_bar`}
                    closeButton={(
                        <Button
                            color="alternate"
                            icon
                            id={`${blockClassName}--title_bar_close`}
                            onClick={this.onCloseDrawer}
                        >
                            <Icon type="times" />
                        </Button>
                    )}
                    title={title}
                />

                <Drawer.Navigation
                    className={`${blockClassName}--navigation_bar`}
                    columns={drawerNavigationColumns}
                    selectedColumnIndex={tabIndividualIndex}
                />
                {tabIndividualIndex !== 2 && (
                    <Drawer.ActionBar
                        className={`${blockClassName}--action_bar`}
                        columns={drawerActionBarColumns}
                    />
                )}

                <Drawer.Content
                    className={`${blockClassName}--content`}
                >
                    {tabIndividualIndex === 0 && (
                        <PeopleSearchDrawerContent
                            personId={personId}
                            setPerson={this.onSelectPerson}
                            showAdvancedSearchFields={false}
                        />
                    )}

                    {tabIndividualIndex === 1 && (
                        <AddPerson
                            canSave={this.onLiteFieldsSatisified}
                            isOpenPossibleDuplicatesDrawer={isOpenPossibleDuplicatesDrawer}
                            onPossibleDuplicatesDrawerToggle={this.onPossibleDuplicatesDrawerToggle}
                            onSave={this.onSelectPerson}
                            saveNow={saveLiteNow}
                            useThisRecord={this.onSelectPerson}
                        />
                    )}

                    {tabIndividualIndex === 2 && personId && (
                        <ExistingRelationshipDrawerContent
                            emergencyContactList={emergencyContactList}
                            personId={personId}
                            setPerson={this.onSelectPerson}
                            showAdvancedSearchFields={false}
                        />
                    )}
                </Drawer.Content>
            </Drawer>
        );
    }
}

AddEmergencyContactFromPersonDrawer.propTypes = propTypes;
AddEmergencyContactFromPersonDrawer.defaultProps = defaultProps;

export default connect(mapStateToProps)(AddEmergencyContactFromPersonDrawer);
