import {
    clone,
    filter,
    find,
    isEmpty,
    size,
} from 'lodash';

const DEFAULT_STATE = {
    isSelectAllChecked: false,
    selectedPersons: [],
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'SearchPersonDrawerPersonSearchActions.ON_RESET':
            return DEFAULT_STATE;

        case 'SearchPersonDrawerPersonSearchActions.ON_SELECT_PERSON': {
            const { id } = action.person;
            const isExists = find(state.selectedPersons, { id });

            const selectedPersons = isExists ?
                filter(state.selectedPersons, (v) => v.id !== id) :
                [...state.selectedPersons, action.person];

            const isSelectAllChecked = !isEmpty(selectedPersons) &&
                size(action.searchResults) === size(selectedPersons);

            return {
                isSelectAllChecked,
                selectedPersons,
            };
        }

        case 'SearchPersonDrawerPersonSearchActions.ON_SELECT_ALL':
            return {
                ...state,
                isSelectAllChecked: action.isSelectAllChecked,
                selectedPersons: action.isSelectAllChecked ? clone(action.searchResults) : [],
            };

        default:
            return state;
    }
}
