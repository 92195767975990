import {
    MinistryLocation,
} from '../global/api/core/models';

const DEFAULT_STATE: MinistryLocation | null = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'SelectMinistryActions.ON_MINISTRY_SELECTED':
            return {
                ...action.ministryLocation,
            };

        case 'SelectMinistryActions.RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
