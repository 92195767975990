import {
    i18n,
} from '../../../global/constants.js';

export const BEM_BLOCK_NAME = 'designation_list';

export const SORT_OPTIONS = [
    {
        label: i18n('Name (A > Z)'),
        value: 'name',
    }, {
        label: i18n('Name (Z > A)'),
        value: '-name',
    }, {
        label: i18n('Creation Date (Asc)'),
        value: 'create_date',
    }, {
        label: i18n('Creation Date (Desc)'),
        value: '-create_date',
    },
];
