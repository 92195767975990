import request from 'axios';
import { appReduxStore } from '../global/configureReduxStore.js';
import {
    USER_PERMISSIONS,
} from '../global/userPermissionConstants.js';
import ApiUtils from '../global/apiUtils.js';

const isPublishedOnly = ({
    store,
}) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        myAccount: {
            preferences: {
                isCmsPreviewEnabled,
            },
        },
    } = store.getState();

    const hasBrowseInDraftModePermission = userAccess.hasPermission(
        USER_PERMISSIONS.browseInDraftMode,
    );

    const publishedOnly = !(hasBrowseInDraftModePermission && isCmsPreviewEnabled);

    return publishedOnly;
};

class HelpActions extends ApiUtils {
    constructor(url) {
        super(url);

        this.store = appReduxStore;
    }

    getAllHelpArticles() {
        this.fetchAllHelpArticles({
            publishedOnly: isPublishedOnly({ store: this.store }),
        })
            .catch((response) => {
                // eslint-disable-next-line no-console
                console.log('Error getting All Help Articles:', response);
            });
    }

    getFilteredHelpArticles({
        module,
        name,
    }) {
        this.fetchFilteredHelpArticles({
            module,
            name,
            publishedOnly: isPublishedOnly({ store: this.store }),
        })
            .catch((response) => {
                // eslint-disable-next-line no-console
                console.log('Error getting Filtered Help Articles:', response);
            });
    }

    resetFilteredHelpArticles() {
        appReduxStore.dispatch({
            type: 'HelpActions.ON_RESETFILTEREDHELPARTICLES',
        });
    }

    resetHelpArticle() {
        appReduxStore.dispatch({
            type: 'HelpActions.ON_RESETHELPARTICLE',
        });
    }
}

const actions = new HelpActions('/api/help-content/');

actions.buildReduxActionClass(
    {
        name: 'fetchAllHelpArticles',
        route: 'filter?publishedOnly={publishedOnly}',
        rq: request.get,
        suppressError: true,
    }, {
        name: 'fetchFilteredHelpArticles',
        route: 'filter?module={module}&name={name}&publishedOnly={publishedOnly}',
        rq: request.get,
        suppressError: true,
    }, {
        name: 'getHelpArticleById',
        route: '{contentId}?versionId={versionId}',
        rq: request.get,
    },
);

export default actions;
