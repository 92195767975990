export const reset = () => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_RESET',
    });
};

export const clearSearchTerm = () => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_CLEAR_SEARCH_TERM',
    });
};

export const updateAddress = (address) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_ADDRESS',
        value: address,
    });
};

export const updateCity = (city) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_CITY',
        value: city,
    });
};

export const updateEmail = (email) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_EMAIL',
        value: email,
    });
};

export const updateFirstName = (firstName) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_FIRSTNAME',
        value: firstName,
    });
};

export const updateLastName = (lastName) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_LASTNAME',
        value: lastName,
    });
};

export const updateNickName = (nickName) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_NICKNAME',
        value: nickName,
    });
};

export const updatePhone = (phone) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_PHONE',
        value: phone,
    });
};

export const updateSearchTerm = (searchTerm) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_SEARCH_TERM',
        value: searchTerm,
    });
};

export const updateZipCode = (zipCode) => (dispatch) => {
    dispatch({
        type: 'PeopleSearchAdvancedFields.ON_UPDATE_ZIPCODE',
        value: zipCode,
    });
};
