/**
 * Giving Opportunity Type (Designation or Campaign)
 *
 * Based on back-end enum [`/api/src/Services/Giving/Saddleback.Cm.Contracts.Giving/Models/GivingOpportunityType.cs`](https://github.com/saddlebackdev/church-management/blob/dev/api/src/Services/Giving/Saddleback.Cm.Contracts.Giving/Models/GivingOpportunityType.cs).
 */
enum GivingOpportunityType {
    Campaign = 'Campaign',
    Designation = 'Designation',
}

export default GivingOpportunityType;
