import _ from 'lodash';

export const DEFAULT_STATE = [
];

const _id = 1;

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'ScopeActions.ON_UPDATE':
            if (action.params.scopes) {
                return _.cloneDeep(action.params.scopes);
            } if (action.params.scope) {
                const scopes = _.cloneDeep(state);
                scopes[action.params.idx] = _.clone(action.params.scope);
                return scopes;
            }
    }

    return state;
}
