import request from 'axios';
import ApiUtils from '../../../global/apiUtils.js';
import { appReduxStore } from '../../../global/configureReduxStore.js';

class PersonNotesActions extends ApiUtils {
    nextPage() {
        appReduxStore.dispatch({ type: 'PersonNotesActions.ON_NEXTPAGE' });
    }

    resetNoteFilters() {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'PersonNotesActions.ON_FILTER_NOTES_RESET',
            });
            resolve();
        });
    }

    resetNoteForm() {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'PersonNotesActions.ON_RESET_NOTE_FORM',
            });
            resolve();
        });
    }

    resetNotes() {
        appReduxStore.dispatch({
            type: 'PersonNotesActions.ON_RESET',
        });
    }

    setWingType(wingType = null) {
        appReduxStore.dispatch({
            type: 'PersonNotesActions.ON_SET_WING_TYPE',
            value: wingType,
        });
    }

    setNoteFormChangeStatus(status = false) {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'PersonNotesActions.ON_SET_NOTE_FORM_CHANGE_STATUS',
                value: status,
            });
            resolve();
        });
    }

    setNoteFormData(data) {
        appReduxStore.dispatch({
            type: 'PersonNotesActions.ON_SET_NOTE_FORM_DATA',
            value: data,
        });
    }

    setNoteFilterData(data) {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'PersonNotesActions.ON_FILTER_NOTES_SET',
                value: data,
            });
            resolve();
        });
    }

    toggleIsRemindersChecked() {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'PersonNotesActions.ON_FILTER_NOTES_TOGGLE_REMINDERS',
            });
            resolve();
        });
    }

    updateCategory(categoryId) {
        appReduxStore.dispatch({
            type: 'PersonNotesActions.ON_UPDATE_CATEGORY',
            value: categoryId,
        });
    }

    updateFilterCategories(selectedCategories) {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'PersonNotesActions.ON_FILTER_NOTES_CATEGORIES',
                value: selectedCategories,
            });
            resolve();
        });
    }

    updateFilterIsPinned(isPinned) {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'PersonNotesActions.ON_FILTER_NOTES_PINNED',
                value: isPinned,
            });
            resolve();
        });
    }

    updateFilterSortBy(sortBy) {
        return new Promise((resolve) => {
            appReduxStore.dispatch({
                type: 'PersonNotesActions.ON_FILTER_NOTES_SORTBY',
                value: sortBy,
            });
            resolve();
        });
    }

    updateSubject(subject) {
        appReduxStore.dispatch({
            type: 'PersonNotesActions.ON_UPDATE_SUBJECT',
            value: subject,
        });
    }

    updateNoteText(noteText) {
        appReduxStore.dispatch({
            type: 'PersonNotesActions.ON_UPDATE_NOTE_TEXT',
            value: noteText,
        });
    }

    updatePurpose(purpose) {
        appReduxStore.dispatch({
            type: 'PersonNotesActions.ON_UPDATE_PURPOSE',
            value: purpose,
        });
    }
}

const actions = new PersonNotesActions('/api/person/');

actions.buildReduxActionClass(
    {
        name: 'get',
        route: '{personId}/note',
        rq: request.get,
    }, {
        name: 'getCategories',
        route: 'admin/note-category',
        rq: request.get,
    }, {
        name: 'create',
        route: '{personId}/note',
        rq: request.post,
    }, {
        name: 'update',
        route: '{personId}/note/{noteId}',
        rq: request.put,
    }, {
        name: 'delete',
        route: '{personId}/note/{noteId}',
        rq: request.delete,
    }, {
        name: 'pinNote',
        route: '{personId}/note/pin',
        rq: request.post,
    }, {
        name: 'unpinNote',
        route: '{personId}/note/unpin',
        rq: request.post,
    }, {
        name: 'search',
        route: 'note/search',
        rq: request.get,
    }, {
        name: 'getNote',
        route: 'note/{noteId}',
        rq: request.get,
    }, {
        name: 'createReminder',
        route: '{personId}/note/{noteId}/reminder',
        rq: request.post,
    },
);

export default actions;
