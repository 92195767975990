import keyMirror from 'keymirror';
import { i18n } from '../../../global/constants.js';

export const ATTRIBUTE_TYPE = keyMirror({
    AnswerSourceEntity: null,
    AnswerSourceType: null,
    AnswerValue: null,
    Campus: null,
    PersonHasMetPrerequisiteMilestones: null,
});

export const ATTRIBUTE_PHRASE = Object.freeze({
    AnswerSourceEntity: i18n('Answer Source'), // TODO: Reword this if and when we need it for future use cases
    AnswerSourceType: i18n('Where the information was collected'),
    AnswerValue: i18n('How the question is answered'),
    Campus: i18n('A Campus'),
    PersonHasMetPrerequisiteMilestones: i18n('Has the person completed prerequisites'),
});
