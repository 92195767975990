export const DEFAULT_STATE = {
    ruleCounts: null,
    isAddingNewRule: false,
    isDeletingRule: false,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'ConnectionFormTemplatesActions.ON_GETFOLLOWUPRULECOUNTS':
            return {
                ...state,
                ruleCounts: action.result || {},
            };

        case 'ConnectionFormTemplatesActions.ON_RESET_ADDING_NEW_RULE_FLAG':
            return {
                ...state,
                isAddingNewRule: false,
            };

        case 'ConnectionFormTemplatesActions.ON_RESET_DELETING_RULE_FLAG':
            return {
                ...state,
                isDeletingRule: false,
            };

        case 'ConnectionFormTemplatesActions.ON_RESET_FOLLOW_UP_RULE_COUNTS':
            return DEFAULT_STATE;

        case 'WorkflowRuleEditorActions.ON_BEFORE_CREATEWORKFLOWRULE':
            return {
                ...state,
                isAddingNewRule: true,
            };

        case 'WorkflowRuleEditorActions.ON_BEFORE_DELETEWORKFLOWRULE':
            return {
                ...state,
                isDeletingRule: true,
            };

        default:
            return state;
    }
};
