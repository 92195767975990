import { findIndex } from 'lodash';

// This function borrowed from: https://stackoverflow.com/a/54834635
export default function addOrReplaceBy(
    arr: any[] = [],
    predicate: (any) => boolean,
    getItem: (existingItem?: any) => any,
): any[] {
    const index = findIndex(arr, predicate);
    return index === -1 ?
        [...arr, getItem()] :
        [
            ...arr.slice(0, index),
            getItem(arr[index]),
            ...arr.slice(index + 1),
        ];
}
