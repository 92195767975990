import request from 'axios';
import ApiUtils from '../../../../global/apiUtils.js';
import { appReduxStore } from '../../../../global/configureReduxStore.js';

class ConnectionFormEntriesByPersonActions extends ApiUtils {
    resetAll() {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntriesByPersonActions.RESET_ALL',
        });
    }

    resetEntriesList() {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntriesByPersonActions.RESET_ENTRIES_LIST',
        });
    }

    resetSingleEntry() {
        appReduxStore.dispatch({
            type: 'ConnectionFormEntriesByPersonActions.RESET_SINGLE_ENTRY',
        });
    }
}

const actions = new ConnectionFormEntriesByPersonActions('/api/connection-form/entry');

actions.buildReduxActionClass(
    {
        name: 'getEntriesList',
        route: '?personId={personId}&sortQuery=-eventdate&pageNumber=0&pageSize=150', // TODO: Can parameterize page number and page size if needed
        rq: request.get,
    },
    {
        name: 'getEntry',
        route: '/{entryId}',
        rq: request.get,
    },
);

export default actions;
