const DEFAULT_STATE = {
    campuses: [],
    entries: {
        count: 0,
        data: [],
        isFetching: false,
    },
    entry: {
        data: {},
        isFetching: false,
    },
    template: {
        data: {},
        isFetching: false,
    },
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'ConnectionCardsActions.ON_GETCAMPUSES': {
            return {
                ...state,
                campuses: actionResult,
            };
        }

        case 'ConnectionCardsActions.ON_BEFORE_GETENTRIES': {
            return {
                ...state,
                entries: {
                    ...state.entries,
                    isFetching: true,
                },
            };
        }

        case 'ConnectionCardsActions.ON_GETENTRIES': {
            const {
                resultCount: count,
                results: data,
            } = actionResult;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    count,
                    data,
                    isFetching: false,
                },
            };
        }

        case 'ConnectionCardsActions.ON_RESETENTRIES': {
            return {
                ...state,
                entries: {
                    ...DEFAULT_STATE.entries,
                },
            };
        }

        case 'ConnectionCardsActions.ON_BEFORE_GETENTRY': {
            return {
                ...state,
                entry: {
                    ...state.entry,
                    isFetching: true,
                },
            };
        }

        case 'ConnectionCardsActions.ON_GETENTRY': {
            return {
                ...state,
                entry: {
                    ...state.entry,
                    data: actionResult,
                    isFetching: false,
                },
            };
        }

        case 'ConnectionCardsActions.ON_RESETENTRY': {
            return {
                ...state,
                entry: {
                    ...DEFAULT_STATE.entry,
                },
            };
        }

        case 'ConnectionCardsActions.ON_BEFORE_GETTEMPLATE': {
            return {
                ...state,
                template: {
                    ...state.template,
                    isFetching: true,
                },
            };
        }

        case 'ConnectionCardsActions.ON_GETTEMPLATE': {
            return {
                ...state,
                template: {
                    ...state.template,
                    data: actionResult,
                    isFetching: false,
                },
            };
        }

        case 'ConnectionCardsActions.ON_RESETTEMPLATE': {
            return {
                ...state,
                template: {
                    ...DEFAULT_STATE.template,
                },
            };
        }

        default:
            return state;
    }
};
