import _ from 'lodash';
import { appendSavedNote } from './auditLogTransforms.js';
import ChunkedPaginationUtils from '../global/chunkedPaginationUtils.js';

const pagedAuditLogs = new ChunkedPaginationUtils();

const DEFAULT_STATE = {
    canLoadMore: false,
    isFetching: true,
    lastUpdated: null,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedAuditLogs.getPageSize(),
    results: [],
};

const globalPersonAuditLog = (state = DEFAULT_STATE, action) => {
    const isFirstPage = !_.isNil(action.callbackParams) && action.callbackParams.pageNumber === 0;

    switch (action.type) {
        case 'AuditLogActions.ON_BEFORE__GETGLOBALPERSONAUDITLOGS':
            return {
                ...state,
                isFetching: true,
                results: isFirstPage ? [] : state.results,
            };

        case 'AuditLogActions.ON__GETGLOBALPERSONAUDITLOGS':
        {
            pagedAuditLogs.loadPage(action.result, 0, isFirstPage);
            const results = pagedAuditLogs.getAll(true);

            return {
                ...state,
                canLoadMore: pagedAuditLogs.canLoadMore(),
                isFetching: false,
                lastUpdated: Date.now(),
                needsToRequest: pagedAuditLogs.needsToLoadPage(),
                pageNumber: pagedAuditLogs.getCurrentPageNumber(),
                pageSize: pagedAuditLogs.getPageSize(),
                results,
            };
        }

        case 'AuditLogActions.ON_GETGLOBALPERSONAUDITLOGSNEXTPAGE':
        {
            const results = pagedAuditLogs.getAll(true);

            return {
                ...state,
                canLoadMore: pagedAuditLogs.canLoadMore(),
                isFetching: false,
                lastUpdated: Date.now(),
                needsToRequest: pagedAuditLogs.needsToLoadPage(),
                pageNumber: pagedAuditLogs.getCurrentPageNumber(),
                pageSize: pagedAuditLogs.getPageSize(),
                results,
            };
        }

        case 'AuditLogActions.ON_RESETGLOBALPERSONAUDITLOG':
            return DEFAULT_STATE;

        case 'AuditLogActions.ON_SAVENOTE':
            return {
                ...state,
                results: appendSavedNote(
                    state.results,
                    action.params.id,
                    action.bodyParams.note,
                    action.callbackParams.noteCreator,
                ),
            };

        default:
            return state;
    }
};

export default globalPersonAuditLog;
