import { isNil } from 'lodash';
import Utils from '../../../global/utils/utils.js';

export const isAbleToSaveBasicInfo = (rule) => {
    const {
        churchEntityId,
        description,
        name,
    } = rule;

    // TODO/FIXME: See if business actually wants `description` field required or not.
    return !Utils.isStringNullOrWhiteSpace(name) &&
        !isNil(churchEntityId) &&
        !Utils.isStringNullOrWhiteSpace(description);
};

const updateRuleAttribute = (state, objWithUpdate) => {
    const { rule } = state;
    const updatedRule = { ...rule, ...objWithUpdate };

    return {
        ...state,
        ableToSaveRuleBasicInfo: isAbleToSaveBasicInfo(updatedRule),
        isDirty: true,
        rule: updatedRule,
    };
};

export const updateRuleBasicInfo = (state, actionType, actionValue) => {
    switch (actionType) {
        case 'WorkflowRuleEditorActions.ON_UPDATE_RULE_CHURCH_ENTITY':
        {
            const {
                label: churchEntityName,
                value: churchEntityId,
            } = actionValue || {};

            const actionHasChurchEntityId = !isNil(churchEntityId);

            const {
                domainSpecificLogic,
                rule: existingRule,
            } = state;

            const rulePreviouslyHadChurchEntityId = !isNil(existingRule.churchEntityId);

            const churchEntityWasAdded =
                !rulePreviouslyHadChurchEntityId &&
                actionHasChurchEntityId;

            const churchEntityWasRemoved =
                rulePreviouslyHadChurchEntityId &&
                !actionHasChurchEntityId;

            const churchEntityWasChanged =
                rulePreviouslyHadChurchEntityId &&
                actionHasChurchEntityId &&
                existingRule.churchEntityId !== churchEntityId;

            const ruleGotDirty =
                churchEntityWasAdded ||
                churchEntityWasRemoved ||
                churchEntityWasChanged;

            let updatedRule = {
                ...existingRule,
                churchEntityId: actionHasChurchEntityId ? churchEntityId : null,
                churchEntityName: churchEntityName || null,
            };

            updatedRule = domainSpecificLogic.handleChurchEntityChange(
                updatedRule,
                churchEntityId,
            );

            return {
                ...state,
                ableToSaveRuleBasicInfo: isAbleToSaveBasicInfo(updatedRule),
                isDirty: ruleGotDirty,
                rule: updatedRule,
            };
        }

        case 'WorkflowRuleEditorActions.ON_UPDATE_RULE_DESCRIPTION':
            return updateRuleAttribute(state, { description: actionValue });

        case 'WorkflowRuleEditorActions.ON_UPDATE_RULE_NAME':
            return updateRuleAttribute(state, { name: actionValue });

        case 'WorkflowRuleEditorActions.ON_UPDATE_RULE_STATUS':
            return updateRuleAttribute(state, { isActive: actionValue });

        default:
            return state;
    }
};
