import 'cropperjs/dist/cropper.css';

import React from 'react';
import {
    Button,
    Grid,
    Icon,
} from '@saddlebackchurch/react-cm-ui';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import { i18n } from '../constants.js';
import { GROUP_PROP_TYPES } from './constants';

const propTypes = {
    classes: PropTypes.shape({
        actions: PropTypes.string,
        fileName: PropTypes.string,
        imageScaleSlider: PropTypes.string,
        inputTypeFile: PropTypes.string,
        linearBackground: PropTypes.string,
        sliderBackground: PropTypes.string,
        title: PropTypes.string,
        uploadPhotoLink: PropTypes.string,
    }),
    fileName: PropTypes.string.isRequired,
    group: GROUP_PROP_TYPES.isRequired,
    onCloseImageModal: PropTypes.func,
    onGetImages: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    previewUrl: PropTypes.string.isRequired,
    uploadAll: PropTypes.bool,
};

const defaultProps = {
    classes: {},
    onCloseImageModal: noop,
    onGetImages: noop,
    uploadAll: false,
};

const mapStateToProps = (state) => {
    const {
        breakpoint: {
            isMobile,
        },
    } = state;

    return {
        isMobile,
    };
};

const BLOCK_CLASS_NAME = 'image_uploader_manager';
const styles = ({
    breakpoints,
    palette,
    spacing,
}) => ({
    inputTypeFile: {
        display: 'none',
    },
    imageScaleSlider: {
        width: '20%',
        marginRight: '10px',
    },
    uploadPhotoLink: {
        color: palette.text.link,
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: spacing(2),
        [breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    title: {
        justifyContent: 'center',
        display: 'flex',
        fontSize: '18px',
        color: palette.common.black,
        fontWeight: 'bold',
        marginTop: '40px',
    },
    fileName: {
        justifyContent: 'center',
        display: 'flex',
        color: 'gray', // this is #808080 and does not seem to be a theme color
        marginTop: '30px',
    },
    actions: {
        justifyContent: 'flex-end',
        display: 'flex',
        marginTop: '70px',
    },
    sliderBackground: {
        backgroundColor: palette.common.black,
        opacity: '.8',
        minHeight: '1px',
    },
    linearBackground: {
        lineHeight: '33px',
        backgroundColor: palette.common.black,
        opacity: '.8',
        whiteSpace: 'pre-line',
    },
});

const cropper = React.createRef(null);

class ImageUploaderManager extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            widthRatio: null,
            heightRatio: null,
        };

        this.editor = null;
        this.onSaveAvatar = this.onSaveAvatar.bind(this);
        this.onHandleScale = this.onHandleScale.bind(this);
        this.onCrop = this.onCrop.bind(this);
    }

    componentDidUpdate() {
        const { group } = this.props;
        const { widthRatio, heightRatio } = this.state;

        if (group.imageContainer.width > 0 && group.imageContainer.height > 0) {
            if (
                group.imageContainer.width !== widthRatio ||
                group.imageContainer.height !== heightRatio
            ) {
                this.setState({
                    widthRatio: group.imageContainer.width,
                    heightRatio: group.imageContainer.height,
                });

                cropper.current.cropper.setAspectRatio(
                    group.imageContainer.width / group.imageContainer.height,
                );
            }
        }
    }

    onHandleScale(e) {
        const scale = parseFloat(e.target.value);

        cropper.current.cropper.zoomTo(scale, {
            x: cropper.current.cropper.width / 2,
            y: cropper.current.cropper.height / 2,
        });
    }

    onCrop() {
        const {
            group,
            onGetImages,
            uploadAll,
        } = this.props;

        if (uploadAll) {
            onGetImages(cropper.current.cropper.getCroppedCanvas({
                height: group.imageContainer.height * 1,
                width: group.imageContainer.width * 1,
            }).toDataURL());
        }
    }

    onSaveAvatar() {
        const {
            fileName,
            group,
            onSave,
        } = this.props;

        onSave(
            fileName,
            cropper.current.cropper.getCroppedCanvas({
                height: group.imageContainer.height * 1,
                width: group.imageContainer.width * 1,
            })?.toDataURL(),
        );
    }

    render() {
        const {
            classes,
            fileName,
            group,
            onCloseImageModal,
            previewUrl,
            uploadAll,
        } = this.props;

        return (
            <React.Fragment>
                <div className="img">
                    <Cropper
                        background={false}
                        center
                        crop={this.onCrop}
                        cropBoxResizable={false}
                        dragMode="move"
                        guides
                        ref={cropper}
                        responsive
                        src={previewUrl}
                        style={{
                            height: 350,
                            width: '100%',
                        }}
                        viewMode={1}
                        zoomOnTouch={false}
                        zoomOnWheel={false}
                    />
                </div>

                <Grid>
                    <Grid.Column
                        className={classes.sliderBackground}
                        sm={12}
                    >
                        <div className={classes.fileName}>
                            <Icon
                                size="small"
                                style={{ marginTop: '3px' }}
                                type="image"
                            />
                            <input
                                className={classes.imageScaleSlider}
                                defaultValue="1"
                                max="4"
                                min="0.2"
                                name="scale"
                                onChange={this.onHandleScale}
                                step="0.01"
                                type="range"
                            />
                            <Icon
                                size="large"
                                type="image"
                            />
                        </div>
                    </Grid.Column>

                    <Grid.Column
                        className={classes.linearBackground}
                        sm={12}
                    >
                        <br />
                    </Grid.Column>

                    <Grid.Column sm={12}>
                        <div className={classes.title}>
                            {`${group.title}: ${group.imageContainer.width} x ${group.imageContainer.height}`}
                        </div>
                    </Grid.Column>

                    <Grid.Column sm={12}>
                        <div className={classes.fileName}>
                            {fileName}
                        </div>
                    </Grid.Column>

                    <Grid.Column sm={12}>
                        <br />
                        <br />
                    </Grid.Column>

                    {!uploadAll ? (
                        <Grid.Column sm={12}>
                            <div className={classes.actions}>
                                <Button
                                    color="secondary"
                                    designVersion={2}
                                    id={`${BLOCK_CLASS_NAME}--close`}
                                    onClick={onCloseImageModal}
                                >
                                    {i18n('Cancel')}
                                </Button>

                                <Button
                                    color="success"
                                    designVersion={2}
                                    id={`${BLOCK_CLASS_NAME}--save`}
                                    onClick={this.onSaveAvatar}
                                >
                                    {i18n('Save')}
                                </Button>
                            </div>
                        </Grid.Column>
                    ) : null}
                </Grid>
            </React.Fragment>
        );
    }
}

ImageUploaderManager.defaultProps = defaultProps;
ImageUploaderManager.propTypes = propTypes;

export default withStyles(styles)(connect(mapStateToProps)(ImageUploaderManager));
