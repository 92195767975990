import './formEntryCard.scss';

import {
    Card,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { isEmpty } from 'lodash';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import FormEntryType from './formEntryType';

const propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        churchEntityName: PropTypes.string,
        containsAgreements: PropTypes.bool,
        formEntryId: PropTypes.number,
        formEntryDate: PropTypes.string,
        formEntryTime: PropTypes.string,
        formEntryType: PropTypes.oneOf(Object.values(FormEntryType)),
        formTemplateName: PropTypes.string,
    }).isRequired,
    disableCardClick: PropTypes.bool,
    onCardClicked: PropTypes.func.isRequired,
};

const defaultProps = {
    className: undefined,
    disableCardClick: false,
};

const bemBlockClassName = 'connection_cards_entry_card';

class FormEntryCard extends React.PureComponent {
    constructor() {
        super();

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const {
            data: {
                formEntryId,
                formEntryType,
            },
            disableCardClick,
            onCardClicked,
        } = this.props;

        if (!disableCardClick) {
            onCardClicked(formEntryType, formEntryId);
        }
    }

    render() {
        const { className, data } = this.props;
        const containerClasses = ClassNames(bemBlockClassName, className);

        if (isEmpty(data)) {
            return false;
        }

        const {
            churchEntityName,
            containsAgreements,
            formEntryDate,
            formEntryTime,
            formTemplateName,
        } = data;

        const backgroundImageClass = containsAgreements ?
            `${bemBlockClassName}--background_agreement_image` : `${bemBlockClassName}--background_image`;

        return (
            <Card
                className={containerClasses}
                onClick={this.onClick}
            >
                <div className={`${bemBlockClassName}--info_wrapper`}>
                    <div className={`${bemBlockClassName}--title_church_info`}>
                        <Typography
                            className={`${bemBlockClassName}--title`}
                            variant="button"
                            component="div"
                        >
                            {formTemplateName || 'N/A'}
                        </Typography>

                        <div className={`${bemBlockClassName}--date_church_details`}>
                            {formEntryDate}
                            {churchEntityName && ` | ${churchEntityName}`}
                        </div>
                    </div>

                    <div className={`${bemBlockClassName}--service_time`}>
                        {formEntryTime || 'N/A'}
                    </div>

                    <span className={backgroundImageClass} />
                </div>
            </Card>
        );
    }
}

FormEntryCard.propTypes = propTypes;
FormEntryCard.defaultProps = defaultProps;

export default FormEntryCard;
