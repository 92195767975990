import {
    Grid,
    TextArea,
} from '@saddlebackchurch/react-cm-ui';
import {
    capitalize,
    isString,
} from 'lodash';
import {
    Controller,
} from 'react-hook-form';
import ClassNames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';

type PropTypes = {
    className?: string | null,
    control: any,
    dataTestId?: string | null,
    formatLabel?: boolean;
    id?: string | null;
    label?: string | null;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
    name: string;
    placeholder?: string | null;
    rules?: {
        required?: boolean,
    },
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    root: {
        paddingBottom: spacing(1.5),
        paddingTop: spacing(1.5),
    },
}));

function FormFieldTextArea({
    className = null,
    control,
    dataTestId = null,
    formatLabel = true,
    id = null,
    label = null,
    md = null,
    name,
    placeholder = null,
    rules = {},
    sm = 12,
}: PropTypes) {
    const classes = useStyles();

    const formattedLabel = formatLabel && isString(formatLabel) ?
        label.replace(/\w+/g, capitalize) :
        label;

    const rootClasses = ClassNames(
        'form--field_text_area',
        classes.root,
        className,
    );

    return (
        <Grid.Column
            className={rootClasses}
            md={md}
            sm={sm}
        >
            <Controller
                control={control}
                name={name}
                render={({
                    field: {
                        onChange,
                        value,
                        ref,
                    },
                }) => (
                    <TextArea
                        dataTestId={dataTestId}
                        fluid
                        id={id}
                        label={formattedLabel}
                        onChange={onChange}
                        placeholder={placeholder}
                        ref={ref}
                        required={rules?.required}
                        tabIndex={0}
                        value={value}
                    />
                )}
                rules={rules}
            />
        </Grid.Column>
    );
}

export default FormFieldTextArea;
