import _ from 'lodash';

export const DEFAULT_STATE = [
];

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'AdminSearchIndexActions.ON_GETTYPES':
            return action.result;
    }
    return state;
}
