import ChunkedPaginationUtils from '../global/chunkedPaginationUtils.js';
import DateUtils from '../global/dateUtils.js';

export const pagedEntries = new ChunkedPaginationUtils();

export const DEFAULT_STATE = {
    canLoadMore: false,
    currentWeekCount: 0,
    isFetching: false,
    needsToRequest: false,
    searchOptions: {
        searchTerm: '',
        searchField: 'responseCardId',
        sort: '-occurrenceDate',
        timeZone: DateUtils.getPreferredTimeZone(),
    },
    pageNumber: 0,
    pageSize: pagedEntries.getPageSize(),
    entries: [],
    total: 0,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        callbackParams,
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'ConnectionCardEntrySearchActions.ON_BEFORE_INTERNAL_GETCONNECTIONCARDENTRIES': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'ConnectionCardEntrySearchActions.ON_INTERNAL_GETCONNECTIONCARDENTRIES': {
            pagedEntries.loadPage(
                actionResult.results,
                actionResult.resultCount,
                callbackParams.first,
            );

            const entries = pagedEntries.getAll(true);

            return {
                ...state,
                canLoadMore: pagedEntries.canLoadMore(),
                currentWeekCount: actionResult.currentWeekCount,
                entries,
                isFetching: false,
                needsToRequest: pagedEntries.needsToLoadPage(),
                pageNumber: pagedEntries.getCurrentPageNumber(),
                pageSize: pagedEntries.getPageSize(),
                total: callbackParams ? pagedEntries.getTotalCount() : state.total,
            };
        }

        case 'ConnectionCardEntrySearchActions.ON_GET_CONNECTION_CARD_ENTRIES_NEXT_PAGE': {
            const entries = pagedEntries.getAll(true);

            return {
                ...state,
                canLoadMore: pagedEntries.canLoadMore(),
                entries,
                needsToRequest: pagedEntries.needsToLoadPage(),
            };
        }

        case 'ConnectionCardEntrySearchActions.ON_RESET_SEARCH_OPTIONS': {
            return {
                ...state,
                searchOptions: {},
            };
        }

        case 'ConnectionCardEntrySearchActions.ON_SET_SEARCH_OPTIONS': {
            return {
                ...state,
                searchOptions: {
                    ...actionResult,
                },
            };
        }

        case 'ConnectionCardEntrySearchActions.ON_RESET': {
            return DEFAULT_STATE;
        }

        default:
            return state;
    }
};
