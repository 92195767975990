import {
    Button,
    dateUtils,
    Divider,
    Grid,
    Header,
} from '@saddlebackchurch/react-cm-ui';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import PersonManagerAPIUtils from '../../utils/Person/Manager/PersonManagerAPIUtils.js';
import PersonManagerStore from '../../stores/Person/Manager/PersonManagerStore.js';
import UserAccessStore from '../../../global/userAccessStore.js';
import { translationFactory } from '../../../global/i18nUtils.js';
import { USER_PERMISSIONS } from '../../../global/userPermissionConstants.js';

const _i = translationFactory('Dashboard.DashboardMinistryMemberDrawer');

const propTypes = {
    closeModal: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    userAccess: PropTypes.instanceOf(UserAccessStore).isRequired,
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
    } = state;

    return { userAccess };
};

class ActivityDrawerMinistryMember extends React.Component {
    constructor(props) {
        super(props);
        this._onPersonManagerStoreChange = this._onPersonManagerStoreChange.bind(this);
        this.payload = props.data.payload ? JSON.parse(props.data.payload) : {};

        this.state = {
            person: {},
        };
    }

    componentDidMount() {
        PersonManagerStore.addChangeListener(this._onPersonManagerStoreChange);
        PersonManagerAPIUtils.getPerson(
            this.payload.MemberPersonUniqueId,
            this.payload.MinistryId,
            this.payload.ChurchEntityId,
        );
    }

    componentWillUnmount() {
        PersonManagerStore.removeChangeListener(this._onPersonManagerStoreChange);
    }

    _onPersonManagerStoreChange() {
        this.setState({
            person: PersonManagerStore.getPerson(),
        });
    }

    render() {
        const { data, closeModal, userAccess } = this.props;
        const { person } = this.state;
        const hasBirthdayAccess = userAccess.isAuthorizedForCampus(
            this.payload.ChurchEntityId,
            USER_PERMISSIONS.accessDateOfBirth,
        );

        const birthDateValueJsx = (<p className="no-margin-top" key="dob-value">{dateUtils.formatDate(person.birthDate)}</p>);
        const birthDateJsx = hasBirthdayAccess ?
            [
                <label className="text-placeholder" key="dob-label">{_i('Birthday')}</label>,
                birthDateValueJsx,
            ] : null;

        return (
            <div>
                <Divider />
                <Header size="medium">{data.summary}</Header>
                <Header size="xsmall" color="static">
                    <label className="text-placeholder">{_i('Ministry')}</label>
                    <p className="no-margin-top">{this.payload.MinistryName}</p>
                </Header>
                <Header size="xsmall" color="static">
                    <label className="text-placeholder">{_i('Campus')}</label>
                    <p className="no-margin-top">{this.payload.ChurchEntityName}</p>
                </Header>
                <Button href={`/my-ministry/overview/${this.payload.MinistryId}/${this.payload.ChurchEntityId}`} onClick={closeModal}>
                    <span>{_i('MinistryDashboard')}</span>
                </Button>
                { !person ? null : (
                    <div>
                        <Divider hidden />
                        <Header size="medium">{_i('About')}</Header>
                        <Divider />
                        <Header size="small">{_i('Personal')}</Header>
                        <Grid columns={3} spacing={2}>
                            <Grid.RowDeprecated>
                                <Grid.Column>
                                    <label className="text-placeholder">{_i('FirstName')}</label>
                                    <p className="no-margin-top">{person.firstName}</p>
                                </Grid.Column>
                                <Grid.Column>
                                    <label className="text-placeholder">{_i('LastName')}</label>
                                    <p className="no-margin-top">{person.lastName}</p>
                                </Grid.Column>
                                <Grid.Column>
                                    {birthDateJsx}
                                </Grid.Column>
                            </Grid.RowDeprecated>
                        </Grid>
                        <Header size="small">{_i('Contact')}</Header>
                        <Grid columns={3} spacing={2}>
                            <Grid.RowDeprecated>
                                <Grid.Column>
                                    <label className="text-placeholder">{_i('Address')}</label>
                                    <p className="no-margin-top">
                                        {person.address1}
                                        <br />
                                        {person.city}
                                        ,
                                        {person.region}
                                        {' '}
                                        {person.postalCode}
                                        <br />
                                        {person.country}
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <label className="text-placeholder">{_i('Email')}</label>
                                    <p className="no-margin-top"><a href={`mailto:${person.email}`}>{person.email}</a></p>
                                </Grid.Column>
                                <Grid.Column>
                                    <label className="text-placeholder">{_i('Cell')}</label>
                                    <p className="no-margin-top"><a href={`tel:${person.cellPhone}`}>{person.cellPhone}</a></p>
                                </Grid.Column>
                            </Grid.RowDeprecated>
                        </Grid>
                        <Divider hidden />
                        <Button href={`/people/search-or-add/record/${person.id}`} onClick={closeModal}>
                            <span>{_i('ViewRecord')}</span>
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

ActivityDrawerMinistryMember.propTypes = propTypes;

export default connect(mapStateToProps)(ActivityDrawerMinistryMember);
