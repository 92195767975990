import {
    Box,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import { i18n } from '../constants.js';
import { ResponseStatus } from '../models';
import errorSatellite from '../../images/error_satellite.svg';

type PropTypes = {
    caption?: string | null;
    pt?: number | null;
    status: ResponseStatus;
    sx?: object | null;
    title?: string | null;
};

const defaultProps: Pick<PropTypes, 'caption' | 'pt' | 'sx' | 'title'> = {
    caption: null,
    pt: null,
    sx: null,
    title: null,
};

const bemBlockName = 'error_status';

function ErrorStatus({
    caption = null,
    pt = null,
    status,
    title = null,
    sx = null,
}: PropTypes) {
    let captionCopy: string = '';
    let titleCopy: string = '';

    switch (status) {
        case ResponseStatus.FORBIDDEN:
            captionCopy = caption ?? i18n('Sorry, but you don\'t have permission to access this page.');
            titleCopy = title ?? i18n('Access Denied');

            break;
        case ResponseStatus.NOT_FOUND:
            captionCopy = caption ?? i18n('Sorry, we can\'t find the page you are looking for.');
            titleCopy = title ?? i18n('Page Not Found');

            break;
        case ResponseStatus.INTERNAL_SERVER_ERROR:
        default:
            captionCopy = caption ?? i18n('We are working on fixing the problem. Please try again.');
            titleCopy = title ?? i18n('Internal Server Error');

            break;
    }

    return (
        <Box
            alignItems="center"
            className={bemBlockName}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pt={pt ?? 8}
            sx={{
                ...sx,
            }}
            data-testid="error-status"
        >
            <Box
                mb={2}
            >
                <img
                    alt={i18n('Satellite')}
                    height={186}
                    src={errorSatellite}
                    width={164}
                />
            </Box>

            <Box
                mb={1}
            >
                <Typography
                    component="div"
                >
                    <Box
                        fontSize={33}
                        fontWeight="fontWeightMedium"
                    >
                        {titleCopy}
                    </Box>
                </Typography>
            </Box>

            <Typography
                component="div"
            >
                <Box
                    fontSize={18}
                >
                    {captionCopy}
                </Box>
            </Typography>
        </Box>
    );
}

ErrorStatus.defaultProps = defaultProps;

export default ErrorStatus;
