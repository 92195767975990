import React from 'react';
import { Grid } from '@saddlebackchurch/react-cm-ui';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GROUPS_PROP_TYPES } from './constants';
import ImageUploaderContent from './imageUploaderContent.jsx';

const propTypes = {
    allOptions: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    filename: PropTypes.string,
    groups: GROUPS_PROP_TYPES.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

const defaultProps = {
    allOptions: true,
    classes: {},
    disabled: false,
    filename: '',
    readOnly: false,
};

const mapStateToProps = (state) => {
    const {
        global: {
            imageUploader,
        },
    } = state;

    return {
        imageUploader,
    };
};

const BLOCK_CLASS_NAME = 'image_uploader_groups';

class ImageUploaderGroups extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(group, index) {
        const { onChange } = this.props;

        onChange(group, index);
    }

    render() {
        const {
            allOptions,
            classes,
            disabled,
            filename,
            groups,
            readOnly,
        } = this.props;

        return (
            <Grid>
                {map(groups, (group, index) => (
                    <ImageUploaderContent
                        allOptions={allOptions}
                        classes={classes}
                        disabled={disabled}
                        fileName={filename}
                        group={group}
                        id={`${BLOCK_CLASS_NAME}--${group.id}`}
                        index={index}
                        key={`${BLOCK_CLASS_NAME}--${group.id}`}
                        onChange={this.onChange}
                        previewUrl={group.imageContainer.src}
                        readOnly={readOnly}
                        width={group.width}
                    />
                ))}
            </Grid>
        );
    }
}

ImageUploaderGroups.propTypes = propTypes;
ImageUploaderGroups.defaultProps = defaultProps;

export default connect(mapStateToProps)(ImageUploaderGroups);
