import { Action } from '../../../global/models';
import ActionType from '../../actionTypes/communication.actionTypes';

export const INITIAL_STATE = {
    audienceType: null,
    campusIds: [],
    communicationCategoryId: null,
    communicationTemplateId: null,
    ctaApplicationType: null,
    description: null,
    emailFromAddress: null,
    emailFromDisplayName: null,
    emailFromId: null,
    emailReplyToAddress: null,
    emailReplyToId: null,
    id: null,
    isActive: null,
    jobType: null,
    name: null,
    layout: null,
    message: null,
    scopedCampusId: null,
    segmentNames: null,
    sourceCommunicationTemplateId: null,
    subject: null,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_COMMUNICATION:
            return action.payload;
        case ActionType.RESET_COMMUNICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
};
