import './personRequirements.scss';

import {
    Button,
    Checkbox,
    Divider,
    Header,
    Label,
} from '@saddlebackchurch/react-cm-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonConfirmation from '../../../js/components/ButtonConfirmation.react.js'; // TODO: Replace with Prompt
import { translationFactory } from '../../../global/i18nUtils.js';

const i18n = translationFactory('Person.Search.PeopleSearchDrawer');

const REQ_MILESTONES = i18n('Milestones');
const REQ_GENDER = i18n('Gender');
export const REQ_AGE = i18n('Age');

export const checkRequirements = (requirements) => {
    const result = [];

    if (requirements) {
        if (_.indexOf(requirements.notEligible, 'MinAge') >= 0 || _.indexOf(requirements.notEligible, 'MaxAge') >= 0) {
            result.push({
                type: REQ_AGE,
                need: { minAge: requirements.minAge, maxAge: (requirements.maxAge === null || requirements.maxAge > 90) ? '90+' : requirements.maxAge },
            });
        }

        if (_.indexOf(requirements.notEligible, 'Gender') >= 0) {
            result.push({
                type: REQ_GENDER,
                need: requirements.gender,
            });
        }

        if (_.indexOf(requirements.notEligible, 'Milestones') >= 0) {
            result.push({
                type: REQ_MILESTONES,
                need: _.map(requirements.missingMilestones, (v) => v.name),
            });
        }
    }

    return result;
};

const ReqMilestones = (props) => {
    const { need } = props;

    return (
        <div>
            <Header size="small">{i18n('Milestones')}</Header>

            {_.map(need, (v, i) => (
                <Label
                    className="milestone-label"
                    id={`ui-label--requirements_milestones_${i}`}
                    key={`milestones-need-${i}`}
                >
                    {v}
                </Label>
            ))}

            <Divider relaxed />
        </div>
    );
};

ReqMilestones.propTypes = {
    need: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const ReqGender = (props) => {
    const { key, need } = props;
    return (
        <div>
            <Header size="small">{i18n('Gender')}</Header>

            <Label
                className="value-label"
                color="transparent"
                id={`ui-label--requirements_gender_${key}`}
            >
                {need}
            </Label>

            <Divider relaxed />
        </div>
    );
};

ReqGender.propTypes = {
    key: PropTypes.string.isRequired,
    need: PropTypes.string.isRequired,
};

const ReqAge = (props) => {
    const { key, need } = props;

    return (
        <div>
            <Header size="small">{i18n('Age')}</Header>

            <Label
                className="value-label"
                color="transparent"
                id={`ui-label--requirements_age_${key}`}
            >
                {(need.minAge === null && need.maxAge !== null) ? `Not older than ${need.maxAge}` : `${need.minAge} - ${need.maxAge}`}
            </Label>

            <Divider relaxed />
        </div>
    );
};

ReqAge.propTypes = {
    key: PropTypes.string.isRequired,
    need: PropTypes.shape({}).isRequired,
};

export class PersonRequirements extends React.Component {
    constructor() {
        super();

        this.state = {
            override: false,
            isConfirmActive: false,
        };

        this.toggleOverride = this.toggleOverride.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onDecline = this.onDecline.bind(this);
        this.onSetPerson = this.onSetPerson.bind(this);
    }

    onConfirm() {
        this.confirm.activate();
        this.setState({ isConfirmActive: true });
    }

    onDecline() {
        this.setState({ isConfirmActive: false });
    }

    onSetPerson() {
        const { person, setPerson } = this.props;
        setPerson(person);
    }

    toggleOverride() {
        this.setState((prevState) => ({
            override: !prevState.override,
        }));
    }

    render() {
        const { requirements, person, setPerson } = this.props;
        const { override, isConfirmActive } = this.state;

        return (
            <div className="people-search-requirements-drawer">
                {_.map(requirements, (r, i) => {
                    const key = `requirement-${i}`;

                    if (r.type === REQ_MILESTONES) {
                        return <ReqMilestones key={key} need={r.need} />;
                    }

                    if (r.type === REQ_GENDER) {
                        return <ReqGender key={key} need={r.need} />;
                    }

                    if (r.type === REQ_AGE) {
                        return <ReqAge key={key} need={r.need} />;
                    }

                    return null;
                })}

                {(person && setPerson) ? (
                    <div>
                        <Checkbox
                            checked={person.exists ? false : override}
                            disabled={person.exists}
                            id="ui-checkbox--override_requirements"
                            label={i18n('OverrideRequirements')}
                            onChange={this.toggleOverride}
                        />

                        <Button
                            disabled={person.exists || isConfirmActive || !override}
                            id="ui-button--select"
                            onClick={this.onConfirm}
                        >
                            <span>{i18n('Select')}</span>
                        </Button>

                        <ButtonConfirmation
                            animateFrom="left"
                            boxType="block"
                            id="ui-button_confirmation--select"
                            message={i18n('AreYouSure')}
                            onConfirmClick={this.onSetPerson}
                            onDeclineClick={this.onDecline}
                            ref={(ref) => { this.confirm = ref; }}
                            type="closed"
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

PersonRequirements.propTypes = {
    person: PropTypes.shape({
        exists: PropTypes.bool,
    }),
    requirements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setPerson: PropTypes.func,
};

PersonRequirements.defaultProps = {
    person: null,
    setPerson: undefined,
};
