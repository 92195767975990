import { has, map } from 'lodash';

export default (
    action: { result: object[] } | null,
    valueField: string,
    labelField: string,
    isActiveField?: string,
): object[] => (map(
    action && action.result,
    (obj) => ({
        ...obj,
        value: obj[valueField],
        label: obj[labelField],
        disabled: has(obj, isActiveField) && obj[isActiveField] === false,
    }),
));
