import keyMirror from 'keymirror';

// See: See api/src/Services/Workflows/Saddleback.Cm.Contracts.Workflows/Models/ActionType.cs
export const ACTION_TYPE = keyMirror({
    CancelDelayedActions: null,
    CreateFollowUp: null,
    SendEmail: null,
});

// See: api/src/Services/Workflows/Saddleback.Cm.Contracts.Workflows/Models/RuleActionSettingsKeys.cs
export const ACTION_SETTING = Object.freeze({
    DelayedActionDependencies: 'delayed_action_dependencies',
    EmailTemplateId: 'email_template_id',
    FromDisplayName: 'from_display_name',
    FromEmail: 'from_email',
    ReplyToEmail: 'reply_to_email',
    ReplyToDisplayName: 'reply_to_display_name',
    TaskTemplateId: 'task_template_id',
});
