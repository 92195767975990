import {
    isFunction,
    isUndefined,
    noop,
} from 'lodash';
import { Button, Icon } from '@saddlebackchurch/react-cm-ui';
import { DragSource } from 'react-dnd';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
    trackEvent,
} from '../../tools/analytics/index.js';
import {
    CONTEXTUAL_HELP_CLOSED,
    CONTEXTUAL_HELP_OPENED,
} from '../../tools/analytics/eventsToTrack.js';

const propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isContainerOpen: PropTypes.bool,
    isDragging: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onDrag: PropTypes.func.isRequired,
    xCoordinate: PropTypes.number.isRequired,
    yCoordinate: PropTypes.number.isRequired,
};

const defaultProps = {
    isContainerOpen: false,
    isDragging: false,
};

class HelpContextualFAB extends React.PureComponent {
    constructor() {
        super();

        this.onButtonClick = this.onButtonClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            isContainerOpen,
            isDragging,
            onDrag,
        } = this.props;

        if (!prevProps.isDragging && isDragging && !isUndefined(onDrag)) {
            onDrag(true);
        }

        if (prevProps.isDragging && !isDragging && !isUndefined(onDrag)) {
            onDrag(false);
        }

        if (prevProps.isContainerOpen !== isContainerOpen) {
            trackEvent(
                isContainerOpen ? CONTEXTUAL_HELP_OPENED : CONTEXTUAL_HELP_CLOSED,
                {},
            );
        }
    }

    onButtonClick() {
        const {
            onClick,
        } = this.props;

        if (isFunction(onClick)) {
            onClick();
        }
    }

    render() {
        const {
            connectDragSource,
            isContainerOpen,
            isDragging,
            xCoordinate,
            yCoordinate,
        } = this.props;

        const rootClasses = ClassNames(
            'help-contextual-fab',
            {
                'is-closed': !isContainerOpen,
                'is-opened': isContainerOpen,
            },
        );

        const style = {
            position: 'fixed',
            zIndex: 11001,
        };

        if (isDragging) {
            return null;
        }

        return connectDragSource(
            <div
                className="help-contextual-fab-container"
                onClick={this.onButtonClick}
                onKeyDown={noop}
                role="button"
                style={({
                    ...style,
                    left: xCoordinate,
                    top: yCoordinate,
                })}
                tabIndex={0}
            >
                <Button
                    className={rootClasses}
                    icon
                    id="ui-button--help_fab_button"
                >
                    <Icon compact type="times" />
                    <Icon compact type="question" />
                </Button>
            </div>,
        );
    }
}

HelpContextualFAB.propTypes = propTypes;
HelpContextualFAB.defaultProps = defaultProps;

export default DragSource(
    'helpContextualFAB', {
        beginDrag(props) {
            const { xCoordinate, yCoordinate } = props;

            return { xCoordinate, yCoordinate };
        },
    },
    (connect, monitor) => ({
        connectDragPreview: connect.dragPreview(),
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)(HelpContextualFAB);
