import { SearchAgeCategory } from '../searchConstants';

export default (isChild?: boolean): SearchAgeCategory => {
    if (isChild === true) {
        return SearchAgeCategory.Child;
    }

    if (isChild === false) {
        return SearchAgeCategory.AdultOrStudent;
    }

    return null;
};
