import { combineReducers } from 'redux';
import filters from './filters.reducer.js';
import paginatedEntries from './paginatedEntries.reducer.js';

const reducers = combineReducers({
    filters,
    paginatedEntries,
});

export default reducers;
