import { combineReducers } from 'redux';
import groupMembers from './groupMembersReducer.js';
import groupsList from './groupListReducer.js';
import possibleGroupMembers from './possibleGroupMembersReducer.js';
import rolesList from './roleList.reducer';

const reducers = combineReducers({
    groupMembers, // chunked pagination state for current membership list for a Group OR Role
    groupsList, // List of available Groups
    possibleGroupMembers, // chunked pagination state for ministry roster query of members that could be added to Group OR Role
    rolesList, // List of available Roles
});

export default reducers;
