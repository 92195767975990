enum JourneyEntityType {
    AttendAnEvent = 'Event',
    AttendAServingOpportunity = 'ServingOpp',
    FillOutAForm = 'Form',
    FillOutAFormApproval = 'Form',
    ListenToAudio = 'Audio',
    ReadADocument = 'PDF',
    RegisterForAnEvent = 'Event',
    RegisterForAMinistry = 'Ministry',
    RegisterForAServingOpportunity = 'ServingOpp',
    TakeAQuiz = 'Quiz',
    VisitALink = 'Link',
    WatchAVideo = 'Video',
}

export default JourneyEntityType;
