import {
    forEach,
    minBy,
    pull,
    size,
} from 'lodash';
import LoginConstants from '../../js/constants/Login/LoginConstants.js';

export const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LoginConstants.LOGOUT: // reset all stores on logout
            return DEFAULT_STATE;

        case 'WarehouseDashboardActions.ON_GETDATASINCEINCEPTION':
            forEach(action.result, (category) => {
                while (size(category.graphData) > 3) {
                    const minData = minBy(category.graphData, 'name');
                    pull(category.graphData, minData);
                }
            });

            return action.result;

        default:
            return state;
    }
};
