import PropTypes from 'prop-types';

export const notePropTypes = PropTypes.shape({
    categoryName: PropTypes.string,
    churchEntity: PropTypes.string,
    churchEntityId: PropTypes.number,
    createdByName: PropTypes.string,
    createdByPerson: PropTypes.shape({
        avatarUrl: PropTypes.string,
        email: PropTypes.string,
        fullName: PropTypes.string,
        id: PropTypes.number,
        uniqueId: PropTypes.number,
    }),
    createDate: PropTypes.number,
    entityId: PropTypes.number,
    entityType: PropTypes.string,
    id: PropTypes.number,
    isPinned: PropTypes.bool,
    isSensitive: PropTypes.bool,
    ministryId: PropTypes.number,
    ministry: PropTypes.string,
    modifiedByName: PropTypes.string,
    modifiedByPerson: PropTypes.string,
    modifyDate: PropTypes.number,
    noteText: PropTypes.string,
    purpose: PropTypes.string,
});

export const noteDefaultProps = {
    categoryId: null,
    categoryName: '',
    churchEntity: '',
    churchEntityId: null,
    createdByName: '',
    createdByPerson: PropTypes.shape({
        avatarUrl: '',
        email: '',
        fullName: '',
        id: null,
        uniqueId: null,
    }),
    createDate: '',
    entityId: null,
    entityType: '',
    id: null,
    isPinned: false,
    isSensitive: false,
    ministryId: null,
    ministry: '',
    modifiedByName: '',
    modifiedByPerson: '',
    modifyDate: '',
    noteText: '',
    purpose: '',
};

export const noteFormPropTypes = PropTypes.shape({
    categoryId: PropTypes.number,
    noteId: PropTypes.number,
    noteText: PropTypes.string,
    purpose: PropTypes.string,
});

export const noteFormDefaultProps = {
    categoryId: null,
    noteId: null,
    noteText: '',
    purpose: '',
};
