export const DEFAULT_STATE = {
    data: [],
    isFetching: true,
};

export default (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'PeopleSettingsMilestones.ON_BEFORE_GETALLMILESTONES':
            return {
                ...state,
                isFetching: true,
            };

        case 'PeopleSettingsMilestones.ON_GETALLMILESTONES':
            return {
                ...state,
                data: actionResult,
                isFetching: false,
            };

        default:
            // no-op
    }

    return state;
};
