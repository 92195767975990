import _ from 'lodash';

const DEFAULT_STATE = {
    agreements: {},
    appSettings: {},
    isFetchingAppSettings: false,
    isFetchingAgreement: false,
    isSavingAgreement: false,
};

const appSettings = (state = DEFAULT_STATE, action) => {
    const {
        result: actionResult,
        type: actionType,
    } = action;

    switch (actionType) {
        case 'AppSettingsActions.RESET':
            return DEFAULT_STATE;

        case 'AppSettingsActions.ON_BEFORE_GETAPPSETTINGS':
            return {
                ...state,
                isFetchingAppSettings: true,
            };

        case 'AppSettingsActions.ON_GETAPPSETTINGS':
            return {
                ...state,
                appSettings: actionResult,
                isFetchingAppSettings: false,
            };

        case 'AppSettingsActions.ON_BEFORE_GETAGREEMENT':
            return {
                ...state,
                isFetchingAgreement: true,
            };

        case 'AppSettingsActions.ON_GETAGREEMENT':
        {
            const {
                params: {
                    agreementName: encodedAgreementName,
                },
            } = action;

            const agreementName = decodeURIComponent(encodedAgreementName);

            const { agreements: existingAgreements } = state;
            const newAgreement = {};
            newAgreement[agreementName] = actionResult;
            const newAgreements = _.merge(existingAgreements, newAgreement);

            return {
                ...state,
                agreements: newAgreements,
                isFetchingAgreement: false,
            };
        }

        case 'AppSettingsActions.ON_BEFORE_UPDATEAGREEMENT':
            return {
                ...state,
                isSavingAgreement: true,
            };

        case 'AppSettingsActions.ON_UPDATEAGREEMENT':
        {
            const { agreements: existingAgreements } = state;
            const newAgreement = {};
            newAgreement[actionResult.name] = actionResult;
            const newAgreements = _.merge(existingAgreements, newAgreement);

            return {
                ...state,
                agreements: newAgreements,
                isSavingAgreement: false,
            };
        }

        default:
    }

    return state;
};

export default appSettings;
