import { get } from 'lodash';

export default (theme) => {
    const backgroundHighlight = get(theme, 'palette.active.primary');
    const backgroundInverse = get(theme, 'palette.background.contrastPrimary');
    const backgroundInverseAlternate = get(theme, 'palette.grey[500]');
    const borderColorHighlight = get(theme, 'palette.active.primary');
    const borderColorBlack = get(theme, 'palette.common.black');
    const backgroundFoo = '#545d68'; // I couldn't find this color in the theme object
    const backgroundBlack = get(theme, 'palette.common.black');
    const backgroundConstrastPrimary = get(theme, 'palette.background.contrastPrimary');
    const buttonheight = 70;
    const checkInRoomDetailsPadding = 250;
    const collapseTransitionDuration = '0.2s';
    const colorCyan = get(theme, 'palette.active.primary');
    const colorTextInverse = get(theme, 'palette.text.contrastText');
    const colorTextStatic = get(theme, 'palette.text.secondary');
    const colorPink = get(theme, 'palette.pink[500]');
    const logoheight = 70;
    const mobileHeaderHeight = get(theme, 'height.appHeader.sm');
    const navigationOpenWidth = get(theme, 'width.navigation.md.expanded');
    const navigationCollapsedWidth = get(theme, 'width.navigation.md.collapsed') - 10;
    const fontWeightBold = get(theme, 'typography.fontWeightBold');
    const fontWeightRegular = get(theme, 'typography.fontWeightRegular');
    const fontWeightSemiBold = get(theme, 'typography.fontWeightMedium');
    const railExtraPadding = 22;
    const zIndexNavigation = 8000;

    return {
        '@global': {
            '.navigation': {
                backgroundColor: backgroundInverse,
                color: colorTextInverse,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                left: 0,
                opacity: 0,
                position: 'fixed',
                top: 0,
                transitionDelay: '750ms',
                transitionProperty: 'opacity',
                userSelect: 'none',
                width: navigationOpenWidth,
                '& .close-button': {
                    alignItems: 'center',
                    backgroundColor: backgroundInverse,
                    color: colorCyan,
                    display: 'flex',
                    flex: 'none',
                    fontWeight: 'bold',
                    height: logoheight,
                    justifyContent: 'flex-start',
                    padding: '0 22px',
                },
                '& .navigation-logo': {
                    alignItems: 'center',
                    backgroundColor: backgroundInverse,
                    display: 'flex',
                    flex: 'none',
                    height: logoheight,
                    justifyContent: 'flex-start',
                    padding: '0 22px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    maxWidth: '100%',
                },
                '& .navigation-main-button': {
                    alignItems: 'center',
                    backgroundColor: backgroundInverseAlternate,
                    boxShadow: `inset 5px 0 0 ${borderColorHighlight}`,
                    cursor: 'pointer',
                    display: 'flex',
                    flex: 'none',
                    height: buttonheight,
                    justifyContent: 'flex-start',
                    padding: '0 22px',
                    transition: 'background-color 150ms linear, box-shadow 150ms linear, padding 150ms linear',
                    '&.is-active': {
                        backgroundColor: backgroundFoo,
                        boxShadow: `inset 0 0 0 ${borderColorHighlight}`,
                    },
                    '&:hover:not(.is-active)': { backgroundColor: backgroundFoo },
                    '& .icon-transition': {
                        flex: 'none',
                        height: 24,
                        position: 'relative',
                        width: 24,
                        '& .section-icon': {
                            left: 0,
                            position: 'absolute',
                            top: 0,
                            width: 24,
                            '&.fade-in-enter': {
                                opacity: 0,
                                transition: 'opacity 150ms linear',
                                '&.animate-active': { opacity: 1 },
                            },
                            '&.fade-out-leave': {
                                opacity: 1,
                                transition: 'opacity 150ms linear',
                                '&.animate-active': { opacity: 0 },
                            },
                        },
                    },
                    '& .section-label': {
                        display: 'inline-block',
                        flex: '1 0 auto',
                        fontWeight: fontWeightBold,
                        margin: '0 11px',
                        '& span': {
                            color: colorTextStatic,
                            display: 'inline-block',
                            fontSize: 'rem(14px)',
                            marginLeft: 4,
                        },
                    },
                    '& .ui.icon': {
                        flex: 'none',
                    },
                },
                '& .navigation-container': {
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                },
                '& .navigation-main-items': {
                    backgroundColor: backgroundInverseAlternate,
                    zIndex: zIndexNavigation + 1,
                    '& .navigation-main-item': {
                        boxShadow: `inset 0 0 0 ${backgroundInverseAlternate}`,
                        margin: 0,
                        padding: '0 11px 0 21px',
                        transition: 'background-color 150ms linear, box-shadow 150ms linear, padding 150ms linear',
                        '&:first-child': { marginTop: 16 },
                        '&.is-hover': {
                            backgroundColor: '#000',
                            boxShadow: `inset 5px 0 0 ${borderColorHighlight}`,
                        },
                    },
                    '& .section-label-container': {
                        alignItems: 'center',
                        display: 'flex',
                        height: 50,
                        justifyContent: 'flex-start',
                    },
                    '& .section-icon': { width: 16 },
                    '& .section-label': {
                        display: 'inline-block',
                        flex: '1 0 auto',
                        fontWeight: fontWeightSemiBold,
                        margin: '0 0 0 11px',
                        '& span': {
                            color: colorTextStatic,
                            display: 'inline-block',
                            fontSize: 'rem(14px)',
                            marginLeft: 4,
                        },
                    },
                    '& .navigation-sub-items': {
                        backgroundColor: '#000',
                        listStyle: 'none',
                        margin: '0 -22px',
                        padding: '22px 0',
                    },
                    '& .navigation-sub-section-item': {
                        '&.is-active, &:hover': {
                            backgroundColor: backgroundHighlight,
                            '& .navigation-sub-section-item-beta-tag, .navigation-sub-section-item-total': {
                                color: colorTextInverse,
                            },
                        },
                    },
                },
                '& .navigation-scrollbar': {
                    bottom: 0,
                    left: 0,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    backgroundColor: backgroundInverseAlternate,
                },
                '& .navigation-scrollbar-inner': {
                    height: '100%',
                    width: navigationOpenWidth,
                },
                '& .navigation-scrollbar-inner > span >': {
                    '& .navigation-main-items, .navigation-sub-items': {
                        height: '100%',
                        listStyle: 'none',
                        margin: 0,
                        padding: 0,
                        position: 'absolute',
                        width: '100%',
                        '&.roll-out-enter': {
                            height: '0%',
                            '&.animate-active': {
                                height: '100%',
                                overflow: 'hidden',
                                transition: 'height 300ms linear',
                            },
                        },
                        '&.roll-in-leave': {
                            height: '100%',
                            '&.animate-active': {
                                height: '0%',
                                overflow: 'hidden',
                                transition: 'height 300ms linear',
                            },
                        },
                    },
                    '& .navigation-sub-items': {
                        backgroundColor: backgroundInverse,
                        zIndex: zIndexNavigation + 2,
                        '& .navigation-sub-item:first-child': { marginTop: 33 },
                        '&  .navigation-sub-section-item': {
                            '&.is-active, &:hover': {
                                backgroundColor: backgroundInverseAlternate,
                                boxShadow: `inset 5px 0 0 ${borderColorHighlight}`,
                            },
                        },
                    },
                },
                '& .navigation-sub-item': {
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    margin: 0,
                    padding: '0 0 33px',
                    '& .navigation-sub-item': {
                        alignItems: 'center',
                        '&-label': {
                            fontWeight: fontWeightSemiBold,
                            flex: '1 0 auto',
                        },
                        '&-total': {
                            alignItems: 'center',
                            backgroundColor: colorPink,
                            borderRadius: 9,
                            color: colorTextInverse,
                            display: 'inline-flex',
                            flex: 'none',
                            fontSize: 'rem(12px)',
                            fontWeight: fontWeightSemiBold,
                            justifyContent: 'center',
                            padding: '0 6px',
                        },
                    },
                    '&:last-child': { paddingBottom: 0 },
                },
                '& .ui.header': {
                    alignItems: 'center',
                    color: colorTextStatic,
                    display: 'flex',
                    fontWeight: fontWeightSemiBold,
                    justifyContent: 'flex-start',
                    margin: '0 0 10px',
                    padding: '0 11px 0 22px',
                    width: '100%',
                },
                '& .navigation-sub-section-items': {
                    listStyle: 'none',
                    margin: 0,
                    padding: 0,
                    width: '100%',
                },
                '& .navigation-sub-section-item': {
                    cursor: 'pointer',
                    display: 'flex',
                    fontSize: '0.875rem',
                    fontWeight: fontWeightSemiBold,
                    margin: 0,
                    padding: '8.5px 11px 8.5px 22px',
                    transition: 'background-color 150ms linear, box-shadow 150ms linear, padding 150ms linear',
                    '& .navigation-sub-section-item-label': {
                        flex: '1 0 auto',
                        fontWeight: fontWeightSemiBold,
                        '& > div': {
                            fontWeight: fontWeightSemiBold,
                            color: colorTextStatic,
                            display: 'inline-block',
                            fontSize: '.75rem',
                            marginLeft: 4,
                            transition: 'color 150ms linear',
                        },
                    },
                    '& .navigation-sub-section-item-total': {
                        color: colorTextStatic,
                        flex: 'none',
                        fontWeight: fontWeightSemiBold,
                    },
                },

                '&.pushed-right': {
                    opacity: '1 !important',
                    transitionDelay: '10ms',
                },
            },
            '.ui.drawer.overlay-nav': { // tertiary navigation
                minWidth: navigationOpenWidth,
                maxWidth: navigationOpenWidth,

                '& .drawer-container': {
                    transition: 'transform 200ms ease-in-out !important',
                },

                '& .drawer-container-inner': {
                    backgroundColor: '#000',
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    '& .title': {
                        alignItems: 'center',
                        color: '#fff',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 22,
                    },
                },
                '& .navigation, .navigation-sub-items': {
                    background: 'black',
                    opacity: 1,
                },
            },
            '@media only screen and (min-width: 768px)': {
                '.navigation': {
                    opacity: 1,
                    zIndex: zIndexNavigation,
                    '& .navigation-main-items': {
                        '& .navigation-sub-items': {
                            height: '100vh',
                            left: navigationOpenWidth,
                            position: 'fixed',
                            top: 0,
                            width: navigationOpenWidth,
                            margin: 0,
                            padding: 0,
                            '&.fade-in-enter': {
                                opacity: 0,
                                transition: 'opacity 175ms linear',
                                '&.animate-active': { opacity: 1 },
                            },
                            '&.fade-out-leave': {
                                opacity: 1,
                                transition: 'opacity 175ms linear',
                                '&.animate-active': { opacity: 0 },
                            },

                        },
                        '&.disable-hover-transition .navigation-sub-items': {
                            '&.fade-in-enter, &.fade-out-leave': {
                                opacity: 1,
                                transition: 'none',
                                '&.animate-active': { opacity: 1 },
                            },
                        },
                        '& .navigation-sub-item:first-child': { marginTop: logoheight },
                    },
                },
            },
        },
        // navigation v3 styles
        navigationRoot: {
            '& .navigation': {
                '&.navigation-v3.navigation-md': {
                    transition: `width ${collapseTransitionDuration}`,
                    '& .navigation-logo': {
                        padding: '0 0 0 0',
                        display: 'block',
                        '& img.logo-env-prod': {
                            color: 'blue',
                            position: 'relative',
                            top: 18,
                            left: 13,
                        },
                        '& img:not(.logo-env-prod)': {
                            color: 'green',
                            position: 'relative',
                            top: 14,
                            left: 13,
                            transition: `height ${collapseTransitionDuration}, width ${collapseTransitionDuration}`,
                        },
                    },
                    '& .navigation-main-button': {
                        backgroundColor: backgroundFoo,
                        padding: '0 20px',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        overflowX: 'hidden',
                        '& .icon-transition': {
                            height: 14,
                            width: 14,
                            '& svg': {
                                height: 16,
                                width: 16,
                            },
                        },
                        '& .section-icon.collapse-menu': {
                            transition: `transform ${collapseTransitionDuration}`,
                        },
                        '& .section-label': {
                            fontWeight: fontWeightRegular,
                        },
                    },
                    '& .navigation-main-items': {
                        marginTop: 0,
                    },
                    '& .navigation-main-item': {
                        '& .navigation-sub-items': {
                            padding: '10px 0',
                            backgroundColor: backgroundConstrastPrimary,
                        },
                        '& .section-label-container': {
                            cursor: 'pointer',
                        },
                        '&.is-hover,&.is-active': {
                            backgroundColor: backgroundConstrastPrimary,
                        },
                        '&.is-hover.is-active': {
                            boxShadow: `inset 5px 0 0 ${backgroundConstrastPrimary}`,
                        },
                        '&.is-active:not(.is-hover)': {
                            boxShadow: `inset 5px 0 0 ${borderColorHighlight} !important`,
                        },
                        '&.is-hover:not(.is-active)': {
                            boxShadow: `inset 5px 0 0 ${backgroundConstrastPrimary} !important`,
                        },
                    },
                    '& .section-label': {
                        transition: `opacity ${collapseTransitionDuration}`,
                    },
                    '& .navigation-sub-section-item': {
                        '&.is-active': {
                            backgroundColor: backgroundInverseAlternate,
                            boxShadow: `inset 5px 0 0 ${borderColorHighlight}`,
                        },
                    },
                },

                '&.navigation-closed.navigation-md': {
                    width: navigationCollapsedWidth,
                    '& .navigation-logo': {
                        '& img:not(.logo-env-prod)': {
                            width: 35,
                            height: 35,
                        },
                    },
                    '& .navigation-main-item': {
                        '&.is-hover.is-active': {
                            backgroundColor: backgroundConstrastPrimary,
                            boxShadow: `inset 5px 0 0 ${borderColorHighlight} !important`,
                        },
                        '&.is-hover': {
                            '& .navigation-sub-items': {
                                opacity: 1,
                                width: navigationOpenWidth,
                            },
                        },
                    },
                    '& .navigation-sub-items': { // top is calculated in navigation level one
                        transition: `opacity ${collapseTransitionDuration}`,
                        opacity: 0,
                        left: navigationCollapsedWidth,
                        margin: 0,
                        position: 'fixed',
                        height: 'auto !important',
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                        padding: '5px 0',
                        width: 0,
                    },
                    '& .navigation-sub-item:first-child': { marginTop: '0px !important' },
                    '& .section-label': {
                        opacity: 0,
                        display: 'none',
                    },
                },
                '&.navigation-open.navigation-md': {
                    width: navigationOpenWidth,
                    '& .navigation-main-item': {
                        transition: collapseTransitionDuration,
                        '&:first-child': { marginTop: 0 },
                        '&:hover': {
                            backgroundColor: backgroundFoo,
                        },
                    },
                    '& .section-icon.collapse-menu': {
                        transform: 'rotate(180deg)',
                    },
                    '& .navigation-sub-items': {
                        position: 'inherit',
                        height: 'auto',
                        margin: '0 -22px',
                        '& .navigation-sub-item:first-child': { marginTop: '0px !important' },
                    },
                },
            },
            '& .navigation-v3.navigation-md.layout': {
                transition: `padding-left ${collapseTransitionDuration}`,
                '& .app-header': {
                    transition: `padding-left ${collapseTransitionDuration}`,
                },
                '& .notification-center': {
                    transition: `padding-left ${collapseTransitionDuration}`,
                    '& .container-content': {
                        padding: '0 22px',
                        '& .notification-center-list': {
                            marginLeft: -20,
                        },
                    },
                },
                '& .ui.rail, .ui.rail.filter-rail': {
                    transition: `padding-left ${collapseTransitionDuration}, width ${collapseTransitionDuration}`,
                    '& .view-scrollbar': {
                        paddingRight: 17,
                    },
                    '& .radio-pill': { // status filter
                        display: 'inline-flex',
                    },
                },
                '& .workflow-overview, .page--action_bar': {
                    transition: `left ${collapseTransitionDuration}`,
                },

                '& .page--details_window': {
                    transition: `left ${collapseTransitionDuration}`,
                },
            },

            '& .navigation-v3.navigation-md.navigation-closed.layout': {
                paddingLeft: navigationCollapsedWidth,
                '& .app-header': {
                    paddingLeft: navigationCollapsedWidth,
                },
                '& .ministries_central--roster_action_bar, .action_bar': {
                    left: `${navigationCollapsedWidth}px`,
                },
                '& .ui.rail.filter-rail': {
                    paddingLeft: navigationCollapsedWidth + railExtraPadding,
                    width: 320,
                },
                '& .check_in_page--room_attendees_details_action_bar': {
                    left: `${checkInRoomDetailsPadding}px !important`,
                },
                '& .page--details_window': {
                    left: navigationCollapsedWidth,
                },
            },
            '& .navigation-v3.navigation-md.navigation-open.layout': {
                paddingLeft: navigationOpenWidth,
                '& .app-header': {
                    paddingLeft: navigationOpenWidth,
                },
                '& .ministries_central--roster_action_bar, .page--action_bar': {
                    left: `${navigationOpenWidth}px !important`,
                },
                '& .ui.rail.filter-rail': {
                    paddingLeft: navigationOpenWidth + railExtraPadding,
                    width: 515,
                },
                '& .page--details_window': {
                    left: navigationOpenWidth,
                },
            },
            '& .ui.drawer.overlay-nav': {
                minWidth: navigationOpenWidth,
                maxWidth: navigationOpenWidth,

                '& .drawer-container': {
                    transition: 'transform 200ms ease-in-out !important',
                },

                '& .drawer-container-inner': {
                    backgroundColor: '#000',
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    '& .title': {
                        alignItems: 'center',
                        color: '#fff',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 22,
                    },
                },
                '& .navigation, .navigation-sub-items': {
                    background: 'black',
                    opacity: 1,
                },
            },
            '@media only screen and (min-width: 768px)': {
                '& .navigation.navigation-v3.navigation-md.navigation-open': {
                    '& .navigation-sub-items': {
                        margin: '0 -22px !important',
                    },
                },
                '& .navigation.navigation-v3.navigation-md.navigation-closed': {
                    '& .navigation-main-items': {
                        '& .navigation-main-item': {
                            '&:first-child': {
                                marginTop: 0,
                            },
                        },
                        '& .navigation-sub-items': {
                            left: navigationCollapsedWidth,
                        },
                    },
                },
            },
            '@media only screen and (max-width: 767px)': {
                '& .navigation.navigation-v3.navigation-sm': {
                    zIndex: 100000,
                    opacity: 1,
                    width: 0,
                    transition: `width ${collapseTransitionDuration}`,
                    backgroundColor: '#38424d',
                    '& .navigation-logo': {
                        display: 'flex',
                        height: mobileHeaderHeight,
                        padding: '0 0 0 13px',
                    },
                    '& .close-button': {
                        height: mobileHeaderHeight,
                        transition: `opacity ${collapseTransitionDuration}`,
                        position: 'absolute',
                        paddingRight: 0,
                        right: 15,
                    },
                    '& .navigation-main-items': {
                        '& .navigation--level-one--breadcrumbs-title': {
                            whiteSpace: 'break-spaces',
                        },
                        '& .navigation--level-one--breadcrumbs-title.vertically-expanded': {
                            height: '100%',
                        },
                    },
                    '& .navigation-main-item': {
                        '&.vertically-expanded': {
                            height: '100%',
                            backgroundColor: backgroundBlack,
                        },
                        '&:first-child': {
                            marginTop: 0,
                        },
                        '&.is-hover,&.is-active': {
                            backgroundColor: backgroundConstrastPrimary,
                        },
                        '&.is-hover.is-active': {
                            boxShadow: `inset 5px 0 0 ${borderColorBlack}`,
                        },
                        '&.is-active:not(.is-hover)': {
                            boxShadow: `inset 5px 0 0 ${borderColorHighlight}`,
                        },
                        '&.is-hover:not(.is-active)': {
                            boxShadow: `inset 5px 0 0 ${borderColorBlack}`,
                        },
                        '& .breadcrumbs-forward-button': {
                            marginRight: 0,
                        },
                        '& .navigation-sub-items': {
                            margin: '0 -22px',
                            padding: '5px 0',
                            backgroundColor: backgroundConstrastPrimary,
                            '& .navigation-sub-section-item': {
                                '&.is-active': {
                                    backgroundColor: backgroundInverseAlternate,
                                    boxShadow: `inset 5px 0 0 ${borderColorHighlight}`,
                                },
                            },
                            '&.vertically-expanded': {
                                height: '100%',
                                backgroundColor: backgroundBlack,
                            },
                        },
                    },
                },
                '& .navigation.navigation-v3.navigation-sm.navigation-open': {
                    width: navigationOpenWidth,
                    '& .close-button': {
                        opacity: 1,
                    },
                },
                '& .layout.navigation-v3.navigation-sm.navigation-open': {
                    '& .mobile-content-transparent-wrapper': {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        zIndex: 8000,
                        opacity: 0.6,
                        overflowY: 'scroll',
                    },
                },
                '& .navigation.navigation-v3.navigation-sm.navigation-closed': {
                    width: 0,
                    '& .navigation-main-button': {
                        padding: '0 0 0 0',
                        '& .icon-transition': {
                            width: 0,
                            height: 0,
                            opacity: 0,
                            '& .section-icon': {
                                width: 0,
                                height: 0,
                            },
                        },
                        '& .section-label': {
                            opacity: 0,
                            width: 0,
                        },
                    },
                    '& .navigation-logo': {
                        padding: '0 0 0 0',
                        opacity: 0,
                        width: 0,
                        zIndex: -1,
                        overflow: 'hidden',
                        '& svg': {
                            width: 0,
                            height: 0,
                        },
                    },
                    '& .close-button': {
                        width: 0,
                        padding: '0 0 0 0 !important',
                        opacity: 0,
                        overflow: 'hidden',
                    },
                },
            },
        },
    };
};
