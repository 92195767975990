enum JourneyCatalogText {
    AttendAnEvent = 'Event Catalog',
    AttendAServingOpportunity = 'Serving Opp Catalog',
    FillOutAForm = 'Form',
    FillOutAFormApproval = 'Form',
    ListenToAudio = 'Audio Resource',
    ReadADocument = 'PDF Document',
    ReadText = 'Text Page',
    RegisterForAnEvent = 'Event Catalog',
    RegisterForAMinistry = 'Ministry Catalog',
    RegisterForAServingOpportunity = 'Serving Opp Catalog',
    TakeAQuiz = 'Quiz',
    WatchAVideo = 'Video Resource',
}

export default JourneyCatalogText;
