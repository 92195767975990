import {
    get,
    map,
    union,
} from 'lodash';
import ChunkedPaginationUtils from '../../global/chunkedPaginationUtils.js';

const pagedScheduledVolunteers = new ChunkedPaginationUtils(20, 60);

export const DEFAULT_STATE = {
    allPersonIds: [],
    canLoadMore: false,
    capacity: 0,
    grandTotal: 0,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: pagedScheduledVolunteers.getPageSize(),
    results: [],
    total: 0,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCHSCHEDULEDVOLUNTEERS':
        {
            pagedScheduledVolunteers.loadPage(
                action.result.volunteers,
                action.result.total,
                action.callbackParams.first,
            );

            const requestGrandTotal = get(action, 'callbackParams.requestGrandTotal', false);

            return {
                ...state,
                allPersonIds: union(
                    state.allPersonIds,
                    map(action.result.volunteers, (v) => v.person.id),
                ),
                results: pagedScheduledVolunteers.getAll(true),
                total: pagedScheduledVolunteers.getTotalCount(),
                canLoadMore: pagedScheduledVolunteers.canLoadMore(),
                needsToRequest: pagedScheduledVolunteers.needsToLoadPage(),
                pageNumber: pagedScheduledVolunteers.getCurrentPageNumber(),
                pageSize: pagedScheduledVolunteers.getPageSize(),
                capacity: requestGrandTotal ? action.result.capacity : state.capacity,
                grandTotal: requestGrandTotal ? action.result.grandTotal : state.grandTotal,
            };
        }

        case 'MinistryServingOpportunityVolunteerActions.ON_SEARCH_SCHEDULED_VOLUNTEERS_NEXTPAGE':
            return {
                ...state,
                results: pagedScheduledVolunteers.getAll(true),
                total: pagedScheduledVolunteers.getTotalCount(),
                canLoadMore: pagedScheduledVolunteers.canLoadMore(),
                needsToRequest: pagedScheduledVolunteers.needsToLoadPage(),
                pageNumber: pagedScheduledVolunteers.getCurrentPageNumber(),
                pageSize: pagedScheduledVolunteers.getPageSize(),
            };

        case 'MinistryServingOpportunityVolunteerActions.ON_RESET_SEARCH_SCHEDULED_VOLUNTEERS':
            return DEFAULT_STATE;

        case 'MinistryServingOpportunityActions.ON_UPDATEOCCURRENCECAPACITY':
            return {
                ...state,
                capacity: action.result.capacity,
            };

        default:
            return state;
    }
}
