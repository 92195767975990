export const REPEAT_NONE = 0;
export const REPEAT_DAILY = 1;
export const REPEAT_WEEKLY = 2;
export const REPEAT_BI_WEEKLY = 3;
export const REPEAT_TRI_WEEKLY = 4;
export const REPEAT_FIRST_WEEK_OF_MONTH = 5;
export const REPEAT_SECOND_WEEK_OF_MONTH = 6;
export const REPEAT_THIRD_WEEK_OF_MONTH = 7;
export const REPEAT_FOURTH_WEEK_OF_MONTH = 8;
export const REPEAT_LAST_WEEK_OF_MONTH = 9;
export const REPEAT_MONTHLY = 10;
export const REPEAT_YEARLY = 11;
export const REPEAT_YEARLY_FIRST_WEEK_OF_MONTH = 12;
export const REPEAT_YEARLY_SECOND_WEEK_OF_MONTH = 13;
export const REPEAT_YEARLY_THIRD_WEEK_OF_MONTH = 14;
export const REPEAT_YEARLY_FOURTH_WEEK_OF_MONTH = 15;
export const REPEAT_YEARLY_LAST_WEEK_OF_MONTH = 16;

export const PUBLISH_EVENTBRITE = 'Eventbrite';
export const PUBLISH_TENANT = 'TenantWebsite';
export const PUBLISHED_STATUS = 'Published';
