import { dateUtils } from '@saddlebackchurch/react-cm-ui';
import {
    cloneDeep,
    find,
    isEmpty,
    isNil,
    map,
    maxBy,
    range,
    remove,
    sortBy,
    isEqual,
} from 'lodash';
import moment from 'moment-timezone';
import * as EVENT from './actions/eventActionConstants.js';
import { EVENT_CATEGORY_ID_WORSHIP_SERVICE } from './constants.js';
import { SCHEDULE_ENTITY_TYPE } from '../../global/constants.js';
import { translationFactory } from '../../global/i18nUtils.js';
import EnumerationStore from '../../js/stores/EnumerationStore.js';
import MomentUtils from '../../global/momentUtils.js';
import Utils, {
    date2quarter,
    quarter2date,
} from '../../global/utils/utils.js';

const i18n = translationFactory('Ministries.MinistryEvents');

export const REPEAT_NONE = 1;
export const REPEAT_DAILY = 2;
export const REPEAT_WEEKLY = 3;
export const REPEAT_MONTHLY = 4;
export const REPEAT_YEARLY = 5;
export const REPEAT_CUSTOM = 6;

export const REPEATING = {
    [REPEAT_DAILY]: i18n('Daily'),
    [REPEAT_WEEKLY]: i18n('Weekly'),
    [REPEAT_MONTHLY]: i18n('Monthly'),
    [REPEAT_YEARLY]: i18n('Yearly'),
};

export const REPEAT_OPTIONS = [
    { value: REPEAT_DAILY, label: i18n('Daily') },
    { value: REPEAT_WEEKLY, label: i18n('Weekly') },
    { value: REPEAT_MONTHLY, label: i18n('Monthly') },
    { value: REPEAT_YEARLY, label: i18n('Yearly') },
];

export const ID_END_REPEAT_NEVER = 'ui-radio--end_repeat_never';
export const ID_END_REPEAT_ON_DATE = 'ui-radio--end_repeat_on_date';
export const ID_END_REPEAT_AFTER_OCCURRENCES =
    'ui-radio--end_repeat_after_occurrences';

export const ID_REPEAT_ON_DAY = 'ui-radio--repeat_on_day';
export const ID_REPEAT_ON_THE = 'ui-radio--repeat_on_the';

export const DAYS = map(range(1, 32), (day) => ({
    value: day,
    label: moment({ month: 11, day }).format('Do'),
}));

export const WEEK_NUMS = [
    { value: 1, label: i18n('First') },
    { value: 2, label: i18n('Second') },
    { value: 3, label: i18n('Third') },
    { value: 4, label: i18n('Fourth') },
    { value: 5, label: i18n('Last') },
];

export const WEEKS = map(range(1, 8), (d) => ({
    value: d,
    label: moment.weekdays(d - 1),
}));

export const MONTHS = map(range(1, 13), (m) => ({
    value: m,
    label: moment.months(m - 1),
}));

export const OCCURRENCES = map(range(2, 25), (v) => ({
    value: v,
    label: `${v} ${i18n('Occurrences')}`,
}));

export const DAYS_OPTIONS = [
    {
        value: 1,
        label: `${i18n('Day')}`,
    },
    ...map(range(2, 7), (value) => ({
        value,
        label: `${value} ${i18n('Days')}`,
    })),
];

export const WEEKS_OPTIONS = [
    {
        value: 1,
        label: `${i18n('Week')}`,
    },
    ...map(range(2, 7), (value) => ({
        value,
        label: `${value} ${i18n('Weeks')}`,
    })),
];

export const MONTHS_OPTIONS = [
    {
        value: 1,
        label: `${i18n('Month')}`,
    },
    ...map(range(2, 7), (value) => ({
        value,
        label: `${value} ${i18n('Months')}`,
    })),
];

export const YEARS_OPTIONS = [
    {
        value: 1,
        label: `${i18n('Year')}`,
    },
    ...map(range(2, 7), (value) => ({
        value,
        label: `${value} ${i18n('Years')}`,
    })),
];

export const generateRepetitions = (date) => {
    const fullDate = moment.unix(date).utc();
    const weeksToStart = dateUtils.getWeekNum(date);
    let weekNum = null;

    if (weeksToStart === 1) {
        weekNum = i18n('First');
    } else if (weeksToStart === 2) {
        weekNum = i18n('Second');
    } else if (weeksToStart === 3) {
        weekNum = i18n('Third');
    } else if (weeksToStart === 4) {
        weekNum = i18n('Fourth');
    } else {
        weekNum = i18n('Last');
    }

    return [
        { value: REPEAT_NONE, label: i18n('DoesNotRepeat') },
        { value: REPEAT_DAILY, label: i18n('RepeatDaily') },
        {
            value: REPEAT_WEEKLY,
            label: Utils.format(i18n('RepeatWeekly'), fullDate.format('dddd')),
        },
        {
            value: REPEAT_MONTHLY,
            label: Utils.format(
                i18n('RepeatMonthly'),
                weekNum,
                fullDate.format('dddd'),
            ),
        },
        {
            value: REPEAT_YEARLY,
            label: Utils.format(i18n('RepeatYearly'), fullDate.format('MMMM D')),
        },
        { value: REPEAT_CUSTOM, label: i18n('RepeatCustom') },
    ];
};

export const getScheduleFromEvent = (date, event, customOptions) => {
    const schedule = {
        endDate: null,
        scheduleEntityTypeId: event.eventCategoryId === EVENT_CATEGORY_ID_WORSHIP_SERVICE ?
            SCHEDULE_ENTITY_TYPE.worshipService : SCHEDULE_ENTITY_TYPE.event,
        startDate: date,
    };

    const startTime = quarter2date(event.startTime);
    const endTime = quarter2date(event.endTime);

    const startDate = moment
        .unix(schedule.startDate)
        .utc()
        .hour(startTime.hour())
        .minute(startTime.minute())
        .second(0)
        .millisecond(0);

    const endDate = moment
        .unix(event.endDate)
        .utc()
        .hour(endTime.hour())
        .minute(endTime.minute())
        .second(0)
        .millisecond(0);

    let ts = moment.duration(endDate.diff(startDate));

    if (ts.asSeconds() <= 0) {
        ts = moment.duration(0);
    }

    const duration = MomentUtils.durationToTimeSpanString(ts);

    if (!isEmpty(customOptions)) {
        schedule.custom = true;
        schedule.sequences = [
            {
                interval: customOptions.repeat,
                timeZone: event.tz,
            },
        ];

        if (customOptions.endRepeat === ID_END_REPEAT_AFTER_OCCURRENCES) {
            schedule.sequences[0].endDate = null;
            schedule.occurrencesCount = customOptions.endsAfter;
        } else if (customOptions.endRepeat === ID_END_REPEAT_ON_DATE) {
            schedule.sequences[0].endDate = customOptions.endRepeatOnDate;
            schedule.occurrencesCount = null;
        } else {
            schedule.sequences[0].endDate = null;
            schedule.occurrencesCount = null;
        }

        if (customOptions.repeating === REPEAT_DAILY) {
            schedule.sequences[0].recurrenceFrequencyId = EVENT.REPEAT_DAILY;
        } else if (customOptions.repeating === REPEAT_WEEKLY) {
            const dayOfWeek = customOptions.repeatOnWeeks[0];
            schedule.sequences[0].dayOfWeek = dayOfWeek;
            schedule.sequences[0].recurrenceFrequencyId = EVENT.REPEAT_WEEKLY;
            schedule.startDayOfWeek = customOptions.startDayOfWeek - 1;
        } else if (customOptions.repeating === REPEAT_MONTHLY) {
            if (customOptions.day) {
                startDate.date(customOptions.day);
                schedule.sequences[0].recurrenceFrequencyId =
                    EVENT.REPEAT_MONTHLY;
            } else {
                schedule.sequences[0].dayOfWeek = customOptions.weekDay - 1;
                if (customOptions.weekNum === 1) {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_FIRST_WEEK_OF_MONTH;
                } else if (customOptions.weekNum === 2) {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_SECOND_WEEK_OF_MONTH;
                } else if (customOptions.weekNum === 3) {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_THIRD_WEEK_OF_MONTH;
                } else if (customOptions.weekNum === 4) {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_FOURTH_WEEK_OF_MONTH;
                } else {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_LAST_WEEK_OF_MONTH;
                }
            }
        } else if (customOptions.repeating === REPEAT_YEARLY) {
            if (customOptions.repeatOnMonth && customOptions.repeatOnDay) {
                startDate.date(customOptions.repeatOnDay);
                startDate.month(customOptions.repeatOnMonth - 1);
                schedule.sequences[0].recurrenceFrequencyId =
                    EVENT.REPEAT_YEARLY;
            } else {
                startDate.month(customOptions.repeatTheMonth - 1);
                schedule.sequences[0].dayOfWeek =
                    customOptions.repeatTheWeekDay - 1;
                if (customOptions.repeatTheWeekNum === 1) {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_YEARLY_FIRST_WEEK_OF_MONTH;
                } else if (customOptions.repeatTheWeekNum === 2) {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_YEARLY_SECOND_WEEK_OF_MONTH;
                } else if (customOptions.repeatTheWeekNum === 3) {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_YEARLY_THIRD_WEEK_OF_MONTH;
                } else if (customOptions.repeatTheWeekNum === 4) {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_YEARLY_FOURTH_WEEK_OF_MONTH;
                } else {
                    schedule.sequences[0].recurrenceFrequencyId =
                        EVENT.REPEAT_YEARLY_LAST_WEEK_OF_MONTH;
                }
            }
        }

        startDate.hour(0).minute(0);

        schedule.sequences[0] = {
            ...schedule.sequences[0],
            startDate: startDate.unix(),
            startTime: startTime.format('HH:mm'),
            duration,
        };
        if (isNil(schedule.sequences[0].dayOfWeek)) {
            schedule.sequences[0].dayOfWeek = startDate.day();
        }

        if (customOptions.repeating === REPEAT_WEEKLY) {
            for (let i = 1; i < customOptions.repeatOnWeeks.length; i += 1) {
                const sequence = cloneDeep(schedule.sequences[0]);
                sequence.dayOfWeek = customOptions.repeatOnWeeks[i];
                schedule.sequences.push(sequence);
            }
        }
    } else {
        let recurrenceFrequencyId = EVENT.REPEAT_NONE;

        if (event.repeating === REPEAT_DAILY) {
            recurrenceFrequencyId = EVENT.REPEAT_DAILY;
        } else if (event.repeating === REPEAT_WEEKLY) {
            recurrenceFrequencyId = EVENT.REPEAT_WEEKLY;
        } else if (event.repeating === REPEAT_MONTHLY) {
            const weekNum = dateUtils.getWeekNum(schedule.startDate);
            recurrenceFrequencyId =
                EVENT.REPEAT_FIRST_WEEK_OF_MONTH + weekNum - 1;
        } else if (event.repeating === REPEAT_YEARLY) {
            recurrenceFrequencyId = EVENT.REPEAT_YEARLY;
        }

        startDate.hour(0).minute(0);

        schedule.sequences = [
            {
                recurrenceFrequencyId,
                startDate: startDate.unix(),
                startTime: startTime.format('HH:mm'),
                duration,
                dayOfWeek: startDate.day(),
                timeZone: event.tz,
            },
        ];
    }

    schedule.startDate = schedule.sequences[0].startDate;

    return schedule;
};

export const getEventFromSchedule = (schedule) => {
    const state = {};

    if (!isEmpty(schedule) && !isNil(schedule.sequences)) {
        state.historySequences = [];
        state.actualSequences = sortBy(schedule.sequences, 'startTime');

        if (find(state.actualSequences, (v) => !v.endDate)) {
            state.historySequences = remove(
                state.actualSequences,
                (v) => v.endDate,
            );
        } else {
            const date = maxBy(state.actualSequences, 'endDate');
            const maxEndDate = date && date.endDate;
            state.historySequences = remove(
                state.actualSequences,
                (v) => v.endDate < maxEndDate,
            );
        }

        if (state.actualSequences.length === 0) return null;

        state.tz = state.actualSequences[0].timeZone;
        const tz = EnumerationStore.findTimeZone(state.tz);
        state.tzLabel = (tz && tz.displayName) || state.tz;

        const startTime = moment(state.actualSequences[0].startTime, 'HH:mm');
        const endTime = startTime
            .clone()
            .add(moment.duration(state.actualSequences[0].duration));
        state.startTime = date2quarter(startTime);
        state.endTime = date2quarter(endTime);

        state.startDate = state.actualSequences[0].startDate;

        const startDate = moment.unix(state.startDate).utc();
        const endDate = startDate
            .clone()
            .hour(startTime.hour())
            .minute(startTime.minute())
            .add(moment.duration(state.actualSequences[0].duration))
            .hour(0)
            .minute(0);
        state.endDate = endDate.unix();

        state.repeatings = generateRepetitions(state.startDate);
        let repeating = REPEAT_NONE;

        if (
            state.actualSequences[0].recurrenceFrequencyId === EVENT.REPEAT_NONE
        ) {
            repeating = REPEAT_NONE;
        } else if (
            state.actualSequences[0].recurrenceFrequencyId ===
            EVENT.REPEAT_DAILY
        ) {
            repeating = REPEAT_DAILY;
        } else if (
            state.actualSequences[0].recurrenceFrequencyId ===
            EVENT.REPEAT_WEEKLY
        ) {
            repeating = REPEAT_WEEKLY;
        } else if (
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_FIRST_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_SECOND_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_THIRD_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_FOURTH_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_LAST_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_MONTHLY
        ) {
            repeating = REPEAT_MONTHLY;
        } else if (
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_YEARLY ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_YEARLY_FIRST_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_YEARLY_SECOND_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_YEARLY_THIRD_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_YEARLY_FOURTH_WEEK_OF_MONTH ||
            state.actualSequences[0].recurrenceFrequencyId ===
                EVENT.REPEAT_YEARLY_LAST_WEEK_OF_MONTH
        ) {
            repeating = REPEAT_YEARLY;
        }

        if (repeating !== REPEAT_NONE) {
            const isOccurrenceRecurring = state.actualSequences[0].isRecurring;
            const hasOccurrencesCount = !isNil(schedule.occurrencesCount);
            const endRepeatOnDate = (isOccurrenceRecurring && hasOccurrencesCount) ||
            !schedule.endDate ? null : state.startDate;
            state.repeating = repeating;
            state.customOptions = {
                endsAfter: isOccurrenceRecurring ? schedule.occurrencesCount : 2,
                endRepeat: ID_END_REPEAT_NEVER,
                endRepeatOnDate,
                repeat: state.actualSequences[0].interval || 1,
                repeating,
            };

            if (schedule.occurrencesCount) {
                state.customOptions.endRepeat = ID_END_REPEAT_AFTER_OCCURRENCES;
                state.customOptions.endsAfter = schedule.occurrencesCount;
            } else if (state.actualSequences[0].endDate) {
                state.customOptions.endRepeat = ID_END_REPEAT_ON_DATE;
                state.customOptions.endRepeatOnDate =
                    state.actualSequences[0].endDate;
            }

            if (repeating === REPEAT_WEEKLY) {
                state.customOptions.repeatOnWeeks = map(
                    state.actualSequences,
                    'dayOfWeek',
                );
                state.customOptions.startDayOfWeek = schedule.startDayOfWeek + 1;
            } else if (repeating === REPEAT_MONTHLY) {
                if (
                    state.actualSequences[0].recurrenceFrequencyId ===
                    EVENT.REPEAT_MONTHLY
                ) {
                    state.customOptions.day = startDate.date();
                    state.customOptions.repeatOn = ID_REPEAT_ON_DAY;
                } else {
                    state.customOptions.weekDay =
                        state.actualSequences[0].dayOfWeek + 1;
                    state.customOptions.repeatOn = ID_REPEAT_ON_THE;
                    if (
                        state.actualSequences[0].recurrenceFrequencyId ===
                        EVENT.REPEAT_FIRST_WEEK_OF_MONTH
                    ) {
                        state.customOptions.weekNum = 1;
                    } else if (
                        state.actualSequences[0].recurrenceFrequencyId ===
                        EVENT.REPEAT_SECOND_WEEK_OF_MONTH
                    ) {
                        state.customOptions.weekNum = 2;
                    } else if (
                        state.actualSequences[0].recurrenceFrequencyId ===
                        EVENT.REPEAT_THIRD_WEEK_OF_MONTH
                    ) {
                        state.customOptions.weekNum = 3;
                    } else if (
                        state.actualSequences[0].recurrenceFrequencyId ===
                        EVENT.REPEAT_FOURTH_WEEK_OF_MONTH
                    ) {
                        state.customOptions.weekNum = 4;
                    } else {
                        state.customOptions.weekNum = 5;
                    }
                }
            } else if (repeating === REPEAT_YEARLY) {
                if (
                    state.actualSequences[0].recurrenceFrequencyId ===
                    EVENT.REPEAT_YEARLY
                ) {
                    state.customOptions.repeatOn = ID_REPEAT_ON_DAY;
                    state.customOptions.repeatOnDay = startDate.date();
                    state.customOptions.repeatOnMonth = startDate.month() + 1;
                    state.customOptions.repeatTheWeekNum = dateUtils.getWeekNum(
                        state.startDate,
                    );
                    state.customOptions.repeatTheWeekDay = startDate.day() + 1;
                    state.customOptions.repeatTheMonth = startDate.month() + 1;
                } else {
                    state.customOptions.repeatOn = ID_REPEAT_ON_THE;
                    state.customOptions.repeatOnMonth = startDate.month() + 1;
                    state.customOptions.repeatOnDay = startDate.date();
                    state.customOptions.repeatTheWeekDay =
                        state.actualSequences[0].dayOfWeek + 1;
                    state.customOptions.repeatTheMonth = startDate.month() + 1;

                    if (
                        state.actualSequences[0].recurrenceFrequencyId ===
                        EVENT.REPEAT_YEARLY_FIRST_WEEK_OF_MONTH
                    ) {
                        state.customOptions.repeatTheWeekNum = 1;
                    } else if (
                        state.actualSequences[0].recurrenceFrequencyId ===
                        EVENT.REPEAT_YEARLY_SECOND_WEEK_OF_MONTH
                    ) {
                        state.customOptions.repeatTheWeekNum = 2;
                    } else if (
                        state.actualSequences[0].recurrenceFrequencyId ===
                        EVENT.REPEAT_YEARLY_THIRD_WEEK_OF_MONTH
                    ) {
                        state.customOptions.repeatTheWeekNum = 3;
                    } else if (
                        state.actualSequences[0].recurrenceFrequencyId ===
                        EVENT.REPEAT_YEARLY_FOURTH_WEEK_OF_MONTH
                    ) {
                        state.customOptions.repeatTheWeekNum = 4;
                    }
                }
            }
        } else {
            state.repeating = repeating;
        }

        state.repeatingLabel = find(state.repeatings, {
            value: state.repeating,
        }).label;
    }

    return state;
};

export const isScheduleEndDateValid = (startDate, schedule) => (
    !schedule.sequences[0].endDate ||
        moment.unix(startDate).startOf('day') <= moment.unix(schedule.sequences[0].endDate).startOf('day')
);

export const scheduleSequencesMapper = (sequence) => {
    const utcSequenceEndDate = moment.unix(sequence.endDate).utc();
    const actualSequenceEndDate = isNil(sequence.endDate) ? null : moment()
        .date(utcSequenceEndDate.date())
        .month(utcSequenceEndDate.month())
        .year(utcSequenceEndDate.year())
        .endOf('day')
        .unix();

    return {
        ...sequence,
        endDate: actualSequenceEndDate,
    };
};

export const checkIsCustomScheduleChanged = (customOptions, initialCustomOptions) => {
    if (!customOptions || !initialCustomOptions) {
        return false;
    }

    return !isEqual(customOptions, initialCustomOptions);
};
