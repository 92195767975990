import { i18n } from '../../../global/constants.js';

export const DEBOUNCE_TIMEOUT = 500;

export const SHARED_ACCOUNT_SORT_OPTION_DEFAULT = {
    label: i18n('Setup Date (Newest - Oldest)'),
    value: 'setupdate',
};

export const SHARED_ACCOUNT_SORT_OPTIONS = [
    {
        label: i18n('Setup Date (Newest - Oldest'),
        value: '-setupdate',
    }, {
        label: i18n('Setup Date (Oldest - Newest'),
        value: 'setupdate',
    }, {
        label: i18n('Person First Name (A > Z)'),
        value: 'firstname',
    }, {
        label: i18n('Person First Name (Z > A)'),
        value: '-firstname',
    }, {
        label: i18n('Person Last Name (A > Z)'),
        value: 'lastname',
    }, {
        label: i18n('Person Last Name (Z > A)'),
        value: '-lastname',
    },
];

export const GIVING_ACTIVITY_SEARCH_MAX_RESULTS_LENGTH = 200;
