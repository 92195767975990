export const DEFAULT_STATE = {
    sources: [],
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case 'PersonMergeActions.ON_GETPEOPLE':
            return { ...state, ...action.result };
    }

    return state;
}
