import {
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    classes: PropTypes.shape({
        draft: PropTypes.string,
        root: PropTypes.string,
    }),
    component: PropTypes.string,
    isPublished: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    variant: PropTypes.string,
};

const defaultProps = {
    classes: undefined,
    component: 'span',
    variant: 'body1',
};

const useStyles = makeStyles(({
    palette,
    spacing,
}) => ({
    draft: {
        color: palette.error.main,
        marginLeft: spacing(0.5),
    },
    root: {},
}));

function ArticleTitle(props) {
    const {
        component,
        isPublished,
        title,
        variant,
    } = props;

    const classes = useStyles(props);

    return (
        <Typography
            classes={{
                root: classes.root,
            }}
            component={component}
            variant={variant}
        >
            {!isPublished ? (
                <React.Fragment>
                    {title}

                    <Typography
                        className={classes.draft}
                        component="span"
                    >
                        (draft)
                    </Typography>
                </React.Fragment>
            ) : title}
        </Typography>
    );
}

ArticleTitle.propTypes = propTypes;
ArticleTitle.defaultProps = defaultProps;

export default ArticleTitle;
