import request from 'axios';
import ApiUtils from '../global/apiUtils.js';
import { appReduxStore } from '../global/configureReduxStore.js';

class QuickNotificationActions extends ApiUtils {
    constructor(baseUrl) {
        super(baseUrl, 'Notifications');
    }

    getNotificationNextPage() {
        appReduxStore.dispatch({ type: 'QuickNotificationActions.ON_GETNOTIFICATION_NEXTPAGE' });
    }

    updateUnreadNotificationCount(unreadCount) {
        appReduxStore.dispatch({
            type: 'QuickNotificationActions.ON_UPDATE_UNREAD_NOTIFICATION_COUNT',
            unreadCount,
        });
    }
}

const actions = new QuickNotificationActions('/api/notification/');

actions.buildReduxActionClass(
    {
        name: 'getUserNotifications',
        route: 'user?channel=App&days={days}&pageNumber={pageNumber}&pageSize={pageSize}&isViewed={isViewed}&categories={category}&sort={sortBy}',
        rq: request.get,
    },
    {
        name: 'getUserNotificationDetail',
        route: 'user/{id}',
        rq: request.get,
    },
    {
        name: 'updateNotificationStatus',
        route: 'status',
        rq: request.post,
    },
);

export default actions;
