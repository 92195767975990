/**
* This enumeration contains the Action Type string constants for designation editor
*/
enum ActionType {
    // Action Type for Designation Type Info of Designation
    SET_DESIGNATION_TYPE_ID = 'DesignationEditorActions.ON_UPDATE_DESIGNATION_TYPE_ID',
    // Action Type for Basic Info of Designation
    SET_NAME = 'DesignationEditorActions.ON_UPDATE_NAME',
    SET_DESCRIPTION = 'DesignationEditorActions.ON_UPDATE_DESCRIPTION',
    SET_SHORT_DESCRIPTION = 'DesignationEditorActions.ON_UPDATE_SHORT_DESCRIPTION',
    SET_DESIGNATION_CODE = 'DesignationEditorActions.ON_UPDATE_DESIGNATION_CODE',
    SET_DESIGNATION_CONTACT_EMAIL = 'DesignationEditorActions.ON_UPDATE_DESIGNATION_CONTACT_EMAIL',
    SET_DESIGNATION_IS_ACTIVE = 'DesignationEditorActions.ON_UPDATE_DESIGNATION_IS_ACTIVE',
    SET_DESIGNATION_IS_DEFAULT = 'DesignationEditorActions.ON_UPDATE_DESIGNATION_IS_DEFAULT',
    SET_DESIGNATION_IS_FEATURED = 'DesignationEditorActions.ON_UPDATE_DESIGNATION_IS_FEATURED',
    // Action Type for Image Uploader of Designation
    SET_COMPANION_APP_HEADER = 'DesignationEditorActions.ON_UPDATE_COMPANION_APP_HEADER',
    SET_THUMBNAIL = 'DesignationEditorActions.ON_UPDATE_THUMBNAIL',
    SET_WEB_HEADER = 'DesignationEditorActions.ON_UPDATE_WEB_HEADER',
    // Action Type for Accounting Coding of Designation
    SET_REVENUE_SUB_ACCOUNT_ID = 'DesignationEditorActions.ON_UPDATE_REVENUE_SUB_ACCOUNT_ID',
    SET_DESIGNATION_MATCH_GIVER_HOME_CAMPUS = 'DesignationEditorActions.ON_UPDATE_DESIGNATION_MATCH_GIVER_HOME_CAMPUS',
    // Action Type for Designation Editor
    RESET_DATA = 'DesignationEditorActions.ON_RESET',
    SET_DESIGNATION_EDITOR_FORM_DATA = 'DesignationEditorActions.ON_UPDATE_DESIGNATION_EDITOR_FORM_DATA',
}

export default ActionType;
