import { connect } from 'react-redux';
import { ModalDeprecated } from '@saddlebackchurch/react-cm-ui';
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { theme } from '@saddlebackchurch/react-cm-ui/core/styles';
import { withRouter } from 'react-router';
import { i18n } from '../../../global/constants.js';
import PersonNoteDetail from './personNoteDetail.jsx';

const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    selectedNote: PropTypes.shape({}),
};

const defaultProps = {
    isOpen: false,
    selectedNote: undefined,
};

const mapStateToProps = (state) => {
    const {
        people: {
            record: {
                noteForm: {
                    selectedNote,
                },
            },
        },
    } = state;

    return {
        selectedNote,
    };
};

export function PersonNoteModal(props) {
    const {
        isOpen,
        router,
        selectedNote,
    } = props;

    const onCloseModal = () => {
        let { pathname } = window.location;
        pathname = pathname.replace(/\/note\/\d+/g, '');
        router.push(pathname);
    };
    const modalTitle = i18n('Note Reminder');
    const modalPlaceHolderHeight = 66;
    const modalPlaceholder = (
        <div
            style={{
                flex: 'none',
                fontFamily: 'Source Sans Pro", sans-serif',
                fontSize: '1.25rem',
                fontWeight: '400',
                height: modalPlaceHolderHeight,
                marginRight: '7px',
                textAlign: 'center',
            }}
        >
            {i18n('Note not found')}
        </div>
    );
    const modalContent = selectedNote ?
        (
            <PersonNoteDetail
                personNote={selectedNote}
                onCloseClick={onCloseModal}
            />
        ) : modalPlaceholder;

    return (
        <ModalDeprecated
            closeButton={selectedNote ? false : 'times'}
            isOpen={isOpen}
            onClose={onCloseModal}
            title={modalTitle}
            autoHeight
            minHeight="auto"
            height="auto"
            headerStyle={{
                zIndex: 100,
                backgroundColor: get(theme, 'palette.common.white'),
            }}
            style={{
                width: 500,
            }}
        >
            {isOpen && modalContent}
        </ModalDeprecated>
    );
}

PersonNoteModal.propTypes = propTypes;
PersonNoteModal.defaultProps = defaultProps;

export const PersonNoteModalConnected = withRouter(connect(mapStateToProps)(PersonNoteModal));
