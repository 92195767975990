import {
    isEqual,
    without,
} from 'lodash';

import {
    SERVING_OPPORTUNITY_SORT_OPTIONS,
} from '../../constants.js';

export const defaultFilters = {
    selectedGroupsIds: [],
    selectedHomeCampusIds: [],
    selectedMinistryMembershipIds: [],
    selectedMissingRequirementIds: [],
    selectedStatusIds: [],
    sort: SERVING_OPPORTUNITY_SORT_OPTIONS[2],
};

export const DEFAULT_STATE = {
    appliedFilters: {
        ...defaultFilters,
    },
    areFiltersDirty: false,
    defaultFilters: {
        ...defaultFilters,
    },
    dirtyFilters: {
        ...defaultFilters,
    },
    isFiltering: false,
    searchTerm: '',
};

const updateAppliedFilterState = (prevState, nextAppliedFilters) => {
    const isFiltering = !isEqual(nextAppliedFilters, defaultFilters);

    return {
        ...prevState,
        appliedFilters: nextAppliedFilters,
        areFiltersDirty: false,
        dirtyFilters: {
            ...nextAppliedFilters,
        },
        isFiltering,
    };
};

const updateDirtyFilterState = (prevState, nextDirtyFilters) => {
    const {
        appliedFilters,
    } = prevState;
    const areFiltersDirty = !isEqual(appliedFilters, nextDirtyFilters);

    return {
        ...prevState,
        areFiltersDirty,
        dirtyFilters: nextDirtyFilters,
    };
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'RosterFilterActions.ON_APPLY_FILTERS': {
            const {
                dirtyFilters,
            } = state;
            const isFiltering = !isEqual(dirtyFilters, defaultFilters);

            return {
                ...state,
                appliedFilters: {
                    ...dirtyFilters,
                },
                areFiltersDirty: false,
                isFiltering,
            };
        }

        case 'RosterFilterActions.ON_CLEAR_FILTERS':
            return updateDirtyFilterState(
                state, {
                    ...defaultFilters,
                },
            );

        case 'RosterFilterActions.ON_CLEAR_SEARCH_TERM':
            return {
                ...state,
                searchTerm: DEFAULT_STATE.searchTerm,
            };

        case 'RosterFilterActions.ON_DIRTY_GROUPS_FILTER_CHANGED': {
            const {
                dirtyFilters,
            } = state;

            return updateDirtyFilterState(
                state, {
                    ...dirtyFilters,
                    selectedGroupsIds: action.value,
                },
            );
        }

        case 'RosterFilterActions.ON_DIRTY_HOME_CAMPUS_FILTER_CHANGED': {
            const {
                dirtyFilters,
            } = state;

            return updateDirtyFilterState(
                state, {
                    ...dirtyFilters,
                    selectedHomeCampusIds: action.value,
                },
            );
        }

        case 'RosterFilterActions.ON_DIRTY_MINISTRY_MEMBERSHIP_FILTER_CHANGED': {
            const {
                dirtyFilters,
            } = state;

            return updateDirtyFilterState(
                state, {
                    ...dirtyFilters,
                    selectedMinistryMembershipIds: action.value,
                },
            );
        }

        case 'RosterFilterActions.ON_DIRTY_MISSING_REQUIREMENT_FILTER_CHANGED': {
            const { dirtyFilters } = state;
            return updateDirtyFilterState(
                state,
                { ...dirtyFilters, selectedMissingRequirementIds: action.value },
            );
        }

        case 'RosterFilterActions.ON_DIRTY_SORT_ORDER_CHANGED': {
            const {
                dirtyFilters,
            } = state;

            return updateDirtyFilterState(
                state, {
                    ...dirtyFilters,
                    sort: action.value,
                },
            );
        }

        case 'RosterFilterActions.ON_DIRTY_STATUS_FILTER_CHANGED': {
            const {
                dirtyFilters,
            } = state;
            return updateDirtyFilterState(
                state, {
                    ...dirtyFilters,
                    selectedStatusIds: action.value,
                },
            );
        }

        case 'RosterFilterActions.ON_GROUPS_FILTER_CHANGED_AND_APPLIED': {
            const {
                selectedGroupsId,
                isChecked,
            } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedGroupsIds: isChecked ? [
                    ...state.appliedFilters.selectedGroupsIds,
                    selectedGroupsId,
                ] : without(
                    state.appliedFilters.selectedGroupsIds,
                    selectedGroupsId,
                ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'RosterFilterActions.ON_HOME_CAMPUS_FILTER_CHANGED_AND_APPLIED': {
            const {
                selectedHomeCampusId,
                isChecked,
            } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedHomeCampusIds: isChecked ? [
                    ...state.appliedFilters.selectedHomeCampusIds,
                    selectedHomeCampusId,
                ] : without(
                    state.appliedFilters.selectedHomeCampusIds,
                    selectedHomeCampusId,
                ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'RosterFilterActions.ON_MINISTRY_MEMBERSHIP_FILTER_CHANGED_AND_APPLIED': {
            const {
                selectedMinistryMembershipId,
                isChecked,
            } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedMinistryMembershipIds: isChecked ? [
                    ...state.appliedFilters.selectedMinistryMembershipIds,
                    selectedMinistryMembershipId,
                ] : without(
                    state.appliedFilters.selectedMinistryMembershipIds,
                    selectedMinistryMembershipId,
                ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'RosterFilterActions.ON_MISSING_REQUIREMENT_FILTER_CHANGED_AND_APPLIED': {
            const { selectedMissingRequirementId, isChecked } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedMissingRequirementIds: isChecked ? [
                    ...state.appliedFilters.selectedMissingRequirementIds,
                    selectedMissingRequirementId,
                ] : without(
                    state.appliedFilters.selectedMissingRequirementIds,
                    selectedMissingRequirementId,
                ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'RosterFilterActions.ON_RESET':
            return DEFAULT_STATE;

        case 'RosterFilterActions.ON_SET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: action.value,
            };

        case 'RosterFilterActions.ON_SORT_ORDER_CHANGED_AND_APPLIED': {
            const updatedFilters = {
                ...state.appliedFilters,
                sort: action.value,
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        case 'RosterFilterActions.ON_STATUS_FILTER_CHANGED_AND_APPLIED': {
            const {
                selectedStatusId,
                isChecked,
            } = action.value;
            const updatedFilters = {
                ...state.appliedFilters,
                selectedStatusIds: isChecked ? [
                    ...state.appliedFilters.selectedStatusIds,
                    selectedStatusId,
                ] : without(
                    state.appliedFilters.selectedStatusIds,
                    selectedStatusId,
                ),
            };

            return updateAppliedFilterState(state, updatedFilters);
        }

        default:
            return state;
    }
};
