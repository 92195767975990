import {
    find,
    isEmpty,
    isFunction,
    map,
    noop,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { BEM_BLOCK_NAME } from '../searchConstants';
import { coreMilestonesPropTypes } from '../../record/global/coreMilestonesPropTypes.js';
import LoadMoreTrigger from '../../../js/components/loadMoreTrigger.jsx';
import PersonPanelAccordionItem from '../../global/personPanelAccordionItem.jsx';

const propTypes = {
    activeLoadMore: PropTypes.bool.isRequired,
    canLoadMore: PropTypes.bool.isRequired,
    coreMilestonesByPersonIds: PropTypes.arrayOf(
        coreMilestonesPropTypes,
    ),
    expandPerson: PropTypes.func,
    isAllowedToBypassEligibility: PropTypes.bool,
    isExclamationColumnVisible: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    isSelectRecordVisible: PropTypes.bool,
    onLoadMoreChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    otherDataGroups: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.func,
    ]),
    people: PropTypes.arrayOf(
        PropTypes.shape({}),
    ),
    promptSelectRecord: PropTypes.bool,
    promptViewRecord: PropTypes.bool,
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    selectRecordButtonLabel: PropTypes.string,
    selectRecordDisabledButtonLabel: PropTypes.string,
};

const defaultProps = {
    coreMilestonesByPersonIds: [],
    expandPerson: undefined,
    isAllowedToBypassEligibility: true,
    isExclamationColumnVisible: false,
    isSelectRecordVisible: false,
    onSelect: noop,
    otherDataGroups: null,
    people: [],
    promptSelectRecord: true,
    promptViewRecord: true,
    selectRecordButtonLabel: null,
    selectRecordDisabledButtonLabel: null,
};

export class PersonListGroupView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onViewRecord = this.onViewRecord.bind(this);
    }

    onViewRecord(personId) {
        const {
            router,
        } = this.props;
        router.push(`/people/search-or-add/record/${personId}`);
    }

    render() {
        const {
            activeLoadMore,
            canLoadMore,
            coreMilestonesByPersonIds,
            isAllowedToBypassEligibility,
            isMobile,
            isExclamationColumnVisible,
            isSelectRecordVisible,
            onLoadMoreChange,
            onSelect,
            otherDataGroups,
            expandPerson,
            people,
            promptSelectRecord,
            promptViewRecord,
            selectRecordButtonLabel,
            selectRecordDisabledButtonLabel,
        } = this.props;

        const personAccordionItemList = map(people, (person) => {
            let coreMilestonesData;

            if (!isEmpty(coreMilestonesByPersonIds)) {
                coreMilestonesData = find(
                    coreMilestonesByPersonIds, (
                        coremilestone,
                    ) => person.id === coremilestone.uniquePersonID,
                );
            }

            const updatedPerson = isFunction(expandPerson) ? expandPerson(person) : person;

            const updatedOtherDataGroups = isFunction(otherDataGroups) ?
                otherDataGroups(person) :
                otherDataGroups;

            const selectRecordButtonDisable = !isAllowedToBypassEligibility &&
                updatedPerson.eligibility &&
                !updatedPerson.eligibility.isEligible;

            const selectButtonLabel = selectRecordButtonDisable ?
                (selectRecordDisabledButtonLabel || selectRecordButtonLabel) :
                selectRecordButtonLabel;

            return (
                <PersonPanelAccordionItem
                    className={`${BEM_BLOCK_NAME}-person_panel_accordion`}
                    key={`${BEM_BLOCK_NAME}--person-panel-${person.id}`}
                    coreMilestonesData={coreMilestonesData}
                    isExclamationColumnVisible={isExclamationColumnVisible}
                    isPrimaryInfoOnlyVisible
                    isViewRecordVisible
                    isSelectRecordVisible={isSelectRecordVisible}
                    onSelect={onSelect}
                    otherDataGroups={updatedOtherDataGroups}
                    person={updatedPerson}
                    promptSelectRecord={promptSelectRecord}
                    promptViewRecord={promptViewRecord}
                    selectRecordButtonDisable={selectRecordButtonDisable}
                    selectRecordButtonLabel={selectButtonLabel}
                />
            );
        });

        return (
            <React.Fragment>
                {personAccordionItemList}

                {canLoadMore ? (
                    <div
                        className="margin-top text-center"
                        id={`${BEM_BLOCK_NAME}--group_view_loader`}
                        style={{ marginBottom: !isMobile ? '88px' : '0' }}
                    >
                        <LoadMoreTrigger
                            active={activeLoadMore}
                            onChange={onLoadMoreChange}
                        />
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}

PersonListGroupView.propTypes = propTypes;
PersonListGroupView.defaultProps = defaultProps;

export default withRouter(PersonListGroupView);
