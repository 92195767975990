import _ from 'lodash';
import validator from 'validator';

const DEFAULT_STATE = {
    data: {
        address: {
            address1: '',
            address2: '',
            city: '',
            isAddressValid: false,
            isPostalCodeValid: false,
            postalCode: null,
            isPostalCodeRequired: true,
            country: 'United States of America',
            countryAlpha2: 'US',
            countryAlpha3: 'USA',
            region: '',
            regionCode: '',
        },
        email: {
            value: '',
            isEmailValid: false,
        },
        phone: {
            countryCode: '',
            displayPhoneNumber: '',
            extension: null,
            isHome: true,
            isMobile: false,
            isPhoneValid: false,
            isWork: false,
            phoneNumber: null,
            phoneType: 'Home Phone',
            phoneTypeId: 1,
        },
        isRequirementFullfilled: false,
    },
    isOpen: false,
};

function checkValidity(
    hasAddresssValues,
    isAddressValid,
    emailValue,
    isEmailValid,
    numberValue,
    isPhoneValid,
) {
    if (hasAddresssValues && !!emailValue && !!numberValue) {
        return isAddressValid && isEmailValid && isPhoneValid;
    }

    if (hasAddresssValues && !emailValue && !!numberValue) {
        return isAddressValid && isPhoneValid;
    }

    if (hasAddresssValues && !!emailValue && !numberValue) {
        return isAddressValid && isEmailValid;
    }

    if (!hasAddresssValues && !!emailValue && !!numberValue) {
        return isEmailValid && isPhoneValid;
    }

    if (!hasAddresssValues && !emailValue && !!numberValue) {
        return isPhoneValid;
    }

    if (!hasAddresssValues && !!emailValue && !numberValue) {
        return isEmailValid;
    }

    return isAddressValid;
}

export default (state = DEFAULT_STATE, action) => {
    const actionType = 'ContactFormDrawerActions';
    const {
        address,
        email,
        phone,
    } = state.data;
    const {
        address1,
        city,
        country,
        countryAlpha2,
        postalCode,
        isPostalCodeRequired,
        region,
    } = address;
    const {
        value: emailValue,
    } = email;
    const {
        number,
    } = phone;

    switch (action.type) {
        case `${actionType}.ON_RESET_DATA`: {
            return {
                ...state,
                ...DEFAULT_STATE.data,
            };
        }

        case `${actionType}.ON_SET_IS_DRAWER_OPEN`: {
            return {
                ...state,
                isOpen: action.result,
            };
        }

        case `${actionType}.ON_UPDATE_ADDRESS_1`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;
            const isAddressValid = !!city &&
                !!country &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!region &&
                !!action.result;
            const hasAddresssValues = !!city ||
                !!postalCode ||
                !!region ||
                !!action.result;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                number,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        address1: action.result,
                        isAddressValid,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_ADDRESS_2`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        address2: action.result,
                    },
                },
            };
        }

        case `${actionType}.ON_UPDATE_CITY`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;
            const isAddressValid = !!address1 &&
                !!country &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!region &&
                !!action.result;
            const hasAddresssValues = !!address1 ||
                !!postalCode ||
                !!region ||
                !!action.result;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                number,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        city: action.result,
                        isAddressValid,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_COUNTRY`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;
            const newCountry = (
                action.result && action.result.label
            ) || '';
            const newCountryAlpha2 = (
                action.result && action.result.code
            ) || '';
            const newCountryAlpha3 = (
                action.result && action.result.value
            ) || '';
            const isAddressValid = !!address1 &&
                !!city &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!region &&
                !!newCountry;
            const hasAddresssValues = !!address1 ||
                !!city ||
                !!postalCode ||
                !!region ||
                !!newCountry;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                number,
                isPhoneValid,
            );
            let newIsPostalCodeRequired = false;

            if (_.includes(validator.isPostalCodeLocales, newCountryAlpha2)) {
                newIsPostalCodeRequired = true;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        country: newCountry,
                        countryAlpha2: newCountryAlpha2,
                        countryAlpha3: newCountryAlpha3,
                        isAddressValid,
                        isPostalCodeRequired: newIsPostalCodeRequired,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_EMAIL`: {
            const { isAddressValid } = address;
            const { isPhoneValid } = phone;
            const { result: newEmailValue } = action;
            const newIsEmailValid = !!newEmailValue && validator.isEmail(newEmailValue);
            const hasAddresssValues = !!address1 ||
                !!city ||
                !!postalCode ||
                !!region;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                newEmailValue,
                newIsEmailValid,
                number,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    email: {
                        ...state.data.email,
                        isEmailValid: newIsEmailValid,
                        value: newEmailValue,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_PHONE`: {
            const { isAddressValid } = address;
            const { isEmailValid } = email;

            const {
                countryCode,
                displayPhoneNumber,
                phoneNumber: newPhoneNumber,
            } = action.result;
            const newIsPhoneValid = !!newPhoneNumber && action.result.isValid;
            const hasAddresssValues = !!address1 ||
                !!city ||
                !!postalCode ||
                !!region;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                newPhoneNumber,
                newIsPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    phone: {
                        ...state.data.phone,
                        countryCode,
                        displayPhoneNumber,
                        phoneNumber: newPhoneNumber,
                        isPhoneValid: newIsPhoneValid,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_PHONE_TYPE`: {
            let newIsHome;
            let newIsMobile;
            let newIsWork;
            let newPhoneType;
            let newPhoneTypeId;

            if (action.result.isHome) {
                newIsHome = true;
                newIsMobile = false;
                newIsWork = false;
                newPhoneType = DEFAULT_STATE.data.phone.phoneType;
                newPhoneTypeId = DEFAULT_STATE.data.phone.phoneTypeId;
            }

            if (action.result.isMobile) {
                newIsHome = false;
                newIsMobile = true;
                newIsWork = false;
                newPhoneType = 'Cell Phone';
                newPhoneTypeId = 3;
            }

            if (action.result.isWork) {
                newIsHome = false;
                newIsMobile = false;
                newIsWork = true;
                newPhoneType = 'Work Phone';
                newPhoneTypeId = 2;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    phone: {
                        ...state.data.phone,
                        isHome: newIsHome,
                        isMobile: newIsMobile,
                        isWork: newIsWork,
                        phoneType: newPhoneType,
                        phoneTypeId: newPhoneTypeId,
                    },
                },
            };
        }

        case `${actionType}.ON_UPDATE_PHONE_EXTENSION`: {
            return {
                ...state,
                data: {
                    ...state.data,
                    phone: {
                        ...state.data.phone,
                        extension: action.result,
                    },
                },
            };
        }

        case `${actionType}.ON_UPDATE_POSTAL_CODE`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;
            let newIsPostalCodeValid = true;
            let newIsPostalCodeRequired = false;

            if (_.includes(validator.isPostalCodeLocales, countryAlpha2)) {
                newIsPostalCodeValid = validator.isPostalCode(action.result, countryAlpha2);
                newIsPostalCodeRequired = true;
            }

            const isAddressValid = !!address1 &&
                !!city &&
                !!country &&
                !!region &&
                newIsPostalCodeValid;
            const hasAddresssValues = !!address1 ||
                !!city ||
                !!region ||
                !!action.result;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                number,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        isAddressValid,
                        isPostalCodeValid: newIsPostalCodeValid,
                        postalCode: action.result,
                        isPostalCodeRequired: newIsPostalCodeRequired,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        case `${actionType}.ON_UPDATE_REGION`: {
            const {
                email: {
                    isEmailValid,
                },
                phone: {
                    isPhoneValid,
                },
            } = state.data;
            const newRegion = (
                action.result && action.result.label
            ) || DEFAULT_STATE.data.address.region;
            const newRegionCode = (
                action.result && action.result.value
            ) || DEFAULT_STATE.data.address.regionCode;
            const isAddressValid = !!address1 &&
                !!city &&
                !!country &&
                (
                    (isPostalCodeRequired && !!postalCode) ||
                    (!isPostalCodeRequired)
                ) &&
                !!newRegion;
            const hasAddresssValues = !!address1 ||
                !!city ||
                !!postalCode ||
                !!newRegion;
            const isRequirementFullfilled = checkValidity(
                hasAddresssValues,
                isAddressValid,
                emailValue,
                isEmailValid,
                number,
                isPhoneValid,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    address: {
                        ...state.data.address,
                        isAddressValid,
                        region: newRegion,
                        regionCode: newRegionCode,
                    },
                    isRequirementFullfilled,
                },
            };
        }

        default:
            return state;
    }
};
