import ActionType from '../communicationsCentral.actionTypes';
import { Action } from '../../global/models';

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_ARE_FILTERS_DIRTY:
            return action.payload;
        default:
            return state;
    }
};
