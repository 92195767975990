import {
    isEmpty,
    map,
} from 'lodash';
import {
    Grid,
} from '@saddlebackchurch/react-cm-ui';
import ClassNames from 'classnames';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';
import useMediaQuery from '@saddlebackchurch/react-cm-ui/core/utils/useMediaQuery';
import useTheme from '@saddlebackchurch/react-cm-ui/core/styles/useTheme';
import {
    BEM_BLOCK_NAME,
    IMAGES_PROP_TYPE,
} from './constants.js';
import {
    stepImages,
} from './utils.js';

const propTypes = {
    images: IMAGES_PROP_TYPE,
};

const defaultProps = {
    images: [],
};

const useStyles = makeStyles(({
    palette,
    shape,
    spacing,
}) => {
    const backgroundSize = 'cover';

    const commonImageStyles = {
        backgroundColor: palette.static.main,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize,
        borderRadius: shape.borderRadiusSecondary,
    };

    return {
        thumbnail: {
            ...commonImageStyles,
            boxShadow: [['inset', 0, 0, 0, 1, palette.static.main]],
            height: 59,
            transition: [['box-shadow', '250ms', 'ease-out']],
            width: '100%',
        },
        thumbnailColumn: {
            width: 72 + spacing(1),
        },
        thumbnailSelected: {
            boxShadow: [['inset', 0, 0, 0, 1, palette.active.primary]],
        },
        selectedImage: {
            ...commonImageStyles,
            paddingTop: `${(3 / 4) * 100}%`,
            width: '100%',
        },
        selectedImageColumn: {
            paddingBottom: `${spacing(1.5)}px !important`,
        },
        root: {},
    };
});

function EducationalDialogImages(props) {
    const {
        images: imagesProp,
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallBp = useMediaQuery(theme.breakpoints.only('sm'));
    const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);

    if (!isSmallBp || isEmpty(imagesProp)) {
        return null;
    }

    const images = stepImages(imagesProp);

    const onSelectImage = (imageIndex) => {
        setSelectedImageIndex(imageIndex);
    };

    let thumbnailNodes = null;

    if (images.length > 1) {
        thumbnailNodes = map(images, (image, index) => (
            <Grid.Column
                key={`${BEM_BLOCK_NAME}--thumbnail_image-${index}`}
                className={classes.thumbnailColumn}
            >
                <div
                    className={ClassNames(
                        classes.thumbnail,
                        {
                            [classes.thumbnailSelected]: index === selectedImageIndex,
                        },
                    )}
                    id={`${BEM_BLOCK_NAME}--thumbnail_image-${index}`}
                    onClick={() => onSelectImage(index)}
                    onKeyDown={() => onSelectImage(index)}
                    role="button"
                    style={{
                        backgroundImage: `url(${image.url})`,
                    }}
                    tabIndex={0}
                    title={image.title}
                >
                    &nbsp;
                </div>
            </Grid.Column>
        ));
    }

    return (
        <Grid
            className={classes.root}
            spacing={1}
        >
            <Grid.Column
                className={classes.selectedImageColumn}
                sm={12}
            >
                <div
                    className={classes.selectedImage}
                    id={`${BEM_BLOCK_NAME}--main_image`}
                    style={{
                        backgroundImage: `url(${images[selectedImageIndex].url})`,
                    }}
                />
            </Grid.Column>

            {thumbnailNodes}
        </Grid>
    );
}

EducationalDialogImages.propTypes = propTypes;
EducationalDialogImages.defaultProps = defaultProps;

export default EducationalDialogImages;
