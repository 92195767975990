import {
    cloneDeep,
    concat,
    isEmpty,
    map,
} from 'lodash';
import {
    ALL_TOPICS,
    appendSavedNote,
    computeCategoryStatistics,
    filterAuditLogsByCategory,
    filterAuditLogsByDate,
    transformAuditLogs,
} from './auditLogTransforms.js';

const DEFAULT_FILTER_STATE = {
    startDate: null,
    endDate: null,
    category: ALL_TOPICS,
    subCategory: null,
};

const DEFAULT_STATE = {
    // `activeAuditLogs` represents a (potentially filtered) set of audit logs
    // for the presently selected entity.
    activeAuditLogs: [],
    // `auditLogLookup` is a dictionary/lookup of multiple
    // (complete, never filtered) sets of audit logs by entity.
    // We use this to switch between audit logs for multiple person records
    // when viewing a record that has undergone merges and we wish to see the
    // audit log for the previously merged records.
    auditLogLookup: {},
    // `categories` holds the category and sub-category count statistics for
    // display and filtering
    categories: {},
    // `isFetching` is conventional and self-explanatory.
    isFetching: true,
    // `filters` holds the state of filters on the currently active set of
    // audit logs.
    filters: DEFAULT_FILTER_STATE,
    // `personPreviousIds` holds a collection of person unique IDs of
    // previously merged records.
    previousPersonIds: [],
    // `previousPersonOptions` is `personPreviousIds` transformed for
    // Dropdown/Select options.
    previousPersonOptions: [],
    // `selectedPerson` is the option that is selected of multiple
    // `previousPersonOptions` are available.
    selectedPerson: null,
};

const singleEntityAuditLogs = (state = DEFAULT_STATE, action) => {
    const { params, result } = action;

    switch (action.type) {
        case 'AuditLogActions.ON_BEFORE_GETAUDITLOG':
            return {
                ...state,
                isFetching: true,
            };

        case 'AuditLogActions.ON_FILTERBYCATEGORY':
        {
            const { entityId, category, subCategory } = result;
            const updatedFilters = {
                ...state.filters,
                category,
                subCategory,
            };

            const unfilteredAuditLogs = state.auditLogLookup[entityId];
            const filteredByDate = filterAuditLogsByDate(unfilteredAuditLogs, updatedFilters);
            const fullyFiltered = filterAuditLogsByCategory(filteredByDate, updatedFilters);

            return {
                ...state,
                activeAuditLogs: fullyFiltered,
                filters: updatedFilters,
            };
        }

        case 'AuditLogActions.ON_FILTERBYDATERANGE':
        {
            const { entityId, startDate, endDate } = result;
            const updatedFilters = {
                ...state.filters,
                startDate,
                endDate,
            };

            const unfilteredAuditLogs = state.auditLogLookup[entityId];
            const filteredByDate = filterAuditLogsByDate(unfilteredAuditLogs, updatedFilters);
            const updatedCategoryStatistics = computeCategoryStatistics(filteredByDate);
            const fullyFiltered = filterAuditLogsByCategory(filteredByDate, updatedFilters);

            return {
                ...state,
                activeAuditLogs: fullyFiltered,
                categories: updatedCategoryStatistics,
                filters: updatedFilters,
            };
        }

        case 'AuditLogActions.ON_GETAUDITLOG':
        {
            const transformedAuditLogs = transformAuditLogs(result);
            const categories = computeCategoryStatistics(transformedAuditLogs);
            const updatedAuditLogLookup = cloneDeep(state.auditLogLookup);
            updatedAuditLogLookup[params.id] = transformedAuditLogs;

            return {
                ...state,
                activeAuditLogs: transformedAuditLogs,
                auditLogLookup: updatedAuditLogLookup,
                isFetching: false,
                categories,
            };
        }

        case 'AuditLogActions.ON_GETPERSONPREVIOUSIDS':
        {
            let previousPersonOptions = [];

            if (!isEmpty(result)) {
                previousPersonOptions.push({ label: 'Current Record', value: params.id });
                previousPersonOptions = concat(
                    previousPersonOptions,
                    map(result, (previousId) => ({ label: `${previousId} - Previous Record`, value: previousId })),
                );
            }

            return {
                ...state,
                previousPersonIds: result,
                previousPersonOptions,
            };
        }

        case 'AuditLogActions.ON_RESETSINGLEENTITYAUDITLOG':
            return DEFAULT_STATE;

        case 'AuditLogActions.ON_SAVENOTE':
        {
            const { bodyParams, callbackParams } = action;
            const { id: auditLogId, note } = bodyParams;
            const { entityId, noteCreator } = callbackParams;
            const { auditLogLookup } = state;
            const updatedAuditLogLookup = cloneDeep(auditLogLookup);

            const updatedAuditLogs = appendSavedNote(
                updatedAuditLogLookup[entityId],
                auditLogId,
                note,
                noteCreator,
            );

            updatedAuditLogLookup[entityId] = updatedAuditLogs;

            return {
                ...state,
                activeAuditLogs: updatedAuditLogs,
                auditLogLookup: updatedAuditLogLookup,
            };
        }

        case 'AuditLogActions.ON_SWITCHPERSON':
        {
            const { id: newPersonId } = result;
            const newPersonAuditLog = state.auditLogLookup[newPersonId] || [];
            const newCategoryStats = !isEmpty(newPersonAuditLog) ?
                computeCategoryStatistics(newPersonAuditLog) :
                state.categories;

            return {
                ...state,
                activeAuditLogs: newPersonAuditLog,
                categories: newCategoryStats,
                filters: DEFAULT_FILTER_STATE,
                selectedPerson: result,
            };
        }

        default:
            return state;
    }
};

export default singleEntityAuditLogs;
