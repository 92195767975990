import { combineReducers } from 'redux';
import avatar from '../../global/avatarManagerReducer.js';
import personalForm from './personalFormReducer.js';
import contactForm from './contactFormReducer.js';
import possibleDuplicates from './possibleDuplicatesReducer.js';

const reducers = combineReducers({
    avatar,
    contactForm,
    personalForm,
    possibleDuplicates,
});

export default reducers;
