import {
    map,
    sortBy,
} from 'lodash';
import { QuestionTypeId } from '../../connectionQuestions/global/models';

const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'CustomFieldActions.ON_GET': {
            const fields = map(
                action.result,
                (field) => ({
                    ...field,
                    questionTypeId: field.questionTypeId || QuestionTypeId.Checkbox, // for containers it could be 0, so have to use `||` not `??`
                    choices: sortBy(field.choices, 'sortOrder'),
                }),
            );

            return sortBy(fields, 'sortOrder');
        }

        case 'CustomFieldActions.ON_RESET':
            return DEFAULT_STATE;

        default:
            return state;
    }
};
