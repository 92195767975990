import ActionType from '../../actionTypes/segmentFilters.actionTypes';
import { Action } from '../../../global/models';

export const INITIAL_STATE = {
    campusLocationIds: [],
    genders: [],
    minAge: 0,
    maxAge: 0,
    maritalStatuses: [],
    hasChildren: null,
    childGradeLevels: [],
    milestoneIds: [],
    isAchieved: false,
    inMinistry: null,
    inSmallGroup: null,
    membershipStatuses: [],
    isDirty: false,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_FILTERS:
            return {
                ...state,
                ...action.payload,
                isDirty: true,
            };
        case ActionType.RESET_FILTERS:
            return {
                ...state,
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};
