import PropTypes from 'prop-types';

export const BEM_BLOCK_NAME = 'new_serving_opportunties_drawer';
export const CHURCH_ENTITY_ID_PROP_TYPE = PropTypes.number;
export const DRAWER_WIDTH = 768;
export const EVENT_DATA_DEFAULT_PROP = undefined;

export const EVENT_DATA_PROP_TYPE = PropTypes.shape({
    id: PropTypes.number.isRequired,
    isRecurring: PropTypes.bool,
    venueId: PropTypes.number.isRequired,
});

export const FORM_MODE_ADD = 'add';
export const FORM_MODE_EDIT = 'edit';

export const MINISTRY_OPTION_PROP_TYPE = PropTypes.shape({
    eventId: PropTypes.number,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.number,
});

export const QUARTERS_PER_HOUR = 4;

export const SERVING_OPPORTUNITY_PROP_TYPE = PropTypes.shape({
    churchEntityId: PropTypes.number,
    contacts: PropTypes.arrayOf(PropTypes.shape({
        personId: PropTypes.number,
        servingOpportunityId: PropTypes.number,
    })),
    description: PropTypes.string,
    eventId: PropTypes.number,
    ministryId: PropTypes.number,
    name: PropTypes.string,
    schedule: PropTypes.shape({
        scheduleEntityTypeId: PropTypes.number,
        sequences: PropTypes.arrayOf(PropTypes.shape({
            duration: PropTypes.string, // e.g. "01:00:00"
            startDate: PropTypes.number, // Unix Timestamp, e.g. 1624665600
            startTime: PropTypes.string, // e.g. "15:00"
            timeZone: PropTypes.string, // e.g. "America/Los_Angeles"
        })),
    }),
    volunteerSettings: PropTypes.arrayOf(PropTypes.shape({
        capacity: PropTypes.number,
    })),
    tempId: PropTypes.string,
});

export const SERVING_OPPORTUNITIES_PROP_TYPE = PropTypes.arrayOf(SERVING_OPPORTUNITY_PROP_TYPE);
export const SERVING_OPP_SHORT_DESCRIPTION_MAX_LENGTH = 120;
export const UX_TYPE_EVENTS = 'events';
export const UX_TYPE_SERVING_OPPORTUNITIES = 'servingOpportunities';
