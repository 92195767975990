import ChunkedPaginationUtils from '../../../global/chunkedPaginationUtils.js';

const localCache = new ChunkedPaginationUtils(25, 50);

const DEFAULT_STATE = {
    isFetching: false,
    data: [],
    hasPinnedNotes: false,
    noteFormStateChange: false,
    wingType: null,
    total: 0,
    canLoadMore: false,
    needsToRequest: false,
    pageNumber: 0,
    pageSize: localCache.getPageSize(),
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'PersonNotesActions.ON_BEFORE_SEARCH': {
            return {
                ...state,
                isFetching: !!action.callbackParams.first,
            };
        }

        case 'PersonNotesActions.ON_SEARCH': {
            localCache.loadPage(
                action.result.results,
                action.result.resultCount,
                action.callbackParams.first,
            );

            return {
                ...state,
                isFetching: false,
                data: localCache.getAll(true),
                hasPinnedNotes: action.result.hasPinnedNotes,
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'PersonNotesActions.ON_NEXTPAGE': {
            return {
                ...state,
                data: localCache.getAll(true),
                total: localCache.getTotalCount(),
                canLoadMore: localCache.canLoadMore(),
                needsToRequest: localCache.needsToLoadPage(),
                pageNumber: localCache.getCurrentPageNumber(),
                pageSize: localCache.getPageSize(),
            };
        }

        case 'PersonNotesActions.ON_RESET':
            return DEFAULT_STATE;

        case 'PersonNotesActions.ON_SET_WING_TYPE': {
            const { value: type } = action;
            return {
                ...state,
                wingType: type,
            };
        }

        case 'PersonNotesActions.ON_SET_NOTE_FORM_CHANGE_STATUS': {
            const { value: status } = action;
            return {
                ...state,
                noteFormStateChange: status,
            };
        }

        default:
            return state;
    }
};
